var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CrudTemplateService } from '@app/shared/templates/crud/crud.template';
import { EHTTPStatus, EMaPUIPermission, Equivalence } from '@mapuilabs/mpl-interfaces';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
let EquivalencesService = class EquivalencesService extends CrudTemplateService {
    constructor(_injector) {
        super(_injector, Equivalence, '/api/equivalences', {
            create: EMaPUIPermission.Equivalences_Create,
            read: EMaPUIPermission.Equivalences_Read,
            update: EMaPUIPermission.Equivalences_Edit,
            delete: EMaPUIPermission.Equivalences_Delete
        });
        this._injector = _injector;
        this.getEquivalencesPage = (pageOptions, search, sort, filters, otherParams) => {
            if (!this._permissionService.authorize(this._permissions.read)) {
                return throwError(EHTTPStatus.Unauthorized);
            }
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (filters) {
                params = params.append('filters', JSON.stringify(filters));
            }
            if (sort) {
                params = params.append('sorting', JSON.stringify(sort));
            }
            if (!pageOptions) {
                //if (pageOptions)
                pageOptions = { page: 1, limit: 10 };
            } //todo: Remove when admin v1 is not used anymore
            params = params.append('pageOptions', JSON.stringify(pageOptions));
            params = this.addOtherParams(params, otherParams);
            return this._http.get(this._endPoint, {
                params: params
            });
        };
    }
    getVouchers(equivalenceId) {
        return this._http.get(`${this._endPoint}/vouchers`, {
            params: {
                _id: equivalenceId
            }
        });
    }
    buildEquivalences(spreadsheetClassBuilder, jsonSpreadsheet, keysToLowerCase) {
        if (!spreadsheetClassBuilder) {
            throw new Error('Cannot build equivalences, spreadsheetClassBuilder not provided.');
        }
        return jsonSpreadsheet.map((obj) => {
            const equivalence = new Equivalence({});
            // Build object based on spreadsheetConfig keys
            const reducer = (accumulator, currentValue) => ({ [currentValue]: accumulator });
            spreadsheetClassBuilder.forEach((item) => {
                const getFromValue = () => item.value;
                const getFromColumn = () => obj[keysToLowerCase ? item.column.toLowerCase() : item.column];
                const applyTransform = (value) => ('transform' in item ? item.transform(value) : value);
                const transformedValue = applyTransform('value' in item ? getFromValue() : getFromColumn());
                const initialValue = item.path.reduceRight(reducer, transformedValue);
                _.merge(equivalence, initialValue);
            });
            return { data: equivalence, metadata: { row: obj.rowNum + 1 } };
        });
    }
    importFromSpreadsheet(spreadsheetImportContainers) {
        const endpoint = `${this._endPoint}/import-spreadsheet`;
        // Guard: permissions
        if (!this._permissionService.authorize(this._permissions.create)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        // Guard: undefined
        if (!spreadsheetImportContainers) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        return this._http.post(endpoint, spreadsheetImportContainers);
    }
};
EquivalencesService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], EquivalencesService);
export { EquivalencesService };
