var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { WidgetAvailableService } from '@services/homepage/widgets/available/widget-available.service';
let AvailableWidgetComponent = class AvailableWidgetComponent {
    constructor(_fuseTranslationLoaderService, _widgetAvailableService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._widgetAvailableService = _widgetAvailableService;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
    }
    ngOnInit() {
        this.getAvailable();
    }
    getAvailable() {
        this._widgetAvailableService.getLastAvailable(10).subscribe((availables) => {
            this.availables = availables;
        }, (error) => {
            if (error.status == 403) {
                this.forbiddenAccess = true;
            }
        });
    }
};
AvailableWidgetComponent = __decorate([
    Component({
        selector: 'mpx-available-widget',
        template: require('./available-widget.component.html').default,
        styles: [require('./available-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof WidgetAvailableService !== "undefined" && WidgetAvailableService) === "function" ? _b : Object])
], AvailableWidgetComponent);
export { AvailableWidgetComponent };
