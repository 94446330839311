import * as angular from 'angular';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { MpHomeController } from './mp-home.controller';
import { MpHomeComponent } from './mp-home.component';
import '../../services/auth/auth.module';
angular
    .module('mapui.home', [
    'mapui.services.auth',
    'mapui.services.hospital',
    'mapui.services.loan',
    'ngResource',
    'infinite-scroll',
    'ngMaterial'
])
    .controller('MpHomeController', MpHomeController)
    .config(configHome)
    .component('mpHome', new MpHomeComponent());
function configHome($stateProvider, $mdIconProvider) {
    $stateProvider.state('mapui.home', {
        url: '/',
        data: {
            access: EMaPUIPermission.Home_Read
        },
        views: {
            'contentangularjs@mapui': {
                template: '<mp-home></mp-home>'
            }
        },
        resolve: {},
        bodyClass: 'home'
    });
    $mdIconProvider.icon('mapui-logo', 'assets/icons/mapui_logo_white.svg');
    $mdIconProvider.icon('hv-logo', 'assets/icons/hv_logo_white.svg');
    $mdIconProvider.icon('hospistock-logo', 'assets/icons/hospistock_logo_white.svg');
}
