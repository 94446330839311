var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
let DatesSelectorComponent = class DatesSelectorComponent {
    constructor() {
        this.onInit = new EventEmitter();
        this.onChange = new EventEmitter();
        this.isReady = new EventEmitter();
        this.dateLabel = new EventEmitter();
        this.startDate = moment.now();
        this.displayUnitOfTime = 'isoWeeks';
        this.skipUnitOfTime = 'weeks';
        this.unitToSkip = 1;
        this.prefix = '';
        this.suffix = '';
        this.format = 'dddd D MMMM YYYY';
        this.lang = 'fr';
    }
    ngOnInit() {
        this.isReady.emit(false);
        if (!this.changeDate()) {
            return;
        }
        this.onInit.emit({ from: this.from.toDate(), to: this.to.toDate() });
        this.isReady.emit(true);
    }
    ngOnChanges(changes) {
        if ((changes.format && !changes.format.firstChange) || (changes.lang && !changes.lang.firstChange)) {
            this.changeFormat();
        }
        if ((changes.startDate && !changes.startDate.firstChange) ||
            (changes.skipUnitOfTime && !changes.skipUnitOfTime.firstChange) ||
            (changes.displayUnitOfTime && !changes.displayUnitOfTime.firstChange)) {
            this.changeDate();
        }
    }
    changeDate() {
        const currentMoment = moment(this.startDate);
        if (!currentMoment) {
            console.error('Start date is invalid');
            return false;
        }
        if (this.max && currentMoment.toDate().getTime() > this.max.getTime()) {
            console.error('Start date > max date');
            return false;
        }
        if (this.min && currentMoment.toDate().getTime() < this.min.getTime()) {
            console.error('Start date < min date');
            return false;
        }
        this.from = currentMoment.clone().startOf(this.displayUnitOfTime);
        this.to = currentMoment.endOf(this.displayUnitOfTime);
        if (!this.from || !this.to) {
            console.error('displayUnitOfTime is invalid');
            return false;
        }
        if (!this.changeFormat()) {
            return false;
        }
        return true;
    }
    changeFormat() {
        this.currentDateLabel = this.capitalize(this.from.locale(this.lang).format(this.format));
        if (!this.currentDateLabel) {
            console.error('Format is invalid');
            return false;
        }
        this.dateLabel.emit(this.currentDateLabel);
        return true;
    }
    capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    onChangeLeft() {
        this.from.subtract(this.unitToSkip, this.skipUnitOfTime);
        this.to.subtract(this.unitToSkip, this.skipUnitOfTime);
        this.changeFormat();
        this.onChange.emit({ from: this.from.toDate(), to: this.to.toDate() });
    }
    onChangeRight() {
        this.from.add(this.unitToSkip, this.skipUnitOfTime);
        this.to.add(this.unitToSkip, this.skipUnitOfTime);
        this.changeFormat();
        this.onChange.emit({ from: this.from.toDate(), to: this.to.toDate() });
    }
};
__decorate([
    Output(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "onInit", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "onChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "isReady", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "dateLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof moment !== "undefined" && moment.MomentInput) === "function" ? _a : Object)
], DatesSelectorComponent.prototype, "startDate", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof moment !== "undefined" && (_b = moment.unitOfTime) !== void 0 && _b.StartOf) === "function" ? _c : Object)
], DatesSelectorComponent.prototype, "displayUnitOfTime", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof moment !== "undefined" && (_d = moment.unitOfTime) !== void 0 && _d.DurationConstructor) === "function" ? _e : Object)
], DatesSelectorComponent.prototype, "skipUnitOfTime", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "unitToSkip", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "prefix", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "suffix", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "format", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Date !== "undefined" && Date) === "function" ? _f : Object)
], DatesSelectorComponent.prototype, "max", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Date !== "undefined" && Date) === "function" ? _g : Object)
], DatesSelectorComponent.prototype, "min", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatesSelectorComponent.prototype, "lang", void 0);
DatesSelectorComponent = __decorate([
    Component({
        selector: 'mpx-dates-selector',
        template: require('./dates-selector.component.html').default,
        styles: [require('./dates-selector.component.scss')]
    })
], DatesSelectorComponent);
export { DatesSelectorComponent };
