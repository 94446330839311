import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MpModalAccessController {
    /** @ngInject */
    constructor(ModalService, Access) {
        this.ModalService = ModalService;
        this.Access = Access;
        this.ok = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        this.contactUs = (event) => {
            this.ModalService.show({
                component: 'mp-modal-contact',
                event: event,
            });
        };
        this.accessProblem = this.Access.isFree();
    }
}
