var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { Error404Component } from './error404/error404.component';
const states = [
    {
        name: 'mapui.common-v2.error-404',
        url: '/errors/404',
        views: {
            'content@mapui': {
                component: Error404Component
            }
        }
    }
];
let CommonRoutingModule = class CommonRoutingModule {
};
CommonRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], CommonRoutingModule);
export { CommonRoutingModule };
