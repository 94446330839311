export const locale = {
    lang: 'fr',
    data: {
        SOURCE_INST_LIST: {
            LIST: `Liste de médicaments`,
            FINISH_BTN: `Terminer`,
            TABLE: {
                NAME: `Nom`,
                DOSAGE: `Dosage`,
                POSOLOGY: `Posologie`
            },
            FINISH_CONFIRM: {
                TITLE: `Valider la prescription`,
                MESSAGE: `En validant vous confirmez avoir complété entièrement la prescription. L'activité changera de statut et il ne sera plus possible de modifier la prescription. Voulez-vous continuer ?`
            },
            DELETED: 'Élément supprimé',
            INFO: {
                ADDED_HOSPITAL: `Ajouté par l'hôpital`,
                MODIFIED_HOSPITAL: `Modifié par l'hôpital`
            }
        }
    }
};
