export const locale = {
    lang: 'fr',
    data: {
        DETAILS_EMAIL: {
            NEW_TITLE: `Nouvelle adresse mail`,
            FORM: {
                TITLE_EMAIL_ADDRESS: `Adresse mail`,
                TITLE_MANAGER: `Responsable de l'adresse`,
                HOSPITAL: `Hôpital`,
                SECURE_MAIL: `Adresse mail`,
                MEMBER: {
                    LABEL: `Membre`,
                    PLACEHOLDER: `Sélectionner un membre de l'hôpital en tant que responsable`
                },
                MANAGER_FIRST_NAME: `Prénom`,
                MANAGER_LAST_NAME: `Nom`,
                MANAGER_MAIL: `Email`,
                MANAGER_PHONE: `Téléphone`,
                BUTTONS: {
                    DELETE: `Supprimer`
                },
                ERRORS: {
                    MAIL_ALREADY_CREATED: `Une adresse MSSanté existe déjà pour cet établissement.`
                }
            },
            ACTIONS: {
                DIALOG: {
                    TITLE: `Supprimer l'adresse mail`,
                    MESSAGE: `Voulez-vous vraiment supprimer l'adresse mail sécurisée ?`,
                    CONFIRM: `Supprimer`
                },
                SNACKBAR: {
                    ADD: `L'adresse mail a bien été ajoutée.`,
                    UPDATE: `L'adresse mail a bien été modifiée.`,
                    DELETE: `L'adresse mail a bien été supprimée.`
                }
            }
        }
    }
};
