var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType } from '@mapuilabs/mpl-interfaces';
import { GroupService } from '@services/group/group.service';
import { CGroupStructure } from '@shared/constTypes/group/group-structure.const';
import { CGroupType } from '@shared/constTypes/group/group-type.const';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { StateService } from '@uirouter/angularjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let ListGroupsComponent = class ListGroupsComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _groupService, _stateService, _injector) {
        super(_injector, _groupService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._groupService = _groupService;
        this._stateService = _stateService;
        this._injector = _injector;
        this.groupTypes = CGroupType;
        this.genericTableConfig = { clickableRows: true };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Header
        this.pageHeaderConfig = {
            title: this._translationService.instant('GROUPS.TITLE'),
            searchBarPlaceholder: this._translationService.instant('GROUPS.SEARCH'),
            icon: 'group'
        };
        // Table
        const translategetType = (type) => { var _a; return this._translate((_a = Object.values(CGroupType).find(({ value }) => value === type)) === null || _a === void 0 ? void 0 : _a.translate); };
        const translateGroupStructure = (groupStructure) => { var _a; return this._translate((_a = Object.values(CGroupStructure).find(({ value }) => value === groupStructure)) === null || _a === void 0 ? void 0 : _a.translate); };
        this.columns = [
            { label: this._translate('GROUPS.TABLE.NAME'), attribute: 'name', sort: true },
            { label: this._translate('GROUPS.TABLE.TYPE'), attribute: 'type', transform: translategetType, sort: true },
            {
                label: this._translate('GROUPS.TABLE.GROUP_STRUCTURE'),
                attribute: 'groupStructure',
                transform: translateGroupStructure,
                sort: true
            }
        ];
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    onRowClicked(group) {
        this._stateService.go('mapui.setting.groups.detail', { id: group._id });
    }
    applyFilter(type) {
        const filters = type === 0 ? [] : [{ type: EFilterType.VALUE, attribute: 'type', value: type }];
        this.onFiltersApplied({ filters, replaceHistory: true });
        // this.onFiltersApplied({ filters, replaceHistory: true }, 'sidebar');
    }
    isItemActive(value) {
        var _a, _b;
        const activeTypeFilter = (_b = (_a = this.filtersValues) === null || _a === void 0 ? void 0 : _a.find(({ attribute }) => attribute === 'type')) === null || _b === void 0 ? void 0 : _b.value;
        return value ? activeTypeFilter === value : !activeTypeFilter || activeTypeFilter === 0;
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_e = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _e : Object)
], ListGroupsComponent.prototype, "_paginator", void 0);
ListGroupsComponent = __decorate([
    Component({
        selector: 'mpx-list-groups',
        template: require('./list-groups.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof GroupService !== "undefined" && GroupService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof Injector !== "undefined" && Injector) === "function" ? _d : Object])
], ListGroupsComponent);
export { ListGroupsComponent };
