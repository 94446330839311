var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable } from '@angular/core';
import { ExternalActorService as AJSExternalActorService } from '../../../main/services/external-actors/external-actor.service';
import { EHTTPStatus, ExternalActor } from '@mapuilabs/mpl-interfaces';
import { BehaviorSubject, from, throwError } from 'rxjs';
import { remove, findIndex } from 'lodash';
import { ExternalConfigurationService } from '../external-configuration/external-configuration.service';
let ExternalActorsService = class ExternalActorsService {
    constructor(ajsExtActorService, _externalConfigService) {
        this.ajsExtActorService = ajsExtActorService;
        this._externalConfigService = _externalConfigService;
        this.onExternalActorsChanged = new BehaviorSubject({});
        this.externalActors = [];
        this.getAll();
    }
    resolve(params) {
        return new Promise((resolve, reject) => {
            if (params.id == 'new') {
                resolve(new ExternalActor());
            }
            else {
                this.getById(params.id).subscribe((ans) => {
                    resolve(new ExternalActor(ans));
                }, (err) => reject(err));
            }
        });
    }
    delete(actor) {
        const promise = new Promise((resolve, reject) => {
            this.ajsExtActorService
                .delete(actor)
                .then((ans) => {
                remove(this.externalActors, (elem) => {
                    return elem._id == actor._id;
                });
                this.onExternalActorsChanged.next(this.externalActors);
                resolve(ans);
            })
                .catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    getAll() {
        const promise = new Promise((resolve, reject) => {
            this.ajsExtActorService
                .getAll()
                .then((ans) => {
                this.externalActors = ans;
                this.onExternalActorsChanged.next(this.externalActors);
                resolve(this.externalActors);
            })
                .catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    getById(id) {
        const promise = new Promise((resolve, reject) => {
            this.ajsExtActorService
                .getById({ _id: id })
                .then((ans) => {
                resolve(ans);
            })
                .catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    save(actor) {
        const promise = new Promise((resolve, reject) => {
            this.ajsExtActorService
                .save(actor)
                .then((ans) => {
                const index = findIndex(this.externalActors, { _id: ans._id });
                this.externalActors.splice(index, 1, ans);
                this.onExternalActorsChanged.next(this.externalActors);
                resolve(ans);
            })
                .catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    add(actor) {
        const promise = new Promise((resolve, reject) => {
            this.ajsExtActorService
                .create(actor)
                .then((ans) => {
                this.externalActors.unshift(ans);
                this.onExternalActorsChanged.next(this.externalActors);
                resolve(ans);
            })
                .catch(() => {
                reject(EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    deleteConfig(actor, config) {
        const promise = new Promise((resolve, reject) => {
            this._externalConfigService.delete(config).subscribe(() => {
                const index = findIndex(actor.configurations, { _id: config._id });
                actor.configurations.splice(index, 1);
                this.save(actor).subscribe(() => {
                    resolve(actor);
                }, () => {
                    return throwError(() => EHTTPStatus.InternalServerError);
                });
            }, () => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
};
ExternalActorsService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AJSExternalActorService !== "undefined" && AJSExternalActorService) === "function" ? _a : Object, typeof (_b = typeof ExternalConfigurationService !== "undefined" && ExternalConfigurationService) === "function" ? _b : Object])
], ExternalActorsService);
export { ExternalActorsService };
