import * as angular from 'angular';
import { EDirRight, EFileAction, EFileType, EKeyCode, ELvl, EWorkspacePermission } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import { saveAs } from 'file-saver';
/**
 * @ngInject
 *
 * File Manager Controller
 */
export class MpFilesController {
    /**
     * Bind key events to actions
     * Init FileUploaderService and bind callbacks
     * Listen for location change
     *
     * @param FileUploaderService
     * @param KeyEventsService
     * @param FilesService
     * @param DirectoryRightsService
     * @param $mdSidenav
     * @param Api
     * @param $scope
     * @param $rootScope
     * @param ModalService
     * @param $q
     * @param $window
     * @param $timeout
     * @param $state
     * @param $stateParams
     * @param _
     * @param Auth
     * @param Access
     */
    /** @ngInject */
    constructor(FileUploaderService, KeyEventsService, FilesService, HospitalService, DirectoryRightsService, $mdSidenav, Api, $scope, $rootScope, ModalService, MemberService, $q, $window, $timeout, $sce, $state, $stateParams, $document, _, Auth, Access, jszip) {
        this.FileUploaderService = FileUploaderService;
        this.KeyEventsService = KeyEventsService;
        this.FilesService = FilesService;
        this.HospitalService = HospitalService;
        this.DirectoryRightsService = DirectoryRightsService;
        this.$mdSidenav = $mdSidenav;
        this.Api = Api;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ModalService = ModalService;
        this.MemberService = MemberService;
        this.$q = $q;
        this.$window = $window;
        this.$timeout = $timeout;
        this.$sce = $sce;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$document = $document;
        this._ = _;
        this.Auth = Auth;
        this.Access = Access;
        this.jszip = jszip;
        /**
         * Selected files
         */
        this.selected = [];
        /**
         * Breadcrumb directories
         * @type {Array}
         */
        this.breadCrumbData = [];
        /**
         * Is context menu open (no matter which)
         * @type {{status: boolean}}
         */
        this.contextMenuIsOpen = { status: false };
        /**
         * On init:
         *    - set isLoading until rootDirectories are fetched (prevent drop files and upload nowhere)
         *    - Go to user root
         *    - Go to selected directory if set in url
         */
        this.$onInit = () => {
            this.isLoading = true;
            this.maxAllowedSize = this.Access.hospital.maxDataUsage;
            this.FilesService.getRootDirectories().then((res) => {
                this.isLoading = false;
                for (const f of res) {
                    if (f.hospital) {
                        this.changeRoot(f);
                    }
                }
            }).catch(() => {
                this.isLoading = false;
            });
            if (this.$stateParams.id) {
                this.Api.workspace.files.get({ id: this.$stateParams.id }, (res) => {
                    this.changeDir(res);
                });
            }
        };
        /**
         * On destroy remove listener and flag this instance as destroyed
         */
        this.$onDestroy = () => {
            if (this._changeRouteListener) {
                this._changeRouteListener();
            }
            this._wasDestroyed = true;
        };
        /**
         * Update the current directories and files according to the given directory
         * @param dir
         */
        this.getDirElements = (dir) => {
            const promises = [];
            const defer = this.$q.defer();
            this._pushNotif(EFileAction.LOADING, null, true);
            promises.push(this.fs.getChildren(dir));
            promises.push(defer.promise);
            this.Api.workspace.breadcrumbs.get({ id: dir._id }, (res) => {
                defer.resolve();
                this.breadCrumbData = res;
                if (res.length > 0 && !Utils.compareIds(this.root, res[0])) {
                    this.root = res[0];
                    this._updateTotalSize();
                }
            }, () => {
                defer.reject();
            });
            this.$q.all(promises).then(() => {
                this.popNotif();
            }).catch(() => {
                this._pushNotif(EFileAction.ERROR, null, false, ELvl.ERROR);
            });
        };
        /**
         * Validate the file renaming
         */
        this.renameFile = () => {
            if (this.renamingFile && this.renamingFile.name && this.renamingFile.name != this.saveFileToRename.name) {
                this.lastActions.push({
                    type: EFileAction.RENAME,
                    files: [this.saveFileToRename],
                    old: this.saveFileToRename.name,
                });
                this._pushNotif(EFileAction.RENAME, [this.saveFileToRename], true);
                const toSend = {
                    _id: this.renamingFile._id,
                    name: this.renamingFile.name,
                };
                this.fs.saveFile(toSend).then(() => {
                    this.fs.getChildren(this.fs.currentDir);
                    this._pushNotif(EFileAction.RENAME, null, false, ELvl.SUCCESS);
                }).catch(() => {
                    this._pushNotif(EFileAction.RENAME, null, false, ELvl.ERROR);
                });
            }
            this.saveFileToRename = undefined;
            this.renamingFile = undefined;
        };
        /**
         * Get list of file filtered by name of file
         */
        this.filtreByTitle = () => {
            const defer = this.$q.defer();
            this.fs.getAllFiles(this.ctrl.searchText).then((files) => {
                defer.resolve(files);
            });
            return defer.promise;
        };
        /**
         *  Change directory in action to the selection file
         */
        this.selectedItemChange = () => {
            if (this.ctrl.selected) {
                if (this.ctrl.selected.isDirectory)
                    this.changeDir(this.ctrl.selected);
                else
                    this.fs.getParentDirectory(this.ctrl.selected).then((data) => {
                        this.changeDir(data);
                    });
            }
        };
        /**
         * open Modal pdf-viewer
         * @param file
         */
        this.viewFile = (file) => {
            if (file.extension == 'pdf') {
                this.ModalService.show({
                    component: 'mp-modal-pdf-viewer',
                    bindings: {
                        file: file,
                    },
                    escapeToClose: true,
                });
            }
        };
        /**
         * Create a new directory in the current directory
         */
        this.createDirectory = () => {
            if (this.newDirectory && this.newDirectory.name) {
                this._pushNotif(EFileAction.CREATE_DIRECTORY, null, true);
                this.fs.addDirectory(this.newDirectory, this.fs.currentDir).then(() => {
                    this.fs.getChildren(this.fs.currentDir);
                    this._pushNotif(EFileAction.CREATE_DIRECTORY, null, false, ELvl.SUCCESS);
                }).catch(() => {
                    this._pushNotif(EFileAction.CREATE_DIRECTORY, null, false, ELvl.ERROR);
                });
                this.newDirectory = undefined;
            }
            this.addDirectory = false;
        };
        /**
         * Delete a single file or all files in the selection
         * @param file
         */
        this.deleteFile = (file = null) => {
            if (!this.rights.delete(file))
                return;
            if (file || this.selected.length > 0) {
                const files = this.selected;
                this.FilesService.confirmDeleteFileModal(files).then(() => {
                    this.lastActions.push({
                        files: files,
                        type: EFileAction.DELETE,
                    });
                    this._pushNotif(EFileAction.DELETE, files, true);
                    const promises = [];
                    for (let file of files) {
                        promises.push(this.fs.deleteFile(file));
                    }
                    this.$q.all(promises).then(() => {
                        this._pushNotif(EFileAction.DELETE, files, false);
                        this.fs.getChildren(this.fs.currentDir);
                        this._updateTotalSize();
                    }).catch(() => {
                        this._pushNotif(EFileAction.DELETE, files, false, ELvl.ERROR);
                    });
                    this.selected = [];
                    this.fs.getChildren(this.fs.currentDir);
                });
            }
        };
        /**
         * put files in a zip file
         */
        this.downloadFile = () => {
            if (this.selected.length > 1) {
                let zip = new this.jszip;
                const promises = [];
                for (let file of this.selected) {
                    promises.push(this.FilesService.downloadFile(file.url).then((Response) => {
                        zip.file(file.name, Response.data);
                    }));
                }
                this.$q.all(promises).then(() => {
                    zip.generateAsync({ type: 'blob' })
                        .then((content) => {
                        saveAs(content, 'Fichiers.zip');
                    });
                });
            }
        };
        /**
         * Check if something is selcted or a Directory is selected
         */
        this.isEnableDownloading = () => {
            let status = false;
            if (this.selected.length == 0)
                status = true;
            for (let file of this.selected) {
                if (file.isDirectory) {
                    status = true;
                }
            }
            return status;
        };
        /**
         * Cancel last action and pop lastActions Stack
         */
        this.rollback = () => {
            const promises = [];
            const lastAction = this.lastActions.length > 0 ? this.lastActions[this.lastActions.length - 1] : null;
            if (lastAction && lastAction.files && lastAction.files.length > 0) {
                const updateSize = lastAction.type == EFileAction.DELETE || lastAction.type == EFileAction.MOVE;
                let notifAction;
                switch (lastAction.type) {
                    case EFileAction.DELETE:
                        notifAction = EFileAction.UNDO_DELETE;
                        break;
                    case EFileAction.MOVE:
                        notifAction = EFileAction.UNDO_MOVE;
                        break;
                    case EFileAction.RENAME:
                        notifAction = EFileAction.UNDO_RENAME;
                        break;
                }
                this._pushNotif(notifAction, lastAction.files, true, ELvl.INFO, lastAction.type == EFileAction.MOVE ? lastAction.old : null);
                this._.forEach(lastAction.files, (file) => {
                    switch (lastAction.type) {
                        case EFileAction.DELETE:
                            file.deleted = false;
                            break;
                        case EFileAction.MOVE:
                            file.parentDirectory = { _id: lastAction.old._id };
                            break;
                        case EFileAction.RENAME:
                            file.name = lastAction.old;
                            break;
                    }
                    promises.push(this.fs.saveFile(file));
                });
                this.$q.all(promises).then(() => {
                    this._pushNotif(notifAction, lastAction.files, false, ELvl.SUCCESS, lastAction.type == EFileAction.MOVE ? lastAction.old : null);
                    this.fs.getChildren(this.fs.currentDir);
                    if (updateSize) {
                        this._updateTotalSize();
                    }
                }).catch(() => {
                    this._pushNotif(notifAction, lastAction.files, false, ELvl.ERROR, lastAction.type == EFileAction.MOVE ? lastAction.old : null);
                });
            }
            if (lastAction) {
                this.lastActions.splice(this.lastActions.length - 1, 1);
            }
        };
        /**
         * Select the given file
         * @param event
         * @param elem
         * @param force force selection (will not unselect if already select and will deselect any other element)
         */
        this.select = (elem = null, event = null, force = false) => {
            if (event) {
                event.stopPropagation();
                this.contextMenuIsOpen.status = false;
                if (this.addDirectory || this.renamingFile) {
                    this.validate(); // Trigger the onClickOutside when renaming a file or creating a directory
                }
            }
            this._lastSelectedFileWKey = [];
            if (this.KeyEventsService.isHoldingCmdKey() && !force && elem) {
                elem.selected = !elem.selected;
                if (elem.selected) {
                    this.selected.push(elem);
                    this._lastSelectedFile.push(elem);
                    this._lastSelectedFileWKey.push(elem);
                }
                else {
                    for (let idx = 0; idx < this.selected.length; idx++) {
                        if (this.selected[idx] == elem) {
                            this.selected.splice(idx, 1);
                            break;
                        }
                    }
                    for (let idx = 0; idx < this._lastSelectedFile.length; idx++) {
                        if (this._lastSelectedFile[idx] == elem) {
                            this._lastSelectedFile.splice(idx, 1);
                            break;
                        }
                    }
                }
            }
            else if (this.KeyEventsService.isPressed(EKeyCode.SHIFT) && !force && elem) {
                if (this._lastSelectedFile.length == 0) {
                    this._unSelectAll();
                    this._selectFilesInArrayUntil(this.orderedFiles, elem);
                }
                else {
                    let last = this._lastSelectedFile[this._lastSelectedFile.length - 1];
                    this._unSelectAll();
                    let diff = this._cmpFilesOrder(last, elem);
                    let before = diff < 0 ? last : elem;
                    let after = diff > 0 ? last : elem;
                    this._selectFilesInArrayUntil(this.orderedFiles, after, before);
                }
            }
            else {
                let selected = false; // hold elem new state if it exists
                if (elem) {
                    selected = force ? true : !elem.selected;
                }
                this._unSelectAll();
                if (elem && selected) { // if elem exists and is selected add it to the selected arrays
                    elem.selected = true;
                    this.selected.push(elem);
                    this._lastSelectedFile.push(elem);
                    this._lastSelectedFileWKey.push(elem);
                }
            }
        };
        /**
         * Open file/directory detail sidenav
         */
        this.openDetails = () => {
            this.$mdSidenav('details-sidenav').open();
        };
        /**
         * Setting the root directory to the given directory.
         * The currentDir and breadCrumbData will be updated.
         * @param root
         */
        this.changeRoot = (root) => {
            this.root = root;
            this.changeDir(root);
            this._updateTotalSize();
        };
        /**
         * Return text file type (ie. Image, Texte, Audio, etc)
         * @param f
         * @returns {any}
         */
        this.getFileType = (f) => {
            if (!this.filesTypeLabel || !f)
                return null;
            const type = f.isDirectory ? 'FILE_TYPE.DIRECTORY' : this.filesTypeLabel[f.extension];
            return type ? type : this.filesTypeLabel['default'];
        };
        /**
         * Return text file extension (used for file type .svg)
         * @param {IFile} f
         * @returns {string}
         */
        this.getFileExt = (f) => {
            if (f && f.extension && this.filesType[f.extension]) {
                return this.filesType[f.extension];
            }
            return this.filesType['default'];
        };
        /**
         * Beginning of file renaming
         * @param file
         */
        this.startRenamingFile = (file = null) => {
            if (!this.rights.rename(file))
                return;
            if (!file && this.selected.length == 1) {
                file = this.selected[0];
            }
            if (file) {
                this.renamingFile = angular.copy(file);
                this.saveFileToRename = file;
            }
        };
        /**
         * return icon for f as root directory
         * @param f
         * @returns {string}
         */
        this.getRootIcon = (f) => {
            if (f.member) {
                return 'icon-account';
            }
            else if (f.hospital) {
                return 'icon-hospital';
            }
            else if (f.region) {
                return 'icon-map-marker';
            }
            else if (f.group) {
                return 'icon-account-multiple';
            }
            return null;
        };
        /**
         * Update the current directory and it's files to the given directory
         * @param dir
         * @param go
         */
        this.changeDir = (dir, go = true) => {
            if (this._wasDestroyed)
                return;
            if (go) {
                this._hasChangedRouteProgramatically = true;
                this.$state.go('mapui.files.directory', { id: dir._id });
            }
            this.fs.setCurrentDir(dir);
            this._unSelectAll();
            this.getDirElements(this.fs.currentDir);
        };
        /**
         * Change the way the content of a directory is displayed (grid or list)
         */
        this.toggleView = () => {
            if (this.currentView == 'list') {
                this.currentView = 'grid';
            }
            else if (this.currentView == 'grid') {
                this.currentView = 'list';
            }
            this.MemberService.updateConfigValue('fileView', this.currentView);
        };
        /**
         * Open upload box where you can follow the uploads progression
         */
        this.openMpUploads = () => {
            this.mpUploadIsOpen = true;
        };
        /**
         * Whenever a validate event is triggered we will try to validate the current modification (new directory or file renaming)
         */
        this.validate = () => {
            if (this.addDirectory) {
                this.createDirectory();
            }
            else if (this.renamingFile) {
                this.renameFile();
            }
            else if (this.selected.length == 1 && this.selected[0].isDirectory) {
                this.changeDir(this.selected[0]);
            }
        };
        /**
         * Open the file creation modal
         * @param event
         * @param file
         */
        this.openMoveFileModal = (event, file) => {
            if (!this.rights.move(file))
                return;
            const files = file ? [file] : this.selected;
            this.ModalService.show({
                component: 'mp-modal-move',
                bindings: {
                    files: files,
                    root: this.root,
                },
                ev: event,
                escapeToClose: true,
            }).then((moveToDir) => {
                if (moveToDir) {
                    this._moveFiles(moveToDir);
                }
            });
        };
        /**
         * Whenever a cancel event is triggered we will stop all current modification
         */
        this.cancel = () => {
            this.$scope.$evalAsync(() => {
                if (this.addDirectory) {
                    this.newDirectory = undefined;
                    this.addDirectory = false;
                }
                else if (this.renamingFile || this.saveFileToRename) {
                    this.renamingFile = undefined;
                    this.saveFileToRename = undefined;
                }
                else {
                    this._unSelectAll();
                }
            });
        };
        /**
         * Go to selected folder rights
         */
        this.openFolderRights = () => {
            const id = this.selected.length ? this.selected[0]._id : this.breadCrumbData[this.breadCrumbData.length - 1]._id;
            this.$state.go('mapui.files.rights', { id: id });
        };
        /**
         * Can user see directory rights icon
         * @return {boolean}
         */
        this.canAccessDirectoryRights = () => {
            // If multi-selection or personal folder we can't see manage rights icon
            if (this.selected.length > 1 || (this.root && Utils.hasId(this.root.member)) ||
                (this.selected.length == 1 && !this.selected[0].isDirectory)) {
                return false;
            }
            return this.Auth.authorize(EWorkspacePermission.FilesRights_Read);
        };
        /**
         * Remove notification
         * @private
         */
        this.popNotif = () => {
            if (this._notifTimeout && typeof this._notifTimeout.cancel === 'function') {
                this._notifTimeout.cancel();
                this._notifTimeout = null;
            }
            this.notifs = undefined;
        };
        /**
         * Useful information of the selected element to generate a drag visual
         * @returns {{total, selected: any}}
         */
        this.generateDragVisual = () => {
            return {
                total: this.selected.length,
                selected: this.selected,
            };
        };
        /**
         * Disable drag over when hovering itself
         * @param index
         * @param external
         * @param type
         * @param callback
         * @returns {boolean}
         */
        this.dragoverCallback = (index, external, type, callback) => {
            return this._canDropItemsTo(callback);
        };
        /**
         * On drop html (dnd-draggable) element callback
         * @param index
         * @param external
         * @param type
         * @param container
         * @returns {boolean}
         */
        this.dropCallback = (index, external, type, container) => {
            if (!this._canDropItemsTo(container)) {
                return false;
            }
            this._moveFiles(container);
            return true;
        };
        /**
         * Do move file (from drop callback or moveFileModal)
         * @param dir
         * @private
         */
        this._moveFiles = (dir) => {
            const promises = [];
            this.lastActions.push({
                files: this.selected,
                type: EFileAction.MOVE,
                old: this.selected[0].parentDirectory,
            });
            this._pushNotif(EFileAction.MOVE, this.selected, true, ELvl.INFO, dir);
            for (let f of this.selected) {
                const toSend = {
                    _id: f._id,
                    parentDirectory: { _id: dir._id },
                };
                promises.push(this.fs.saveFile(toSend));
            }
            this.$q.all(promises).then(() => {
                this._pushNotif(EFileAction.MOVE, this.selected, false, ELvl.SUCCESS, dir);
                this.fs.getChildren(this.fs.currentDir);
            }).catch(() => {
                this._pushNotif(EFileAction.MOVE, this.selected, false, ELvl.ERROR, dir);
            });
        };
        /**
         * Display notification
         * @param action will allow to know what text to display and guess some parameters (cancelable, timeout time, ...)
         * @param files impacted by the action
         * @param isLoading if true a loading wheel will appear and the notif will not be destroyed automatically
         * @param lvl is a success, info, error or warning
         * @param target used for move
         * @private
         */
        this._pushNotif = (action, files, isLoading = false, lvl = ELvl.INFO, target = null) => {
            if (this._notifTimeout && typeof this._notifTimeout.cancel === 'function') {
                this._notifTimeout.cancel();
                this._notifTimeout = null;
            }
            const notif = {
                text: '',
                cancelable: false,
                isLoading: isLoading,
                lvl: lvl,
                values: {
                    nbr: files ? files.length : 0,
                    name: '',
                },
                closable: false,
            };
            switch (action) {
                case EFileAction.DELETE:
                    notif.text = 'FILES.NOTIFICATION.DELETE';
                    break;
                case EFileAction.UNDO_DELETE:
                    notif.text = 'FILES.NOTIFICATION.DELETE.UNDO';
                    break;
                case EFileAction.MOVE:
                case EFileAction.UNDO_MOVE:
                    notif.text = 'FILES.NOTIFICATION.MOVE';
                    notif.values.id = target._id;
                    notif.values.name = target.name;
                    break;
                case EFileAction.RENAME:
                    notif.text = 'FILES.NOTIFICATION.RENAME';
                    break;
                case EFileAction.UNDO_RENAME:
                    notif.text = 'FILES.NOTIFICATION.RENAME.UNDO';
                    break;
                case EFileAction.CREATE_DIRECTORY:
                    notif.text = 'FILES.NOTIFICATION.CREATE_DIRECTORY';
                    break;
                case EFileAction.LOADING:
                    notif.text = 'FILES.NOTIFICATION.LOADING';
                    break;
                case EFileAction.ADD_FILE_TOO_LARGE:
                    notif.text = 'FILES.NOTIFICATION.FILE_TOO_LARGE';
                    break;
                case EFileAction.ADD_FILE_NO_SPACE:
                    notif.text = 'FILES.NOTIFICATION.FILE_NO_SPACE';
                    break;
                case EFileAction.ADD_FILE_BAD_EXTENSION:
                    notif.text = 'FILES.NOTIFICATION.FILE_BAD_EXTENSION';
                    break;
            }
            if (lvl !== ELvl.ERROR && lvl !== ELvl.WARNING) {
                if (action !== EFileAction.RENAME && action !== EFileAction.CREATE_DIRECTORY
                    && action !== EFileAction.UNDO_RENAME && action !== EFileAction.LOADING && files) {
                    if (files.length > 1) {
                        notif.text += '.MULTI_ITEMS';
                    }
                    else {
                        notif.text += '.SINGLE_ITEM';
                    }
                }
                if (action !== EFileAction.LOADING && action !== EFileAction.ERROR) {
                    if (isLoading && action) {
                        notif.text += '.PROGRESS';
                    }
                    else {
                        notif.text += '.DONE';
                        notif.cancelable = action == EFileAction.RENAME || action == EFileAction.MOVE;
                        notif.closable = true;
                    }
                }
            }
            else if (lvl === ELvl.ERROR) {
                notif.text += '.ERROR';
                notif.closable = true;
            }
            else {
                notif.closable = true;
            }
            this.notifs = [notif];
            if (!isLoading) {
                // Auto close notification if it's not in progress (that is supposed to be closed on callback receiving)
                this._notifTimeout = this.$timeout(() => {
                    this.popNotif();
                }, 5000);
            }
        };
        /**
         * Unselect all selected files
         * @param except
         * @private
         */
        this._unSelectAll = (except = null) => {
            let found = false;
            if (this.selected.length > 0) {
                for (let item of this.selected) {
                    if (item !== except) {
                        item.selected = false;
                    }
                    else {
                        found = true;
                    }
                }
                this.selected = found ? [except] : [];
                this._lastSelectedFile = found ? [except] : [];
                this._lastSelectedFileWKey = [];
            }
        };
        /**
         * Selection using SHIFT + Arrow keys
         * @param arr
         * @param last
         * @param first
         * @private
         */
        this._selectFilesInArrayUntil = (arr, last = null, first = null) => {
            if (!arr || arr.length == 0)
                return;
            let foundFirst = false;
            for (let f of arr) {
                if (f === first) {
                    foundFirst = true;
                }
                if (!first || foundFirst) {
                    f.selected = true;
                    this.selected.push(f);
                }
                if (f == last) {
                    return;
                }
            }
        };
        /**
         * Is fist file before/after/is second file in display files list
         * @param first
         * @param second
         * @returns {number}
         * @private
         */
        this._cmpFilesOrder = (first, second) => {
            if (first === second) {
                return 0;
            }
            for (let f of this.orderedFiles) {
                if (f === first) {
                    return -1; // First is before second
                }
                if (f === second) {
                    return 1; // Second is before first
                }
            }
        };
        /**
         * Can drop item to container
         * @param dir
         * @returns {boolean}
         * @private
         */
        this._canDropItemsTo = (dir) => {
            for (let f of this.selected) {
                if (Utils.compareIds(f, dir)) {
                    return false;
                }
            }
            return true;
        };
        /**
         * Return previous file in current displayed files and directories list
         * @param file
         * @returns {any}
         * @private
         */
        this._getPrevFile = (file = null) => {
            if (this.orderedFiles.length == 0)
                return null;
            if (!file) {
                return this.orderedFiles[this.orderedFiles.length - 1];
            }
            let prev = null;
            for (let f of this.orderedFiles) {
                if (f === file) {
                    return prev;
                }
                prev = f;
            }
            return null;
        };
        /**
         * Return next file in current displayed files and directories list
         * @param file
         * @returns {any}
         * @private
         */
        this._getNextFile = (file = null) => {
            if (this.orderedFiles.length == 0)
                return null;
            if (!file) {
                return this.orderedFiles[0];
            }
            let found = false;
            for (let f of this.orderedFiles) {
                if (found) {
                    return f;
                }
                if (f === file) {
                    found = true;
                }
            }
            return null;
        };
        /**
         * Apply selection when user uses arrow
         * If the SHIFT key is holded it will trigger the multi-select mode
         * If not it will select previous or next file depending of key and view mode (table [Up/Bottom] or grid [Left/right])
         * @param next
         * @private
         */
        this._selectWithArrowKey = (next) => {
            const shift = this.KeyEventsService.isPressed(EKeyCode.SHIFT);
            let last = this._lastSelectedFileWKey.length > 0 ? this._lastSelectedFileWKey[this._lastSelectedFileWKey.length - 1] : null;
            const other = next ? this._getNextFile(last) : this._getPrevFile(last);
            if (other) {
                this.$scope.$evalAsync(() => {
                    if (shift) {
                        if (this._lastSelectedFile.length == 0)
                            return;
                        // If multiple files where selected as reference (not with MAJ) then we keep only the last one
                        if (this._lastSelectedFile.length > 1) {
                            const lastSelected = this._lastSelectedFile[this._lastSelectedFile.length - 1];
                            for (let f of this.selected) {
                                f.selected = false;
                            }
                            lastSelected.selected = true;
                            this._lastSelectedFile = [lastSelected];
                            this.selected = [lastSelected];
                        }
                        let idx = this._lastSelectedFileWKey.indexOf(other);
                        if (idx !== -1) {
                            this._lastSelectedFileWKey.splice(idx, this._lastSelectedFileWKey.length - idx);
                            for (let f of this.selected.splice(this.selected.indexOf(other), this.selected.length - idx)) {
                                f.selected = false;
                            }
                        }
                        idx = this.selected.indexOf(other);
                        if (idx !== -1) { // Is already selected
                            if (this._lastSelectedFile.indexOf(other) === -1) { // Is not a reference: unselect it
                                other.selected = false;
                                this.selected.splice(idx, 1);
                            }
                        }
                        else { // Select it
                            other.selected = true;
                            this.selected.push(other);
                            this._lastSelectedFileWKey.push(other);
                        }
                    }
                    else {
                        this.select(other, null, this._lastSelectedFileWKey.indexOf(other) !== -1);
                    }
                });
            }
        };
        /**
         * Update hospital total file size
         * @private
         */
        this._updateTotalSize = () => {
            this.Api.workspace.size.get({ hid: this.Auth.user.hospital._id }, (res) => {
                this.totalSize = res.size;
            });
        };
        this.currentView = this.Access.user.config.fileView;
        this.mpUploadIsOpen = false;
        this.isLoading = true;
        this.addDirectory = false;
        this.lastActions = [];
        this._lastSelectedFile = [];
        this._lastSelectedFileWKey = [];
        this._wasDestroyed = false;
        this.ctrl = {
            selected: null,
            searchText: '',
        };
        this.$scope.pdf = {
            data: null,
            src: null,
        };
        this.FileUploaderService.init(false);
        this.Api.filesType.get((ans) => {
            this.filesType = ans;
        });
        this.Api.filesTypeLabel.get((ans) => {
            this.filesTypeLabel = ans;
        });
        ////////////////////////////////////
        // Bind key events to actions
        ////////////////////////////////////
        this.KeyEventsService.setIsMac(this.$window.navigator.platform.indexOf('Mac') !== -1);
        this.KeyEventsService.onValidate = this.validate;
        this.KeyEventsService.onCancel = this.cancel;
        this.KeyEventsService.onRollback = this.rollback;
        this.KeyEventsService.onRename = () => {
            this.$scope.$evalAsync(() => {
                this.startRenamingFile();
            });
        };
        this.KeyEventsService.onDelete = () => {
            if (this.renamingFile)
                return;
            this.deleteFile();
        };
        this.KeyEventsService.onSelectAll = () => {
            if (this.renamingFile)
                return;
            this._unSelectAll();
            this.$scope.$evalAsync(() => {
                for (let f of this.fs.currentFiles) {
                    f.selected = true;
                    this.selected.push(f);
                    this._lastSelectedFile.push(f);
                    this._lastSelectedFileWKey.push(f);
                }
            });
        };
        this.KeyEventsService.onUp = () => {
            if (this.renamingFile)
                return;
            if (this.currentView == 'list') {
                this._selectWithArrowKey(false);
            }
        };
        this.KeyEventsService.onDown = () => {
            if (this.renamingFile)
                return;
            if (this.currentView == 'list') {
                this._selectWithArrowKey(true);
            }
        };
        this.KeyEventsService.onLeft = () => {
            if (this.renamingFile)
                return;
            if (this.currentView == 'list' && this.breadCrumbData.length > 1) {
                this.changeDir(this.breadCrumbData[this.breadCrumbData.length - 2]);
            }
            else if (this.currentView != 'list') {
                this._selectWithArrowKey(false);
            }
        };
        this.KeyEventsService.onRight = () => {
            if (this.renamingFile)
                return;
            if (this.currentView == 'list' && this.selected.length == 1 && this.selected[0].isDirectory
                && !KeyEventsService.isPressed(EKeyCode.SHIFT)) {
                this.changeDir(this.selected[0]);
            }
            else if (this.currentView != 'list') {
                this._selectWithArrowKey(true);
            }
        };
        this.fs = FilesService;
        this.uploader = this.FileUploaderService.uploader;
        this.uploader.filters.push({
            name: 'canUpload',
            fn: () => {
                return this.rights.add();
            },
        });
        ////////////////////////////////////
        // FileUploadService callbacks
        ////////////////////////////////////
        this.FileUploaderService.onAfterAddingAll = () => {
            this.FileUploaderService.upload(EFileType.Cloud);
        };
        this.FileUploaderService.onAfterAddingFile = (item) => {
            let path = '';
            let curr;
            for (let dir of this.breadCrumbData) {
                path += path.length ? '/' + dir.name : dir.name;
                curr = dir;
            }
            item.path = path;
            item.directory = curr;
            this.mpUploadIsOpen = true;
        };
        this.FileUploaderService.onItemUploadSuccess = (item, f) => {
            this.FilesService.saveUploadedFile(item, f).then(() => {
                item.isComplete = true;
                this.fs.getChildren(this.fs.currentDir);
                this._updateTotalSize();
            }).catch(err => {
                item.isError = true;
                console.error(err);
                // this.ToastService.show(err);
            });
        };
        this.FileUploaderService.onItemFileSizeTooLarge = (item) => {
            this._pushNotif(EFileAction.ADD_FILE_TOO_LARGE, null, false, ELvl.WARNING);
        };
        this.FileUploaderService.onItemNoSpaceAvailable = (item) => {
            this._pushNotif(EFileAction.ADD_FILE_NO_SPACE, null, false, ELvl.WARNING);
        };
        this.FileUploaderService.onItemFileBadExtension = (item) => {
            this._pushNotif(EFileAction.ADD_FILE_BAD_EXTENSION, null, false, ELvl.WARNING);
        };
        this.FileUploaderService.onBeforeUploadItem = (item, index) => {
            this.$timeout(() => {
                this.$scope.$broadcast('scroll-to', { 'selector': '.item-' + index });
            }, 100);
        };
        ////////////////////////////////////
        // IMember rights
        ////////////////////////////////////
        this.rights = {
            see: (f = null) => {
                const res = this.rights._hasRight(EDirRight.SEE_DIR, f);
                return res;
            },
            rename: (f = null) => {
                return this.rights._hasRight(f && f.isDirectory ? EDirRight.RENAME_DIR : EDirRight.RENAME_CHILD, f && f.isDirectory ? f : null);
            },
            move: (f = null) => {
                return this.rights._checkSingleOrMulti(f, EDirRight.MOVE_DIR, EDirRight.MOVE_CHILD);
            },
            delete: (f = null) => {
                return this.rights._checkSingleOrMulti(f, EDirRight.DELETE_DIR, EDirRight.DELETE_CHILD);
            },
            add: () => {
                return this.rights._hasRight(EDirRight.ADD_CHILD);
            },
            download: () => {
                return this.rights._hasRight(EDirRight.DOWNLOAD_CHILD);
            },
            _hasRight: (action, f = null) => {
                return this.DirectoryRightsService.userCanDoActionInside(this.Auth.user._id, action, this.breadCrumbData, f);
            },
            _checkSingleOrMulti: (f, dirAction, childAction) => {
                if (f) {
                    return this.rights._hasRight(f.isDirectory ? dirAction : childAction, f.isDirectory ? f : null);
                }
                for (let file of this.selected) {
                    if (!this.rights._hasRight(file.isDirectory ? dirAction : childAction, file.isDirectory ? file : null)) {
                        return false;
                    }
                }
                return true;
            },
        };
        this._changeRouteListener = this.$rootScope.$on('$locationChangeSuccess', (url) => {
            if (this._hasChangedRouteProgramatically === false && this.$stateParams.id) {
                // Force load page when click on back btn (or whenever the location changed without passing by changeDir()
                this.Api.workspace.files.get({ id: this.$stateParams.id }, (res) => {
                    if (this._wasDestroyed)
                        return;
                    this.$scope.$evalAsync(() => {
                        this.changeDir(res, false);
                    });
                });
            }
            this._hasChangedRouteProgramatically = false;
        });
    }
    ;
}
