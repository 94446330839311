import * as angular from 'angular';
import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
/** @ngInject */
export class MpHospitalSearchController {
    /** @ngInject */
    constructor(HospitalService, _, $q) {
        this.HospitalService = HospitalService;
        this._ = _;
        this.$q = $q;
        /**
         * Function which search all groups refer to the given value.
         * @param value the input search text.
         * @returns {angular.IPromise<Array<IHospital>>}
         */
        this.searchHospitals = (value) => {
            const defer = this.$q.defer();
            let type = EEstablishmentType.Hospital;
            if (this.type)
                type = this.type;
            this.HospitalService.findByName(this.ctrl.searchText, type)
                .then(res => {
                defer.resolve(this._.filter(res, (r) => {
                    return !this.excluded || !this._.find(this.excluded, (e) => {
                        return Utils.compareIds(r, e);
                    });
                }));
            })
                .catch(err => defer.reject);
            return defer.promise;
        };
        /**
         * Function called when a group is selected.
         */
        this.searchHospitalsSelected = () => {
            if (this.ctrl.selected) {
                this.onSelected({ hosp: this.ctrl.selected });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
    $onChanges(changes) {
        if (changes.hospModel && this.hospModel) {
            this.hospModel = angular.copy(this.hospModel);
            this.ctrl.searchText = this.hospModel.name;
        }
        if (changes.triggerClean && this.triggerClean) {
            this.ctrl.searchText = null;
            // if (this.resetClean)
            this.resetClean();
        }
    }
}
