var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { Directive, ElementRef, EventEmitter, Inject, Output } from '@angular/core';
import { DOCUMENT_CLICK } from '@core/tokens/events/document-click.token';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
let ClickOutsideDirective = class ClickOutsideDirective {
    constructor(documentClick$, elementRef) {
        this.documentClick$ = documentClick$;
        this.elementRef = elementRef;
        // Init EventEmmiters
        this.clickOutside = new EventEmitter();
        // Init Subjects
        this._unsubscribeAll = new Subject();
        // Fire clickOutside on documentClick event
        this.documentClick$.pipe(takeUntil(this._unsubscribeAll)).subscribe(($event) => {
            const clickedInside = this.elementRef.nativeElement.contains($event.target);
            if (!clickedInside) {
                this.clickOutside.emit();
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
};
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], ClickOutsideDirective.prototype, "clickOutside", void 0);
ClickOutsideDirective = __decorate([
    Directive({
        selector: '[clickOutsideDirective]'
    }),
    __param(0, Inject(DOCUMENT_CLICK)),
    __metadata("design:paramtypes", [typeof (_a = typeof Observable !== "undefined" && Observable) === "function" ? _a : Object, typeof (_b = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _b : Object])
], ClickOutsideDirective);
export { ClickOutsideDirective };
