import { EConservation, EPreparationType } from '@mapuilabs/mpl-interfaces';
export const PrepSubCategory = {
    hospital: {
        name: 'Hospital preparation',
        value: EPreparationType.Hospital,
        translate: 'PHARMACY.PREPARATION.CATEGORIES.HOSPITAL'
    },
    magistral: {
        name: 'Masterful preparation',
        value: EPreparationType.Masterful,
        translate: 'PHARMACY.PREPARATION.CATEGORIES.MASTERFUL'
    }
};
export const PrepConditions = {
    tempAmbient: {
        name: 'Ambient temperature',
        value: EConservation.AmbientTemperature,
        translate: 'PHARMACY.PREPARATION.CONDITIONS.AMBIENT_TEMP'
    },
    tempFrom2To8: {
        name: 'From +2 to +8°C',
        value: EConservation.From2To8,
        translate: 'PHARMACY.PREPARATION.CONDITIONS.FROM2TO8'
    },
    noLight: {
        name: 'No light',
        value: EConservation.NoLight,
        translate: 'PHARMACY.PREPARATION.CONDITIONS.NO_LIGHT'
    }
};
