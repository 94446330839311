var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, EventEmitter, Input, ContentChild, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EGraphStep } from '@mapuilabs/mpl-interfaces';
import { AreaChartComponent, TooltipService } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { SnackbarService } from '@services/snackbar/snackbar.service';
let GraphWidgetComponent = class GraphWidgetComponent {
    constructor(_snackbarService, _changeDetectorRef, chartToolTipService, viewContainerRef) {
        this._snackbarService = _snackbarService;
        this._changeDetectorRef = _changeDetectorRef;
        this.chartToolTipService = chartToolTipService;
        this.viewContainerRef = viewContainerRef;
        this.graphType = 'line'; // Select which graph to display. To add other graphs, edit this type and add it into html
        this.autoUpdateOnParams = true; // Does edit params auto update graph data (by using getGraphData function)
        this.convertAxisAsDate = false; // If x axis are dates (convert as dates to get responsive labels)
        this.stepType = EGraphStep;
        this.autoSelect = true;
        // Loading / No data
        this.isLoading = false;
        this.noData = false;
        this.emptyTableMsg = 'COMMONS.EMPTY_TABLE';
        this.emptyTableIcon = 'package-variant';
        this.chartToolTipService.injectionService.setRootViewContainer(this.viewContainerRef);
    }
    ngOnInit() {
        var _a;
        this.noData = !((_a = this.graphData) === null || _a === void 0 ? void 0 : _a.results);
        if (!this.graphData.schemeType) {
            this.graphData.schemeType = 'ordinal';
        }
        if (this.graphData.showXAxisLabel && !this.graphData.xAxisLabel) {
            console.warn('x axis label is empty');
        }
        if (this.graphData.showYAxisLabel && !this.graphData.yAxisLabel) {
            console.warn('y axis label is empty');
        }
        this.graphData.xAxisTickFormatting = this.dateTickFormatting;
        this.selectedStep = this.getDefaultStep();
        this.updateEmit.subscribe(() => {
            if (this.autoSelect) {
                this.selectedStep = this.getDefaultStep();
            }
            this.isLoading = true;
            this.getGraphData(this.selectedStep, this.params)
                .then((newGraphData) => {
                var _a;
                this.isLoading = false;
                if (newGraphData) {
                    if (this.convertAxisAsDate) {
                        newGraphData.forEach((serieData) => serieData.series.forEach((data) => (data.name = new Date(data.name)))); // Cast as date to display xAxis as date
                    }
                    this.graphData.results = newGraphData;
                    for (const graph of newGraphData) {
                        this.noData = true;
                        if (graph.series.length > 0) {
                            this.noData = false;
                            break;
                        }
                    }
                    if (this.noData) {
                        return;
                    }
                    // Check if data values === 0 to set noData as true
                    if (typeof ((_a = newGraphData[0].series[0]) === null || _a === void 0 ? void 0 : _a.value) === 'number') {
                        this.noData = true;
                        for (const graph of newGraphData) {
                            for (let i = 0; i < graph.series.length; i++) {
                                if (graph.series[i].value > 0) {
                                    this.noData = false;
                                    return;
                                }
                            }
                        }
                    }
                    else {
                        this.noData = false;
                    }
                }
                else {
                    this.noData = true;
                }
            })
                .catch((error) => {
                console.error(error);
                this._snackbarService.openError(error);
                this.isLoading = false;
                this.noData = true;
            })
                .finally(() => {
                this._changeDetectorRef.detectChanges();
            });
        });
    }
    ngOnChanges(changes) {
        var _a;
        if (this.autoUpdateOnParams && ((_a = changes === null || changes === void 0 ? void 0 : changes.params) === null || _a === void 0 ? void 0 : _a.currentValue)) {
            this.updateEmit.emit();
        }
    }
    dateTickFormatting(val) {
        if (val instanceof Date) {
            return val.toLocaleString('fr-FR', { month: 'short', day: 'numeric' });
        }
        return val;
    }
    getDefaultStep() {
        var _a;
        if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.period) {
            if (!this.params.period.start) {
                return EGraphStep.MONTH;
            }
            const start = moment(this.params.period.start);
            const end = this.params.period.end ? moment(this.params.period.end) : moment(moment.now());
            const diffMonth = Math.abs(start.diff(end, 'month', true));
            if (diffMonth <= 1) {
                return EGraphStep.DAY;
            }
            if (diffMonth <= 6) {
                return EGraphStep.WEEK;
            }
            return EGraphStep.MONTH;
        }
    }
    capitalizeSelectOptions(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], GraphWidgetComponent.prototype, "graphType", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], GraphWidgetComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof AreaChartComponent !== "undefined" && AreaChartComponent) === "function" ? _e : Object)
], GraphWidgetComponent.prototype, "graphData", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], GraphWidgetComponent.prototype, "getGraphData", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GraphWidgetComponent.prototype, "params", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GraphWidgetComponent.prototype, "autoUpdateOnParams", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GraphWidgetComponent.prototype, "convertAxisAsDate", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], GraphWidgetComponent.prototype, "updateEmit", void 0);
__decorate([
    ContentChild('customSeriesTooltip', { static: true }),
    __metadata("design:type", Object)
], GraphWidgetComponent.prototype, "customSeriesTooltip", void 0);
__decorate([
    ContentChild('customTooltip', { static: true }),
    __metadata("design:type", Object)
], GraphWidgetComponent.prototype, "customTooltip", void 0);
GraphWidgetComponent = __decorate([
    Component({
        selector: 'mpx-graph-widget',
        template: require('./graph-widget.component.html').default,
        styles: [require('./graph-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _b : Object, typeof (_c = typeof TooltipService !== "undefined" && TooltipService) === "function" ? _c : Object, typeof (_d = typeof ViewContainerRef !== "undefined" && ViewContainerRef) === "function" ? _d : Object])
], GraphWidgetComponent);
export { GraphWidgetComponent };
