var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { ListARSComponent } from './list-ars/list-ars.component';
import { DetailARSComponent } from './detail-ars/detail-ars.component';
import { ARSMembersTableComponent } from './detail-ars/members-table/members-table.component';
import { SharedModule } from '@app/shared/shared.module';
import { AdminARSRoutingModule } from './ars-routing.module';
let AdminARSModule = class AdminARSModule {
    constructor() { }
};
AdminARSModule = __decorate([
    NgModule({
        declarations: [ListARSComponent, DetailARSComponent, ARSMembersTableComponent],
        imports: [
            CommonModule,
            BrowserAnimationsModule,
            UIRouterModule,
            FuseSharedModule,
            FuseDirectivesModule,
            MatButtonModule,
            MatPaginatorModule,
            MatIconModule,
            MatTableModule,
            MatSortModule,
            MatRippleModule,
            MatProgressSpinnerModule,
            MatListModule,
            MatCardModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            SharedModule
        ],
        exports: [AdminARSRoutingModule]
    }),
    __metadata("design:paramtypes", [])
], AdminARSModule);
export { AdminARSModule };
