import { EDirRight } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MpDirectoryHierarchyController {
    /** @ngInject */
    constructor(FilesService, $scope, DirectoryRightsService, Auth) {
        this.FilesService = FilesService;
        this.$scope = $scope;
        this.DirectoryRightsService = DirectoryRightsService;
        this.Auth = Auth;
        /**
         * Open sub directory
         * @param event
         * @param dir
         */
        this.expandCollapse = (event, dir) => {
            event.stopPropagation();
            if (dir.isExpanded) {
                dir.isExpanded = false;
            }
            else {
                if (dir.hasFetchChildren) {
                    dir.isExpanded = true;
                }
                else {
                    dir.isLoading = true;
                    this.FilesService.getChildrenDirectories(dir).then((res) => {
                        dir.isLoading = false;
                        dir.children = res;
                        dir.isExpanded = true;
                        dir.hasFetchChildren = true;
                        for (let c of res) {
                            c.parentDirectory = dir;
                        }
                    });
                }
            }
        };
        /**
         * A directory is selected
         * @param event
         * @param dir
         */
        this.select = (event, dir) => {
            event.stopPropagation();
            if (!this.isExcluded(dir) && this.canMoveTo(dir)) {
                if (this.topSelectDir) {
                    this.topSelectDir(dir);
                }
            }
        };
        /**
         * Check if the given directory [[IFile]] is excluded
         * @param dir
         * @returns {boolean}
         */
        this.isExcluded = (dir) => {
            for (let f of this.excluded) {
                if (Utils.compareIds(f, dir)) {
                    return true;
                }
            }
            return false;
        };
        /**
         * Can user move items in given [[IFile]]
         * @param directory
         * @return {boolean}
         */
        this.canMoveTo = (directory) => {
            if (!directory.bc) {
                directory.bc = MpDirectoryHierarchyController._createBreadcrumbForDirectory(directory);
            }
            return this.DirectoryRightsService.userCanDoActionInside(this.Auth.user._id, EDirRight.ADD_CHILD, directory.bc);
        };
        /**
         * Can user see [[IFile]]
         * @param directory
         * @return {boolean}
         */
        this.canSee = (directory) => {
            if (!directory.bc) {
                directory.bc = MpDirectoryHierarchyController._createBreadcrumbForDirectory(directory);
            }
            return this.DirectoryRightsService.userCanDoActionInside(this.Auth.user._id, EDirRight.SEE_DIR, directory.bc);
        };
    }
    ;
    /**
     * Get all path (as list of [[IFile]]s of the given [[IFile]])
     * @param directory
     * @return {Array}
     * @private
     */
    static _createBreadcrumbForDirectory(directory) {
        let bc = [];
        do {
            bc.unshift(directory);
            directory = directory.parentDirectory;
        } while (directory);
        return bc;
    }
}
