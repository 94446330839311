export const locale = {
    lang: 'fr',
    data: {
        ERRORS: {
            MAJOR: {
                MISSING_COLUMN: 'La colonne est manquante dans le fichier à importer.',
                INTERNAL_ERROR: "Une erreur inattendue s'est produite. Veuillez contacter le service de support.",
                EMPTY_FILE: 'Le fichier ne peut pas être VIDE.'
            },
            MODERATE: {
                CELL_CHECK_ARRAY: 'Plusieurs valeurs sont acceptées, séparées par " {{ separator }} ".',
                CELL_CHECK_BOOLEAN: 'La valeur doit être OUI ou NON.',
                CELL_CHECK_CIP: 'La valeur doit être un CIP13 valide.',
                CELL_CHECK_CLADIMED: 'La valeur doit être un code CLADIMED valide.',
                CELL_CHECK_DATE_GT: 'La valeur doit être une DATE postérieure à {{date}}.',
                CELL_CHECK_DATE_GTE: 'La valeur doit être une DATE égale ou postérieure à {{date}}.',
                CELL_CHECK_DATE_LT: 'La valeur doit être une DATE antérieure à {{date}}.',
                CELL_CHECK_DATE_LTE: 'La valeur doit être une DATE égale ou antérieure à {{date}}.',
                CELL_CHECK_DATE: 'La valeur doit être une DATE.',
                CELL_CHECK_DATE_FORMAT: 'La valeur de la cellule doit être une DATE respectant un des formats suivants : {{formats}}.',
                CELL_CHECK_EQUALS: 'La valeur de la cellule doit être égale {{value}}.',
                CELL_CHECK_LIST: 'La valeur de la cellule doit faire partie de la liste prédéfinie.',
                CELL_CHECK_STRING: 'La valeur de la cellule doit être un TEXTE.',
                CELL_CHECK_NUMBER: 'La valeur de la cellule doit être un NOMBRE POSITIF.',
                CELL_CHECK_REQUIRED: 'La valeur de la cellule ne peut pas être VIDE.',
                CELL_CHECK_UCD: 'La valeur de la cellule doit être un UCD7 ou UCD13 valide.'
            },
            MINOR: {
                CELL_PROCESS_CLEAR: 'Des ESPACES peuvent être supprimés AVANT, DANS et/ou APRES la valeur.',
                CELL_PROCESS_UCD13: "L'UCD peut être converti en UCD13.",
                CELL_PROCESS_CIP13: 'Le CIP peut être converti en CIP13.',
                CELL_PROCESS_REMOVE_SPECIAL_CHAR: 'Des caractères spéciaux peuvent être retirés de la référence.',
                CELL_PROCESS_TRIM: 'Des ESPACES peuvent être supprimés AVANT et/ou APRES la valeur.'
            }
        }
    }
};
