export const subscriptionsNotifs = {
    loan: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.LOAN"
    },
    borrowing: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.BORROWING"
    },
    available: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.AVAILABLE"
    },
    preparation: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.PREPARATION"
    },
    request: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.REQUEST"
    },
    news: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.NEWS"
    },
    hospital: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.HOSPITAL"
    },
    sharedFile: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.FILE"
    },
    group: {
        translate: "SETTINGS.NOTIFICATIONS.SUBSCRIPTION.GROUP"
    }
};
