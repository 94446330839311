var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableSummaryComponent } from './available-summary.component';
import { FlexModule } from '@angular/flex-layout';
import { AvailableRequestModule } from '../available-request/available-request.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AvailableSummaryRoutingModule } from './available-summary-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AvailableHospitalModule } from '../available-hospital/available-hospital.module';
import { SharedModule } from '@app/shared/shared.module';
let AvailableSummaryModule = class AvailableSummaryModule {
};
AvailableSummaryModule = __decorate([
    NgModule({
        declarations: [AvailableSummaryComponent],
        imports: [
            CommonModule,
            SharedModule,
            FlexModule,
            AvailableRequestModule,
            AvailableHospitalModule,
            MatIconModule,
            TranslateModule,
            AvailableSummaryRoutingModule,
            MatButtonModule,
            MatBadgeModule,
            MatTooltipModule,
            FormsModule,
            MatFormFieldModule
        ]
    })
], AvailableSummaryModule);
export { AvailableSummaryModule };
