var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EGroupType, EMpStatus, EPubSubTopic } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HvToolbarController {
    /** @ngInject */
    constructor($scope, $rootScope, $state, $mdSidenav, $translate, $q, PubSubService, HospitalService, HospivilleService, MedicationReconciliationService, ModalService, ToastService, PatientService, Access, Api, Auth, $document, $location, _, VaultService, ToolbarService, _hvSessionService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$mdSidenav = $mdSidenav;
        this.$translate = $translate;
        this.$q = $q;
        this.PubSubService = PubSubService;
        this.HospitalService = HospitalService;
        this.HospivilleService = HospivilleService;
        this.MedicationReconciliationService = MedicationReconciliationService;
        this.ModalService = ModalService;
        this.ToastService = ToastService;
        this.PatientService = PatientService;
        this.Access = Access;
        this.Api = Api;
        this.Auth = Auth;
        this.$document = $document;
        this.$location = $location;
        this._ = _;
        this.VaultService = VaultService;
        this.ToolbarService = ToolbarService;
        this._hvSessionService = _hvSessionService;
        /**
         * Tells if the patient merging modal is open
         */
        this.mergeModalOpen = false;
        /**
         * Set Hospiville data
         * @private
         */
        this._setData = () => {
            this.isAlreadyProcessingData = true;
            const defer = this.$q.defer();
            this.patient = null;
            this.selectedMedRec = null;
            this.medicationReconciliations = null;
            if (Utils.hasId(this.Access.patient)) {
                this.HospivilleService.getPatient()
                    .then((patient) => __awaiter(this, void 0, void 0, function* () {
                    var _a;
                    this.patient = patient;
                    if (this.patient.medicationReconciliations) {
                        // Using await and not then/catch to avoid loosing "this" context with nested promise with big arrows callbacks
                        const medRecs = yield this.HospivilleService.getAllMedRec(this.patient.medicationReconciliations);
                        this.medicationReconciliations = medRecs;
                        if (this.medicationReconciliations) {
                            if (!this.selectedMedRec) {
                                this.selectedMedRec = this._.find(this.medicationReconciliations, (mr) => {
                                    return Utils.compareIds(mr, this.Access.patient.medicationReconciliation);
                                });
                                if (!this.selectedMedRec) {
                                    this.selectedMedRec = this._.find(this.medicationReconciliations, {
                                        isActive: true
                                    });
                                }
                            }
                            this.Access.changePatient(Utils.getId(this.patient), this.selectedMedRec ? Utils.getId(this.selectedMedRec) : null);
                            this._hvSessionService.setActivePatient = {
                                _id: (_a = this.patient) === null || _a === void 0 ? void 0 : _a.establishmentPatient
                            };
                            this._hvSessionService.setActiveMedRec = this.selectedMedRec ? this.selectedMedRec : null;
                            this.isAlreadyProcessingData = false;
                        }
                        defer.resolve();
                    }
                    this.isAlreadyProcessingData = false;
                    defer.resolve();
                }))
                    .catch(() => {
                    this.isAlreadyProcessingData = false;
                    defer.reject();
                });
            }
            return defer.promise;
        };
        // private _showMergeModalIfNeeded = (patient: IPatient) => {
        //     const defer = this.$q.defer();
        //     if (patient.oldPatientInfo) {
        //         this.mergeModalOpen = true;
        //         this.ModalService.show({
        //             component: 'hv-modal-merge-patient-info',
        //             bindings: {
        //                 patient: patient,
        //             },
        //             escapeToClose: true,
        //         }).then((ans: IPatient) => {
        //             ans.oldPatientInfo = null;
        //             this.HospivilleService.savePatient(ans).then((pat: IPatient) => {
        //                 defer.resolve(pat);
        //             });
        //         }).catch();
        //     } else {
        //         defer.resolve(patient);
        //     }
        //     return defer.promise;
        // };
        this._refreshPatientFile = () => {
            this.$rootScope.$broadcast('refreshPatientFile');
        };
        this.toggleSidenav = () => {
            this.ToolbarService.callbackToggleNavbar();
        };
        /**
         * Logout Function
         */
        this.logout = () => {
            this.PubSubService.emit(EPubSubTopic.IDLE_STOP);
            this.Access.logout(() => {
                this.$location.path('/auth/login');
            }, () => {
                this.$rootScope.error = 'Failed to logout';
            });
        };
        /**
         * Change Language
         */
        this.changeLanguage = (lang) => {
            this.selectedLanguage = lang;
            // Change the language
            this.$translate.use(lang.code);
        };
        /**
         * Remove current [[IPatient]] selection
         */
        this.changePatient = () => {
            if (this.patient) {
                this.patient = null;
                this.Access.changePatient(null, null);
                this._hvSessionService.resetCookies();
                this._refreshPatientFile();
                this.$state.go('hospivilleV2.patients.list');
            }
        };
        /**
         * Add a new [[IPatient]] and set as current patient
         */
        this.addPatient = () => {
            if (this.migrationState) {
                return;
            }
            this.Access.changePatient(null, null);
            this.patient = null;
            this.selectedMedRec = null;
            this.medicationReconciliations = null;
            this.$state.go('hospiville.patient-file', { newPatient: true }, { reload: true });
        };
        /**
         * Select a new patient and set
         * @param patient
         */
        this.selectPatient = ({ patient }) => {
            this.patient = patient;
            this.Access.changePatient(patient._id, null);
            this._setData().then(() => this._refreshPatientFile());
            if (this.$state.current.name === 'hospiville.patient-file') {
                this.$state.reload();
            }
            else {
                this.$state.go('hospiville.patient-file');
            }
        };
        /**
         * Set selected [[IMedicationReconciliation]] to null
         */
        this.changeMedRec = () => {
            this.selectedMedRec = undefined;
            this.Access.changePatient(this.patient._id, null);
            this.$rootScope.$broadcast('refreshPatientFile');
        };
        /**
         * Select an [[IMedicationReconciliation]]
         */
        this.selectMedRec = () => {
            this.Access.changePatient(this.patient._id, this.selectedMedRec ? this.selectedMedRec._id : null);
            this.$rootScope.$broadcast('refreshPatientFile');
        };
        /**
         * Close current [[IMedicationReconciliation]]
         */
        this.endMedRec = () => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'HOSPIVILLE.TOOLBAR.MODAL.CONFIRM.TITLE',
                        text: 'HOSPIVILLE.TOOLBAR.MODAL.CONFIRM.TEXT'
                    }
                },
                escapeToClose: false
            }).then(() => {
                if (this.patient.medicationReconciliations) {
                    for (const medRec of this.patient.medicationReconciliations) {
                        if (medRec._id === this.selectedMedRec._id) {
                            medRec.active = false;
                            break;
                        }
                    }
                }
                this.selectedMedRec.isActive = false;
                this.MedicationReconciliationService.end(this.selectedMedRec)
                    .then((saveMedRec) => {
                    if (this.Access.isInterfaced() && this.selectedMedRec.initiator) {
                        this.HospivilleService.exportMedRecStatus(Utils.getId(saveMedRec), 3, 'update');
                    }
                    this.selectedMedRec = null;
                    this.Access.changePatient(this.patient._id, null);
                    this.$rootScope.$broadcast('refreshPatientFile');
                    this.ToastService.show(EMpStatus.Ok);
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            });
        };
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this._constTypes || (!value && value !== 0)) {
                return;
            }
            return this._.find(this._constTypes[from], { value: value }).translate;
        };
        /**
         * Get the given info regarding the given consent status
         * @param {number} status
         * @param {string} info
         * @returns {any}
         */
        this.getStatusInfo = (status, info) => {
            if (!this._constTypes || status === 0) {
                return;
            }
            return this._.find(this._constTypes['statusConsent'], { value: status })[info];
        };
        /**
         * Get patient gender
         * @param {number} gender
         * @returns {string}
         */
        this.getGenderPatient = (gender) => {
            return gender === undefined ? '' : gender === 0 ? '♂' : '♀';
        };
        /**
         * Check that merge between [[IPatient]] and old[[IPatient]] is possible/Needed
         */
        this._isMergePossible = (patient) => {
            this.PatientService.get(Utils.getId(patient), true)
                .then((pat) => {
                this.PatientService.get(Utils.getId(pat), true, true)
                    .then((oldPat) => {
                    this.isMergeNeeded = !this._.isEqual(oldPat, pat);
                })
                    .catch((err) => {
                    console.log(err);
                });
            })
                .catch((err) => {
                console.log(err);
            });
        };
        if (this.Access.user) {
            this.user = this.Access.user;
            //Set with a role if needed;
            this.canAccessEstablishment = true;
        }
        // Data
        this.$rootScope.global = {};
        this.bodyEl = angular.element('body');
        this.languages = {
            fr: {
                title: 'French',
                translation: 'TOOLBAR.FRENCH',
                code: 'fr',
                flag: 'fr'
            },
            en: {
                title: 'English',
                translation: 'TOOLBAR.ENGLISH',
                code: 'en',
                flag: 'us'
            }
        };
        //onInit
    }
    $onInit() {
        this.HospitalService.get(this.Access.hospital._id)
            .then((hosp) => {
            this.hospital = hosp;
            this._.each(hosp.groups, (group) => {
                if (group.type == EGroupType.GHT) {
                    this.ght = group;
                }
            });
        })
            .catch((err) => console.log(err));
        if (!this.VaultService.isLocked()) {
            this._setData()
                .then(() => {
                //this.Access.changePatient(Utils.getId(this.patient), Utils.getId(this.selectedMedRec));
                this._refreshPatientFile();
            })
                .catch((err) => console.error(err));
        }
        this.selectedLanguage = this.languages[this.$translate.use() ? this.$translate.use() : 'en'];
        this._refreshPatientListener = this.$rootScope.$on('refreshPatient', (event) => {
            // A $scope unsubscribes itself $onDestroy
            this._setData()
                .then(() => {
                //this.Access.changePatient(Utils.getId(this.patient), Utils.getId(this.selectedMedRec));
                this._refreshPatientFile();
            })
                .catch((err) => console.error(err));
        });
        // Not sure it is ever called once...
        this.$rootScope.$on('changePatient', (event) => {
            this._setData()
                .then(() => {
                //this.Access.changePatient(Utils.getId(this.patient), Utils.getId(this.selectedMedRec));
                this._refreshPatientFile();
            })
                .catch((err) => console.error(err));
        });
        this.PubSubService.on(EPubSubTopic.VAULT_UNLOCKED, () => {
            // Hack because if at the startup of the front-app $onInit is triggered and vault unlocked is triggered too
            if (this.isAlreadyProcessingData) {
                return;
            }
            this._setData()
                .then(() => {
                //this.Access.changePatient(Utils.getId(this.patient), Utils.getId(this.selectedMedRec));
                this._refreshPatientFile();
            })
                .catch((err) => console.error(err));
        });
        this.Api.hvConstType.get((ans) => {
            this._constTypes = ans;
        });
        this.isHospital = this.Access.isHospital();
        this.isCompany = this.Access.isCompany();
        if (!this.isHospital && !this.isCompany) {
            this.$document.find('#toolbar').addClass('pharma-toolbar');
            this.$document.find('#main').addClass('pharma-content');
        }
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onDestroy() {
        //will unsubscribe the listener
        if (this._refreshPatientListener) {
            this._refreshPatientListener();
        }
    }
}
