var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { fuseAnimations } from '@fuse/animations';
import { Utils } from '@main/services/Utils';
import { MatTableDataSource } from '@angular/material/table';
let LaboGroupHospitalsTabComponent = class LaboGroupHospitalsTabComponent {
    constructor() {
        this.groupLaboHospitals = [];
        this.hospitalsForm = new UntypedFormControl();
        this.displayedColumns = ['name', 'action'];
        this.dataSource = new MatTableDataSource();
    }
    ngOnInit() {
        this._hospitals = this.groupLaboHospitals.map((groupLaboHospital) => groupLaboHospital.hospital);
        this.updateDatasource();
    }
    get hospitals() {
        return this._hospitals;
    }
    updateDatasource() {
        this.dataSource.data = this._hospitals;
    }
    addHospital() {
        this._hospitals.push(this.selectedHospital);
        this.updateDatasource();
        this.closeMenu();
        this.form.markAsDirty();
    }
    removeHospital(hospital) {
        const index = this._hospitals.indexOf(hospital);
        if (index >= 0) {
            this._hospitals.splice(index, 1);
            this.updateDatasource();
        }
        this.form.markAsDirty();
    }
    closeMenu() {
        this.menu.closeMenu();
        this.selectedHospital = null;
        this.hospitalsForm.reset();
    }
    onSelectNewHospital(hospital) {
        if (hospital) {
            this.selectedHospital = hospital;
        }
    }
    // Sort
    sortCompare(a, b, direct) {
        if (!a) {
            return direct ? 1 : -1;
        }
        if (!b) {
            return direct ? -1 : 1;
        }
        if (a === b) {
            return 0;
        }
        if (a < b) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    sortData(sort) {
        if (!this._hospitals) {
            return;
        }
        const data = this._hospitals.slice();
        if (!sort || !sort.active || sort.direction === '') {
            this._hospitals = data.sort((a, b) => {
                return this.sortCompare(Utils.normalize(a.name), Utils.normalize(b.name), true);
            });
            this.updateDatasource();
            return;
        }
        this._hospitals = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.sortCompare(Utils.normalize(a.name), Utils.normalize(b.name), isAsc);
                default:
                    return 0;
            }
        });
        this.updateDatasource();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _a : Object)
], LaboGroupHospitalsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Array !== "undefined" && Array) === "function" ? _b : Object)
], LaboGroupHospitalsTabComponent.prototype, "groupLaboHospitals", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], LaboGroupHospitalsTabComponent.prototype, "animationDone", void 0);
__decorate([
    ViewChild('selectHospitalMenuTrigger', { static: false }),
    __metadata("design:type", typeof (_c = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _c : Object)
], LaboGroupHospitalsTabComponent.prototype, "menu", void 0);
LaboGroupHospitalsTabComponent = __decorate([
    Component({
        selector: 'mpx-labo-group-hospitals-tab',
        template: require('./labo-group-hospitals-tab.component.html').default,
        styles: [require('./labo-group-hospitals-tab.component.scss')],
        animations: fuseAnimations
    })
], LaboGroupHospitalsTabComponent);
export { LaboGroupHospitalsTabComponent };
