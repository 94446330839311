import { EStatusIndicator } from '@mapuilabs/mpl-interfaces';
export const CMedRecStatus = {
    onGoing: {
        value: EStatusIndicator.Ongoing,
        translate: 'MEDREC.STATUS.ON_GOING',
        color: '#ff9800'
    },
    toValidate: {
        value: EStatusIndicator.ToValidate,
        translate: 'MEDREC.STATUS.TO_VALIDATE',
        color: '#ff9800'
    },
    validated: {
        value: EStatusIndicator.Validate,
        translate: 'MEDREC.STATUS.VALIDATED',
        color: '#8cc34a'
    }
};
