export class MpModalPdfViewer {
    constructor() {
        this.controller = 'MpModalPdfViewerController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-pdf-viewer.html').default;
        this.bindings = {
            file: '<',
        };
    }
}
