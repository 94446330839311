var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterModule } from '@uirouter/angular';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { ReleaseNotesListComponent } from './release-notes-list/release-notes-list.component';
import { ReleaseNoteDetailsComponent } from './release-note-details/release-note-details.component';
import { ReleaseNotesRoutingModule } from './release-notes-routing.module';
let ReleaseNotesModule = class ReleaseNotesModule {
};
ReleaseNotesModule = __decorate([
    NgModule({
        declarations: [ReleaseNotesListComponent, ReleaseNoteDetailsComponent],
        imports: [
            CommonModule,
            ReleaseNotesRoutingModule,
            BrowserAnimationsModule,
            UIRouterModule,
            FuseSharedModule,
            SharedModule,
            ColorPickerModule,
            NgxMatDatetimePickerModule,
            QuillModule.forRoot(),
            MatButtonModule,
            MatChipsModule,
            MatDatepickerModule,
            MatDividerModule,
            MatFormFieldModule,
            MatIconModule,
            MatInputModule,
            MatMenuModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatSortModule,
            MatSlideToggleModule,
            MatTableModule,
            MatTooltipModule
        ],
        exports: [ReleaseNotesRoutingModule],
        providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 300, hideDelay: 200 } }]
    })
], ReleaseNotesModule);
export { ReleaseNotesModule };
