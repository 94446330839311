export class MpSearchTownComponent {
    constructor() {
        this.controller = 'MpSearchTownController';
        this.controllerAs = 'vm';
        this.template = require('./mp-search-town.html').default;
        this.bindings = {
            label: '<',
            type: '<',
            town: '<',
            form: '<',
            name: '<',
            required: '<',
            onSelected: '&',
            onTextChange: '&',
        };
    }
    ;
}
