import * as angular from 'angular';
/** @ngInject */
export function RouteConfig($stateProvider, $urlRouterProvider, $locationProvider) {
    $urlRouterProvider.rule(($injector, $location) => {
        // Yes the protocol exist on $location in new version of LocationService
        if ($location.protocol() === 'file') {
            return;
        }
        const path = $location.path();
        // check to see if the path already ends in '/'
        if (path[path.length - 1] === '/') {
            return path.substring(0, path.length - 1);
        }
    });
    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise('/common/errors/404');
    // FIX for trailing slashes. Gracefully "borrowed" from https://github.com/angular-ui/ui-router/issues/50
    /**
     * Layout Style Switcher
     *
     * This code is here for demonstration purposes.
     * If you don't need to switch between the layout
     * styles like in the demo, you can set one manually by
     * typing the template urls into the `State definitions`
     * area and remove this code
     */
    // Inject $cookies
    let $cookies;
    angular.injector(['ngCookies']).invoke([
        '$cookies',
        function (_$cookies) {
            $cookies = _$cookies;
        }
    ]);
    // Get active layout
    const layoutStyle = $cookies.get('layoutStyle') || 'verticalNavigation';
    const layouts = {
        verticalNavigation: {
            main: 'mpMain',
            toolbar: 'mpToolbar',
            navigation: 'mpNavigation',
            quickPanel: 'mpQuickPanel'
        },
        contentOnly: {
            main: 'mpContentOnly'
        },
        hospiville: {
            main: 'mpMain',
            toolbar: 'hvToolbar',
            navigation: 'mpNavigation',
            quickPanel: 'mpQuickPanel'
        }
    };
    // END - Layout Style Switcher
    // State definitions
    $stateProvider
        .state('mapui', {
        abstract: true,
        views: {
            'main@': {
                component: layouts[layoutStyle].main
            },
            'toolbar@mapui': {
                component: layouts[layoutStyle].toolbar
            },
            'navigation@mapui': {
                component: layouts[layoutStyle].navigation
            },
            'quickPanel@mapui': {
                component: layouts[layoutStyle].quickPanel
            }
        }
    })
        .state('hospiville', {
        abstract: true,
        views: {
            'main@': {
                component: layouts.hospiville.main
            },
            'toolbar-hv@hospiville': {
                component: layouts.hospiville.toolbar
            },
            'navigation@hospiville': {
                component: layouts.hospiville.navigation
            }
        }
    })
        .state('hospivilleV2', {
        abstract: true,
        views: {
            'main@': {
                component: layouts.hospiville.main
            },
            'toolbar@hospivilleV2': {
                component: layouts.verticalNavigation.toolbar
            },
            'navigation@hospivilleV2': {
                component: layouts.hospiville.navigation
            },
            'quickPanel@hospivilleV2': {
                component: layouts.hospiville.quickPanel
            }
        }
    });
}
