import { ETransit } from '@mapuilabs/mpl-interfaces';
export const CMedRecTransit = {
    entrance: {
        value: ETransit.Entrance,
        translate: 'MEDREC.TRANSIT.ENTRANCE'
    },
    exit: {
        value: ETransit.Exit,
        translate: 'MEDREC.TRANSIT.EXIT'
    },
    transfer: {
        value: ETransit.Transfer,
        translate: 'MEDREC.TRANSIT.TRANSFER'
    }
};
