export class HvPathologiesInterviewComponent {
    constructor() {
        this.controller = 'HvPathologiesInterviewController';
        this.controllerAs = 'vm';
        this.template = require('./hv-pathologies-interview.html').default;
        this.bindings = {
            onSave: '&',
            patient: '='
        };
    }
}
