var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { HsLaboMedicalDeviceDashboardRoutingModule } from './dashboard/hs-labo-medical-device-dashboard-routing.module';
import { HsLaboMedicalDevicePublicationRoutingModule } from './publication/hs-labo-medical-device-publication-routing.module';
import { HsLaboMedicalDeviceStatisticRoutingModule } from '@app/hospistock/labo/medical-device/statistic/hs-labo-medical-device-statistic-routing.module';
const states = [
    {
        name: 'mapui.hospistock.labo.medical-device',
        url: '/medical-device',
        abstract: true
    }
];
let HsLaboMedicalDeviceRoutingModule = class HsLaboMedicalDeviceRoutingModule {
};
HsLaboMedicalDeviceRoutingModule = __decorate([
    NgModule({
        imports: [
            UIRouterModule.forChild({ states: states }),
            HsLaboMedicalDeviceDashboardRoutingModule,
            HsLaboMedicalDeviceStatisticRoutingModule,
            HsLaboMedicalDevicePublicationRoutingModule
        ]
    })
], HsLaboMedicalDeviceRoutingModule);
export { HsLaboMedicalDeviceRoutingModule };
