var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { RolesService } from '@services/role/roles.service';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
import { DialogService } from '@services/dialog/dialog.service';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { SecureEmailAddressService } from '@services/admin/secure-email-address/secure-email-address.service';
import { take } from 'rxjs/operators';
let ActionsTabComponent = class ActionsTabComponent {
    constructor(_rolesService, _admMemberService, _dialogService, _snackBarService, _stateService, _translationService, _admHospitalService, _secureEmailAddressService) {
        this._rolesService = _rolesService;
        this._admMemberService = _admMemberService;
        this._dialogService = _dialogService;
        this._snackBarService = _snackBarService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this._admHospitalService = _admHospitalService;
        this._secureEmailAddressService = _secureEmailAddressService;
        this._rolesService.getAllHospital().subscribe((data) => {
            for (const project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'hospistock':
                        this.hospistockRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                }
            }
            this._setFormControls(this.hospital);
        }, (error) => this._snackBarService.openError(error));
    }
    ngOnChanges(changes) {
        if (changes.hospital) {
            this._setFormControls(this.hospital);
            this._secureEmailAddressService.getByHospitalId(this.hospital._id).subscribe((mail) => (this.secureEmailAddress = mail), (error) => this._snackBarService.openError(error));
        }
    }
    _setFormControls(hospital) {
        const mapuiRolesDefault = [];
        const hospivilleRolesDefault = [];
        const hospistockRolesDefault = [];
        const workspaceRolesDefault = [];
        if (hospital) {
            for (const role of hospital.roles) {
                if (this.mapuiRoles &&
                    this.mapuiRoles.length &&
                    this.mapuiRoles.filter((elem) => elem._id == role._id).length) {
                    mapuiRolesDefault.push(this.mapuiRoles.find((elem) => elem._id == role._id));
                }
                else if (this.hospivilleRoles &&
                    this.hospivilleRoles.length &&
                    this.hospivilleRoles.filter((elem) => elem._id == role._id).length) {
                    hospivilleRolesDefault.push(this.hospivilleRoles.find((elem) => elem._id == role._id));
                }
                if (this.hospistockRoles &&
                    this.hospistockRoles.length &&
                    this.hospistockRoles.filter((elem) => elem._id == role._id).length) {
                    hospistockRolesDefault.push(this.hospistockRoles.find((elem) => elem._id == role._id));
                }
                if (this.workspaceRoles &&
                    this.workspaceRoles.length &&
                    this.workspaceRoles.filter((elem) => elem._id == role._id).length) {
                    workspaceRolesDefault.push(this.workspaceRoles.find((elem) => elem._id == role._id));
                }
            }
        }
        this.form.setControl('roleMapui', new UntypedFormControl(mapuiRolesDefault));
        this.form.setControl('roleHospiville', new UntypedFormControl(hospivilleRolesDefault));
        this.form.setControl('roleHospistock', new UntypedFormControl(hospistockRolesDefault));
        this.form.setControl('roleWorkspace', new UntypedFormControl(workspaceRolesDefault));
        this.form.setControl('active', new UntypedFormControl(hospital ? hospital.active : null));
        this.form.setControl('register', new UntypedFormControl(hospital ? hospital.register : null));
        this.form.setControl('memberSince', new UntypedFormControl(hospital && hospital.memberSince ? new Date(hospital.memberSince) : null));
        this.form.setControl('memberTo', new UntypedFormControl(hospital && hospital.memberTo ? new Date(hospital.memberTo) : null));
        this.form.setControl('hospivilleMemberSince', new UntypedFormControl(hospital && hospital.hospivilleMemberSince ? new Date(hospital.hospivilleMemberSince) : null));
        this.form.setControl('hospivilleMemberTo', new UntypedFormControl(hospital && hospital.hospivilleMemberTo ? new Date(hospital.hospivilleMemberTo) : null));
        this.form.setControl('trialEnd', new UntypedFormControl(hospital && hospital.trialEnd ? new Date(hospital.trialEnd) : null));
        this.form.setControl('maxDataUsage', new UntypedFormControl(hospital ? Math.floor(hospital.maxDataUsage / Math.pow(1024, 3)) : null, [
            Validators.min(1),
            Validators.required
        ]));
    }
    // Add the given number of days to the 'trialEnd' FormControl;
    // Number can be negative
    addDaysMemberTo(nbDays) {
        const newDate = new Date(this.form.value.trialEnd);
        newDate.setDate(newDate.getDate() + nbDays);
        this.form.controls.trialEnd.setValue(newDate);
        this.form.controls.trialEnd.markAsDirty();
    }
    //Public get the maxUsage from the form control in bytes
    getMaxUsage() {
        return this.form.value.maxDataUsage * Math.pow(1024, 3);
    }
    logInHospital() {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('admHospitalDetail.ACTIONS.LOG_AS.CONFIRM_DIALOG.TITLE'),
            message: this._translationService.instant('admHospitalDetail.ACTIONS.LOG_AS.CONFIRM_DIALOG.MESSAGE', {
                establishment: this.hospital.name
            })
        })
            .pipe(take(1))
            .subscribe((ans) => {
            if (ans) {
                this._admMemberService
                    .changeCurrentMemberHospital(this.hospital)
                    .pipe(take(1))
                    .subscribe((data) => {
                    if (data !== null) {
                        window.location.reload();
                    }
                    else {
                        this._snackBarService.open(this._translationService.instant('admHospitalDetail.ACTIONS.ERROR'));
                    }
                }, this._snackBarService.openError);
            }
        });
    }
    /** Delete the current hospital after user confirmation **/
    deleteHospital() {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('admHospitalDetail.ACTIONS.DELETE.CONFIRM_DIALOG.TITLE'),
            message: this._translationService.instant('admHospitalDetail.ACTIONS.DELETE.CONFIRM_DIALOG.MESSAGE', {
                establishment: this.hospital.name
            })
        })
            .pipe(take(1))
            .subscribe((data) => {
            if (data) {
                this._admHospitalService
                    .delete(this.hospital)
                    .pipe(take(1))
                    .subscribe(() => {
                    this._snackBarService.open(this._translationService.instant('admHospitalDetail.ACTIONS.SNACK_DELETE'));
                    this._stateService.go('mapui.adm.hospitals.list');
                }, this._snackBarService.openError);
            }
        });
    }
    //Used by the parent component to construct the updated hospital instance.
    updateHospital(hospital) {
        return new Observable((observer) => {
            hospital.roles = this.form.value.roleMapui.concat(this.form.value.roleHospiville, this.form.value.roleHospistock, this.form.value.roleWorkspace);
            hospital.active = this.form.value.active;
            hospital.register = this.form.value.register;
            hospital.memberSince = this.form.value.memberSince;
            hospital.memberTo = this.form.value.memberTo;
            hospital.hospivilleMemberSince = this.form.value.hospivilleMemberSince;
            hospital.hospivilleMemberTo = this.form.value.hospivilleMemberTo;
            hospital.trialEnd = this.form.value.trialEnd;
            hospital.maxDataUsage = this.form.value.maxDataUsage * Math.pow(1024, 3);
            observer.next();
            observer.complete();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_j = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _j : Object)
], ActionsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_k = typeof Hospital !== "undefined" && Hospital) === "function" ? _k : Object)
], ActionsTabComponent.prototype, "hospital", void 0);
ActionsTabComponent = __decorate([
    Component({
        selector: 'mpx-actions-tab',
        template: require('./actions-tab.component.html').default,
        styles: [require('./actions-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof RolesService !== "undefined" && RolesService) === "function" ? _a : Object, typeof (_b = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object, typeof (_g = typeof AdminHospitalService !== "undefined" && AdminHospitalService) === "function" ? _g : Object, typeof (_h = typeof SecureEmailAddressService !== "undefined" && SecureEmailAddressService) === "function" ? _h : Object])
], ActionsTabComponent);
export { ActionsTabComponent };
