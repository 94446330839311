export const locale = {
    lang: 'fr',
    data: {
        listHospitals: {
            TITLE: 'Hôpitaux',
            SEARCH_HOSPITAL: 'Rechercher un hôpital',
            TABLE: {
                NAME: 'Nom',
                FINESS_CODES: 'Codes Finess',
                GROUP: 'Groupe',
                ACCOUNTS: 'Comptes',
                ROLES: 'Rôles'
            },
            DELETED: "L'hôpital a été supprimé !",
            CONFIRM_MESSAGE: 'Voulez-vous vraiment supprimer cet hôpital ?',
            IMPORT_BTN: `Importer`
        }
    }
};
