export const locale = {
    lang: 'en',
    data: {
        HOSPITAL_TEAM: {
            TITLE: 'My hospital - Team',
            SEARCH: 'Search a member',
            SIDEBAR: {
                TITLE: 'Types',
                ALL: 'All'
            },
            TABLE: {
                HEADER: {
                    NAME: 'Name',
                    EMAIL: 'Email',
                    TYPE: 'Type',
                    STATUS: 'Status',
                    ACTIONS: 'Actions'
                },
                EDIT: {
                    TITLE: 'Edit the member'
                }
            }
        }
    }
};
