export class SameAs {
    /** @ngInject */
    constructor() {
        this.link = (scope, elem, attr, ctrl) => {
            if (!ctrl)
                return;
            const ngModel = ctrl[0];
            ngModel.$validators.mismatch = (value) => {
                return value == scope.$eval(attr.sameAs);
            };
            scope.$watch(() => {
                return scope.$eval(attr.sameAs);
            }, () => {
                ngModel.$validate();
            });
        };
        this.restrict = 'A';
        this.require = ['ngModel'];
    }
    static factory() {
        const directive = () => new SameAs();
        directive.$inject = [];
        return directive;
    }
}
