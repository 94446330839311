var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsModalAlternativeFormComponent } from '@app/hospistock/shared/components/modals/alternative/form/hs-modal-alternative-form.component';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { DialogService } from '@app/services/dialog/dialog.service';
import { IdentityService } from '@app/services/identity/identity.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EHsHealthProductTypes, EHsUserTypes, HsPublicationAlternativeASchema, HsPublicationContainer } from '@mapuilabs/mpl-interfaces';
import { lastValueFrom } from 'rxjs';
let HsPublicationDetailAlternativeDetailComponent = class HsPublicationDetailAlternativeDetailComponent {
    constructor(_changeDetectorRef, _dialogService, _fuseTranslationLoaderService, _healthProductService, _publicationContainerService, _identityService, _injector, _matDialog, _snackbarService, _translationService) {
        this._changeDetectorRef = _changeDetectorRef;
        this._dialogService = _dialogService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._healthProductService = _healthProductService;
        this._publicationContainerService = _publicationContainerService;
        this._identityService = _identityService;
        this._injector = _injector;
        this._matDialog = _matDialog;
        this._snackbarService = _snackbarService;
        this._translationService = _translationService;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init events
        this.deleted = new EventEmitter();
        this.selectionChanged = new EventEmitter();
    }
    // Accessors
    get metadata() {
        return this.publicationContainer.metadata;
    }
    get publication() {
        return this.publicationContainer.publication;
    }
    get alternativeId() {
        return Utils.getId(this.alternative);
    }
    get isDrugRef() {
        return this._healthProductService.isDrugRef;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a, _b;
        // Init alternative permissions
        this.isCreatedByLabo = this.alternative.creatorType === EHsUserTypes.LABO;
        this.creatorName = this._publicationContainerService.readAlternativeCreatorName(this.alternative);
        this._updateIsCreator();
        this._updateIsValid();
        // Init permissions
        this.canSeePharmacyCount = this._publicationContainerService.viewType === EHsUserTypes.LABO;
        this.canSelect = this._publicationContainerService.viewType === EHsUserTypes.PUI;
        const group = this._publicationContainerService.getPurchaseGroupWhereUserIsAdministrator(this._identityService.user);
        this.canValidate =
            this._publicationContainerService.viewType === EHsUserTypes.GA &&
                this.isCreatedByLabo &&
                ((_b = (_a = group === null || group === void 0 ? void 0 : group.hospistockConfig) === null || _a === void 0 ? void 0 : _a.alternatives) === null || _b === void 0 ? void 0 : _b.moderator);
        this.readonly = this._publicationContainerService.readonly;
        // Read hospitals who have seleted the alternative
        if (this.canSeePharmacyCount) {
            this._retrieveHospitals();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Retrieve hostitals who have selected the alternative & update view
     */
    _retrieveHospitals() {
        return __awaiter(this, void 0, void 0, function* () {
            // Request alternatives based on publication Id
            const hospitals = yield lastValueFrom(this._publicationContainerService.readHospitalsByAlternative(Utils.getId(this.publication), this.alternativeId));
            this.nbHospitals = hospitals === null || hospitals === void 0 ? void 0 : hospitals.length;
            // Manually trigger rendering if view not destroyed.
            // View can be destroyed if method called after a server request.
            // In this case view can be destroyed if currentPublication changed before read hospitals response.
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }
    /**
     * Update alternative & trigger rendering
     * @param alternative New alternative value
     */
    _updateAlternative(alternative) {
        Object.assign(this.alternative, alternative);
        // Manually trigger rendering
        this._changeDetectorRef.detectChanges();
    }
    /**
     * Update isCreator by checking if the user is the alternative's creator.
     */
    _updateIsCreator() {
        const creatorTypeMatches = this.alternative.creatorType === this._publicationContainerService.viewType;
        const creatorMatches = this.alternative.creatorType === EHsUserTypes.GA
            ? Utils.compareIds(this.alternative.creatorGroup, this._publicationContainerService.getPurchaseGroupWhereUserIsAdministrator(this._identityService.user))
            : Utils.compareIds(this.alternative.creator, this._identityService.hospital);
        this.isCreator = creatorMatches && creatorTypeMatches;
    }
    /**
     * Update flag isValid depending on static metadata
     */
    _updateIsValid() {
        const staticMetadata = this.metadata.static;
        this.isValid = staticMetadata
            ? staticMetadata.validAlternatives
                .map(({ alternative }) => Utils.getId(alternative))
                .includes(this.alternativeId)
            : false;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Handle alternative selection
    // -----------------------------------------------------------------------------------------------------
    /**
     * Update selected alternative by the one provided.
     */
    selectAlternative() {
        return __awaiter(this, void 0, void 0, function* () {
            // Update selected alternative in metadata
            const updatedMetadata = yield lastValueFrom(this._publicationContainerService.selectAlternative([this.alternativeId]));
            // Update metadata without triggering subject changes
            Object.assign(this.metadata, updatedMetadata);
            // Update alternatives
            this.selectionChanged.emit();
        });
    }
    /**
     * Unselect the current selected alternative.
     */
    unselectAlternative() {
        return __awaiter(this, void 0, void 0, function* () {
            // Update selected alternative in metadata
            const updatedMetadata = yield lastValueFrom(this._publicationContainerService.selectAlternative([]));
            // Update metadata without triggering subject changes
            Object.assign(this.metadata, updatedMetadata);
            // Update alternatives
            this.selectionChanged.emit();
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Handle alternative validation
    // -----------------------------------------------------------------------------------------------------
    /**
     * Validate alternative in metadata and updat
     */
    validAlternative() {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedMetadata = yield lastValueFrom(this._publicationContainerService.validAlternative(this.alternativeId));
            // Update metadata without triggering subject changes
            Object.assign(this.metadata, updatedMetadata);
            // Update isValid
            this._updateIsValid();
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
    }
    /**
     * Refuse alternative in metadata
     */
    refuseAlternative() {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedMetadata = yield lastValueFrom(this._publicationContainerService.refuseAlternative(this.alternativeId));
            // Update metadata without triggering subject changes
            Object.assign(this.metadata, updatedMetadata);
            // Update isValid
            this._updateIsValid();
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Alternatives CRUD operations
    // -----------------------------------------------------------------------------------------------------
    /**
     * Edit alternative by opening a form in dialog.
     * Update alternative if edited.
     */
    editAlternative() {
        // Set dialog configuration
        const dialogRef = this._matDialog.open(HsModalAlternativeFormComponent, {
            data: {
                injector: this._injector,
                publication: this.publication,
                alternative: this.alternative
            },
            disableClose: true,
            minWidth: '60%',
            restoreFocus: false
        });
        // Open AlternativeFormModal
        try {
            dialogRef.afterClosed().subscribe({
                next: (alternative) => __awaiter(this, void 0, void 0, function* () {
                    if (alternative) {
                        // Edit alternative
                        const updatedAlternative = yield lastValueFrom(this._publicationContainerService.updateAlternative(this.alternativeId, new this._publicationContainerService.publicationAlternativeConstructor(alternative)));
                        // Update alternative locally
                        this._updateAlternative(updatedAlternative);
                        // Notify user
                        this._snackbarService.open(this._translationService.instant('PUBLICATION.NOTIFS.EDIT_ALTERNATIVE'));
                    }
                })
            });
        }
        catch (err) {
            this._snackbarService.openError(err);
        }
    }
    /**
     * Delete alternative with a confirmation dialog.
     * Update alternatives list if deleted.
     */
    deleteAlternative() {
        // Set dialog configuration
        const dialogRef = this._dialogService.openConfirmDialog({
            title: this._translationService.instant('PUBLICATION.MESSAGES.ALTERNATIVES.DELETE.TITLE'),
            message: this._translationService.instant('PUBLICATION.MESSAGES.ALTERNATIVES.DELETE.CONTENT'),
            confirmButton: this._translationService.instant('COMMONS.DELETE')
        });
        // Open confirm dialog
        try {
            dialogRef.subscribe((confirmation) => __awaiter(this, void 0, void 0, function* () {
                if (confirmation) {
                    // Delete alternative
                    yield lastValueFrom(this._publicationContainerService.deleteAlternative(this.alternativeId));
                    this.deleted.emit();
                    // Notify user
                    this._snackbarService.open(this._translationService.instant('PUBLICATION.NOTIFS.DELETE_ALTERNATIVE'));
                }
            }));
        }
        catch (err) {
            console.error(err);
            this._snackbarService.openError(err);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_l = typeof HsPublicationAlternativeASchema !== "undefined" && HsPublicationAlternativeASchema) === "function" ? _l : Object)
], HsPublicationDetailAlternativeDetailComponent.prototype, "alternative", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationDetailAlternativeDetailComponent.prototype, "isSelected", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_m = typeof HsPublicationContainer !== "undefined" && HsPublicationContainer) === "function" ? _m : Object)
], HsPublicationDetailAlternativeDetailComponent.prototype, "publicationContainer", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_o = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _o : Object)
], HsPublicationDetailAlternativeDetailComponent.prototype, "deleted", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_p = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _p : Object)
], HsPublicationDetailAlternativeDetailComponent.prototype, "selectionChanged", void 0);
HsPublicationDetailAlternativeDetailComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-alternative-detail',
        template: require('./hs-publication-detail-alternative-detail.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof DialogService !== "undefined" && DialogService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _d : Object, typeof (_e = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _e : Object, typeof (_f = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _f : Object, typeof (_g = typeof Injector !== "undefined" && Injector) === "function" ? _g : Object, typeof (_h = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _h : Object, typeof (_j = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _j : Object, typeof (_k = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _k : Object])
], HsPublicationDetailAlternativeDetailComponent);
export { HsPublicationDetailAlternativeDetailComponent };
