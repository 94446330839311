var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EFilterType } from '@mapuilabs/mpl-interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { RolesService } from '@services/role/roles.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { FormControl, FormGroup } from '@angular/forms';
let ListMembersSidebarComponent = class ListMembersSidebarComponent {
    constructor(_fuseTranslationLoaderService, _snackBarService, _rolesService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackBarService = _snackBarService;
        this._rolesService = _rolesService;
        this.form = new FormGroup({
            roleConfig: new FormControl('$all'),
            roleMapui: new FormControl([]),
            roleHospiville: new FormControl([]),
            roleHospistock: new FormControl([]),
            roleWorkspace: new FormControl([]),
            roleAdminPanel: new FormControl([]),
            acceptedCGUDateOK: new FormControl(),
            acceptedCGUDateKO: new FormControl(),
            deleted: new FormControl(false),
            locked: new FormControl(),
            lockedByAdmin: new FormControl(),
            hospital: new FormControl()
        });
        // Save form value records
        this._formRecordDefault = this.form.value;
        this.mapuiRoles = [];
        this.hospivilleRoles = [];
        this.hospistockRoles = [];
        this.workspaceRoles = [];
        this.adminPanelRoles = [];
        /**
         * Sends the filters to the list view so that it can update.
         */
        this.onApplyFilters = (replaceHistory = false) => {
            const filters = [];
            const filterRoleValues = [
                ...this.form.controls['roleMapui'].value,
                ...this.form.controls['roleHospiville'].value,
                ...this.form.controls['roleHospistock'].value,
                ...this.form.controls['roleWorkspace'].value,
                ...this.form.controls['roleAdminPanel'].value
            ];
            if (filterRoleValues.length > 0) {
                filters.push({
                    type: EFilterType.VALUES,
                    attribute: 'roles',
                    inArray: true,
                    values: filterRoleValues
                });
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'roleConfig',
                    value: this.form.controls['roleConfig'].value
                });
            }
            const acceptedCGUDateOK = this.form.controls['acceptedCGUDateOK'].value;
            const acceptedCGUDateKO = this.form.controls['acceptedCGUDateKO'].value;
            const acceptedCGUDate = [];
            if (acceptedCGUDateOK || acceptedCGUDateKO) {
                if (acceptedCGUDateOK) {
                    acceptedCGUDate.push('date');
                }
                if (acceptedCGUDateKO) {
                    acceptedCGUDate.push('null');
                }
                filters.push({
                    type: EFilterType.TYPE,
                    attribute: 'acceptedCGUDate',
                    values: acceptedCGUDate
                });
            }
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'deleted',
                value: this.form.controls['deleted'].value
            });
            if (this.form.controls['locked'].value === true) {
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'locked',
                    value: true
                });
            }
            if (this.form.controls['lockedByAdmin'].value === true) {
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'lockedByAdmin',
                    value: true
                });
            }
            if (this.form.controls['hospital'].value === true) {
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'hospital',
                    value: null
                });
            }
            this.filtersApplied.emit({ filters, replaceHistory });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.filtersApplied = new EventEmitter();
        this._rolesService.getAllMember().subscribe((data) => {
            for (const project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'hospistock':
                        this.hospistockRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                        break;
                    case 'adminPanel':
                        this.adminPanelRoles = project.roles;
                }
            }
            this._setFormControls();
        }, (error) => this._snackBarService.openError(error));
    }
    ngOnChanges(changes) {
        if ('applyFilters' in changes) {
            this.applyFilters = this.applyFilters || [];
            this._setFormControls();
        }
    }
    /**
     * Set default filters when user reaches /admin/member/list.
     * @private
     */
    _setFormControls() {
        if (this.mapuiRoles &&
            this.hospivilleRoles &&
            this.hospistockRoles &&
            this.workspaceRoles &&
            this.adminPanelRoles) {
            const filterRole = this.applyFilters.find((filter) => filter.attribute === 'roles');
            const filterRoleConfig = this.applyFilters.find((filter) => filter.attribute === 'roleConfig');
            if (filterRole) {
                const mapuiFormControl = this.mapuiRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const hospivilleFormControl = this.hospivilleRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const hospistockFormControl = this.hospistockRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const workspaceFormControl = this.workspaceRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const adminPanelFormControl = this.adminPanelRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                this.form.setControl('roleConfig', new FormControl(filterRoleConfig.value));
                this.form.setControl('roleMapui', new FormControl(mapuiFormControl));
                this.form.setControl('roleHospiville', new FormControl(hospivilleFormControl));
                this.form.setControl('roleHospistock', new FormControl(hospistockFormControl));
                this.form.setControl('roleWorkspace', new FormControl(workspaceFormControl));
                this.form.setControl('roleAdminPanel', new FormControl(adminPanelFormControl));
            }
            const acceptedCGUDate = this.applyFilters.find((filter) => filter.attribute === 'acceptedCGUDate');
            this.form.setControl('acceptedCGUDateOK', new FormControl(acceptedCGUDate === null || acceptedCGUDate === void 0 ? void 0 : acceptedCGUDate.values.find((value) => value === 'date')));
            this.form.setControl('acceptedCGUDateKO', new FormControl(acceptedCGUDate === null || acceptedCGUDate === void 0 ? void 0 : acceptedCGUDate.values.find((value) => value === 'null')));
            const locked = this.applyFilters.find((filter) => filter.attribute === 'locked');
            const lockedByAdmin = this.applyFilters.find((filter) => filter.attribute === 'lockedByAdmin');
            const hospital = this.applyFilters.find((filter) => filter.attribute === 'hospital');
            const deleted = this.applyFilters.find((filter) => filter.attribute === 'deleted');
            this.form.setControl('locked', new FormControl((locked === null || locked === void 0 ? void 0 : locked.value) === true ? true : false));
            this.form.setControl('lockedByAdmin', new FormControl((lockedByAdmin === null || lockedByAdmin === void 0 ? void 0 : lockedByAdmin.value) === true ? true : false));
            this.form.setControl('hospital', new FormControl((hospital === null || hospital === void 0 ? void 0 : hospital.value) === null ? true : false));
            this.form.setControl('deleted', new FormControl((deleted === null || deleted === void 0 ? void 0 : deleted.value) ? true : false));
        }
    }
    resetFilters() {
        this.form.reset(this._formRecordDefault);
        this.filtersApplied.emit({
            filters: [],
            replaceHistory: true
        });
    }
};
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], ListMembersSidebarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Array !== "undefined" && Array) === "function" ? _e : Object)
], ListMembersSidebarComponent.prototype, "applyFilters", void 0);
ListMembersSidebarComponent = __decorate([
    Component({
        selector: 'mpx-members-sidebar',
        template: require('./list-members-sidebar.component.html').default,
        styles: [require('./list-members-sidebar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object, typeof (_c = typeof RolesService !== "undefined" && RolesService) === "function" ? _c : Object])
], ListMembersSidebarComponent);
export { ListMembersSidebarComponent };
