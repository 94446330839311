export const locale = {
    lang: 'en',
    data: {
        patients_list_sidebar: {
            TITLE: 'Filters',
            APPLY_BUTTON: 'Apply',
            FILTERS: {
                STATUS: {
                    TITLE: 'Status',
                    MEDREC: 'Reconciliation',
                    DRUG_BALANCE: 'Drug balance'
                },
                PROCESS: 'Reconciliation process',
                TRANSIT: 'Active reconciliation type',
                MEDREC_DATE: {
                    TITLE: 'Reconciliation date',
                    START: 'From',
                    END: 'To'
                },
                HOSP_SERVICE: 'Service',
                PATHWAYS: {
                    MAIN: 'Pathways',
                    NO_PATHWAY: 'No pathway'
                }
            }
        }
    }
};
