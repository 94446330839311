import { EAdminPanelPermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class ExternalConfigurationService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Create a new [[IExternalConfiguration]]
         * @param configuration
         * @returns {Promise<IExternalConfiguration>}
         */
        this.create = (configuration) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalConfigurations_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalConfigurations.add(configuration, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        this.getById = (id) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalConfigurations_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalConfigurations.get({ id: Utils.getId(id) }, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an existing [[IExternalConfiguration]] in dataBase using the [[Id]]
         * @param configuration The [[IExternalConfiguration]] to update
         * @returns {Promise<IExternalConfiguration>}
         */
        this.save = (configuration) => {
            const defer = this.$q.defer();
            if (!configuration) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EAdminPanelPermission.ExternalConfigurations_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalConfigurations.save({ id: configuration._id }, configuration, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Delete the given [[IExternalConfiguration]]
         * @param configuration
         */
        this.delete = (configuration) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalConfigurations_Delete)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalConfigurations.delete({ id: Utils.getId(configuration) }, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
}
