import { SpreadSheet } from '../SpreadSheet';
export class MpUploadXlsxController {
    /** @ngInject */
    constructor(FileUploaderService, _) {
        this.FileUploaderService = FileUploaderService;
        this._ = _;
        /**
         * Callback called when the files are selected
         * @param {Array<LocalFile>} files
         */
        this.fileSelected = (files) => {
            var _a;
            if (files) {
                this.fileError = false;
                this.file = new SpreadSheet(files[0], null, (_a = this.rawData) !== null && _a !== void 0 ? _a : false);
            }
        };
        /**
         *
         */
        this.importSetup = () => {
            this.file.worksheet = this.file.sheetNames[this.sheetIdx - 1];
            this.configureMatches();
            if (!this.fileError)
                this.confirm();
            else if (this.fileError) {
                this.file = null;
            }
            return;
        };
        /**
         * Fill columns matches if autoMatch is true
         */
        this.configureMatches = () => {
            if (!this.autoMatch)
                return;
            if (this.file.worksheet && this.autoMatch) {
                this.file.firstRowAsHeader = true;
                // console.log('META FILE', this.file);
                let normalizedHeaders = this._.map(this.file.headerNames, (header) => {
                    return header.trim().toLowerCase();
                });
                // console.log('HEADERS NORMALIZED', normalizedHeaders);
                for (let match of this.matches) {
                    //TODO <START>
                    // Condition très execpetionnelle durant covid-19 pour un éditeur qui veut faire matcher 'reference_establiss'
                    // au lieu de 'reference_etablissement' on utilise donc un matching with includes et non un strict matching sur cette colonne
                    // pour l'import des stocks
                    if (match.key === 'reference_etabliss') {
                        let matchedArray = this._.map(normalizedHeaders, (normHead, idx) => {
                            if (normHead.includes(match.key))
                                return idx;
                        });
                        if (!!matchedArray.filter(n => typeof n === 'number').length)
                            this.file.matches[match.key] = matchedArray.filter(n => typeof n === 'number')[0];
                        //TODO <END>
                    }
                    else {
                        this.file.matches[match.key] = this._.indexOf(normalizedHeaders, match.key);
                    }
                }
                // console.log('MATCHES',this.matches);
                // console.log('FILE MATCHES', this.file.matches);
                for (let fileMatch in this.file.matches) {
                    if (this.file.matches[fileMatch] === -1 && this._isRequireMatch(fileMatch)) {
                        this.fileError = true;
                        return;
                    }
                }
                this.fileError = false;
            }
        };
        /**
         *
         */
        this.confirm = () => {
            if (this.onChange) {
                this.onChange({ spreadsheet: this.file });
            }
        };
        this.FileUploaderService.init(true, ['xls', 'xlsx', 'csv']);
        this.fileError = false;
    }
    $onInit() {
        this.uploader = this.FileUploaderService.uploader;
    }
    _isRequireMatch(fileMatch) {
        let filteredMatch = this._.filter(this.matches, (match) => {
            return match.key === fileMatch;
        });
        if (filteredMatch && filteredMatch[0])
            return filteredMatch[0].require;
    }
}
