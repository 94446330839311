var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AvailableAndOffers, EAvailableStatus, EInterest } from '@mapuilabs/mpl-interfaces';
import { AvailableRequestService } from '../../available-request.service';
import { IdentityService } from '@services/identity/identity.service';
import { Utils } from '@main/services/Utils';
let AvailableListItemComponent = class AvailableListItemComponent {
    constructor(_availableRequestService, _identityService) {
        this._availableRequestService = _availableRequestService;
        this._identityService = _identityService;
        this.rippleEvent = new EventEmitter();
        this.interest = EInterest;
    }
    ngOnInit() {
        this._availableRequestService.onCurrentAvailableChangedFromDetail.subscribe((currentAvailable) => {
            if (currentAvailable.available._id == this.availableAndOffers.available._id) {
                this.availableAndOffers = currentAvailable;
                this.offer = this.availableAndOffers.offers.find((offer) => offer.hospital == this._identityService.hospital._id);
            }
        });
        this.offer = this.availableAndOffers.offers.find((offer) => Utils.getId(offer.hospital) == this._identityService.hospital._id);
    }
    disableRipple(value) {
        this.rippleEvent.emit(value);
    }
    get isClosed() {
        return this.available.status === EAvailableStatus.Closed;
    }
    get isArchived() {
        return this.available.status === EAvailableStatus.Archived;
    }
    get isValidated() {
        var _a;
        return (_a = this.offer) === null || _a === void 0 ? void 0 : _a.validated;
    }
    addOffer(offer, interest, event) {
        event.stopPropagation();
        if (!offer) {
            offer = {
                interest: interest,
                hospital: this._identityService.hospital._id,
                member: this._identityService.user._id,
                available: this.availableAndOffers.available._id.toString()
            };
            this._availableRequestService.addOffer(offer).subscribe((newOffer) => {
                this.offer = newOffer;
                this.availableAndOffers.offers.push(newOffer);
                this._availableRequestService.onAvailableListChanged(this.availableAndOffers);
            });
        }
        else {
            offer.interest = interest;
            this._availableRequestService.updateOffer(offer).subscribe((updateOffer) => {
                this.offer = updateOffer;
                this.availableAndOffers.offers = this.availableAndOffers.offers.map((item) => {
                    if (Utils.compareIds(item, updateOffer)) {
                        return updateOffer;
                    }
                    return item;
                });
                this._availableRequestService.onAvailableListChanged(this.availableAndOffers);
            });
        }
    }
    get available() {
        return this.availableAndOffers.available;
    }
    get showFirstDCI() {
        var _a;
        return ((_a = this.available.drug.DCI) === null || _a === void 0 ? void 0 : _a.length) ? this.available.drug.DCI[0] : null;
    }
    get hasReachedEndDate() {
        return new Date(this.availableAndOffers.available.endDate).getTime() - Date.now() <= 0;
    }
    get discount() {
        if (this.available.unitPrice == null || this.available.drug.manufacturerPrice == null) {
            return undefined;
        }
        if (isNaN(this.available.unitPrice) || isNaN(this.available.drug.manufacturerPrice)) {
            return undefined;
        }
        return Number(((1 - this.available.unitPrice / this.available.drug.manufacturerPrice) * 100).toFixed(2));
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof AvailableAndOffers !== "undefined" && AvailableAndOffers) === "function" ? _c : Object)
], AvailableListItemComponent.prototype, "availableAndOffers", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], AvailableListItemComponent.prototype, "rippleEvent", void 0);
AvailableListItemComponent = __decorate([
    Component({
        selector: 'mpx-available-list-item',
        template: require('./available-list-item.component.html').default,
        styles: [require('./available-list-item.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AvailableRequestService !== "undefined" && AvailableRequestService) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object])
], AvailableListItemComponent);
export { AvailableListItemComponent };
