export class MpFileDetailsSidenavComponent {
    constructor() {
        this.controller = 'MpFileDetailsSidenavController';
        this.controllerAs = 'vm';
        this.template = require('./mp-file-details-sidenav.html').default;
        this.bindings = {
            file: '=',
            showActions: '<',
        };
    }
}
