import { EFileAction, ELvl } from '@mapuilabs/mpl-interfaces';
export class MpFileUploaderController {
    /** @ngInject */
    constructor($timeout, Api, Auth, FilesService, ModalService) {
        this.$timeout = $timeout;
        this.Api = Api;
        this.Auth = Auth;
        this.FilesService = FilesService;
        this.ModalService = ModalService;
        /**
         * Remove notification
         */
        this.popNotif = () => {
            if (this.notifTimeout && typeof this.notifTimeout.cancel === 'function') {
                this.notifTimeout.cancel();
                this.notifTimeout = null;
            }
            this.notif = undefined;
        };
        this.isUserFileOwner = (file) => {
            return (this.Auth.user._id === file.creationUID);
        };
        this.autoUploadFile = () => {
        };
        /**
         * Extract extension from fileUploaderService for uploader input
         */
        this.extractAllowedExtension = () => {
            let tmpArray = this.fileUploaderService.getAllowedExtension();
            let res = '';
            if (tmpArray) {
                for (let ext of tmpArray) {
                    ext = '.' + ext + ',';
                    res += ext;
                }
                return res;
            }
            else {
                return '*';
            }
        };
        /**
         * Display notification
         * @param action will define the text
         * @param lvl will define the color
         * @param closable display the close button or not
         * @private
         */
        this._pushNotif = (action, lvl = ELvl.WARNING, closable = true) => {
            let label;
            switch (action) {
                case EFileAction.ADD_FILE_BAD_EXTENSION:
                    label = 'FILE_BAD_EXTENSION';
                    break;
                case EFileAction.ADD_FILE_TOO_LARGE:
                    label = 'FILE_TOO_LARGE';
                    break;
                case EFileAction.ADD_FILE_NO_SPACE:
                    label = 'FILE_NO_SPACE';
                    break;
                case EFileAction.ERROR:
                    label = 'FILE_UPLOAD_ERROR';
                    break;
            }
            this.notif = {
                text: `FILES.NOTIFICATION.${label}`,
                lvl: lvl,
                closable: closable,
            };
            this.notifTimeout = this.$timeout(() => {
                this.popNotif();
            }, 2000);
        };
        this.deleteFile = (file, index) => {
            this.FilesService.confirmDeleteFileModal([file]).then(() => {
                this.onFileDelete({ file: this.files.splice(index, 1)[0] });
            });
        };
    }
    $onInit() {
        this.uploader = this.fileUploaderService.uploader;
        this.uploader.onAfterAddingFile = (item) => {
            if (!this.autoUpload)
                return;
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.ADMIN.CRON.MODAL.CONFIRM.TITLE',
                        text: 'MAPUI.ADMIN.CRON.MODAL.CONFIRM.UN_SCHEDULE',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                this.uploader.uploadItem(item);
            });
        };
        this.fileUploaderService.onItemFileBadExtension = () => {
            this._pushNotif(EFileAction.ADD_FILE_BAD_EXTENSION);
        };
        this.fileUploaderService.onItemFileSizeTooLarge = () => {
            this._pushNotif(EFileAction.ADD_FILE_TOO_LARGE);
        };
        this.fileUploaderService.onItemNoSpaceAvailable = () => {
            this._pushNotif(EFileAction.ADD_FILE_NO_SPACE);
        };
        this.fileUploaderService.onItemUploadError = () => {
            this._pushNotif(EFileAction.ERROR, ELvl.ERROR);
        };
        if (this.files && !(this.files instanceof Array)) {
            this.files = [this.files];
        }
        this.Api.filesType.get((ans) => {
            this.filesType = ans;
        });
        this.extractAllowedExtension();
    }
    $onChanges() {
    }
}
