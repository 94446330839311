import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class RegisterV2Controller {
    /** @ngInject */
    constructor(Auth, ToastService, $rootScope, $location, $window) {
        this.Auth = Auth;
        this.ToastService = ToastService;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$window = $window;
        this.register = () => {
            this.Auth.register({ email: this.email, hospital: this.hospitalRequested }, () => this.$location.path('/auth/register-validation'), (err) => {
                if (err.match(/Email '(.*)' already assigned/)) {
                    this.ToastService.show(EMpStatus.EmailAlreadyInUSe);
                }
                else {
                    this.ToastService.show(EMpStatus.RejectByServer);
                }
            });
        };
        this.registerPharmacist = () => {
            this.$window.location = '/ecps/officine';
        };
    }
}
