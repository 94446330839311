export const locale = {
    lang: 'fr',
    data: {
        admHospitalDetail: {
            EDIT_TITLE: `Hôpital - `,
            NEW_TITLE: `Nouvel hôpital`,
            TABS: {
                INFORMATION: `Informations`,
                OTHER: `Autres`,
                PERMISSIONS: `Actions / Permissions`,
                MEMBERS: `Membres`
            },
            INFORMATION: {
                NAME: `Nom de l'hôpital`,
                ADDRESS: `Adresse`,
                NUMBER: `Numéro`,
                WAY: `Voie`,
                ZIP: `Code postal`,
                CITY: `Ville`,
                COUNTRY: `Pays`,
                CONTACT: `Coordonnées`,
                PHONE_STD: `Téléphone standard`,
                PHONE_PHARMA: `Téléphone pharmacie`,
                EMAIL_PHARMA: 'Email pharmacie',
                FAX: `Fax`,
                WEBSITE: `Site web`,
                CAPACITY: `Capacité`,
                BED: {
                    MCO: `Lits MCO`,
                    SSR: `Lits SSR`,
                    PSY: `Lits PSY`,
                    EPHAD: `Lits EPHAD`
                },
                LOCATION: `Localisation`,
                REGION: `Région`,
                LATITUDE: `Latitude`,
                LONGITUDE: `Longitude`,
                OTHER: `Autres informations`,
                FINESS: `FINESS`,
                FINESSJUR: `FINESS Juridique`,
                PURCHASE_GROUP: "Groupement(s) d'achats",
                NON_SUBSCRIBED_GROUP: 'Groupement(s) non adhérent(s)',
                PURCHASE_GRP_NAME: 'Nom du groupement',
                APPLY_FINESS: `Pré-remplir`,
                LOGO: `Logo de l'hôpital`
            },
            ACTIONS: {
                ROLE_MAPUI: `Rôle MaPUI`,
                ROLE_HOSPIVILLE: `Rôle Hospiville`,
                ROLE_HOSPISTOCK: `Rôle Hospistock`,
                ROLE_WORKSPACE: `Rôle Workspace`,
                REGISTERED: `Enregistré`,
                ACTIVE: `Actif`,
                HOSPIVILLE_MEMBER_SINCE: `Membre Hospiville depuis`,
                HOSPIVILLE_MEMBER_TO: `Membre Hospiville jusqu'à`,
                MEMBER_SINCE: `Membre MaPUI depuis`,
                MEMBER_TO: `Membre MaPUI jusqu'à`,
                TRIAL_END: `Fin période d'essai`,
                ADD_DAYS: `Ajouter 30 jours`,
                REMOVE_DAYS: `Retirer 30 jours`,
                MAX_DATA: `Espace disponible (Go)`,
                SECURE_MAIL: {
                    INPUT: `Adresse mail sécurisée`,
                    BUTTON: `Modifier l'adresse mail`
                },
                LOG_AS: {
                    LONG: `Se connecter dans l'hôpital`,
                    SHORT: `Se connecter`,
                    CONFIRM_DIALOG: {
                        TITLE: 'Se connecter en tant que...',
                        MESSAGE: `Voulez-vous vous connecter dans l'établissement {{establishment}} ?`
                    }
                },
                DELETE: {
                    LONG: `Supprimer l'hôpital`,
                    SHORT: `Supprimer`,
                    CONFIRM_DIALOG: {
                        TITLE: `Supprimer l'établissement`,
                        MESSAGE: `Voulez-vous vraiment supprimer l'établissement {{establishment}} ?`
                    }
                },
                SNACK_DELETE: `L'hôpital a été supprimé.`,
                VALID: `Valider`,
                ERROR: `Erreur`
            },
            MEMBERS: {
                NAME: `Nom`,
                EMAIL: `Email`,
                LAST_CONNECT: `Dernière connexion`,
                ROLES: `Rôles`,
                EMPTY: `Cet hôpital n'a aucun membre.`
            },
            EDIT_OK: `L'hôpital a été modifié !`,
            CREATE_OK: `L'hôpital a été créé !`
        }
    }
};
