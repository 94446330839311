var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EChartDataType } from '../../../typings/chart-data-type.enum';
let ExKeyDataComponent = class ExKeyDataComponent {
    constructor() {
        this.dateKeyData = {
            isLoading: false,
            type: EChartDataType.Date,
            title: 'Widget qui contient une date',
            value: '2020-12-25 01:00:00.000Z',
            bottomLink: {
                title: "C'est bientôt Noël...!",
                url: 'mapui.pharmacyV2.provisions'
            },
            icon: {
                position: 'before',
                name: 'card_giftcard',
                style: { color: 'red' }
            }
        };
        this.numberKeyData = {
            isLoading: false,
            type: EChartDataType.Number,
            title: 'Widget qui contient un nombre',
            value: '42',
            fontClass: 'font-size-50'
        };
        this.loadingKeyData = {
            isLoading: true,
            type: EChartDataType.Text,
            title: 'Widget qui charge indéfiniment',
            value: null
        };
        this.inputKeyData = {
            isLoading: false,
            type: EChartDataType.Text,
            title: 'Widget qui contient ce que tu veux (texte)',
            description: 'Le texte peut être plus ou moins long, peu importe, il y a le scroll ! Par contre la description étend la hauteur du widget.',
            value: 'Que dois-je afficher ?',
            bottomLink: {
                title: 'Lien vers...',
                url: 'mapui.adm.sandbox.main'
            }
        };
        this.pageHeaderConfig = {
            title: 'Key Data Widget',
            icon: 'extension '
        };
    }
};
ExKeyDataComponent = __decorate([
    Component({
        selector: 'ex-key-data',
        template: require('./ex-key-data.component.html').default,
        styles: [require('./ex-key-data.component.scss')],
        animations: fuseAnimations
    })
], ExKeyDataComponent);
export { ExKeyDataComponent };
