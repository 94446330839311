var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { BillingAwaitingComponent } from './billing-awaiting/billing-awaiting.component';
import { BillingDetailComponent } from './billing-awaiting/billing-detail/billing-detail.component';
import { GenerateBillingComponent } from './billing-awaiting/billing-detail/billing-generate/billing-generate.component';
import { MemberBillingDetailComponent } from './billing-awaiting/billing-detail/member-detail/member-detail.component';
import { BillingInvoiceComponent } from './billing-invoice/billing-invoice.component';
import { BillingSelectSettleComponent } from './billing-select-settle/billing-select-settle.component';
import { BillingSelectBillComponent } from './billing-bill/billing-select-bill.component';
import { BillingComponent } from './billing.component';
let BillingModule = class BillingModule {
};
BillingModule = __decorate([
    NgModule({
        declarations: [
            BillingComponent,
            BillingSelectBillComponent,
            BillingSelectSettleComponent,
            BillingAwaitingComponent,
            BillingDetailComponent,
            MemberBillingDetailComponent,
            GenerateBillingComponent,
            BillingInvoiceComponent
        ],
        imports: [
            ReactiveFormsModule,
            CommonModule,
            MatPaginatorModule,
            MatTabsModule,
            MatCheckboxModule,
            MatIconModule,
            MatTooltipModule,
            MatButtonModule,
            TranslateModule,
            FusePipesModule,
            FlexLayoutModule,
            SharedModule,
            MatDialogModule,
            MatInputModule,
            MatFormFieldModule,
            MatSelectModule,
            MatStepperModule,
            MatExpansionModule,
            MatTableModule,
            MatChipsModule,
            MatCardModule,
            MatListModule
        ]
    })
], BillingModule);
export { BillingModule };
