export const locale = {
    lang: 'en',
    data: {
        externalActorDetail: {
            EDIT_TITLE: 'External actor - {{actor}}',
            NEW_ACTOR: 'New external actor',
            INFORMATION_TAB: {
                TITLE: 'Information',
                ACTOR_NAME: "External actor's name",
                TYPE: 'Type'
            },
            CONFIGURATION_TAB: {
                TITLE: 'Configurations',
                ID: 'Id',
                URL: 'URL',
                ESTABLISHMENTS: 'Establishments'
            }
        }
    }
};
