export const locale = {
    lang: 'fr',
    data: {
        externalActorDetail: {
            EDIT_TITLE: 'Acteur externe - {{actor}}',
            NEW_ACTOR: 'Nouvel acteur externe',
            INFORMATION_TAB: {
                TITLE: 'Informations',
                ACTOR_NAME: "Nom de l'acteur externe",
                TYPE: 'Type'
            },
            CONFIGURATION_TAB: {
                TITLE: 'Configurations',
                ID: 'Id',
                URL: 'URL',
                ESTABLISHMENTS: 'Établissements'
            }
        }
    }
};
