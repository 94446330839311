export const locale = {
    lang: 'fr',
    data: {
        PERMISSION_VIEW: {
            TITLE: `Dernières ruptures`,
            SINCE: 'Depuis le ',
            TO: "Jusqu'au",
            SEE_MORE: 'Voir plus de ruptures',
            NO_STOCKOUT: 'Pas de ruptures récentes',
            FORBIDDEN_MESSAGE: "Vous n'avez pas accès à cette fonctionnalité.",
            CONTACT_US: 'Contactez-nous'
        }
    }
};
