var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { HospitalService } from '@services/hospital/hospital.service';
import { map } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let SearchHospitalMemberComponent = class SearchHospitalMemberComponent {
    constructor(_hospitalService, _fuseTranslationLoaderService) {
        this._hospitalService = _hospitalService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.optionSelected = new EventEmitter();
        // Search Member config
        this.searchAutocompleteConfig = {
            displayFn: (member) => (member === null || member === void 0 ? void 0 : member.fullName) || (member === null || member === void 0 ? void 0 : member.email),
            autocompleteSearchFn: (searchValue) => this._hospitalService
                .searchMembersByHospitalId(Utils.getId(this.hospital), searchValue)
                .pipe(map((data) => {
                var _a;
                return (_a = data.docs) === null || _a === void 0 ? void 0 : _a.filter((member) => !Utils.getIds(this.exclude).includes(Utils.getId(member)));
            }))
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onOptionSelected(member) {
        this.optionSelected.emit(member);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Hospital !== "undefined" && Hospital) === "function" ? _c : Object)
], SearchHospitalMemberComponent.prototype, "hospital", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], SearchHospitalMemberComponent.prototype, "exclude", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchHospitalMemberComponent.prototype, "optionSelected", void 0);
SearchHospitalMemberComponent = __decorate([
    Component({
        selector: 'mpx-search-hospital-member',
        template: require('./search-hospital-member.component.html').default,
        styles: ['']
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchHospitalMemberComponent);
export { SearchHospitalMemberComponent };
