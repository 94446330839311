var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
import { ImportedHospitalsService } from '@services/admin/hospital/import-hospitals.service';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let ListHospitalsComponent = class ListHospitalsComponent extends ListPage {
    constructor(_injector, _importedHospitalsService, _fuseTranslationLoaderService, _hospitalService) {
        super(_injector, _hospitalService.getPage);
        this._injector = _injector;
        this._importedHospitalsService = _importedHospitalsService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._hospitalService = _hospitalService;
        // Columns to display in the data table
        this.displayedColumns = ['name', 'finessCodes', 'group', 'nbMembers', 'roles'];
        this.isDownloadSpreadsheetPending = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translationService.instant('listHospitals.TITLE'),
            searchBarPlaceholder: this._translationService.instant('listHospitals.SEARCH_HOSPITAL'),
            icon: 'local_hospital'
        };
    }
    openModalImport() {
        this._importedHospitalsService.openModalImport().subscribe((value) => {
            this.isLoadingResults = true;
            this._importedHospitalsService.addHospitalsByFiness(value).subscribe(() => {
                this._reload.emit();
                this.isLoadingResults = false;
                this._snackbarService.open(`Vérifier la console du navigateur pour voir le résultat de la requête.`);
            }, () => {
                this.isLoadingResults = false;
                this._snackbarService.open(this._translationService.instant('COMMONS.ERRORS.DEFAULT'));
            });
        }, (err) => {
            this.isLoadingResults = false;
            if (err === EMpStatus.NoResult) {
                this._snackbarService.open(this._translationService.instant('COMMONS.ERRORS.DEFAULT'));
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    downloadSpreadsheet() {
        return __awaiter(this, void 0, void 0, function* () {
            // Update pending flag
            this.isDownloadSpreadsheetPending = true;
            yield this._hospitalService.getSpreadsheetAndDownload(this.searchValue, this.sortValue, this.filtersValues);
            // Update pending flag
            this.isDownloadSpreadsheetPending = false;
        });
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_e = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _e : Object)
], ListHospitalsComponent.prototype, "_paginator", void 0);
ListHospitalsComponent = __decorate([
    Component({
        selector: 'mpx-list-hospitals',
        template: require('./list-hospitals.component.html').default,
        styles: [require('./list-hospitals.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof ImportedHospitalsService !== "undefined" && ImportedHospitalsService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof AdminHospitalService !== "undefined" && AdminHospitalService) === "function" ? _d : Object])
], ListHospitalsComponent);
export { ListHospitalsComponent };
