export class MpTableController {
    /** @ngInject */
    constructor($element) {
        this.$element = $element;
        /**
         * Calculate the number of columns for the current `smart-table`
         * @private
         */
        this._countColumns = () => {
            this._columns = Array.prototype.filter.call(this.$element[0].rows, (row) => {
                return !row.classList.contains('ng-leave');
            })
                .reduce((acc, current) => {
                return current.cells.length > acc ? current.cells.length : acc;
            }, 1);
        };
    }
    /**
     * Return the number of columns
     * @return {number}
     */
    get columns() {
        if (!this._columns) {
            this._countColumns();
        }
        return this._columns;
    }
}
