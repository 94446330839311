var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { HsModalPublicationFormComponent } from '@app/hospistock/shared/components/modals/publication/form/hs-modal-publication-form.component';
import { CHsSortStates } from '@app/hospistock/shared/constTypes/hs-sort.const';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { EHsToolbarViewModes } from '@app/hospistock/shared/types/hs-toolbar.types';
import { DialogService } from '@app/services/dialog/dialog.service';
import { IdentityService } from '@app/services/identity/identity.service';
import { NavBarService } from '@app/services/nav-bar-subject/nav-bar-subject.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Utils } from '@main/services/Utils';
import { StateService, UIRouterGlobals } from '@uirouter/angularjs';
import { debounce, isEqual } from 'lodash';
import { BehaviorSubject, lastValueFrom, merge, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
let HsPublicationToolbarComponent = class HsPublicationToolbarComponent {
    constructor(_dialogService, _fuseSidebarService, _publicationContainerService, _identityService, _injector, _matDialog, _mediaObserver, _navBarService, _router, _snackbarService, _stateService, _translationService) {
        this._dialogService = _dialogService;
        this._fuseSidebarService = _fuseSidebarService;
        this._publicationContainerService = _publicationContainerService;
        this._identityService = _identityService;
        this._injector = _injector;
        this._matDialog = _matDialog;
        this._mediaObserver = _mediaObserver;
        this._navBarService = _navBarService;
        this._router = _router;
        this._snackbarService = _snackbarService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this.filtersApplied = new EventEmitter();
        this.sortChange = new EventEmitter();
        /**
         * Pipe keyvalue : comparer to order by ascending property value
         */
        this.orderByRank = (a, b) => {
            return a.value.rank - b.value.rank;
        };
        /**
         * Finds the sidebar matching the given name and opens it if it's close.
         * @param sidebarName: String equals to the fuse-sidebar name attribute.
         */
        this.openFiltersSidebar = (sidebarName) => {
            this._fuseSidebarService.getSidebar(sidebarName).open();
        };
        // Init Subjects
        this._unsubscribeAll = new Subject();
        this.isCompactView$ = new BehaviorSubject(false);
        this.displayListToolbar$ = new BehaviorSubject(false);
        this.displayDetailToolbar$ = new BehaviorSubject(false);
    }
    // Navbar unfolded flag
    get navBarUnfolded$() {
        return this._navBarService.navBarUnfolded;
    }
    get currentPublicationContainer$() {
        var _a;
        return (_a = this.currentPublicationContainer$$) === null || _a === void 0 ? void 0 : _a.value;
    }
    get currentPublicationContainer() {
        var _a;
        return (_a = this.currentPublicationContainer$) === null || _a === void 0 ? void 0 : _a.value;
    }
    get currentPublication() {
        var _a;
        return (_a = this.currentPublicationContainer) === null || _a === void 0 ? void 0 : _a.publication;
    }
    get readonly() {
        return this._publicationContainerService.readonly;
    }
    get viewMode() {
        return this._publicationContainerService.viewMode;
    }
    get isDualView() {
        return this.viewMode === EHsToolbarViewModes.DUAL;
    }
    get isPfizerLabo() {
        return this._publicationContainerService.isPfizerOperator;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a, _b;
        // Apply default filter only if no filter is currently applied
        if (this.filtersConfig && !((_a = this.filtersValues) === null || _a === void 0 ? void 0 : _a.length)) {
            this.filtersApplied.emit(this.filtersConfig.defaultFilters.value);
        }
        // Debound to avoid to much request to the server
        this.refreshList = debounce(this.refreshList, 800, {
            leading: true,
            trailing: false
        });
        this.canEdit$ = (_b = this.currentPublicationContainer$$) === null || _b === void 0 ? void 0 : _b.pipe(takeUntil(this._unsubscribeAll), filter((currentPublicationContainer$) => !!currentPublicationContainer$), map(() => this._publicationContainerService.canEditPublication(this.currentPublication)));
        this.hasCheckedPublications$ = this._publicationContainerService.checkedPublicationContainers$.pipe(takeUntil(this._unsubscribeAll), map((containers) => containers.length > 0));
        this.hasListedPublications$ = this._publicationContainerService.publicationContainers$$.pipe(takeUntil(this._unsubscribeAll), map((containers$) => containers$.length > 0));
        this.canDeleteChecked$ = this._publicationContainerService.checkedPublicationContainers$.pipe(takeUntil(this._unsubscribeAll), map((publicationContainers) => publicationContainers.every(({ publication }) => Utils.compareIds(publication.creator, this._identityService.user.hospital) &&
            publication.creatorType === this._publicationContainerService.viewType &&
            this._publicationContainerService.readonly === false)));
        this.isIndeterminate$ = this._publicationContainerService.checkedPublicationContainers$.pipe(takeUntil(this._unsubscribeAll), map((containers) => {
            var _a;
            return containers.length !== ((_a = this._publicationContainerService.publicationContainers$$) === null || _a === void 0 ? void 0 : _a.value.length) &&
                containers.length > 0;
        }));
        // Handle responsive view behavior
        this._handleResponsiveViewBehavior();
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    _responsiveLayoutFlags() {
        const isDualViewMode = this.viewMode === EHsToolbarViewModes.DUAL;
        const isSingleListViewMode = this.viewMode === EHsToolbarViewModes.SINGLE_LIST;
        const isSingleDetailViewMode = this.viewMode === EHsToolbarViewModes.SINGLE_DETAIL;
        // Update isCompactView
        const isCompactViewOrConditions = [
            this._mediaObserver.isActive('lt-md'),
            this._mediaObserver.isActive('md') && this._isNavBarUnfolded
        ];
        this.isCompactView$.next(isCompactViewOrConditions.includes(true));
        // Update displayListToolbar
        const displayListToolbarOrConditions = [
            this.isCompactView$.value && !this._hasPublicationContainer,
            isSingleListViewMode,
            this._mediaObserver.isActive('md') && !this.isCompactView$.value && isDualViewMode,
            this._mediaObserver.isActive('gt-md') && isDualViewMode
        ];
        this.displayListToolbar$.next(displayListToolbarOrConditions.includes(true));
        // Update displayDetailToolbar
        const displayDetailToolbarOrConditions = [
            this.isCompactView$.value && this._hasPublicationContainer,
            isSingleDetailViewMode,
            this._mediaObserver.isActive('md') && !this.isCompactView$.value && isDualViewMode,
            this._mediaObserver.isActive('gt-md') && isDualViewMode
        ];
        this.displayDetailToolbar$.next(displayDetailToolbarOrConditions.includes(true));
    }
    _handleResponsiveViewBehavior() {
        // Update responsives flags on navBarUnfolded, currentPublicationContainers$$ or mediaObserver changes
        const updateIsNavbarUnfolded = (value) => (this._isNavBarUnfolded = value);
        const updateHasPublicationContainer = (publicationContainer$) => (this._hasPublicationContainer = !!publicationContainer$);
        merge(this._navBarService.navBarUnfolded.pipe(tap((value) => updateIsNavbarUnfolded(value))), this.currentPublicationContainer$$.pipe(tap((value) => updateHasPublicationContainer(value))), this._mediaObserver.asObservable())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._responsiveLayoutFlags());
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Select all publications
     */
    toggleSelectAll($event) {
        // Prevent default
        $event.preventDefault();
        this._publicationContainerService.toggleSelectAll();
    }
    /**
     * Toggle tag on checked publications
     *
     * @param tag
     */
    toggleTagOnCheckedPublications(tag) {
        this._publicationContainerService.toggleTagOnCheckedPublications(tag);
    }
    /**
     * Delete all checked publications
     */
    deleteCheckedPublications() {
        return __awaiter(this, void 0, void 0, function* () {
            this._dialogService
                .openConfirmDialog({
                title: this._translationService.instant('PUBLICATION.MESSAGES.PUBLICATION.DELETE.TITLE'),
                message: this._publicationContainerService.checkedPublicationContainers$.value.length > 1
                    ? this._translationService.instant('PUBLICATION.MESSAGES.PUBLICATION.DELETE.CONTENT_MULTI')
                    : this._translationService.instant('PUBLICATION.MESSAGES.PUBLICATION.DELETE.CONTENT'),
                confirmButton: this._translationService.instant('COMMONS.YES'),
                cancelButton: this._translationService.instant('COMMONS.NO')
            })
                .subscribe((data) => __awaiter(this, void 0, void 0, function* () {
                if (data) {
                    yield Promise.all(this._publicationContainerService.checkedPublicationContainers$.value.map(({ publication }) => this._publicationContainerService.deletePublication(Utils.getId(publication))));
                    this.refreshList();
                }
            }));
        });
    }
    /**
     * Check if a tag is associated to the checked publication to display the checkbox state in the toolbar's tag menu.
     * @param tag Tag to check.
     * @returns Boolean indicate if the tag must be displayed as check or not.
     */
    isTagCheck(tag) {
        const checkedPublicationContainers = this._publicationContainerService.checkedPublicationContainers$.value;
        return checkedPublicationContainers.every(({ metadata }) => { var _a; return (_a = metadata === null || metadata === void 0 ? void 0 : metadata.static) === null || _a === void 0 ? void 0 : _a.tags.includes(tag); });
    }
    /**
     * Check if a tag is associated to at least one of the checked publication to display the checkbox state in the toolbar's tag menu.
     * @param tag Tag to check.
     * @returns Boolean indicate if the tag must be displayed as indeterminate or not.
     */
    isTagIndeterminate(tag) {
        const checkedPublications = this._publicationContainerService.checkedPublicationContainers$.value;
        const taggedPublications = checkedPublications.filter(({ metadata }) => { var _a; return (_a = metadata === null || metadata === void 0 ? void 0 : metadata.static) === null || _a === void 0 ? void 0 : _a.tags.includes(tag); });
        return taggedPublications.length > 0 && taggedPublications.length !== checkedPublications.length;
    }
    /**
     * Download a spreadsheet matching the current sort & filters
     */
    downloadSpreadsheet() {
        return __awaiter(this, void 0, void 0, function* () {
            // Update pending flag
            this.isDownloadSpreadsheetPending = true;
            yield this._publicationContainerService.downloadPublicationsSpreadsheet(this.searchValue, this.sortValue, this.filtersValues);
            // Update pending flag
            this.isDownloadSpreadsheetPending = false;
        });
    }
    /**
     * Switch view dual/single
     */
    switchView(view) {
        if (view === 'single' && /\.dual$/g.test(this._router.current.name)) {
            this._stateService.go(this._router.current.name.replace(/\.dual$/g, '.list'));
        }
        if (view === 'dual' && /\.list$/g.test(this._router.current.name)) {
            this._stateService.go(this._router.current.name.replace(/\.list$/g, '.dual'));
        }
    }
    isDualViewActive() {
        return /\.dual$/g.test(this._router.current.name);
    }
    /**
     * Return the sort state for the given sort to display the corresponding icon in the toolbar's sort menu.
     * @param sort Sort to check.
     * @returns Sort state of type IHsPublicationSortState which can have default, ascending or descending direction and a corresponding icon.
     */
    getSortState(sort) {
        if (!this.sortValue || this.sortValue.active !== sort.active) {
            return CHsSortStates.default;
        }
        else {
            return CHsSortStates[this.sortValue.direction];
        }
    }
    /**
     * Handle the sort selection in the toolbar's sort menu.
     * @param sort Sort to handle.
     */
    selectSort(sort) {
        const nextState = {
            default: { active: sort.active, direction: CHsSortStates.asc.direction },
            asc: { active: sort.active, direction: CHsSortStates.desc.direction },
            desc: undefined
        };
        if (this.sortValue && (sort === null || sort === void 0 ? void 0 : sort.active) === this.sortValue.active) {
            this.sortChange.emit(nextState[this.sortValue.direction]);
            // this.sortChange(nextState[this.sortValue.direction]);
        }
        else {
            this.sortChange.emit(nextState.default);
            // this.sortChange(nextState.default);
        }
    }
    /**
     * Check if a preset filter is selected by checking the current filters.
     * @param presetFilter Preset filter to check.
     * @returns Is the preset filter active.
     */
    isPresetFilterActive(presetFilter) {
        return isEqual(presetFilter.value.filters, this.filtersValues);
    }
    /**
     * Check if at least one filter is applied and no preset filter are selected by checking the current filters.
     * @returns Is the advanced filter option active.
     */
    isAdvancecdPresetFilterActive() {
        var _a;
        return (this.filterCount > 0 &&
            ((_a = this.filtersConfig) === null || _a === void 0 ? void 0 : _a.presetFilters.every((presetFilter) => !this.isPresetFilterActive(presetFilter))));
    }
    /**
     * Check if a sort is active by checking the current sort.
     * @param sort Sort to check.
     * @returns Is the sort active.
     */
    isSortActive(sort) {
        var _a;
        return sort.active === ((_a = this.sortValue) === null || _a === void 0 ? void 0 : _a.active);
    }
    /**
     * Refresh the list by triggering the ListPage _reload method
     */
    refreshList() {
        this._publicationContainerService.onReloadList.next();
    }
    /**
     * Deselect the current publication.
     * Detail displayed in the right panel becomes empty.
     */
    resetCurrentPublication() {
        this._publicationContainerService.readPublication(null);
    }
    /**
     * Open publication form modal to create/update publication
     */
    editCurrentPublication() {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogRef = this._matDialog.open(HsModalPublicationFormComponent, {
                data: {
                    injector: this._injector,
                    publication: this.currentPublication
                },
                disableClose: true,
                minWidth: '60%',
                restoreFocus: false
            });
            const publication = yield lastValueFrom(dialogRef.afterClosed());
            if (publication) {
                // Update publication
                yield this._publicationContainerService.updatePublication(publication);
            }
        });
    }
    /**
     * Toggle history sidebar.
     */
    toggleHistorySidebar() {
        this._fuseSidebarService.getSidebar('sidebar-history').toggleOpen();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationToolbarComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_o = typeof BehaviorSubject !== "undefined" && BehaviorSubject) === "function" ? _o : Object)
], HsPublicationToolbarComponent.prototype, "currentPublicationContainer$$", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], HsPublicationToolbarComponent.prototype, "filterCount", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationToolbarComponent.prototype, "filtersConfig", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsPublicationToolbarComponent.prototype, "filtersValues", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], HsPublicationToolbarComponent.prototype, "isLoading", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationToolbarComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationToolbarComponent.prototype, "sortValue", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_p = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _p : Object)
], HsPublicationToolbarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_q = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _q : Object)
], HsPublicationToolbarComponent.prototype, "sortChange", void 0);
HsPublicationToolbarComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-toolbar',
        template: require('./hs-publication-toolbar.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _b : Object, typeof (_c = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof Injector !== "undefined" && Injector) === "function" ? _e : Object, typeof (_f = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _f : Object, typeof (_g = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _g : Object, typeof (_h = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _h : Object, typeof (_j = typeof UIRouterGlobals !== "undefined" && UIRouterGlobals) === "function" ? _j : Object, typeof (_k = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _k : Object, typeof (_l = typeof StateService !== "undefined" && StateService) === "function" ? _l : Object, typeof (_m = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _m : Object])
], HsPublicationToolbarComponent);
export { HsPublicationToolbarComponent };
