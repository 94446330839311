var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { HsPurchaseGroupDrugRoutingModule } from './drug/hs-purchase-group-drug-routing.module';
import { HsPurchaseGroupMedicalDeviceRoutingModule } from './medical-device/hs-purchase-group-medical-device-routing.module';
const states = [
    {
        name: 'mapui.hospistock.purchase-group',
        url: '/purchase-group',
        abstract: true
    }
];
let HsPurchaseGroupRoutingModule = class HsPurchaseGroupRoutingModule {
};
HsPurchaseGroupRoutingModule = __decorate([
    NgModule({
        imports: [
            UIRouterModule.forChild({ states: states }),
            HsPurchaseGroupDrugRoutingModule,
            HsPurchaseGroupMedicalDeviceRoutingModule
        ]
    })
], HsPurchaseGroupRoutingModule);
export { HsPurchaseGroupRoutingModule };
