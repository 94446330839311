import { EPatientConsent } from '@mapuilabs/mpl-interfaces';
export const CPatientConsentStatus = {
    notRealised: {
        value: EPatientConsent.NotRealized,
        translate: 'PATIENT.CONSENT.NOT_REALISED',
        color: '#cfd8dc'
    },
    ok: {
        value: EPatientConsent.OK,
        translate: 'PATIENT.CONSENT.OK',
        color: '#96bf31'
    },
    ko: {
        value: EPatientConsent.KO,
        translate: 'PATIENT.CONSENT.KO',
        color: '#F44336'
    },
    waiting: {
        value: EPatientConsent.Waiting,
        translate: 'PATIENT.CONSENT.WAITING',
        color: '#FDD835'
    },
    unrealisable: {
        value: EPatientConsent.Unrealizable,
        translate: 'PATIENT.CONSENT.UNREALISABLE',
        color: '#F57C00'
    }
};
