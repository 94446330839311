var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { MedicalDeviceService } from '@app/services/medical-device/medical-device.service';
import { EHsHealthProductTypes, HsPublicationASchema } from '@mapuilabs/mpl-interfaces';
let HsPublicationDetailHealthProductsComponent = class HsPublicationDetailHealthProductsComponent {
    constructor(_healthProductService, _medicalDeviceService, _snackbarService, _translationService) {
        this._healthProductService = _healthProductService;
        this._medicalDeviceService = _medicalDeviceService;
        this._snackbarService = _snackbarService;
        this._translationService = _translationService;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this.collapsed = true;
        this.limit = 2;
    }
    get collapsable() {
        return this._collapsable;
    }
    set collapsable(val) {
        this._collapsable = coerceBooleanProperty(val);
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    generateHealthProductDetail(healthProduct) {
        return this._healthProductService
            .handleHealthProduct((drug) => [
            drug.ref &&
                this._translationService.instant(['COMMONS.WORDS.CIP', 'COMMONS.PUNCTUATION.COLON']) +
                    ' ' +
                    drug.ref
        ], (medicalDevice) => [
            medicalDevice.ref &&
                this._translationService.instant(['COMMONS.WORDS.REF', 'COMMONS.PUNCTUATION.COLON']) +
                    ' ' +
                    medicalDevice.ref,
            medicalDevice.cladimed.ref &&
                this._translationService.instant(['CLADIMED', 'COMMONS.PUNCTUATION.COLON']) +
                    ' ' +
                    medicalDevice.cladimed.ref
        ], healthProduct)
            .filter((value) => value)
            .join(', ');
    }
    generateLimitListInfo() {
        return (this.limit +
            ' ' +
            this._translationService.instant('COMMONS.WORDS.OVER').toLocaleLowerCase() +
            ' ' +
            this.publication.healthProductRef.healthProducts.length);
    }
    onToggleCollapsed() {
        this.collapsed = !this.collapsed;
    }
    redirectToProductLink(exhausCode) {
        this._medicalDeviceService.findMedicalDeviceLink(exhausCode).subscribe({
            next: (link) => {
                const message = this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.SHEET.LINK_NOT_FOUND');
                link ? window.open(link) : this._snackbarService.open(message);
            },
            error: (err) => console.error(`redirectToProductLink error: ${err}`)
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof HsPublicationASchema !== "undefined" && HsPublicationASchema) === "function" ? _e : Object)
], HsPublicationDetailHealthProductsComponent.prototype, "publication", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], HsPublicationDetailHealthProductsComponent.prototype, "collapsable", null);
HsPublicationDetailHealthProductsComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-health-products',
        template: require('./hs-publication-detail-health-products.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _a : Object, typeof (_b = typeof MedicalDeviceService !== "undefined" && MedicalDeviceService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], HsPublicationDetailHealthProductsComponent);
export { HsPublicationDetailHealthProductsComponent };
