var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { IdentityService } from '../../../services/identity/identity.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { HospitalService } from '../../../services/hospital/hospital.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { Access } from '@main/services/auth/access.service';
import { TranslationService } from '@app/services/translation/translation.service';
let HospitalInformationComponent = class HospitalInformationComponent {
    constructor(identityService, _permissionService, _fuseTranslationLoaderService, _snackbarService, _translateService, _translationService, _hospitalService, access) {
        this.identityService = identityService;
        this._permissionService = _permissionService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackbarService = _snackbarService;
        this._translateService = _translateService;
        this._translationService = _translationService;
        this._hospitalService = _hospitalService;
        this.access = access;
        this.canEdit = this._permissionService.authorize(EMaPUIPermission.Establishment_Edit);
        this.isEdited = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Initialization
        this.pageHeaderConfig = {
            title: this._translationService.instant('HOSPITAL_INFORMATION.TITLE'),
            icon: 'info'
        };
    }
    ngAfterViewInit() {
        this.data = this.identityService.hospital;
    }
    saveInformations() {
        this._hospitalService
            .save({
            _id: this.data._id,
            addrNumber: this.data.addrNumber,
            addrStreet: this.data.addrStreet,
            addrZIP: this.data.addrZIP,
            addrCountry: this.data.addrCountry,
            phoneSTD: this.data.phoneSTD,
            phoneSCT: this.data.phoneSCT,
            emailPharmaDept: this.data.emailPharmaDept,
            Fax: this.data.Fax,
            website: this.data.website,
            bedMCO: this.data.bedMCO,
            bedEPHAD: this.data.bedEPHAD,
            bedSSR: this.data.bedSSR,
            bedPSY: this.data.bedPSY
        })
            .subscribe((hospitalSaved) => {
            if (hospitalSaved) {
                this.data = hospitalSaved;
                this.isEdited = false;
                this._snackbarService.open(this._translateService.instant('HOSPITAL_INFORMATION.SAVE.SUCCESS'));
            }
            else {
                this._snackbarService.openCustomError(this._translateService.instant('HOSPITAL_INFORMATION.SAVE.FAILURE'));
            }
        }, (err) => {
            console.error(err);
            this._snackbarService.openError(err);
        });
    }
};
HospitalInformationComponent = __decorate([
    Component({
        selector: 'mpx-hospital-information',
        template: require('./hospital-information.component.html').default,
        styles: [require('./hospital-information.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object, typeof (_b = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object, typeof (_g = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _g : Object, typeof (_h = typeof Access !== "undefined" && Access) === "function" ? _h : Object])
], HospitalInformationComponent);
export { HospitalInformationComponent };
