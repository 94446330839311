export const locale = {
    lang: 'en',
    data: {
        ROLES: {
            TITLE: 'Roles manager',
            SAVE_SUCCESS: 'Permissions saved',
            SIDEBAR: {
                TITLE: 'Projects list'
            },
            TOOLTIPS: {
                UNHANDELED_PERMISSIONS: 'Unhandeled permissions:'
            },
            TABLE: {
                HEADER: {
                    ROLE: 'Role',
                    TYPE: 'Type',
                    SUBSCRIPTION: 'Subscription',
                    CUSTOM_HOME: 'Custom home',
                    DESC: 'Description',
                    PERMISSIONS: 'Permissions',
                    ACTIONS: 'Actions'
                },
                EDIT: {
                    TITLE: 'Edit',
                    MODAL: {
                        TITLES: {
                            EDIT: 'Editing role',
                            NEW: 'New role',
                            INFORMATION: 'Information',
                            ROLES: 'Roles',
                            HOME: 'Custom home page'
                        },
                        TYPE: 'Type',
                        NAME: 'Name',
                        SUBSCRIPTION: 'Subscription',
                        DESCRIPTION: 'Description',
                        HOME: 'Home page (state)',
                        IS_ADMIN: 'Administrator?',
                        AVAILABLE_USER_ROLES: 'Accessible roles',
                        MESSAGES: {
                            STATE_EMPTY: 'No state',
                            STATE_BROKEN: 'Broken state'
                        }
                    },
                    SUCCESS: {
                        EDIT: 'Role edited',
                        NEW: 'Role created'
                    }
                },
                DELETE: {
                    TITLE: 'Delete',
                    SUCCESS: 'Role deleted',
                    MODAL: {
                        CONTENT: 'Do you really want to delete this role?'
                    }
                },
                DUPLICATE: {
                    TITLE: 'Duplicate',
                    SUCCESS: 'Role duplicated'
                },
                LEAVE_MODAL: {
                    TITLE: 'Leave?',
                    CONTENT: 'Do you really want to leave without saving?'
                }
            },
            MAPUI: {
                TITLE: 'MaPUI',
                HOME: 'Home',
                USER: 'Users',
                USER_ROLE: "Users' roles",
                ESTABLISHMENT: 'My hospital',
                ESTABLISHMENT_TEAM: 'Establishment team',
                GROUPS: 'Groups',
                PREPARATIONS: 'Preparations',
                PREP_INST: 'Preparation instance',
                STOCKOUT: 'Stockouts',
                STOCKOUT_LABO: 'Laboratory HsStockouts',
                EQUIVALENCES: 'Equivalences',
                PHARMACEUTIC_INTERVENTIONS: 'Pharma interventions',
                DASHBOARD: 'Dashboard',
                MED_INST: 'Drug instance',
                REQUEST: 'Requests',
                MAP: 'Map',
                AVAILABLE: 'Available',
                AVAILABLE_FREEMIUM: 'Available Freemium',
                BILLING: 'Billing',
                STATISTICS: 'Statistics',
                ARCHIVES: 'Archives',
                LOCAL_TRANSPORT: 'Local',
                WIDGET_HOME: 'Home page Widget',
                ARS: 'ARS',
                STOCK: 'Stock',
                STOCK_RISK: 'Stock risk',
                NATIONAL_ENTITY: 'National entity'
            },
            WORKSPACE: {
                TITLE: 'Workspace',
                FILES: 'Files',
                FILES_RIGHTS: 'Directory rights manager',
                NEWS: 'News',
                GROUPS: 'Groups'
            },
            HOSPISTOCK: {
                TITLE: 'Hospistock',
                // # Common
                // Vault
                VAULT_DISCONNECTION: 'Disconnection',
                // # Admin
                ADMIN: 'Admin',
                // # Labo
                // Display menu mode
                LABO_DISPLAY_MENU_FLAT: 'Laboratory - Flat menu',
                LABO_DISPLAY_MENU_NESTED: 'Laboratory - Nested menu',
                // Drug
                LABO_DRUG_DASHBOARD: 'Laboratory (drug) - Dashboard',
                LABO_DRUG_STATISTIC: 'Laboratory (drug) - Statistics',
                LABO_DRUG_PUBLICATION: 'Laboratory (drug) - Publications',
                LABO_DRUG_PUBLICATION_STATS: 'Laboratory (drug) - Publication statistics',
                LABO_DRUG_ALTERNATIVE: 'Laboratory (drug) - Alternatives',
                // Medical device
                LABO_MEDICAL_DEVICE_DASHBOARD: 'Laboratory (medical device) - Dashboard',
                LABO_MEDICAL_DEVICE_STATISTIC: 'Laboratory (medical device) - Statistics',
                LABO_MEDICAL_DEVICE_PUBLICATION: 'Laboratory (medical device) - Publications',
                LABO_MEDICAL_DEVICE_PUBLICATION_STATS: 'Laboratory (medical device) - Publication statistics',
                // # Purchase group
                // Display menu mode
                PURCHASE_GROUP_DISPLAY_MENU_FLAT: 'Purchase Group - Flat menu',
                PURCHASE_GROUP_DISPLAY_MENU_NESTED: 'Purchase Group - Nested menu',
                // Drug
                PURCHASE_GROUP_DRUG_DASHBOARD: 'Purchase Group (drug) - Dashboard',
                PURCHASE_GROUP_DRUG_MARKET: 'Purchase Group (drug) - Market',
                PURCHASE_GROUP_DRUG_PUBLICATION: 'Purchase Group (drug) - Publications',
                PURCHASE_GROUP_DRUG_PUBLICATION_STATS: 'Purchase Group (drug) - Publication statistics',
                // Medical device
                PURCHASE_GROUP_MEDICAL_DEVICE_DASHBOARD: 'Purchase Group (medical device) - Dashboard',
                PURCHASE_GROUP_MEDICAL_DEVICE_MARKET: 'Purchase Group (medical device) - Market',
                PURCHASE_GROUP_MEDICAL_DEVICE_PUBLICATION: 'Purchase Group (medical device) - Publications',
                PURCHASE_GROUP_MEDICAL_DEVICE_PUBLICATION_STATS: 'Purchase Group (medical device) - Publication statistics',
                // # Pharmacy
                // Display menu mode
                PHARMACY_DISPLAY_MENU_FLAT: 'Pharmacy - Flat menu',
                PHARMACY_DISPLAY_MENU_NESTED: 'Pharmacy - Nested menu',
                // Drug
                PHARMACY_DRUG_BOOKLET: 'Pharmacy (drug) - Booklet',
                PHARMACY_DRUG_DASHBOARD: 'Pharmacy (drug) - Dashboard',
                PHARMACY_DRUG_PUBLICATION: 'Pharmacy (drug) - Publications',
                // Medical device
                PHARMACY_MEDICAL_DEVICE_BOOKLET: 'Pharmacy (medical device) - Booklet',
                PHARMACY_MEDICAL_DEVICE_DASHBOARD: 'Pharmacy (medical device) - Dashboard',
                PHARMACY_MEDICAL_DEVICE_PUBLICATION: 'Pharmacy (medical device) - Publications'
            },
            HOSPIVILLE: {
                TITLE: 'Hospiville',
                IS_PREMIUM: 'Subscribed to HospiVille',
                MIGRATION_PERM: 'Migration HospiVille',
                PATIENTS: 'Patients',
                PATIENT: 'Patient',
                RECONCILIATION: 'Reconciliation',
                PATIENT_FILE: 'Patient file',
                PATIENT_INTERVIEW_SURVEY: 'Patient interview',
                DBS: 'Drug balance sheet',
                MEDICATION_RECONCILIATION: 'Treatment reconciliation',
                RECONCILIATION_SHEET: 'Reconciliation sheet',
                PRESCRIPTION: 'Prescription',
                PATIENT_FILES: 'Patient documents',
                EXTENSIVE_PATIENT_INTERVIEW: 'Extensive patient interview',
                TREATMENT_ANALYSIS: 'Treatment analysis',
                CARE_PLAN: 'Care plan',
                ACTIVITIES: 'Exchanges between city & hospital',
                MAILBOX: 'Mailbox',
                STATISTICS: 'Statistics',
                ESTABLISHMENT: 'Establishment',
                SHARE_PATIENT: 'Patient sharing',
                CONFIGURATION: 'Configuration',
                PREVIOUS_MED_REC: 'Previous MedRec',
                CHECKUP: 'BPM',
                OCTAVE: 'OCTAVE'
            },
            ADMIN_PANEL: {
                TITLE: 'Admin Panel',
                ADMIN_PANEL: 'Administration panel',
                ESTABLISHMENTS: 'Hospitals',
                USERS: 'Users',
                GROUPS: 'Groups',
                ADD_ESTABLISHMENT: 'Add hospital',
                STATISTICS: 'Statistics',
                PROSPECTS: 'Prospects',
                SECURE_EMAILS: 'Secured emails',
                ADD_USER: 'User invitation',
                GHT: 'GHT',
                ONLINE_USERS: 'Online users',
                LABO_GROUPS: 'Labo groups',
                CRON: 'CRON Manager',
                ROLES: 'Roles',
                LOGS: 'Logs',
                EXTERNAL_ACTORS: 'External actors',
                EXTERNAL_CONFIGURATIONS: 'External configuration',
                STOCKOUT_CONFIG: 'Config Hospistock'
            }
        }
    }
};
