export class HvPatientShareModalComponent {
    constructor() {
        this.controller = 'HvPatientShareModalController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-share-modal.html').default;
        this.bindings = {
            patient: '<',
        };
    }
    ;
}
