import { EEstablishmentType, EMpStatus, EProject, ERoleSubscription } from "@mapuilabs/mpl-interfaces";
import { Utils } from "@main/services/Utils";
export class HvPatientTransferModalController {
    /** @ngInject */
    constructor(ModalService, HospitalService, ToastService, $q) {
        this.ModalService = ModalService;
        this.HospitalService = HospitalService;
        this.ToastService = ToastService;
        this.$q = $q;
        /**
         * Function which search all groups refer to the given value.
         * @param value the input search text.
         * @returns {angular.IPromise<Array<IHospital>>}
         */
        this.searchHospitals = () => {
            const defer = this.$q.defer();
            this.HospitalService.findByName(this.ctrl.searchText, EEstablishmentType.Hospital)
                .then(res => {
                defer.resolve(res);
            })
                .catch(err => defer.reject);
            return defer.promise;
        };
        /**
         *
         * @param item
         */
        this.isHospitalInList = (item) => {
            if (!item || !this.hvSubscribedHosps) {
                return false;
            }
            return !!this.hvSubscribedHosps.find((h) => Utils.compareIds(h, item));
        };
        /**
         * Function called when a group is selected.
         */
        this.selectHospital = (hospital) => {
            if (this.selectedHospital) {
                this.selectedHospital = null;
            }
            if (this.isHospitalInList(hospital)) {
                this.selectedHospital = hospital;
            }
        };
        /**
         * Close the modal with actions performing
         */
        this.valid = () => {
            this.ModalService.close(this.selectedHospital);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        this.ctrl = {
            selected: null,
            searchText: ""
        };
        this.HospitalService.findByProjectSubscription(EProject.Hospiville, ERoleSubscription.Subscribed).then((res) => {
            this.hvSubscribedHosps = res;
        }).catch((err) => {
            this.ToastService.show(EMpStatus.RejectByServer);
        });
    }
}
