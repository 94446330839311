export const locale = {
    lang: 'en',
    data: {
        MAIL_DETAILS: {
            NOT_SELECTED: `Select a message to read`,
            FROM: `From:`,
            TO: `To:`,
            ATTACHMENTS: {
                TITLE: `Attachments`,
                TOOLTIP: `All attachments can be found in Files tab.`
            }
        }
    }
};
