import { EActivityStatus } from '@mapuilabs/mpl-interfaces';
export const CActivityStatuses = {
    waiting: {
        value: EActivityStatus.Waiting,
        translate: 'activities.STATUSES.WAITING'
    },
    files: {
        value: EActivityStatus.Files,
        translate: 'activities.STATUSES.FILES'
    },
    addedToConciliation: {
        value: EActivityStatus.AddedToConciliation,
        translate: 'activities.STATUSES.ADDED_TO_CONCILIATION'
    },
    ended: {
        value: EActivityStatus.Ended,
        translate: 'activities.STATUSES.ENDED'
    },
    canceled: {
        value: EActivityStatus.Canceled,
        translate: 'activities.STATUSES.CANCELED'
    },
    mails: {
        value: EActivityStatus.Mail,
        translate: 'activities.STATUSES.MAILS'
    },
    medRec: {
        value: EActivityStatus.MedRec,
        translate: 'activities.STATUSES.MEDREC'
    },
    endedWithFiles: {
        value: EActivityStatus.EndedWithFiles,
        translate: 'activities.STATUSES.ENDED_WITH_FILES'
    }
};
