import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvReconciliationSheetComponent } from './hv-reconciliation-sheet.component';
import { HvReconciliationSheetController } from './hv-reconciliation-sheet.controller';
angular
    .module('hospiville.patient.reconciliation-sheet', [])
    .config(configReconciliationSheet)
    .component('hvReconciliationSheet', new HvReconciliationSheetComponent())
    .controller('HvReconciliationSheetController', HvReconciliationSheetController);
/** @ngInject */
function configReconciliationSheet($stateProvider) {
    $stateProvider.state('hospiville.reconciliation-sheet', {
        url: '/reconciliation-sheet',
        data: {
            access: EHospivillePermission.ReconciliationSheet_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-reconciliation-sheet></hv-reconciliation-sheet>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville reconciliation-sheets',
    });
}
