var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// @Angular
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UIRouterModule } from '@uirouter/angular';
// @Fuse
import { FuseCountdownModule, FuseSidebarModule, FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
// Librairies
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { NgScrollbarModule } from 'ngx-scrollbar';
// Material Modules
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
// MaPUI Modules
import { SmallListModule } from './components/mpx-small-list/mpx-small-list.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
// Components
import { RequestWidgetComponent } from '@shared/components/widgets/home/request-widget/request-widget.component';
import { HsStockoutWidgetComponent } from '@shared/components/widgets/home/stockout-widget/stockout-widget.component';
import { MpxButtonComponent } from './components/button/button.component';
import { ChangeHospitalComponent } from './components/change-hospital/change-hospital.component';
import { ChipComponent } from './components/chip/chip.component';
import { CompactEditMenuComponent } from './components/compact-edit-menu/compact-edit-menu.component';
import { CreateHospitalMemberComponent } from './components/create-hospital-member/create-hospital-member.component';
import { DataUsageComponent } from './components/data-usage/data-usage.component';
import { DatepickerLimitsComponent } from './components/dates/datepicker-limits/datepicker-limits.component';
import { DatesSelectorComponent } from './components/dates/dates-selector/dates-selector.component';
import { SinceDateMenuComponent } from './components/dates/since-date-menu/since-date-menu.component';
import { MpxDialogContentComponent } from './components/dialog-content/dialog-content.component';
import { DynamicDataContainerComponent } from './components/dynamic-data-container/dynamic-data-container.component';
import { EyeDropSelectionComponent } from './components/eye-drop-selection/eye-drop-selection.component';
import { FileDragNDropComponent } from './components/file-drag-n-drop/file-drag-n-drop.component';
import { DragNDropDirective } from './components/file-drag-n-drop/file-drag-n-drop.directive';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { ListHospitalsSidebarComponent } from './components/filters-sidebar/list-hospitals-sidebar.component';
import { ForbiddenPermissionViewComponent } from './components/forbidden-permission-view/forbidden-permission-view.component';
import { FormDrugDosagePosologyComponent } from './components/form-drug-dosage-posology/form-drug-dosage-posology.component';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { GenericMapComponent } from './components/generic-map/generic-map.component';
import { GenericTableComponent, GenericTableCustomTemplateDirective, GenericTableNoDataDirective } from './components/generic-table/generic-table.component';
import { GroupCellComponent } from './components/group-cell/group-cell.component';
import { MessageIconBoxComponent } from './components/message-icon-box/message-icon-box.component';
import { ModalGenericTableComponent } from './components/modals/modal-generic-table/modal-generic-table.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { PageMenuSidebarModule } from './components/page-menu-sidebar/page-menu-sidebar.module';
import { RegionSelectComponent } from './components/region-select/region-select.component';
import { ReleaseNoteWidgetComponent } from './components/release-note-widget/release-note-widget.component';
import { ResponsiveBtnComponent } from './components/responsive-btn/responsive-btn.component';
import { RolesCellComponent } from './components/roles-cell/roles-cell.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchDciComponent } from './components/search-dci/search-dci.component';
import { SearchEstablishmentFinessComponent } from './components/search-establishment-finess/search-establishment-finess.component';
import { SearchEstablishmentMemberComponent } from './components/search-establishment-member/search-establishment-member.component';
import { SearchGroupMemberComponent } from './components/search-group-member/search-group-member.component';
import { SearchHospitalMemberComponent } from './components/search-hospital-member/search-hospital-member.component';
import { SearchHospitalComponent } from './components/search-hospital/search-hospital.component';
import { SearchLaboratoryExpComponent } from './components/search-laboratory-exp/search-laboratory-exp.component';
import { MedicSearchCleanDciComponent } from './components/search-medic-clean-dci/search-medic-clean-dci.component';
import { SearchMedicComponent } from './components/search-medic/search-medic.component';
import { SearchMemberComponent } from './components/search-member/search-member.component';
import { SelectDosageTypeComponent } from './components/select-dosage-type/select-dosage-type.component';
import { SelectPosologyComponent } from './components/select-posology/select-posology.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SourceInstancesListComponent } from './components/source-instances-list/source-instances-list.component';
import { SpreadsheetImportModalComponent } from './components/spreadsheet-import-modal/spreadsheet-import-modal.component';
import { SpreadsheetImportReportListComponent } from './components/spreadsheet-import-modal/spreadsheet-import-report-list/spreadsheet-import-report-list.component';
import { StatesMenuComponent } from './components/states-menu/states-menu.component';
import { WidgetHomeComponent } from './components/widgets/dashboard/widget-home/widget-home.component';
import { GraphWidgetComponent } from './components/widgets/graphs/graph-widget/graph-widget.component';
import { LineAreaChartComponent } from './components/widgets/graphs/line-area-chart/line-area-chart.component';
import { StackedAreaChartComponent } from './components/widgets/graphs/stacked-area-chart/stacked-area-chart.component';
import { AvailableWidgetComponent } from './components/widgets/home/available-widget/available-widget.component';
import { LoanOrBorrowWidgetComponent } from './components/widgets/home/loan-or-borrow-widget/loan-or-borrow-widget.component';
import { TwitterWidgetComponent } from './components/widgets/home/twitter-widget/twitter-widget.component';
import { KeyDataWidgetComponent } from './components/widgets/key-data/key-data.component';
// Directives
import { DynamicDataContainerContentDirective } from './components/dynamic-data-container/dynamic-data-container-content.directive';
import { MpxButtonsContainerDirective } from './directives/buttons-container/buttons-container.directive';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { UpperCaseInputDirective } from './directives/to-upper-case.directive';
// Pipes
import { SearchCladimedComponent } from '@shared/components/search-cladimed/search-cladimed.component';
import { SearchIndustrialComponent } from '@shared/components/search-industrial/search-industrial.component';
import { BytesPipe } from './pipes/bytes/bytes.pipe';
let SharedModule = class SharedModule {
};
SharedModule = __decorate([
    NgModule({
        imports: [
            // @Angular
            CommonModule,
            FormsModule,
            RouterModule.forRoot([]),
            UIRouterModule,
            // @Fuse
            FuseCountdownModule,
            FuseSharedModule,
            FuseSidebarModule,
            FuseWidgetModule,
            // Librairies
            InfiniteScrollModule,
            LeafletModule,
            NgxChartsModule,
            QuillModule.forRoot(),
            NgScrollbarModule,
            // Material Modules
            MatAutocompleteModule,
            MatButtonModule,
            MatCheckboxModule,
            MatDatepickerModule,
            MatDialogModule,
            MatDividerModule,
            MatDividerModule,
            MatExpansionModule,
            MatFormFieldModule,
            MatIconModule,
            MatInputModule,
            MatListModule,
            MatMenuModule,
            MatProgressBarModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatSlideToggleModule,
            MatSortModule,
            MatTableModule,
            MatTooltipModule,
            MatRadioModule,
            // MaPUI Modules
            PageHeaderModule,
            PageMenuSidebarModule,
            SmallListModule
        ],
        declarations: [
            // Components
            AvailableWidgetComponent,
            BytesPipe,
            ChangeHospitalComponent,
            ChipComponent,
            CompactEditMenuComponent,
            CreateHospitalMemberComponent,
            DataUsageComponent,
            DatepickerLimitsComponent,
            DatesSelectorComponent,
            DynamicDataContainerComponent,
            EyeDropSelectionComponent,
            FileDragNDropComponent,
            FileUploaderComponent,
            FileViewerComponent,
            ForbiddenPermissionViewComponent,
            FormDrugDosagePosologyComponent,
            GenericDialogComponent,
            GenericMapComponent,
            GenericTableComponent,
            GraphWidgetComponent,
            GroupCellComponent,
            HsStockoutWidgetComponent,
            KeyDataWidgetComponent,
            LineAreaChartComponent,
            ListHospitalsSidebarComponent,
            LoanOrBorrowWidgetComponent,
            MedicSearchCleanDciComponent,
            MessageIconBoxComponent,
            ModalGenericTableComponent,
            MpxButtonComponent,
            MpxDialogContentComponent,
            PageLayoutComponent,
            RegionSelectComponent,
            ReleaseNoteWidgetComponent,
            RequestWidgetComponent,
            ResponsiveBtnComponent,
            RolesCellComponent,
            SearchBarComponent,
            SearchCladimedComponent,
            SearchDciComponent,
            SearchEstablishmentFinessComponent,
            SearchEstablishmentMemberComponent,
            SearchGroupMemberComponent,
            SearchHospitalComponent,
            SearchHospitalMemberComponent,
            SearchIndustrialComponent,
            SearchLaboratoryExpComponent,
            SearchMedicComponent,
            SearchMemberComponent,
            SelectDosageTypeComponent,
            SelectPosologyComponent,
            SidebarComponent,
            SinceDateMenuComponent,
            SourceInstancesListComponent,
            SpreadsheetImportModalComponent,
            SpreadsheetImportReportListComponent,
            StackedAreaChartComponent,
            StatesMenuComponent,
            TwitterWidgetComponent,
            WidgetHomeComponent,
            // Directives
            ClickOutsideDirective,
            DragNDropDirective,
            DynamicDataContainerContentDirective,
            GenericTableCustomTemplateDirective,
            GenericTableNoDataDirective,
            MpxButtonsContainerDirective,
            UpperCaseInputDirective
        ],
        exports: [
            // MaPUI Modules
            PageHeaderModule,
            PageMenuSidebarModule,
            SmallListModule,
            // Components
            AvailableWidgetComponent,
            BytesPipe,
            ChangeHospitalComponent,
            ChipComponent,
            CompactEditMenuComponent,
            CreateHospitalMemberComponent,
            DataUsageComponent,
            DatepickerLimitsComponent,
            DatesSelectorComponent,
            DynamicDataContainerComponent,
            EyeDropSelectionComponent,
            FileDragNDropComponent,
            FileUploaderComponent,
            FileViewerComponent,
            ForbiddenPermissionViewComponent,
            FormDrugDosagePosologyComponent,
            GenericDialogComponent,
            GenericMapComponent,
            GenericTableComponent,
            GraphWidgetComponent,
            GroupCellComponent,
            HsStockoutWidgetComponent,
            KeyDataWidgetComponent,
            LineAreaChartComponent,
            ListHospitalsSidebarComponent,
            MedicSearchCleanDciComponent,
            MessageIconBoxComponent,
            ModalGenericTableComponent,
            MpxButtonComponent,
            MpxDialogContentComponent,
            PageLayoutComponent,
            RegionSelectComponent,
            ReleaseNoteWidgetComponent,
            RequestWidgetComponent,
            ResponsiveBtnComponent,
            RolesCellComponent,
            SearchBarComponent,
            SearchCladimedComponent,
            SearchDciComponent,
            SearchEstablishmentFinessComponent,
            SearchEstablishmentMemberComponent,
            SearchGroupMemberComponent,
            SearchHospitalComponent,
            SearchHospitalMemberComponent,
            SearchIndustrialComponent,
            SearchLaboratoryExpComponent,
            SearchMedicComponent,
            SearchMemberComponent,
            SelectDosageTypeComponent,
            SelectPosologyComponent,
            SidebarComponent,
            SinceDateMenuComponent,
            SourceInstancesListComponent,
            SpreadsheetImportModalComponent,
            StatesMenuComponent,
            TwitterWidgetComponent,
            WidgetHomeComponent,
            // Directives
            ClickOutsideDirective,
            DynamicDataContainerContentDirective,
            GenericTableCustomTemplateDirective,
            GenericTableNoDataDirective,
            MpxButtonsContainerDirective,
            UpperCaseInputDirective
        ],
        providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 300, hideDelay: 200 } }, DatePipe]
    })
], SharedModule);
export { SharedModule };
