var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormBuilder } from '@angular/forms';
import { ExternalActorsService } from '../../../services/external-actor/external-actors.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExternalActor } from '@mapuilabs/mpl-interfaces';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StateService } from '@uirouter/angular';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { ExternalConfigurationService } from '../../../services/external-configuration/external-configuration.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslationService } from '@app/services/translation/translation.service';
let ExternalActorDetailComponent = class ExternalActorDetailComponent {
    constructor(_formBuilder, _externalActorsService, _externalConfigService, _translationService, _fuseTranslationLoaderService, _snackBar, _location, _stateService, _snackBarService) {
        this._formBuilder = _formBuilder;
        this._externalActorsService = _externalActorsService;
        this._externalConfigService = _externalConfigService;
        this._translationService = _translationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackBar = _snackBar;
        this._location = _location;
        this._stateService = _stateService;
        this._snackBarService = _snackBarService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        if (this._stateService.params.id == 'new') {
            this.pageType = 'new';
        }
        else {
            this.pageType = 'edit';
        }
        this.pageHeaderConfig = {
            title: this.pageType == 'new'
                ? this._translate('externalActorDetail.NEW_ACTOR')
                : this._translate('externalActorDetail.EDIT_TITLE', { actor: this.actor.name }),
            icon: 'account_balance'
        };
        this.actorForm = this.createActorForm();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    saveActor() {
        const data = this.actorForm.getRawValue();
        this._externalActorsService.save(data).subscribe(() => {
            this.actorForm = this.createActorForm();
            this._snackBarService.open("L'acteur a été modifié !");
        }, (err) => {
            this._snackBarService.openError(err);
        });
    }
    addActor() {
        const data = this.actorForm.getRawValue();
        this._externalActorsService.add(data).subscribe((actor) => {
            this.actor = new ExternalActor(actor);
            this.actorForm = this.createActorForm();
            this.pageType = 'edit';
            this._snackBarService.open("L'acteur a été créer !");
            this._location.replaceState('adm/externalactor/detail/' + this.actor._id);
        }, this._snackBarService.openError);
    }
    deleteConfig(config) {
        this._externalActorsService.deleteConfig(this.actor, config).subscribe((ans) => {
            this.actor = ans;
            this._snackBarService.open('La configuration a été supprimé');
        }, (err) => {
            this._snackBarService.openError(err);
        });
    }
    createActorForm() {
        return this._formBuilder.group({
            _id: [this.actor._id],
            name: [this.actor.name],
            type: [this.actor.type]
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_k = typeof ExternalActor !== "undefined" && ExternalActor) === "function" ? _k : Object)
], ExternalActorDetailComponent.prototype, "actor", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ExternalActorDetailComponent.prototype, "action", void 0);
ExternalActorDetailComponent = __decorate([
    Component({
        selector: 'mpx-external-actor-detail',
        template: require('./external-actor-detail.component.html').default,
        styles: [require('./external-actor-detail.component.scss')],
        animations: fuseAnimations,
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _a : Object, typeof (_b = typeof ExternalActorsService !== "undefined" && ExternalActorsService) === "function" ? _b : Object, typeof (_c = typeof ExternalConfigurationService !== "undefined" && ExternalConfigurationService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _e : Object, typeof (_f = typeof MatSnackBar !== "undefined" && MatSnackBar) === "function" ? _f : Object, typeof (_g = typeof Location !== "undefined" && Location) === "function" ? _g : Object, typeof (_h = typeof StateService !== "undefined" && StateService) === "function" ? _h : Object, typeof (_j = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _j : Object])
], ExternalActorDetailComponent);
export { ExternalActorDetailComponent };
