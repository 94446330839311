var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { locale as french } from '../../../i18n/fr';
import { locale as english } from '../../../i18n/en';
import { EFilterType } from '@mapuilabs/mpl-interfaces';
import { UrlService } from '@services/url/url.service';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
import { TranslationService } from '@services/translation/translation.service';
let AvailableHospitalSidebarComponent = class AvailableHospitalSidebarComponent {
    constructor(_memberService, _translationService, _fuseTranslationLoaderService, _fuseSidebarService, _urlService) {
        this._memberService = _memberService;
        this._translationService = _translationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseSidebarService = _fuseSidebarService;
        this._urlService = _urlService;
        this.filtersComparison = {
            ['AVAILABLE.SIDEBAR.END_DATE.EXPIRED']: {
                type: EFilterType.COMPARISON,
                attribute: 'endDate',
                comparator: '$lt',
                value: new Date(),
                valueType: 'date'
            }
            /*['AVAILABLE.SIDEBAR.END_DATE.EXPIRED_SOON']: <IFilterRange>{
                attribute: 'endDate',
                start: {{startDate}},
                end: this.today
            }*/
        };
        /**
         * Set default filters when user reaches /admin/member/list.
         * @private
         */
        this._setDefaultFilters = () => {
            this.applyFilters = [
                {
                    attribute: 'status',
                    type: EFilterType.VALUES,
                    inArray: true,
                    values: ['1', '2'],
                    valueType: 'number'
                }
            ];
            this.onApplyFilters(true);
        };
        /**
         * Returns if the checkbox should be checked.
         * @param checkboxName: String to find in the filters array (this.applyFilters).
         * @param value: Value to look for in the values array of a IFilterValues.
         */
        this.isChecked = (checkboxName, value) => {
            if (this.applyFilters) {
                const index = this.applyFilters.findIndex((filter) => filter.attribute === checkboxName);
                if (index > -1) {
                    switch (this.applyFilters[index].type) {
                        case EFilterType.VALUE:
                        case EFilterType.COMPARISON:
                            return true;
                        case EFilterType.VALUES:
                        case EFilterType.TYPE:
                            if (value !== undefined) {
                                // value can be null
                                return this.applyFilters[index].values.includes(value);
                            }
                    }
                }
            }
            return false;
        };
        /**
         * Saves or removes a IFilterValue from the filters array, which attribute is
         * given in MatCheckbox (aka MatCheckboxChange.source) name.
         * @param checkboxChange: Event on the checkboxes used for unique value filters.
         * @param filter
         */
        this.onFilterComparisonChecked = (checkboxChange, filter) => {
            const isChecked = checkboxChange.checked;
            if (!this.applyFilters) {
                this.applyFilters = [];
            }
            if (isChecked) {
                this.applyFilters.push(filter);
            }
            else {
                const foundFilterIndex = this.applyFilters.findIndex((f) => {
                    if (f.type === EFilterType.COMPARISON) {
                        return (f.attribute === filter.attribute &&
                            f.comparator === filter.comparator &&
                            f.value === filter.value);
                    }
                    return false;
                });
                if (foundFilterIndex > -1) {
                    this.applyFilters.splice(foundFilterIndex, 1);
                }
            }
        };
        /**
         * Saves or removes a IFilterValues from the filters array, which attribute is
         * given in MatCheckbox (aka MatCheckboxChange.source) name.
         * @param checkboxChange: Event on the checkboxes used for unique value filters.
         */
        this.onFilterValuesChecked = (checkboxChange) => {
            const checkboxName = checkboxChange.source.name.toString();
            const checkboxValue = checkboxChange.source.value;
            const isChecked = checkboxChange.checked;
            if (!this.applyFilters) {
                this.applyFilters = [];
            }
            const filterIndex = this.applyFilters.findIndex((filter) => filter.attribute === checkboxName);
            if (isChecked) {
                if (filterIndex > -1) {
                    // Filter already exists: adds the value to the IFilterValues.values array
                    this.applyFilters[filterIndex].values.push(checkboxValue);
                }
                else {
                    // Create a IFilterValues to add in the filter array
                    const applyFilter = {
                        type: EFilterType.VALUES,
                        attribute: checkboxName,
                        inArray: true,
                        values: [checkboxValue],
                        valueType: 'number'
                    };
                    this.applyFilters.push(applyFilter);
                }
            }
            else {
                if (filterIndex > -1) {
                    // Double checking filter existence
                    const filterValues = this.applyFilters[filterIndex].values;
                    if (filterValues.length > 1) {
                        // Remove value from the array
                        filterValues.splice(filterValues.findIndex((value) => value === checkboxValue), 1);
                    }
                    else {
                        // If only one value, remove the whole filter from applyFilters array
                        this.applyFilters.splice(filterIndex, 1);
                    }
                }
                else {
                    throw Error(`Filter ${checkboxName} missing. Could not remove it from the list of filter to apply.`);
                }
            }
        };
        /**
         * Sends the filters to the list view so that it can update.
         */
        this.onApplyFilters = (replaceHistory = false) => {
            this.filtersApplied.emit({
                filters: this.applyFilters,
                replaceHistory: replaceHistory
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.filtersApplied = new EventEmitter();
    }
    ngAfterViewInit() {
        this._setDefaultFilters();
    }
    resetFilters() {
        this.filtersApplied.emit({
            filters: [],
            replaceHistory: true
        });
        // Self close sidebar
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
    }
};
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], AvailableHospitalSidebarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Array !== "undefined" && Array) === "function" ? _g : Object)
], AvailableHospitalSidebarComponent.prototype, "applyFilters", void 0);
AvailableHospitalSidebarComponent = __decorate([
    Component({
        selector: 'mpx-available-hosp-filter-sidebar',
        template: require('./filter-sidebar.component.html').default,
        styles: [require('./filter-sidebar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _d : Object, typeof (_e = typeof UrlService !== "undefined" && UrlService) === "function" ? _e : Object])
], AvailableHospitalSidebarComponent);
export { AvailableHospitalSidebarComponent };
