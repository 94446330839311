var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f;
import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoanService } from '@app/services/loan/loan.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { ELoanCat, EVoucherEmergency, EVoucherType, EVoucherVolume, EVoucherWeight } from '@mapuilabs/mpl-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Access } from '@main/services/auth/access.service';
import { locale as englishCommon } from '../../../../common/i18n/en';
import { locale as frenchCommon } from '../../../../common/i18n/fr';
let GenerateBillingComponent = class GenerateBillingComponent {
    constructor(dialogRef, data, _fuseTranslationLoaderService, _access, _fb, _loanService, _translateService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._access = _access;
        this._fb = _fb;
        this._loanService = _loanService;
        this._translateService = _translateService;
        this.eVoucherEmergency = EVoucherEmergency;
        this.eVoucherVolume = EVoucherVolume;
        this.eVoucherWeight = EVoucherWeight;
        /**
         * Suggested [[Iloan]]s which could be add to the voucher
         */
        this.suggestedLoans = [];
        this.transport = {
            emergency: undefined,
            service: undefined,
            nbrPackage: undefined,
            volume: undefined,
            weight: undefined
        };
        /**
         * Final [[ILoan]] list to add in the voucher
         */
        this.voucherList = [];
        /**
         * Final comment to add in the voucher
         */
        this.pdfComment = '';
        /**
         * Final transport data to add in the voucher
         */
        this.transportData = {
            emergency: undefined,
            service: undefined,
            nbrPackage: undefined,
            volume: undefined,
            weight: undefined
        };
        this._fuseTranslationLoaderService.loadTranslations(frenchCommon, englishCommon);
    }
    ngOnInit() {
        this.loan = this.data.loan;
        this.voucherType = this.data.voucherType;
        this.transport = {
            emergency: null,
            service: '',
            nbrPackage: null,
            volume: null,
            weight: null
        };
        this._createVoucherListAndGetSuggestedLoans();
        this.form = this._fb.group({
            suggestedLoans: this.suggestedLoans,
            emergency: this.transport.emergency,
            nbrPackage: this.transport.nbrPackage,
            service: this.transport.service,
            volume: this.transport.volume,
            weight: this.transport.weight,
            comment: this.pdfComment
        });
    }
    submitForm() {
        var _a;
        if (this.form.invalid) {
            return;
        }
        this.transport.service = this.form.controls['service'].value;
        this.transport.emergency = this.form.controls['emergency'].value;
        this.transport.nbrPackage = this.form.controls['nbrPackage'].value;
        this.transport.volume = this.form.controls['volume'].value;
        this.transport.weight = this.form.controls['weight'].value;
        this.pdfComment = this.form.controls['comment'].value;
        this.suggestedLoans = this.form.controls['suggestedLoans'].value || [];
        if (((_a = this.suggestedLoans) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            this.voucherList.push(...this.suggestedLoans.map((loan) => {
                return loan._id;
            }));
        }
        this.dialogRef.close();
    }
    _createVoucherListAndGetSuggestedLoans() {
        if (Utils.hasId(this.loan)) {
            this.voucherList.push(Utils.getId(this.loan));
        }
        if (this.voucherType === EVoucherType.Loan) {
            if (this.loan.loanCategoryCreation === ELoanCat.LoanOrBorrow) {
                this._loanService.getLoans(this._access.hospital._id).subscribe({
                    next: (loans) => {
                        if (loans) {
                            this._isLent(loans);
                            this.filterSuggestionsLoans();
                        }
                    },
                    error: (error) => {
                        console.error(error);
                    }
                });
            }
            else if (this.loan.loanCategoryCreation === ELoanCat.Prep) {
                this._loanService.getPreparationsFrom(this._access.hospital._id).subscribe({
                    next: (loans) => {
                        if (loans) {
                            this._isLent(loans);
                            this.filterSuggestionsLoans();
                        }
                    },
                    error: (error) => {
                        console.error(error);
                    }
                });
            }
        }
        else if (this.voucherType === EVoucherType.Borrow) {
            if (this.loan.loanCategoryCreation === ELoanCat.LoanOrBorrow) {
                this._loanService.getBorrowings(this._access.hospital._id).subscribe({
                    next: (loans) => {
                        if (loans) {
                            this._isBorrowed(loans);
                            this.filterSuggestionsLoans();
                        }
                    },
                    error: (error) => {
                        console.error(error);
                    }
                });
            }
            else if (this.loan.loanCategoryCreation === ELoanCat.Prep) {
                this._loanService.getPreparationsFrom(this._access.hospital._id).subscribe({
                    next: (loans) => {
                        if (loans) {
                            this._isBorrowed(loans);
                            this.filterSuggestionsLoans();
                        }
                    },
                    error: (error) => {
                        console.error(error);
                    }
                });
            }
        }
    }
    /**
     * Get emergency
     * @returns {number}
     */
    getEmergency(type) {
        return EVoucherEmergency[type];
    }
    /**
     * Filter a list of [[ILoan]] for loan voucher
     * @param {Array<ILoan>} loans
     * @returns {Array<ILoan>}
     * @private
     */
    _isLent(loans) {
        if (!loans) {
            return;
        }
        for (const l of loans) {
            if (Utils.getId(l.lender) === this._access.hospital._id &&
                Utils.getId(l.borrower) === Utils.getId(this.loan.borrower)) {
                this.suggestedLoans.push(l);
            }
        }
    }
    /**
     * Filter a list of [[ILoan]] for borrowings voucher
     * @param {Array<ILoan>} loans
     * @returns {Array<ILoan>}
     * @private
     */
    _isBorrowed(loans) {
        if (!loans) {
            return;
        }
        for (const l of loans) {
            if (Utils.getId(l.lender) === Utils.getId(this.loan.lender) &&
                Utils.getId(l.borrower) === this._access.hospital._id) {
                this.suggestedLoans.push(l);
            }
        }
    }
    /**
     * Filter [[ILoan]]s and keep all except this loan
     */
    filterSuggestionsLoans() {
        const filtered = this.suggestedLoans.filter((item) => {
            if (this.loan._id !== item._id) {
                return true;
            }
        });
        this.suggestedLoans = filtered;
    }
    /**
     * Get the current selected language
     * @returns {string} the current language
     */
    _getLang() {
        return this._translateService.getDefaultLang();
    }
    _generatePdfLink() {
        if (this.voucherType === EVoucherType.Loan) {
            return `/pdf-generation/bon-de-pret.pdf?pdfmodel=voucher&loans=${this.voucherList}&type=loan&lang=${this._getLang()}&pdfComment=${encodeURI(this.pdfComment)}&transport=${encodeURI(JSON.stringify(this.transport))}`;
        }
        else if (this.voucherType === EVoucherType.Borrow) {
            return `/pdf-generation/bon-d-emprunt.pdf?pdfmodel=voucher&loans=${this.voucherList}&type=borrowing&lang=${this._getLang()}&pdfComment=${encodeURI(this.pdfComment)}&transport=${encodeURI(JSON.stringify(this.transport))}`;
        }
        return '';
    }
};
GenerateBillingComponent = __decorate([
    Component({
        selector: 'mpx-billing-generate',
        template: require('./billing-generate.component.html').default
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _a : Object, Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof Access !== "undefined" && Access) === "function" ? _c : Object, typeof (_d = typeof FormBuilder !== "undefined" && FormBuilder) === "function" ? _d : Object, typeof (_e = typeof LoanService !== "undefined" && LoanService) === "function" ? _e : Object, typeof (_f = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _f : Object])
], GenerateBillingComponent);
export { GenerateBillingComponent };
