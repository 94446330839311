var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { EstablishmentService } from '@services/establishment/establishment.service';
let SearchEstablishmentFinessComponent = class SearchEstablishmentFinessComponent {
    constructor(_establishmentService, _fuseTranslationLoaderService) {
        this._establishmentService = _establishmentService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.onSelected = new EventEmitter();
        // establishments observables
        this.establishments = null;
        this._search = (value) => {
            if (value != '') {
                return this._establishmentService.findByNameAndType(value, this.types);
            }
            else {
                return of(null);
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this.establishments = this.parentFormControl.valueChanges.pipe(startWith(''), debounceTime(500), switchMap(this._search));
        if (!this.types) {
            this.types = [EEstablishmentType.Hospital, EEstablishmentType.EHPAD];
        }
    }
    inputChanged() {
        this.onSelected.emit(null);
    }
    optionSelected(selected) {
        this.onSelected.emit(selected);
    }
    displayFn(establishment) {
        return establishment && establishment.name ? establishment.name : undefined;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _c : Object)
], SearchEstablishmentFinessComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], SearchEstablishmentFinessComponent.prototype, "types", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchEstablishmentFinessComponent.prototype, "onSelected", void 0);
SearchEstablishmentFinessComponent = __decorate([
    Component({
        selector: 'search-establishment-finess',
        template: require('./search-establishment-finess.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof EstablishmentService !== "undefined" && EstablishmentService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchEstablishmentFinessComponent);
export { SearchEstablishmentFinessComponent };
