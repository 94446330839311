import { EHospivillePermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
export class SourceService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get a specific [[ISource]]
         * @param id The [[ISource]] [[Id]]
         * @returns A promise containing the [[ISource]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.sources.get({ id: id }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get all the [[ISource]]
         * @returns A promise containing an array of [[ISource]]
         */
        this.getAll = () => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.sources.all({}, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add a new [[ISource]] to the dataBase
         * @param source The [[ISource]] to add
         * @returns A promise containing the newly created [[ISource]]
         */
        this.add = (source) => {
            const defer = this.$q.defer();
            if (!source) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.sources.add(source, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update a [[ISource]]
         * @param source The [[ISource]] to update
         * @returns A promise containing the newly updated [[ISource]]
         */
        this.save = (source) => {
            const defer = this.$q.defer();
            if (!source) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.sources.save(source, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Check if the current [[IPatient]] is a reference to the current [[ISource]]
         * @returns {boolean}
         * @private
         */
        this.isReference = (references) => {
            if (!references)
                return false;
            return references.some((ref) => {
                if (!ref || !ref._id || !this.Access.patient)
                    return false;
                return ref._id === this.Access.patient._id;
            });
        };
    }
}
