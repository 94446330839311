export const locale = {
    lang: 'en',
    data: {
        PREPARATION: {
            TITLE: 'Preparations',
            SEARCH: 'Search a preparation',
            TABLE: {
                NAME: 'Name',
                DOSAGE: 'Dosage',
                PREP_ADMIN: 'Route of administration',
                GALENIC_FORM: 'Galenic form',
                ACTIONS: 'Actions'
            },
            MODALS: {
                COMPLETE: {
                    TITLE: {
                        ADD: 'Add preparation',
                        EDIT: 'Edit preparation {{ name }}'
                    },
                    FORM: {
                        NAME: 'Name',
                        DOSAGE: 'Dosage',
                        TYPE: 'Type',
                        GALENIC_FORM: 'Galenic form',
                        VOLUME: 'Volume',
                        CONSERVATION_COND: {
                            BIG: 'Conservation conditions',
                            SMALL: 'Conservation'
                        },
                        EXCIPIENTS: 'Excipients',
                        PREP_ADMINISTRATION: 'Route of administration',
                        UNIT_PRICE: 'Unit price (indicative)'
                    }
                },
                DELETE: {
                    TITLE: 'Delete a preparation',
                    MESSAGE: 'Do you really want to delete the preparation {{ name }}?'
                }
            },
            NOTIFS: {
                ADD: 'Preparation added',
                EDIT: 'Preparation edited',
                DELETE: 'Preparation deleted'
            }
        }
    }
};
