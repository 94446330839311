import { EDrugBalanceType, ETransit, } from '@mapuilabs/mpl-interfaces';
export class InformationService {
    /** @ngInject */
    constructor($translate, Api, Access, ModalService, _) {
        this.$translate = $translate;
        this.Api = Api;
        this.Access = Access;
        this.ModalService = ModalService;
        this._ = _;
        /**
         * get message when no source referred on [[IDrugBalance]] sheet
         * @param instances
         * @param internLink
         * @returns {string}
         */
        this.getMissingSourceRef = (instances, internLink) => {
            if (instances) {
                for (const instance of instances) {
                    if (!instance.validSource) {
                        return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NOT_REFER_SOURCE')
                            + (internLink ? ' <a href="/' + internLink + '">'
                                + this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.WORDS.HERE') + '</a>.' : '.');
                    }
                }
            }
            return '';
        };
        /**
         * Get message when Allergies conflict are found
         * @param allergies
         * @param drugBalance
         * @returns {string}
         */
        this.getAllergiesConflict = (allergies, drugBalance) => {
            if (drugBalance && drugBalance.instances && allergies) {
                const type = (EDrugBalanceType.DrugBalance === drugBalance.type) ?
                    (this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.WORDS.M_THE') + ' ' + this.$translate.instant('HOSPIVILLE.GENERAL.DBS'))
                    : (this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.WORDS.F_THE') + ' ' + this.$translate.instant('HOSPIVILLE.GENERAL.PRESCRIPTION')
                        + ' ' + (drugBalance.transit ? this.$translate.instant(this._.find(this._constTypes.transit, { value: drugBalance.transit }).translate) : ''));
                let header = this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.ALLERGIES_CONFLICT', { type: type });
                let message = '';
                for (const allergy of allergies) {
                    let same = this._.find(drugBalance.instances, (inst) => {
                        if (!inst.codeATC)
                            return false;
                        return inst.codeATC && allergy.codeATC ? inst.codeATC.substring(0, 4) === allergy.codeATC.substring(0, 4) : false;
                    });
                    if (same) {
                        message += allergy.denomination + ' - ' + same.denomination + '<br>';
                    }
                }
                return message !== '' ? header + message : '';
            }
            return '';
        };
        /**
         * Get message when [[IHealthProfessional]] doctor and/or [[Staff]] pharmacist are not referred on conciliation sheet
         * @param doctor
         * @param member
         * @returns {string}
         */
        this.getMissingSheetElements = (doctor, member, prescriptionId) => {
            let header = this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NOT_REFER_SHEET.TITLE');
            let message = '';
            if (!doctor) {
                message += '<br>' + this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NOT_REFER_SHEET.DOCTOR', { id: prescriptionId });
            }
            if (!member) {
                message += '<br>' + this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NOT_REFER_SHEET.PHARMACIST');
            }
            return message !== '' ? header + message + "." : '';
        };
        /**
         * Get message when no current [[IPatient]] or no [[IMedicationReconciliation]]
         * @returns {string}
         */
        this.noPatientOrConciliation = () => {
            if (!this.Access.patient || !this.Access.patient._id) {
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NO_PATIENT');
            }
            if (!this.Access.patient.medicationReconciliation._id) {
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NO_CONCILIATION');
            }
            return '';
        };
        this.noDrugBalance = (medRec) => {
            if (!medRec || !medRec.dbs) {
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NO_DBS');
            }
            return '';
        };
        this.noPatientOrConciliationCarePlan = (medicationReconciliation) => {
            if (!this.Access.patient || !this.Access.patient._id) {
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NO_PATIENT');
            }
            if (!this.Access.patient.medicationReconciliation._id) {
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NO_CONCILIATION');
            }
            if (!medicationReconciliation)
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.UNACTIVE_CONCILIATION');
            if (!medicationReconciliation.prescription)
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.NO_PRESCRIPTION');
            if (medicationReconciliation.prescription.transit == undefined || medicationReconciliation.prescription.transit == ETransit.Entrance)
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.WRONG_TRANSIT');
            return '';
        };
        /**
         * Open source information for drug balance sheet
         */
        this.getInfoSource = () => {
            this.ModalService.show({
                component: 'hv-modal-information',
                bindings: {
                    text: this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.DBS.SOURCES'),
                },
            });
        };
        this.missingValidation = (medRec) => {
            if (medRec && (!medRec.validator || (medRec.dbs && !medRec.dbs.validator))) {
                return this.$translate.instant('HOSPIVILLE.SERVICES.INFORMATION.MISSING_VALIDATION');
            }
            return '';
        };
        this.Api.hvConstType.get((ans) => {
            this._constTypes = ans;
        });
    }
}
