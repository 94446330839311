export const locale = {
    lang: 'en',
    data: {
        admMemberDetails: {
            EDIT_TITLE: 'User',
            NEW_TITLE: 'New user',
            TABS: {
                ACTIONS: 'Information / Actions',
                IDENTITY: 'Identity',
                ROLES: 'Roles'
            },
            ACTIONS: {
                INFORMATION: {
                    TITLE: 'Information',
                    HOSPITAL: 'Hospital',
                    NO_HOSPITAL: 'No establishment',
                    CREATION_DATE: 'Creation date',
                    CGU: 'GCU',
                    LAST_CONNECTION: 'Last connection',
                    BROWSER_OS: 'Web browser / OS',
                    LOCKED: 'Locked',
                    BLOCKED: 'Blocked (locked by admin)',
                    VALID_MAIL: 'Temporary password',
                    DELETED: 'Deleted account'
                },
                BUTTONS: {
                    SELECT_HOSPITAL: 'Select hospital',
                    CHANGE_HOSPITAL: 'Change hospital',
                    SAVE_TOOLTIP: 'Please save the user before carrying out this action',
                    ACTIVATE: 'Activate account',
                    DEACTIVATE: 'Deactivate account',
                    UNBLOCK: 'Unblock account',
                    WELCOME_MAIL: 'Send welcome mail',
                    INVITE_MAIL: 'Send invitation mail',
                    REMOVE_DOUBLE_AUTH: 'Remove two factor authentication',
                    DELETE_ACCOUNT: 'Delete account',
                    RESURRECT_ACCOUNT: 'Resurrect account',
                    EMAIL_CONFIRM_DIALOG: {
                        SEND_WELCOME_MAIL: {
                            TITLE: 'Welcome mail',
                            MESSAGE: 'Do you want to send a welcome mail to {{member}}?',
                            ALREADY_SENT_MESSAGE: 'A welcome mail has already been send on {{date}} by {{member}}. Do you still want to proceed?'
                        },
                        SEND_INVITE_MAIL: {
                            TITLE: 'Invitation mail',
                            MESSAGE: 'Do you want to send an invitation mail to {{member}}?',
                            ALREADY_SENT_MESSAGE: 'An invitation mail has already been send on {{date}} by {{member}}. Do you still want to proceed?'
                        },
                        ALREADY_SENT_TITLE: 'Previously sent mail'
                    },
                    EMAIL_SENT: 'Mail has been sent'
                }
            },
            IDENTITY: {
                EMAIL: 'Email',
                USERNAME: 'Login',
                FIRST_NAME: 'First name',
                FAMILY_NAME: 'Last name',
                GENDER: 'Gender',
                PHONE: 'Phone',
                RPPS: 'RPPS number'
            },
            ROLES: {
                ROLE_MAPUI: 'MaPUI role',
                ROLE_HOSPIVILLE: 'Hospiville role',
                ROLE_HOSPISTOCK: 'Hospistock role',
                ROLE_WORKSPACE: 'Workspace role',
                IS_ADMIN: 'Administrator',
                ROLE_ADMIN_PANEL: 'Administrator role'
            },
            MESSAGES: {
                SNACKBAR: {
                    EDIT_OK: 'User has been edited!',
                    CREATE_OK: 'User has been created!',
                    CHANGE_HOSPITAL_OK: "The user's hospital has been changed !",
                    ACTIVATE_OK: 'Account is now activated!',
                    DEACTIVATE_OK: 'Account is now deactivated!',
                    UNBLOCK_OK: 'Account is now unlocked!',
                    REMOVE_DOUBLE_AUTH_OK: 'Two factor authentication has been removed!',
                    DELETE_ACCOUNT_OK: 'User has been deleted!',
                    RESURRECT_OK: 'User has been resurrected'
                },
                ALERT: {
                    UNBLOCK: {
                        TITLE: 'Unblock account',
                        MESSAGE: 'Are you sure you want to unblock this account?'
                    },
                    DISABLE_2FA: {
                        TITLE: 'Remove two factor authentication',
                        MESSAGE: 'Do you really want to remove two factor authentication for this user?'
                    },
                    DELETE: {
                        TITLE: 'Confirm deletion',
                        MESSAGE: 'Do you really want to delete the user?'
                    },
                    RESURRECT: {
                        TITLE: 'Confirm la resurrection',
                        MESSAGE: 'Do you really want to resurrect the user ?'
                    }
                }
            }
        }
    }
};
