import { GroupService } from './groups.service';
import { MpShareComponent } from './mp-share/mp-share.component';
import { MpShareController } from './mp-share/mp-share.controller';
import * as angular from 'angular';
angular
    .module('mapui.services.groups', [
    'mapui.services.auth',
    'mapui.services.modal',
])
    .service('GroupService', GroupService)
    .component('mpShare', new MpShareComponent())
    .controller('MpShareController', MpShareController);
