var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, Input } from '@angular/core';
import { EHsPublicationTag } from '@mapuilabs/mpl-interfaces';
import { getTagFromEnum } from '@app/hospistock/shared/constTypes/hs-tag.const';
let HsPublicationTagComponent = class HsPublicationTagComponent {
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        if (this.value) {
            const { translate, bulletStyles } = getTagFromEnum(this.value);
            this.label = translate;
            this.bulletStyles = bulletStyles;
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof EHsPublicationTag !== "undefined" && EHsPublicationTag) === "function" ? _a : Object)
], HsPublicationTagComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationTagComponent.prototype, "isDynamic", void 0);
HsPublicationTagComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-tag',
        template: `
        <div class="unselected-chip-bg px-8 py-4 h-22" fxLayout="row" fxLayoutAlign="start center">
            <div class="w-8 h-8 mr-8 b-r-4" [ngClass]="bulletStyles"></div>

            <div class="tag-title">{{ label | translate }}</div>

            <mat-icon
                *ngIf="isDynamic"
                class="pl-4 s-14 amber-700-fg font-weight-900"
                aria-label="tag-icon"
                fxLayoutAlign="center"
                [matTooltip]="'PUBLICATION.TOOLTIPS.AUTO_TAG' | translate"
            >
                bolt
            </mat-icon>
        </div>
    `,
        styles: [
            `
            /* Helpers dont handle sizes which are not a multiple of 4 */
            /* This case is an exception and should not be added in the helpers rules */
            .h-22 {
                height: 22px;
            }

            /* Fuse helpers dont have !important flag which is needed to overwrite the .mat-icon rule*/
            .font-weight-900 {
                font-weight: 900 !important;
            }
        `
        ]
    })
], HsPublicationTagComponent);
export { HsPublicationTagComponent };
