var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Member } from '@mapuilabs/mpl-interfaces';
import { subscriptionsNotifs } from '../../../shared/constTypes/subscriptions/notifications.const';
let NotificationsTabComponent = class NotificationsTabComponent {
    constructor() {
        this.notifsList = subscriptionsNotifs;
    }
    ngOnInit() {
        this.setFormControls();
    }
    setFormControls() {
        for (let notif in this.notifsList) {
            this.form.setControl(notif, new UntypedFormControl(this.user.subscriptions.notifications[notif]));
        }
    }
    updateMember(member) {
        for (let notif in this.notifsList) {
            member.subscriptions.notifications[notif] = this.form.value[notif];
        }
        return member;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _a : Object)
], NotificationsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Member !== "undefined" && Member) === "function" ? _b : Object)
], NotificationsTabComponent.prototype, "user", void 0);
NotificationsTabComponent = __decorate([
    Component({
        selector: 'mpx-notifications-tab',
        template: require('./notifications-tab.component.html').default,
        styles: [require('./notifications-tab.component.scss')]
    }),
    __metadata("design:paramtypes", [])
], NotificationsTabComponent);
export { NotificationsTabComponent };
