export const locale = {
    lang: 'en',
    data: {
        listPharmacies: {
            TITLE: 'Pharmacies',
            SEARCH_HOSPITAL: 'Search a pharmacy',
            TABLE: {
                NAME: 'Name',
                FINESS_CODES: 'Finess codes',
                ACCOUNTS: 'Accounts',
                ROLES: 'Roles'
            },
            DELETED: 'The pharmacy has been deleted!',
            CONFIRM_MESSAGE: 'Do you really want to delete this pharmacy?',
            IMPORT_BTN: `Importer`
        }
    }
};
