var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
let HsDashboardWidgetContainerComponent = class HsDashboardWidgetContainerComponent {
    constructor() {
        this.noData = false;
        this.isLoading = false;
        this.forbiddenAccess = false;
        this.actionClicked = new EventEmitter();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onActionClicked() {
        this.actionClicked.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsDashboardWidgetContainerComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsDashboardWidgetContainerComponent.prototype, "noData", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsDashboardWidgetContainerComponent.prototype, "isLoading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsDashboardWidgetContainerComponent.prototype, "forbiddenAccess", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_a = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _a : Object)
], HsDashboardWidgetContainerComponent.prototype, "actionClicked", void 0);
HsDashboardWidgetContainerComponent = __decorate([
    Component({
        selector: 'mpx-hs-dashboard-widget-container',
        template: require('./hs-dashboard-widget-container.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [])
], HsDashboardWidgetContainerComponent);
export { HsDashboardWidgetContainerComponent };
