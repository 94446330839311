var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { EFileType, Hospital } from '@mapuilabs/mpl-interfaces';
import { FileUploaderComponent } from '../../../../shared/components/file-uploader/file-uploader.component';
let InformationTabComponent = class InformationTabComponent {
    constructor() {
        //Event is emitted when the the "pre fill" button is clicked
        this.toFillEstablishment = new EventEmitter();
        // form control used by the finess establishment selector
        this.establishmentForm = new UntypedFormControl();
        // FileType Enum to be used in template
        this.eFileType = EFileType;
    }
    ngOnInit() { }
    ngOnChanges(changes) {
        if (changes.pharmacy) {
            this._setFormControls(this.pharmacy);
        }
    }
    _setFormControls(pharmacy) {
        this.form.setControl('name', new UntypedFormControl(pharmacy.name, Validators.required));
        this.form.setControl('addrNumber', new UntypedFormControl(pharmacy.addrNumber));
        this.form.setControl('addrStreet', new UntypedFormControl(pharmacy.addrStreet));
        this.form.setControl('addrCity', new UntypedFormControl(pharmacy.addrCity));
        this.form.setControl('addrZIP', new UntypedFormControl(pharmacy.addrZIP));
        this.form.setControl('addrCountry', new UntypedFormControl(pharmacy.addrCountry));
        this.form.setControl('finess', new UntypedFormControl(pharmacy.finessCode));
        this.form.setControl('finessJur', new UntypedFormControl(pharmacy.finessCodeJur));
    }
    updateHospital(pharmacy) {
        return new Observable((observer) => {
            pharmacy.name = this.form.value.name;
            pharmacy.addrNumber = this.form.value.addrNumber;
            pharmacy.addrStreet = this.form.value.addrStreet;
            pharmacy.addrCity = this.form.value.addrCity;
            pharmacy.addrZIP = this.form.value.addrZIP;
            pharmacy.addrCountry = this.form.value.addrCountry;
            pharmacy.finessCode = this.form.value.finess;
            pharmacy.finessCodeJur = this.form.value.finessJur;
            this.fileSelector.upload(false).subscribe((data) => {
                //Construct the logo url with the newly uploader image
                if (data && data.size) {
                    const ctnr = data.values().next().value;
                    pharmacy.imgSrc = `/files/8/${ctnr.response.body.storageName}/${ctnr.file.name}`;
                }
                observer.next(data);
                observer.complete();
            }, (err) => {
                observer.error(err);
            });
        });
    }
    establishmentChange(data) {
        this.selectedEstablishment = data;
    }
    applyFinessPreFill() {
        this.form.markAsDirty();
        if (this.selectedEstablishment) {
            this.toFillEstablishment.emit(this.selectedEstablishment);
        }
        this.checklistMenu.closeMenu();
    }
    // method passed to the file uploader. Will be called each time the file selection change
    // Used to set the form since the file uploader does not uses formControl
    fileSelectionChanged(data) {
        this.form.markAsDirty();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _a : Object)
], InformationTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Hospital !== "undefined" && Hospital) === "function" ? _b : Object)
], InformationTabComponent.prototype, "pharmacy", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], InformationTabComponent.prototype, "toFillEstablishment", void 0);
__decorate([
    ViewChild('checklistMenuTrigger', { static: false }),
    __metadata("design:type", typeof (_d = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _d : Object)
], InformationTabComponent.prototype, "checklistMenu", void 0);
__decorate([
    ViewChild('logoFileSelector', { static: false }),
    __metadata("design:type", typeof (_e = typeof FileUploaderComponent !== "undefined" && FileUploaderComponent) === "function" ? _e : Object)
], InformationTabComponent.prototype, "fileSelector", void 0);
InformationTabComponent = __decorate([
    Component({
        selector: 'mpx-information-tab',
        template: require('./information-tab.component.html').default,
        styles: [require('.//information-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [])
], InformationTabComponent);
export { InformationTabComponent };
