export class MpNothingToShowComponent {
    constructor() {
        this.controller = 'MpNothingToShowController';
        this.controllerAs = 'vm';
        this.template = require('./mp-nothing-to-show.html').default;
        this.bindings = {
            title: '<',
            text: '<',
        };
    }
    ;
}
