import { EDirRight } from '@mapuilabs/mpl-interfaces';
import { Utils } from '../Utils';
export class DirectoryRightsService {
    /** @ngInject */
    constructor() {
        /**
         * Get directory rights as bitmask
         * @param bc breadcrumb array
         * @return {number}
         */
        this.getDirectoryRightsBitmask = (bc) => {
            const refDir = this.getReferenceDirectory(bc);
            return refDir.defaultRights !== null && refDir.defaultRights !== undefined ? refDir.defaultRights : this._defaultRights;
        };
        /**
         * Return last reference directory (set by getDirectoryRightsBitmask)
         * @param bc breadcrumb array
         * @return {IFile}
         */
        this.getReferenceDirectory = (bc) => {
            for (let i = bc.length - 1; i >= 0; i--) {
                if (Utils.isInteger(bc[i].defaultRights)) {
                    return bc[i];
                }
            }
            return bc[0];
        };
        /**
         * Get directory rights as IDirectoryRights object
         * @param bc breadcrumb array
         * @return {number}
         */
        this.getDirectoryRights = (bc) => {
            return this.bitmaskToDirectoryRights(this.getDirectoryRightsBitmask(bc));
        };
        /**
         * Can {user} do {action} for current directory based of {bc}
         * @param userId
         * @param action
         * @param bc Breadcrumbs
         * @param file Specific file to check
         * @return {any}
         */
        this.userCanDoActionInside = (userId, action, bc, file = null) => {
            if (!bc.length)
                return false;
            const refDir = file && Utils.isInteger(file.defaultRights) ? file : this.getReferenceDirectory(bc);
            // Try to find specific rights for given user and directory
            if (refDir.membersRights) {
                for (let r of refDir.membersRights) {
                    if (Utils.compareIds(r.member, userId)) {
                        return !!(r.rights & action);
                    }
                }
            }
            return !!(this.getDirectoryRightsBitmask(bc) & action);
        };
        /**
         * Generate an IDirectoryRights object from a bitmask
         * The IDirectoryRights is used in view while bitmask is used to store rights in database
         * @param right
         * @return {IDirectoryRights}
         */
        this.bitmaskToDirectoryRights = (right) => {
            return {
                seeDirectory: !!(right & EDirRight.SEE_DIR),
                renameDirectory: !!(right & EDirRight.RENAME_DIR),
                moveDirectory: !!(right & EDirRight.MOVE_DIR),
                deleteDirectory: !!(right & EDirRight.DELETE_DIR),
                renameChildren: !!(right & EDirRight.RENAME_CHILD),
                moveChildren: !!(right & EDirRight.MOVE_CHILD),
                deleteChildren: !!(right & EDirRight.DELETE_CHILD),
                uploadChildren: !!(right & EDirRight.ADD_CHILD),
                downloadChildren: !!(right & EDirRight.DOWNLOAD_CHILD),
            };
        };
        /**
         * Generate a bitmask from an IDirectoryRights object
         * The bitmask is used to store rights in database while IDirectoryRights is used in view
         * @param right
         * @return {number}
         */
        this.directoryRightsToBitmask = (right) => {
            let val = 0;
            if (right.seeDirectory) {
                val |= EDirRight.SEE_DIR;
            }
            if (right.renameDirectory) {
                val |= EDirRight.RENAME_DIR;
            }
            if (right.moveDirectory) {
                val |= EDirRight.MOVE_DIR;
            }
            if (right.deleteDirectory) {
                val |= EDirRight.DELETE_DIR;
            }
            if (right.renameChildren) {
                val |= EDirRight.RENAME_CHILD;
            }
            if (right.moveChildren) {
                val |= EDirRight.MOVE_CHILD;
            }
            if (right.deleteChildren) {
                val |= EDirRight.DELETE_CHILD;
            }
            if (right.uploadChildren) {
                val |= EDirRight.ADD_CHILD;
            }
            if (right.downloadChildren) {
                val |= EDirRight.DOWNLOAD_CHILD;
            }
            return val;
        };
        this._defaultRights = EDirRight.SEE_DIR | EDirRight.RENAME_DIR | EDirRight.MOVE_DIR | EDirRight.DELETE_DIR
            | EDirRight.RENAME_CHILD | EDirRight.MOVE_CHILD | EDirRight.DELETE_CHILD
            | EDirRight.DOWNLOAD_CHILD | EDirRight.ADD_CHILD;
    }
}
