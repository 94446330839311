import * as angular from 'angular';
import './hv-care-plan/hv-care-plan.module';
import './hv-drug-balance-sheet/hv-drug-balance-sheet.module';
import './hv-extensive-patient-interview/hv-extensive-patient-interview.module';
import './hv-medication-reconciliation/hv-medication-reconciliation.module';
import './hv-patient-file/hv-patient-file.module';
import './hv-patient-files/hv-patient-files.module';
import './hv-patient-interview-survey/hv-patient-interview-survey.module';
import './hv-prescription/hv-prescription.module';
import './hv-reconciliation-sheet/hv-reconciliation-sheet.module';
import './hv-treatment-analysis/hv-treatment-analysis.module';
import './hv-patient-interview-survey/public-patient-interview-survey/public-patient-interview-survey.module';
angular.module('hospiville.patient', [
    'hospiville.patient.dbs',
    'hospiville.patient.medication-reconciliation',
    'hospiville.patient.reconciliation-sheet',
    'hospiville.patient.file',
    'hospiville.patient.files',
    'hospiville.patient.prescription',
    'hospiville.patient.interview-survey',
    'hospiville.public-patient.interview-survey',
    'hospiville.patient.extensive-patient-interview',
    'hospiville.patient.treatment-analysis',
    'hospiville.patient.care-plan',
]);
