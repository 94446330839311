import { LocalFile } from '../../LocalFile';
/**
 * Allow the binding between the file selection and an `ng-model`
 * The model returned is a [[LocalFile]]
 * @private
 */
export class MpFileSelectDirective {
    /** @ngInject */
    constructor($window) {
        this.$window = $window;
        this.link = (scope, element, attrs, ctrl) => {
            const { File, FormData } = this.$window;
            element.on('change', onChange);
            scope.$on('destroy', function () {
                element.off('change', onChange);
            });
            /**
             * Callback for the change event
             * the `ng-model` MUST be an array of [[LocalFile]]
             * Create a new [[LocalFile]] and add it to the `ng-model`
             */
            function onChange() {
                const files = !!(File && FormData) ? element[0].files : element[0]; // If HTML 5 then the files property exist else use the element[0]
                const localFiles = [];
                for (let f of files) {
                    localFiles.push(new LocalFile({
                        element: element,
                        blob: f,
                        scope: scope,
                    }));
                }
                ctrl.$setViewValue(localFiles);
            }
        };
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    static factory() {
        const directive = ($window) => new MpFileSelectDirective($window);
        directive.$inject = ['$window'];
        return directive;
    }
}
