var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as Moment from 'moment';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CustomWidgetHomeASchema } from '@mapuilabs/mpl-interfaces';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { Utils } from '@main/services/Utils';
import { DialogService } from '@services/dialog/dialog.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { CustomWidgetsService } from '@services/homepage/widgets/custom-widgets/custom-widgets.service';
let CustomWidgetDetailsComponent = class CustomWidgetDetailsComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _widgetHomeService, _dialogService, _snackbarService, fuseNavigationService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._widgetHomeService = _widgetHomeService;
        this._dialogService = _dialogService;
        this._snackbarService = _snackbarService;
        this.fuseNavigationService = fuseNavigationService;
        this.moment = Moment;
        this.isEditPage = false;
        this.buttonsList = [];
        this.buttonsListColumns = ['title', 'state', 'color', 'actions'];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this.isEditPage = !!this.widgetHome;
        if (!this.widgetHome) {
            this.widgetHome = new CustomWidgetHomeASchema();
        }
        this.createWidgetFormGroup();
        this.createButtonFormGroup();
        this.pageHeaderConfig = {
            title: [
                this._translate('WIDGET_HOME.TITLE'),
                this.isEditPage
                    ? this.widgetHome.title.message || this._translate('COMMONS.EDIT')
                    : this._translate('COMMONS.NEW')
            ].join(' - '),
            icon: 'credit_card'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    createWidgetFormGroup() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.formWidget = new UntypedFormGroup({});
        this.formWidget.setControl('periodStart', new UntypedFormControl((_a = this.widgetHome.period) === null || _a === void 0 ? void 0 : _a.start, Validators.required));
        this.formWidget.setControl('periodEnd', new UntypedFormControl((_b = this.widgetHome.period) === null || _b === void 0 ? void 0 : _b.end));
        this.formWidget.setControl('active', new UntypedFormControl(this.widgetHome.active));
        this.formWidget.setControl('title', new UntypedFormControl((_c = this.widgetHome.title) === null || _c === void 0 ? void 0 : _c.message, Validators.required));
        this.formWidget.setControl('titleColor', new UntypedFormControl((_d = this.widgetHome.title) === null || _d === void 0 ? void 0 : _d.color));
        this.formWidget.setControl('content', new UntypedFormControl((_e = this.widgetHome.content) === null || _e === void 0 ? void 0 : _e.message));
        this.formWidget.setControl('contentColor', new UntypedFormControl((_f = this.widgetHome.content) === null || _f === void 0 ? void 0 : _f.color));
        this.formWidget.setControl('background', new UntypedFormControl(this.widgetHome.background));
        this.formWidget.setControl('priorityOrder', new UntypedFormControl(this.widgetHome.priorityOrder));
        this.formWidget.setControl('width', new UntypedFormControl(this.widgetHome.width.toString()));
        this.formWidget.setControl('counter', new UntypedFormControl(this.widgetHome.counter));
        this.formWidget.setControl('buttonsLayout', new UntypedFormControl((_g = this.widgetHome.buttons) === null || _g === void 0 ? void 0 : _g.layout));
        this.buttonsList = ((_h = this.widgetHome.buttons) === null || _h === void 0 ? void 0 : _h.list) || [];
    }
    createButtonFormGroup() {
        this.formButtons = new UntypedFormGroup({});
        this.formButtons.setControl('title', new UntypedFormControl('', Validators.required));
        this.formButtons.setControl('state', new UntypedFormControl(undefined, this.doesGetState(true)));
        this.formButtons.setControl('url', new UntypedFormControl('', this.doesGetState(false)));
        this.formButtons.setControl('color', new UntypedFormControl());
    }
    updateButtonList() {
        this.buttonsList = Object.assign([], this.buttonsList);
    }
    doesGetState(isState) {
        return () => {
            var _a;
            const stateValue = (_a = this.formButtons.controls.state) === null || _a === void 0 ? void 0 : _a.value;
            if (isState) {
                return stateValue ? null : { doesGetState: true };
            }
            return stateValue === 'otherUrl' && !this.formButtons.controls.url.value ? { doesGetState: true } : null;
        };
    }
    addDBWidget() {
        if (this.isEditPage) {
            this._widgetHomeService.save(this.createWidgetASchemaFromForm(this.widgetHome)).subscribe((result) => {
                this.widgetHome = result;
                this.createWidgetFormGroup();
            }, (err) => this._snackbarService.openError(err));
        }
        else {
            this._widgetHomeService.add(this.createWidgetASchemaFromForm()).subscribe((result) => {
                this.isEditPage = true;
                this.widgetHome = result;
                this.createWidgetFormGroup();
            }, (err) => this._snackbarService.openError(err));
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    addButton() {
        if (this.formButtons.invalid || !this.formButtons.dirty) {
            return;
        }
        this.buttonsList.push({
            title: {
                message: this.formButtons.controls.title.value
            },
            state: this.formButtons.controls.state.value,
            url: this.formButtons.controls.url.value,
            color: this.formButtons.controls.color.value
        });
        this.updateButtonList();
        this.formWidget.markAsDirty();
        this.formButtons.reset();
    }
    editButtonColor(button, color) {
        const foundButton = this.buttonsList.find((buttonList) => buttonList.title === button.title &&
            buttonList.state === button.state &&
            buttonList.url === button.url &&
            buttonList.color === button.color);
        foundButton.color = color;
        this.formWidget.markAsDirty();
    }
    deleteButton(button) {
        Utils.removeElemFromArray(this.buttonsList, (buttonList) => buttonList.title === button.title &&
            buttonList.state === button.state &&
            buttonList.url === button.url &&
            buttonList.color === button.color);
        this.updateButtonList();
        this.formWidget.markAsDirty();
    }
    finishWidgetHome() {
        if (this.formWidget.invalid || this.formWidget.pristine) {
            return this._snackbarService.openCustomError(this._translationService.instant('WIDGET_HOME.DETAIL.INVALID_FORM'));
        }
        if (!this.formButtons.pristine) {
            this._dialogService
                .openConfirmDialog({
                title: this._translationService.instant('WIDGET_HOME.DETAIL.RESET.TITLE'),
                message: this._translationService.instant('WIDGET_HOME.DETAIL.RESET.MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.YES'),
                cancelButton: this._translationService.instant('COMMONS.NO')
            })
                .subscribe((doesCancelButton) => {
                if (doesCancelButton) {
                    this.formButtons.reset();
                    this.addDBWidget();
                }
            });
        }
        else {
            this.addDBWidget();
        }
    }
    createWidgetASchemaFromForm(oldWidgetHome) {
        const width = parseInt(this.formWidget.controls.width.value);
        const newWidget = new CustomWidgetHomeASchema({
            period: {
                start: this.formWidget.controls.periodStart.value,
                end: this.formWidget.controls.periodEnd.value
            },
            title: {
                message: this.formWidget.controls.title.value,
                color: this.formWidget.controls.titleColor.value
            },
            content: {
                message: this.formWidget.controls.content.value,
                color: this.formWidget.controls.contentColor.value
            },
            background: this.formWidget.controls.background.value,
            priorityOrder: this.formWidget.controls.priorityOrder.value,
            width: width === 1 || width === 2 || width === 3 ? width : 1,
            counter: this.formWidget.controls.counter.value,
            buttons: {
                layout: this.formWidget.controls.buttonsLayout.value,
                list: this.buttonsList
            },
            active: this.formWidget.controls.active.value
        });
        if (oldWidgetHome) {
            newWidget._id = oldWidgetHome._id;
            newWidget.creationUID = oldWidgetHome.creationUID;
            newWidget.creationDate = oldWidgetHome.creationDate;
        }
        return newWidget;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof CustomWidgetHomeASchema !== "undefined" && CustomWidgetHomeASchema) === "function" ? _g : Object)
], CustomWidgetDetailsComponent.prototype, "widgetHome", void 0);
CustomWidgetDetailsComponent = __decorate([
    Component({
        selector: 'mpx-custom-widget-details',
        template: require('./custom-widget-details.component.html').default,
        styles: [require('./custom-widget-details.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof CustomWidgetsService !== "undefined" && CustomWidgetsService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object, typeof (_f = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _f : Object])
], CustomWidgetDetailsComponent);
export { CustomWidgetDetailsComponent };
