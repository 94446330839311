import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvPatientFileComponent } from './hv-patient-file.component';
import { HvPatientFileController } from './hv-patient-file.controller';
import { HvDoctorSelectionComponent } from './hv-doctor-selection/hv-doctor-selection.component';
import { HvDoctorSelectionController } from './hv-doctor-selection/hv-doctor-selection.controller';
import { HvModalComplianceTestComponent } from './hv-modal-compliance-test/hv-modal-compliance-test.component';
import { HvModalComplianceTestController } from './hv-modal-compliance-test/hv-modal-compliance-test.controller';
import { HvModalConsentComponent } from './hv-modal-consent/hv-modal-consent.component';
import { HvModalConsentController } from './hv-modal-consent/hv-modal-consent.controller';
import { HvModalMasterPatientComponent } from './hv-modal-master-patient/hv-modal-master-patient.component';
import { HvModalMergePatientInfoComponent } from './hv-modal-merge-patient-info/hv-modal-merge-patient-info.compoment';
import { HvModalMergePatientInfoController } from './hv-modal-merge-patient-info/hv-modal-merge-patient-info.controller';
import { HvModalSearchHealthProComponent } from './hv-modal-search-health-pro/hv-modal-search-health-pro.component';
import { HvModalSearchHealthProController } from './hv-modal-search-health-pro/hv-modal-search-health-pro.controller';
import { HvPatientInformationComponent } from './hv-patient-information/hv-patient-information.component';
import { HvPatientInformationController } from './hv-patient-information/hv-patient-information.controller';
import { HvPatientMedRecsComponent } from './hv-patient-med-recs/hv-patient-med-recs.component';
import { HvPatientMedRecsController } from './hv-patient-med-recs/hv-patient-med-recs.controller';
import { HvPatientSourceComponent } from './hv-patient-source/hv-patient-source.component';
import { HvPatientSourceController } from './hv-patient-source/hv-patient-source.controller';
import { HvModalMasterPatientController } from './hv-modal-master-patient/hv-modal-master-patient.controller';
import { HvPatientPathwaysComponent } from '@main/hospiville/hv-patient/hv-patient-file/hv-patient-pathways/hv-patient-pathways.component';
import { HvPatientPathwaysController } from '@main/hospiville/hv-patient/hv-patient-file/hv-patient-pathways/hv-patient-pathways.controller';
angular
    .module('hospiville.patient.file', [
    'mapui.services.underscore',
    'mapui.services.auth',
    'mapui.services.toast',
    'mapui.services.modal',
    'hospiville.services.patient',
    'angularFileUpload',
])
    .config(configPatientFile)
    .component('hvPatientFile', new HvPatientFileComponent())
    .controller('HvPatientFileController', HvPatientFileController)
    .component('hvDoctorSelection', new HvDoctorSelectionComponent())
    .controller('HvDoctorSelectionController', HvDoctorSelectionController)
    .component('hvModalComplianceTest', new HvModalComplianceTestComponent())
    .controller('HvModalComplianceTestController', HvModalComplianceTestController)
    .component('hvModalConsent', new HvModalConsentComponent())
    .controller('HvModalConsentController', HvModalConsentController)
    .component('hvModalMasterPatient', new HvModalMasterPatientComponent())
    .component('hvModalMergePatientInfo', new HvModalMergePatientInfoComponent())
    .controller('HvModalMergePatientInfoController', HvModalMergePatientInfoController)
    .component('hvModalSearchHealthPro', new HvModalSearchHealthProComponent())
    .controller('HvModalSearchHealthProController', HvModalSearchHealthProController)
    .component('hvPatientInformation', new HvPatientInformationComponent())
    .controller('HvPatientInformationController', HvPatientInformationController)
    .component('hvPatientMedRecs', new HvPatientMedRecsComponent())
    .controller('HvPatientMedRecsController', HvPatientMedRecsController)
    .component('hvPatientSource', new HvPatientSourceComponent())
    .controller('HvPatientSourceController', HvPatientSourceController)
    .component('hvPatientPathways', new HvPatientPathwaysComponent())
    .controller('HvPatientPathwaysController', HvPatientPathwaysController)
    .controller('HvModalMasterPatientController', HvModalMasterPatientController);
/** @ngInject */
function configPatientFile($stateProvider) {
    $stateProvider.state('hospiville.patient-file', {
        url: '/patient-file',
        params: {
            newPatient: { squash: true, value: null },
        },
        data: {
            access: EHospivillePermission.PatientFile_Read,
            hasSensitiveData: true,
            askForSave: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-patient-file></hv-patient-file>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville patient-file',
    });
    $stateProvider.state('hospiville.patient-file.lppi', {
        url: '/:finessJur/:lppi',
        data: {
            access: EHospivillePermission.PatientFile_Read,
            hasSensitiveData: true,
            askForSave: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-patient-file></hv-patient-file>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville patient-file',
    });
    $stateProvider.state('hospiville.patient-file.part', {
        url: '/part/:selectedTab',
        data: {
            access: EHospivillePermission.PatientFile_Read,
            hasSensitiveData: true,
            askForSave: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-patient-file></hv-patient-file>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville patient-file',
    });
}
