var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '@angular/core';
let SmallListItemComponent = class SmallListItemComponent {
};
SmallListItemComponent = __decorate([
    Component({
        selector: 'mpx-small-list-item',
        template: `
        <div
            class="small-list-item-container py-4 px-16 font-size-14 min-h-32 divider-by"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <ng-content select="mpx-small-list-col"></ng-content>

            <div fxLayout="row" fxLayoutGap="4px">
                <ng-content select="mpx-small-list-button"></ng-content>
            </div>
        </div>
    `,
        styles: [
            `
            :host:not(:last-child) .small-list-item-container {
                border-bottom: none !important;
            }
        `
        ]
    })
], SmallListItemComponent);
export { SmallListItemComponent };
