var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { lastValueFrom } from 'rxjs';
import { ExternalActorsService } from '../../services/external-actor/external-actors.service';
import { ExternalConfigurationService } from '../../services/external-configuration/external-configuration.service';
import { ExternalActorDetailComponent } from './external-actor-detail/external-actor-detail.component';
import { ExternalConfigDetailComponent } from './external-config-detail/external-config-detail.component';
import { ListExternalActorsComponent } from './list-external-actors/list-external-actors.component';
const states = [
    {
        name: 'mapui.adm.external-actors-v2.list',
        url: '/list',
        views: {
            'content@mapui': {
                component: ListExternalActorsComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.external-actors-v2.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: ExternalActorDetailComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'actor',
                deps: [Transition, ExternalActorsService],
                resolveFn: (trans, extActorService) => {
                    return extActorService.resolve(trans.params());
                }
            }
        ]
    },
    {
        name: 'mapui.adm.external-actors-v2.config',
        url: '/:actorId/config/:id',
        views: {
            'content@mapui': {
                component: ExternalConfigDetailComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        resolve: [
            {
                token: 'actor',
                deps: [Transition, ExternalActorsService],
                resolveFn: (trans, extActorService) => {
                    return lastValueFrom(extActorService.getById(trans.params().actorId));
                }
            },
            {
                token: 'config',
                deps: [Transition, ExternalConfigurationService],
                resolveFn: (trans, extConfigService) => {
                    return extConfigService.resolve(trans.params());
                }
            }
        ]
    }
];
let ExternalActorsRoutingModule = class ExternalActorsRoutingModule {
};
ExternalActorsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], ExternalActorsRoutingModule);
export { ExternalActorsRoutingModule };
