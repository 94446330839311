var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { DialogService } from '../../../services/dialog/dialog.service';
import { CronService } from '../../../services/cron/cron.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
let ListCronComponent = class ListCronComponent {
    constructor(_fuseTranslationLoaderService, _cronService, _translationService, _dialogService, _snackbarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._cronService = _cronService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._snackbarService = _snackbarService;
        this.cronList = undefined;
        this.tableColumns = ['name', 'status', 'lastRun', 'schedule', 'actions'];
        this.lastSort = undefined;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('CRON.TITLE'),
            icon: 'alarm'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._refresh();
    }
    ngOnDestroy() {
        this._stopRefreshInterval();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // Refresh clock
    _refresh() {
        this._cronService.getAll().subscribe((crons) => {
            this.cronList = crons;
            this.sortData(this.lastSort);
            this._startRefreshInterval();
        });
    }
    _startRefreshInterval() {
        if (!this.clock) {
            this.clock = setInterval(() => {
                this._refresh();
            }, 10000);
        }
    }
    _stopRefreshInterval() {
        if (this.clock) {
            clearInterval(this.clock);
            this.clock = null;
        }
    }
    // Sort
    _myCompareStatus(a, b, direct) {
        if (a.isRunning && !b.isRunning) {
            return direct ? -1 : 1;
        }
        if (b.isRunning && !a.isRunning) {
            return direct ? 1 : -1;
        }
        if (a.isScheduled === b.isScheduled) {
            return this._myCompareStrings(a.name, b.name, direct);
        }
        if (!a.isScheduled && b.isScheduled) {
            return direct ? 1 : -1;
        }
        return direct ? -1 : 1;
    }
    _myCompareStrings(a, b, direct) {
        const result = this._translate(a).localeCompare(this._translate(b));
        if (result === 0) {
            return 0;
        }
        if (result < 0) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    _myCompareDate(a, b, direct) {
        if (!a.lastRun) {
            return direct ? 1 : -1;
        }
        if (!b.lastRun) {
            return direct ? -1 : 1;
        }
        const date1 = new Date(a.lastRun);
        const date2 = new Date(b.lastRun);
        if (date1.getTime() === date2.getTime()) {
            return this._myCompareStrings(a.name, b.name, direct);
        }
        if (date1 > date2) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    _runDialog(action) {
        return this._dialogService.openConfirmDialog({
            title: this._translate(`CRON.TABLE.ACTIONS.${action}.MODAL.TITLE`),
            message: this._translate(`CRON.TABLE.ACTIONS.${action}.MODAL.MESSAGE`),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    sortData(sort) {
        if (!this.cronList) {
            return;
        }
        this.lastSort = sort;
        const data = this.cronList.slice();
        if (!sort || !sort.active || sort.direction === '') {
            this.cronList = data.sort((a, b) => {
                const order = this._myCompareStatus(a, b, true);
                if (order === 0) {
                    return this._myCompareStrings(a.name, b.name, false);
                }
                return order;
            });
            return;
        }
        this.cronList = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this._myCompareStrings(a.name, b.name, false);
                case 'status':
                    return this._myCompareStatus(a, b, isAsc);
                case 'lastRun':
                    return this._myCompareDate(a, b, isAsc);
                default:
                    return 0;
            }
        });
    }
    // Actions
    runCron(cron) {
        this._runDialog('RUN').subscribe((result) => {
            if (result) {
                this._cronService.actions(cron, 'run').subscribe(() => {
                    this._snackbarService.open(this._translate('CRON.TABLE.ACTIONS.RUN.MODAL.NOTIF'));
                    this._refresh();
                }, (err) => {
                    this._snackbarService.openError(err);
                });
            }
        });
    }
    deleteCron(cron) {
        this._runDialog('DELETE').subscribe((result) => {
            if (result) {
                this._cronService.remove(cron).subscribe(() => {
                    this._snackbarService.open(this._translate('CRON.TABLE.ACTIONS.DELETE.MODAL.NOTIF'));
                    this._refresh();
                }, (err) => {
                    this._snackbarService.openError(err);
                });
            }
        });
    }
    toggleCron(cron, toggle) {
        const taskTrad = toggle ? 'ON' : 'OFF';
        this._runDialog(taskTrad).subscribe((result) => {
            if (result) {
                this._cronService.actions(cron, toggle ? 'schedule' : 'un-schedule').subscribe(() => {
                    this._snackbarService.open(this._translate('CRON.TABLE.ACTIONS.' + taskTrad + '.MODAL.NOTIF'));
                    this._refresh();
                }, (err) => this._snackbarService.openError(err));
            }
        });
    }
};
ListCronComponent = __decorate([
    Component({
        selector: 'mpx-list-cron',
        template: require('./list-cron.component.html').default,
        styles: [require('./list-cron.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof CronService !== "undefined" && CronService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], ListCronComponent);
export { ListCronComponent };
