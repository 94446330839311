var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, Injector } from '@angular/core';
import { getInfoFromTag } from '@app/hospistock/shared/constTypes/hs-tag.const';
import { PermissionService } from '@app/services/permission/permission.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { UrlService } from '@app/services/url/url.service';
import { CrudReadonly } from '@app/shared/templates/crud/crud-readonly.class';
import { Access } from '@main/services/auth/access.service';
import { Utils } from '@main/services/Utils';
import { EGroupType, EHsUserTypes, HsPublicationContainer } from '@mapuilabs/mpl-interfaces';
import { IdentityService } from '@services/identity/identity.service';
import { StateService, UIRouter } from '@uirouter/angularjs';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { CHsPublicationType } from '../constTypes/publication/hs-publication-type.const';
import { EHsToolbarViewModes } from '../types/hs-toolbar.types';
import { HsDownloadService } from './hs-download.service';
import { HsHealthProductService } from './hs-health-product.service';
import { HsLaboratoryService } from './hs-laboratory.service';
let HsPublicationContainerService = class HsPublicationContainerService extends CrudReadonly {
    constructor(_access, _changeDetectorRef, _downloadService, _httpClient, _identityService, _laboratoryService, _route, _snackbarService, _stateService, _translationService, _urlService, _healthProductService, _injector, _permissionService) {
        super(_injector, HsPublicationContainer, '', {
            create: null,
            read: null,
            update: null,
            delete: null
        });
        this._access = _access;
        this._changeDetectorRef = _changeDetectorRef;
        this._downloadService = _downloadService;
        this._httpClient = _httpClient;
        this._identityService = _identityService;
        this._laboratoryService = _laboratoryService;
        this._route = _route;
        this._snackbarService = _snackbarService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this._urlService = _urlService;
        this._healthProductService = _healthProductService;
        this._injector = _injector;
        this._permissionService = _permissionService;
        this.readonly = false;
        // Initialization
        this._init();
        // State
        this._currentState = this._route.globals.current;
        // Config
        this._serviceConfig = this._currentState.data.servicesConfig.publicationContainer;
        this._commonEndPoint = 'api/hospistock/common';
        this._viewEndPoint = `${this._serviceConfig.endPointBase}/publication`;
        this.viewType = this._serviceConfig.viewType;
        this.types = this._serviceConfig.types;
        this.publicationConstructor = this._serviceConfig.constructors.publication;
        this.publicationAlternativeConstructor = this._serviceConfig.constructors.publicationAlternative;
        this.publicationMetadataConstructor = this._serviceConfig.constructors.publicationMetadata;
        // CrudReadonly config
        // CrudReadonly is only used to retrieve containers
        this._endPoint = `${this._viewEndPoint}/container`;
        this._permissions = this._serviceConfig.permissions;
        // Handle readonly permission
        this.readonly = !this._permissionService.authorizeAny([
            this._permissions.create.toString(),
            this._permissions.update.toString(),
            this._permissions.delete.toString()
        ]);
        // Init Subjects & BehaviorSubjects
        this.onReloadList = new Subject();
        this.isLoadingDetail = new BehaviorSubject(false);
        this.publicationContainers$$ = new BehaviorSubject([]);
        this.currentPublicationContainer$$ = new BehaviorSubject(null);
        this.checkedPublicationContainers$ = new BehaviorSubject([]);
        // Init Pfizer data
        this.isPfizerOperator = new BehaviorSubject(null);
    }
    _init() {
        return __awaiter(this, void 0, void 0, function* () {
            this._initPfizerdata();
            // Handle view mode
            this._handleViewMode();
        });
    }
    _handleViewMode() {
        const setDefaultViewMode = () => {
            console.warn('No view mode provided in route data, use default DUAL view mode.');
            return EHsToolbarViewModes.DUAL;
        };
        const currentState = this._route.globals.current;
        this.viewMode = currentState.data.viewMode || setDefaultViewMode();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Utils
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Container - CrudReadonly is only used to retrieve containers
    // -----------------------------------------------------------------------------------------------------
    _findPublicationContainerByPublicationId(id) {
        const publicationContainers = this.publicationContainers$$.value;
        const index = publicationContainers.findIndex((publicationContainer) => Utils.compareIds(publicationContainer.value.publication, id));
        // Guard: publicationContainer not found
        if (index === -1) {
            throw new Error('Publication container not found');
        }
        return publicationContainers[index];
    }
    _findCurrentPublicationContainer(containerPublicationList) {
        // Compare current container with containerPublicationList
        if (Utils.compareIds(Utils.getId(containerPublicationList.publication), this.currentPublicationContainer$$.value.value.publication)) {
            return this.currentPublicationContainer$$.value;
        }
        else {
            throw new Error('Publication container is different from current publication');
        }
    }
    updatePublicationContainers(publicationContainers) {
        // Deselect all publications
        this.deselectAllPublications();
        // Complete all BehaviorSujects
        this.publicationContainers$$.value.forEach((publicationContainer) => publicationContainer.complete());
        // Update publicationContainers with new BehaviorSubjects
        this.publicationContainers$$.next(publicationContainers.map((publicationContainer) => new BehaviorSubject(publicationContainer)));
        // Update current publication
        const currentPublicationUrlId = this._urlService.getParam('id');
        const publicationIds = publicationContainers.map(({ publication }) => publication._id);
        const isIncluded = !!currentPublicationUrlId && publicationIds.includes(currentPublicationUrlId);
        this.readPublication(isIncluded ? currentPublicationUrlId : null);
    }
    /**
     * Read publication container by publication id
     * @param publicationId HsPublication's id to retrieve
     * @returns Container containing publication, metadata & associated health product directory
     */
    readPublicationContainerByPublicationId(publicationId) {
        return this.getById(publicationId);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Commons routes methods
    // -----------------------------------------------------------------------------------------------------
    getSupervisorsList() {
        return this._httpClient.get(`${this._commonEndPoint}/supervisors`);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Publication
    // -----------------------------------------------------------------------------------------------------
    createPublication(publication) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `${this._viewEndPoint}`;
            return yield lastValueFrom(this._httpClient.post(endpoint, publication));
        });
    }
    /**
     * Select the current publication to display detail in the right panel
     * @param id HsPublication's id to select
     */
    readPublication(id) {
        return __awaiter(this, void 0, void 0, function* () {
            // Update URL
            this._stateService.go(this._currentState.name, { id });
            // Set current publication
            yield this._setCurrentPublication(id);
        });
    }
    updatePublication(updatePublication) {
        return __awaiter(this, void 0, void 0, function* () {
            const id = Utils.getId(updatePublication);
            // Guard: id not provided
            if (!id) {
                throw new Error('Cannot udpate HsPublicationASchema, no id provided');
            }
            const endpoint = `${this._viewEndPoint}/${id}`;
            try {
                const updatedPublication = yield lastValueFrom(this._httpClient.put(endpoint, updatePublication));
                const updatedContainer = yield lastValueFrom(this.getById(Utils.getId(updatedPublication)));
                this.updateLocalPublicationContainer(updatedContainer);
                return updatedPublication;
            }
            catch (err) {
                // Handle HttpErrorResponses
                if (err instanceof HttpErrorResponse) {
                    // Handle outdated data
                    if (err.status === 409) {
                        this._snackbarService.warningHttpStatus(err.status);
                        this.onReloadList.next();
                    }
                    else {
                        this._snackbarService.errorHttpStatus(err.status);
                    }
                }
                else {
                    console.error(err);
                }
            }
        });
    }
    deletePublication(id) {
        return __awaiter(this, void 0, void 0, function* () {
            // Guard: id not provided
            if (!id) {
                throw new Error('Cannot delete HsPublicationASchema, no id provided');
            }
            const endpoint = `${this._viewEndPoint}/${id}`;
            try {
                yield lastValueFrom(this._httpClient.delete(endpoint, { responseType: 'text' }));
            }
            catch (err) {
                // Handle HttpErrorResponses
                if (err instanceof HttpErrorResponse) {
                    // Handle data not found
                    if (err.status === 404) {
                        this._snackbarService.warningHttpStatus(err.status);
                    }
                    else {
                        this._snackbarService.errorHttpStatus(err.status);
                    }
                }
                else {
                    this._snackbarService.openError(err);
                    console.error(err);
                }
            }
        });
    }
    /**
     * Set current publication by id
     * @param id
     */
    _setCurrentPublication(id) {
        return __awaiter(this, void 0, void 0, function* () {
            // Update isLoading
            this.isLoadingDetail.next(true);
            // Get publicationContainer's reference with complete data
            const readPublicationContainer = () => __awaiter(this, void 0, void 0, function* () {
                // Retrieve publication with complete data
                const publicationContainer = yield lastValueFrom(this.readPublicationContainerByPublicationId(id));
                // In dual mode view, find the publicationContainer corresponding to the provided id
                // and update it with the data retrieved from server.
                // This aim to link the list-item's stockotuContainer to the detail publicationContainer
                // so they are both updated when one or the other is updated.
                const getAndUpdatePublicationContainer = () => {
                    const foundPublicationContainer$ = this._findPublicationContainerByPublicationId(id);
                    // Return publicationContainer's reference with assigned complePublicationContainer data
                    Object.assign(foundPublicationContainer$.value, publicationContainer);
                    foundPublicationContainer$.next(foundPublicationContainer$.value);
                    return foundPublicationContainer$;
                };
                // Get publicationContainer's reference from publicationContainers$$ and update it
                // or create new BehaviorSubject with the retrieve publication container if in singleViewMode.
                const isSingleViewMode = !(this.viewMode === EHsToolbarViewModes.DUAL);
                const publicationContainer$ = isSingleViewMode
                    ? new BehaviorSubject(publicationContainer)
                    : getAndUpdatePublicationContainer();
                return publicationContainer$;
            });
            // Update currentPublication
            this.currentPublicationContainer$$.next(id ? yield readPublicationContainer() : null);
            // Update isLoading
            this.isLoadingDetail.next(false);
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
    }
    _isUserPublicationOwner(publication) {
        switch (this.viewType) {
            case EHsUserTypes.PUI:
                return Utils.compareIds(publication.creator, this._identityService.user.hospital);
            case EHsUserTypes.GA:
                return Utils.compareIds(publication.creatorGroup, this.getPurchaseGroupWhereUserIsAdministrator(this._identityService.user));
            case EHsUserTypes.LABO:
                return Utils.compareIds(publication.creator, this._identityService.user.hospital);
            default:
                throw new Error('Cannot check if user can edit publication, publicationViewType not handled.');
        }
    }
    updateLocalPublicationContainer(container) {
        const isSingleDetailViewMode = this.viewMode === EHsToolbarViewModes.SINGLE_DETAIL;
        const publicationContainer$ = isSingleDetailViewMode
            ? this._findCurrentPublicationContainer(container)
            : this._findPublicationContainerByPublicationId(Utils.getId(container.publication));
        // Update container
        publicationContainer$.next(container);
    }
    isPublicationTerminated(publication) {
        // Handle no endDate
        if (!(publication === null || publication === void 0 ? void 0 : publication.endDate)) {
            return false;
        }
        else {
            // Convert dates to moment date objects starting on the start of the day
            const endDate = moment(publication.endDate).startOf('day');
            const today = moment().startOf('day');
            return endDate.isBefore(today, 'day');
        }
    }
    isFuturePublication(publication) {
        // Handle no startDate
        if (!(publication === null || publication === void 0 ? void 0 : publication.startDate)) {
            return false;
        }
        const startDate = moment(publication.startDate).startOf('day');
        const today = moment().startOf('day');
        return startDate.isAfter(today, 'day');
    }
    canEditPublication(publication) {
        return publication.creatorType === this.viewType && !this.readonly && this._isUserPublicationOwner(publication);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Checked HsPublications
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle checked publication by id
     * @param id
     */
    toggleCheckedPublication(id) {
        const checkedPublicationContainers = [...this.checkedPublicationContainers$.value];
        const containerIndex = checkedPublicationContainers.findIndex(({ publication }) => Utils.getId(publication) === id);
        const isPublicationChecked = containerIndex !== -1;
        if (isPublicationChecked) {
            // Remove from checked publications
            checkedPublicationContainers.splice(containerIndex, 1);
        }
        else {
            // Add to checked publications
            const publicationContainer$ = this.publicationContainers$$.value.find((publicationContainer) => {
                const publication = publicationContainer.value.publication;
                return Utils.getId(publication) === id;
            });
            checkedPublicationContainers.push(publicationContainer$.value);
        }
        // Update checkedPublications
        this.checkedPublicationContainers$.next(checkedPublicationContainers);
        // Manually trigger rendering
        this._changeDetectorRef.detectChanges();
    }
    /**
     * Toggle select all
     */
    toggleSelectAll() {
        if (this.checkedPublicationContainers$.value.length === this.publicationContainers$$.value.length) {
            this.deselectAllPublications();
        }
        else {
            this.selectAllPublicationss();
        }
    }
    /**
     * Select all publications
     */
    selectAllPublicationss() {
        this.checkedPublicationContainers$.next([...this.publicationContainers$$.value.map(({ value }) => value)]);
    }
    /**
     * Deselect all publications
     */
    deselectAllPublications() {
        this.checkedPublicationContainers$.next([]);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ HsPublication related information
    // -----------------------------------------------------------------------------------------------------
    publicationExistsForUserFromHealthProductsRef(refs, id) {
        let params = new HttpParams();
        params = params.set('healthProductsRef', JSON.stringify(refs));
        if (id) {
            params = params.set('id', id);
        }
        return this._httpClient.get(`${this._viewEndPoint}/publication-exists`, { params });
    }
    getPurchaseGroupWhereUserIsAdministrator(user) {
        var _a;
        return ((_a = user.hospital.groups
            // only Purchase group
            .filter((group) => group.type === EGroupType.Purchase)
            // only group where `user` is administrator
            .filter((group) => group.administrators.find((administrator) => Utils.compareIds(administrator, user)))) === null || _a === void 0 ? void 0 : _a[0]); // `user` can be coordinator of only one purchase group
    }
    getOperatorsList() {
        return this._httpClient.get(`${this._viewEndPoint}/labos`);
    }
    getDepositary() {
        const endpoint = `${this._commonEndPoint}/depositary`;
        return this._httpClient.get(endpoint);
    }
    getWholesaler() {
        const endpoint = `${this._commonEndPoint}/wholesaler`;
        return this._httpClient.get(endpoint);
    }
    getTypeLabel(value) {
        const publicationType = Object.values(CHsPublicationType).find((type) => type.value === value);
        // Guard
        if (!publicationType) {
            throw new Error('Cannot get HsPublicationType label, no CPublicationType is corresponding to the value : ' + value);
        }
        return this._translate(publicationType.translate);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ PFIZER ...
    // -----------------------------------------------------------------------------------------------------
    _initPfizerdata() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const pfizerOperator = yield lastValueFrom(this._laboratoryService.getPfizerLaboratory());
            this.isPfizerOperator.next(Utils.compareIds(this._access.hospital, pfizerOperator));
            this._pfizerTheriaqueNames = (_a = pfizerOperator === null || pfizerOperator === void 0 ? void 0 : pfizerOperator.theriaqueNames) === null || _a === void 0 ? void 0 : _a.map(({ name }) => name);
        });
    }
    /**
     * The function only exists to know if a publication was created by Pfizer so we can display a dedicated label when a
     * publication is terminated...
     * (Do not hesitate to remove if the specific label is not used anymore)
     */
    isPfizerLaboExp(publication) {
        var _a;
        // PFIZER does not sell medical devices
        if (this._healthProductService.isDrugRef(publication.healthProductRef)) {
            return !!((_a = this._pfizerTheriaqueNames) === null || _a === void 0 ? void 0 : _a.includes(publication.healthProductRef.operator));
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Metadata
    // -----------------------------------------------------------------------------------------------------
    _createMetadata(publicationId, staticMetadata) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/metadata`;
        // Create metadata and assign attributes if provided
        const newMetadata = Object.assign({ publication: publicationId }, staticMetadata);
        return this._httpClient.post(endpoint, newMetadata);
    }
    _updateMetadata(staticMetadata) {
        const endpoint = `${this._viewEndPoint}/metadata/${staticMetadata._id}`;
        return this._httpClient.put(endpoint, staticMetadata);
    }
    _readDynamicMetadata(publicationId) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/metadata/dynamic`;
        return this._httpClient.get(endpoint);
    }
    _updatePublicationStaticMetadata(publicationId, staticMetadata) {
        // Get publicationContainer's reference
        const publicationContainer$ = this._findPublicationContainerByPublicationId(publicationId);
        // Update metadata to local publication
        Object.assign(publicationContainer$.value.metadata, { static: staticMetadata });
        publicationContainer$.next(publicationContainer$.value);
    }
    _updatePublicationDynamicMetadata(publicationId, dynamicMetadata) {
        // Get publicationContainer's reference
        const publicationContainer$ = this._findPublicationContainerByPublicationId(publicationId);
        // Update metadata to local publication
        Object.assign(publicationContainer$.value.metadata, { dynamic: dynamicMetadata });
        publicationContainer$.next(publicationContainer$.value);
    }
    setMetadataImportant(publicationId, metadata) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.readonly === true) {
                return;
            }
            // Deep copy to prevent update before server response
            const staticMetadata = (_a = cloneDeep(metadata)) === null || _a === void 0 ? void 0 : _a.static;
            const toggleImportant = (staticMetadata) => {
                staticMetadata.important = !staticMetadata.important;
                return staticMetadata;
            };
            const updatedStaticMetadata = yield lastValueFrom(staticMetadata
                ? this._updateMetadata(toggleImportant(staticMetadata))
                : this._createMetadata(publicationId, new this.publicationMetadataConstructor({ important: true })));
            this._updatePublicationStaticMetadata(publicationId, updatedStaticMetadata);
        });
    }
    setMetadataSupervisor(publicationId, metadata, supervisor) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Deep copy to prevent update before server response
            const staticMetadata = (_a = cloneDeep(metadata)) === null || _a === void 0 ? void 0 : _a.static;
            const updateSupervisor = (metadata) => {
                return Object.assign(metadata, { supervisor });
            };
            const updatedStaticMetadata = yield lastValueFrom(staticMetadata
                ? this._updateMetadata(updateSupervisor(staticMetadata))
                : this._createMetadata(publicationId, new this.publicationMetadataConstructor({ supervisor })));
            this._updatePublicationStaticMetadata(publicationId, updatedStaticMetadata);
        });
    }
    refreshDynamicMetadata(publicationId) {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedStaticMetadata = yield lastValueFrom(this._readDynamicMetadata(publicationId));
            this._updatePublicationDynamicMetadata(publicationId, updatedStaticMetadata);
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Alternative
    // -----------------------------------------------------------------------------------------------------
    readHospitalsByAlternative(publicationId, alternativeId) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/alternatives/${alternativeId}/hospitals`;
        return this._httpClient.get(endpoint);
    }
    readAlternativesByPublication(publicationId) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/alternatives`;
        return this._httpClient.get(endpoint);
    }
    createAlternatives(publicationId, newAlternative) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/alternatives`;
        return this._httpClient.post(endpoint, newAlternative);
    }
    selectAlternative(selectedAlternativesIds) {
        var _a;
        const staticMetadata = (_a = this.currentPublicationContainer$$.value) === null || _a === void 0 ? void 0 : _a.value.metadata.static;
        staticMetadata.selectedAlternatives = selectedAlternativesIds.map((selectedAlternativesId) => ({
            alternative: selectedAlternativesId,
            date: new Date()
        }));
        return this._updateMetadata(staticMetadata);
    }
    validAlternative(alternativesId) {
        var _a;
        const staticMetadata = (_a = this.currentPublicationContainer$$.value) === null || _a === void 0 ? void 0 : _a.value.metadata.static;
        staticMetadata.validAlternatives.push({
            alternative: alternativesId,
            date: new Date()
        });
        return this._updateMetadata(staticMetadata);
    }
    refuseAlternative(alternativesId) {
        var _a;
        const staticMetadata = (_a = this.currentPublicationContainer$$.value) === null || _a === void 0 ? void 0 : _a.value.metadata.static;
        const indexToRefuse = staticMetadata.validAlternatives.findIndex((value) => value.alternative === alternativesId);
        if (indexToRefuse !== -1) {
            staticMetadata.validAlternatives.splice(indexToRefuse, 1);
            return this._updateMetadata(staticMetadata);
        }
    }
    updateAlternative(alternativeId, editAlternative) {
        const endpoint = `${this._viewEndPoint}/alternatives/${alternativeId}`;
        return this._httpClient.put(endpoint, editAlternative);
    }
    deleteAlternative(alternativeId) {
        const endpoint = `${this._viewEndPoint}/alternatives/${alternativeId}`;
        return this._httpClient.delete(endpoint, { responseType: 'text' });
    }
    /**
     * Get creator name depending of the creatorType.
     * The creator name is get from the creatorGroup if creator type is GA and from creator otherwise.
     * @returns Creator name to display.
     */
    readAlternativeCreatorName(alternative) {
        const getName = (creator) => typeof creator === 'string' ? creator : creator.name;
        return alternative.creatorType === EHsUserTypes.GA
            ? getName(alternative.creatorGroup)
            : getName(alternative.creator);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Comments
    // -----------------------------------------------------------------------------------------------------
    readComments(publicationId) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/comments`;
        return this._httpClient.get(endpoint);
    }
    createComment(publicationId, text, shareComment) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/comments`;
        const newComment = {
            text: text,
            authorType: null,
            author: this._identityService.user._id,
            isShared: (shareComment === null || shareComment === void 0 ? void 0 : shareComment.valueOf) ? shareComment : null,
            publication: publicationId
        };
        return this._httpClient.post(endpoint, newComment);
    }
    updateComment(commentId, comment) {
        const endpoint = `${this._viewEndPoint}/comments/${commentId}`;
        return this._httpClient.put(endpoint, comment);
    }
    deleteComment(publicationId) {
        const endpoint = `${this._viewEndPoint}/comments/${publicationId}`;
        return this._httpClient.delete(endpoint, { responseType: 'text' });
    }
    readHistory(publicationId) {
        const endpoint = `${this._viewEndPoint}/${publicationId}/history`;
        return this._httpClient.get(endpoint);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Files
    // -----------------------------------------------------------------------------------------------------
    /**
     * Download spreadsheet with all publications matching to the current user view.
     * @param search
     * @param sort
     * @param filters
     */
    downloadPublicationsSpreadsheet(search, sort, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._downloadService.downloadPublicationsSpreadsheet(search, sort, filters, this.viewType);
        });
    }
    /**
     * Download spreadsheet with statistics related to the provided publication.
     * @param publication
     */
    downloadPublicationStatSpreadsheet(publication) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._downloadService.downloadPublicationStatSpreadsheet(publication, this.viewType);
        });
    }
    /**
     * Download spreadsheet with statistics related to all publications.
     * @param publication
     */
    downloadGlobalStatSpreadsheet(search, sort, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._downloadService.downloadGlobalStatSpreadsheet(search, sort, filters, this.viewType);
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Tags
    // -----------------------------------------------------------------------------------------------------
    _computeDynamicTags(metadata) {
        var _a;
        return (_a = metadata.dynamic) === null || _a === void 0 ? void 0 : _a.tags.filter((tag) => { var _a; return !((_a = metadata.static) === null || _a === void 0 ? void 0 : _a.tags.includes(tag)); }).map((tag) => getInfoFromTag(tag));
    }
    _computeStaticTags(metadata) {
        var _a;
        return ((_a = metadata.static) === null || _a === void 0 ? void 0 : _a.tags.map((tag) => getInfoFromTag(tag))) || [];
    }
    computeTags(metadata) {
        return [
            ...this._computeDynamicTags(metadata).map((tag) => Object.assign(tag, { isDynamic: true })),
            ...this._computeStaticTags(metadata)
        ].sort((a, b) => a.rank - b.rank);
    }
    /**
     * Toggle tag on checked publications
     *
     * @param tag
     */
    toggleTagOnCheckedPublications(tag) {
        const checkedPublicationContainers = this.checkedPublicationContainers$.value;
        // Guard: at least one publication is checked
        if (checkedPublicationContainers.length === 0) {
            console.error('No publication checked: cannot update tags.');
        }
        const numberOfTaggedPublications = checkedPublicationContainers.filter(({ metadata }) => { var _a; return (_a = metadata.static) === null || _a === void 0 ? void 0 : _a.tags.includes(tag); }).length;
        const untagPublication = (staticMetadata) => {
            // Remove tag
            const index = staticMetadata.tags.indexOf(tag);
            if (index !== -1) {
                staticMetadata.tags.splice(index, 1);
            }
            return staticMetadata;
        };
        const tagPublication = (staticMetadata) => {
            // Add tag if metadata does not include it
            if (!staticMetadata.tags.includes(tag)) {
                staticMetadata.tags.push(tag);
            }
            return staticMetadata;
        };
        // Untag publications if all tagged otherwise tags all publications
        const allPublicationsAreTagged = numberOfTaggedPublications === checkedPublicationContainers.length;
        const handlePublicationTag = allPublicationsAreTagged ? untagPublication : tagPublication;
        checkedPublicationContainers.forEach((publicationContainer) => __awaiter(this, void 0, void 0, function* () {
            // Deep copy to prevent update before server response
            const staticMetadata = cloneDeep(publicationContainer.metadata).static;
            const publicationId = Utils.getId(publicationContainer.publication);
            const updatedStaticMetadata = yield lastValueFrom(staticMetadata
                ? this._updateMetadata(handlePublicationTag(staticMetadata))
                : this._createMetadata(publicationId, new this.publicationMetadataConstructor(handlePublicationTag({ tags: [] }))));
            // Update metadata
            this._updatePublicationStaticMetadata(Utils.getId(publicationContainer.publication), updatedStaticMetadata);
        }));
    }
    getAllHospitalStatsByPublication(publication) {
        return __awaiter(this, void 0, void 0, function* () {
            const publicationId = Utils.getId(publication);
            const endpoint = `${this._viewEndPoint}/${publicationId}/pharmacy-stats`;
            return lastValueFrom(this._httpClient.get(endpoint));
        });
    }
};
HsPublicationContainerService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof Access !== "undefined" && Access) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _b : Object, typeof (_c = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _c : Object, typeof (_d = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _d : Object, typeof (_e = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _e : Object, typeof (_f = typeof HsLaboratoryService !== "undefined" && HsLaboratoryService) === "function" ? _f : Object, typeof (_g = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _g : Object, typeof (_h = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _h : Object, typeof (_j = typeof StateService !== "undefined" && StateService) === "function" ? _j : Object, typeof (_k = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _k : Object, typeof (_l = typeof UrlService !== "undefined" && UrlService) === "function" ? _l : Object, typeof (_m = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _m : Object, typeof (_o = typeof Injector !== "undefined" && Injector) === "function" ? _o : Object, typeof (_p = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _p : Object])
], HsPublicationContainerService);
export { HsPublicationContainerService };
