export class MpModalVaultUnlockController {
    /** @ngInject */
    constructor(VaultService) {
        this.VaultService = VaultService;
        this.submit = () => {
            this.VaultService.unlock(this.password).catch(() => {
                this.invalidPasswords.push(this.password);
            });
        };
        this.invalidPasswords = [];
        console.log('VAULT LOCK CTOR');
    }
}
