var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SearchHospitalComponent } from '@shared/components/search-hospital/search-hospital.component';
let ExSearchHospitalComponent = class ExSearchHospitalComponent {
    constructor() {
        this.formControl = new UntypedFormControl();
        this.pageHeaderConfig = {
            title: 'Search Hospital Component',
            icon: 'extension '
        };
    }
    onHospitalSelection(event) {
        console.log('onHospitalSelection: ', event);
    }
    reset() {
        this._searchHospitalChild.reset();
    }
};
__decorate([
    ViewChild(SearchHospitalComponent, { static: false }),
    __metadata("design:type", typeof (_a = typeof SearchHospitalComponent !== "undefined" && SearchHospitalComponent) === "function" ? _a : Object)
], ExSearchHospitalComponent.prototype, "_searchHospitalChild", void 0);
ExSearchHospitalComponent = __decorate([
    Component({
        selector: 'ex-search-hospital',
        template: require('./ex-search-hospital.component.html').default,
        styles: [require('./ex-search-hospital.component.scss')]
    })
], ExSearchHospitalComponent);
export { ExSearchHospitalComponent };
