var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { SpreadsheetParserService } from '@services/spreadsheet-parser/spreadsheet-parser.service';
import { FileErrorSeverity } from '@services/spreadsheet-parser/spreadsheet-parser.types';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { SpreadsheetImportModalState, SpreadsheetImportModalStatus } from './spreadsheet-import-modal.types';
import { ESpreadsheetImportReportListTypes, SpreadsheetImportReportList } from './spreadsheet-import-report-list/spreadsheet-import-report-list.types';
let SpreadsheetImportModalComponent = class SpreadsheetImportModalComponent {
    constructor(_data, _changeDetectorRef, _dialogRef, _fuseTranslationLoaderService, _snackbarService, _spreadsheetParserService, _translationService) {
        var _a, _b, _c, _d;
        this._data = _data;
        this._changeDetectorRef = _changeDetectorRef;
        this._dialogRef = _dialogRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackbarService = _snackbarService;
        this._spreadsheetParserService = _spreadsheetParserService;
        this._translationService = _translationService;
        // Bind enums to view
        this.ESpreadsheetImportModalState = SpreadsheetImportModalState;
        this.ESpreadsheetImportModalStatus = SpreadsheetImportModalStatus;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init state & status
        this.state = SpreadsheetImportModalState.SELECTING;
        this.status = SpreadsheetImportModalStatus.PENDING;
        // Extract templates
        this.parsingReportTemplate = (_a = this._data.parsing.report) === null || _a === void 0 ? void 0 : _a.template;
        this.importReportTemplate = (_b = this._data.import.report) === null || _b === void 0 ? void 0 : _b.template;
        // Init default configs
        this._defaultParsingReportConfig = new Map([
            [
                ESpreadsheetImportReportListTypes.ERROR,
                {
                    status: this._translate('MESSAGES.PARSING.REPORT.ERROR.STATUS'),
                    instructions: this._translate('MESSAGES.PARSING.REPORT.ERROR.INSTRUCTIONS')
                }
            ],
            [
                ESpreadsheetImportReportListTypes.WARNING,
                {
                    status: this._translate('MESSAGES.PARSING.REPORT.WARNING.STATUS'),
                    instructions: this._translate('MESSAGES.PARSING.REPORT.WARNING.INSTRUCTIONS')
                }
            ],
            [
                ESpreadsheetImportReportListTypes.INFO,
                {
                    status: this._translate('MESSAGES.PARSING.REPORT.INFO.STATUS'),
                    instructions: this._translate('MESSAGES.PARSING.REPORT.INFO.INSTRUCTIONS')
                }
            ],
            [
                ESpreadsheetImportReportListTypes.SUCCESS,
                {
                    hide: true
                }
            ]
        ]);
        this._defaultImportReportConfig = new Map([
            [
                ESpreadsheetImportReportListTypes.ERROR,
                {
                    status: this._translate('MESSAGES.IMPORT.REPORT.ERROR.STATUS'),
                    instructions: this._translate('MESSAGES.IMPORT.REPORT.ERROR.INSTRUCTIONS')
                }
            ],
            [
                ESpreadsheetImportReportListTypes.WARNING,
                {
                    status: this._translate('MESSAGES.IMPORT.REPORT.WARNING.STATUS'),
                    instructions: this._translate('MESSAGES.IMPORT.REPORT.WARNING.INSTRUCTIONS')
                }
            ],
            [
                ESpreadsheetImportReportListTypes.INFO,
                {
                    status: this._translate('MESSAGES.IMPORT.REPORT.INFO.STATUS'),
                    instructions: this._translate('MESSAGES.IMPORT.REPORT.INFO.INSTRUCTIONS')
                }
            ],
            [
                ESpreadsheetImportReportListTypes.SUCCESS,
                {
                    status: this._translate('MESSAGES.IMPORT.REPORT.SUCCESS.STATUS'),
                    instructions: this._translate('MESSAGES.IMPORT.REPORT.SUCCESS.INSTRUCTIONS')
                }
            ]
        ]);
        // Setup configs
        this.parsingReportConfig = this._setupConfigMap((_c = this._data.parsing.report) === null || _c === void 0 ? void 0 : _c.config, this._defaultParsingReportConfig);
        this.importReportConfig = this._setupConfigMap((_d = this._data.import.report) === null || _d === void 0 ? void 0 : _d.config, this._defaultImportReportConfig);
    }
    get title() {
        return this._data.title;
    }
    get acceptedExtensions() {
        return this._data.parsing.acceptedExtensions;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    // Guard: ensure item's type is in ESpreadsheetImportReportListTypes before using map
    _typeGuard(type) {
        if (!(type in ESpreadsheetImportReportListTypes)) {
            console.warn(`Cannot handle type ${type}, because it does not match a ESpreadsheetImportReportListTypes.`);
            return;
        }
    }
    _setupConfigMap(map, defaultMap) {
        const resultMap = new Map();
        Utils.enumToValues(ESpreadsheetImportReportListTypes).forEach((type) => {
            this._typeGuard(type);
            const element = Object.assign({}, defaultMap.get(type), map.get(type));
            resultMap.set(type, element);
        });
        return resultMap;
    }
    /**
     * Bind TranslationService's instant method
     * @param value Text to translate
     * @param arg Translation arguments
     * @returns Translated text
     */
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    /**
     * Return data to parent component
     */
    _parseSpreadsheet() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Convert spreadsheet
            const { file, errors } = yield this._spreadsheetParserService.parseAsJson(this.fileContainer, this._data.parsing.config);
            // Update fileErrorContainer
            this.fileErrorContainer = errors;
            // Handle abortOnMajorError
            if (((_a = this._data.parsing.config.options) === null || _a === void 0 ? void 0 : _a.abortOnMajorError) &&
                this.fileErrorContainer.hasErrors(FileErrorSeverity.MAJOR)) {
                throw this.fileErrorContainer.major;
            }
            return file;
        });
    }
    /**
     * Build reportList from parsing result
     * @param fileErrorContainer
     * @returns
     */
    _fileErrorContainerToReportList(fileErrorContainer) {
        // Guard: undefined fileErrorContainer
        if (!fileErrorContainer) {
            return null;
        }
        const fileErrorSeverityToReportListTypesMap = new Map([
            [FileErrorSeverity.MAJOR.toString(), ESpreadsheetImportReportListTypes.ERROR],
            [FileErrorSeverity.MODERATE.toString(), ESpreadsheetImportReportListTypes.WARNING],
            [FileErrorSeverity.MINOR.toString(), ESpreadsheetImportReportListTypes.INFO]
        ]);
        const map = new Map();
        Object.values(fileErrorContainer).forEach((item) => {
            const type = fileErrorSeverityToReportListTypesMap.get(item.severity);
            const config = this.parsingReportConfig.get(type);
            const items = item.errors;
            map.set(type, { config, items });
        });
        return new SpreadsheetImportReportList(map);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Handler for dragNDropComponent onLoadedFilesChanged event output
     * @param fileContainerSet Files after change
     */
    updateLoadedFiles(fileContainerSet) {
        const iterator = fileContainerSet.values();
        this.fileContainer = iterator.next().value;
    }
    parseFile() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // Start parsing
                this.state = SpreadsheetImportModalState.PARSING;
                this.status = SpreadsheetImportModalStatus.PENDING;
                // Manually trigger rendering
                this._changeDetectorRef.detectChanges();
                // Parse spreadsheet
                this.file = yield this._parseSpreadsheet();
                // Generate report
                this.parsingReportList = this._fileErrorContainerToReportList(this.fileErrorContainer);
                // End parsing
                const elementsToImport = this.file.content.length > 0;
                this.status = elementsToImport ? SpreadsheetImportModalStatus.DONE : SpreadsheetImportModalStatus.EMPTY;
                // Manually trigger rendering
                this._changeDetectorRef.detectChanges();
            }
            catch (err) {
                this.status = SpreadsheetImportModalStatus.FAILED;
                // Manually trigger rendering
                this._changeDetectorRef.detectChanges();
                // Handle error & snackbar
                console.error('Error during spreadsheet import:', err);
                this._snackbarService.openCustomError(this._translationService.instant('ERRORS.PARSING_ERROR'));
                // Generate report
                this.parsingReportList = this._fileErrorContainerToReportList(this.fileErrorContainer);
            }
        });
    }
    importFile() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // File guard
                if (this.file === undefined) {
                    throw new Error('Cannot proceed to import, file is undefined');
                }
                // Start import
                this.state = SpreadsheetImportModalState.IMPORTING;
                this.status = SpreadsheetImportModalStatus.PENDING;
                // Execute @Input upload function
                this.importReportList = yield this._data.import.uploadFn(this.file, this.importReportConfig);
                // Set this.status according to the importReportList listed successes, infos, errors or warnings.
                this._generateReportStatus();
                this._changeDetectorRef.detectChanges();
            }
            catch (err) {
                this.status = SpreadsheetImportModalStatus.FAILED;
                // Manually trigger rendering
                this._changeDetectorRef.detectChanges();
                // Handle error & snackbar
                console.error('Error during spreadsheet import:', err);
                this._snackbarService.openCustomError(this._translationService.instant('ERRORS.IMPORT_ERROR'));
            }
        });
    }
    /**
     * Close dialog by returning is isFileUploaded
     */
    closeDialog() {
        const isImportDone = this.state === SpreadsheetImportModalState.IMPORTING &&
            (this.status === SpreadsheetImportModalStatus.DONE || this.status === SpreadsheetImportModalStatus.WARNING);
        this._dialogRef.close(isImportDone);
    }
    _generateReportStatus() {
        var _a, _b, _c, _d;
        const hasSuccess = ((_a = this.importReportList.map.get(ESpreadsheetImportReportListTypes.SUCCESS)) === null || _a === void 0 ? void 0 : _a.items.length) > 0 ||
            ((_b = this.importReportList.map.get(ESpreadsheetImportReportListTypes.INFO)) === null || _b === void 0 ? void 0 : _b.items.length) > 0;
        const hasWarning = ((_c = this.importReportList.map.get(ESpreadsheetImportReportListTypes.WARNING)) === null || _c === void 0 ? void 0 : _c.items.length) > 0;
        const hasError = ((_d = this.importReportList.map.get(ESpreadsheetImportReportListTypes.ERROR)) === null || _d === void 0 ? void 0 : _d.items.length) > 0;
        // Only success
        if (hasSuccess && !hasError && !hasWarning) {
            this.status = SpreadsheetImportModalStatus.DONE;
        }
        else if (hasError && !hasSuccess && !hasWarning) {
            // Only triggered when there are only errors on the report list.
            this.status = SpreadsheetImportModalStatus.FAILED;
        }
        else {
            // For all other cases, trigger warning to explain user that something weird has happened.
            this.status = SpreadsheetImportModalStatus.WARNING;
        }
    }
};
SpreadsheetImportModalComponent = __decorate([
    Component({
        selector: 'mpx-spreadsheet-import-modal',
        template: require('./spreadsheet-import-modal.component.html').default,
        styles: [require('./spreadsheet-import-modal.component.scss')],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [Object, typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof SpreadsheetParserService !== "undefined" && SpreadsheetParserService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object])
], SpreadsheetImportModalComponent);
export { SpreadsheetImportModalComponent };
