export const locale = {
    lang: 'fr',
    data: {
        admHospitalDetail: {
            EDIT_TITLE: `Officine - `,
            NEW_TITLE: `Nouvelle officine`,
            TABS: {
                INFORMATION: `Informations`,
                OTHER: `Autres`,
                PERMISSIONS: `Actions / Permissions`,
                MEMBERS: `Membres`
            },
            INFORMATION: {
                NAME: `Nom de l'officine`,
                ADDRESS: `Adresse`,
                NUMBER: `Numéro`,
                WAY: `Voie`,
                ZIP: `Code postal`,
                CITY: `Ville`,
                COUNTRY: `Pays`,
                CONTACT: `Coordonnées`,
                PHONE_STD: `Téléphone standard`,
                PHONE_PHARMA: `Téléphone de l'officine`,
                FAX: `Fax`,
                WEBSITE: `Site web`,
                CAPACITY: `Capacité`,
                BED: {
                    MCO: `Lits MCO`,
                    SSR: `Lits SSR`,
                    PSY: `Lits PSY`,
                    EPHAD: `Lits EPHAD`
                },
                LOCATION: `Localisation`,
                REGION: `Région`,
                LATITUDE: `Latitude`,
                LONGITUDE: `Longitude`,
                OTHER: `Autres informations`,
                FINESS: `FINESS`,
                FINESSJUR: `FINESS Juridique`,
                APPLY_FINESS: `Pré-remplir`,
                LOGO: `Logo de l'officine`
            },
            ACTIONS: {
                ROLE_MAPUI: `Rôle MaPUI`,
                ROLE_HOSPIVILLE: `Rôle Hospiville`,
                ROLE_WORKSPACE: `Rôle Workspace`,
                REGISTERED: `Enregistré`,
                ACTIVE: `Actif`,
                MEMBER_SINCE: `Membre depuis`,
                MEMBER_TO: `Membre jusqu'à`,
                TRIAL_END: `Fin période d'essai`,
                ADD_DAYS: `Ajouter 30 jours`,
                REMOVE_DAYS: `Retirer 30 jours`,
                MAX_DATA: `Espace disponible (Go)`,
                LOG_AS: {
                    LONG: `Se connecter dans l'officine`,
                    SHORT: `Se connecter`,
                    CONFIRM_DIALOG: {
                        TITLE: 'Se connecter en tant que...',
                        MESSAGE: `Voulez-vous vous connecter dans l'établissement {{establishment}} ?`
                    }
                },
                DELETE: {
                    LONG: `Supprimer l'officine`,
                    SHORT: `Supprimer`,
                    CONFIRM_DIALOG: {
                        TITLE: `Supprimer l'établissement`,
                        MESSAGE: `Voulez-vous vraiment supprimer l'établissement {{establishment}} ?`
                    }
                },
                SNACK_DELETE: `L'hôpital a été supprimé.`,
                VALID: `Valider`,
                ERROR: `Erreur`
            },
            MEMBERS: {
                NAME: `Nom`,
                EMAIL: `Email`,
                LAST_CONNECT: `Dernière connexion`,
                ROLES: `Rôles`,
                EMPTY: `Cette officine n'a aucun membre.`
            },
            EDIT_OK: `L'officine a été modifiée !`,
            CREATE_OK: `L'officine a été créée !`
        }
    }
};
