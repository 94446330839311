var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from '../../../services/dialog/dialog.service';
let ExGenericDialogComponent = class ExGenericDialogComponent {
    constructor(_dialogService) {
        this._dialogService = _dialogService;
        this.withoutConfigReturn = null;
        this.withConfigReturn = null;
        this.config = {
            title: 'Titre',
            message: 'Un joli message ?',
            confirmButton: 'OUI !!',
            cancelButton: 'Non...'
        };
        this.pageHeaderConfig = {
            title: 'Generic Dialog Component',
            icon: 'extension '
        };
    }
    openGenericDialog(config) {
        return this._dialogService.openConfirmDialog(config);
    }
    openWithoutConfig() {
        this.openGenericDialog().subscribe((data) => {
            this.withoutConfigReturn = data;
        });
    }
    openWithConfig() {
        this.openGenericDialog(this.config).subscribe((data) => {
            this.withConfigReturn = data;
        });
    }
};
ExGenericDialogComponent = __decorate([
    Component({
        selector: 'ex-generic-dialog',
        template: require('./ex-generic-dialog.component.html').default,
        styles: [require('./ex-generic-dialog.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object])
], ExGenericDialogComponent);
export { ExGenericDialogComponent };
