export const locale = {
    lang: 'fr',
    data: {
        ONLINE_USERS: {
            TITLE: 'Utilisateurs en ligne',
            SEARCH: 'Rechercher un utilisateur',
            TABLE: {
                NAME: 'Nom',
                EMAIL: 'Mail',
                HOSPITAL: 'Hôpital',
                GROUP: 'Groupe',
                STATUS: {
                    TITLE: 'Statut',
                    ONLINE: 'En ligne',
                    OFFLINE: 'Hors ligne'
                },
                SINCE: 'Depuis',
                USER_DELETED: 'Utilisateur supprimé',
                EMPTY: "Pas d'utilisateur en ligne trouvé.",
                DETAIL: {
                    USER_PAGE: 'Page utilisateur',
                    WEEK: 'Semaine ',
                    DURATION: 'Durée',
                    FROM: 'De',
                    TO: 'À',
                    EMPTY: "L'utilisateur n'a pas de session cette semaine."
                }
            }
        }
    }
};
