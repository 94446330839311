/**
 * Created by Eygle on 15/02/2017.
 */
export class MpUploadsComponent {
    constructor() {
        this.controller = 'MpUploadsController';
        this.controllerAs = 'vm';
        this.template = require('./mp-uploads.html').default;
        this.bindings = {
            uploader: '<',
            show: '=',
            onChangeDir: '&',
        };
    }
}
