export const rolesSubscriptions = {
    free: {
        value: 1,
        translate: 'ROLES.SUBSCRIPTIONS.FREE'
    },
    trial: {
        value: 2,
        translate: 'ROLES.SUBSCRIPTIONS.TRIAL'
    },
    subscribed: {
        value: 3,
        translate: 'ROLES.SUBSCRIPTIONS.SUBSCRIBED'
    },
    none: {
        value: 4,
        translate: 'ROLES.SUBSCRIPTIONS.NONE'
    }
};
