var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { Component, Input } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../../../i18n/fr';
import { locale as english } from '../../../i18n/en';
import { FilterManager } from '../../../../filter-manager.class';
import { ExchangeMapService } from '../../../../../../exchange/map/map-exchange.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { LoanService } from '@services/loan/loan.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { AvailableRequestService } from '../../../../../../exchange/available/available-request/available-request.service';
import { Utils } from '@main/services/Utils';
import { StateService } from '@uirouter/core';
let MapMadSearchComponent = class MapMadSearchComponent {
    constructor(_fuseTranslationLoaderService, _fuseSidebarService, exchangeMapService, dashboardService, _loanService, _snackbarService, _availableRequestService, _stateService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseSidebarService = _fuseSidebarService;
        this.exchangeMapService = exchangeMapService;
        this.dashboardService = dashboardService;
        this._loanService = _loanService;
        this._snackbarService = _snackbarService;
        this._availableRequestService = _availableRequestService;
        this._stateService = _stateService;
        this.searchMad = '';
        this.availablesData = [];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    get searchHospital() {
        var _a;
        return (_a = this.filterManager.getFilter('search')) === null || _a === void 0 ? void 0 : _a.value;
    }
    search() {
        this.availablesData = [];
        this.availablePaginator = null;
        if (this.searchMad.length > 0) {
            this.updateAvailablesList();
        }
    }
    updateAvailablesList() {
        const pageSize = 10;
        const nextPage = this.getNewPageNumber();
        if (nextPage > 0) {
            this._availableRequestService
                .getPageAvailableOffer({
                page: nextPage,
                limit: pageSize
            }, this.searchMad, {
                active: 'hospital.name',
                direction: 'asc'
            })
                .subscribe((available) => {
                this.availablePaginator = available;
                this.availablesData = [...this.availablesData, ...available.items];
            });
        }
    }
    getNewPageNumber() {
        if (!this.availablePaginator) {
            return 1;
        }
        if (this.availablePaginator.page < this.availablePaginator.total / this.availablePaginator.limit) {
            return this.availablePaginator.page + 1;
        }
        return -1;
    }
    redirectToAvailable(available) {
        this._stateService.go('mapui.exchangeV2.available.available.available', {
            id: Utils.getId(available)
        });
    }
    setCurrentName(currentName) {
        this.currentName = currentName;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_j = typeof FilterManager !== "undefined" && FilterManager) === "function" ? _j : Object)
], MapMadSearchComponent.prototype, "filterManager", void 0);
MapMadSearchComponent = __decorate([
    Component({
        selector: 'map-mad-search',
        template: require('./mad-search.component.html').default,
        styles: [require('./mad-search.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _b : Object, typeof (_c = typeof ExchangeMapService !== "undefined" && ExchangeMapService) === "function" ? _c : Object, typeof (_d = typeof DashboardService !== "undefined" && DashboardService) === "function" ? _d : Object, typeof (_e = typeof LoanService !== "undefined" && LoanService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object, typeof (_g = typeof AvailableRequestService !== "undefined" && AvailableRequestService) === "function" ? _g : Object, typeof (_h = typeof StateService !== "undefined" && StateService) === "function" ? _h : Object])
], MapMadSearchComponent);
export { MapMadSearchComponent };
