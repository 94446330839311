import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MedicalDeviceService {
    /** @ngInject */
    constructor(Api, $q) {
        this.Api = Api;
        this.$q = $q;
        this.findByName = (query) => {
            const defer = this.$q.defer();
            if (query.length < 3) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.medicalDevice.byName.get({ name: encodeURI(query) }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
    ;
}
