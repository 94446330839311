var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import { ListActivitiesSourceViewComponent } from './list-activities-source-view/list-activities-source-view.component';
import { ListActivitiesDestViewComponent } from './list-activities-dest-view/list-activities-dest-view.component';
const states = [
    {
        name: 'hospivilleV2.activities.source.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@hospivilleV2': {
                component: ListActivitiesSourceViewComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-light-green',
            access: EHospivillePermission.Activities_Read
        }
    },
    {
        name: 'hospivilleV2.activities.dest.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@hospivilleV2': {
                component: ListActivitiesDestViewComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-light-green',
            access: EHospivillePermission.Activities_Read
        }
    }
];
let ListActivitiesRoutingModule = class ListActivitiesRoutingModule {
};
ListActivitiesRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], ListActivitiesRoutingModule);
export { ListActivitiesRoutingModule };
