var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EAdminPanelPermission, EHTTPStatus, Hospital } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { FileSaverService } from 'ngx-filesaver';
import { lastValueFrom, throwError } from 'rxjs';
let AdminHospitalService = class AdminHospitalService extends CrudTemplateService {
    constructor(__injector, _fileSaverService) {
        super(__injector, Hospital, '/api/admin/hospitals', {
            create: EAdminPanelPermission.Establishments_Create,
            read: EAdminPanelPermission.Establishments_Read,
            update: EAdminPanelPermission.Establishments_Edit,
            delete: EAdminPanelPermission.Establishments_Delete
        });
        this.__injector = __injector;
        this._fileSaverService = _fileSaverService;
    }
    searchByName(search) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if ((search === null || search === void 0 ? void 0 : search.length) < 3) {
            return;
        }
        let params = new HttpParams();
        params = params.append('search', search);
        return this._http.get(this._endPoint, { params: params });
    }
    //retrieve a xlsx spreadsheet matching the passed options
    getSpreadsheetAndDownload(search, sort, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._permissionService.authorize(this._permissions.read)) {
                throwError(() => EHTTPStatus.Unauthorized);
                return;
            }
            const spreadsheetEndPoint = '/xlsx-generation/hospitals.xlsx?xlsxmodel=hospitals';
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (filters) {
                params = params.append('filters', JSON.stringify(filters));
            }
            if (sort) {
                params = params.append('sorting', JSON.stringify(sort));
            }
            const headers = new HttpHeaders({
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const res = yield lastValueFrom(this._http.get(spreadsheetEndPoint, {
                params: params,
                headers: headers,
                responseType: 'arraybuffer'
            }));
            const blob = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            this._fileSaverService.save(blob, 'hospitals.xls');
        });
    }
};
AdminHospitalService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof FileSaverService !== "undefined" && FileSaverService) === "function" ? _b : Object])
], AdminHospitalService);
export { AdminHospitalService };
