var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ActionsTabComponent } from './detail-labos/actions-tab/actions-tab.component';
import { AdminDetailLabosComponent } from './detail-labos/detail-labos.component';
import { GroupsTabComponent } from './detail-labos/groups-tab/groups-tab.component';
import { HospistockTabComponent } from './detail-labos/hospistock-tab/hospistock-tab.component';
import { InformationTabComponent } from './detail-labos/information-tab/information-tab.component';
import { MembersTabComponent } from './detail-labos/members-tab/members-tab.component';
import { LaboGroupDetailComponent } from './labo-group-detail/labo-group-detail.component';
import { LaboGroupHospitalsTabComponent } from './labo-group-detail/labo-group-hospitals-tab/labo-group-hospitals-tab.component';
import { LaboGroupInformationTabComponent } from './labo-group-detail/labo-group-information-tab/labo-group-information-tab.component';
import { AdminLabosRoutingModule } from './labos-routing.module';
import { AdminListLabosComponent } from './list-labos/list-labos.component';
let AdminLabosModule = class AdminLabosModule {
};
AdminLabosModule = __decorate([
    NgModule({
        declarations: [
            AdminListLabosComponent,
            AdminDetailLabosComponent,
            InformationTabComponent,
            MembersTabComponent,
            ActionsTabComponent,
            GroupsTabComponent,
            LaboGroupDetailComponent,
            LaboGroupInformationTabComponent,
            LaboGroupHospitalsTabComponent,
            HospistockTabComponent
        ],
        imports: [
            CommonModule,
            UIRouterModule,
            BrowserAnimationsModule,
            AdminLabosRoutingModule,
            FuseSharedModule,
            FuseSidebarModule,
            SharedModule,
            MatAutocompleteModule,
            MatButtonModule,
            MatCheckboxModule,
            MatDatepickerModule,
            MatDividerModule,
            MatIconModule,
            MatInputModule,
            MatListModule,
            MatMenuModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatSlideToggleModule,
            MatSortModule,
            MatTableModule,
            MatTabsModule,
            MatTooltipModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [AdminLabosRoutingModule],
        bootstrap: [AdminListLabosComponent, AdminDetailLabosComponent, LaboGroupDetailComponent]
    })
], AdminLabosModule);
export { AdminLabosModule };
