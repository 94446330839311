export class MpChipsMedicComponent {
    constructor() {
        this.controller = 'MpChipsMedicController';
        this.controllerAs = 'vm';
        this.template = require('./mp-chips-medic.html').default;
        this.bindings = {
            label: '<',
            form: '<',
            name: '<',
            drugs: '<',
            required: '<',
            isDisabled: '<',
            noCache: '<',
            disallowCustomName: '<',
            onChanged: '&',
            id: '@',
        };
    }
}
