export const locale = {
    lang: 'en',
    data: {
        GROUPS: {
            TITLE: 'Groups',
            SEARCH: 'Search a group',
            CREATE_NEW: 'Create a new group',
            TYPE: 'Group types',
            STRUCTURE: {
                HOSPITAL: 'Hospitals',
                MEMBER: 'People'
            },
            TABLE: {
                NAME: 'Name',
                TYPE: 'Type',
                ACTION: 'Action',
                GROUP_STRUCTURE: 'Members type'
            }
        },
        GROUP_DETAIL: {
            EDIT_TITLE: `Group - `,
            NEW_TITLE: `New group`,
            TABS: {
                INFORMATION: `Informations`,
                MEMBERS: `Members`
            },
            EDIT_OK: `Group has been edited!`,
            CREATE_OK: `Group has been created!`,
            INFORMATION: {
                TITLES: {
                    NAME: 'Name',
                    TYPE: 'Type',
                    GROUP_STRUCTURE: 'Members type',
                    LIST_ADMIN: 'Administrators list'
                },
                ADMINS: {
                    TABLE: {
                        NAME: 'Name',
                        HOSPITAL: 'Hospital',
                        EMAIL: 'Email',
                        ACTION: 'Action'
                    }
                }
            },
            MEMBERS: {
                EMPTY: `This group has no members.`
            },
            MESSAGES: {
                SNACKBAR: {
                    DELETE_USER_OK: `Member has been removed!`,
                    DELETE_ADMIN_OK: `Administrator has been removed!`,
                    ADD_USER_OK: `Member has been added!`,
                    DELETE_GROUP_OK: `Group has been deleted!`,
                    ADD_ADMIN_OK: `Administrator has been added!`
                },
                DELETE_MEMBER: {
                    TITLE: `Confirm deletion`,
                    MESSAGE: `Do you really want to remove this member from the group?`
                },
                DELETE_GROUP: {
                    TITLE: `Confirm deletion`,
                    MESSAGE: `Do you really want to delete this group?`,
                    BUTTON: {
                        LONG: 'Delete the groupe',
                        SHORT: 'Delete'
                    }
                }
            },
            HOSPISTOCK: {
                ALTERNATIVES: {
                    TITLE: 'Alternatives',
                    MODERATOR: 'Laboratory alternative moderation'
                }
            }
        }
    }
};
