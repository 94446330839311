import { EHospivillePermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as angular from 'angular';
export class MRResultService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get a specific [[IMRResult]]
         * @param id The [[IMRResult]] [[Id]]
         * @return A promise containing the [[IMRResult]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation) || !id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mrResults.get({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                    id: id,
                }, (res) => defer.resolve(res[0]), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get all the [[IMRResult]] for an [[IMRInstance]] given
         * @return A promise containing an array of [[IMRInstance]]
         */
        this.getAll = () => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mrResults.get({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an [[IMRResult]]
         * @param mRResult The [[IMRResult]] to update
         */
        this.save = (mRResult) => {
            const defer = this.$q.defer();
            if (!mRResult || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mrResults.save({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, mRResult, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add an [[IMRResult]] to the dataBase
         * @param mRResult The [[IMRResult]] to create
         */
        this.add = (mRResult) => {
            const defer = this.$q.defer();
            if (!mRResult || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mrResults.add({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, mRResult, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Mark as deleted an [[IMRResult]]
         * @param mRResult The [[IMRResult]] to delete
         */
        this.delete = (mRResult) => {
            const defer = this.$q.defer();
            if (!mRResult || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mrResults.delete({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, mRResult, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        this._emptyResult = {
            status: null,
            hasDiscrepancy: false,
            discrepancyType: null,
            decision: null,
            comment: null,
            drugBalances: [],
            prescriptions: [],
            index: null,
            medicationReconciliation: {},
        };
    }
    get emptyResult() {
        return angular.copy(this._emptyResult);
    }
}
