import * as angular from 'angular';
import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MpModalMoveController {
    /** @ngInject */
    constructor(ModalService, FilesService) {
        this.ModalService = ModalService;
        this.FilesService = FilesService;
        this.$onInit = () => {
            this.hierarchy = [];
            let root = angular.copy(this.root);
            this.hierarchy.push(root);
            if (!root.children || root.children.length == 0) {
                this.FilesService.getChildrenDirectories(root).then((res) => {
                    root.children = res;
                    root.isExpanded = true;
                    root.hasFetchChildren = true;
                });
            }
        };
        /**
         * Select a directory [[IFile]]
         * @param dir
         */
        this.selectDirectory = (dir) => {
            if (dir && !this._isExcludedDir(dir)) {
                this.unselectDirectories(this.hierarchy);
                dir.selected = true;
                this.selected = dir;
            }
        };
        /**
         * If the given directory [[IFile]] is excluded
         * @param dir
         * @returns {boolean}
         * @private
         */
        this._isExcludedDir = (dir) => {
            for (let item of this.files) {
                if (Utils.compareIds(dir, item)) {
                    return true;
                }
            }
            return false;
        };
        /**
         * Unselect selected directories
         * @param directories
         */
        this.unselectDirectories = (directories) => {
            for (let dir of directories) {
                dir.selected = false;
                if (dir.children) {
                    this.unselectDirectories(dir.children);
                }
            }
        };
        /**
         * Close the modal without actions performing
         */
        this.closeDialog = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this.ModalService.close(this.selected);
        };
        this.hierarchy = [];
    }
    ;
}
