export const CHsDrugPublicationListItemInfos = {
    ref: {
        svgIcon: 'pill',
        title: 'PUBLICATION.LIST.ITEM.TITLES.UCD'
    },
    owner: {
        icon: 'business',
        title: 'PUBLICATION.LIST.ITEM.TITLES.OWNER'
    },
    period: {
        icon: 'date_range',
        title: 'PUBLICATION.LIST.ITEM.TITLES.PERIOD'
    },
    pharmacies: {
        icon: 'local_hospital',
        title: 'PUBLICATION.LIST.ITEM.TITLES.PHARMACIES_AUDIENCE'
    },
    source: {
        icon: 'person',
        title: 'PUBLICATION.LIST.ITEM.TITLES.SOURCE'
    },
    stock: {
        icon: 'bar_chart',
        title: 'PUBLICATION.LIST.ITEM.TITLES.STOCK_INDICATOR'
    },
    terminatedPeriod: {
        icon: 'event_available'
    }
};
export const CHsMedicalDevicePublicationListItemInfos = Object.assign(Object.assign({}, CHsDrugPublicationListItemInfos), { ref: {
        icon: 'healing',
        title: 'PUBLICATION.LIST.ITEM.TITLES.REF'
    }, owner: {
        icon: 'business',
        title: 'PUBLICATION.LIST.ITEM.TITLES.SUPPLIER' // override owner
    } });
