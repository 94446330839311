var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType, ELoanMapCategory } from '@mapuilabs/mpl-interfaces';
import { locale as french } from '../../../i18n/fr';
import { locale as english } from '../../../i18n/en';
import { FilterManager } from '../../../../filter-manager.class';
let LoansFilterComponent = class LoansFilterComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this.loansList = [
            {
                icon: 'assets/images/mapui/pins/marker_fuse_yellow.png',
                label: 'map.CONFIG_PANEL.FILTERS.LOANS.LABELS.AVAILABLE',
                type: ELoanMapCategory.Available,
                toFilter: true
            },
            {
                icon: 'assets/images/mapui/pins/marker_fuse_red.png',
                label: 'map.CONFIG_PANEL.FILTERS.LOANS.LABELS.REQUEST',
                type: ELoanMapCategory.Request,
                toFilter: true
            },
            {
                icon: 'assets/images/mapui/pins/marker_fuse_blue.png',
                label: 'map.CONFIG_PANEL.FILTERS.LOANS.LABELS.OTHERS',
                type: ELoanMapCategory.None,
                toFilter: false
            }
        ];
        this.updateFilters();
    }
    updateFilters() {
        const toShow = this.loansList.map((item) => {
            if (item.toFilter) {
                return item.type;
            }
        });
        if (toShow.find((elem) => elem == ELoanMapCategory.Available) ||
            toShow.find((elem) => elem == ELoanMapCategory.Request)) {
            toShow.push(ELoanMapCategory.AvailableAndRequest);
        }
        const filter = { type: EFilterType.VALUES, inArray: true, values: toShow.filter(Boolean) };
        this.filterManager.updateFilter('loanType', filter);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof FilterManager !== "undefined" && FilterManager) === "function" ? _b : Object)
], LoansFilterComponent.prototype, "filterManager", void 0);
LoansFilterComponent = __decorate([
    Component({
        selector: 'map-loans-filter',
        template: require('./loans-filter.component.html').default,
        styles: [require('./loans-filter.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], LoansFilterComponent);
export { LoansFilterComponent };
