var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PermissionService } from '@app/services/permission/permission.service';
import { fuseAnimations } from '@fuse/animations';
import { EHospistockPermission, EHsConfigLaboSpreadsheetImportMode, Hospital } from '@mapuilabs/mpl-interfaces';
let HospistockTabComponent = class HospistockTabComponent {
    constructor(_permissionService) {
        this._permissionService = _permissionService;
        this.pageType = 'new';
        this.mailValidator = (form) => {
            const sendEmail = form.value.sendMailContact;
            return sendEmail ? true : false;
        };
        this.setFormControls(this.laboratory);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if (changes.laboratory) {
            this.setFormControls(this.laboratory);
        }
    }
    setFormControls(laboratory) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if (laboratory) {
            this.form.setControl('importMode', new FormControl(((_c = (_b = (_a = this.laboratory) === null || _a === void 0 ? void 0 : _a.hospistockConfig) === null || _b === void 0 ? void 0 : _b.spreadsheetImport) === null || _c === void 0 ? void 0 : _c.importMode) !== null || null));
            this.form.setControl('laboContactEmail', new FormControl(((_f = (_e = (_d = this.laboratory) === null || _d === void 0 ? void 0 : _d.hospistockConfig) === null || _e === void 0 ? void 0 : _e.alternativesNotifications) === null || _f === void 0 ? void 0 : _f.laboContactEmail) || null, Validators.email));
            this.form.setControl('sendMailContact', new FormControl(((_j = (_h = (_g = this.laboratory) === null || _g === void 0 ? void 0 : _g.hospistockConfig) === null || _h === void 0 ? void 0 : _h.alternativesNotifications) === null || _j === void 0 ? void 0 : _j.sendMailContact) || null));
            this.form.setControl('sendMailMembersRole', new FormControl(((_m = (_l = (_k = this.laboratory) === null || _k === void 0 ? void 0 : _k.hospistockConfig) === null || _l === void 0 ? void 0 : _l.alternativesNotifications) === null || _m === void 0 ? void 0 : _m.sendMailMembersRole) || null));
            if (this._permissionService.hospitalHasPermissions(this.laboratory, [
                EHospistockPermission.LaboDrugAlternative_Read,
                EHospistockPermission.LaboDrugAlternative_SeeMenuItem
            ])) {
                this.form.get('sendMailMembersRole').enable();
            }
            else {
                this.form.get('sendMailMembersRole').disable();
                this.form.get('sendMailMembersRole').patchValue(false);
            }
            this.form.setControl('sendMailFirstProposalOnly', new FormControl(((_q = (_p = (_o = this.laboratory) === null || _o === void 0 ? void 0 : _o.hospistockConfig) === null || _p === void 0 ? void 0 : _p.alternativesNotifications) === null || _q === void 0 ? void 0 : _q.sendMailFirstProposalOnly) || null));
        }
    }
    updateLaboratoryHospistock(labo) {
        labo.hospistockConfig.spreadsheetImport.importMode = this.form.value.importMode
            ? EHsConfigLaboSpreadsheetImportMode.SNAPSHOT
            : null;
        labo.hospistockConfig.alternativesNotifications.laboContactEmail = this.form.value.laboContactEmail;
        labo.hospistockConfig.alternativesNotifications.sendMailContact = this.form.value.sendMailContact;
        if (!this._permissionService.hospitalHasPermissions(this.laboratory, [
            EHospistockPermission.LaboDrugAlternative_Read,
            EHospistockPermission.LaboDrugAlternative_SeeMenuItem
        ])) {
            labo.hospistockConfig.alternativesNotifications.sendMailMembersRole = false;
        }
        else {
            labo.hospistockConfig.alternativesNotifications.sendMailMembersRole = this.form.value.sendMailMembersRole;
        }
        labo.hospistockConfig.alternativesNotifications.sendMailFirstProposalOnly =
            this.form.value.sendMailFirstProposalOnly;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Hospital !== "undefined" && Hospital) === "function" ? _b : Object)
], HospistockTabComponent.prototype, "laboratory", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _c : Object)
], HospistockTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HospistockTabComponent.prototype, "pageType", void 0);
HospistockTabComponent = __decorate([
    Component({
        selector: 'mpx-hospistock-tab',
        template: require('./hospistock-tab.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _a : Object])
], HospistockTabComponent);
export { HospistockTabComponent };
