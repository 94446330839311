import * as angular from 'angular';
export class MpStepperController {
    /** @ngInject */
    constructor($timeout, $translate) {
        this.$timeout = $timeout;
        this.$translate = $translate;
        /**
         * Return whether the stepper can be displayed
         * @return {boolean}
         */
        this.display = () => {
            if (this.show != undefined) {
                return this.show;
            }
            else {
                return true;
            }
        };
        /**
         * Set the wanted orientation
         * @param orientation The wanted orientation
         */
        this.setOrientation = (orientation) => {
            this.orientation = orientation || 'horizontal';
        };
        /**
         * Register the main form for the stepper
         * @param form The main form to register
         */
        this.registerMainForm = (form) => {
            this.mainForm = form;
        };
        /**
         * register a new step
         * @param element The step DOM node
         * @param scope The step scope
         * @param form The step form
         * @return {IMpStep}
         */
        this.registerStep = (element, scope, form) => {
            const step = {
                element: element,
                scope: scope,
                form: form,
                stepNumber: scope.step || (this.steps.length + 1),
                stepTitle: '',
            };
            this.$translate.refresh().then(() => {
                this.$translate(scope.stepTitle).then((title) => {
                    step.stepTitle = title;
                });
            });
            this.steps.push(step);
            this.steps.sort(function (a, b) {
                return a.stepNumber - b.stepNumber;
            });
            return step;
        };
        /**
         * Set up the initial step
         */
        this.setupSteps = () => {
            this.setCurrentStep(this.currentStepNumber);
        };
        /**
         * Reset all the stepper forms
         */
        this.resetForm = () => {
            this.$timeout(function () {
                for (let x = 0; x < this.steps.length; x++) {
                    this.steps[x].form.$setPristine();
                    this.steps[x].form.$setUntouched();
                }
                this.mainForm.$setPristine();
                this.mainForm.$setUntouched();
                this.gotoFirstStep();
            });
        };
        /**
         * Set the current step at the given index
         * @param stepNumber The wanted index
         */
        this.setCurrentStep = (stepNumber) => {
            if (!this.isStepNumberValid(stepNumber)) {
                return;
            }
            this.currentStepNumber = stepNumber;
            if (this.orientation === 'horizontal') {
                for (let i = 0; i < this.steps.length; i++) {
                    this.steps[i].element.hide();
                }
                this.steps[this.currentStepNumber - 1].element.show();
            }
            else if (this.orientation === 'vertical') {
                for (let j = 0; j < this.steps.length; j++) {
                    this.steps[j].element.find('.mp-stepper-step-content').hide();
                }
                this.steps[this.currentStepNumber - 1].element.find('.mp-stepper-step-content').show();
            }
        };
        /**
         * Go to the given step
         * @param stepNumber The wanted step
         */
        this.gotoStep = (stepNumber) => {
            if (this.isStepHidden(stepNumber)) {
                return;
            }
            this.setCurrentStep(stepNumber);
        };
        /**
         * Go to the previous step
         */
        this.gotoPreviousStep = () => {
            let stepNumber = this.currentStepNumber - 1;
            for (let s = stepNumber; s >= 1; s--) {
                if (!this.isStepHidden(s)) {
                    stepNumber = s;
                    break;
                }
            }
            this.setCurrentStep(stepNumber);
        };
        /**
         * Go to the next step
         */
        this.gotoNextStep = () => {
            let stepNumber = this.currentStepNumber + 1;
            for (let s = stepNumber; s <= this.steps.length; s++) {
                if (!this.isStepHidden(s)) {
                    stepNumber = s;
                    break;
                }
            }
            this.setCurrentStep(stepNumber);
        };
        /**
         * Go to the first step
         */
        this.gotoFirstStep = () => {
            this.setCurrentStep(1);
        };
        /**
         * Go to the last step
         */
        this.gotoLastStep = () => {
            this.setCurrentStep(this.steps.length);
        };
        /**
         * Return whether the current step is the first one
         * @return {boolean}
         */
        this.isFirstStep = () => {
            return this.currentStepNumber === 1;
        };
        /**
         * Return whether the current step is the last one
         * @return {boolean}
         */
        this.isLastStep = () => {
            return this.currentStepNumber === this.steps.length;
        };
        /**
         * Return whether the current step is a final one
         * @return {boolean}
         */
        this.isFinalStep = () => {
            return (this.isLastStep() || this.steps[this.currentStepNumber - 1].scope.finalStep);
        };
        /**
         * Return whether the given index is the current step index
         * @param stepNumber The step index to chck
         * @return {boolean}
         */
        this.isStepCurrent = (stepNumber) => {
            if (!this.isStepNumberValid(stepNumber)) {
                return false;
            }
            return this.currentStepNumber === stepNumber;
        };
        /**
         * Return whether the step at the given index is disabled
         * @param stepNumber the index to check
         * @return {boolean}
         */
        this.isStepDisabled = (stepNumber) => {
            let disabled = false;
            if (!this.isStepNumberValid(stepNumber)) {
                return false;
            }
            for (let i = 1; i < stepNumber; i++) {
                if (!this.isStepValid(i)) {
                    disabled = true;
                    break;
                }
            }
            return disabled;
        };
        /**
         * Return whether the step at the given index is optional
         * @param stepNumber The index to check
         * @return {boolean}
         */
        this.isStepOptional = (stepNumber) => {
            if (!this.isStepNumberValid(stepNumber)) {
                return false;
            }
            return !!this.steps[stepNumber - 1].scope.optionalStep;
        };
        /**
         * Return whether the step at the given index is hidden
         * @param stepNumber The index to check
         * @return {boolean}
         */
        this.isStepHidden = (stepNumber) => {
            if (!this.isStepNumberValid(stepNumber)) {
                return false;
            }
            return !!this.steps[stepNumber - 1].scope.hideStep;
        };
        /**
         * Predicate used to remove all the hidden steps
         * @param step The step to check
         * @return {boolean}
         */
        this.filterHiddenStep = (step) => {
            return !this.isStepHidden(step.stepNumber);
        };
        /**
         * Return whether the step's form is in a valid state
         * @param stepNumber The step index
         * @return {boolean}
         */
        this.isStepValid = (stepNumber) => {
            if (!this.isStepNumberValid(stepNumber)) {
                return false;
            }
            if (this.isStepOptional(stepNumber)) {
                return true;
            }
            return this.steps[stepNumber - 1].form.$valid;
        };
        /**
         * Return whether the given index is a valid one
         * @param stepNumber the index to check
         * @return {boolean}
         */
        this.isStepNumberValid = (stepNumber) => {
            return !(angular.isUndefined(stepNumber) || stepNumber < 1 || stepNumber > this.steps.length);
        };
        /**
         * Return whether the main form is valid
         * @return {boolean}
         */
        this.isFormValid = () => {
            if (!this.mainForm) {
                return false;
            }
            return this.mainForm.$valid;
        };
        this.mainForm = undefined;
        this.orientation = 'horizontal';
        this.steps = [];
        this.currentStepNumber = 1;
    }
}
