var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { PageHeaderButtonComponent } from './page-header-button/page-header-button.component';
import { PageHeaderSearchBarButtonComponent } from './page-header-search-bar/page-header-search-bar-button/page-header-search-bar-button.component';
import { PageHeaderService } from './page-header.service';
let PageHeaderComponent = class PageHeaderComponent {
    constructor(_pageHeaderService) {
        this._pageHeaderService = _pageHeaderService;
        this.animations = {
            title: { value: '*', params: { delay: '400ms', x: '-25px' } },
            icon: { value: '*', params: { delay: '400ms', scale: '0.2' } }
        };
        this.onSearch = new EventEmitter();
        this.onSidebarMenuButtonClick = new EventEmitter();
    }
    get searchBar() {
        return this._searchBar;
    }
    set searchBar(val) {
        this._searchBar = coerceBooleanProperty(val);
    }
    get sidebarMenuButton() {
        return this._sidebarMenuButton;
    }
    set sidebarMenuButton(val) {
        this._sidebarMenuButton = coerceBooleanProperty(val);
    }
    get backBtn() {
        return this._backBtn;
    }
    set backBtn(val) {
        this._backBtn = coerceBooleanProperty(val);
    }
    get hasPageHeaderButtons() {
        var _a;
        return ((_a = this._pageHeaderButtonComponents) === null || _a === void 0 ? void 0 : _a.length) > 0;
    }
    get hasPageHeaderSearchBarButtons() {
        var _a;
        return ((_a = this._pageHeaderSearchBarButtonComponents) === null || _a === void 0 ? void 0 : _a.length) > 0;
    }
    get isCompactViewMode() {
        return this._pageHeaderService.isCompactViewMode;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Puts the string the user is searching in the URL and reloads the table at page 1
     * to show the result of the query.
     */
    search(searchValue) {
        this.onSearch.emit(searchValue);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], PageHeaderComponent.prototype, "searchBar", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], PageHeaderComponent.prototype, "sidebarMenuButton", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], PageHeaderComponent.prototype, "backBtn", null);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PageHeaderComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PageHeaderComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PageHeaderComponent.prototype, "placeholder", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_b = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _b : Object)
], PageHeaderComponent.prototype, "onSearch", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], PageHeaderComponent.prototype, "onSidebarMenuButtonClick", void 0);
__decorate([
    ContentChildren(PageHeaderButtonComponent),
    __metadata("design:type", typeof (_d = typeof QueryList !== "undefined" && QueryList) === "function" ? _d : Object)
], PageHeaderComponent.prototype, "_pageHeaderButtonComponents", void 0);
__decorate([
    ContentChildren(PageHeaderSearchBarButtonComponent),
    __metadata("design:type", typeof (_e = typeof QueryList !== "undefined" && QueryList) === "function" ? _e : Object)
], PageHeaderComponent.prototype, "_pageHeaderSearchBarButtonComponents", void 0);
PageHeaderComponent = __decorate([
    Component({
        selector: 'page-header',
        template: require('./page-header.component.html').default,
        styles: [require('./page-header.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof PageHeaderService !== "undefined" && PageHeaderService) === "function" ? _a : Object])
], PageHeaderComponent);
export { PageHeaderComponent };
