var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityASchema } from '@mapuilabs/mpl-interfaces';
import { ActivitySourceService } from '@services/hospiville/activities/activities-source.service';
import { TranslationService } from '@services/translation/translation.service';
import { UrlService } from '@services/url/url.service';
import { CActivityTypes } from '@shared/constTypes/activity/activity-type.const';
import { take } from 'rxjs/operators';
import { locale as hospivillesEnglish } from '../../../i18n/en';
import { locale as hospivilleFrench } from '../../../i18n/fr';
import { locale as activitiesEnglish } from '../../i18n/en';
import { locale as activitiesFrench } from '../../i18n/fr';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { MailsService } from '../mails.service';
import { Access } from '@main/services/auth/access.service';
let ActivityDetailsSourceComponent = class ActivityDetailsSourceComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _activityService, _mailService, _urlService, _ajsAccess) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._activityService = _activityService;
        this._mailService = _mailService;
        this._urlService = _urlService;
        this._ajsAccess = _ajsAccess;
        // -----------------------------------------------------------------------------------------------------
        // @ View methods
        // -----------------------------------------------------------------------------------------------------
        /**
         * Updates the member object when a child component made a PUT request
         */
        this.refreshActivity = () => {
            this._activityService
                .getById(this.activity._id)
                .pipe(take(1))
                .subscribe((newActivity) => {
                this.activity = newActivity;
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this._fuseTranslationLoaderService.loadTranslations(activitiesFrench, activitiesEnglish);
        this._fuseTranslationLoaderService.loadTranslations(hospivilleFrench, hospivillesEnglish);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        function isPopulated(object) {
            return typeof object !== 'string';
        }
        const patient = this.activity.patient;
        const dest = this.activity.dest;
        if (isPopulated(patient) && isPopulated(dest)) {
            // Header
            this.pageHeaderConfig = {
                title: this._translationService.getTranslation(CActivityTypes, this.activity.type) + ' - ' + dest.name,
                subtitle: this._translate('COMMONS.WORDS.PATIENT') +
                    this._translate('COMMONS.PUNCTUATION.COLON') +
                    ' ' +
                    patient.masterPatient.fullName
            };
        }
        this.migrationState = this._ajsAccess.isHVMigrationState();
    }
    ngAfterViewInit() {
        this._mailService.updateMails(this.activity.mails);
        this.fileId = this._urlService.getParam('fileId');
        if (this.fileId) {
            this.tabGroup.selectedIndex = 2;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _g : Object)
], ActivityDetailsSourceComponent.prototype, "activity", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ActivityDetailsSourceComponent.prototype, "currentTabIndex", void 0);
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_h = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _h : Object)
], ActivityDetailsSourceComponent.prototype, "tabGroup", void 0);
ActivityDetailsSourceComponent = __decorate([
    Component({
        selector: 'hvx-activity-details-source',
        template: require('./activity-details-source.component.html').default,
        styles: [require('./activity-details-source.component.scss'), require('../activity-details.component.scss')],
        encapsulation: ViewEncapsulation.None,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof ActivitySourceService !== "undefined" && ActivitySourceService) === "function" ? _c : Object, typeof (_d = typeof MailsService !== "undefined" && MailsService) === "function" ? _d : Object, typeof (_e = typeof UrlService !== "undefined" && UrlService) === "function" ? _e : Object, typeof (_f = typeof Access !== "undefined" && Access) === "function" ? _f : Object])
], ActivityDetailsSourceComponent);
export { ActivityDetailsSourceComponent };
