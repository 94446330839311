import * as angular from "angular";
import "@mapuilabs/mpl-interfaces";
import { EEstablishmentType, EHospivillePermission, EPubSubTopic } from "@mapuilabs/mpl-interfaces";
import { Utils } from "../Utils";
export class Auth {
    /** @ngInject */
    constructor(permConst, $http, $cookies, PubSubService, $rootScope, $translate, $q, $window, Api) {
        this.permConst = permConst;
        this.$http = $http;
        this.$cookies = $cookies;
        this.PubSubService = PubSubService;
        this.$rootScope = $rootScope;
        this.$translate = $translate;
        this.$q = $q;
        this.$window = $window;
        this.Api = Api;
        /**
         * Update list of permissions
         */
        this.updateUserAccesses = () => {
            const defer = this.$q.defer();
            this._userAccesses = {};
            this.$http.get('/api/permissions')
                .then((allPerms) => {
                this._userAccesses = allPerms.data;
                defer.resolve();
            });
            return defer.promise;
        };
        /**
         * Set user accesses with given data
         * @param accesses
         */
        this.setUserAccesses = (accesses) => {
            this._userAccesses = accesses;
        };
        this.getUserAcesses = () => {
            return this._userAccesses;
        };
        /**
         *
         * @param permission
         */
        this.authorize = (permission) => {
            // Like PublicAccess
            if (permission === true) {
                return true;
            }
            return this._userAccesses ? this._userAccesses[permission] : false;
        };
        /**
         * Authorize any of given permission
         * @param permissions
         */
        this.authorizeAny = (permissions) => {
            for (const permission of permissions) {
                if (this.authorize(permission)) {
                    return true;
                }
            }
            return false;
        };
        /**
         * Register new user
         * @param user
         * @param success
         * @param error
         */
        this.register = (user, success, error) => {
            this.$http.post('/register', user)
                .then(res => {
                this._changeUser(res);
                success();
            })
                .catch((err) => error(err.data));
        };
        this.registerPharmacy = (jwt, email) => {
            const defer = this.$q.defer();
            this.$http.post('/register-officine', { jwt: jwt, email: email })
                .then((res) => {
                this._changeUser(res);
                defer.resolve(res.data);
            }).catch(defer.reject);
            return defer.promise;
        };
        /**
         * Forgot password
         * @param user
         * @param success
         * @param error
         */
        this.forgotPassword = (user, success, error) => {
            this.$http.post('/forgot-password', user)
                .then(res => {
                this._changeUser(res);
                success();
            })
                .catch((err) => error(err));
        };
        /**
         * Login
         * @param user
         * @param origin
         */
        this.login = (user) => {
            const defer = this.$q.defer();
            this.$http.post(`/login`, user)
                .then((res) => {
                if (res.data.token) {
                    defer.resolve(res.data);
                }
                else {
                    this.applyLogin(res.data);
                    defer.resolve(res.data);
                }
            }).catch(err => defer.reject(err));
            return defer.promise;
        };
        /**
         * Logout
         * @param success
         * @param error
         */
        this.logout = (success, error) => {
            this.$http.post('/logout', {})
                .then(() => {
                this.PubSubService.emit(EPubSubTopic.SOCKET_CLOSE);
                this._changeUser({ email: '', config: { language: 'fr' } });
                success();
            })
                .catch(() => error());
        };
        /**
         * Apply login and redirect
         * @param user
         */
        this.applyLogin = (user) => {
            this._changeUser(user);
            this.PubSubService.emit(EPubSubTopic.SOCKET_CONNECT);
        };
        /**
         * Change selected Patient
         * @param {Id} id
         * @param {Id} medicationReconciliation
         */
        this.changePatient = (id, medicationReconciliation) => {
            if (this.authorize(EHospivillePermission.PatientFiles_Read)) {
                this._patient = { _id: id, medicationReconciliation: { _id: medicationReconciliation } };
                this.$cookies.putObject('patient', {
                    _id: id,
                    medicationReconciliation: { _id: medicationReconciliation }
                });
            }
        };
        /**
         * Is user in an hospital
         * @return {boolean}
         */
        this.isHospital = () => {
            return Utils.hasId(this.user) && EEstablishmentType.Hospital == this.user.hospital.establishmentType;
        };
        /**
         * Is user in a pharmacy
         * @return {boolean}
         */
        this.isPharmacy = () => {
            return Utils.hasId(this.user) && EEstablishmentType.Pharmacy == this.user.hospital.establishmentType;
        };
        /**
         * Is user in a company
         * @return {boolean}
         */
        this.isCompany = () => {
            return Utils.hasId(this.user) && EEstablishmentType.Company == this.user.hospital.establishmentType;
        };
        /**
         * Is user in a company
         * @return {boolean}
         */
        this.isLaboratory = () => {
            return Utils.hasId(this.user) && EEstablishmentType.Laboratory == this.user.hospital.establishmentType;
        };
        /**
         * Change user
         * @param user
         * @public
         */
        this._changeUser = (user) => {
            this.user = user;
            this.$rootScope.$emit('refreshAccess');
            this.$translate.use(this.user.config.language);
        };
        //USED BY ANGULAR APP
        this.updateUserCookie = (user) => {
            this.$cookies.putObject('user', user);
        };
        this._userAccesses = permConst;
        this.user = this.$cookies.getObject('user') || { email: '' };
        if (this.user.config) {
            this.$translate.use(this.user.config.language);
        }
        if (this.$cookies.getObject('user') && this.user.hospital) {
            this.$http.get('/api/hospitals/' + this.user.hospital._id)
                .then(hosp => {
                angular.merge(this.user.hospital, hosp.data);
                this.$rootScope.$emit('refreshAccess');
                this.PubSubService.emit(EPubSubTopic.USER_LOADED, {});
            });
        }
        this.$cookies.remove('user'); // Remove the cookie after reading it (then the user will be logged out if it's session expired)
        this._patient = this.authorize(EHospivillePermission.PatientFiles_Read) ? this.$cookies.getObject('patient') : null;
    }
    /**
     * Current patient getter
     * @return {IAuthPatient}
     */
    get patient() {
        return this._patient;
    }
    /**
     * Check Octave or subscribed permissions
     */
    get hasOctaveReadPermission() {
        return this.authorize(EHospivillePermission.Octave_Read);
    }
    get hasPremiumReadPermission() {
        return this.authorize(EHospivillePermission.isPremium_Read);
    }
    get isBothOctaveAndPremium() {
        return (this.hasOctaveReadPermission && this.hasPremiumReadPermission);
    }
    get isOnlyOctave() {
        return (this.hasOctaveReadPermission && !this.hasPremiumReadPermission);
    }
    get isOnlyPremium() {
        return (!this.hasOctaveReadPermission && this.hasPremiumReadPermission);
    }
}
