import * as angular from 'angular';
export class MpDateController {
    /** @ngInject */
    constructor(Api, _) {
        this.Api = Api;
        this._ = _;
        /**
         * Function called when date change.
         */
        this.changeDate = () => {
            if (this.model)
                this.model = new Date(angular.copy(this.model.setHours(12)));
            this.change({ model: this.model });
        };
    }
    $onInit() {
        this.model = (this.model) ? new Date(this.model) : null;
    }
    /**
     * The output called when the source is changed by the user
     * public onChange: ($event: any) => void;
     */
    $onChanges(changes) {
    }
}
