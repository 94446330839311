var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CustomWidgetsService } from '@services/homepage/widgets/custom-widgets/custom-widgets.service';
import { lastValueFrom } from 'rxjs';
import { CustomWidgetDetailsComponent } from './custom-widget-details/custom-widget-details.component';
import { CustomWidgetsListComponent } from './custom-widgets-list/custom-widgets-list.component';
const states = [
    {
        name: 'mapui.adm.homepage.custom-widgets.list',
        url: '/list',
        views: {
            'content@mapui': {
                component: CustomWidgetsListComponent
            }
        },
        data: {
            access: EMaPUIPermission.WidgetHome_Read,
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.homepage.custom-widgets.details',
        url: '/details/:id',
        views: {
            'content@mapui': {
                component: CustomWidgetDetailsComponent
            }
        },
        data: {
            access: EMaPUIPermission.WidgetHome_Read,
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: false
            }
        },
        resolve: [
            {
                token: 'widgetHome',
                deps: [Transition, CustomWidgetsService],
                resolveFn: (trans, widgetHomeService) => {
                    const id = trans.params().id;
                    if (id && id !== 'new') {
                        return lastValueFrom(widgetHomeService.getById(id));
                    }
                }
            }
        ]
    }
];
let CustomWidgetsRoutingModule = class CustomWidgetsRoutingModule {
};
CustomWidgetsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], CustomWidgetsRoutingModule);
export { CustomWidgetsRoutingModule };
