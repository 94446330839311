var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { AdminService as AjsAdminService } from '../../../../main/services/admin/admin.service';
import { EHTTPStatus, EMpStatus } from '@mapuilabs/mpl-interfaces';
class ImportHospitalsResults {
}
let ImportedHospitalsService = class ImportedHospitalsService {
    constructor(_http, _ajsAdminService) {
        this._http = _http;
        this._ajsAdminService = _ajsAdminService;
        this._endPoint = 'api/admin/hospitals/import-by-finess';
        this.addHospitalsByFiness = (data) => {
            if (!data) {
                return throwError(() => EHTTPStatus.BadRequest);
            }
            return new Observable((observer) => {
                this._treatImportedData(data).subscribe((finessList) => {
                    this._http.post(this._endPoint, finessList).subscribe((hospitals) => {
                        observer.next(hospitals);
                        observer.complete();
                    }, () => {
                        observer.error(throwError(EMpStatus.RejectByServer));
                    });
                }, () => {
                    observer.error(throwError(EMpStatus.RejectByServer));
                });
            });
        };
    }
    /**
     * Open ajs modal to upload and parse xlsx file data
     */
    openModalImport() {
        return from(this._ajsAdminService.openModalImportHospitalsManagement());
    }
    /**
     * Extract specific data from worksheet
     * @param data
     */
    _treatImportedData(data) {
        if (!data) {
            return throwError(EMpStatus.RejectByServer);
        }
        const rowsByRef = data.file.rowsGroupedBy('');
        const clearedData = {
            finess: []
        };
        for (const prop in rowsByRef) {
            if (rowsByRef.hasOwnProperty(prop)) {
                for (const entry of rowsByRef[prop]) {
                    if (entry.length && entry.getValueFor('finess')) {
                        clearedData.finess.push(entry.getValueFor('finess'));
                    }
                }
            }
        }
        return of(clearedData);
    }
};
ImportedHospitalsService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object, typeof (_b = typeof AjsAdminService !== "undefined" && AjsAdminService) === "function" ? _b : Object])
], ImportedHospitalsService);
export { ImportedHospitalsService };
