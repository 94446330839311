export const locale = {
    lang: `fr`,
    data: {
        ARCHIVES: {
            TITLE: 'Archives',
            SEARCH: 'Recherche',
            TO_ARCHIVE: 'À archiver',
            LOAN: 'Prêt',
            BORROWING: 'Emprunt',
            ARCHIVES: 'Archives',
            ARCHIVE: 'Archiver',
            ARCHIVE_MODAL: {
                CONFIRM: {
                    TITLE: 'Confirmation',
                    ARCHIVE_ITEM: 'Archiver le prêt sélectionné.',
                    ARCHIVE_ITEMS: 'Archiver les prêts sélectionnés.'
                }
            },
            DELETE_MODAL: {
                DELETE: 'Supprimer cet échange ?',
                REMOVE_AVAILABLE: 'Retirer cette mise à disposition ?',
                SUCCES: 'Échange supprimé'
            },
            HEADERS: {
                TYPE: 'Type',
                SELECTION: 'Sélection',
                MODIFIED: 'Date',
                NAME: 'Nom',
                HOSPITAL: 'Hôpital',
                STATUS: 'Statut',
                QUANTITY: 'Quantité',
                PRICE: 'Prix',
                ACTIONS: 'Actions',
                LABS: 'Labo (tit | exp)',
                EXPIRY_DATE: 'Expire le',
                GALENIC: 'Forme galénique',
                TRANSPORT_TYPE: 'Mode de transport'
            }
        },
        INFO_COVID: 'Ceci est un outil collaboratif entre établissements de santé.',
        SERVICES: {
            LOAN: {
                SUMMARY: {
                    HEADERS: {
                        GENERAL: 'Informations générales',
                        COMMENTS: 'Commentaires',
                        STAFF: 'Équipe',
                        VOUCHERS: 'Bons et avis de paiement'
                    },
                    CONTENT: {
                        TYPES: {
                            LABEL: 'Type',
                            LOAN: 'Prêt',
                            BORROW: 'Emprunt',
                            REQUEST: 'Demande',
                            AVAILABLE: 'Mise à disposition'
                        },
                        LABORATORY: {
                            LABEL: 'Laboratoire'
                        },
                        STAFF: {
                            LENDING: 'Chargé(e) de prêt',
                            BORROWING: "Chargé(e) d'emprunt",
                            VALID: 'Chargé(e) de validation'
                        },
                        CATEGORIES: {
                            LABEL: 'Catégorie',
                            DRUG: 'Médicament',
                            MD: 'Dispositif médical',
                            PREP: 'Préparation',
                            STE: 'Stérilisation'
                        },
                        COMMENTS: {
                            LENDER: 'Commentaire prêteur',
                            BORROWER: 'Commentaire emprunteur',
                            REQUEST: 'Commentaire de demande',
                            BILL: "Commentaire d'avis de paiement",
                            RETURN: 'Commentaire de retour',
                            VALID: 'commentaire de validation',
                            RECEIPT: 'Commentaire de réception',
                            NO_COMMENTS: 'Aucun commentaire'
                        },
                        UPDATE: {
                            TITLE: 'Dernière modification',
                            LABEL: 'Le {{date}} à {{time}} par {{who}}'
                        },
                        DATES: {
                            CREATION: 'Crée le {{date}} à {{time}}',
                            EXPIRY: "Date d'expiration"
                        },
                        RETURN_TYPE: {
                            SIMPLE: 'Retourné le {{date}} à {{time}}',
                            BILLED: 'Facturé le {{date}} à {{time}}'
                        },
                        QUANTITY: 'Quantité',
                        LOT_NUMBER: 'Numéros de lot',
                        OPERATING_ROOM: 'Block opératoire'
                    },
                    ACTIONS: {
                        DOWNLOAD: {
                            VOUCHER: {
                                LOAN: 'Bon de prêt',
                                BORROWING: "Bon d'emprunt",
                                RETURN: 'Bon de retour'
                            },
                            INVOICE: 'Avis de paiement'
                        }
                    }
                }
            },
            MEMBER: {
                EMAIL: 'Mail',
                PHONE: 'Téléphone',
                ACTIVITIES: 'Activités'
            }
        }
    }
};
