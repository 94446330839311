var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EFilterType, Hospital } from '@mapuilabs/mpl-interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslationService } from '@services/translation/translation.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { UrlService } from '@services/url/url.service';
import { StatsActivitiesService } from '@services/stats/stats-activities.service';
import { StatsActivitiesByHospitalService } from '@services/stats/stat-activities-by-hospital.service';
let ActivitiesDetailByHospitalComponent = class ActivitiesDetailByHospitalComponent {
    constructor(_urlService, _fuseTranslationLoaderService, _translationService) {
        var _a, _b;
        this._urlService = _urlService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this.updateCreationGraph = new EventEmitter();
        this.updateTimeGraph = new EventEmitter();
        this.timeAverage = 0;
        this.dateLimits = {
            start: (_a = this._urlService.getParam('period')) === null || _a === void 0 ? void 0 : _a.start,
            end: (_b = this._urlService.getParam('period')) === null || _b === void 0 ? void 0 : _b.end
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Toolbar
        this.sinceDateConfig = {
            button: {
                icon: { name: 'date_range', classes: ['secondary-text'] },
                label: this._translationService.instant('COMMONS.WORDS.PERIOD'),
                classes: ['cap']
            }
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Header
        this.pageHeaderConfig = {
            title: this.hospital.name,
            icon: 'local_hospital'
        };
    }
    ngAfterViewInit() {
        this.updateCreationGraph.emit();
        this.updateTimeGraph.emit();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSinceChange($event) {
        this.dateLimits = $event;
    }
    getURLPeriodFilter() {
        return {
            filters: JSON.stringify([
                {
                    type: EFilterType.RANGE,
                    attribute: 'period',
                    start: this.dateLimits.start,
                    end: this.dateLimits.end
                }
            ])
        };
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof Hospital !== "undefined" && Hospital) === "function" ? _d : Object)
], ActivitiesDetailByHospitalComponent.prototype, "hospital", void 0);
ActivitiesDetailByHospitalComponent = __decorate([
    Component({
        selector: 'mpx-activities-detail-by-hospital',
        template: require('./activities-detail-by-hospital.component.html').default,
        styles: [require('./activities-detail-by-hospital.component.scss')],
        animations: fuseAnimations,
        providers: [{ provide: StatsActivitiesService, useClass: StatsActivitiesByHospitalService }]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UrlService !== "undefined" && UrlService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object])
], ActivitiesDetailByHospitalComponent);
export { ActivitiesDetailByHospitalComponent };
