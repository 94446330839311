var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { BehaviorSubject } from 'rxjs';
import { HsPublicationDetailStatsWidgetModalComponent } from '../../../modals/publication/detail/stats-widget/hs-modal-publication-detail-stats-widget.component';
let HsPublicationDetailStatsWidgetComponent = class HsPublicationDetailStatsWidgetComponent {
    constructor(_matDialog) {
        this._matDialog = _matDialog;
        // Init constants
        this.dynamicDataContainerConfig = { noData: { size: 'xs' }, loader: { size: 'xs' } };
    }
    // Accessors
    get data() {
        return this.data$.value;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    openModal() {
        if (this.data.value || this.data.denominator) {
            this._matDialog.open(HsPublicationDetailStatsWidgetModalComponent, {
                data: { data: this.data, config: this.config },
                minWidth: '60%',
                restoreFocus: false
            });
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof BehaviorSubject !== "undefined" && BehaviorSubject) === "function" ? _b : Object)
], HsPublicationDetailStatsWidgetComponent.prototype, "data$", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationDetailStatsWidgetComponent.prototype, "config", void 0);
HsPublicationDetailStatsWidgetComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-stats-widget',
        template: require('./hs-publication-detail-stats-widget.component.html').default,
        animations: fuseAnimations,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _a : Object])
], HsPublicationDetailStatsWidgetComponent);
export { HsPublicationDetailStatsWidgetComponent };
