export class HvPatientInterviewSurveyInfoComponent {
    constructor() {
        this.controller = 'HvPatientInterviewSurveyInfoController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-interview-survey-info.html').default;
        this.bindings = {
            text: '@',
        };
    }
}
