var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../../../../../i18n/fr';
import { locale as english } from '../../../../../i18n/en';
import { UntypedFormControl } from '@angular/forms';
import { CHsAdvancedFilterSettings } from '@app/hospistock/shared/constTypes/hs-tag.const';
let HsSidebarFiltersAdvancedComponent = class HsSidebarFiltersAdvancedComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.filterSettings = CHsAdvancedFilterSettings;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _b : Object)
], HsSidebarFiltersAdvancedComponent.prototype, "control", void 0);
HsSidebarFiltersAdvancedComponent = __decorate([
    Component({
        selector: 'mpx-hs-sidebar-filters-advanced',
        template: require('./hs-sidebar-filters-advanced.component.html').default,
        styles: [require('./hs-sidebar-filters-advanced.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], HsSidebarFiltersAdvancedComponent);
export { HsSidebarFiltersAdvancedComponent };
