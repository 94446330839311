var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { ɵWebAnimationsDriver } from '@angular/animations/browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl, MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { setAngularJSGlobal, UpgradeModule } from '@angular/upgrade/static';
import { AppCommonModule } from '@common/common.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import * as angular from 'angular';
import { CookieService } from 'ngx-cookie-service';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { angularjsIndexModule } from '../index.module.ajs';
import { AdminModule } from './admin/admin.module';
import { AccessUpgrade, AdminServiceUpgrade, ApiUpgrade, AuthUpgrade, DashboardServiceUpgrade, DrugInstanceServiceUpgrade, ExternalActorServiceUpgrade, ExternalConfigurationServiceUpgrade, HospitalServiceUpgrade, hospivilleServiceProvider, MemberServiceUpgrade, ModalServiceUpgrade, NotificationServiceUpgrade, PubSubServiceUpgrade, QuickPanelServiceUpgrade, ToolbarServiceUpgrade } from './ajs-upgraded-providers';
import { AppRoutingModule } from './app-routing.module';
import { ExchangeModule } from './exchange/exchange.module';
import { fuseConfig } from './fuse-config';
import { HomeModule } from './home/home.module';
import { HsModule } from './hospistock/hs.module';
import { HospivilleModule } from './hospiville/hospiville.module';
import { IconRegisterModule } from './icon-register.module';
import { LayoutModule } from './layout/layout.module';
import { MpMatPaginatorIntl } from './paginator-intl';
import { PharmacyModule } from './pharmacy/pharmacy.module';
import { SettingModule } from './setting/setting.module';
export const FR_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY'
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
registerLocaleData(localeFr);
registerLocaleData(localeEn);
// Makes the injector available anywhere in the app
let AppModule = class AppModule {
    constructor(upgrade, _translateService, _overlayContainer) {
        this.upgrade = upgrade;
        this._translateService = _translateService;
        this._overlayContainer = _overlayContainer;
        // "monkey-patch" to make the animations work with shadowDom
        // https://github.com/angular/angular/issues/25672#issuecomment-560413034
        ɵWebAnimationsDriver.prototype.containsElement = (el1, el2) => {
            // Travel up the tree to the root node.
            let elem = el2;
            while (elem && elem !== document.documentElement) {
                if (elem === el1) {
                    return true;
                }
                elem = elem.parentNode || elem.host;
            }
            return false;
        };
        // apply a default theme to make sure the cdk-overlay will be created.
        // Usefull in index.run.ajs where we change the theme class.
        this._overlayContainer.getContainerElement().classList.add('theme-blue');
    }
    ngDoBootstrap() {
        setAngularJSGlobal(angular);
        this.upgrade.bootstrap(document.body, [angularjsIndexModule.name], { strictDi: true });
        this._translateService.addLangs(['en', 'fr']);
    }
};
AppModule = __decorate([
    NgModule({
        imports: [
            AdminModule,
            AppCommonModule,
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            ExchangeModule,
            FuseModule.forRoot(fuseConfig),
            FuseSharedModule,
            HomeModule,
            HsModule,
            HospivilleModule,
            HttpClientModule,
            IconRegisterModule,
            LayoutModule,
            PharmacyModule,
            SettingModule,
            TranslateModule.forRoot(),
            UpgradeModule
        ],
        exports: [AppRoutingModule],
        declarations: [],
        providers: [
            { provide: MatPaginatorIntl, useClass: MpMatPaginatorIntl },
            hospivilleServiceProvider,
            AuthUpgrade,
            ApiUpgrade,
            ExternalActorServiceUpgrade,
            ExternalConfigurationServiceUpgrade,
            HospitalServiceUpgrade,
            DrugInstanceServiceUpgrade,
            MemberServiceUpgrade,
            PubSubServiceUpgrade,
            AdminServiceUpgrade,
            DashboardServiceUpgrade,
            QuickPanelServiceUpgrade,
            AccessUpgrade,
            NotificationServiceUpgrade,
            ToolbarServiceUpgrade,
            ModalServiceUpgrade,
            CookieService,
            {
                provide: DateAdapter,
                useClass: MomentDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            },
            { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
            { provide: MAT_DATE_FORMATS, useValue: FR_DATE_FORMAT },
            { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
            {
                provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
                useValue: { pageSizeOptions: [10, 25, 50, 100] }
            },
            {
                provide: ErrorHandler,
                useValue: Sentry.createErrorHandler({
                    showDialog: false
                })
            },
            {
                provide: NG_SCROLLBAR_OPTIONS,
                useValue: { visibility: 'hover' }
            }
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UpgradeModule !== "undefined" && UpgradeModule) === "function" ? _a : Object, typeof (_b = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _b : Object, typeof (_c = typeof OverlayContainer !== "undefined" && OverlayContainer) === "function" ? _c : Object])
], AppModule);
export { AppModule };
