var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import * as moment from 'moment';
import * as _ from 'lodash';
let SinceDateMenuComponent = class SinceDateMenuComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._defaultConfig = { button: { icon: { name: 'date_range' }, displayLabel: true } };
        this.onSinceChange = new EventEmitter();
        this.sinceDatesOptions = [
            { number: '1', translate: 'TIME.WEEKS', icon: 'view_headline', date: this.removeDatesToDate(1, 'weeks') },
            { number: '1', translate: 'TIME.MONTHS', icon: 'view_week', date: this.removeDatesToDate(1, 'months') },
            { number: '6', translate: 'TIME.MONTHS', icon: 'view_module', date: this.removeDatesToDate(6, 'months') },
            { number: '1', translate: 'TIME.YEARS', icon: 'view_comfy', date: this.removeDatesToDate(1, 'years') },
            {
                number: '',
                translate: 'SINCE_DATE_MENU.START_MONTH',
                icon: 'arrow_back',
                date: moment(moment.now()).startOf('months').toDate()
            },
            {
                number: '',
                translate: 'SINCE_DATE_MENU.START_YEAR',
                icon: 'keyboard_backspace',
                date: moment(moment.now()).startOf('years').toDate()
            },
            { number: '', translate: 'SINCE_DATE_MENU.CREATION', icon: 'settings_backup_restore', date: null }
        ];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    set config(val) {
        this._config = _.merge({}, this._defaultConfig, val);
    }
    get config() {
        return this._config || this._defaultConfig;
    }
    removeDatesToDate(date, format) {
        return moment(moment.now()).subtract(date, format).toDate();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SinceDateMenuComponent.prototype, "menuPosition", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], SinceDateMenuComponent.prototype, "config", null);
__decorate([
    Output(),
    __metadata("design:type", typeof (_b = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _b : Object)
], SinceDateMenuComponent.prototype, "onSinceChange", void 0);
SinceDateMenuComponent = __decorate([
    Component({
        selector: 'mpx-since-date-menu',
        template: require('./since-date-menu.component.html').default,
        styles: [require('./since-date-menu.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], SinceDateMenuComponent);
export { SinceDateMenuComponent };
