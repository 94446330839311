import * as angular from 'angular';
import { HvToolbarController } from './toolbar/toolbar.controller';
import { HvToolbarComponent } from './toolbar/toolbar.component';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HospivilleSessionService } from '../../app/services/hospiville/hospiville-session/hospiville-session.service';
import { ToolbarService } from './toolbar/toolbar.service';
angular
    .module('mapui.layout.hospiville', ['app.core'])
    .controller('HvToolbarController', HvToolbarController)
    .service('ToolbarService', ToolbarService)
    .component('hvToolbar', HvToolbarComponent)
    .factory('_hvSessionService', downgradeInjectable(HospivilleSessionService));
