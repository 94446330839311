import { EAdminPanelPermission, EMaPUIPermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MemberService {
    /** @ngInject */
    constructor(Api, Access, $q) {
        this.Api = Api;
        this.Access = Access;
        this.$q = $q;
        /**
         * Get all [[IMember]]
         * @return A promise containing an array of [[IMember]]
         */
        this.getAll = (startDate = null, endDate = null, field = null) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.User_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                let options = {};
                if (startDate)
                    options.startDate = startDate.getTime();
                if (endDate)
                    options.endDate = endDate.getTime();
                if (field)
                    options.field = field;
                this.Api.admin.member.byId.all(options, (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Get all [[IMember]]
         * @return A promise containing an array of [[IMember]]
         */
        this.getByHosp = (hid, admin = false) => {
            const defer = this.$q.defer();
            if ((admin && !this.Access.authorize(EAdminPanelPermission.Users_Read)) ||
                (!admin && !this.Access.authorize(EMaPUIPermission.User_Read))) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                const route = admin ? this.Api.admin.member.byHospital : this.Api.member.byHospital;
                route.all({ hid: hid }, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get a specific [[IMember]]
         * @param id The [[IMember]] [[Id]]
         * @param admin
         * @returns A promise containing the [[IMember]]
         */
        this.get = (id, admin = false) => {
            const defer = this.$q.defer();
            if ((admin && !this.Access.authorize(EAdminPanelPermission.Users_Read)) ||
                (!admin && !this.Access.authorize(EMaPUIPermission.User_Read))) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                const route = admin ? this.Api.admin.member.byId : this.Api.member.byId;
                route.get({ id: id }, (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Update the [[IMember]] in the dataBase
         * @param member The [[IMember]] to update
         * @param admin
         * @returns A promise containing the status of the request
         */
        this.save = (member, admin = false) => {
            const defer = this.$q.defer();
            if (!member) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if ((admin && !this.Access.authorize(EAdminPanelPermission.Users_Edit)) ||
                (!admin && !Utils.compareIds(member, this.Access.user))) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                (admin ? this.Api.admin : this.Api).member.byId.save({ id: member._id }, member, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Change member configuration value
         */
        this.updateConfigValue = (key, value) => {
            this.Access.user.config[key] = value;
            this.save({ _id: this.Access.user._id, config: this.Access.user.config });
        };
    }
}
