export const locale = {
    lang: 'en',
    data: {
        CREATE_HOSPITAL_MEMBER: {
            TITLE: 'New member creation',
            INPUTS: {
                FIRSTNAME: `First name`,
                FAMILYNAME: `Last name`,
                GENDER: `Gender`,
                PHONE: `Phone`,
                EMAIL: 'Email',
                INPP: `PP National Identification`,
                RPPS: `RPPS number`,
                JOB: {
                    TITLE: `Job`,
                    STATUS: `Status`,
                    ACTIVITIES: `Activities`
                },
                ROLES: {
                    MAPUI: 'MaPUI Roles',
                    HOSPIVILLE: 'Hospiville Roles',
                    WORKSPACE: 'Workspace Roles'
                }
            },
            ERRORS: {
                EMAIL: {
                    REQUIRED: 'This input is required',
                    EMAIL: 'Enter a valid email',
                    DOMAIN: 'This domain is invalid, contact the support to create the member',
                    EXIST: 'A member already exist with this email'
                }
            },
            SUCCESS: {
                CREATE: 'Member added',
                SAVE: 'Member saved'
            }
        }
    }
};
