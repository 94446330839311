export const locale = {
    lang: 'en',
    data: {
        SIDEBAR: {
            TITLE: 'Options',
            FILTERS: {
                APPLY_BUTTON: 'Apply filters',
                STATUSES: 'Statuses',
                TYPES: 'Types'
            }
        }
    }
};
