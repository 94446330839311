var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { PageHeaderService } from '../page-header.service';
let PageHeaderSearchBarComponent = class PageHeaderSearchBarComponent {
    constructor(_pageHeaderService) {
        this._pageHeaderService = _pageHeaderService;
        this.onSearch = new EventEmitter();
    }
    get expandable() {
        return this._expandable;
    }
    set expandable(val) {
        this._expandable = coerceBooleanProperty(val);
    }
    get isCompactViewMode() {
        return this._pageHeaderService.isCompactViewMode;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this.expandableClass = this.expandable;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Event handlers
    // -----------------------------------------------------------------------------------------------------
    /**
     * Fire onSearch output event$
     */
    search() {
        this.onSearch.emit(this.searchValue);
    }
    /**
     * Detect if next event if click on icon button when leaving input focus.
     * Event fired before input focusout
     */
    onIconMouseDown() {
        this._mouseDownOnIcon = true;
    }
    /**
     * Handle click on icon button
     */
    onIconClick() {
        if (this.expandable && !this.extendedClass) {
            this.searchInput.nativeElement.focus();
        }
        else {
            this.search();
            // Focus input after search
            if (this.extendedClass) {
                this.searchInput.nativeElement.focus();
            }
        }
        // Reset _mouseDownOnIcon flag
        this._mouseDownOnIcon = false;
    }
    /**
     * Handle input focusin & focusout events by triggering the component expansion
     * @param isFocused focusin: true, focusout: false
     */
    onFocusSearchInput(isFocused) {
        if (this.expandable && !(this._mouseDownOnIcon && !isFocused)) {
            this.extendedClass = isFocused;
        }
    }
    /**
     * Reset searchValue and search
     */
    onReset() {
        this.searchValue = '';
        this.search();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], PageHeaderSearchBarComponent.prototype, "expandable", null);
__decorate([
    Input(),
    __metadata("design:type", String)
], PageHeaderSearchBarComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PageHeaderSearchBarComponent.prototype, "placeholder", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_b = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _b : Object)
], PageHeaderSearchBarComponent.prototype, "onSearch", void 0);
__decorate([
    ViewChild('searchInput', { static: false }),
    __metadata("design:type", typeof (_c = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _c : Object)
], PageHeaderSearchBarComponent.prototype, "searchInput", void 0);
__decorate([
    HostBinding('class.expandable'),
    __metadata("design:type", Boolean)
], PageHeaderSearchBarComponent.prototype, "expandableClass", void 0);
__decorate([
    HostBinding('class.extended'),
    __metadata("design:type", Boolean)
], PageHeaderSearchBarComponent.prototype, "extendedClass", void 0);
PageHeaderSearchBarComponent = __decorate([
    Component({
        selector: 'page-header-search-bar',
        template: `
        <div
            class="search h-48 card-bg b-r-28 mat-elevation-z2"
            [ngClass]="(isCompactViewMode | async) ? 'px-4' : 'px-8'"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <ng-content></ng-content>

            <button mat-icon-button class="icon-fg" (mousedown)="onIconMouseDown()" (click)="onIconClick()">
                <mat-icon>search</mat-icon>
            </button>

            <input
                matInput
                #searchInput
                [(ngModel)]="searchValue"
                class="p-0 mx-8 text-fg boder-none w-100-p"
                [placeholder]="placeholder"
                (keydown.enter)="search()"
                (focusin)="onFocusSearchInput(true)"
                (focusout)="onFocusSearchInput(false)"
            />

            <button mat-icon-button matSuffix *ngIf="searchValue" class="icon-fg" fxFlex="noshrink" (click)="onReset()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    `,
        styles: [require('./page-header-search-bar.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof PageHeaderService !== "undefined" && PageHeaderService) === "function" ? _a : Object])
], PageHeaderSearchBarComponent);
export { PageHeaderSearchBarComponent };
