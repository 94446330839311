var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, Input } from '@angular/core';
const messageIconBoxSizesMap = new Map([
    ['xs', { diameter: '32', fontSize: 'font-size-12', gap: '4px', icon: 's-32', padding: 'p-4' }],
    ['sm', { diameter: '40', fontSize: 'font-size-14', gap: '8px', icon: 's-40', padding: 'p-8' }],
    ['md', { diameter: '48', fontSize: 'font-size-16', gap: '12px', icon: 's-48', padding: 'p-16' }],
    ['lg', { diameter: '56', fontSize: 'font-size-20', gap: '16px', icon: 's-56', padding: 'p-24' }]
]);
const messageIconBoxTypesMap = new Map([
    ['hint', { textColor: 'hint-text' }],
    ['secondary', { textColor: 'secondary-text' }]
]);
let MessageIconBoxComponent = class MessageIconBoxComponent {
    constructor() {
        this._defaultSize = 'md';
        this._defaultType = 'secondary';
    }
    get styles() {
        return Object.assign(Object.assign({}, messageIconBoxSizesMap.get(this.size || this._defaultSize)), messageIconBoxTypesMap.get(this.type || this._defaultType));
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], MessageIconBoxComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MessageIconBoxComponent.prototype, "svgIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MessageIconBoxComponent.prototype, "message", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MessageIconBoxComponent.prototype, "spinner", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MessageIconBoxComponent.prototype, "size", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MessageIconBoxComponent.prototype, "type", void 0);
MessageIconBoxComponent = __decorate([
    Component({
        selector: 'mpx-message-icon-box',
        template: `
        <div
            [ngClass]="styles.padding"
            fxLayout="column"
            fxLayoutAlign="center center"
            [fxLayoutGap]="styles.gap"
            fxFlex="grow"
        >
            <!-- Spinner -->
            <mat-spinner *ngIf="spinner; else iconTemplate" color="primary" [diameter]="styles.diameter"></mat-spinner>

            <!-- Icon -->
            <ng-template #iconTemplate>
                <mat-icon [svgIcon]="!icon && svgIcon" [ngClass]="[styles.textColor, styles.icon]">
                    {{ icon }}
                </mat-icon>
            </ng-template>

            <!-- Message -->
            <span [ngClass]="[styles.fontSize, styles.textColor]">{{ message }}</span>
        </div>
    `,
        styles: ['']
    })
], MessageIconBoxComponent);
export { MessageIconBoxComponent };
