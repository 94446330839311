export class MpLoanRollbackComponent {
    constructor() {
        this.controller = 'MpLoanRollbackController';
        this.controllerAs = 'vm';
        this.template = require('./mp-loan-rollback.html').default;
        this.bindings = {
            loan: '<',
            state: '<',
            onClicked: '&',
            showAsButton: '<',
        };
    }
}
