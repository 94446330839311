var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsFileService } from '@app/hospistock/shared/services/hs-file.service';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { regExpFirstZerosAndSpecialCharacters } from '@app/shared/constTypes/reg-exp.const';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EFileType, EHsHealthProductTypes, EHsUserTypes, HsDrug, HsPublicationAlternativeDrugASchema, HsPublicationAlternativeMedicalDeviceASchema } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { IdentityService } from '@services/identity/identity.service';
import { MedicService } from '@services/medic/medic.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { UploadService } from '@services/upload/upload.service';
import { CustomValidators } from '@shared/commons/validators';
import { FileDragNDropReadType } from '@shared/components/file-drag-n-drop/file-drag-n-drop.component';
import { saveAs } from 'file-saver';
import { lastValueFrom, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';
let HsModalAlternativeFormComponent = class HsModalAlternativeFormComponent {
    constructor(_fb, _dialogService, _fuseTranslationLoaderService, _identityService, _medicService, _uploadService, _dialogRef, _snackbarService, _fileService, _translationService, data) {
        this._fb = _fb;
        this._dialogService = _dialogService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._identityService = _identityService;
        this._medicService = _medicService;
        this._uploadService = _uploadService;
        this._dialogRef = _dialogRef;
        this._snackbarService = _snackbarService;
        this._fileService = _fileService;
        this._translationService = _translationService;
        this.data = data;
        this.eFileType = EFileType.PublicationAttachment;
        this.fileDragNDropReadType = FileDragNDropReadType;
        this.isSaving = false;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        // Bind controller's services to injector's services
        this._healthProductService = this.data.injector.get(HsHealthProductService);
        this._publicationContainerService = this.data.injector.get(HsPublicationContainerService);
        // Init defaults
        this.healthProducts = [];
        this.labExpList = [];
        this.publicationExists = false;
        // Init constants
        this.isLaboViewType = this._publicationContainerService.viewType === EHsUserTypes.LABO;
        this.dynamicDataContainerConfig = {
            loader: {
                label: this._translationService.instant('PUBLICATION.MODAL.FORM.SAVE_IN_PROGRESS')
            }
        };
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Init defaults
        this.mode = this.data.alternative ? 'EDIT' : 'NEW';
        this.data.alternative =
            this.data.alternative || new this._publicationContainerService.publicationAlternativeConstructor({});
        // Generate form controls
        this.form = this._fb.group({
            startDate: [this.data.alternative.startDate],
            endDate: [this.data.alternative.endDate],
            manual: this.data.alternative.manual || false,
            healthProductRef: this._healthProductService.handleHealthProductRef((drugRef) => this._fb.group({
                name: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.name, Validators.required],
                ref: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.ref, [Validators.required, CustomValidators.ucd7or13]],
                ATC: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.ATC],
                laboratoryTit: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.laboratoryTit],
                operator: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.operator],
                healthProducts: [(drugRef === null || drugRef === void 0 ? void 0 : drugRef.healthProducts) || [], Validators.required]
            }), (medicalDeviceRef) => this._fb.group({
                name: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.name, Validators.required],
                ref: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.ref],
                operator: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.operator, [Validators.required]],
                healthProducts: [(medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.healthProducts) || [], Validators.required]
            }), this.data.alternative.healthProductRef),
            imported: this.data.alternative.imported || false,
            priceOffer: this.data.alternative.priceOffer || false,
            unitPackaging: [
                this.data.alternative.unitPackaging === undefined ? null : this.data.alternative.unitPackaging
            ],
            information: this.data.alternative.information,
            files: [this.data.alternative.files || []]
        });
        // Bind the search method for the autocomplete
        this.form
            .get('healthProductRef.operator')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll), startWith(''), debounceTime(500))
            .subscribe({ next: (value) => this._search(value) });
        // Compute isPublicationTerminated flag
        this.isPublicationTerminated = this._publicationContainerService.isPublicationTerminated(this.data.publication);
        // Disable presentations if medic manual
        if (this.form.get('manual').value) {
            this._updatePresentations([]);
            this.form.get('healthProductRef.healthProducts').reset();
            this.form.get('healthProductRef.healthProducts').disable();
        }
        // Subscribe to health product changes to update existing health product flag
        this.alternativeAlreadyExists$ = this.form.get('healthProductRef.healthProducts').valueChanges.pipe(takeUntil(this._unsubscribeAll), switchMap(() => this._publicationAlreadyExists()));
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _search(value) {
        if (this.form.get('manual').value && value && value != '') {
            this._medicService.findLaboratoryExp(value).subscribe((data) => {
                this.labExpList = data.map((elem) => ({ name: elem.cdf_nom, code: elem.cdf_code_pk }));
            });
        }
    }
    _handleError(err) {
        console.error(err);
        this._snackbarService.open(this._translationService.instant('PUBLICATION.NOTIFS.ERROR'), undefined, {
            panelClass: 'warn',
            duration: 2000
        });
        this._dialogRef.close();
    }
    _updatePresentations(presentations) {
        var _a;
        this.healthProducts = presentations.map((cip) => new HsDrug({ ref: cip.cip13, name: cip.presentation }));
        // Select unique presentation by default
        if (this.healthProducts.length === 1) {
            const formPresentation = this.form.get('healthProductRef.healthProducts');
            formPresentation.setValue([(_a = this.healthProducts) === null || _a === void 0 ? void 0 : _a[0]]);
            // Allow form to be submitted after modification in edit mode
            this.form.markAsDirty();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    optionSelected(selected) {
        this.form.get('healthProductRef.operator').setValue(selected.name);
    }
    _publicationAlreadyExists() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (this.form.get('manual').value || ((_a = this.form.get('healthProductRef.healthProducts').value) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return false;
            }
            return lastValueFrom(this._publicationContainerService.publicationExistsForUserFromHealthProductsRef(this.form.get('healthProductRef.healthProducts').value.map(({ ref }) => ref), Utils.getId(this.data.publication)));
        });
    }
    clearEndDate() {
        this.form.get('endDate').reset(null);
        this.form.markAsDirty();
    }
    /**
     * Download file using url parameter
     * @param fileObj
     */
    downloadFile(fileObj) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const buffer = yield lastValueFrom(this._uploadService.downloadFile(fileObj.url));
                const blob = new Blob([buffer]);
                const file = new File([blob], fileObj.name);
                saveAs(file); // Save in user's file system
            }
            catch (err) {
                this._snackbarService.openError(err);
                console.error(err);
            }
        });
    }
    /**
     * Delete file from publication
     * @param fileToDelete File to delete
     */
    deleteFile(fileToDelete) {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogConfig = {
                title: this._translationService.instant('PUBLICATION.MESSAGES.FILES.DELETE.TITLE'),
                message: this._translationService.instant('PUBLICATION.MESSAGES.FILES.DELETE.CONTENT'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            const actionConfirmed = yield lastValueFrom(this._dialogService.openConfirmDialog(dialogConfig));
            if (actionConfirmed) {
                try {
                    // Delete file from db & server
                    yield lastValueFrom(this._fileService.delete(fileToDelete));
                    // Delete file from local list
                    const files = this.form.get('files').value;
                    const index = files.findIndex((file) => file._id === fileToDelete._id);
                    if (index !== -1) {
                        files.splice(index, 1);
                        this.form.markAsDirty();
                        // Update publication in db
                        Object.assign(this.data.alternative, { files });
                    }
                    this._snackbarService.open(this._translationService.instant('COMMONS.DELETED'));
                }
                catch (err) {
                    this._snackbarService.openError(err);
                    console.error(err);
                }
            }
        });
    }
    /**
     * Upload File Set and add Returned IFile(s) to publication
     */
    _uploadFileSet() {
        if (!this.fileContainerSet || !this.fileContainerSet.size) {
            return;
        }
        this._uploadService.uploadAndSave(this.fileContainerSet, this.eFileType);
        const uploadPromises = [];
        this.fileContainerSet.forEach((file) => {
            file.progress.subscribe({
                next: (value) => (file.response = value),
                error: (err) => (file.error = err),
                complete: () => (file.completed = true)
            });
            uploadPromises.push(lastValueFrom(file.progress));
        });
        return Promise.all(uploadPromises);
    }
    /**
     * Check if all forms are valid and pristine
     */
    isInvalidOrPristine() {
        const forms = [this.form];
        return forms.some((form) => form.invalid) || !forms.some((form) => !form.pristine);
    }
    /**
     * Submit form by gathering all form and user values and uploading related files
     */
    submitForm() {
        return __awaiter(this, void 0, void 0, function* () {
            // Prevent invalid form
            if (this.isInvalidOrPristine()) {
                return;
            }
            // Set isSaving status
            this.isSaving = true;
            // Handle files
            try {
                // Upload files
                yield this._uploadFileSet();
                // Add files to form
                if (this.fileContainerSet && this.fileContainerSet.size) {
                    this.fileContainerSet.forEach((file) => {
                        var _a;
                        if (file.error || !file.response || !file.response.body) {
                            console.error(`The file named '${(_a = file.file) === null || _a === void 0 ? void 0 : _a.name}' contains error or no response.`);
                            return; // Don't push the file
                        }
                        // Add uploaded file to form
                        this.form.get('files').value.push(file.response.body);
                    });
                }
                // Get user values
                const userValues = {
                    creator: this.mode == 'NEW' ? this._identityService.user.hospital._id : this.data.alternative.creator
                };
                // Get form values
                const formValues = Object.assign({}, this.form.value);
                // Update alternative
                Object.assign(this.data.alternative, formValues, userValues);
                const alternative = this._healthProductService.handleHealthProductRef(
                // Handle HsDrugRef
                () => {
                    // Convert to UCD13
                    formValues.healthProductRef.ref = Utils.ucd7ToUcd13(formValues.healthProductRef.ref);
                    return new HsPublicationAlternativeDrugASchema(this.data.alternative);
                }, 
                // Handle HsMedicalDeviceRef
                () => {
                    // Clean medical devices ref
                    formValues.healthProductRef.healthProducts.forEach((healthProduct) => {
                        healthProduct.ref = healthProduct.ref.replace(regExpFirstZerosAndSpecialCharacters, '');
                    });
                    return new HsPublicationAlternativeMedicalDeviceASchema(this.data.alternative);
                });
                this._dialogRef.close(this.data.alternative);
            }
            catch (err) {
                this._handleError(err);
            }
        });
    }
    /**
     * Handle onSelectionChanged file-uploader output
     */
    onFileSelectionChanged(fileContainerSet) {
        this.fileContainerSet = fileContainerSet;
        this.form.markAsDirty();
    }
};
HsModalAlternativeFormComponent = __decorate([
    Component({
        selector: 'mpx-hs-modal-alternative-form',
        template: require('./hs-modal-alternative-form.component.html').default,
        styles: [require('./hs-modal-alternative-form.component.scss')]
    }),
    __param(10, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _a : Object, typeof (_b = typeof DialogService !== "undefined" && DialogService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof MedicService !== "undefined" && MedicService) === "function" ? _e : Object, typeof (_f = typeof UploadService !== "undefined" && UploadService) === "function" ? _f : Object, typeof (_g = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _g : Object, typeof (_h = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _h : Object, typeof (_j = typeof HsFileService !== "undefined" && HsFileService) === "function" ? _j : Object, typeof (_k = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _k : Object, Object])
], HsModalAlternativeFormComponent);
export { HsModalAlternativeFormComponent };
