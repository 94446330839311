var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ReleaseNotesListComponent_1;
var _a, _b, _c, _d, _e;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ReleaseNoteASchema } from '@mapuilabs/mpl-interfaces';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { Utils } from '@main/services/Utils';
import { TranslationService } from '@services/translation/translation.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ReleaseNotesService } from '@services/homepage/widgets/release-notes/release-notes.service';
import { StateService } from '@uirouter/angularjs';
let ReleaseNotesListComponent = ReleaseNotesListComponent_1 = class ReleaseNotesListComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _snackbarService, _stateService, _releaseNotesService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._snackbarService = _snackbarService;
        this._stateService = _stateService;
        this._releaseNotesService = _releaseNotesService;
        this.displayedColumns = ['title', 'actions'];
        this.widgetsHome = [];
        this.isLoading = true;
        this.noData = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this._releaseNotesService.getAll().subscribe((widgets) => {
            this.isLoading = false;
            if (!widgets || (widgets === null || widgets === void 0 ? void 0 : widgets.length) <= 0) {
                this.noData = true;
                return;
            }
            this.widgetsHome = widgets.map((w) => new ReleaseNoteASchema(w));
            this.sortData();
        });
        this.pageHeaderConfig = {
            title: this._translate('RELEASE_NOTES.TITLE'),
            icon: 'event_note'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // Sort
    static compareElems(a, b, direction) {
        if (a === b) {
            return 0;
        }
        if (!a || (b && a < b)) {
            return direction ? -1 : 1;
        }
        return direction ? 1 : -1;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    goToDetail(id = 'new') {
        this._stateService.go('mapui.adm.homepage.release-notes.details', { id });
    }
    duplicateWidget(widgetHome) {
        delete widgetHome._id;
        this._releaseNotesService.add(widgetHome).subscribe((result) => {
            this.widgetsHome.push(result);
            this.widgetsHome = Object.assign([], this.widgetsHome);
            this._snackbarService.open(this._translationService.instant('RELEASE_NOTES.TABLE.DUPLICATE.NOTIF'));
        }, (err) => this._snackbarService.openError(err));
    }
    deleteWidget(widgetHome) {
        this._releaseNotesService.delete(widgetHome).subscribe((result) => {
            if (result === 'OK') {
                Utils.removeElemFromArray(this.widgetsHome, (widget) => widget._id === widgetHome._id);
                this.widgetsHome = Object.assign([], this.widgetsHome);
            }
            else {
                this._snackbarService.openCustomError(this._translationService.instant('COMMONS.ERRORS.DEFAULT'));
            }
        }, (err) => this._snackbarService.openError(err));
    }
    sortData(sort) {
        if (!this.widgetsHome) {
            return;
        }
        const data = this.widgetsHome.slice();
        if (!sort || !sort.active || sort.direction === '') {
            this.widgetsHome = data.sort((a, b) => {
                var _a, _b;
                return ReleaseNotesListComponent_1.compareElems((_a = b.creationDate) === null || _a === void 0 ? void 0 : _a.getTime(), (_b = a.creationDate) === null || _b === void 0 ? void 0 : _b.getTime(), true);
            });
            return;
        }
        this.widgetsHome = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'title':
                    return ReleaseNotesListComponent_1.compareElems(a.title.message, b.title.message, isAsc);
                default:
                    return 0;
            }
        });
    }
};
ReleaseNotesListComponent = ReleaseNotesListComponent_1 = __decorate([
    Component({
        selector: 'mpx-release-notes-list',
        template: require('./release-notes-list.component.html').default,
        styles: [require('./release-notes-list.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof StateService !== "undefined" && StateService) === "function" ? _d : Object, typeof (_e = typeof ReleaseNotesService !== "undefined" && ReleaseNotesService) === "function" ? _e : Object])
], ReleaseNotesListComponent);
export { ReleaseNotesListComponent };
