/** @ngInject */
export class MpNothingToShowController {
    /** @ngInject */
    constructor() {
    }
    $onInit() {
    }
    $onChanges(changes) {
    }
}
