import * as angular from 'angular';
/**
 * This service works like angular ngResource module: (https://github.com/angular/angular.js/blob/master/src/ngResource/resource.js)
 */
const $resourceMinErr = angular.$$minErr('$resource');
/**
 * We need our custom method because encodeURIComponent is too aggressive and doesn't follow
 * http://www.ietf.org/rfc/rfc3986.txt with regards to the character set (pchar) allowed in path
 * segments:
 *    segment       = *pchar
 *    pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
 *    pct-encoded   = "%" HEXDIG HEXDIG
 *    unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
 *    sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
 *                     / "*" / "+" / "," / ";" / "="
 */
function encodeUriSegment(val) {
    return encodeUriQuery(val, true).replace(/%26/gi, '&').replace(/%3D/gi, '=').replace(/%2B/gi, '+');
}
/**
 * This method is intended for encoding *key* or *value* parts of query component. We need a custom
 * method because encodeURIComponent is too aggressive and encodes stuff that doesn't have to be
 * encoded per http://tools.ietf.org/html/rfc3986:
 *    query         = *( pchar / "/" / "?" )
 *    pchar         = unreserved / pct-encoded / sub-delims / ":" / "@"
 *    unreserved    = ALPHA / DIGIT / "-" / "." / "_" / "~"
 *    pct-encoded   = "%" HEXDIG HEXDIG
 *    sub-delims    = "!" / "$" / "&" / "'" / "(" / ")"
 *                     / "*" / "+" / "," / ";" / "="
 */
function encodeUriQuery(val, pctEncodeSpaces) {
    return encodeURIComponent(val).replace(/%40/gi, '@').replace(/%3A/gi, ':').replace(/%24/g, '$').replace(/%2C/gi, ',').replace(/%3B/gi, ';').replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
}
// Helper functions and regex to lookup a dotted path on an object
// stopping at undefined/null.  The path must be composed of ASCII
// identifiers (just like $parse)
const MEMBER_NAME_REGEX = /^(\.[a-zA-Z_$@][0-9a-zA-Z_$@]*)+$/;
function isValidDottedPath(path) {
    return (path != null && path !== '' && path !== 'hasOwnProperty' &&
        MEMBER_NAME_REGEX.test('.' + path));
}
function lookupDottedPath(obj, path) {
    if (!isValidDottedPath(path)) {
        throw $resourceMinErr('badmember', 'Dotted member path "@{0}" is invalid.', path);
    }
    const keys = path.split('.');
    for (let i = 0, ii = keys.length; i < ii && angular.isDefined(obj); i++) {
        const key = keys[i];
        obj = (obj !== null) ? obj[key] : undefined;
    }
    return obj;
}
/**
 * Create a shallow copy of an object and clear other fields from the destination
 */
function shallowClearAndCopy(src, dst) {
    dst = dst || {};
    angular.forEach(dst, function (value, key) {
        delete dst[key];
    });
    for (let key in src) {
        if (src.hasOwnProperty(key) && !(key.charAt(0) === '$' && key.charAt(1) === '$')) {
            dst[key] = src[key];
        }
    }
    return dst;
}
/**
 * Socket Resource Provider
 * This provider is based on the Angular Resource (ngResource) service
 * The goal is to reproduce $resource behaviour with sockets
 * @constructor
 */
export function SocketResourceProvider() {
    const PROTOCOL_AND_IPV6_REGEX = /^https?:\/\/\[[^\]]*][^/]*/;
    const provider = this;
    this.defaults = {
        // Strip slashes by default
        stripTrailingSlashes: true,
        // Make non-instance requests cancellable (via `$cancelRequest()`)
        cancellable: false,
        // Default actions configuration
        actions: {
            'get': { method: 'GET' },
            'save': { method: 'POST' },
            'query': { method: 'GET', isArray: true },
            'remove': { method: 'DELETE' },
            'delete': { method: 'DELETE' },
        },
    };
    SocketResourceProvider.prototype.$get = ['SocketService', '$log', '$q', '$timeout', function (SocketService, $log, $q, $timeout) {
            let noop = angular.noop, forEach = angular.forEach, extend = angular.extend, copy = angular.copy, isArray = angular.isArray, isDefined = angular.isDefined, isFunction = angular.isFunction, isNumber = angular.isNumber;
            function Route(template, defaults) {
                this.template = template;
                //noinspection JSPotentiallyInvalidUsageOfThis
                this.defaults = extend({}, provider.defaults, defaults);
                this.urlParams = {};
            }
            Route.prototype = {
                setUrlParams: function (config, params, actionUrl) {
                    let self = this, url = actionUrl || self.template, val, encodedVal, protocolAndIpv6 = '';
                    const urlParams = self.urlParams = Object.create(null);
                    forEach(url.split(/\W/), (param) => {
                        if (param === 'hasOwnProperty') {
                            throw $resourceMinErr('badname', 'hasOwnProperty is not a valid parameter name.');
                        }
                        if (!(new RegExp('^\\d+$').test(param)) && param &&
                            (new RegExp('(^|[^\\\\]):' + param + '(\\W|$)').test(url))) {
                            urlParams[param] = {
                                isQueryParamValue: (new RegExp('\\?.*=:' + param + '(?:\\W|$)')).test(url),
                            };
                        }
                    });
                    url = url.replace(/\\:/g, ':');
                    url = url.replace(PROTOCOL_AND_IPV6_REGEX, function (match) {
                        protocolAndIpv6 = match;
                        return '';
                    });
                    params = params || {};
                    forEach(self.urlParams, (paramInfo, urlParam) => {
                        val = params.hasOwnProperty(urlParam) ? params[urlParam] : self.defaults[urlParam];
                        if (isDefined(val) && val !== null) {
                            if (paramInfo.isQueryParamValue) {
                                encodedVal = encodeUriQuery(val, true);
                                // encodedVal = val;
                            }
                            else {
                                encodedVal = encodeUriSegment(val);
                                // encodedVal = val;
                            }
                            url = url.replace(new RegExp(':' + urlParam + '(\\W|$)', 'g'), (match, p1) => {
                                return encodedVal + p1;
                            });
                        }
                        else {
                            url = url.replace(new RegExp('(/?):' + urlParam + '(\\W|$)', 'g'), (match, leadingSlashes, tail) => {
                                if (tail.charAt(0) === '/') {
                                    return tail;
                                }
                                else {
                                    return leadingSlashes + tail;
                                }
                            });
                        }
                    });
                    // strip trailing slashes and set the url (unless this behavior is specifically disabled)
                    if (self.defaults.stripTrailingSlashes) {
                        url = url.replace(/\/+$/, '') || '/';
                    }
                    // Collapse `/.` if found in the last URL path segment before the query.
                    // E.g. `http://url.com/id/.format?q=x` becomes `http://url.com/id.format?q=x`.
                    url = url.replace(/\/\.(?=\w+($|\?))/, '.');
                    // Replace escaped `/\.` with `/.`.
                    // (If `\.` comes from a param value, it will be encoded as `%5C.`.)
                    config.url = protocolAndIpv6 + url.replace(/\/(\\|%5C)\./, '/.');
                    // set params - delegate param encoding to $http
                    forEach(params, (value, key) => {
                        if (!self.urlParams[key]) {
                            config.params = config.params || {};
                            config.params[key] = value;
                        }
                    });
                },
            };
            function resourceFactory(url, paramDefaults, actions, options) {
                const route = new Route(url, options);
                actions = extend({}, provider.defaults.actions, actions);
                function extractParams(data, actionParams) {
                    const ids = {};
                    actionParams = extend({}, paramDefaults, actionParams);
                    forEach(actionParams, function (value, key) {
                        if (isFunction(value)) {
                            value = value(data);
                        }
                        ids[key] = value && value.charAt && value.charAt(0) === '@' ?
                            lookupDottedPath(data, value.substr(1)) : value;
                    });
                    return ids;
                }
                function defaultResponseInterceptor(response) {
                    return response.resource;
                }
                function Resource(value) {
                    shallowClearAndCopy(value || {}, this);
                }
                Resource.prototype.toJSON = function () {
                    const data = extend({}, this);
                    delete data.$promise;
                    delete data.$resolved;
                    delete data.$cancelRequest;
                    return data;
                };
                forEach(actions, function (action, name) {
                    const hasBody = action.hasBody === true || (action.hasBody !== false && /^(POST|PUT|PATCH)$/i.test(action.method));
                    let numericTimeout = action.timeout;
                    const cancellable = isDefined(action.cancellable) ?
                        action.cancellable : route.defaults.cancellable;
                    if (numericTimeout && !isNumber(numericTimeout)) {
                        $log.debug('ngResource:\n' +
                            '  Only numeric values are allowed as `timeout`.\n' +
                            '  Promises are not supported in $resource, because the same value would ' +
                            'be used for multiple requests. If you are looking for a way to cancel ' +
                            'requests, you should use the `cancellable` option.');
                        delete action.timeout;
                        numericTimeout = null;
                    }
                    Resource[name] = function (a1, a2, a3, a4) {
                        let params = {}, data, success, error;
                        switch (arguments.length) {
                            case 4:
                                error = a4;
                                success = a3;
                            // falls through
                            case 3:
                            case 2:
                                if (isFunction(a2)) {
                                    if (isFunction(a1)) {
                                        success = a1;
                                        error = a2;
                                        break;
                                    }
                                    success = a2;
                                    error = a3;
                                    // falls through
                                }
                                else {
                                    params = a1;
                                    data = a2;
                                    success = a3;
                                    break;
                                }
                            // falls through
                            case 1:
                                if (isFunction(a1)) {
                                    success = a1;
                                }
                                else if (hasBody) {
                                    data = a1;
                                }
                                else {
                                    params = a1;
                                }
                                break;
                            case 0:
                                break;
                            default:
                                throw $resourceMinErr('badargs', 'Expected up to 4 arguments [params, data, success, error], got {0} arguments', arguments.length);
                        }
                        const isInstanceCall = this instanceof Resource;
                        const value = isInstanceCall ? data : (action.isArray ? [] : new Resource(data));
                        const socketMsg = {};
                        const responseInterceptor = action.interceptor && action.interceptor.response ||
                            defaultResponseInterceptor;
                        const responseErrorInterceptor = action.interceptor && action.interceptor.responseError ||
                            undefined;
                        const hasError = !!error;
                        const hasResponseErrorInterceptor = !!responseErrorInterceptor;
                        let timeoutDeferred;
                        let numericTimeoutPromise;
                        forEach(action, function (value, key) {
                            switch (key) {
                                default:
                                    socketMsg[key] = copy(value);
                                    break;
                                case 'params':
                                case 'isArray':
                                case 'interceptor':
                                case 'cancellable':
                                    break;
                            }
                        });
                        if (!isInstanceCall && cancellable) {
                            timeoutDeferred = $q.defer();
                            socketMsg.timeout = timeoutDeferred.promise;
                            if (numericTimeout) {
                                numericTimeoutPromise = $timeout(timeoutDeferred.resolve, numericTimeout);
                            }
                        }
                        if (hasBody)
                            socketMsg.data = data;
                        route.setUrlParams(socketMsg, extend({}, extractParams(data, action.params || {}), params), action.url);
                        const defer = $q.defer();
                        let promise = defer.promise;
                        console.trace(socketMsg);
                        SocketService.emit(socketMsg, (err, data) => {
                            if (err) {
                                defer.reject(err);
                            }
                            else {
                                defer.resolve(data);
                            }
                        });
                        promise = promise['finally'](function () {
                            value.$resolved = true;
                            if (!isInstanceCall && cancellable) {
                                value.$cancelRequest = noop;
                                $timeout.cancel(numericTimeoutPromise);
                                timeoutDeferred = numericTimeoutPromise = socketMsg.timeout = null;
                            }
                        });
                        // promise = promise.then(
                        //   function (response) {
                        //     return response;
                        //   },
                        //   (hasError || hasResponseErrorInterceptor) ?
                        //     function (response) {
                        //       if (hasError && !hasResponseErrorInterceptor) {
                        //         // Avoid `Possibly Unhandled Rejection` error,
                        //         // but still fulfill the returned promise with a rejection
                        //         promise.catch(noop);
                        //       }
                        //       if (hasError) error(response);
                        //       return $q.reject(response);
                        //     } :
                        //     undefined);
                        promise = promise.then(function (response) {
                            (success || noop)(response);
                            return response;
                        }, (hasError || hasResponseErrorInterceptor) ?
                            function (response) {
                                if (hasError && !hasResponseErrorInterceptor) {
                                    // Avoid `Possibly Unhandled Rejection` error,
                                    // but still fulfill the returned promise with a rejection
                                    promise.catch(noop);
                                }
                                if (hasError)
                                    error(response);
                                return $q.reject(response);
                            } :
                            undefined);
                        if (!isInstanceCall) {
                            // we are creating instance / collection
                            // - set the initial promise
                            // - return the instance / collection
                            value.$promise = promise;
                            value.$resolved = false;
                            if (cancellable)
                                value.$cancelRequest = cancelRequest;
                            return value;
                        }
                        // instance call
                        return promise;
                        function cancelRequest(value) {
                            promise.catch(noop);
                            timeoutDeferred.resolve(value);
                        }
                    };
                    Resource.prototype['$' + name] = function (params, success, error) {
                        if (isFunction(params)) {
                            error = success;
                            success = params;
                            params = {};
                        }
                        const result = Resource[name].call(this, params, this, success, error);
                        return result.$promise || result;
                    };
                });
                Resource.bind = function (additionalParamDefaults) {
                    const extendedParamDefaults = extend({}, paramDefaults, additionalParamDefaults);
                    return resourceFactory(url, extendedParamDefaults, actions, options);
                };
                return Resource;
            }
            return resourceFactory;
        }];
}
