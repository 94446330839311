export class MpSelectStaffMemberComponent {
    constructor() {
        this.controller = 'MpSelectStaffController';
        this.controllerAs = 'vm';
        this.template = require('./mp-select-staff-member.html').default;
        this.bindings = {
            form: '<',
            from: '<',
            all: '<',
            model: '<',
            disabled: '<',
            label: '@',
            onOpen: '&',
            onClose: '&',
            onViewChange: '&',
            required: '<'
        };
    }
}
