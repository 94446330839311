export class MpModalLoanCreatedComponent {
    constructor() {
        this.controller = 'MpModalLoanCreatedController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-loan.html').default;
        this.bindings = {
            loan: '<',
        };
    }
}
