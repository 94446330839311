import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class HvSearchMssLetterBoxController {
    /** @ngInject */
    constructor(SecureMessagingService, Access, ToastService, _) {
        this.SecureMessagingService = SecureMessagingService;
        this.Access = Access;
        this.ToastService = ToastService;
        this._ = _;
        /**
         * Activate research with select or autocomplete
         */
        this.completeResearch = false;
        /**
         * Load an array of [[IMSSLetterBoxes]] for the given pharmacy source
         */
        this.loadMssAddresses = () => {
            let finesses = [];
            this._.map(this.establishments, (item) => {
                finesses.push(item.finessCode.toString());
            });
            return this.SecureMessagingService.findLetterBoxesByFiness(finesses)
                .then((letterBoxes) => {
                this.letterBoxes = letterBoxes;
            })
                .catch(() => {
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        /**
         * Query [[IMSSLetterBoxes]] research service
         */
        this.searchMssAddress = () => {
            return this.SecureMessagingService.findLetterBoxesByAdress(this.ctrl.searchText);
        };
        /**
         * Output logic when an [[IMSSLetterBoxes]] address is selected
         */
        this.searchMssSelected = () => {
            if (this.ctrl.selected && this.completeResearch) {
                this.letterBox = this.ctrl.selected;
            }
            if (!this.chooseEstablishment) {
                this.onSelected({ $event: { mssLetterBox: this.letterBox } });
            }
            else {
                this.onSelected({ $event: { mssLetterBox: this.letterBox, selectedRecipient: this.selectedRecipient } });
            }
        };
        /**
         * Clear search terms
         */
        this.clearSearch = () => {
            this.searchTerm = '';
            this.ctrl.searchText = '';
            this.ctrl.selected = '';
        };
        /**
         * Switch research from md-select to md-autocomplete and in return
         */
        this.changeResearchMode = () => {
            this.completeResearch = !this.completeResearch;
            this.clearSearch();
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
}
