var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Input } from '@angular/core';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { CHsDashboardIconsCategory } from '@app/hospistock/shared/constTypes/hs-dashboard.const';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { HsNewsService } from '@services/homepage/widgets/hospistock/hospistock-news.service';
let HsDashboardWidgetNewsComponent = class HsDashboardWidgetNewsComponent {
    constructor(_newsService, _translate, _fuseTranslationLoaderService) {
        this._newsService = _newsService;
        this._translate = _translate;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._isModeInput = false;
        this.news = [];
        this.end = false;
        this.iconsCategory = CHsDashboardIconsCategory;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Widget config
        this.widgetContainerConfig = {
            title: this._translate.instant('DASHBOARD.WIDGET.NEWS.TITLE')
        };
    }
    set newsInput(values) {
        this._isModeInput = true;
        this.news = values;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        if (!this._isModeInput) {
            this.getNews();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getNews() {
        this.isLoading = true;
        this._newsService.getLines().subscribe({
            next: (data) => {
                this._paginateNews = data;
                this.news.push(...data.items);
            },
            error: (err) => console.error(err),
            complete: () => (this.isLoading = false)
        });
    }
    getNextNews() {
        var _a;
        if (!((_a = this._paginateNews) === null || _a === void 0 ? void 0 : _a.hasNextPage) || this._isModeInput) {
            this.end = true;
            return;
        }
        this.isGetLines = true;
        this._newsService.getNextNews({ page: this._paginateNews.nextPage, limit: 5 }).subscribe({
            next: (data) => {
                this._paginateNews = data;
                this.news.push(...data.items);
            },
            error: (err) => console.error(err),
            complete: () => (this.isGetLines = false)
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof Array !== "undefined" && Array) === "function" ? _d : Object),
    __metadata("design:paramtypes", [typeof (_e = typeof Array !== "undefined" && Array) === "function" ? _e : Object])
], HsDashboardWidgetNewsComponent.prototype, "newsInput", null);
HsDashboardWidgetNewsComponent = __decorate([
    Component({
        selector: 'mpx-hs-dashboard-widget-news',
        template: require('./hs-dashboard-widget-news.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HsNewsService !== "undefined" && HsNewsService) === "function" ? _a : Object, typeof (_b = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object])
], HsDashboardWidgetNewsComponent);
export { HsDashboardWidgetNewsComponent };
