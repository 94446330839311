var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdminARSModule } from './ars/ars.module';
import { CronModule } from './cron/cron.module';
import { ExternalActorsModule } from './external-actors/external-actors.module';
import { GroupsModule } from './groups/groups.module';
import { HomepageModule } from './homepage/homepage.module';
import { AdminHsModule } from './hospistock/admin-hs.module';
import { HospitalsModule } from './hospitals/hospitals.module';
import { AdminLabosModule } from './labos/labos.module';
import { MembersModule } from './members/members.module';
import { OnlineUsersModule } from './online-users/online-users.module';
import { PharmaciesModule } from './pharmacies/pharmacies.module';
import { AdminPurchaseGroupsModule } from './purchase-groups/purchase-groups.module';
import { RolesModule } from './roles/roles.module';
import { SandboxModule } from './sandbox/sandbox.module';
import { SecureEmailsModule } from './secure-emails/secure-emails.module';
import { AdminRoutingModule } from './admin-routing.module';
import { StatsModule } from './stats/stats.module';
import { SharedModule } from '@app/shared/shared.module';
let AdminModule = class AdminModule {
};
AdminModule = __decorate([
    NgModule({
        declarations: [],
        imports: [
            AdminARSModule,
            AdminHsModule,
            AdminLabosModule,
            AdminPurchaseGroupsModule,
            CommonModule,
            CronModule,
            ExternalActorsModule,
            GroupsModule,
            HomepageModule,
            HospitalsModule,
            MembersModule,
            OnlineUsersModule,
            PharmaciesModule,
            RolesModule,
            SandboxModule,
            SecureEmailsModule,
            SharedModule,
            StatsModule
        ],
        exports: [AdminRoutingModule]
    })
], AdminModule);
export { AdminModule };
