import * as angular from 'angular';
export class StaticResource {
    /** @ngInject */
    constructor($http, $q) {
        this.$http = $http;
        this.$q = $q;
        /**
         * Get static resource
         */
        this.get = (url) => {
            const defer = this.$q.defer();
            if (this.loaded.hasOwnProperty(url)) {
                // From cache (loaded)
                defer.resolve(angular.copy(this.loaded[url]));
            }
            else if (this.loading.hasOwnProperty(url)) {
                // From a currently loading request (loading)
                this.loading[url].then(() => {
                    defer.resolve(angular.copy(this.loaded[url]));
                }).catch(err => defer.reject(err));
            }
            else {
                // Request from server (first call)
                const loadingDefer = this.$q.defer();
                this.loading[url] = loadingDefer.promise;
                this.$http({ method: 'GET', url: url })
                    .then(res => {
                    this.loaded[url] = res.data;
                    defer.resolve(angular.copy(this.loaded[url]));
                    loadingDefer.resolve(angular.copy(this.loaded[url]));
                })
                    .catch(err => {
                    defer.reject(err);
                    loadingDefer.reject(err);
                })
                    .finally(() => delete this.loading[url]);
            }
            return defer.promise;
        };
        this.loaded = {};
        this.loading = {};
    }
}
