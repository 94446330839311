import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class HvModalSearchHealthProController {
    /** @ngInject */
    constructor(HealthProfessionalService, ModalService) {
        this.HealthProfessionalService = HealthProfessionalService;
        this.ModalService = ModalService;
        this.searchDoc = () => {
            var _a;
            delete this.doc;
            delete this.selectIdx;
            if (((_a = this.searchName) === null || _a === void 0 ? void 0 : _a.length) >= 3)
                this.HealthProfessionalService.findDoctor(this.searchName).then((items) => {
                    this.docs = items;
                });
        };
        this.selectDoc = (index) => {
            this.doc = this.docs[index];
            this.selectIdx = index;
        };
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close modal with actions performing
         */
        this.ok = () => {
            this.ModalService.close(this.doc);
        };
    }
}
