var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { HsLaboDrugAlternativeRoutingModule } from './alternative/hs-labo-drug-alternative-routing.module';
import { HsLaboDrugDashboardRoutingModule } from './dashboard/hs-labo-drug-dashboard-routing.module';
import { HsLaboDrugPublicationRoutingModule } from './publication/hs-labo-drug-publication-routing.module';
import { HsLaboDrugStatisticRoutingModule } from '@app/hospistock/labo/drug/statistic/hs-labo-drug-statistic-routing.module';
const states = [
    {
        name: 'mapui.hospistock.labo.drug',
        url: '/drug',
        abstract: true
    }
];
let HsLaboDrugRoutingModule = class HsLaboDrugRoutingModule {
};
HsLaboDrugRoutingModule = __decorate([
    NgModule({
        imports: [
            UIRouterModule.forChild({ states: states }),
            HsLaboDrugPublicationRoutingModule,
            HsLaboDrugStatisticRoutingModule,
            HsLaboDrugDashboardRoutingModule,
            HsLaboDrugAlternativeRoutingModule
        ]
    })
], HsLaboDrugRoutingModule);
export { HsLaboDrugRoutingModule };
