export class MpModalPdfViewerController {
    /**
     * @param $sce
     * @param FilesService
     */
    /** @ngInject */
    constructor($sce, FilesService) {
        this.$sce = $sce;
        this.FilesService = FilesService;
    }
    $onInit() {
        this.FilesService.downloadFile(this.file.url).then((response) => {
            let currentblob = new Blob([response.data], { type: 'application/pdf' });
            this.blobFileUrl = this.$sce.trustAsResourceUrl(URL.createObjectURL(currentblob));
        }).catch((error) => console.log(error));
    }
}
