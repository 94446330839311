var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EstablishmentPatientService } from '../../../../services/establishment-patient/establishment-patient.service';
import { IdentityService } from '../../../../services/identity/identity.service';
import { ListPage } from '../../../../shared/templates/listPage/listPage.class';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let ArchivedPatientsListComponent = class ArchivedPatientsListComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _identityService, _injector, _establishmentService) {
        super(_injector, _establishmentService.getAllArchivedPatientsByEstablishmentPaginated);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._identityService = _identityService;
        this._injector = _injector;
        this._establishmentService = _establishmentService;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.params = [{ param: 'hospital', value: JSON.stringify(_identityService.hospital._id) }];
        // Header
        this.pageHeaderConfig = {
            title: this._translate('ARCHIVED_PATIENTS.TITLE'),
            searchBarPlaceholder: this._translate('ARCHIVED_PATIENTS.SEARCH'),
            icon: 'folder_shared'
        };
        this.columns = [
            {
                label: this._translate('ARCHIVED_PATIENTS.TABLE.FULLNAME'),
                attribute: 'masterPatient.fullName',
                sort: true
            }
        ];
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_e = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _e : Object)
], ArchivedPatientsListComponent.prototype, "_paginator", void 0);
ArchivedPatientsListComponent = __decorate([
    Component({
        selector: 'mpx-archived-patients-list',
        template: require('./archived-patients-list.component.html').default,
        styles: [require('./archived-patients-list.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object, typeof (_c = typeof Injector !== "undefined" && Injector) === "function" ? _c : Object, typeof (_d = typeof EstablishmentPatientService !== "undefined" && EstablishmentPatientService) === "function" ? _d : Object])
], ArchivedPatientsListComponent);
export { ArchivedPatientsListComponent };
