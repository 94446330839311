var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavBarService } from '@services/nav-bar-subject/nav-bar-subject.service';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
let PageHeaderService = class PageHeaderService {
    constructor(_mediaObserver, _navBarService) {
        this._mediaObserver = _mediaObserver;
        this._navBarService = _navBarService;
        // Subjects
        this._unsubscribeAll = new Subject();
        this.isCompactViewMode = new BehaviorSubject(false);
        // Update isCompactView on responsives & navBarUnfolded changes
        merge(this._navBarService.navBarUnfolded.pipe(tap((value) => (this._isNavBarUnfolded = value))), this._mediaObserver.asObservable())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._updateIsCompactView());
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    _updateIsCompactView() {
        this.isCompactViewMode.next(this._mediaObserver.isActive('lt-md') || (this._mediaObserver.isActive('md') && this._isNavBarUnfolded));
    }
};
PageHeaderService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _a : Object, typeof (_b = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _b : Object])
], PageHeaderService);
export { PageHeaderService };
