export const locale = {
    lang: 'en',
    data: {
        ERRORS: {
            IMPORT_ERROR: 'Import file error',
            PARSING_ERROR: 'Processing file error'
        },
        MESSAGES: {
            PARSING: {
                STATUS: {
                    DONE: 'File processing success!',
                    EMPTY: 'No element to import.',
                    FAILED: 'File processing failed!',
                    IN_PROGRESS: 'File processing in progress...',
                    WARNING: 'File processed with warnings'
                },
                REPORT: {
                    ERROR: {
                        STATUS: 'Major errors',
                        INSTRUCTIONS: 'Please correct the errors listed below before trying again the importation.'
                    },
                    WARNING: {
                        STATUS: 'Moderate errors',
                        INSTRUCTIONS: 'The related row will not be imported. To importe those rows you must correct the errors listed below and try again the importation.'
                    },
                    INFO: {
                        STATUS: 'Minor errors',
                        INSTRUCTIONS: 'You can correct the errors listed below to improve the file stability.'
                    },
                    TEMPLATE: {
                        COLUMN: 'Column ',
                        ERROR_MESSAGE: 'Error ',
                        ROW: 'Row ',
                        VALUE: 'Value'
                    }
                }
            },
            IMPORT: {
                STATUS: {
                    DONE: 'Import success!',
                    DONEEMPTY: 'No element imported.',
                    FAILED: 'Import failed!',
                    IN_PROGRESS: 'Import in progress...'
                },
                REPORT: {
                    ERROR: {
                        STATUS: 'Major errors',
                        INSTRUCTIONS: 'Import failed.'
                    },
                    WARNING: {
                        STATUS: 'Minor errors',
                        INSTRUCTIONS: 'Some elements could not be imported.'
                    },
                    INFO: {
                        STATUS: 'Information',
                        INSTRUCTIONS: 'Some elements were imported with additional information.'
                    },
                    SUCCESS: {
                        STATUS: 'Import success'
                    }
                }
            },
            SELECTION: {
                STATUS: {
                    TITLE: 'Select a file to import'
                }
            }
        },
        TITLES: {
            MAIN: 'Import',
            PARSING: {
                STATUS: 'File processing',
                REPORT: 'Processing report'
            },
            IMPORT: {
                STATUS: 'File import',
                REPORT: 'Import report'
            },
            SELECTION: 'Select the booklet to import'
        }
    }
};
