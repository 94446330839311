import * as angular from 'angular';
export class HvTreatmentAnalysisController {
    /** @ngInject */
    constructor(InformationService, _, Api, Access, ModalService, msApi) {
        this.InformationService = InformationService;
        this._ = _;
        this.Api = Api;
        this.Access = Access;
        this.ModalService = ModalService;
        this.msApi = msApi;
        /**
         * Open Modal, edit [[IPharmaIntervention]]
         */
        this.editIntervention = ($event, selectedIntervention) => {
            this.ModalService.show({
                component: 'hv-modal-pharma-intervention',
                bindings: {
                    intervention: selectedIntervention,
                    drugs: this.drugs,
                },
                escapeToClose: true,
            }).then((intervention) => {
                if (!selectedIntervention)
                    this.interventions.push(intervention);
                else
                    selectedIntervention = angular.copy(intervention);
            });
        };
        /**
         * Delete the given [[IPharmaIntervention]]
         * @param selectedIntervention
         */
        this.deleteIntervention = (selectedIntervention) => {
        };
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[from], { value: value }).translate;
        };
        this.interventions = [];
        this.ready = false;
        msApi.request('treatment.data@get', {}, 
        // SUCCESS
        (response) => {
            this.drugs = response.treatment.instances;
        });
    }
    $onInit() {
        if (this.Access.patient) {
            this.ready = true;
        }
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
}
