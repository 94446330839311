/** @ngInject */
export class MpSearchCountryController {
    /** @ngInject */
    constructor(GeoService) {
        this.GeoService = GeoService;
        /**
         * Search all [[ITown]] occurrence from the searchText
         * @returns {angular.IPromise<Array<ITown>>}
         */
        this.searchCountry = () => {
            return this.GeoService.findCountry(this.ctrl.searchText);
        };
        /**
         * An [[ITown]] is selected and returned
         */
        this.searchCountrySelected = () => {
            if (this.ctrl.selected) {
                this.onSelected({
                    $event: { country: this.ctrl.selected },
                });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.country) {
            this.ctrl.searchText = (this.country) ? this.country.name : '';
        }
    }
}
