import * as angular from 'angular';
export class MpTableDirective {
    /** @ngInject */
    constructor() {
        this.compile = (tElement, tAttrs) => {
            tElement.addClass('mp-table');
            if (tAttrs.hasOwnProperty('mpProgress')) {
                const body = tElement.find('tbody')[0];
                const progress = angular.element('<thead class="mp-table-progress" mp-table-progress>');
                if (body) {
                    tElement[0].insertBefore(progress[0], body);
                }
            }
        };
        this.restrict = 'A';
        this.controller = 'MpTableController';
        this.controllerAs = 'vm';
        this.scope = {};
        this.bindToController = {
            loading: '=mpProgress',
        };
    }
    static factory() {
        const directive = () => new MpTableDirective();
        directive.$inject = [];
        return directive;
    }
}
