var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@app/services/translation/translation.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { IdentityService } from '../../../services/identity/identity.service';
import { MemberService } from '../../../services/member/member.service';
import { CreateHospitalMemberComponent } from '../../../shared/components/create-hospital-member/create-hospital-member.component';
import { pharmacistsStatus } from '../../../shared/constTypes/pharmacists/pharmacists.const';
import { pharmacyStudentStatus } from '../../../shared/constTypes/pharmacists/pharmacy-students.const';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let HospitalTeamComponent = class HospitalTeamComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _translateService, _matDialog, _memberService, _identityService) {
        var _a;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._translateService = _translateService;
        this._matDialog = _matDialog;
        this._memberService = _memberService;
        this._identityService = _identityService;
        this.allHospitalMembersList = [];
        this.hospitalMembersList = [];
        this.tableColumns = ['name', 'email', 'type', 'status', 'actions'];
        this.typesList = [];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.updateMembersList();
        // Header
        this.pageHeaderConfig = {
            title: ((_a = this._identityService.hospital) === null || _a === void 0 ? void 0 : _a.name) || this._translationService.instant('HOSPITAL_TEAM.TITLE'),
            searchBarPlaceholder: this._translationService.instant('HOSPITAL_TEAM.SEARCH'),
            svgIcon: 'account_key'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    updateMembersList() {
        this._memberService.getCurrentHospitalMembers().subscribe((currentMembers) => {
            this.allHospitalMembersList = currentMembers;
            this.hospitalMembersList = currentMembers;
            this.typesList = _.compact(currentMembers.map(({ job }) => job));
            this.sortData(null);
        });
    }
    // Table managment
    setTableData(code) {
        if (code) {
            this.hospitalMembersList = this.allHospitalMembersList.filter((member) => { var _a; return ((_a = member.job) === null || _a === void 0 ? void 0 : _a.code) === code; });
        }
        else {
            this.hospitalMembersList = this.allHospitalMembersList;
        }
        this.sortData(this.lastSort);
    }
    getStatusTranslate(job, status) {
        if (job && status) {
            if (job.code === 'G15_21') {
                for (const pharmacist in pharmacistsStatus) {
                    if (pharmacistsStatus[pharmacist].value === status) {
                        return pharmacistsStatus[pharmacist].translate;
                    }
                }
            }
            if (job.code === 'G16_21') {
                for (const pharmaStudent in pharmacyStudentStatus) {
                    if (pharmacyStudentStatus[pharmaStudent].value === status) {
                        return pharmacyStudentStatus[pharmaStudent].translate;
                    }
                }
            }
        }
        return ' ';
    }
    // Edit member
    editMemberModal(member) {
        this._matDialog
            .open(CreateHospitalMemberComponent, {
            data: { member },
            maxWidth: '800px',
            width: '60%'
        })
            .afterClosed()
            .subscribe(() => {
            this.updateMembersList();
        });
    }
    // Sort
    sortCompare(a, b, direct) {
        if (!a) {
            return direct ? 1 : -1;
        }
        if (!b) {
            return direct ? -1 : 1;
        }
        if (a === b) {
            return 0;
        }
        if (a < b) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    sortData(sort) {
        this.lastSort = sort;
        if (!this.hospitalMembersList) {
            return;
        }
        const data = this.hospitalMembersList.slice();
        if (!sort || !sort.active || sort.direction === '') {
            this.hospitalMembersList = data.sort((a, b) => {
                var _a, _b;
                const value = this.sortCompare((_a = a.job) === null || _a === void 0 ? void 0 : _a.displayName, (_b = b.job) === null || _b === void 0 ? void 0 : _b.displayName, true);
                if (value === 0) {
                    return this.sortCompare(Utils.normalize(a.fullName), Utils.normalize(b.fullName), true);
                }
                return value;
            });
            return;
        }
        this.hospitalMembersList = data.sort((a, b) => {
            var _a, _b;
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.sortCompare(Utils.normalize(a.fullName), Utils.normalize(b.fullName), isAsc);
                case 'email':
                    return this.sortCompare(a.email, b.email, isAsc);
                case 'type':
                    return this.sortCompare((_a = a.job) === null || _a === void 0 ? void 0 : _a.displayName, (_b = b.job) === null || _b === void 0 ? void 0 : _b.displayName, isAsc);
                case 'status':
                    return this.sortCompare(this._translateService.instant(this.getStatusTranslate(a.job, a.status)), this._translateService.instant(this.getStatusTranslate(b.job, b.status)), isAsc);
                default:
                    return 0;
            }
        });
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        if (!this.searchValue) {
            this.hospitalMembersList = this.allHospitalMembersList;
            this.sortData(this.lastSort);
            return;
        }
        const searchRegex = new RegExp(Utils.normalize(this.searchValue), 'i');
        this.hospitalMembersList = this.allHospitalMembersList.filter((member) => {
            var _a;
            return testSearchRegex(member.fullName) ||
                testSearchRegex(member.email) ||
                testSearchRegex((_a = member.job) === null || _a === void 0 ? void 0 : _a.displayName) ||
                testSearchRegex(this._translateService.instant(this.getStatusTranslate(member.job, member.status)));
        });
        this.sortData(this.lastSort);
        function testSearchRegex(strToTest) {
            if (!strToTest) {
                return false;
            }
            return searchRegex.test(Utils.normalize(strToTest));
        }
    }
};
HospitalTeamComponent = __decorate([
    Component({
        selector: 'mpx-hospital-team',
        template: require('./hospital-team.component.html').default,
        styles: [require('./hospital-team.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof MemberService !== "undefined" && MemberService) === "function" ? _e : Object, typeof (_f = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _f : Object])
], HospitalTeamComponent);
export { HospitalTeamComponent };
