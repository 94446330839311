var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ng from 'angular';
import { Auth } from '../../main/services/auth/auth.service';
import { locale as french } from '../../app/i18n/fr';
import { locale as english } from '../../app/i18n/en';
let FuseTranslationLoaderService = class FuseTranslationLoaderService {
    /**
     * Constructor
     *
     * @param {TranslateService} _translateService
     * @param {Auth} _ajsAuthService
     * @param {$rootScope} $rootScope
     */
    constructor(_translateService, _ajsAuthService, $rootScope) {
        this._translateService = _translateService;
        this._ajsAuthService = _ajsAuthService;
        this.$rootScope = $rootScope;
        if (_ajsAuthService.user) {
            this._translateService.setDefaultLang(this._ajsAuthService.user.config.language);
        }
        $rootScope.$on('changeLanguage', (event, data) => {
            this._translateService.setDefaultLang(data);
        });
        this.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Load translations
     *
     * @param {Locale} args
     */
    loadTranslations(...args) {
        const locales = [...args];
        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this._translateService.setTranslation(locale.lang, locale.data, true);
        });
    }
};
FuseTranslationLoaderService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __param(2, Inject('$rootScope')),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _a : Object, typeof (_b = typeof Auth !== "undefined" && Auth) === "function" ? _b : Object, typeof (_c = typeof ng !== "undefined" && ng.IRootScopeService) === "function" ? _c : Object])
], FuseTranslationLoaderService);
export { FuseTranslationLoaderService };
