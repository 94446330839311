import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MpModalContactController {
    /** @ngInject */
    constructor(ModalService, Access, Api, ToastService) {
        this.ModalService = ModalService;
        this.Access = Access;
        this.Api = Api;
        this.ToastService = ToastService;
        this.send = () => {
            this.Api.contact.send({
                mail: this.email,
                subject: this.subject,
                message: this.message,
            }, (res) => {
                this.ToastService.show(EMpStatus.Ok);
                this.cancel();
            }, () => {
                this.ToastService.show(EMpStatus.RejectByServer);
                this.cancel();
            });
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        this.subject = 'Demande d\'information ';
    }
    $onInit() {
        this.email = this.Access.user.email;
    }
}
