export const locale = {
    lang: 'fr',
    data: {
        CRON: {
            TITLE: 'CRON Manager',
            TABLE: {
                NAME: 'Nom',
                STATUS: {
                    TITLE: 'Status',
                    RUNNING: 'En cours...',
                    ON: 'Programmé',
                    OFF: 'Déprogrammé'
                },
                LAST_RUN: 'Dernière exécution',
                SCHEDULE: 'Règle de récurrence',
                ACTIONS: {
                    TITLE: 'Actions',
                    RUN: {
                        TITLE: 'Lancer la tâche une fois',
                        MODAL: {
                            TITLE: 'Demande de confirmation',
                            MESSAGE: 'Voulez-vous vraiment lancer cette tâche ?',
                            NOTIF: 'Tâche lancée'
                        }
                    },
                    ON: {
                        TITLE: 'Programmer la tâche',
                        MODAL: {
                            TITLE: 'Demande de confirmation',
                            MESSAGE: 'Voulez-vous vraiment programmer cette tâche ?',
                            NOTIF: 'Tâche programmée'
                        }
                    },
                    OFF: {
                        TITLE: 'Déprogrammer la tâche',
                        MODAL: {
                            TITLE: 'Demande de confirmation',
                            MESSAGE: 'Voulez-vous vraiment déprogrammer cette tâche ?',
                            NOTIF: 'Tâche déprogrammée'
                        }
                    },
                    DELETE: {
                        TITLE: 'Supprimer la tâche',
                        MODAL: {
                            TITLE: 'Demande de confirmation',
                            MESSAGE: 'Voulez-vous vraiment supprimer cette tâche ? La classe associée doit être supprimée',
                            NOTIF: 'Tâche supprimée'
                        }
                    }
                }
            }
        }
    }
};
