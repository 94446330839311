export const locale = {
    lang: 'en',
    data: {
        ONLINE_USERS: {
            TITLE: 'Online users',
            SEARCH: 'Search a user',
            TABLE: {
                NAME: 'Name',
                EMAIL: 'Email',
                HOSPITAL: 'Hospital',
                GROUP: 'Group',
                STATUS: {
                    TITLE: 'Status',
                    ONLINE: 'Online',
                    OFFLINE: 'Offline'
                },
                SINCE: 'Since',
                USER_DELETED: 'User deleted',
                EMPTY: 'No online user found.',
                DETAIL: {
                    USER_PAGE: 'User page',
                    WEEK: 'Week ',
                    DURATION: 'Duration',
                    FROM: 'From',
                    TO: 'To',
                    EMPTY: "The user doesn't have a session this week."
                }
            }
        }
    }
};
