var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
let DialogService = class DialogService {
    constructor(_matDialog) {
        this._matDialog = _matDialog;
    }
    /**
     * Opens a configurable GenericDialogComponent and returns an Observable waiting
     * for the user to close the modal.
     * @param dialogConfig
     */
    openConfirmDialog(dialogConfig = {}) {
        if (!dialogConfig) {
            return null;
        }
        const confirmDialogRef = this._matDialog.open(GenericDialogComponent, {
            disableClose: false,
            maxWidth: dialogConfig === null || dialogConfig === void 0 ? void 0 : dialogConfig.maxWidth
        });
        if (dialogConfig.title) {
            confirmDialogRef.componentInstance.title = dialogConfig.title;
        }
        if (dialogConfig.message) {
            confirmDialogRef.componentInstance.message = dialogConfig.message;
        }
        if (dialogConfig.confirmButton) {
            confirmDialogRef.componentInstance.confirmButton = dialogConfig.confirmButton;
        }
        if (dialogConfig.cancelButton) {
            confirmDialogRef.componentInstance.cancelButton = dialogConfig.cancelButton;
        }
        return confirmDialogRef.afterClosed();
    }
};
DialogService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _a : Object])
], DialogService);
export { DialogService };
