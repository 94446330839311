import { EDosageType, EMpStatus, EPosologyType, } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HvListInstancesController {
    /** @ngInject */
    constructor(Api, _, HospivilleService, DrugInstanceService, ToastService) {
        this.Api = Api;
        this._ = _;
        this.HospivilleService = HospivilleService;
        this.DrugInstanceService = DrugInstanceService;
        this.ToastService = ToastService;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * Select a drug with mp-search-drug component (from autocomplete)
         * @param event
         */
        this.selectDrug = (event) => {
            this.newDI.denomination = event.drug.denomination;
            this.newDI.UCD13 = event.drug.UCD13;
            this.newDI.codeATC = event.drug.codeATC;
            this.newDI.DCI = event.drug.DCI;
            if (this._.find(this.constTypes.dosages, { value: +event.pharmaForm })) {
                this.newDI.sources[0].dosageType = +event.pharmaForm;
            }
            if (this.newDI.sources[0].dosageType == 14) {
                this.newDI.sources[0].dosageDropInfo = {
                    left: 0,
                    right: 0,
                };
            }
        };
        /**
         * Set given [[IDrugInstanceSource]] dosage
         * @param {number} quantity
         * @param {number} type
         * @param {IDropInfo} dropInfo
         * @param {IDrugInstanceSource} instSource
         */
        this.dosageChange = ({ quantity, type, dropInfo }, instSource) => {
            instSource.dosage = quantity;
            instSource.dosageType = type;
            if (type == 14) {
                instSource.dosageDropInfo = dropInfo;
            }
        };
        /**
         * Set given [[IDrugInstanceSource]] posology
         * @param posology
         * @param instSource
         */
        this.posologyChange = ({ posologies }, instSource) => {
            instSource.posologies = posologies;
        };
        /**
         * Add current instance to [[IDrugInstance]]s and init new instance
         */
        this.addNewInstance = () => {
            if (!this.instances) {
                this.instances = [];
            }
            this.instances.push(angular.copy(this.newDI));
            this.newDI = { sources: [{}], index: ++this._index };
        };
        /**
         * Delete given index [[IDrugInstance]]
         * @param {IDrugInstance} inst
         * @param {number} index
         */
        this.deleteInst = (inst, index) => {
            this.instances.splice(index, 1);
            if (Utils.hasId(inst)) {
                this.DrugInstanceService.save(this._resetOwnSrc(inst))
                    .then(() => this.ToastService.show(EMpStatus.Ok))
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            }
        };
        /**
         * Reset dosage and posologies attributes of own source in the DrugInstance
         * @param instance
         * @private
         */
        this._resetOwnSrc = (instance) => {
            for (let dbInstance of this.drugBalance.instances) {
                if (dbInstance._id == instance._id) {
                    const ownSrcIndex = dbInstance.sources.findIndex((source) => source.source._id == this.source._id);
                    if (ownSrcIndex >= 0) {
                        dbInstance.sources[ownSrcIndex].dosage = null;
                        dbInstance.sources[ownSrcIndex].posologies = [];
                        if (dbInstance.sources[ownSrcIndex].dosageType === EDosageType.eye_drop) {
                            dbInstance.sources[ownSrcIndex].dosageDropInfo = null;
                        }
                    }
                    return dbInstance;
                }
            }
        };
        /**
         * When instances need to be saved
         */
        this.save = () => {
            if (this.onSave) {
                if (this.addToDbs)
                    this._addToDbs();
                this.onSave({
                    $event: { dbs: this.drugBalance, instances: this.instances },
                });
            }
        };
        /**
         * Add new [[IDrugInstance]]s to the given [[IDrugBalance]]
         * @private
         */
        this._addToDbs = () => {
            if (!this.drugBalance.sources) {
                this.drugBalance.sources = [];
            }
            if (!this.drugBalance.instances) {
                this.drugBalance.instances = this.instances;
            }
            else {
                for (let source of this.drugBalance.sources) {
                    if (Utils.compareIds(source.source, this.source)) {
                        source.date = new Date();
                        break;
                    }
                }
                // Add new drugs in DrugB
                for (const instance of this.instances) {
                    const dbInst = this.drugBalance.instances.find((inst) => inst.denomination == instance.denomination);
                    if (!dbInst) {
                        instance.sources[0].source = { _id: this.source._id };
                        this.drugBalance.instances.push(angular.copy(instance));
                    }
                }
                const newInstances = this.instances.filter((inst) => {
                    return !!inst.sources.find((src) => !src.source);
                });
                if (newInstances) {
                    for (let instance in this.drugBalance.instances) {
                        if (this.drugBalance.instances.hasOwnProperty(instance)) {
                            let sameInst = newInstances.find((inst) => {
                                return inst.denomination == this.drugBalance.instances[instance].denomination;
                            });
                            if (sameInst) {
                                const dBSrcIndex = this.drugBalance.instances[instance].sources.findIndex((src) => Utils.compareIds(this.source, src.source));
                                sameInst.sources[0].source = { _id: this.source._id };
                                if (dBSrcIndex < 0) {
                                    this.drugBalance.instances[instance].sources.push(sameInst.sources[0]);
                                }
                                else {
                                    this.drugBalance.instances[instance].sources.splice(dBSrcIndex, 1, sameInst.sources[0]);
                                }
                            }
                        }
                    }
                }
            }
            const existingSrc = this._.find(this.drugBalance.sources, (source) => {
                return source.source._id === this.source._id;
            });
            if (!existingSrc) {
                this.drugBalance.sources.push({ date: new Date(), source: this.source });
            }
        };
        /**
         * Get the higher index
         * @param index
         * @private
         */
        this._maxIndex = (index) => {
            this._index = index >= this._index ? index + 1 : this._index;
            this.newDI.index = this._index;
        };
        this.findOwnSource = (instance) => {
            return this._.find(instance.sources, (src) => {
                var _a;
                if (src.source === undefined) {
                    return true;
                }
                return ((_a = src.source) === null || _a === void 0 ? void 0 : _a._id) === this.source._id;
            });
        };
        this.newDI = { sources: [{}] };
        this._index = 0;
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
    $onChanges(changes) {
        var _a;
        if (changes.instances) {
            this.instances = angular.copy(changes.instances.currentValue);
            this._maxIndex(this.HospivilleService.getMaxIndex());
        }
        if (changes.drugBalance) {
            this.drugBalance = angular.copy(changes.drugBalance.currentValue);
            if (this.drugBalance) {
                this._maxIndex(this.HospivilleService.getMaxIndex());
            }
            if ((_a = this.drugBalance) === null || _a === void 0 ? void 0 : _a.instances) {
                // Show instances that have already been saved in drug balance by this source
                for (const instance of this.drugBalance.instances) {
                    if (!this.instances) {
                        this.instances = [];
                    }
                    const sourceInstance = instance.sources.find((source) => {
                        return source.source._id === this.source._id
                            && ((source.dosage && source.dosageType) || source.dosageDropInfo);
                    });
                    if (sourceInstance) {
                        this.instances.push(instance);
                    }
                }
            }
        }
        if (changes.source) {
            this.source = angular.copy(changes.source.currentValue);
        }
    }
}
