export class HvDoctorSelectionController {
    /** @ngInject */
    constructor(ModalService, Access) {
        this.ModalService = ModalService;
        this.Access = Access;
        /**
         * Select a Doctor by opening a modal
         */
        this.selectDoctor = () => {
            this.ModalService.show({
                component: 'hv-modal-search-health-pro',
                bindings: {
                    source: this.source,
                    title: this.mpTitle,
                },
                escapeToClose: true,
            }).then((res) => {
                this.doctor = res;
                this.change = true;
                this.onChange({
                    $event: { doctor: this.doctor, change: this.change },
                });
            }).catch(() => {
                if (!this.doctor) {
                    this.deleteDoctor();
                }
            });
        };
        /**
         *  delete doctor
         */
        this.deleteDoctor = () => {
            this.change = true;
            this.onDelete({
                $event: { doctor: this.doctor, change: this.change },
            });
            this.doctor = null;
        };
        /**
         *
         */
        this.formatDoctorName = () => {
            let formatedName = '';
            if (this.doctor) {
                this.doctor.dutyFirstName ? formatedName += `${this.doctor.dutyFirstName.substring(0, 1).toUpperCase()}${this.doctor.dutyFirstName.substring(1, this.doctor.dutyFirstName.length).toLowerCase()}` : formatedName += '';
                this.doctor.dutyLastName ? formatedName += ` ${this.doctor.dutyLastName.toUpperCase()}` : formatedName += '';
                this.doctor._id ? formatedName += ` (${this.doctor._id})` : formatedName += '';
            }
            return formatedName;
        };
    }
    $onInit() {
        if (this.source) {
            this.doctor = this.source.healthProfessional;
        }
        if (this.openmodal == true) {
            this.selectDoctor();
        }
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(change) {
        if (change.source && change.source.currentValue) {
            this.source = change.source.currentValue;
            this.doctor = this.source.healthProfessional;
        }
    }
}
