var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Directive, Input, HostListener, HostBinding } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaObserver } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
let FuseMatSidenavHelperDirective = class FuseMatSidenavHelperDirective {
    /**
     * Constructor
     *
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseMatSidenavHelperService} _fuseMatSidenavHelperService
     * @param {MatSidenav} _matSidenav
     * @param {MediaObserver} _mediaObserver
     */
    constructor(_fuseMatchMediaService, _fuseMatSidenavHelperService, _matSidenav, _mediaObserver) {
        this._fuseMatchMediaService = _fuseMatchMediaService;
        this._fuseMatSidenavHelperService = _fuseMatSidenavHelperService;
        this._matSidenav = _matSidenav;
        this._mediaObserver = _mediaObserver;
        // Set the defaults
        this.isLockedOpen = true;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Register the sidenav to the service
        this._fuseMatSidenavHelperService.setSidenav(this.fuseMatSidenavHelper, this._matSidenav);
        if (this.matIsLockedOpen && this._mediaObserver.isActive(this.matIsLockedOpen)) {
            this.isLockedOpen = true;
            this._matSidenav.mode = 'side';
            this._matSidenav.toggle(true);
        }
        else {
            this.isLockedOpen = false;
            this._matSidenav.mode = 'over';
            this._matSidenav.toggle(false);
        }
        this._fuseMatchMediaService.onMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            if (this.matIsLockedOpen && this._mediaObserver.isActive(this.matIsLockedOpen)) {
                this.isLockedOpen = true;
                this._matSidenav.mode = 'side';
                this._matSidenav.toggle(true);
            }
            else {
                this.isLockedOpen = false;
                this._matSidenav.mode = 'over';
                this._matSidenav.toggle(false);
            }
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
};
__decorate([
    HostBinding('class.mat-is-locked-open'),
    __metadata("design:type", Boolean)
], FuseMatSidenavHelperDirective.prototype, "isLockedOpen", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FuseMatSidenavHelperDirective.prototype, "fuseMatSidenavHelper", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], FuseMatSidenavHelperDirective.prototype, "matIsLockedOpen", void 0);
FuseMatSidenavHelperDirective = __decorate([
    Directive({
        selector: '[fuseMatSidenavHelper]'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseMatchMediaService !== "undefined" && FuseMatchMediaService) === "function" ? _a : Object, typeof (_b = typeof FuseMatSidenavHelperService !== "undefined" && FuseMatSidenavHelperService) === "function" ? _b : Object, typeof (_c = typeof MatSidenav !== "undefined" && MatSidenav) === "function" ? _c : Object, typeof (_d = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _d : Object])
], FuseMatSidenavHelperDirective);
export { FuseMatSidenavHelperDirective };
let FuseMatSidenavTogglerDirective = class FuseMatSidenavTogglerDirective {
    /**
     * Constructor
     *
     * @param {FuseMatSidenavHelperService} _fuseMatSidenavHelperService
     */
    constructor(_fuseMatSidenavHelperService) {
        this._fuseMatSidenavHelperService = _fuseMatSidenavHelperService;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * On click
     */
    onClick() {
        this._fuseMatSidenavHelperService.getSidenav(this.fuseMatSidenavToggler).toggle();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], FuseMatSidenavTogglerDirective.prototype, "fuseMatSidenavToggler", void 0);
__decorate([
    HostListener('click'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FuseMatSidenavTogglerDirective.prototype, "onClick", null);
FuseMatSidenavTogglerDirective = __decorate([
    Directive({
        selector: '[fuseMatSidenavToggler]'
    }),
    __metadata("design:paramtypes", [typeof (_e = typeof FuseMatSidenavHelperService !== "undefined" && FuseMatSidenavHelperService) === "function" ? _e : Object])
], FuseMatSidenavTogglerDirective);
export { FuseMatSidenavTogglerDirective };
