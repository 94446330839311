import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
/** @ngInject */
export class HvModalMergePatientInfoController {
    /** @ngInject */
    constructor(PatientService, ModalService, $q, $filter, _) {
        this.PatientService = PatientService;
        this.ModalService = ModalService;
        this.$q = $q;
        this.$filter = $filter;
        this._ = _;
        this.config = {
            lines: [
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.ALLERGIES',
                    display: function (obj) {
                        let str = '';
                        if (obj.allergies && obj.allergies.length) {
                            for (let allergy of obj.allergies) {
                                str += allergy.denomination + '<br>';
                            }
                        }
                        return str;
                    },
                    compare: function (obj1, obj2) {
                        if (!obj1.allergies && !obj2.allergies)
                            return true;
                        if ((!obj1.allergies && obj2.allergies)
                            || (obj1.allergies && !obj2.allergies)
                            || (obj1.allergies.length != obj2.allergies.length)) {
                            return false;
                        }
                        for (let idx in obj1.allergies) {
                            if ((obj1.allergies && obj2.allergies && obj1.allergies[idx] && obj2.allergies[idx]) &&
                                obj1.allergies[idx].UCD13 != obj2.allergies[idx].UCD13)
                                return false;
                        }
                        return true;
                    },
                    attribute: 'allergies',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.ALLERGIES_COMMENT',
                    attribute: 'allergiesComment',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.ANTIBIOTIC',
                    attribute: 'antibiotic',
                    display: (obj) => {
                        let str = '';
                        if (obj.antibiotic && obj.antibiotic.drug) {
                            str += obj.antibiotic.drug.denomination + '<br>';
                            if (obj.antibiotic && obj.antibiotic.date)
                                str += this.$filter('date')(obj.antibiotic.date);
                        }
                        return str;
                    },
                    compare: (obj1, obj2) => {
                        if (!obj1.antibiotic && !obj2.antibiotic)
                            return true;
                        else if (obj1.antibiotic && obj2.antibiotic && obj1.antibiotic.drug && obj2.antibiotic.drug) {
                            if (obj1.antibiotic.drug.UCD13 != obj2.antibiotic.drug.UCD13)
                                return false;
                            if (this.$filter('date')(obj1.antibiotic.date) != this.$filter('date')(obj2.antibiotic.date))
                                return false;
                            return true;
                        }
                        return false;
                    },
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.LPPI',
                    attribute: 'lppi',
                    display: function (obj) {
                        if (!obj.lppi)
                            return '';
                        let str = '';
                        for (let inst in obj.lppi) {
                            str += obj.lppi[inst] + '<br>';
                        }
                        return str;
                    },
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.SMOKING',
                    attribute: 'smoking',
                    display: function (obj) {
                        if (obj.smoking)
                            return 'Oui';
                        else
                            return 'Non';
                    },
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.HISTORY',
                    attribute: 'medicalHistory',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.WEIGHT',
                    attribute: 'weight',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.HEIGHT',
                    attribute: 'height',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.IMC',
                    attribute: 'imc',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.OBSERVANCE_COMMENT',
                    attribute: 'observanceComment',
                },
                {
                    label: 'HOSPIVILLE.MERGE_PATIENT_INFO.DATA.DIFFICULTIES_COMMENT',
                    attribute: 'difficultiesComment',
                },
            ],
            sources: [{ name: 'Patient partagé' }, { name: 'Patient de mon hôpital' }],
        };
        this.onChangeMerge = (res) => {
            this.mergeComplete = res.complete;
            this._mergeResult = res.dest;
        };
        this.ok = () => {
            this.patient = this._.extend(this.patient, this._mergeResult);
            this.ModalService.close(this.patient);
        };
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Load the oldpatientInfo from server
         * @private
         */
        this._loadPatientInfo = () => {
            const defer = this.$q.defer();
            if (!this.patient || !this.patient.oldPatientInfo)
                return;
            this.PatientService.get(Utils.getId(this.patient), true).then((pat) => {
                this.patient = pat;
                this.PatientService.get(Utils.getId(this.patient), true, true).then((oldPat) => {
                    this.oldPatient = oldPat;
                    defer.resolve(this.oldPatient);
                }).catch((err) => {
                    console.log(err);
                    defer.reject(err);
                });
            });
            return defer.promise;
        };
        // console.log('MODAL MERGE CTOR');
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.patient) {
            this.patient = angular.copy(this.patient);
            console.log(angular.copy(this.patient));
            this._loadPatientInfo().then((ans) => {
                this.sources = [this.patient, this.oldPatient];
                console.log(this.sources);
            });
        }
    }
}
