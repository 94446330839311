var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EBilling } from '@mapuilabs/mpl-interfaces';
import { UrlService } from '@services/url/url.service';
import { BillingAwaitingComponent } from './billing-awaiting/billing-awaiting.component';
import { BillingSelectSettleComponent } from './billing-select-settle/billing-select-settle.component';
import { BillingSelectBillComponent } from './billing-bill/billing-select-bill.component';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let BillingComponent = class BillingComponent {
    /** @ngInject */
    constructor(_urlService, _translationService, _fuseTranslationLoaderService) {
        this._urlService = _urlService;
        this._translationService = _translationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        /**
         * The search query
         */
        this.searchValue = '';
        /**
         *
         * @type {EBilling}
         */
        this.EBilling = EBilling;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: `${this._translate('BILLING.BILLINGS')} / ${this._translate('BILLING.SETTLEMENTS')}`,
            icon: 'receipt_long'
        };
    }
    ngAfterViewInit() {
        this.tabGroup.selectedIndex = this._urlService.getParam('tab') || 0;
    }
    onTabChange(event) {
        this._urlService.addParams({ tab: event.index }, true);
    }
    _translate(value) {
        return this._translationService.instant(value);
    }
};
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_d = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _d : Object)
], BillingComponent.prototype, "tabGroup", void 0);
__decorate([
    ViewChild('billingTab', { static: false }),
    __metadata("design:type", typeof (_e = typeof BillingAwaitingComponent !== "undefined" && BillingAwaitingComponent) === "function" ? _e : Object)
], BillingComponent.prototype, "billingTab", void 0);
__decorate([
    ViewChild('selectBillingTab', { static: false }),
    __metadata("design:type", typeof (_f = typeof BillingSelectBillComponent !== "undefined" && BillingSelectBillComponent) === "function" ? _f : Object)
], BillingComponent.prototype, "selectBillingTab", void 0);
__decorate([
    ViewChild('selectSettlementTab', { static: false }),
    __metadata("design:type", typeof (_g = typeof BillingSelectSettleComponent !== "undefined" && BillingSelectSettleComponent) === "function" ? _g : Object)
], BillingComponent.prototype, "selectSettleTab", void 0);
BillingComponent = __decorate([
    Component({
        selector: 'mpx-billing',
        template: require('./billing.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UrlService !== "undefined" && UrlService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object])
], BillingComponent);
export { BillingComponent };
