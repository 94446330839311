var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AvailableAndOffers } from '@mapuilabs/mpl-interfaces';
import { take } from 'rxjs/operators';
import { HospitalService } from '@services/hospital/hospital.service';
let AcceptAvailableOfferComponent = class AcceptAvailableOfferComponent {
    constructor(_hospitalService, data) {
        this._hospitalService = _hospitalService;
        this.data = data;
        this._getDistance();
    }
    get available() {
        return this.data.available;
    }
    get offer() {
        return this.data.offers[0];
    }
    get hospital() {
        return this.offer.hospital;
    }
    get totalPrice() {
        return Number((this.available.quantity * this.available.unitPrice).toFixed(2));
    }
    _getDistance() {
        this._hospitalService
            .getDistance(this.offer.hospital._id)
            .pipe(take(1))
            .subscribe((distance) => {
            this.distance = distance;
        }, console.error);
    }
};
AcceptAvailableOfferComponent = __decorate([
    Component({
        selector: 'mpx-accept-available-offer',
        template: require('./accept-available-offer.component.html').default,
        styles: [require('./accept-available-offer.component.scss')]
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _a : Object, typeof (_b = typeof AvailableAndOffers !== "undefined" && AvailableAndOffers) === "function" ? _b : Object])
], AcceptAvailableOfferComponent);
export { AcceptAvailableOfferComponent };
