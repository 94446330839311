import { HvPublicPatientInterviewSurveyInfoController } from './public-patient-interview-survey.controller';
import * as angular from 'angular';
angular.module('hospiville.public-patient.interview-survey', [])
    .config(configPublicPatientInterviewSurvey)
    .controller('HvPublicPatientInterviewSurveyInfoController', HvPublicPatientInterviewSurveyInfoController);
function configPublicPatientInterviewSurvey($stateProvider) {
    $stateProvider.state('hospiville.public-patient-interview-survey', {
        views: {
            'main@': {
                template: require('../../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@hospiville.public-patient-interview-survey': {
                //template: '<hv-patient-interview-survey></hv-patient-interview-survey>'
                template: require('../hv-patient-interview-survey.html').default,
                controller: 'HvPublicPatientInterviewSurveyInfoController as vm',
            },
        },
        resolve: {},
        bodyClass: 'hospiville patient-interview-survey',
    });
}
