import { UnsupportedBrowserController } from './unsupported-browser.controller';
import * as angular from 'angular';
angular
    .module('mapui.services.auth.unsupported-browser', [])
    .config(config)
    .controller('UnsupportedBrowserController', UnsupportedBrowserController);
/** @ngInject */
function config($stateProvider) {
    $stateProvider.state('mapui.pages_incompatible_browser', {
        url: '/unsupported-browser',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_incompatible_browser': {
                template: require('./unsupported-browser.html').default,
                controller: 'UnsupportedBrowserController as vm',
            },
        },
        bodyClass: 'unsupported-browser',
    });
}
