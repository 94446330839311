var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { EEstablishmentType, Hospital } from '@mapuilabs/mpl-interfaces';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslationService } from '@services/translation/translation.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { InformationTabComponent } from './information-tab/information-tab.component';
import { ActionsTabComponent } from './actions-tab/actions-tab.component';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { HospitalService } from '@services/hospital/hospital.service';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
import { UrlService } from '@services/url/url.service';
let AdminDetailPurchaseGroupsComponent = class AdminDetailPurchaseGroupsComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _snackBarService, _hospitalService, _adminHospitalService, _urlService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._hospitalService = _hospitalService;
        this._adminHospitalService = _adminHospitalService;
        this._urlService = _urlService;
        this.detailForm = new UntypedFormGroup({
            infoForm: new UntypedFormGroup({}),
            actionsForm: new UntypedFormGroup({})
        });
        this.pageType = 'new';
        this.isMembersLoading = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        if (this.purchaseGroup) {
            this.pageType = 'edit';
        }
        else {
            this.purchaseGroup = new Hospital({
                establishmentType: EEstablishmentType.PurchaseGroup,
                trialEnd: new Date()
            });
        }
        const title = this.pageType === 'new'
            ? `${this._translationService.instant('PURCHASE_GROUPS.TITLE')} - ${this._translationService.instant('COMMONS.NEW')}`
            : `${this._translationService.instant('PURCHASE_GROUPS.TITLE')} - ${this.purchaseGroup.name}`;
        this.pageHeaderConfig = {
            title: title,
            svgIcon: 'preparation'
        };
    }
    ngAfterViewInit() {
        this.tabGroup.selectedIndex = this._urlService.getParam('tab') || 0;
    }
    onTabChange(event) {
        this._urlService.addParams({ tab: event.index }, true);
        this.animationDone = false;
    }
    isMemberTabActive() {
        var _a;
        if (this.pageType === 'edit' &&
            this.purchaseGroup &&
            this.membersTab.isActive &&
            (this.purchaseGroup.members === undefined || ((_a = this.purchaseGroup.members) === null || _a === void 0 ? void 0 : _a.length) <= 0) &&
            !this.isMembersLoading) {
            this.isMembersLoading = true;
            this._hospitalService.getMembers(this.purchaseGroup._id).subscribe((members) => {
                this.purchaseGroup.members = members;
                if (!members || members.length <= 0) {
                    this.purchaseGroup.members = null;
                }
                this.isMembersLoading = false;
            });
        }
    }
    updatePurchaseGroup() {
        this.informationTabComponent.updatePurchaseGroup(this.purchaseGroup);
        this.actionsTabComponent.updatePurchaseGroup(this.purchaseGroup);
        this.purchaseGroup.region = undefined;
    }
    savePurchaseGroup() {
        this.updatePurchaseGroup();
        this._adminHospitalService.save(this.purchaseGroup).subscribe((newPurchaseGroup) => {
            this._snackBarService.open(this._translationService.instant('COMMONS.SAVED'));
            this.purchaseGroup = newPurchaseGroup;
            this.detailForm.markAsPristine();
        });
    }
    addPurchaseGroup() {
        this.updatePurchaseGroup();
        this._hospitalService.add(this.purchaseGroup).subscribe((newPurchaseGroup) => {
            this._snackBarService.open(this._translationService.instant('COMMONS.CREATED'));
            this.purchaseGroup = newPurchaseGroup;
            this.pageType = 'edit';
            this.detailForm.markAsPristine();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Hospital !== "undefined" && Hospital) === "function" ? _g : Object)
], AdminDetailPurchaseGroupsComponent.prototype, "purchaseGroup", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _h : Object)
], AdminDetailPurchaseGroupsComponent.prototype, "detailForm", void 0);
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_j = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _j : Object)
], AdminDetailPurchaseGroupsComponent.prototype, "tabGroup", void 0);
__decorate([
    ViewChild(InformationTabComponent, { static: false }),
    __metadata("design:type", typeof (_k = typeof InformationTabComponent !== "undefined" && InformationTabComponent) === "function" ? _k : Object)
], AdminDetailPurchaseGroupsComponent.prototype, "informationTabComponent", void 0);
__decorate([
    ViewChild(ActionsTabComponent, { static: false }),
    __metadata("design:type", typeof (_l = typeof ActionsTabComponent !== "undefined" && ActionsTabComponent) === "function" ? _l : Object)
], AdminDetailPurchaseGroupsComponent.prototype, "actionsTabComponent", void 0);
__decorate([
    ViewChild('membersTab', { static: false }),
    __metadata("design:type", typeof (_m = typeof MatTab !== "undefined" && MatTab) === "function" ? _m : Object)
], AdminDetailPurchaseGroupsComponent.prototype, "membersTab", void 0);
AdminDetailPurchaseGroupsComponent = __decorate([
    Component({
        selector: 'mpx-detail-purchase-groups',
        template: require('./detail-purchase-groups.component.html').default,
        styles: [require('./detail-purchase-groups.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _d : Object, typeof (_e = typeof AdminHospitalService !== "undefined" && AdminHospitalService) === "function" ? _e : Object, typeof (_f = typeof UrlService !== "undefined" && UrlService) === "function" ? _f : Object])
], AdminDetailPurchaseGroupsComponent);
export { AdminDetailPurchaseGroupsComponent };
