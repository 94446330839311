import { EDosageType } from '@mapuilabs/mpl-interfaces';
export const CDosageType = {
    dosages: [
        {
            value: EDosageType.aerosol,
            translate: 'DOSAGE.AEROSOL.NAME',
            translateShort: 'DOSAGE.AEROSOL.SHORT'
        },
        {
            value: EDosageType.effervescent_tablet,
            translate: 'DOSAGE.EFFERVESCENT_TABLET.NAME',
            translateShort: 'DOSAGE.EFFERVESCENT_TABLET.SHORT'
        },
        {
            value: EDosageType.orodispersible_tablet,
            translate: 'DOSAGE.ORODISPERSIBLE_TABLET.NAME',
            translateShort: 'DOSAGE.ORODISPERSIBLE_TABLET.SHORT'
        },
        {
            value: EDosageType.bulb,
            translate: 'DOSAGE.BULB.NAME',
            translateShort: 'DOSAGE.BULB.SHORT'
        },
        {
            value: EDosageType.application,
            translate: 'DOSAGE.APPLICATION.NAME',
            translateShort: 'DOSAGE.APPLICATION.SHORT'
        },
        {
            value: EDosageType.puff,
            translate: 'DOSAGE.PUFF.NAME',
            translateShort: 'DOSAGE.PUFF.SHORT'
        },
        {
            value: EDosageType.tablespoon,
            translate: 'DOSAGE.TABLESPOON.NAME',
            translateShort: 'DOSAGE.TABLESPOON.SHORT'
        },
        {
            value: EDosageType.pill,
            translate: 'DOSAGE.PILL.NAME',
            translateShort: 'DOSAGE.PILL.SHORT'
        },
        {
            value: EDosageType.capsule,
            translate: 'DOSAGE.CAPSULE.NAME',
            translateShort: 'DOSAGE.CAPSULE.SHORT'
        },
        {
            value: EDosageType.suppository,
            translate: 'DOSAGE.SUPPOSITORY.NAME',
            translateShort: 'DOSAGE.SUPPOSITORY.SHORT'
        },
        {
            value: EDosageType.ovule,
            translate: 'DOSAGE.OVULE.NAME',
            translateShort: 'DOSAGE.OVULE.SHORT'
        },
        {
            value: EDosageType.patch,
            translate: 'DOSAGE.PATCH.NAME',
            translateShort: 'DOSAGE.PATCH.SHORT'
        },
        {
            value: EDosageType.eye_drop,
            translate: 'DOSAGE.EYE_DROP.NAME',
            translateShort: 'DOSAGE.EYE_DROP.SHORT'
        },
        {
            value: EDosageType.drop,
            translate: 'DOSAGE.DROP.NAME',
            translateShort: 'DOSAGE.DROP.SHORT'
        },
        {
            value: EDosageType.syringe,
            translate: 'DOSAGE.SYRINGE.NAME',
            translateShort: 'DOSAGE.SYRINGE.SHORT'
        },
        {
            value: EDosageType.millimeter,
            translate: 'DOSAGE.MILLILITER.NAME',
            translateShort: 'DOSAGE.MILLILITER.SHORT'
        },
        {
            value: EDosageType.milligram,
            translate: 'DOSAGE.MILLIGRAM.NAME',
            translateShort: 'DOSAGE.MILLIGRAM.SHORT'
        },
        {
            value: EDosageType.microgram,
            translate: 'DOSAGE.MICROGRAM.NAME',
            translateShort: 'DOSAGE.MICROGRAM.SHORT'
        },
        {
            value: EDosageType.gram,
            translate: 'DOSAGE.GRAM.NAME',
            translateShort: 'DOSAGE.GRAM.SHORT'
        },
        {
            value: EDosageType.liter,
            translate: 'DOSAGE.LITER.NAME',
            translateShort: 'DOSAGE.LITER.SHORT'
        },
        {
            value: EDosageType.inhalation,
            translate: 'DOSAGE.INHALATION.NAME',
            translateShort: 'DOSAGE.INHALATION.SHORT'
        },
        {
            value: EDosageType.unit,
            translate: 'DOSAGE.UNIT.NAME',
            translateShort: 'DOSAGE.UNIT.SHORT'
        },
        {
            value: EDosageType.bag,
            translate: 'DOSAGE.BAG.NAME',
            translateShort: 'DOSAGE.BAG.SHORT'
        },
        {
            value: EDosageType.unidose,
            translate: 'DOSAGE.UNIDOSE.NAME',
            translateShort: 'DOSAGE.UNIDOSE.SHORT'
        },
        {
            value: EDosageType.teaspoon,
            translate: 'DOSAGE.TEASPOON.NAME',
            translateShort: 'DOSAGE.TEASPOON.SHORT'
        },
        {
            value: EDosageType.granule,
            translate: 'DOSAGE.GRANULE.NAME',
            translateShort: 'DOSAGE.GRANULE.SHORT'
        },
        {
            value: EDosageType.tube,
            translate: 'DOSAGE.TUBE.NAME',
            translateShort: 'DOSAGE.TUBE.SHORT'
        },
        {
            value: EDosageType.wash,
            translate: 'DOSAGE.WASH.NAME',
            translateShort: 'DOSAGE.WASH.SHORT'
        },
        {
            value: EDosageType.pellet,
            translate: 'DOSAGE.PELLET.NAME',
            translateShort: 'DOSAGE.PELLET.SHORT'
        },
        {
            value: EDosageType.measure_spoon,
            translate: 'DOSAGE.MEASURE_SPOON.NAME',
            translateShort: 'DOSAGE.MEASURE_SPOON.SHORT'
        },
        {
            value: EDosageType.international_unit,
            translate: 'DOSAGE.INTERNATIONAL_UNIT.NAME',
            translateShort: 'DOSAGE.INTERNATIONAL_UNIT.SHORT'
        },
        {
            value: EDosageType.pouch,
            translate: 'DOSAGE.POUCH.NAME',
            translateShort: 'DOSAGE.POUCH.SHORT'
        },
        {
            value: EDosageType.injection,
            translate: 'DOSAGE.INJECTION.NAME',
            translateShort: 'DOSAGE.INJECTION.SHORT'
        },
        {
            value: EDosageType.bottle,
            translate: 'DOSAGE.BOTTLE.NAME',
            translateShort: 'DOSAGE.BOTTLE.SHORT'
        },
        {
            value: EDosageType.pot,
            translate: 'DOSAGE.POT.NAME',
            translateShort: 'DOSAGE.POT.SHORT'
        },
        {
            value: EDosageType.dose,
            translate: 'DOSAGE.DOSE.NAME',
            translateShort: 'DOSAGE.DOSE.SHORT'
        }
    ]
};
