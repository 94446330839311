import * as angular from "angular";
import { ELoanCat, ELoanState, EMpStatus } from "@mapuilabs/mpl-interfaces";
import { Utils } from "../Utils";
export class DashboardService {
    /** @ngInject */
    constructor(LoanService, PreparationService, $q, Access, StatusService, ModalService, HospitalService, ToastService, _) {
        this.LoanService = LoanService;
        this.PreparationService = PreparationService;
        this.$q = $q;
        this.Access = Access;
        this.StatusService = StatusService;
        this.ModalService = ModalService;
        this.HospitalService = HospitalService;
        this.ToastService = ToastService;
        this._ = _;
        /**
         * Get all loans from the current hospital
         * @returns {Promise<Array<Loan>>}
         */
        this.getLoans = () => {
            const defer = this.$q.defer();
            this.LoanService.getDrugLoans(this.Access.hospital._id)
                .then((res) => defer.resolve(res))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Get all borrowings from the current hospital
         * @returns {Promise<Array<Loan>>}
         */
        this.getBorrowings = () => {
            const defer = this.$q.defer();
            this.LoanService.getDrugBorrowings(this.Access.hospital._id)
                .then((res) => defer.resolve(res))
                .catch(err => defer.reject(err));
            return defer.promise;
        };
        /**
         * Get all available from the current hospital
         * @returns {Promise<Array<Loan>>}
         */
        this.getAvailable = () => {
            const defer = this.$q.defer();
            this.LoanService.getAvailableFrom(this.Access.hospital._id)
                .then((res) => defer.resolve(res))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Get all requests from the current hospital
         * @returns {Promise<Array<Loan>>}
         */
        this.getRequests = () => {
            const defer = this.$q.defer();
            this.LoanService.getRequestsFrom(this.Access.hospital._id)
                .then((res) => defer.resolve(res))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Get all preparations from the current hospital
         * @returns {Promise<Array<Loan>>}
         */
        this.getPreparations = () => {
            const defer = this.$q.defer();
            this.LoanService.getPreparationsFrom(this.Access.hospital._id)
                .then((res) => defer.resolve(res))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Open the loan modal to update an existing instance
         * @param event
         * @param loan
         * @returns {Promise<EMpStatus>}
         */
        this.openLoanUpdateModal = (event, loan) => {
            const defer = this.$q.defer();
            this.ModalService.show({
                component: 'mp-modal-loan-created',
                bindings: {
                    loan: loan,
                },
                ev: event,
                escapeToClose: false,
                clickOutsideToClose: true,
            })
                .then((answer) => {
                this._updateLoan(answer.loan, answer.state)
                    .then((status) => {
                    this.ToastService.show(status);
                    defer.resolve(status);
                })
                    .catch((err) => {
                    this.ToastService.show(err);
                    defer.reject(err);
                });
            })
                .catch((err) => {
                if (err !== EMpStatus.CloseByUser) {
                    this.ToastService.show(err);
                }
                defer.reject(err);
            });
            return defer.promise;
        };
        /**
         * Open available modal
         * @param event
         * @param loan
         * @returns {IPromise<null>}
         */
        this.openAvailable = (event, loan) => {
            const defer = this.$q.defer();
            this.ModalService.show({
                component: 'mp-modal-available',
                bindings: {
                    available: loan,
                },
                ev: event,
                escapeToClose: true,
                clickOutsideToClose: false,
            }).then((res) => {
                res.state = ELoanState.Answer;
                this.LoanService.saveAvailable(res)
                    .then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                    defer.resolve();
                })
                    .catch((err) => defer.reject(err));
            })
                .catch(() => defer.reject());
            return defer.promise;
        };
        /**
         * Open request modal
         * @param event
         * @param loan
         * @returns {IPromise<null>}
         */
        this.openRequest = (event, loan) => {
            const defer = this.$q.defer();
            this.ModalService.show({
                component: 'mp-modal-request',
                bindings: {
                    request: loan,
                },
                ev: event,
                escapeToClose: true,
                clickOutsideToClose: false,
            }).then((res) => {
                res.state = ELoanState.Answer;
                this.LoanService.saveRequest(res)
                    .then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                    defer.resolve();
                })
                    .catch((err) => defer.reject(err));
            })
                .catch(() => defer.reject());
            return defer.promise;
        };
        /**
         * Open the loan modal to create a new instance
         * @param event
         * @param loan
         * @returns {Promise<EMpStatus>}
         */
        this.openLoanCreationModal = (event, loan = null) => {
            const defer = this.$q.defer();
            if (!loan)
                loan = {};
            this.ModalService.show({
                component: 'mp-modal-loan-creation',
                ev: event,
                escapeToClose: true,
                clickOutsideToClose: false,
                bindings: {
                    loan: loan,
                },
            })
                .then((answer) => {
                this._readOrCreateEstablishmentDB(answer.establishment, answer.loan, answer.creaMember)
                    .then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                    defer.resolve(EMpStatus.Ok);
                })
                    .catch((err) => {
                    this.ToastService.show(err);
                    defer.reject(err);
                });
            })
                .catch((err) => {
                this.ToastService.show(err);
                defer.reject(err);
            });
            return defer.promise;
        };
        /**
         * Create a new [[Loan]] instance
         * @param hospital
         * @param loan
         * @param creaMember
         * @returns {Promise<Loan>}
         * @private
         */
        this._createLoanDB = (hospital, loan, creaMember) => {
            const hid = Utils.getId(this.Access.hospital);
            const defer = this.$q.defer();
            if (ELoanCat.Request == loan.loanCategoryCreation) {
                loan.lender = null;
                loan.borrower = { _id: hid };
                loan.borrowerActive = true;
                loan.borrowingPerson = { _id: Utils.getId(creaMember) };
                loan.loanTypeCreation = false;
            }
            else if (ELoanCat.Available == loan.loanCategoryCreation) {
                loan.lender = { _id: hid };
                loan.lenderActive = true;
                loan.borrower = null;
                loan.loanTypeCreation = true;
                loan.loanPerson = { _id: Utils.getId(creaMember) };
            }
            else if (loan.loanTypeCreation) { //PRET
                loan.lender = { _id: hid };
                loan.lenderActive = true;
                loan.loanPerson = { _id: Utils.getId(creaMember) };
                loan.borrower = { _id: Utils.getId(hospital) };
                if (hospital.active != true) {
                    loan.borrowerActive = false;
                    loan.confirmationDate = new Date();
                    loan.forcedConfirm = true;
                    if (ELoanCat.Prep == loan.loanCategoryCreation) {
                        loan.forcedValid = true;
                        loan.inPrep = new Date();
                    }
                }
            }
            else {
                loan.lender = { _id: Utils.getId(hospital) }; //EMPRUNT
                loan.borrower = { _id: hid };
                loan.borrowerActive = true;
                loan.borrowingPerson = { _id: Utils.getId(creaMember) };
                if (hospital.active != true) {
                    loan.lenderActive = false;
                    loan.confirmationDate = new Date();
                    loan.forcedConfirm = true;
                    if (ELoanCat.Prep == loan.loanCategoryCreation) {
                        loan.forcedValid = true;
                        loan.inPrep = new Date();
                    }
                }
            }
            loan.creationDate = new Date();
            this.LoanService.add(loan)
                .then((newLoan) => defer.resolve(newLoan))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Check if the [[IHospital]] is registered
         * If not create one
         * @param establishment
         * @param loan
         * @param creaMember
         * @returns {Promise<Loan>}
         * @private
         */
        this._readOrCreateEstablishmentDB = (establishment, loan, creaMember) => {
            const defer = this.$q.defer();
            const h = Utils.getId(establishment) ? establishment : establishment ? establishment.hospital : null;
            if (ELoanCat.Available == loan.loanCategoryCreation || ELoanCat.Request == loan.loanCategoryCreation || h) {
                this._createLoanDB(h, loan, creaMember)
                    .then((newLoan) => defer.resolve(newLoan))
                    .catch((err) => defer.reject(err));
            }
            else {
                this.HospitalService.createFromEstablishment(establishment)
                    .then((hospital) => this._createLoanDB(hospital, loan, creaMember)
                    .then((newLoan) => defer.resolve(newLoan))
                    .catch((err) => defer.reject(err)))
                    .catch((error) => defer.reject(error));
            }
            return defer.promise;
        };
        /**
         * Set a request [[ILoan]] as confirmed
         * @param loan
         * @returns {Promise<EMpStatus>}
         * @private
         */
        this._confirmRequest = (loan) => {
            const defer = this.$q.defer();
            let quantityBak;
            let takenQuantityBak;
            loan.confirmationDate = new Date();
            loan.lender = { _id: this.Access.hospital._id };
            if (loan.takenQuantity == loan.quantity) {
                loan.loanCategoryCreation = 1;
                loan.fromRequest = new Date();
                loan.takenQuantity = null;
                this.LoanService.save(loan)
                    .then(() => defer.resolve(EMpStatus.Ok))
                    .catch((err) => defer.reject(err));
            }
            else if (loan.takenQuantity > 0 && loan.takenQuantity < loan.quantity) {
                quantityBak = loan.quantity;
                takenQuantityBak = loan.takenQuantity;
                loan.quantity = loan.takenQuantity;
                loan.takenQuantity = null;
                loan.fromRequest = new Date();
                loan.loanCategoryCreation = 1;
                this.LoanService.save(loan)
                    .then(() => {
                    loan.loanCategoryCreation = 6;
                    loan._id = null;
                    loan.confirmationDate = null;
                    loan.lender = null;
                    loan.quantity = quantityBak - takenQuantityBak;
                    loan.commentBill = null;
                    loan.billNumber = null;
                    loan.chargePercent = null;
                    loan.charge = null;
                    loan.tva = null;
                    loan.returnExpected = null;
                    loan.unitPrice = null;
                    this.LoanService.add(loan)
                        .then(() => defer.resolve(EMpStatus.Ok))
                        .catch((err) => defer.reject(err));
                })
                    .catch((err) => defer.reject(err));
            }
            else {
                loan.confirmationDate = null;
                loan.lender = null;
            }
            return defer.promise;
        };
        /**
         * Set a [[ILoan]] as confirmed
         * @param loan
         * @returns {any}
         * @private
         */
        this._confirmLoan = (loan) => {
            const defer = this.$q.defer();
            let leftOver;
            let newLoan;
            if (this.StatusService.isRequest(loan)) {
                return this._confirmRequest(loan);
            }
            loan.confirmationDate = new Date();
            if (this.StatusService.isPrep(loan) && !loan.forcedConfirm) {
                if (loan.quantity == loan.quantityRet) {
                    if (loan.preparation) {
                        this.PreparationService.save(loan.preparation)
                            .then(() => {
                            delete (loan.preparation);
                            this.LoanService.save(loan)
                                .then(() => defer.resolve(EMpStatus.Ok))
                                .catch((err) => defer.reject(err));
                        });
                    }
                    else {
                        this.LoanService.save(loan)
                            .then(() => defer.resolve(EMpStatus.Ok))
                            .catch((err) => defer.reject(err));
                    }
                }
                else if (loan.quantityRet > 0 && loan.quantityRet < loan.quantity) {
                    leftOver = loan.quantity - loan.quantityRet;
                    newLoan = angular.copy(loan);
                    newLoan._id = undefined;
                    newLoan.lots = undefined;
                    newLoan.loanPerson = undefined;
                    newLoan.loanComment = undefined;
                    newLoan.confirmationDate = undefined;
                    newLoan.prepDate = undefined;
                    newLoan.quantityRet = 0;
                    newLoan.quantity = leftOver;
                    loan.initialQuantity = loan.quantity;
                    loan.quantity = loan.quantityRet;
                    newLoan.reliquat = { _id: loan._id };
                    this.LoanService.add(newLoan)
                        .then(() => this.LoanService.save(loan)
                        .then(() => defer.resolve(EMpStatus.Ok))
                        .catch((err) => defer.reject(err)))
                        .catch((err) => defer.reject(err));
                }
            }
            else {
                this.LoanService.save(loan)
                    .then(() => defer.resolve(EMpStatus.Ok))
                    .catch((err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Set a [[ILoan]] as validated
         * @param loan
         * @returns {Promise<EMpStatus>}
         * @private
         */
        this._validateLoan = (loan) => {
            const defer = this.$q.defer();
            if (this.StatusService.isPrep(loan)) {
                loan.inPrep = new Date();
            }
            this.LoanService.save(loan)
                .then(() => defer.resolve(EMpStatus.Ok))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Update a [[Loan]]
         * @param loan
         * @returns {Promise<EMpStatus>}
         * @private
         */
        this._saveLoanChange = (loan) => {
            const defer = this.$q.defer();
            this.LoanService.save(loan)
                .then(() => defer.resolve(EMpStatus.Ok))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Set a [[ILoan]] as billed
         * @param loan
         * @returns {Promise<EMpStatus>}
         * @private
         */
        this._billedLoan = (loan) => {
            const defer = this.$q.defer();
            if (loan.billingLender) {
                loan.billingDateLender = new Date();
            }
            else {
                loan.billingDateBorrower = new Date();
            }
            this.LoanService.save(loan)
                .then(() => defer.resolve(EMpStatus.Ok))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Set a [[ILoan]] as returned
         * @param loan
         * @returns {Promise<EMpStatus>}
         * @private
         */
        this._returnLoan = (loan) => {
            const defer = this.$q.defer();
            let newLoan;
            let leftOver;
            if (loan.quantity == loan.quantityRet) {
                this.LoanService.save(loan)
                    .then(() => defer.resolve(EMpStatus.Ok))
                    .catch((err) => defer.reject(err));
            }
            else if (loan.quantityRet > 0 && loan.quantityRet < loan.quantity) {
                leftOver = loan.quantity - loan.quantityRet;
                newLoan = angular.copy(loan);
                newLoan._id = undefined;
                newLoan.lots = undefined;
                newLoan.lotNumber = undefined;
                newLoan.loanPerson = undefined;
                newLoan.loanComment = undefined;
                newLoan.prepDate = undefined;
                newLoan.returnDate = undefined;
                newLoan.returnExpiryDate = undefined;
                newLoan.reliquat = { _id: loan._id };
                newLoan.quantityRet = null;
                newLoan.quantity = leftOver;
                loan.initialQuantity = loan.quantity;
                loan.quantity = loan.quantityRet;
                this.LoanService.add(newLoan)
                    .then(() => this.LoanService.save(loan)
                    .then(() => defer.resolve(EMpStatus.Ok))
                    .catch((err) => defer.reject(err)))
                    .catch((err) => defer.reject(err));
            }
            else {
                this.LoanService.save(loan)
                    .then(() => defer.resolve(EMpStatus.Ok))
                    .catch((err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Archive a [[Loan]]
         * @param loan
         * @returns {Promise<EMpStatus>}
         * @private
         */
        this._archiveLoan = (loan) => {
            const defer = this.$q.defer();
            if (loan.lender._id == this.Access.hospital._id) {
                loan.archiveLender = true;
            }
            else {
                loan.archiveBorrower = true;
            }
            this.LoanService.save(loan)
                .then(() => defer.resolve(EMpStatus.Ok))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Dispatch the [[Loan]] to the update function required
         * @param loan
         * @param state
         * @returns {angular.IPromise<EMpStatus>}
         * @private
         */
        this._updateLoan = (loan, state) => {
            let res;
            if (ELoanCat.Available === loan.loanCategoryCreation || ELoanCat.Request === loan.loanCategoryCreation) {
                const defer = this.$q.defer();
                res = defer.promise;
                this.LoanService.save(loan)
                    .then(() => defer.resolve(EMpStatus.Ok))
                    .catch((err) => defer.reject(err));
            }
            else {
                loan.state = state;
                switch (state) {
                    case ELoanState.Validation:
                        res = this._validateLoan(loan);
                        break;
                    case ELoanState.Confirmation:
                        res = this._confirmLoan(loan);
                        break;
                    case ELoanState.ValidReception:
                        res = this._saveLoanChange(loan);
                        break;
                    case ELoanState.Bill:
                        res = this._billedLoan(loan);
                        break;
                    case ELoanState.Return:
                        res = this._returnLoan(loan);
                        break;
                    case ELoanState.ReturnValidation:
                        res = this._saveLoanChange(loan);
                        break;
                    case ELoanState.Deletion:
                        break;
                    case ELoanState.Archive:
                        res = this._archiveLoan(loan);
                        break;
                    default:
                        res = this._saveLoanChange(loan);
                        break;
                }
            }
            return res;
        };
    }
}
