import * as angular from 'angular';
import PerfectScrollbar from 'perfect-scrollbar';
export class MpScroll {
    /** @ngInject */
    constructor($timeout, mpScrollConfig, msUtils, fuseConfig) {
        this.$timeout = $timeout;
        this.mpScrollConfig = mpScrollConfig;
        this.msUtils = msUtils;
        this.fuseConfig = fuseConfig;
        this.compile = (tElement) => {
            // Do not replace scrollbars if
            // 'disableCustomScrollbars' config enabled
            if (this.fuseConfig.getConfig('disableCustomScrollbars')) {
                return;
            }
            // Do not replace scrollbars on mobile devices
            // if 'disableCustomScrollbarsOnMobile' config enabled
            if (this.fuseConfig.getConfig('disableCustomScrollbarsOnMobile') && this.msUtils.isMobile()) {
                return;
            }
            // Add class
            tElement.addClass('ms-scroll');
            return (scope, iElement, iAttrs) => {
                let options = {};
                var perfect;
                // If options supplied, evaluate the given
                // value. This is because we don't want to
                // have an isolated scope but still be able
                // to use scope variables.
                // We don't want an isolated scope because
                // we should be able to use this everywhere
                // especially with other directives
                if (iAttrs.msScroll) {
                    options = scope.$eval(iAttrs.msScroll);
                }
                // Extend the given config with the ones from provider
                options = angular.extend({}, this.mpScrollConfig.getConfig(), options);
                // Initialize the scrollbar
                this.$timeout(function () {
                    perfect = new PerfectScrollbar(iElement[0], options);
                }, 0);
                // Update the scrollbar on element mouseenter
                iElement.on('mouseenter', updateScrollbar);
                // Watch scrollHeight and update
                // the scrollbar if it changes
                scope.$watch(function () {
                    return iElement.prop('scrollHeight');
                }, function (current, old) {
                    if (angular.isUndefined(current) || angular.equals(current, old)) {
                        return;
                    }
                    if (iAttrs.scrollDown == 'true') {
                        iElement[0].scrollTop = current;
                    }
                    updateScrollbar();
                });
                // Watch scrollWidth and update
                // the scrollbar if it changes
                scope.$watch(function () {
                    return iElement.prop('scrollWidth');
                }, function (current, old) {
                    if (angular.isUndefined(current) || angular.equals(current, old)) {
                        return;
                    }
                    updateScrollbar();
                });
                /**
                 * Update the scrollbar
                 */
                function updateScrollbar() {
                    if (perfect) {
                        perfect.update(iElement[0]);
                    }
                }
                scope.$on('scroll-to', function (event, args) {
                    if (args.selector) {
                        const scrollTo = $(iElement[0]).find(args.selector);
                        if (scrollTo.length > 0) {
                            const $item = $(scrollTo[0]);
                            const $container = $(iElement[0]);
                            const containerTop = $container.offset().top;
                            let itemTop = $item.offset().top;
                            const halfItemHeight = $item.height() / 2;
                            const halfContainerHeight = $container.height() / 2;
                            if ($item.parent() !== $container) {
                                const parentTop = $item.parent().offset().top;
                                itemTop = containerTop - parentTop + itemTop;
                            }
                            iElement[0].scrollTop = itemTop - containerTop - halfContainerHeight + halfItemHeight;
                            updateScrollbar();
                        }
                    }
                });
                // Cleanup on destroy
                scope.$on('$destroy', function () {
                    iElement.off('mouseenter');
                    if (perfect) {
                        perfect.destroy(iElement[0]);
                    }
                });
            };
        };
        this.restrict = 'AE';
    }
    static factory() {
        const directive = ($timeout, mpScrollConfig, msUtils, fuseConfig) => new MpScroll($timeout, mpScrollConfig, msUtils, fuseConfig);
        directive.$inject = ['$timeout', 'mpScrollConfig', 'msUtils', 'fuseConfig'];
        return directive;
    }
}
export class MpScrollConfigProvider {
    /** @ngInject */
    constructor() {
        this.config = (configuration) => {
            this.defaultConfiguration = angular.extend({}, this.defaultConfiguration, configuration);
        };
        this.$get = () => {
            return {
                getConfig: getConfig,
            };
            /**
             * Return the config
             */
            function getConfig() {
                return this.defaultConfiguration;
            }
        };
        this.defaultConfiguration = {
            wheelSpeed: 1,
            wheelPropagation: false,
            swipePropagation: true,
            minScrollbarLength: null,
            maxScrollbarLength: null,
            useBothWheelAxes: false,
            useKeyboard: true,
            suppressScrollX: false,
            suppressScrollY: false,
            scrollXMarginOffset: 0,
            scrollYMarginOffset: 0,
            stopPropagationOnClick: true,
        };
    }
}
// /** @ngInject */
// function mpScrollConfigProvider() {
//   // Default configuration
//   let defaultConfiguration = {
//     wheelSpeed: 1,
//     wheelPropagation: false,
//     swipePropagation: true,
//     minScrollbarLength: null,
//     maxScrollbarLength: null,
//     useBothWheelAxes: false,
//     useKeyboard: true,
//     suppressScrollX: false,
//     suppressScrollY: false,
//     scrollXMarginOffset: 0,
//     scrollYMarginOffset: 0,
//     stopPropagationOnClick: true
//   };
//
//   // Methods
//   this.config = config;
//
//   //////////
//
//   /**
//    * Extend default configuration with the given one
//    *
//    * @param configuration
//    */
//   function config(configuration) {
//   }
//
//   /**
//    * Service
//    */
//   this.$get = function () {
//     return {
//       getConfig: getConfig
//     };
//
//     //////////
//
//     /**
//      * Return the config
//      */
//     function getConfig() {
//       return defaultConfiguration;
//     }
//   };
// }
