import * as angular from 'angular';
export class MpTabsController {
    /** @ngInject */
    constructor($translate) {
        this.$translate = $translate;
        /**
         * Return whether the given index represent the current tab
         * @param tabNumber The tab's index to check
         * @return {boolean}
         */
        this.isTabCurrent = (tabNumber) => {
            if (!this.isTabNumberValid(tabNumber)) {
                return false;
            }
            return this._currentTabNumber === tabNumber;
        };
        /**
         * Return whether the tab at the given index is disabled
         * @param tabNumber The tab's index to check
         * @return {boolean}
         */
        this.isTabDisabled = (tabNumber) => {
            let disabled = false;
            if (!this.isTabNumberValid(tabNumber)) {
                return false;
            }
            for (let i = 1; i < tabNumber; i++) {
                if (!this.isTabValid(i)) {
                    disabled = true;
                    break;
                }
            }
            return disabled;
        };
        /**
         * Return whether the tab at the given index is hidden
         * @param tabNumber The tab's index to check
         * @return {boolean}
         */
        this.isTabHidden = (tabNumber) => {
            if (!this.isTabNumberValid(tabNumber)) {
                return false;
            }
            return !!this.tabs[tabNumber - 1].scope.hideStep;
        };
        /**
         * Predicate to remove the hidden tabs
         * @param tab The tab to check
         * @return {boolean}
         */
        this.filterHiddenTab = (tab) => {
            return !this.isTabHidden(tab.tabNumber);
        };
        /**
         * Return whether the tab is valid
         * @param tabNumber Unused parameter
         * @return {boolean}
         */
        this.isTabValid = (tabNumber) => {
            return true;
        };
        /**
         * Set the current tab at the given index
         * @param tabNumber The tab's index wanted
         */
        this.setCurrentTab = (tabNumber) => {
            if (!this.isTabNumberValid(tabNumber)) {
                return;
            }
            this._currentTabNumber = tabNumber;
            for (let i = 0; i < this.tabs.length; i++) {
                this.tabs[i].element.hide();
            }
            this.tabs[this._currentTabNumber - 1].element.show();
        };
        /**
         * Return whether the tab index is valid
         * @param tabNumber The tab's index to check
         * @return {boolean}
         */
        this.isTabNumberValid = (tabNumber) => {
            return !(angular.isUndefined(tabNumber) || tabNumber < 1 || tabNumber > this.tabs.length);
        };
        /**
         * Go to the given tab
         * @param tabNumber The tab's index to go
         */
        this.gotoTab = (tabNumber) => {
            if (this.isTabHidden(tabNumber)) {
                return;
            }
            this.setCurrentTab(tabNumber);
        };
        /**
         * Register a new tab
         * @param element The DOM node
         * @param scope The current scope
         * @param form Unsused parameter
         * @return {any}
         */
        this.registerTab = (element, scope, form = null) => {
            const tab = {
                element: element,
                scope: scope,
                tabNumber: scope.index || (this.tabs.length + 1),
                tabTitle: '',
            };
            this.$translate.refresh().then(() => {
                this.$translate(scope.tabTitle).then((title) => {
                    tab.tabTitle = title;
                });
            });
            this.tabs.push(tab);
            this.tabs.sort(function (a, b) {
                return a.stepNumber - b.stepNumber;
            });
            return tab;
        };
        /**
         * Set up the initial tab
         */
        this.setupTabs = () => {
            this.setCurrentTab(this._currentTabNumber);
        };
        this.tabs = [];
        this._currentTabNumber = 1;
    }
}
