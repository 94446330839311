var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MailsService } from '../../../mails.service';
import { EActivityMailStatus } from './activity-mails-list-item/activity-mails-list-item.component';
import { IdentityService } from '../../../../../../services/identity/identity.service';
let ActivityMailsListComponent = class ActivityMailsListComponent {
    constructor(_mailService, _identityService) {
        this._mailService = _mailService;
        this._identityService = _identityService;
    }
    ngOnInit() {
        // Subscribe to update mails on changes
        this._mailService.onMailsChanged.subscribe((mails) => {
            this.mails = mails.sort((a, b) => {
                // Sort mail by descending date
                if (b.date < a.date)
                    return -1;
                if (b.date > a.date)
                    return 1;
                return 0;
            });
        });
        // Subscribe to update mails on changes
        this._mailService.onSelectedMailChanged.subscribe((selected) => {
            this.selectedMail = selected;
        });
    }
    selectMail(mail) {
        this._mailService.updateSelectedMail(mail);
    }
    getStatus(mail) {
        return this._identityService.hospital._id == mail.hospital
            ? EActivityMailStatus.sent
            : EActivityMailStatus.received;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], ActivityMailsListComponent.prototype, "mails", void 0);
ActivityMailsListComponent = __decorate([
    Component({
        selector: 'activity-mails-list',
        template: require('./activity-mails-list.component.html').default,
        styles: [require('./activity-mails-list.component.scss')],
        animations: fuseAnimations,
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MailsService !== "undefined" && MailsService) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object])
], ActivityMailsListComponent);
export { ActivityMailsListComponent };
