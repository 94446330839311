// -----------------------------------------------------------------------------------------------------
// @ Spreadsheet Error
// -----------------------------------------------------------------------------------------------------
export class SpreadsheetParserErrorsMessage {
}
export var FileErrorSeverity;
(function (FileErrorSeverity) {
    FileErrorSeverity["MAJOR"] = "MAJOR";
    FileErrorSeverity["MODERATE"] = "MODERATE";
    FileErrorSeverity["MINOR"] = "MINOR";
})(FileErrorSeverity || (FileErrorSeverity = {}));
export class SpreadsheetParserError {
    constructor(message, col, row, value) {
        this.message = message;
        this.row = row;
        this.col = col;
        this.value = value;
    }
}
export class SpreadsheetParserErrorContainer {
    constructor() {
        this.major = { severity: FileErrorSeverity.MAJOR, errors: [] };
        this.moderate = { severity: FileErrorSeverity.MODERATE, errors: [] };
        this.minor = { severity: FileErrorSeverity.MINOR, errors: [] };
    }
    addError(severity, error) {
        switch (severity) {
            case FileErrorSeverity.MAJOR:
                this.major.errors.push(error);
                break;
            case FileErrorSeverity.MODERATE:
                this.moderate.errors.push(error);
                break;
            case FileErrorSeverity.MINOR:
                this.minor.errors.push(error);
                break;
        }
    }
    hasErrors(severity) {
        let containersToCheck;
        const containers = [this.major, this.moderate, this.minor];
        if (severity) {
            const severityArray = Array.isArray(severity) ? severity : [severity];
            containersToCheck = containers.filter((container) => severityArray.some((item) => container.severity === item));
        }
        else {
            containersToCheck = containers;
        }
        return containersToCheck.some((container) => container.errors.length > 0);
    }
}
