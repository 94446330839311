import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class AcceptCGUController {
    /** @ngInject */
    constructor(Auth, Access, MemberService, ToastService, $cookies, $state) {
        this.Auth = Auth;
        this.Access = Access;
        this.MemberService = MemberService;
        this.ToastService = ToastService;
        this.$cookies = $cookies;
        this.$state = $state;
        /**
         * Submit new password and set a new password expireDate
         */
        this.submit = () => {
            if (!this.memberForm.acceptedCGU) {
                this.ToastService.show(EMpStatus.CGUNotAccepted);
                return;
            }
            this.MemberService.save({
                _id: this.Auth.user._id,
                acceptedCGUDate: new Date(),
            }).then((res) => {
                this.Auth.user.acceptedCGU = res.acceptedCGU;
                this.Auth.user.acceptedCGUDate = res.acceptedCGUDate;
                this.$cookies.putObject('user', this.Auth.user);
                this.Access.isInterfaced() ? this.$state.go('hospiville.patient-file') : this.$state.go('mapui.home');
            });
        };
        this.memberForm = {};
    }
}
