import { Injector } from '@angular/core';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HS_DASHBOARD_WIDGET_LABO_LIST_CONFIG, HsDashboardWidgetLaboListComponent } from '@app/hospistock/shared/components/dashboard/widget/labo-list/hs-dashboard-widget-labo-list.component';
import { HsDashboardWidgetNewsComponent } from '@app/hospistock/shared/components/dashboard/widget/news/hs-dashboard-widget-news.component';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { Layout, Widget } from '@app/shared/components/widgets/dashboard/widgets';
import { EHsHealthProductTypes, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import { HsDashboardWidgetGroupListComponent } from '@app/hospistock/shared/components/dashboard/widget/group-list/hs-dashboard-widget-group-list.component';
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Dashboard Service Config
// -----------------------------------------------------------------------------------------------------
const endPoint = 'api/hospistock/purchase-group/drug/dashboard';
const viewType = EHsUserTypes.GA;
const productType = EHsHealthProductTypes.DRUG;
export const hsPurchaseGroupDrugDashboardServiceConfig = {
    endPoint,
    viewType,
    productType
};
export const hsPurchaseGroupDrugDashboardLayout = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    const widgetLaboListConfig = {
        columns: [
            {
                label: translationService.instant('DASHBOARD.WIDGET.LABO_LIST.TABLE.NAME'),
                attribute: 'name'
            },
            {
                label: translationService.instant('DASHBOARD.WIDGET.LABO_LIST.TABLE.CREATION_DATE'),
                attribute: 'creationDate',
                classes: ['w-90']
            },
            {
                label: translationService.instant(CHsPublicationType.stockout.translate + '_SHORT'),
                attribute: 'stockoutTotal',
                classes: ['w-70']
            },
            {
                label: translationService.instant(CHsPublicationType.pressure.translate),
                attribute: 'pressureTotal',
                classes: ['w-70']
            },
            {
                label: translationService.instant(CHsPublicationType.quota.translate + '_SHORT'),
                attribute: 'quotaTotal',
                classes: ['w-70']
            }
        ]
    };
    return new Layout([
        new Widget(HsDashboardWidgetNewsComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
        new Widget(HsDashboardWidgetLaboListComponent, [{ 0: 1 }, { 1: 1 }, { 1: 1 }], Injector.create({
            providers: [
                {
                    provide: HS_DASHBOARD_WIDGET_LABO_LIST_CONFIG,
                    useValue: widgetLaboListConfig
                }
            ]
        })),
        new Widget(HsDashboardWidgetGroupListComponent, [{ 0: 1 }, { 0: 1 }, { 2: 0 }])
    ], null);
};
