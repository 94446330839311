export const locale = {
    lang: 'fr',
    data: {
        DRAG_N_DROP: {
            TITLES: {
                FILES_LIST: 'Liste des fichiers à importer'
            },
            DROPZONE: {
                INSTRUCTIONS: 'Glisser et déposer les fichiers ici',
                OR: 'ou',
                BROWSE_BTN: 'Parcourir'
            },
            ERRORS: {
                MULTIPLE_NOT_ALLOWED: "Vous ne pouvez pas charger plus d'un fichier"
            }
        }
    }
};
