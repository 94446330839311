var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslationService } from '../translation/translation.service';
import { CMpStatus } from '../../shared/constTypes/errors/mp-status.const';
let SnackbarService = class SnackbarService {
    constructor(_snackBar, _translationService) {
        this._snackBar = _snackBar;
        this._translationService = _translationService;
        // TODO: Set translation for this const object
        this._cMpStatus = CMpStatus;
        this._httpErrors = {
            400: 'COMMONS.ERRORS.BAD_REQUEST',
            403: 'COMMONS.ERRORS.FORBIDDEN',
            404: 'COMMONS.ERRORS.NOT_FOUND',
            409: 'COMMONS.ERRORS.CONFLICT',
            500: 'COMMONS.ERRORS.INTERNAL_SERVER_ERROR'
        };
        this._defaultConfig = {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
        };
        // Old
        /**
         * Opens a manually configured snackbar.
         * @param message
         * @param action
         * @param config
         */
        this.open = (message, action, config) => {
            if (!message) {
                return;
            }
            const newConfig = this._applyStyle(config);
            this._snackBar.open(message, action, newConfig);
        };
        /**
         * Opens an error-styled snackbar with a given message.
         * @param message
         * @param action
         * @param config
         */
        this.openCustomError = (message, action, config) => {
            if (!message) {
                return;
            }
            const snackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));
            this._snackBar.open(message, action, snackBarConfig);
        };
        /**
         * Opens an error-styled snackbar with a given message.
         * @param action
         * @param config
         */
        this.openDefaultError = (action, config) => {
            const message = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
            const snackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));
            this._snackBar.open(message, action, snackBarConfig);
        };
        /**
         * Opens an error-styled snackbar with a message according to the HTTP error.
         * @param error
         * @param action
         * @param config
         */
        this.openError = (error, action, config) => {
            if (!error) {
                return;
            }
            let message = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
            if (error.status && this._httpErrors[error.status]) {
                message = this._translationService.instant(this._httpErrors[error.status]);
            }
            const snackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));
            this._snackBar.open(message, action, snackBarConfig);
        };
        /**
         * Opens an error-styled snackbar with a message according to the HTTP
         * status code.
         * @param status
         * @param action
         * @param config
         */
        this.openErrorFromStatus = (status, action, config) => {
            if (!status) {
                return;
            }
            let message = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
            if (this._httpErrors[status]) {
                message = this._translationService.instant(this._httpErrors[status]);
            }
            const snackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));
            this._snackBar.open(message, action, snackBarConfig);
        };
        /**
         * Opens an error-styled snackbar with a message according to the HTTP
         * status code.
         * @param status
         * @param action
         * @param config
         */
        this.openErrorMpStatus = (status, action, config) => {
            if (!status) {
                return;
            }
            const message = this._translationService.instant('COMMONS.ERRORS.DEFAULT');
            //TODO
            /*const mpStatus = this._cMpStatus.find((mpStatus) => mpStatus.value === status);
            if (mpStatus) message = this._translationService.instant(mpStatus.translate);*/
            const snackBarConfig = this._applyErrorDefaultStyle(this._applyStyle(config));
            this._snackBar.open(message, action, snackBarConfig);
        };
        /**
         * Applies the given config in the snackbar.
         * @param config
         * @private
         */
        this._applyStyle = (config) => {
            let newConfig = Object.assign({}, this._defaultConfig);
            if (config) {
                newConfig = config;
            }
            return newConfig;
        };
        /**
         * Applies the default style for errors snackbars.
         * @param config
         * @private
         */
        this._applyErrorDefaultStyle = (config) => {
            if (!config) {
                return null;
            }
            if (!config.panelClass) {
                config.panelClass = 'warn';
            }
            return config;
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Snack bars from message
    // -----------------------------------------------------------------------------------------------------
    success(message, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'green-100' });
        this._snackBar.open(message, action, config);
    }
    default(message, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'grey-300' });
        this._snackBar.open(message, action, config);
    }
    warning(message, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'amber-100' });
        this._snackBar.open(message, action, config);
    }
    error(message, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'red-100' });
        this._snackBar.open(message, action, config);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Snack bars from HttpError
    // -----------------------------------------------------------------------------------------------------
    successHttpStatus(status, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'green-100' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }
    defaultHttpStatus(status, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'grey-300' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }
    warningHttpStatus(status, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'amber-100' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }
    errorHttpStatus(status, action) {
        const config = Object.assign({}, this._defaultConfig, { panelClass: 'red-100' });
        const message = this._translationService.instant(status ? this._httpErrors[status] : 'COMMONS.ERRORS.DEFAULT');
        this._snackBar.open(message, action, config);
    }
};
SnackbarService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MatSnackBar !== "undefined" && MatSnackBar) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], SnackbarService);
export { SnackbarService };
export class SnackBarConfig extends MatSnackBarConfig {
}
