import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
export const CEstablishmentType = [
    {
        type: EEstablishmentType.Hospital,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
        adminState: 'mapui.adm.hospitals.detail'
    },
    {
        type: EEstablishmentType.Pharmacy,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.PHARMACY',
        adminState: 'mapui.adm.pharmacies.detail'
    },
    {
        type: EEstablishmentType.EHPAD,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.EHPAD'
    },
    {
        type: EEstablishmentType.Laboratory,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.LABORATORY',
        adminState: 'mapui.adm.labos.detail'
    },
    {
        type: EEstablishmentType.Company,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.COMPANY'
    },
    {
        type: EEstablishmentType.Other,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.OTHER'
    },
    {
        type: EEstablishmentType.ARS,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.ARS',
        adminState: 'mapui.adm.ars.detail'
    },
    {
        type: EEstablishmentType.NationalEntity,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.NATIONAL'
    },
    {
        type: EEstablishmentType.PurchaseGroup,
        name: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.PURCHASE_GROUP',
        adminState: 'mapui.adm.purchase-groups.detail'
    }
];
export function getEstablishmentAdminState(establishmentType) {
    const item = CEstablishmentType.find((item) => item.type === establishmentType);
    if (!item.adminState) {
        return getEstablishmentAdminState(EEstablishmentType.Hospital);
    }
    return item.adminState;
}
