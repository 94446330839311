/** @ngInject */
export class MpSidenavStaffTypeController {
    /** @ngInject */
    constructor(Api, AuthorSpecialityService, _) {
        this.Api = Api;
        this.AuthorSpecialityService = AuthorSpecialityService;
        this._ = _;
        /**
         * Only AuthorSpe that should be displayed in the sidenav
         */
        this.displayedSpe = ['G15_21', 'G15_38', 'G16_21', 'G15_10'];
        /**
         * Called when a new type is selected
         * @param type
         * @param status
         * @param typeName
         */
        this.filterChange = (spe, status, typeName) => {
            // this.ctrl.selectedType = (type) ? type : 0;
            this.onChange({ spe: spe, status: status, name: typeName });
        };
        /**
         * Create a new [[IMember]] opening the `mp-modal-staff` component
         * @param event
         */
        this.edit = (event) => {
            this.onEdit({ $event: event });
        };
        this.ctrl = { selectedType: 0, selectedStatus: 0 };
        this.Api.constType.get((res) => {
            this.ctrl.status = res.pharmacistsStatus;
        });
        this.AuthorSpecialityService.getAll().then((spe) => {
            this.authorSpecialities = [];
            for (let elem of spe) {
                if (this._.find(this.displayedSpe, (inst) => {
                    return (inst == elem.code);
                })) {
                    this.authorSpecialities.push(elem);
                }
            }
        });
    }
}
