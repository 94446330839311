export class HvModalDrugBalanceAddSourceComponent {
    constructor() {
        this.controller = 'HvModalDrugBalanceAddSourceController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-drug-balance-add-source.html').default;
        this.bindings = {
            source: '<',
            patientSources: '<',
            dbsSources: '<',
        };
    }
}
