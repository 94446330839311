import { EPubSubTopic } from '@mapuilabs/mpl-interfaces';
export class MpModalVaultWarningController {
    /** @ngInject */
    constructor(PubSubService) {
        this.$onDestroy = () => {
            for (let topic of this._topics) {
                topic();
            }
        };
        this._topics = [];
        this._topics.push(PubSubService.on(EPubSubTopic.IDLE_WARN, (val) => {
            this.countdown = val;
        }));
    }
}
