import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { EHospistockPermission, EHsHealthProductTypes, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Service Config
// -----------------------------------------------------------------------------------------------------
const viewType = EHsUserTypes.LABO;
const healthProductType = EHsHealthProductTypes.MEDICAL_DEVICE;
const healthProduct = { healthProductType: healthProductType };
const translationBase = 'PUBLICATION.LABO.MEDICAL_DEVICE.STATISTIC';
const permissions = {
    create: null,
    read: EHospistockPermission.LaboMedicalDeviceStatistic_Read,
    update: null
};
export const hsLaboMedicalDeviceStatisticServiceConfig = {
    endPoint: 'api/hospistock/labo/medical-device/statistic',
    permissions,
    viewType,
    healthProduct
};
// Page Header Config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant(translationBase + '.TITLE'),
    searchBarPlaceholder: translationService.instant(translationBase + '.SEARCH'),
    icon: 'analytics'
});
// Table Config
const tableConfig = (translationService) => {
    const countLength = (data) => data.length;
    const formatDate = (date) => (date ? moment(date).format('ll') : null);
    const publicationTypeTranslations = _(CHsPublicationType)
        .mapKeys((value) => value.value)
        .mapValues((value) => translationService.instant(value.translate))
        .value();
    const formatPublicationType = (type) => publicationTypeTranslations[type];
    return {
        columns: [
            {
                label: translationService.instant(translationBase + '.TABLE.HEALTH_PRODUCT_REF_NAME'),
                attribute: 'healthProductRef.name',
                sort: true,
                classes: ['max-w-20-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.TYPE'),
                attribute: 'type',
                transform: formatPublicationType,
                sort: true,
                classes: ['max-w-20-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF'),
                template: 'healthProductsRefTemplate',
                attribute: 'healthProductRef.healthProducts',
                sort: true,
                classes: ['max-w-15-p', 'display-flex']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.UPDATE_DATE'),
                attribute: 'updateDate',
                transform: formatDate,
                sort: true,
                classes: ['max-w-10-p justify-center']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.AVERAGE_DURATION'),
                attribute: 'averageDuration',
                sort: true,
                classes: ['max-w-5-p justify-center']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.PROPOSED_ALTERNATIVES'),
                template: 'proposedAlternativeTemplate',
                attribute: 'metadata.proposedAlternative',
                classes: ['max-w-10-p justify-center'],
                sort: true
            },
            {
                label: translationService.instant(translationBase + '.TABLE.SELECTED_ALTERNATIVE'),
                template: 'selectedAlternativeTemplate',
                attribute: 'metadata.countSelectedAlternative',
                classes: ['max-w-10-p justify-center'],
                sort: true
            },
            {
                label: translationService.instant(translationBase + '.TABLE.SEEN'),
                attribute: 'metadata.countSeen',
                transform: countLength,
                classes: ['max-w-5-p justify-center'],
                sort: false
            },
            {
                label: translationService.instant(translationBase + '.TABLE.READ'),
                attribute: 'metadata.countRead',
                transform: countLength,
                classes: ['max-w-5-p justify-center'],
                sort: false
            }
        ]
    };
};
export const hsLaboMedicalDeviceStatisticConfig = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        table: tableConfig(translationService),
        pageHeader: pageHeaderConfig(translationService),
        healthProductType,
        translationBase
    };
};
