var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { Component, Input, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { forkJoin } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { Hospital, Member } from '@mapuilabs/mpl-interfaces';
import { UrlService } from '@services/url/url.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
import { TranslationService } from '@services/translation/translation.service';
import { RolesTabComponent } from './roles-tab/roles-tab.component';
import { IdentityTabComponent } from './identity-tab/identity-tab.component';
import { ActionsTabComponent } from './actions-tab/actions-tab.component';
let MemberDetailsComponent = class MemberDetailsComponent {
    constructor(_fuseTranslationLoaderService, _memberService, _stateService, _urlService, _translationService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._memberService = _memberService;
        this._stateService = _stateService;
        this._urlService = _urlService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        /**
         * Creates an empty FormGroup. The form controls will be added by the child components.
         */
        this.createDetailFormGroup = () => {
            this.detailForm = new UntypedFormGroup({
                actionsForm: new UntypedFormGroup({}),
                identityForm: new UntypedFormGroup({}),
                rolesForm: new UntypedFormGroup({})
            });
        };
        /**
         * Uses the children methods to update the member.
         */
        this._updateMember = () => {
            return forkJoin([
                this.actionsTabComponent.updateMember(this.member),
                this.identityTabComponent.updateMember(this.member),
                this.rolesTabComponent.updateMember(this.member)
            ]);
        };
        /**
         * Updates an already existing member.
         */
        this.saveMember = () => {
            this._updateMember().subscribe(() => {
                this._memberService.save(this.member).subscribe((newMember) => {
                    this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.EDIT_OK'));
                    this._memberService.getById(newMember._id).subscribe((newestMember) => {
                        this.member = newestMember;
                        this.detailForm.markAsPristine();
                    });
                }, (err) => {
                    if (err.status) {
                        this._snackBarService.openError(err);
                    }
                });
            });
        };
        /**
         * Updates the member object when a child component made a PUT request
         * @param data
         */
        this.refreshMember = () => {
            this._memberService.getById(this.member._id).subscribe((newMember) => {
                this.member = newMember;
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        if (this._stateService.params.id === 'new') {
            this.pageType = 'new';
            this.member = new Member({ hospital: this.hospital });
        }
        else {
            this.pageType = 'edit';
        }
        this.createDetailFormGroup();
        let title = this.pageType === 'new'
            ? this._translationService.instant('admMemberDetails.NEW_TITLE')
            : `${this._translationService.instant('admMemberDetails.EDIT_TITLE')} ${this.member.fullName}`;
        if (this.member.deleted === true) {
            title += ` (${this._translationService.instant('admMemberDetails.ACTIONS.INFORMATION.DELETED')})`;
        }
        this.pageHeaderConfig = {
            title: title,
            icon: 'user'
        };
    }
    ngAfterViewInit() {
        this.tabGroup.selectedIndex = this._urlService.getParam('tab') || 0;
    }
    onTabChange(event) {
        this._urlService.addParams({ tab: event.index }, true);
    }
    /**
     * Adds an new member to the database.
     */
    addMember() {
        this._updateMember().subscribe(() => {
            this._memberService.add(this.member).subscribe((newMember) => {
                this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.CREATE_OK'));
                this._memberService.getById(newMember._id).subscribe((newestMember) => {
                    this.member = newestMember;
                    this.pageType = 'edit';
                    this.detailForm.markAsPristine();
                });
            }, (err) => {
                if (err.status) {
                    this._snackBarService.openError(err);
                }
            });
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Member !== "undefined" && Member) === "function" ? _g : Object)
], MemberDetailsComponent.prototype, "member", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof Hospital !== "undefined" && Hospital) === "function" ? _h : Object)
], MemberDetailsComponent.prototype, "hospital", void 0);
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_j = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _j : Object)
], MemberDetailsComponent.prototype, "tabGroup", void 0);
__decorate([
    ViewChild(ActionsTabComponent, { static: false }),
    __metadata("design:type", typeof (_k = typeof ActionsTabComponent !== "undefined" && ActionsTabComponent) === "function" ? _k : Object)
], MemberDetailsComponent.prototype, "actionsTabComponent", void 0);
__decorate([
    ViewChild(RolesTabComponent, { static: false }),
    __metadata("design:type", typeof (_l = typeof RolesTabComponent !== "undefined" && RolesTabComponent) === "function" ? _l : Object)
], MemberDetailsComponent.prototype, "rolesTabComponent", void 0);
__decorate([
    ViewChild(IdentityTabComponent, { static: false }),
    __metadata("design:type", typeof (_m = typeof IdentityTabComponent !== "undefined" && IdentityTabComponent) === "function" ? _m : Object)
], MemberDetailsComponent.prototype, "identityTabComponent", void 0);
MemberDetailsComponent = __decorate([
    Component({
        selector: 'mpx-member-details',
        template: require('./member-details.component.html').default,
        styles: [require('./member-details.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof UrlService !== "undefined" && UrlService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object])
], MemberDetailsComponent);
export { MemberDetailsComponent };
