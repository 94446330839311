import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as angular from 'angular';
export class MpModalAvailableController {
    /** @ngInject */
    constructor(ModalService, MemberService, Access) {
        var _a;
        this.ModalService = ModalService;
        this.MemberService = MemberService;
        this.Access = Access;
        /**
         * Output function for the `mp-select-staff-member` component
         * @param model the [[Id]] of the selected [[IMember]]
         */
        this.selectStaffMember = ({ model }) => {
            this.available.creaMember = { _id: model };
            this.available.borrowingPerson = { _id: model };
            this.available.borrower = { _id: this.Access.hospital._id };
        };
        /**
         * Submit the form
         * Perform some check before hands using the controller knowledge
         */
        this.submit = () => {
            this.ModalService.close(this.available);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        if ((_a = this.available) === null || _a === void 0 ? void 0 : _a.expiryDate) {
            this.available.expiryDate = new Date(this.available.expiryDate.toString());
        }
    }
    $onInit() {
        this.MemberService.getByHosp(this.Access.hospital._id)
            .then((res) => this.staff = res);
        // TODO check utility of following member getter (already populated ?)
        if (Utils.hasId(this.available.loanPerson)) {
            this.MemberService.get(this.available.loanPerson._id)
                .then((res) => this.available.loanPerson = res);
        }
    }
    $onChanges(changes) {
        if (changes.available) {
            this.available = angular.copy(this.available);
        }
    }
}
