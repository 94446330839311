var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { EquivalencesRoutingModule } from './equivalences-routing.module';
import { EquivalencesListComponent } from './equivalences-list/equivalences-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EditEquivalenceComponent } from './edit-equivalence/edit-equivalence.component';
import { EquivalenceVoucherComponent } from './equivalence-voucher/equivalence-voucher.component';
import { EquivalenceDetailComponent } from './equivalence-detail/equivalence-detail.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
let EquivalencesModule = class EquivalencesModule {
};
EquivalencesModule = __decorate([
    NgModule({
        declarations: [
            EquivalencesListComponent,
            EditEquivalenceComponent,
            EquivalenceVoucherComponent,
            EquivalenceDetailComponent
        ],
        imports: [
            CommonModule,
            FuseSharedModule,
            SharedModule,
            MatPaginatorModule,
            MatTooltipModule,
            MatIconModule,
            MatInputModule,
            MatSelectModule,
            MatDialogModule,
            MatButtonModule,
            MatListModule,
            MatFormFieldModule
        ],
        exports: [EquivalencesRoutingModule]
    })
], EquivalencesModule);
export { EquivalencesModule };
