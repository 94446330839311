var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { DrugInstanceService as AjsDIService } from '../../../../main/hospiville/services/drugInstance/drugInstance.service';
import { from } from 'rxjs';
let PosologyService = class PosologyService {
    constructor(_ajsDIService) {
        this._ajsDIService = _ajsDIService;
    }
    // generate a complete ITiming from a type
    getPresetPosology(type) {
        const timing = this._ajsDIService.getPresetPosology(type);
        timing.presetCode = type;
        return timing;
    }
    openSpecificPosologyModal(timings) {
        return from(this._ajsDIService.openSpecificPosologyModal(timings));
    }
    generatePosologySentence(timing) {
        return this._ajsDIService.generatePosologySentence(timing);
    }
    generateArrayPosology(timingArray) {
        if (!timingArray || !timingArray.length) {
            return '';
        }
        let sentence = '';
        let idx = 0;
        for (const timing of timingArray) {
            if (idx) {
                sentence += ', ';
            }
            sentence += this.generatePosologySentence(timing);
            idx++;
        }
        return sentence;
    }
};
PosologyService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AjsDIService !== "undefined" && AjsDIService) === "function" ? _a : Object])
], PosologyService);
export { PosologyService };
