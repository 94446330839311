import './underscore/underscore.module';
import './admin/admin.module';
import './auth/auth.module';
import './author-speciality/author-speciality.module';
import './dashboard/dashboard.module';
import './directory-rights/directory-rights.module';
import './establishment/establishment.module';
import './file/file.module';
import './file-uploader/file-uploader.module';
import './geo/geo.module';
import './groups/groups.module';
import './health-professional/health-professional.module';
import './hospital/hospital.module';
import './jszip/jszip.module';
import './key-events/key-events.module';
import './labels/mp-label/mp-label.module';
import './loan/loan.module';
import './mails/mails.module';
import './medic/medic.module';
import './medical-device/medical-device.module';
import './member/member.module';
import './merge/merge.module';
import './modal/modal.module';
import './notification/notification.module';
import './preparation/preparation.module';
import './pub-sub/pub-sub.module';
import './role/role.module';
import './socket/socket.module';
import './staff/staff.module';
import './static-resource/static-resource.module';
import './status/status.module';
import './toast/toast.module';
import './vault/vault.module';
import './XLSX/XLSX.module';
import { ExternalActorService } from './external-actors/external-actor.service';
import { ExternalConfigurationService } from './external-actors/external-configuration.service';
import { HistoryService } from './history/history.service';
import * as angular from 'angular';
angular
    .module('mapui.services', [
    'mapui.services.auth',
    'mapui.services.socket',
    'mapui.services.hospital',
    'mapui.services.loan',
    'mapui.services.dashboard',
    'mapui.services.member',
    'mapui.services.medic',
    'mapui.services.medical-device',
    'mapui.services.staff',
    'mapui.services.status',
    'mapui.services.static-resource',
    'mapui.services.modal',
    'mapui.services.admin',
    'mapui.services.toast',
    'mapui.services.preparation',
    'mapui.services.underscore',
    'mapui.services.jszip',
    'mapui.services.fileUploader',
    'mapui.services.directoryRights',
    'mapui.services.groups',
    'mapui.services.keyEvents',
    'mapui.services.labels',
    'mapui.services.file',
    'mapui.services.establishment',
    'mapui.services.healthProfessional',
    'mapui.services.notification',
    'mapui.services.pub-sub',
    'mapui.services.vault',
    'mapui.services.mails',
    'mapui.services.role',
    'mapui.services.geo',
    'mapui.services.merge',
    'mapui.services.author-speciality'
])
    .service('ExternalConfigurationService', ExternalConfigurationService)
    .service('ExternalActorService', ExternalActorService)
    .service('HistoryService', HistoryService);
