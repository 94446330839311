var _a, _b;
// import 'core-js/es/reflect';
import 'zone.js';
import 'reflect-metadata';
import * as angular from 'angular';
import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouter } from '@uirouter/core';
import { AppModule } from './app/app.module';
import 'angular-i18n/angular-locale_fr';
import { EEnv } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
const subdomain = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname) === null || _b === void 0 ? void 0 : _b.split('.')[0];
let environment;
if (subdomain === 'app' || subdomain === 'v1') {
    Utils.env = EEnv.Prod;
    environment = 'production';
}
else if (subdomain === 'preprod') {
    Utils.env = EEnv.Preprod;
    environment = 'preproduction';
}
else if (subdomain === 'demo') {
    Utils.env = EEnv.Demo;
    environment = 'demo';
}
else {
    Utils.env = EEnv.Dev;
    environment = 'development';
}
console.log('Running environment: ', Utils.env);
let dsn = 'https://422f3e26f7d64e7da691588721c1e84f@sentry.mapui.fr/2';
if (Utils.env === EEnv.Dev) {
    dsn = null;
}
Sentry.init({
    dsn,
    integrations: [
        new CaptureConsole({
            levels: ['error']
        }),
        new BrowserTracing()
    ],
    environment: environment,
    enabled: Utils.env !== EEnv.Dev,
    tracesSampleRate: 1.0
});
fetch('/api/permissions') // Get permissions
    .then((result) => result.json())
    .then((data) => {
    // Define a 'permConst' module which will be used by Auth service as first data.
    angular.module('permConst', []).constant('permConst', data);
    if (Utils.env === EEnv.Prod) {
        enableProdMode();
    }
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then((platformRef) => {
        // get() the UIRouter instance from DI to initialize the router
        const urlService = platformRef.injector.get(UIRouter).urlService;
        platformRef.injector.get(NgZone).run(() => {
            urlService.listen();
            urlService.sync();
        });
    })
        .catch((e) => console.error(e));
})
    .catch((e) => console.error(e));
