var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
const DATE_INPUT_FORMAT = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
let DatepickerLimitsComponent = class DatepickerLimitsComponent {
    constructor() {
        this.onChange = new EventEmitter();
        this.reverseColors = false; // Reverse colors between background/accent if inputs are on a header for exemple
        this.isLimitsLock = true; // Is start have end max date & end have start min date
    }
    ngOnInit() {
        this.startDateForm = new UntypedFormControl(this.startDate ? moment(this.startDate) : null);
        this.endDateForm = new UntypedFormControl(this.endDate ? moment(this.endDate) : null);
    }
    ngOnChanges(changes) {
        var _a, _b, _c, _d, _e, _f;
        if (changes) {
            if ((_a = changes.startDate) === null || _a === void 0 ? void 0 : _a.currentValue) {
                (_b = this.startDateForm) === null || _b === void 0 ? void 0 : _b.setValue(moment(this.startDate));
            }
            else if (changes.startDate) {
                (_c = this.startDateForm) === null || _c === void 0 ? void 0 : _c.setValue('');
            }
            if ((_d = changes.endDate) === null || _d === void 0 ? void 0 : _d.currentValue) {
                (_e = this.endDateForm) === null || _e === void 0 ? void 0 : _e.setValue(moment(this.endDate));
            }
            else if (changes.endDate) {
                (_f = this.endDateForm) === null || _f === void 0 ? void 0 : _f.setValue(null);
            }
        }
    }
    onDateChange() {
        var _a, _b;
        this.onChange.emit({
            start: ((_a = this.startDateForm) === null || _a === void 0 ? void 0 : _a.value) ? this.startDateForm.value.toDate() : null,
            end: ((_b = this.endDateForm) === null || _b === void 0 ? void 0 : _b.value) ? this.endDateForm.value.toDate() : null
        });
    }
    getMaxDate() {
        if (!this.isLimitsLock || !this.endDateForm.value) {
            return this.maxDate;
        }
        if (!this.maxDate || moment(this.maxDate).isBefore(this.endDateForm.value)) {
            return this.endDateForm.value;
        }
        return this.maxDate;
    }
    getMinDate() {
        if (!this.isLimitsLock || !this.startDateForm.value) {
            return this.minDate;
        }
        if (!this.minDate || moment(this.minDate).isBefore(this.startDateForm.value)) {
            return this.startDateForm.value;
        }
        return this.minDate;
    }
};
__decorate([
    Output(),
    __metadata("design:type", Object)
], DatepickerLimitsComponent.prototype, "onChange", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DatepickerLimitsComponent.prototype, "startLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object)
], DatepickerLimitsComponent.prototype, "startDate", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], DatepickerLimitsComponent.prototype, "endLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], DatepickerLimitsComponent.prototype, "endDate", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Date !== "undefined" && Date) === "function" ? _c : Object)
], DatepickerLimitsComponent.prototype, "minDate", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof Date !== "undefined" && Date) === "function" ? _d : Object)
], DatepickerLimitsComponent.prototype, "maxDate", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatepickerLimitsComponent.prototype, "reverseColors", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], DatepickerLimitsComponent.prototype, "isLimitsLock", void 0);
DatepickerLimitsComponent = __decorate([
    Component({
        selector: 'mpx-datepicker-limits',
        template: require('./datepicker-limits.component.html').default,
        providers: [
            {
                provide: DateAdapter,
                useClass: MomentDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            },
            { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMAT }
        ]
    })
    // Inputs layouts can be set on parent component
], DatepickerLimitsComponent);
export { DatepickerLimitsComponent };
