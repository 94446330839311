var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatRippleModule } from '@angular/material/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { SharedModule } from '../../shared/shared.module';
import { SecureEmailsRouting } from './secure-emails.routing';
import { SecureEmailsListComponent } from './list-emails/list-emails.component';
import { SecureEmailAddressDetailsComponent } from './details-email/details-email.component';
let SecureEmailsModule = class SecureEmailsModule {
};
SecureEmailsModule = __decorate([
    NgModule({
        declarations: [SecureEmailsListComponent, SecureEmailAddressDetailsComponent],
        imports: [
            CommonModule,
            BrowserAnimationsModule,
            UIRouterModule,
            SharedModule,
            FuseSharedModule,
            FuseDirectivesModule,
            FuseSharedModule,
            MatAutocompleteModule,
            MatButtonModule,
            MatDialogModule,
            MatIconModule,
            MatInputModule,
            MatFormFieldModule,
            MatSelectModule,
            MatSortModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatTableModule
        ],
        exports: [SecureEmailsRouting]
    })
], SecureEmailsModule);
export { SecureEmailsModule };
