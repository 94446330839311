var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { MedicalDeviceService } from '@app/services/medical-device/medical-device.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { regExpFirstZerosAndSpecialCharacters } from '@app/shared/constTypes/reg-exp.const';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HsMedicalDevice } from '@mapuilabs/mpl-interfaces';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { BehaviorSubject, filter, lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
let HsMedicalDeviceSearchComponent = class HsMedicalDeviceSearchComponent {
    constructor(_medicalDeviceService, _fb, _fuseTranslationLoaderService, _snackbarService, _translationService) {
        this._medicalDeviceService = _medicalDeviceService;
        this._fb = _fb;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackbarService = _snackbarService;
        this._translationService = _translationService;
        this._multiple = false;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Mpx-dynamic-data-container config
        this.dynamicDataContainerConfig = {
            loader: { size: 'sm' }
        };
        this.medicalDevicesManualColumns = [
            {
                label: this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.NAME.TITLE'),
                template: 'nameTemplate',
                attribute: 'name',
                sort: true,
                classes: ['']
            },
            {
                label: this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.REFERENCE.TITLE'),
                attribute: 'ref',
                sort: true,
                classes: ['max-w-20-p pl-10']
            },
            {
                label: this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.BRAND.TITLE'),
                attribute: 'brand',
                sort: true,
                classes: ['max-w-15-p']
            },
            {
                label: this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.UDI_CODE.TITLE'),
                attribute: 'udi',
                sort: true,
                classes: ['max-w-20-p']
            },
            {
                label: this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.ACTION.TITLE'),
                template: 'deleteTemplate',
                sort: false,
                classes: ['w-56']
            }
        ];
        this.medicalDevicesColumns = [
            ...this.medicalDevicesManualColumns,
            {
                label: this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.SHEET.TITLE'),
                template: 'link',
                sort: false,
                classes: ['w-56']
            }
        ];
        this.medicalDevices = [];
        // Init Subjects
        this._unsubscribeAll = new Subject();
        this.isLoading$ = new BehaviorSubject(false);
    }
    get multiple() {
        return this._multiple;
    }
    set multiple(val) {
        this._multiple = coerceBooleanProperty(val);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get searchMedicalDevice() {
        return this.formSearchMedicalDevice.value;
    }
    get healthProductRef() {
        return this.formHealthProductRef.value;
    }
    get healthProducts() {
        return this.formHealthProductRef.get('healthProducts').value;
    }
    set healthProducts(value) {
        this.formHealthProductRef.patchValue({ healthProducts: value });
    }
    get industrialNames() {
        var _a;
        return (_a = this.industrials) === null || _a === void 0 ? void 0 : _a.map(({ name }) => name);
    }
    get isSearchIndustrialRequired() {
        return this.formSearchMedicalDevice.get('operator').hasValidator(Validators.required);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // --------------------------------------------------
        // # Init forms
        var _a, _b, _c, _d, _e;
        // Init medical device selection form
        this.selectedMedicalDevicesControl = this._fb.control([]);
        // Init searchMedicalDevice form
        const isSingleAndManualMode = !this.multiple && this.formManual.value;
        this.formSearchMedicalDevice = this._fb.group({
            operator: [(_a = this.healthProductRef) === null || _a === void 0 ? void 0 : _a.operator],
            name: [isSingleAndManualMode ? (_b = this.healthProducts[0]) === null || _b === void 0 ? void 0 : _b.name : ''],
            ref: [isSingleAndManualMode ? (_c = this.healthProducts[0]) === null || _c === void 0 ? void 0 : _c.ref : ''],
            cladimed: [isSingleAndManualMode ? (_d = this.healthProducts[0]) === null || _d === void 0 ? void 0 : _d.cladimed : { name: '', ref: '' }],
            udi: [isSingleAndManualMode ? (_e = this.healthProducts[0]) === null || _e === void 0 ? void 0 : _e.udi : '']
        });
        this._updateFormSearchMedicalDeviceValidators();
        // --------------------------------------------------
        // # Init subscriptions
        // Disable forms when searching medical devices
        this.isLoading$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (value) => {
                if (value) {
                    this.formManual.disable({ emitEvent: false });
                    this.formSearchMedicalDevice.disable();
                    this.selectedMedicalDevicesControl.disable();
                }
                else {
                    this.formManual.enable({ emitEvent: false });
                    this.formSearchMedicalDevice.enable();
                    this.selectedMedicalDevicesControl.enable();
                }
            }
        });
        // Reset form when manual mode is triggered
        this.formManual.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: () => {
                this._updateFormSearchMedicalDeviceValidators();
                this._resetHealthProducts();
                this._resetMedicalDevices();
                this._resetSearchMedicalDevices();
                this.formSearchMedicalDevice.markAsUntouched();
                this.formSearchMedicalDevice.markAsPristine();
            }
        });
        // Always have an array of medical devices into the selectedMedicalDevicesControl (not an object)
        this.selectedMedicalDevicesControl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (value) => (this.selectedMedicalDevices = [value].flat())
        });
        // Mark form as dirty after healthProducts init
        // Bind healthProducts to healthProductsDataSource
        // Handle healthProductRef name in single mode
        this.formHealthProductRef
            .get('healthProducts')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: () => {
                var _a;
                // MatTableDatasource is used to handle sorting
                this.healthProductsDataSource.data = this.healthProducts;
                this.formHealthProductRef.get('healthProducts').markAsDirty();
                // Handle healthProductRef name if multiple is disabled
                if (!this.multiple) {
                    this.formHealthProductRef.patchValue({ name: (_a = this.healthProducts[0]) === null || _a === void 0 ? void 0 : _a.name });
                }
            }
        });
        // Update first healthProduct element in single mode
        this.formSearchMedicalDevice.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), filter(() => !this.multiple && this.formManual.value))
            .subscribe({
            next: ({ cladimed, name, ref, udi }) => (this.healthProducts = this.formSearchMedicalDevice.valid ? [{ cladimed, name, ref, udi }] : [])
        });
        // Bind searchMedicalDevice's operator field to healthProductRef's operator
        this.formSearchMedicalDevice
            .get('operator')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: (operator) => this.formHealthProductRef.patchValue({ operator })
        });
        // --------------------------------------------------
        // # Init variables
        const medicalDevices = this.healthProducts.map(this._convertHsMedicalDeviceToIExhausmedMedicalDevice);
        this.healthProductsDataSource = new MatTableDataSource(this.healthProducts);
        this.medicalDevices = medicalDevices;
        this.selectedMedicalDevicesControl.setValue(this.multiple ? medicalDevices : medicalDevices[0]);
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    _convertIExhausmedMedicalDeviceToHsMedicalDevice(medicalDevice) {
        const { reference, name, brand, udi_code, exhausCode, cladimed, cladimedName, industrial } = medicalDevice;
        return {
            ref: reference,
            name,
            brand,
            udi: udi_code,
            cladimed: { name: cladimedName, ref: cladimed },
            exhausCode,
            operator: industrial
        };
    }
    _convertHsMedicalDeviceToIExhausmedMedicalDevice(medicalDevice) {
        const { name, ref, cladimed, exhausCode, udi, operator } = medicalDevice;
        return {
            name,
            reference: ref,
            cladimed: cladimed === null || cladimed === void 0 ? void 0 : cladimed.ref,
            cladimedName: cladimed === null || cladimed === void 0 ? void 0 : cladimed.name,
            exhausCode,
            udi_code: udi,
            industrial: operator
        };
    }
    _isEqualCleanRefs(a, b) {
        const cleanA = a.replace(regExpFirstZerosAndSpecialCharacters, '');
        const cleanB = b.replace(regExpFirstZerosAndSpecialCharacters, '');
        return cleanA === cleanB;
    }
    _updateFormSearchMedicalDeviceValidators() {
        const controlsName = ['name', 'ref', 'operator'];
        if (this.formManual.value) {
            controlsName.forEach((name) => this.formSearchMedicalDevice.get(name).setValidators(Validators.required));
        }
        else {
            controlsName.forEach((name) => this.formSearchMedicalDevice.get(name).clearValidators());
        }
    }
    _searchMedicalDevice() {
        return __awaiter(this, void 0, void 0, function* () {
            // Toggle flag isLoading
            this.isLoading$.next(true);
            const searchValues = {
                name: this.searchMedicalDevice.name,
                reference: this.searchMedicalDevice.ref,
                industrial: this.searchMedicalDevice.operator,
                cladimed: this.searchMedicalDevice.cladimed.ref
            };
            try {
                this.medicalDevices = yield lastValueFrom(this._medicalDeviceService.findMedicalDevice(searchValues));
                // Update selected medical devices
                this.selectedMedicalDevicesControl.setValue(this.medicalDevices.filter(({ reference }) => this.healthProducts.some(({ ref }) => this._isEqualCleanRefs(reference, ref))));
            }
            catch (err) {
                console.error(err);
                this._resetMedicalDevices();
            }
            // Toggle flag isLoading
            this.isLoading$.next(false);
        });
    }
    _resetMedicalDevices() {
        this.medicalDevices = [];
        this.selectedMedicalDevicesControl.setValue([]);
    }
    _resetHealthProducts() {
        this.healthProducts = [];
    }
    _resetOperator() {
        this.formSearchMedicalDevice.patchValue({ operator: this.industrials ? this.industrials[0].name : '' });
    }
    _resetCladimed() {
        this.formSearchMedicalDevice.patchValue({ cladimed: { name: '', ref: '' } });
    }
    _resetSearchMedicalDevices() {
        this.formSearchMedicalDevice.patchValue({ ref: '', name: '', udi: '' });
        this._resetOperator();
        this._resetCladimed();
    }
    _updateHealthProducts() {
        this.healthProducts = Array.from(new Set([
            ...this.healthProducts.filter((item) => !this.medicalDevices.some(({ reference }) => this._isEqualCleanRefs(reference, item.ref))),
            ...this.selectedMedicalDevices.map(this._convertIExhausmedMedicalDeviceToHsMedicalDevice)
        ]));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    onCladimedSelected(cladimed) {
        const { name, ref } = cladimed;
        this.formSearchMedicalDevice.patchValue({ cladimed: { name, ref } });
    }
    onIndustrialSelected(industrial) {
        this.formSearchMedicalDevice.patchValue({ operator: industrial });
    }
    onResetIndustrial() {
        this._resetOperator();
        if (!this.formManual.value) {
            this._resetHealthProducts();
            this._resetMedicalDevices();
        }
    }
    onResetCladimed() {
        this._resetCladimed();
    }
    onMedicalDeviceSelected() {
        // Update healthProducts
        this._updateHealthProducts();
        // Update Industrial fields if empty
        if (!this.searchMedicalDevice.operator && this.selectedMedicalDevices.length) {
            const { industrial } = this.selectedMedicalDevices[0];
            // Update Industrial form field
            this.formSearchMedicalDevice.patchValue({ operator: industrial });
        }
    }
    onRemoveMedicalDevice(index) {
        // Remove item in selectedMedicalDevices
        const selectedMedicalDevicesIndex = this.selectedMedicalDevices.findIndex(({ reference }) => this._isEqualCleanRefs(this.healthProducts[index].ref, reference));
        if (selectedMedicalDevicesIndex !== -1) {
            // Remove item from selectedMedicalDevices trigger onChanges to update the selectedMedicalDevicesControl
            this.selectedMedicalDevices.splice(selectedMedicalDevicesIndex, 1);
            this.selectedMedicalDevicesControl.setValue([...this.selectedMedicalDevices]);
        }
        else {
            // Remove item in healthProducts
            this.healthProducts.splice(index, 1);
        }
        // Update healthProducts
        this._updateHealthProducts();
    }
    onSearchMedicalDevice() {
        this._searchMedicalDevice();
    }
    onRedirectToProductLink(exhausCode) {
        this._medicalDeviceService.findMedicalDeviceLink(exhausCode).subscribe({
            next: (link) => {
                const message = this._translationService.instant('PUBLICATION.MEDICAL_DEVICE.SHEET.LINK_NOT_FOUND');
                link ? window.open(link) : this._snackbarService.open(message);
            },
            error: (err) => console.error(`redirectToProductLink error: ${err}`)
        });
    }
    compareExhausmedMedicalDeviceFn(a, b) {
        var _a, _b;
        return ((_a = a === null || a === void 0 ? void 0 : a.exhausCode) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = b === null || b === void 0 ? void 0 : b.exhausCode) === null || _b === void 0 ? void 0 : _b.toString());
    }
    onAddMedicalDeviceManually() {
        const medicalDevice = new HsMedicalDevice(this.searchMedicalDevice);
        this.healthProducts = [...this.healthProducts, medicalDevice];
    }
    generateMedicalDeviceDetail(medicalDevice) {
        return [
            medicalDevice.reference &&
                this._translationService.instant(['COMMONS.WORDS.REF', 'COMMONS.PUNCTUATION.COLON']) +
                    ' ' +
                    medicalDevice.reference,
            medicalDevice.industrial &&
                this._translationService.instant([
                    'PUBLICATION.MEDICAL_DEVICE.SUPPLIER.TITLE',
                    'COMMONS.PUNCTUATION.COLON'
                ]) +
                    ' ' +
                    medicalDevice.industrial
        ]
            .filter((value) => value)
            .join(', ');
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof FormGroup !== "undefined" && FormGroup) === "function" ? _f : Object)
], HsMedicalDeviceSearchComponent.prototype, "formHealthProductRef", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _g : Object)
], HsMedicalDeviceSearchComponent.prototype, "formManual", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsMedicalDeviceSearchComponent.prototype, "industrials", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof Observable !== "undefined" && Observable) === "function" ? _h : Object)
], HsMedicalDeviceSearchComponent.prototype, "alreadyExists$", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsMedicalDeviceSearchComponent.prototype, "alreadyExistsLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], HsMedicalDeviceSearchComponent.prototype, "multiple", null);
HsMedicalDeviceSearchComponent = __decorate([
    Component({
        selector: 'mpx-hs-form-medical-device',
        template: require('./hs-form-medical-device.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MedicalDeviceService !== "undefined" && MedicalDeviceService) === "function" ? _a : Object, typeof (_b = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object])
], HsMedicalDeviceSearchComponent);
export { HsMedicalDeviceSearchComponent };
