import { EFileType } from '@mapuilabs/mpl-interfaces';
/** @ngInject */
export class FilesService {
    /** @ngInject */
    constructor(Api, $q, Access, ModalService, $http) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        this.ModalService = ModalService;
        this.$http = $http;
        /**
         * Save the given [[IFile]]
         * @param item
         * @param file
         * @returns {IPromise<null|string>}
         */
        this.saveUploadedFile = (item, file) => {
            file.parentDirectory = { _id: item.directory._id };
            file.member = { _id: this.Access.user._id };
            file.type = EFileType.Cloud;
            const defer = this.$q.defer();
            this.Api.workspace.files.add(file, (res) => {
                defer.resolve();
            }, (err) => {
                defer.reject(err);
            });
            return defer.promise;
        };
        /**
         * Simply get the extension of a filename as a string (e.g. ".pdf")
         * @param filename
         * @returns {string}
         */
        this._getFileExtension = (filename) => {
            return filename.split('.').pop();
        };
        /**
         * Simply get the name of a file without the extension
         * @param filename
         * @returns {string}
         * @private
         */
        this._getFileName = (filename) => {
            return filename.split('.')[0];
        };
        this.getRootDirectories = () => {
            const defer = this.$q.defer();
            if (!this.rootFiles || !this.rootFiles.length) {
                this.Api.workspace.root.get(null, (res) => {
                    this.rootFiles = res;
                    this.getAllId();
                    defer.resolve(this.rootFiles);
                }, () => {
                    defer.reject();
                });
            }
            else {
                defer.resolve(this.rootFiles);
            }
            return defer.promise;
        };
        /**
         * Get every files within the given directory
         * @param dir
         * @returns {IPromise<Array<IFile>>}
         */
        this.getChildren = (dir) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.all({ parentDirectoryId: dir._id }, (res) => {
                this.currentFiles = res;
                this.currentFiles.sort((a, b) => {
                    const namea = a.name.toLowerCase();
                    const nameb = b.name.toLowerCase();
                    if (a.isDirectory && b.isDirectory == false)
                        return -1;
                    if (a.isDirectory && b.isDirectory)
                        return namea.localeCompare(nameb);
                    if (a.isDirectory == false && b.isDirectory == false)
                        return namea.localeCompare(nameb);
                    return 0;
                });
                defer.resolve(this.currentFiles);
            }, (error) => {
                defer.reject(error);
            });
            return defer.promise;
        };
        /**
         * Get every directories within the given directory
         * @param dir
         * @returns {IPromise<Array<IFile>>}
         */
        this.getChildrenDirectories = (dir) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.all({ parentDirectoryId: dir._id, isDirectory: true }, (res) => {
                defer.resolve(res);
            }, (error) => {
                defer.reject(error);
            });
            return defer.promise;
        };
        this.getParentDirectory = (file) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.get({ id: file.parentDirectory }, (res) => {
                defer.resolve(res);
            }, (error) => {
                defer.reject(error);
            });
            return defer.promise;
        };
        /**
         * Get all filtered [[IFile]]s
         * @param filter
         * @param limit
         * @param member
         * @returns {IPromise<Array<IFile>>}
         */
        this.getFilesByFilter = (filter, limit, member) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.all({
                filter: filter,
                limit: limit,
                hospital: { _id: member.hospital._id },
                member: { _id: member.id },
                news: true,
                isDirectory: false,
                type: EFileType.Cloud,
            }, {}, (res) => {
                defer.resolve(res);
            }, () => {
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Open the confirmation Modal before deleting a file.
         * Update the displayed files on success
         * @param files
         * @returns {IPromise<any>|Promise<any>}
         */
        this.confirmDeleteFileModal = (files) => {
            const defer = this.$q.defer();
            let title, text;
            if (files.length == 1) {
                title = 'FILES.CONFIRM.DELETE_' + (files[0].isDirectory ? 'DIRECTORY' : 'FILE') + '.TITLE';
                text = 'FILES.CONFIRM.DELETE_' + (files[0].isDirectory ? 'DIRECTORY' : 'FILE') + '.MESSAGE';
            }
            else {
                title = 'FILES.CONFIRM.DELETE_MULTIPLE.TITLE';
                text = 'FILES.CONFIRM.DELETE_MULTIPLE.MESSAGE';
            }
            const data = {
                title: title,
                text: text,
                values: { nbr: files.length },
            };
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: data,
                },
                escapeToClose: false,
            }).then(() => {
                defer.resolve();
            }).catch(() => {
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Delete the given [[IFile]]
         * @param file
         * @returns {IPromise<string>}
         */
        this.deleteFile = (file) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.delete({ id: file._id }, (res) => {
                defer.resolve(res);
            }, (res) => {
                defer.reject(res);
            });
            return defer.promise;
        };
        this.downloadFile = (url) => {
            return this.$http.get(url, { responseType: 'arraybuffer' }).then((response) => {
                return response;
            }, (response) => {
                return response;
            });
        };
        this.getAllFiles = (name) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.all({
                fileName: name,
                hospitals_id: this.ids.hospitals,
                groups_id: this.ids.groups,
                members_id: this.ids.members,
            }, (files) => {
                defer.resolve(files);
            });
            return defer.promise;
        };
        /**
         * Update an existing [[IFile]] in dataBase using the [[Id]]
         * @param file
         * @returns {IPromise<null>}
         */
        this.saveFile = (file) => {
            const defer = this.$q.defer();
            this.Api.workspace.files.save({}, file, () => {
                defer.resolve();
            }, () => {
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Add a new directory [[IFile]]
         * @param dir directory to add
         * @param parentDir used to set the directory data.
         */
        this.addDirectory = (dir, parentDir = this.currentDir) => {
            const defer = this.$q.defer();
            dir.parentDirectory = { _id: parentDir._id };
            if (dir.hospital) {
                dir.hospital = { _id: parentDir.hospital._id };
            }
            if (dir.group) {
                dir.group = { _id: parentDir.group._id };
            }
            if (dir.member) {
                dir.member = { _id: parentDir.member._id };
            }
            dir.isDirectory = true;
            this.Api.workspace.files.add({}, dir, () => {
                defer.resolve();
                // this.ToastService.show(EMpStatus.Ok);
            }, () => {
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Set given [[IFile]] as current directory
         * @param dir
         */
        this.setCurrentDir = (dir) => {
            this.currentDir = dir;
        };
        this.rootFiles = [];
        this.ids = {
            members: [],
            hospitals: [],
            groups: [],
        };
    }
    ;
    /**
     * Gets every root directories of the current user (user, hospital, group, region)
     * The route will create unexisting directories
     * @returns {IPromise<Array<IFile>>}
     */
    getAllId() {
        for (const file of this.rootFiles) {
            if (file.group) {
                this.ids.groups.push(file.group._id);
            }
            if (file.member) {
                this.ids.members.push(file.member._id);
            }
            if (file.hospital) {
                this.ids.hospitals.push(file.hospital._id);
            }
        }
    }
}
