export const locale = {
    lang: 'en',
    data: {
        AVAILABLE: {
            TITLE: 'Available offers',
            TITLE_OWN: `My available offers`,
            SEARCH: 'Search an offer',
            TOOLTIP_NEW: `New offer`,
            OPEN_FILTER: 'Show filters',
            EXPORT_EXCEL: 'Export in excel format',
            LIST: {
                NO_AVAILABLE: 'No available offer',
                BACK_TO_LIST: `Back to list`,
                ITEM: {
                    TITLES: {
                        QUANTITY: `Quantity: `,
                        ESTABLISHMENT: 'Establishment (region)',
                        EXPIRY_DATE: `Expiry date: `,
                        END_DATE: {
                            LABEL: `Ends on: `,
                            TOOLTIP: `Offer expired`
                        },
                        CREATION_DATE: `Published on: `,
                        PRICE: `Unit price ex-tax: `
                    },
                    TAGS: {
                        VALIDATED: `Reprise`
                    }
                }
            },
            INFO: {
                DRUG: 'Product',
                TITLE: 'Contact details',
                HOSPITAL: 'Hospital',
                ADDRESS: 'Address',
                PHONE: 'Phone',
                PHARMACIST: 'Pharamcist',
                MAIL: 'Mail',
                HOLDER: 'Laboratory holder',
                CREATOR: 'Laboratory creator',
                ATC: 'ATC',
                DRUG_EXPIRATION_DATE: 'Expiry date',
                QUANTITY: 'Quantity',
                UCD: 'UCD',
                DISTANCE: 'Distance',
                DCI: 'DCI',
                PRESERVATION: 'Preservation',
                TOTAL: 'Total price ex-tax (€)'
            },
            SORT: {
                TITLE: `Sort`,
                MENU: {
                    CREATION_DATE: 'Publication date',
                    END_DATE: 'End date',
                    EXPIRATION_DATE: 'Expiry date',
                    QUANTITY: 'Quantity',
                    DISTANCE: 'Distance'
                }
            },
            TOOLTIPS: {
                OPEN_FILTER: 'Filters',
                OPEN_SORT: 'Sort',
                REFRESH: 'Refresh'
            },
            PRESET_FILTERS: {
                TITLE: `Filters`,
                MENU: {
                    ADVANCED_FILTERS: 'Advanced filters',
                    EMPTY: 'No filter',
                    INTERESTED: 'Interested',
                    NOT_INTERESTED: 'Not interested',
                    VALIDATED: 'Taken',
                    WITHOUT_INTERESTED: 'Not rated',
                    INTERESTED_AND_WITHOUT_INTERESTED: 'Interested and not rated'
                }
            },
            EDIT: {
                TITLE: {
                    NEW: `New available offer`,
                    EDIT: `Modify my offer`
                },
                MANDATORY_HINT: `Fields marked with an asterisk (*) are mandatory.`,
                TOTAL_PRICE_ERROR: `The total price of your offer must be at least {{price}}.`,
                NARCOTIC_ERROR: `The publication of narcotic drugs is not allowed on the platform.`,
                INPUTS: {
                    PRODUCT: `Product`,
                    LABO: `Operating laboratory`,
                    QUANTITY: `Quantity`,
                    PRICE: {
                        SHORT: `Sell. price (€)`,
                        LONG: `Sell. unit price ex-tax (€)`
                    },
                    MANUFACTURER_PRICE: {
                        SHORT: `Manuf. price (€)`,
                        LONG: `Manuf. unit price ex-tax (€)`
                    },
                    DISCOUNT: `Discount (%)`,
                    BATCH_NB: `Batch number`,
                    PRESERVATION: `Preservation conditions`,
                    EXPIRY_DATE: `Product expiry date`,
                    DESCRIPTION: {
                        SHORT: `Commentary`,
                        LONG: `If necessary, give some details (condition of the packaging, phone number...).`
                    },
                    MEMBER: {
                        LABEL: `Pharmacist`,
                        UNSELECTED_MEMBER: `You must select a pharmacist from the drop-down list.`
                    },
                    END_DATE: `End date of offer`
                },
                AVAILABLE_ADDED: `Available offer has been created.`,
                AVAILABLE_UPDATED: `Available offer has been updated.`,
                AVAILABLE_VALIDATED: `Available offer has been validated.`
            },
            DETAILS: {
                NO_SELECTED_AVAILABLE: 'Select an offer to show details.',
                END_DATE_TOOLTIP: `Reached post exipiry date`,
                VALIDATED: `Taken`,
                POST_INFO: {
                    TITLE: `Details about this post`,
                    QUANTITY: `Quantity`,
                    PRICE: `Unit price ex-tax (€)`,
                    TOTAL_PRICE: `Total price ex-tax (€)`,
                    EXPIRY_DATE: 'Offer expiry date',
                    BATCH_NB: `Batch number`,
                    REFEREE: `Post referee`,
                    CREATION_DATE: 'Offer publication date',
                    END_DATE: `Post end date`
                },
                DRUG_INFO: {
                    TITLE: `Product details`,
                    ATC: 'ATC',
                    UCD: 'UCD',
                    LABO_HOLDER: `Operating laboratory`,
                    LABO_CREATOR: `Exploitation laboratory`,
                    MANUFACTURER_PRICE: `Manuf. unit price ex-tax (€)`,
                    PRESERVATION: 'Preservation'
                },
                OFFERS: {
                    TITLE: `Interested establishments`,
                    NONE: `No establishment is interested at the moment.`,
                    NO_COMMENT: `The establishment did not add comments.`,
                    ACCEPT: `Validate offer`,
                    PREV: `Previous`,
                    NEXT: `Next`,
                    DISTANCE: ` ({{distance}}km)`,
                    VALIDATED_TOOLTIP: `Took availability offer`
                },
                VALIDATE_OFFER: {
                    TITLE: `Validate available offer`,
                    HINT: ``,
                    HOSPITAL_INFO: {
                        TITLE: `Contact details`,
                        NAME: `Establishment`,
                        PHONE: `Pharmacy phone`,
                        EMAIL: `Pharmacy email`,
                        MEMBER: `Applicant`,
                        MEMBER_PHONE: `Phone`,
                        MEMBER_EMAIL: `Email`
                    },
                    COMMENT: `Comment from the establishment`,
                    RECAP: {
                        TITLE: `Summary of availability`,
                        PRODUCT: `Product`,
                        QUANTITY: `Quantity`,
                        PRICE: `Unit price ex-tax (€)`,
                        EXPIRY_DATE: 'Expiry date'
                    }
                },
                DELETE: {
                    TITLE: `Delete available offer`,
                    MESSAGE: `Are you sure you want to delete this offer?`,
                    DELETED: `Available offer has been deleted.`
                },
                ARCHIVE: {
                    TITLE: `Archive available offer`,
                    MESSAGE: `Are you sure you want to archive this offer? You can retrieve it by activating the 'Archived' filter.`
                }
            },
            SIDEBAR: {
                TITLE: 'Filters',
                APPLY_BUTTON: 'Apply',
                RESET: 'Reset',
                TYPES_ERROR: 'Select at least one type.',
                GROUP: 'Group',
                IN_MY_REGION: 'In my region',
                IN: 'In',
                TYPES: 'Types',
                FAVORITE: 'Favorites',
                LOCALIZATION: 'Localization',
                INTEREST: 'Interested',
                NOT_INTEREST: 'Not interested',
                NOT_RATED: `Not rated`,
                TOOK: 'Taken',
                DISTRICT: 'Region',
                DISTANCE: 'Distance (km)',
                STATUS: {
                    TITLE: `Availability status`,
                    OPEN: `Open`,
                    CLOSED: `Validated`,
                    ARCHIVED: `Archived`
                },
                END_DATE: {
                    TITLE: `Expiration of availability`,
                    SOON_EXPIRED: `Soon expired`,
                    EXPIRED: `Expired only`
                }
            }
        }
    }
};
