var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { find } from 'lodash';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HospitalService } from '../../../services/hospital/hospital.service';
let SearchHospitalComponent = class SearchHospitalComponent {
    constructor(_hospitalService, _fuseTranslationLoaderService) {
        this._hospitalService = _hospitalService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        /**
         * Emits when an establishment is selected.
         */
        this.onSelected = new EventEmitter();
        this.hospitals = null;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        var _a;
        this.text = (_a = this.parentFormControl) === null || _a === void 0 ? void 0 : _a.value;
        if (!this.type) {
            this.type = EEstablishmentType.Hospital;
        }
        this.hospitals = this.parentFormControl.valueChanges.pipe(startWith(''), debounceTime(500), switchMap((value) => {
            if (typeof value === 'string') {
                return this.search(value);
            }
            // Return empty array when hospital has just been selected
            return of([]);
        }));
    }
    /**
     * Check is form control is set as required
     */
    get isRequired() {
        var _a;
        if ((_a = this.parentFormControl) === null || _a === void 0 ? void 0 : _a.validator) {
            const validator = this.parentFormControl.validator({});
            return validator && validator.required;
        }
        return false;
    }
    search(value) {
        if (value && value != '') {
            return new Observable((observer) => {
                this._hospitalService.findByName(value, this.type).subscribe((data) => {
                    data = data.filter((elem) => {
                        return (!this.exclude ||
                            !find(this.exclude, (ex) => {
                                return ex._id.toString() == elem._id.toString();
                            }));
                    });
                    observer.next(data);
                });
            });
        }
        else {
            return of(null);
        }
    }
    inputChanged(data) {
        this.onSelected.emit(null);
    }
    optionSelected(selected) {
        this.onSelected.emit(selected);
    }
    displayFn(hospital) {
        return (hospital === null || hospital === void 0 ? void 0 : hospital.name) ? hospital.name : undefined;
    }
    reset() {
        this.parentFormControl.reset();
        this.onSelected.emit(null);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _c : Object)
], SearchHospitalComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof EEstablishmentType !== "undefined" && EEstablishmentType) === "function" ? _d : Object)
], SearchHospitalComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], SearchHospitalComponent.prototype, "exclude", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], SearchHospitalComponent.prototype, "onSelected", void 0);
SearchHospitalComponent = __decorate([
    Component({
        selector: 'mpx-search-hospital',
        template: require('./search-hospital.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchHospitalComponent);
export { SearchHospitalComponent };
