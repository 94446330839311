export const locale = {
    lang: 'fr',
    data: {
        activities: {
            TITLE: `Échanges ville-hôpital`,
            SUBTITLE: `Demandes d'ordonnance`,
            SEARCH_ACTIVITY: `Rechercher un patient ou un établissement`,
            DOC_HV_ACTIVITIES: 'Doc Échanges v-h',
            TABLE: {
                DATE: `Date`,
                TYPE: `Type`,
                ESTABLISHMENT: `Établissement`,
                PATIENT: `Patient`,
                STATUS: `Statut`
            },
            STATUSES: {
                WAITING: `En attente`,
                FILES: `Fichier(s) envoyé(s)`,
                ADDED_TO_CONCILIATION: `Ajouté(s) au bilan`,
                ENDED: `Terminé`,
                CANCELED: `Annulé`,
                MAILS: `Mail(s) reçu(s)`,
                MEDREC: `Conciliation`,
                ENDED_WITH_FILES: 'Fichier(s) supplémentaire(s)'
            },
            TYPES: {
                ASK_PRESCRIPTION: `Demande de prescription`,
                MEDREC: `Partage de fiche de conciliation`,
                ASK_PRESCRIPTION_OCTAVE: `OCTAVE - Suivi des accompagnements médicamenteux`
            },
            MEDICATION_RECONCILIATION: {
                TRANSIT: {
                    ENTRANCE: `Conciliation d'admission`,
                    EXIT: `Conciliation de sortie`,
                    TRANSFER: `Conciliation de transfert`
                }
            },
            ORIGIN: {
                HOSPITAL: 'Hôpital',
                PHARMACY: 'Officine'
            }
        }
    }
};
