export class MpUploadXlsxComponent {
    constructor() {
        this.template = require('./mp-upload-xlsx.html').default;
        this.controller = 'MpUploadXlsxController';
        this.controllerAs = 'vm';
        this.bindings = {
            autoConfirm: '<',
            file: '=',
            matches: '<',
            onChange: '&',
            form: '<',
            prefill: '<',
            multiple: '<',
            autoMatch: '<',
            sheetIdx: '<',
            rawData: '<'
        };
    }
}
