var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { PreparationsRoutingModule } from './preparations-routing.module';
import { PreparationsListComponent } from './preparations-list/preparations-list.component';
import { EditPrepaModalComponent } from './edit-prepa-modal/edit-prepa-modal.component';
import { SharedModule } from '@app/shared/shared.module';
let PreparationsModule = class PreparationsModule {
};
PreparationsModule = __decorate([
    NgModule({
        declarations: [PreparationsListComponent, EditPrepaModalComponent],
        imports: [
            CommonModule,
            FuseSharedModule,
            SharedModule,
            MatTableModule,
            MatButtonModule,
            MatIconModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatSelectModule,
            MatSortModule,
            MatRippleModule,
            MatDialogModule,
            MatFormFieldModule,
            MatInputModule
        ],
        exports: [PreparationsRoutingModule]
    })
], PreparationsModule);
export { PreparationsModule };
