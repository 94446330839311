import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvPatientInterviewSurveyComponent } from './hv-patient-interview-survey.component';
import { HvPatientInterviewSurveyController } from './hv-patient-interview-survey.controller';
import { HvPatientInterviewSurveyInfoComponent } from './hv-patient-interview-survey-info/hv-patient-interview-survey-info.component';
import { HvPatientInterviewSurveyInfoController } from './hv-patient-interview-survey-info/hv-patient-interview-survey-info.controller';
angular
    .module('hospiville.patient.interview-survey', [])
    .config(configPatientInterviewSurvey)
    .component('hvPatientInterviewSurvey', new HvPatientInterviewSurveyComponent())
    .controller('HvPatientInterviewSurveyController', HvPatientInterviewSurveyController)
    .component('hvPatientInterviewSurveyInfo', new HvPatientInterviewSurveyInfoComponent())
    .controller('HvPatientInterviewSurveyInfoController', HvPatientInterviewSurveyInfoController);
/** @ngInject */
function configPatientInterviewSurvey($stateProvider) {
    $stateProvider.state('hospiville.patient-interview-survey', {
        url: '/patient-interview-survey',
        data: {
            access: EHospivillePermission.PatientInterviewSurvey_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-patient-interview-survey></hv-patient-interview-survey>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville patient-interview-survey',
    });
}
