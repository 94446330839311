import * as angular from 'angular';
export class HvInstanceController {
    /** @ngInject */
    constructor(Api, _) {
        this.Api = Api;
        this._ = _;
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[from], { value: value }).translate;
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
    $onChanges(changes) {
        if (changes.source) {
            this.source = angular.copy(changes.source.currentValue);
        }
    }
}
