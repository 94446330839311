export const locale = {
    lang: 'fr',
    data: {
        ERROR_404: {
            TITLE: '404',
            CONTENT: 'Désolé, mais nous ne pouvons pas trouver la page que vous recherchez',
            LINK: "Retour à l'accueil"
        }
    }
};
