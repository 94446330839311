var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { HsPublicationSingleViewDetailComponent } from '@app/hospistock/shared/components/publication/single-view/single-view-detail/hs-publication-single-view-detail.component';
import { HsPublicationSingleViewListComponent } from '@app/hospistock/shared/components/publication/single-view/single-view-list/hs-publication-single-view-list.component';
import { EHsToolbarViewModes } from '@app/hospistock/shared/types/hs-toolbar.types';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { hsMedicalDeviceServiceConfig } from '../admin-hs-medical-device.config';
import { hsPublicationConfigAdmin, hsServiceConfigAdmin } from './admin-hs-medical-device-publication.config';
const states = [
    {
        name: 'mapui.adm.hospistock.medical-device.publication',
        url: '/publication',
        abstract: true
    },
    {
        name: 'mapui.adm.hospistock.medical-device.publication.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: HsPublicationSingleViewListComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.HsStockoutConfig_Read,
            viewMode: EHsToolbarViewModes.SINGLE_LIST,
            servicesConfig: {
                healthProduct: hsMedicalDeviceServiceConfig,
                publicationContainer: hsServiceConfigAdmin
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsPublicationConfigAdmin
            }
        ]
    },
    {
        name: 'mapui.adm.hospistock.medical-device.publication.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: HsPublicationSingleViewDetailComponent
            }
        },
        params: {
            id: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.HsStockoutConfig_Read,
            viewMode: EHsToolbarViewModes.SINGLE_DETAIL,
            servicesConfig: {
                healthProduct: hsMedicalDeviceServiceConfig,
                publicationContainer: hsServiceConfigAdmin
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsPublicationConfigAdmin
            }
        ]
    }
];
let AdminHsMedicalDevicesPublicationRoutingModule = class AdminHsMedicalDevicesPublicationRoutingModule {
};
AdminHsMedicalDevicesPublicationRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], AdminHsMedicalDevicesPublicationRoutingModule);
export { AdminHsMedicalDevicesPublicationRoutingModule };
