export class MpAutofocus {
    /** @ngInject */
    constructor($timeout) {
        this.$timeout = $timeout;
        this.link = (scope, elem) => {
            this.$timeout(function () {
                elem[0].focus();
            });
        };
        this.restrict = 'A';
    }
    static factory() {
        const directive = ($timeout) => new MpAutofocus($timeout);
        directive.$inject = ['$timeout'];
        return directive;
    }
}
