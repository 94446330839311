import { UntypedFormControl, Validators } from '@angular/forms';
import { regExpAlphanumeric, regExpCIP, regExpCIP7, regExpCladimed, regExpDecimalNumber, regExpFalse, regExpFirstZeros, regExpSpecialCharacters, regExpTrue, regExpUCD, regExpUCD7 } from '@app/shared/constTypes/reg-exp.const';
import { Utils } from '@main/services/Utils';
import * as _ from 'lodash';
import * as moment from 'moment';
// -----------------------------------------------------------------------------------------------------
// @ Validators
// -----------------------------------------------------------------------------------------------------
class CustomCellValidatorsFn {
    static tap(fct) {
        return (control) => {
            fct(control);
            return null;
        };
    }
    static array(validator) {
        return (control) => {
            const validateCell = (cellValue, validator) => {
                return new UntypedFormControl(cellValue, validator.function).errors;
            };
            // const tmp =
            //     Array.isArray(control.value) &&
            //     control.value.map((item: JsonSpreadSheetCell) => validateCell(item, validator));
            const isValid = Array.isArray(control.value) &&
                control.value.every((item) => validateCell(item, validator) === null);
            return isValid ? null : { array: true };
        };
    }
    static boolean() {
        return (control) => {
            const isValid = typeof control.value === 'boolean';
            return isValid ? null : { boolean: true };
        };
    }
    static date() {
        return (control) => {
            const isValid = moment(control.value, true).isValid();
            return isValid ? null : { date: true };
        };
    }
    static dateGt(value) {
        return (control) => {
            const isValid = moment(control.value).isAfter(moment(value), 'day');
            return isValid ? null : { dateGt: true };
        };
    }
    static dateGte(value) {
        return (control) => {
            const isValid = moment(control.value).isSameOrAfter(moment(value), 'day');
            return isValid ? null : { dateGte: true };
        };
    }
    static dateLt(value) {
        return (control) => {
            const isValid = moment(control.value).isBefore(moment(value), 'day');
            return isValid ? null : { dateLt: true };
        };
    }
    static dateLte(value) {
        return (control) => {
            const isValid = moment(control.value).isSameOrBefore(moment(value), 'day');
            return isValid ? null : { dateLte: true };
        };
    }
    static enum(enumObj) {
        return (control) => {
            const enumValues = Utils.enumToValues(enumObj).map((value) => value.toString());
            const isValid = enumValues.includes(control.value);
            return isValid ? null : { enum: true };
        };
    }
    static equals(value) {
        return (control) => {
            const isValid = _.isEqual(control.value, value);
            return isValid ? null : { equals: true };
        };
    }
    static notEquals(value) {
        return (control) => {
            const isValid = !_.isEqual(control.value, value);
            return isValid ? null : { notEquals: true };
        };
    }
    static list(list) {
        return (control) => {
            const isValid = list.includes(control.value);
            return isValid ? null : { list: true };
        };
    }
}
export class CellValidators {
}
CellValidators.tap = (fct, condition) => ({
    name: 'tap',
    function: CustomCellValidatorsFn.tap(fct),
    condition
});
CellValidators.array = (separator, validator, condition) => ({
    name: 'array',
    function: CustomCellValidatorsFn.array(validator),
    getMessage: () => [validator.getMessage(), { key: 'ERRORS.MODERATE.CELL_CHECK_ARRAY', params: { separator } }].flat(),
    condition
});
CellValidators.boolean = (condition) => ({
    name: 'boolean',
    function: CustomCellValidatorsFn.boolean(),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_BOOLEAN' }),
    condition
});
CellValidators.cip = (condition) => ({
    name: 'cip',
    function: Validators.pattern(regExpCIP),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_CIP' }),
    condition
});
CellValidators.cladimed = (condition) => ({
    name: 'cladimed',
    function: Validators.pattern(regExpCladimed),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_CLADIMED' }),
    condition
});
CellValidators.date = (formats, condition) => ({
    name: 'date',
    function: CustomCellValidatorsFn.date(),
    getMessage: () => formats
        ? {
            key: 'ERRORS.MODERATE.CELL_CHECK_DATE_FORMAT',
            params: { formats: _.isArray(formats) ? [...formats].join(', ') : formats.toString() }
        }
        : { key: 'ERRORS.MODERATE.CELL_CHECK_DATE' },
    condition
});
CellValidators.dateGt = (value, condition) => ({
    name: 'dateGt',
    function: CustomCellValidatorsFn.dateGt(value),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_DATE_GT', params: { date: moment(value).format('L') } }),
    condition
});
CellValidators.dateGte = (value, condition) => ({
    name: 'dateGte',
    function: CustomCellValidatorsFn.dateGte(value),
    getMessage: () => ({
        key: 'ERRORS.MODERATE.CELL_CHECK_DATE_GTE',
        params: { date: moment(value).format('L') }
    }),
    condition
});
CellValidators.dateLt = (value, condition) => ({
    name: 'dateLt',
    function: CustomCellValidatorsFn.dateLt(value),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_DATE_LT', params: { date: moment(value).format('L') } }),
    condition
});
CellValidators.dateLte = (value, condition) => ({
    name: 'dateLte',
    function: CustomCellValidatorsFn.dateLte(value),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_DATE_LTE', params: { date: moment(value).format('L') } }),
    condition
});
CellValidators.list = (list, condition) => ({
    name: 'list',
    function: CustomCellValidatorsFn.list(list),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_LIST' }),
    condition
});
CellValidators.enum = (enumObj, condition) => ({
    name: 'enum',
    function: CustomCellValidatorsFn.enum(enumObj),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_LIST' }),
    condition
});
CellValidators.equals = (value, condition) => ({
    name: 'equals',
    function: CustomCellValidatorsFn.equals(value),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_EQUALS' }),
    condition
});
CellValidators.notEquals = (value, condition) => ({
    name: 'notEquals',
    function: CustomCellValidatorsFn.notEquals(value),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_NOT_EQUALS' }),
    condition
});
CellValidators.string = (condition) => ({
    name: 'string',
    function: Validators.pattern(regExpAlphanumeric),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_STRING' }),
    condition
});
CellValidators.number = (condition) => ({
    name: 'number',
    function: Validators.pattern(regExpDecimalNumber),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_NUMBER' }),
    condition
});
CellValidators.required = (condition) => ({
    name: 'required',
    function: Validators.required,
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_REQUIRED' }),
    condition
});
CellValidators.ucd = (condition) => ({
    name: 'ucd',
    function: Validators.pattern(regExpUCD),
    getMessage: () => ({ key: 'ERRORS.MODERATE.CELL_CHECK_UCD' }),
    condition
});
// -----------------------------------------------------------------------------------------------------
// @ Processes
// -----------------------------------------------------------------------------------------------------
class CustomCellProcessesFn {
    static split(separator) {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (!(typeof value === 'string')) {
                    return value;
                }
                // Separator type guard
                if (!(typeof separator === 'string')) {
                    return value;
                }
                return value.split(separator);
            };
            return Array.isArray(cellValue) ? cellValue : process(cellValue);
        };
    }
    static boolean() {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (!(typeof value === 'string')) {
                    return value;
                }
                if (regExpTrue.test(value)) {
                    return true;
                }
                else if (regExpFalse.test(value)) {
                    return false;
                }
                else {
                    return value;
                }
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static clear() {
        return this.replace(/\s+/g, '');
    }
    static date(formats) {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (typeof value === 'boolean') {
                    return value;
                }
                const date = moment(value, formats, true);
                return date.isValid() ? date.format() : value;
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static map(map) {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (!(typeof value === 'string')) {
                    return value;
                }
                return map.get(value) || value;
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static number() {
        return (cellValue) => {
            const process = (value) => {
                const number = Utils.transformToNumber(value);
                return number || value;
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static trim() {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (!(typeof value === 'string')) {
                    return value;
                }
                return _.trim(value);
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static ucd13() {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (typeof value !== 'string') {
                    return value;
                }
                return regExpUCD7.test(value) ? Utils.ucd7ToUcd13(value) : value;
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static cip13() {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (!(typeof value === 'string')) {
                    return value;
                }
                return regExpCIP7.test(value) ? Utils.cip7ToCip13(value) : value;
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
    static removeSpecialCharacters() {
        return this.replace(regExpSpecialCharacters, '');
    }
    static removeFirstZeros() {
        return this.replace(regExpFirstZeros, '');
    }
    static replace(regex, replaceValue) {
        return (cellValue) => {
            const process = (value) => {
                // Type guard
                if (typeof value !== 'string') {
                    return value;
                }
                return regex.test(value) ? value.replace(regex, replaceValue) : value;
            };
            return Array.isArray(cellValue) ? cellValue.map((item) => process(item)) : process(cellValue);
        };
    }
}
export class CellProcesses {
}
CellProcesses.split = (separator) => ({
    function: CustomCellProcessesFn.split(separator)
});
CellProcesses.boolean = () => ({
    function: CustomCellProcessesFn.boolean()
});
CellProcesses.clear = () => ({
    function: CustomCellProcessesFn.clear(),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_CLEAR' }
});
CellProcesses.date = (formats) => ({
    function: CustomCellProcessesFn.date(formats)
});
CellProcesses.map = (map) => ({
    function: CustomCellProcessesFn.map(map)
});
CellProcesses.number = () => ({
    function: CustomCellProcessesFn.number()
});
CellProcesses.string = () => ({
    function: _.toString
});
CellProcesses.trim = () => ({
    function: CustomCellProcessesFn.trim(),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_TRIM' }
});
CellProcesses.UCD13 = () => ({
    function: CustomCellProcessesFn.ucd13(),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_UCD13' }
});
CellProcesses.CIP13 = () => ({
    function: CustomCellProcessesFn.cip13(),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_CIP13' }
});
CellProcesses.removeSpecialCharacters = () => ({
    function: CustomCellProcessesFn.removeSpecialCharacters(),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_REMOVE_SPECIAL_CHAR' }
});
CellProcesses.removeFirstZeros = () => ({
    function: CustomCellProcessesFn.removeFirstZeros(),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_REMOVE_SPECIAL_CHAR' }
});
CellProcesses.replace = (regExp, value) => ({
    function: CustomCellProcessesFn.replace(regExp, value),
    message: { key: 'ERRORS.MINOR.CELL_PROCESS_REPLACE' }
});
