var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { UIRouterModule } from '@uirouter/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { ClipboardModule } from 'ngx-clipboard';
import { MembersRoutingModule } from './members-routing.module';
import { SharedModule } from '@shared/shared.module';
import { HospitalsModule } from '../hospitals/hospitals.module';
import { ListMembersComponent } from './list-members/list-members.component';
import { ListMembersSidebarComponent } from './list-members/filters-sidebar/list-members-sidebar.component';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { ActionsTabComponent } from './member-details/actions-tab/actions-tab.component';
import { IdentityTabComponent } from './member-details/identity-tab/identity-tab.component';
import { RolesTabComponent } from './member-details/roles-tab/roles-tab.component';
let MembersModule = class MembersModule {
};
MembersModule = __decorate([
    NgModule({
        declarations: [
            ListMembersComponent,
            ListMembersSidebarComponent,
            MemberDetailsComponent,
            ActionsTabComponent,
            RolesTabComponent,
            IdentityTabComponent
        ],
        imports: [
            BrowserAnimationsModule,
            FuseSharedModule,
            MatIconModule,
            CommonModule,
            FuseDirectivesModule,
            MatTableModule,
            MatSortModule,
            MatRippleModule,
            MatPaginatorModule,
            MatMenuModule,
            MatButtonModule,
            MatSnackBarModule,
            MatDialogModule,
            FuseConfirmDialogModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            UIRouterModule,
            ClipboardModule,
            MatProgressSpinnerModule,
            FuseSidebarModule,
            MatCheckboxModule,
            MatTabsModule,
            MatCardModule,
            MatListModule,
            SharedModule,
            HospitalsModule,
            MatBadgeModule,
            MatTooltipModule,
            MatRadioModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [MembersRoutingModule]
    })
], MembersModule);
export { MembersModule };
