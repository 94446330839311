export const locale = {
    lang: 'en',
    data: {
        NEWS_HOSPISTOCK: {
            TITLE: 'Hospistock News',
            TABLE: {
                CATEGORY: 'Catégorie',
                TITLE: 'Title',
                ACTIONS: 'Actions',
                DUPLICATE: {
                    TITLE: 'Duplicate',
                    NOTIF: 'Widget duplicated'
                },
                EMPTY: 'No widgets'
            },
            DETAILS: {
                RESET: {
                    TITLE: 'Adding an element',
                    MESSAGE: 'Do you really want to continue? The current personalisation element will be removed.'
                },
                INVALID_FORM: 'Invalid form',
                SETTINGS: {
                    LABEL: 'Settings',
                    TITLE: 'Widget title',
                    BACKGROUND_COLOR: 'Background color',
                    POSITIONS: {
                        ONE_COLUMN: `1 column view :`,
                        TWO_COLUMNS: `2 columns view :`,
                        THREE_COLUMNS: `3 columns view :`,
                        X: `Column`,
                        Y: `Order`,
                        DO_NOT_SHOW: `Do not show`
                    },
                    LINES: {
                        LABEL: `Lines`,
                        ADD_BTN_TOOLTIP: `Add a line`,
                        TABLE: {
                            LINE_LABEL: `Line label`,
                            CATEGORY: `Category`,
                            PUBLICATION_DATE: `Publication date`,
                            CONTENT: {
                                PLACEHOLDER: 'Type line content...'
                            },
                            TAGS: {
                                ADD_BTN: `Add a tag`,
                                FORM_LABEL: `Label`,
                                STATE_BTN: `Internal link`,
                                VALIDATE_TOOLTIP: `Add tag`
                            },
                            KNOWLEDGE_BASE: `Knowledge base article`
                        }
                    },
                    BUTTONS: {
                        LABEL: 'Buttons',
                        TITLE: 'Title',
                        REDIRECT: {
                            TITLE: 'Redirection'
                        },
                        URL: 'URL',
                        COLOR: 'Color',
                        LAYOUT: 'Layout'
                    }
                },
                PREVIEW: {
                    LABEL: 'Preview'
                }
            },
            SNACKBAR: {
                WIDGET_SAVED: `Widget saved.`,
                LINE_ADDED: `Line added.`,
                LINE_SAVED: `Line saved.`,
                LINE_REMOVED: `Line removed.`,
                TAG_ADDED: `Tag added.`,
                TAG_REMOVED: `Tag removed.`
            },
            TOOLTIPS: {
                ADDING_LINE: `Adding a new line`
            }
        }
    }
};
