var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { Component, Injector, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { EquivalencesService } from '@app/services/equivalences/equivalences.service';
import { ListPage } from '@app/shared/templates/listPage/listPage.class';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Access } from '@main/services/auth/access.service';
import { Utils } from '@main/services/Utils';
import { SpreadsheetImportResultStatus } from '@mapuilabs/mpl-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { DialogService } from '@app/services/dialog/dialog.service';
import { EditEquivalenceComponent } from '../edit-equivalence/edit-equivalence.component';
import { EquivalenceVoucherComponent } from '../equivalence-voucher/equivalence-voucher.component';
import { SpreadsheetImportModalComponent } from '@app/shared/components/spreadsheet-import-modal/spreadsheet-import-modal.component';
import { ESpreadsheetImportReportListTypes, SpreadsheetImportReportList } from '@app/shared/components/spreadsheet-import-modal/spreadsheet-import-report-list/spreadsheet-import-report-list.types';
import { CellProcesses, CellValidators } from '@app/services/spreadsheet-parser/spreadsheet-parser.const';
import { lastValueFrom } from 'rxjs';
import * as _ from 'lodash';
import { EquivalenceColumns, EquivalenceSpreadsheetImportResultMessages } from './import-types/equivalences.types';
let EquivalencesListComponent = class EquivalencesListComponent extends ListPage {
    constructor(_injector, _equivalencesService, _access, _matDialog, _dialogService, _stateService, _state, _translateService, _fuseTranslationLoaderService) {
        super(_injector, _equivalencesService.getEquivalencesPage);
        this._injector = _injector;
        this._equivalencesService = _equivalencesService;
        this._access = _access;
        this._matDialog = _matDialog;
        this._dialogService = _dialogService;
        this._stateService = _stateService;
        this._state = _state;
        this._translateService = _translateService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('EQUIVALENCES.TITLE'),
            searchBarPlaceholder: this._translate('EQUIVALENCES.SEARCH'),
            icon: 'account_balance'
        };
        this.genericTableConfig = {
            clickableRows: true
        };
        this.columns = [
            {
                label: this._translate('EQUIVALENCES.MEDIC'),
                attribute: 'medicName',
                sort: true
            },
            {
                label: this._translate('EQUIVALENCES.MEDIC_DCI'),
                attribute: 'medicDci',
                sort: true
            },
            {
                label: this._translate('EQUIVALENCES.EQUIVALENCE'),
                template: 'subName',
                sort: true,
                attribute: 'substitutions.name'
            },
            {
                label: this._translate('EQUIVALENCES.EQUIVALENCE_DCI'),
                template: 'subDci',
                sort: true,
                attribute: 'substitutions.dci'
            },
            {
                label: this._translate('EQUIVALENCES.ACTION'),
                template: 'delivery',
                attribute: 'delivery',
                sort: true,
                classes: ['max-w-120']
            },
            {
                label: this._translate('EQUIVALENCES.REASON'),
                template: 'reason',
                attribute: 'reason',
                sort: true,
                classes: ['max-w-120']
            },
            {
                label: this._translate('EQUIVALENCES.ACTIONS'),
                template: 'actions',
                classes: ['max-w-120']
            }
        ];
        this._buildImportConfig();
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    /**
     * Open equivalence modal to add new [[IEquivalence]]
     */
    openAddEquivalenceModal() {
        const dialogRef = this._matDialog.open(EditEquivalenceComponent, {
            width: '60%'
        });
        dialogRef.afterClosed().subscribe({
            next: (addedEquivalence) => {
                if (addedEquivalence) {
                    this._equivalencesService.add(addedEquivalence).subscribe({
                        next: () => {
                            this._snackbarService.open(this._translateService.instant('EQUIVALENCES.ADD_MODAL.SUCCESS.NEW'));
                            this.search();
                        },
                        error: (err) => this._snackbarService.openError(err)
                    });
                }
            },
            error: (err) => {
                this._snackbarService.openError(err);
            }
        });
    }
    /**
     * Open equivalence modal to import [[IEquivalence]]s file
     */
    openImportEquivalencesModal() {
        // Guard: no import config
        if (!this._importConfig) {
            throw new Error('Import config not provided, cannot import equivalences.');
        }
        // Define uploead function to use in import modal
        const uploadFn = (file, importReportConfig) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            // Build health product directory
            const spreadsheetImportContainers = this._equivalencesService.buildEquivalences(this._spreadsheetClassBuilder, file.content, (_a = this._importConfig.spreadsheetParserConfig.options) === null || _a === void 0 ? void 0 : _a.headerToLowerCase);
            // Upload health product directory
            const response = yield lastValueFrom(this._equivalencesService.importFromSpreadsheet(spreadsheetImportContainers));
            // Build reportList from import result
            const importResultStatusToReportListTypesMap = new Map([
                [SpreadsheetImportResultStatus.CREATED.toString(), ESpreadsheetImportReportListTypes.SUCCESS],
                [SpreadsheetImportResultStatus.UPDATED.toString(), ESpreadsheetImportReportListTypes.INFO],
                [SpreadsheetImportResultStatus.IGNORED.toString(), ESpreadsheetImportReportListTypes.WARNING],
                [SpreadsheetImportResultStatus.REJECTED.toString(), ESpreadsheetImportReportListTypes.ERROR]
            ]);
            const map = new Map();
            _(response)
                // => { CREATED: SpreadsheetImportResultContainer[], UPDATED: SpreadsheetImportResultContainer[], ...}
                .groupBy('metadata.status')
                // => { [CREATED, SpreadsheetImportResultContainer[]], [UPDATED: SpreadsheetImportResultContainer[]], ...}
                .toPairs()
                // => [ { type: SUCCESS, elements: { metadata: { message: string }, data: HsHealthProductItem }[] }, { type: INFO, elements: { metadata: { message: string }, data: HsHealthProductItem }[] }, ...]
                .forEach(([status, results]) => {
                const type = importResultStatusToReportListTypesMap.get(status);
                const config = importReportConfig.get(type);
                const items = results;
                map.set(type, { config, items });
            });
            return new SpreadsheetImportReportList(map);
        });
        const data = {
            title: this._translate('EQUIVALENCES.TITLES.ADD'),
            parsing: {
                acceptedExtensions: '.ods, .xls, .xlsx, .csv',
                config: this._importConfig.spreadsheetParserConfig,
                report: {
                    config: new Map([
                        // Corrected errors
                        [ESpreadsheetImportReportListTypes.INFO, { hide: true }]
                    ])
                }
            },
            import: {
                report: {
                    config: new Map([
                        [
                            ESpreadsheetImportReportListTypes.SUCCESS,
                            {
                                status: this._translate('EQUIVALENCES.IMPORT.REPORT.SUCCESS.STATUS'),
                                instructions: this._translate('EQUIVALENCES.IMPORT.REPORT.SUCCESS.INSTRUCTIONS')
                            }
                        ],
                        [
                            ESpreadsheetImportReportListTypes.WARNING,
                            {
                                status: this._translate('EQUIVALENCES.IMPORT.REPORT.WARNING.STATUS'),
                                instructions: this._translate('EQUIVALENCES.IMPORT.REPORT.WARNING.INSTRUCTIONS')
                            }
                        ]
                    ]),
                    template: this.importReportListCustomTemplate
                },
                uploadFn
            }
        };
        const dialogRef = this._matDialog.open(SpreadsheetImportModalComponent, {
            data,
            disableClose: true,
            minWidth: '60%',
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe((isFileUploaded) => {
            if (isFileUploaded) {
                this._reload.emit();
            }
        }, (err) => this._snackbarService.openError(err));
    }
    /**
     * Map and translate import result's messages returned by HospistockService's importFromSpreadsheet method.
     * @param message Message returned by HospistockService's importFromSpreadsheet method as HsHealthProductSpreadsheetImportResultMessages enum.
     * @returns Mapped & Translated message to display in the import report list.
     */
    translateReportListMessage(message) {
        return this._translate(this._importConfig.spreadsheetImportResultMessagesMap.get(message));
    }
    gotoEquivalenceDetail(id) {
        this._state.go('mapui.equivalence-details', { id: id });
    }
    /**
     * Open equivalence modal to edit the given [[IEquivalence]]
     * @param event
     * @param {IEquivalence} equivalence
     */
    editEquivalence(event, equivalence) {
        const dialogRef = this._matDialog.open(EditEquivalenceComponent, {
            width: '60%',
            data: { equivalence: equivalence }
        });
        dialogRef.afterClosed().subscribe({
            next: (editedEquivalence) => {
                if (editedEquivalence) {
                    this._equivalencesService.save(editedEquivalence).subscribe({
                        next: () => {
                            this.search();
                            this._snackbarService.open(this._translateService.instant('EQUIVALENCES.ADD_MODAL.SUCCESS.EDIT'));
                        },
                        error: (err) => this._snackbarService.openError(err)
                    });
                }
            },
            error: (err) => this._snackbarService.openError(err)
        });
    }
    /**
     * Open confirmation modal to delete a given [[IEquivalence]]
     * @param equivalence
     * @returns {IPromise<string>}
     */
    confirmDeleteEquivalence(equivalence) {
        this._dialogService
            .openConfirmDialog({
            title: this._translate('COMMONS.DELETE') + ' ?',
            message: this._translate('EQUIVALENCES.DELETE_MODAL.TEXT'),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        })
            .subscribe((result) => {
            if (result) {
                this._equivalencesService.delete(equivalence).subscribe({
                    next: () => {
                        this.search();
                        this._snackbarService.open(this._translate('EQUIVALENCES.DELETE_MODAL.SUCCESS'));
                    },
                    error: (err) => this._snackbarService.openError(err)
                });
            }
        });
    }
    /**
     * Open voucher modal to add new [[IEquivalenceVoucher]]
     * @param equivalence
     */
    openVoucherModal(equivalence) {
        const dialogRef = this._matDialog.open(EquivalenceVoucherComponent, {
            width: '60%',
            data: { equivalence: equivalence }
        });
        dialogRef.afterClosed().subscribe({
            next: (voucher) => {
                if (!equivalence.vouchers) {
                    equivalence.vouchers = [];
                }
                equivalence.vouchers.push(voucher);
            },
            error: (err) => this._snackbarService.openError(err)
        });
    }
    isOwner(equivalence) {
        return Utils.compareIds(this._access.hospital, equivalence.hospital);
    }
    openDetail(equivalence) {
        this._stateService.go('mapui.pharmacyV2.equivalences.detail', { id: equivalence._id });
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    _buildImportConfig() {
        this._spreadsheetClassBuilder = [
            {
                column: EquivalenceColumns.MEDIC_NAME,
                path: ['medicName']
            },
            {
                column: EquivalenceColumns.MEDIC_DCI,
                path: ['medicDci']
            },
            {
                column: EquivalenceColumns.MEDIC_POSOLOGY,
                path: ['medicPosology']
            },
            {
                column: EquivalenceColumns.EQUI_NAME,
                path: ['substitutions', '0', 'name']
            },
            {
                column: EquivalenceColumns.EQUI_DCI,
                path: ['substitutions', '0', 'dci']
            },
            {
                column: EquivalenceColumns.DELIVERY,
                path: ['delivery']
            },
            {
                column: EquivalenceColumns.REASON,
                path: ['reason']
            },
            {
                column: EquivalenceColumns.COMMENT,
                path: ['comment']
            }
        ];
        const spreadsheetParserConfig = {
            columns: [
                {
                    label: EquivalenceColumns.MEDIC_NAME,
                    processes: [CellProcesses.trim()],
                    validators: [CellValidators.required()]
                },
                {
                    label: EquivalenceColumns.MEDIC_DCI,
                    processes: [CellProcesses.trim()],
                    validators: []
                },
                {
                    label: EquivalenceColumns.MEDIC_POSOLOGY,
                    processes: [CellProcesses.trim()],
                    validators: []
                },
                {
                    label: EquivalenceColumns.EQUI_NAME,
                    processes: [CellProcesses.trim()],
                    validators: [CellValidators.required()]
                },
                {
                    label: EquivalenceColumns.EQUI_DCI,
                    processes: [CellProcesses.trim()],
                    validators: []
                },
                {
                    label: EquivalenceColumns.DELIVERY,
                    processes: [CellProcesses.trim()],
                    validators: []
                },
                {
                    label: EquivalenceColumns.REASON,
                    processes: [CellProcesses.trim()],
                    validators: []
                },
                {
                    label: EquivalenceColumns.COMMENT,
                    processes: [CellProcesses.trim()],
                    validators: []
                }
            ],
            options: {
                headerToLowerCase: true,
                abortOnMajorError: true
            }
        };
        const spreadsheetImportResultMessagesMap = new Map(Utils.enumToKeys(EquivalenceSpreadsheetImportResultMessages).map((key) => [
            key,
            'EQUIVALENCES.IMPORT.REPORT.MESSAGES.' + key
        ]));
        this._importConfig = {
            spreadsheetImportResultMessagesMap,
            spreadsheetParserConfig
        };
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_k = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _k : Object)
], EquivalencesListComponent.prototype, "_paginator", void 0);
__decorate([
    ViewChild('importReportListCustomTemplate', { static: true }),
    __metadata("design:type", typeof (_l = typeof TemplateRef !== "undefined" && TemplateRef) === "function" ? _l : Object)
], EquivalencesListComponent.prototype, "importReportListCustomTemplate", void 0);
EquivalencesListComponent = __decorate([
    Component({
        selector: 'mpx-equivalences-list',
        template: require('./equivalences-list.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof EquivalencesService !== "undefined" && EquivalencesService) === "function" ? _b : Object, typeof (_c = typeof Access !== "undefined" && Access) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof DialogService !== "undefined" && DialogService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object, typeof (_g = typeof StateService !== "undefined" && StateService) === "function" ? _g : Object, typeof (_h = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _h : Object, typeof (_j = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _j : Object])
], EquivalencesListComponent);
export { EquivalencesListComponent };
