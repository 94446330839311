export const languagesList = {
    fr: {
        'title': 'Français',
        'flag': 'fr'
    },
    en: {
        'title': 'English',
        'flag': 'us'
    }
};
