var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Input, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { ExchangeMapService } from '../../../../exchange/map/map-exchange.service';
import { ELoanMapCategory } from '@mapuilabs/mpl-interfaces';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FilterManager } from '../../filter-manager.class';
let ExchangeMapLegendComponent = class ExchangeMapLegendComponent {
    constructor(_fuseTranslationLoaderService, _exchangeMapService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._exchangeMapService = _exchangeMapService;
        this.filters = ELoanMapCategory;
        this.isOpen = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngAfterViewInit() {
        this._panel.expandedChange.subscribe((isOpen) => (this.isOpen = isOpen));
    }
    search() {
        this._exchangeMapService.updateSearch(this.searchValue);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof FilterManager !== "undefined" && FilterManager) === "function" ? _c : Object)
], ExchangeMapLegendComponent.prototype, "filterManager", void 0);
__decorate([
    ViewChild(MatExpansionPanel, { static: true }),
    __metadata("design:type", typeof (_d = typeof MatExpansionPanel !== "undefined" && MatExpansionPanel) === "function" ? _d : Object)
], ExchangeMapLegendComponent.prototype, "_panel", void 0);
ExchangeMapLegendComponent = __decorate([
    Component({
        selector: 'exchange-map-legend',
        template: require('./map-legend.component.html').default,
        styles: [require('./map-legend.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof ExchangeMapService !== "undefined" && ExchangeMapService) === "function" ? _b : Object])
], ExchangeMapLegendComponent);
export { ExchangeMapLegendComponent };
