var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { CEstablishmentType } from '@shared/constTypes/establishment-type/establishment-type.const';
let ChangeHospitalComponent = class ChangeHospitalComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        /**
         * Sends the selected establishment to the component's parent
         */
        this.onSelected = new EventEmitter();
        this.CEstablishmentType = CEstablishmentType;
        /**
         * Output function for the `mpx-hospital-search` component.
         * @param establishment The [[Hospital]] selected
         */
        this.onHospitalSelected = (establishment) => {
            if (establishment) {
                this.onSelected.emit(establishment);
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        // Preselect type Hospital
        this.selectedType = EEstablishmentType.Hospital;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _b : Object)
], ChangeHospitalComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], ChangeHospitalComponent.prototype, "exclude", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], ChangeHospitalComponent.prototype, "onSelected", void 0);
ChangeHospitalComponent = __decorate([
    Component({
        selector: 'mpx-change-hospital',
        template: require('./change-hospital.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], ChangeHospitalComponent);
export { ChangeHospitalComponent };
