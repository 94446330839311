var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
let MembersTabComponent = class MembersTabComponent {
    constructor() {
        this.isLoading = false;
        this.displayedColumns = ['name', 'email', 'lastConnect', 'roles'];
    }
    // Sort
    sortCompare(a, b, direct) {
        if (!a) {
            return direct ? 1 : -1;
        }
        if (!b) {
            return direct ? -1 : 1;
        }
        if (a === b) {
            return 0;
        }
        if (a < b) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    sortData(sort) {
        if (!this.laboratoryMembers) {
            return;
        }
        const data = this.laboratoryMembers.slice();
        if (!sort || !sort.active || sort.direction === '') {
            this.laboratoryMembers = data.sort((a, b) => {
                return this.sortCompare(Utils.normalize(a.fullName), Utils.normalize(b.fullName), true);
            });
            return;
        }
        this.laboratoryMembers = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.sortCompare(Utils.normalize(a.fullName), Utils.normalize(b.fullName), isAsc);
                case 'email':
                    return this.sortCompare(a.email, b.email, isAsc);
                case 'lastConnect':
                    return this.sortCompare(a.lastConnect ? new Date(a.lastConnect).getTime() : null, b.lastConnect ? new Date(b.lastConnect).getTime() : null, isAsc);
                default:
                    return 0;
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], MembersTabComponent.prototype, "laboratoryMembers", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], MembersTabComponent.prototype, "isLoading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MembersTabComponent.prototype, "animationDone", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Hospital !== "undefined" && Hospital) === "function" ? _b : Object)
], MembersTabComponent.prototype, "laboratory", void 0);
MembersTabComponent = __decorate([
    Component({
        selector: 'mpx-members-tab',
        template: require('./members-tab.component.html').default,
        styles: [require('./members-tab.component.scss')],
        animations: fuseAnimations
    })
], MembersTabComponent);
export { MembersTabComponent };
