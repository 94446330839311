export const locale = {
    lang: 'en',
    data: {
        activities: {
            TITLE: `City-hospital exchanges`,
            SUBTITLE: `Prescription requests`,
            SEARCH_ACTIVITY: `Search an activity`,
            TABLE: {
                DATE: `Date`,
                TYPE: `Type`,
                ESTABLISHMENT: `Establishment`,
                PATIENT: `Patient`,
                STATUS: `Status`
            },
            STATUSES: {
                WAITING: `Waiting`,
                FILES: `File(s) received`,
                ADDED_TO_CONCILIATION: `File(s) added to drug balance`,
                ENDED: `Ended`,
                CANCELED: `Cancelled`,
                MAILS: `Email(s) received`,
                MEDREC: `Medication reconciliation`,
                ENDED_WITH_FILE: 'Additional files'
            },
            TYPES: {
                ASK_PRESCRIPTION: `Ask for prescription`,
                MEDREC: `Sharing reconciliation sheet`,
                ASK_PRESCRIPTION_OCTAVE: `OCTAVE - Follow-up of medication supports`
            },
            MEDICATION_RECONCILIATION: {
                TRANSIT: {
                    ENTRANCE: `Entrance reconciliation`,
                    EXIT: `Exit reconciliation`,
                    TRANSFER: `Transfer reconciliation`
                }
            },
            ORIGIN: {
                HOSPITAL: 'Hospital',
                PHARMACY: 'Pharmacy'
            }
        }
    }
};
