var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { DefaultLayout } from './widgets';
import { IdentityService } from '@services/identity/identity.service';
import { EMemberStatus } from '@mapuilabs/mpl-interfaces';
let WidgetService = class WidgetService {
    constructor(identity) {
        this.identity = identity;
    }
    getLayout() {
        switch (this.identity.user.status) {
            case EMemberStatus.Doctor:
                return new DefaultLayout();
            case EMemberStatus.Pharmacist:
                return new DefaultLayout();
            case EMemberStatus.PharmacyStudent:
                return new DefaultLayout();
            case EMemberStatus.Preparator:
                return new DefaultLayout();
            default:
                return new DefaultLayout();
        }
    }
};
WidgetService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object])
], WidgetService);
export { WidgetService };
