var ESearchReasonType;
(function (ESearchReasonType) {
    ESearchReasonType[ESearchReasonType["Label"] = 1] = "Label";
    ESearchReasonType[ESearchReasonType["Code"] = 2] = "Code";
})(ESearchReasonType || (ESearchReasonType = {}));
export class HvSearchHospReasonController {
    /** @ngInject */
    constructor(MedicationReconciliationService) {
        this.MedicationReconciliationService = MedicationReconciliationService;
        this.ESearchType = ESearchReasonType;
        /**
         * Return an array containing all matches from value
         * @param {string} value
         * @returns {angular.IPromise<Array<any>>}
         */
        this.searchReason = () => {
            if (this.searchType === ESearchReasonType.Label)
                return this.MedicationReconciliationService.searchHospReasonByName(this.ctrl.searchText);
            if (this.searchType === ESearchReasonType.Code)
                return this.MedicationReconciliationService.searchHospReasonByCode(this.ctrl.searchText.toUpperCase());
        };
        /**
         * Change dispaly for selected [[IHospReason]] in md-autocomplete input
         * @param reason
         */
        this.formatSelection = (reason) => {
            if (reason.code != undefined) {
                return `${reason.code} - ${reason.libelle_long}`;
            }
            return reason.libelle_long;
        };
        /**
         * An [[IPatient]] is selected and returned
         */
        this.hospReasonSelected = () => {
            if (this.ctrl.selected) {
                this.reason.code = this.ctrl.selected.code;
                this.reason.label_long = this.ctrl.selected.libelle_long;
                this.reason.label_short = this.ctrl.selected.libelle_court;
                this.onSelected({ $event: { reason: this.reason } });
            }
        };
        this.searchType = this.ESearchType.Label;
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
    $onInit() {
        if (!this.reason)
            this.reason = {};
        else if (this.reason) {
            if (this.reason.code)
                this.ctrl.searchText = `${this.reason.code} - ${this.reason.label_long}`;
            else
                this.ctrl.searchText = `${this.reason.label_long}`;
        }
    }
    setInputPlaceholder() {
        if (this.searchType === ESearchReasonType.Label)
            return 'Recherche de motif d\'hospitalisation par libellé';
        if (this.searchType === ESearchReasonType.Code)
            return 'Recherche de motif d\'hospitalisation par libellé';
    }
}
