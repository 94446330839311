import { ERoleSubscription } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class RoleUtils {
    /** @ngInject */
    constructor(_) {
        /**
         * Set project roles
         * @param roles
         */
        this.setProjectsRoles = (roles) => {
            this.projectsRoles = roles;
            // Look for trial roles
            for (const p of this.projectsRoles) {
                for (const role of p.roles) {
                    if (role.subscription === ERoleSubscription.Trial) {
                        this._trials[p.name] = Utils.getId(role);
                    }
                }
            }
        };
        /**
         * Set selected roles
         * @param roles
         */
        this.setSelectedRoles = (roles) => {
            if (!this.projectsRoles.length)
                return;
            for (const project of this.projectsRoles) {
                this.selectedRoles[project.name] = this._.map(this._.filter(roles, v2 => {
                    return !!this._.find(project.roles, v3 => {
                        return Utils.compareIds(v2, v3);
                    });
                }), v => {
                    return Utils.getId(v);
                });
            }
        };
        /**
         * Set selected roles
         * @param roles
         */
        this.setSelectedRolesInvitation = (roles) => {
            if (!this.projectsRoles.length)
                return;
            for (const project of this.projectsRoles) {
                this.selectedRoles[project.name] = this._.filter(roles, (role) => {
                    return (role.project == project.name.toLocaleLowerCase() && role.type == 2);
                });
                project.roles = this.selectedRoles[project.name];
            }
        };
        /**
         * Format given project name
         * @param name
         */
        this.formatProjectName = (name) => {
            if (!name)
                return null;
            return name.substr(0, 1).toUpperCase() + name.substr(1).replace(/([A-Z])/g, ' $1');
        };
        /**
         * Get list of role to store in [[IMember]] or [[IHospital]] instance
         */
        this.getDefinitiveRoles = () => {
            const roles = [];
            for (const project of this.projectsRoles) {
                for (const role of project.roles) {
                    if (!!this.selectedRoles[project.name] && !!~this.selectedRoles[project.name].indexOf(Utils.getId(role))) {
                        roles.push({ _id: Utils.getId(role) });
                    }
                }
            }
            return roles;
        };
        /**
         * Set default roles by given names
         */
        this.setDefaultSelected = (subscriptions) => {
            if (!Array.isArray(subscriptions)) {
                subscriptions = [subscriptions];
            }
            this.selectedRoles = {};
            for (const project of this.projectsRoles) {
                for (const role of project.roles) {
                    if (!!~subscriptions.indexOf(role.subscription)) {
                        if (!this.selectedRoles[project.name]) {
                            this.selectedRoles[project.name] = [];
                        }
                        this.selectedRoles[project.name].push(Utils.getId(role));
                    }
                }
            }
        };
        /**
         * Is trial selected
         */
        this.isTrial = (roles = null) => {
            if (roles) {
                for (const t of Object.keys(this._trials)) {
                    if (this._.find(roles, v => Utils.compareIds(v, this._trials[t]))) {
                        return true;
                    }
                }
                return false;
            }
            return this.selectedRoles.mapui && !!~this.selectedRoles.mapui.indexOf(this._trials.mapui);
        };
        /**
         * Add all trial ids in given roles if they does not already exists
         * @param roles
         */
        this.addTrial = (roles) => {
            for (const t of Object.keys(this._trials)) {
                if (!this._.find(roles, v => Utils.compareIds(v, this._trials[t]))) {
                    roles.push(this._trials[t]);
                }
            }
        };
        /**
         * Add all trial ids in given roles if they does not already exists
         * @param roles
         */
        this.removeTrial = (roles) => {
            for (const t of Object.keys(this._trials)) {
                const idx = this._.findIndex(roles, v => Utils.compareIds(v, this._trials[t]));
                if (!!~idx) {
                    roles.splice(idx, 1);
                }
            }
        };
        this.formatRolesNamesByProject = (roles) => {
            const projects = [];
            for (const role of roles) {
                let project = this._.find(projects, v => v.name === this.formatProjectName(role.project));
                if (!project) {
                    project = { name: this.formatProjectName(role.project), roles: '' };
                    projects.push(project);
                }
                project.roles += project.roles ? `, ${role.name}` : role.name;
            }
            return projects;
        };
        this._ = _;
        this.selectedRoles = {};
        this.projectsRoles = [];
        this._trials = {};
    }
}
