export const locale = {
    lang: 'fr',
    data: {
        CONFIGURATION: {
            TITLE: 'Configuration',
            SERVICE_TITLE: 'Services de mon établissement',
            NEW_SERVICE_LABEL: 'Nouveau service dans votre hôpital',
            EDIT_WARNING: 'La modification du service impactera le nom du service sélectionné sur les conciliations antérieures.',
            SERVICE_DELETED: 'Service supprimé',
            TOOLTIP: {
                ADD: 'Ajouter un service',
                EDIT: 'Modifier',
                RESTORE: 'Restaurer le service'
            }
        }
    }
};
