import 'angular-translate';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'angular-translate-loader-partial';
import 'ng-idle';
/** @ngInject */
export function IndexConfig($translateProvider, $translatePartialLoaderProvider, $windowProvider, $httpProvider, IdleProvider, TitleProvider) {
    $httpProvider.defaults.withCredentials = true;
    const $window = $windowProvider.$get();
    const lang = $window.navigator.language || $window.navigator.userLanguage;
    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '/app/i18n/{lang}.json',
    });
    $translateProvider.preferredLanguage(lang.split('-')[0] === 'fr' ? 'fr' : 'en');
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
    $translateProvider.useCookieStorage();
    $translatePartialLoaderProvider.addPart('app');
    // configure Idle settings
    IdleProvider.idle(60 /* seconds */ * 27 /* min */); // Duration seconds of inactivity to consider a user idle
    IdleProvider.timeout(60 /* seconds */ * 3 /* min */); // Duration in seconds to display a warning before actually terminate user session
    TitleProvider.enabled(false); // it is enabled by default
}
;
