import { BREAKPOINT } from '@angular/flex-layout';
const CUSTOM_BREAKPOINTS = [
    {
        alias: 'lg',
        suffix: 'Lg',
        mediaQuery: 'screen and (min-width: 1280px) and (max-width: 1920px)',
        overlapping: false,
        priority: 700
    },
    {
        alias: 'xl',
        suffix: 'Xl',
        mediaQuery: 'screen and (min-width: 1920.1px)',
        overlapping: false,
        priority: 650
    }
];
export const CustomBreakPointsProvider = {
    provide: BREAKPOINT,
    useValue: CUSTOM_BREAKPOINTS,
    multi: true
};
