var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { SharedModule } from '@shared/shared.module';
import { AvailableHospitalListComponent } from './available-hospital-list/available-hospital-list.component';
import { AvailableHospitalDetailComponent } from './available-hospital-detail/available-hospital-detail.component';
import { AvailableHospitalRoutingModule } from './available-hospital-routing.module';
import { AvailableHospitalListItemComponent } from './available-hospital-list/available-hospital-list-item/available-hospital-list-item.component';
import { EditAvailableComponent } from './available-hospital-list/edit-available/edit-available.component';
import { AcceptAvailableOfferComponent } from './available-hospital-detail/accept-available-offer/accept-available-offer.component';
import { AvailableHospitalSidebarComponent } from './available-hospital-list/filter-sidebar/filter-sidebar.component';
import { CantCreateModalComponent } from './available-hospital-list/cant-create-modal/cant-create-modal.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
let AvailableHospitalModule = class AvailableHospitalModule {
};
AvailableHospitalModule = __decorate([
    NgModule({
        declarations: [
            AvailableHospitalListComponent,
            AvailableHospitalListItemComponent,
            AvailableHospitalSidebarComponent,
            EditAvailableComponent,
            CantCreateModalComponent,
            AvailableHospitalDetailComponent,
            AcceptAvailableOfferComponent
        ],
        imports: [
            CommonModule,
            BrowserAnimationsModule,
            FlexModule,
            FuseSharedModule,
            FuseSidebarModule,
            SharedModule,
            MatBadgeModule,
            MatButtonModule,
            MatCheckboxModule,
            MatDatepickerModule,
            MatDialogModule,
            MatExpansionModule,
            MatIconModule,
            MatInputModule,
            MatListModule,
            MatMenuModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatTooltipModule,
            AvailableHospitalRoutingModule,
            NgScrollbarModule
        ],
        exports: [AvailableHospitalDetailComponent]
    })
], AvailableHospitalModule);
export { AvailableHospitalModule };
