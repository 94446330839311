export class MpSidenavSummaryComponent {
    constructor() {
        this.controller = 'MpSidenavSummaryController';
        this.controllerAs = 'vm';
        this.template = require('./mp-sidenav-summary.html').default;
        this.bindings = {
            loan: '<',
            onMemberSelection: '&',
        };
    }
}
