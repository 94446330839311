import * as angular from 'angular';
import { HvModalDrugBalanceEntryComponent } from './hv-modal-drug-balance-entry/hv-modal-drug-balance-entry.component';
import { HvModalDrugBalanceEntryController } from './hv-modal-drug-balance-entry/hv-modal-drug-balance-entry.controller';
import { HvSearchDrugComponent } from './hv-search-drug/hv-search-drug.component';
import { HvSearchDrugController } from './hv-search-drug/hv-search-drug.controller';
import { DrugBalanceService } from './drugBalance.service';
import { Access } from "@main/services/auth/access.service";
angular.module('hospiville.services.drugBalance', [])
    .component('hvModalDrugBalanceEntry', new HvModalDrugBalanceEntryComponent())
    .controller('HvModalDrugBalanceEntryController', HvModalDrugBalanceEntryController)
    .component('hvSearchDrug', new HvSearchDrugComponent())
    .controller('HvSearchDrugController', HvSearchDrugController)
    .service('DrugBalanceService', DrugBalanceService)
    .service('Access', Access);
