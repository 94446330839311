import * as angular from 'angular';
import 'angular';
import 'jquery';
import 'datatables.net';
import 'angular-datatables';
import 'v-accordion';
import { LoanService } from './loan.service';
import { MpSidenavSummaryController } from './mp-sidenav-summary/mp-sidenav-summary.controller';
import { MpSidenavSummaryComponent } from './mp-sidenav-summary/mp-sidenav-summary.component';
import { MpModalRequestController } from './mp-modal-request/mp-modal-request.controller';
import { MpModalRequestComponent } from './mp-modal-request/mp-modal-request.component';
import { MpModalLoanCreationController } from './mp-modal-loan/mp-modal-loan-creation.controller';
import { MpModalLoanCreationComponent } from './mp-modal-loan/mp-modal-loan-creation.component';
import { MpModalLoanCreatedController } from './mp-modal-loan/mp-modal-loan-created.controller';
import { MpModalLoanCreatedComponent } from './mp-modal-loan/mp-modal-loan-created.component';
import { MpLoanRollbackController } from './mp-modal-loan/mp-loan-rollback/mp-loan-rollback.controller';
import { MpLoanRollbackComponent } from './mp-modal-loan/mp-loan-rollback/mp-loan-rollback.component';
import { MpModalAvailableController } from './mp-modal-available/mp-modal-available.controller';
import { MpModalAvailableComponent } from './mp-modal-available/mp-modal-available.component';
import { MpLoanLiteController } from './mp-loan-lite/mp-loan-lite.controller';
import { MpLoanLiteComponent } from './mp-loan-lite/mp-loan-lite.component';
import { MpLoanCardsController } from './mp-loan-cards/mp-loan-cards.controller';
import { MpLoanCardsComponent } from './mp-loan-cards/mp-loan-cards.component';
angular
    .module('mapui.services.loan', [
    'datatables',
    'vAccordion',
    'ngAnimate',
    'mapui.services.auth',
    'mapui.services.modal',
    'mapui.services.staff',
    'mapui.services.medic',
    'mapui.services.fileUploader',
    'mapui.services.status',
])
    .service('LoanService', LoanService)
    .controller('MpSidenavSummaryController', MpSidenavSummaryController)
    .component('mpSidenavSummary', new MpSidenavSummaryComponent())
    .controller('MpModalRequestController', MpModalRequestController)
    .component('mpModalRequest', new MpModalRequestComponent())
    .controller('MpModalLoanCreationController', MpModalLoanCreationController)
    .component('mpModalLoanCreation', new MpModalLoanCreationComponent())
    .controller('MpModalLoanCreatedController', MpModalLoanCreatedController)
    .component('mpModalLoanCreated', new MpModalLoanCreatedComponent())
    .controller('MpLoanRollbackController', MpLoanRollbackController)
    .component('mpLoanRollback', new MpLoanRollbackComponent())
    .controller('MpModalAvailableController', MpModalAvailableController)
    .component('mpModalAvailable', new MpModalAvailableComponent())
    .controller('MpLoanLiteController', MpLoanLiteController)
    .component('mpLoanLite', new MpLoanLiteComponent())
    .controller('MpLoanCardsController', MpLoanCardsController)
    .component('mpLoanCards', new MpLoanCardsComponent())
    .run(runLoan);
function runLoan($templateCache) {
    $templateCache.put('app/main/services/loan/mp-sidenav-summary/views/header.html', require('./mp-sidenav-summary/views/header.html').default);
    $templateCache.put('app/main/services/loan/mp-sidenav-summary/views/staff.html', require('./mp-sidenav-summary/views/staff.html').default);
    $templateCache.put('app/main/services/loan/mp-sidenav-summary/views/comments.html', require('./mp-sidenav-summary/views/comments.html').default);
    $templateCache.put('app/main/services/loan/mp-sidenav-summary/views/vouchers.html', require('./mp-sidenav-summary/views/vouchers.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-request/steps/summary.html', require('./mp-modal-request/steps/summary.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-request/steps/respond.html', require('./mp-modal-request/steps/respond.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/validation/prep/steps/validator.html', require('./mp-modal-loan/steps/validation/prep/steps/validator.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/validation/prep/prep.html', require('./mp-modal-loan/steps/validation/prep/prep.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/medic/steps/return-person-information.html', require('./mp-modal-loan/steps/return/medic/steps/return-person-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/medic/steps/return-product-information.html', require('./mp-modal-loan/steps/return/medic/steps/return-product-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/dm/steps/return-person-information.html', require('./mp-modal-loan/steps/return/dm/steps/return-person-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/dm/steps/return-product-information.html', require('./mp-modal-loan/steps/return/dm/steps/return-product-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-available/steps/summary.html', require('./mp-modal-available/steps/summary.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-available/steps/reservation.html', require('./mp-modal-available/steps/reservation.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/creation.html', require('./mp-modal-loan/steps/creation/creation.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/validation/validation.html', require('./mp-modal-loan/steps/validation/validation.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/confirmation.html', require('./mp-modal-loan/steps/confirmation/confirmation.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirm-command/confirm-command.html', require('./mp-modal-loan/steps/confirm-command/confirm-command.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/return.html', require('./mp-modal-loan/steps/return/return.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return-validation/return-validation.html', require('./mp-modal-loan/steps/return-validation/return-validation.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/loan/loan.html', require('./mp-modal-loan/steps/confirmation/loan/loan.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/borrow/borrow.html', require('./mp-modal-loan/steps/confirmation/borrow/borrow.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/prep/prep.html', require('./mp-modal-loan/steps/confirmation/prep/prep.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/borrow/steps/confirmer.html', require('./mp-modal-loan/steps/confirmation/borrow/steps/confirmer.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/borrow/steps/additional-information.html', require('./mp-modal-loan/steps/confirmation/borrow/steps/additional-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/loan/steps/confirmer.html', require('./mp-modal-loan/steps/confirmation/loan/steps/confirmer.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/prep/steps/confirmer.html', require('./mp-modal-loan/steps/confirmation/prep/steps/confirmer.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/confirmation/prep/steps/additional-information.html', require('./mp-modal-loan/steps/confirmation/prep/steps/additional-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/steps/general-information.html', require('./mp-modal-loan/steps/creation/steps/general-information.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/steps/hospital-selection.html', require('./mp-modal-loan/steps/creation/steps/hospital-selection.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/steps/request.html', require('./mp-modal-loan/steps/creation/steps/request.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/steps/available.html', require('./mp-modal-loan/steps/creation/steps/available.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/steps/instance.html', require('./mp-modal-loan/steps/creation/steps/instance.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/creation/steps/preparation.html', require('./mp-modal-loan/steps/creation/steps/preparation.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/dm/dm.html', require('./mp-modal-loan/steps/return/dm/dm.html').default);
    $templateCache.put('app/main/services/loan/mp-modal-loan/steps/return/medic/medic.html', require('./mp-modal-loan/steps/return/medic/medic.html').default);
}
