export const locale = {
    lang: 'fr',
    data: {
        ACTIVITY_DETAILS: {
            TABS: {
                ACTIONS: {
                    TITLE: `Informations et actions`,
                    STATUS: `Statut de l'activité :`,
                    INFORMATION: {
                        TITLE: `Informations`,
                        PATIENT: `Patient`,
                        CONSENT: `Consentement `,
                        SOURCE: `Source`,
                        DEST: `Destinataire`,
                        MED_REC_TRANSIT: `Type de conciliation`,
                        NB_DOCS: `Nombre de documents associés à l'activité`
                    },
                    BUTTONS: {
                        END_ACTIVITY: {
                            BUTTON_LABEL: `Clôturer l'activité`,
                            CONFIRM_DIALOG_TITLE: `Clôturer la demande`,
                            CONFIRM_DIALOG_MESSAGE: `Voulez-vous vraiment clôturer cette activité ?`,
                            CONFIRM_DIALOG_OK_BUTTON: `Terminer l'activité`,
                            ALREADY_ENDED: `L'activité a déjà été clôturée.`
                        },
                        CANCEL_ACTIVITY: {
                            BUTTON_LABEL: `Annuler la demande`,
                            CONFIRM_DIALOG_TITLE: `Annuler la demande`,
                            CONFIRM_DIALOG_MESSAGE: `Voulez-vous vraiment annuler cette activité ?`,
                            CONFIRM_DIALOG_CANCEL_BUTTON: `Retour`,
                            CONFIRM_DIALOG_OK_BUTTON: `Annuler l'activité`,
                            ALREADY_CANCELED: `L'activité a déjà été annulée.`
                        },
                        IMPORT_FILES: `Importer des fichiers`,
                        ADD_TO_MEDREC: `Ajouter au bilan médicamenteux`,
                        GOT_MEDREC: `Une fiche de conciliation a déjà été envoyée.`
                    }
                },
                MAILS: `Mails`,
                FILES: `Fichiers`,
                ADD_TO_DRUG_BALANCE: `Ajouter au bilan médicamenteux`
            },
            SNACKBAR: {
                MODIFIED: `L'activité a bien été modifiée.`,
                ENDED: `La demande a bien été clôturée.`,
                CANCELED: `La demande a bien été annulée.`,
                FILES_ADDED: `Les fichiers ont bien été ajoutés.`
            }
        }
    }
};
