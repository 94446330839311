export class PubSubService {
    /** @ngInject */
    constructor($timeout, _) {
        this.$timeout = $timeout;
        this._ = _;
        /**
         * Subscribe to a specific topic
         * @param topic The topic to subscribe to
         * @param fn The callback called when a message is published on the topic
         * @return A function used to unregistered this subscription
         */
        this.on = (topic, fn) => {
            const wrap = {
                sid: null,
                fn: null,
            };
            if (!this._topics[topic]) {
                this._topics[topic] = [];
            }
            wrap.sid = ++this._sid;
            wrap.fn = fn;
            this._topics[topic].push(wrap);
            return () => {
                const ite = this._.findIndex(this._topics[topic], (elem) => {
                    return elem.sid == wrap.sid;
                });
                if (~ite) {
                    this._topics[topic].splice(ite, 1);
                }
            };
        };
        /**
         * Emit a message to a given topic with optional parameters
         * @param topic The topic to notify
         * @param args The optional parameters
         */
        this.emit = (topic, ...args) => {
            this.$timeout(() => {
                if (this._topics[topic]) {
                    for (const wrapper of this._topics[topic]) {
                        wrapper.fn.apply(this, args);
                    }
                }
            }, 0);
        };
        this._topics = {};
        this._sid = -1;
    }
}
