var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { rolesTypes } from '@shared/constTypes/roles/roles-types.const';
import { rolesSubscriptions } from '@shared/constTypes/roles/roles-subscriptions.const';
import { Utils } from '@main/services/Utils';
let EditRoleComponent = class EditRoleComponent {
    constructor(fuseNavigationService, _dialogRef, data) {
        this.fuseNavigationService = fuseNavigationService;
        this._dialogRef = _dialogRef;
        this.data = data;
        this.rolesTypesList = rolesTypes;
        this.rolesSubList = rolesSubscriptions;
        this.form = new UntypedFormGroup({});
        this.isStatesMenuLoading = true;
        data.rolesList = data.rolesList.filter((role) => !role.isAdmin && role.type === rolesTypes.user.value);
        this.setFormControls();
    }
    ngAfterViewInit() {
        this.isStatesMenuLoading = false;
    }
    setFormControls() {
        var _a, _b, _c, _d, _e, _f, _g;
        this.form.setControl('type', new UntypedFormControl((_a = this.data.role) === null || _a === void 0 ? void 0 : _a.type));
        this.form.setControl('name', new UntypedFormControl((_b = this.data.role) === null || _b === void 0 ? void 0 : _b.name));
        this.form.setControl('subscription', new UntypedFormControl(((_c = this.data.role) === null || _c === void 0 ? void 0 : _c.subscription) || -1));
        this.form.setControl('isAdmin', new UntypedFormControl((_d = this.data.role) === null || _d === void 0 ? void 0 : _d.isAdmin));
        this.form.setControl('description', new UntypedFormControl((_e = this.data.role) === null || _e === void 0 ? void 0 : _e.description));
        this.form.setControl('home', new UntypedFormControl((_f = this.data.role) === null || _f === void 0 ? void 0 : _f.home));
        this.form.setControl('availableUserRoles', new UntypedFormControl(((_g = this.data.role) === null || _g === void 0 ? void 0 : _g.availableUserRoles) ? Utils.getIds(this.data.role.availableUserRoles) : []));
    }
    submitForm() {
        if (this.form.invalid || this.form.pristine || !this.form.dirty) {
            return;
        }
        const role = this.data.role || {
            project: this.data.project,
            type: this.form.controls.type.value,
            name: this.form.controls.name.value,
            description: this.form.controls.description.value,
            permissions: [],
            subscription: this.form.controls.subscription.value,
            isAdmin: this.form.controls.isAdmin.value,
            home: this.form.controls.home.value,
            hospital: null,
            availableUserRoles: this.form.controls.availableUserRoles.value
        };
        if (this.data.role) {
            role.type = this.form.controls.type.value;
            role.name = this.form.controls.name.value;
            role.description = this.form.controls.description.value;
            role.subscription = this.form.controls.subscription.value;
            role.isAdmin = this.form.controls.isAdmin.value;
            role.home = this.form.controls.home.value;
            role.availableUserRoles = this.form.controls.availableUserRoles.value;
        }
        this._dialogRef.close(role);
    }
};
EditRoleComponent = __decorate([
    Component({
        selector: 'mpx-edit-role',
        template: require('./edit-role.component.html').default,
        styles: [require('./edit-role.component.scss')]
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _a : Object, typeof (_b = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _b : Object, Object])
], EditRoleComponent);
export { EditRoleComponent };
