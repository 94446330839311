export const locale = {
    lang: `fr`,
    data: {
        map: {
            MARKERS: {
                HOSPITAL: `Hôpital :`,
                FINESS_CODE: `FINESS Géo :`,
                FINESS_CODE_JUR: `FINESS Jur :`,
                BUTTONS: {
                    SEE_MORE: `Voir plus`
                }
            },
            SEARCH: `Rechercher un établissement`,
            CONFIG_PANEL: {
                TITLE: `Légende`,
                FILTERS: {
                    LOANS: {
                        LABELS: {
                            AVAILABLE: `Mise à disposition`,
                            REQUEST: `Demande`,
                            OTHERS: `Autres`
                        }
                    },
                    GROUPS: {
                        TITLE: `Groupes`,
                        PLACEHOLDER: `Sélectionner un groupe`
                    },
                    MAD_SEARCH: {
                        TITLE: `Mises à disposition`,
                        PLACEHOLDER: `Rechercher une mise à dispo.`
                    }
                }
            }
        }
    }
};
