export const locale = {
    lang: 'fr',
    data: {
        PREPARATION: {
            TITLE: 'Préparations',
            SEARCH: 'Rechercher une préparation',
            TABLE: {
                NAME: 'Nom',
                DOSAGE: 'Dosage',
                PREP_ADMIN: "Voie d'administration",
                GALENIC_FORM: 'Forme galénique',
                ACTIONS: 'Actions'
            },
            MODALS: {
                COMPLETE: {
                    TITLE: {
                        ADD: 'Ajouter une préparation',
                        EDIT: 'Modifier la préparation {{ name }}'
                    },
                    FORM: {
                        NAME: 'Nom',
                        DOSAGE: 'Dosage',
                        TYPE: 'Type',
                        GALENIC_FORM: 'Forme galénique',
                        VOLUME: 'Volume',
                        CONSERVATION_COND: {
                            BIG: 'Conditions de conservation',
                            SMALL: 'Conservation'
                        },
                        EXCIPIENTS: 'Excipients',
                        PREP_ADMINISTRATION: "Voie d'administration",
                        UNIT_PRICE: 'Prix unitaire (indicatif)'
                    }
                },
                DELETE: {
                    TITLE: 'Supprimer une préparation',
                    MESSAGE: 'Êtes vous sûr de vouloir supprimer la préparation {{ name }} ?'
                }
            },
            NOTIFS: {
                ADD: 'Préparation ajoutée',
                EDIT: 'Préparation modifiée',
                DELETE: 'Préparation supprimée'
            }
        }
    }
};
