export class HvModalDrugBalanceEntryComponent {
    constructor() {
        this.controller = 'HvModalDrugBalanceEntryController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-drug-balance-entry.html').default;
        this.bindings = {
            activity: '<',
        };
    }
}
