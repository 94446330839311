export class UnlockVaultController {
    /** @ngInject */
    constructor(VaultService, $location) {
        const redirectUrl = $location.search().redirectURL;
        $location.search('redirectURL', null);
        if (redirectUrl !== undefined && redirectUrl !== '') {
            VaultService.setUnlocked();
            $location.path(redirectUrl);
            return;
        }
        $location.path('/');
        return;
    }
}
