var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { ListPharmaciesComponent } from './list-pharmacies/list-pharmacies.component';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from '../../shared/shared.module';
import { PharmaciesRoutingModule } from './pharmacies-routing.module';
import { PharmaciesDetailComponent } from './pharmacies-detail/pharmacies-detail.component';
import { InformationTabComponent } from './pharmacies-detail/information-tab/information-tab.component';
import { ActionsTabComponent } from './pharmacies-detail/actions-tab/actions-tab.component';
import { OtherTabComponent } from './pharmacies-detail/other-tab/other-tab.component';
import { MembersTabComponent } from './pharmacies-detail/members-tab/members-tab.component';
let PharmaciesModule = class PharmaciesModule {
    constructor() { }
};
PharmaciesModule = __decorate([
    NgModule({
        declarations: [
            ListPharmaciesComponent,
            PharmaciesDetailComponent,
            InformationTabComponent,
            ActionsTabComponent,
            OtherTabComponent,
            MembersTabComponent
        ],
        imports: [
            CommonModule,
            UIRouterModule,
            BrowserAnimationsModule,
            ClipboardModule,
            FuseSharedModule,
            FuseDirectivesModule,
            FuseConfirmDialogModule,
            FuseSidebarModule,
            SharedModule,
            MatIconModule,
            MatTableModule,
            MatSortModule,
            MatRippleModule,
            MatPaginatorModule,
            MatMenuModule,
            MatButtonModule,
            MatSnackBarModule,
            MatDialogModule,
            MatSlideToggleModule,
            MatTabsModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            MatProgressSpinnerModule,
            MatAutocompleteModule,
            MatCheckboxModule,
            MatDatepickerModule,
            MatNativeDateModule,
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [PharmaciesRoutingModule]
    }),
    __metadata("design:paramtypes", [])
], PharmaciesModule);
export { PharmaciesModule };
