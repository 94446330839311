export const locale = {
    lang: 'en',
    data: {
        WIDGET_HOME: {
            TITLE: 'Custom widgets',
            TABLE: {
                PERIOD: {
                    TITLE: 'Period',
                    FROM: 'From',
                    TO: 'To',
                    NOT_DEFINE: 'indefinitely'
                },
                TITLE: 'Title',
                ACTIONS: 'Actions',
                VISIBILITY: {
                    ON: 'Activate',
                    OFF: 'Deactivate'
                },
                DUPLICATE: {
                    TITLE: 'Duplicate',
                    NOTIF: 'Widget home duplicated'
                },
                EMPTY: 'No widgets'
            },
            DETAIL: {
                RESET: {
                    TITLE: 'Adding button',
                    MESSAGE: 'Do you really want to continue? The addition of the current button will be cancelled.'
                },
                INVALID_FORM: 'Invalid form',
                SETTINGS: {
                    LABEL: 'Settings',
                    PERIOD_START: 'Start date',
                    PERIOD_END: 'End date',
                    VISIBILITY: 'Visibility',
                    PRIORITY_ORDER: 'Priority order',
                    WIDTH: 'Width',
                    BACKGROUND_COLOR: 'Background color',
                    TITLE: 'Title',
                    CONTENT: {
                        TITLE: 'Content',
                        HELP: {
                            LABEL: 'Help: ',
                            ITALIC: 'italic',
                            BOLD: 'bold',
                            UNDERLINE: 'underline',
                            ELEMENT: 'element',
                            TITLE: 'title',
                            BREAK_LINE: '2 spaces to break line'
                        }
                    },
                    COUNTER: 'Counter',
                    BUTTONS: {
                        LABEL: 'Buttons',
                        TITLE: 'Title',
                        REDIRECT: {
                            TITLE: 'Redirection'
                        },
                        URL: 'URL',
                        COLOR: 'Color',
                        LAYOUT: 'Layout'
                    }
                },
                PREVIEW: {
                    LABEL: 'Preview'
                }
            }
        }
    }
};
