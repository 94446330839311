export const locale = {
    lang: 'fr',
    data: {
        PANEL_TITLES: {
            VISIBLE_FILES: `Fichiers`,
            HIDDEN_FILES: `Fichiers masqués`
        },
        TOOLTIPS: {
            MAIL: `Pièce jointe de mail`,
            IMPORT: `Fichier importé`,
            COMMENT: 'Commentaire associé au document'
        },
        BUTTONS: {
            IMPORT: `Importer un fichier`,
            HIDE: `Masquer le fichier`,
            SHOW: `Démasquer le fichier`,
            ADD_TO_DRUG_BALANCE: `Ajouter au bilan médicamenteux`,
            ADD_TO_PATIENT_FILES: 'Ajouter aux documents du patient',
            VIEW_COMMENT: `Voir le commentaire`,
            DELETE: `Supprimer le fichier`
        },
        SNACKBAR: {
            HIDE_FILE: `Le fichier a été déplacé dans la section "Fichiers masqués".`,
            NO_MORE_FILES: `Tous les fichiers ont été supprimés, l'activité est repassée 'En attente'.`
        },
        MOVE_TO_PATIENT_FILES: `Le fichier a été ajouté aux documents du patient.`,
        ALREADY_ADDED: 'Fichier déjà présent dans les documents du patient.',
        CONFIRM_DIALOG: {
            DELETE_TITLE: `Confirmer la suppression du fichier sélectionné`,
            DELETE_MESSAGE: `Êtes-vous sûr de vouloir supprimer ce fichier ?`
        }
    }
};
