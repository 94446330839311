export const locale = {
    lang: 'en',
    data: {
        REQUESTS_LIST: {
            TITLE: `Product requests`,
            INFO_COVID: `This is a collaborative tool between health facilities. It is not related to the COVID-19 module and RHAs.`,
            TABLE: {
                EMPTY: `No request for the moment.`,
                MEDIC_NAME: `Drug name`,
                HOSPITAL_NAME: `Hospital`,
                CREATION_DATE: `Date`,
                TYPE: `Type`,
                STATUS: `Status`,
                QUANTITY: `Quantity`
            },
            TOOLTIPS: {
                PREPARATION: 'Preparation',
                PILL: 'Pill',
                MEDICAL_DEVICE: 'Medical device'
            },
            SNACKBAR: {
                EDIT_OK: `The request has been modified.`
            }
        },
        SERVICES: {
            STATUS: {
                TYPES: {
                    LOAN: `Loan`,
                    AVAILABLE: `Provision`,
                    BORROW: `Borrow`
                },
                VOUCHERS: {
                    RETURN: `Return voucher`,
                    LOAN: `Loan voucher`,
                    BORROW: `Borrowing voucher`
                },
                FORCED: {
                    FORCED: `the {{date}} by {{name}}`,
                    CONFIRM: `Confirmation forced `,
                    RETURN: `Return forced the {{date}} by {{name}}`,
                    RELEASE: `Release forced`
                },
                WAITING: {
                    AVAILABLE: `Waiting for retake`,
                    ACCEPT: `Waiting for acceptation`,
                    CONFIRM: `Waiting for confirmation`,
                    PUBLISHED: `Request published`,
                    LIBERATE: `Waiting for release`,
                    BILL: `Waiting for billing`,
                    RETURN: `Waiting for return`,
                    RECEIVE: `Waiting for reception`,
                    SETTLE: `Waiting for settlement`,
                    VALID_RETURN: `Waiting for review`,
                    QUARANTINE: `Sterility quarantine`,
                    MANUFACTURING: `Manufacturing`
                },
                OK: `Ok`,
                TO: {
                    ACCEPT: `To accept`,
                    RELEASE: `To release`,
                    CONFIRM: `To confirm`,
                    LIBERATE: `To liberate`,
                    RETURN: `To return`,
                    BILL: `To bill`,
                    SETTLE: `To settle`,
                    RECEIVE: `To receive`,
                    VALID_RETURN: `To review`
                },
                DID: {
                    ACCEPTED: `Accepted on {{date}}`,
                    RELEASED: `Released on {{date}} by {{name}}`,
                    CONFIRMED: `Confirmed on {{date}} by {{name}}`,
                    YOU_RELEASED: `You released on {{date}}`,
                    YOU_CONFIRMED: `You confirmed on {{date}}`,
                    RETURNED: `Returned on {{date}}`,
                    PAYED_OFF: `Bill payed off on {{date}}`,
                    BILLED: `Billed on {{date}}`
                },
                AUTO: `automatically`
            }
        }
    }
};
