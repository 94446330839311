var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d;
import { Component, Inject, InjectionToken, Input } from '@angular/core';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsDashboardService } from '@app/hospistock/shared/services/hs-dashboard.service';
import { HsDownloadService } from '@app/hospistock/shared/services/hs-download.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
export const HS_DASHBOARD_WIDGET_LABO_LIST_CONFIG = new InjectionToken('hsDashboardWidgetLaboListConfig');
let HsDashboardWidgetLaboListComponent = class HsDashboardWidgetLaboListComponent {
    constructor(_fuseTranslationLoaderService, _dashboardService, _downloadService, _translationService, config) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._dashboardService = _dashboardService;
        this._downloadService = _downloadService;
        this._translationService = _translationService;
        this.config = config;
        // Init
        this.noData = false;
        this.isLoading = false;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.widgetContainerConfig = {
            title: this._translationService.instant('DASHBOARD.WIDGET.LABO_LIST.TITLE'),
            action: { icon: 'cloud_download' }
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._getLaboList();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _getLaboList() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            try {
                this.labos = (yield lastValueFrom(this._dashboardService.getDashboardLaboList())).map((labo) => Object.assign(labo, { creationDate: moment(labo.creationDate).format('MMM YYYY') }));
            }
            catch (err) {
                // Handle forbidden access
                if ((err === null || err === void 0 ? void 0 : err.status) === 403) {
                    this.forbiddenAccess = true;
                }
                // Assign empty array to display widget
                this.labos = [];
                this.noData = true;
                console.error(err);
            }
            this.isLoading = false;
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getSpreadsheetAndDownload() {
        this._downloadService.downloadLaboListAsSpreadsheet(this._dashboardService.viewType);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsDashboardWidgetLaboListComponent.prototype, "labos", void 0);
HsDashboardWidgetLaboListComponent = __decorate([
    Component({
        selector: 'mpx-hs-dashboard-widget-labo-list',
        template: require('./hs-dashboard-widget-labo-list.component.html').default,
        providers: [HsDownloadService]
    }),
    __param(4, Inject(HS_DASHBOARD_WIDGET_LABO_LIST_CONFIG)),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof HsDashboardService !== "undefined" && HsDashboardService) === "function" ? _b : Object, typeof (_c = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _c : Object, typeof (_d = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _d : Object, Object])
], HsDashboardWidgetLaboListComponent);
export { HsDashboardWidgetLaboListComponent };
