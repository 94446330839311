export class HvDoctorSelectionComponent {
    constructor() {
        this.controller = 'HvDoctorSelectionController';
        this.controllerAs = 'vm';
        this.template = require('./hv-doctor-selection.html').default;
        this.bindings = {
            onChange: '&',
            onDelete: '&',
            source: '<',
            change: '<',
            openmodal: '<',
            mpTitle: '<'
        };
    }
}
