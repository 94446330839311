var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { CommonRoutingModule } from './common-routing.module';
import { Error404Component } from './error404/error404.component';
let AppCommonModule = class AppCommonModule {
};
AppCommonModule = __decorate([
    NgModule({
        declarations: [Error404Component],
        imports: [CommonModule, FuseSharedModule],
        exports: [CommonRoutingModule]
    })
], AppCommonModule);
export { AppCommonModule };
