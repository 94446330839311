import { EEnv, EPubSubTopic, ESocketTopic } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import { io } from 'socket.io-client';
/**
 * Socket Service using socket-io
 */
export class SocketService {
    /** @ngInject */
    constructor(Access, PubSubService, ModalService) {
        this.Access = Access;
        this.PubSubService = PubSubService;
        this.ModalService = ModalService;
        /**
         * Connect to server if it's not yet connected
         * This method must be called before each socket emit or on
         */
        this.connect = () => {
            if (!this._isConnected && this.Access.isLogged()) {
                this._socket.open();
            }
        };
        /**
         * Send a message
         * @param data
         * @param callback
         * @param type
         */
        this.emit = (type, data, callback) => {
            this._socket.emit(type || ESocketTopic.Notification, data, (response) => {
                if (callback) {
                    callback(response.err, response.data);
                }
            });
        };
        /**
         * Listen socket for a specific event (eventName)
         * The callback will be called everytime an eventName's message arrives
         * @param eventName
         * @param callback
         */
        this.on = (eventName, callback) => {
            this._socket.on(eventName, (msg) => {
                // console.info('[Socket] Receive msg with topic \'' + eventName + '\':', msg);
                callback(msg);
            });
        };
        /**
         * Get online members
         * @return {Array<IMember>}
         */
        this.onlineMembers = () => {
            return this._onlineMembers;
        };
        /**
         * Close the socket
         */
        this.close = () => {
            this._socket.disconnect();
        };
        switch (Utils.env) {
            case EEnv.Dev:
                this._port = 4646;
                break;
            default:
                this._port = 443;
                break;
        }
        this._isConnected = false;
        this._socket = io('//' + location.hostname + ':' + this._port + '/', {
            autoConnect: false,
            secure: true,
            transports: ['websocket', 'polling'],
        });
        this._socket.on('connect', () => {
            this._isConnected = true;
        });
        this._socket.on('connect_error', () => {
            this._isConnected = false;
        });
        this._socket.on(ESocketTopic.OnlineMembers, (args) => {
            this._onlineMembers = args;
        });
        this._socket.on(ESocketTopic.kill, () => {
            // Do not kill the page if there is another page opened during COVID crisis
            // this.PubSubService.emit(EPubSubTopic.IDLE_STOP);
            // this.ModalService.close(null);
            // this.ModalService.show({
            //     component: 'mp-modal-kill',
            //     bindings: {},
            //     escapeToClose: false,
            //     clickOutsideToClose: false,
            //     multiple: false,
            // });
            // this._socket.disconnect(true);
        });
        this._socket.on('error', (msg) => {
            console.error('Socket error', msg);
            if (msg === 'Not authorized') {
                console.info('Socket is no longer authorized');
                // TODO http request to refresh socket token
                this.close();
                this._isConnected = false;
            }
        });
        this._socket.on('disconnect', () => {
            this._isConnected = false;
        });
        this.PubSubService.on(EPubSubTopic.SOCKET_CONNECT, () => this.connect());
        this.PubSubService.on(EPubSubTopic.SOCKET_CLOSE, () => this.close());
    }
}
