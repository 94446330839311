export const locale = {
    lang: 'en',
    data: {
        REQUEST_WIDGET: {
            TITLE: `Last requests`,
            UNIT: ` Unit(s)`,
            DEADLINE: 'Deadline: ',
            NO_REQUEST: 'No recent requests.',
            SEE_MORE: 'See more requests',
            FORBIDDEN_MESSAGE: 'Access denied',
            CONTACT_US: 'Contact us'
        }
    }
};
