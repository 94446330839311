export class HvModalSelectReconciliationTypeComponent {
    constructor() {
        this.controller = 'HvModalSelectReconciliationTypeController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-select-reconciliation-type.html').default;
        this.bindings = {
            medRec: '<',
        };
    }
}
