import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CrudReadonly } from './crud-readonly.class';
export class CrudTemplateService extends CrudReadonly {
    constructor(_injector, _ctor, _endPoint, _permissions) {
        super(_injector, _ctor, _endPoint, _permissions);
        this._injector = _injector;
        this._ctor = _ctor;
        this._endPoint = _endPoint;
        this._permissions = _permissions;
    }
    save(obj, options) {
        if (!this._permissionService.authorize(this._permissions.update)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!obj || !obj._id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${obj._id}`;
        return this._http.put(fullEndPoint, obj, options).pipe(map((data) => new this._ctor(data)));
    }
    add(obj) {
        if (!this._permissionService.authorize(this._permissions.create)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!obj) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        return this._http.post(this._endPoint, obj).pipe(map((data) => new this._ctor(data)));
    }
    delete(obj) {
        if (!this._permissionService.authorize(this._permissions.delete)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!obj || !obj._id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${obj._id}`;
        return this._http.delete(fullEndPoint, { responseType: 'text' });
    }
}
