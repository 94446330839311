var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EMaPUIPermission, Preparation } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { PermissionService } from '@services/permission/permission.service';
import { PreparationService } from '@services/preparations/preparations.service';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { EditPrepaModalComponent } from '../edit-prepa-modal/edit-prepa-modal.component';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let PreparationsListComponent = class PreparationsListComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _permissionService, _preparationService, _dialogService, _matDialog, _injector) {
        super(_injector, _preparationService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._permissionService = _permissionService;
        this._preparationService = _preparationService;
        this._dialogService = _dialogService;
        this._matDialog = _matDialog;
        this._injector = _injector;
        this.canAddPrepa = this._permissionService.authorize(EMaPUIPermission.Preparations_Create);
        this.canEditPrepa = this._permissionService.authorize(EMaPUIPermission.Preparations_Edit);
        this.canDeletePrepa = this._permissionService.authorize(EMaPUIPermission.Preparations_Delete);
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Header
        this.pageHeaderConfig = {
            title: this._translate('PREPARATION.TITLE'),
            searchBarPlaceholder: this._translate('PREPARATION.SEARCH'),
            svgIcon: 'preparation'
        };
        this.genericTableConfig = { clickableRows: true };
        this.columns = [
            { label: this._translate('PREPARATION.TABLE.NAME'), attribute: 'name', sort: true },
            { label: this._translate('PREPARATION.TABLE.DOSAGE'), attribute: 'dosage', sort: true },
            { label: this._translate('PREPARATION.TABLE.PREP_ADMIN'), attribute: 'prepAdministration', sort: true },
            { label: this._translate('PREPARATION.TABLE.GALENIC_FORM'), attribute: 'galenicForm', sort: true }
        ];
        if (this.canDeletePrepa) {
            this.columns.push({
                label: this._translate('PREPARATION.TABLE.ACTIONS'),
                sort: false,
                template: 'actions'
            });
        }
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    addPrepaModal() {
        const dialogRef = this._matDialog.open(EditPrepaModalComponent, {
            minWidth: '60%'
        });
        dialogRef.afterClosed().subscribe((prepa) => {
            if (prepa) {
                this._preparationService.add(prepa).subscribe(() => {
                    this._reload.emit();
                    this._snackbarService.open(this._translationService.instant('PREPARATION.NOTIFS.ADD'));
                }, (err) => this._snackbarService.openError(err));
            }
        }, (err) => {
            this._snackbarService.openError(err);
        });
    }
    editPrepaModal(prepa) {
        const dialogRef = this._matDialog.open(EditPrepaModalComponent, {
            minWidth: '60%',
            data: new Preparation(prepa)
        });
        dialogRef.afterClosed().subscribe((prepa) => {
            if (prepa) {
                this._preparationService.save(prepa).subscribe(() => {
                    this._reload.emit();
                    this._snackbarService.open(this._translationService.instant('PREPARATION.NOTIFS.EDIT'));
                }, (err) => this._snackbarService.openError(err));
            }
        }, (err) => {
            this._snackbarService.openError(err);
        });
    }
    deletePrepaModal(prepa) {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('PREPARATION.MODALS.DELETE.TITLE'),
            message: this._translationService.instant('PREPARATION.MODALS.DELETE.MESSAGE', { name: prepa.name }),
            confirmButton: this._translationService.instant('COMMONS.DELETE'),
            cancelButton: this._translationService.instant('COMMONS.CANCEL')
        })
            .subscribe((result) => {
            if (result) {
                this._preparationService.delete(new Preparation(prepa)).subscribe(() => {
                    this._reload.emit();
                    this._snackbarService.open(this._translationService.instant('PREPARATION.NOTIFS.DELETE'));
                }, (err) => this._snackbarService.openError(err));
            }
        });
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_g = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _g : Object)
], PreparationsListComponent.prototype, "_paginator", void 0);
PreparationsListComponent = __decorate([
    Component({
        selector: 'mpx-preparations-list',
        template: require('./preparations-list.component.html').default,
        styles: [require('./preparations-list.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _b : Object, typeof (_c = typeof PreparationService !== "undefined" && PreparationService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _e : Object, typeof (_f = typeof Injector !== "undefined" && Injector) === "function" ? _f : Object])
], PreparationsListComponent);
export { PreparationsListComponent };
