export const locale = {
    lang: 'en',
    data: {
        DETAIL: {
            TITLE: `Regional Health Agency`,
            INFORMATION: {
                TITLE: `Information`,
                NAME: `Name`,
                FRENCH_DEPARTMENTS: `Departments`,
                NB_ESTABLISHMENT: `Number of establishment`,
                ROLES: {
                    MAPUI: `MaPUI roles`,
                    HOSPIVILLE: `Hospiville roles`,
                    WORKSPACE: `Workspace roles`
                }
            },
            TABLE: {
                TITLE: `Members of `,
                MEMBER_NAME: `Member's name`,
                MEMBER_EMAIL: `Contact mail`,
                MEMBER_2FA: `2FA`
            },
            SNACKBAR: {
                OK: `The Regional Health Agency has been modified.`
            }
        }
    }
};
