export const locale = {
    lang: 'en',
    data: {
        ERRORS: {
            MAJOR: {
                MISSING_COLUMN: 'The column si missing in the file to import',
                INTERNAL_ERROR: 'An unexpected error occured. Please contact help desk.',
                EMPTY_FILE: 'The file cannot be EMPTY.'
            },
            MODERATE: {
                CELL_CHECK_ARRAY: 'Multiple values accepted, separated by " {{ separator }} ".',
                CELL_CHECK_BOOLEAN: 'Cell value must be a YES or NO.',
                CELL_CHECK_CIP: 'Cell value must be a valid CIP13.',
                CELL_CHECK_CLADIMED: 'Cell value must be a valid CLADIMED.',
                CELL_CHECK_DATE: 'Cell value must be a DATE.',
                CELL_CHECK_DATE_FORMAT: 'Cell value must be a DATE with one of the following formats: {{formats}}.',
                CELL_CHECK_DATE_GT: 'Cell value must be a DATE greater than {{date}}.',
                CELL_CHECK_DATE_GTE: 'Cell value must be a DATE equal or greater than {{date}}.',
                CELL_CHECK_DATE_LT: 'Cell value must be a DATE lower than {{date}}.',
                CELL_CHECK_DATE_LTE: 'Cell value must be a DATE equal or lower than {{date}}.',
                CELL_CHECK_EQUALS: 'Cell value must equals {{value}}.',
                CELL_CHECK_LIST: 'Cell value must be part of the predefined list.',
                CELL_CHECK_REQUIRED: 'Cell value must not be EMPTY.',
                CELL_CHECK_STRING: 'Cell value must be a TEXT.',
                CELL_CHECK_NUMBER: 'Cell value must be a POSITIVE NUMBER.',
                CELL_CHECK_UCD: 'Cell value must be a valid UCD7 or UCD13.'
            },
            MINOR: {
                CELL_PROCESS_CLEAR: 'Some SPACES can be deleted BEFORE, BETWEEN and/or AFTER the value.',
                CELL_PROCESS_UCD13: 'The UCD can be converted into UCD13.',
                CELL_PROCESS_CIP13: 'The CIP can be converted into CIP13.',
                CELL_PROCESS_REMOVE_SPECIAL_CHAR: 'Special caracters can be removed from reference.',
                CELL_PROCESS_TRIM: 'Some SPACES can be deleted BEFORE and/or AFTER the value.'
            }
        }
    }
};
