var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { UIRouterModule } from '@uirouter/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseNavigationModule, FuseSidebarModule } from '@fuse/components';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { NavbarComponent } from './navbar/navbar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NotificationPanelComponent } from './notification-panel/notification-panel.component';
import { MatListModule } from '@angular/material/list';
let LayoutModule = class LayoutModule {
};
LayoutModule = __decorate([
    NgModule({
        declarations: [ToolbarComponent, NavbarComponent, NotificationPanelComponent],
        bootstrap: [ToolbarComponent, NavbarComponent, NotificationPanelComponent],
        imports: [
            RouterModule,
            UIRouterModule,
            CommonModule,
            MatButtonModule,
            MatIconModule,
            FuseDirectivesModule,
            FuseSidebarModule,
            FuseSharedModule,
            FuseNavigationModule,
            BrowserAnimationsModule,
            MatMenuModule,
            MatToolbarModule,
            MatBadgeModule,
            MatTooltipModule,
            MatListModule
        ]
    })
], LayoutModule);
export { LayoutModule };
