var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StateService } from '@uirouter/angularjs';
import { SecureEmailAddressService } from '../../../services/admin/secure-email-address/secure-email-address.service';
import { ListPage } from '../../../shared/templates/listPage/listPage.class';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let SecureEmailsListComponent = class SecureEmailsListComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _secureEmailAddressService, _stateService, _injector) {
        super(_injector, _secureEmailAddressService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._secureEmailAddressService = _secureEmailAddressService;
        this._stateService = _stateService;
        this._injector = _injector;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('LIST_EMAILS.TITLE'),
            searchBarPlaceholder: this._translate('LIST_EMAILS.SEARCH_EMAIL'),
            svgIcon: 'email-lock'
        };
        this.genericTableConfig = { clickableRows: true };
        this.columns = [
            {
                label: this._translate('LIST_EMAILS.TABLE.HOSPITAL'),
                attribute: 'hospital.name',
                sort: true
            },
            {
                label: this._translate('LIST_EMAILS.TABLE.EMAIL'),
                attribute: 'secureEmail',
                sort: true
            },
            {
                label: this._translate('LIST_EMAILS.TABLE.MANAGER'),
                attribute: 'managerFirstName',
                template: 'manager',
                sort: true
            }
        ];
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    goToDetail(id) {
        this._stateService.go('mapui.adm.secure-emails.detail', { id });
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_e = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _e : Object)
], SecureEmailsListComponent.prototype, "_paginator", void 0);
SecureEmailsListComponent = __decorate([
    Component({
        selector: 'mpx-list-emails',
        template: require('./list-emails.component.html').default,
        styles: [require('../../../app.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof SecureEmailAddressService !== "undefined" && SecureEmailAddressService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof Injector !== "undefined" && Injector) === "function" ? _d : Object])
], SecureEmailsListComponent);
export { SecureEmailsListComponent };
