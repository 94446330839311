var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { ESpreadsheetImportReportListTypes } from './spreadsheet-import-report-list.types';
let SpreadsheetImportReportListComponent = class SpreadsheetImportReportListComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        // Bind enums to view
        this.ESpreadsheetImportReportListTypes = ESpreadsheetImportReportListTypes;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init maps
        this.reportListTypeTitleIconMap = new Map([
            [ESpreadsheetImportReportListTypes.ERROR, { label: 'error_outline', styles: ['red-400-fg'] }],
            [ESpreadsheetImportReportListTypes.WARNING, { label: 'report_problem', styles: ['amber-400-fg'] }],
            [ESpreadsheetImportReportListTypes.INFO, { label: 'info', styles: ['blue-400-fg'] }],
            [ESpreadsheetImportReportListTypes.SUCCESS, { label: 'done', styles: ['green-400-fg'] }]
        ]);
        this.reportListTypeMessageStylesMap = new Map([
            [ESpreadsheetImportReportListTypes.ERROR, ['error-light']],
            [ESpreadsheetImportReportListTypes.WARNING, ['warning']],
            [ESpreadsheetImportReportListTypes.INFO, ['info']],
            [ESpreadsheetImportReportListTypes.SUCCESS, ['success-light']]
        ]);
        this.reportListTypeExpansionPanelContentStylesMap = new Map([
            [ESpreadsheetImportReportListTypes.ERROR, ['red-100-bg']],
            [ESpreadsheetImportReportListTypes.WARNING, ['amber-100-bg']],
            [ESpreadsheetImportReportListTypes.INFO, ['blue-100-bg']],
            [ESpreadsheetImportReportListTypes.SUCCESS, ['green-100-bg']]
        ]);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], SpreadsheetImportReportListComponent.prototype, "list", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SpreadsheetImportReportListComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof TemplateRef !== "undefined" && TemplateRef) === "function" ? _b : Object)
], SpreadsheetImportReportListComponent.prototype, "contentTemplate", void 0);
SpreadsheetImportReportListComponent = __decorate([
    Component({
        selector: 'mpx-spreadsheet-import-report-list',
        template: require('./spreadsheet-import-report-list.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], SpreadsheetImportReportListComponent);
export { SpreadsheetImportReportListComponent };
