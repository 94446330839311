import { EHospivillePermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
export class SecureMessagingService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Find an array of [[IMSSLetterBoxes]] (ORG and PER) with the given finess
         * @param finess
         */
        this.findLetterBoxesByFiness = (finess) => {
            const defer = this.$q.defer();
            if (!finess)
                defer.reject(EMpStatus.NoDataProvided);
            else if (!this.Access.authorize(EHospivillePermission.PatientFile_Read))
                defer.reject(EMpStatus.NoAccess);
            else {
                this.Api.mssLetterBoxes.get({ finess: finess }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Find an array of [[IMSSLetterBoxes]] (ORG and PER) containing the given address
         * @param address
         */
        this.findLetterBoxesByAdress = (address) => {
            const defer = this.$q.defer();
            if (!address || address.length < 3)
                defer.reject(EMpStatus.NoDataProvided);
            else if (!this.Access.authorize(EHospivillePermission.PatientFile_Read))
                defer.reject(EMpStatus.NoAccess);
            else {
                this.Api.mssLetterBoxes.get({ address: address }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
}
