import { ELoanState } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
/** @ngInject */
export class MpLoanRollbackController {
    /** @ngInject */
    constructor(StatusService, ModalService, _, Api) {
        this.StatusService = StatusService;
        this.ModalService = ModalService;
        this._ = _;
        this.Api = Api;
        /**
         * Get all steps compared to [[ELoanState]]
         * @returns {Array<string>}
         * @private
         */
        this._getSteps = () => {
            if (ELoanState.ReturnValidation == this.state) {
                return ['validReturn'];
            }
            if (ELoanState.Return == this.state || ELoanState.Bill == this.state) {
                return ['validReturn', 'return'];
            }
            if (ELoanState.ValidReception == this.state) {
                return ['validReturn', 'return', 'confirmReceipt'];
            }
            if (ELoanState.Confirmation == this.state) {
                return ['validReturn', 'return', 'confirmReceipt', 'confirmation'];
            }
            if (ELoanState.Validation == this.state) {
                return ['validReturn', 'return', 'confirmReceipt', 'confirmation', 'validation'];
            }
            return [];
        };
        /**
         * Get the [[ILoan]] type
         * @returns {string}
         * @private
         */
        this._getType = () => {
            if (this.StatusService.isPrep(this.loan)) {
                return 'prep';
            }
            if (this.StatusService.isMed(this.loan)) {
                return 'medic';
            }
            if (this.StatusService.isDM(this.loan)) {
                return 'dm';
            }
            return '';
        };
        /**
         * Create a copy of the [[ILoan]], rollback
         * @private
         */
        this._createBackup = () => {
            const arg = angular.copy(this.loan);
            arg.loanPerson = undefined;
            arg.preparation = undefined;
            arg.borrowingPerson = undefined;
            arg.borrower = undefined;
            arg.validPerson = undefined;
            arg.lender = undefined;
            this.rollback = arg;
        };
        /**
         * Remove values not refer in constRollback table
         * @param step the [[ILoan]] step
         * @private
         */
        this._removeDynamics = (step) => {
            if (step == 'confirmation') {
                //Check if a loan or a borrowing
                if (this.StatusService.isLoan(this.loan)) {
                    this.loan.borrowingPerson = null;
                    this.loan.borrowingComment = null;
                }
                else {
                    this.loan.loanPerson = null;
                    this.loan.loanComment = null;
                }
                //FILES
                if (this.StatusService.isPrep(this.loan) && this.loan.filesConfirmation) {
                    this.loan.filesConfirmation = null;
                }
            }
            if (step == 'return') {
                // if (this.StatusService.isSte(this.loan)) {
                //TODO A VERIFIER. N'existe pas actuellement
                /* this.loan.ancList.ancillaires_ret = null;*/
                //}
            }
        };
        /**
         * Ask confirmation to rollback and close modal with/without performing action.
         */
        this.setRollback = () => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.TITLE',
                        text: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.CANCEL_LAST_STEP',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                const steps = this._getSteps();
                const type = this._getType();
                if (this._.size(steps) == 0) {
                    // TODO : add toast fail
                    return false;
                }
                if (type === '') {
                    // TODO : add toast fail
                    return false;
                }
                this._createBackup();
                if (this._constRollback) {
                    for (let i = 0; i < this._.size(steps); ++i) {
                        this._removeDynamics(steps[i]);
                        for (const attr in this._constRollback[steps[i]][type]) {
                            if (this._constRollback[steps[i]][type].hasOwnProperty(attr)) {
                                if (this.loan.hasOwnProperty(this._constRollback[steps[i]][type][attr])) {
                                    this.loan[this._constRollback[steps[i]][type][attr]] = null;
                                }
                            }
                        }
                    }
                }
                this.onClicked({ loan: this.loan, rollback: this.rollback });
            })
                .catch(() => {
                this.onClicked({ loan: null, rollback: null });
            });
        };
    }
    $onInit() {
        this.Api.constType.get((res) => {
            this._constRollback = res.rollback;
        });
    }
    $onChange(changes) {
        if (changes.loan) {
            this.loan = angular.copy(this.loan);
        }
        if (changes.showAsButton) {
            this.showAsButton = angular.copy(this.showAsButton);
        }
    }
}
