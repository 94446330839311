var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { ChangeDetectionStrategy, Component, Injector, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsDownloadService } from '@app/hospistock/shared/services/hs-download.service';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsImportSpreadsheetService } from '@app/hospistock/shared/services/hs-import-spreadsheet.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { NavBarService } from '@app/services/nav-bar-subject/nav-bar-subject.service';
import { SpreadsheetImportModalComponent } from '@app/shared/components/spreadsheet-import-modal/spreadsheet-import-modal.component';
import { ESpreadsheetImportReportListTypes, SpreadsheetImportReportList } from '@app/shared/components/spreadsheet-import-modal/spreadsheet-import-report-list/spreadsheet-import-report-list.types';
import { ListPage } from '@app/shared/templates/listPage/listPage.class';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHsHealthProductTypes, SpreadsheetImportResultStatus } from '@mapuilabs/mpl-interfaces';
import { merge } from 'angular';
import * as _ from 'lodash';
import { compact } from 'lodash';
import { lastValueFrom, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { HsModalPublicationFormComponent } from '../../modals/publication/form/hs-modal-publication-form.component';
let HsPublicationDualViewComponent = class HsPublicationDualViewComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _downloadService, _importSpreadsheetService, _publicationContainerService, _healthProductService, _matDialog, _navBarService, _injector) {
        super(_injector, _publicationContainerService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._downloadService = _downloadService;
        this._importSpreadsheetService = _importSpreadsheetService;
        this._publicationContainerService = _publicationContainerService;
        this._healthProductService = _healthProductService;
        this._matDialog = _matDialog;
        this._navBarService = _navBarService;
        this._injector = _injector;
        this.downloadDisabled = false;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this._templateDownloadCallback = () => {
            this.downloadDisabled = false;
            this._changeDetectorRef.detectChanges();
        };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init subjects
        this._unsubscribeAll = new Subject();
        // Init constants
        this.dynamicDataContainerConfig = {
            noData: {
                svgIcon: 'cart-remove',
                label: this._translationService.instant('PUBLICATION.DETAILS.NO_SELECTED_PUBLICATION')
            }
        };
    }
    // Navbar unfolded flag
    get navBarUnfolded() {
        return this._navBarService.navBarUnfolded;
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // HsPublication service values
    get readonly() {
        return this._publicationContainerService.readonly;
    }
    get isLoadingDetail$() {
        return this._publicationContainerService.isLoadingDetail;
    }
    get currentPublicationContainer$$() {
        return this._publicationContainerService.currentPublicationContainer$$;
    }
    get currentPublicationContainer$() {
        return this._publicationContainerService.currentPublicationContainer$$.value;
    }
    get publicationContainers$$() {
        return this._publicationContainerService.publicationContainers$$;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a, _b;
        // ListPage ngOnInit
        super.ngOnInit();
        // Update config
        this.detailConfig = this.config.detail;
        this.filtersConfig = this.config.filters;
        this.importConfig = this.config.import;
        this.importAlternativeSpreadsheetConfig = (_a = this.importConfig) === null || _a === void 0 ? void 0 : _a.spreadsheet.alternative;
        this.importPublicationSpreadsheetConfig = (_b = this.importConfig) === null || _b === void 0 ? void 0 : _b.spreadsheet.publication;
        this.listConfig = this.config.list;
        this.pageHeaderConfig = this.config.pageHeader;
        // Update Service publications on ListPage dataSource change event
        // Skip first BehaviorSubject initial value
        this.dataSource.pipe(skip(1), takeUntil(this._unsubscribeAll)).subscribe((publicationContainers) => this._publicationContainerService.updatePublicationContainers(publicationContainers), (err) => console.error(err));
        // Subscribe to reload event
        this._publicationContainerService.onReloadList
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._reload.emit());
        // Deselect all checked publications when updating list (before response)
        this._reload.subscribe(() => this._publicationContainerService.deselectAllPublications());
    }
    ngOnDestroy() {
        // ListPage ngOnDestroy
        super.ngOnDestroy();
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
        // Remove event listeners
        removeEventListener('beforeunload', this._templateDownloadCallback);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Bind TranslationService's instant method
     * @param value Text to translate
     * @param arg Translation arguments
     * @returns Translated text
     */
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    /**
     * Open publication form modal to create/update publication
     */
    addPublication() {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogRef = this._matDialog.open(HsModalPublicationFormComponent, {
                data: { injector: this._injector },
                disableClose: true,
                minWidth: '60%',
                maxWidth: '60%',
                restoreFocus: false
            });
            const publication = yield lastValueFrom(dialogRef.afterClosed());
            if (publication) {
                yield this._publicationContainerService.createPublication(publication);
                this._reload.emit();
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Header methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Downlaod the Hospistock documentation
     */
    downloadHospistockDoc() {
        window
            .open('https://www.mapui.fr/wp-content/uploads/2022/04/MP_Hospistock_Guide_utilisateurs_V2.0_04042022.pdf', '_blank')
            .focus();
    }
    /**
     * Bind PageHeader onSearch to ListPage search method
     * @param searchValue Search value to pass
     */
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    /**
     * Download import template
     */
    downloadImportTemplate() {
        var _a;
        if (!((_a = this.config) === null || _a === void 0 ? void 0 : _a.translationBase)) {
            throw new Error('No valid translation found');
        }
        const fileTemplateUrl = this._translate(this.config.translationBase + '.TEMPLATE.' + this._healthProductService.healthProductType);
        if (this.downloadDisabled) {
            return;
        }
        this.downloadDisabled = true;
        this._downloadService
            .downloadImportTemplate(fileTemplateUrl)
            .addEventListener('beforeunload', this._templateDownloadCallback);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Import methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Translate import result's messages in the translation attribute using spreadsheetImportResultMessagesMap from import config.
     * @param results SpreadsheetImportResultContainer containing the messages to translate.
     */
    _translateReportListMessages(results) {
        results.forEach(({ metadata }) => {
            const messagesMap = this.importPublicationSpreadsheetConfig.spreadsheetImportResultMessagesMap;
            //  Guard: no message
            if (!metadata.message) {
                return;
            }
            const importReportMessage = messagesMap.get(metadata.message);
            if (importReportMessage) {
                metadata.translation = this._translate(importReportMessage);
            }
            else {
                console.warn('Cannot get import report message related to', metadata.message);
            }
        });
    }
    /**
     * Open publication form modal to create/update publication
     */
    importPublications() {
        // Guard: no import config
        if (!this.importConfig) {
            throw new Error('Import config not provided, cannot import publications.');
        }
        // Define upload function to use in import modal
        const uploadFn = (file, importReportConfig) => __awaiter(this, void 0, void 0, function* () {
            // Build publication containers from imported spreadsheet
            const spreadsheetImportPublicationContainers = this._importSpreadsheetService.buildPublications(file.content, this.importPublicationSpreadsheetConfig);
            // Build alternatives containers from imported spreadsheet
            const spreadsheetImportAlternativeContainers = this._importSpreadsheetService.buildAlternatives(file.content, this.importAlternativeSpreadsheetConfig);
            // Upload publication
            const importPublicationsResponse = yield lastValueFrom(this._importSpreadsheetService.importPublicationsFromSpreadsheet(compact(spreadsheetImportPublicationContainers)));
            // Upload alternatives (must wait the publications import)
            const importAlternativesResponse = yield lastValueFrom(this._importSpreadsheetService.importAlternativesFromSpreadsheet(compact(spreadsheetImportAlternativeContainers)));
            const response = [...importPublicationsResponse, ...importAlternativesResponse];
            // Build reportList from import result
            const importResultStatusToReportListTypesMap = new Map([
                [SpreadsheetImportResultStatus.CREATED.toString(), ESpreadsheetImportReportListTypes.SUCCESS],
                [SpreadsheetImportResultStatus.UPDATED.toString(), ESpreadsheetImportReportListTypes.INFO],
                [SpreadsheetImportResultStatus.IGNORED.toString(), ESpreadsheetImportReportListTypes.WARNING],
                [SpreadsheetImportResultStatus.REJECTED.toString(), ESpreadsheetImportReportListTypes.ERROR]
            ]);
            const map = new Map();
            _(response)
                // Translate messages
                .tap((response) => this._translateReportListMessages(response))
                // => { CREATED: SpreadsheetImportResultContainer[], UPDATED: SpreadsheetImportResultContainer[], ...}
                .groupBy('metadata.status')
                // => { [CREATED, SpreadsheetImportResultContainer[]], [UPDATED: SpreadsheetImportResultContainer[]], ...}
                .toPairs()
                // => [ { type: SUCCESS, elements: { metadata: { message: string }, data: HsPublicationSpreadsheetImportContainer }[] }, { type: INFO, elements: { metadata: { message: string }, data: HsPublicationSpreadsheetImportContainer }[] }, ...]
                .forEach(([status, results]) => {
                // Sort items by row
                const items = results.sort((a, b) => a.metadata.row ? (b.metadata.row ? a.metadata.row - b.metadata.row : -1) : +1);
                const type = importResultStatusToReportListTypesMap.get(status);
                const config = importReportConfig.get(type);
                map.set(type, { config, items });
            });
            return new SpreadsheetImportReportList(map);
        });
        // Define SpreadsheetImportModal data with config and upload function
        const data = {
            title: this._translate('PUBLICATION.MODAL.IMPORT.TITLE'),
            parsing: {
                acceptedExtensions: '.ods, .xls, .xlsx, .csv',
                config: {
                    columns: [
                        ...this.importPublicationSpreadsheetConfig.spreadsheetParserConfig.columns,
                        ...this.importAlternativeSpreadsheetConfig.spreadsheetParserConfig.columns
                    ],
                    options: merge(this.importPublicationSpreadsheetConfig.spreadsheetParserConfig.options, this.importAlternativeSpreadsheetConfig.spreadsheetParserConfig.options)
                },
                report: {
                    config: new Map([
                        // Corrected errors
                        [ESpreadsheetImportReportListTypes.INFO, { hide: true }]
                    ])
                }
            },
            import: {
                report: {
                    config: new Map([
                        [
                            ESpreadsheetImportReportListTypes.SUCCESS,
                            {
                                status: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.SUCCESS.STATUS'),
                                instructions: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.SUCCESS.INSTRUCTIONS')
                            }
                        ],
                        [
                            ESpreadsheetImportReportListTypes.INFO,
                            {
                                status: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.INFO.STATUS'),
                                instructions: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.INFO.INSTRUCTIONS')
                            }
                        ],
                        [
                            ESpreadsheetImportReportListTypes.WARNING,
                            {
                                status: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.WARNING.STATUS'),
                                instructions: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.WARNING.INSTRUCTIONS')
                            }
                        ],
                        [
                            ESpreadsheetImportReportListTypes.ERROR,
                            {
                                status: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.ERROR.STATUS'),
                                instructions: this._translate('PUBLICATION.MODAL.IMPORT.REPORT.ERROR.INSTRUCTIONS')
                            }
                        ]
                    ]),
                    template: this._importReportListCustomTemplate
                },
                uploadFn
            }
        };
        const dialogRef = this._matDialog.open(SpreadsheetImportModalComponent, {
            data,
            disableClose: true,
            minWidth: '60%',
            maxWidth: '60%',
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe((isFileUploaded) => {
            if (isFileUploaded) {
                this._reload.emit();
            }
        }, (err) => this._snackbarService.openError(err));
    }
    displayRefs(healthProducts) {
        return healthProducts === null || healthProducts === void 0 ? void 0 : healthProducts.map((product) => product.ref).join(', ');
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationDualViewComponent.prototype, "config", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_j = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _j : Object)
], HsPublicationDualViewComponent.prototype, "_paginator", void 0);
__decorate([
    ViewChild('importReportListCustomTemplate', { static: true }),
    __metadata("design:type", typeof (_k = typeof TemplateRef !== "undefined" && TemplateRef) === "function" ? _k : Object)
], HsPublicationDualViewComponent.prototype, "_importReportListCustomTemplate", void 0);
HsPublicationDualViewComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-dual-view',
        template: require('./hs-publication-dual-view.component.html').default,
        animations: fuseAnimations,
        changeDetection: ChangeDetectionStrategy.OnPush,
        providers: [HsPublicationContainerService, HsHealthProductService, HsImportSpreadsheetService, HsDownloadService]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _b : Object, typeof (_c = typeof HsImportSpreadsheetService !== "undefined" && HsImportSpreadsheetService) === "function" ? _c : Object, typeof (_d = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _d : Object, typeof (_e = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _e : Object, typeof (_f = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _f : Object, typeof (_g = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _g : Object, typeof (_h = typeof Injector !== "undefined" && Injector) === "function" ? _h : Object])
], HsPublicationDualViewComponent);
export { HsPublicationDualViewComponent };
