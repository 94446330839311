export const locale = {
    lang: `fr`,
    data: {
        VOUCHER: {
            TITLE: 'Générer un bon',
            NAME: 'Création de bon pour : ',
            SUGGESTION: "Suggestions d'échanges à ajouter à ce bon :",
            SUGGESTION_EXCHANGES: "Suggestions d'échanges",
            FORBIDDEN_CHARACTER: "Un charactère n'est pas autorisé dans le formulaire.",
            NO_LOAN: 'Aucun prêts trouvés.',
            VALID: 'Valider',
            TRANSPORT: {
                TITLE: 'Bon de transport',
                EMERGENCY: {
                    TITLE: 'Urgence',
                    LESS_2HOURS: '< 2 heures',
                    LESS_6HOURS: '< 6 heures',
                    FROM_24HOURS_TO_48HOURS: '24 à 48h'
                },
                SERVICE: 'Service',
                NBR_PACKAGE: 'Nombre de colis',
                VOLUME: {
                    TITLE: 'Volume',
                    SMALL: 'peu encombrant',
                    LARGE: 'très encombrant'
                },
                WEIGHT: {
                    TITLE: 'Poid',
                    LIGHT: 'peu lourd',
                    HEAVY: 'très lourd'
                },
                COMMENT: 'Commentaire'
            }
        }
    }
};
