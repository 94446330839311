import { EDiscrepancy, EHospivillePermission, EMpStatus, EPosologyType, EStatus, EStatusIndicator } from "@mapuilabs/mpl-interfaces";
import * as angular from "angular";
import { Utils } from "../../../services/Utils";
export class HvMedicationReconciliationController {
    /** @ngInject */
    constructor(_, Api, Access, ToastService, DrugInstanceService, HospivilleService, $state, $scope, $q, ModalService, InformationService) {
        this._ = _;
        this.Api = Api;
        this.Access = Access;
        this.ToastService = ToastService;
        this.DrugInstanceService = DrugInstanceService;
        this.HospivilleService = HospivilleService;
        this.$state = $state;
        this.$scope = $scope;
        this.$q = $q;
        this.ModalService = ModalService;
        this.InformationService = InformationService;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * @type {EDiscrepancy}
         */
        this.EDiscrepancy = EDiscrepancy;
        /**
         * @type {EStatus}
         */
        this.EStatus = EStatus;
        /**
         * @type {EStatusIndicator}
         */
        this.EStatusIndicator = EStatusIndicator;
        /**
         * Add a new prescription [[IDrugBalance]]
         */
        this.newPrescription = () => {
            let prescription = {
                transit: this.medReconciliation.transit,
            };
            this.HospivilleService.addPrescription(prescription)
                .then((prescription) => {
                this.medReconciliation.prescription = prescription;
                this.save().then(() => {
                    this._calcConciliationFor();
                });
            });
        };
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.constTypes || !from || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[from], { value: value }).translate;
        };
        /**
         * Get short discrepancy translate from constTypes
         * @param value
         */
        this.getShortDiscrepancyTranslate = (value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes.discrepancy, { value: value }).translateShort;
        };
        /**
         * Get the translation of a given value
         * @param value
         * @returns {string}
         */
        this.getShortDosage = (value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes.dosages, { value: value }).translateShort;
        };
        /**
         * Edit a given [[IMResult]]
         * @param event
         * @param conciliation
         * @param index
         */
        this.editConciliation = (event, conciliation, index) => {
            // if (conciliation.status) {
            this.ModalService.show({
                component: 'hv-modal-conciliation',
                bindings: {
                    conciliation: conciliation,
                },
                ev: event,
                escapeToClose: true,
            }).then((res) => {
                this._isChange = true;
                this.medReconciliation.results.splice(index, 1, angular.copy(res));
            });
            // }
        };
        /**
         * Edit a given [[IDrugInstance]]
         * @param event
         * @param drugs
         * @param index
         */
        this.editDrugs = (event, drugs, index) => {
            this.ModalService.show({
                component: 'hv-modal-drug',
                bindings: {
                    drugs: drugs,
                },
                ev: event,
                escapeToClose: true,
            }).then((allDrugInst) => {
                const promises = [];
                this._isChange = true;
                for (let drugInst of allDrugInst) {
                    promises.push(this.HospivilleService.saveOrAdd(this.DrugInstanceService, drugInst));
                }
                this.$q.all(promises).then((instances) => {
                    this.medReconciliation.results[index].prescriptions = [...instances];
                    this.medReconciliation.results[index].status = this._calcStatus(this.medReconciliation.results[index]);
                    this.medReconciliation.results[index].hasDiscrepancy = !this.medReconciliation.results[index].status ? null : this.medReconciliation.results[index].status != EStatus.Maintain;
                    if (this.medReconciliation.results[index].hasDiscrepancy && this.medReconciliation.results[index].discrepancyType !== EDiscrepancy.MedicinalError) {
                        if (this.medReconciliation.results[index].discrepancyType === EDiscrepancy.Intentional && this.medReconciliation.results[index].comment)
                            this.medReconciliation.results[index].discrepancyType = EDiscrepancy.IntentionalDocumented;
                    }
                    this.medReconciliation.prescription.instancesGroupBy[index] = [...instances];
                    let newInst = [...instances];
                    for (const instance of this.medReconciliation.prescription.instances) {
                        if (instance.index != index) {
                            newInst.push(instance);
                        }
                    }
                    this.medReconciliation.prescription.instances = [...newInst];
                });
            });
        };
        this.checkResultsComment = () => {
            for (let r of this.medReconciliation.results) {
                if (r.discrepancyType === EDiscrepancy.IntentionalDocumented && (!r.comment || r.comment === ''))
                    return true;
            }
            return false;
        };
        /**
         * Trigger [[IDrugBalance]] import from external editor API
         */
        this.importPrecription = () => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'Importer une prescription',
                        text: 'L\'import d\'une prescription depuis un DPI va remplacer la prescription existante. Êtes-vous sûr de vouloir importer une prescription ?',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                let maxDbsIdx = this.medReconciliation.dbs.instances.filter((inst) => !!Utils.getId(inst)).length - 1;
                this.HospivilleService.importPrescriptionFromExternalActor(this.Access.hospital.finessCode, this.medReconciliation.epi, Utils.getId(this.medReconciliation), maxDbsIdx)
                    .then((prescription) => {
                    var _a;
                    if (!prescription || !prescription.instances.length) {
                        this.ToastService.show(EMpStatus.NoResult);
                        return;
                    }
                    (_a = this.medReconciliation) === null || _a === void 0 ? true : delete _a.prescription;
                    this.medReconciliation.prescription = prescription;
                    this.medReconciliation.preSorted = false;
                    this.save().then(() => {
                        this._init().then(() => {
                            this._isChange = true;
                            this.isSave = false;
                            //Yes it's totally dumb but it works !
                            this.save();
                        });
                    });
                }).catch((err) => {
                    console.log('ERROR', err);
                    this.ToastService.show(EMpStatus.RejectByServer);
                });
            }).catch((err) => {
                console.log(err);
                return err;
            });
        };
        /**
         * Check interfacing for current [[IMember]] for actions limitation
         */
        this.checkInterface = () => {
            return this.Access.isInterfaced();
        };
        /**
         * Check medRec initiator
         */
        this.checkInitiator = () => {
            return !!this.medReconciliation.initiator;
        };
        /**
         * Add a new Line to all [[IMedicationReconciliation]] [[IDrugBalance]] (prescription)
         */
        this.addLine = () => {
            const newIndex = this.HospivilleService.getMaxIndex(this.medReconciliation) + 1;
            this.medReconciliation.dbs.instances.push({
                validSource: {},
                drug: null,
                denomination: null,
                UCD13: null,
                codeATC: null,
                index: newIndex,
            });
            this.medReconciliation.prescription.instances.push({
                validSource: {},
                drug: null,
                denomination: null,
                UCD13: null,
                codeATC: null,
                index: newIndex,
            });
            this.medReconciliation.results.push({
                status: null,
                hasDiscrepancy: false,
                discrepancyType: null,
                decision: null,
                comment: null,
                drugBalances: [],
                prescriptions: [],
                index: newIndex,
                medicationReconciliation: { _id: this.medReconciliation._id },
            });
            this._drugInstanceGroupBy();
        };
        /**
         * Transfer a DBS [[IDrugInstance]] to a prescription [[IDrugInstance]]
         * @param index
         */
        this.transferDBSInstance = (index) => {
            let newInstances = [];
            const promises = [];
            for (let instance of this.medReconciliation.prescription.instances) {
                if (instance.index == +index) {
                    if (Utils.hasId(instance)) {
                        this.DrugInstanceService.delete(instance);
                    }
                }
                else {
                    newInstances.push(instance);
                }
            }
            this.medReconciliation.prescription.instancesGroupBy[index] = [];
            for (let dbsInst of this.medReconciliation.dbs.instancesGroupBy[index]) {
                let newDrugInstance = {
                    drugBalance: { _id: this.medReconciliation.prescription._id },
                    validSource: dbsInst.validSource,
                    index: +this.medReconciliation.dbs.instancesGroupBy[index].index,
                    denomination: dbsInst.denomination,
                    UCD13: dbsInst.UCD13,
                    codeATC: dbsInst.codeATC,
                    drug: dbsInst.drug,
                    DCI: dbsInst.DCI,
                };
                if (dbsInst.denomination) { //To check
                    promises.push(this.DrugInstanceService.add(newDrugInstance)
                        .then((newInst) => {
                        this.medReconciliation.prescription.instancesGroupBy[index].push(newInst);
                        newInstances.push(newInst);
                    }));
                }
                else {
                    this.medReconciliation.prescription.instancesGroupBy[index].push(newDrugInstance);
                    newInstances.push(newDrugInstance);
                }
            }
            this.$q.all(promises)
                .then(() => {
                this.medReconciliation.prescription.instances = [...newInstances];
                this._calcConciliationForIndex(index);
            });
        };
        /**
         * Get time from milliseconds to days hours minutes seconds
         * @param time
         * @returns {string}
         */
        this.getTimeDiff = (time) => {
            let x = time / 1000;
            const seconds = Math.floor(x % 60);
            x /= 60;
            const minutes = Math.floor(x % 60);
            x /= 60;
            const hours = Math.floor(x % 24);
            x /= 24;
            const days = Math.floor(x);
            return (days ? days + 'j ' : '') + (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm ' : '') + (seconds ? seconds + 's' : '');
        };
        /**
         * Check discrepancy change open conciliation if UID
         * @param {IMRResult} result
         * @param event
         * @param index
         */
        this.discrepancyTypeChange = (result, event, index) => {
            if (EDiscrepancy.MedicinalError == result.discrepancyType) {
                this.editConciliation(event, result, index);
            }
        };
        /**
         * Save all [[IMedicationReconciliation]]s
         */
        this.save = () => {
            if (angular.isFunction(this._stopMedRecWatch))
                this._stopMedRecWatch();
            const defer = this.$q.defer();
            this.isSave = true;
            if (!this.medReconciliation.time)
                this.medReconciliation.time = 0;
            this.medReconciliation.time += Math.abs((new Date()).getTime() - this._time.getTime());
            if (this.medReconciliation.statusIndicator !== EStatusIndicator.Validate
                || (this.medReconciliation.statusIndicator === EStatusIndicator.Validate && this.medReconciliation.validator)) {
                this.medReconciliation.update = {
                    date: new Date(),
                    user: { _id: Utils.getId(this.Access.user), fullName: this.Access.user.fullName },
                };
            }
            this.medReconciliation.preSorted = true;
            this.HospivilleService.saveMedRec(this.medReconciliation, true)
                .then((medRec) => {
                this.isSave = false;
                if (this._statusUpdated && this.Access.isInterfaced() && this.checkInitiator()) {
                    if (medRec.dbs.statusIndicator === this.EStatusIndicator.Validate
                        && medRec.statusIndicator === this.EStatusIndicator.Validate)
                        this.HospivilleService.exportMedRecStatus(Utils.getId(medRec), this.EStatusIndicator.Validate, 'update');
                    else if (medRec.dbs.statusIndicator !== this.EStatusIndicator.Validate
                        && medRec.statusIndicator === this.EStatusIndicator.Validate)
                        this.HospivilleService.exportMedRecStatus(Utils.getId(medRec), this.EStatusIndicator.Ongoing, 'update');
                    else
                        this.HospivilleService.exportMedRecStatus(Utils.getId(medRec), medRec.statusIndicator, 'update');
                }
                this.medReconciliation = medRec;
                this._drugInstanceGroupBy(true);
                this._initSortable();
                this.ToastService.show(EMpStatus.Ok);
                defer.resolve();
                this._startMedRecWatch();
                this._time = new Date();
            })
                .catch(() => {
                this.isSave = false;
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Change the status indicator of the medrec
         * @param status
         */
        this.changeStatusIndicator = (status) => {
            this._stopMedRecWatch();
            this.medReconciliation.statusIndicator = status;
            this._startMedRecWatch();
            this._statusUpdated = true;
        };
        /**
         * Init all data
         * @private
         */
        this._init = () => {
            const defer = this.$q.defer();
            this._isChange = false;
            this.ready = false;
            this.medReconciliation = null;
            if (angular.isFunction(this._stopMedRecWatch))
                this._stopMedRecWatch();
            this.Api.hvConstType.get((ans) => {
                this.constTypes = ans;
            });
            this.HospivilleService.getMedRec(true)
                .then((medRec) => {
                this.medReconciliation = medRec;
                this._drugInstanceGroupBy();
                if (this.medReconciliation.prescription) {
                    this._initSortable();
                    this._drugBalanceUpdate(true);
                }
                this._startMedRecWatch();
                this.ready = true;
                defer.resolve();
            });
            return defer.promise;
        };
        /**
         * Set instanceGroupBy object by index for prescriptions and drug balance sheet instances.
         * @private
         */
        this._drugInstanceGroupBy = (reinit = false) => {
            if (this.medReconciliation.prescription) {
                if (!this.medReconciliation.prescription.instancesGroupBy || reinit == true)
                    this.medReconciliation.prescription.instancesGroupBy = {};
                this.medReconciliation.prescription.instancesGroupBy = this._.extend(this.medReconciliation.prescription.instancesGroupBy, this._.groupBy(this.medReconciliation.prescription.instances, 'index'));
            }
            if (!this.medReconciliation.dbs.instancesGroupBy || reinit == true)
                this.medReconciliation.dbs.instancesGroupBy = {};
            this.medReconciliation.dbs.instancesGroupBy = this._.extend(this.medReconciliation.dbs.instancesGroupBy, this._.groupBy(this.medReconciliation.dbs.instances, 'index'));
        };
        // public orderDrugInstanceByAtc = (): void => {
        //     let temp = [];
        //     if (!this.medReconciliation.prescription || !this.medReconciliation.prescription.instancesGroupBy) {
        //         for (let idx in this.medReconciliation.dbs.instancesGroupBy) {
        //             temp.push({
        //                 dbs: this.medReconciliation.dbs.instancesGroupBy[idx],
        //                 result: this.medReconciliation.results[idx],
        //             });
        //         }
        //     } else {
        //         for (let idx in this.medReconciliation.prescription.instancesGroupBy) {
        //             temp.push({
        //                     prescription: this.medReconciliation.prescription.instancesGroupBy[idx],
        //                     dbs: this.medReconciliation.dbs.instancesGroupBy[idx],
        //                     result: this.medReconciliation.results[idx],
        //                 },
        //             );
        //         }
        //     }
        //     temp.sort((a, b) => {
        //         let atcA = a.dbs[0].codeATC;
        //         if (!atcA && a.prescription)
        //             atcA = a.prescription[0].codeATC;
        //
        //         let atcB = b.dbs[0].codeATC;
        //         if (!atcB && b.prescription)
        //             atcB = b.prescription[0].codeATC;
        //         if (!atcB)
        //             return -1;
        //         if (!atcA)
        //             return 1;
        //         if (atcA > atcB)
        //             return 1;
        //         return -1;
        //     });
        //     this.medReconciliation.dbs.instancesGroupBy = {};
        //     if (this.medReconciliation.prescription && this.medReconciliation.prescription.instancesGroupBy)
        //         this.medReconciliation.prescription.instancesGroupBy = {};
        //     let idx = 0;
        //     for (let item of temp) {
        //         if (item.dbs) {
        //             for (let elem of item.dbs) {
        //                 elem.index = idx;
        //                 let dbsTrueInstance = this._.find(this.medReconciliation.dbs.instances, (instance) => {
        //                     return instance == elem;
        //                 });
        //                 if (dbsTrueInstance)
        //                     dbsTrueInstance.index = idx;
        //             }
        //         }
        //         if (item.prescription) {
        //             // item.prescription.index = idx;
        //             for (let elem of item.prescription) {
        //                 elem.index = idx;
        //                 let prescriptionTrueInstance = this._.find(this.medReconciliation.prescription.instances, (instance) => {
        //                     return instance == elem;
        //                 });
        //
        //                 if (prescriptionTrueInstance)
        //                     prescriptionTrueInstance.index = idx;
        //             }
        //         }
        //         if (item.result) {
        //             item.result.index = idx;
        //         }
        //         this.medReconciliation.dbs.instancesGroupBy[idx] = item.dbs;
        //         if (this.medReconciliation.prescription && this.medReconciliation.prescription.instancesGroupBy)
        //             this.medReconciliation.prescription.instancesGroupBy[idx] = item.prescription;
        //         if (item.result)
        //             this.medReconciliation.results[idx] = item.result;
        //         idx++;
        //     }
        // };
        /**
         * Calculate all results of a given [[IMedicationReconciliation]]
         * @param isFirst
         * @param idx
         * @private
         */
        this._calcConciliationFor = (isFirst, idx) => {
            if (!isFirst) {
                this._isChange = true;
            }
            if (idx == undefined)
                for (let index in this.medReconciliation.prescription.instancesGroupBy) {
                    this._calcConciliationForIndex(index, isFirst);
                }
            else
                this._calcConciliationForIndex(idx, isFirst);
        };
        /**
         * Update DBS [[IDrugBalance]]
         * @param isFirst
         * @param idx
         * @param idx2
         * @private
         */
        this._drugBalanceUpdate = (isFirst, idx, idx2) => {
            for (let index in this.medReconciliation.dbs.instancesGroupBy) {
                if (this.medReconciliation.dbs.instancesGroupBy.hasOwnProperty(index)) {
                    for (let i = 0; i < this.medReconciliation.dbs.instancesGroupBy[index].length; ++i) {
                        if (this.medReconciliation.dbs.instancesGroupBy[index].length > 1 && !this.medReconciliation.dbs.instancesGroupBy[index][i].denomination) { //To check
                            this.medReconciliation.dbs.instancesGroupBy[index].splice(i, 1);
                        }
                        this.medReconciliation.dbs.instancesGroupBy[index][i].index = +index;
                    }
                    if (!this.medReconciliation.dbs.instancesGroupBy[index].length) {
                        this.medReconciliation.dbs.instancesGroupBy[index].push({
                            validSource: {},
                            drug: null,
                            denomination: null,
                            UCD13: null,
                            codeATC: null,
                            index: +index,
                            drugBalance: { _id: this.medReconciliation.dbs._id },
                        });
                    }
                }
            }
            if (idx == undefined && idx2 == undefined)
                this._calcConciliationFor(isFirst);
            else {
                this._calcConciliationFor(isFirst, idx);
                this._calcConciliationFor(isFirst, idx2);
            }
        };
        /**
         * Init sort list
         * @private
         */
        this._initSortable = () => {
            this.medReconciliation.prescription['sortOptions'] = {
                orderChanged: () => {
                },
                itemMoved: (type) => {
                    let idx = this._.findIndex(type.dest.sortableScope.modelValue, (elem) => {
                        return elem.index != type.source.itemScope.drugInstance.index;
                    });
                    this._calcConciliationFor(false, type.source.itemScope.drugInstance.index);
                    this._calcConciliationFor(false, type.dest.sortableScope.modelValue[idx].index);
                },
                accept: (sourceItemHandleScope, destSortableScope) => {
                    return sourceItemHandleScope.itemScope.sortableScope.$parent.$parent.$id === destSortableScope.$parent.$parent.$id;
                },
            };
            this.medReconciliation.dbs['sortOptions'] = {
                orderChanged: () => {
                },
                itemMoved: (types) => {
                    let idx = this._.findIndex(types.dest.sortableScope.modelValue, (item) => {
                        return item.index != types.source.itemScope.instance.index;
                    });
                    this._drugBalanceUpdate(false, types.source.itemScope.instance.index, types.dest.sortableScope.modelValue[idx].index);
                },
                accept: (sourceItemHandleScope, destSortableScope) => {
                    return sourceItemHandleScope.itemScope.sortableScope.$parent.$parent.$id === destSortableScope.$parent.$parent.$id;
                },
            };
        };
        /**
         * Calculate the status of the [[IMResult]]
         * @param result
         * @returns {EStatus}
         * @private
         */
        this._calcStatus = (result) => {
            let hasOneEquivalence = false;
            const dbsHasDrug = !!this._.find(result.drugBalances, (inst) => {
                return inst.denomination;
            });
            const prescriptionHasDrug = !!this._.find(result.prescriptions, (inst) => {
                return inst.denomination;
            });
            const noValidSource = !!this._.find(result.drugBalances, (inst) => {
                return !inst.validSource;
            }) || !!this._.find(result.prescriptions, (inst) => {
                return !inst.validSource;
            });
            if ((!dbsHasDrug && !prescriptionHasDrug) || noValidSource) {
                return null;
            }
            if (!dbsHasDrug && prescriptionHasDrug) {
                return EStatus.Initiate;
            }
            if (dbsHasDrug && !prescriptionHasDrug) {
                return EStatus.Stop;
            }
            if (result.drugBalances.length !== result.prescriptions.length) {
                return EStatus.Amend;
            }
            for (const instance of result.drugBalances) {
                if (!instance.denomination) {
                    continue;
                }
                let sameInst = this._.find(result.prescriptions, (inst) => {
                    if (!inst.denomination)
                        return false;
                    return inst.UCD13 === instance.UCD13;
                });
                let equivalence = this._.find(result.prescriptions, (inst) => {
                    return inst.codeATC && instance.codeATC ? inst.codeATC.substring(0, 5) === instance.codeATC.substring(0, 5) : false;
                });
                if (sameInst || equivalence) {
                    if (!this.DrugInstanceService.compareDosages((sameInst ? sameInst : equivalence).validSource, instance.validSource)) {
                        return EStatus.Amend;
                    }
                    if (!this.DrugInstanceService.comparePosologiesArray((sameInst ? sameInst : equivalence).validSource.posologies, instance.validSource.posologies)) {
                        return EStatus.Amend;
                    }
                    //
                    // if ((<IDrugInstance>(sameInst ? sameInst : equivalence)).validSource.posology.type !== instance.validSource.posology.type) {
                    //    return EStatus.Amend;
                    // }
                    // if (!angular.equals((<IDrugInstance>(sameInst ? sameInst : equivalence)).validSource.posology.specific, instance.validSource.posology.specific)) {
                    //    return EStatus.Amend;
                    // }
                    if (!sameInst && equivalence) {
                        hasOneEquivalence = true;
                    }
                }
                else {
                    return EStatus.Amend;
                }
            }
            if (hasOneEquivalence) {
                return EStatus.Equivalence;
            }
            return EStatus.Maintain;
        };
        /**
         * Start the watcher on med red
         * @private
         */
        this._startMedRecWatch = () => {
            this._stopMedRecWatch = this.$scope.$watch('vm.medReconciliation', (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    this._resetStatus();
                }
            }, true);
        };
        /**
         * Reset the status on the medrec
         * @private
         */
        this._resetStatus = () => {
            if (this.medReconciliation && this.medReconciliation.statusIndicator == EStatusIndicator.Validate) {
                this.medReconciliation.statusIndicator = EStatusIndicator.Ongoing;
                this.medReconciliation.validator = null;
            }
        };
        this.diService = this.DrugInstanceService;
        this._time = new Date();
        this.$scope.$on('refreshPatientFile', () => {
            this._init();
        });
        this._statusUpdated = false;
        this.isAuthorised = this.Access.authorize(EHospivillePermission.MedicationReconciliation_Validate);
        this.migrationState = this.Access.isHVMigrationState();
        if (!this.migrationState) {
            this.$scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
                if (!this.ready || !this._isChange) {
                    this.isSave = true;
                }
                if (!this.isSave) {
                    this.isSave = true;
                    event.preventDefault();
                    this.ModalService.show({
                        component: 'mp-modal-confirm',
                        bindings: {
                            data: {
                                title: 'Sauvegarder',
                                text: 'Voulez-vous enregister les modifications apportées ? ',
                            },
                        },
                        escapeToClose: false,
                        clickOutsideToClose: false
                    }).then(() => {
                        this.save().then(() => {
                            this.$scope.$destroy();
                            this.$state.go(toState, toParams);
                        }).catch((err) => {
                            this.ToastService.show(EMpStatus.RejectByServer);
                        });
                    }).catch(() => {
                        this.$state.go(toState);
                    });
                }
            });
        }
    }
    $onInit() {
        this._init();
    }
    /**
     * Calculate the result for the given index
     * @param index
     * @param isFirst
     * @private
     */
    _calcConciliationForIndex(index, isFirst) {
        if (this.medReconciliation.prescription.instancesGroupBy.hasOwnProperty(index)) {
            for (let i = 0; i < this.medReconciliation.prescription.instancesGroupBy[index].length; ++i) {
                if (this.medReconciliation.prescription.instancesGroupBy[index].length > 1 && !this.medReconciliation.prescription.instancesGroupBy[index][i].denomination) {
                    this.medReconciliation.prescription.instancesGroupBy[index].splice(i, 1);
                }
                this.medReconciliation.prescription.instancesGroupBy[index][i].index = +index;
            }
            if (!this.medReconciliation.prescription.instancesGroupBy[index].length) {
                this.medReconciliation.prescription.instancesGroupBy[index].push({
                    validSource: {},
                    drug: null,
                    denomination: null,
                    UCD13: null,
                    codeATC: null,
                    index: +index,
                    drugBalance: { _id: this.medReconciliation.prescription._id },
                });
            }
            if (this.medReconciliation.results[index]) {
                this.medReconciliation.results[index].prescriptions = [];
                this.medReconciliation.results[index].drugBalances = [];
            }
            if (this._.find(this.medReconciliation.prescription.instancesGroupBy[index], { validSource: null }) || this._.find(this.medReconciliation.dbs.instancesGroupBy[index], { validSource: null })) {
                this.medReconciliation.results[index].status = null;
            }
            else {
                this.medReconciliation.results[index].prescriptions = [...this.medReconciliation.prescription.instancesGroupBy[index]];
                this.medReconciliation.results[index].drugBalances = [...this.medReconciliation.dbs.instancesGroupBy[index]];
                if ((isFirst && !this.medReconciliation.results[index].status) || !isFirst) {
                    this.medReconciliation.results[index].status = this._calcStatus(this.medReconciliation.results[index]);
                }
                this.medReconciliation.results[index].hasDiscrepancy = !!this.medReconciliation.results[index].status;
                if (this.medReconciliation.results[index].hasDiscrepancy && this.medReconciliation.results[index].discrepancyType !== EDiscrepancy.MedicinalError) {
                    if (this.medReconciliation.results[index].discrepancyType === EDiscrepancy.Intentional && this.medReconciliation.results[index].comment)
                        this.medReconciliation.results[index].discrepancyType = EDiscrepancy.IntentionalDocumented;
                }
                if (this.medReconciliation.results[index].status === EStatus.Maintain || this.medReconciliation.results[index].status === EStatus.Equivalence) {
                    this.medReconciliation.results[index].discrepancyType = null;
                    this.medReconciliation.results[index].subDiscrepancyType = undefined;
                    this.medReconciliation.results[index].hasDiscrepancy = null;
                    this.medReconciliation.results[index].clinicalConsequence = undefined;
                    this.medReconciliation.results[index].decision = undefined;
                }
            }
        }
    }
}
