export class MpTimeComponent {
    constructor() {
        this.controller = 'MpTimeController';
        this.controllerAs = 'vm';
        this.template = require('./mp-time.html').default;
        this.bindings = {
            model: '=',
            disabled: '<',
            required: '<',
            name: '@',
            label: '@',
        };
    }
}
