import { EventEmitter } from '@angular/core';
export class FilterManager {
    constructor() {
        this.filterUpdated = new EventEmitter();
        this._filters = {};
    }
    updateFilter(name, filter) {
        this._filters[name] = filter;
        this._emit();
    }
    getFilter(name) {
        return this._filters[name];
    }
    removeFilter(name) {
        this._filters[name] = undefined;
        this._emit();
    }
    removeFilters() {
        this._filters = {};
        this._emit();
    }
    _emit() {
        this.filterUpdated.emit(this._filters);
    }
}
