var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { CrudFileService } from '../../../services/file/crud-file-service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
let ExFileViewerComponent = class ExFileViewerComponent {
    constructor(_crudFileService, _snackbarService) {
        this._crudFileService = _crudFileService;
        this._snackbarService = _snackbarService;
        /**
         * Should be filled with ids from IFile collection.
         * Be sure the files are correctly stored.
         */
        this._fileIds = [
            '5e5e5268d8f8a14b24dfec16',
            '5e6647976248e41f1cb8568a',
            '5e5e603b052ac14cf98a5639' // File with other extension
        ];
        this.files = [];
        this.pageHeaderConfig = {
            title: 'File Viewer Component',
            icon: 'extension '
        };
    }
    ngOnInit() {
        this._fileIds.forEach((fileId) => {
            this._crudFileService.getById(fileId).subscribe((file) => {
                this.files.push(file);
            }, (error) => {
                console.error(error);
                this._snackbarService.openError(error);
            });
        });
    }
};
ExFileViewerComponent = __decorate([
    Component({
        selector: 'ex-file-viewer',
        template: require('./ex-file-viewer.component.html').default,
        styles: [],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof CrudFileService !== "undefined" && CrudFileService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object])
], ExFileViewerComponent);
export { ExFileViewerComponent };
