export class HvModalMasterPatientComponent {
    constructor() {
        this.controller = 'HvModalMasterPatientController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-master-patient.html').default;
        this.bindings = {
            patient: '<',
        };
    }
    ;
}
