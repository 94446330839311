export class MpHospitalSearchComponent {
    constructor() {
        this.controller = 'MpHospitalSearchController';
        this.controllerAs = 'vm';
        this.template = require('./mp-hospital-search.html').default;
        this.bindings = {
            hospModel: '<',
            required: '<',
            label: '<',
            excluded: '<',
            type: '<',
            onSelected: '&',
            triggerClean: '<',
            resetClean: '&',
        };
    }
}
