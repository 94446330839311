var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { PosologyService } from '@services/hospiville/posology/posology.service';
import { DrugInstanceService } from '@services/hospiville/drug-instance/drug-instance.service';
import { ActivitiesInstancesService } from '@services/hospiville/activities/activities-instances.service';
import { TranslationService } from '@services/translation/translation.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { DialogService } from '@services/dialog/dialog.service';
import { ActivitySourceService } from '@services/hospiville/activities/activities-source.service';
let SourceInstancesListComponent = class SourceInstancesListComponent {
    constructor(_fuseTranslationLoaderService, _posologyService, _drugInstanceService, _activitiesInstancesService, _translationService, _dialogService, _activityService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._posologyService = _posologyService;
        this._drugInstanceService = _drugInstanceService;
        this._activitiesInstancesService = _activitiesInstancesService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._activityService = _activityService;
        this._snackBarService = _snackBarService;
        this.canManage = false;
        this.canDelete = false;
        this.showMention = false;
        this.onDeleteInstance = new EventEmitter();
        this.isDone = new EventEmitter();
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    getInstanceIsDispensaryPharmacy(instance) {
        return instance.info.isDispensaryPharmacy;
    }
    isAddedByHospital(instance) {
        return this.getInstanceIsDispensaryPharmacy(instance) == undefined;
    }
    isModifiedByHospital(instance) {
        return (this.getInstanceIsDispensaryPharmacy(instance) != undefined &&
            this.getInstanceIsDispensaryPharmacy(instance) == false);
    }
    getDosageSentence(simpleInstance) {
        return this._drugInstanceService.generateDosageSentence(simpleInstance.info);
    }
    getPosologySentence(simpleInstance) {
        return this._posologyService.generateArrayPosology(simpleInstance.info.posologies);
    }
    delete(id) {
        this.onDeleteInstance.emit(this.instances[id]);
    }
    markAsDone() {
        this.isDone.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], SourceInstancesListComponent.prototype, "canManage", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SourceInstancesListComponent.prototype, "canDelete", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SourceInstancesListComponent.prototype, "showMention", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], SourceInstancesListComponent.prototype, "instances", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_j = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _j : Object)
], SourceInstancesListComponent.prototype, "onDeleteInstance", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_k = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _k : Object)
], SourceInstancesListComponent.prototype, "isDone", void 0);
SourceInstancesListComponent = __decorate([
    Component({
        selector: 'hvx-sources-instances-list',
        template: require('./source-instances-list.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof PosologyService !== "undefined" && PosologyService) === "function" ? _b : Object, typeof (_c = typeof DrugInstanceService !== "undefined" && DrugInstanceService) === "function" ? _c : Object, typeof (_d = typeof ActivitiesInstancesService !== "undefined" && ActivitiesInstancesService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof DialogService !== "undefined" && DialogService) === "function" ? _f : Object, typeof (_g = typeof ActivitySourceService !== "undefined" && ActivitySourceService) === "function" ? _g : Object, typeof (_h = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _h : Object])
], SourceInstancesListComponent);
export { SourceInstancesListComponent };
