export const locale = {
    lang: 'fr',
    data: {
        TITLES: {
            SUCCESS: {
                STATUS: 'Réussies'
            },
            ERROR: {
                STATUS: 'Erreurs majeures'
            },
            WARNING: {
                STATUS: 'Erreurs mineurs'
            },
            INFO: {
                STATUS: 'Informations'
            }
        }
    }
};
