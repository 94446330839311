var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { EHospistockPermission, File } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
let HsFileService = class HsFileService extends CrudTemplateService {
    constructor(_injector) {
        super(_injector, File, '/api/hospistock/files', {
            create: [
                EHospistockPermission.LaboDrugPublication_Create,
                EHospistockPermission.LaboMedicalDevicePublication_Create,
                EHospistockPermission.PurchaseGroupDrugPublication_Create,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Create,
                EHospistockPermission.PharmacyDrugPublication_Create,
                EHospistockPermission.PharmacyMedicalDevicePublication_Create
            ],
            read: [
                EHospistockPermission.LaboDrugPublication_Read,
                EHospistockPermission.LaboMedicalDevicePublication_Read,
                EHospistockPermission.PurchaseGroupDrugPublication_Read,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Read,
                EHospistockPermission.PharmacyDrugPublication_Read,
                EHospistockPermission.PharmacyMedicalDevicePublication_Read
            ],
            update: [
                EHospistockPermission.LaboDrugPublication_Edit,
                EHospistockPermission.LaboMedicalDevicePublication_Edit,
                EHospistockPermission.PurchaseGroupDrugPublication_Edit,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Edit,
                EHospistockPermission.PharmacyDrugPublication_Edit,
                EHospistockPermission.PharmacyMedicalDevicePublication_Edit
            ],
            delete: [
                EHospistockPermission.LaboDrugPublication_Delete,
                EHospistockPermission.LaboMedicalDevicePublication_Delete,
                EHospistockPermission.PurchaseGroupDrugPublication_Delete,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Delete,
                EHospistockPermission.PharmacyDrugPublication_Delete,
                EHospistockPermission.PharmacyMedicalDevicePublication_Delete
            ]
        });
        this._injector = _injector;
    }
};
HsFileService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], HsFileService);
export { HsFileService };
