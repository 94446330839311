export class HvPublicPatientInterviewSurveyInfoController {
    /** @ngInject */
    constructor() {
        this.ready = true;
        this.InformationService = {
            noPatientOrConciliation: () => {
                return false;
            },
        };
    }
}
