import * as angular from 'angular';
/** @ngInject */
export function fuseConfigProvider() {
    // Default configuration
    var fuseConfiguration = {
        'disableCustomScrollbars': false,
        'disableMdInkRippleOnMobile': true,
        'disableCustomScrollbarsOnMobile': true,
    };
    // Methods
    this.config = config;
    //////////
    /**
     * Extend default configuration with the given one
     *
     * @param configuration
     */
    function config(configuration) {
        fuseConfiguration = angular.extend({}, fuseConfiguration, configuration);
    }
    /**
     * Service
     */
    this.$get = function () {
        var service = {
            getConfig: getConfig,
            setConfig: setConfig,
        };
        return service;
        //////////
        /**
         * Returns a config value
         */
        function getConfig(configName) {
            if (angular.isUndefined(fuseConfiguration[configName])) {
                return false;
            }
            return fuseConfiguration[configName];
        }
        /**
         * Creates or updates config object
         *
         * @param configName
         * @param configValue
         */
        function setConfig(configName, configValue) {
            fuseConfiguration[configName] = configValue;
        }
    };
}
