var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, HostBinding, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AvailableAndOffers, EAvailableStatus, EInterest } from '@mapuilabs/mpl-interfaces';
import { AvailableHospitalService } from '../../available-hospital.service';
let AvailableHospitalListItemComponent = class AvailableHospitalListItemComponent {
    constructor(_availableHospitalService) {
        this._availableHospitalService = _availableHospitalService;
    }
    get available() {
        return this.availableAndOffers.available;
    }
    get offers() {
        return this.availableAndOffers.offers;
    }
    get isClosed() {
        return this.available.status === EAvailableStatus.Closed;
    }
    get isArchived() {
        return this.available.status === EAvailableStatus.Archived;
    }
    get showFirstDCI() {
        var _a;
        return ((_a = this.available.drug.DCI) === null || _a === void 0 ? void 0 : _a.length) ? this.available.drug.DCI[0] : null;
    }
    get nbOfOffers() {
        const offersInterested = this.availableAndOffers.offers.filter((offer) => offer.interest === EInterest.Interested);
        return offersInterested === null || offersInterested === void 0 ? void 0 : offersInterested.length;
    }
    get discount() {
        if (this.available.unitPrice == null || this.available.drug.manufacturerPrice == null) {
            return undefined;
        }
        if (isNaN(this.available.unitPrice) || isNaN(this.available.drug.manufacturerPrice)) {
            return undefined;
        }
        return Number(((1 - this.available.unitPrice / this.available.drug.manufacturerPrice) * 100).toFixed(2));
    }
    get hasReachedEndDate() {
        return (this.available.status !== EAvailableStatus.Archived &&
            new Date(this.availableAndOffers.available.endDate).getTime() - Date.now() <= 0);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof AvailableAndOffers !== "undefined" && AvailableAndOffers) === "function" ? _b : Object)
], AvailableHospitalListItemComponent.prototype, "availableAndOffers", void 0);
__decorate([
    HostBinding('class.selected'),
    __metadata("design:type", Boolean)
], AvailableHospitalListItemComponent.prototype, "selected", void 0);
AvailableHospitalListItemComponent = __decorate([
    Component({
        selector: 'mpx-available-hospital-list-item',
        template: require('./available-hospital-list-item.component.html').default,
        styles: [require('./available-hospital-list-item.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AvailableHospitalService !== "undefined" && AvailableHospitalService) === "function" ? _a : Object])
], AvailableHospitalListItemComponent);
export { AvailableHospitalListItemComponent };
