var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsModalBookletFormComponent } from '@app/hospistock/shared/components/modals/booklet/form/hs-modal-booklet-form.component';
import { EHsHealthProductDirectoryTypes } from '@app/hospistock/shared/types/hs-health-product-directory.types';
import { CellProcesses, CellValidators } from '@app/services/spreadsheet-parser/spreadsheet-parser.const';
import { Utils } from '@main/services/Utils';
import { EHospistockPermission, EHsPharmacyMedicalDeviceBookletImportResultMessages, EHsPharmacyMedicalDevicePublicationsImport, HsBookletItemMedicalDeviceASchema } from '@mapuilabs/mpl-interfaces';
// -----------------------------------------------------------------------------------------------------
// @ HealtProductReferences Global Config
// -----------------------------------------------------------------------------------------------------
const translationBase = 'PUBLICATION.BOOKLET';
// -----------------------------------------------------------------------------------------------------
// @ HealtProductReferences Service Config
// -----------------------------------------------------------------------------------------------------
const endPoint = 'api/hospistock/pharmacy/medical-device/booklet';
const permissions = {
    create: EHospistockPermission.PharmacyMedicalDeviceBooklet_Create,
    read: EHospistockPermission.PharmacyMedicalDeviceBooklet_Read,
    update: EHospistockPermission.PharmacyMedicalDeviceBooklet_Edit,
    delete: EHospistockPermission.PharmacyMedicalDeviceBooklet_Delete
};
const spreadsheetClassBuilder = [
    {
        column: EHsPharmacyMedicalDevicePublicationsImport.MEDICAL_DEVICE_REF,
        path: ['healthProductRef', 'healthProducts', '0', 'ref']
    },
    {
        column: EHsPharmacyMedicalDevicePublicationsImport.MEDICAL_DEVICE_NAME,
        path: ['healthProductRef', 'healthProducts', '0', 'name']
    },
    {
        column: EHsPharmacyMedicalDevicePublicationsImport.CONSUMPTION,
        path: ['consumption']
    },
    {
        column: EHsPharmacyMedicalDevicePublicationsImport.STOCK,
        path: ['stock']
    }
];
export const hsPharmacyMedicalDeviceBookletServiceConfig = {
    classConstructor: HsBookletItemMedicalDeviceASchema,
    endPoint,
    permissions,
    readLatestImportDate: true,
    spreadsheetClassBuilder
};
// -----------------------------------------------------------------------------------------------------
// @ HealtProductDirectory Config
// -----------------------------------------------------------------------------------------------------
// Import Config
const spreadsheetParserConfig = {
    columns: [
        {
            label: EHsPharmacyMedicalDevicePublicationsImport.MEDICAL_DEVICE_NAME,
            processes: [CellProcesses.trim()],
            validators: []
        },
        {
            label: EHsPharmacyMedicalDevicePublicationsImport.MEDICAL_DEVICE_REF,
            processes: [
                CellProcesses.string(),
                CellProcesses.clear(),
                CellProcesses.removeFirstZeros(),
                CellProcesses.removeSpecialCharacters()
            ],
            validators: [CellValidators.required(), CellValidators.string()]
        },
        {
            label: EHsPharmacyMedicalDevicePublicationsImport.CONSUMPTION,
            processes: [CellProcesses.clear()],
            validators: [CellValidators.number()]
        },
        {
            label: EHsPharmacyMedicalDevicePublicationsImport.STOCK,
            processes: [CellProcesses.clear()],
            validators: [CellValidators.number()]
        },
        {
            label: EHsPharmacyMedicalDevicePublicationsImport.UDI,
            processes: [
                CellProcesses.string(),
                CellProcesses.clear(),
                CellProcesses.removeFirstZeros(),
                CellProcesses.removeSpecialCharacters()
            ],
            validators: []
        }
    ],
    options: {
        headerToLowerCase: true,
        abortOnMajorError: true
    }
};
const spreadsheetImportResultMessagesMap = new Map(Utils.enumToKeys(EHsPharmacyMedicalDeviceBookletImportResultMessages).map((key) => [
    key,
    translationBase + '.IMPORT.REPORT.MESSAGES.' + key
]));
const importConfig = {
    spreadsheetImportResultMessagesMap,
    spreadsheetParserConfig
};
// Page Header Config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant(translationBase + '.TITLES.MAIN'),
    searchBarPlaceholder: translationService.instant(translationBase + '.SEARCH_DM'),
    icon: 'import_contacts'
});
// Table Config
const tableConfig = (translationService) => {
    return {
        columns: [
            {
                label: translationService.instant(translationBase + '.TABLE.MEDICAL_DEVICE_NAME'),
                attribute: 'healthProductRef.healthProducts[0].name',
                sortAttribute: 'healthProductRef.healthProducts.name',
                sort: true,
                classes: ['min-w-192']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.MEDICAL_DEVICE_REF'),
                tooltip: translationService.instant('PUBLICATION.TOOLTIPS.MEDICAL_DEVICE_REF'),
                attribute: 'healthProductRef.healthProducts[0].ref',
                sortAttribute: 'healthProductRef.healthProducts.ref',
                sort: true,
                classes: ['min-w-80 max-w-25-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.CONSUMPTION'),
                attribute: 'consumption',
                sort: true,
                classes: ['w-96']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.STOCK'),
                attribute: 'stock',
                sort: true,
                classes: ['w-96']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.ACTIONS'),
                template: 'actionsTemplate',
                classes: ['w-96'],
                sort: false
            }
        ]
    };
};
export const hsPharmacyMedicalDeviceBookletConfig = (translationService, fuseTranslationLoaderService) => __awaiter(void 0, void 0, void 0, function* () {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        healthProductDirectoryType: EHsHealthProductDirectoryTypes.BOOKLET,
        import: importConfig,
        pageHeader: pageHeaderConfig(translationService),
        table: tableConfig(translationService),
        translationBase,
        modal: HsModalBookletFormComponent
    };
});
