var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { HsRoutingModule } from './hospistock/hs-routing.module';
const states = [
    {
        name: 'mapui.adm',
        url: '/adm'
    },
    // Hospistock routing is defined HsRoutingModule
    {
        name: 'mapui.pharmacyV2',
        url: '/pharmacyV2'
    },
    {
        name: 'mapui.homeV2',
        url: '/homeV2'
    },
    {
        name: 'mapui.exchangeV2',
        url: '/exchangeV2'
    },
    {
        name: 'mapui.setting',
        url: '/setting'
    },
    {
        name: 'mapui.common-v2',
        url: '/common'
    }
];
let AppRoutingModule = class AppRoutingModule {
};
AppRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forRoot({ states: states }), HsRoutingModule],
        exports: [UIRouterUpgradeModule]
    })
], AppRoutingModule);
export { AppRoutingModule };
