import * as angular from 'angular';
import { PreparationService } from './preparation.service';
import { MpModalPrepLabelsController } from './mp-modal-prep-labels/mp-modal-prep-labels.controller';
import { MpModalPrepLabelsComponent } from './mp-modal-prep-labels/mp-modal-prep-labels.component';
angular
    .module('mapui.services.preparation', [
    'mapui.services.modal',
])
    .service('PreparationService', PreparationService)
    .controller('MpModalPrepLabelsController', MpModalPrepLabelsController)
    .component('mpModalPrepStatus', new MpModalPrepLabelsComponent());
