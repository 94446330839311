import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvTreatmentAnalysisComponent } from './hv-treatment-analysis.component';
import { HvTreatmentAnalysisController } from './hv-treatment-analysis.controller';
import { HvModalPharmaInterventionComponent } from './hv-modal-pharma-intervention/hv-modal-pharma-intervention.component';
import { HvModalPharmaInterventionController } from './hv-modal-pharma-intervention/hv-modal-pharma-intervention.controller';
angular
    .module('hospiville.patient.treatment-analysis', [])
    .config(configTreatmentAnalysis)
    .component('hvTreatmentAnalysis', new HvTreatmentAnalysisComponent())
    .controller('HvTreatmentAnalysisController', HvTreatmentAnalysisController)
    .component('hvModalPharmaIntervention', new HvModalPharmaInterventionComponent())
    .controller('HvModalPharmaInterventionController', HvModalPharmaInterventionController);
/** @ngInject */
function configTreatmentAnalysis($stateProvider, msApiProvider) {
    $stateProvider.state('hospiville.treatment-analysis', {
        url: '/analyse-traitement',
        data: {
            access: EHospivillePermission.TreatmentAnalysis_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-treatment-analysis></hv-treatment-analysis>',
            },
        },
        resolve: {
            Data: function (msApi) {
                return msApi.resolve('treatment.data@get');
            },
        },
        bodyClass: 'hospiville treatment-analysis',
    });
    msApiProvider.register('treatment.data', ['app/main/hospiville/hv-patient/tmp-data/treatment.json']);
}
