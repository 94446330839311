import { EHistoryAction, EHistoryType } from '@mapuilabs/mpl-interfaces';
export class HistoryService {
    /** @ngInject */
    constructor(Api, Access, $q) {
        this.Api = Api;
        this.Access = Access;
        this.$q = $q;
        /**
         * User navigate
         * @param state
         * @param params
         */
        this.pushNavigation = (state, params) => {
            if (!this.Access.isLogged())
                return;
            this.Api.history.add({}, {
                type: EHistoryType.Navigation,
                action: EHistoryAction.Open,
                target: state.name,
                data: params,
            });
        };
    }
    /**
     * Get all history items
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {boolean} includeAdmins
     * @param type
     * @param action
     * @return {Q.Promise<Array<IHistory>>}
     */
    getAll(startDate, endDate, includeAdmins, type = undefined, action = undefined) {
        const defer = this.$q.defer();
        this.Api.history.get({
            start: startDate,
            end: endDate,
            includeAdmins: includeAdmins,
            type: type,
            action: action,
        }, res => defer.resolve(res), err => defer.reject(err));
        return defer.promise;
    }
}
