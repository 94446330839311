export const locale = {
    lang: 'fr',
    data: {
        NAVIGATION: {
            BILLING: {
                TITLE: 'Avis de paiement'
            }
        },
        BILLING: {
            BILLINGS: 'Avis de paiement',
            SETTLEMENTS: 'Liquidations',
            AWAITING: {
                TITLE: 'En attente',
                HEADERS: {
                    TYPE: 'Type',
                    MODIFIED: 'Date de modification',
                    NAME: 'Nom',
                    HOSPITAL: 'Hôpital',
                    STATUS: 'Statut',
                    QUANTITY: 'Quantité',
                    PRICE: 'Prix',
                    ACTION: 'Action'
                },
                CONFIRM: {
                    FORCE: {
                        TITLE: 'Demande de confirmation',
                        SETTLE_MESSAGE: "Êtes-vous sûr de vouloir effectuer la liquidation à la place de l'emprunteur ?",
                        BILL_MESSAGE: "Êtes-vous sûr de vouloir créer l'avis de paiement à la place du prêteur ?"
                    }
                },
                FORCE_SETTLEMENT: 'Forcer la liquidation',
                FORCE_BILLING: "Forcer l'avis de paiement"
            },
            BILLINGS_TABLE: {
                CREATED: 'Date de création',
                NAME: 'Nom',
                QUANTITY: 'Quantité'
            },
            NAME: 'Créé le {{date}} - {{name}}',
            ACTIONS: {
                BILL: 'Créer avis de paiement',
                SETTLE: 'Liquider',
                PREVIOUS: 'Précédent',
                NEXT: 'Suivant'
            },
            STEPS: {
                INFO: "Seuls les produits d'un même établissement peuvent être sélectionnés.",
                SELECT_LOANS: 'Sélection des produits',
                ACTIONS: 'Actions'
            },
            SUCCESS: {
                SUCCESS_SETTLEMENT: 'La liquidation a été effectuée',
                SUCCESS_BILLING: "L'avis de paiement a été créé"
            },
            INPUTS: {
                UNIT_PRICE: 'Prix unitaire',
                VTA: 'TVA',
                CHARGE: 'Frais',
                CHARGE_TYPE: 'Type de frais',
                BILL_NUMBER: 'Numéros de facture',
                BILL_COMMENT: "Commentaire d'avis de paiement",
                ERRORS: {
                    MANDATORY: 'Obligatoire',
                    REQUIRED: 'Requis'
                }
            },
            INVOICE: {
                INVOICE: 'FACTURE',
                DATE: 'DATE',
                PRODUCT: 'PRODUIT',
                QUANTITY: 'QUANTITÉ',
                UNIT_PRICE: 'PRIX UNITAIRE',
                TOTAL: 'TOTAL',
                SUBTOTAL: 'SOUS-TOTAL',
                VAT: 'TVA {{vat}} %',
                ONLY_VAT: 'TVA',
                CHARGE: 'FRAIS',
                OBJECT: 'Avis de paiement',
                SUB_OBJECT: 'Le service pharmacie de {{hospital}} a délivré des médicaments ou articles pharmaceutiques',
                ACTIONS: {
                    DOWNLOAD: 'Télécharger'
                }
            },
            NONE: "Aucun produit {{(type == 'BILL') ? 'pour avis de paiement' : 'à liquider'}}",
            NBR_PRODUCTS: 'Nombre de produits'
        }
    }
};
