export const locale = {
    lang: 'fr',
    data: {
        PATIENTS: {
            TITLE: 'Tableau de bord patients',
            OLD_LIST: `Ancienne liste de patients`,
            SEARCH: 'Rechercher un patient (nom, IPP, ...)',
            STATUS: {
                NOT_REALIZED: 'Non réalisé'
            },
            ARCHIVED: 'Patients archivés',
            DOC_HV: 'Doc HospiVille',
            DOC_HV_ACTIVITIES: 'Doc Échanges v-h',
            MODAL: {
                DELETE: {
                    TITLE: 'Supprimer le patient',
                    MESSAGE: 'Etes-vous sur de vouloir supprimer ce patient ?'
                }
            },
            TOOLTIPS: {
                FILTER_SIDEBAR: 'Afficher les filtres'
            },
            TOASTS: {
                KO: 'Une erreur est survenue.',
                DELETE_PATIENT: {
                    OK: 'Patient supprimé.'
                }
            },
            TABLE: {
                HEADERS: {
                    PATIENT: 'Patient',
                    PPI: 'IPP',
                    SERVICE: 'Service',
                    ADMISSION: 'Admission (dernière en date)',
                    EXIT: 'Sortie (dernière en date)'
                },
                MENU: {
                    SEE_MORE: 'Détails',
                    DELETE: 'Supprimer'
                },
                TITLES: {
                    PATIENT_INFOS: 'Informations patient',
                    ENTRANCE_MEDREC: "Conciliation d'admission",
                    EXIT_MEDREC: 'Conciliation de sortie',
                    REFERENCE: {
                        TITLE: `Pharmacie(s) de référence`,
                        NO_REF: `Aucune pharmacie de référence n'a été renseignée pour ce patient.`
                    }
                },
                LABELS: {
                    CONSENT: 'Consentement :',
                    SOCIAL_NUMBER: 'NIR :',
                    TRANSFER: 'Transféré par :',
                    PATHWAYS: 'Parcours :',
                    EPI: 'IEP :',
                    PROCESS: 'Processus :',
                    DBS: 'BMO :',
                    CTM: 'CTM :',
                    PATIENT_FILE: 'Fiche patient'
                }
            }
        }
    }
};
