import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class MpSelectStaffMemberController {
    /** @ngInject */
    constructor(Access) {
        this.Access = Access;
        /**
         * Called when the select input is closed
         */
        this.close = () => {
            if (this.onClose) {
                this.onClose({
                    $event: {
                        model: this.model,
                    },
                });
            }
        };
        /**
         * Called when the select input is opened
         */
        this.open = () => {
            if (this.onOpen) {
                this.onOpen();
            }
        };
        /**
         * Called output function. May close the modal
         */
        this.viewChange = () => {
            if (this.onViewChange) {
                this.onViewChange();
            }
        };
    }
    $onInit() {
        this.model = angular.copy(this.model);
        this.from = angular.copy(this.from);
        this.all = angular.copy(this.all);
        if (Utils.hasId(this.Access.user) && !this.model && !this.disabled) {
            this.model = this.Access.user._id;
            this.close();
        }
        if (this.required === undefined || this.required === null) {
            this.required = true;
        }
    }
    $onChanges(changes) {
        if (changes.model) {
            this.model = angular.copy(this.model);
        }
        if (this.disabled) {
            if (this.all) {
                this.staff = this.all;
            }
            else {
                this.staff = this.from;
            }
        }
        else {
            this.staff = this.from;
        }
    }
}
