export const locale = {
    lang: 'fr',
    data: {
        ERRORS: {
            IMPORT_ERROR: "Erreur lors de l'importation du fichier",
            PARSING_ERROR: 'Erreur lors du traitement du fichier'
        },
        MESSAGES: {
            PARSING: {
                STATUS: {
                    DONE: 'Traitement du fichier terminé avec succès !',
                    EMPTY: 'Aucun élément à importer.',
                    FAILED: 'Echec du traitement du fichier !',
                    IN_PROGRESS: 'Traitement du fichier en cours'
                },
                REPORT: {
                    ERROR: {
                        STATUS: 'Erreurs majeures',
                        INSTRUCTIONS: "Veuillez corriger les erreurs listées ci-dessous avant de réessayer l'importation."
                    },
                    WARNING: {
                        STATUS: 'Erreurs non corrigées',
                        INSTRUCTIONS: 'Les lignes comportant les erreurs listées ci-dessous ne seront pas importées.'
                    },
                    INFO: {
                        STATUS: 'Erreurs corrigées',
                        INSTRUCTIONS: 'Vous pouvez corriger les erreurs listées ci-dessous pour améliorer la stabilité du fichier.'
                    },
                    TEMPLATE: {
                        COLUMN: 'Colonne',
                        ERROR_MESSAGE: 'Erreur',
                        ROW: 'Ligne',
                        VALUE: 'Valeur'
                    }
                }
            },
            IMPORT: {
                STATUS: {
                    DONE: 'Importation terminée avec succès !',
                    EMPTY: 'Aucun élément importé.',
                    FAILED: "Échec de l'importation !",
                    IN_PROGRESS: 'Importation en cours...',
                    WARNING: 'Importation partiellement effectuée'
                },
                REPORT: {
                    ERROR: {
                        STATUS: 'Erreurs majeures',
                        INSTRUCTIONS: "L'importation a échouée."
                    },
                    WARNING: {
                        STATUS: 'Élements non importés.',
                        INSTRUCTIONS: "Certains éléments n'ont pas pu être importés."
                    },
                    INFO: {
                        STATUS: 'Informations',
                        INSTRUCTIONS: 'Certains éléments ont été importés avec des informations complémentaires.'
                    },
                    SUCCESS: {
                        STATUS: 'Importations réussies'
                    }
                }
            },
            SELECTION: {
                STATUS: {
                    TITLE: 'Sélectionner un fichier à importer'
                }
            }
        },
        TITLES: {
            MAIN: 'Importation',
            PARSING: {
                STATUS: 'Traitement du fichier',
                REPORT: 'Rapport de traitement'
            },
            IMPORT: {
                STATUS: 'Importation du fichier',
                REPORT: "Rapport d'importation"
            },
            SELECTION: 'Sélectionner un fichier à importer'
        }
    }
};
