var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { PageMenuSidebarComponent } from './page-menu-sidebar.component';
import { PageMenuSidebarItemComponent } from './page-menu-sidebar-item/page-menu-sidebar-item.component';
import { MatRippleModule } from '@angular/material/core';
let PageMenuSidebarModule = class PageMenuSidebarModule {
};
PageMenuSidebarModule = __decorate([
    NgModule({
        imports: [CommonModule, FuseSharedModule, MatRippleModule],
        declarations: [PageMenuSidebarComponent, PageMenuSidebarItemComponent],
        exports: [PageMenuSidebarComponent, PageMenuSidebarItemComponent]
    })
], PageMenuSidebarModule);
export { PageMenuSidebarModule };
