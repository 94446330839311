var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { DOCUMENT_CLICK } from '@core/tokens/events/document-click.token';
import { Utils } from '@main/services/Utils';
import { EHsUserTypes, HsPublicationASchema } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
let HsPublicationDetailCommentsComponent = class HsPublicationDetailCommentsComponent {
    constructor(documentClick$, _changeDetectorRef, _dialogService, _snackbarService, _publicationContainerService, _translationService) {
        this.documentClick$ = documentClick$;
        this._changeDetectorRef = _changeDetectorRef;
        this._dialogService = _dialogService;
        this._snackbarService = _snackbarService;
        this._publicationContainerService = _publicationContainerService;
        this._translationService = _translationService;
        // Init Subjects
        this._unsubscribeAll = new Subject();
        // Init constants
        this.readonly = this._publicationContainerService.readonly;
        this.isGAViewType = this._publicationContainerService.viewType === EHsUserTypes.GA;
        this.shareComment = true;
        // Init FormControl
        this.setFormControls();
    }
    setFormControls() {
        this.form = new UntypedFormGroup({});
        if (this.isGAViewType) {
            this.form.setControl('shareComment', new UntypedFormControl());
            this.form.setValue({ shareComment: this.shareComment });
            this.form.setControl('comment', new UntypedFormControl('', Validators.required));
        }
        else {
            this.form.setControl('comment', new UntypedFormControl('', Validators.required));
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Update comments
        this._retrieveComments();
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open edit form by setting editMode to true to trigger form.
     * Method must wait next document click event before showing edit form.
     * Reason: Click event from document is fired with delay and trigger clickOutsideDirective's clickOutside event causing the form to be closed.
     * Recommendation : Do not use preventDefault instead otherwise form will not be closed when click event is fired on other edit menu button
     */
    openEditMode() {
        // Wait next click event
        this.documentClick$.pipe(take(1)).subscribe(() => {
            // Open edit mode
            this.editMode = true;
            // Manually update
            this._changeDetectorRef.detectChanges();
            // Scroll to edit form
            this._publicationContainerService.scrollbarDetailPanel.scrollToElement(this.editFormRef);
            this.setFormControls();
        });
    }
    /**
     * Close edit form by setting editMode to false to hide form.
     */
    closeEditMode() {
        // Close edit mode
        this.editMode = false;
        // Reset formControl
        this.form.reset();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    addComment() {
        return __awaiter(this, void 0, void 0, function* () {
            const value = this.form.value.comment;
            if (value.trim().length > 0) {
                try {
                    // Create comment
                    if (this.isGAViewType) {
                        this.shareComment = this.form.value.shareComment;
                        yield lastValueFrom(this._publicationContainerService.createComment(Utils.getId(this.publication), value, this.shareComment));
                    }
                    else {
                        yield lastValueFrom(this._publicationContainerService.createComment(Utils.getId(this.publication), value));
                    }
                    // Close form
                    this.closeEditMode();
                    // Update comments
                    yield this._retrieveComments();
                    // Refresh metadata if first comment added to trigger comment icon indicator in list-items update
                    if (this.comments.length === 1) {
                        this._publicationContainerService.refreshDynamicMetadata(Utils.getId(this.publication));
                    }
                    // Reset formControl
                    this.form.reset();
                    // Notify user
                    const message = this._translationService.instant('PUBLICATION.NOTIFS.ADD_COMMENT');
                    this._snackbarService.open(message);
                }
                catch (err) {
                    this._errorHandler(err);
                }
            }
        });
    }
    onEditComment(comment) {
        return __awaiter(this, void 0, void 0, function* () {
            if (comment.text.trim().length > 0) {
                try {
                    // Update comment
                    yield lastValueFrom(this._publicationContainerService.updateComment(Utils.getId(comment), comment));
                    // Update comments
                    yield this._retrieveComments();
                    // Notify user
                    const message = this._translationService.instant('PUBLICATION.NOTIFS.EDIT_COMMENT');
                    this._snackbarService.open(message);
                }
                catch (err) {
                    this._errorHandler(err);
                }
            }
        });
    }
    onDeleteComment(comment) {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('PUBLICATION.MESSAGES.COMMENT.DELETE.TITLE'),
            message: this._translationService.instant('PUBLICATION.MESSAGES.COMMENT.DELETE.CONTENT'),
            confirmButton: this._translationService.instant('COMMONS.DELETE')
        })
            // Delete only in confirmation
            .pipe(filter((confirmation) => confirmation))
            .subscribe(() => __awaiter(this, void 0, void 0, function* () {
            try {
                // Delete comment
                yield lastValueFrom(this._publicationContainerService.deleteComment(Utils.getId(comment)));
                // Update comments
                yield this._retrieveComments();
                // Refresh metadata if last comment removed to trigger comment icon indicator in list-items update
                if (this.comments.length === 0) {
                    this._publicationContainerService.refreshDynamicMetadata(Utils.getId(this.publication));
                }
                // Notify user
                const message = this._translationService.instant('PUBLICATION.NOTIFS.DELETE_COMMENT');
                this._snackbarService.open(message);
            }
            catch (err) {
                this._errorHandler(err);
            }
        }));
    }
    /**
     * Update comments from server
     */
    _retrieveComments() {
        return __awaiter(this, void 0, void 0, function* () {
            // Request comments based on publication Id
            this.comments = yield lastValueFrom(this._publicationContainerService.readComments(Utils.getId(this.publication)));
            // Manually trigger rendering if view not destroyed.
            // View can be destroyed if method called after a server request.
            // In this case view can be destroyed if currentPublication changed before read comments response.
            if (!this._changeDetectorRef['destroyed']) {
                this._changeDetectorRef.detectChanges();
            }
        });
    }
    _errorHandler(err) {
        console.error(err);
        this._snackbarService.openError(err);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof HsPublicationASchema !== "undefined" && HsPublicationASchema) === "function" ? _g : Object)
], HsPublicationDetailCommentsComponent.prototype, "publication", void 0);
__decorate([
    ViewChild('editForm', { static: false }),
    __metadata("design:type", typeof (_h = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _h : Object)
], HsPublicationDetailCommentsComponent.prototype, "editFormRef", void 0);
HsPublicationDetailCommentsComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-comments',
        template: require('./hs-publication-detail-comments.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(DOCUMENT_CLICK)),
    __metadata("design:paramtypes", [typeof (_a = typeof Observable !== "undefined" && Observable) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object])
], HsPublicationDetailCommentsComponent);
export { HsPublicationDetailCommentsComponent };
