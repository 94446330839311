export const locale = {
    lang: 'en',
    data: {
        SINCE_DATE_MENU: {
            START_MONTH: 'Since current month',
            START_YEAR: 'Since current year',
            CREATION: 'Since creation'
        }
    }
};
