import { EHsPublicationType } from '@mapuilabs/mpl-interfaces';
export const CHsPublicationType = {
    stockout: {
        name: 'stockout',
        value: EHsPublicationType.Stockout,
        translate: 'PHARMACY.PUBLICATION.TYPES.STOCKOUT'
    },
    quota: {
        name: 'quota',
        value: EHsPublicationType.Quota,
        translate: 'PHARMACY.PUBLICATION.TYPES.QUOTA'
    },
    pressure: {
        name: 'pressure',
        value: EHsPublicationType.Pressure,
        translate: 'PHARMACY.PUBLICATION.TYPES.PRESSURE'
    },
    comEnded: {
        name: 'comEnded',
        value: EHsPublicationType.ComEnded,
        translate: 'PHARMACY.PUBLICATION.TYPES.COM_ENDED'
    },
    shortExpiration: {
        name: 'shortExpiration',
        value: EHsPublicationType.ShortExpiration,
        translate: 'PHARMACY.PUBLICATION.TYPES.SHORT_EXPIRATION'
    },
    wholesalerOnly: {
        name: 'wholesalerOnly',
        value: EHsPublicationType.WholesalerOnly,
        translate: 'PHARMACY.PUBLICATION.TYPES.WHOLESALER_ONLY'
    },
    transferLabo: {
        name: 'transferLabo',
        value: EHsPublicationType.TransferLabo,
        translate: 'PHARMACY.PUBLICATION.TYPES.TRANSFER_LABO'
    }
};
