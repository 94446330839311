import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { PermissionService } from '@services/permission/permission.service';
export class CrudReadonly {
    constructor(_injector, _ctor, _endPoint, _permissions) {
        this._injector = _injector;
        this._ctor = _ctor;
        this._endPoint = _endPoint;
        this._permissions = _permissions;
        /**
         *
         * @param pageOptions
         * @param search
         * @param sort
         * @param filters
         * @param otherParams
         */
        this.getPage = (pageOptions, search, sort, filters, otherParams) => {
            if (!this._permissionService.authorize(this._permissions.read)) {
                return throwError(() => EHTTPStatus.Unauthorized);
            }
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (filters) {
                params = params.append('filters', JSON.stringify(filters));
            }
            if (sort) {
                params = params.append('sorting', JSON.stringify(sort));
            }
            if (!pageOptions) {
                //if (pageOptions)
                pageOptions = { page: 1, limit: 10 };
            } //todo: Remove when admin v1 is not used anymore
            params = params.append('pageOptions', JSON.stringify(pageOptions));
            params = this.addOtherParams(params, otherParams);
            return this._http.get(this._endPoint, { params: params });
        };
        this.addOtherParams = (params, otherParams) => {
            if (otherParams) {
                for (const param of otherParams) {
                    if (!param.param) {
                        console.error(`Parameter not found.`);
                    }
                    params = params.append(param.param, param.value);
                }
            }
            return params;
        };
        this._http = _injector.get(HttpClient);
        this._permissionService = _injector.get(PermissionService);
    }
    getById(id) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${id}`;
        return this._http.get(fullEndPoint).pipe(map((data) => new this._ctor(data)));
    }
    getAll() {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        return this._http.get(this._endPoint);
    }
}
