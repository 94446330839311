import * as angular from 'angular';
import { IsAdministratorDirective } from './directives/is-administrator.directive';
import { MpModalUploadHospitalsFileComponent } from './mp-modal-upload-hospitals-file/mp-modal-upload-hospitals-file.component';
import { MpModalUploadHospitalsFileController } from './mp-modal-upload-hospitals-file/mp-modal-upload-hospitals-file.controller';
import { AdminService } from './admin.service';
angular
    .module('mapui.services.admin', [
    'mapui.services.modal',
])
    .directive('isAdministrator', IsAdministratorDirective.factory())
    .controller('MpModalUploadHospitalsFileController', MpModalUploadHospitalsFileController)
    .component('mpModalUploadHospitalsFile', new MpModalUploadHospitalsFileComponent())
    .service('AdminService', AdminService);
