export class HvPatientSourceComponent {
    constructor() {
        this.controller = 'HvPatientSourceController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-source.html').default;
        this.bindings = {
            onChange: '&',
            onDelete: '&',
            sources: '<',
            type: '<',
            form: '<',
            name: '<',
            patient: '<',
            id: '@',
        };
    }
}
