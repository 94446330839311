import { EHospivillePermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class PatientService {
    /* /!**
      * The current patient files without consent filter
      *!/
     private _currentPatientFiles: Array<IFile>;*/
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get a list [[IPatient]] matching a query
         * @param query The query
         * @returns A promise containing an array of [[IPatient]]
         */
        this.getByName = (query) => {
            const defer = this.$q.defer();
            if (!query) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patients.find({ name: query }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get a [[IPatient]] matching a juridic finess code from establishment and a lppi
         * @param finessCode The hospital finess
         * @param lppi The lppi
         * @returns A promise containing a [[IPatient]]
         */
        this.getByFinessAndLppi = (finessCode, lppi) => {
            const defer = this.$q.defer();
            if (!finessCode || !lppi) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patientLppi.get({ finessCode: finessCode, lppi: lppi }, (res) => defer.resolve(this._fetchPatient(res)), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get a specific [[IPatient]]
         * @param id The [[IPatient]] [[Id]]
         * @param noFetch
         * @param oldPatientInfo
         * @returns A promise containing the [[IPatient]]
         */
        this.get = (id, noFetch = false, oldPatientInfo = false) => {
            const defer = this.$q.defer();
            if (!id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patients.get({ id: id, oldInfo: oldPatientInfo }, (res) => {
                    if (noFetch)
                        defer.resolve(res);
                    else
                        defer.resolve(this._fetchPatient(res));
                }, (err) => {
                    if (err.status === 498) {
                        return defer.reject(EMpStatus.RejectByServer);
                    }
                    if (!noFetch)
                        this.Access.changePatient(null, null);
                    defer.reject(EMpStatus.RejectByServer);
                });
            }
            return defer.promise;
        };
        /**
         * Get an array of all the [[IPatient]]
         * @returns A promise containing an array of [[IPatient]]
         */
        this.getAll = () => {
            const defer = this.$q.defer();
            let medicationReconciliations = [];
            if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patients.all({}, (res) => {
                    /*for (let i = 0; i < res.length; ++i) {
                        medicationReconciliations[res[i]._id] = res[i].medicationReconciliations;
                        res[i].medicationReconciliations = undefined;
                    }*/
                    defer.resolve(res);
                }, () => {
                    defer.reject(EMpStatus.RejectByServer);
                });
            }
            return defer.promise;
        };
        /**
         * Add a new [[IPatient]] to the dateBase
         * @param patient The [[IPatient]] to add
         * @returns A promise containing the newly created [[IPatient]]
         */
        this.add = (patient) => {
            const defer = this.$q.defer();
            if (!patient) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patients.add({}, patient, (res) => defer.resolve(this._fetchPatient(res)), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update the given [[IPatient]]
         * @param patient The [[IPatient]] to update
         * @returns A promise with the newly updated [[IPatient]]
         */
        this.save = (patient) => {
            const defer = this.$q.defer();
            if (!patient) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patients.save(patient, (res) => defer.resolve(this._fetchPatient(res)), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Set as deleted the given [[IPatient]]
         * @param patient The [[IPatient]] to delete
         * @returns A promise containing the server response
         */
        this.delete = (patient) => {
            const defer = this.$q.defer();
            if (!patient) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Delete)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patients.delete({}, patient, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        this.setMasterPatient = (patient) => {
            const defer = this.$q.defer();
            if (!patient) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.Patient_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.masterPatients.add({}, patient, (ans) => {
                    defer.resolve(ans);
                }, () => {
                    defer.reject(EMpStatus.RejectByServer);
                });
            }
            return defer.promise;
        };
        this.postPatientShareRequest = (request) => {
            const defer = this.$q.defer();
            if (!request)
                defer.reject(EMpStatus.NoDataProvided);
            else if (!this.Access.authorize(EHospivillePermission.SharePatient_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patientShare.post({}, request, (res) => {
                    defer.resolve(res);
                }, (err) => {
                    defer.reject(err);
                });
            }
            return defer.promise;
        };
        /**
         * The a patientInfo from it's Id.
         * THE METHOD WILL RETURN A IPatientInfo and NOT A IPatient !
         * @param patientInfoId
         */
        this.getPatientInfo = (patientInfoId) => {
            const defer = this.$q.defer();
            if (!patientInfoId)
                defer.reject(EMpStatus.NoDataProvided);
            else if (!this.Access.authorize(EHospivillePermission.Patient_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.patientInfo.get({ id: patientInfoId }, (ans) => {
                    defer.resolve(ans);
                }, (err) => {
                    defer.reject(err);
                });
            }
            return defer.promise;
        };
        /**
         * Fetch [[IPatient]]
         * @param patient
         * @returns {IPatient}
         * @private
         */
        this._fetchPatient = (patient) => {
            patient.allergies = patient.allergies || [];
            patient.has = {
                allergies: !!patient.allergies && !!patient.allergies.length,
                antibiotic: !!patient.antibiotic && (!!patient.antibiotic.date || !!patient.antibiotic.drug)
            };
            patient.complianceTest = patient.complianceTest || [];
            return patient;
        };
    }
    transferPatient(patient, hospital) {
        const defer = this.$q.defer();
        if (!Utils.getId(patient) || !Utils.getId(hospital))
            defer.reject(EMpStatus.NoDataProvided);
        else {
            this.Api.transferPatient.post({ pid: Utils.getId(patient) }, { hid: Utils.getId(hospital) }, (ans) => {
                defer.resolve(ans);
            }, (err) => {
                defer.reject(err);
            });
        }
        return defer.promise;
    }
    getLastPatientTransfer(transferPatients) {
        if (!transferPatients) {
            return null;
        }
        transferPatients.sort((a, b) => {
            const aTime = new Date(a.date).getTime();
            const bTime = new Date(b.date).getTime();
            if (aTime > bTime) {
                return -1;
            }
            if (aTime < bTime) {
                return 1;
            }
            return 0;
        });
        return transferPatients[0];
    }
}
