export const locale = {
    lang: 'fr',
    data: {
        listMembers: {
            TITLE: 'Utilisateurs',
            SEARCH_MEMBER: 'Rechercher un utilisateur',
            TABLE: {
                NAME: 'Nom',
                EMAIL: 'Email',
                HOSPITAL: 'Hôpital',
                ROLES: 'Rôles',
                LAST_CONNECT: 'Dernière connexion'
            },
            DELETED: "L'utilisateur a été supprimé !",
            CONFIRM_MESSAGE: 'Voulez-vous vraiment supprimer cet utilisateur ?'
        }
    }
};
