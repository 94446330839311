export const locale = {
    lang: 'en',
    data: {
        PANEL_TITLES: {
            VISIBLE_FILES: `Sent files`,
            HIDDEN_FILES: `Masked files`
        },
        TOOLTIPS: {
            MAIL: 'Mail attachment',
            IMPORT: 'Imported files',
            COMMENT: 'Comment associated to file'
        },
        PLACEHOLDERS: {
            COMMENT: `Comment`
        },
        BUTTONS: {
            IMPORT: `Import a file`,
            DELETE: `Delete this file`,
            ADD_COMMENT: `Add comment`,
            EDIT_COMMENT: 'Edit comment',
            VALID: `Valid`
        },
        SNACKBAR: {
            DELETE: `The file has been removed.`,
            NO_MORE_FILES: `The files have been deleted, the activity is back to the 'Waiting' status.`,
            COMMENT_ADDED: `Comment added to file.`
        },
        CONFIRM_DIALOG: {
            DELETE_TITLE: `Confirm the deletion of the selected file`,
            DELETE_MESSAGE: `Are you sure you want to delete this file?`
        }
    }
};
