/**
 * Cancel all drop (like dropping a file and loading it in page) on document.
 * This is very useful when implementing file D&D and wanting it to be drop on a zone only
 */
export class MpNoDrop {
    /** @ngInject */
    constructor($document) {
        this.$document = $document;
        this.link = (scope, elem, attrs) => {
            this.$document.on('dragover', (e) => {
                e = e || event;
                e.preventDefault();
            });
            this.$document.on('drop', (e) => {
                e = e || event;
                e.preventDefault();
            });
            scope.$on('$destroy', () => {
                this.$document.off('dragover');
                this.$document.off('drop');
            });
        };
        this.restrict = 'E';
    }
    static factory() {
        const directive = ($document) => new MpNoDrop($document);
        directive.$inject = ['$document'];
        return directive;
    }
}
