/** Node for tree item */
export class HsPublicationBroadcastTreeItemNode {
    constructor(node) {
        this.label = node === null || node === void 0 ? void 0 : node.label;
        if ((node === null || node === void 0 ? void 0 : node.selected) !== undefined) {
            this.selected = node === null || node === void 0 ? void 0 : node.selected;
        }
        this.value = node === null || node === void 0 ? void 0 : node.value;
    }
}
/** Nested  tree item node with children */
export class HsPublicationBroadcastTreeNestedItemNode extends HsPublicationBroadcastTreeItemNode {
    constructor(node) {
        super(node);
        if (node instanceof HsPublicationBroadcastTreeNestedItemNode) {
            if (node.children) {
                this.children = node.children;
            }
        }
    }
}
/** Flat tree item node with expandable and level information */
export class HsPublicationBroadcastTreeItemFlatNode extends HsPublicationBroadcastTreeItemNode {
    constructor(node) {
        super(node);
        if (node instanceof HsPublicationBroadcastTreeItemFlatNode) {
            this.expandable = node.expandable;
            this.level = node.level;
        }
    }
}
