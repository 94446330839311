import * as angular from 'angular';
export class HvSearchDrugController {
    /** @ngInject */
    constructor(MedicService, Access) {
        this.MedicService = MedicService;
        this.Access = Access;
        /**
         * Return an array containing all matches from value
         * @returns A promise containing an Array of string
         */
        this.searchMedics = () => {
            return this.MedicService.findByName(this.ctrl.searchText);
        };
        /**
         * Set the selected medicine in the [[Loan]]
         */
        this.searchDrugsSelected = () => {
            if (this.ctrl.selected) {
                this._hasJustSelected = true;
                let drug = {
                    denomination: this.ctrl.selected.denomination,
                    UCD13: this.ctrl.selected.ucd13,
                    DCI: this.ctrl.selected.sac_nom,
                    codeATC: this.ctrl.selected.sp_catc_code_fk,
                };
                this.DCI = this.ctrl.selected.sac_nom;
                this.MedicService.getLab(this.ctrl.selected.sp_code_sq_pk)
                    .then((ans) => {
                    this.ctrl.selected.laboratory_exp = ans.laboratory_exp;
                    this.ctrl.selected.laboratory_tit = ans.laboratory_tit;
                    this.onSelected({ $event: { drug: drug, pharmaForm: this.ctrl.selected.codefo } });
                });
            }
        };
        /**
         * On text change call callback
         */
        this.searchTextChange = () => {
            if (!this._hasJustSelected) {
                if (this.onTextChange) {
                    this.onTextChange({ text: this.ctrl.searchText });
                }
            }
            this._hasJustSelected = false;
        };
        /**
         * when unfocus register other unkonwn drug
         */
        this.onBlur = () => {
            if (!this._hasJustSelected && !this.disallowCustomName && this.onSelected && this.drug.denomination !== this.ctrl.searchText) {
                this.knownDrug = 0;
                this.onSelected({
                    $event: {
                        drug: {
                            denomination: this.ctrl.searchText,
                            UCD13: null,
                            DCI: null,
                            codeATC: null,
                        },
                    },
                });
                this._hasJustSelected = false;
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
        this.name = this.name || 'search-drug-autocomplete';
        this._hasJustSelected = false;
        this.showDci = true;
        this.disallowCustomName = false;
        this.knownDrug = -1;
    }
    $onInit() {
        if (this.drug) {
            if (this.drug.denomination)
                this.knownDrug = 0;
            this.ctrl.searchText = this.drug.denomination;
            if (this.drug.DCI) {
                this.DCI = this.drug.DCI;
                this.knownDrug = 1;
            }
            else if (this.drug.sac_nom) {
                this.DCI = this.drug.sac_nom;
                this.knownDrug = 1;
            }
        }
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(changes) {
        if (changes.drug) {
            if (this.drug)
                this.ctrl.searchText = angular.copy(this.drug.denomination);
        }
    }
}
