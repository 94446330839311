var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, Input, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { GroupLabo, GroupLaboHospital, Hospital } from '@mapuilabs/mpl-interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { AdminLaboGroupService } from '@services/admin/labo-group/admin-labo-group.service';
import { LaboGroupInformationTabComponent } from './labo-group-information-tab/labo-group-information-tab.component';
import { LaboGroupHospitalsTabComponent } from './labo-group-hospitals-tab/labo-group-hospitals-tab.component';
import { StateService } from '@uirouter/angular';
let LaboGroupDetailComponent = class LaboGroupDetailComponent {
    constructor(_fuseTranslationLoaderService, _stateService, _translationService, _snackBarService, _adminLaboGroupService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._adminLaboGroupService = _adminLaboGroupService;
        this.isHospitalsLoading = false;
        /**
         * Creates an empty FormGroup. The form controls will be added by the child components.
         */
        this.createDetailFormGroup = () => {
            this.detailForm = new UntypedFormGroup({
                informationForm: new UntypedFormGroup({}),
                hospitalsForm: new UntypedFormGroup({})
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        if (this._stateService.params.id === 'new') {
            this.pageType = 'new';
            this.laboratoryGroup = new GroupLabo({});
            this.laboratoryGroup.labo = this.laboratory;
            this.laboratoryGroup.hospitals = [];
        }
        else {
            this.pageType = 'edit';
            if (!this.laboratoryGroup.hospitals) {
                this.laboratoryGroup.hospitals = [];
            }
        }
        const title = this.pageType === 'new'
            ? `${this._translationService.instant('LABOS.DETAILS.GROUPS.DETAIL.TITLE')} - ${this._translationService.instant('COMMONS.NEW')}`
            : `${this._translationService.instant('LABOS.DETAILS.GROUPS.DETAIL.TITLE')} - ${this.laboratoryGroup.name}`;
        this.pageHeaderConfig = {
            title: title,
            svgIcon: 'preparation'
        };
        this.createDetailFormGroup();
    }
    applyFormResult() {
        this.informationTabComponent.updateLaboratoryGroup(this.laboratoryGroup);
    }
    updateGroupLaboHospitals() {
        const hospitals = this.hospitalsTabComponent.hospitals;
        const laboGroupHospitals = this.laboratoryGroup.hospitals;
        // Delete removed hospitals
        this.laboratoryGroup.hospitals = laboGroupHospitals.filter((laboGroupHospital) => {
            return hospitals.some((hospital) => hospital._id == laboGroupHospital.hospital._id);
        });
        // Add new hospitals
        hospitals.forEach((hospital) => {
            const isNewHospital = !laboGroupHospitals.some((laboGroupHospital) => {
                return laboGroupHospital.hospital._id == hospital._id;
            });
            if (isNewHospital) {
                const laboGroupHospital = new GroupLaboHospital(hospital);
                laboGroupHospital.hospital = hospital;
                this.laboratoryGroup.hospitals.push(laboGroupHospital);
            }
        });
    }
    saveLaboGroup() {
        this.applyFormResult();
        this.updateGroupLaboHospitals();
        this._adminLaboGroupService.save(this.laboratoryGroup).subscribe((newLaboratoryGroup) => {
            this._snackBarService.open(this._translationService.instant('LABOS.DETAILS.GROUPS.DETAIL.EDIT_OK'));
            this.laboratoryGroup = newLaboratoryGroup;
            this.detailForm.markAsPristine();
        }, (err) => this._snackBarService.openError(err));
    }
    addLaboGroup() {
        this.applyFormResult();
        this.updateGroupLaboHospitals();
        this._adminLaboGroupService.add(this.laboratoryGroup).subscribe((newLaboratoryGroup) => {
            this._snackBarService.open(this._translationService.instant('LABOS.DETAILS.GROUPS.DETAIL.CREATE_OK'));
            this.laboratoryGroup = newLaboratoryGroup;
            if (!this.laboratoryGroup.hospitals) {
                this.laboratoryGroup.hospitals = [];
            }
            this.pageType = 'edit';
            this.detailForm.markAsPristine();
        }, (err) => this._snackBarService.openError(err));
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Hospital !== "undefined" && Hospital) === "function" ? _f : Object)
], LaboGroupDetailComponent.prototype, "laboratory", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof GroupLabo !== "undefined" && GroupLabo) === "function" ? _g : Object)
], LaboGroupDetailComponent.prototype, "laboratoryGroup", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _h : Object)
], LaboGroupDetailComponent.prototype, "detailForm", void 0);
__decorate([
    ViewChild(LaboGroupInformationTabComponent, { static: false }),
    __metadata("design:type", typeof (_j = typeof LaboGroupInformationTabComponent !== "undefined" && LaboGroupInformationTabComponent) === "function" ? _j : Object)
], LaboGroupDetailComponent.prototype, "informationTabComponent", void 0);
__decorate([
    ViewChild(LaboGroupHospitalsTabComponent, { static: false }),
    __metadata("design:type", typeof (_k = typeof LaboGroupHospitalsTabComponent !== "undefined" && LaboGroupHospitalsTabComponent) === "function" ? _k : Object)
], LaboGroupDetailComponent.prototype, "hospitalsTabComponent", void 0);
LaboGroupDetailComponent = __decorate([
    Component({
        selector: 'mpx-labo-group-detail',
        template: require('./labo-group-detail.component.html').default,
        styles: [require('./labo-group-detail.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof AdminLaboGroupService !== "undefined" && AdminLaboGroupService) === "function" ? _e : Object])
], LaboGroupDetailComponent);
export { LaboGroupDetailComponent };
