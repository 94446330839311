export class HvPatientInformationComponent {
    constructor() {
        this.controller = 'HvPatientInformationController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-information.html').default;
        this.bindings = {
            onSave: '&',
            patient: '<',
            change: '=',
            patientForm: '=',
        };
    }
}
