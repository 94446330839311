var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterModule } from '@uirouter/angular';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { RequestListComponent } from './request-list/request-list.component';
import { RequestRoutingModule } from './request.routing';
import { SharedModule } from '../../shared/shared.module';
let RequestModule = class RequestModule {
};
RequestModule = __decorate([
    NgModule({
        declarations: [RequestListComponent],
        imports: [
            CommonModule,
            BrowserAnimationsModule,
            UIRouterModule,
            SharedModule,
            FuseSharedModule,
            FuseDirectivesModule,
            MatIconModule,
            MatTableModule,
            MatSortModule,
            MatPaginatorModule,
            MatIconModule,
            MatMenuModule,
            MatButtonModule,
            MatSnackBarModule,
            MatDialogModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            MatRadioModule,
            MatRippleModule,
            MatProgressSpinnerModule,
            MatTooltipModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [RequestRoutingModule]
    })
], RequestModule);
export { RequestModule };
