var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { EFilterType, Hospital } from '@mapuilabs/mpl-interfaces';
import { HospitalService } from '@services/hospital/hospital.service';
import { FilterManager } from '@shared/map/filter-manager.class';
let ExchangeGroupsFilterComponent = class ExchangeGroupsFilterComponent {
    constructor(_hospitalService) {
        this._hospitalService = _hospitalService;
    }
    ngOnInit() {
        this._hospitalService.getGroups().subscribe((hospital) => {
            this.groups = new Hospital(hospital).groups;
        });
    }
    filterGroup(event) {
        const filter = { type: EFilterType.VALUES, inArray: true, values: event.value };
        this.filterManager.updateFilter('groups', filter);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof FilterManager !== "undefined" && FilterManager) === "function" ? _b : Object)
], ExchangeGroupsFilterComponent.prototype, "filterManager", void 0);
ExchangeGroupsFilterComponent = __decorate([
    Component({
        selector: 'exchange-groups-filter',
        template: require('./groups-filter.component.html').default,
        styles: [require('./groups-filter.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _a : Object])
], ExchangeGroupsFilterComponent);
export { ExchangeGroupsFilterComponent };
