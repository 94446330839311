import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { CHsDefaultFiltersLabo, CHsPresetFilters, CHsPresetFiltersLabo } from '@app/hospistock/shared/constTypes/hs-preset-filters.const';
import { CHsDrugSort } from '@app/hospistock/shared/constTypes/hs-sort.const';
import { CHsDynamicTags, CHsDynamicTagsLabo, CHsStaticTags, CHsStaticTagsLabo } from '@app/hospistock/shared/constTypes/hs-tag.const';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { CellProcesses, CellValidators } from '@app/services/spreadsheet-parser/spreadsheet-parser.const';
import { regExpNotApplicable, regExpUnknown } from '@app/shared/constTypes/reg-exp.const';
import { Utils } from '@main/services/Utils';
import { EHospistockPermission, EHsLaboDrugPublicationsImport, EHsLaboDrugPublicationsImportResultMessages, EHsPublicationFilter, EHsPublicationType, EHsUserTypes, HsPublicationAlternativeDrug, HsPublicationAlternativeDrugASchema, HsPublicationAlternativeDrugValidator, HsPublicationDrug, HsPublicationDrugASchema, HsPublicationDrugValidator, HsPublicationMetadataDrugASchema } from '@mapuilabs/mpl-interfaces';
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Service Config
// -----------------------------------------------------------------------------------------------------
const viewType = EHsUserTypes.LABO;
const translationBase = 'PUBLICATION.LABO';
const unknownReplacement = 'unknown';
const permissions = {
    create: EHospistockPermission.LaboDrugPublication_Create,
    read: EHospistockPermission.LaboDrugPublication_Read,
    update: EHospistockPermission.LaboDrugPublication_Edit,
    delete: EHospistockPermission.LaboDrugPublication_Delete
};
const types = [
    CHsPublicationType.stockout,
    CHsPublicationType.quota,
    CHsPublicationType.pressure,
    CHsPublicationType.comEnded,
    CHsPublicationType.shortExpiration,
    CHsPublicationType.transferLabo
];
// Transform functions
const handleUnknownEnum = (value) => (value === unknownReplacement ? null : value);
const handleRefArray = (value) => {
    if (!value) {
        // optional array
        return value;
    }
    // Guard: must be an array
    if (!Array.isArray(value)) {
        throw new Error('Cannot handle ref, value must be an array. Value:' + value);
    }
    const buildRef = (value) => {
        if (typeof value !== 'string') {
            throw new Error('Cannot handle ref, value must be of type string. Value:' + value);
        }
        return { ref: value };
    };
    return value.map((item) => buildRef(item));
};
const spreadsheetPublicationClassBuilder = [
    // Read from constant using “value”
    {
        value: EHsUserTypes.LABO,
        path: ['creatorType']
    },
    // Read from parsed file
    {
        column: EHsLaboDrugPublicationsImport.UCD,
        path: ['healthProductRef', 'ref']
    },
    {
        column: EHsLaboDrugPublicationsImport.DRUG_NAME,
        path: ['healthProductRef', 'name']
    },
    {
        column: EHsLaboDrugPublicationsImport.CIP,
        path: ['healthProductRef', 'healthProducts'],
        transform: handleRefArray
    },
    {
        column: EHsLaboDrugPublicationsImport.END_DATE,
        path: ['endDate']
    },
    {
        column: EHsLaboDrugPublicationsImport.INFORMATION,
        path: ['information']
    },
    {
        column: EHsLaboDrugPublicationsImport.START_DATE,
        path: ['startDate']
    },
    {
        value: false,
        path: ['manual']
    },
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        path: ['type']
    },
    {
        // Transform 'unknown' string to null because spreadsheet can't make the difference between empty cell and null value
        column: EHsLaboDrugPublicationsImport.REMAINDER_PRESERVATION,
        path: ['remaindersConservation', 'active'],
        transform: handleUnknownEnum
    },
    {
        column: EHsLaboDrugPublicationsImport.REGULATORY_QUOTA,
        path: ['regulatoryQuota'],
        transform: handleUnknownEnum
    }
];
const spreadsheetAlternativeClassBuilder = [
    // Publication
    // Read from parsed file
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        path: ['publication', 'type']
    },
    {
        column: EHsLaboDrugPublicationsImport.START_DATE,
        path: ['publication', 'startDate']
    },
    {
        column: EHsLaboDrugPublicationsImport.UCD,
        path: ['publication', 'healthProductRef', 'ref']
    },
    {
        column: EHsLaboDrugPublicationsImport.CIP,
        path: ['publication', 'healthProductRef', 'healthProducts'],
        transform: handleRefArray
    },
    // Alternative
    // Read from constant using "value"
    {
        value: EHsUserTypes.LABO,
        path: ['creatorType']
    },
    // Read from parsed file
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_UCD,
        path: ['healthProductRef', 'ref']
    },
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_DRUG_NAME,
        path: ['healthProductRef', 'name']
    },
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_CIP,
        path: ['healthProductRef', 'healthProducts'],
        transform: handleRefArray
    },
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_UNIT_PACKAGING,
        path: ['unitPackaging'],
        transform: handleUnknownEnum
    },
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_START_DATE,
        path: ['startDate']
    },
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_END_DATE,
        path: ['endDate']
    },
    {
        column: EHsLaboDrugPublicationsImport.ALTERNATIVE_INFORMATION,
        path: ['information']
    }
];
export const hsLaboDrugPublicationServiceConfig = {
    constructors: {
        publication: HsPublicationDrugASchema,
        publicationAlternative: HsPublicationAlternativeDrugASchema,
        publicationMetadata: HsPublicationMetadataDrugASchema
    },
    endPointBase: 'api/hospistock/labo/drug',
    permissions,
    spreadsheetClassBuilder: {
        publication: spreadsheetPublicationClassBuilder,
        alternative: spreadsheetAlternativeClassBuilder
    },
    viewType,
    types
};
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Config
// -----------------------------------------------------------------------------------------------------
// List Config
const listConfig = {
    header: true,
    operator: true,
    source: false,
    audiencePharmacy: true,
    period: true,
    stockIndicator: false,
    tags: true,
    updateDate: true,
    status: false,
    seen: false
};
// Detail Config
const displayStats = (permissionService) => permissionService.authorize(EHospistockPermission.LaboDrugPublicationStats_Read);
const detailConfig = (permissionService) => ({
    header: {
        important: true,
        seen: false,
        tags: true
    },
    healthProducts: true,
    alternatives: true,
    summary: true,
    information: true,
    files: true,
    market: false,
    broadcastList: true,
    stats: displayStats(permissionService),
    supervisor: false,
    comments: false
});
// Sorts Config
const sorts = CHsDrugSort;
// Tags Config
const tags = {
    static: Object.assign(Object.assign({}, CHsStaticTags), CHsStaticTagsLabo),
    dynamic: Object.assign(Object.assign({}, CHsDynamicTags), CHsDynamicTagsLabo)
};
// Filters Config
const filtersConfig = {
    presetFilters: [...CHsPresetFilters, ...CHsPresetFiltersLabo],
    defaultFilters: CHsDefaultFiltersLabo,
    filtersSidebarConfig: {
        filters: [
            EHsPublicationFilter.DATE,
            // EHsPublicationFilter.OPERATORS,
            EHsPublicationFilter.OTHER,
            // EHsPublicationFilter.SUPERVISOR,
            EHsPublicationFilter.TAGS,
            EHsPublicationFilter.TYPES
        ],
        tags: Object.assign(Object.assign({}, tags.static), tags.dynamic),
        others: { important: true, unread: false }
    }
};
// Page Header Config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant('PUBLICATION.TITLES.MAIN_LABO'),
    searchBarPlaceholder: translationService.instant('PUBLICATION.SEARCH'),
    svgIcon: 'cart-remove'
});
// Toolbar config
const toolbarConfig = {
    selection: true,
    sorts,
    tags,
    switchView: false
};
// Import Config
const acceptedDateFormats = ['DD/MM/YYYY', 'DD/MM/YY'];
const quotaPublicationPressureCondition = [
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        validator: CellValidators.notEquals(EHsPublicationType.ComEnded.toString())
    },
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        validator: CellValidators.notEquals(EHsPublicationType.ShortExpiration.toString())
    },
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        validator: CellValidators.notEquals(EHsPublicationType.WholesalerOnly.toString())
    },
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        validator: CellValidators.notEquals(EHsPublicationType.TransferLabo.toString())
    }
];
const startDateConditionsPublication = [
    {
        column: EHsLaboDrugPublicationsImport.TYPE,
        validator: CellValidators.notEquals(EHsPublicationType.ShortExpiration.toString())
    }
];
const alternativeUcdExistsCondition = {
    column: EHsLaboDrugPublicationsImport.ALTERNATIVE_UCD,
    validator: CellValidators.required()
};
const regulatoryQuotaConditionsPublication = {
    column: EHsLaboDrugPublicationsImport.TYPE,
    validator: CellValidators.equals(EHsPublicationType.Quota.toString())
};
const spreadsheetParserOptions = {
    headerToLowerCase: true,
    abortOnMajorError: true
};
const spreadsheetParserConfigPublication = (translationService) => {
    const CPublicationTypeLaboMap = new Map(types.map(({ translate, value }) => {
        const translation = translationService.instant(translate);
        return [translation, value.toString()];
    }));
    return {
        columns: [
            {
                label: EHsLaboDrugPublicationsImport.TYPE,
                processes: [CellProcesses.trim(), CellProcesses.map(CPublicationTypeLaboMap)],
                validators: [CellValidators.required(), CellValidators.enum(EHsPublicationType)]
            },
            {
                label: EHsLaboDrugPublicationsImport.DRUG_NAME,
                processes: [CellProcesses.trim()],
                validators: [CellValidators.required()]
            },
            {
                label: EHsLaboDrugPublicationsImport.UCD,
                processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.UCD13()],
                validators: [CellValidators.required(), CellValidators.ucd()]
            },
            {
                label: EHsLaboDrugPublicationsImport.CIP,
                processes: [
                    CellProcesses.string(),
                    CellProcesses.clear(),
                    CellProcesses.split(';'),
                    CellProcesses.CIP13()
                ],
                validators: [CellValidators.required(), CellValidators.array(';', CellValidators.cip())]
            },
            {
                label: EHsLaboDrugPublicationsImport.START_DATE,
                processes: [CellProcesses.clear(), CellProcesses.date(acceptedDateFormats)],
                validators: [
                    CellValidators.required(startDateConditionsPublication),
                    CellValidators.date(acceptedDateFormats)
                ]
            },
            {
                label: EHsLaboDrugPublicationsImport.END_DATE,
                processes: [CellProcesses.clear(), CellProcesses.date(acceptedDateFormats)],
                validators: [CellValidators.date(acceptedDateFormats), CellValidators.dateGte(new Date())]
            },
            {
                label: EHsLaboDrugPublicationsImport.REMAINDER_PRESERVATION,
                processes: [
                    CellProcesses.clear(),
                    CellProcesses.boolean(),
                    CellProcesses.replace(regExpNotApplicable, unknownReplacement)
                ],
                validators: [
                    CellValidators.required(quotaPublicationPressureCondition),
                    // Values can be either yes (true), no (false) or unknownReplacement("unknown" later transformed into null)
                    CellValidators.list([true, false, unknownReplacement])
                ]
            },
            {
                label: EHsLaboDrugPublicationsImport.REGULATORY_QUOTA,
                processes: [
                    CellProcesses.clear(),
                    CellProcesses.boolean(),
                    CellProcesses.replace(regExpUnknown, unknownReplacement)
                ],
                // Values can be either yes (true), no (false) or unknownReplacement("unknown" later transformed into null)
                validators: [
                    CellValidators.required(regulatoryQuotaConditionsPublication),
                    CellValidators.list([true, false, unknownReplacement])
                ]
            },
            {
                label: EHsLaboDrugPublicationsImport.DEPOSITARY,
                processes: [CellProcesses.trim()],
                validators: []
            },
            {
                label: EHsLaboDrugPublicationsImport.WHOLESALER,
                processes: [CellProcesses.trim()],
                validators: []
            },
            {
                label: EHsLaboDrugPublicationsImport.INFORMATION,
                processes: [CellProcesses.trim()],
                validators: []
            }
        ],
        options: spreadsheetParserOptions
    };
};
const spreadsheetParserConfigAlternative = () => {
    return {
        columns: [
            // Publication
            {
                label: EHsLaboDrugPublicationsImport.UCD,
                processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.UCD13()],
                validators: [CellValidators.required(), CellValidators.ucd()]
            },
            // Alternative
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_DRUG_NAME,
                processes: [CellProcesses.trim()],
                validators: [CellValidators.required(alternativeUcdExistsCondition)]
            },
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_UCD,
                processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.UCD13()],
                validators: [CellValidators.ucd()]
            },
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_CIP,
                processes: [
                    CellProcesses.string(),
                    CellProcesses.clear(),
                    CellProcesses.split(';'),
                    CellProcesses.CIP13()
                ],
                validators: [
                    CellValidators.required(alternativeUcdExistsCondition),
                    CellValidators.array(';', CellValidators.cip())
                ]
            },
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_START_DATE,
                processes: [CellProcesses.clear(), CellProcesses.date(acceptedDateFormats)],
                validators: [CellValidators.date(acceptedDateFormats)]
            },
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_END_DATE,
                processes: [CellProcesses.clear(), CellProcesses.date(acceptedDateFormats)],
                validators: [CellValidators.date(acceptedDateFormats), CellValidators.dateGte(new Date())]
            },
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_INFORMATION,
                processes: [CellProcesses.trim()],
                validators: []
            },
            {
                label: EHsLaboDrugPublicationsImport.ALTERNATIVE_UNIT_PACKAGING,
                processes: [
                    CellProcesses.clear(),
                    CellProcesses.boolean(),
                    CellProcesses.replace(regExpUnknown, unknownReplacement)
                ],
                validators: [CellValidators.list([true, false, unknownReplacement])]
            }
        ],
        options: spreadsheetParserOptions
    };
};
const spreadsheetImportResultMessagesMap = new Map(Utils.enumToKeys(EHsLaboDrugPublicationsImportResultMessages).map((key) => [
    key,
    'PUBLICATION.MODAL.IMPORT.REPORT.MESSAGES.' + key
]));
const importConfig = (translationService) => ({
    spreadsheet: {
        publication: {
            spreadsheetParserConfig: spreadsheetParserConfigPublication(translationService),
            spreadsheetImportResultMessagesMap,
            classValidator: HsPublicationDrugValidator,
            classConstructor: HsPublicationDrug
        },
        alternative: {
            spreadsheetParserConfig: spreadsheetParserConfigAlternative(),
            spreadsheetImportResultMessagesMap,
            classValidator: HsPublicationAlternativeDrugValidator,
            classConstructor: HsPublicationAlternativeDrug
        }
    }
});
export const hsLaboDrugPublicationConfig = (translationService, fuseTranslationLoaderService, permissionService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        detail: detailConfig(permissionService),
        filters: filtersConfig,
        import: importConfig(translationService),
        list: listConfig,
        pageHeader: pageHeaderConfig(translationService),
        toolbar: toolbarConfig,
        translationBase
    };
};
