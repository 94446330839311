var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { EquivalenceDetailComponent } from './equivalence-detail/equivalence-detail.component';
import { EquivalencesListComponent } from './equivalences-list/equivalences-list.component';
const states = [
    {
        name: 'mapui.pharmacyV2.equivalences.list',
        url: '/list?pagination?sorting?search',
        views: {
            'content@mapui': {
                component: EquivalencesListComponent
            }
        },
        data: {
            access: EMaPUIPermission.Equivalences_Read,
            theme: 'theme-blue'
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.pharmacyV2.equivalences.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: EquivalenceDetailComponent
            }
        },
        data: {
            acess: EMaPUIPermission.Equivalences_Read,
            theme: 'theme-blue'
        }
    }
];
let EquivalencesRoutingModule = class EquivalencesRoutingModule {
};
EquivalencesRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], EquivalencesRoutingModule);
export { EquivalencesRoutingModule };
