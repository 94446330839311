var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { AdminHospitalService } from '../../services/admin/hospital/admin-hospital.service';
import { lastValueFrom } from 'rxjs';
import { HospitalDetailComponent } from './hospital-detail/hospital-detail.component';
import { ListHospitalsComponent } from './list-hospitals/list-hospitals.component';
const states = [
    {
        name: 'mapui.adm.hospitals.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: ListHospitalsComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.hospitals.detail',
        url: '/detail/:id?tab',
        views: {
            'content@mapui': {
                component: HospitalDetailComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: true
            },
            tab: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'hospital',
                deps: [Transition, AdminHospitalService],
                resolveFn: (trans, adminHospitalService) => {
                    if (trans.params().id != 'new') {
                        return lastValueFrom(adminHospitalService.getById(trans.params().id));
                    }
                }
            }
        ]
    }
];
let HospitalsRoutingModule = class HospitalsRoutingModule {
};
HospitalsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], HospitalsRoutingModule);
export { HospitalsRoutingModule };
