export class MpModalVaultWarningComponent {
    constructor() {
        this.controller = 'MpModalVaultWarningController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-vault-warning.html').default;
        this.bindings = {
            countdown: '@',
        };
    }
}
