import { Access } from './access.service';
import { Auth } from './auth.service';
import * as angular from 'angular';
import './unsupported-browser/unsupported-browser.module';
import './login-v2/login-v2.module';
import './register-v2/register-v2.module';
import './register-validation/register-validation.module';
import './unlock-vault/unlock-vault.module';
import './validation/validation.module';
import './mp-forgot-password/mp-forgot-password.module';
import './register-pharmacist/register-pharmacist.module';
import './register-pharmacist-validation/register-pharmacist-validation.module';
import './unknown-user/unknown-user.module';
angular
    .module('mapui.services.auth', [
    'mapui.services.auth.unsupported-browser',
    'mapui.services.auth.login-v2',
    'mapui.services.auth.register-v2',
    'mapui.services.auth.forgot-password',
    'mapui.services.auth.register-validation',
    'mapui.services.auth.unlock-vault',
    'mapui.services.auth.register-pharmacist',
    'mapui.services.auth.register-pharmacist-validation',
    'mapui.services.auth.unknown-user',
])
    .service('Access', Access)
    .service('Auth', Auth);
