export class MpModalRoleComponent {
    constructor() {
        this.controller = 'MpModalRoleController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-role.html').default;
        this.bindings = {
            role: '<',
            project: '<',
        };
    }
    ;
}
