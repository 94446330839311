export const locale = {
    lang: 'fr',
    data: {
        ROLES: {
            TITLE: 'Gestion des rôles',
            SAVE_SUCCESS: 'Permissions sauvegardées',
            SIDEBAR: {
                TITLE: 'Liste des projets'
            },
            TOOLTIPS: {
                UNHANDELED_PERMISSIONS: 'Permissions non prises en charge :'
            },
            TABLE: {
                HEADER: {
                    ROLE: 'Role',
                    TYPE: 'Type',
                    SUBSCRIPTION: 'Abonnement',
                    CUSTOM_HOME: 'Accueil personnalisé',
                    DESC: 'Description',
                    PERMISSIONS: 'Permissions',
                    ACTIONS: 'Actions'
                },
                EDIT: {
                    TITLE: 'Modifier',
                    MODAL: {
                        TITLES: {
                            EDIT: 'Modification du rôle',
                            NEW: 'Nouveau rôle',
                            INFORMATION: 'Informations',
                            ROLES: 'Rôles',
                            HOME: "Page d'accueil personnalisée"
                        },
                        TYPE: 'Type',
                        NAME: 'Nom',
                        SUBSCRIPTION: 'Abonnement',
                        DESCRIPTION: 'Description',
                        HOME: "Page d'accueil (état)",
                        IS_ADMIN: 'Administrateur ?',
                        AVAILABLE_USER_ROLES: 'Rôles accessibles',
                        MESSAGES: {
                            STATE_EMPTY: 'Aucun state',
                            STATE_BROKEN: 'State invalide'
                        }
                    },
                    SUCCESS: {
                        EDIT: 'Rôle modifié',
                        NEW: 'Rôle créé'
                    }
                },
                DELETE: {
                    TITLE: 'Supprimer',
                    SUCCESS: 'Rôle supprimé',
                    MODAL: {
                        CONTENT: 'Êtes-vous sûr de vouloir supprimer le rôle ?'
                    }
                },
                DUPLICATE: {
                    TITLE: 'Dupliquer',
                    SUCCESS: 'Role dupliqué'
                },
                LEAVE_MODAL: {
                    TITLE: 'Quitter ?',
                    CONTENT: 'Êtes-vous sûr de vouloir quitter sans sauvegarder ?'
                }
            },
            MAPUI: {
                TITLE: 'MaPUI',
                HOME: 'Accueil',
                USER: 'Utilisateurs',
                USER_ROLE: 'Rôles des utilisateurs',
                ESTABLISHMENT: 'Mon hôpital',
                ESTABLISHMENT_TEAM: 'Mon hôpital - Équipe',
                GROUPS: 'Groupes',
                PREPARATIONS: 'Préparations',
                PREP_INST: "Instance d'une préparation",
                STOCKOUT: "Ruptures d'appro.",
                STOCKOUT_LABO: 'Ruptures laboratoire',
                EQUIVALENCES: 'Équivalences',
                PHARMACEUTIC_INTERVENTIONS: 'Interventions pharma.',
                DASHBOARD: 'Tableau de bord',
                MED_INST: "Instance d'un médicament",
                REQUEST: 'Requêtes',
                MAP: 'Carte',
                AVAILABLE: 'Mises à disposition',
                AVAILABLE_FREEMIUM: 'Mises à dispo Freemium',
                BILLING: 'Avis de paiement',
                STATISTICS: 'Statistiques',
                ARCHIVES: 'Archives',
                LOCAL_TRANSPORT: 'Local',
                WIDGET_HOME: "Widget page d'accueil",
                ARS: 'ARS',
                STOCK: 'Stock',
                STOCK_RISK: 'Risque rupture',
                NATIONAL_ENTITY: 'Entité nationale'
            },
            WORKSPACE: {
                TITLE: 'Workspace',
                FILES: 'Fichiers',
                FILES_RIGHTS: 'Gestionnaire de droits de dossier',
                NEWS: 'News',
                GROUPS: 'Groupes'
            },
            HOSPISTOCK: {
                TITLE: 'Hospistock',
                // # Common
                // Vault
                VAULT_DISCONNECTION: 'Déconnexion',
                // # Admin
                ADMIN: 'Admin',
                // # Labo
                // Display menu mode
                LABO_DISPLAY_MENU_FLAT: 'Laboratoire - Menu simple',
                LABO_DISPLAY_MENU_NESTED: 'Laboratoire - Menu imbriqué',
                // Drug
                LABO_DRUG_DASHBOARD: 'Laboratoire (médicament) - Tableau de bord',
                LABO_DRUG_STATISTIC: 'Laboratoire (médicament) - Statistiques',
                LABO_DRUG_PUBLICATION: 'Laboratoire (médicament) - Publications',
                LABO_DRUG_ALTERNATIVE: 'Laboratoire (médicament) - Alternatives',
                LABO_DRUG_PUBLICATION_STATS: 'Laboratoire (médicament) - Statistiques publication',
                // Medical device
                LABO_MEDICAL_DEVICE_DASHBOARD: 'Laboratoire (DM) - Tableau de bord',
                LABO_MEDICAL_DEVICE_STATISTIC: 'Laboratoire (DM) - Statistiques',
                LABO_MEDICAL_DEVICE_PUBLICATION: 'Laboratoire (DM) - Publications',
                LABO_MEDICAL_DEVICE_PUBLICATION_STATS: 'Laboratoire (DM) - Statistiques publication',
                // # Purchase group
                // Display menu mode
                PURCHASE_GROUP_DISPLAY_MENU_FLAT: "Groupement d'Achat - Menu simple",
                PURCHASE_GROUP_DISPLAY_MENU_NESTED: "Groupement d'Achat - Menu imbriqué",
                // Drug
                PURCHASE_GROUP_DRUG_DASHBOARD: "Groupement d'Achat (médicament) - Tableau de bord",
                PURCHASE_GROUP_DRUG_MARKET: "Groupement d'Achat (médicament) - Marché",
                PURCHASE_GROUP_DRUG_PUBLICATION: "Groupement d'Achat (médicament) - Publications",
                PURCHASE_GROUP_DRUG_PUBLICATION_STATS: "Groupement d'Achat (médicament) - Statistiques publication",
                // Medical device
                PURCHASE_GROUP_MEDICAL_DEVICE_DASHBOARD: "Groupement d'Achat (DM) - Tableau de bord",
                PURCHASE_GROUP_MEDICAL_DEVICE_MARKET: "Groupement d'Achat (DM) - Marché",
                PURCHASE_GROUP_MEDICAL_DEVICE_PUBLICATION: "Groupement d'Achat (DM) - Publications",
                PURCHASE_GROUP_MEDICAL_DEVICE_PUBLICATION_STATS: "Groupement d'Achat (DM) - Statistiques publication",
                // # Pharmacy
                // Display menu mode
                PHARMACY_DISPLAY_MENU_FLAT: 'PUI - Menu simple',
                PHARMACY_DISPLAY_MENU_NESTED: 'PUI - Menu imbriqué',
                // Drug
                PHARMACY_DRUG_BOOKLET: 'PUI (médicament) - Livret',
                PHARMACY_DRUG_DASHBOARD: 'PUI (médicament) - Tableau de bord',
                PHARMACY_DRUG_PUBLICATION: 'PUI (médicament) - Publications',
                // Medical device
                PHARMACY_MEDICAL_DEVICE_BOOKLET: 'PUI (DM) - Livret',
                PHARMACY_MEDICAL_DEVICE_DASHBOARD: 'PUI (DM) - Tableau de bord',
                PHARMACY_MEDICAL_DEVICE_PUBLICATION: 'PUI (DM) - Publications'
            },
            HOSPIVILLE: {
                TITLE: 'Hospiville',
                IS_PREMIUM: 'Abonné HospiVille',
                MIGRATION_PERM: 'Migration HospiVille',
                PATIENTS: 'Patients',
                PATIENT: 'Patient',
                RECONCILIATION: 'Conciliation',
                PATIENT_FILE: 'Fiche patient',
                PATIENT_INTERVIEW_SURVEY: 'Entretien patient',
                DBS: 'Bilan médicamenteux (BMO)',
                MEDICATION_RECONCILIATION: 'Conciliation des traitements (CTM)',
                RECONCILIATION_SHEET: 'Fiche de conciliation',
                PRESCRIPTION: 'Prescription',
                PATIENT_FILES: 'Documents patient',
                EXTENSIVE_PATIENT_INTERVIEW: "Recueil d'informations",
                TREATMENT_ANALYSIS: 'Analyse traitement',
                CARE_PLAN: 'Plan de prise',
                ACTIVITIES: 'Échanges ville-hôpital',
                MAILBOX: 'Boite mail',
                STATISTICS: 'Statistiques',
                ESTABLISHMENT: 'Établissement',
                SHARE_PATIENT: 'Partage de patient',
                CONFIGURATION: 'Configuration',
                PREVIOUS_MED_REC: 'Conciliations antérieures',
                CHECKUP: 'BPM',
                OCTAVE: 'OCTAVE'
            },
            ADMIN_PANEL: {
                TITLE: 'Panneau Admin',
                ADMIN_PANEL: 'Panneau admin',
                ESTABLISHMENTS: 'Hôpitaux',
                USERS: 'Utilisateurs',
                GROUPS: 'Groupes',
                ADD_ESTABLISHMENT: "Ajout d'hôpital",
                STATISTICS: 'Statistiques',
                PROSPECTS: 'Prospects',
                SECURE_EMAILS: 'Emails sécurisés',
                ADD_USER: 'Invitation utilisateur',
                GHT: 'GHT',
                ONLINE_USERS: 'Utilisateur en ligne',
                LABO_GROUPS: 'Groupes labo',
                CRON: 'CRON Manager',
                ROLES: 'Rôles',
                LOGS: 'Logs',
                EXTERNAL_ACTORS: 'Acteurs externes V1',
                EXTERNAL_CONFIGURATIONS: 'Configuration externe',
                STOCKOUT_CONFIG: 'Config Hospistock'
            }
        }
    }
};
