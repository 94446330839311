var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { EAdminPanelPermission, StatsActivities } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
let StatsActivitiesService = class StatsActivitiesService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, StatsActivities, '/api/admin/stats/activities', {
            create: null,
            read: EAdminPanelPermission.Statistics_Read,
            update: null
        });
        this.__injector = __injector;
    }
    getNumberOfActivities(hospital, period) {
        const options = { params: {} };
        if (hospital) {
            options.params.hospital = hospital;
        }
        if (period) {
            options.params.period = JSON.stringify(period);
        }
        return this._http.get(`${this._endPoint}/total`, options);
    }
    getCreationGraphData(step, period, hospitalId) {
        const options = {
            params: { period: JSON.stringify(period), step: step }
        };
        if (hospitalId) {
            options.params.hospital = hospitalId;
        }
        return this._http.get(`${this._endPoint}/graph/creation`, options);
    }
};
StatsActivitiesService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], StatsActivitiesService);
export { StatsActivitiesService };
