export class MpModalAccessComponent {
    constructor() {
        this.controller = 'MpModalAccessController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-access.html').default;
        this.bindings = {
            backButton: '<',
        };
    }
}
