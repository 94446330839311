import { ESortingDirection } from '@mapuilabs/mpl-interfaces';
export const CHsSortStates = {
    default: { direction: undefined, icon: 'unfold_more' },
    asc: { direction: ESortingDirection.asc, icon: 'keyboard_arrow_up' },
    desc: { direction: ESortingDirection.desc, icon: 'keyboard_arrow_down' }
};
const CHsSort = [
    {
        title: 'UPDATE_DATE',
        active: 'updateDate'
    },
    {
        title: 'START_DATE',
        active: 'startDate'
    },
    {
        title: 'END_DATE',
        active: 'endDate'
    }
];
export const CHsDrugSort = [
    ...CHsSort,
    {
        title: 'DRUG_NAME',
        active: 'healthProductRef.name'
    },
    {
        title: 'DRUG_LABO_EXP',
        active: 'healthProductRef.operator'
    }
];
export const CHsMedicalDeviceSort = [
    ...CHsSort,
    {
        title: 'MEDICAL_DEVICE_NAME',
        active: 'healthProductRef.name'
    },
    {
        title: 'SUPPLIER',
        active: 'healthProductRef.operator'
    }
];
