var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { lastValueFrom } from 'rxjs';
import { SecureEmailAddressService } from '../../services/admin/secure-email-address/secure-email-address.service';
import { SecureEmailAddressDetailsComponent } from './details-email/details-email.component';
import { SecureEmailsListComponent } from './list-emails/list-emails.component';
const states = [
    {
        name: 'mapui.adm.secure-emails.list',
        url: '/list?pagination?search?sorting',
        views: {
            'content@mapui': {
                component: SecureEmailsListComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            pagination: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.secure-emails.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: SecureEmailAddressDetailsComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'secureEmailAddress',
                deps: [Transition, SecureEmailAddressService],
                resolveFn: (trans, secureEmailService) => {
                    if (trans.params().id != 'new') {
                        return lastValueFrom(secureEmailService.getById(trans.params().id));
                    }
                }
            }
        ]
    }
];
let SecureEmailsRouting = class SecureEmailsRouting {
};
SecureEmailsRouting = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], SecureEmailsRouting);
export { SecureEmailsRouting };
