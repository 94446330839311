export class MpVirtualPageSelectController {
    /** @ngInject */
    constructor($element, $scope) {
        this.$element = $element;
        this.$scope = $scope;
        /**
         * Return the minimum of page number to display on the list
         * @param pages The number of pages to display
         * @param total The numbers of pages
         * @return {number}
         * @private
         */
        this._getMin = (pages, total) => {
            return Math.min(pages, isFinite(total) && this._isPositive(total) ? total : 1);
        };
        /**
         * Return whether the given number is greater than zero
         * @param number The number to check
         * @return {boolean}
         * @private
         */
        this._isPositive = (number) => {
            return number > 0;
        };
        /**
         *
         * Set the correct pages numbers in the list
         * @param max The limit of pages numbers to display on the list
         * @private
         */
        this._setPages = (max) => {
            if (this.pages.length > max) {
                this.pages.splice(max);
            }
            else {
                for (let i = this.pages.length; i < max; i++) {
                    this.pages.push(i + 1);
                }
            }
        };
        this.pages = [];
        this._watchers = [];
        this._total = 0;
    }
    $onInit() {
        this._content = this.$element.find('md-content');
        this._content.on('scroll', () => {
            if ((this._content.prop('clientHeight') + this._content.prop('scrollTop')) >= this._content.prop('scrollHeight')) {
                this.$scope.$applyAsync(() => {
                    this._setPages(this._getMin(this.pages.length + 10, this._total));
                });
            }
        });
        this._watchers.push(this.$scope.$watch('stItemsByPage', () => {
            this._total = this.$scope.totalItemCount / this.$scope.stItemsByPage;
        }));
        this._watchers.push(this.$scope.$watch('totalItemCount', (total) => {
            this._total = this.$scope.totalItemCount / this.$scope.stItemsByPage;
            this._setPages(this._getMin(Math.max(this.pages.length, 10), total));
        }));
        this._watchers.push(this.$scope.$watch('currentPage', (page) => {
            for (let i = this.pages.length; i < page; i++) {
                this.pages.push(i + 1);
            }
        }));
    }
    $onDestroy() {
        for (const watcher of this._watchers) {
            watcher();
        }
    }
}
