var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EGroup, EMaPUIPermission, Group, EGroupType, EHospistockPermission } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '../../shared/templates/crud/crud.template';
let GroupService = class GroupService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, Group, '/api/groups', {
            create: EMaPUIPermission.Groups_Create,
            read: [
                EMaPUIPermission.Groups_Read,
                EHospistockPermission.PurchaseGroupDrugPublication_Read,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Read
            ],
            update: [
                EMaPUIPermission.Groups_Edit,
                EHospistockPermission.PurchaseGroupDrugPublication_Edit,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Edit
            ],
            delete: EMaPUIPermission.Groups_Delete
        });
        this.__injector = __injector;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ CREATE routes
    // -----------------------------------------------------------------------------------------------------
    /**
     * addGroupMember
     * Add memberor hospital from group
     * @param group group target
     * @param member member to add
     */
    addGroupMember(group, member) {
        const groupStructurePath = group.groupStructure === EGroup.Hospital ? 'hospitals' : 'members';
        const endpoint = `${this._endPoint}/${groupStructurePath}/${group._id}`;
        return this._http.put(endpoint, { id: member._id });
    }
    /**
     * addGroupAdmin
     * Add administrator from group
     * @param group group target
     * @param member member to add
     */
    addGroupAdmin(group, member) {
        const endpoint = `${this._endPoint}/admins/${group._id}`;
        return this._http.put(endpoint, { id: member._id });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ READ routes
    // -----------------------------------------------------------------------------------------------------
    /**
     * getMembersByGroupId
     * Get all Members related to the group by group's Members and/or group's Hospitals
     * @param groupId group id target
     */
    readGroupsWithHospitals(healthProductType) {
        // Set params
        const params = new HttpParams()
            .set('type', EGroupType.Purchase.toString())
            .set('productType', healthProductType);
        return this._http.get(`${this._endPoint}/hospitals/`, { params });
    }
    /**
     * getMembersByGroupId
     * Get all Members related to the group by group's Members and/or group's Hospitals
     * @param groupId group id target
     */
    getMembersByGroupId(groupId, otherParams) {
        const endpoint = `${this._endPoint}/${groupId}/members/`;
        let params = new HttpParams();
        params = this.addOtherParams(params, otherParams);
        return this._http.get(endpoint, { params: params });
    }
    /**
     * searchMembersByGroupId
     * Search into all Members related to the group by group's Members and/or group's Hospitals
     * @param groupId group id target
     * @param search search string
     */
    searchMembersByGroupId(groupId, search) {
        const params = [
            {
                param: 'pageOptions',
                value: JSON.stringify({ limit: 10, page: 1 })
            },
            {
                param: 'search',
                value: search
            }
        ];
        return this.getMembersByGroupId(groupId, params);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ UPDATE routes
    // -----------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------
    // @ DELETE routes
    // -----------------------------------------------------------------------------------------------------
    /**
     * removeGroupMember
     * Remove member or hospital from group
     * @param group group target
     * @param member member to add
     */
    removeGroupMember(group, member) {
        const groupStructurePath = group.groupStructure === EGroup.Hospital ? 'hospitals' : 'members';
        const endpoint = `${this._endPoint}/${group._id}/${groupStructurePath}/${member._id}`;
        return this._http.delete(endpoint);
    }
    /**
     * removeGroupAdmin
     * Remove member or hospital from group
     * @param group group target
     * @param member member to add
     */
    removeGroupAdmin(group, member) {
        const endpoint = `${this._endPoint}/${group._id}/admins/${member._id}`;
        return this._http.delete(endpoint);
    }
};
GroupService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], GroupService);
export { GroupService };
