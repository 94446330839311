export class MpMultiple {
    /** @ngInject */
    constructor() {
        this.link = (scope, elem, attr) => {
            const unwatch = scope.$watch('mpMultiple', function (newValue) {
                if (scope.$eval(attr.mpMultiple)) {
                    elem.attr('multiple', '');
                }
                else {
                    elem.removeAttr('multiple');
                }
            });
        };
        this.restrict = 'A';
    }
    static factory() {
        const directive = () => new MpMultiple();
        directive.$inject = [];
        return directive;
    }
}
