var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, Input } from '@angular/core';
import { EGroupType } from '@mapuilabs/mpl-interfaces';
import { CGroupType } from '@shared/constTypes/group/group-type.const';
let GroupCellComponent = class GroupCellComponent {
    ngOnInit() {
        this.groupTypes = Object.values(CGroupType)
            .filter((item) => item.value === EGroupType.Purchase || item.value === EGroupType.Purchase_Non_Subscribed)
            .map(({ value, translate }) => ({
            translate,
            value,
            names: this.groups
                .filter((group) => group.type === value)
                .map((group) => group.name)
                .join(', ')
        }));
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], GroupCellComponent.prototype, "groups", void 0);
GroupCellComponent = __decorate([
    Component({
        selector: 'group-cell',
        template: require('./group-cell.component.html').default,
        styles: [require('./group-cell.component.scss')]
    })
], GroupCellComponent);
export { GroupCellComponent };
