export var EquivalenceColumns;
(function (EquivalenceColumns) {
    EquivalenceColumns["MEDIC_NAME"] = "medicName";
    EquivalenceColumns["MEDIC_DCI"] = "medicDci";
    EquivalenceColumns["MEDIC_POSOLOGY"] = "medicPosology";
    EquivalenceColumns["EQUI_NAME"] = "equiName";
    EquivalenceColumns["EQUI_DCI"] = "equiDCI";
    EquivalenceColumns["DELIVERY"] = "delivery";
    EquivalenceColumns["REASON"] = "reason";
    EquivalenceColumns["COMMENT"] = "comment";
})(EquivalenceColumns || (EquivalenceColumns = {}));
export var EquivalenceSpreadsheetImportResultMessages;
(function (EquivalenceSpreadsheetImportResultMessages) {
    EquivalenceSpreadsheetImportResultMessages["INVALID_DRUG"] = "INVALID_DRUG";
    EquivalenceSpreadsheetImportResultMessages["MULTIPLE_IMPORT"] = "MULTIPLE_IMPORT";
})(EquivalenceSpreadsheetImportResultMessages || (EquivalenceSpreadsheetImportResultMessages = {}));
