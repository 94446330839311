var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CronRoutingModule } from './cron-routing.module';
import { ListCronComponent } from './list-cron/list-cron.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@app/shared/shared.module';
let CronModule = class CronModule {
};
CronModule = __decorate([
    NgModule({
        declarations: [ListCronComponent],
        imports: [
            CommonModule,
            CronRoutingModule,
            FuseSharedModule,
            SharedModule,
            MatTableModule,
            MatIconModule,
            MatTooltipModule,
            MatSortModule,
            MatButtonModule
        ]
    })
], CronModule);
export { CronModule };
