var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { MainPatientsListComponent } from './main-patients-list/main-patients-list.component';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MainPatientsListRoutingModule } from './main-patients-list-routing.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { HospivilleToolbarModule } from '../../toolbar/toolbar.module';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { FuseSidebarModule } from '@fuse/components';
import { MainPatientsListSidebarComponent } from './main-patients-list/filters-sidebar/main-patients-list-sidebar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UIRouterModule } from '@uirouter/angular';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@app/shared/shared.module';
let MainPatientsListModule = class MainPatientsListModule {
};
MainPatientsListModule = __decorate([
    NgModule({
        declarations: [MainPatientsListComponent, MainPatientsListSidebarComponent],
        imports: [
            CommonModule,
            UIRouterModule,
            FuseSharedModule,
            SharedModule,
            MatIconModule,
            MatSelectModule,
            MatButtonModule,
            MatPaginatorModule,
            MatTableModule,
            MatSortModule,
            MatProgressSpinnerModule,
            MatInputModule,
            MatMenuModule,
            MatChipsModule,
            MatDividerModule,
            HospivilleToolbarModule,
            MatRippleModule,
            FuseSidebarModule,
            MatCheckboxModule,
            MatDatepickerModule,
            MatSlideToggleModule,
            MatBadgeModule,
            MatTooltipModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [MainPatientsListRoutingModule]
    })
], MainPatientsListModule);
export { MainPatientsListModule };
