export class MpDateComponent {
    constructor() {
        this.controller = 'MpDateController';
        this.controllerAs = 'vm';
        this.template = require('./mp-date.html').default;
        this.bindings = {
            model: '=',
            disabled: '<',
            minDate: '<',
            required: '<',
            change: '&',
            name: '@',
            placeholder: '<',
        };
    }
}
