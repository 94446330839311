var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { CHsDrugPublicationListItemInfos, CHsMedicalDevicePublicationListItemInfos } from '@app/hospistock/shared/constTypes/publication/hs-publication-list-item-info.const';
import { fuseAnimations } from '@fuse/animations';
import { EHsHealthProductTypes, EHsUserTypes, HsPublicationASchema } from '@mapuilabs/mpl-interfaces';
let HsPublicationOverviewComponent = class HsPublicationOverviewComponent {
    constructor() {
        this.isTerminated = false;
        this.isExpanded = false;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a;
        this._computeSource();
        switch (this.healthProductType) {
            case EHsHealthProductTypes.DRUG:
                this.infos = CHsDrugPublicationListItemInfos;
                break;
            case EHsHealthProductTypes.MEDICAL_DEVICE:
                this.infos = CHsMedicalDevicePublicationListItemInfos;
                break;
            default:
                this.infos = CHsDrugPublicationListItemInfos;
                break;
        }
        this.healthProductRefs = (_a = this.publication.healthProductRef.healthProducts) === null || _a === void 0 ? void 0 : _a.map(({ ref }) => ref).join(' ; ');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _computeSource() {
        switch (this.publication.creatorType) {
            case EHsUserTypes.LABO:
                this.source = this.publication.creator.name;
                break;
            case EHsUserTypes.GA:
                this.source = this.publication.creatorGroup.name;
                break;
            case EHsUserTypes.PUI:
                this.source = this.publication.creator.name;
                break;
            default:
                break;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    toggleExpandable() {
        this.isExpanded = !this.isExpanded;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof HsPublicationASchema !== "undefined" && HsPublicationASchema) === "function" ? _a : Object)
], HsPublicationOverviewComponent.prototype, "publication", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationOverviewComponent.prototype, "isTerminated", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof EHsHealthProductTypes !== "undefined" && EHsHealthProductTypes) === "function" ? _b : Object)
], HsPublicationOverviewComponent.prototype, "healthProductType", void 0);
HsPublicationOverviewComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-overview',
        template: require('./hs-publication-overview.component.html').default,
        animations: fuseAnimations
    })
], HsPublicationOverviewComponent);
export { HsPublicationOverviewComponent };
