var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Establishment } from '@mapuilabs/mpl-interfaces';
import { map } from 'rxjs/operators';
import { PermissionService } from '../permission/permission.service';
let EstablishmentService = class EstablishmentService {
    constructor(_permissionService, _http) {
        this._permissionService = _permissionService;
        this._http = _http;
        this._endpoint = 'api/external/establishment';
    }
    // Returns establishment matching given string and type
    findByNameAndType(name, types) {
        const params = new HttpParams().set('name', name).set('types', types.toString());
        return this._http.get(this._endpoint, { params: params }).pipe(map((data) => {
            return data.map((elem) => new Establishment(elem));
        }));
    }
};
EstablishmentService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _a : Object, typeof (_b = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _b : Object])
], EstablishmentService);
export { EstablishmentService };
