import { ESocketTopic } from '@mapuilabs/mpl-interfaces';
import { Subject } from "rxjs";
import { EventEmitter } from "@angular/core";
export class NotificationService {
    /** @ngInject */
    constructor(_, SocketService) {
        this._ = _;
        this.SocketService = SocketService;
        this.listeners = [];
        this.opened = new EventEmitter();
        this.listenNotification = () => {
            const obs = new Subject();
            this.SocketService.on(ESocketTopic.Notification, (data) => {
                obs.next({ topic: ESocketTopic.Notification, notification: data });
            });
            return obs;
        };
        this.listenNotificationUpdate = () => {
            const obs = new Subject();
            this.SocketService.on(ESocketTopic.NotificationUpdate, (data) => {
                obs.next({ topic: ESocketTopic.NotificationUpdate, notification: data });
            });
            return obs;
        };
        /**
         * Register callback to listen for notifications
         */
        this.register = (types, callback, target = null, stopPropagation = false) => {
            const listener = {
                types: types instanceof Array ? types : [types],
                target: target,
                stopPropagation: stopPropagation,
                callback: callback,
                date: new Date,
            };
            this.listeners.push(listener);
            return listener;
        };
        /**
         * Un-register listener
         * @param {INotificationListener} listener
         */
        this.unregister = (listener) => {
            if (listener) {
                for (let i in this.listeners) {
                    if (this.listeners.hasOwnProperty(i) && this.listeners[i].date === listener.date &&
                        this._.isEqual(this.listeners[i].types, listener.types) && this.listeners[i].target === listener.target) {
                        this.listeners.splice(parseInt(i), 1);
                        return;
                    }
                }
            }
        };
    }
    emitOpened() {
        this.opened.emit();
    }
}
