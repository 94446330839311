export class MpMergeComponent {
    constructor() {
        this.controller = 'MpMergeController';
        this.controllerAs = 'vm';
        this.template = require('./mp-merge.html').default;
        this.bindings = {
            config: '<',
            sources: '<',
            onChange: '&',
        };
    }
    ;
}
