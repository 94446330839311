var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, EventEmitter, Injector, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { LoanService } from '@app/services/loan/loan.service';
import { StatusService } from '@app/services/status/status.service';
import { ListPage } from '@app/shared/templates/listPage/listPage.class';
import { MatPaginator } from '@angular/material/paginator';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as frExchanges } from '../../request/i18n/fr';
import { locale as enExchanges } from '../../request/i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from '@app/services/dialog/dialog.service';
import { forkJoin } from 'rxjs';
let ToArchiveTabComponent = class ToArchiveTabComponent extends ListPage {
    constructor(_injector, sts, _loanService, _dialogService, _fuseTranslationLoaderService, _state) {
        super(_injector, _loanService.getLoansAndBorrowings);
        this._injector = _injector;
        this.sts = sts;
        this._loanService = _loanService;
        this._dialogService = _dialogService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._state = _state;
        this._fuseTranslationLoaderService.loadTranslations(french, english, frExchanges, enExchanges);
        this.genericTableConfig = {
            clickableRows: false,
            checkboxColumn: {
                active: true,
                multiple: true
            }
        };
        this.columns = [
            {
                label: this._translate('ARCHIVES.HEADERS.NAME'),
                attribute: 'medicName',
                sort: true
            },
            {
                label: this._translate('ARCHIVES.HEADERS.STATUS'),
                template: 'status'
            }
        ];
        this.tableDataSource = new MatTableDataSource([]);
        this.dataSource.subscribe((data) => {
            this.tableDataSource.data = data;
        });
        this.selectionEmpty = new EventEmitter();
        this._loanService.reloadArchives.subscribe(() => this.search());
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    ngOnChanges(changes) {
        if (changes.searchValue) {
            this.search();
        }
    }
    tabChange() {
        this._selectedLoans.clear();
        this.selectionEmpty.emit(true);
        this.search();
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    getStatusIndicator(loan) {
        const paletteName = this._state.current.data.theme.replace('theme-', '');
        return this.sts.getStatusIndicator(loan, paletteName);
    }
    selectionChanged(changes) {
        this._selectedLoans = changes.source;
        if (this._selectedLoans.selected.length === 0) {
            this.selectionEmpty.emit(false);
        }
        else {
            this.selectionEmpty.emit(true);
        }
    }
    /**
     * Archive all the selected [[ILoan]]
     * @param event The DOM event
     */
    archiveSelected() {
        this._dialogService
            .openConfirmDialog({
            title: this._translate('ARCHIVES.ARCHIVE_MODAL.CONFIRM.TITLE'),
            message: this._selectedLoans.selected.entries.length > 1
                ? this._translate('ARCHIVES.ARCHIVE_MODAL.CONFIRM.ARCHIVE_ITEMS')
                : this._translate('ARCHIVES.ARCHIVE_MODAL.CONFIRM.ARCHIVE_ITEM'),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        })
            .subscribe((result) => {
            if (result) {
                forkJoin(this._selectedLoans.selected.map((loan) => {
                    if (this.sts.amIL(loan)) {
                        loan.archiveLender = true;
                    }
                    else {
                        loan.archiveBorrower = true;
                    }
                    const result = this._loanService.save(loan);
                    return result;
                })).subscribe(() => {
                    this._selectedLoans.clear();
                    this._loanService.reloadArchives.emit();
                });
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ToArchiveTabComponent.prototype, "searchValue", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], ToArchiveTabComponent.prototype, "selectionEmpty", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], ToArchiveTabComponent.prototype, "_paginator", void 0);
ToArchiveTabComponent = __decorate([
    Component({
        selector: 'mpx-to-archive-tab',
        template: require('./to-archive-tab.component.html').default,
        styles: [require('../../../status-colors.scss')],
        encapsulation: ViewEncapsulation.None
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof StatusService !== "undefined" && StatusService) === "function" ? _b : Object, typeof (_c = typeof LoanService !== "undefined" && LoanService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object])
], ToArchiveTabComponent);
export { ToArchiveTabComponent };
