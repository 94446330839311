export const locale = {
    lang: 'fr',
    data: {
        SINCE_DATE_MENU: {
            START_MONTH: 'Depuis ce mois',
            START_YEAR: 'Depuis cette année',
            CREATION: 'Depuis la création'
        }
    }
};
