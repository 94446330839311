var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { EAdminPanelPermission, EMaPUIPermission, Loan } from '@mapuilabs/mpl-interfaces';
import { HttpParams } from '@angular/common/http';
let WidgetRequestService = class WidgetRequestService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, Loan, '/api/loans/requests', {
            create: EAdminPanelPermission.AdminPanel_Read,
            read: EMaPUIPermission.Home_Read,
            update: EMaPUIPermission.Home_Read,
            delete: EMaPUIPermission.Home_Read
        });
    }
    getLastRequest(limit) {
        const params = new HttpParams().set('limit', limit.toString());
        return this._http.get(this._endPoint, { params: params });
    }
};
WidgetRequestService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], WidgetRequestService);
export { WidgetRequestService };
