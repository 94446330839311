export class HvModalInformationController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this.ModalService.close({});
        };
    }
    $onInit() {
    }
    $onChanges(changes) {
    }
}
