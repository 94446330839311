export class HvUnderstandingInterviewComponent {
    constructor() {
        this.controller = 'HvUnderstandingInterviewController';
        this.controllerAs = 'vm';
        this.template = require('./hv-understanding-interview.html').default;
        this.bindings = {
            onSave: '&',
            patient: '=',
            treatment: '<'
        };
    }
}
