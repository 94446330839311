export class HvInstanceComponent {
    constructor() {
        this.controller = 'HvInstanceController';
        this.controllerAs = 'vm';
        this.template = require('./hv-instance.html').default;
        this.bindings = {
            source: '<',
        };
    }
    ;
}
