var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import * as moment from 'moment';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SessionService } from '@services/session/session.service';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { StateService } from '@uirouter/angularjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { HistoryService } from '@app/services/history/history.service';
let ListOnlineUsersComponent = class ListOnlineUsersComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _sessionService, _historyService, _stateService, _injector) {
        super(_injector, _sessionService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._sessionService = _sessionService;
        this._historyService = _historyService;
        this._stateService = _stateService;
        this._injector = _injector;
        this.displayedColumns = ['name', 'email', 'hospital', 'group', 'online', 'date'];
        this.exportLoading = false;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('ONLINE_USERS.TITLE'),
            searchBarPlaceholder: this._translate('ONLINE_USERS.SEARCH'),
            icon: 'timelapse'
        };
        this.genericTableConfig = { clickableRows: true };
        this.columns = [
            {
                label: this._translate('ONLINE_USERS.TABLE.NAME'),
                attribute: 'member.fullName',
                classes: ['min-w-140', 'max-w-300']
            },
            {
                label: this._translate('ONLINE_USERS.TABLE.EMAIL'),
                attribute: 'member.email',
                classes: ['max-w-300']
            },
            {
                label: this._translate('ONLINE_USERS.TABLE.HOSPITAL'),
                attribute: 'member.hospital.name',
                classes: ['min-w-180']
            },
            {
                label: this._translate('ONLINE_USERS.TABLE.GROUP'),
                template: 'group'
            },
            {
                label: this._translate('ONLINE_USERS.TABLE.STATUS.TITLE'),
                template: 'online',
                attribute: 'online',
                classes: ['max-w-100'],
                sort: true
            },
            {
                label: this._translate('ONLINE_USERS.TABLE.SINCE'),
                template: 'date',
                attribute: 'date',
                classes: ['max-w-140'],
                sort: true
            }
        ];
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        super.ngOnInit();
        this.startUpdateData();
        this.emptyTableMsg = 'ONLINE_USERS.TABLE.EMPTY';
    }
    ngOnDestroy() {
        this.stopUpdateData();
        super.ngOnDestroy();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    startUpdateData() {
        if (!this.clock) {
            this.clock = setInterval(() => {
                this._reload.emit();
            }, 30000);
        }
    }
    stopUpdateData() {
        if (this.clock) {
            clearInterval(this.clock);
            this.clock = null;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    goToDetails(session) {
        var _a, _b;
        this._stateService.go(((_a = session.member) === null || _a === void 0 ? void 0 : _a._id) ? 'mapui.adm.online_users.details' : '', { id: (_b = session.member) === null || _b === void 0 ? void 0 : _b._id });
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    getOnlineDuration(date) {
        if (!date) {
            return;
        }
        const diff = moment().diff(new Date(date));
        return moment.duration(diff).humanize();
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_f = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _f : Object)
], ListOnlineUsersComponent.prototype, "_paginator", void 0);
ListOnlineUsersComponent = __decorate([
    Component({
        selector: 'mpx-list-online-users',
        template: require('./list-online-users.component.html').default,
        styles: [require('./list-online-users.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof SessionService !== "undefined" && SessionService) === "function" ? _b : Object, typeof (_c = typeof HistoryService !== "undefined" && HistoryService) === "function" ? _c : Object, typeof (_d = typeof StateService !== "undefined" && StateService) === "function" ? _d : Object, typeof (_e = typeof Injector !== "undefined" && Injector) === "function" ? _e : Object])
], ListOnlineUsersComponent);
export { ListOnlineUsersComponent };
