var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable } from '@angular/core';
import { Access } from '@main/services/auth/access.service';
import { Utils } from '@main/services/Utils';
import { ELoanCat, ELoanSubCat } from '@mapuilabs/mpl-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { CLoanStatusIndicators } from '@shared/constTypes/loans/loan-status.const';
let StatusService = class StatusService {
    constructor(_access, _translate) {
        this._access = _access;
        this._translate = _translate;
        this._refreshHospital();
        // remplacer par loan-status.const.ts
        this._constTypes = CLoanStatusIndicators;
    }
    /**
     * Return whether the current user is the lender of the loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    amIL(loan) {
        if (!loan) {
            return false;
        }
        return Utils.compareIds(this._hid, loan.lender);
    }
    /**
     * Return whether the current user is the borrower of the loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    amIB(loan) {
        if (!loan) {
            return false;
        }
        return Utils.compareIds(this._hid, loan.borrower);
    }
    /**
     * Return whether the preparation is received
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isReceived(loan) {
        return this.isPrep(loan) && Utils.hasId(loan.steCheckPerson);
    }
    /**
     * Return whether the loan is returned
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isReturned(loan) {
        /* if (!loan) return false;
         if (loan.oldReturnDate != undefined && loan.oldReturnDate != null)
         return !!loan.oldReturnDate;
         else*/
        return !!loan.returnDate;
    }
    /**
     * Return whether the loan need a return
     * @param loan the [[Loan]] to test
     * @returns {boolean}
     */
    isReturn(loan) {
        if (!loan) {
            return false;
        }
        return loan.returnExpected;
    }
    /**
     * Return whether the loan is a bill
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isBill(loan) {
        if (!loan) {
            return false;
        }
        return !this.isReturn(loan);
    }
    /**
     * Return whether the loan has been billed
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isBilled(loan) {
        if (!loan) {
            return false;
        }
        return this.hasBBilled(loan) || this.hasLBilled(loan);
    }
    /**
     * Return whether the loan is a request
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isRequest(loan) {
        if (!loan) {
            return false;
        }
        return ELoanCat.Request == loan.loanCategoryCreation;
    }
    /**
     * Return whether the lender has billed the loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    hasLBilled(loan) {
        if (!loan) {
            return false;
        }
        return !!loan.billingDateLender;
    }
    /**
     * Return whether the borrower has billed the loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    hasBBilled(loan) {
        if (!loan) {
            return false;
        }
        return !!loan.billingDateBorrower;
    }
    /**
     * Return whether the loan is a preparation loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isPrep(loan) {
        if (!loan) {
            return false;
        }
        return ELoanCat.Prep == loan.loanCategoryCreation;
    }
    /**
     * Return whether the loan is a medication loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isMed(loan) {
        if (!loan) {
            return false;
        }
        return ELoanSubCat.Medic == loan.loanSubCategoryCreation;
    }
    /**
     * Return whether the loan is a medical device loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isDM(loan) {
        if (!loan) {
            return false;
        }
        return ELoanSubCat.DM == loan.loanSubCategoryCreation;
    }
    /**
     * Return whether the loan is an available
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isMaD(loan) {
        if (!loan) {
            return false;
        }
        return ELoanCat.Available == loan.loanCategoryCreation;
    }
    /**
     * Return whether the loan need an acceptation step
     * Used only for preparations
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    needAcceptation(loan) {
        if (!loan) {
            return false;
        }
        return this.isPrep(loan);
    }
    /**
     * Return whether the loan is accepted
     * Used only for preparations
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isAcceptedPrep(loan) {
        if (!loan) {
            return false;
        }
        return loan.inPrep != null;
    }
    /**
     * Return whether the loan is confirmed
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isConfirmed(loan) {
        if (!loan) {
            return false;
        }
        return !!loan.confirmationDate;
    }
    /**
     * Return whether the loan is a loan
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isLoan(loan) {
        if (!loan) {
            return false;
        }
        return Utils.compareIds(loan.creaMember, loan.loanPerson);
    }
    /**
     * Return whether the loan is a borrow
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isBorrow(loan) {
        if (!loan) {
            return false;
        }
        // If the loan is already created then we cas check the borrowingPerson._id
        const createdLoanIsBorrow = Utils.compareIds(loan.creaMember, loan.borrowingPerson);
        // If the loan is in creation the previous condition is false and we check if the user selected the borrowing of a drug
        return !createdLoanIsBorrow && loan.loanCategoryCreation === 1 && !loan.loanTypeCreation
            ? true
            : createdLoanIsBorrow;
    }
    /**
     * Return whether the loan is a loan or borrow
     * @param loan the [[ILoan]] to test
     * @returns {boolean}
     */
    isLoanOrBorrow(loan) {
        if (!loan) {
            return false;
        }
        return ELoanCat.LoanOrBorrow == loan.loanCategoryCreation;
    }
    getStatusName(loan) {
        let res = 'ok';
        if (this.isMaD(loan)) {
            res = 'waitingAvailable';
        }
        else if (this.needAcceptation(loan) && !this.isAcceptedPrep(loan)) {
            if (this.amIL(loan)) {
                res = 'toAccept';
            }
            else {
                res = 'waitingAccept';
            }
        }
        else if (!this.isConfirmed(loan)) {
            if (this.isPrep(loan)) {
                if (this.amIL(loan)) {
                    res = 'toLiberate';
                }
                else {
                    res = 'waitingLiberate';
                }
            }
            else if ((this.isLoan(loan) && this.amIB(loan)) || (this.isBorrow(loan) && this.amIL(loan))) {
                res = 'toConfirm';
            }
            else {
                res = 'waitingConfirm';
            }
        }
        else if (this.isPrep(loan) && !this.isReceived(loan)) {
            if (this.amIB(loan)) {
                res = 'toReceive';
            }
            else {
                res = 'waitingReceive';
            }
        }
        else if (this.isReturn(loan)) {
            if (!this.isReturned(loan)) {
                if (this.amIB(loan)) {
                    res = 'toReturn';
                }
                else {
                    res = 'waitingReturn';
                }
            }
        }
        else if (this.isBill(loan)) {
            if (!this.hasLBilled(loan)) {
                if (this.amIL(loan)) {
                    res = 'toBill';
                }
                else {
                    res = 'waitingBill';
                }
            }
            else if (!this.hasBBilled(loan)) {
                if (this.amIB(loan)) {
                    res = 'toSettle';
                }
                else {
                    res = 'waitingSettle';
                }
            }
        }
        return res;
    }
    createHtmlIndicator(statusName, paletteName) {
        const indic = this._constTypes.find((status) => status.name === statusName);
        if (indic) {
            return `<span class="text-boxed ${indic.cssClass} font-weight-600 ${paletteName}">${this._translate.instant(indic.translate)}</span>`;
        }
    }
    /**
     * Return the Status indicator of the loan
     * Used in the dashboard by datatables
     * @param loan the [[ILoan]] to test
     * @returns {string}
     */
    getStatusIndicator(loan, paletteName) {
        if (!loan.status) {
            loan.status = this.getStatusName(loan);
        }
        return this.createHtmlIndicator(loan.status, paletteName);
    }
    _refreshHospital() {
        if (this._access.hospital) {
            this._hid = this._access.hospital._id;
        }
        else {
            this._hid = null;
        }
    }
};
StatusService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Access !== "undefined" && Access) === "function" ? _a : Object, typeof (_b = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _b : Object])
], StatusService);
export { StatusService };
