var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth as AjsAuth } from '../../../../main/services/auth/auth.service';
import { CrudTemplateService } from '../../../shared/templates/crud/crud.template';
import { EAdminPanelPermission, EHTTPStatus, EHospitalMailType, Member } from '@mapuilabs/mpl-interfaces';
import { FileSaverService } from 'ngx-filesaver';
let AdminMemberService = class AdminMemberService extends CrudTemplateService {
    constructor(__injector, _ajsAuth, _fileSaverService) {
        super(__injector, Member, '/api/admin/members', {
            create: EAdminPanelPermission.Users_Create,
            read: EAdminPanelPermission.Users_Read,
            update: EAdminPanelPermission.Users_Edit,
            delete: EAdminPanelPermission.Users_Delete
        });
        this.__injector = __injector;
        this._ajsAuth = _ajsAuth;
        this._fileSaverService = _fileSaverService;
        this.add = (member) => {
            const endpoint = `/api/members`;
            const addCrud = new CrudTemplateService(this.__injector, Member, endpoint, this._permissions);
            return addCrud.add(member);
        };
        /**
         * Locks the given member (ie lockedByAdmin = true).
         * Not the same as deleted and locked.
         * @param member
         */
        this.lock = (member) => {
            let params = new HttpParams();
            params = params.append('lock', 'true');
            return this.save(member, { params: params });
        };
        /**
         * Unlocks the given member (ie lockedByAdmin = false).
         * @param member
         */
        this.unlock = (member) => {
            let params = new HttpParams();
            params = params.append('unlock', 'true');
            return this.save(member, { params: params });
        };
        /**
         * Unblocks the given member (ie locked = false).
         * @param member
         */
        this.unblockMember = (member) => {
            const endpoint = `unlock-user/${member._id}`;
            return this._http.put(endpoint, member, { responseType: 'text' });
        };
        /**
         * Disables the TwoFA for the current user.
         */
        this.removeDoubleAuth = (member) => {
            member.twoFactorKey = null;
            member.twoFactorKeyTemp = null;
            return this.save(member);
        };
    }
    /**
     * Changes member's hospital.
     * @param member
     * @param hospital
     */
    changeHospital(member, hospital) {
        if (!member || !hospital || !member._id || !hospital._id) {
            return of(null);
        }
        const endpoint = `api/admin/members/${member._id}/change-hospital/${hospital._id}`;
        return this._http.put(endpoint, member).pipe(map((data) => new Member(data)));
    }
    /**
     * This method doesnt reload the user from auth service.
     * It is expected that the app ill be reloaded after calling this method.
     */
    changeCurrentMemberHospital(hospital) {
        if (!hospital) {
            return of(null);
        }
        const member = new Member(this._ajsAuth.user);
        member.hospital = hospital;
        return this.save(member);
    }
    sendWelcomeMail(member) {
        const mailCrud = new CrudTemplateService(this.__injector, Member, '/api/admin/members/mail', this._permissions);
        const params = new HttpParams().append('type', EHospitalMailType.Welcome.toString());
        return mailCrud.save(member, { params: params });
    }
    sendInviteMail(member) {
        const mailCrud = new CrudTemplateService(this.__injector, Member, '/api/admin/members/mail', this._permissions);
        const params = new HttpParams().append('type', EHospitalMailType.Invite.toString());
        return mailCrud.save(member, { params: params });
    }
    /**
     * getMembersByHospital
     */
    getMembersByHospital(hid) {
        const endpoint = `api/admin/hospitals/members`;
        const params = new HttpParams().append('hid', hid);
        return this._http.get(endpoint, { params });
    }
    /**
     * getMembersByNameOrEmail
     * @param {string} search name or email
     * @return {Observable<IPaginationResult<Member>>} array of found members
     */
    getMembersByNameOrEmail(search) {
        const pageOptions = {
            limit: 10,
            page: 0
        };
        return this.getPage(pageOptions, search);
    }
    //retrieve a xlsx spreadsheet matching the passed options
    getSpreadsheetAndDownload(search, sort, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._permissionService.authorize(this._permissions.read)) {
                throwError(() => EHTTPStatus.Unauthorized);
                return;
            }
            const spreadsheetEndPoint = '/xlsx-generation/members.xlsx?xlsxmodel=members';
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (filters) {
                params = params.append('filters', JSON.stringify(filters));
            }
            if (sort) {
                params = params.append('sorting', JSON.stringify(sort));
            }
            const headers = new HttpHeaders({
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const res = yield lastValueFrom(this._http.get(spreadsheetEndPoint, {
                params: params,
                headers: headers,
                responseType: 'arraybuffer'
            }));
            const blob = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            this._fileSaverService.save(blob, 'members.xls');
        });
    }
};
AdminMemberService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof AjsAuth !== "undefined" && AjsAuth) === "function" ? _b : Object, typeof (_c = typeof FileSaverService !== "undefined" && FileSaverService) === "function" ? _c : Object])
], AdminMemberService);
export { AdminMemberService };
