import { EMpStatus, ESource } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalPharmaInterventionController {
    /** @ngInject */
    constructor(ModalService, Api) {
        this.ModalService = ModalService;
        this.Api = Api;
        /**
         * @type {ESource}
         */
        this.ESource = ESource;
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this._close(this.intervention);
        };
        /**
         * Close the modal with actions performing
         * @param answer
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
    $onChanges(changes) {
        if (changes.intervention) {
            this.intervention = angular.copy(this.intervention);
        }
        if (changes.drugs) {
            this.drugs = angular.copy(this.drugs);
        }
    }
}
