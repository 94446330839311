var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '../../../main/services/auth/auth.service';
let ChangePasswordService = class ChangePasswordService {
    constructor(_auth, _http) {
        this._auth = _auth;
        this._http = _http;
    }
    changePassword(body) {
        return new Promise((resolve, reject) => {
            const fullEndPoint = `/change-password/${body._id}`;
            this._http.put(fullEndPoint, body).subscribe((user) => {
                this._auth._changeUser(user);
                resolve();
            }, () => {
                reject();
            });
        });
    }
};
ChangePasswordService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Auth !== "undefined" && Auth) === "function" ? _a : Object, typeof (_b = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _b : Object])
], ChangePasswordService);
export { ChangePasswordService };
