export const locale = {
    lang: 'en',
    data: {
        DRUG_DOS_POSO: {
            DRUG: `Drug`,
            QUANTITY: `Quantity`,
            ADD: 'Add to the prescription'
        }
    }
};
