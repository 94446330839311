var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, EventEmitter, Input, Output } from '@angular/core';
let PageMenuSidebarItemComponent = class PageMenuSidebarItemComponent {
    constructor() {
        this.onClick = new EventEmitter();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], PageMenuSidebarItemComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PageMenuSidebarItemComponent.prototype, "isActive", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_a = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _a : Object)
], PageMenuSidebarItemComponent.prototype, "onClick", void 0);
PageMenuSidebarItemComponent = __decorate([
    Component({
        selector: 'page-menu-sidebar-item',
        template: require('./page-menu-sidebar-item.component.html').default
    }),
    __metadata("design:paramtypes", [])
], PageMenuSidebarItemComponent);
export { PageMenuSidebarItemComponent };
