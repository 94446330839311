export const locale = {
    lang: 'fr',
    data: {
        COMMONS: {
            ACCEPT: 'Accepter',
            ADD: 'Ajouter',
            APPLY: 'Appliquer',
            ARCHIVE: 'Archiver',
            ARCHIVED: 'Archivé',
            ASK_CONFIRM: 'Confirmer ?',
            CANCEL: 'Annuler',
            CONFIRM: 'Confirmer',
            COPY: 'Copier',
            CLOSE: 'Fermer',
            CLOSED: 'Fermé',
            CREATE: 'Créer',
            CREATED: 'Créé',
            DEFAULT: 'Par défaut',
            DELETE: 'Supprimer',
            DELETED: 'Supprimé',
            DETAIL: 'Détail',
            EDIT: 'Modifier',
            EDITED: 'Modifié',
            NEW: 'Nouveau',
            NO: 'Non',
            NO_RESULT_FOUND: 'Pas de résultat trouvé',
            OK: 'Ok',
            OPTIONAL: 'Optionnel',
            OPEN: `Ouvrir`,
            OPEN_ADJ: 'Ouvert',
            PREV: 'Précédent',
            RESET: 'Réinitialiser',
            REFUSE: 'Refuser',
            SAVE: 'Sauvegarder',
            SAVED: 'Sauvegardé',
            SELECT: 'Sélectionner',
            SEARCH: 'Rechercher',
            UNKNOWN: 'Inconnu',
            UNKNOWN_F: 'Inconnue',
            UNSELECT: 'Désélectionner',
            VALIDATE: 'Valider',
            VALIDATED: 'Validé',
            YES: 'Oui',
            WORDS: {
                ADMIN: 'Admin',
                ATC: 'ATC',
                DATE_END: 'Date de fin',
                DATE_START: 'Date de début',
                DISPENSARY: 'Officine',
                DCI: 'DCI',
                DRUG: 'Médicament',
                VIRTUAL_DRUG: 'Méd. virtuel',
                DRUGS: 'Médicaments',
                EDIT_MENU: "Menu d'édition",
                END: 'Fin',
                GROUP: 'Groupe',
                HOSPISTOCK: 'Hospistock',
                LABORATORY: 'Laboratoire',
                MEDICAL_DEVICE: 'Dispositif médical',
                MEDICAL_DEVICES: 'Dispositifs médicaux',
                NAME: 'Nom',
                NEW: 'Nouveau{count, plural, =0{} one{} other{x}}',
                NEXT: 'Suivant',
                NONE: 'Aucun',
                OVER: 'Sur',
                PATIENT: 'Patient',
                PERIOD: 'Période',
                PHARMACY: 'PUI',
                PUBLICATION: 'Publication',
                PUBLICATIONS: 'Publications',
                REASON: 'Raison',
                REF: 'Ref',
                REFERENCE: 'Référence fournisseur',
                ROW: 'Ligne',
                SEARCH: 'Recherche',
                SELECTION: 'Sélection',
                SOURCE: 'Source',
                START: 'Début',
                STATISTICS: 'Statistiques',
                SUBSCRIBED: 'Adhérent',
                TERMINATED_F: 'Terminée',
                TERMINATED: 'Terminé',
                TEMPLATE: 'Modèle',
                TYPE: 'Type',
                UCD: 'UCD',
                UPDATED: 'Modifié le',
                CIP: 'CIP'
            },
            VERBS: {
                DOWNLOAD: 'Télécharger',
                EXPORT: 'Exporter',
                IMPORT: 'Importer',
                LOAD: 'Charger',
                SEARCH: 'Rechercher',
                SEE: 'Voir',
                UPDATE_PAST_F: 'Mise à jour',
                UPDATE_PAST: 'Mis à jour'
            },
            MESSAGES: {
                LOADING_DATA: 'Chargement des données',
                NO_DATA: 'Aucune donnée',
                SEE_ALL: 'Voir tous',
                SEE_LESS: 'Voir moins',
                SEE_MORE: 'Voir plus'
            },
            ERRORS: {
                DEFAULT: 'Une erreur est survenue.',
                BAD_REQUEST: 'Requête erronée.',
                FORBIDDEN: 'Requête non autorisée.',
                NOT_FOUND: 'Ressource non trouvée.',
                INTERNAL_SERVER_ERROR: 'Erreur interne du serveur.',
                CONFLICT: 'Action annulée, synchronisation des données...',
                NO_FILE_CONTENT: 'Impossible de charger le contenu.'
            },
            GENDERS: {
                FEMALE: 'Femme',
                MALE: 'Homme'
            },
            EMPTY_TABLE: `Aucun résultat.`,
            MAIL_PRIORITY: {
                HIGH: 'Priorité haute',
                NORMAL: 'Priorité normale',
                LOW: 'Priorité basse'
            },
            PUNCTUATION: {
                COLON: ' :',
                ELLIPSIS: '...',
                SEMICOLON: ' ;'
            }
        },
        PAGINATOR: {
            ITEMS_PER_PAGE: 'items par page',
            NEXT_PAGE: 'Page suivante',
            PREVIOUS_PAGE: 'Page précédente',
            OF_LABEL: 'sur'
        },
        VALIDATORS: {
            CLADIMED: 'Sélectionnez un cladimed ou tapez un code cladimed à 6 caractères',
            DATE: 'Cette valeur doit être une date valide',
            EMAIL: `L'email n'est pas au bon format.`,
            NUMBER: 'Cette valeur doit être un nombre',
            NUMBER_OR_DECIMAL_NUMBER: 'Cette valeur doit être un nombre ou un nombre décimal',
            REQUIRED: `Ce champ est requis.`,
            MANUAL_CLADIMED: 'Cladimed 6',
            UCD7_OR_13: 'Un UCD doit être composé de 7 ou 13 chiffres'
        },
        UNITS: {
            BYTES: {
                B: 'octets',
                KB: 'Ko',
                MB: 'Mo',
                GB: 'Go',
                TB: 'To'
            }
        },
        TIME: {
            SECONDS: 'secondes',
            HOURS: 'heures',
            DAYS: 'jours',
            DAY_ABBR: 'j',
            WEEKS: 'semaines',
            MONTHS: 'mois',
            YEARS: 'ans'
        },
        MAPUI: {
            GENERAL: {
                CONST_TYPE: {
                    ESTABLISHMENT: {
                        HOSPITAL: 'Hôpital',
                        PHARMACY: 'Pharmacie',
                        EHPAD: 'EHPAD',
                        LABORATORY: 'Laboratoire',
                        COMPANY: 'Entreprise',
                        OTHER: 'Autre',
                        ARS: 'ARS',
                        NATIONAL: 'Entité Nationale',
                        PURCHASE_GROUP: "Groupement d'achat"
                    },
                    MEDICINE_DEPARTMENT: {
                        ADDICTOLOGY: 'Addictologie',
                        ALGOLOGY: 'Algologie',
                        ALCOOLOGY: 'Alcoologie',
                        ALLERGOLOGY: 'Allergologie',
                        PAC: 'Anatomie et cytologie pathologiques',
                        ANDROLOGY: 'Andrologie',
                        ANGIOLOGY: 'Angiologie',
                        BIOCHEMISTRY: 'Biochimie',
                        SURGERY: 'Chirurgie',
                        DIGESTIVE_SURG: 'Chirurgie digestive',
                        FACIAL_SURG: 'Chirurgie maxillo-faciale et stomatologie',
                        PAEDIATRIC_SURG: 'Chirurgie infantile',
                        ORTHO_SURG: 'Chirurgie orthopédique et traumatologique',
                        PLASTIC_SURG: 'Chirurgie plastique',
                        CARDIO_SURG: 'Chirurgie thoracique et cardio-vasculaire',
                        UROLOGICAL_SURG: 'Chirurgie urologique',
                        VASCULAR_SURG: 'Chirurgie vasculaire',
                        VISCERAL_SURG: 'Chirurgie viscérale',
                        CARDIOLOGY: 'Cardiologie',
                        CORONARY_ANGIOGRAPHY: 'Coronarographie',
                        DERMATOLOGY: 'Dermatologie',
                        ENDOCRINOLOGY: 'Endocrinologie',
                        GYNECOLOGY: 'Gynecologie',
                        GERIATRIC: 'Gériatrie',
                        GERIATRIC_SHORT_STAY: 'Court séjour gériatrique',
                        GENETIC: 'Génétique',
                        HAEMATOLOGY: 'Hématologie',
                        GASTROENTEROLOGY: 'Hépato-Gastro-Entérologie',
                        INFECTIOUS_DISEASE: 'Maladies infectieuses',
                        INTERNAL_MEDICINE: 'Médecine interne',
                        NUCLEAR_MEDICINE: 'Médecine nucléaire',
                        NEPHROLOGY: 'Néphrologie',
                        NEO_NATOLOGY: 'Néo-natologie',
                        NEUROSURGERY: 'Neurochirurgie',
                        NEUROLOGY: 'Neurologie',
                        NEUROPSYCHOLOGY: 'Neuropsychiatrie',
                        PEDIATRIC_NEUROLOGY: 'Neuropédiatrie',
                        PEDIATRICS: 'Pédiatrie',
                        PNEUMOLOGY: 'Pneumologie',
                        PSYCHIATRIC: 'Psychiatrie',
                        OPHTHALMOLOGY: 'Ophtalmologie',
                        ODONTOLOGY: 'Odontologie',
                        ONCOLOGY: 'Oncologie',
                        OTOLOGY: 'Otologie',
                        ENT_STOMATOLOGY: 'ORL / Stomatologie',
                        RADIOLOGY_IMAGERY: 'Radiologie / Imagerie',
                        RADIOTHERAPY: 'Radiothérapie',
                        INTENSIVE_CARE: 'Réanimation',
                        RHEUMATHOLOGY: 'Rhumatologie',
                        PALLIATIVE_CARE: 'Soins palliatifs',
                        STOMATOLOGY: 'Stomatologie',
                        EMERGENCY: 'Urgences'
                    },
                    GROUP: {
                        TYPES: {
                            ALL: 'Tous',
                            REGION: 'Région',
                            GHT: 'GHT',
                            PURCHASE: 'Achat',
                            ASSOCIATION: 'Association',
                            PRIVATE: 'Groupe privé',
                            MUTUAL: 'Groupe mutualiste',
                            OTHER: 'Autre',
                            PURCHASE_NON_SUBSCRIBED: 'Achat - non adhérent'
                        }
                    }
                }
            },
            HOME_INFO_DATABASES: `Les jeux de valeurs de ce module sont construits sur les bases de données issues des référentiels ATC (propriété de l'OMS), Médicabase (propriété de l'Association Médicabase), BDPM (propriété de l'ANSM), UCD (propriété du CIP Club), Thériaque (propriété du CNHIM) et CIOdm (propriété de PHAST Services).`
        },
        STOCK_TYPE: {
            MEDIC: `Médicament`,
            MEDICAL_DEVICE: `Dispositif médical`
        },
        STOCK_RISK: {
            DRUGS_CAT: {
                SPECIFIC: 'Spécifique',
                CORTICOSTEROIDS: 'Corticoïde',
                SEDATION: 'Sédation / Anesthésie',
                SEDATION_STUP: 'Sédation / Anesthésie (Stupéfiants)',
                LOCOREGIONAL_ANESTHESIA: 'Anesthésie locorégionale',
                PAIN_RELIEVER: 'Antalgie',
                INFECTIOLOGY: 'Infectiologie',
                CARDIOLOGY: 'Cardiologie',
                MEDICAL_DEVICE: 'Dispositif médical',
                DIALYSIS: 'Dialyse',
                INSULIN: 'Insuline',
                ANTITHROMBOTIC: 'Antithrombotique',
                IMMUNISUPPRESSANT: 'Immunosuppresseur',
                VACCINES: 'Vaccins Grippe',
                OTHER: 'Autre'
            },
            MDS_CAT: {
                RESPIRATORY: 'Respiratoire / Drainage',
                MONITORING: 'Monitorage',
                VASCULAR: 'Vasculaire / Parenteral',
                DIGESTIVE: 'Digestif',
                URINARY: 'Urinaire',
                PROTECTION: 'Protection'
            }
        },
        DELIVERY_ADDRESS: {
            MEDIC: 'Médicaments',
            MATERIAL: 'Dispositif medical',
            EPI: 'EPI'
        },
        PHARMACIST: {
            STATUS: {
                HOD: 'Chef de service',
                AH_ASSISTANT: 'Assistant hospitalo-universitaire',
                SPECIALIST_ASSISTANT: 'Assistant specialiste',
                INTERNAL: 'Interne',
                ASSISTANT_PHARMA: 'Pharmacien adjoint',
                PHARMA_MANAGER: 'Pharmacien gérant',
                ATTACHED_PRACTITIONER: 'Praticien attaché',
                AH_PRACTITIONER: 'Praticien hospitalo-universitaire',
                HOSPITAL_PRACTITIONER: 'Praticien hospitalier',
                SURGEON: 'Chirurgien'
            }
        },
        PHARMACY_STUDENT: {
            STATUS: {
                '5HU': 'Étudiant 5HU'
            }
        },
        CHANGE_PASSWORD: {
            TITLE: 'Changer de mot de passe',
            EXPIRED: {
                TITLE: 'Votre mot de passe a expiré',
                SUBTITLE: 'Merci de le modifier'
            },
            TEMPORARY: {
                TITLE: 'Le mot de passe temporaire doit être modifié',
                SUBTITLE: 'Créez votre propre mot de passe'
            },
            INPUTS: {
                CURRENT: 'Mot de passe actuel',
                NEW: 'Nouveau mot de passe',
                CONFIRM: 'Confirmer le nouveau mot de passe'
            },
            ERRORS: {
                REQUIRED: 'Ce champs est requis.',
                TOO_SHORT: 'Le mot de passe doit contenir 8 caractères minimum.',
                NO_UPPERCASE: 'Le mot de passe doit contenir au moins une majuscule.',
                NO_LOWERCASE: 'Le mot de passe doit contenir au moins une minuscule.',
                NO_DIGIT: 'Le mot de passe doit contenir au moins un chiffre.',
                MISMATCH: 'Les mots de passe ne correspondent pas.',
                MATCH: 'Le nouveau mot de passe ne peut pas être le mot de passe actuel.',
                WRONG_PASSWORD: 'Le mot de passe est incorrect.'
            },
            VALIDATOR_INFO: 'Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial.',
            SAVE: 'Sauvegarder le mot de passe',
            SUCCESS_EDITED: 'Mot de passe modifié avec succès'
        },
        SETTINGS: {
            MAILS: {
                SUBSCRIPTION: {
                    LOAN_BORROWING: 'Prêts et Emprunts',
                    AVAILABLE: 'Mises à disposition',
                    PREP: 'Préparations',
                    PD: 'Demandes de produit',
                    NEWSLETTER: 'Newsletters',
                    STOCKOUT: "Ruptures d'approvisionnement",
                    REQUESTS: 'Requêtes de médicaments ou dispositifs médicaux'
                }
            },
            NOTIFICATIONS: {
                SUBSCRIPTION: {
                    LOAN: 'Prêts',
                    BORROWING: 'Emprunts',
                    AVAILABLE: 'Mises à disposition',
                    AVAILABLE_EXPIRE: 'Expiration des mises à disposition',
                    PREPARATION: 'Préparations',
                    REQUEST: 'Demandes de produit',
                    REQUEST_EXPIRE: 'Expiration des demandes de produit',
                    NEWS: 'News',
                    HOSPITAL: 'Nouveaux arrivants sur la plateforme',
                    FILE: 'Nouveaux documents partagés',
                    GROUP: 'Groupes et invitations',
                    USER: 'Utilisateurs',
                    STOCKOUT_LABO: 'Mises à jour des ruptures laboratoire'
                }
            }
        },
        ROLES: {
            TYPES: {
                ESTABLISHMENT: 'Établissement',
                USER: 'Utilisateur'
            },
            SUBSCRIPTIONS: {
                FREE: 'Gratuit',
                TRIAL: "À l'essai",
                SUBSCRIBED: 'Payant',
                NONE: 'Aucun'
            },
            PERMISSIONS: {
                TITLE: 'Permissions',
                SEE_MENU_ITEM: 'Afficher dans le menu',
                READ: 'Lire',
                CREATE: 'Créer',
                EDIT: 'Modifier',
                DELETE: 'Supprimer',
                SHARE: 'Partager',
                CREATE_BORROWING: 'Créer un prêt',
                CREATE_LOAN: 'Créer un emprunt',
                RETAKE: 'Reprendre',
                VALIDATE: 'Valider'
            }
        },
        EXCHANGES: {
            TYPES: {
                PREPARATION: 'Préparation',
                MEDIC: 'Médicament',
                MEDICAL_DEVICE: 'Dispositif médical'
            },
            AVAILABLE: {
                DRUG: {
                    PRESERVATION: {
                        FRESH_BETWEEN_8_DEG_AND_15_DEG: 'Au frais (8°C à 15°C)',
                        SAFE_FROM_HUMIDITY: "À l'abri de l'humidité",
                        SAFE_FROM_LIGHT: "À l'abri de la lumière",
                        INSIDE_FREEZER: 'Au congélateur',
                        INSIDE_FRIDGE: 'Au réfrigérateur (2°C à 8°C)',
                        ROOM_TEMPERATURE: 'À température ambiante',
                        SAFE_FROM_HEAT: "À l'abri de la chaleur",
                        NOT_FROZEN: 'Ne pas congeler',
                        NOT_STORED_IN_FRIDGE: 'Ne pas conserver au réfrigérateur',
                        IN_ORIGIN_WRAP: "Dans l'emballage d'origine",
                        SAFE_FROM_FROST: "À l'abri du gel (< 0°C)",
                        CAREFULLY_CLOSED: 'Soigneusement fermé',
                        SAFE_FROM_FLAME: "À l'abri d'une flamme",
                        VERTICAL_POSITION: 'En position verticale',
                        NOT_EXPOSED_TO_SUN_RAYS: 'Ne pas exposer aux rayons du soleil',
                        SAFE_FROM_X_RAYS: "À l'abri des rayons X",
                        AT_37_DEG: 'À une température de 37°C',
                        NOT_DRILLED: 'Ne pas percer',
                        NOT_THROWN_TO_FIRE_EVEN_EMPTY: 'Ne pas jeter au feu même vide',
                        NOT_EXPOSED_TO_EXCESSIVE_HEAT: 'Ne pas exposer à une chaleur excessive',
                        SAFE_FROM_IONIZING_RAYS: "À l'abri des rayons ionisants",
                        IN_CLIMATIC_AREA_1: 'En zone climatique I',
                        SAFE_FROM_FLAMMABLE_MATERIAL: "À l'abri des matières combustibles",
                        IN_CLIMATIC_AREA_2: 'En zone climatique II',
                        IN_CLIMATIC_AREA_3: 'En zone climatique III',
                        IN_CLIMATIC_AREA_4: 'En zone climatique IV',
                        NO_SPECIFIC_MEASURE: 'Pas de précautions particulières',
                        NOT_OVER_30_DEG: 'Ne pas dépasser 30°C',
                        NOT_OVER_25_DEG: 'Ne pas dépasser 25°C',
                        NOT_SHAKEN: 'Ne pas agiter',
                        RESPECT_COLD_CHAIN: 'Respecter la chaîne du froid',
                        STRICTLY_HIGHER_15_DEG: 'À température > 15°C',
                        KEEP_AWAY_FROM_FROZEN_ITEM: 'Pas de contact avec des objets congelés',
                        NOT_EXPOSED_HIGHER_50_DEG: 'Ne pas exposer à une température > 50°C',
                        NOT_PRESERVED_UNDER_MIN_10_DEG_CEL: 'Ne pas conserver au dessous de -10°C',
                        SAFE_FROM_BUMP_AND_DROP: "À l'abri des chocs et des chutes",
                        STRICTLY_HIGHER_4_DEG: 'À température > à 4°C',
                        NO_INFO: 'Aucune information',
                        STRICTLY_HIGHER_5_DEG: 'À température > à 5°C',
                        IN_ORIGINAL_PRIMARY_PACKAGING: "Dans conditionnement primaire d'origine",
                        STORED_LOWER_OR_EQUAL_THAN_MIN_18_DEG: 'Conserver a une température < ou = à -18°C',
                        NOT_OVER_MIN_20_DEG: 'Ne pas dépasser temp > -20°C',
                        NOT_STORED_UNDER_2_DEG_CEL: 'Ne pas conserver au dessous de 2°C',
                        CHECK_LOOK_IF_EXPOSED_TO_HEAT: 'Si exposition chaleur : vérifier aspect',
                        NOT_STORED_UNDER_MIN_20_DEG_CEL: 'Ne pas conserver au dessous de -20°C',
                        NOT_STORED_UNDER_MIN_10_DEG_CEL: 'Ne pas conserver au dessus de -10°C',
                        NOT_HIGHER_22_DEG: 'Ne pas dépasser 22°C',
                        NOT_STORED_UNDER_18_DEG_CEL: 'Ne pas conserver au dessous de 18°C',
                        NOT_HIGHER_35_DEG: 'Ne pas dépasser 35°C',
                        SAFE_FROM_COLD: "À l'abri du froid",
                        SAFE_FROM_DRYING_UP: "À l'abri du déssèchement",
                        STRICTLY_HIGHER_10_DEG: 'À température > à 10°C',
                        FLAT_POSITION: 'À plat',
                        NOT_HEATED: 'Ne pas chauffer',
                        BETWEEN_2_AND_8_DEG_AFTER_OPEN_AND_BEFORE_USE: 'Entre 2°C et 8°C après ouverture et avant utlisation',
                        STORED_UNDER_MIN_20_DEG_CEL: 'Conserver au dessous de -20°C',
                        STRICTLY_HIGHER_8_DEG: 'À température > à 8°C',
                        REGULAR_CHECK_OF_LOOK: `Vérifier périodiquement l'aspect`,
                        IN_CRYO_CONTAINER_UNDER_MIN_120_DEG: 'En conteneur pour conservation cryogénique (-120°C)',
                        STORED_IN_LIQUID_NITROGEN_VAP_STATE: "Conserver dans la phase vapeur de l'azote liquide (< -150°C)",
                        NOT_STERILIZED: 'Ne pas stériliser',
                        NO_RADIATION_EXPO: 'Ne pas exposer aux rayonnements',
                        NOT_OVER_40_DEG: 'Ne pas dépasser 40°C',
                        NOT_STORED_UNDER_MIN_18_DEG_CEL: 'Ne pas conserver au dessus de -18°C',
                        STRICTLY_TO_4_DEG: 'Strictement à 4°C'
                    }
                }
            }
        },
        PHARMACY: {
            PREPARATION: {
                CATEGORIES: {
                    HOSPITAL: 'Hôpital',
                    MASTERFUL: 'Magistrale'
                },
                CONDITIONS: {
                    AMBIENT_TEMP: 'Température ambiante',
                    FROM2TO8: 'De +2 à +8°C',
                    NO_LIGHT: "A l'abri de la lumière"
                }
            },
            PUBLICATION: {
                TYPES: {
                    STOCKOUT: 'Rupture de stock',
                    STOCKOUT_SHORT: 'Rupt. stock',
                    QUOTA: 'Contingentement',
                    QUOTA_SHORT: 'Conting.',
                    PRESSURE: 'Tension',
                    COM_ENDED: 'Arrêt de commercialisation',
                    SHORT_EXPIRATION: 'Péremption courte',
                    WHOLESALER_ONLY: 'Circuit grossiste uniquement',
                    TRANSFER_LABO: 'Transfert portefeuille produits'
                },
                TAGS: {
                    ARCHIVED: 'Archivé',
                    PUI: 'PUI',
                    GA: 'GA',
                    FINISHED: 'Terminé',
                    BOOKLET: 'Livret',
                    MARKET: 'Marché',
                    ALTERNATIVE: 'Alternative',
                    PROCESSED: 'Traité',
                    SETTINGS: {
                        FILTER: 'Filtrer',
                        HIDE: 'Masquer',
                        INCLUDE: 'Inclure'
                    }
                },
                HISTORY: {
                    ALTERNATIVE: {
                        CREATE: "Ajout d'une alternative",
                        DELETE: "Suppression d'une alternative",
                        UPDATE: "Modification d'une alternative",
                        TITLE: 'Alternative',
                        // Alternative Drug
                        UPDATE_DRUG: "Modification d'un médicament d'une alternative",
                        // Alternative Medical device
                        UPDATE_MEDICAL_DEVICE: "Modification d'un dispositif médical d'une alternative",
                        // Alternatives information
                        CREATE_INFORMATION: "Ajout d'une information d'une alternative",
                        DELETE_INFORMATION: "Suppression d'une information d'une alternative",
                        UPDATE_INFORMATION: "Modification d'une information d'une alternative",
                        // Alternatives start date
                        CREATE_START_DATE: "Ajout de la date de début d'une alternative",
                        DELETE_START_DATE: "Suppression de la date de début d'une alternative",
                        UPDATE_START_DATE: "Modification de la date de début d'une alternative",
                        // Alternatives end date
                        CREATE_END_DATE: "Ajout d'une date de réapprovisionnement d'une alternative",
                        DELETE_END_DATE: "Suppression d'une date de réapprovisionnement d'une alternative",
                        UPDATE_END_DATE: "Modification d'une date de réapprovisionnement d'une alternative",
                        // Unit packaging
                        UPDATE_UNIT_PACKAGING: "Modification du conditionnement unitaire d'une alternative"
                    },
                    PUBLICATION: {
                        CREATE: 'Ajout de la publication',
                        DELETE: 'Suppression de la publication',
                        UPDATE: 'Modification de la publication',
                        // Depositary
                        CREATE_DEPOSITARY: "Ajout d'un dépositaire",
                        DELETE_DEPOSITARY: "Suppression d'un dépositaire",
                        UPDATE_DEPOSITARY: "Modification d'un dépositaire",
                        // Drug
                        UPDATE_DRUG: "Modification d'un médicament",
                        UPDATE_DRUG_PRESENTATION: 'Modification de présentation',
                        // Information
                        CREATE_INFORMATION: "Ajout d'une information",
                        DELETE_INFORMATION: "Suppression d'une information",
                        UPDATE_INFORMATION: "Modification d'une information",
                        // End date
                        CREATE_END_DATE: "Ajout d'une date de réapprovisionnement",
                        DELETE_END_DATE: "Suppression d'une date de réapprovisionnement",
                        UPDATE_END_DATE: "Modification d'une date de réapprovisionnement",
                        // Medical device
                        UPDATE_MEDICAL_DEVICE: 'Modification du libellé publication',
                        UPDATE_MEDICAL_DEVICE_PRESENTATION: 'Modification de référence',
                        // Regulatory quota
                        CREATE_REGULATORY_QUOTA: 'Ajout du contingentement réglementaire',
                        DELETE_REGULATORY_QUOTA: 'Suppression du contingentement réglementaire',
                        UPDATE_REGULATORY_QUOTA: 'Modification du contingentement réglementaire',
                        // Remainders conservation
                        CREATE_REMAINDERS_CONSERVATION: 'Ajout de la conservation des reliquats',
                        DELETE_REMAINDERS_CONSERVATION: 'Suppression de conservation des reliquats',
                        UPDATE_REMAINDERS_CONSERVATION: 'Modification de la conservation des reliquats',
                        // Start date
                        CREATE_START_DATE: 'Ajout de la date de début de publication',
                        DELETE_START_DATE: 'Suppression de la date de début de publication',
                        UPDATE_START_DATE: 'Modification de la date de début de publication',
                        // Target groups
                        DELETE_TARGET_GROUPS: "Suppression d'un groupement d'achat",
                        UPDATE_TARGET_GROUPS: "Modification d'un groupement d'achat",
                        // Wholesaler
                        CREATE_WHOLESALER: "Ajout d'un grossiste - répartiteur",
                        DELETE_WHOLESALER: "Suppression d'un grossiste - répartiteur",
                        UPDATE_WHOLESALER: "Modification d'un grossiste - répartiteur"
                    },
                    // Comments
                    CREATE_COMMENT: "Ajout d'un commentaire",
                    DELETE_COMMENT: "Suppression d'un commentaire",
                    UPDATE_COMMENT: "Modification d'un commentaire",
                    // Regulatory quota
                    REGULATORY_QUOTA: {
                        YES: 'Oui',
                        NO: 'Non',
                        UNKNOWN: 'Inconnu'
                    },
                    // Remainders conservation
                    REMAINDERS_CONSERVATION: {
                        YES: 'Oui',
                        NO: 'Non',
                        NOT_APPLICABLE: 'Non applicable'
                    },
                    // Tag
                    CREATE_TAG: "Ajout d'un tag",
                    DELETE_TAG: "Suppression d'un tag",
                    // Type
                    DELETE_PUBLICATION_TYPE: 'Suppression du type de publication',
                    UPDATE_PUBLICATION_TYPE: 'Modification du type de publication',
                    NEW_VALUE: 'Nouvelle valeur ',
                    NO_DETAILS: 'Pas de détails',
                    OLD_VALUE: 'Ancienne valeur ',
                    VALUE: 'Valeur',
                    // Unit packaging
                    UNIT_PACKAGING: {
                        YES: 'Oui',
                        NO: 'Non',
                        UNKNOWN: 'Inconnu'
                    }
                }
            }
        }
    }
};
