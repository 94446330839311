var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { MedicService } from '@services/medic/medic.service';
let SearchMedicComponent = class SearchMedicComponent {
    constructor(_medicService) {
        this._medicService = _medicService;
        this.onSelected = new EventEmitter();
        this._search = (value) => {
            if (typeof value === 'string' && (value === null || value === void 0 ? void 0 : value.length) > 0) {
                return new Observable((observer) => {
                    if (value) {
                        this._medicService.findDrugByName(value).subscribe((data) => {
                            observer.next(data);
                        });
                    }
                });
            }
            else {
                return of(null);
            }
        };
    }
    ngOnInit() {
        this.medics = this.parentFormControl.valueChanges.pipe(startWith(''), debounceTime(500), switchMap(this._search));
    }
    inputChanged() {
        this.onSelected.emit({ denomination: this.parentFormControl.value });
    }
    optionSelected(selected) {
        this.onSelected.emit(selected);
    }
    displayFn(drug) {
        return drug && drug.denomination ? drug.denomination : undefined;
    }
    //Called whenever the input change to for the panel to open
    //Original problem is that the panel wont open if the component
    //is placed in a mat-tab
    openPanel() {
        this.autoTrigger.openPanel();
    }
    /**
     * Check is form control is set as required
     */
    get isRequired() {
        var _a;
        if ((_a = this.parentFormControl) === null || _a === void 0 ? void 0 : _a.validator) {
            const validator = this.parentFormControl.validator({});
            return validator && validator.required;
        }
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _b : Object)
], SearchMedicComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchMedicComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchMedicComponent.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchMedicComponent.prototype, "floatLabel", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchMedicComponent.prototype, "required", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], SearchMedicComponent.prototype, "onSelected", void 0);
__decorate([
    ViewChild('autoTrigger', { static: true }),
    __metadata("design:type", typeof (_d = typeof MatAutocompleteTrigger !== "undefined" && MatAutocompleteTrigger) === "function" ? _d : Object)
], SearchMedicComponent.prototype, "autoTrigger", void 0);
SearchMedicComponent = __decorate([
    Component({
        selector: 'search-medic',
        template: require('./search-medic.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MedicService !== "undefined" && MedicService) === "function" ? _a : Object])
], SearchMedicComponent);
export { SearchMedicComponent };
