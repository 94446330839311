export const locale = {
    lang: 'en',
    data: {
        PUBLICATION: {
            BUTTONS: {
                DOCUMENTATION: 'Documentation',
                TEMPLATE: 'Template',
                IMPORT_BOOKLET: 'Import Booklet',
                IMPORT_MARKET: 'Import Market',
                EDIT: 'Edit',
                HISTORY: 'History',
                NEW: 'New'
            },
            CHIPS: {
                NEW: 'New',
                UPDATED: 'Updated'
            },
            WORDS: {
                LABO_HOLDER: 'Laboratory Holder',
                LABO_OPERATOR: 'Laboratory Operator',
                TERMINATED_PFIZER: 'Outdated'
            },
            TITLES: {
                ADD_ALTERNATIVE: 'Add an alternative',
                ADD: 'Create publication',
                ALTERNATIVE: 'Alternative',
                ALTERNATIVES: 'Alternatives',
                BROADCAST_LIST: 'Broadcast',
                COMMENTS: 'Comments',
                DATE: 'Dates',
                SUMMARY: 'Details',
                DRUG: 'Drug',
                EDIT: 'Edit publication',
                EDIT_ALTERNATIVE: 'Edit alternative',
                FILES_IMPORTED: 'Files imported',
                FILES_TO_IMPORT: 'Files to import',
                FILES: 'Files',
                HISTORY: 'History of modifications',
                INFORMATION: 'Information',
                LABO_WIDGET: {
                    PHARMACY_BOOKLET: 'In booklet',
                    PHARMACY_READ: 'Read',
                    PHARMACY_SEEN: 'Seen',
                    PHARMACY_SELECT_ALTERNATIVE: 'My selected alternative / Total'
                },
                MAIN_GROUP: 'Hospistock - Purchasing Group',
                MAIN_LABO: 'Hospistock - Laboratory',
                MAIN_LABO_ALTERNATIVES: 'Hospistock - My alternatives',
                MAIN_PUI: 'Hospistock - Pharmacy',
                MARKETS: 'Markets',
                MEDICAL_DEVICES: 'Medical devices',
                PRESENTATIONS_LIST: 'Selected presentations',
                PRESENTATIONS: 'Presentation',
                PRICE_OFFER_INITIALS: 'PO',
                STOCK_INDICATOR_CAPTION: 'Stock indicator caption',
                STATS: 'Pharmacy Statistics',
                SUPERVISOR: 'Supervisor'
            },
            SUMMARY: {
                ATC: 'ATC',
                DEPOSITARY: 'Depositary',
                END_DATE: 'Start date',
                HOLDER: 'Laboratory holder',
                IMPORTED_DRUG: 'Imported drug',
                NAME: 'Drug name',
                OPERATOR: 'Laboratory operator',
                SUPPLIER: 'Supplier',
                PERIOD: 'Period',
                PRICE_OFFER: 'Price offer',
                CLADIMED: 'CLADIMED',
                REGULATORY_QUOTA: 'Regulatory quota',
                REMAINDERS_CONSERVATION: 'Conservation of remainders',
                SOURCE: 'Source',
                START_DATE: 'End date',
                STOCK_INDICATOR: 'Stock indicator',
                TRANSFER_LABO: 'New laboratory',
                TRANSFER_SUPPLIER: 'New supplier',
                UCD: 'UCD',
                UNIT_PACKAGING: 'Unit packaging',
                UPDATE_DATE: 'Updated',
                WHOLESALER: 'Wholesaler'
            },
            TOOLTIPS: {
                ADD_ALTERNATIVE: 'Add an alternative',
                ADD_COMMENT: 'Add a comment',
                ATTACHMENT: 'Attachment',
                AUTO_TAG: 'Automatically added',
                COMMENTS: 'Comments',
                DELETE: 'Delete',
                EDIT: 'Edit',
                EXPORT_LIST: 'Export list',
                EXPORT_STATS: 'Export statistics',
                SWITCH_VIEW: 'Switch view',
                HISTORY: 'History',
                IMPORTANT: 'Important',
                INFORMATION: 'Information',
                LOOK: 'Look',
                MEDICAL_DEVICE_REF: 'The references have been clean (special characters and leading zeroes removed)',
                OPEN_FILTER: 'Filters',
                OPEN_SORT: 'Sort',
                PERIOD_INDICATIVE_BASIS: 'Dates given on an indicative basis',
                PRICE_OFFER: 'Price offer',
                PRODUCT_NUMBER: 'Affected products',
                REFRESH: 'Refresh',
                REMAIN_CONSERVATION: 'Remain conservation',
                SHARE_STATUS_COMMENT: 'Comment broadcasted to pharmacies members',
                TAG: 'Tags',
                PRODUCT_OUT_OF_STOCK: 'Product out of stock',
                YOUR_PRODUCT: 'Your product'
            },
            EXPORT_EXCEL: 'Export as spreadsheet',
            EXPORT_SHORT: 'Export',
            ADVANCED_FILTERS: 'Advanced filters',
            SEARCH: 'Search a publication',
            NOTIFS: {
                ADD_ALTERNATIVE: 'Alternative added',
                EDIT_ALTERNATIVE: 'Alternative edited',
                DELETE_ALTERNATIVE: 'Alternative deleted',
                ERROR_ALTERNATIVE: 'Alternative not added',
                ADD: 'Publication added',
                SAVE: 'Publication edited',
                DELETE: 'Publication deleted',
                ERROR: 'Publication not added',
                ADD_COMMENT: 'Comment added',
                EDIT_COMMENT: 'Comment edited',
                DELETE_COMMENT: 'Comment deleted'
            },
            MODAL: {
                LABO_STATS: {
                    READ: 'Read at',
                    SEEN: 'Seen at',
                    BOOKLET: 'In booklet'
                },
                TITLES: {
                    NEW_BOOKLET_ITEM: 'Add an entry to the booklet',
                    NEW_MARKET_ITEM: 'Add an entry to the market',
                    EDIT_BOOKLET_ITEM: 'Edit booklet entry',
                    EDIT_MARKET_ITEM: 'Edit market entry'
                },
                FORM: {
                    BROADCAST: {
                        LABEL: 'Broadcast list',
                        INPUTS: {
                            SELECT_ALL: {
                                LABEL: 'Broadcast to all Purchasing Groups'
                            },
                            GROUPS: {
                                LABEL: 'Purchasing Groups selection',
                                PLACEHOLDER: 'Search a Purchasing Group'
                            }
                        },
                        SUBTITLES: {
                            SELECT_GROUPS: 'Select purchasing groups',
                            SELECT_BROADCAST_LIST: 'Select broadcast list'
                        },
                        ERRORS: {
                            NO_GROUP_SELECTED: 'The selection of at least one purchasing group is mandatory.',
                            NO_GROUP_OR_HOSPITAL_SELECTED: 'The selection of at least one purchasing group or hospital is mandatory.'
                        }
                    },
                    TYPE: {
                        LABEL: 'Publication type',
                        PLACEHOLDER: 'Select publication type'
                    },
                    REMAINDERS_CONSERVATION: {
                        ACTIVE: {
                            NOT_PRACTICABLE: 'Not practicable'
                        },
                        DESCRIPTION: {
                            LABEL: 'Information',
                            PLACEHOLDER: 'Fill in additional information'
                        }
                    },
                    START_DATE: {
                        LABEL: 'Start date',
                        PLACEHOLDER: 'Select publication start date'
                    },
                    START_DATE_ALTERNATIVE: {
                        LABEL: 'Start date',
                        PLACEHOLDER: 'Select alternative start date'
                    },
                    END_DATE: {
                        LABEL: 'End date',
                        PLACEHOLDER: 'Select publication end date'
                    },
                    END_DATE_ALTERNATIVE: {
                        LABEL: 'End date',
                        PLACEHOLDER: 'Select alternative end date'
                    },
                    DRUG: {
                        CUSTOM_INPUT: {
                            LABEL: 'Fill in drug manually'
                        },
                        SEARCH: {
                            LABEL: 'Search for a drug',
                            PLACEHOLDER: 'Searching for a drug by name / UCD'
                        },
                        NAME: {
                            LABEL: 'Drug name',
                            PLACEHOLDER: 'Fill in drug name'
                        },
                        ATC: {
                            LABEL: 'Drug ATC',
                            PLACEHOLDER: 'Fill in drug ATC'
                        },
                        UCD: {
                            LABEL: 'Drug UCD',
                            PLACEHOLDER: 'Fill in drug UCD'
                        },
                        LABO_HOLDER: {
                            LABEL: 'Laboratory Holder',
                            PLACEHOLDER: 'Fill in laboratory holder'
                        },
                        LABO_OPERATOR: {
                            LABEL: 'Laboratory Operator',
                            PLACEHOLDER: 'Fill in laboratory operator'
                        },
                        ALREADY_USED: {
                            LABEL: 'A publication already exists for this drug'
                        },
                        ALT_ALREADY_USED: {
                            LABEL: 'A publication is in progress for this alternative drug'
                        },
                        ALTERNATIVE_ALREADY_USED: {
                            LABEL: 'An alternative already exists for this drug'
                        },
                        BOOKLET_ALREADY_USED: {
                            LABEL: 'A booklet entry already exists for this drug'
                        },
                        MARKET_ALREADY_USED: {
                            LABEL: 'A market entry already exists for this drug'
                        },
                        GENERIC_ALREADY_USED: {
                            LABEL: 'A similar element already exists'
                        },
                        DRUG_NAME: {
                            LABEL: 'Drug name'
                        },
                        CONSUMPTION: {
                            LABEL: 'Daily consumption'
                        },
                        STOCK: {
                            LABEL: 'Stock'
                        },
                        CIP: {
                            LABEL: 'CIP/ACL'
                        },
                        LOT: {
                            LABEL: 'Lot number'
                        },
                        MARKET_NUM: {
                            LABEL: 'Market number'
                        },
                        MARKET_SUBJECT: {
                            LABEL: 'Market object'
                        },
                        CAMPAIGN: {
                            LABEL: 'Campaign'
                        },
                        ABBREVIATION: {
                            LABEL: 'Abbreviation'
                        }
                    },
                    MEDICAL_DEVICE: {
                        ALREADY_USED: {
                            LABEL: 'A publication already exists for at least one medical device selected'
                        },
                        BOOKLET_ALREADY_USED: {
                            LABEL: 'A booklet entry already exists for this medical device'
                        },
                        MARKET_ALREADY_USED: {
                            LABEL: 'A market entry already exists for this medical device'
                        },
                        MISSING_INDUSTRIALS: 'Config error, no industrial added to your profil.'
                    },
                    WHOLESALER: {
                        LABEL: 'Wholesaler',
                        PLACEHOLDER: 'Select a wholesaler'
                    },
                    DEPOSITARY: {
                        LABEL: 'Depositary',
                        PLACEHOLDER: 'Select a depositary'
                    },
                    PRESENTATION: {
                        LABEL: 'Drug presentation',
                        PLACEHOLDER: 'Select a presentation',
                        SELECTED: {
                            PLURAL: 'presentations selected'
                        }
                    },
                    INFORMATION: {
                        LABEL: 'Information',
                        PLACEHOLDER: 'Fill in additional information'
                    },
                    ERROR: {
                        MISSING_TYPE: 'The publication type is mandatory.',
                        MISSING_DRUG: 'A drug is mandatory.',
                        MISSING_MEDICAL_DEVICES: 'A medical device is mandatory.',
                        MISSING_START_DATE: 'The start date is mandatory.',
                        MISSING_BROADCAST_LIST: 'The selection of at least one establishment is mandatory.'
                    },
                    SAVE_IN_PROGRESS: 'Save in progress...'
                },
                IMPORT: {
                    TITLE: 'Publication import',
                    REPORT: {
                        SUCCESS: {
                            STATUS: 'Created publications',
                            INSTRUCTIONS: 'The publications listed below have been created successfully.'
                        },
                        INFO: {
                            STATUS: 'Updated publications',
                            INSTRUCTIONS: 'The publications listed below have been updated successfully.'
                        },
                        WARNING: {
                            STATUS: 'Unchanged publications',
                            INSTRUCTIONS: 'The publications listed below have not been changed.'
                        },
                        ERROR: {
                            STATUS: 'Not imported publications',
                            INSTRUCTIONS: 'The publications listed below have not been imported.'
                        },
                        MESSAGES: {
                            // Commons
                            INTERNAL_SERVER_ERROR: 'An unexpected error occured. Please contact help desk.',
                            // Publication
                            TERMINATED: 'Publication must be "current".',
                            INVALID_DRUG: "The publication's product does not belong to your catalog.",
                            INVALID_PRESENTATION: "The presentation is not related to the publication's drug.",
                            MULTIPLE_CURRENT_PUBLICATIONS: 'Multiple "current" publications related to this product found.',
                            MULTIPLE_IMPORT: 'Multiple publications related to this product found in the import file.',
                            SET_AS_TERMINATED: "Publication's end date updated.",
                            UNCHANGED: 'Unchanged publication.',
                            UPDATED: 'One or more changes was detected on publication.',
                            CREATED: 'Publication was succesfully created.',
                            // Alternative
                            NO_CURRENT_PUBLICATION_ALT: 'No publication related to this product found',
                            TERMINATED_ALT: 'Alternative must be "current".',
                            INVALID_DRUG_ALT: "The alternative's product does not belong to your catalog.",
                            INVALID_PRESENTATION_ALT: "The presentation is not related to the alternative's drug.",
                            MULTIPLE_CURRENT_ALTERNATIVES: 'Multiple "current" alternatives related to this product found.',
                            MULTIPLE_IMPORT_ALT: 'Multiple alternatives related to this product found in the import file.',
                            SET_AS_TERMINATED_ALT: "Alternative's end date updated.",
                            UNCHANGED_ALT: 'Unchanged alternative.',
                            UPDATED_ALT: 'One or more changes was detected on alternative.',
                            CREATED_ALT: 'Alternative successfully created.'
                        }
                    }
                }
            },
            REFRESH: {
                BUTTON: 'Refresh'
            },
            SWITCH_VIEW: {
                BUTTON: 'View',
                MENU: {
                    DUAL: 'Double view',
                    SINGLE: 'Simple view'
                }
            },
            PRESET_FILTER: {
                BUTTON: 'Filters',
                MENU: {
                    CURRENT: 'Current',
                    DISABLE_BOOKLET: 'Disable booklet',
                    DISABLE_MARKET: 'Disable markets',
                    EMPTY: 'No filter',
                    GA_CURRENT: 'Only Group / Current',
                    GA_NO_ARCHIVED: 'Only Group / Hide Archived',
                    IMPORTANT: 'Only important',
                    NO_ARCHIVED: 'Hide Archived',
                    UNREAD: 'Only unread',
                    PUI_CURRENT: 'Only Pharmacy / Current',
                    PUI_NO_ARCHIVED: 'Only Pharmacy / Hide Archived'
                }
            },
            SORT: {
                BUTTON: 'Sort',
                MENU: {
                    UPDATE_DATE: 'Modification date',
                    START_DATE: 'Starting date',
                    END_DATE: 'Ending date',
                    DRUG_NAME: 'Drug name',
                    DRUG_LABO_EXP: 'Laboratory',
                    SUPPLIER: 'Supplier',
                    MEDICAL_DEVICE_NAME: 'Medical device name'
                }
            },
            LIST: {
                NO_PUBLICATION: 'No publication',
                ITEM: {
                    TITLES: {
                        OWNER: 'Owner:',
                        SOURCE: 'Source:',
                        PERIOD: 'Period:',
                        PHARMACIES_AUDIENCE: 'Pharmacy Audience:',
                        STOCK_INDICATOR: 'Stock indication:',
                        STOCK_COVERAGE: `Your stock allows you to cover about {{ stockDays }} days of breakage`,
                        UCD: 'UCD :',
                        REF: 'Supplier reference(s) :'
                    },
                    LABELS: {
                        FINISHED: 'Finished'
                    }
                }
            },
            DETAILS: {
                BROADCAST_LIST: {
                    MODAL: {
                        SHOW_NOT_SELECTED: 'Display establishment not selected'
                    },
                    ALL: 'Broadcasted to all purchasing groups.',
                    PARTIAL: 'to {{count}} purchasing groups.',
                    SHOW_LIST: 'show list',
                    STATUS: {
                        PARTIAL: 'Partial',
                        GLOBAL: 'Global'
                    }
                },
                NO_SELECTED_PUBLICATION: 'Select a publication to show detail',
                NO_HISTORY_FOUND: 'No history found',
                ALTERNATIVE: {
                    ADD_ALTERNATIVE: 'Add alternative',
                    NO_ALTERNATIVE: 'No alternative'
                },
                NO_COMMENT: 'No comment',
                COMMENTS: {
                    NEW_INPUT: {
                        PLACEHOLDER: 'Leave a comment'
                    },
                    EDIT_INPUT: {
                        PLACEHOLDER: 'Edit your comment'
                    },
                    BROADCAST_PHARMACY: 'Comment broadcasted to pharmacies members'
                }
            },
            SIDEBAR: {
                TITLES: {
                    MAIN: 'Filters',
                    BOOKLET: 'Booklet',
                    MARKET: 'Markets',
                    DATES: 'Dates',
                    OTHERS: 'Others',
                    LABOS: 'Laboratories',
                    SUPPLIER: 'Suppliers',
                    TYPES: 'Types',
                    TAGS: 'Tags',
                    SUPERVISOR: 'Supervisor',
                    SUBTITLES: {
                        BOOKLET: 'Booklet',
                        MARKET: 'Markets',
                        SELECTED_LABOS: 'Selected Laboratories',
                        SELECTED_SUPPLIER: 'Selected suppliers',
                        SELECTED_SUPERVISOR: 'Selected supervisor'
                    }
                },
                LABELS: {
                    DISABLE_BOOKLET: 'Disable booklet',
                    DISABLE_MARKET: 'Disable markets',
                    START_DATE: 'Publication start date',
                    END_DATE: 'Publication end date',
                    UNKNOWN_END_DATE: 'Unknown end date',
                    TERMINATED: 'Terminated publications',
                    TERMINATED_PFIZER: 'Passed endDate publications',
                    IMPORTANT: 'Important publications',
                    UNREAD: 'Unread publications'
                },
                INPUTS: {
                    END_DATE: {
                        LABEL: 'End date',
                        PLACEHOLDER: 'Select publication end date'
                    },
                    LABOS: {
                        LABEL: 'Select a laboratory'
                    },
                    SUPPLIER: {
                        LABEL: 'Select a supplier'
                    },
                    START_DATE: {
                        LABEL: 'Start date',
                        PLACEHOLDER: 'Select publication start date'
                    },
                    SUPERVISOR: {
                        LABEL: 'Select a supervisor'
                    }
                }
            },
            THRESHOLDS: {
                CRITICAL: {
                    LABEL: 'Critical stock',
                    CAPTION: 'Stock critique caption'
                },
                INSUFFICIENT: {
                    LABEL: 'Insufficient stock',
                    CAPTION: 'Stock insuffisant caption'
                },
                LIMIT: {
                    LABEL: 'Limited stock',
                    CAPTION: 'Stock limite caption'
                },
                OPTIMAL: {
                    LABEL: 'Optimal stock',
                    CAPTION: 'Stock optimal caption'
                },
                SUFFICIENT: {
                    LABEL: 'Sufficient stock',
                    CAPTION: 'Stock suffisant caption'
                },
                STOCK_NULL: {
                    LABEL: 'Unknown stock',
                    CAPTION: 'Unknown stock'
                },
                STOCK_ZERO: {
                    LABEL: 'Stock null',
                    CAPTION: 'Stock is equal to zero'
                },
                CONSUMPTION_NULL: {
                    LABEL: 'Unknown consumption',
                    CAPTION: 'Unknown consumption'
                },
                CONSUMPTION_ZERO: {
                    LABEL: 'Consumption null',
                    CAPTION: 'Consumption is equal to zero'
                },
                NO_END_DATE: {
                    LABEL: 'Unknown end date',
                    CAPTION: 'Unknown end date'
                }
            },
            MESSAGES: {
                FILES: {
                    DELETE: {
                        TITLE: 'Confirm deletion',
                        CONTENT: 'Do you really want to delete this file from the publication?'
                    }
                },
                ALTERNATIVES: {
                    DELETE: {
                        TITLE: 'Confirm deletion',
                        CONTENT: 'Do you really want to delete this alternative?'
                    }
                },
                PUBLICATION: {
                    DELETE: {
                        TITLE: 'Confirm deletion',
                        CONTENT: 'Do you really want to delete this publication?',
                        CONTENT_MULTI: 'Do you really want to delete these publications?'
                    }
                },
                COMMENT: {
                    DELETE: {
                        TITLE: 'Confirm deletion',
                        CONTENT: 'Do you really want to delete this comment?'
                    }
                }
            },
            BOOKLET: {
                TITLES: {
                    ADD: 'Add booklet',
                    DIRECTORY_NEVER_IMPORTED: 'Booklet never imported',
                    LATEST_DIRECTORY_IMPORT: 'Booklet importation: {{value}}',
                    MAIN: 'Hospistock - Booklet',
                    DELETE_ITEM: 'Do you really want to delete this entry of the booklet ?'
                },
                CONTENT: {
                    DELETE_ITEM: 'Do you really want to delete the entry of the booklet containing {{name}} ?',
                    DELETED_ITEM: '{{name}} deleted from the booklet'
                },
                IMPORT: {
                    TITLE: 'Booklet import',
                    REPORT: {
                        SUCCESS: {
                            STATUS: 'Created booklet items',
                            INSTRUCTIONS: 'The booklet items listed below have been created successfully.'
                        },
                        WARNING: {
                            STATUS: 'Unprocessed booklet items',
                            INSTRUCTIONS: 'The booklet items listed below do not meet the import rules and have not been processed.'
                        },
                        MESSAGES: {
                            INVALID_DRUG: 'The UCD does not match any known drug.',
                            MULTIPLE_IMPORT: 'Multiple booklet items related to this drug found in the import file.'
                        }
                    },
                    TEMPLATE: {
                        DRUG: 'Import-template-drug-booklet-Hospistock.xlsx',
                        MEDICAL_DEVICE: 'Import-template-booklet-medical-device-Hospistock.xlsx'
                    }
                },
                LABELS: {
                    NEVER_IMPORT: 'Never imported'
                },
                SEARCH: 'Search a drug',
                TABLE: {
                    ACTIONS: 'Actions',
                    CIP13: 'CIP',
                    PUBLICATION_TITLE: 'Publication Title',
                    CLADIMED_REF: 'CLADIMED code',
                    CONSUMPTION: 'Daily consumption',
                    DRUG_NAME: 'Drug name',
                    MEDICAL_DEVICE_NAME: 'Medical device name',
                    MEDICAL_DEVICE_REF: 'Medical device reference',
                    OPERATOR: 'Operator',
                    REFERENCE: 'Reference',
                    STOCK: 'Stock'
                },
                ERRORS: {
                    FILE_FORMAT_INVALID: 'File format not valid.',
                    PARSING_ERROR: 'File reading error.'
                },
                NOTIFS: {
                    ADD_TO: 'Entry added to the booklet',
                    MODIFIED: 'Booklet entry successfully modified'
                }
            },
            MARKET: {
                TITLES: {
                    ADD: 'Add market',
                    DIRECTORY_NEVER_IMPORTED: 'Market never imported',
                    LATEST_DIRECTORY_IMPORT: 'Market importation: {{value}}',
                    MAIN: 'Hospistock - Market',
                    DELETE_ITEM: 'Do you really want to delete this entry of the market ?'
                },
                CONTENT: {
                    DELETE_ITEM: 'Do you really want to delete the entry of the market containing {{name}} ?',
                    DELETED_ITEM: '{{name}} deleted from the market'
                },
                IMPORT: {
                    TITLE: 'Market import',
                    REPORT: {
                        SUCCESS: {
                            STATUS: 'Created market items',
                            INSTRUCTIONS: 'The market items listed below have been created successfully.'
                        },
                        WARNING: {
                            STATUS: 'Unprocessed market items',
                            INSTRUCTIONS: 'The market items listed below do not meet the import rules and have not been processed.'
                        },
                        MESSAGES: {
                            INVALID_DRUG: 'The UCD does not match any known drug.',
                            MULTIPLE_IMPORT: 'Multiple market items related to this drug found in the import file.'
                        }
                    },
                    TEMPLATE: {
                        DRUG: 'Import-template-drug-market-Hospistock.xlsx',
                        MEDICAL_DEVICE: 'Import-template-market-medical-device-Hospistock.xlsx'
                    }
                },
                LABELS: {
                    NEVER_IMPORT: 'Never imported'
                },
                SEARCH: 'Search a drug',
                TABLE: {
                    ABBREVIATION: 'Abrev.',
                    CAMPAIGN: 'Camp.',
                    CIP13: 'CIP/ACL',
                    CLADIMED_CODE: 'CLADIMED code',
                    PUBLICATION_TITLE: 'Publication Title',
                    MEDICAL_DEVICE_NAME: 'Medical device name',
                    DRUG_NAME: 'Drug name',
                    LOT: 'Lot number',
                    MARKETNUM: 'Market number',
                    MARKETSUBJECT: 'Market subject',
                    SUPPLIER_REF: 'Supplier reference',
                    UDI: 'UDI',
                    UCD13: 'UCD',
                    ACTIONS: 'Actions'
                },
                ERRORS: {
                    FILE_FORMAT_INVALID: 'File format not valid.',
                    PARSING_ERROR: 'File reading error.'
                },
                NOTIFS: {
                    ADD_TO: 'Entry added to the market',
                    MODIFIED: 'Market entry successfully modified'
                }
            },
            LABO: {
                DRUG: {
                    STATISTIC: {
                        SEARCH: 'Search using drug name, UCD or CIP',
                        TABLE: {
                            AVERAGE_DURATION: 'Average duration',
                            UPDATE_DATE: 'Update date',
                            HEALTH_PRODUCT_REF_NAME: 'Drug name',
                            HEALTH_PRODUCT_REF_REF: 'UCD',
                            HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'CIP',
                            PROPOSED_ALTERNATIVES: 'Proposed alternatives',
                            READ: 'Read',
                            SEEN: 'Seen',
                            SELECTED_ALTERNATIVE: 'Selected alternatives',
                            TYPE: 'Type'
                        },
                        PROPOSED_ALTERNATIVE_TABLE: {
                            TITLE: 'Proposed alternatives',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Drug name',
                                HEALTH_PRODUCT_REF_REF: 'UCD',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'CIP',
                                LABORATORY_TIT: 'Laboratory Operator',
                                SOURCE: 'Source',
                                PROPOSED_DATE: 'Proposed date'
                            }
                        },
                        SELECTED_ALTERNATIVE_TABLE: {
                            TITLE: 'Selected alternatives',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Drug name',
                                HEALTH_PRODUCT_REF_REF: 'UCD',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'CIP',
                                SOURCE: 'Source',
                                OPERATOR: 'Operator',
                                SELECTED_DATE: 'Selected date',
                                PHARMACY_SELECTED_ALTERNATIVE: 'Pharmacies that selected'
                            }
                        },
                        TITLE: 'Statistics'
                    }
                },
                MEDICAL_DEVICE: {
                    STATISTIC: {
                        SEARCH: 'Search using medical device or reference',
                        TABLE: {
                            AVERAGE_DURATION: 'Average duration',
                            UPDATE_DATE: 'Update date',
                            HEALTH_PRODUCT_REF_NAME: 'Publication name',
                            HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'References',
                            PROPOSED_ALTERNATIVES: 'Proposed alternatives',
                            READ: 'Read',
                            SEEN: 'Seen',
                            SELECTED_ALTERNATIVE: 'Selected alternatives',
                            TYPE: 'Type'
                        },
                        PROPOSED_ALTERNATIVE_TABLE: {
                            TITLE: 'Proposed alternatives',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Publication name',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'References',
                                SOURCE: 'Source',
                                OPERATOR: 'Operator',
                                PROPOSED_DATE: 'Proposed date'
                            }
                        },
                        SELECTED_ALTERNATIVE_TABLE: {
                            TITLE: 'Selected alternatives',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Publication name',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'References',
                                SOURCE: 'Source',
                                OPERATOR: 'Operator',
                                SELECTED_DATE: 'Selected date',
                                PHARMACY_SELECTED_ALTERNATIVE: 'Pharmacies that selected'
                            }
                        },
                        TITLE: 'Statistics'
                    }
                },
                TITLE: 'Laboratory',
                TEMPLATE: {
                    DRUG: 'Import-template-drug-laboratory-Hospistock.xlsx',
                    MEDICAL_DEVICE: 'Import-template-laboratory-medical-device-Hospistock.xlsx'
                },
                TABLE: {
                    CIP13: 'CIP',
                    ALTERNATIVE: 'Alternative',
                    MEDICAL_DEVICE_NAME: 'Medical device name',
                    MEDICAL_DEVICE_REF: 'Référence fournisseur',
                    OPERATOR: 'Exploitant',
                    REFERENCE: 'Référence fournisseur',
                    UCD13: 'UCD',
                    SOURCE: 'Who proposed',
                    CREATION_DATE: 'Date of proposal',
                    PUI_ESTABLISHMENT: 'Who selected',
                    SELECTION_DATE: 'Selection date'
                }
            },
            PURCHASE_GROUP: {
                TITLE: 'Purchase group',
                IMPORT: {
                    TEMPLATE: {
                        DRUG: 'Import-template-drug-purchase-group-Hospistock.xlsx',
                        MEDICAL_DEVICE: 'Import-template-purchase-group-medical-device-Hospistock.xlsx'
                    }
                }
            },
            PHARMACY: {
                TITLE: 'Pharmacy',
                IMPORT: {
                    TEMPLATE: {
                        DRUG: 'Import-template-drug-pharmacy-Hospistock.xlsx',
                        MEDICAL_DEVICE: 'Import-template-pharmacy-medical-device-Hospistock.xlsx'
                    }
                }
            },
            MEDICAL_DEVICE: {
                CUSTOM_INPUT: {
                    LABEL: 'Enter medical devices manually'
                },
                CLADIMED: {
                    HELP: 'Example: ALGINATE PLATE - F51BC01',
                    TITLE: 'CLADIMED',
                    SEARCH: 'Search for CLADIMED'
                },
                ADD: {
                    SINGULAR: 'Add a medical device',
                    PLURAL: 'Add medical devices'
                },
                DETAILS: {
                    TITLE: 'Medical devices details'
                },
                SEARCH: {
                    TITLE: 'Search for medical devices'
                },
                GROUP_NAME: {
                    TITLE: 'Medical device group name',
                    INPUT_LABEL: 'E.g. Implantable catheter ports'
                },
                SELECT: {
                    PLURAL: 'Selection of medical devices',
                    SINGULAR: 'Selection of medical device'
                },
                LIST: {
                    TITLE: 'List ofs medical devices'
                },
                NAME: { TITLE: 'Name' },
                REFERENCE: { TITLE: 'Reference' },
                SUPPLIER: { TITLE: 'Fournisseur' },
                BRAND: { TITLE: 'Brand' },
                UDI_CODE: { TITLE: 'UDI Code' },
                SHEET: {
                    LINK_NOT_FOUND: 'No medical device sheet found',
                    TITLE: 'Sheet',
                    TOOLTIP: 'Exhausmed sheet'
                },
                ACTION: { TITLE: 'Actions', TOOLTIP: 'Delete medical device' },
                PLURAL_SELECTED: 'selected medical devices',
                PLURAL_FOUND: 'found medical devices',
                NO_MEDICAL_DEVICE_FOUND: 'No medical device found',
                SEARCH_PENDING: 'Search pending'
            }
        },
        DASHBOARD: {
            COMMONS: {
                WIDGET: {
                    LABO_LIST: {
                        TITLE: `Laboratories list`,
                        NO_LABO: 'No laboratories.',
                        TABLE: {
                            NAME: 'Name',
                            CREATION_DATE: 'Registration date'
                        }
                    },
                    NEWS: {
                        TITLE: 'News',
                        EMPTY: 'No news',
                        END: 'End of news'
                    }
                },
                SUMMARY: {
                    AVERAGE_PUBLICATION_DURATION: {
                        TITLE: 'Average duration of publications'
                    },
                    PUBLICATION_TOTAL: {
                        TITLE: 'Total publications'
                    },
                    TITLE: 'My current publications',
                    NO_LABO: 'No publication.'
                },
                GROUP_LIST: {
                    TITLE: `List of purchase group`,
                    NO_LABO: 'No purchase group.',
                    TABLE: {
                        CREATION_DATE: 'Register date.'
                    },
                    MODAL: {
                        TITLE: 'List of pharmacies'
                    }
                }
            }
        },
        EXPORT: {
            ADMIN: 'admin',
            GA: 'purchase-group',
            LABO: 'labo',
            PUI: 'pharmacy',
            DRUG: 'drug',
            MEDICAL_DEVICE: 'medical-device',
            GROUP_LIST: 'purchase-group-list',
            LABO_LIST: 'labo-list',
            PUBLICATION: 'publications',
            PUBLICATION_GLOBAL: 'global-publications',
            PUBLICATION_STATS: 'publication-stats',
            PUBLICATIONS_STATS: 'publications-stats-labo',
            MARKET: 'market',
            BOOKLET: 'booklet',
            ALTERNATIVE: 'alternative'
        },
        ALTERNATIVE: {
            SIDEBAR: {
                TITLES: {
                    MAIN: 'Filters',
                    DATES: 'Dates',
                    GROUPS: 'Purchase group',
                    SUBTITLES: {
                        SELECTED_GROUPS: 'Selected purchase group'
                    }
                },
                INPUTS: {
                    CREATION: {
                        LABEL: 'Proposal date'
                    },
                    SELECTION: {
                        LABEL: 'Selection date'
                    },
                    GROUPS: {
                        LABEL: 'Select a purchase group'
                    }
                }
            }
        }
    }
};
