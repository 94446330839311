import { Utils } from '@main/services/Utils';
import { EEstablishmentType, ELoanCat, ELoanState, ELoanSubCat, } from '@mapuilabs/mpl-interfaces';
export class StatusService {
    /** @ngInject */
    constructor($filter, Access, $translate, Api, $rootScope, ModalService, LoanService, ToastService, _) {
        this.$filter = $filter;
        this.Access = Access;
        this.$translate = $translate;
        this.Api = Api;
        this.$rootScope = $rootScope;
        this.ModalService = ModalService;
        this.LoanService = LoanService;
        this.ToastService = ToastService;
        this._ = _;
        /**
         * Get the stet of the given [[ILoan]]
         * @param loan the [[ILoan]]
         * @returns {ELoanState}
         */
        this.calcStep = (loan) => {
            if (this.needAcceptation(loan) && !this.isAcceptedPrep(loan)) {
                return ELoanState.Validation;
            }
            if (!this.isConfirmed(loan)) {
                return ELoanState.Confirmation;
            }
            if (this.isPrep(loan) && !this.isReceived(loan)) {
                return ELoanState.ValidReception;
            }
            if (this.isReturn(loan)) {
                if (!this.isReturned(loan)) {
                    return ELoanState.Return;
                }
                if (this.isReturned(loan)) {
                    return ELoanState.Archive;
                }
            }
            if (this.isBill(loan)) {
                return ELoanState.Bill;
            }
        };
        /**
         * Return whether the preparation is received
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isReceived = (loan) => {
            return (this.isPrep(loan) && Utils.hasId(loan.steCheckPerson));
        };
        /**
         * Return whether the loan is already created
         * @param loan the [[Loan]] to test
         * @returns {boolean}
         */
        this.isCreated = (loan) => {
            return loan && loan.creationDate != null;
        };
        /**
         * Return whether the loan has a category
         * The category must be of type [[ELoanCat]]
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasCat = (loan) => {
            return !!loan && loan.loanCategoryCreation != null;
        };
        /**
         * Return whether the loan has a sub-category
         * The sub-category must be of type [[ELoanSubCat]]
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasSubCat = (loan) => {
            return !!loan && loan.loanSubCategoryCreation != null;
        };
        /**
         * Return whether the loan has a type
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasType = (loan) => {
            return !!loan && loan.loanTypeCreation != null;
        };
        /**
         * Return if the establishment is a hospital
         * @param hospital the [[IHospital]] to test
         * @returns {boolean}
         */
        this.isHospital = (hospital) => {
            return !!hospital && EEstablishmentType.Hospital == hospital.establishmentType;
        };
        /**
         * Return if the establishment is a pharmacy
         * @param hospital the [[IHospital]] to test
         * @returns {boolean}
         */
        this.isPharma = (hospital) => {
            return !!hospital && EEstablishmentType.Pharmacy == hospital.establishmentType;
        };
        /**
         * Return whether the current user is the lender of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.amIL = (loan) => {
            if (!loan)
                return false;
            return Utils.compareIds(this._hid, loan.lender);
        };
        /**
         * Return whether the current user is the borrower of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.amIB = (loan) => {
            if (!loan)
                return false;
            return Utils.compareIds(this._hid, loan.borrower);
        };
        /**
         * Return whether a download can be performed
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.canDl = (loan) => {
            if (!loan)
                return false;
            if (this.isMaD(loan)) {
                return false;
            }
            return this.isCreated(loan);
        };
        /**
         * Return whether the loan's borrower is an active hospital
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isBActive = (loan) => {
            if (!loan)
                return false;
            if (!loan.borrower)
                return false;
            return loan.borrower.active;
        };
        /**
         * Return whether the loan's lender is an active hospital
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isLActive = (loan) => {
            if (!loan)
                return false;
            if (!loan.lender)
                return false;
            return loan.lender.active;
        };
        /**
         * Return whether the loan is a medication loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isMed = (loan) => {
            if (!loan)
                return false;
            return ELoanSubCat.Medic == loan.loanSubCategoryCreation;
        };
        /**
         * Return whether the loan is a request
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isRequest = (loan) => {
            if (!loan)
                return false;
            return ELoanCat.Request == loan.loanCategoryCreation;
        };
        /**
         * Return whether the loan is an available
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isMaD = (loan) => {
            if (!loan)
                return false;
            return ELoanCat.Available == loan.loanCategoryCreation;
        };
        /**
         * Return whether the loan is a loan or borrow
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isLoanOrBorrow = (loan) => {
            if (!loan)
                return false;
            return ELoanCat.LoanOrBorrow == loan.loanCategoryCreation;
        };
        /**
         * Return whether the loan is a medical device loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isDM = (loan) => {
            if (!loan)
                return false;
            return ELoanSubCat.DM == loan.loanSubCategoryCreation;
        };
        /**
         * Return whether the loan is a preparation loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isPrep = (loan) => {
            if (!loan)
                return false;
            return ELoanCat.Prep == loan.loanCategoryCreation;
        };
        /**
         * Return whether the return is confirmed by the lender
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isReturnConfirmed = (loan) => {
            if (!loan)
                return false;
            return loan.confirmCommented;
        };
        /**
         * Return whether the loan need an acceptation step
         * Used only for preparations
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.needAcceptation = (loan) => {
            if (!loan)
                return false;
            return (this.isPrep(loan));
        };
        /**
         * Return whether the loan is accepted
         * Used only for preparations
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isAcceptedPrep = (loan) => {
            if (!loan)
                return false;
            return loan.inPrep != null;
        };
        /**
         * Return whether the loan is a loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isLoan = (loan) => {
            if (!loan)
                return false;
            return Utils.compareIds(loan.creaMember, loan.loanPerson);
        };
        /**
         * Return whether the loan is a borrow
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isBorrow = (loan) => {
            if (!loan)
                return false;
            // If the loan is already created then we cas check the borrowingPerson._id
            const createdLoanIsBorrow = Utils.compareIds(loan.creaMember, loan.borrowingPerson);
            // If the loan is in creation the previous condition is false and we check if the user selected the borrowing of a drug
            return !createdLoanIsBorrow && loan.loanCategoryCreation === 1 && !loan.loanTypeCreation ? true : createdLoanIsBorrow;
        };
        /**
         * Return whether the loan is returned
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isReturned = (loan) => {
            /* if (!loan) return false;
             if (loan.oldReturnDate != undefined && loan.oldReturnDate != null)
             return !!loan.oldReturnDate;
             else*/
            return !!loan.returnDate;
        };
        /**
         * Return whether the loan need a return
         * @param loan the [[Loan]] to test
         * @returns {boolean}
         */
        this.isReturn = (loan) => {
            if (!loan)
                return false;
            return loan.returnExpected;
        };
        /**
         * Return whether the loan is a bill
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isBill = (loan) => {
            if (!loan)
                return false;
            return !this.isReturn(loan);
        };
        /**
         * Return whether the lender has billed the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasLBilled = (loan) => {
            if (!loan)
                return false;
            return !!loan.billingDateLender;
        };
        /**
         * Return whether the borrower has billed the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasBBilled = (loan) => {
            if (!loan)
                return false;
            return !!loan.billingDateBorrower;
        };
        /**
         * return whether the loan was a available
         * @param {ILoan} loan
         * @returns {boolean}
         */
        this.isFromAavailable = (loan) => {
            if (!loan)
                return false;
            return !!loan.fromMAD;
        };
        /**
         * Return whether the loan has been billed
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isBilled = (loan) => {
            if (!loan)
                return false;
            return this.hasBBilled(loan) || this.hasLBilled(loan);
        };
        /**
         * Return whether the loan is confirmed
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isConfirmed = (loan) => {
            if (!loan)
                return false;
            return !!loan.confirmationDate;
        };
        /**
         * Return whether the current user is the creator of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isCreator = (loan) => {
            if (!loan)
                return false;
            if (this.amIL(loan) && this.isLoan(loan))
                return true;
            return this.amIB(loan) && this.isBorrow(loan);
        };
        /**
         * Return whether the loan has a lender
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasLender = (loan) => {
            if (!loan)
                return false;
            return Utils.hasId(loan.loanPerson);
        };
        /**
         * Return whether the loan has a borrower
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasBorrower = (loan) => {
            if (!loan)
                return false;
            return Utils.hasId(loan.borrowingPerson);
        };
        /**
         * Return whether the loan has a borrower hospital
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.hasHBorrower = (loan) => {
            if (!loan)
                return false;
            return Utils.hasId(loan.borrower);
        };
        /**
         * Return whether the loan has been force returned
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isForcedReturned = (loan) => {
            if (!loan)
                return false;
            return loan.forcedReturn;
        };
        /**
         * Return whether the loan has been force confirmed
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isForcedConfirmed = (loan) => {
            if (!loan)
                return false;
            return loan.forcedConfirm;
        };
        /**
         * Return whether the loan has been archived
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isArchive = (loan) => {
            if (!loan)
                return false;
            if (Utils.compareIds(this._hid, loan.lender) && Utils.hasId(loan.archiveLender))
                return true;
            return Utils.compareIds(this._hid, loan.borrower) && Utils.hasId(loan.archiveBorrower);
        };
        /**
         * Get the [[ILoan]] translation type
         * @param loan the [[ILoan]] to test
         * @returns {string}
         */
        this.recapType = (loan) => {
            if (!loan)
                return '';
            if (this.isMaD(loan))
                return this.$translate.instant('MAPUI.SERVICES.STATUS.TYPES.AVAILABLE');
            if (this.amIL(loan))
                return this.$translate.instant('MAPUI.SERVICES.STATUS.TYPES.LOAN');
            if (this.amIB(loan))
                return this.$translate.instant('MAPUI.SERVICES.STATUS.TYPES.BORROW');
        };
        /**
         * Get the right [[IHospital]] name.
         * @param loan
         * @returns {string}
         */
        this.centerName = (loan) => {
            if (!loan) {
                return '';
            }
            if (loan.borrower && (this.isLoan(loan) || this.isMaD(loan))) {
                if (loan.borrower.active) {
                    return loan.borrower.name;
                }
                else {
                    return this.$translate.instant('STATUS.AUTO');
                }
            } //return 'automatiquement';
            else if (loan.lender && (this.isBorrow(loan) || this.isPrep(loan))) {
                if (loan.lender.active) {
                    return loan.lender.name;
                }
                else {
                    return this.$translate.instant('STATUS.AUTO');
                }
            }
            // return 'automatiquement';
            return '';
        };
        /**
         * Return the confirmation state of the loan
         * @param loan the [[ILoan]] to test
         * @returns {string}
         */
        this.confirmation = (loan) => {
            if (!loan) {
                return {
                    text: '',
                    color: { color: 'black' },
                };
            }
            if (!loan.confirmationDate) {
                if (this.isMaD(loan) && loan.loanTypeCreation) {
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.AVAILABLE'),
                        color: { color: 'orange' },
                    };
                }
                else if (this.needAcceptation(loan) && !this.isAcceptedPrep(loan) && this.amIB(loan)) {
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.ACCEPT', { name: loan.lender.name }),
                        color: { color: 'blue' },
                    };
                }
                else if (this.needAcceptation(loan) && this.isAcceptedPrep(loan) && this.amIB(loan)) {
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.DID.ACCEPTED', { date: loan.inPrep }),
                        color: { color: 'blue' },
                    };
                }
                else if ((this.isBorrow(loan) && this.amIB(loan)) || (this.isLoan(loan) && this.amIL(loan))) {
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.CONFIRM', { name: this.centerName(loan) }),
                        color: { color: 'orange' },
                    };
                }
                else if ((this.isBorrow(loan) && this.amIL(loan)) || (this.isLoan(loan) && this.amIB(loan))) {
                    if (this.needAcceptation(loan) && !this.isAcceptedPrep(loan)) {
                        return {
                            text: this.$translate.instant('MAPUI.SERVICES.STATUS.TO.ACCEPT'),
                            color: { color: 'red' },
                        };
                    }
                    if (this.isPrep(loan) && this.isAcceptedPrep(loan)) {
                        return {
                            text: this.$translate.instant('MAPUI.SERVICES.STATUS.TO.RELEASE'),
                            color: { color: 'red' },
                        };
                    }
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.TO.CONFIRM'),
                        color: { color: 'red' },
                    };
                }
            }
            else {
                if (this.isForcedConfirmed(loan)) {
                    let prefix;
                    if (this.isPrep(loan)) {
                        prefix = this.$translate.instant('MAPUI.SERVICES.STATUS.FORCED.RELEASE');
                    }
                    else {
                        prefix = this.$translate.instant('MAPUI.SERVICES.STATUS.FORCED.CONFIRM');
                    }
                    if (this.isBorrow(loan)) {
                        return {
                            text: prefix + this.$translate.instant('MAPUI.SERVICES.STATUS.FORCED.FORCED', {
                                date: loan.confirmationDate,
                                name: loan.borrower.name,
                            }),
                            color: { color: 'green' },
                        };
                    }
                    else {
                        return {
                            text: prefix + this.$translate.instant('MAPUI.SERVICES.STATUS.FORCED.FORCED', {
                                date: loan.confirmationDate,
                                name: loan.lender.name,
                            }),
                            color: { color: 'green' },
                        };
                    }
                }
                else if (((this.isBorrow(loan) || this.isPrep(loan)) && this.amIB(loan)) || ((this.isLoan(loan) || this.isMaD(loan)) && this.amIL(loan))) {
                    if (this.isPrep(loan)) {
                        return {
                            text: this.$translate.instant('MAPUI.SERVICES.STATUS.DID.RELEASED', {
                                date: loan.inPrep,
                                name: this.centerName(loan),
                            }),
                            color: { color: 'green' },
                        };
                    }
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.DID.CONFIRMED', {
                            date: loan.confirmationDate,
                            name: this.centerName(loan),
                        }),
                        color: { color: 'green' },
                    };
                }
                else if (((this.isBorrow(loan) || this.isPrep(loan)) && this.amIL(loan)) || ((this.isLoan(loan) || this.isMaD(loan)) && this.amIB(loan))) {
                    if (this.isPrep(loan)) {
                        return {
                            text: this.$translate.instant('MAPUI.SERVICES.STATUS.DID.YOU_RELEASED', { date: loan.inPrep }),
                            color: { color: 'green' },
                        };
                    }
                    return {
                        text: this.$translate.instant('MAPUI.SERVICES.STATUS.DID.YOU_CONFIRMED', { date: loan.confirmationDate }),
                        color: { color: 'green' },
                    };
                }
            }
        };
        this.status = (loan) => {
            let obj;
            if (typeof loan == 'string' && loan[0] != '{')
                return loan;
            if (loan && typeof loan == 'string') {
                obj = JSON.parse(loan);
            }
            if (!loan) {
                return '';
            }
            if (typeof loan == 'object') {
                obj = loan;
            }
            let res = this.$translate.instant('MAPUI.SERVICES.STATUS.OK');
            if (this.needAcceptation(obj) && !this.isAcceptedPrep(obj)) {
                if (this.amIL(obj)) {
                    res = this.$translate.instant('MAPUI.SERVICES.STATUS.TO.ACCEPT');
                }
                else {
                    res = this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.ACCEPT');
                }
            }
            else if (!this.isConfirmed(obj)) {
                if (this.isPrep(obj)) {
                    res = this.$translate.instant('MAPUI.SERVICES.STATUS.TO.CONFIRM');
                }
                else {
                    if (this.amIL(obj)) {
                        res = this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.CONFIRM');
                    }
                    else {
                        res = this.$translate.instant('MAPUI.SERVICES.STATUS.TO.CONFIRM');
                    }
                }
            }
            else if (this.isReturn(obj) && !this.isReturned(obj)) {
                if (this.amIL(obj)) {
                    res = this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.RETURN');
                }
                else {
                    res = this.$translate.instant('MAPUI.SERVICES.STATUS.TO.RETURN');
                }
            }
            else if (this.isBill(obj)) {
                if (this.amIL(obj)) {
                    if (!this.isBilled(obj)) {
                        if (!this.hasLBilled(obj)) {
                            res = this.$translate.instant('MAPUI.SERVICES.STATUS.TO.BILL');
                        }
                        else {
                            res = this.$translate.instant('MAPUI.SERVICES.STATUS.WAITING.BILL');
                        }
                    }
                }
                else {
                    if (!this.isBilled(obj)) {
                        if (!this.hasBBilled(obj)) {
                            res = this.$translate.instant('MAPUI.SERVICES.STATUS.TO.SETTLE');
                        }
                        else {
                            res = this.$translate.instant('MAPUI.SERVICES.STATUS.OK');
                        }
                    }
                }
            }
            return res;
        };
        /**
         * Return the title for the download link
         * @param loan the [[ILoan]] to test
         * @returns {string}
         */
        this.dlTitle = (loan) => {
            if (!loan) {
                return '';
            }
            if (loan.returnDate) {
                return this.$translate.instant('MAPUI.SERVICES.STATUS.VOUCHERS.RETURN');
            }
            else if (!loan.returnDate && this.amIL(loan)) {
                return this.$translate.instant('MAPUI.SERVICES.STATUS.VOUCHERS.LOAN');
            }
            else if (!loan.returnDate && this.amIB(loan)) {
                return this.$translate.instant('MAPUI.SERVICES.STATUS.VOUCHERS.BORROW');
            }
            return '';
        };
        /**
         * Return whether the loan is expired
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.isExpired = (loan) => {
            return loan.expiryDate && new Date(loan.expiryDate.toString()) <= new Date();
        };
        this.createHtmlIndicator = (indicName) => {
            let indic = this._.find(this._constTypes.loanStatusIndicators, (elem) => {
                return elem.name == indicName;
            });
            if (indic)
                return `<span class="text-boxed ${indic.cssClass} font-weight-600 white-fg">${this.$translate.instant(indic.translate)}</span>`;
        };
        this.getStatusName = (loan) => {
            let res = 'ok';
            if (this.isMaD(loan)) {
                res = 'waitingAvailable';
            }
            else if (this.needAcceptation(loan) && !this.isAcceptedPrep(loan)) {
                if (this.amIL(loan)) {
                    res = 'toAccept';
                }
                else {
                    res = 'waitingAccept';
                }
            }
            else if (!this.isConfirmed(loan)) {
                if (this.isPrep(loan)) {
                    if (this.amIL(loan)) {
                        res = 'toLiberate';
                    }
                    else {
                        res = 'waitingLiberate';
                    }
                }
                else if ((this.isLoan(loan) && this.amIB(loan)) || (this.isBorrow(loan) && this.amIL(loan))) {
                    res = 'toConfirm';
                }
                else {
                    res = 'waitingConfirm';
                }
            }
            else if (this.isPrep(loan) && !this.isReceived(loan)) {
                if (this.amIB(loan)) {
                    res = 'toReceive';
                }
                else {
                    res = 'waitingReceive';
                }
            }
            else if (this.isReturn(loan)) {
                if (!this.isReturned(loan)) {
                    if (this.amIB(loan)) {
                        res = 'toReturn';
                    }
                    else {
                        res = 'waitingReturn';
                    }
                }
            }
            else if (this.isBill(loan)) {
                if (!this.hasLBilled(loan)) {
                    if (this.amIL(loan)) {
                        res = 'toBill';
                    }
                    else {
                        res = 'waitingBill';
                    }
                }
                else if (!this.hasBBilled(loan)) {
                    if (this.amIB(loan)) {
                        res = 'toSettle';
                    }
                    else {
                        res = 'waitingSettle';
                    }
                }
            }
            return res;
        };
        /**
         * Return the Status indicator of the loan
         * Used in the dashboard by datatables
         * @param loan the [[ILoan]] to test
         * @returns {string}
         */
        this.getStatusIndicator = (loan) => {
            if (!loan.status)
                loan.status = this.getStatusName(loan);
            return this.createHtmlIndicator(loan.status);
        };
        this._refreshHospital = () => {
            if (this.Access.hospital) {
                this._hid = this.Access.hospital._id;
            }
            else {
                this._hid = null;
            }
        };
        this._refreshHospital();
        this.$rootScope.$on('refreshAccess', this._refreshHospital);
        this.Api.constType.get((res) => {
            this._constTypes = res;
        });
    }
}
