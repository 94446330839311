import { ELabelType, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MpModalPrepLabelsController {
    /** @ngInject */
    constructor(ModalService, LoanService, _) {
        this.ModalService = ModalService;
        this.LoanService = LoanService;
        this._ = _;
        /**
         * Callback when the user selected or added labels
         * @param {Array<ILabel>} list
         */
        this.addLabel = (list) => {
            this.loan.prepLabels = this._.uniq(list, 'title');
        };
        /**
         * Callback when the user remove a label
         * @param {number} idx
         */
        this.removeLabel = (idx) => {
            if (this.loan.prepLabels)
                this.loan.prepLabels.splice(idx, 1);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this.ModalService.close(this.loan);
        };
        this.labelType = ELabelType.Preparations;
    }
    ;
    $onInit() {
        this.LoanService.get(Utils.getId(this.loan)).then((res) => {
            this.loan = res;
        });
    }
}
