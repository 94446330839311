export const locale = {
    lang: 'en',
    data: {
        TITLE: `Import files`,
        SUBTITLE: `Files to add`,
        TIP: `We recommend that you import files in PDF format in order to facilitate the addition of the patient's prescriptions to the medication check-up.`,
        BUTTONS: {
            ADD_FILES: `Add files`,
            SEND_FILES: `Send files`
        }
    }
};
