import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HFilesService } from './hv-patient-files.service';
import { HvPatientFilesComponent } from './hv-patient-files.component';
import { HvPatientFilesController } from './hv-patient-files.controller';
angular
    .module('hospiville.patient.files', [
    'mapui.services.toast',
    'hospiville.services.patient',
    'angularFileUpload',
])
    .config(configPatientFiles)
    .service('HFilesService', HFilesService)
    .component('hvPatientFiles', new HvPatientFilesComponent())
    .controller('HvPatientFilesController', HvPatientFilesController);
/** @ngInject */
function configPatientFiles($stateProvider) {
    $stateProvider.state('hospiville.patient-files', {
        url: '/documents',
        data: {
            access: EHospivillePermission.PatientFiles_Read,
            hasSensitiveData: true,
            askForSave: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-patient-files></hv-patient-files>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville patient-files',
    });
}
