var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
let RolesCellComponent = class RolesCellComponent {
    ngOnChanges(changes) {
        this.activeRolesMapui = [];
        this.activeRolesAdminPanel = [];
        this.activeRolesWorkspace = [];
        this.activeRolesHospiville = [];
        this.activeRolesHospistock = [];
        if (changes.member) {
            for (const role of this.member.roles) {
                switch (role.project) {
                    case 'mapui':
                        this.activeRolesMapui.push(role);
                        break;
                    case 'adminPanel':
                        this.activeRolesAdminPanel.push(role);
                        break;
                    case 'workspace':
                        this.activeRolesWorkspace.push(role);
                        break;
                    case 'hospiville':
                        this.activeRolesHospiville.push(role);
                        break;
                    case 'hospistock':
                        this.activeRolesHospistock.push(role);
                        break;
                }
            }
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], RolesCellComponent.prototype, "member", void 0);
RolesCellComponent = __decorate([
    Component({
        selector: 'roles-cell',
        template: require('./roles-cell.component.html').default,
        styles: [require('./roles-cell.component.scss')]
    })
], RolesCellComponent);
export { RolesCellComponent };
