export const locale = {
    lang: 'en',
    data: {
        UserAccount: {
            TABS: {
                INFORMATION: `General information`,
                PARAMS: `Settings`,
                MAILS: `Mailing list`,
                NOTIFICATIONS: `Notifications`
            },
            INFORMATION: {
                FIRSTNAME: `First name`,
                FAMILYNAME: `Last name`,
                GENDER: `Gender`,
                PHONE: `Phone`,
                INPP: `PP National Identification`,
                RPPS: `RPPS number`,
                JOB: {
                    TITLE: `Job`,
                    STATUS: `Status`,
                    ACTIVITIES: `Activities`
                }
            },
            SETTINGS: {
                LANGUAGE: 'Language',
                NOTIF_SOUND: 'Notifications sound',
                FORGOT_PASSWORD: 'Forgot password?',
                DOUBLE_AUTH: {
                    TITLE: 'Two Factor Authentication',
                    ADD: 'Add',
                    DELETE: 'Disable two factor authentication',
                    KEY: 'Key',
                    TOKEN: 'Token',
                    INVALID_TOKEN: 'Please enter the token',
                    CANCEL: 'Cancel',
                    VALID: 'Validate',
                    ERROR_MSG: 'Invalid token'
                },
                OTHER: 'Other'
            },
            EMAILS: {
                PREFIX: 'Receive emails about '
            },
            NOTIFICATIONS: {
                PREFIX: 'Receive notifications about '
            },
            SAVE: {
                SUCCESSFULLY: 'Profile saved'
            }
        }
    }
};
