export class HvPatientSearchController {
    /** @ngInject */
    constructor(PatientService) {
        this.PatientService = PatientService;
        /**
         * Return an array containing all matches from value
         * @param {string} value
         * @returns {angular.IPromise<Array<IPatient>>}
         */
        this.searchPatients = (value) => {
            return this.PatientService.getByName(this.ctrl.searchText);
        };
        /**
         * An [[IPatient]] is selected and returned
         */
        this.searchPatientsSelected = () => {
            if (this.ctrl.selected) {
                this.onSelected({ $event: { patient: this.ctrl.selected } });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
}
