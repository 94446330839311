var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EFileType, EHospivillePermission, EMpStatus, EPosologyType, ESignaturePattern, ESource, ETransit } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import { saveAs } from 'file-saver';
export class HvReconciliationSheetController {
    /** @ngInject */
    constructor($scope, $http, _, Api, Access, $translate, ToastService, HospivilleService, MemberService, MailsService, SourceService, ModalService, DrugInstanceService, InformationService, SecureMessagingService) {
        this.$scope = $scope;
        this.$http = $http;
        this._ = _;
        this.Api = Api;
        this.Access = Access;
        this.$translate = $translate;
        this.ToastService = ToastService;
        this.HospivilleService = HospivilleService;
        this.MemberService = MemberService;
        this.MailsService = MailsService;
        this.SourceService = SourceService;
        this.ModalService = ModalService;
        this.DrugInstanceService = DrugInstanceService;
        this.InformationService = InformationService;
        this.SecureMessagingService = SecureMessagingService;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * Let user choose a signature depending on the referee profile
         */
        this.selectedSignature = ESignaturePattern.Pharmacist;
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.hvConst || (!value && value !== 0))
                return;
            return this._.find(this.hvConst[from], { value: value }).translate;
        };
        /**
         * Save reconciliation sheet
         * @param memberId
         */
        this.saveInst = (memberId) => {
            this.HospivilleService.saveDrugBalance(this.medReconciliation.prescription)
                .then((prescription) => this.medReconciliation.prescription = prescription);
            this.HospivilleService.saveMedRec(this.medReconciliation, false, true)
                .then((saveMedRec) => {
                this.medReconciliation = saveMedRec;
                this.ToastService.show(EMpStatus.Ok);
            })
                .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            if (memberId && !Utils.compareIds(this.member, memberId)) {
                this.MemberService.get(memberId)
                    .then(member => {
                    this.member = member;
                });
            }
        };
        /**
         * Create query to send
         * @returns {string}
         */
        this.getQuery = () => {
            var _a;
            if (!this.medReconciliation || !this.hospital || !this.patient || !this.member || !this.doctor)
                return '';
            const doctor = this.seeDoctor ? ('&doctor=' + this.doctor.healthProfessional.dutyFirstName + ' ' + this.doctor.healthProfessional.dutyLastName) : '';
            return 'id=' + this.medReconciliation._id
                + '&pId=' + this.patient._id + '&hospital=' + this.hospital._id
                + '&signature=' + this.selectedSignature
                + '&pharmacist=' + ((_a = this.member) === null || _a === void 0 ? void 0 : _a.fullName)
                + `${this.member.RPPS ? `&rpps=${this.member.RPPS}` : ''}`
                + `${this.member.phone ? `&phone=${this.member.phone}` : ''}`
                + doctor + '&lang=' + this.$translate.use();
        };
        /**
         * Check if interface allows reconciliation sheet export
         */
        this.canExport = () => {
            return !!this.Access.isInterfaced();
        };
        /**
         * Check medRec initiator
         */
        this.checkInitiator = () => {
            return !!this.medReconciliation.initiator;
        };
        /**
         * Export reconciliation sheet
         */
        this.exportRecSheet = () => {
            if (this.medReconciliation.transit == null) {
                this.ModalService.show({
                    component: 'hv-modal-select-reconciliation-type',
                    bindings: {
                        medRec: this.medReconciliation
                    },
                    escapeToClose: false
                }).then((updatedMedRec) => {
                    this.HospivilleService.saveMedRec(updatedMedRec, false, true).then((medRec) => {
                        this.medReconciliation = medRec;
                        this.HospivilleService.exportSynthesisSheet(this.getQuery(), 'medRec').then((ans) => {
                            this.ToastService.show(ans);
                        });
                    }).catch((err) => {
                        console.error(err);
                    });
                });
            }
            else {
                this.HospivilleService.exportSynthesisSheet(this.getQuery(), 'medRec').then((ans) => {
                    this.ToastService.show(ans);
                });
            }
        };
        /**
         * Download reconciliation sheet as excel
         */
        this.downloadExcel = () => {
            const req = {
                method: 'GET',
                url: '/xlsx-generation/fiche-de-conciliation_'
                    + this.patient.firstName.charAt(0)
                    + this.patient.familyName.charAt(0)
                    + '.xlsx?xlsxmodel=reconciliation-sheet&id='
                    + this.medReconciliation._id + '&pid=' + this.patient._id + '&hospital=' + this.hospital._id,
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                },
                responseType: 'arraybuffer'
            };
            this.$http(req).then((data) => {
                const blob = new Blob([(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                saveAs(blob, 'fiche-de-conciliation_'
                    + this.patient.firstName.charAt(0)
                    + this.patient.familyName.charAt(0)
                    + '.xlsx');
            });
        };
        /**
         * Set doctor to null
         * @param {string} search
         */
        this.searchDoctorChanged = ({ search }) => {
            this.doctor = null;
        };
        /**
         * Set the [[IHealthProfessional]] (doctor)  source to the [[IDrugBalance]]
         * @param doctor
         */
        this.selectHealthPro = ({ doctor }) => {
            if (!this.medReconciliation.prescription.sources[0]) {
                this.medReconciliation.prescription.sources.push({ date: new Date, source: { type: ESource.Doctor } });
            }
            this.medReconciliation.prescription.sources[0].source.healthProfessional = doctor;
            this.doctor = this.medReconciliation.prescription.sources[0].source;
            if (Utils.hasId(this.medReconciliation.prescription.sources[0].source)) {
                this.SourceService.save(this.medReconciliation.prescription.sources[0].source);
            }
            else {
                this.SourceService.add(this.medReconciliation.prescription.sources[0].source).then((src) => {
                    this.medReconciliation.prescription.sources[0].source = src;
                });
            }
        };
        /**
         * Get [[IDrugBalanceSource]] name translation
         * @param src
         * @returns {string}
         */
        this.getSourceName = (src) => {
            if (!this.hvConst)
                return;
            return this._.find(this.hvConst.sources, { value: src }).translate;
        };
        /**
         * Init reconciliation sheet data
         * @private
         */
        this._init = () => {
            this.isEditable = false;
            this.ready = false;
            this.doctor = null;
            this.medReconciliation = null;
            // get medication reconciliation instance
            this.HospivilleService.getMedRec()
                .then((medRec) => {
                this.medReconciliation = medRec;
                if (this.medReconciliation && this.medReconciliation.validator
                    && this.medReconciliation.dbs && this.medReconciliation.dbs.validator) {
                    this.medReconciliation.results = this._.sortBy(this.medReconciliation.results, 'index');
                    if (this.medReconciliation.prescription && this.medReconciliation.dbs) {
                        if (this.medReconciliation.prescription
                            && this.medReconciliation.prescription.sources && this.medReconciliation.prescription.sources[0]) {
                            this.doctor = this.medReconciliation.prescription.sources[0].source;
                        }
                        this.seeDoctor = !(this.medReconciliation.transit === ETransit.Entrance);
                        this.doctor = this.seeDoctor ? this.doctor : {};
                        this.HospivilleService.getPatient()
                            .then((patient) => this.patient = patient);
                        // get staff from the current hospital
                        this.MemberService.getByHosp(this.Access.hospital._id)
                            .then((members) => {
                            this.staff = members;
                            this.member = this._.find(this.staff, (staff) => {
                                return (Utils.compareIds(staff, medRec.validator));
                            });
                        });
                        this.ready = true;
                    }
                }
            });
        };
        this.diService = this.DrugInstanceService;
        this.hospital = this.Access.hospital;
        this.member = this.Access.user;
        this.$scope.$on('refreshPatientFile', () => {
            this._init();
        });
    }
    $onInit() {
        this.canSendEmail = this.Access.authorize(EHospivillePermission.Mailbox_Create);
        this.Api.hvConstType.get((ans) => {
            this.hvConst = ans;
        });
        this.migrationState = this.Access.isHVMigrationState();
        this._init();
    }
    /**
     * Send mail to external [[IHealthProfessional]]s and [[IEstablishment]]s
     */
    sendMail() {
        return __awaiter(this, void 0, void 0, function* () {
            let mail = {};
            if (!this.canSendEmail) {
                return this.ToastService.show(EMpStatus.NoAccess);
            }
            let subject = this.$translate.instant('HOSPIVILLE.RECONCILIATION_SHEET.MAIL.SUBJECT', { transit: this.$translate.instant(this.getTranslation('transit', this.medReconciliation.transit)) });
            mail.subject = `${subject} ${this.patient.firstName} ${this.patient.familyName}`;
            mail.attachments = [{
                    name: `fiche-de-conciliation_${this.patient.firstName.charAt(0)}${this.patient.familyName.charAt(0)}.pdf`,
                    extension: 'pdf'
                }];
            let establishments = this._.map(this._.filter(this.patient.sources, (p) => {
                return p.establishment !== undefined;
            }), (s) => {
                return s.establishment;
            });
            const res = yield this.ModalService.show({
                component: 'mp-modal-compose-mail',
                bindings: {
                    recipientsList: establishments,
                    mail: mail,
                    hidden: { CC: true, BCC: true, secureSwitch: false },
                    bodyTemplate: 'SHARE_RECONCILIATION_SHEET',
                    secure: true,
                    data: {
                        transit: this.$translate.instant(this.getTranslation('transit', this.medReconciliation.transit)),
                        patient: this.patient,
                        medRec: this.medReconciliation
                    }
                },
                escapeToClose: true
            });
            try {
                if (res.firstName) {
                    yield this.MailsService.sendMssRequest(res);
                    return this.ToastService.show(EMpStatus.Ok);
                }
                res.medicationReconciliation = this.medReconciliation;
                res.hospital = this.hospital;
                const file = yield this.HospivilleService.generateRecSheetPDF(this.patient, this.getQuery() + `&fileType=${EFileType.EmailAttachment}`);
                res.mail.attachments = [file];
                if (res.onlyActivity) {
                    this.HospivilleService.uploadToActivity([file], this.patient, res);
                }
                else if (!res.isSecure) {
                    yield this.MailsService.sendReconciliationSheet(res);
                    this.ToastService.show(EMpStatus.Ok);
                }
                else {
                    if (!res.dest) {
                        for (const establishment of establishments) {
                            let finesses = [establishment.finessCode];
                            const letterBoxes = yield this.SecureMessagingService.findLetterBoxesByFiness(finesses);
                            for (let letterBox of letterBoxes) {
                                if (letterBox.letterBoxAddress === res.mail.to) {
                                    res.dest = finesses[0];
                                    yield this.MailsService.sendReconciliationSheet(res);
                                }
                            }
                            this.ToastService.show(EMpStatus.Ok);
                        }
                    }
                    else {
                        yield this.MailsService.sendReconciliationSheet(res);
                        this.ToastService.show(EMpStatus.Ok);
                    }
                }
            }
            catch (err) {
                this.ToastService.show(EMpStatus.RejectByServer);
            }
        });
    }
}
