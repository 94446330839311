export class MpTablePaginationDirective {
    /** @ngInject */
    constructor() {
        this.compile = (tElement) => {
            /**
             * Post Link
             */
            return (scope, iElement, iAttrs, ctrls) => {
                const mpTableCtrl = ctrls[0];
                scope.vm.columns = mpTableCtrl.columns;
            };
        };
        this.restrict = 'A';
        this.scope = {};
        this.template = require('./templates/mp-table-pagination-wrapper.html').default;
        this.controller = 'MpTablePaginationController';
        this.controllerAs = 'vm';
        this.require = ['^mpTable'];
        this.priority = 500;
    }
    static factory() {
        const directive = () => new MpTablePaginationDirective();
        directive.$inject = [];
        return directive;
    }
}
