export class HvModalSearchHealthProComponent {
    constructor() {
        this.controller = 'HvModalSearchHealthProController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-search-health-pro.html').default;
        this.bindings = {
            source: '<',
            title: '<'
        };
    }
}
