var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
let HsPublicationListItemInfoComponent = class HsPublicationListItemInfoComponent {
    constructor() {
        this.terminated = false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationListItemInfoComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationListItemInfoComponent.prototype, "svgIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationListItemInfoComponent.prototype, "title", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationListItemInfoComponent.prototype, "terminated", void 0);
HsPublicationListItemInfoComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-list-item-info',
        template: `
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
            <mat-icon
                class="s-20 terminated-indicator"
                [svgIcon]="svgIcon"
                [ngClass]="{ 'secondary-text': !terminated, 'green-400-fg': terminated }"
            >
                {{ icon }}
            </mat-icon>

            <strong class="text-nowrap" fxHide.lt-lg>{{ title }}</strong>

            <ng-content></ng-content>
        </div>
    `,
        changeDetection: ChangeDetectionStrategy.OnPush
    })
], HsPublicationListItemInfoComponent);
export { HsPublicationListItemInfoComponent };
