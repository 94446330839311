export class HvDosageComponent {
    constructor() {
        this.controller = 'HvDosageController';
        this.controllerAs = 'vm';
        this.template = require('./hv-dosage.html').default;
        this.bindings = {
            source: '<',
        };
    }
    ;
}
