var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '@main/services/Utils';
import { EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import { FileSaverService } from 'ngx-filesaver';
import { lastValueFrom } from 'rxjs';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslationService } from '@services/translation/translation.service';
import { locale as french } from '@app/hospistock/i18n/fr';
import { locale as english } from '@app/hospistock/i18n/en';
import * as moment from 'moment';
import { EHsHealthProductDirectoryTypes } from '../types/hs-health-product-directory.types';
import { CHsDownloadWordPressURL } from '../constTypes/hs-download.const';
let HsDownloadService = class HsDownloadService {
    constructor(_fileSaverService, _fuseTranslationLoaderService, _healthProductService, _httpClient, _translationService) {
        this._fileSaverService = _fileSaverService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._healthProductService = _healthProductService;
        this._httpClient = _httpClient;
        this._translationService = _translationService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Build methods
    // -----------------------------------------------------------------------------------------------------
    downloadImportTemplate(fileTemplateUrl) {
        // Guard: no import config
        if (!fileTemplateUrl) {
            throw new Error('Import config not provided, download import template.');
        }
        // Gather urls to get the public address to get the Excel file requested.
        const finalDownloadUrl = CHsDownloadWordPressURL + fileTemplateUrl;
        // Retrieve file
        return window.open(finalDownloadUrl);
    }
    /**
     * Download spreadsheet with all publications matching to the current user view.
     * @param search
     * @param sort
     * @param filters
     * @param publicationViewType
     */
    downloadPublicationsSpreadsheet(search, sort, filters, publicationViewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const target = `publication-${publicationViewType}-${this._healthProductService.healthProductType}`;
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const viewTypeName = this._translationService.instant(`EXPORT.${publicationViewType}`);
            const fileName = `${this._translationService.instant('EXPORT.PUBLICATION')}-${viewTypeName}-${healthProductName}`;
            // Handle params
            const paramsObj = Object.assign({ search: search || '' }, { sorting: sort !== undefined ? JSON.stringify(sort) : null }, { filters: filters !== undefined ? JSON.stringify(filters) : null });
            yield this._downloadSpreadsheet(target, fileName, new HttpParams({ fromObject: paramsObj }));
        });
    }
    /**
     * Download spreadsheet with all publications matching to the current user view.
     * @param search
     * @param sort
     * @param filters
     * @param healthProductDirectoryType
     */
    downloadProductDirectorySpreadsheet(search, sort, healthProductDirectoryType) {
        return __awaiter(this, void 0, void 0, function* () {
            const healthProductDirectoryTypeName = healthProductDirectoryType === EHsHealthProductDirectoryTypes.BOOKLET ? 'BOOKLET' : 'MARKET';
            const target = `${healthProductDirectoryTypeName.toLowerCase()}-${this._healthProductService.healthProductType}`;
            const healthProductDirectoryName = this._translationService.instant(`EXPORT.${healthProductDirectoryTypeName}`);
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const fileName = `${healthProductDirectoryName}-${healthProductName}`;
            // Handle params
            const paramsObj = {};
            if (search) {
                Object.assign(paramsObj, { search });
            }
            if (sort) {
                Object.assign(paramsObj, { sorting: JSON.stringify(sort) });
            }
            yield this._downloadSpreadsheet(target, fileName, new HttpParams({ fromObject: paramsObj }));
        });
    }
    /**
     * Download spreadsheet with all alternatives.
     * @param search
     * @param sort
     * @param filters
     * @param publicationViewType
     */
    downloadAlternativesSpreadsheet(search, sort, filters, publicationViewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const target = `alternative-${publicationViewType}-${this._healthProductService.healthProductType}`;
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const viewTypeName = this._translationService.instant(`EXPORT.${publicationViewType}`);
            const fileName = `${this._translationService.instant('EXPORT.ALTERNATIVE')}-${viewTypeName}-${healthProductName}`;
            // Handle params
            const paramsObj = Object.assign({ search: search || '' }, { sorting: sort ? JSON.stringify(sort) : '' }, { filters: filters ? JSON.stringify(filters) : '' });
            yield this._downloadSpreadsheet(target, fileName, new HttpParams({ fromObject: paramsObj }));
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Stats from the statistics page
     */
    downloadLaboPublicationsStatsSpreadsheet(search, sort) {
        const target = `publications-stats-${EHsUserTypes.LABO}-${this._healthProductService.healthProductType}`;
        const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
        const viewTypeName = this._translationService.instant(`EXPORT.${EHsUserTypes.LABO}`);
        const fileName = `${this._translationService.instant('EXPORT.PUBLICATIONS_STATS')}-${viewTypeName}-${healthProductName}-hospistock`;
        const paramsObj = {};
        if (search) {
            Object.assign(paramsObj, { search });
        }
        if (sort) {
            Object.assign(paramsObj, { sorting: JSON.stringify(sort) });
        }
        return this._downloadSpreadsheet(target, fileName, new HttpParams({ fromObject: paramsObj }));
    }
    /**
     * Download spreadsheet with statistics related to the provided publication.
     * @param publication
     * @param publicationViewType
     */
    downloadPublicationStatSpreadsheet(publication, publicationViewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const target = `publication-stats-${publicationViewType}-${this._healthProductService.healthProductType}`;
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const viewTypeName = this._translationService.instant(`EXPORT.${publicationViewType}`);
            const fileName = `${this._translationService.instant('EXPORT.PUBLICATION_STATS')}-${viewTypeName}-${healthProductName}`;
            // Handle params
            const paramsObj = Object.assign({ publicationId: Utils.getId(publication) });
            yield this._downloadSpreadsheet(target, fileName, new HttpParams({ fromObject: paramsObj }));
        });
    }
    /**
     * Download spreadsheet with statistics related to all publications.
     * @param search
     * @param sort
     * @param filters
     * @param publicationViewType
     */
    downloadGlobalStatSpreadsheet(search, sort, filters, publicationViewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const target = `publication-global-stats-${publicationViewType}`;
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const viewTypeName = this._translationService.instant(`EXPORT.${publicationViewType}`);
            const fileName = `${this._translationService.instant('EXPORT.PUBLICATION_GLOBAL')}-${viewTypeName}-${healthProductName}`;
            // Handle params
            const paramsObj = Object.assign({ search: search || '' }, { sorting: sort !== undefined ? JSON.stringify(sort) : null }, { filters: filters !== undefined ? JSON.stringify(filters) : null });
            yield this._downloadSpreadsheet(target, fileName, new HttpParams({ fromObject: paramsObj }));
        });
    }
    downloadLaboListAsSpreadsheet(publicationViewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const target = `publication-dashboard-labo-list-${publicationViewType}-${this._healthProductService.healthProductType}`;
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const viewTypeName = this._translationService.instant(`EXPORT.${publicationViewType}`);
            const fileName = `${this._translationService.instant('EXPORT.LABO_LIST')}-${viewTypeName}-${healthProductName}-hospistock`;
            yield this._downloadSpreadsheet(target, fileName);
        });
    }
    downloadGroupListAsSpreadsheet(publicationViewType) {
        return __awaiter(this, void 0, void 0, function* () {
            const target = `publication-dashboard-group-list-${publicationViewType}-${this._healthProductService.healthProductType}`;
            const healthProductName = this._translationService.instant(`EXPORT.${this._healthProductService.healthProductType}`);
            const viewTypeName = this._translationService.instant(`EXPORT.${publicationViewType}`);
            const fileName = `${this._translationService.instant('EXPORT.GROUP_LIST')}-${viewTypeName}-${healthProductName}-hospistock`;
            yield this._downloadSpreadsheet(target, fileName);
        });
    }
    _downloadSpreadsheet(url, filename, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const spreadsheetEndPoint = `/xlsx-generation/publication.xlsx?xlsxmodel=${url}`;
            const responseType = 'arraybuffer';
            const headers = new HttpHeaders({
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const res = yield lastValueFrom(this._httpClient.get(spreadsheetEndPoint, { params, headers, responseType }));
            const blob = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            filename = `${moment().format('DD-MM-YYYY')}-${filename}.xls`;
            this._fileSaverService.save(blob, filename);
        });
    }
};
HsDownloadService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof FileSaverService !== "undefined" && FileSaverService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _c : Object, typeof (_d = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object])
], HsDownloadService);
export { HsDownloadService };
