import * as angular from 'angular';
export class HvDropDisplayController {
    /** @ngInject */
    constructor() {
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.dropInfo)
            this.dropInfo = angular.copy(this.dropInfo);
        if (changes.sentence)
            this.sentence = angular.copy(this.sentence);
    }
}
