import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvPrescriptionComponent } from './hv-prescription.component';
import { HvPrescriptionController } from './hv-prescription.controller';
angular
    .module('hospiville.patient.prescription', [])
    .config(configPrescription)
    .component('hvPrescription', new HvPrescriptionComponent())
    .controller('HvPrescriptionController', HvPrescriptionController);
/** @ngInject */
function configPrescription($stateProvider) {
    $stateProvider.state('hospiville.prescription', {
        url: '/prescription',
        data: {
            access: EHospivillePermission.Prescription_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-prescription></hv-prescription>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville prescription',
    });
}
