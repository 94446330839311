export const locale = {
    lang: 'fr',
    data: {
        PATIENT: {
            CONSENT: {
                TITLE: `Consentement :`,
                NOT_REALISED: `Non réalisé `,
                OK: `OK`,
                KO: `Refusé`,
                WAITING: `En attente`,
                UNREALISABLE: `Non réalisable`
            },
            PATHWAYS: {
                OCTAVE: 'OCTAVE'
            }
        },
        DOSAGE: {
            PILL: {
                NAME: 'comprimé',
                SHORT: 'cpr'
            },
            EFFERVESCENT_TABLET: {
                NAME: 'comprimé effervescent',
                SHORT: 'cpr effervescent'
            },
            ORODISPERSIBLE_TABLET: {
                NAME: 'comprimé orodispersible',
                SHORT: 'cpr orodispersible'
            },
            CAPSULE: {
                NAME: 'gélule',
                SHORT: 'gélule'
            },
            SUPPOSITORY: {
                NAME: 'suppositoire',
                SHORT: 'suppositoire'
            },
            OVULE: {
                NAME: 'ovule',
                SHORT: 'ovule'
            },
            APPLICATION: {
                NAME: 'application',
                SHORT: 'application'
            },
            PATCH: {
                NAME: 'patch',
                SHORT: 'patch'
            },
            EYE_DROP: {
                NAME: 'goutte yeux',
                SHORT: 'goutte yeux'
            },
            DROP: {
                NAME: 'goutte',
                SHORT: 'goutte'
            },
            SYRINGE: {
                NAME: 'seringue',
                SHORT: 'seringue'
            },
            MILLILITER: {
                NAME: 'millilitre',
                SHORT: 'ml'
            },
            MILLIGRAM: {
                NAME: 'milligramme',
                SHORT: 'mg'
            },
            MICROGRAM: {
                NAME: 'microgramme',
                SHORT: 'µg'
            },
            GRAM: {
                NAME: 'gramme',
                SHORT: 'g'
            },
            LITER: {
                NAME: 'litre',
                SHORT: 'l'
            },
            AEROSOL: {
                NAME: 'aérosol',
                SHORT: 'aérosol'
            },
            INHALATION: {
                NAME: 'inhalation',
                SHORT: 'inhalation'
            },
            PUFF: {
                NAME: 'bouffée',
                SHORT: 'bouffée'
            },
            UNIT: {
                NAME: 'unité',
                SHORT: 'unité'
            },
            BAG: {
                NAME: 'sachet',
                SHORT: 'sachet'
            },
            UNIDOSE: {
                NAME: 'unidose',
                SHORT: 'unidose'
            },
            TABLESPOON: {
                NAME: 'c. à soupe',
                SHORT: 'c. à s.'
            },
            TEASPOON: {
                NAME: 'c. à café',
                SHORT: 'c. à c.'
            },
            GRANULE: {
                NAME: 'granule',
                SHORT: 'granule'
            },
            TUBE: {
                NAME: 'tube',
                SHORT: 'tube'
            },
            WASH: {
                NAME: 'lavage',
                SHORT: 'lavage'
            },
            BULB: {
                NAME: 'ampoule',
                SHORT: 'ampoule'
            },
            PELLET: {
                NAME: 'pastille',
                SHORT: 'pastille'
            },
            MEASURE_SPOON: {
                NAME: 'cuillère mesure',
                SHORT: 'c. mesure'
            },
            INTERNATIONAL_UNIT: {
                NAME: 'unité internationale',
                SHORT: 'UI'
            },
            POUCH: {
                NAME: 'poche',
                SHORT: 'poche'
            },
            INJECTION: {
                NAME: 'injection',
                SHORT: 'injection'
            },
            BOTTLE: {
                NAME: 'flacon',
                SHORT: 'flacon'
            },
            POT: {
                NAME: 'pot',
                SHORT: 'pot'
            },
            DOSE: {
                NAME: 'dose',
                SHORT: 'dose'
            }
        },
        POSOLOGY_TYPE: {
            MORNING: 'matin',
            NOON: 'midi',
            EVENING: 'soir',
            BEDTIME: 'au coucher',
            M_N_E: 'matin - midi - soir',
            M_N_E_B: 'matin - midi - soir - coucher',
            EVERY_4H: 'toutes les 4h',
            EVERY_6H: 'toutes les 6h',
            EVERY_8H: 'toutes les 8h',
            M_E: 'matin - soir',
            M_N: 'matin - midi',
            N_E: 'midi - soir',
            DAY: 'jour',
            SNACK: 'goûter',
            OTHER: 'autre'
        },
        MEDREC: {
            STATUS: {
                ON_GOING: 'En cours',
                TO_VALIDATE: 'À valider',
                VALIDATED: 'Validé'
            },
            TRANSIT: {
                ENTRANCE: "Conciliation d'admission",
                TRANSFER: 'Conciliation de transfert',
                EXIT: 'Conciliation de sortie'
            },
            PROCESS: {
                PROACTIVE: 'Proactif',
                RETROACTIVE: 'Rétroactif'
            }
        }
    }
};
