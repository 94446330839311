var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { WidgetRequestService } from '@services/homepage/widgets/request/widget-request.service';
import { DashboardService } from '@main/services/dashboard/dashboard.service';
let RequestWidgetComponent = class RequestWidgetComponent {
    constructor(_fuseTranslationLoaderService, _widgetRequestService, _dashboardService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._widgetRequestService = _widgetRequestService;
        this._dashboardService = _dashboardService;
        this.openUpdateModal = (event, loan) => {
            event.stopPropagation();
            this._dashboardService.openLoanUpdateModal(event, loan).then(() => {
                this._refresh();
            });
        };
        this._refresh = () => {
            this.getRequest();
        };
        this._fuseTranslationLoaderService.loadTranslations(english, french);
    }
    ngOnInit() {
        this.getRequest();
    }
    getRequest() {
        this._widgetRequestService.getLastRequest(10).subscribe((requests) => {
            this.requests = requests;
        }, (error) => {
            if (error.status == 403) {
                this.forbiddenAccess = true;
            }
        });
    }
};
RequestWidgetComponent = __decorate([
    Component({
        selector: 'mpx-request-widget',
        template: require('./request-widget.component.html').default,
        styles: [require('./request-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof WidgetRequestService !== "undefined" && WidgetRequestService) === "function" ? _b : Object, typeof (_c = typeof DashboardService !== "undefined" && DashboardService) === "function" ? _c : Object])
], RequestWidgetComponent);
export { RequestWidgetComponent };
