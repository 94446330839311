var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Injector, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Layout } from '../widgets';
let WidgetArrangementComponent = class WidgetArrangementComponent {
    constructor(mediaObserver, _injector) {
        this.mediaObserver = mediaObserver;
        this._injector = _injector;
        this.widgetsByColumn = [];
        this.columnNumber = 3;
        this.watcher = mediaObserver.asObservable().subscribe(() => {
            if (mediaObserver.isActive('xl')) {
                this.columnNumber = 3;
            }
            else if (mediaObserver.isActive('md') || mediaObserver.isActive('lg')) {
                this.columnNumber = 2;
            }
            else if (mediaObserver.isActive('lt-md')) {
                this.columnNumber = 1;
            }
            this._loadWidgets();
        });
    }
    ngOnChanges(changes) {
        if (changes.layout.previousValue) {
            this._loadWidgets();
        }
    }
    ngOnDestroy() {
        this.watcher.unsubscribe();
    }
    /**
     * Bind local injector to widget's injector as parent to access inherited providers.
     * This may be required when an injectable is only provided in the parents components and are not accessibles in root.
     * @param injector Injector on which the local Injector will be bound.
     * @returns Updated injector provided in arguments.
     */
    bindInheritedInjector(injector) {
        return injector ? Object.assign(injector, { parent: this._injector }) : this._injector;
    }
    _loadWidgets() {
        this.widgetsByColumn = [];
        for (let i = 0; i < this.columnNumber; i++) {
            this.widgetsByColumn.push([]);
        }
        const column = this.columnNumber - 1;
        for (let i = 0; i < this.layout.widgets.length; i++) {
            const key = Object.keys(this.layout.widgets[i].weightByColumn[column])[0];
            if (parseInt(key) < 0) {
                continue;
            }
            let insertAt = 0;
            while (insertAt < this.widgetsByColumn[key].length) {
                if (this.layout.widgets[i].weightByColumn[column][key] <
                    this.widgetsByColumn[key][insertAt].weightByColumn[column][key]) {
                    break;
                }
                insertAt++;
            }
            this.widgetsByColumn[key].splice(insertAt, 0, this.layout.widgets[i]);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Layout !== "undefined" && Layout) === "function" ? _c : Object)
], WidgetArrangementComponent.prototype, "layout", void 0);
WidgetArrangementComponent = __decorate([
    Component({
        selector: 'widget-arrangement',
        template: require('./widget-arrangement.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _a : Object, typeof (_b = typeof Injector !== "undefined" && Injector) === "function" ? _b : Object])
], WidgetArrangementComponent);
export { WidgetArrangementComponent };
