import { ELoanCat, ELoanState } from '@mapuilabs/mpl-interfaces';
/**
 * Store all conditions for the mpForm modal
 */
export class ModalLoanConditions {
    /** @ngInject */
    constructor(StatusService, Access) {
        this.StatusService = StatusService;
        this.Access = Access;
        /**
         * Return if [[ILoan]] do not need to fill valid step.
         * @param loan the [[ILoan]]
         * @param state the [[ELoaState]]
         * @returns {boolean}
         */
        this.isValidFieldsDisabled = (loan, state) => {
            return this.StatusService.amIB(loan) || state != ELoanState.Validation;
        };
        /**
         * Return if validation [[ELoanState]] and active [[IHospital]] is lender
         * @param loan the [[ILoan]]
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.seeValidAction = (loan, state) => {
            return this.StatusService.amIL(loan) && state == ELoanState.Validation;
        };
        /**
         * Return if return [[ELoanState]] and active [[IHospital]] is borrower
         * @param loan the [[ILoan]]
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.seeReturnActions = (loan, state) => {
            return (state == ELoanState.Return && this.StatusService.amIB(loan));
        };
        /**
         * Return if not return [[ELoanState]] or active [[IHospital]] is lender
         * @param loan the [[ILoan]]
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.isReturnFieldsDisabled = (loan, state) => {
            if (this.StatusService.amIL(loan)) {
                return true;
            }
            return state != ELoanState.Return;
        };
        /**
         * Return if not confirmation [[ELoanState]]
         * or active [[IHospital]] is borrower and [[ILoan]] type is preparation
         * or active [[IHospital]] is lender and [[ILoan]] type is loan
         * or active [[IHospital]] is borrower and [[ILoan]] type is borrowing
         * @param loan the [[ILoan]]
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.isConfirmFieldsDisabled = (loan, state) => {
            if (state != ELoanState.Confirmation) {
                return true;
            }
            if (this.StatusService.isPrep(loan)) {
                return this.StatusService.amIB(loan);
            }
            if (this.StatusService.amIL(loan) && this.StatusService.isLoan(loan)) {
                return true;
            }
            return this.StatusService.amIB(loan) && this.StatusService.isBorrow(loan);
        };
        /**
         * Return if confirmation [[ELoanState]] and [[ILoan]] type is preparation and active [[IHospital]] is lender
         * or active [[IHospital]] is lender and [[ILoan]] type is borrowing
         * or active [[IHospital]] is borrower and [[ILoan]] type is loan
         * @param loan the [[ILoan]]
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.seeConfirmAction = (loan, state) => {
            if (state == ELoanState.Confirmation) {
                if (this.StatusService.isPrep(loan)) {
                    return this.StatusService.amIL(loan);
                }
                if (this.StatusService.amIL(loan) && this.StatusService.isBorrow(loan)) {
                    return true;
                }
                if (this.StatusService.amIB(loan) && this.StatusService.isLoan(loan)) {
                    return true;
                }
            }
            return false;
        };
        this.isCreatedAsLoan = (loan) => {
            return loan.loanTypeCreation;
        };
        this.canSelectTypeCreation = (loan, isLoan) => {
            if (ELoanCat.LoanOrBorrow == loan.loanCategoryCreation) {
                return isLoan ? this.Access.canCreateLoanMedInst() : this.Access.canCreateBorrowingMedInst();
            }
            if (ELoanCat.Prep == loan.loanCategoryCreation) {
                return isLoan ? this.Access.canCreateLoanPrepInst() : this.Access.canCreateBorrowingPrepInst();
            }
        };
        /**
         * Check if the current user can perform the action
         * @param action The [[ELoanCat]] to check
         * @returns {boolean}
         */
        this.canDoAction = (action) => {
            if (ELoanCat.LoanOrBorrow == action) {
                return this.Access.canCreateInst();
            }
            if (ELoanCat.Available == action) {
                return this.Access.canDoMAD();
            }
            if (ELoanCat.Prep == action) {
                return this.Access.canDoPrep();
            }
            if (ELoanCat.Request == action) {
                return this.Access.canDoRequest();
            }
            return false;
        };
        /**
         * Return whether the user can see sub-actions
         * @param loan The [[ILoan]] to check
         * @return {boolean}
         */
        this.canSeeSubCat = (loan) => {
            return !this.StatusService.isPrep(loan);
        };
        /**
         * Return if the user can select a sub-action
         * @param loan The [[ILoan]] to check
         * @return {boolean}
         */
        this.canSelectSubCat = (loan) => {
            return this.StatusService.hasCat(loan);
        };
        /**
         * Return if the current user can perform a loan
         * @param isLoan a boolean that indicates if the loan is a loan or a borrow
         * @param loan The [[ILoan]] to check
         * @return {boolean}
         */
        this.canLend = (isLoan, loan) => {
            if (isLoan && this.StatusService.isPrep(loan)) {
                return false;
            }
            else if (!isLoan && this.StatusService.isMaD(loan)) {
                return false;
            }
            return true;
        };
        /**
         * Return if the current user can forced validation
         * @param loan The [[ILoan]] to check
         * @return {boolean}
         */
        this.canForceValidation = (loan) => {
            return (!this.StatusService.isRequest(loan) || !this.StatusService.isMaD(loan))
                && (this.StatusService.needAcceptation(loan) && !this.StatusService.isAcceptedPrep(loan))
                && (this.StatusService.isPrep(loan) && this.StatusService.amIB(loan));
        };
        /**
         * Return if the current user can forced confirmation
         * @param loan The [[ILoan]] to check
         * @return {boolean}
         */
        this.canForceConfirm = (loan) => {
            if (this.StatusService.isRequest(loan) || this.StatusService.isMaD(loan))
                return false;
            if (!this.StatusService.isConfirmed(loan)) {
                if (this.StatusService.isPrep(loan)) {
                    return (this.StatusService.amIB(loan));
                }
                else if ((this.StatusService.amIL(loan) && this.isLoan(loan)) || (this.StatusService.amIB(loan) && this.isBorrow(loan))) {
                    return true;
                }
            }
            return false;
        };
        /**
         * Return if the current user can forced return
         * @param loan The [[ILoan]] to check
         * @return {boolean}
         */
        this.canForceReturn = (loan) => {
            return (!this.StatusService.isRequest(loan) || !this.StatusService.isMaD(loan))
                && !this.StatusService.isReturned(loan)
                && this.StatusService.amIL(loan);
        };
        /**
         * Return if the [[ILoan]] is a request
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isRequest = (loan) => {
            return this.StatusService.isRequest(loan);
        };
        /**
         * Return if the [[ILoan]] is a medication
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isMed = (loan) => {
            return this.StatusService.isMed(loan);
        };
        /**
         * Return if the [[ILoan]] is a medical device
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isDM = (loan) => {
            return this.StatusService.isDM(loan);
        };
        /**
         * Return if the [[ILoan]] is a preparation
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isPrep = (loan) => {
            return this.StatusService.isPrep(loan);
        };
        /**
         * Return if the [[ILoan]] is a loan
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isLoan = (loan) => {
            return this.StatusService.isLoan(loan);
        };
        /**
         * Return if the [[ILoan]] is a borrow
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isBorrow = (loan) => {
            return this.StatusService.isBorrow(loan);
        };
        /**
         * Return if the [[ILoan]] need a return
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isReturn = (loan) => {
            return this.StatusService.isReturn(loan);
        };
        /**
         * Return whether the current user is the lender of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.amIL = (loan) => {
            return this.StatusService.amIL(loan);
        };
        /**
         * Return whether the current user is the borrower of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this.amIB = (loan) => {
            return this.StatusService.amIB(loan);
        };
        /**
         * Return if the [[ILoan]] rollback the validation
         * @param loan The [[ILoan]] to check
         * @param state The [[ELoanState]] of The [[ILoan]]
         * @returns {boolean}
         */
        this.canRollbackValidation = (loan, state) => {
            return state == ELoanState.Confirmation && this.StatusService.amIL(loan) && this.StatusService.isAcceptedPrep(loan);
        };
        /**
         * Return if the [[ILoan]] rollback the confirmation
         * @param loan The [[ILoan]] to check
         * @param state The [[ELoanState]] of The [[ILoan]]
         * @returns {boolean}
         */
        this.canRollbackConfirmation = (loan, state) => {
            return (state == ELoanState.Return || (state == ELoanState.Bill && !this.StatusService.isPrep(loan)) || state == ELoanState.ValidReception)
                && ((this.StatusService.amIL(loan) && this.StatusService.isBorrow(loan)) || (this.StatusService.amIB(loan) && this.StatusService.isLoan(loan)))
                && (this.StatusService.isConfirmed(loan) || loan.forcedConfirm);
        };
        /**
         * Return if the [[ILoan]] rollback the return
         * @param loan The [[ILoan]] to check
         * @param state The [[ELoanState]] of The [[ILoan]]
         * @returns {boolean}
         */
        this.canRollbackReturn = (loan, state) => {
            return state != ELoanState.Return && this.StatusService.amIB(loan) && this.StatusService.isReturned(loan);
        };
        /**
         * Return if the [[ILoan]] rollback the return confirmation
         * @param loan The [[ILoan]] to check
         * @param state The [[ELoanState]] of The [[ILoan]]
         * @returns {boolean}
         */
        this.canRollbackReturnConfirmation = (loan, state) => {
            return state != ELoanState.ReturnValidation && this.StatusService.amIL(loan) && this.StatusService.isReturnConfirmed(loan);
        };
        /**
         * Return if the [[ILoan]] rollback the confirm command
         * @param loan The [[ILoan]] to check
         * @param state The [[ELoanState]] of The [[ILoan]]
         * @returns {boolean}
         */
        this.canRollbackReceiveConfirm = (loan, state) => {
            return state != ELoanState.ValidReception && this.StatusService.amIB(loan) && this.StatusService.isReceived(loan);
        };
        /**
         * Return if the [[ILoan]] is a loan
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.needConfirmLoan = (loan) => {
            return !this.isPrep(loan) && ((this.isLoan(loan) && this.StatusService.amIB(loan)) || (this.isLoan(loan) && this.StatusService.amIL(loan)));
        };
        /**
         * Return if the [[ILoan]] is a borrowing
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.needConfirmBorrow = (loan) => {
            return !this.isPrep(loan) && ((this.isBorrow(loan) && this.StatusService.amIL(loan)) || (this.isBorrow(loan) && this.StatusService.amIB(loan)));
        };
        /**
         * Return if the [[ILoan]] is a borrowing or a loan
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.showLoanOrBorrow = (loan) => {
            return (!this.StatusService.isMaD(loan) && !this.isRequest(loan));
        };
        /**
         * Return if the [[ILoan]] is an available
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isAvailable = (loan) => {
            return this.StatusService.isMaD(loan);
        };
        /**
         * Return if the creation of the [[ILoan]] need the sixth step
         * @param loan The [[ILoan]] to check
         * @returns {boolean}
         */
        this.isInstance = (loan) => {
            return this.StatusService.isLoanOrBorrow(loan);
        };
        /**
         * Return if need to select a [[IHospital]]
         * @param loan
         * @returns {boolean}
         */
        this.needHospitalSelection = (loan) => {
            /*    if (this.StatusService.isCreated(loan))
             return false;*/
            return (!this.isRequest(loan) && !this.isAvailable(loan));
        };
        /**
         * Return if need to show confirmation
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.showConfirmSubmit = (state) => {
            return ELoanState.Confirmation == state;
        };
        /**
         * Return if need to show validation
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.showValidSubmit = (state) => {
            return ELoanState.Validation == state;
        };
        /**
         * Return if need to show return validation
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.showReturnValidSubmit = (state) => {
            return ELoanState.ReturnValidation == state;
        };
        /**
         * Return if need to show return
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.showReturnSubmit = (state) => {
            return ELoanState.Return == state;
        };
        /**
         * Return if need to show validation reception
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.showValidRecepSubmit = (state) => {
            return ELoanState.ValidReception == state;
        };
        /**
         * Return if the [[ILoan]] can do return validation step
         * @param loan the [[ILoan]]
         * @param state the [[ELoanState]]
         * @returns {boolean}
         */
        this.canReturnValid = (loan, state) => {
            return this.StatusService.amIL(loan) && state == ELoanState.ReturnValidation;
        };
        /**
         * Return if the [[ILoan]] can do confirmation step
         * @param loan the [[ILoan]]
         * @param state the [[ELoanStep]]
         * @returns {boolean}
         */
        this.canConfirmCommand = (loan, state) => {
            return this.StatusService.isPrep(loan) && this.StatusService.amIB(loan) && state == ELoanState.ValidReception;
        };
    }
}
