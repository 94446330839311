var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PageLayoutComponent } from '../page-layout/page-layout.component';
var ESidebarHeaderSizes;
(function (ESidebarHeaderSizes) {
    ESidebarHeaderSizes["S"] = "h-92";
    ESidebarHeaderSizes["M"] = "h-116";
    ESidebarHeaderSizes["L"] = "h-140";
})(ESidebarHeaderSizes || (ESidebarHeaderSizes = {}));
let SidebarComponent = class SidebarComponent {
    constructor(pageLayoutComponent) {
        this.pageLayoutComponent = pageLayoutComponent;
        // Subjects
        this._unsubscribeAll = new Subject();
        // Event emitters
        this.openedChanged = new EventEmitter();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this.headerHeight$ = this.pageLayoutComponent.isCompactViewMode.pipe(takeUntil(this._unsubscribeAll), map((isCompactViewMode) => {
            return isCompactViewMode
                ? this.pageLayoutComponent.displayCompactViewFullTopBg
                    ? ESidebarHeaderSizes.L
                    : ESidebarHeaderSizes.S
                : ESidebarHeaderSizes.M;
        }));
    }
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SidebarComponent.prototype, "name", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_b = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _b : Object)
], SidebarComponent.prototype, "openedChanged", void 0);
SidebarComponent = __decorate([
    Component({
        selector: 'mpx-sidebar',
        template: require('./sidebar.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(PageLayoutComponent)),
    __metadata("design:paramtypes", [typeof (_a = typeof PageLayoutComponent !== "undefined" && PageLayoutComponent) === "function" ? _a : Object])
], SidebarComponent);
export { SidebarComponent };
