import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class AuthorSpecialityService {
    /** @ngInject */
    constructor(Api, $q) {
        this.Api = Api;
        this.$q = $q;
        this.getAll = () => {
            const defer = this.$q.defer();
            this.Api.authorSpeciality.all({}, (ans) => {
                defer.resolve(ans);
            }, () => {
                defer.reject(EMpStatus.RejectByServer);
            });
            return defer.promise;
        };
    }
}
