var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { Group } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { GroupService } from '@services/group/group.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { lastValueFrom } from 'rxjs';
let MembersTabComponent = class MembersTabComponent {
    constructor(_dialogService, _translationService, _snackBarService, _groupService) {
        this._dialogService = _dialogService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._groupService = _groupService;
        this.memberForm = new UntypedFormControl();
    }
    ngOnChanges(changes) {
        if (changes.group) {
            this.dataSource = new MatTableDataSource(this.group.members);
            this.displayedColumns = this.isUserAdmin ? ['name', 'action'] : ['name'];
        }
    }
    updateDatasource() {
        this.dataSource = new MatTableDataSource(this.group.members);
    }
    removeMember(member) {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogConfig = {
                title: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_MEMBER.TITLE'),
                message: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_MEMBER.MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            const data = yield lastValueFrom(this._dialogService.openConfirmDialog(dialogConfig));
            if (!data) {
                return;
            }
            try {
                yield lastValueFrom(this._groupService.removeGroupMember(this.group, member));
                const index = this.group.members.indexOf(member);
                if (index !== -1) {
                    this.group.members.splice(index, 1);
                }
                this.updateDatasource();
                this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.DELETE_USER_OK'));
            }
            catch (_a) {
                this._snackBarService.openDefaultError();
            }
        });
    }
    addMember() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const member = yield lastValueFrom(this._groupService.addGroupMember(this.group, this.selectedMember));
                if (!this.group.members || !this.group.members.length) {
                    this.group.members = [];
                }
                this.group.members.push(member);
                this.closeMenu();
                this.updateDatasource();
                this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.ADD_USER_OK'));
            }
            catch (_a) {
                this._snackBarService.openDefaultError();
            }
        });
    }
    closeMenu() {
        this.menu.closeMenu();
        this.selectedMember = null;
        this.memberForm.reset();
    }
    onSelectNewMember(futureMember) {
        if (futureMember) {
            this.selectedMember = futureMember;
        }
    }
    isMemberAdmin(member) {
        return this.group.administrators.some((admin) => admin._id === member._id);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MembersTabComponent.prototype, "isUserAdmin", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Group !== "undefined" && Group) === "function" ? _e : Object)
], MembersTabComponent.prototype, "group", void 0);
__decorate([
    ViewChild('selectMemberMenuTrigger', { static: false }),
    __metadata("design:type", typeof (_f = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _f : Object)
], MembersTabComponent.prototype, "menu", void 0);
MembersTabComponent = __decorate([
    Component({
        selector: 'mpx-members-tab',
        template: require('./members-tab.component.html').default,
        styles: [require('./members-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof GroupService !== "undefined" && GroupService) === "function" ? _d : Object])
], MembersTabComponent);
export { MembersTabComponent };
