var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import { locale as english, locale as french } from '@app/hospistock/i18n/en';
import { CHsDrugPublicationListItemInfos, CHsMedicalDevicePublicationListItemInfos } from '@app/hospistock/shared/constTypes/publication/hs-publication-list-item-info.const';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { IdentityService } from '@app/services/identity/identity.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EHsHealthProductTypes, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
let HsPublicationListItemComponent = class HsPublicationListItemComponent {
    constructor(_changeDetectorRef, _fuseTranslationLoaderService, _healthProductService, _identityService, _publicationContainerService) {
        this._changeDetectorRef = _changeDetectorRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._healthProductService = _healthProductService;
        this._identityService = _identityService;
        this._publicationContainerService = _publicationContainerService;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        // Init constants
        this.isLaboViewType = this._publicationContainerService.viewType === EHsUserTypes.LABO;
        this.readonly = this._publicationContainerService.readonly;
        switch (this.healthProductType) {
            case EHsHealthProductTypes.DRUG:
                this.infos = CHsDrugPublicationListItemInfos;
                break;
            case EHsHealthProductTypes.MEDICAL_DEVICE:
                this.infos = CHsMedicalDevicePublicationListItemInfos;
                break;
            default:
                break;
        }
    }
    onClick() {
        // Read publication on component click
        this._publicationContainerService.readPublication(Utils.getId(this._publication));
    }
    get currentPublicationContainer() {
        var _a;
        return (_a = this._publicationContainerService.currentPublicationContainer$$.value) === null || _a === void 0 ? void 0 : _a.value;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    get _metadata() {
        return this.publicationContainer$.value.metadata;
    }
    get _publication() {
        return this.publicationContainer$.value.publication;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Update isChecked on checkedPublicationContainers changes
        this._publicationContainerService.checkedPublicationContainers$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            // Update isChecked
            this._updateIsChecked();
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
        // Update isCurrent on currentPublicationContainer changes
        this._publicationContainerService.currentPublicationContainer$$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            // Update isCurrent
            this._updateIsCurrent();
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
        // Update isCurrent & isUnread on publicationContainer changes
        this.publicationContainer$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            var _a, _b, _c, _d;
            // Update isCurrento
            this._updateIsCurrent();
            // Update isImportant
            this.isImportant = (_b = (_a = this._metadata) === null || _a === void 0 ? void 0 : _a.static) === null || _b === void 0 ? void 0 : _b.important;
            // Update isChecked
            this._updateIsChecked();
            // Update isPfizerLaboExp
            this.isPfizerLaboExp = this._publicationContainerService.isPfizerLaboExp(this._publication);
            // Compute tags
            this.computedTags = this._publicationContainerService.computeTags(this._metadata);
            // Update isNewPublication && isUpdatedPublication
            if (!this.isLaboViewType) {
                const userVersions = this._metadata.static.members.find((member) => {
                    return Utils.getId(this._identityService.user) === Utils.getId(member.member);
                });
                const lastReadVersion = (_d = (_c = userVersions === null || userVersions === void 0 ? void 0 : userVersions.read) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.number;
                if (!lastReadVersion) {
                    this.isNewPublication = true;
                }
                else {
                    this.isNewPublication = false;
                    this.isUpdatedPublication = this._publication.versions[0].number > lastReadVersion;
                }
                this.otherReaders = this._metadata.static.members
                    .filter((member) => {
                    return Utils.getId(this._identityService.user) !== Utils.getId(member.member);
                })
                    .filter((member) => {
                    var _a, _b;
                    return ((_b = (_a = member.read) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.number) === this._publication.versions[0].number;
                })
                    .map((member) => member.member['fullName'])
                    .join('\n');
            }
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _updateIsCurrent() {
        var _a;
        this.isCurrent = Utils.compareIds(this._publication, (_a = this.currentPublicationContainer) === null || _a === void 0 ? void 0 : _a.publication);
    }
    _updateIsChecked() {
        this.isChecked = !!this._publicationContainerService.checkedPublicationContainers$.value.find(({ publication }) => Utils.getId(publication) === Utils.getId(this._publication));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Check if the publication is terminated
     * @param publication HsPublication to process
     * @returns Is publication terminated
     */
    isPublicationTerminated(publication) {
        return this._publicationContainerService.isPublicationTerminated(publication);
    }
    isFuturePublication(publication) {
        return this._publicationContainerService.isFuturePublication(publication);
    }
    onCheckedChange() {
        this._publicationContainerService.toggleCheckedPublication(Utils.getId(this._publication));
    }
    /**
     * Get the publication source depending of the publication creatorType
     * @param publication HsPublication to process
     * @returns HsPublication source
     */
    getPublicationSource(publication) {
        return publication.creatorType === EHsUserTypes.GA
            ? publication.creatorGroup.name
            : publication.creator.name;
    }
    setImportant(event) {
        return __awaiter(this, void 0, void 0, function* () {
            // Prevent propagation
            event.stopPropagation();
            yield this._publicationContainerService.setMetadataImportant(Utils.getId(this._publication), this._metadata);
        });
    }
    getTypeLabel(value) {
        return this._publicationContainerService.getTypeLabel(value);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationListItemComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof BehaviorSubject !== "undefined" && BehaviorSubject) === "function" ? _f : Object)
], HsPublicationListItemComponent.prototype, "publicationContainer$", void 0);
__decorate([
    HostListener('click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], HsPublicationListItemComponent.prototype, "onClick", null);
HsPublicationListItemComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-list-item',
        template: require('./hs-publication-list-item.component.html').default,
        styles: [require('./hs-publication-list-item.component.scss')],
        animations: fuseAnimations,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _e : Object])
], HsPublicationListItemComponent);
export { HsPublicationListItemComponent };
