export const locale = {
    lang: 'en',
    data: {
        PERMISSION_VIEW: {
            TITLE: `Last stockouts`,
            SINCE: 'Since ',
            TO: 'Until',
            SEE_MORE: 'See more stockouts',
            NO_STOCKOUT: 'No recent stockouts',
            FORBIDDEN_MESSAGE: 'Access denied',
            CONTACT_US: 'Contact us'
        }
    }
};
