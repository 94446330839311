export const locale = {
    lang: 'en',
    data: {
        STATS_HOSPIVILLE: {
            HEADER: {
                TITLE: 'Hospiville statistics - Activities',
                START_DATE: 'Start date',
                END_DATE: 'End date'
            },
            HOSPITALS_TABLE: {
                SEARCH: 'Search',
                HOSPITAL: 'Hospital',
                QUANTITY: 'Quantity',
                LAST_ACTIVITY: 'Last'
            },
            WIDGET_TOTAL_ACTIVITIES: {
                TITLE: 'Total of activities'
            },
            WIDGET_PERIOD_ACTIVITIES: {
                TITLE: 'Activities on the period'
            },
            GRAPH_CREATION: {
                TITLE: 'Evolution of activity creation',
                CURVES: {
                    TOTAL: 'Total'
                },
                Y_AXIS: 'Number of activity creation'
            },
            GRAPH_TIME: {
                TITLE: 'Average duration of activities (average : {{ average }} minutes)',
                CURVES: {
                    ACTIVITIES: 'Activities',
                    DRUG_BAL: 'Drug balance'
                },
                Y_AXIS: 'Duration of activities (in minutes)'
            }
        }
    }
};
