import * as angular from 'angular';
import { ESource, EHospivillePermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class HvPatientInformationController {
    /** @ngInject */
    constructor(_, $scope, $state, Api, Access, ModalService, PatientService, ToastService, Auth) {
        this._ = _;
        this.$scope = $scope;
        this.$state = $state;
        this.Api = Api;
        this.Access = Access;
        this.ModalService = ModalService;
        this.PatientService = PatientService;
        this.ToastService = ToastService;
        this.Auth = Auth;
        /**
         * @type {ESource}
         */
        this.ESource = ESource;
        this.mergeConfig = {
            lines: [
                {
                    label: 'HOSPIVILLE.PATIENT_FILE.INFORMATION.INPUTS.FIRSTNAME',
                    attribute: 'data1'
                },
                {
                    label: 'HOSPIVILLE.PATIENT_FILE.INFORMATION.INPUTS.LASTNAME',
                    attribute: 'data2'
                },
                {
                    label: 'HOSPIVILLE.PATIENT_FILE.INFORMATION.INPUTS.USUALNAME',
                    display: (obj) => {
                        if (obj.data3)
                            return obj.data3.nested;
                        return null;
                    },
                    get: (obj) => {
                        if (obj.data3)
                            return obj.data3.nested;
                        return null;
                    },
                    set: (dest, source) => {
                        if (!dest.data3)
                            dest.data3 = {};
                        dest.data3.nested = source.data3.nested;
                    }
                }
            ],
            sources: [
                { name: 'Source A' },
                { name: 'Source B' }
            ]
        };
        this.mergeSources = [
            {
                data1: 'data1',
                data2: 'data2',
                data3: { nested: 'data3' }
            },
            {
                data1: 'data1-B',
                data2: 'data2-B',
                data3: { nested: 'data3-B' }
            }
        ];
        /**
         * Set the given [[ISource]]
         * @param source
         * @param index
         */
        this.sourceChange = ({ source }, index) => {
            let changed = false;
            this.change = true;
            if (index != null) {
                this.patient.sources.splice(index, 1, angular.copy(source));
            }
            else {
                if (!this.patient.sources) {
                    this.patient.sources = [];
                }
                for (let i = 0; i < this.patient.sources.length; ++i) {
                    if (source.type === this.patient.sources[i].type) {
                        changed = true;
                        this.patient.sources[i] = source;
                        break;
                    }
                }
                if (!changed) {
                    this.patient.sources.push(source);
                }
            }
        };
        /**
         * Open Modal, edit Compliance Test
         */
        this.editTest = () => {
            if (!this.patient.complianceTest) {
                this.patient.complianceTest = [];
            }
            this.ModalService.show({
                component: 'hv-modal-compliance-test',
                bindings: {
                    test: this.patient.complianceTest
                },
                escapeToClose: true
            }).then((res) => {
                this.patient.complianceTest = res;
                this.change = true;
            });
        };
        /**
         * Open modal to collect patient consent
         */
        this.editConsent = () => {
            this.ModalService.show({
                component: 'hv-modal-consent',
                bindings: {
                    consent: this.patient.consent
                },
                escapeToClose: true
            }).then((consent) => {
                this.patient.consent = consent;
                // this.patient = angular.copy(this.patient);
                this.change = true;
                this.save();
            });
        };
        /**
         * Get Compliance Test score
         * @returns {number}
         */
        this.getScore = () => {
            if (!this.patient || !this.patient.complianceTest || !this.patient.complianceTest.length)
                return;
            return this._.countBy(this.patient.complianceTest, (val) => {
                return val ? 'true' : 'false';
            })['false'] | 0;
        };
        /**
         * Remove the given [[ISource]] [[IPatient]]
         * @param source
         * @param index
         */
        this.deleteSource = ({ source }, index) => {
            this.change = true;
            this.patient.sources.splice(index, 1);
        };
        /**
         * Calculate IMC
         */
        this.calcIMC = () => {
            if (this.patient.height && this.patient.weight) {
                this.change = true;
                this.patient.imc = Math.round(this.patient.weight / Math.pow(this.patient.height, 2) * 100) / 100;
            }
        };
        /**
         * Get IMC level has color
         * @returns {string}
         */
        this.imcLevel = () => {
            if (!this.patient || !this.patient.imc)
                return '';
            if (this.patient.imc >= 18.5 && this.patient.imc <= 25)
                return 'green';
            if ((this.patient.imc >= 16.5 && this.patient.imc < 18.5) || (this.patient.imc > 25 && this.patient.imc <= 35))
                return 'orange';
            return 'red';
        };
        /**
         * Set patient drugs from allergies or self medication
         * @param drugs
         * @param drugFrom
         */
        this.selectDrug = ({ drugs }, drugFrom) => {
            this.change = true;
            this.patient[drugFrom] = angular.copy(drugs);
        };
        /**
         * Set patient antibiotic drug
         * @param drug
         */
        this.selectAntibiotic = (event) => {
            this.change = true;
            if (this.patient.antibiotic)
                this.patient.antibiotic = {};
            this.patient.antibiotic.drug = angular.copy(event.drug);
        };
        /**
         * Will remove allergies from patient if the switch is toggled off
         * @param value
         */
        this.switchAllergies = (value) => {
            if (!value) {
                this.patient.allergies = null;
            }
        };
        /**
         * Will remove the antibiotic date if the switch is toggled off
         * @param value
         */
        this.switchAntibiotic = (value) => {
            if (!value && this.patient.antibiotic) {
                this.patient.antibiotic.date = null;
            }
        };
        /**
         * Return the translate path of the given gender
         * @param gender
         */
        this.getGenderTranslate = (gender) => {
            if (this.genderType) {
                for (let type of this.genderType) {
                    if (type.value == gender)
                        return type.translate;
                }
            }
        };
        /**
         * Use the modal to edit the patient identity
         */
        this.editPatientIdentity = () => {
            this.ModalService.show({
                component: 'hv-modal-master-patient',
                bindings: {
                    patient: this.patient
                },
                escapeToClose: false
            }).then((pat) => {
                // this.patient = pat;
                Utils.fullAssign(this.patient, pat);
                this.save();
            });
        };
        /**
         * Save [[IPatient]]
         */
        this.save = () => {
            if (this.onSave) {
                this.isSaved = true;
                this.onSave({});
            }
        };
        this.doctorSelection = (event) => {
            let hasDoc = false;
            for (let source of this.patient.sources) {
                if (source.type == this.ESource.Doctor) {
                    source.healthProfessional = angular.copy(event.doctor);
                    this.doctorSource = source;
                    hasDoc = true;
                }
            }
            if (hasDoc == false) {
                let newSrc = {
                    type: this.ESource.Doctor,
                    healthProfessional: angular.copy(event.doctor)
                };
                this.doctorSource = newSrc;
                this.patient.sources.push(newSrc);
            }
            this.change = event.change;
        };
        this.doctorDelete = (event) => {
            for (let i in this.patient.sources) {
                if (this.patient.sources[i].type == this.ESource.Doctor) {
                    this.patient.sources.splice(parseInt(i, 10), 1);
                    this.doctorSource = null;
                    break;
                }
            }
            this.change = event.change;
        };
        this.doctorSpeSelection = (event, index) => {
            this.patient.sources[index].healthProfessional = event.doctor;
            this.change = event.change;
        };
        this.doctorSpeDelete = (event, index) => {
            this.patient.sources.splice(index, 1);
            this.change = event.change;
        };
        this.addSpeDoc = () => {
            let source = {
                type: this.ESource.Specialist
            };
            this.patient.sources.push(source);
        };
        //Tells if the last addspe button should be displayed
        this.showAddSpeButton = () => {
            if (!this.patient.sources || !this.patient.sources.length || this.patient.sources.length < 1) {
                return true;
            }
            let doctorSources = [];
            for (let src of this.patient.sources) {
                if (src.type == ESource.Specialist) {
                    doctorSources.push(src);
                }
            }
            if (!doctorSources.length)
                return true;
            return !!doctorSources[doctorSources.length - 1].healthProfessional;
        };
        this.hospital = this.Access.hospital;
        this.isSaved = false;
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.genderType = ans.gender;
        });
        this.doctorSource = this._.find(this.patient.sources, (item) => {
            return item.type === this.ESource.Doctor;
        });
        this.sharePermission = this.Access.authorize(EHospivillePermission.SharePatient_Create);
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(changes) {
        if (this.patient) {
            this.doctorSource = this._.find(this.patient.sources, (item) => {
                return item.type === this.ESource.Doctor;
            });
        }
        if (changes.patient) {
            this.isSaved = false;
        }
    }
    get isOnlyPremium() {
        return this.Auth.isOnlyPremium;
    }
    get canAddOctavePathway() {
        return this.Auth.isBothOctaveAndPremium;
    }
    canTransferPatient() {
        return this.Auth.authorize(EHospivillePermission.SharePatient_Create);
    }
    transferPatient() {
        var _a;
        this.ModalService.show({
            component: 'hv-patient-transfer-modal',
            escapeToClose: true,
            bindings: {
                hasOnGoingConcil: !!((_a = this.patient.medicationReconciliations) === null || _a === void 0 ? void 0 : _a.find((mr) => mr.active)),
            }
        }).then((selectedHosp) => {
            this.PatientService.transferPatient(this.patient, selectedHosp).then((ans) => {
                this.ToastService.show(EMpStatus.Ok);
                this.Access.changePatient(null, null);
                this.$scope.$emit('changePatient');
                this.$state.go('hospivilleV2.patients.list');
            }).catch((err) => {
                if (err !== EMpStatus.RejectByServer)
                    this.ToastService.show(err);
            });
        });
    }
}
