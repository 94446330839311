export const locale = {
    lang: 'en',
    data: {
        FILE_TO_DBS: {
            TITLE: `Addition of the pharmacy prescription to the drug balance sheet`,
            FILE_VIEW: {
                FROM: `File sent by:`,
                PATIENT: `Patient:`
            },
            PRESCRIPTION_VIEW: {
                TITLE: `Prescription`
            },
            ADDED: 'Added to prescription',
            ALREADY_ADDED: 'Medication already on the balance sheet',
            BUTTONS: {
                GO_TO_DBS: `Go to the drug balance sheet`
            },
            ALERT_SAVE: {
                TITLE: `Changes not saved`,
                MESSAGE: `You have made changes to the prescription that have not been saved. Are you sure you want to leave the page?`
            }
        }
    }
};
