var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable, Injector } from '@angular/core';
import { AvailableASchema, EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { IdentityService } from '@services/identity/identity.service';
import { from, Observable, Subject } from 'rxjs';
let AvailableRequestService = class AvailableRequestService extends CrudTemplateService {
    constructor(__injector, _identityService) {
        super(__injector, AvailableASchema, 'api/available', {
            create: EMaPUIPermission.Available_Create,
            read: EMaPUIPermission.Available_Read,
            update: EMaPUIPermission.Available_Edit,
            delete: EMaPUIPermission.Available_Delete
        });
        this.__injector = __injector;
        this._identityService = _identityService;
        this.getPageAvailableOffer = (pageOptions, search, sort, filters, otherParams) => {
            return this.getPage(pageOptions, search, sort, filters, otherParams);
        };
        this.availablesAndOffers = [];
        this.onCurrentAvailableChangedFromList = new Subject();
        this.onCurrentAvailableChangedFromDetail = new Subject();
        this.onAvailablesAndOffersChanged = new Subject();
        this.onAvailablesAndOffersChanged.subscribe((availablesAndOffers) => {
            this.availablesAndOffers = availablesAndOffers;
        });
    }
    /**
     * Set current available by id
     *
     * @param available
     */
    setCurrentAvailable(available) {
        this.currentAvailable = null;
        if (available) {
            this.currentAvailable = available;
        }
        this.onCurrentAvailableChangedFromList.next(this.currentAvailable);
    }
    onAvailableListChanged(available) {
        if (this.currentAvailable && this.currentAvailable.available._id == available.available._id) {
            this.onCurrentAvailableChangedFromList.next(available);
        }
    }
    /**
     * Emit to list available that an AvailableOffer has changed
     * @param available
     */
    onAvailableDetailChanged(available) {
        this.currentAvailable = available;
        this.onCurrentAvailableChangedFromDetail.next(available);
    }
    addOffer(offer1) {
        return from(this._http.post(`${this._endPoint}/offer`, offer1));
    }
    updateOffer(availableOffer) {
        return from(this._http.put(`${this._endPoint}/offer/${availableOffer._id}`, availableOffer));
    }
    getOfferByAvailable(available) {
        return from(this._http.get(`${this._endPoint}/offer/by-available?availableId=${available._id}`));
    }
    getUniqueAvailableOffer(id) {
        return new Observable((observer) => {
            this._http.get(`${this._endPoint}/${id}`).subscribe((available) => {
                this.getOfferByAvailable(available).subscribe((availableOffer) => {
                    this.currentAvailable = {
                        available: available,
                        offers: availableOffer
                    };
                    this.onCurrentAvailableChangedFromList.next(this.currentAvailable);
                    observer.next(true);
                    observer.complete();
                }, (error) => {
                    console.error(error);
                    observer.next(false);
                });
            }, () => observer.next(false));
        });
    }
};
AvailableRequestService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object])
], AvailableRequestService);
export { AvailableRequestService };
