import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class GeoService {
    /** @ngInject */
    constructor(Api, $q) {
        this.Api = Api;
        this.$q = $q;
        /**
         * Get all [[ITown]]
         * @returns A promise containing all [[ITown]]
         */
        this.findTown = (search) => {
            const defer = this.$q.defer();
            this.Api.towns.find({
                search: search,
            }, (ans) => {
                defer.resolve(ans);
            }, () => {
                defer.reject(EMpStatus.RejectByServer);
            });
            return defer.promise;
        };
        /**
         * Get all [[ICountry]]
         * @returns A promise containing all [[ICountry]]
         */
        this.findCountry = (search) => {
            const defer = this.$q.defer();
            this.Api.countries.find({
                search: search,
            }, (ans) => {
                defer.resolve(ans);
            }, () => {
                defer.reject(EMpStatus.RejectByServer);
            });
            return defer.promise;
        };
    }
}
