export const locale = {
    lang: 'en',
    data: {
        NAVIGATION: {
            BILLING: {
                TITLE: 'Billing'
            }
        },
        BILLING: {
            BILLINGS: 'Billings',
            SETTLEMENTS: 'Settlements',
            AWAITING: {
                TITLE: 'Awaiting',
                HEADERS: {
                    TYPE: 'Type',
                    MODIFIED: 'Update date',
                    NAME: 'Name',
                    HOSPITAL: 'Hospital',
                    STATUS: 'Status',
                    QUANTITY: 'Quantity',
                    PRICE: 'Price',
                    ACTION: 'Action'
                },
                CONFIRM: {
                    FORCE: {
                        TITLE: 'Confirmation needed',
                        SETTLE_MESSAGE: 'Are you sure you want to carry out the settlement instead of the borrower?',
                        BILL_MESSAGE: 'Are you sure you want to create the billing instead of the lender?'
                    }
                },
                FORCE_SETTLEMENT: 'Force Settlement',
                FORCE_BILLING: 'Force billing'
            },
            BILLINGS_TABLE: {
                CREATED: 'Creation date',
                NAME: 'Name',
                QUANTITY: 'Quantity'
            },
            NAME: 'Created the {{date}} - {{name}}',
            ACTIONS: {
                BILL: 'Bill',
                SETTLE: 'Settle',
                PREVIOUS: 'Previous',
                NEXT: 'Next'
            },
            STEPS: {
                INFO: 'Only products from the same establishment can be selected.',
                SELECT_LOANS: 'Select loans',
                ACTIONS: 'Actions'
            },
            SUCCESS: {
                SUCCESS_SETTLEMENT: 'Settlement has been carried out',
                SUCCESS_BILLING: 'Billing has been created'
            },
            INPUTS: {
                UNIT_PRICE: 'Unit price',
                VTA: 'VTA',
                CHARGE: 'Charge',
                CHARGE_TYPE: 'Charge type',
                BILL_NUMBER: 'Bill number',
                BILL_COMMENT: 'Bill comment',
                ERRORS: {
                    MANDATORY: 'Mandatory',
                    REQUIRED: 'Required'
                }
            },
            INVOICE: {
                INVOICE: 'INVOICE',
                DATE: 'DATE',
                PRODUCT: 'PRODUCT',
                QUANTITY: 'QUANTITY',
                UNIT_PRICE: 'UNIT PRICE',
                TOTAL: 'TOTAL',
                SUBTOTAL: 'SUBTOTAL',
                VAT: 'VAT {{vat}} %',
                ONLY_VAT: 'VAT',
                CHARGE: 'CHARGE',
                OBJECT: 'Payment notification',
                SUB_OBJECT: 'The pharmaceutical department  of {{hospital}} has delivered medicines or pharmaceutical products',
                ACTIONS: {
                    DOWNLOAD: 'Download'
                }
            },
            NONE: "No product to {{(type == 'BILL') ? 'bill' : 'settle'}}",
            NBR_PRODUCTS: 'Products number'
        }
    }
};
