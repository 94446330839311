var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { Component, Inject, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DOCUMENT } from '@angular/common';
import { CustomWidgetHomeASchema } from '@mapuilabs/mpl-interfaces';
let WidgetHomeComponent = class WidgetHomeComponent {
    constructor(_document, _stateService) {
        this._document = _document;
        this._stateService = _stateService;
        this.timeout = false;
    }
    ngOnChanges(changes) {
        var _a;
        const counter = (_a = changes === null || changes === void 0 ? void 0 : changes.widgetData.previousValue) === null || _a === void 0 ? void 0 : _a.counter;
        if (counter && this.widgetData.counter && counter !== this.widgetData.counter) {
            this.timeout = true;
            setTimeout(() => {
                this.timeout = false;
            }, 1);
        }
    }
    navigateToLink(button) {
        if (button.state !== 'otherUrl') {
            this._stateService.go(button.state);
        }
        else if (button.url) {
            window.open(button.url, '_blank');
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof CustomWidgetHomeASchema !== "undefined" && CustomWidgetHomeASchema) === "function" ? _c : Object)
], WidgetHomeComponent.prototype, "widgetData", void 0);
WidgetHomeComponent = __decorate([
    Component({
        selector: 'mpx-widget-home',
        template: require('./widget-home.component.html').default,
        styles: [require('./widget-home.component.scss')]
    }),
    __param(0, Inject(DOCUMENT)),
    __metadata("design:paramtypes", [typeof (_a = typeof Document !== "undefined" && Document) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object])
], WidgetHomeComponent);
export { WidgetHomeComponent };
