export const locale = {
    lang: 'fr',
    data: {
        EYE_DROP_SELECTION: {
            TITLE: 'Gouttes',
            LEFT: 'Oeil gauche',
            RIGHT: 'Oeil droit',
            OK: 'OK'
        }
    }
};
