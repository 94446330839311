import { EClinicalConsequence, EDiscrepancy, EMpStatus } from "@mapuilabs/mpl-interfaces";
import * as moment from "moment";
import * as d3 from "d3";
import { Utils } from "@main/services/Utils";
export class HvStatisticsController {
    /** @ngInject */
    constructor(HvStatisticService, HospivilleService, HospitalService, PatientService, Access, Api, $translate, _, ConfigurationService, ModalService) {
        this.HvStatisticService = HvStatisticService;
        this.HospivilleService = HospivilleService;
        this.HospitalService = HospitalService;
        this.PatientService = PatientService;
        this.Access = Access;
        this.Api = Api;
        this.$translate = $translate;
        this._ = _;
        this.ConfigurationService = ConfigurationService;
        this.ModalService = ModalService;
        /**
         * @type {EClinicalConsequence}
         */
        this.EClinicalConsequence = EClinicalConsequence;
        /**
         * Get given [[EClinicalConsequence]] translation
         * @param consequence
         * @returns {any}
         */
        this.getClinicalConsequenceTranslation = (consequence) => {
            if (!this.constTypes || !consequence)
                return '';
            return this._.find(this.constTypes.clinicalConsequence, { value: consequence }).translate;
        };
        /**
         * Round given data in percent
         * @param number
         * @returns {number}
         */
        this.roundData = (number) => {
            if (!number)
                return 0;
            return Math.round(number / this.nbDiscrepancyUID * 100);
        };
        /**
         * Set dates
         * @param {number} toSub
         * @param {string} subTo
         */
        this.setDates = (toSub, subTo) => {
            this.parameters.end = moment().endOf('day').toDate();
            this.parameters.start = moment().subtract(toSub, subTo).startOf('day').toDate();
            if (!this.hasRestriction) {
                this._init();
            }
        };
        /**
         * Set if parameters change
         * @param model
         * @param {string} element
         */
        this.paramsChange = (model, element) => {
            if (element === 'start') {
                model = moment(model).startOf('day').toISOString();
            }
            if (element === 'end')
                model = moment(model).endOf('day').toISOString();
            this.parameters[element] = model;
            if (!this.hasRestriction) {
                this._init();
            }
        };
        /**
         * Reset start end dates
         */
        this.all = () => {
            this.parameters.start = null;
            this.parameters.end = null;
            if (!this.hasRestriction) {
                this._init();
            }
        };
        /**
         * Format chart values
         * @param value
         * @param ratio
         * @param id
         * @return {string}
         */
        this.format = (value, ratio, id) => {
            return d3.format('s')(value);
        };
        /**
         * Get given [[ESubDiscrepancy]] translation
         * @param type
         * @returns {any}
         * @private
         */
        this._getSubDiscrepancyTranslation = (type) => {
            if (!this.constTypes || !type)
                return '';
            return this.$translate.instant(this._.find(this.constTypes.subDiscrepancy, { value: type }).translate);
        };
        /**
         * Calculate discrepancy results (ID/UID), types and consequences
         * @param results
         * @private
         */
        this._countDiscrepancy = (results) => {
            let discrepancyResult = this._.filter(results, (v) => {
                return v.hasDiscrepancy === true;
            });
            this.nbDiscrepancy = discrepancyResult.length;
            let discrepancyUIDResult = this._.filter(results, (v) => {
                return v.hasDiscrepancy === true && v.discrepancyType === EDiscrepancy.MedicinalError;
            });
            this.nbDiscrepancyUID = discrepancyUIDResult.length;
            this.count = this._.countBy(discrepancyResult, (res) => {
                if (res.discrepancyType === EDiscrepancy.Intentional) {
                    return 'DIND';
                }
                else if (res.discrepancyType === EDiscrepancy.IntentionalDocumented) {
                    return 'DID';
                }
                else if (res.discrepancyType === EDiscrepancy.MedicinalError) {
                    return 'DNI';
                }
                else {
                    return 'NOT';
                }
                // return res.discrepancyType ? 'DI' : res.discrepancyType != null ? 'DNI' : 'NOT';
            });
            this.count.type = this._.countBy(discrepancyUIDResult, (res) => {
                return res.subDiscrepancyType;
            });
            this.count.clinicalConsequence = this._.countBy(discrepancyUIDResult, (res) => {
                return res.clinicalConsequence;
            });
            this.count.countDiscrepancy = this._.countBy(discrepancyUIDResult, (res) => {
                return (res.discrepancyCorrected && res.discrepancyType != null) == true ? 'true' :
                    (!res.discrepancyCorrected && res.discrepancyType != null) ? 'false' : 0;
            });
        };
        /**
         * Init graphic charts
         * @private
         */
        this._initCharts = () => {
            let discrepancyType = { columns: [], data: [] };
            let colors = ['#96bf31', '#ffa726', '#E91E63', '#32abdf', '#f6d74c', '#585fa8', '#00BCD4', '#607D8B'];
            for (let type in this.count.type) {
                if (this.count.type.hasOwnProperty(type)) {
                    if (type !== 'null' && type !== 'undefined') {
                        discrepancyType.columns.push({
                            id: type,
                            name: this._getSubDiscrepancyTranslation(parseInt(type)),
                            type: 'pie',
                            color: colors[parseInt(type) - 1],
                        });
                        discrepancyType.data.push({ [type]: this.count.type[type] });
                    }
                    else {
                        discrepancyType.columns.push({
                            id: 'not',
                            name: this.$translate.instant('HOSPIVILLE.STATISTICS.NOT_SPECIFIED'),
                            type: 'pie',
                            color: '#e54f31',
                        });
                        discrepancyType.data.push({ not: this.count.type[type] });
                    }
                }
            }
            this.instances = {
                donuts: {
                    discrepancy: {
                        columns: [{
                                id: '1',
                                name: 'DID',
                                type: 'donut',
                                color: '#96bf31',
                            }, {
                                id: '2',
                                name: 'DIND',
                                type: 'donut',
                                color: '#87A444',
                            }, {
                                id: '3',
                                name: 'DNI',
                                type: 'donut',
                                color: '#ffa726',
                            }, {
                                id: '4',
                                name: this.$translate.instant('HOSPIVILLE.STATISTICS.NOT_SPECIFIED'),
                                type: 'donut',
                                color: '#e54f31',
                            }],
                        data: [{ 1: this.count.DID }, { 2: this.count.DIND }, { 3: this.count.DNI }, { 4: this.count.NOT }],
                    },
                    corrected: {
                        columns: [{
                                id: '1',
                                name: 'DNI corrigée',
                                type: 'donut',
                                color: '#96bf31',
                            },
                            {
                                id: '2',
                                name: 'DNI non corrigée',
                                type: 'donut',
                                color: '#ffa726',
                            }],
                        data: [{ 1: this.count.countDiscrepancy.true }, { 2: this.count.countDiscrepancy.false }],
                    },
                },
                pies: {
                    discrepancy: {
                        columns: discrepancyType.columns,
                        data: discrepancyType.data,
                    },
                },
            };
        };
        /**
         * Calculate conciliation average
         * @param medRecs
         * @private
         */
        this._calcConciliationAverage = (medRecs) => {
            let sum = 0;
            for (let medRec of medRecs) {
                if (medRec === null || medRec === void 0 ? void 0 : medRec.totalTime) {
                    sum += medRec.totalTime;
                }
            }
            let x = (sum / medRecs.length) / 1000;
            const seconds = Math.floor(x % 60);
            x /= 60;
            const minutes = Math.floor(x % 60);
            x /= 60;
            const hours = Math.floor(x % 24);
            x /= 24;
            const days = Math.floor(x);
            this.conciliationAverage = (days ? days + 'j ' : '') + (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm ' : '') + (seconds ? seconds + 's' : '');
        };
        /**
         * Init data
         * @private
         */
        this._init = () => {
            this.HvStatisticService.getMedRecs(this.parameters).then((medRecs) => {
                let results = [];
                let found = 0;
                for (let medRec of medRecs) {
                    results = results.concat(medRec.results);
                    if (medRec.deleted)
                        found++;
                }
                this.nbMedRecs = medRecs.length;
                this._countDiscrepancy(results);
                this._calcConciliationAverage(medRecs);
                this._initCharts();
                this.ready = true;
            });
        };
        this.parameters = {
            transit: null,
            start: null,
            end: null,
            hospitalServices: [],
        };
        this.isLoading = false;
        this.count = {};
        this.ready = false;
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        //Add hospital Id with more than 1500 medicationReconciliations
        this.hasRestriction = this._checkStatsRestriction([
            '566c5f041cba8d7c15f632c4' //ID CHU Angers
        ]);
    }
    $onInit() {
        this.ConfigurationService.getHospitalServices(this.Access.hospital).then((hospiservices) => {
            this.HospitalServices = hospiservices;
            if (!this.hasRestriction) {
                this._init();
            }
            else {
                this.ready = true;
            }
        });
        this.migrationState = this.Access.isHVMigrationState();
    }
    /**
     * genereate excel statistics
     * @param search
     * @param sort
     * @param filters
     */
    downloadExcel() {
        this.isLoading = true;
        this.ModalService.show({
            component: 'hv-modal-waiting-download',
            escapeToClose: false,
            clickOutsideToClose: false
        });
        this.HvStatisticService.generateXlsx(this.parameters)
            .then(() => {
            this.isLoading = false;
            this.ModalService.cancel(EMpStatus.Ok);
        })
            .catch(() => { this.isLoading = false; });
    }
    /**
     * Check if page should be restricted for given hospitals' ids list
     * @param unauthorizedHopIds
     * @private
     */
    _checkStatsRestriction(unauthorizedHopIds) {
        for (let id of unauthorizedHopIds) {
            if (Utils.compareIds(this.Access.hospital, id)) {
                return true;
            }
        }
        return false;
    }
}
