import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { CHsDefaultFiltersGroup, CHsPresetFilters, CHsPresetFiltersGroup } from '@app/hospistock/shared/constTypes/hs-preset-filters.const';
import { CHsDrugSort } from '@app/hospistock/shared/constTypes/hs-sort.const';
import { CHsDynamicTags, CHsDynamicTagsGroup, CHsStaticTags, CHsStaticTagsGroup } from '@app/hospistock/shared/constTypes/hs-tag.const';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { EHospistockPermission, EHsPublicationFilter, EHsUserTypes, HsPublicationAlternativeDrugASchema, HsPublicationDrugASchema, HsPublicationMetadataDrugASchema } from '@mapuilabs/mpl-interfaces';
import * as moment from 'moment';
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Service Config
// -----------------------------------------------------------------------------------------------------
const viewType = EHsUserTypes.GA;
const translationBase = 'PUBLICATION.PURCHASE_GROUP';
const permissions = {
    create: EHospistockPermission.PurchaseGroupDrugPublication_Create,
    read: EHospistockPermission.PurchaseGroupDrugPublication_Read,
    update: EHospistockPermission.PurchaseGroupDrugPublication_Edit,
    delete: EHospistockPermission.PurchaseGroupDrugPublication_Delete
};
export const hsPurchaseGroupDrugPublicationServiceConfig = {
    constructors: {
        publication: HsPublicationDrugASchema,
        publicationAlternative: HsPublicationAlternativeDrugASchema,
        publicationMetadata: HsPublicationMetadataDrugASchema
    },
    endPointBase: 'api/hospistock/purchase-group/drug',
    permissions,
    spreadsheetClassBuilder: null,
    viewType,
    types: [
        CHsPublicationType.stockout,
        CHsPublicationType.quota,
        CHsPublicationType.pressure,
        CHsPublicationType.comEnded,
        CHsPublicationType.shortExpiration,
        CHsPublicationType.wholesalerOnly
    ]
};
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Config
// -----------------------------------------------------------------------------------------------------
// List config
const listConfig = {
    header: true,
    operator: true,
    source: true,
    audiencePharmacy: false,
    period: true,
    stockIndicator: false,
    tags: true,
    updateDate: true,
    status: true,
    seen: true
};
// Detail config
const detailConfig = {
    header: {
        important: true,
        seen: true,
        tags: true
    },
    healthProducts: true,
    alternatives: true,
    summary: true,
    information: true,
    files: true,
    market: true,
    broadcastList: false,
    stats: true,
    supervisor: false,
    comments: true
};
// Sorts config
const sorts = CHsDrugSort;
// Tags config
const tags = {
    static: Object.assign(Object.assign({}, CHsStaticTags), CHsStaticTagsGroup),
    dynamic: Object.assign(Object.assign({}, CHsDynamicTags), CHsDynamicTagsGroup)
};
// Filters config
const filtersConfig = {
    presetFilters: [...CHsPresetFilters, ...CHsPresetFiltersGroup],
    defaultFilters: CHsDefaultFiltersGroup,
    filtersSidebarConfig: {
        filters: [
            EHsPublicationFilter.DATE,
            EHsPublicationFilter.OPERATORS,
            EHsPublicationFilter.OTHER,
            // EHsPublicationFilter.SUPERVISOR,
            EHsPublicationFilter.TAGS,
            EHsPublicationFilter.TYPES
        ],
        tags: Object.assign(Object.assign({}, tags.static), tags.dynamic),
        others: { important: true, unread: true }
    }
};
// Page header config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant('PUBLICATION.TITLES.MAIN_GROUP'),
    searchBarPlaceholder: translationService.instant('PUBLICATION.SEARCH'),
    svgIcon: 'cart-remove'
});
// Toolbar config
const toolbarConfig = {
    selection: true,
    sorts,
    tags,
    switchView: true
};
export const hsPurchaseGroupDrugPublicationConfig = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        detail: detailConfig,
        filters: filtersConfig,
        import: null,
        list: listConfig,
        pageHeader: pageHeaderConfig(translationService),
        toolbar: toolbarConfig,
        translationBase
    };
};
const tableColumns = (translationService) => {
    const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);
    return [
        {
            label: ' ',
            template: 'checkbox',
            classes: ['max-w-48']
        },
        {
            label: translationService.instant('COMMONS.WORDS.UPDATED'),
            attribute: 'publication.updateDate',
            transform: formatDate,
            classes: ['max-w-96']
        },
        {
            label: translationService.instant('COMMONS.WORDS.NAME'),
            attribute: 'publication.healthProductRef.name'
        },
        {
            label: translationService.instant('PUBLICATION.WORDS.LABO_OPERATOR'),
            attribute: 'publication.healthProductRef.operator'
        },
        {
            label: translationService.instant('COMMONS.WORDS.TYPE'),
            template: 'type',
            classes: ['max-w-96']
        },
        {
            label: translationService.instant('COMMONS.WORDS.START'),
            attribute: 'publication.startDate',
            transform: formatDate,
            classes: ['max-w-96']
        },
        {
            label: translationService.instant('COMMONS.WORDS.END'),
            attribute: 'publication.endDate',
            transform: formatDate,
            classes: ['max-w-96']
        },
        {
            label: translationService.instant('PUBLICATION.SIDEBAR.TITLES.TAGS'),
            template: 'tags'
        }
    ];
};
export const hsPurchaseGroupDrugPublicationSingleListConfig = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        columns: tableColumns(translationService),
        detail: detailConfig,
        filters: filtersConfig,
        headerStats: false,
        import: null,
        list: null,
        pageHeader: pageHeaderConfig(translationService),
        toolbar: toolbarConfig,
        translationBase
    };
};
