var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { MedicService } from '@services/medic/medic.service';
import { map } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let MedicSearchCleanDciComponent = class MedicSearchCleanDciComponent {
    constructor(_medicService, _fuseTranslationLoaderService) {
        this._medicService = _medicService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init EventEmitters
        this.inputChanges = new EventEmitter();
        this.optionSelected = new EventEmitter();
        this.reset = new EventEmitter();
        // Search Member config
        this.searchAutocompleteConfig = {
            displayFn: (theriaqueDrug) => { var _a; return (_a = theriaqueDrug === null || theriaqueDrug === void 0 ? void 0 : theriaqueDrug.denomination) !== null && _a !== void 0 ? _a : undefined; },
            autocompleteSearchFn: (searchValue) => (this.laboratoriesExp
                ? this._medicService.findDrugByNameWithLabExp(searchValue, this.laboExpCodes, { labos: true })
                : this._medicService.findDrugByName(searchValue, { cleanDci: true, labos: true })).pipe(map((data) => data === null || data === void 0 ? void 0 : data.filter((theriaqueDrug) => !Utils.getIds(this.exclude).includes(Utils.getId(theriaqueDrug))))),
            minimumLength: 3
        };
    }
    get laboExpCodes() {
        return this.laboratoriesExp.map(({ code }) => code);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onOptionSelected(theriaqueDrug) {
        this.optionSelected.emit(theriaqueDrug);
    }
    onInputChanges(value) {
        this.inputChanges.emit(value);
    }
    resetDrugForm() {
        this.reset.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], MedicSearchCleanDciComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MedicSearchCleanDciComponent.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MedicSearchCleanDciComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], MedicSearchCleanDciComponent.prototype, "exclude", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MedicSearchCleanDciComponent.prototype, "required", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], MedicSearchCleanDciComponent.prototype, "laboratoriesExp", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], MedicSearchCleanDciComponent.prototype, "inputChanges", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], MedicSearchCleanDciComponent.prototype, "optionSelected", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], MedicSearchCleanDciComponent.prototype, "reset", void 0);
MedicSearchCleanDciComponent = __decorate([
    Component({
        selector: 'mpx-search-medic-clean-dci',
        template: require('./search-medic-clean-dci.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MedicService !== "undefined" && MedicService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], MedicSearchCleanDciComponent);
export { MedicSearchCleanDciComponent };
