var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { MemberModoService } from '../../../services/member/modo/modo.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { CGender } from '../../../shared/constTypes/member/member-gender.const';
let CreateHospitalMemberComponent = class CreateHospitalMemberComponent {
    constructor(_data, _fuseTranslationLoaderService, _translateService, _dialogRef, _snackbarService, _memberModoService) {
        var _a, _b, _c, _d, _e, _f;
        this._data = _data;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._dialogRef = _dialogRef;
        this._snackbarService = _snackbarService;
        this._memberModoService = _memberModoService;
        this.cGender = CGender;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.form = new FormGroup({
            firstName: new FormControl((_a = this._data.member) === null || _a === void 0 ? void 0 : _a.firstName),
            familyName: new FormControl((_b = this._data.member) === null || _b === void 0 ? void 0 : _b.familyName),
            gender: new FormControl((_c = this._data.member) === null || _c === void 0 ? void 0 : _c.gender),
            phone: new FormControl((_d = this._data.member) === null || _d === void 0 ? void 0 : _d.phone),
            email: new FormControl({ value: (_e = this._data.member) === null || _e === void 0 ? void 0 : _e.email, disabled: true }),
            rpps: new FormControl((_f = this._data.member) === null || _f === void 0 ? void 0 : _f.RPPS)
        });
    }
    saveMember() {
        const member = this._data.member;
        Object.assign(member, this.form.value);
        this._memberModoService.save(member).subscribe({
            next: (saveMember) => {
                this._dialogRef.close(saveMember);
                this._snackbarService.open(this._translateService.instant('CREATE_HOSPITAL_MEMBER.SUCCESS.SAVE'));
            },
            error: (err) => {
                console.error(err);
                this._snackbarService.openError(err);
            }
        });
    }
};
CreateHospitalMemberComponent = __decorate([
    Component({
        selector: 'mpx-create-hospital-member',
        template: require('./create-hospital-member.component.html').default
    }),
    __param(0, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [Object, typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _b : Object, typeof (_c = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof MemberModoService !== "undefined" && MemberModoService) === "function" ? _e : Object])
], CreateHospitalMemberComponent);
export { CreateHospitalMemberComponent };
