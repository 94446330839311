var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, ViewChild } from '@angular/core';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { MatTabGroup } from '@angular/material/tabs';
import { UrlService } from '@services/url/url.service';
import { ToArchiveTabComponent } from './to-archive-tab/to-archive-tab.component';
import { ArchivedTabComponent } from './archived-tab/archived-tab.component';
let ArchivesComponent = class ArchivesComponent {
    constructor(_translationService, _urlService, _fuseTranslationLoaderService) {
        this._translationService = _translationService;
        this._urlService = _urlService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        /**
         * The search query
         */
        this.searchValue = '';
        this.buttonEnabled = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('ARCHIVES.TITLE'),
            searchBarPlaceholder: this._translate('ARCHIVES.SEARCH'),
            icon: 'account_balance'
        };
    }
    ngAfterViewInit() {
        this.tabGroup.selectedIndex = this._urlService.getParam('tab') || 0;
    }
    toggleButtonClickable(enable) {
        this.buttonEnabled = enable;
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
    }
    onTabChange(event) {
        this._urlService.addParams({ tab: event.index }, true);
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
};
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_d = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _d : Object)
], ArchivesComponent.prototype, "tabGroup", void 0);
__decorate([
    ViewChild('toArchiveTab', { static: false }),
    __metadata("design:type", typeof (_e = typeof ToArchiveTabComponent !== "undefined" && ToArchiveTabComponent) === "function" ? _e : Object)
], ArchivesComponent.prototype, "toArchiveTab", void 0);
__decorate([
    ViewChild('archivedTab', { static: false }),
    __metadata("design:type", typeof (_f = typeof ArchivedTabComponent !== "undefined" && ArchivedTabComponent) === "function" ? _f : Object)
], ArchivesComponent.prototype, "archivedTab", void 0);
ArchivesComponent = __decorate([
    Component({
        selector: 'mpx-archives',
        template: require('./archives.component.html').default,
        styles: [require('./archives.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object, typeof (_b = typeof UrlService !== "undefined" && UrlService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object])
], ArchivesComponent);
export { ArchivesComponent };
