var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { LoanService } from '@app/services/loan/loan.service';
import { StatusService } from '@app/services/status/status.service';
import { StateService } from '@uirouter/angularjs';
import { ListPage } from '@app/shared/templates/listPage/listPage.class';
import { MatPaginator } from '@angular/material/paginator';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { DialogService } from '@app/services/dialog/dialog.service';
let ArchivedTabComponent = class ArchivedTabComponent extends ListPage {
    constructor(_injector, sts, _loanService, _stateService, _dialogService, _fuseTranslationLoaderService, _state) {
        super(_injector, _loanService.getArchives);
        this._injector = _injector;
        this.sts = sts;
        this._loanService = _loanService;
        this._stateService = _stateService;
        this._dialogService = _dialogService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._state = _state;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.genericTableConfig = { clickableRows: true };
        this.columns = [
            {
                label: this._translate('ARCHIVES.HEADERS.TYPE'),
                template: 'typeLogo',
                sort: true,
                attribute: 'loanSubCategoryCreation'
            },
            {
                label: this._translate('ARCHIVES.HEADERS.MODIFIED'),
                attribute: 'creationDate',
                sort: true,
                template: 'creationDate'
            },
            {
                label: this._translate('ARCHIVES.HEADERS.NAME'),
                attribute: 'medicName',
                sort: true
            },
            { label: this._translate('ARCHIVES.HEADERS.HOSPITAL'), attribute: 'hospitalName' },
            { label: this._translate('ARCHIVES.HEADERS.STATUS'), template: 'status' },
            { label: this._translate('ARCHIVES.HEADERS.QUANTITY'), attribute: 'quantity', sort: true },
            { label: this._translate('ARCHIVES.HEADERS.ACTIONS'), template: 'actions' }
        ];
        this._loanService.reloadArchives.subscribe(() => this.search());
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    ngOnChanges(changes) {
        if (changes.searchValue) {
            this.search();
        }
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    getStatusIndicator(loan) {
        const paletteName = this._state.current.data.theme.replace('theme-', '');
        return this.sts.getStatusIndicator(loan, paletteName);
    }
    deleteLoan(loan) {
        this._dialogService
            .openConfirmDialog({
            title: this._translate('COMMONS.DELETE') + ' ?',
            message: this.sts.isMaD(loan)
                ? this._translate('ARCHIVES.DELETE_MODAL.REMOVE_AVAILABLE')
                : this._translate('ARCHIVES.DELETE_MODAL.DELETE'),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        })
            .subscribe((result) => {
            if (result) {
                this._loanService.delete(loan).subscribe(() => {
                    this.search();
                    this._snackbarService.open(this._translate('ARCHIVES.DELETE_MODAL.SUCCESS'));
                }, (err) => this._snackbarService.openError(err));
            }
        });
    }
    openDetail(archive) {
        this._stateService.go('mapui.exchangeV2.archives.detail', { id: archive._id });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ArchivedTabComponent.prototype, "searchValue", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], ArchivedTabComponent.prototype, "_paginator", void 0);
ArchivedTabComponent = __decorate([
    Component({
        selector: 'mpx-archived-tab',
        template: require('./archived-tab.component.html').default,
        styles: [require('../../../status-colors.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof StatusService !== "undefined" && StatusService) === "function" ? _b : Object, typeof (_c = typeof LoanService !== "undefined" && LoanService) === "function" ? _c : Object, typeof (_d = typeof StateService !== "undefined" && StateService) === "function" ? _d : Object, typeof (_e = typeof DialogService !== "undefined" && DialogService) === "function" ? _e : Object, typeof (_f = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _f : Object, typeof (_g = typeof StateService !== "undefined" && StateService) === "function" ? _g : Object])
], ArchivedTabComponent);
export { ArchivedTabComponent };
