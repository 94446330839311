var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
let AvailableSummaryService = class AvailableSummaryService {
    constructor(_http) {
        this._http = _http;
        this._endPoint = 'api/available';
    }
    getOfferByAvailable(available) {
        return from(this._http.get(`${this._endPoint}/offer/by-available?availableId=${available._id}`));
    }
    getUniqueAvailableOffer(id) {
        return new Observable((observer) => {
            this._http.get(`${this._endPoint}/${id}`).subscribe((available) => {
                this.getOfferByAvailable(available).subscribe((availableOffer) => {
                    this.currentAvailable = {
                        available: available,
                        offers: availableOffer
                    };
                    observer.next(true);
                    observer.complete();
                }, (error) => {
                    console.log(error);
                    observer.next(false);
                });
            }, () => observer.next(false));
        });
    }
};
AvailableSummaryService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object])
], AvailableSummaryService);
export { AvailableSummaryService };
