export const locale = {
    lang: 'fr',
    data: {
        PUBLICATION: {
            BUTTONS: {
                DOCUMENTATION: 'Documentation',
                TEMPLATE: 'Template',
                IMPORT_BOOKLET: 'Import Livret',
                IMPORT_MARKET: 'Import Marché',
                EDIT: 'Editer',
                HISTORY: 'Historique',
                NEW: 'Nouveau'
            },
            CHIPS: {
                NEW: 'New',
                UPDATED: 'MàJ'
            },
            WORDS: {
                LABO_HOLDER: 'Laboratoire Titulaire',
                LABO_OPERATOR: 'Laboratoire Exploitant',
                TERMINATED_PFIZER: 'Date dépassée'
            },
            TITLES: {
                ADD_ALTERNATIVE: "Ajout d'une alternative",
                ADD: "Création d'une publication",
                ALTERNATIVE: 'Alternative',
                ALTERNATIVES: 'Alternatives',
                BROADCAST_LIST: 'Diffusion',
                COMMENTS: 'Commentaires',
                DATE: 'Dates',
                SUMMARY: 'Détails',
                DRUG: 'Médicament',
                EDIT: 'Modification de la publication',
                EDIT_ALTERNATIVE: "Modification d'une alternative",
                FILES_IMPORTED: 'Fichiers importés',
                FILES_TO_IMPORT: 'Sélection de fichiers à importer',
                FILES: 'Fichiers',
                HISTORY: 'Historique de modifications',
                INFORMATION: 'Information',
                LABO_WIDGET: {
                    PHARMACY_BOOKLET: 'Au livret',
                    PHARMACY_READ: 'Lu',
                    PHARMACY_SEEN: 'Vu',
                    PHARMACY_SELECT_ALTERNATIVE: 'Mon alternative sélectionnée / Total'
                },
                MAIN_GROUP: "Hospistock - Groupement d'Achat",
                MAIN_LABO: 'Hospistock - Laboratoire',
                MAIN_LABO_ALTERNATIVES: 'Hospistock - Mes médicaments alternative',
                MAIN_PUI: 'Hospistock - PUI',
                MARKETS: 'Marchés',
                MEDICAL_DEVICES: 'Dispositifs médicaux',
                PRESENTATIONS_LIST: 'Présentations sélectionnées',
                PRESENTATIONS: 'Présentations',
                PRICE_OFFER_INITIALS: 'OP',
                STOCK_INDICATOR_CAPTION: "Légende d'indices de stock",
                STATS: 'Statistiques PUI',
                SUPERVISOR: 'Référent'
            },
            SUMMARY: {
                ATC: 'ATC',
                DEPOSITARY: 'Dépositaire',
                END_DATE: 'Date de fin',
                HOLDER: 'Laboratoire titulaire',
                IMPORTED_DRUG: 'Médicament importé',
                NAME: 'Nom du médicament',
                OPERATOR: 'Laboratoire exploitant',
                SUPPLIER: 'Fournisseur',
                PERIOD: 'Période',
                PRICE_OFFER: 'Offre de prix',
                CLADIMED: 'CLADIMED',
                REGULATORY_QUOTA: 'Contingentement réglementaire',
                REMAINDERS_CONSERVATION: 'Conservation des reliquats',
                SOURCE: 'Source',
                START_DATE: 'Date de début',
                STOCK_INDICATOR: 'Indice de stock',
                TRANSFER_LABO: 'Nouveau laboratoire exploitant',
                TRANSFER_SUPPLIER: 'Nouveau fournisseur',
                UCD: 'UCD',
                UNIT_PACKAGING: 'Conditionnement unitaire',
                UPDATE_DATE: 'Mise à jour',
                WHOLESALER: 'Grossiste - Répartiteur'
            },
            TOOLTIPS: {
                ADD_ALTERNATIVE: 'Ajouter une alternative',
                ADD_COMMENT: 'Ajouter un commentaire',
                ATTACHMENT: 'Fichier joint',
                AUTO_TAG: 'Ajouté automatiquement',
                COMMENTS: 'Commentaires',
                DELETE: 'Supprimer',
                EDIT: 'Editer',
                EXPORT_LIST: 'Exporter la liste',
                EXPORT_STATS: 'Exporter les statistiques',
                SWITCH_VIEW: 'Basculer de vue',
                HISTORY: 'Historique',
                IMPORTANT: 'Important',
                INFORMATION: 'Informations',
                LOOK: 'Voir',
                MEDICAL_DEVICE_REF: 'Les références ont été nettoyées (caractères spéciaux et zéros en tête retirés)',
                OPEN_FILTER: 'Filtres',
                OPEN_SORT: 'Tri',
                PERIOD_INDICATIVE_BASIS: "Dates données à titre indicatif \n et susceptibles d'évoluer",
                PRICE_OFFER: 'Offre de prix',
                PRODUCT_NUMBER: 'Produits concernés',
                REFRESH: 'Rafraîchir',
                REMAIN_CONSERVATION: 'Conservation des reliquats',
                SHARE_STATUS_COMMENT: 'Commentaire diffusé aux PUI membres',
                TAG: 'Tags',
                PRODUCT_OUT_OF_STOCK: 'Produit en rupture',
                YOUR_PRODUCT: 'Produit de votre portefeuille'
            },
            EXPORT_EXCEL: 'Exporter au format excel',
            EXPORT_SHORT: 'Exporter',
            ADVANCED_FILTERS: 'Filtres avancés',
            SEARCH: 'Rechercher une publication',
            NOTIFS: {
                ADD_ALTERNATIVE: 'Alternative ajoutée',
                EDIT_ALTERNATIVE: 'Alternative modifiée',
                DELETE_ALTERNATIVE: 'Alternative supprimée',
                ERROR_ALTERNATIVE: 'Alternative non ajoutée',
                ADD: 'Publication ajoutée',
                SAVE: 'Publication modifiée',
                DELETE: 'Publication supprimée',
                ERROR: 'Publication non ajoutée',
                ADD_COMMENT: 'Commentaire ajouté',
                EDIT_COMMENT: 'Commentaire modifié',
                DELETE_COMMENT: 'Commentaire supprimé'
            },
            MODAL: {
                LABO_STATS: {
                    READ: 'Lu le',
                    SEEN: 'Vu le',
                    BOOKLET: 'Au livret'
                },
                TITLES: {
                    NEW_BOOKLET_ITEM: 'Nouvel ajout au livret',
                    NEW_MARKET_ITEM: 'Nouvel ajout au marché',
                    EDIT_BOOKLET_ITEM: "Modifier l'entrée du livret",
                    EDIT_MARKET_ITEM: "Modifier l'entrée du marché"
                },
                FORM: {
                    BROADCAST: {
                        LABEL: 'Diffusion',
                        INPUTS: {
                            SELECT_ALL: {
                                LABEL: "Diffuser à tous les Groupements d'Achat"
                            },
                            GROUPS: {
                                LABEL: "Groupements d'achat",
                                PLACEHOLDER: "Rechercher un groupement d'achat"
                            }
                        },
                        SUBTITLES: {
                            SELECT_GROUPS: "Sélection des groupements d'achats",
                            SELECT_BROADCAST_LIST: 'Sélection de la liste de diffusion'
                        },
                        ERRORS: {
                            NO_GROUP_SELECTED: "La sélection d'au moins un groupement est obligatoire.",
                            NO_GROUP_OR_HOSPITAL_SELECTED: "La sélection d'au moins un groupement ou hôpital est obligatoire."
                        }
                    },
                    TYPE: {
                        LABEL: 'Type de publication',
                        PLACEHOLDER: 'Sélectionnez le type de publication'
                    },
                    REMAINDERS_CONSERVATION: {
                        ACTIVE: {
                            NOT_PRACTICABLE: 'Non applicable'
                        },
                        DESCRIPTION: {
                            LABEL: 'Informations',
                            PLACEHOLDER: 'Renseignez des informations complémentaires'
                        }
                    },
                    START_DATE: {
                        LABEL: 'Date de début',
                        PLACEHOLDER: 'Sélectionnez la date de début de publication'
                    },
                    START_DATE_ALTERNATIVE: {
                        LABEL: 'Date de début',
                        PLACEHOLDER: "Sélectionnez la date de début de l'alternative"
                    },
                    END_DATE: {
                        LABEL: 'Date de fin',
                        PLACEHOLDER: 'Sélectionnez la date de fin de publication'
                    },
                    END_DATE_ALTERNATIVE: {
                        LABEL: 'Date de fin',
                        PLACEHOLDER: "Sélectionnez la date de fin de l'alternative"
                    },
                    DRUG: {
                        CUSTOM_INPUT: {
                            LABEL: 'Renseignez un médicament manuellement'
                        },
                        SEARCH: {
                            LABEL: 'Recherche de médicament',
                            PLACEHOLDER: 'Recherchez un médicament par nom / UCD'
                        },
                        NAME: {
                            LABEL: 'Nom de médicament',
                            PLACEHOLDER: 'Renseignez le nom du médicament concerné'
                        },
                        ATC: {
                            LABEL: 'ATC du médicament',
                            PLACEHOLDER: "Renseignez l'ATC du médicament concerné"
                        },
                        UCD: {
                            LABEL: 'UCD du médicament',
                            PLACEHOLDER: "Renseignez l'UCD du médicament concerné"
                        },
                        LABO_HOLDER: {
                            LABEL: 'Laboratoire Titulaire',
                            PLACEHOLDER: 'Renseignez le laboratoire titulaire'
                        },
                        LABO_OPERATOR: {
                            LABEL: 'Laboratoire Exploitant',
                            PLACEHOLDER: 'Renseignez le laboratoire exploitant'
                        },
                        ALREADY_USED: {
                            LABEL: 'Une publication existe déjà pour une des présentations sélectionnées'
                        },
                        ALT_ALREADY_USED: {
                            LABEL: 'Une publication est en cours pour cette alternative de médicament'
                        },
                        ALTERNATIVE_ALREADY_USED: {
                            LABEL: 'Une alternative existe déjà pour ce médicament'
                        },
                        BOOKLET_ALREADY_USED: {
                            LABEL: 'Une entrée du livret existe déjà pour ce médicament'
                        },
                        MARKET_ALREADY_USED: {
                            LABEL: 'Une entrée du marché existe déjà pour ce médicament'
                        },
                        GENERIC_ALREADY_USED: {
                            LABEL: 'Un élément similaire existe déjà'
                        },
                        DRUG_NAME: {
                            LABEL: 'Nom du médicament'
                        },
                        CONSUMPTION: {
                            LABEL: 'Consommation journalière'
                        },
                        STOCK: {
                            LABEL: 'Stock'
                        },
                        CIP: {
                            LABEL: 'CIP/ACL'
                        },
                        LOT: {
                            LABEL: 'N° lot'
                        },
                        MARKET_NUM: {
                            LABEL: 'N° marché'
                        },
                        MARKET_SUBJECT: {
                            LABEL: 'Objet du marché'
                        },
                        CAMPAIGN: {
                            LABEL: 'Campagne'
                        },
                        ABBREVIATION: {
                            LABEL: 'Abréviation'
                        }
                    },
                    MEDICAL_DEVICE: {
                        ALREADY_USED: {
                            LABEL: 'Une publication existe déjà pour un des dispositifs médicaux sélectionnés'
                        },
                        BOOKLET_ALREADY_USED: {
                            LABEL: 'Une entrée du livret existe déjà pour ce dispositif médical'
                        },
                        MARKET_ALREADY_USED: {
                            LABEL: 'Une entrée au marché existe déjà pour ce dispositif médical'
                        },
                        MISSING_INDUSTRIALS: "Problème de configuration, aucun fournisseur n'a été associé à votre profil."
                    },
                    WHOLESALER: {
                        LABEL: 'Grossiste - Répartiteur',
                        PLACEHOLDER: 'Sélectionnez le grossiste - répartiteur'
                    },
                    DEPOSITARY: {
                        LABEL: 'Dépositaire',
                        PLACEHOLDER: 'Sélectionnez le dépositaire'
                    },
                    PRESENTATION: {
                        LABEL: 'Présentation du médicament',
                        PLACEHOLDER: 'Sélectionnez la présentation',
                        SELECTED: {
                            PLURAL: 'présentations sélectionnées'
                        }
                    },
                    INFORMATION: {
                        LABEL: 'Informations',
                        PLACEHOLDER: 'Renseignez des informations complémentaires'
                    },
                    ERROR: {
                        MISSING_TYPE: 'Le type de publication est obligatoire.',
                        MISSING_DRUG: 'Un médicament est obligatoire.',
                        MISSING_MEDICAL_DEVICES: 'Un dispositif médical est obligatoire.',
                        MISSING_START_DATE: 'La date de début est obligatoire.',
                        MISSING_BROADCAST_LIST: "La sélection d'au moins un établissement est obligatoire."
                    },
                    SAVE_IN_PROGRESS: 'Sauvegarde en cours...'
                },
                IMPORT: {
                    TITLE: 'Importation des publications',
                    REPORT: {
                        SUCCESS: {
                            STATUS: 'Publications créées',
                            INSTRUCTIONS: 'Les publications listées ci-dessous ont été correctement créées.'
                        },
                        INFO: {
                            STATUS: 'Publications mises à jour',
                            INSTRUCTIONS: 'Les publications listées ci-dessous ont été correctement mises à jour.'
                        },
                        WARNING: {
                            STATUS: 'Publications non mises à jour',
                            INSTRUCTIONS: "Les publications listées ci-dessous n'ont pas été mises à jour."
                        },
                        ERROR: {
                            STATUS: 'Publications non importées',
                            INSTRUCTIONS: "Les publications listées ci-dessous ont rencontré une erreur et n'ont pas pu être importées."
                        },
                        MESSAGES: {
                            // Commons
                            INTERNAL_SERVER_ERROR: "Une erreur inattendue s'est produite. Veuillez contacter le service de support.",
                            // Publication
                            TERMINATED: 'La publication doit être "en cours".',
                            INVALID_DRUG: "Le produit de la publication n'est pas rattaché à votre portefeuille.",
                            INVALID_PRESENTATION: "La présentation de la publication n'est pas associée à ce médicament.",
                            MULTIPLE_CURRENT_PUBLICATIONS: 'Plusieurs publications "en cours" associées à ce produit ont été trouvées.',
                            MULTIPLE_IMPORT: "Plusieurs publications associées à ce produit ont été trouvées dans le fichier d'importation.",
                            SET_AS_TERMINATED: "Date de fin de la publication modifiée à la date d’hier en raison de l'absence de cette référence dans le fichier d'importation.",
                            UNCHANGED: 'Aucun changement détecté sur la publication.',
                            UPDATED: 'Un ou plusieurs changements détectés sur la publication.',
                            CREATED: 'La publication a été correctement créée.',
                            // Alternative
                            NO_CURRENT_PUBLICATION_ALT: 'Aucune publication "en cours" associée à ce produit n\'a été trouvée',
                            TERMINATED_ALT: 'L\'alternative doit être "en cours".',
                            INVALID_DRUG_ALT: "Le produit de l'alternative n'est pas rattaché à votre portefeuille.",
                            INVALID_PRESENTATION_ALT: "La présentation de l'alternative n'est pas associée à ce médicament.",
                            MULTIPLE_CURRENT_ALTERNATIVES: 'Plusieurs alternatives "en cours" associées à ce produit ont été trouvées.',
                            MULTIPLE_IMPORT_ALT: "Plusieurs alternatives associées à ce produit ont été trouvées dans le fichier d'importation.",
                            SET_AS_TERMINATED_ALT: "Date de fin de l'alternative modifiée à la date d’hier en raison de l'absence de cette référence dans le fichier d'importation.",
                            UNCHANGED_ALT: "Aucun changement détecté sur l'alternative.",
                            UPDATED_ALT: "Un ou plusieurs changements détectés sur l'alternative.",
                            CREATED_ALT: "L'alternative a été correctement créée."
                        }
                    }
                }
            },
            REFRESH: {
                BUTTON: 'Rafraîchir'
            },
            SWITCH_VIEW: {
                BUTTON: 'Vue',
                MENU: {
                    DUAL: 'Vue liste',
                    SINGLE: 'Vue tableau'
                }
            },
            PRESET_FILTER: {
                BUTTON: 'Filtres',
                MENU: {
                    CURRENT: 'Uniquement en cours',
                    DISABLE_BOOKLET: 'Désactiver le livret',
                    DISABLE_MARKET: 'Désactiver les marchés',
                    EMPTY: 'Aucun filtre',
                    GA_CURRENT: 'Uniquement GA / En cours',
                    GA_NO_ARCHIVED: 'Uniquement GA / Masquer Archivé',
                    GA_TERMINATED: 'Uniquement GA / Terminées',
                    IMPORTANT: 'Uniquement important',
                    NO_ARCHIVED: 'Masquer Archivé',
                    UNREAD: 'Uniquement non-lues',
                    PUI_CURRENT: 'Uniquement PUI / En cours',
                    PUI_NO_ARCHIVED: 'Uniquement PUI / Masquer Archivé',
                    PUI_TERMINATED: 'Uniquement PUI / Terminées',
                    TERMINATED: 'Uniquement terminées',
                    TERMINATED_PFIZER: 'Uniquement date dépassées'
                }
            },
            SORT: {
                BUTTON: 'Trier',
                MENU: {
                    UPDATE_DATE: 'Date de modification',
                    START_DATE: 'Date de début',
                    END_DATE: 'Date de fin',
                    DRUG_NAME: 'Nom de médicament',
                    DRUG_LABO_EXP: 'Laboratoire exploitant',
                    SUPPLIER: 'Fournisseur',
                    MEDICAL_DEVICE_NAME: 'Nom du dispositif médical'
                }
            },
            LIST: {
                NO_PUBLICATION: 'Aucune publication',
                ITEM: {
                    TITLES: {
                        OWNER: 'Exploitant :',
                        SUPPLIER: 'Fournisseur :',
                        SOURCE: 'Source :',
                        PERIOD: 'Période :',
                        PHARMACIES_AUDIENCE: 'Audience PUI :',
                        STOCK_INDICATOR: 'Indice de stock :',
                        STOCK_COVERAGE: `Votre stock vous permet de couvrir environ {{ stockDays }} jours de publication`,
                        UCD: 'UCD :',
                        REF: 'Référence(s) fournisseur :'
                    },
                    LABELS: {
                        FINISHED: 'Terminé'
                    }
                }
            },
            DETAILS: {
                BROADCAST_LIST: {
                    MODAL: {
                        SHOW_NOT_SELECTED: 'Afficher les établissements non sélectionnés'
                    },
                    ALL: "Diffusée à l'ensemble des groupements adhérents.",
                    PARTIAL: 'à {{count}} groupements.',
                    SHOW_LIST: 'voir liste',
                    STATUS: {
                        PARTIAL: 'Partielle',
                        GLOBAL: 'A tous'
                    }
                },
                NO_SELECTED_PUBLICATION: 'Sélectionnez une publication pour afficher le détail',
                NO_HISTORY_FOUND: 'Aucun historique trouvé',
                ALTERNATIVE: {
                    ADD_ALTERNATIVE: 'Ajouter une alternative',
                    NO_ALTERNATIVE: 'Aucune alternative'
                },
                NO_COMMENT: 'Aucun commentaire',
                COMMENTS: {
                    NEW_INPUT: {
                        PLACEHOLDER: 'Laissez un commentaire'
                    },
                    EDIT_INPUT: {
                        PLACEHOLDER: 'Modifiez votre commentaire'
                    },
                    BROADCAST_PHARMACY: 'Commentaire diffusé à mes PUI membres'
                }
            },
            SIDEBAR: {
                TITLES: {
                    MAIN: 'Filtres',
                    BOOKLET: 'Livret',
                    MARKET: 'Marchés',
                    DATES: 'Dates',
                    OTHERS: 'Autres',
                    LABOS: 'Laboratoires',
                    SUPPLIER: 'Fournisseurs',
                    TYPES: 'Types de publication',
                    TAGS: 'Tags',
                    SUPERVISOR: 'Référent',
                    SUBTITLES: {
                        BOOKLET: 'Livret',
                        MARKET: 'Marchés',
                        SELECTED_LABOS: 'Laboratoires sélectionnés',
                        SELECTED_SUPPLIER: 'Fournisseurs sélectionnés',
                        SELECTED_SUPERVISOR: 'Référent sélectionné'
                    }
                },
                LABELS: {
                    DISABLE_BOOKLET: 'Désactiver le livret',
                    DISABLE_MARKET: 'Désactiver les marchés',
                    UNKNOWN_END_DATE: 'Date de réapprovisionnement inconnue',
                    TERMINATED: 'Publications terminées',
                    TERMINATED_PFIZER: 'Publications date dépassée',
                    IMPORTANT: 'Publications importantes',
                    UNREAD: 'Publications non-lues'
                },
                INPUTS: {
                    END_DATE: {
                        LABEL: 'Date de réapprovisionnement',
                        PLACEHOLDER: 'Sélectionnez la date de réapprovisionnement'
                    },
                    LABOS: {
                        LABEL: 'Sélectionner un laboratoire'
                    },
                    SUPPLIER: {
                        LABEL: 'Sélectionner un fournisseur'
                    },
                    START_DATE: {
                        LABEL: 'Date de début',
                        PLACEHOLDER: 'Sélectionnez la date de début de publication'
                    },
                    SUPERVISOR: {
                        LABEL: 'Sélectionner un référent'
                    }
                }
            },
            THRESHOLDS: {
                CRITICAL: {
                    LABEL: 'Stock critique',
                    CAPTION: 'Stock après la date de fin : ⩽ -7j'
                },
                INSUFFICIENT: {
                    LABEL: 'Stock insuffisant',
                    CAPTION: 'Stock après la date de fin : > -7j et ⩽ 0j'
                },
                LIMIT: {
                    LABEL: 'Stock limite',
                    CAPTION: 'Stock après la date de fin : > 0j et ⩽ 7j'
                },
                OPTIMAL: {
                    LABEL: 'Stock optimal',
                    CAPTION: 'Stock après la date de fin : > 14j'
                },
                SUFFICIENT: {
                    LABEL: 'Stock suffisant',
                    CAPTION: 'Stock après la date de fin : > 7j et ⩽ 14j'
                },
                STOCK_NULL: {
                    LABEL: 'Stock inconnu',
                    CAPTION: 'Stock non renseigné'
                },
                STOCK_ZERO: {
                    LABEL: 'Stock nul',
                    CAPTION: 'Stock égal à zéro'
                },
                CONSUMPTION_NULL: {
                    LABEL: 'Conso inconnue',
                    CAPTION: 'Consommation non renseignée'
                },
                CONSUMPTION_ZERO: {
                    LABEL: 'Conso nulle',
                    CAPTION: 'Consommation égale à zéro'
                },
                NO_END_DATE: {
                    LABEL: 'Date de fin inconnue',
                    CAPTION: 'Date de fin de publication inconnue'
                }
            },
            MESSAGES: {
                FILES: {
                    DELETE: {
                        TITLE: 'Confirmer la suppression',
                        CONTENT: 'Voulez-vous vraiment supprimer ce fichier de la publication ?'
                    }
                },
                ALTERNATIVES: {
                    DELETE: {
                        TITLE: 'Confirmer la suppression',
                        CONTENT: 'Voulez-vous vraiment supprimer cette alternative ?'
                    }
                },
                PUBLICATION: {
                    DELETE: {
                        TITLE: 'Confirmer la suppression',
                        CONTENT: 'Voulez-vous vraiment supprimer cette publication ?',
                        CONTENT_MULTI: 'Voulez-vous vraiment supprimer ces publications ?'
                    }
                },
                COMMENT: {
                    DELETE: {
                        TITLE: 'Confirmer la suppression',
                        CONTENT: 'Voulez-vous vraiment supprimer ce commentaire ?'
                    }
                }
            },
            BOOKLET: {
                TITLES: {
                    ADD: 'Ajout de livret',
                    DIRECTORY_NEVER_IMPORTED: 'Livret jamais importé',
                    LATEST_DIRECTORY_IMPORT: 'Importation livret : {{value}}',
                    MAIN: 'Hospistock - Livret',
                    DELETE_ITEM: 'Confirmer la suppression de cette entrée du livret'
                },
                CONTENT: {
                    DELETE_ITEM: "Voulez-vous vraiment supprimer l'entrée du livret contenant {{name}} ?",
                    DELETED_ITEM: '{{name}} supprimé du livret'
                },
                IMPORT: {
                    TITLE: 'Importation du livret',
                    REPORT: {
                        SUCCESS: {
                            STATUS: 'Élements livret créés',
                            INSTRUCTIONS: 'Les élements livret listés ci-dessous ont été correctement créés.'
                        },
                        WARNING: {
                            STATUS: 'Élements livret non traités',
                            INSTRUCTIONS: "Les élements livret listés ci-dessous ne respectent pas les règles d'importation et n'ont pas été traités."
                        },
                        MESSAGES: {
                            INVALID_DRUG: "L'UCD ne correspond à aucun médiament connu.",
                            MULTIPLE_IMPORT: "Plusieurs élements livret associés à ce médicament ont été trouvés dans le fichier d'importation."
                        }
                    },
                    TEMPLATE: {
                        DRUG: 'Modele-dimportation-livret-medicament-Hospistock.xlsx',
                        MEDICAL_DEVICE: 'Modele-dimportation-livret-dispositif-medical-Hospistock.xlsx'
                    }
                },
                LABELS: {
                    NEVER_IMPORT: 'Jamais importé'
                },
                SEARCH: 'Rechercher un médicament',
                SEARCH_DM: 'Rechercher un DM',
                TABLE: {
                    ACTIONS: 'Actions',
                    CIP13: 'CIP',
                    PUBLICATION_TITLE: 'Titre publication',
                    CLADIMED_REF: 'Code CLADIMED',
                    CONSUMPTION: 'Conso. journalière',
                    DRUG_NAME: 'Nom du médicament',
                    MEDICAL_DEVICE_NAME: 'Nom du dispositif médical',
                    MEDICAL_DEVICE_REF: 'Référence fournisseur',
                    OPERATOR: 'Exploitant',
                    REFERENCE: 'Référence fournisseur',
                    STOCK: 'Stock',
                    UCD13: 'UCD'
                },
                ERRORS: {
                    FILE_FORMAT_INVALID: 'Format de fichier invalide.',
                    PARSING_ERROR: 'Error de lecture du fichier'
                },
                NOTIFS: {
                    ADD_TO: 'Ajout au livret réalisé',
                    MODIFIED: "Modification de l'entrée du marché réalisée"
                }
            },
            MARKET: {
                TITLES: {
                    ADD: 'Ajout de marché',
                    DIRECTORY_NEVER_IMPORTED: 'Marché jamais importé',
                    LATEST_DIRECTORY_IMPORT: 'Importation marché : {{value}}',
                    MAIN: 'Hospistock - Marché',
                    DELETE_ITEM: 'Confirmer la suppression de cette entrée du marché'
                },
                CONTENT: {
                    DELETE_ITEM: "Voulez-vous vraiment supprimer l'entrée du marché contenant {{name}} ?",
                    DELETED_ITEM: '{{name}} supprimé du marché'
                },
                IMPORT: {
                    TITLE: 'Importation des élements marché',
                    REPORT: {
                        SUCCESS: {
                            STATUS: 'Élements marché créés',
                            INSTRUCTIONS: 'Les élements marché listés ci-dessous ont été correctement créés.'
                        },
                        WARNING: {
                            STATUS: 'Élements marché non traités',
                            INSTRUCTIONS: "Les élements marché listés ci-dessous ne respectent pas les règles d'importation et n'ont pas été traités."
                        },
                        MESSAGES: {
                            INVALID_DRUG: "L'UCD ne correspond à aucun médiament connu.",
                            MULTIPLE_IMPORT: "Plusieurs élements marché associés à ce médicament ont été trouvés dans le fichier d'importation."
                        }
                    },
                    TEMPLATE: {
                        DRUG: 'Modele-dimportation-marche-medicament-Hospistock.xlsx',
                        MEDICAL_DEVICE: 'Modele-dimportation-marche-dispositif-medical-Hospistock.xlsx'
                    }
                },
                LABELS: {
                    NEVER_IMPORT: 'Jamais importé'
                },
                SEARCH: 'Rechercher un médicament',
                SEARCH_DM: 'Rechercher un DM',
                TABLE: {
                    ABBREVIATION: 'Abrev.',
                    CAMPAIGN: 'Camp.',
                    CIP13: 'CIP/ACL',
                    CLADIMED_CODE: 'Code CLADIMED',
                    PUBLICATION_TITLE: 'Titre publication',
                    DRUG_NAME: 'Nom du médicament',
                    LOT: 'N° lot',
                    MARKETNUM: 'N° marché',
                    MARKETSUBJECT: 'Objet du marché',
                    MEDICAL_DEVICE_NAME: 'Nom du dispositif médical',
                    UCD13: 'UCD',
                    UDI: 'IUD',
                    SUPPLIER_REF: 'Référence fournisseur',
                    ACTIONS: 'Actions'
                },
                ERRORS: {
                    FILE_FORMAT_INVALID: 'Format de fichier invalide.',
                    PARSING_ERROR: 'Error de lecture du fichier'
                },
                NOTIFS: {
                    ADD_TO: 'Ajout au marché réalisé',
                    MODIFIED: "Modification de l'entrée du marché réalisée"
                }
            },
            LABO: {
                DRUG: {
                    STATISTIC: {
                        SEARCH: 'Rechercher par médicament, UCD ou CIP',
                        TABLE: {
                            AVERAGE_DURATION: 'Durée en jours',
                            UPDATE_DATE: 'Date de modification',
                            HEALTH_PRODUCT_REF_NAME: 'Nom du médicament',
                            HEALTH_PRODUCT_REF_REF: 'UCD',
                            HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'CIP',
                            PROPOSED_ALTERNATIVES: 'Alternatives proposées',
                            READ: 'Lu',
                            SEEN: 'Vu',
                            SELECTED_ALTERNATIVE: 'Alternatives sélectionnées',
                            TYPE: 'Type'
                        },
                        PROPOSED_ALTERNATIVE_TABLE: {
                            TITLE: 'Alternatives proposées',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Nom du médicament',
                                HEALTH_PRODUCT_REF_REF: 'UCD',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'CIP',
                                OPERATOR: 'Laboratoire exploitant',
                                SOURCE: 'Source',
                                PROPOSED_DATE: 'Date de proposition'
                            }
                        },
                        SELECTED_ALTERNATIVE_TABLE: {
                            TITLE: 'Alternatives sélectionnées',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Nom du médicament',
                                HEALTH_PRODUCT_REF_REF: 'UCD',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'CIP',
                                OPERATOR: 'Laboratoire exploitant',
                                SOURCE: 'Source',
                                SELECTED_DATE: 'Date de sélection',
                                PHARMACY_SELECTED_ALTERNATIVE: 'PUI ayant sélectionné'
                            }
                        },
                        TITLE: 'Statistiques'
                    }
                },
                MEDICAL_DEVICE: {
                    STATISTIC: {
                        SEARCH: 'Rechercher par dispositif médical ou référence',
                        TABLE: {
                            AVERAGE_DURATION: 'Durée en jours',
                            ACTION: 'Action',
                            UPDATE_DATE: 'Date de modification',
                            DRUG_NAME: 'Nom du médicament',
                            HEALTH_PRODUCT_REF_NAME: 'Nom de la publication',
                            HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'Références fournisseur',
                            PROPOSED_ALTERNATIVES: 'Alternatives proposées',
                            READ: 'Lu',
                            SEEN: 'Vu',
                            SELECTED_ALTERNATIVE: 'Alternatives sélectionnées',
                            TYPE: 'Type'
                        },
                        PROPOSED_ALTERNATIVE_TABLE: {
                            TITLE: 'Alternatives proposées',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Nom de la publication',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'Références fournisseur',
                                SOURCE: 'Source',
                                OPERATOR: 'Exploitant',
                                PROPOSED_DATE: 'Date de proposition'
                            }
                        },
                        SELECTED_ALTERNATIVE_TABLE: {
                            TITLE: 'Alternatives sélectionnées',
                            TABLE: {
                                HEALTH_PRODUCT_REF_NAME: 'Nom de la publication',
                                HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF: 'Références fournisseur',
                                SOURCE: 'Source',
                                OPERATOR: 'Exploitant',
                                SELECTED_DATE: 'Date de sélection',
                                PHARMACY_SELECTED_ALTERNATIVE: 'PUI ayant sélectionné'
                            }
                        },
                        TITLE: 'Statistiques'
                    }
                },
                TITLE: 'Laboratoire',
                TEMPLATE: {
                    DRUG: 'Modele-dimportation-laboratoire-medicament-Hospistock.xlsx',
                    MEDICAL_DEVICE: 'Modele-dimportation-laboratoire-dispositif-medical-Hospistock.xlsx'
                },
                TABLE: {
                    CIP13: 'CIP',
                    ALTERNATIVE: 'Alternative',
                    MEDICAL_DEVICE_NAME: 'Nom du dispositif médical',
                    MEDICAL_DEVICE_REF: 'Référence fournisseur',
                    OPERATOR: 'Exploitant',
                    REFERENCE: 'Référence fournisseur',
                    UCD13: 'UCD',
                    SOURCE: 'Qui propose',
                    CREATION_DATE: 'Date de proposition',
                    PUI_ESTABLISHMENT: 'Qui sélectionne',
                    SELECTION_DATE: 'Date de sélection'
                }
            },
            PURCHASE_GROUP: {
                TITLE: "Groupement d'achat",
                TEMPLATE: {
                    DRUG: 'Modele-dimportation-groupement-dachat-medicament-Hospistock.xlsx',
                    MEDICAL_DEVICE: 'Modele-dimportation-groupement-dachat-dispositif-medical-Hospistock.xlsx'
                }
            },
            PHARMACY: {
                TITLE: 'PUI',
                TEMPLATE: {
                    DRUG: 'Modele-dimportation-PUI-medicament-Hospistock.xlsx',
                    MEDICAL_DEVICE: 'Modele-dimportation-PUI-dispositif-medical-Hospistock.xlsx'
                }
            },
            MEDICAL_DEVICE: {
                CUSTOM_INPUT: {
                    LABEL: 'Renseigner des dispositifs médicaux manuellement'
                },
                CLADIMED: {
                    HELP: 'Exemple: PANSEMENT ALGINATE PLAQUE - F51BC01',
                    TITLE: 'CLADIMED',
                    SEARCH: 'Recherche de CLADIMED'
                },
                ADD: {
                    SINGULAR: 'Ajouter un dispositif médical',
                    PLURAL: 'Ajouter des dispositifs médicaux'
                },
                DETAILS: {
                    TITLE: 'Détails des dispositifs médicaux'
                },
                SEARCH: {
                    TITLE: 'Recherche de dispositifs médicaux'
                },
                GROUP_NAME: {
                    TITLE: 'Libellé publication de dispositifs médicaux',
                    INPUT_LABEL: 'Ex : Chambres à cathéter implantables '
                },
                SELECT: {
                    PLURAL: 'Sélection de dispositifs médicaux',
                    SINGULAR: 'Sélection de dispositif médical'
                },
                LIST: {
                    TITLE: 'Liste des dispositifs médicaux'
                },
                NAME: { TITLE: 'Nom' },
                REFERENCE: { TITLE: 'Référence fournisseur' },
                SUPPLIER: { TITLE: 'Fournisseur' },
                BRAND: { TITLE: 'Marque' },
                UDI_CODE: { TITLE: 'Code UDI' },
                SHEET: {
                    LINK_NOT_FOUND: 'Pas de fiche trouvée pour le dispositif médical',
                    TITLE: 'Fiche',
                    TOOLTIP: 'Fiche Exhausmed'
                },
                ACTION: { TITLE: 'Actions', TOOLTIP: 'Supprimer le dispositif médical' },
                PLURAL_SELECTED: 'dispositifs médicaux sélectionnés',
                PLURAL_FOUND: 'dispositifs médicaux trouvés',
                NO_MEDICAL_DEVICE_FOUND: 'Aucun dispositif médical trouvé',
                SEARCH_PENDING: 'Recherche en cours...'
            }
        },
        DASHBOARD: {
            WIDGET: {
                LABO_LIST: {
                    TITLE: `Liste des laboratoires`,
                    NO_LABO: 'Pas de laboratoire.',
                    TABLE: {
                        NAME: 'Nom',
                        CREATION_DATE: "Date d'inscr."
                    }
                },
                NEWS: {
                    TITLE: `Actualités`,
                    EMPTY: "Pas d'actualité",
                    END: 'Fin des actualités'
                },
                SUMMARY: {
                    AVERAGE_PUBLICATION_DURATION: {
                        TITLE: 'Durée moyenne des publications'
                    },
                    PUBLICATION_TOTAL: {
                        TITLE: 'Total de publications'
                    },
                    TITLE: `Mes publications en cours`,
                    NO_LABO: 'Pas de publication.'
                },
                GROUP_LIST: {
                    TITLE: `Liste des groupements d'achat`,
                    NO_LABO: "Pas de groupements d'achat.",
                    TABLE: {
                        CREATION_DATE: "Date d'inscr."
                    },
                    MODAL: {
                        TITLE: 'Liste des PUI membres'
                    }
                }
            }
        },
        EXPORT: {
            ADMIN: 'admin',
            GA: "groupement-d'achat",
            LABO: 'labo',
            PUI: 'PUI',
            DRUG: 'medicament',
            MEDICAL_DEVICE: 'dispositif-medical',
            GROUP_LIST: 'liste-groupements',
            LABO_LIST: 'liste-labos',
            PUBLICATION: 'publications',
            PUBLICATION_GLOBAL: 'publications-globales',
            PUBLICATION_STATS: 'stats-publication',
            PUBLICATIONS_STATS: 'stats-publications',
            MARKET: 'marché',
            BOOKLET: 'livret',
            ALTERNATIVE: 'alternative'
        },
        ALTERNATIVE: {
            SIDEBAR: {
                TITLES: {
                    MAIN: 'Filtres',
                    DATES: 'Dates',
                    GROUPS: "Groupements d'achat",
                    SUBTITLES: {
                        SELECTED_GROUPS: "Groupements d'achat sélectionnés"
                    }
                },
                INPUTS: {
                    CREATION: {
                        LABEL: 'Date de proposition'
                    },
                    SELECTION: {
                        LABEL: 'Date de sélection'
                    },
                    GROUPS: {
                        LABEL: "Sélectionner un groupement d'achat"
                    }
                }
            }
        }
    }
};
