var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatTabGroup } from '@angular/material/tabs';
let ActivityMailsTabComponent = class ActivityMailsTabComponent {
    constructor() {
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _a : Object)
], ActivityMailsTabComponent.prototype, "tabGroup", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], ActivityMailsTabComponent.prototype, "mails", void 0);
ActivityMailsTabComponent = __decorate([
    Component({
        selector: 'activity-mails-tab',
        template: require('./activity-mails-tab.component.html').default,
        styles: [require('./activity-mails-tab.component.scss')],
        animations: fuseAnimations,
    }),
    __metadata("design:paramtypes", [])
], ActivityMailsTabComponent);
export { ActivityMailsTabComponent };
