import { LoginV2Controller } from './login-v2.controller';
import * as angular from 'angular';
angular.module('mapui.services.auth.login-v2', []).config(config).controller('LoginV2Controller', LoginV2Controller);
/** @ngInject */
function config($stateProvider) {
    // State
    $stateProvider.state('mapui.pages_auth_login-v2', {
        url: '/auth/login?:RPPS&:email&:valid&:origin&:redirect',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm'
            },
            'content@mapui.pages_auth_login-v2': {
                template: require('./login-v2.html').default,
                controller: 'LoginV2Controller as vm'
            }
        },
        bodyClass: 'login-v2'
    });
}
