export class MpSearchCountryComponent {
    constructor() {
        this.controller = 'MpSearchCountryController';
        this.controllerAs = 'vm';
        this.template = require('./mp-search-country.html').default;
        this.bindings = {
            label: '<',
            type: '<',
            country: '<',
            form: '<',
            name: '<',
            onSelected: '&',
        };
    }
    ;
}
