var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import { Utils } from '@main/services/Utils';
import { Access } from '@main/services/auth/access.service';
import { EHTTPStatus, ELoanCat, EMaPUIPermission, EMpStatus, Loan } from '@mapuilabs/mpl-interfaces';
import { ExchangeStatusesService } from '@services/exchange/statuses/exchange-statuses.service';
import { IdentityService } from '@services/identity/identity.service';
import { CLoanStatusIndicators } from '@shared/constTypes/loans/loan-status.const';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusService } from '../status/status.service';
let LoanService = class LoanService extends CrudTemplateService {
    constructor(_injector, _identityService, _exchangeStatusesService, _access, sts) {
        super(_injector, Loan, '/api/loans', {
            create: EMaPUIPermission.Request_Create,
            read: EMaPUIPermission.Request_Read,
            update: EMaPUIPermission.Request_Edit,
            delete: EMaPUIPermission.Request_Delete
        });
        this._injector = _injector;
        this._identityService = _identityService;
        this._exchangeStatusesService = _exchangeStatusesService;
        this._access = _access;
        this.sts = sts;
        this.getLoansAndBorrowings = (pageOptions, search, sort, filters, otherParams) => {
            if (!this._permissionService.authorize(this._permissions.read)) {
                return throwError(() => EHTTPStatus.Unauthorized);
            }
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (filters) {
                params = params.append('filters', JSON.stringify(filters));
            }
            if (sort) {
                params = params.append('sorting', JSON.stringify(sort));
            }
            if (!pageOptions) {
                //if (pageOptions)
                pageOptions = { page: 1, limit: 10 };
            } //todo: Remove when admin v1 is not used anymore
            params = params.append('pageOptions', JSON.stringify(pageOptions));
            params = this.addOtherParams(params, otherParams);
            return this._http.get(`/api/hospitals/${this._access.hospital._id}/loans-and-borrowings`, {
                params: params
            });
        };
        this.getArchives = (pageOptions, search, sort, filters, otherParams) => {
            if (!this._permissionService.authorize(this._permissions.read)) {
                return throwError(() => EHTTPStatus.Unauthorized);
            }
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (filters) {
                params = params.append('filters', JSON.stringify(filters));
            }
            if (sort) {
                params = params.append('sorting', JSON.stringify(sort));
            }
            if (!pageOptions) {
                //if (pageOptions)
                pageOptions = { page: 1, limit: 10 };
            } //todo: Remove when admin v1 is not used anymore
            params = params.append('pageOptions', JSON.stringify(pageOptions));
            params = this.addOtherParams(params, otherParams);
            return this._http
                .get(`/api/hospitals/${this._access.hospital._id}/archives`, {
                params: params
            })
                .pipe(map((response) => {
                response.items.forEach((loan) => {
                    if (this.sts.amIL(loan)) {
                        if (loan.borrower) {
                            loan.hospitalName = loan.borrower.name;
                        }
                    }
                    if (this.sts.amIB(loan)) {
                        if (loan.lender) {
                            loan.hospitalName = loan.lender.name;
                        }
                    }
                });
                return response;
            }));
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain all the awaiting loans of the wanted hospital
         * @param hid The [[Id]] of the wanted hospital. If no set, current hospital is used.
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getBillingWaitingFrom = (pageOptions, search, sort, filters, otherParams, hid) => {
            if (!hid) {
                hid = this._access.hospital._id;
            }
            if (!this._access.authorize(EMaPUIPermission.Billing_Read)) {
                return throwError(() => EMpStatus.NoAccess);
            }
            else {
                let params = new HttpParams();
                // Begin assigning parameters
                if (search) {
                    params = params.append('search', search);
                }
                if (filters) {
                    params = params.append('filters', JSON.stringify(filters));
                }
                if (sort) {
                    params = params.append('sorting', JSON.stringify(sort));
                }
                if (!pageOptions) {
                    pageOptions = { page: 1, limit: 10 };
                }
                params = params.append('pageOptions', JSON.stringify(pageOptions));
                params = this.addOtherParams(params, otherParams);
                return this._http.get(`/api/hospitals/${hid}/billings-awaiting/`, {
                    params: params
                });
            }
        };
        this.cLoanStatusIndicators = CLoanStatusIndicators;
        this.reloadArchives = new EventEmitter();
    }
    getPaginatedAvailablesForMap(filters) {
        return this._http.get(`${this._endPoint}/map/available`, {
            params: { filters: JSON.stringify(filters) }
        });
    }
    getLastLoan(id, limit) {
        const params = new HttpParams().set('limit', limit.toString());
        return this._http.get(`/api/hospitals/${id}/last-loans`, {
            params: params
        });
    }
    isLenderFromLoanOrBorrow(loan) {
        return (Utils.compareIds(this === null || this === void 0 ? void 0 : this._identityService.hospital, loan.lender) &&
            ELoanCat.LoanOrBorrow == loan.loanCategoryCreation);
    }
    isBorrowerFromLoanOrBorrow(loan) {
        return (Utils.compareIds(this === null || this === void 0 ? void 0 : this._identityService.hospital, loan.borrower) &&
            ELoanCat.LoanOrBorrow == loan.loanCategoryCreation);
    }
    getStatusIndicator(loan) {
        const statusName = this._exchangeStatusesService.getStatusName(loan);
        return this.cLoanStatusIndicators.find((status) => status.name === statusName);
    }
    /**
     * Get an array of [[ILoan]].
     * The array contain some loan (see it as 'Pret') made by the wanted hospital
     * @param hid The [[Id]] of the wanted hospital
     * @returns A promise containing the array of [[ILoan]]
     */
    getLoans(hid) {
        if (!this._access.authorize(EMaPUIPermission.MedInst_Read)) {
            throwError(() => EMpStatus.NoAccess);
        }
        else {
            return this._http.get(`/api/hospitals/${hid}/loans/`);
        }
    }
    getLoan(lid) {
        if (!this._access.authorize(EMaPUIPermission.MedInst_Read)) {
            throwError(() => EMpStatus.NoAccess);
        }
        else {
            return this._http.get(`/api/loans/${lid}`);
        }
    }
    /**
     * Get an array of [[ILoan]].
     * The array contain some borrowings (see it as 'Emprunt') made by the wanted hospital
     * @param hid The [[Id]] of the wanted hospital
     * @returns A promise containing the array of [[ILoan]]
     */
    getBorrowings(hid) {
        if (!this._access.authorize(EMaPUIPermission.MedInst_Read)) {
            throwError(() => EMpStatus.NoAccess);
        }
        else {
            return this._http.get(`/api/hospitals/${hid}/borrowings`);
        }
    }
    /**
     * Get an array of [[ILoan]].
     * The array contain all the loans in a billing state of the wanted hospital
     * @param hid The [[Id]] of the wanted hospital. If no set, current hospital is used.
     * @returns A promise containing the array of [[ILoan]]
     */
    getBillingsFrom(hid) {
        if (!hid) {
            hid = this._access.hospital._id;
        }
        if (!this._access.authorize(EMaPUIPermission.Billing_Read)) {
            return throwError(() => EMpStatus.NoAccess);
        }
        else {
            return this._http.get(`/api/hospitals/${hid}/billings/`);
        }
    }
    /**
     * Get an array of [[ILoan]].
     * The array contain all the loans in a settlement state of the wanted hospital
     * @param hid The [[Id]] of the wanted hospital. If no set, current hospital is used.
     * @returns A promise containing the array of [[ILoan]]
     */
    getSettlementsFrom(hid) {
        if (!hid) {
            hid = this._access.hospital._id;
        }
        if (!this._access.authorize(EMaPUIPermission.Billing_Read)) {
            return throwError(() => EMpStatus.NoAccess);
        }
        else {
            return this._http.get(`/api/hospitals/${hid}/settlements/`);
        }
    }
    /**
     * Get an array of [[ILoan]].
     * The array of preparations of the given [[IHospital]]
     * @param hid The [[Id]] of the wanted hospital
     * @returns A promise containing the array of [[ILoan]]
     */
    getPreparationsFrom(hid) {
        if (!hid) {
            hid = this._access.hospital._id;
        }
        if (!this._access.authorize(EMaPUIPermission.PrepInst_Read)) {
            return throwError(() => EMpStatus.NoAccess);
        }
        const res = [];
        return new Observable((observer) => {
            this.getBorrowings(hid).subscribe((loans) => {
                loans.find((loan) => {
                    if (loan.loanCategoryCreation === ELoanCat.Prep) {
                        res.push(loan);
                    }
                });
                observer.next(res);
                observer.complete();
            });
        });
    }
    isPrep(loan) {
        return this._exchangeStatusesService._isPrep(loan);
    }
    isDM(loan) {
        return this._exchangeStatusesService._isDM(loan);
    }
    isMed(loan) {
        return this._exchangeStatusesService._isMed(loan);
    }
};
LoanService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object, typeof (_c = typeof ExchangeStatusesService !== "undefined" && ExchangeStatusesService) === "function" ? _c : Object, typeof (_d = typeof Access !== "undefined" && Access) === "function" ? _d : Object, typeof (_e = typeof StatusService !== "undefined" && StatusService) === "function" ? _e : Object])
], LoanService);
export { LoanService };
