var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, EventEmitter, Input, Output } from '@angular/core';
let HsPublicationDetailActionTitleComponent = class HsPublicationDetailActionTitleComponent {
    constructor() {
        this.actionClicked = new EventEmitter();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationDetailActionTitleComponent.prototype, "actionIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationDetailActionTitleComponent.prototype, "titleIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationDetailActionTitleComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationDetailActionTitleComponent.prototype, "tooltip", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsPublicationDetailActionTitleComponent.prototype, "titleTooltip", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], HsPublicationDetailActionTitleComponent.prototype, "disableAction", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_a = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _a : Object)
], HsPublicationDetailActionTitleComponent.prototype, "actionClicked", void 0);
HsPublicationDetailActionTitleComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-action-title',
        template: `
        <div class="mb-8 h-40" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
            <span class="font-size-16" fxLayoutAlign="start center">
                <span>{{ text }}</span>
                <mat-icon class="mx-4 secondary-text" [matTooltip]="titleTooltip">{{ titleIcon }}</mat-icon>
            </span>

            <button
                *ngIf="!disableAction && actionIcon"
                mat-mini-fab
                color="primary"
                class="centered-content mat-elevation-z2"
                [matTooltip]="tooltip"
                (click)="actionClicked.emit()"
            >
                <mat-icon>{{ actionIcon }}</mat-icon>
            </button>
        </div>
    `
    }),
    __metadata("design:paramtypes", [])
], HsPublicationDetailActionTitleComponent);
export { HsPublicationDetailActionTitleComponent };
