var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ExternalActorsService } from '../../../services/external-actor/external-actors.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StateService } from '@uirouter/core';
import { ExternalActor, ExternalConfiguration } from '@mapuilabs/mpl-interfaces';
import { ExternalConfigurationService } from '../../../services/external-configuration/external-configuration.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { HospitalService } from '../../../services/hospital/hospital.service';
import { SearchHospitalComponent } from '../../../shared/components/search-hospital/search-hospital.component';
import { findIndex } from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { TranslationService } from '@app/services/translation/translation.service';
let ExternalConfigDetailComponent = class ExternalConfigDetailComponent {
    constructor(_formBuilder, _externalConfigService, _externalActorService, _translationService, _snackBar, _location, _stateService, _snackBarService, _hospitalService, _fuseTranslationLoaderService) {
        this._formBuilder = _formBuilder;
        this._externalConfigService = _externalConfigService;
        this._externalActorService = _externalActorService;
        this._translationService = _translationService;
        this._snackBar = _snackBar;
        this._location = _location;
        this._stateService = _stateService;
        this._snackBarService = _snackBarService;
        this._hospitalService = _hospitalService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        //TOREMOVE
        this.myControl = new UntypedFormControl();
        this.options = ['One', 'Two', 'Three'];
        this.isLoading = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        if (this._stateService.params.id == 'new') {
            this.pageType = 'new';
        }
        else {
            this.pageType = 'edit';
        }
        this.pageHeaderConfig = {
            title: this.pageType === 'new'
                ? this._translate('externalConfigDetail.TITLE_NEW')
                : this._translate('externalConfigDetail.TITLE_EDIT', { id: this.config.id })
        };
        this.configForm = this.createConfigForm();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    hospitalChange(event) {
        this.selectedHospital = event;
    }
    selectHospital() {
        this.config.establishments.push(this.selectedHospital);
        this._searchHospitalChild.reset();
    }
    deleteEstablishment(establishment) {
        const index = findIndex(this.config.establishments, { _id: establishment._id });
        this.config.establishments.splice(index, 1);
    }
    saveConfig() {
        const data = this.configForm.getRawValue();
        this.config.id = data.id;
        this.config.url = data.url;
        this.config.password = data.password;
        this.config.username = data.username;
        this._externalConfigService.save(this.config).subscribe((newConf) => {
            this.config = newConf;
            this.createConfigForm();
            this._snackBarService.open('La Configuration a été sauvegardée !');
        }, (err) => {
            this._snackBarService.openError(err);
        });
    }
    addConfig() {
        this.isLoading = true;
        const data = this.configForm.getRawValue();
        this.config.id = data.id;
        this.config.url = data.url;
        this.config.password = data.password;
        this.config.username = data.username;
        this.config.actor = this.actor;
        this._externalConfigService.add(this.config).subscribe((newConf) => {
            if (!this.actor.configurations) {
                this.actor.configurations = [];
            }
            this.actor.configurations.push(newConf);
            this._externalActorService.save(this.actor).subscribe(() => {
                this.config = newConf;
                this.createConfigForm();
                this._snackBarService.open('La configuration a été créée');
                this.isLoading = false;
                this.pageType = 'edit';
                this._location.replaceState(`adm/externalactor/${this.actor._id}/config/${this.config._id}`);
            }, (err) => {
                this._snackBarService.openError(err);
            });
        }, (err) => {
            this._snackBarService.openError(err);
        });
    }
    snackBarCopy() {
        this._snackBarService.open('Clé copiée dans le presse papier');
    }
    createConfigForm() {
        return this._formBuilder.group({
            _id: [this.config._id],
            id: [this.config.id],
            url: [this.config.url],
            username: [this.config.username],
            password: [this.config.password],
            hospital: null
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_l = typeof ExternalActor !== "undefined" && ExternalActor) === "function" ? _l : Object)
], ExternalConfigDetailComponent.prototype, "actor", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_m = typeof ExternalConfiguration !== "undefined" && ExternalConfiguration) === "function" ? _m : Object)
], ExternalConfigDetailComponent.prototype, "config", void 0);
__decorate([
    ViewChild(SearchHospitalComponent, { static: false }),
    __metadata("design:type", typeof (_o = typeof SearchHospitalComponent !== "undefined" && SearchHospitalComponent) === "function" ? _o : Object)
], ExternalConfigDetailComponent.prototype, "_searchHospitalChild", void 0);
ExternalConfigDetailComponent = __decorate([
    Component({
        selector: 'mpx-external-config-detail',
        template: require('./external-config-detail.component.html').default,
        styles: [require('./external-config-detail.component.scss')],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _a : Object, typeof (_b = typeof ExternalConfigurationService !== "undefined" && ExternalConfigurationService) === "function" ? _b : Object, typeof (_c = typeof ExternalActorsService !== "undefined" && ExternalActorsService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof MatSnackBar !== "undefined" && MatSnackBar) === "function" ? _e : Object, typeof (_f = typeof Location !== "undefined" && Location) === "function" ? _f : Object, typeof (_g = typeof StateService !== "undefined" && StateService) === "function" ? _g : Object, typeof (_h = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _h : Object, typeof (_j = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _j : Object, typeof (_k = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _k : Object])
], ExternalConfigDetailComponent);
export { ExternalConfigDetailComponent };
