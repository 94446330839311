import { EHospivillePermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { saveAs } from 'file-saver';
export class HvStatisticService {
    /** @ngInject */
    constructor(Api, $http, $q, Access, ToastService) {
        this.Api = Api;
        this.$http = $http;
        this.$q = $q;
        this.Access = Access;
        this.ToastService = ToastService;
        /**
         * Get all the [[IDrugBalance]] for an [[IHospital]] given
         * @return A promise containing an array of [[IDrugBalance]]
         */
        this.getMedRecs = (parameters) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EHospivillePermission.Statistics_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.statHospiville.stats.medRecs.get(parameters, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
    generateXlsx(parameters) {
        const defer = this.$q.defer();
        const req = {
            method: 'GET',
            url: '/xlsx-generation/hospiville-statistics/Statistiques-hospiville'
                + '.xlsx?xlsxmodel=hospiville-statistics&hid=' + this.Access.hospital._id + '&transit=' + parameters.transit + '&start=' + parameters.start + '&end=' + parameters.end,
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            body: parameters,
            responseType: 'arraybuffer',
        };
        this.$http(req).then((data) => {
            const blob = new Blob([(data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'Statistiques-hospiville.xlsx');
            defer.resolve(true);
        }).catch((error) => {
            this.ToastService.show(EMpStatus.RejectByServer);
            defer.resolve(error);
        });
        return defer.promise;
    }
}
