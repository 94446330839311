var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityASchema } from '@mapuilabs/mpl-interfaces';
import { ActivityDestService } from '@services/hospiville/activities/activities-dest.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { CActivityTypes } from '@shared/constTypes/activity/activity-type.const';
import { locale as hospivillesEnglish } from '../../../i18n/en';
import { locale as hospivilleFrench } from '../../../i18n/fr';
import { locale as activitiesEnglish } from '../../i18n/en';
import { locale as activitiesFrench } from '../../i18n/fr';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let ActivityDetailsDestComponent = class ActivityDetailsDestComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _activityService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._activityService = _activityService;
        this._snackBarService = _snackBarService;
        // -----------------------------------------------------------------------------------------------------
        // @ View methods
        // -----------------------------------------------------------------------------------------------------
        this.goToTabIndex = (tabIndex) => {
            this.tabGroup.selectedIndex = tabIndex;
        };
        /**
         * Updates the member object when a child component made a PUT request
         */
        this.refreshActivity = () => {
            this._activityService.getById(this.activity._id).subscribe((updatedActivity) => {
                this.activity = updatedActivity;
            }, (error) => this._snackBarService.openError(error));
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this._fuseTranslationLoaderService.loadTranslations(activitiesFrench, activitiesEnglish);
        this._fuseTranslationLoaderService.loadTranslations(hospivilleFrench, hospivillesEnglish);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        function isPopulated(object) {
            return typeof object !== 'string';
        }
        const patient = this.activity.patient;
        const source = this.activity.source;
        if (isPopulated(patient) && isPopulated(source)) {
            // Header
            this.pageHeaderConfig = {
                title: this._translationService.getTranslation(CActivityTypes, this.activity.type) + ' - ' + source.name,
                subtitle: this._translate('COMMONS.WORDS.PATIENT') +
                    this._translate('COMMONS.PUNCTUATION.COLON') +
                    ' ' +
                    patient.masterPatient.fullName
            };
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _e : Object)
], ActivityDetailsDestComponent.prototype, "activity", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ActivityDetailsDestComponent.prototype, "currentTabIndex", void 0);
__decorate([
    ViewChild(MatTabGroup, { static: true }),
    __metadata("design:type", typeof (_f = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _f : Object)
], ActivityDetailsDestComponent.prototype, "tabGroup", void 0);
ActivityDetailsDestComponent = __decorate([
    Component({
        selector: 'hvx-activity-details-dest',
        template: require('./activity-details-dest.component.html').default,
        styles: [require('../activity-details.component.scss'), require('./activity-details-dest.component.scss')],
        encapsulation: ViewEncapsulation.None
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof ActivityDestService !== "undefined" && ActivityDestService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object])
], ActivityDetailsDestComponent);
export { ActivityDetailsDestComponent };
