import { EHospivillePermission, EMpStatus, ETransit, } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class HvModalDrugBalanceAddPastMedRecController {
    /** @ngInject */
    constructor(ModalService, MedicationReconciliationService, DrugInstanceService, ToastService, $translate, Auth) {
        this.ModalService = ModalService;
        this.MedicationReconciliationService = MedicationReconciliationService;
        this.DrugInstanceService = DrugInstanceService;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.Auth = Auth;
        this.canSelectPreviousMedRec = () => {
            return this.Auth.authorize(EHospivillePermission.PreviousMedRec_Create);
        };
        /**
         *
         */
        this.mrChange = () => {
            this.MedicationReconciliationService.convertToSimpleInstances(this.selectedMedRec._id)
                .then((insts) => {
                this.instances = insts;
                this.mrDate = this.selectedMedRec.date ? new Date(this.selectedMedRec.date) : new Date(this.selectedMedRec.creationDate);
            })
                .catch((err) => {
                this.ToastService.show(err);
                console.error(err);
            });
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this.ModalService.close({ instances: this.instances, mrDate: this.mrDate });
        };
    }
    $onInit() {
        this.MedicationReconciliationService.getAllPast()
            .then((medRecs) => this.pastMedRecs = medRecs)
            .catch((err) => {
            this.ToastService.show(err);
            console.error(err);
        });
    }
    generateDosageAndPosologySentence(inst) {
        return `${this.DrugInstanceService.generateDosageSentence(inst.info)} ${this.DrugInstanceService.generatePosologySentence(inst.info.posologies[0])}`;
    }
    getTransitLabel(transit) {
        switch (transit) {
            case ETransit.Entrance:
                return this.$translate.instant('HOSPIVILLE.CONST_TYPE.TRANSIT.ENTRANCE');
            case ETransit.Exit:
                return this.$translate.instant('HOSPIVILLE.CONST_TYPE.TRANSIT.EXIT');
            case ETransit.Transfer:
                return this.$translate.instant('HOSPIVILLE.CONST_TYPE.TRANSIT.TRANSFER');
        }
        return '';
    }
    isCurrentMR(mr) {
        return Utils.compareIds(Utils.getId(mr), Utils.getId(this.medReconciliation));
    }
}
