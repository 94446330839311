export const locale = {
    lang: 'fr',
    data: {
        WIDGET_HOME: {
            TITLE: 'Widgets personnalisés',
            TABLE: {
                PERIOD: {
                    TITLE: 'Période',
                    FROM: 'Du',
                    TO: 'Au',
                    NOT_DEFINE: 'indéfiniment'
                },
                TITLE: 'Titre',
                ACTIONS: 'Actions',
                VISIBILITY: {
                    ON: 'Activer',
                    OFF: 'Désactiver'
                },
                DUPLICATE: {
                    TITLE: 'Dupliquer',
                    NOTIF: 'Widget dupliqué'
                },
                EMPTY: 'Aucun widget'
            },
            DETAIL: {
                RESET: {
                    TITLE: 'Ajout de bouton en cours',
                    MESSAGE: "Voulez-vous vraiment continuer ? L'ajout du bouton en cours sera annulé."
                },
                INVALID_FORM: 'Formulaire invalide',
                SETTINGS: {
                    LABEL: 'Paramètres',
                    PERIOD_START: 'Date de début',
                    PERIOD_END: 'Date de fin',
                    VISIBILITY: 'Visibilité',
                    PRIORITY_ORDER: 'Ordre de priorité',
                    WIDTH: 'Largeur',
                    BACKGROUND_COLOR: 'Couleur de fond',
                    TITLE: 'Titre',
                    CONTENT: {
                        TITLE: 'Contenu',
                        HELP: {
                            LABEL: 'Aide : ',
                            ITALIC: 'italique',
                            BOLD: 'gras',
                            UNDERLINE: 'souligné',
                            ELEMENT: 'élément',
                            TITLE: 'titre',
                            BREAK_LINE: '2 espaces pour un retour à la ligne'
                        }
                    },
                    COUNTER: 'Compteur',
                    BUTTONS: {
                        LABEL: 'Boutons',
                        TITLE: 'Titre',
                        REDIRECT: {
                            TITLE: 'Redirection'
                        },
                        URL: 'URL',
                        COLOR: 'Couleur',
                        LAYOUT: 'Alignement'
                    }
                },
                PREVIEW: {
                    LABEL: 'Pré-visualisation'
                }
            }
        }
    }
};
