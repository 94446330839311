export const locale = {
    lang: 'en',
    data: {
        AVAILABLE_WIDGET: {
            TITLE: `Last availables`,
            UNIT_TOTAL: ` unit | Total: `,
            NO_AVAILABLE: 'No recent available offers.',
            EXPIRES: 'Expires on ',
            SEE_MORE: 'See more availables',
            FORBIDDEN_MESSAGE: 'Access denied',
            CONTACT_US: 'Contact us'
        }
    }
};
