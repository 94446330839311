export class HvModalDrugComponent {
    constructor() {
        this.controller = 'HvModalDrugController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-drug.html').default;
        this.bindings = {
            drugs: '<',
        };
    }
}
