var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '@angular/core';
let ExSinceDateMenuComponent = class ExSinceDateMenuComponent {
    constructor() {
        this.menuPosition = 'before';
        this.iconValue = 'calendar_today';
        this.sinceDateMenuConfig = { button: { icon: { name: this.iconValue } } };
        this.pageHeaderConfig = {
            title: 'Since date menu Component',
            icon: 'extension '
        };
    }
    onIconChange() {
        this.sinceDateMenuConfig = { button: { icon: { name: this.iconValue } } };
    }
};
ExSinceDateMenuComponent = __decorate([
    Component({
        selector: 'mpx-ex-since-date-menu',
        template: require('./ex-since-date-menu.component.html').default,
        styles: [require('./ex-since-date-menu.component.scss')]
    })
], ExSinceDateMenuComponent);
export { ExSinceDateMenuComponent };
