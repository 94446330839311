var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsHealthProductDirectoryService } from '@app/hospistock/shared/services/hs-health-product-directory.service';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { regExpFirstZerosAndSpecialCharacters } from '@app/shared/constTypes/reg-exp.const';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EHsHealthProductTypes, HsBookletItemDrugASchema, HsBookletItemMedicalDeviceASchema } from '@mapuilabs/mpl-interfaces';
import { CustomValidators } from '@shared/commons/validators';
import { lastValueFrom, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
let HsModalBookletFormComponent = class HsModalBookletFormComponent {
    constructor(data, _dialogRef, _fuseTranslationLoaderService, _fb) {
        this.data = data;
        this._dialogRef = _dialogRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fb = _fb;
        this.isSaving = false;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        // Bind controller's services to injector's services
        this._healthProductService = this.data.injector.get(HsHealthProductService);
        this._healthProductDirectoryService = this.data.injector.get(HsHealthProductDirectoryService);
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a, _b, _c, _d;
        // Init defaults
        this.bookletItem = this.data.item;
        this.mode = this.bookletItem ? 'EDIT' : 'NEW';
        // Define form
        this.form = this._fb.group({
            // HealthProduct form
            healthProductRef: this._healthProductService.handleHealthProductRef(
            // Handle HsDrug
            (drugRef) => this._fb.group({
                name: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.name, Validators.required],
                ref: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.ref, [Validators.required, CustomValidators.ucd7or13]],
                ATC: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.ATC],
                laboratoryTit: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.laboratoryTit],
                operator: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.operator],
                healthProducts: [(drugRef === null || drugRef === void 0 ? void 0 : drugRef.healthProducts) || [], Validators.required]
            }), 
            // Handle HsMedicalDevice
            (medicalDeviceRef) => this._fb.group({
                name: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.name, Validators.required],
                ref: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.ref],
                operator: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.operator, [Validators.required]],
                healthProducts: [(medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.healthProducts) || [], Validators.required]
            }), (_a = this.bookletItem) === null || _a === void 0 ? void 0 : _a.healthProductRef),
            // Manual specific
            manual: [((_b = this.bookletItem) === null || _b === void 0 ? void 0 : _b.manual) || false],
            // Booklet specific
            consumption: [((_c = this.bookletItem) === null || _c === void 0 ? void 0 : _c.consumption) || null, CustomValidators.decimalNumber],
            stock: [((_d = this.bookletItem) === null || _d === void 0 ? void 0 : _d.stock) || null, CustomValidators.decimalNumber]
        });
        // Subscribe to health product changes to update existing health product flag
        this.bookletItemAlreadyExists$ = this.form.get('healthProductRef.healthProducts').valueChanges.pipe(takeUntil(this._unsubscribeAll), switchMap(() => this._healthProductItemAlreadyExists()), map((existingMarketItem) => !!existingMarketItem));
    }
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    isInvalidOrPristine() {
        return this.form.invalid || this.form.pristine;
    }
    submitForm() {
        return __awaiter(this, void 0, void 0, function* () {
            // Prevent invalid form
            if (this.isInvalidOrPristine()) {
                return;
            }
            const healthProductDirectoryItem = this._healthProductService.handleHealthProductRef(
            // Handle HsDrug
            () => {
                const healthProductDirectoryItem = Object.assign(this.bookletItem || {}, this.form.value);
                // Convert to UCD13
                healthProductDirectoryItem.healthProductRef.ref = Utils.ucd7ToUcd13(healthProductDirectoryItem.healthProductRef.ref);
                return new HsBookletItemDrugASchema(healthProductDirectoryItem);
            }, 
            // Handle HsMedicalDevice
            () => {
                const healthProductDirectoryItem = Object.assign(this.bookletItem || {}, this.form.value);
                // Clean medical devices ref
                healthProductDirectoryItem.healthProductRef.healthProducts.forEach((healthProduct) => {
                    healthProduct.ref = healthProduct.ref.replace(regExpFirstZerosAndSpecialCharacters, '');
                });
                return new HsBookletItemMedicalDeviceASchema(healthProductDirectoryItem);
            });
            // Update isSaving status
            this.isSaving = true;
            this._dialogRef.close(healthProductDirectoryItem);
        });
    }
    _healthProductItemAlreadyExists() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const healthProductRef = this.form.get('healthProductRef').value;
            if (this.form.get('manual').value || ((_a = healthProductRef.healthProducts) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return false;
            }
            return lastValueFrom(this._healthProductDirectoryService.itemExistsForUserFromHealthProductsRef(healthProductRef, Utils.getId(this.bookletItem)));
        });
    }
};
HsModalBookletFormComponent = __decorate([
    Component({
        selector: 'mpx-hs-modal-booklet-form',
        template: require('./hs-modal-booklet-form.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [Object, typeof (_a = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _c : Object])
], HsModalBookletFormComponent);
export { HsModalBookletFormComponent };
