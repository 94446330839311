import { EAdminPanelPermission, EHospivillePermission, EMaPUIPermission, EWorkspacePermission } from '@mapuilabs/mpl-interfaces';
export const navigation = [
    {
        id: 'root',
        title: 'Accueil',
        type: 'item',
        icon: 'home',
        permissions: EMaPUIPermission.Home_SeeMenuItem,
        url: 'mapui.home'
    },
    {
        id: 'admin',
        title: 'Administration',
        type: 'group',
        permissions: [
            EAdminPanelPermission.ExternalActors_SeeMenuItem,
            EAdminPanelPermission.Cron_SeeMenuItem,
            EAdminPanelPermission.GHT_SeeMenuItem,
            EAdminPanelPermission.LaboGroups_SeeMenuItem,
            EAdminPanelPermission.AdminPanel_SeeMenuItem,
            EAdminPanelPermission.Prospects_SeeMenuItem,
            EAdminPanelPermission.Roles_SeeMenuItem,
            EAdminPanelPermission.SecureEmails_SeeMenuItem,
            EAdminPanelPermission.Statistics_SeeMenuItem,
            EAdminPanelPermission.HsStockoutConfig_SeeMenuItem
        ],
        children: [
            {
                id: 'admin.panel',
                title: "Panneau d'admin",
                type: 'collapsable',
                icon: 'view_list',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                children: [
                    {
                        id: 'admin.panel.ars',
                        title: 'ARS',
                        type: 'item',
                        icon: 'pin_drop',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.ars.list'
                    },
                    {
                        id: 'admin.panel.hospitals',
                        title: 'Hôpitaux',
                        type: 'item',
                        icon: 'local_hospital',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.hospitals.list'
                    },
                    {
                        id: 'admin.panel.officines',
                        title: 'Officines',
                        type: 'item',
                        icon: 'local_pharmacy',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.pharmacies.list'
                    },
                    {
                        id: 'admin.panel.users',
                        title: 'Utilisateurs',
                        type: 'item',
                        icon: 'person',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.members.list'
                    },
                    {
                        id: 'admin.panel.groups',
                        title: 'Groupes',
                        type: 'item',
                        icon: 'groups',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.groups.list'
                    },
                    {
                        id: 'admin.panel.labos',
                        title: 'Laboratoires',
                        type: 'item',
                        svg: 'preparation',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.labos.list'
                    },
                    {
                        id: 'admin.panel.purchase-groups',
                        title: "Groupements d'achat",
                        type: 'item',
                        icon: 'shopping_cart',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.purchase-groups.list'
                    }
                ]
            },
            {
                id: 'admin.statistics',
                title: 'Statistiques',
                type: 'collapsable',
                icon: 'pie_chart',
                permissions: EAdminPanelPermission.Statistics_SeeMenuItem,
                children: [
                    {
                        id: 'admin.statistics.hospiville',
                        title: 'Hospiville - Concil.',
                        type: 'item',
                        icon: 'local_hospital',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.stats.hospiville'
                    },
                    {
                        id: 'admin.statistics.activities',
                        title: 'Hospiville - Activités',
                        type: 'item',
                        icon: 'local_pharmacy',
                        classes: 'theme-purple-nav',
                        url: 'mapui.adm.stats.activities'
                    }
                ]
            },
            {
                id: 'mapui.adm.homepage',
                title: `Config. page d'accueil`,
                type: 'collapsable',
                icon: 'table_chart',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                children: [
                    {
                        id: 'mapui.adm.homepage.custom-widgets.list',
                        title: 'Widgets personnalisés',
                        type: 'item',
                        icon: 'widgets',
                        classes: 'theme-purple-nav',
                        permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                        url: 'mapui.adm.homepage.custom-widgets.list'
                    },
                    {
                        id: 'mapui.adm.homepage.release-notes.list',
                        title: 'Notes de version',
                        type: 'item',
                        classes: 'theme-purple-nav',
                        icon: 'event_note',
                        permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                        url: 'mapui.adm.homepage.release-notes.list'
                    }
                ]
            },
            {
                id: 'admin.sandbox',
                title: 'Sandbox',
                type: 'item',
                icon: 'extension',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.Cron_SeeMenuItem,
                url: 'mapui.adm.sandbox.main'
            },
            {
                id: 'admin.secure-emails',
                title: 'Emails sécurisés',
                type: 'item',
                icon: 'email',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.SecureEmails_SeeMenuItem,
                url: 'mapui.adm.secure-emails.list'
            },
            {
                id: 'admin.online-users',
                title: 'Utilisateurs en ligne',
                type: 'item',
                icon: 'timelapse',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.OnlineUsers_SeeMenuItem,
                url: 'mapui.adm.online_users.list'
            },
            {
                id: 'admin.cron',
                title: 'CRON Manager',
                type: 'item',
                icon: 'alarm',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.Cron_SeeMenuItem,
                url: 'mapui.adm.cron.list'
            },
            {
                id: 'admin.roles',
                title: 'Rôles',
                type: 'item',
                icon: 'check_circle',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                url: 'mapui.adm.roles.project'
            },
            {
                id: 'admin.external-actors',
                title: 'Acteurs externes',
                type: 'item',
                icon: 'account_balance',
                classes: 'theme-purple-nav',
                permissions: EAdminPanelPermission.AdminPanel_SeeMenuItem,
                url: 'mapui.adm.external-actors-v2.list'
            },
            {
                id: 'admin.hospistock',
                title: `Hospistock`,
                type: 'collapsable',
                svg: 'cart-remove',
                permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                children: [
                    {
                        id: 'admin.hospistock.drug',
                        title: `Médicament`,
                        type: 'collapsable',
                        svg: 'pill',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        children: [
                            {
                                id: 'admin.hospistock.drug.publication',
                                title: 'Publications',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-purple-nav',
                                permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                                url: 'mapui.adm.hospistock.drug.publication.list'
                            }
                        ]
                    },
                    {
                        id: 'admin.hospistock.medical-device',
                        title: `DM`,
                        type: 'collapsable',
                        icon: 'healing',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        children: [
                            {
                                id: 'admin.hospistock.medical-device.publication',
                                title: 'Publications',
                                type: 'item',
                                svg: 'cart-remove',
                                classes: 'theme-purple-nav',
                                permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                                url: 'mapui.adm.hospistock.medical-device.publication.list'
                            }
                        ]
                    },
                    {
                        id: 'admin.hospistock.config',
                        title: 'Config',
                        type: 'item',
                        icon: 'settings',
                        classes: 'theme-purple-nav',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        url: 'mapui.adm.hospistock.config'
                    },
                    {
                        id: 'admin.hospistock.news',
                        title: 'News',
                        type: 'item',
                        icon: 'event_note',
                        classes: 'theme-purple-nav',
                        permissions: EAdminPanelPermission.HsStockoutConfig_SeeMenuItem,
                        url: 'mapui.adm.hospistock.news'
                    }
                ]
            }
        ]
    },
    {
        id: 'workspace',
        title: 'Espace de travail',
        type: 'group',
        permissions: [EWorkspacePermission.Files_SeeMenuItem],
        children: [
            {
                id: 'workspace.files',
                title: 'Fichiers',
                type: 'item',
                icon: 'folder',
                permissions: EWorkspacePermission.Files_SeeMenuItem,
                url: 'mapui.files'
            }
        ]
    },
    {
        id: 'hospiville',
        title: 'Hospiville',
        type: 'group',
        permissions: [
            EHospivillePermission.Activities_SeeMenuItem,
            EHospivillePermission.Mailbox_SeeMenuItem,
            EHospivillePermission.CarePlan_SeeMenuItem,
            EHospivillePermission.DBS_SeeMenuItem,
            EHospivillePermission.ExtensivePatientInterview_SeeMenuItem,
            EHospivillePermission.MedicationReconciliation_SeeMenuItem,
            EHospivillePermission.PatientFile_SeeMenuItem,
            EHospivillePermission.PatientFiles_SeeMenuItem,
            EHospivillePermission.PatientInterviewSurvey_SeeMenuItem,
            EHospivillePermission.Prescription_SeeMenuItem,
            EHospivillePermission.ReconciliationSheet_SeeMenuItem,
            EHospivillePermission.TreatmentAnalysis_SeeMenuItem,
            EHospivillePermission.Patients_SeeMenuItem,
            EHospivillePermission.Statistics_SeeMenuItem,
            EHospivillePermission.Configuration_SeeMenuItem
        ],
        children: [
            {
                id: 'hospiville.patients-v2',
                title: 'Patients',
                type: 'item',
                icon: 'group',
                classes: 'theme-light-green-nav',
                permissions: EHospivillePermission.Patients_SeeMenuItem,
                url: 'hospivilleV2.patients.list'
            },
            {
                id: 'hospiville.patient',
                title: 'Patient',
                type: 'collapsable',
                icon: 'person',
                permissions: [
                    EHospivillePermission.CarePlan_SeeMenuItem,
                    EHospivillePermission.DBS_SeeMenuItem,
                    EHospivillePermission.ExtensivePatientInterview_SeeMenuItem,
                    EHospivillePermission.MedicationReconciliation_SeeMenuItem,
                    EHospivillePermission.PatientFile_SeeMenuItem,
                    EHospivillePermission.PatientFiles_SeeMenuItem,
                    EHospivillePermission.PatientInterviewSurvey_SeeMenuItem,
                    EHospivillePermission.Prescription_SeeMenuItem,
                    EHospivillePermission.ReconciliationSheet_SeeMenuItem,
                    EHospivillePermission.TreatmentAnalysis_SeeMenuItem
                ],
                children: [
                    {
                        id: 'hospiville.patient.file',
                        title: 'Fiche patient',
                        type: 'item',
                        icon: 'portrait',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.PatientFile_SeeMenuItem,
                        url: 'hospiville.patient-file'
                    },
                    {
                        id: 'hospiville.patient.interview-survey',
                        title: 'Entretien patient',
                        type: 'item',
                        icon: 'chat',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.PatientInterviewSurvey_SeeMenuItem,
                        url: 'hospiville.patient-interview-survey'
                    },
                    {
                        id: 'hospiville.patient.dbs',
                        title: 'Bilan médicamenteux',
                        type: 'item',
                        icon: 'assignment_turned_in',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.DBS_SeeMenuItem,
                        url: 'hospiville.bmo'
                    },
                    {
                        id: 'hospiville.patient.medication-reconciliation',
                        title: 'CTM',
                        type: 'item',
                        icon: 'sync',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.MedicationReconciliation_SeeMenuItem,
                        url: 'hospiville.ctm'
                    },
                    {
                        id: 'hospiville.patient.reconciliation-sheet',
                        title: 'Fiche de conciliation',
                        type: 'item',
                        icon: 'assignment',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.ReconciliationSheet_SeeMenuItem,
                        url: 'hospiville.reconciliation-sheet'
                    },
                    {
                        id: 'hospiville.patient.prescription',
                        title: 'Prescriptions',
                        type: 'item',
                        icon: 'healing',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.Prescription_SeeMenuItem,
                        url: 'hospiville.prescription'
                    },
                    {
                        id: 'hospiville.patient.files',
                        title: 'Documents patient',
                        type: 'item',
                        icon: 'folder',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.PatientFiles_SeeMenuItem,
                        url: 'hospiville.patient-files'
                    },
                    {
                        id: 'hospiville.patient.extensive-patient-interview',
                        title: "Recueil d'information",
                        type: 'item',
                        icon: 'description',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.ExtensivePatientInterview_SeeMenuItem,
                        url: 'hospiville.extensive-patient-interview'
                    },
                    {
                        id: 'hospiville.patient.treatment-analysis',
                        title: 'Analyse traitement',
                        type: 'item',
                        icon: 'search',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.TreatmentAnalysis_SeeMenuItem,
                        url: 'hospiville.treatment-analysis'
                    },
                    {
                        id: 'hospiville.patient.care-plan',
                        title: 'Plan de prise',
                        type: 'item',
                        icon: 'format_list_numbered',
                        classes: 'theme-light-green-nav',
                        permissions: EHospivillePermission.CarePlan_SeeMenuItem,
                        url: 'hospiville.care-plan'
                    }
                ]
            },
            {
                id: 'hospiville.configuration',
                title: 'Configuration',
                type: 'item',
                icon: 'settings',
                classes: 'theme-light-green-nav',
                permissions: EHospivillePermission.Configuration_SeeMenuItem,
                url: 'hospivilleV2.configuration'
            },
            {
                id: 'hospiville.statistics',
                title: 'Hospiville Statistiques',
                type: 'item',
                icon: 'pie_chart',
                classes: 'theme-light-green-nav',
                permissions: EHospivillePermission.Statistics_SeeMenuItem,
                url: 'hospiville.statistics'
            },
            {
                //complex permission rules are defined in navbar.component.ts
                id: 'hospiville.activities-v2-dest',
                type: 'item',
                title: 'Échanges ville-hôpital',
                icon: 'repeat',
                classes: 'theme-light-green-nav',
                url: 'hospivilleV2.activities.dest.list',
                hidden: true
            },
            {
                //complex permission rules are defined in navbar.component.ts
                id: 'hospiville.activities-v2-source',
                type: 'item',
                title: 'Échanges ville-hôpital',
                icon: 'repeat',
                classes: 'theme-light-green-nav',
                url: 'hospivilleV2.activities.source.list',
                hidden: true
            }
        ]
    }
];
