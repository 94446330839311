import * as angular from 'angular';
import { HvPatientSearchComponent } from './hv-patient-search/hv-patient-search.component';
import { HvPatientSearchController } from './hv-patient-search/hv-patient-search.controller';
import { HvPatientShareModalComponent } from './hv-patient-share-modal/hv-patient-share-modal.component';
import { HvPatientShareModalController } from './hv-patient-share-modal/hv-patient-share-modal.controller';
import { HvPatientTransferModalComponent } from './hv-patient-transfer-modal/hv-patient-transfer-modal.component';
import { HvPatientTransferModalController } from './hv-patient-transfer-modal/hv-patient-transfer-modal.controller';
import { PatientService } from './patient.service';
angular.module('hospiville.services.patient', [])
    .component('hvPatientSearch', new HvPatientSearchComponent())
    .controller('HvPatientSearchController', HvPatientSearchController)
    .component('hvPatientShareModal', new HvPatientShareModalComponent())
    .controller('HvPatientShareModalController', HvPatientShareModalController)
    .component('hvPatientTransferModal', new HvPatientTransferModalComponent())
    .controller('HvPatientTransferModalController', HvPatientTransferModalController)
    .service('PatientService', PatientService);
