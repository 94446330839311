var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { HsDownloadService } from '@app/hospistock/shared/services/hs-download.service';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { UrlService } from '@app/services/url/url.service';
let HsPublicationSingleViewDetailComponent = class HsPublicationSingleViewDetailComponent {
    constructor(_publicationContainerService, _translationService, _urlService) {
        this._publicationContainerService = _publicationContainerService;
        this._translationService = _translationService;
        this._urlService = _urlService;
        // Read publication
        this._publicationContainerService.readPublication(this._urlService.getParam('id'));
        // Init constants
        this.dynamicDataContainerConfig = {
            noData: {
                svgIcon: 'cart-remove',
                label: this._translationService.instant('PUBLICATION.DETAILS.NO_SELECTED_PUBLICATION')
            }
        };
    }
    get isLoadingDetail() {
        return this._publicationContainerService.isLoadingDetail.value;
    }
    get currentPublicationContainer$$() {
        return this._publicationContainerService.currentPublicationContainer$$;
    }
    get currentPublicationContainer$() {
        return this.currentPublicationContainer$$.value;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Update config
        this.pageHeaderConfig = this.config.pageHeader;
        this.detailConfig = this.config.detail;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationSingleViewDetailComponent.prototype, "config", void 0);
HsPublicationSingleViewDetailComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-single-view-detail',
        template: require('./hs-publication-single-view-detail.component.html').default,
        providers: [HsPublicationContainerService, HsHealthProductService, HsDownloadService]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof UrlService !== "undefined" && UrlService) === "function" ? _c : Object])
], HsPublicationSingleViewDetailComponent);
export { HsPublicationSingleViewDetailComponent };
