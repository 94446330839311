import { EMpStatus } from '@mapuilabs/mpl-interfaces';
/** @ngInject */
export class MpFirstConnectionController {
    /** @ngInject */
    constructor($state, _, Auth, Api, ToastService, MemberService, $cookies) {
        this.$state = $state;
        this._ = _;
        this.Auth = Auth;
        this.Api = Api;
        this.ToastService = ToastService;
        this.MemberService = MemberService;
        this.$cookies = $cookies;
        /**
         * Submit the first connection information
         */
        this.submit = () => {
            if (!this.memberForm.acceptedCGU) {
                this.ToastService.show(EMpStatus.CGUNotAccepted);
                return;
            }
            this.isLoading = true;
            this.memberForm.acceptedCGUDate = new Date();
            this.Auth.user.acceptedCGUDate = this.memberForm.acceptedCGUDate;
            this.MemberService.save(this.memberForm)
                .then((res) => {
                this.isLoading = false;
                this.Auth.user.activation = false;
                this.Auth.user.acceptedCGU = true;
                this.Auth.user.acceptedCGUDate = res.acceptedCGUDate;
                this.Auth.user.fullName = res.fullName;
                this.Auth.user.firstName = res.firstName;
                this.Auth.user.familyName = res.familyName;
                this.$cookies.putObject('user', this.Auth.user);
                this.$state.go('mapui.home');
            });
        };
        this.isLoading = false;
    }
    $onInit() {
        this.Api.constType.get((res) => {
            this.diffusionTypes = res.diffusionTypes;
        });
        this.memberForm = {
            _id: this.Auth.user._id,
            firstName: this.Auth.user.firstName,
            familyName: this.Auth.user.familyName,
            phone: this.Auth.user.phone,
        };
    }
}
