import 'angular-file-upload';
import * as angular from 'angular';
import { FileUploaderService } from './file-uploader.service';
import { MpFilesDisplayComponent } from './mp-files-display/mp-files-display.component';
import { MpFilesDisplayController } from './mp-files-display/mp-files-display.controller';
import { MpFileUploaderComponent } from './mp-file-uploader/mp-file-uploader.component';
import { MpFileUploaderController } from './mp-file-uploader/mp-file-uploader.controller';
angular
    .module('mapui.services.fileUploader', [
    'angularFileUpload',
])
    .service('FileUploaderService', FileUploaderService)
    .component('mpFilesDisplay', new MpFilesDisplayComponent())
    .controller('MpFilesDisplayController', MpFilesDisplayController)
    .component('mpFileUploader', new MpFileUploaderComponent())
    .controller('MpFileUploaderController', MpFileUploaderController);
