var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { Component } from '@angular/core';
import { IdentityService } from '../../services/identity/identity.service';
import { EGroupType, EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { UrlService } from '../../services/url/url.service';
import { QuickPanelService } from '../../../layout/vertical-navigation/quick-panel/quick-panel.service';
import { Access as AjsAccess } from '../../../main/services/auth/access.service';
import { HospitalService } from '../../services/hospital/hospital.service';
import { PermissionService } from '../../services/permission/permission.service';
import { NotificationService } from '../../services/notification/notification.service';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { FuseConfigService } from '@fuse/services/config.service';
let ToolbarComponent = class ToolbarComponent {
    constructor(_identityService, _stateService, Access, _hospitalService, _permissionService, _fuseConfigService, _fuseSidebarService, _fuseTranslationLoaderService, urlService, quickpanelService, notificationService) {
        this._identityService = _identityService;
        this._stateService = _stateService;
        this.Access = Access;
        this._hospitalService = _hospitalService;
        this._permissionService = _permissionService;
        this._fuseConfigService = _fuseConfigService;
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.urlService = urlService;
        this.quickpanelService = quickpanelService;
        this.notificationService = notificationService;
        // Theme
        this._theme = 'theme-blue';
        this.user = this._identityService.user;
        this.canAccessHome = this._permissionService.authorize(EMaPUIPermission.Home_SeeMenuItem);
        this.canAccessDashboard = this._permissionService.authorize(EMaPUIPermission.Dashboard_SeeMenuItem);
        this.canAccessMap = this._permissionService.authorize(EMaPUIPermission.Map_SeeMenuItem);
        this.canAccessProfile = this._permissionService.authorize(EMaPUIPermission.User_SeeMenuItem);
        this.canAccessEstablishment = this._permissionService.authorize(EMaPUIPermission.Establishment_SeeMenuItem);
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    get theme() {
        return this.fuseConfig.scheme === 'dark' ? `${this._theme}-dark` : this._theme;
    }
    ngOnInit() {
        this._identityService.reloadHospital();
        this._hospitalService.getById(this._identityService.hospital._id).subscribe((hospital) => {
            this.hospital = hospital;
            if (hospital.groups) {
                for (const group of hospital.groups) {
                    if (group.type == EGroupType.GHT) {
                        this.ght = group.name;
                    }
                }
            }
        });
        // Subscribe to the config changes
        this._fuseConfigService.config.subscribe((config) => {
            this.fuseConfig = config;
        });
    }
    toggleOpen() {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }
    openPanel(sidenavId) {
        this.quickpanelService.open(sidenavId);
    }
    logout() {
        this.Access.logout(() => {
            this._stateService.go('mapui.pages_auth_login-v2');
        }, () => console.log('ERROR'));
    }
    setScheme(scheme) {
        this._fuseConfigService.config = Object.assign({ scheme });
    }
};
ToolbarComponent = __decorate([
    Component({
        selector: 'mpx-toolbar',
        template: require('./toolbar.component.html').default,
        styles: [require('./toolbar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof AjsAccess !== "undefined" && AjsAccess) === "function" ? _c : Object, typeof (_d = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _d : Object, typeof (_e = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _e : Object, typeof (_f = typeof FuseConfigService !== "undefined" && FuseConfigService) === "function" ? _f : Object, typeof (_g = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _g : Object, typeof (_h = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _h : Object, typeof (_j = typeof UrlService !== "undefined" && UrlService) === "function" ? _j : Object, typeof (_k = typeof QuickPanelService !== "undefined" && QuickPanelService) === "function" ? _k : Object, typeof (_l = typeof NotificationService !== "undefined" && NotificationService) === "function" ? _l : Object])
], ToolbarComponent);
export { ToolbarComponent };
