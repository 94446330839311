import * as angular from 'angular';
export class HvExtensivePatientInterviewController {
    /** @ngInject */
    constructor(HospivilleService, Api, ModalService, InformationService, msApi) {
        this.HospivilleService = HospivilleService;
        this.Api = Api;
        this.ModalService = ModalService;
        this.InformationService = InformationService;
        this.msApi = msApi;
        /**
         * Save [[IPatient]]
         */
        this.savePatient = ({ treatment }) => {
            if (treatment)
                this.treatment = angular.copy(treatment);
        };
        /**
         * Open Modal, create [[IPharmaIntervention]]
         */
        this.editIntervention = ($event) => {
            this.ModalService.show({
                component: 'hv-modal-pharma-intervention',
                bindings: {
                    drugs: this.treatment.instances,
                },
                escapeToClose: true,
            }).then((intervention) => {
            });
        };
        this.treatment = { instances: [] };
        this.ready = false;
        msApi.request('treatment.data@get', {}, 
        // SUCCESS
        (response) => {
            this.treatment = response.treatment;
        });
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        if (this.HospivilleService.hasPatient) {
            this.HospivilleService.getPatient().then((patient) => {
                this.patient = patient;
                this.ready = true;
            });
        }
    }
}
