import * as angular from 'angular';
export class HvSelectDosageController {
    /** @ngInject */
    constructor(Api, ModalService, Access) {
        this.Api = Api;
        this.ModalService = ModalService;
        this.Access = Access;
        /**
         * triggered when the dosage type is changed
         */
        this.changeDosageType = () => {
            if (this.type == 14) { // drop
                //   console.log(this.dropInfo);
                this.ModalService.show({
                    component: 'hv-modal-select-drop',
                    bindings: {
                        dropInfo: this.dropInfo,
                    },
                    escapeToClose: true,
                })
                    .then((newDropInfo) => {
                    this.dropInfo = newDropInfo;
                    this.change();
                });
            }
            else {
                this.dropInfo = null;
            }
        };
        /**
         * When dosage or type change
         */
        this.change = () => {
            if (this.onChange) {
                this.onChange({
                    $event: { quantity: this.quantity, type: this.type, dropInfo: this.dropInfo },
                });
            }
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(changes) {
        //  console.log(changes);
        if (changes.dosage) {
            this.quantity = angular.copy(this.quantity);
        }
        if (changes.type) {
            this.type = angular.copy(this.type);
        }
        if (changes.dropInfo) {
            this.dropInfo = angular.copy(this.dropInfo);
        }
    }
}
