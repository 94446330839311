import { EDrugBalanceType, EHospivillePermission, EMpStatus, EStatusIndicator, ETransit } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HospivilleService {
    /** @ngInject */
    constructor(DrugBalanceService, DrugInstanceService, MedicationReconciliationService, MRResultService, PatientService, SourceService, VaultService, ToastService, $translate, $q, $http, Access, $rootScope, _) {
        this.DrugBalanceService = DrugBalanceService;
        this.DrugInstanceService = DrugInstanceService;
        this.MedicationReconciliationService = MedicationReconciliationService;
        this.MRResultService = MRResultService;
        this.PatientService = PatientService;
        this.SourceService = SourceService;
        this.VaultService = VaultService;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.$q = $q;
        this.$http = $http;
        this.Access = Access;
        this.$rootScope = $rootScope;
        this._ = _;
        /**
         *
         * @return {IPromise<IDrugBalance>}
         */
        this.getDrugBalanceSheet = () => {
            const defer = this.$q.defer();
            if (!this._medReconciliation) {
                this.getMedRec().then((medRec) => {
                    if (medRec) {
                        this._medReconciliation = medRec;
                        this.DrugBalanceService.getByMid(EDrugBalanceType.DrugBalance, medRec._id)
                            .then((dbs) => {
                            if (this._medReconciliation) {
                                this._medReconciliation.dbs = dbs;
                            }
                            this.MedicationReconciliationService.save(this._medReconciliation).then(() => {
                                var _a;
                                defer.resolve((_a = this._medReconciliation) === null || _a === void 0 ? void 0 : _a.dbs);
                            }).catch((err) => defer.reject(err));
                        })
                            .catch((err) => defer.reject(err));
                    }
                });
            }
            else {
                this.DrugBalanceService.getByMid(EDrugBalanceType.DrugBalance, this._medReconciliation._id)
                    .then((dbs) => {
                    this._medReconciliation.dbs = dbs;
                    this.MedicationReconciliationService.save(this._medReconciliation);
                    return defer.resolve(this._medReconciliation.dbs);
                })
                    .catch((err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         *
         * @param service
         * @param obj
         * @returns {angular.IPromise<any>}
         */
        this.saveOrAdd = (service, obj) => {
            const defer = this.$q.defer();
            if (Utils.hasId(obj)) {
                service.save(obj);
                defer.resolve(obj);
            }
            else {
                service.add(obj)
                    .then((newObj) => {
                    defer.resolve(newObj);
                });
            }
            return defer.promise;
        };
        /**
         *
         * @return {IPromise<IDrugBalance>}
         */
        this.getDBSForDBSView = () => {
            const defer = this.$q.defer();
            this.getDrugBalanceSheet().then((dbs) => {
                var _a;
                if (!((_a = this._medReconciliation) === null || _a === void 0 ? void 0 : _a.dbs))
                    return defer.resolve();
                defer.resolve(this._adaptDrugBalanceForDBS(this._medReconciliation.dbs));
            }).catch((err) => {
                console.error(err);
                this.ToastService.show(err);
            });
            return defer.promise;
        };
        /**
         *
         * @param drugBalance
         * @param toClean
         * @return {IPromise<IDrugBalance>}
         */
        this.saveDrugBalance = (drugBalance, toClean = false) => {
            const defer = this.$q.defer();
            const performSave = () => {
                let ps = [];
                if (EStatusIndicator.Validate === drugBalance.statusIndicator && !drugBalance.validator) {
                    drugBalance.validator = {
                        _id: Utils.getId(this.Access.user),
                        fullName: this.Access.user.fullName,
                    };
                }
                if (drugBalance) {
                    for (let drInst of drugBalance.instances) {
                        ps.push(this.saveOrAdd(this.DrugInstanceService, drInst));
                    }
                    this.$q.all(ps).then((newInsts) => {
                        drugBalance.instances = [...newInsts];
                        this.DrugBalanceService.save(drugBalance)
                            .then(() => {
                            if (EDrugBalanceType.DrugBalance == drugBalance.type && this._medReconciliation) {
                                this._medReconciliation.dbs = drugBalance;
                            }
                            defer.resolve(drugBalance);
                        });
                    }).catch((err) => defer.reject(err));
                    if (drugBalance.unSelectInstances) {
                        ps = [];
                        for (let drInst of drugBalance.unSelectInstances) {
                            ps.push(this.saveOrAdd(this.DrugInstanceService, drInst));
                        }
                        this.$q.all(ps).then((newInsts) => {
                            drugBalance.unSelectInstances = [...newInsts];
                            this.DrugBalanceService.save(drugBalance)
                                .then(() => {
                                if (EDrugBalanceType.DrugBalance == drugBalance.type && this._medReconciliation) {
                                    this._medReconciliation.dbs = drugBalance;
                                }
                                defer.resolve(drugBalance);
                            });
                        }).catch((err) => defer.reject(err));
                    }
                }
                else {
                    defer.reject(EMpStatus.NoDataProvided);
                }
            };
            if (toClean)
                this._cleanDrugBalance(drugBalance);
            performSave();
            return defer.promise;
        };
        /**
         *
         * @param medRec
         * @param toClean
         * @param simply
         * @returns {IPromise<IMedicationReconciliation>}
         */
        this.saveMedRec = (medRec, toClean = false, simply = false) => {
            const defer = this.$q.defer();
            const performSave = () => {
                const instancePromises = [];
                const resultPromises = [];
                if (EStatusIndicator.Validate === medRec.statusIndicator && !medRec.validator) {
                    medRec.validator = { _id: Utils.getId(this.Access.user), fullName: this.Access.user.fullName };
                }
                if (medRec.prescription) {
                    for (let drInst of medRec.prescription.instances) {
                        instancePromises.push(this.saveOrAdd(this.DrugInstanceService, drInst));
                    }
                }
                for (let result of medRec.results) {
                    resultPromises.push(this.saveOrAdd(this.MRResultService, result));
                }
                this.$q.all(instancePromises).then((instances) => {
                    medRec.prescription.instances = [...instances];
                    this.DrugBalanceService.save(medRec.prescription);
                    this.saveDrugBalance(medRec.dbs, true);
                    this.$q.all(resultPromises).then((results) => {
                        medRec.results = [...results];
                        medRec.transit = medRec.prescription.transit;
                        this.MedicationReconciliationService.save(medRec)
                            .then(() => {
                            this._medReconciliation = medRec;
                            defer.resolve(this._adaptMedRec(angular.copy(medRec)));
                        });
                    }).catch((err) => defer.reject(err));
                });
            };
            if (toClean) {
                this._cleanMedRec(medRec);
                performSave();
            }
            else if (simply) {
                this.MedicationReconciliationService.save(medRec)
                    .then(() => defer.resolve(medRec))
                    .catch((err) => console.error(err));
            }
            else {
                performSave();
            }
            return defer.promise;
        };
        /**
         *
         * @param patient
         * @returns {IPromise<IPatient>}
         */
        this.savePatient = (patient) => {
            const defer = this.$q.defer();
            const promises = [];
            if (patient.sources) {
                for (let source of patient.sources) {
                    promises.push(this.saveOrAdd(this.SourceService, source));
                }
            }
            this.$q.all(promises)
                .then((sources) => {
                patient.sources = [...sources];
                let promise = Utils.hasId(patient) ?
                    this.PatientService.save(patient) : this.PatientService.add(patient);
                promise.then((savedPatient) => {
                    this._patient = savedPatient;
                    if (!Utils.hasId(patient)) {
                        this.Access.changePatient(this._patient._id, null);
                    }
                    return defer.resolve(this._patient);
                }).catch((err) => {
                    console.log(err);
                    return defer.reject(EMpStatus.NoDataProvided);
                });
            })
                .catch((err) => {
                console.error(err);
                return defer.reject(err);
            });
            return defer.promise;
        };
        /**
         * Get the current [[IPatient]]
         * @returns {IPromise<IPatient>}
         */
        this.getPatient = () => {
            const defer = this.$q.defer();
            this.PatientService.get(this.Access.patient._id)
                .then((patient) => {
                this._patient = patient;
                defer.resolve(this._patient);
            })
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Get the current [[IEstablishmentPatient]]
         * @returns {IPromise<IEstablishmentPatient>}
         */
        this.toEstaPat = (patient) => {
            let estaPat = {
                _id: patient._id,
                addrNumber: patient.addrNumber,
                addrStreet: patient.addrStreet,
                addrZIP: patient.addrZIP,
                addrCity: patient.addrCity,
                addrCountry: patient.addrCountry,
                phone: patient.phone,
                masterPatient: { _id: patient.masterPatient },
                patientInfo: { _id: patient.patientInfo },
                oldPatientInfo: { _id: patient.oldPatientInfo },
                establishments: patient.establishments,
                sources: patient.sources,
                files: patient.files,
                lppi: patient.lppi,
                archived: patient.archived,
                backupMedRecs: patient.backupMedRecs,
                medicationReconciliations: [],
                transfers: [],
            };
            for (let mr of patient.medicationReconciliations) {
                estaPat.medicationReconciliations.push({ _id: mr._id });
            }
            for (let tf of patient.transfers) {
                estaPat.transfers.push({
                    date: tf.date,
                    hospital: tf.hospital,
                    member: tf.member,
                    originPatient: this.toEstaPat(tf.originPatient),
                });
            }
            return estaPat;
        };
        /**
         *
         * @param {boolean} toAdapt
         * @param {Id} id
         * @returns {angular.IPromise<IMedicationReconciliation>}
         */
        this.getMedRec = (toAdapt = false, id) => {
            var _a, _b;
            const defer = this.$q.defer();
            let _id = id || ((_b = (_a = this.Access.patient) === null || _a === void 0 ? void 0 : _a.medicationReconciliation) === null || _b === void 0 ? void 0 : _b._id);
            this.MedicationReconciliationService.get(_id)
                .then((medRec) => {
                this._medReconciliation = medRec;
                if (this._medReconciliation.prescription && !this._medReconciliation.preSorted) {
                    this._preSortMedRec(this._medReconciliation);
                }
                if (!this._medReconciliation.output) {
                    this._medReconciliation.output = this._initMedRecOutput();
                }
                defer.resolve(toAdapt ? this._adaptMedRec(angular.copy(this._medReconciliation)) : this._medReconciliation);
            }).catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         *
         * @param id
         * @return {IPromise<IDrugBalance>}
         */
        this.getPrescription = (id) => {
            const defer = this.$q.defer();
            this.DrugBalanceService.get(id)
                .then((prescription) => {
                defer.resolve(prescription);
            })
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         *
         * @return {IPromise<IDrugBalance>}
         */
        this.addPrescription = (prescription) => {
            const defer = this.$q.defer();
            this.DrugBalanceService.add({
                type: EDrugBalanceType.Prescription,
                date: new Date(),
                medicationReconciliation: { _id: this.Access.patient.medicationReconciliation._id },
                transit: prescription ? prescription.transit : null,
            })
                .then((newPrescription) => defer.resolve(newPrescription))
                .catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Get the higher index of all given [[IDrugInstance]]s
         * @param medRec
         * @returns {number}
         */
        this.getMaxIndex = (medRec = null) => {
            let instances = [];
            if (this._medReconciliation) {
                if (!medRec)
                    medRec = this._medReconciliation;
                if (medRec.prescription && medRec.prescription.instances)
                    instances = instances.concat(medRec.prescription.instances);
                if (medRec.dbs && medRec.dbs.instances)
                    instances = instances.concat(medRec.dbs.instances);
            }
            let max = -1;
            if (instances.length > 0) {
                for (const inst of instances) {
                    if (inst.index > max) {
                        max = inst.index;
                    }
                }
            }
            return max;
        };
        /**
         * Delete a drug instance
         * @param inst
         * @returns {IPromise<ISource>}
         */
        this.deleteDrugInst = (inst) => {
            const defer = this.$q.defer();
            this.DrugInstanceService.delete(inst)
                .then(() => defer.resolve())
                .catch(() => defer.reject());
            return defer.promise;
        };
        this.lockVault = () => {
            this.VaultService.lock();
        };
        /**
         *
         * @param {Array<IMedicationReconciliation>} medicationReconciliations
         * @returns {angular.IPromise<Array<IMedicationReconciliation>>}
         */
        this.getAllMedRec = (medicationReconciliations) => {
            const defer = this.$q.defer();
            const promises = [];
            if (!medicationReconciliations) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                for (let medRec of medicationReconciliations) {
                    promises.push(this._getMedRecById(medRec._id));
                }
                this.$q.all(promises)
                    .then((res) => defer.resolve(res)) // resolve(medRecs)
                    .catch((err) => {
                    console.error(err);
                    return defer.reject(EMpStatus.RejectByServer);
                });
            }
            return defer.promise;
        };
        /**
         * Call the FHIR bundle creation module to update [[IMedicationReconciliation]] status
         * @param mid
         * @param status
         * @param action
         */
        this.exportMedRecStatus = (mid, status, action) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !mid || status === undefined) {
                // defer.reject(EMpStatus.NoDataProvided);
                return EMpStatus.NoDataProvided;
            }
            this.$http.get(`/external-interface/sillage/updateStatus/${action}?status=${status}&pid=${Utils.getId(this.Access.patient)}&id=${mid}`)
                .then((answer) => {
                // defer.resolve(EMpStatus.Ok);
            }).catch((err) => {
                console.log(err);
                // defer.reject(EMpStatus.RejectByServer);
            });
            // return defer.promise;
        };
        /**
         * Call the FHIR bundle creation module to create synthesis sheet
         * @param queryParams
         * @param type
         */
        this.exportSynthesisSheet = (queryParams, type) => {
            const defer = this.$q.defer();
            const pdfModel = {
                medRec: 'reconciliation-sheet',
                dbs: 'drug-balance-synthesis',
            };
            if (!Utils.hasId(this.Access.patient) || !queryParams)
                defer.reject(EMpStatus.NoDataProvided);
            else {
                this.$http.get(`/external-interface/sillage/${type}/create?pdfmodel=${pdfModel[type]}&${queryParams}`)
                    .then((answer) => {
                    defer.resolve(EMpStatus.Ok);
                })
                    .catch((err) => {
                    console.log(err);
                    defer.reject(EMpStatus.RejectByServer);
                });
            }
            return defer.promise;
        };
        /**
         * Ask for prescription
         * @param finess
         * @param epi
         * @param mid
         * @param maxIdx
         */
        this.importPrescriptionFromExternalActor = (finess, epi, mid, maxIdx) => {
            const defer = this.$q.defer();
            if (!finess || !epi)
                defer.reject(EMpStatus.NoDataProvided);
            this.$http.get(`/external-interface/sillage/drugInstances/import?finess=${finess}&epi=${epi}&mid=${mid}&maxIdx=${maxIdx}`)
                .then((ans) => {
                defer.resolve(ans.data);
            }).catch((err) => {
                console.log(err);
                defer.reject(err);
            });
            return defer.promise;
        };
        /**
         * Generate MedRec sheet synthesis and return buffer from it.
         * @param patient
         * @param query
         */
        this.generateRecSheetPDF = (patient, query) => {
            const defer = this.$q.defer();
            const req = {
                method: 'GET',
                url: '/pdf-generation/fiche-de-conciliation_'
                    + patient.firstName.charAt(0)
                    + patient.familyName.charAt(0)
                    + '.pdf?pdfmodel=reconciliation-sheet&'
                    + query,
                headers: {
                    'Content-Type': 'application/pdf',
                },
            };
            this.$http(req).then((data) => {
                defer.resolve(data.data);
            }).catch((err) => defer.reject(err));
            return defer.promise;
        };
        /**
         * Init data
         * @private
         */
        this._init = () => {
            this._medReconciliation = null;
            this._patient = null;
            this._patientLoaded = false;
        };
        /**
         * Init Medication Reconciliation Output
         * @returns {{description: string, date: Date}}
         * @private
         */
        this._initMedRecOutput = () => {
            const department = (this._medReconciliation.hospitalService && this._medReconciliation.hospitalService.name)
                ? this._medReconciliation.hospitalService.name : '[...]';
            let transitString = '';
            if (ETransit.Entrance === this._medReconciliation.transit) {
                transitString = this.$translate.instant('HOSPIVILLE.CONST_TYPE.TRANSIT.ENTRANCE');
            }
            else if (ETransit.Transfer === this._medReconciliation.transit) {
                transitString = this.$translate.instant('HOSPIVILLE.CONST_TYPE.TRANSIT.TRANSFER');
            }
            else {
                transitString = this.$translate.instant('HOSPIVILLE.CONST_TYPE.TRANSIT.EXIT');
            }
            return {
                description: this.$translate.instant('HOSPIVILLE.GENERAL.OUTPUT.TEXT_DOCTOR') +
                    this.$translate.instant('HOSPIVILLE.GENERAL.OUTPUT.TEXT1')
                    + `'${department}'`
                    + this.$translate.instant('HOSPIVILLE.GENERAL.OUTPUT.TEXT2')
                    + transitString
                    + this.$translate.instant('HOSPIVILLE.GENERAL.OUTPUT.TEXT3'),
                date: new Date(),
            };
        };
        /**
         *
         * @param drugBalance
         * @return {IPromise<null>}
         * @private
         */
        this._cleanDrugBalance = (drugBalance) => {
            const tmpArr = [];
            if (drugBalance && drugBalance.instances) {
                for (const drugInstance of drugBalance.instances) {
                    if (drugInstance && drugInstance.denomination) {
                        tmpArr.push(drugInstance);
                    }
                }
                drugBalance.instances = [...tmpArr];
            }
        };
        /**
         *
         * @param medRec
         * @return {IPromise<null>}
         * @private
         */
        this._cleanMedRec = (medRec) => {
            let results = [];
            let instances;
            this._cleanDrugBalance(medRec.prescription);
            for (let result of medRec.results) {
                instances = [];
                if (!this._isMRResultUsed(result, medRec)) {
                    if (Utils.hasId(result)) {
                        this.MRResultService.delete(result);
                    }
                }
                else {
                    for (let dbsInst of result.drugBalances) {
                        if (dbsInst.denomination) {
                            instances.push(dbsInst);
                        }
                    }
                    result.drugBalances = [...instances];
                    instances = [];
                    for (let prescriptionInst of result.prescriptions) {
                        if (prescriptionInst.denomination) {
                            instances.push(prescriptionInst);
                        }
                    }
                    result.prescriptions = [...instances];
                    if (!!result.drugBalances.length || !!result.prescriptions.length) {
                        results.push(angular.copy(result));
                    }
                    else {
                        if (Utils.hasId(result)) {
                            this.MRResultService.delete(result);
                        }
                    }
                }
            }
            medRec.results = [...results];
        };
        this._isMRResultUsed = (result, medRec) => {
            if (this._.find(medRec.prescription.instances, (inst) => {
                return inst.index === result.index && this._.find(result.prescriptions, (prescription) => {
                    return prescription._id === inst._id && prescription.denomination === inst.denomination && prescription.index === inst.index;
                });
            })) {
                return true;
            }
            if (this._.find(medRec.dbs.instances, (inst) => {
                return inst.index === result.index && this._.find(result.drugBalances, (dbs) => {
                    return dbs._id === inst._id && dbs.denomination === inst.denomination && dbs.index === inst.index;
                });
            })) {
                return true;
            }
            return false;
        };
        /**
         *
         * @param drugBalance
         * @return {IDrugBalance}
         * @private
         */
        this._adaptDrugBalanceForDBS = (drugBalance) => {
            if (drugBalance && (drugBalance === null || drugBalance === void 0 ? void 0 : drugBalance.instances)) {
                for (const instance of drugBalance === null || drugBalance === void 0 ? void 0 : drugBalance.instances) {
                    let instSources = [];
                    for (const source of drugBalance.sources) {
                        let exist = this._.find(instance.sources, (src) => {
                            return src.source && source.source && src.source._id === source.source._id;
                        });
                        if (exist) {
                            instSources.push(exist);
                            continue;
                        }
                        instSources.push({
                            posologies: [],
                            dosage: null,
                            dosageType: null,
                            dosageDropInfo: null,
                            source: { _id: source.source ? source.source._id : null },
                        });
                    }
                    instance.sources = instSources;
                }
            }
            return drugBalance;
        };
        /**
         *
         * @return {IPromise<null>}
         * @private
         */
        this._adaptMedRec = (medRec) => {
            if (medRec.dbs && medRec.prescription) {
                const max = this.getMaxIndex(medRec);
                for (let i = 0; i <= max; ++i) {
                    if (!medRec.results) {
                        medRec.results = [];
                    }
                    if (!this._.find(medRec.prescription.instances, { index: i })) {
                        medRec.prescription.instances.push(Utils.assign(this.DrugInstanceService.emptyInstance, { index: i }));
                    }
                    if (!this._.find(medRec.results, { index: Number(i) })) {
                        medRec.results.push(Utils.assign(this.MRResultService.emptyResult, {
                            index: i,
                            medicationReconciliation: { _id: medRec._id },
                        }));
                    }
                    if (!this._.find(medRec.dbs.instances, { index: i })) {
                        medRec.dbs.instances.push(Utils.assign(this.DrugInstanceService.emptyInstance, { index: i }));
                    }
                }
                medRec.prescription.instances = this._.sortBy(medRec.prescription.instances, 'index');
                medRec.dbs.instances = this._.sortBy(medRec.dbs.instances, 'index');
                medRec.results = this._.sortBy(medRec.results, 'index');
            }
            return medRec;
        };
        /**
         * Apply a sort by on medRec list
         * @param medRec
         * @private
         */
        this._preSortMedRec = (medRec) => {
            if (medRec.prescription && medRec.dbs) {
                for (let instance of medRec.prescription.instances) {
                    let same = this._.find(medRec.dbs.instances, { UCD13: instance.UCD13 });
                    let equivalence = this._.find(medRec.dbs.instances, (inst) => {
                        return inst.codeATC && instance.codeATC ? inst.codeATC.substring(0, 5) === instance.codeATC.substring(0, 5) : false;
                    });
                    if (same) {
                        instance.index = same.index;
                    }
                    else if (equivalence) {
                        instance.index = equivalence.index;
                    }
                    else {
                        instance.index = null;
                    }
                }
                let maxIndex = this.getMaxIndex(medRec);
                for (let instance of medRec.prescription.instances) {
                    if (!instance.index && instance.index !== 0) {
                        instance.index = ++maxIndex;
                    }
                }
                medRec.preSorted = true;
            }
        };
        this._init();
        this.$rootScope.$on('refreshPatientFile', (event) => {
            this._init();
        });
        this.$rootScope.$on('refreshPatient', (event) => {
            this._init();
        });
    }
    get hasPatient() {
        return this.Access.patient && this.Access.patient._id;
    }
    set patient(value) {
        this._patient = value;
    }
    _getMedRecById(mid) {
        const defer = this.$q.defer();
        this.MedicationReconciliationService.get(mid)
            .then((res) => defer.resolve(res))
            .catch((err) => {
            console.error(err);
            return defer.reject(err);
        });
        return defer.promise;
    }
    uploadToActivity(files, patient, result) {
        const defer = this.$q.defer();
        this.$http.post('/files/uploadToActivity', {
            files: files,
            patient: patient,
            medRec: result.medicationReconciliation,
            mail: result.mail,
            hospital: result.hospital
        }).then((data) => {
            defer.resolve(data.data);
        }).catch((err) => defer.reject(err));
        return defer.promise;
    }
}
