var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { EAdminPanelPermission, EHTTPStatus, Hospital } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '../../../shared/templates/crud/crud.template';
let AdminARSService = class AdminARSService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, Hospital, '/api/admin/ars', {
            create: EAdminPanelPermission.Establishments_Create,
            read: EAdminPanelPermission.Establishments_Read,
            update: EAdminPanelPermission.Establishments_Edit,
            delete: EAdminPanelPermission.Establishments_Delete,
        });
        this.__injector = __injector;
        /**
         * Get members of the given ARS
         * @param aid ARS ID
         * @param pageOptions
         * @param sort
         */
        this.getMembers = (aid, pageOptions, sort) => {
            if (!this._permissionService.authorize(this._permissions.read))
                return throwError(() => EHTTPStatus.Unauthorized);
            if (!aid)
                return throwError(() => EHTTPStatus.BadRequest);
            let params = new HttpParams();
            if (sort)
                params = params.append('sorting', JSON.stringify(sort));
            if (!pageOptions)
                pageOptions = { page: 1, limit: 5 };
            params = params.append('pageOptions', JSON.stringify(pageOptions));
            const fullEndPoint = `${this._endPoint}/${aid}/members`;
            return this._http.get(fullEndPoint, { params: params });
        };
    }
};
AdminARSService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], AdminARSService);
export { AdminARSService };
