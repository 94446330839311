export const locale = {
    lang: 'en',
    data: {
        map: {
            MARKERS: {
                HOSPITAL: 'Hospital:',
                FINESS_CODE: 'FINESS Code:',
                FINESS_CODE_JUR: 'Jur. FINESS Code:',
                BUTTONS: {
                    SEE_MORE: 'More'
                }
            },
            CONFIG_PANEL: {
                TITLE: `Legend`,
                FILTERS: {
                    LOANS: {
                        LABELS: {
                            AVAILABLE: `Available`,
                            REQUEST: `Requests`,
                            OTHERS: `Others`
                        }
                    },
                    GROUPS: {
                        TITLE: `Groups`,
                        PLACEHOLDER: `Select a group`
                    },
                    MAD_SEARCH: {
                        TITLE: `Availables`,
                        PLACEHOLDER: `Search an available.`
                    }
                }
            }
        }
    }
};
