import * as angular from 'angular';
import { MpMainComponent } from './main/main.component';
import { MpMainController } from './main/main.controller';
import { QuickPanelService } from './quick-panel/quick-panel.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { ToolbarComponent } from '../../app/layout/toolbar/toolbar.component';
import { NavbarComponent } from '../../app/layout/navbar/navbar.component';
import { NotificationPanelComponent } from '../../app/layout/notification-panel/notification-panel.component';
import { Access } from '@main/services/auth/access.service';
angular
    .module('mapui.layout.vertical-navigation', [])
    .service('Access', Access)
    .component('mpMain', MpMainComponent)
    .controller('MpMainController', MpMainController)
    .directive('mpNavigation', downgradeComponent({ component: NavbarComponent }))
    .directive('mpQuickPanel', downgradeComponent({ component: NotificationPanelComponent }))
    .directive('mpToolbar', downgradeComponent({ component: ToolbarComponent }))
    .service('QuickPanelService', QuickPanelService);
