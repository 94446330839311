export class HvPatientPathwaysComponent {
    constructor() {
        this.controller = 'HvPatientPathwaysController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-pathways.html').default;
        this.bindings = {
            showAddButton: '<',
            patient: '<',
            onSavePathway: '&'
        };
    }
    ;
}
