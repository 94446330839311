import { EDrugPreservation } from '@mapuilabs/mpl-interfaces';
export const CDrugPreservation = {
    /*freshBetween8Deg15Deg: {
        value: EDrugPreservation.FreshBetween8Deg15Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.FRESH_BETWEEN_8_DEG_AND_15_DEG'
    },
    safeFromHumidity: {
        value: EDrugPreservation.SafeFromHumidity,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_HUMIDITY'
    },*/
    safeFromLight: {
        value: EDrugPreservation.SafeFromLight,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_LIGHT'
    },
    /*insideFreezer: {
        value: EDrugPreservation.InsideFreezer,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.INSIDE_FREEZER'
    },*/
    insideFridge: {
        value: EDrugPreservation.InsideFridge,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.INSIDE_FRIDGE'
    },
    roomTemperature: {
        value: EDrugPreservation.RoomTemperature,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.ROOM_TEMPERATURE'
    },
    /*safeFromHeat: {
        value: EDrugPreservation.SafeFromHeat,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_HEAT'
    },
    notFrozen: {
        value: EDrugPreservation.NotFrozen,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_FROZEN'
    },
    notStoredInFridge: {
        value: EDrugPreservation.NotStoredInFridge,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STORED_IN_FRIDGE'
    },
    inOriginWrap: {
        value: EDrugPreservation.InOriginWrap,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_ORIGIN_WRAP'
    },
    safeFromFrost: {
        value: EDrugPreservation.SafeFromFrost,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_FROST'
    },
    carefullyClosed: {
        value: EDrugPreservation.CarefullyClosed,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.CAREFULLY_CLOSED'
    },
    safeFromFlame: {
        value: EDrugPreservation.SafeFromFlame,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_FLAME'
    },
    verticalPosition: {
        value: EDrugPreservation.VerticalPosition,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.VERTICAL_POSITION'
    },
    notExposedToSunRays: {
        value: EDrugPreservation.NotExposedToSunRays,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_EXPOSED_TO_SUN_RAYS'
    },
    safeFromXRays: {
        value: EDrugPreservation.SafeFromXRays,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_X_RAYS'
    },
    at37Deg: {
        value: EDrugPreservation.At37Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.AT_37_DEG'
    },
    notDrilled: {
        value: EDrugPreservation.NotDrilled,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_DRILLED'
    },
    notThrownToFireEvenEmpty: {
        value: EDrugPreservation.NotThrownToFireEvenEmpty,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_THROWN_TO_FIRE_EVEN_EMPTY'
    },
    notExposedToExcessiveHeat: {
        value: EDrugPreservation.NotExposedToExcessiveHeat,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_EXPOSED_TO_EXCESSIVE_HEAT'
    },
    safeFromIonizingRays: {
        value: EDrugPreservation.SafeFromIonizingRays,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_IONIZING_RAYS'
    },
    inClimaticArea1: {
        value: EDrugPreservation.InClimaticArea1,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_CLIMATIC_AREA_1'
    },
    safeFromFlammableMaterial: {
        value: EDrugPreservation.SafeFromFlammableMaterial,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_FLAMMABLE_MATERIAL'
    },
    inClimateArea2: {
        value: EDrugPreservation.InClimaticArea2,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_CLIMATIC_AREA_2'
    },
    inClimateArea3: {
        value: EDrugPreservation.InClimaticArea3,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_CLIMATIC_AREA_3'
    },
    inClimateArea4: {
        value: EDrugPreservation.InClimaticArea4,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_CLIMATIC_AREA_4'
    },*/
    noSpecificMeasure: {
        value: EDrugPreservation.NoSpecificMeasure,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NO_SPECIFIC_MEASURE'
    }
    /*notOver30Deg: {
        value: EDrugPreservation.NotOver30Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_OVER_30_DEG'
    },
    notOver25Deg: {
        value: EDrugPreservation.NotOver25Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_OVER_25_DEG'
    },
    notShaken: {
        value: EDrugPreservation.NotShaken,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_SHAKEN'
    },
    respectColdChain: {
        value: EDrugPreservation.RespectColdChain,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.RESPECT_COLD_CHAIN'
    },
    strictlyHigher15Deg: {
        value: EDrugPreservation.StrictlyHigher15Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STRICTLY_HIGHER_15_DEG'
    },
    keepAwayFromFrozenItem: {
        value: EDrugPreservation.KeepAwayFromFrozenItem,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.KEEP_AWAY_FROM_FROZEN_ITEM'
    },
    notExposedHigher50Deg: {
        value: EDrugPreservation.NotExposedHigher50Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_EXPOSED_HIGHER_50_DEG'
    },
    notPreservedUnderMin10DegCel: {
        value: EDrugPreservation.NotPreservedUnderMin10DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_PRESERVED_UNDER_MIN_10_DEG_CEL'
    },
    safeFromBumpAndDrop: {
        value: EDrugPreservation.SafeFromBumpAndDrop,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_BUMP_AND_DROP'
    },
    strictlyHigher4Deg: {
        value: EDrugPreservation.StrictlyHigher4Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STRICTLY_HIGHER_4_DEG'
    },
    noInfo: {
        value: EDrugPreservation.NoInfo,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NO_INFO'
    },
    strictlyHigher5Deg: {
        value: EDrugPreservation.StrictlyHigher5Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STRICTLY_HIGHER_5_DEG'
    },
    inOriginalPrimaryPackaging: {
        value: EDrugPreservation.InOriginalPrimaryPackaging,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_ORIGINAL_PRIMARY_PACKAGING'
    },
    storedLowerOrEqualThanMin18Deg: {
        value: EDrugPreservation.StoredLowerOrEqualThanMin18Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STORED_LOWER_OR_EQUAL_THAN_MIN_18_DEG'
    },
    notOverMin20Deg: {
        value: EDrugPreservation.NotOverMin20Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_OVER_MIN_20_DEG'
    },
    notStoredUnder2DegCel: {
        value: EDrugPreservation.NotStoredUnder2DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STORED_UNDER_2_DEG_CEL'
    },
    checkLookIfExposedToHeat: {
        value: EDrugPreservation.CheckLookIfExposedToHeat,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.CHECK_LOOK_IF_EXPOSED_TO_HEAT'
    },
    notStoredUnderMin20DegCel: {
        value: EDrugPreservation.NotStoredUnderMin20DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STORED_UNDER_MIN_20_DEG_CEL'
    },
    notStoredUnderMin10DegCel: {
        value: EDrugPreservation.NotStoredUnderMin10DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STORED_UNDER_MIN_10_DEG_CEL'
    },
    notHigher22Deg: {
        value: EDrugPreservation.NotHigher22Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_HIGHER_22_DEG'
    },
    notStoredUnder18DegCel: {
        value: EDrugPreservation.NotStoredUnder18DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STORED_UNDER_18_DEG_CEL'
    },
    notHigher35Deg: {
        value: EDrugPreservation.NotHigher35Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_HIGHER_35_DEG'
    },
    safeFromCold: {
        value: EDrugPreservation.SafeFromCold,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_COLD'
    },
    safeFromDryingUp: {
        value: EDrugPreservation.SafeFromDryingUp,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.SAFE_FROM_DRYING_UP'
    },
    strictlyHigher10Deg: {
        value: EDrugPreservation.StrictlyHigher10Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STRICTLY_HIGHER_10_DEG'
    },
    flatPosition: {
        value: EDrugPreservation.FlatPosition,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.FLAT_POSITION'
    },
    notHeated: {
        value: EDrugPreservation.NotHeated,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_HEATED'
    },
    between2and8DegAfterOpenAndBeforeUse: {
        value: EDrugPreservation.Between2and8DegAfterOpenAndBeforeUse,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.BETWEEN_2_AND_8_DEG_AFTER_OPEN_AND_BEFORE_USE'
    },
    storedUnderMin20DegCel: {
        value: EDrugPreservation.StoredUnderMin20DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STORED_UNDER_MIN_20_DEG_CEL'
    },
    strictlyHigher8Deg: {
        value: EDrugPreservation.StrictlyHigher8Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STRICTLY_HIGHER_8_DEG'
    },
    regularCheckOfLook: {
        value: EDrugPreservation.RegularCheckOfLook,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.REGULAR_CHECK_OF_LOOK'
    },
    inCryoContainerUnderMin120Deg: {
        value: EDrugPreservation.InCryoContainerUnderMin120Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.IN_CRYO_CONTAINER_UNDER_MIN_120_DEG'
    },
    storedInLiquidNitrogenVapState: {
        value: EDrugPreservation.StoredInLiquidNitrogenVapState,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STORED_IN_LIQUID_NITROGEN_VAP_STATE'
    },
    notSterilized: {
        value: EDrugPreservation.NotSterilized,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STERILIZED'
    },
    noRadiationExpo: {
        value: EDrugPreservation.NoRadiationExpo,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NO_RADIATION_EXPO'
    },
    notOver40Deg: {
        value: EDrugPreservation.NotOver40Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_OVER_40_DEG'
    },
    notStoredUnderMin18DegCel: {
        value: EDrugPreservation.NotStoredUnderMin18DegCel,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.NOT_STORED_UNDER_MIN_18_DEG_CEL'
    },
    strictlyTo4Deg: {
        value: EDrugPreservation.StrictlyTo4Deg,
        translate: 'EXCHANGES.AVAILABLE.DRUG.PRESERVATION.STRICTLY_TO_4_DEG'
    }*/
};
