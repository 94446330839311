import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class MpModalRequestController {
    /** @ngInject */
    constructor(ModalService, MemberService, Access, _, Api) {
        var _a;
        this.ModalService = ModalService;
        this.MemberService = MemberService;
        this.Access = Access;
        this._ = _;
        this.Api = Api;
        /**
         * Set [[ILoan]] staff member when member is selected
         * @param model
         */
        this.selectStaffMember = ({ model }) => {
            this.request.loanPerson = { _id: model };
            this.request.lender = { _id: this.Access.hospital._id };
        };
        /**
         * Submit the form
         * Perform some check before hands using the controller knowledge
         */
        this.submit = () => {
            this.ModalService.close(this.request);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        if ((_a = this.request) === null || _a === void 0 ? void 0 : _a.expiryDate) {
            this.request.expiryDate = new Date(this.request.expiryDate.toString());
        }
    }
    $onInit() {
        this.MemberService.getByHosp(this.Access.hospital._id)
            .then((allStaff) => this.staff = allStaff);
        this.Api.constType.get((res) => {
            this.returnType = res.isreturn;
            this.type = this._.find(this.returnType, { value: this.request.returnExpected }).translate;
        });
        if (this.request.borrowingPerson) {
            this.MemberService.get(this.request.borrowingPerson._id)
                .then((staff) => this.request.borrowingPerson = staff);
        }
    }
    $onChanges(changes) {
        if (changes.request) {
            this.request = angular.copy(this.request);
        }
    }
}
