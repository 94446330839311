import * as angular from 'angular';
import 'c3/c3';
import './main/services/auth/auth.module';
import './core/core.module';
import './main/services/services.module';
import './main/common/common.module';
import 'ng-idle';
import 'angular-translate';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import './deps/ng-infinite-scroll';
import { Api } from './index.api.ajs';
import { IndexConfig } from './index.config.ajs';
import { mapuiConstants } from './index.constants.ajs';
import { IndexController } from './index.controller.ajs';
import { runIndex } from './index.run.ajs';
import { MainController } from './main/main.controller';
import { MpFormatSizeFilter } from './main/filters/mp-format-size/mp-format-size';
import { SameAs } from './main/directives/password-validator/same-as.directive';
import { PasswordValidatorDirective } from './main/directives/password-validator/password-validator.directive';
import { ExcludeList } from './main/directives/password-validator/exclude-list.directive';
import { DifferentTo } from './main/directives/password-validator/different-to.directive';
import { mpVerticalStepperStepDirective } from './main/directives/mp-vertical-stepper/mp-vertical-stepper-step.directive';
import { mpVerticalStepperDirective } from './main/directives/mp-vertical-stepper/mp-vertical-stepper.directive';
import { MpStepperController } from './main/directives/mp-vertical-stepper/mp-vertical-stepper.controller';
import { mpTabs } from './main/directives/mp-tabs/mp-tabs.directive';
import { MpTabsController } from './main/directives/mp-tabs/mp-tabs.controller';
import { mpTab } from './main/directives/mp-tabs/mp-tab.directive';
import { MpVirtualPageSelectDirective } from './main/directives/mp-table/mp-virtual-page-select.directive';
import { MpVirtualPageSelectController } from './main/directives/mp-table/mp-virtual-page-select.controller';
import { MpTableSearchDirective } from './main/directives/mp-table/mp-table-search.directive';
import { MpTableProgressDirective } from './main/directives/mp-table/mp-table-progress.directive';
import { MpTableProgressController } from './main/directives/mp-table/mp-table-progress.controller';
import { MpTablePaginationDirective } from './main/directives/mp-table/mp-table-pagination.directive';
import { MpTablePaginationController } from './main/directives/mp-table/mp-table-pagination.controller';
import { MpTableDirective } from './main/directives/mp-table/mp-table.directive';
import { MpTableController } from './main/directives/mp-table/mp-table.controller';
import { MpScroll, MpScrollConfigProvider } from './main/directives/mp-scroll/mp-scroll.directive';
import { MpNoDrop } from './main/directives/mp-no-drop/mp-no-drop';
import { MpMultiple } from './main/directives/mp-multiple/mp-multiple.directive';
import { MpModalImageDirective } from './main/directives/mp-modal-image/mp-modal-image.directive';
import { MpModalImageController } from './main/directives/mp-modal-image/mp-modal-image.controller';
import { MpKeyEvents } from './main/directives/mp-key-events/mp-key-events';
import { MpDragOverDirective } from './main/directives/mp-drag-over/mp-drag-over.directive';
import { MpAutofocus } from './main/directives/mp-autofocus/mp-autofocus.directive';
import { HvNirValidationDirective } from './main/directives/hv-nir-validator/hv-nir-validation.directive';
import './main/general/general.module';
import './main/exchanges/exchanges.module';
import './main/workspace/workspace.module';
import './layout/layout.module';
import 'angular-smart-table/dist/smart-table';
import 'ng-idle/index';
import './main/hospiville/hospiville.module';
import { RouteConfig } from './index.route.ajs';
import '@uirouter/angular-hybrid';
export const angularjsIndexModule = angular
    .module('mapui', [
    'app.core',
    'mapui.common',
    'mapui.general',
    'mapui.services',
    'mapui.exchanges',
    'mapui.workspace',
    'mapui.layout',
    'smart-table',
    'ngIdle',
    'permConst',
    'hospiville',
    'ngCookies'
])
    .service('Api', Api)
    .config(IndexConfig)
    .config(($mdAriaProvider) => $mdAriaProvider.disableWarnings()) // Globally disables all ARIA warnings.
    .constant('Permission', mapuiConstants)
    .controller('IndexController', IndexController)
    .controller('MainController', MainController)
    .config(['$urlServiceProvider', ($urlService) => $urlService.deferIntercept()])
    .config(RouteConfig)
    .run(runIndex)
    .filter('mpTrust', [
    '$sce',
    function ($sce) {
        return function (value) {
            return $sce.trustAsResourceUrl(value);
        };
    }
])
    .filter('mpFormatSize', MpFormatSizeFilter.filter)
    .directive('sameAs', SameAs.factory())
    .directive('passwordValidator', PasswordValidatorDirective.factory())
    .directive('excludeList', ExcludeList.factory())
    .directive('differentTo', DifferentTo.factory())
    .directive('mpVerticalStepperStep', mpVerticalStepperStepDirective.factory())
    .directive('mpVerticalStepper', mpVerticalStepperDirective.factory())
    .controller('MpStepperController', MpStepperController)
    .directive('mpTabs', mpTabs.factory())
    .controller('MpTabsController', MpTabsController)
    .directive('mpTab', mpTab.factory())
    .directive('mpVirtualPageSelect', MpVirtualPageSelectDirective.factory())
    .controller('MpVirtualPageSelectController', MpVirtualPageSelectController)
    .directive('mpTableSearch', MpTableSearchDirective.factory())
    .directive('mpTableProgress', MpTableProgressDirective.factory())
    .controller('MpTableProgressController', MpTableProgressController)
    .directive('mpTablePagination', MpTablePaginationDirective.factory())
    .controller('MpTablePaginationController', MpTablePaginationController)
    .directive('mpTable', MpTableDirective.factory())
    .controller('MpTableController', MpTableController)
    .provider('mpScrollConfig', new MpScrollConfigProvider())
    .directive('mpScroll', MpScroll.factory())
    .directive('mpNoDrop', MpNoDrop.factory())
    .directive('mpMultiple', MpMultiple.factory())
    .directive('mpModalImage', MpModalImageDirective.factory())
    .controller('MpModalImageController', MpModalImageController)
    .directive('mpKeyEvents', MpKeyEvents.factory())
    .directive('mpDragOver', MpDragOverDirective.factory())
    .directive('mpAutofocus', MpAutofocus.factory())
    .directive('hvNirValidation', HvNirValidationDirective.factory());
