var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { RolesService } from '@services/role/roles.service';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
import { DialogService } from '@services/dialog/dialog.service';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
let ActionsTabComponent = class ActionsTabComponent {
    constructor(_rolesService, _admMemberService, _dialogService, _snackBarService, _stateService, _translationService, _admHospitalService) {
        this._rolesService = _rolesService;
        this._admMemberService = _admMemberService;
        this._dialogService = _dialogService;
        this._snackBarService = _snackBarService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this._admHospitalService = _admHospitalService;
        this._rolesService.getAllHospital().subscribe((data) => {
            for (const project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                }
            }
            this._setFormControls(this.pharmacy);
        });
    }
    ngOnChanges(changes) {
        if (changes.pharmacy) {
            this._setFormControls(this.pharmacy);
        }
    }
    _setFormControls(pharmacy) {
        const mapuiRolesDefault = [];
        const hospivilleRolesDefault = [];
        const workspaceRolesDefault = [];
        if (pharmacy) {
            for (const role of pharmacy.roles) {
                if (this.mapuiRoles &&
                    this.mapuiRoles.length &&
                    this.mapuiRoles.filter((elem) => elem._id == role._id).length) {
                    mapuiRolesDefault.push(this.mapuiRoles.find((elem) => elem._id == role._id));
                }
                else if (this.hospivilleRoles &&
                    this.hospivilleRoles.length &&
                    this.hospivilleRoles.filter((elem) => elem._id == role._id).length) {
                    hospivilleRolesDefault.push(this.hospivilleRoles.find((elem) => elem._id == role._id));
                }
                if (this.workspaceRoles &&
                    this.workspaceRoles.length &&
                    this.workspaceRoles.filter((elem) => elem._id == role._id).length) {
                    workspaceRolesDefault.push(this.workspaceRoles.find((elem) => elem._id == role._id));
                }
            }
        }
        this.form.setControl('roleMapui', new UntypedFormControl(mapuiRolesDefault));
        this.form.setControl('roleHospiville', new UntypedFormControl(hospivilleRolesDefault));
        this.form.setControl('roleWorkspace', new UntypedFormControl(workspaceRolesDefault));
        this.form.setControl('active', new UntypedFormControl(pharmacy ? pharmacy.active : null));
        this.form.setControl('register', new UntypedFormControl(pharmacy ? pharmacy.register : null));
        this.form.setControl('memberSince', new UntypedFormControl(pharmacy && pharmacy.memberSince ? new Date(pharmacy.memberSince) : null));
        this.form.setControl('memberTo', new UntypedFormControl(pharmacy && pharmacy.memberTo ? new Date(pharmacy.memberTo) : null));
        this.form.setControl('trialEnd', new UntypedFormControl(pharmacy && pharmacy.trialEnd ? new Date(pharmacy.trialEnd) : null));
        this.form.setControl('maxDataUsage', new UntypedFormControl(pharmacy ? Math.floor(pharmacy.maxDataUsage / Math.pow(1024, 3)) : null, [
            Validators.min(1),
            Validators.required
        ]));
    }
    // Add the given number of days to the 'trialEnd' FormControl;
    // Number can be negative
    addDaysMemberTo(nbDays) {
        const newDate = new Date(this.form.value.trialEnd);
        newDate.setDate(newDate.getDate() + nbDays);
        this.form.controls.trialEnd.setValue(newDate);
        this.form.controls.trialEnd.markAsDirty();
    }
    //Public get the maxUsage from the form control in bytes
    getMaxUsage() {
        return this.form.value.maxDataUsage * Math.pow(1024, 3);
    }
    logInHospital() {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('admHospitalDetail.ACTIONS.LOG_AS.CONFIRM_DIALOG.TITLE'),
            message: this._translationService.instant('admHospitalDetail.ACTIONS.LOG_AS.CONFIRM_DIALOG.MESSAGE', {
                establishment: this.pharmacy.name
            })
        })
            .pipe(take(1))
            .subscribe((ans) => {
            if (ans) {
                this._admMemberService
                    .changeCurrentMemberHospital(this.pharmacy)
                    .pipe(take(1))
                    .subscribe((data) => {
                    if (data !== null) {
                        window.location.reload();
                    }
                    else {
                        this._snackBarService.open(this._translationService.instant('admHospitalDetail.ACTIONS.ERROR'));
                    }
                }, this._snackBarService.openError);
            }
        });
    }
    // Delete the current pharmacy after user confirmation
    deleteHospital() {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('admHospitalDetail.ACTIONS.DELETE.CONFIRM_DIALOG.TITLE'),
            message: this._translationService.instant('admHospitalDetail.ACTIONS.DELETE.CONFIRM_DIALOG.MESSAGE', {
                establishment: this.pharmacy.name
            })
        })
            .pipe(take(1))
            .subscribe((data) => {
            if (data) {
                this._admHospitalService
                    .delete(this.pharmacy)
                    .pipe(take(1))
                    .subscribe(() => {
                    this._snackBarService.open(this._translationService.instant('admHospitalDetail.ACTIONS.SNACK_DELETE'));
                    this._stateService.go('mapui.adm.pharmacies.list');
                }, this._snackBarService.openError);
            }
        });
    }
    //Used by the parent component to construct the updated hospital instance.
    updateHospital(pharmacy) {
        return new Observable((observer) => {
            pharmacy.roles = this.form.value.roleMapui.concat(this.form.value.roleHospiville, this.form.value.roleWorkspace);
            pharmacy.active = this.form.value.active;
            pharmacy.register = this.form.value.register;
            pharmacy.memberSince = this.form.value.memberSince;
            pharmacy.memberTo = this.form.value.memberTo;
            pharmacy.trialEnd = this.form.value.trialEnd;
            pharmacy.maxDataUsage = this.form.value.maxDataUsage * Math.pow(1024, 3);
            observer.next();
            observer.complete();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _h : Object)
], ActionsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_j = typeof Hospital !== "undefined" && Hospital) === "function" ? _j : Object)
], ActionsTabComponent.prototype, "pharmacy", void 0);
ActionsTabComponent = __decorate([
    Component({
        selector: 'mpx-actions-tab',
        template: require('./actions-tab.component.html').default,
        styles: [require('./actions-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof RolesService !== "undefined" && RolesService) === "function" ? _a : Object, typeof (_b = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object, typeof (_g = typeof AdminHospitalService !== "undefined" && AdminHospitalService) === "function" ? _g : Object])
], ActionsTabComponent);
export { ActionsTabComponent };
