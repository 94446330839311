export const locale = {
    lang: 'en',
    data: {
        ARCHIVED_PATIENTS: {
            TITLE: 'Archived patients',
            SEARCH: 'Search an archived patient',
            TABLE: {
                FULLNAME: 'Name',
                EMPTY: 'No archived patient'
            }
        }
    }
};
