var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
let MedicalDeviceService = class MedicalDeviceService {
    constructor(_http) {
        this._http = _http;
        this._endPoint = 'api/external/exhausmed/medical-device';
    }
    findMedicalDevice(params) {
        let httpParams = new HttpParams();
        for (const [key, value] of Object.entries(params)) {
            if (value) {
                httpParams = httpParams.set(key, value);
            }
        }
        return this._http.get(this._endPoint, { params: httpParams });
    }
    findMedicalDeviceLink(exhauscode) {
        const endPoint = `${this._endPoint}/link`;
        const httpParams = new HttpParams().set('exhauscode', exhauscode);
        return this._http.get(endPoint, { params: httpParams });
    }
};
MedicalDeviceService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object])
], MedicalDeviceService);
export { MedicalDeviceService };
