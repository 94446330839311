var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { Component, Input, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { forkJoin } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { EEstablishmentType, Hospital } from '@mapuilabs/mpl-interfaces';
import { UrlService } from '../../../services/url/url.service';
import { AdminPharmacyService } from '../../../services/admin/pharmacy/admin-pharmacy.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { InformationTabComponent } from './information-tab/information-tab.component';
import { ActionsTabComponent } from './actions-tab/actions-tab.component';
import { OtherTabComponent } from './other-tab/other-tab.component';
let PharmaciesDetailComponent = class PharmaciesDetailComponent {
    constructor(_fuseTranslationLoaderService, _pharmacyService, _stateService, _urlService, _translationService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._pharmacyService = _pharmacyService;
        this._stateService = _stateService;
        this._urlService = _urlService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        if (this._stateService.params.id == 'new') {
            this.pageType = 'new';
            this.pharmacy = new Hospital({
                maxDataUsage: Math.pow(1024, 3),
                establishmentType: EEstablishmentType.Pharmacy,
                trialEnd: new Date()
            });
        }
        else {
            this.pageType = 'edit';
        }
        const title = this.pageType === 'new'
            ? this._translationService.instant('admHospitalDetail.NEW_TITLE')
            : `${this._translationService.instant('admHospitalDetail.EDIT_TITLE')} ${this.pharmacy.name}`;
        this.pageHeaderConfig = {
            title: title,
            icon: 'local_pharmacy'
        };
        this.createDetailFormGroup();
    }
    ngAfterViewInit() {
        this.tabGroup.selectedIndex = this._urlService.getParam('tab') || 0;
    }
    onAnimationDone() {
        this.animationDone = true;
    }
    onTabChange(event) {
        this._urlService.addParams({ tab: event.index }, true);
        this.animationDone = false;
    }
    // Create an empty FormGroup. The form controls will be added by the child components
    createDetailFormGroup() {
        this.detailForm = new UntypedFormGroup({
            infoForm: new UntypedFormGroup({}),
            otherForm: new UntypedFormGroup({}),
            actionsForm: new UntypedFormGroup({})
        });
    }
    // Use the children methods to update the hospital
    _updateHospital() {
        return forkJoin([
            this.informationTabComponent.updateHospital(this.pharmacy),
            this.otherTabComponent.updateHospital(this.pharmacy),
            this.actionsTabComponent.updateHospital(this.pharmacy)
        ]);
    }
    // Save an already existing hospital
    saveHospital() {
        this._updateHospital().subscribe(() => {
            this._pharmacyService.save(this.pharmacy).subscribe((newHosp) => {
                this._snackBarService.open(this._translationService.instant('admHospitalDetail.EDIT_OK'));
                this.pharmacy = newHosp;
                this.detailForm.markAsPristine();
            });
        });
    }
    // Add an new hospital to the database
    addHospital() {
        this._updateHospital().subscribe(() => {
            this._pharmacyService.add(this.pharmacy).subscribe((newHosp) => {
                this._snackBarService.open(this._translationService.instant('admHospitalDetail.CREATE_OK'));
                this.pharmacy = newHosp;
                this.pageType = 'edit';
                this.detailForm.markAsPristine();
            });
        });
    }
    // Called whenever a pre fill with an establishment has been requested
    fillWithEstablishment(establishment) {
        this.pharmacy.fillWithEstablishment(establishment);
        this.pharmacy.establishmentType = EEstablishmentType.Hospital;
        this.pharmacy = new Hospital(this.pharmacy);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Hospital !== "undefined" && Hospital) === "function" ? _g : Object)
], PharmaciesDetailComponent.prototype, "pharmacy", void 0);
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_h = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _h : Object)
], PharmaciesDetailComponent.prototype, "tabGroup", void 0);
__decorate([
    ViewChild(InformationTabComponent, { static: false }),
    __metadata("design:type", typeof (_j = typeof InformationTabComponent !== "undefined" && InformationTabComponent) === "function" ? _j : Object)
], PharmaciesDetailComponent.prototype, "informationTabComponent", void 0);
__decorate([
    ViewChild(ActionsTabComponent, { static: false }),
    __metadata("design:type", typeof (_k = typeof ActionsTabComponent !== "undefined" && ActionsTabComponent) === "function" ? _k : Object)
], PharmaciesDetailComponent.prototype, "actionsTabComponent", void 0);
__decorate([
    ViewChild(OtherTabComponent, { static: false }),
    __metadata("design:type", typeof (_l = typeof OtherTabComponent !== "undefined" && OtherTabComponent) === "function" ? _l : Object)
], PharmaciesDetailComponent.prototype, "otherTabComponent", void 0);
PharmaciesDetailComponent = __decorate([
    Component({
        selector: 'mpx-adm-pharmacy-detail',
        template: require('./pharmacies-detail.component.html').default,
        styles: [require('./pharmacies-detail.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof AdminPharmacyService !== "undefined" && AdminPharmacyService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof UrlService !== "undefined" && UrlService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object])
], PharmaciesDetailComponent);
export { PharmaciesDetailComponent };
