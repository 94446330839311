var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { HsHealthProductDirectoryComponent } from '@app/hospistock/shared/components/health-product-directory/hs-health-product-directory.component';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHospistockPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { hsPharmacyDrugServiceConfig } from '../hs-pharmacy-drug.config';
import { hsPharmacyDrugBookletConfig, hsPharmacyDrugBookletServiceConfig } from './hs-pharmacy-drug-booklet.config';
const states = [
    {
        name: 'mapui.hospistock.pharmacy.drug.booklet',
        url: '/booklet/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: HsHealthProductDirectoryComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-blue',
            access: [EHospistockPermission.PharmacyDrugBooklet_SeeMenuItem],
            servicesConfig: {
                healthProduct: hsPharmacyDrugServiceConfig,
                healthProductDirectory: hsPharmacyDrugBookletServiceConfig
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsPharmacyDrugBookletConfig
            }
        ]
    }
];
let HsPharmacyDrugBookletRoutingModule = class HsPharmacyDrugBookletRoutingModule {
};
HsPharmacyDrugBookletRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], HsPharmacyDrugBookletRoutingModule);
export { HsPharmacyDrugBookletRoutingModule };
