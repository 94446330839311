var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { NavBarService } from '@app/services/nav-bar-subject/nav-bar-subject.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EAvailableStatus } from '@mapuilabs/mpl-interfaces';
import { IdentityService } from '@services/identity/identity.service';
import { CAvailableRequestFilter } from '@shared/constTypes/availables/available-request-filter.const';
import { CAvailableSort, CAvailableSortStates } from '@shared/constTypes/availables/available-sort.const';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { Transition } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { locale as globalEnglish } from '../../../../i18n/en';
import { locale as globalFrench } from '../../../../i18n/fr';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { AvailableRequestService } from '../available-request.service';
let AvailableListComponent = class AvailableListComponent extends ListPage {
    constructor(_availableRequestService, _fuseTranslationLoaderService, _identityService, _navBarService, _stateService, _transition, _injector) {
        super(_injector, _availableRequestService.getPageAvailableOffer);
        this._availableRequestService = _availableRequestService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._identityService = _identityService;
        this._navBarService = _navBarService;
        this._stateService = _stateService;
        this._transition = _transition;
        this._injector = _injector;
        this.sorts = CAvailableSort;
        this.presetFilters = CAvailableRequestFilter;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english, globalFrench, globalEnglish);
        // Init Subjects
        this._subscriptionNotifier = new Subject();
        this.dataSource.subscribe((data) => {
            if (data && data.length > 0) {
                this._availableRequestService.setCurrentAvailable(data.find((avAndOffers) => avAndOffers.available._id == this._transition.params().id));
            }
        }, console.error);
        this.pageHeaderConfig = {
            title: this._translationService.instant('AVAILABLE.TITLE'),
            searchBarPlaceholder: this._translationService.instant('AVAILABLE.SEARCH'),
            icon: 'shopping_cart'
        };
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    get availableAndOffers() {
        return this.dataSource.value;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._navBarService.navBarUnfolded
            .pipe(takeUntil(this._subscriptionNotifier))
            .subscribe((value) => (this.navBarUnfolded = value));
        this._availableRequestService.onCurrentAvailableChangedFromList.subscribe((currentAv) => (this.currentAvAndOffers = currentAv));
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this._availableRequestService.setCurrentAvailable(null);
        this.dataSource.unsubscribe();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _isValidated(offer) {
        return offer && offer.validated === true;
    }
    _isArchived(av) {
        return av.status === EAvailableStatus.Archived;
    }
    _isCurrentAvailable(av) {
        var _a;
        return av._id == ((_a = this.currentAvAndOffers) === null || _a === void 0 ? void 0 : _a.available._id);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    resetCurrentAvailable() {
        this._availableRequestService.setCurrentAvailable(null);
    }
    readAvailable(avAndOffers) {
        this._stateService.go('mapui.exchangeV2.available.available-request.available', {
            id: avAndOffers.available._id
        });
        this._availableRequestService.setCurrentAvailable(avAndOffers);
    }
    getStyle(av) {
        const styles = [];
        const isCurrentAvailable = this._isCurrentAvailable(av.available);
        if (isCurrentAvailable) {
            styles.push(...['primary-border-left bl-4 bl-solid']);
        }
        const isArchived = this._isArchived(av.available);
        if (isArchived) {
            styles.push('background-bg');
        }
        const isValidated = this._isValidated(av.offers.find(({ hospital }) => Utils.getId(hospital) == this._identityService.hospital._id));
        if (isValidated) {
            styles.push('green-50-bg');
        }
        return styles;
    }
    /**
     * Toggle the sidebar
     * @param name
     */
    toggleSidebar(name) {
        this._fuseSidebarService.getSidebar(name).open();
    }
    /**
     * Deselect current stockout
     */
    deselectCurrentAvailable() {
        this._stateService.go('mapui.exchangeV2.available.available-request.available', {
            id: '0'
        });
        this._availableRequestService.setCurrentAvailable(null);
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    getSortState(sort) {
        if (!this.sortValue || this.sortValue.active !== sort.active) {
            return CAvailableSortStates.default;
        }
        else {
            return CAvailableSortStates[this.sortValue.direction];
        }
    }
    selectSort(sort) {
        const nextState = {
            default: { active: sort.active, direction: CAvailableSortStates.asc.direction },
            asc: { active: sort.active, direction: CAvailableSortStates.desc.direction },
            desc: undefined
        };
        if (this.sortValue && (sort === null || sort === void 0 ? void 0 : sort.active) === this.sortValue.active) {
            this.sortChange(nextState[this.sortValue.direction]);
        }
        else {
            this.sortChange(nextState.default);
        }
    }
    isPresetFilterActive(presetFilter) {
        return isEqual(presetFilter.value.filters, this.filtersValues);
    }
    isSortActive(sort) {
        var _a;
        return sort.active === ((_a = this.sortValue) === null || _a === void 0 ? void 0 : _a.active);
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], AvailableListComponent.prototype, "_paginator", void 0);
AvailableListComponent = __decorate([
    Component({
        selector: 'mpx-available-list',
        template: require('./available-list.component.html').default,
        styles: [require('./available-list.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AvailableRequestService !== "undefined" && AvailableRequestService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _c : Object, typeof (_d = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof Transition !== "undefined" && Transition) === "function" ? _f : Object, typeof (_g = typeof Injector !== "undefined" && Injector) === "function" ? _g : Object])
], AvailableListComponent);
export { AvailableListComponent };
