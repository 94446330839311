var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '@angular/core';
let ExChipComponent = class ExChipComponent {
    constructor() {
        this.pageHeaderConfig = {
            title: 'Chip Component',
            icon: 'extension'
        };
        this.titles = {
            toolbar: 'Composant Chip utilisation',
            subtitles: {
                documentation: 'Documentation',
                examples: 'Exemples'
            }
        };
        this.documentation = {
            inputs: {
                title: 'Inputs',
                items: [
                    {
                        name: `size: 'sm' | 'md' | 'lg'`,
                        description: 'Taille de la chip.'
                    }
                ]
            },
            outputs: {
                title: 'Outputs',
                items: []
            }
        };
    }
};
ExChipComponent = __decorate([
    Component({
        selector: 'mpx-ex-chip',
        template: require('./ex-chip.component.html').default,
        styles: [require('./ex-chip.component.scss')]
    })
], ExChipComponent);
export { ExChipComponent };
