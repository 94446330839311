import { EMemberStatus, EMpStatus, } from '@mapuilabs/mpl-interfaces';
export class HealthProfessionalService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get a specific [[IHealthProfessional]]
         * @param id The [[IHealthProfessional]] [[Id]]
         * @returns A promise containing the [[IHealthProfessional]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            this.Api.healthProfessional.get({ id: id }, (item) => defer.resolve(item), () => defer.reject(EMpStatus.RejectByServer));
            return defer.promise;
        };
        /**
         * Find [[IHealthProfessional]] by type [[EMemberStatus]] = Doctor
         * @param name the given name to find
         * @param state
         * @returns {Promise<T>|IPromise<T>}
         */
        this.findDoctor = (name) => {
            const defer = this.$q.defer();
            this.Api.healthProfessional.find({ name: name, type: EMemberStatus.Doctor }, ans => {
                defer.resolve(ans);
            }, () => defer.reject(EMpStatus.RejectByServer));
            return defer.promise;
        };
        /**
         * Find email from [[IEstablishment]] FINESS
         * @param {string} FINESS
         * @param {boolean} secure
         * @returns {angular.IPromise<MailAddr>}
         */
        this.findMailFromFiness = (FINESS, secure) => {
            const defer = this.$q.defer();
            this.Api.healthProfessional.findMail({ finess: FINESS, secure: secure }, ans => defer.resolve(ans[secure ? 'secureEmail' : 'email']), () => defer.reject(EMpStatus.RejectByServer));
            return defer.promise;
        };
    }
}
