import * as angular from 'angular';
import './general/general.module';
import './exchanges/exchanges.module';
import './hospiville/hospiville.module';
export class MainController {
    /** @ngInject */
    constructor($scope, $rootScope) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        // Data
        //////////
        // console.log("MAIN CTRL !");
        // Remove the splash screen
        $scope.$on('$viewContentAnimationEnded', function (event) {
            if (event.targetScope.$id === $scope.$id) {
                // console.log('REMOVE LOADERS');
                $rootScope.$broadcast('msSplashScreen::remove');
                angular.element(document.querySelector('#preloader')).remove();
            }
        });
    }
}
