import * as angular from 'angular';
import './hv-patient/hv-patient.module';
import './hv-pharmacy/hv-pharmacy.module';
import './hv-statistics/hv-statistics.module';
import './services/services.module';
import 'ng-sortable';
angular.module('hospiville', [
    'as.sortable',
    'hospiville.services',
    'hospiville.patient',
    'hospiville.statistics',
    'hospiville.pharmacy'
]);
