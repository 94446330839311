var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { copy } from 'angular';
import { Component } from '@angular/core';
import { EProjects } from '@mapuilabs/mpl-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { RolesService } from '@services/role/roles.service';
import { rolesSubscriptions } from '@shared/constTypes/roles/roles-subscriptions.const';
import { rolesTypes } from '@shared/constTypes/roles/roles-types.const';
import { DialogService } from '@services/dialog/dialog.service';
import { EditRoleComponent } from './edit-role/edit-role.component';
import { StateService } from '@uirouter/angularjs';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
const projectToTranslationMap = new Map([
    [EProjects.ADMIN_PANEL, 'ROLES.ADMIN_PANEL.TITLE'],
    [EProjects.HOSPISTOCK, 'ROLES.HOSPISTOCK.TITLE'],
    [EProjects.HOSPIVILLE, 'ROLES.HOSPIVILLE.TITLE'],
    [EProjects.MAPUI, 'ROLES.MAPUI.TITLE'],
    [EProjects.WORKSPACE, 'ROLES.WORKSPACE.TITLE']
]);
let ListRolesComponent = class ListRolesComponent {
    constructor(_dialogService, _fuseNavigationService, _fuseTranslationLoaderService, _matDialog, _rolesService, _snackbarService, _stateService, _translateService, _translationService) {
        this._dialogService = _dialogService;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._matDialog = _matDialog;
        this._rolesService = _rolesService;
        this._snackbarService = _snackbarService;
        this._stateService = _stateService;
        this._translateService = _translateService;
        this._translationService = _translationService;
        this.rolesSubscriptionsList = rolesSubscriptions;
        this.rolesTypesList = rolesTypes;
        this.tableColumns = ['type', 'name', 'subscription', 'home', 'description', 'permissions', 'actions'];
        this.roles = undefined;
        this.projects = Object.values(EProjects);
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Header
        this.pageHeaderConfig = {
            title: this._translate('ROLES.TITLE'),
            icon: 'group'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        if (!this.selectedProject) {
            this.selectedProject = this._stateService.params.project ? this._stateService.params.project : 'mapui';
        }
        this.selectProject(this.selectedProject);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    myCompare(a, b, direct) {
        if (a === b) {
            return 0;
        }
        if (a < b) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    _updateRolesUnhandleledPermissions() {
        const permissions = this._rolesService.getPermissionFromProject(this.selectedProject);
        this.rolesUnhandleledPermissions = this.roles.map((role) => role.permissions.filter((permission) => !Object.values(permissions).includes([role.project, permission].join('|'))));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getTranslate(data, index) {
        for (const role in data) {
            if (data[role].value === index) {
                return data[role].translate;
            }
        }
    }
    addRoleModal() {
        const dialogRef = this._matDialog.open(EditRoleComponent, {
            width: '40%',
            data: { project: this.selectedProject, rolesList: this.roles }
        });
        dialogRef.afterClosed().subscribe((role) => {
            if (role) {
                this._rolesService.add(role).subscribe((result) => {
                    this.roles.push(result);
                    this._updateRolesUnhandleledPermissions();
                    this._snackbarService.open(this._translateService.instant('ROLES.TABLE.EDIT.SUCCESS.NEW'));
                }, (err) => this._snackbarService.openError(err));
            }
        }, (err) => {
            this._snackbarService.openError(err);
        });
    }
    editRoleModal(role) {
        const dialogRef = this._matDialog.open(EditRoleComponent, {
            width: '40%',
            data: { role: role, rolesList: this.roles }
        });
        dialogRef.afterClosed().subscribe((role) => {
            if (role) {
                this._rolesService.save(role).subscribe(() => {
                    this._updateRolesUnhandleledPermissions();
                    this._snackbarService.open(this._translateService.instant('ROLES.TABLE.EDIT.SUCCESS.EDIT'));
                }, (err) => this._snackbarService.openError(err));
            }
        }, (err) => this._snackbarService.openError(err));
    }
    deleteModal(role) {
        this._dialogService
            .openConfirmDialog({
            title: this._translateService.instant('COMMONS.DELETE') + ' ?',
            message: this._translateService.instant('ROLES.TABLE.DELETE.MODAL.CONTENT') + ' ' + role.name + ' ?',
            confirmButton: this._translateService.instant('COMMONS.YES'),
            cancelButton: this._translateService.instant('COMMONS.NO')
        })
            .subscribe((result) => {
            if (result) {
                this._rolesService.delete(role).subscribe(() => {
                    this.roles.splice(this.roles.indexOf(role), 1);
                    this._updateRolesUnhandleledPermissions();
                    this._snackbarService.open(this._translateService.instant('ROLES.TABLE.DELETE.SUCCESS'));
                }, (err) => this._snackbarService.openError(err));
            }
        });
    }
    duplicateRole(role) {
        const newRole = copy(role);
        newRole._id = undefined;
        newRole.name = 'Copie de ' + newRole.name;
        this._rolesService.add(newRole).subscribe((result) => {
            this.roles.push(result);
            this._snackbarService.open(this._translateService.instant('ROLES.TABLE.DUPLICATE.SUCCESS'));
        }, (err) => this._snackbarService.openError(err));
    }
    selectProject(project) {
        this.selectedProject = project;
        this._stateService.go('mapui.adm.roles.project', { project: project });
        this._rolesService.getAllByProject(project).subscribe((roles) => {
            this.roles = roles;
            this._updateRolesUnhandleledPermissions();
        });
    }
    isStateValid(state) {
        return !!this._fuseNavigationService.getTitleByState(state);
    }
    getProjectTranslation(project) {
        return projectToTranslationMap.get(project);
    }
    getUnhandeledPermissionsTooltip(index) {
        var _a;
        const headerMessage = this._translationService.instant('ROLES.TOOLTIPS.UNHANDELED_PERMISSIONS') + '\n';
        return (_a = [headerMessage, ...this.rolesUnhandleledPermissions[index]]) === null || _a === void 0 ? void 0 : _a.join('\n');
    }
};
ListRolesComponent = __decorate([
    Component({
        selector: 'mpx-list-roles',
        template: require('./list-roles.component.html').default,
        styles: [require('./list-roles.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof RolesService !== "undefined" && RolesService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object, typeof (_g = typeof StateService !== "undefined" && StateService) === "function" ? _g : Object, typeof (_h = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _h : Object, typeof (_j = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _j : Object])
], ListRolesComponent);
export { ListRolesComponent };
