var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
const DATE_INPUT_FORMAT = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
let ExDatepickerLimitsComponent = class ExDatepickerLimitsComponent {
    constructor() {
        this.startLabel = '';
        this.endLabel = '';
        this.reverseBackground = false;
        this.reverseColors = false;
        this.isLimitsLock = true;
        this.pageHeaderConfig = {
            title: 'Datepicker limits Component',
            icon: 'extension '
        };
    }
};
ExDatepickerLimitsComponent = __decorate([
    Component({
        selector: 'ex-datepicker-limits',
        template: require('./ex-datepicker-limits.component.html').default,
        providers: [
            {
                provide: DateAdapter,
                useClass: MomentDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            },
            { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMAT }
        ]
    })
], ExDatepickerLimitsComponent);
export { ExDatepickerLimitsComponent };
