import * as angular from 'angular';
/** @ngInject */
export class MpSearchMedicController {
    /** @ngInject */
    constructor(MedicService) {
        this.MedicService = MedicService;
        /**
         * Return an array containing all matches from value
         * @returns A promise containing an Array of string
         */
        this.searchMedics = () => {
            return this.MedicService.findByName(this.ctrl.searchText);
        };
        /**
         * Set the selected medicine in the [[Loan]]
         */
        this.searchDrugsSelected = () => {
            if (this.ctrl.selected) {
                this._hasJustSelected = true;
                this.ctrl.selected.UCD13 = this.ctrl.selected.ucd13;
                this.MedicService.getLab(this.ctrl.selected.sp_code_sq_pk)
                    .then((ans) => {
                    this.ctrl.selected.laboratory_exp = ans.laboratory_exp;
                    this.ctrl.selected.laboratory_tit = ans.laboratory_tit;
                    // console.log("turned to " + this._hasJustSelected);
                    this.onSelected({ drug: this.ctrl.selected });
                });
            }
        };
        /**
         * On text change call callback
         */
        this.searchTextChange = () => {
            if (!this._hasJustSelected) {
                if (this.onTextChange) {
                    this.onTextChange({ text: this.ctrl.searchText });
                }
                if (!this.disallowCustomName && this.onSelected) {
                    this.onSelected({ drug: { denomination: this.ctrl.searchText, UCD13: null, CIP13: null } });
                }
            }
            this._hasJustSelected = false;
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
        this.name = this.name || 'search-medic-autocomplete';
        this._hasJustSelected = false;
    }
    $onInit() {
        if (this.drug) {
            this.ctrl.searchText = this.drug;
        }
    }
    $onChanges(changes) {
        if (changes.drug) {
            this.ctrl.searchText = angular.copy(this.drug);
        }
    }
}
