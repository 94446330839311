import { EMaPUIPermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
export class PreparationService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Filter that indicates if the [[IPreparation]] name match the query
         * @param query
         * @returns {(prep:IPreparation)=>(boolean|boolean)}
         * @private
         */
        this._createFilter = (query) => {
            return function (prep) {
                const lowerName = prep.name.toLowerCase();
                if (query) {
                    return lowerName.indexOf(query.toLowerCase()) === 0;
                }
                return true;
            };
        };
        /**
         * Matches all [[IPreparation]] from a [[IHopital]]
         * @param query [[string]] representing the searched [[IPreparation]]
         * @param es The [[IHospital]] where the [[IPreparation]] are tested
         * @returns A promise containing the array of all the [[IPreparation]] matching the query
         */
        this.searchPreps = (query, es) => {
            const defer = this.$q.defer();
            defer.resolve(es.preparations ? es.preparations.filter(this._createFilter(query)) : []);
            return defer.promise;
        };
        /**
         * Get all [[IPreparation]] from a specific [[IHospital]]
         * @param hid The [[IHospital]] [[Id]]
         * @returns A promise containing an array of [[IPreparation]]
         */
        this.getAllFrom = (hid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.PrepInst_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.preparation.byHospital.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get all [[IPreparation]]
         * @returns A promise containing an array of [[IPreparation]]
         */
        this.getAll = () => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Preparations_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.preparation.direct.get({}, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update the [[IPreparation]] in the dataBase
         * @param preparation The [[IPreparation]] to update
         * @returns {IPromise<T>|Promise<T>}
         */
        this.save = (preparation) => {
            const defer = this.$q.defer();
            if (!preparation) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.Preparations_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.preparation.direct.save({}, preparation, ans => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
}
