var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
let SmallListColComponent = class SmallListColComponent {
    get bold() {
        return this._bold;
    }
    set bold(val) {
        this._bold = coerceBooleanProperty(val);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], SmallListColComponent.prototype, "bold", null);
SmallListColComponent = __decorate([
    Component({
        selector: 'mpx-small-list-col',
        template: `
        <span class="mat-body-1 overflow-hidden text-overflow-ellipsis grow" [ngClass]="{ bold: bold === true }">
            <ng-content></ng-content>
        </span>
    `,
        styles: [
            `
            :host {
                flex: 1 1 100%;
                display: flex;
                overflow: hidden;
            }

            .bold {
                font-weight: 700;
            }
        `
        ]
    })
], SmallListColComponent);
export { SmallListColComponent };
