import * as angular from 'angular';
export class HvSelectDrugBalanceSourceController {
    /** @ngInject */
    constructor(Api, _) {
        this.Api = Api;
        this._ = _;
        /**
         * Get [[ISource]] translation
         * @param {ESource} type
         * @returns {string}
         */
        this.sourceType = (type) => {
            if (!this._constType || type === null)
                return '';
            return this._.find(this._constType.sources, { value: type }).translate;
        };
        /**
         * When source change
         */
        this.change = () => {
            if (this.onChange) {
                this.onChange({
                    $event: { source: this.source },
                });
            }
        };
        /**
         * Set available [[ISource]]s
         * @private
         */
        this._setList = () => {
            this.Api.hvConstType.get((ans) => {
                this._constType = ans;
                this.sourceTypes = ans.sources;
            });
            for (const src of this.patientSources) {
                if (this.dbsSources && !this._.find(this.dbsSources, (source) => {
                    return source.source._id === src._id;
                })) {
                    this.availablePatientSrc.push(src);
                }
            }
        };
        this.availablePatientSrc = [];
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.patientSources) {
            this.patientSources = angular.copy(this.patientSources);
        }
        if (changes.source) {
            this.source = angular.copy(this.source);
            if (this.source && this.source._id)
                this.availablePatientSrc.push(this.source);
        }
        if (changes.dbsSources) {
            this.dbsSources = angular.copy(this.dbsSources);
            this._setList();
        }
    }
}
