export const locale = {
    lang: 'en',
    data: {
        TITLES: {
            SUCCESS: {
                STATUS: 'Success'
            },
            ERROR: {
                STATUS: 'Major errors'
            },
            WARNING: {
                STATUS: 'Minor errors'
            },
            INFO: {
                STATUS: 'Information'
            }
        }
    }
};
