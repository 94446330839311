var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f;
import { Component } from '@angular/core';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { HsDashboardService } from '@app/hospistock/shared/services/hs-dashboard.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType, EHsHealthProductTypes, EHsPublicationFilter, EHsPublicationTag, EHsPublicationType } from '@mapuilabs/mpl-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { StateService, UIRouterGlobals } from '@uirouter/angularjs';
let HsDashboardWidgetPublicationsSummaryComponent = class HsDashboardWidgetPublicationsSummaryComponent {
    constructor(_healthProductService, _fuseTranslationLoaderService, _dashboardService, _router, _stateService, _translate) {
        this._healthProductService = _healthProductService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._dashboardService = _dashboardService;
        this._router = _router;
        this._stateService = _stateService;
        this._translate = _translate;
        this.summary = undefined;
        this.CPublicationType = CHsPublicationType;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this.eHsPublicationType = EHsPublicationType;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init
        this.noData = false;
        this.isLoading = false;
        this.widgetContainerConfig = {
            title: this._translate.instant('DASHBOARD.WIDGET.SUMMARY.TITLE')
        };
        this.healthProductType = _healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._getPublicationsSummary();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _getPublicationsSummary() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            try {
                this.summary = yield lastValueFrom(this._dashboardService.getDashboardEstablishmentPublicationsSummary());
            }
            catch (err) {
                // Handle forbidden access
                if ((err === null || err === void 0 ? void 0 : err.status) === 403) {
                    this.forbiddenAccess = true;
                }
                // Assign empty array to display widget
                this.noData = true;
                console.error(err);
            }
            this.isLoading = false;
        });
    }
    /**
     * Redirects the user to a specific publication type page with optional filters.
     *
     * @param publicationType - The publication type to navigate to.
     *
     * This function constructs the URL for the specified publication type and appends
     * optional filters to the URL parameters. It then navigates to the constructed URL
     * using the Angular UI Router's state service.
     */
    redirectToPublicationsType(publicationType) {
        // Define a regular expression to replace '.dashboard' with '.publication' in the current state
        const lastListRegExp = new RegExp('\\.dashboard$', 'g');
        // Replace the last occurrence of '.dashboard' with '.publication' in the current state
        const publicationState = this._router.current.name.replace(lastListRegExp, '.publication');
        if (publicationType) {
            const archivedFilter = {
                type: EFilterType.VALUE,
                attribute: EHsPublicationTag.ARCHIVED,
                value: false
            };
            const typeFilter = {
                type: EFilterType.VALUES,
                attribute: EHsPublicationFilter.TYPES,
                inArray: true,
                values: [publicationType]
            };
            const filterParams = [archivedFilter, typeFilter];
            // Navigate to the publication page with the specified filters
            this._stateService.go(publicationState, { filters: JSON.stringify(filterParams) });
        }
        else {
            // Navigate to the publication page
            this._stateService.go(publicationState);
        }
    }
};
HsDashboardWidgetPublicationsSummaryComponent = __decorate([
    Component({
        template: require('./hs-dashboard-widget-publications-summary.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HsDashboardService !== "undefined" && HsDashboardService) === "function" ? _c : Object, typeof (_d = typeof UIRouterGlobals !== "undefined" && UIRouterGlobals) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _f : Object])
], HsDashboardWidgetPublicationsSummaryComponent);
export { HsDashboardWidgetPublicationsSummaryComponent };
