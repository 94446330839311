var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { ConfigurationService } from '../../../services/hospiville/configuration/configuration.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { MedicationReconciliationService } from '../../../services/hospiville/medication-reconciliation/medication-reconciliation.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { Access } from '@main/services/auth/access.service';
let ConfigurationComponent = class ConfigurationComponent {
    constructor(_medicationReconciliationService, _fuseTranslationLoaderService, _configurationService, _translationService, _snackbarService, _ajsAccess) {
        this._medicationReconciliationService = _medicationReconciliationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._configurationService = _configurationService;
        this._translationService = _translationService;
        this._snackbarService = _snackbarService;
        this._ajsAccess = _ajsAccess;
        // -1 means that no hospital service is currently being edited
        this.currentEditionIndex = -1;
        this.currentEditionName = new UntypedFormControl('', Validators.required);
        this.newServiceName = new UntypedFormControl('', Validators.required);
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translationService.instant('CONFIGURATION.TITLE'),
            icon: 'build'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._configurationService.getAll().subscribe((hospitalServices) => (this.services = hospitalServices), (error) => this._snackbarService.openError(error));
        this.filteredOptionsEditionName = this.currentEditionName.valueChanges.pipe(map((value) => this.searchDepartment(value)));
        this.filteredOptionsNewName = this.newServiceName.valueChanges.pipe(map((value) => this.searchDepartment(value)));
        this.migrationState = this._ajsAccess.isHVMigrationState();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    swapDeletedBooleen(serviceIndex, service) {
        this.services[serviceIndex].hsDeleted = !this.services[serviceIndex].hsDeleted;
        this._configurationService.save(service).subscribe((value) => (this.services[serviceIndex] = value), (error) => this._snackbarService.openError(error));
    }
    startEditingService(serviceIndex) {
        this.currentEditionIndex = serviceIndex;
        this.currentEditionName.setValue(this.services[serviceIndex].name);
        this.currentEditionName.markAsPristine();
    }
    cancelEditingService() {
        this.currentEditionIndex = -1;
        this.currentEditionName.markAsPristine();
    }
    saveService(serviceIndex, service) {
        this.services[serviceIndex].name = this.currentEditionName.value;
        this._configurationService.save(service).subscribe((value) => {
            this.services[serviceIndex] = value;
            this.currentEditionIndex = -1;
            this.currentEditionName.markAsPristine();
        }, (error) => this._snackbarService.openError(error));
    }
    addService() {
        this._configurationService
            .add({
            name: this.newServiceName.value,
            hsDeleted: false
        })
            .subscribe((value) => {
            this.services.push(value);
            this.newServiceName.reset();
        }, (error) => this._snackbarService.openError(error));
    }
    searchDepartment(query) {
        return this._medicationReconciliationService.searchDepartment(query);
    }
};
ConfigurationComponent = __decorate([
    Component({
        selector: 'mpx-configuration',
        template: require('./configuration.component.html').default,
        styles: [require('./configuration.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MedicationReconciliationService !== "undefined" && MedicationReconciliationService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof ConfigurationService !== "undefined" && ConfigurationService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object, typeof (_f = typeof Access !== "undefined" && Access) === "function" ? _f : Object])
], ConfigurationComponent);
export { ConfigurationComponent };
