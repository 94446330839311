export const locale = {
    lang: 'fr',
    data: {
        TOOLBAR: {
            TOGGLE_NAVIGATION: 'Navigation',
            TOGGLE_NAVIGATION_FOLD: 'Déplier / Replier la navigation',
            TOGGLE_NAVIGATION_MODE: 'Mode de navigation',
            SEARCH: 'Recherche',
            USER_SETTINGS: 'Paramètres Utilisateurs',
            TOGGLE_QUICK_PANEL: 'Accès rapide',
            ENGLISH: 'Anglais',
            SPANISH: 'Espagnol',
            TURKISH: 'Turc',
            FRENCH: 'Français',
            PROFILE: {
                MY_PROFILE: 'Mon profil',
                LOGOUT: 'Déconnexion'
            },
            NAV: {
                HOME: 'Accueil',
                DASHBOARD: 'Tableau de bord',
                MAP: 'Carte'
            }
        },
        NOTIFICATIONS: {
            TITLE: 'Notifications',
            MARK_ALL_AS_READ: 'Tout marquer comme lu',
            SETTINGS: 'Paramètres',
            NO_NOTIF: 'Aucune notification',
            ALL_LOADED: 'Fin des notifications',
            RELATIVE_TIME: 'Il y a {{diff}}',
            LOAD_MORE: 'Voir plus',
            LABEL: {
                LOAN: {
                    CREATE: '<strong>{{hospital}}</strong> vous prête {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> reprends {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> a confirmé le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    RETURN: '<strong>{{hospital}}</strong> a retourné le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    BILL: '<strong>{{hospital}}</strong> a facturé le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: '<strong>{{hospital}}</strong> a liquidé la facture du prêt de {{nbr}} <strong>{{drug}}</strong>'
                },
                BORROWING: {
                    CREATE: '<strong>{{hospital}}</strong> emprunte {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> vous prête {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: "<strong>{{hospital}}</strong> a confirmé l'emprunt de {{nbr}} <strong>{{drug}}</strong>",
                    RETURN: "<strong>{{hospital}}</strong> a retourné l'emprunt de {{nbr}} <strong>{{drug}}</strong>",
                    BILL: '<strong>{{hospital}}</strong> a facturé le prêt de {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: "<strong>{{hospital}}</strong> a liquidé la facture de l'emprunt de {{nbr}} <strong>{{drug}}</strong>"
                },
                AVAILABLE: {
                    CREATE: '<strong>{{hospital}}</strong> a mis à disposition {{nbr}} <strong>{{drug}}</strong>',
                    REQUEST: '<strong>{{hospital}}</strong> souhaite reprendre {{nbr}} <strong>{{drug}}</strong> que vous avez mis à disposition',
                    EXPIRE: 'Votre mise à disposition de {{nbr}} <strong>{{drug}}</strong> a expiré',
                    VALIDATE: '<strong>{{hospital}}</strong> a validé votre demande de reprise pour {{nbr}} <strong>{{drug}}</strong>'
                },
                PREPARATION: {
                    CREATE: '<strong>{{hospital}}</strong> met à votre disposition {{nbr}} <strong>{{drug}}</strong>',
                    REQUEST: '<strong>{{hospital}}</strong> demande la préparation de {{nbr}} <strong>{{drug}}</strong>',
                    VALIDATE: '<strong>{{hospital}}</strong> a validé la préparation de <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> a libéré la préparation de <strong>{{drug}}</strong>',
                    'VALIDATE-RECEPTION': '<strong>{{hospital}}</strong> a accusé réception de la préparation de <strong>{{drug}}</strong>',
                    BILL: '<strong>{{hospital}}</strong> a facturé la préparation de {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: '<strong>{{hospital}}</strong> a liquidé la facture de la préparation de {{nbr}} <strong>{{drug}}</strong>'
                },
                REQUEST: {
                    CREATE: '<strong>{{hospital}}</strong> a besoin de {{nbr}} <strong>{{drug}}</strong>',
                    EXPIRE: 'Votre demande de {{nbr}} <strong>{{drug}}</strong> a expiré'
                },
                GROUP: {
                    REQUEST: '<strong>{{who}}</strong> souhaite rejoindre le groupe <strong>{{group}}</strong>',
                    INVITE: '<strong>{{who}}</strong> vous invite à rejoindre le groupe <strong>{{group}}</strong>',
                    CANCEL: "<strong>{{who}}</strong> a annulé l'invitation à rejoindre le groupe <strong>{{group}}</strong>",
                    ACCEPT: '<strong>{{who}}</strong> a accepté votre invitation au groupe <strong>{{group}}</strong>',
                    DENY: '<strong>{{who}}</strong> a refusé votre invitation au groupe <strong>{{group}}</strong>',
                    QUIT: '<strong>{{who}}</strong> a quitté le groupe <strong>{{group}}</strong>',
                    EJECT: '<strong>{{who}}</strong> vous a retiré du groupe <strong>{{group}}</strong>',
                    GRANT: "<strong>{{who}}</strong> vous a élevé au rang d'administrateur du groupe <strong>{{group}}</strong>",
                    REVOKE: "<strong>{{who}}</strong> vous a retiré les droits d'administration du groupe <strong>{{group}}</strong>"
                },
                HOSPITAL: {
                    'RANK-MEMBER': 'Votre établissement est dorénavant membre premium !'
                },
                USER: {
                    'RANK-HOSPIVILLE': 'Vous avez dorénavant accès à HospiVille !',
                    'RANK-ADMIN': 'Vous êtes dorénavant administrateur !'
                },
                'THERAPEUTIC-BOOKLET': {
                    SHARE: '<strong>{{who}}</strong> a partagé avec <strong>{{group}}</strong> des informations concernant <strong>{{booklet}}</strong>',
                    'REMOVE-SHARE': '<strong>{{who}}</strong> a arrété de partager des informations concernant <strong>{{booklet}}</strong>',
                    'UPDATE-SHARE': "<strong>{{who}}</strong> a mis à jour les informations qu'il partage avec <strong>{{group}}</strong> concernant <strong>{{booklet}}</strong>"
                },
                SHAREDFILE: {
                    CREATE: 'Le fichier <strong>{{filename}}</strong> a été partagé avec vous dans le dossier <strong>{{parent}}</strong>'
                },
                'STOCKOUT-LABO': {
                    'UPDATE-SHARE': "Le laboratoire <strong>{{labo}}</strong> a mis à jour sa liste de rupture d'approvisionement</strong>"
                },
                ADMIN: {
                    LOAN: {
                        CREATE: '<strong>{{hospital}}</strong> prête {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        ANSWER: '<strong>{{hospital}}</strong> reprends {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        CONFIRM: '<strong>{{hospital}}</strong> a confirmé le prêt de {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        RETURN: '<strong>{{hospital}}</strong> a retourné le prêt de {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        BILL: '<strong>{{hospital}}</strong> a facturé le prêt de {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        SETTLE: '<strong>{{hospital}}</strong> a liquidé la facture du prêt de {{nbr}} <strong>{{drug}}</strong> fait par {{dest}}'
                    },
                    BORROWING: {
                        CREATE: '<strong>{{hospital}}</strong> emprunte {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        ANSWER: '<strong>{{hospital}}</strong> prête {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        CONFIRM: "<strong>{{hospital}}</strong> a confirmé l'emprunt de {{nbr}} <strong>{{drug}}</strong> à {{dest}}",
                        RETURN: "<strong>{{hospital}}</strong> a retourné l'emprunt de <strong>{{drug}}</strong> à {{dest}}",
                        BILL: '<strong>{{hospital}}</strong> a facturé le prêt de {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        SETTLE: "<strong>{{hospital}}</strong> a liquidé la facture de l'emprunt de {{nbr}} <strong>{{drug}}</strong> fait par {{dest}}"
                    },
                    AVAILABLE: {
                        REQUEST: '<strong>{{hospital}}</strong> souhaite reprendre {{nbr}} <strong>{{drug}}</strong> mis à disposition par {{dest}}',
                        EXPIRE: 'La mise à disposition faite par {{dest}} de {{nbr}} <strong>{{drug}}</strong> a expiré'
                    },
                    REQUEST: {
                        EXPIRE: 'La demande faite par {{dest}} de {{nbr}} <strong>{{drug}}</strong> a expiré'
                    },
                    PREPARATION: {
                        CREATE: '<strong>{{hospital}}</strong> prépare {{nbr}} <strong>{{drug}}</strong> pour {{dest}}',
                        REQUEST: '<strong>{{hospital}}</strong> demande la préparation de {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        VALIDATE: '<strong>{{hospital}}</strong> a validé la préparation de {{nbr}} <strong>{{drug}}</strong> pour {{dest}}',
                        CONFIRM: '<strong>{{hospital}}</strong> a libéré la préparation de {{nbr}} <strong>{{drug}}</strong> pour {{dest}}',
                        'VALIDATE-RECEPTION': '<strong>{{hospital}}</strong> a accusé réception de la préparation de {{nbr}} <strong>{{drug}}</strong> faite par {{dest}}',
                        BILL: '<strong>{{hospital}}</strong> a facturé la préparation de {{nbr}} <strong>{{drug}}</strong> à {{dest}}',
                        SETTLE: '<strong>{{hospital}}</strong> a liquidé la facture de la préparation de {{nbr}} <strong>{{drug}}</strong> faite par {{dest}}'
                    },
                    GROUP: {
                        REQUEST: '<strong>{{who}}</strong> souhaite rejoindre le groupe <strong>{{group}}</strong>',
                        INVITE: '<strong>{{who}}</strong> invite {{dest}} à rejoindre le groupe <strong>{{group}}</strong>',
                        CANCEL: "<strong>{{who}}</strong> a annulé l'invitation à rejoindre le groupe <strong>{{group}}</strong>",
                        ACCEPT: "<strong>{{who}}</strong> a accepté l'invitation de {{dest}} à rejoindre le groupe <strong>{{group}}</strong>",
                        DENY: "<strong>{{who}}</strong> a refusé l'invitation de {{dest}} à rejoindre le groupe <strong>{{group}}</strong>",
                        QUIT: '<strong>{{who}}</strong> a quitté le groupe <strong>{{group}}</strong>',
                        EJECT: '<strong>{{who}}</strong> a retiré {{dest}} du groupe <strong>{{group}}</strong>',
                        GRANT: "<strong>{{who}}</strong> a élevé {{dest}} au rang d'administrateur du groupe <strong>{{group}}</strong>",
                        REVOKE: "<strong>{{who}}</strong> a retiré les droits d'administration du groupe <strong>{{group}}</strong> à {{dest}}"
                    },
                    USER: {
                        CREATE: '<strong>{{email}}</strong> a rejoint la plateforme',
                        JOIN: "<strong>{{email}}</strong> s'est inscrit sur un compte invité",
                        'CHANGE-HOSPITAL': '<strong>{{email}}</strong> a été lié(e) à <strong>{{hospital}}</strong>` par {{admin}}',
                        LOCKED: "<strong>{{email}}</strong> a bloqué son compte (trop d'essais)",
                        'IP-LOCKED': "L'IP <strong>{{ip}}</strong> a été bloquée temporairement (trop d'essais)",
                        UNLOCKED: '<strong>{{email}}</strong> a été débloqué par {{admin}}',
                        'ADD-RIGHTS': "Le(s) droit(s) <strong>{{rights.join(', ')}}</strong> a/ont été assigné(s) à <strong>{{email}}</strong> par {{admin}}",
                        'REMOVE-RIGHTS': "Le(s) droit(s) <strong>{{rights.join(', ')}}</strong> a/ont été retiré(s) à <strong>{{email}}</strong> par {{admin}}"
                    },
                    HOSPITAL: {
                        'ADD-RIGHTS': "Le(s) droit(s) <strong>{{rights.join(', ')}}</strong> a/ont été assigné(s) à <strong>{{hospital}}</strong> par {{admin}}",
                        'REMOVE-RIGHTS': "Le(s) droit(s) <strong>{{rights.join(', ')}}</strong> a/ont été retiré(s) à <strong>{{hospital}}</strong> par {{admin}}"
                    },
                    'THERAPEUTIC-BOOKLET': {
                        SHARE: '<strong>{{who}}</strong> a partagé avec <strong>{{nbGroups}}</strong> groupes des informations concernant <strong>{{booklet}}</strong>',
                        'REMOVE-SHARE': '<strong>{{who}}</strong> a arrété de partager des informations concernant <strong>{{booklet}}</strong>',
                        'UPDATE-SHARE': "<strong>{{who}}</strong> a mis à jour les informations qu'il partage avec <strong>{{nbGroups}}</strong> groupes concernant <strong>{{booklet}}</strong>"
                    },
                    SHAREDFILE: {
                        CREATE: 'Le fichier <strong>{{filename}}</strong> a été partagé dans le dossier <strong>{{parent}}</strong>'
                    },
                    'STOCKOUT-LABO': {
                        'UPDATE-SHARE': "Le laboratoire <strong>{{labo}}</strong> a mis à jour sa liste de rupture d'approvisionement</strong>"
                    }
                }
            }
        }
    }
};
