export var SpreadsheetImportModalState;
(function (SpreadsheetImportModalState) {
    SpreadsheetImportModalState["SELECTING"] = "SELECTING";
    SpreadsheetImportModalState["PARSING"] = "PARSING";
    SpreadsheetImportModalState["IMPORTING"] = "IMPORTING";
})(SpreadsheetImportModalState || (SpreadsheetImportModalState = {}));
export var SpreadsheetImportModalStatus;
(function (SpreadsheetImportModalStatus) {
    SpreadsheetImportModalStatus["DONE"] = "DONE";
    SpreadsheetImportModalStatus["EMPTY"] = "EMPTY";
    SpreadsheetImportModalStatus["FAILED"] = "FAILED";
    SpreadsheetImportModalStatus["WARNING"] = "WARNING";
    SpreadsheetImportModalStatus["PENDING"] = "PENDING";
})(SpreadsheetImportModalStatus || (SpreadsheetImportModalStatus = {}));
