import { ELoanCat, EMaPUIPermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
/**
 * LoanService is a service used to interact with the loan API (e.g : get, add or delete some loans)
 */
export class LoanService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get an array of [[ILoan]].
         * The array contain all the loans in a billing state of the wanted hospital
         * @param hid The [[Id]] of the wanted hospital. If no set, current hospital is used.
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getBillingsFrom = (hid) => {
            const defer = this.$q.defer();
            if (!hid) {
                hid = this.Access.hospital._id;
            }
            if (!this.Access.authorize(EMaPUIPermission.Billing_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.billings.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain all the awaiting loans of the wanted hospital
         * @param hid The [[Id]] of the wanted hospital. If no set, current hospital is used.
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getBillingWaitingFrom = (hid) => {
            const defer = this.$q.defer();
            if (!hid) {
                hid = this.Access.hospital._id;
            }
            if (!this.Access.authorize(EMaPUIPermission.Billing_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.billingsAwaiting.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain all the loans in a settlement state of the wanted hospital
         * @param hid The [[Id]] of the wanted hospital. If no set, current hospital is used.
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getSettlementsFrom = (hid) => {
            const defer = this.$q.defer();
            if (!hid) {
                hid = this.Access.hospital._id;
            }
            if (!this.Access.authorize(EMaPUIPermission.Billing_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.settlements.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain some loan (see it as 'Pret') made by the wanted hospital
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getLoans = (hid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.MedInst_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.loans.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain some loan (see it as 'Pret') made by the wanted hospital without preparations
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getDrugLoans = (hid) => {
            const defer = this.$q.defer();
            this.getLoans(hid).then((ans) => {
                defer.resolve(ans.filter((loan) => {
                    return loan.loanCategoryCreation != ELoanCat.Prep;
                }));
            });
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain some borrowings (see it as 'Emprunt') made by the wanted hospital without preparations
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getDrugBorrowings = (hid) => {
            const defer = this.$q.defer();
            this.getBorrowings(hid).then((ans) => {
                defer.resolve(ans.filter((loan) => {
                    return loan.loanCategoryCreation != ELoanCat.Prep;
                }));
            });
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array of preparations of the given [[IHospital]]
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getPreparationsFrom = (hid) => {
            const defer = this.$q.defer();
            let res = [];
            const promises = [];
            const borrowingsProcess = () => {
                const d = this.$q.defer();
                this.getBorrowings(hid)
                    .then((ans) => {
                    d.resolve(ans.filter((loan) => {
                        return loan.loanCategoryCreation == ELoanCat.Prep;
                    }));
                })
                    .catch((err) => d.reject(err));
                return d.promise;
            };
            const lendingsProcess = () => {
                const d = this.$q.defer();
                this.getLoans(hid)
                    .then((ans) => {
                    d.resolve(ans.filter((loan) => {
                        return loan.loanCategoryCreation == ELoanCat.Prep;
                    }));
                })
                    .catch((err) => d.reject(err));
                return d.promise;
            };
            if (!this.Access.authorize(EMaPUIPermission.PrepInst_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                promises.push(borrowingsProcess().then((ans) => {
                    res = res.concat(ans);
                }));
                promises.push(lendingsProcess().then((ans) => {
                    res = res.concat(ans);
                }));
                this.$q.all(promises).then(() => defer.resolve(res));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain some borrowings (see it as 'Emprunt') made by the wanted hospital
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getBorrowings = (hid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.MedInst_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.borrowings.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain some Available (see it as 'Mise a disposition') made by the wanted hospital
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getAvailableFrom = (hid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Available_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.available.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]].
         * The array contain some Request (see it as 'Demande') made by the wanted hospital
         * @param hid The [[Id]] of the wanted hospital
         * @returns A promise containing the array of [[ILoan]]
         */
        this.getRequestsFrom = (hid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Request_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.requests.get({ hid: hid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]]
         * The array contains every Available from every hospitals.
         * @returns A promise containing an array of [[ILoan]]
         */
        this.getAvailable = (week = null, includeMyself = false) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Available_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.available.byId.all({ week: week, myselfIncluded: includeMyself }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]]
         * The array contains every Available from a group.
         * @returns A promise containing an array of [[ILoan]]
         */
        this.getAvailableByGroup = (gid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Available_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.available.byGroup.all({ gid: gid }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]]
         * The array contains every Available from every hospitals.
         * @returns A promise containing an array of [[ILoan]]
         */
        this.getLastAvailable = (limit) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Available_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.available.byId.all({ limit: limit }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]]
         * The array contains every Requests from every hospitals.
         * @param week
         * @param includeMyself
         * @returns A promise containing an array of [[ILoan]]
         */
        this.getRequests = (week, includeMyself = false) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Request_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.requests.get({ week: week, myselfIncluded: includeMyself }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]]
         * The array contains every Requests from every hospitals.
         * @returns A promise containing an array of [[ILoan]]
         */
        this.getLastRequests = (limit) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Request_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.requests.get({ limit: limit }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get an array of [[ILoan]]
         * The array contains [[nb]] loans.
         * @returns A promise containing an array of [[ILoan]]
         */
        this.getLastLoans = (hid, nb) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.MedInst_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byHospital.lastLoans.get({ hid: hid, nb: nb }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get a [[ILoan]].
         * @param id The [[Id]] of the loan
         * @returns A promise containing the array of [[ILoan]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.MedInst_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byId.get({ id: id }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add a new [[ILoan]] in dataBase
         * @param loan The [[ILoan]] to create
         * @returns A promise containing the newly [[ILoan]] created
         */
        this.add = (loan) => {
            const defer = this.$q.defer();
            if (!loan) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.MedInst_CreateLoan) && !this.Access.authorize(EMaPUIPermission.MedInst_CreateBorrowing)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byId.add({}, loan, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an existing [[ILoan]] in dataBase
         * @param loan The [[ILoan]] to update
         * @returns A promise containing the status of the request
         */
        this.save = (loan) => {
            const defer = this.$q.defer();
            if (!loan) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.MedInst_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byId.save({ id: loan._id }, loan, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * delete an existing [[ILoan]] in DataBase
         * @param loan The [[ILoan]] to update
         * @returns A promise containing the status of the request
         */
        this.delete = (loan) => {
            const defer = this.$q.defer();
            if (!loan) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.MedInst_Delete)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.byId.delete({ id: loan._id }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add a rollback to an existing [[ILoan]] in dataBase
         * @param loan The [[ILoan]] to update
         * @returns A promise containing the newly rollback [[ILoan]] created
         */
        this.rollback = (loan) => {
            const defer = this.$q.defer();
            if (!loan) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.MedInst_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.loans.rollback.add({ hid: this.Access.hospital._id, id: loan._id }, loan, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Research [[ILabel]]
         * @returns {angular.IPromise<Array<ILabel>>}
         */
        this.getPreparationsLabels = () => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Preparations_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.labels.preparations.all({}, (res) => defer.resolve(res), () => defer.reject);
            }
            return defer.promise;
        };
        /**
         *
         * @param {ILoan} available
         * @returns {angular.IPromise<void>}
         */
        this.saveAvailable = (available) => {
            const defer = this.$q.defer();
            const quantity = available.quantity;
            const takenQuantity = available.takenQuantity;
            if (!this.Access.authorize(EMaPUIPermission.Available_Retake)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                available.loanCategoryCreation = ELoanCat.LoanOrBorrow;
                available.fromMAD = new Date();
                available.takenQuantity = undefined;
                if (quantity == takenQuantity) {
                    this.save(available)
                        .then(() => defer.resolve())
                        .catch((err) => defer.reject(err));
                }
                else if (takenQuantity < quantity) {
                    available.quantity = takenQuantity;
                    this.save(available)
                        .then(() => {
                        available.origin = available._id;
                        available._id = undefined;
                        available.confirmationDate = undefined;
                        available.borrower = undefined;
                        available.borrowingPerson = undefined;
                        available.fromMAD = undefined;
                        available.wasMAD = true;
                        available.loanCategoryCreation = ELoanCat.Available;
                        available.quantity = quantity - takenQuantity;
                        this.add(available).then((ans) => defer.resolve(ans))
                            .catch((err) => defer.reject(err));
                    })
                        .catch((err) => defer.reject(err));
                }
            }
            return defer.promise;
        };
        /**
         *
         * @param {ILoan} request
         * @returns {angular.IPromise<void>}
         */
        this.saveRequest = (request) => {
            const defer = this.$q.defer();
            const quantity = request.quantity;
            const takenQuantity = request.takenQuantity;
            if (!this.Access.authorize(EMaPUIPermission.Request_Retake)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                request.confirmationDate = new Date();
                request.loanCategoryCreation = ELoanCat.LoanOrBorrow;
                request.fromRequest = new Date();
                request.takenQuantity = undefined;
                request.returnExpected = request.requestReturn;
                if (quantity == takenQuantity) {
                    this.save(request)
                        .then(() => defer.resolve())
                        .catch((err) => defer.reject(err));
                }
                else if (takenQuantity < quantity) {
                    request.quantity = takenQuantity;
                    this.save(request)
                        .then(() => {
                        request.origin = request._id;
                        request.loanCategoryCreation = ELoanCat.Request;
                        request._id = undefined;
                        request.confirmationDate = undefined;
                        request.lender = undefined;
                        request.loanPerson = undefined;
                        request.loanComment = undefined;
                        request.fromRequest = undefined;
                        request.returnExpected = undefined;
                        request.quantity = quantity - takenQuantity;
                        this.add(request).then((ans) => defer.resolve(ans))
                            .catch((err) => defer.reject(err));
                    });
                }
            }
            return defer.promise;
        };
    }
}
