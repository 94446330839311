import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MpModalUploadHospitalsFileController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        /**
         *
         */
        this.fileType = '4'; // Default to generic. Why string ?
        /**
         * Close the modal without actions performing
         */
        this.close = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close modal with actions performing
         */
        this.ok = () => {
            if (!this.file)
                this.ModalService.cancel(EMpStatus.NoResult);
            else
                this.ModalService.close({ file: this.file, importOrigin: this.fileType });
        };
        this.processedRows = 0;
        this.matches = [
            {
                key: 'finess',
                translate: 'MAPUI.SERVICES.ADMIN.IMPORT.MATCHES.FINESS',
                require: true,
            },
        ];
    }
    /**
     * Return the percentage of row processed
     * @return {number}
     */
    get processed() {
        if (!this.file) {
            return 0;
        }
        return (this.processedRows / this.file.rows) * 100;
    }
    $onInit() {
    }
}
