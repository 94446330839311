var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UIRouterModule } from '@uirouter/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { SandboxRoutingModule } from './sandbox-routing.module';
import { MatCardModule } from '@angular/material/card';
import { ExSearchMedicalDeviceComponent } from '@app/admin/sandbox/ex-search-medical-device/ex-search-medical-device.component';
import { HsSharedModule } from '@app/hospistock/shared/hs-shared.module';
import { ExDatepickerLimitsComponent } from './dates/ex-datepicker-limits/ex-datepicker-limits.component';
import { ExDatesSelectorComponent } from './dates/ex-dates-selector/ex-dates-selector.component';
import { ExSinceDateMenuComponent } from './dates/ex-since-date-menu/ex-since-date-menu.component';
import { ExChipComponent } from './ex-chip/ex-chip.component';
import { ExDataUsageComponent } from './ex-data-usage/ex-data-usage.component';
import { ExFileDragNDropComponent } from './ex-file-drag-n-drop/ex-file-drag-n-drop.component';
import { ExFileUploaderComponent } from './ex-file-uploader/ex-file-uploader.component';
import { ExFileViewerComponent } from './ex-file-viewer/ex-file-viewer.component';
import { ExGenericDialogComponent } from './ex-generic-dialog/ex-generic-dialog.component';
import { ExKeyDataComponent } from './ex-key-data/ex-key-data.component';
import { ExSearchDrugComponent } from './ex-search-drug/ex-search-drug.component';
import { ExSearchEstablishmentFinessComponent } from './ex-search-establishment-finess/ex-search-establishment-finess.component';
import { ExSearchHospitalComponent } from './ex-search-hospital/ex-search-hospital.component';
import { ExSelectDosageTypeComponent } from './ex-select-dosage-type/ex-select-dosage-type.component';
import { ExSelectPosologyComponent } from './ex-select-posology/ex-select-posology.component';
import { SandboxComponent } from './sandbox.component';
let SandboxModule = class SandboxModule {
};
SandboxModule = __decorate([
    NgModule({
        declarations: [
            SandboxComponent,
            ExDataUsageComponent,
            ExFileDragNDropComponent,
            ExFileUploaderComponent,
            ExSearchHospitalComponent,
            ExSearchEstablishmentFinessComponent,
            ExGenericDialogComponent,
            ExSearchDrugComponent,
            ExSearchMedicalDeviceComponent,
            ExFileViewerComponent,
            ExSelectDosageTypeComponent,
            ExSelectPosologyComponent,
            ExKeyDataComponent,
            ExDatepickerLimitsComponent,
            ExDatesSelectorComponent,
            ExSinceDateMenuComponent,
            ExChipComponent
        ],
        imports: [
            BrowserAnimationsModule,
            BrowserAnimationsModule,
            CommonModule,
            CommonModule,
            FuseDirectivesModule,
            FuseSharedModule,
            FuseSharedModule,
            HsSharedModule,
            MatButtonModule,
            MatCardModule,
            MatDatepickerModule,
            MatDividerModule,
            MatFormFieldModule,
            MatIconModule,
            MatInputModule,
            MatSelectModule,
            MatSlideToggleModule,
            SharedModule,
            UIRouterModule
        ],
        exports: [SandboxRoutingModule]
    })
], SandboxModule);
export { SandboxModule };
