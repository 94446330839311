import { EActivityType } from '@mapuilabs/mpl-interfaces';
export const CActivityTypes = {
    askPrescription: {
        value: EActivityType.AskPrescription,
        translate: 'activities.TYPES.ASK_PRESCRIPTION'
    },
    medReconciliation: {
        value: EActivityType.SendMedReconciliation,
        translate: 'activities.TYPES.MEDREC'
    },
    askPrescriptionOctave: {
        value: EActivityType.AskPrescriptionOctave,
        translate: 'activities.TYPES.ASK_PRESCRIPTION_OCTAVE'
    }
};
