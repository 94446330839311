var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HospivilleRoutingModule } from './hospiville-routing.module';
import { ActivitiesModule } from './activities/activities.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { PatientsModule } from './patients/patients.module';
import { HospivilleToolbarModule } from './toolbar/toolbar.module';
import { HospivilleSessionService } from '@services/hospiville/hospiville-session/hospiville-session.service';
let HospivilleModule = class HospivilleModule {
};
HospivilleModule = __decorate([
    NgModule({
        declarations: [],
        imports: [CommonModule, ActivitiesModule, ConfigurationModule, PatientsModule, HospivilleToolbarModule],
        exports: [HospivilleRoutingModule],
        providers: [HospivilleSessionService]
    })
], HospivilleModule);
export { HospivilleModule };
