var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as angular from "angular";
import "angular-material";
import { EActivityStatus, EActivityType, EHospivillePermission, EMpStatus, EPathways, EPatientConsent, ESource, EStatusIndicator } from "@mapuilabs/mpl-interfaces";
import { Utils } from "@main/services/Utils";
export class HvDrugBSController {
    /** @ngInject */
    constructor(Api, Access, _, ModalService, $scope, $state, $translate, $mdDialog, ToastService, $mdBottomSheet, HospivilleService, ActivitiesService, MailsService, MemberService, SourceService, InformationService, DrugInstanceService, DrugBalanceService, Auth, $q) {
        this.Api = Api;
        this.Access = Access;
        this._ = _;
        this.ModalService = ModalService;
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$mdDialog = $mdDialog;
        this.ToastService = ToastService;
        this.$mdBottomSheet = $mdBottomSheet;
        this.HospivilleService = HospivilleService;
        this.ActivitiesService = ActivitiesService;
        this.MailsService = MailsService;
        this.MemberService = MemberService;
        this.SourceService = SourceService;
        this.InformationService = InformationService;
        this.DrugInstanceService = DrugInstanceService;
        this.DrugBalanceService = DrugBalanceService;
        this.Auth = Auth;
        this.$q = $q;
        /**
         * @type {EStatusIndicator}
         */
        this.EStatusIndicator = EStatusIndicator;
        /**
         * Enum containing all types of sources
         */
        this.ESource = ESource;
        /**
         * Flag for mail icon loader
         */
        this.isLoading = false;
        /**
         * Select a [[IDrugInstance]] from medUCD search
         * @param drug
         * @param pharmaForm
         * @param inst
         * @param index
         * @param nbr
         */
        this.selectDrugFor = ({ drug, pharmaForm }, inst, index, nbr = 0) => {
            Utils.assign(inst, drug);
            if (this._.find(this.constTypes.dosages, { value: +pharmaForm }))
                for (let src of inst.sources) {
                    if (!src.dosageType && pharmaForm) {
                        src.dosageType = +pharmaForm;
                        if (src.dosageType == 14) {
                            src.dosageDropInfo = {
                                left: 0,
                                right: 0
                            };
                        }
                    }
                }
            if (nbr == 1)
                this.drugBalance.unSelectInstances.splice(index, 1, angular.copy(inst));
            else
                this.drugBalance.instances.splice(index, 1, angular.copy(inst));
        };
        /**
         * Add a column to the [[IDrugBalance]] table
         */
        this.addColumn = () => {
            this._isChange = true;
            this.ready = true;
            this.ModalService.show({
                component: 'hv-modal-drug-balance-add-source',
                bindings: {
                    patientSources: this.patient.sources,
                    dbsSources: this.drugBalance.sources
                },
                escapeToClose: true
            })
                .then((source) => {
                this.drugBalance.sources.push(angular.copy(source));
                for (const instance of this.drugBalance.instances) {
                    if (!instance.sources) {
                        instance.sources = [];
                    }
                    instance.sources.push({
                        posologies: [],
                        dosage: null,
                        dosageType: null,
                        source: { _id: source.source._id }
                    });
                }
            })
                .catch(err => {
                if (err != EMpStatus.CloseByUser)
                    this.ToastService.show(err);
            });
        };
        /**
         * Add a column to the [[IDrugBalance]] table
         */
        this.addPastMedRec = () => {
            this._isChange = true;
            this.ready = true;
            this.ModalService.show({
                component: 'hv-modal-drug-balance-add-past-med-rec',
                bindings: {
                    medReconciliation: this.medReconciliation
                },
                escapeToClose: true
            }).then((res) => {
                this.SourceService.add({ type: this.ESource.PreviousMedRec, date: res.mrDate }).then((nwSrc) => {
                    this.drugBalance.sources.push({ source: nwSrc, date: res.mrDate });
                    if (!this.drugBalance.instances)
                        this.drugBalance.instances = [];
                    this.drugBalance.instances = this.DrugInstanceService.mergeDIArrAndSIArr(res.instances, this.drugBalance.instances, nwSrc);
                    this.drugBalance = this.HospivilleService._adaptDrugBalanceForDBS(this.drugBalance);
                    this.initList();
                    for (const indexInstance in this.drugBalance.instances) {
                        if (this.drugBalance.instances.hasOwnProperty(indexInstance)) {
                            this.calcConcordance(+indexInstance, this.drugBalance.instances[indexInstance]);
                        }
                    }
                });
            }).catch((err) => {
                if (err != EMpStatus.CloseByUser)
                    this.ToastService.show(err);
            });
        };
        /**
         * Add (a) line(s) to the [[IDrugBalance]] table
         */
        this.addLine = (nbr = 0) => {
            this._isChange = true;
            const newInstance = {
                index: this.HospivilleService.getMaxIndex() + 1,
                sources: [],
                listSrc: []
            };
            for (const source of this.drugBalance.sources) {
                newInstance.sources.push({
                    posologies: [],
                    dosage: null,
                    dosageType: null,
                    source: { _id: source.source._id }
                });
            }
            if (nbr == 1) {
                this.drugBalance.unSelectInstances.push(newInstance);
            }
            else {
                this.drugBalance.instances.push(newInstance);
            }
        };
        /**
         * Select a [[IDrugInstanceSource]] for a [[IDrugInstance]]selectS from a [[IDrugBalanceSource]]
         * @param inst
         * @param source
         * @param selectAll if it's true -> select all without distinction
         * @param keyInst the key of the IDrugInstance in th IDrugBalance
         */
        this.selectSource = (inst, source, selectAll, keyInst) => {
            this._isChange = true;
            for (let elem of inst.sources) {
                if (Utils.compareIds(elem.source, source) && elem.posologies.length) {
                    if (!elem.selected || selectAll) {
                        elem.selected = true;
                        inst.validSource = angular.copy(elem);
                    }
                    else {
                        elem.selected = false;
                        inst.validSource = null;
                    }
                }
                else {
                    elem.selected = false;
                }
            }
            this.calcConcordance(keyInst, inst);
        };
        /**
         * Select a [[IDrugInstanceSource]] for all [[IDrugInstance]]s from a [[IDrugBalanceSource]]
         * @param source
         */
        this.selectAllDrugFromSource = (source) => {
            let selector = false;
            for (const inst of this.drugBalance.instances)
                for (let elem of inst.sources)
                    if (Utils.compareIds(elem.source, source) && elem.posologies.length && !elem.selected)
                        selector = true;
            for (const key in this.drugBalance.instances) {
                if (this.drugBalance.instances.hasOwnProperty(key)) {
                    const inst = this.drugBalance.instances[key];
                    this.selectSource(inst, source, selector, +key);
                }
            }
        };
        /**
         * Duplicate a [[IDrugInstanceSource]] to an other one
         * @param inst
         * @param source
         * @param srcToDuplicate
         * @param indexInstance
         */
        this.duplicateSource = (inst, source, srcToDuplicate, indexInstance) => {
            this._isChange = true;
            let instSrc = {};
            for (const elem of inst.sources) {
                if (Utils.compareIds(srcToDuplicate, elem.source)) {
                    instSrc = angular.copy(elem);
                    break;
                }
            }
            for (let elem of inst.sources) {
                if (Utils.compareIds(source, elem.source)) {
                    elem.dosage = instSrc.dosage;
                    elem.dosageDropInfo = instSrc.dosageDropInfo;
                    elem.dosageType = instSrc.dosageType;
                    elem.posologies = instSrc.posologies;
                    if (elem.isDispensaryPharmacy != undefined)
                        elem.isDispensaryPharmacy = false;
                    break;
                }
            }
            this.calcConcordance(indexInstance, inst);
        };
        /**
         * Delete a [[IDrugInstance]] from the [[IDrugBalance]]
         * @param inst
         * @param index
         */
        this.deleteInst = (inst, index) => {
            if (!Utils.hasId(inst)) {
                this.drugBalance.instances.splice(index, 1);
                this.generatedConcordance.splice(index, 1);
            }
            else {
                this.HospivilleService.deleteDrugInst(inst)
                    .then(() => {
                    this.drugBalance.instances.splice(index, 1);
                    this.ToastService.show(EMpStatus.Ok);
                    this.generatedConcordance.splice(index, 1);
                    this._stopDrugBalanceWatch();
                    this.dbsForm.$pristine = false;
                    this._resetStatus();
                    this._startDrugBalanceWatch();
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            }
        };
        this.deleteUnselectInst = (inst, index) => {
            if (!Utils.hasId(inst)) {
                this.drugBalance.unSelectInstances.splice(index, 1);
            }
            else {
                this.HospivilleService.deleteDrugInst(inst)
                    .then(() => {
                    this.drugBalance.unSelectInstances.splice(index, 1);
                    this.ToastService.show(EMpStatus.Ok);
                    this._stopDrugBalanceWatch();
                    this.dbsForm.$pristine = false;
                    this._resetStatus();
                    this._startDrugBalanceWatch();
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            }
        };
        /**
         * Check if the current [[IPatient]] is a reference to the current [[ISource]]
         * @returns {boolean}
         * @private
         */
        this.isReference = (references) => {
            return this.SourceService.isReference(references);
        };
        /**
         * Delete an entire [[IDrugBalanceSource]]
         * @param source
         * @param index
         */
        this.deleteSource = (source, index) => {
            this._isChange = true;
            if (this.drugBalance.sources.length == 1) {
                for (let i = 0; i < this.drugBalance.instances.length; ++i) {
                    this.deleteInst(this.drugBalance.instances[i], i);
                }
                this.drugBalance.instances = [];
            }
            else {
                for (let inst of this.drugBalance.instances) {
                    if (inst.validSource && Utils.compareIds(inst.validSource.source, source.source)) {
                        inst.validSource = null;
                    }
                    let instSrc = [];
                    for (const src of inst.sources) {
                        if (!Utils.compareIds(source.source, src.source)) {
                            instSrc.push(src);
                        }
                    }
                    inst.sources = instSrc;
                }
            }
            for (const src of this.drugBalance.sources) {
                if (Utils.compareIds(src.source, source.source)) {
                    this.drugBalance.sources.splice(index, 1);
                }
            }
            this._stopDrugBalanceWatch();
            this.dbsForm.$pristine = false;
            this._resetStatus();
            this._startDrugBalanceWatch();
        };
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            var _a;
            if (!this.constTypes || (!value && value !== 0))
                return;
            const translation = (_a = this._.find(this.constTypes[from], { value: value })) === null || _a === void 0 ? void 0 : _a.translate;
            if (!translation)
                return;
            return translation;
        };
        /**
         * Calculate how many sources are similar
         * @param indexInstance
         * @param inst
         * @returns {number}
         */
        this.calcConcordance = (indexInstance, inst) => {
            if (inst.sources.length === 1) {
                this.generatedConcordance[indexInstance] = 0;
                return;
            }
            const srcCopy = angular.copy(inst.sources);
            let concordanceNbr = [];
            let index = 0;
            let max;
            if (inst.validSource && inst.validSource.source && inst.validSource.source._id === 'concordance') {
                inst.validSource = null;
            }
            for (const cpSrc of srcCopy) {
                concordanceNbr[index] = 0;
                for (const src of inst.sources) {
                    let same = this.DrugInstanceService.compareDosages(cpSrc, src)
                        && this.DrugInstanceService.comparePosologiesArray(cpSrc.posologies, src.posologies);
                    let empty = (src.dosage == null && !src.dosageDropInfo) || src.dosageType == null || !src.posologies
                        || (src.dosageDropInfo && src.dosageDropInfo.right == 0 && src.dosageDropInfo.left == 0);
                    if (!empty && same) {
                        concordanceNbr[index]++;
                        if (concordanceNbr[index] == 3 && !inst.validSource) {
                            inst.validSource = angular.copy(cpSrc);
                            inst.validSource.source._id = 'concordance';
                        }
                    }
                }
                index++;
            }
            max = Math.max(...concordanceNbr);
            this.generatedConcordance[indexInstance] = this._.countBy(concordanceNbr, (val) => {
                return val == 0 ? 'empty' : 'filled';
            })['empty'] == concordanceNbr.length - 1 ? 0 : (max > 3) ? 3 : max;
        };
        /**
         * Show [[IDrugBalance]] synthesis
         */
        this.showSynthesis = () => {
            this.$mdBottomSheet.show({
                template: require('./hv-drug-balance-sheet-synthesis/hv-drug-balance-sheet-synthesis.html').default,
                controller: 'HvDrugBalanceSheetSynthesisController',
                controllerAs: 'vm',
                parent: angular.element(document.getElementsByTagName('body')),
                bindToController: true,
                locals: {
                    drugBalance: this.drugBalance
                }
            }).then((result) => {
                this.HospivilleService.saveDrugBalance(result)
                    .then((savedDBS) => {
                    this.drugBalance = savedDBS;
                    this.ToastService.show(EMpStatus.Ok);
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            });
        };
        /**
         * Set given [[IDrugInstanceSource]] posology
         * @param posology
         * @param instSource
         * @param instance
         * @param instanceIndex
         */
        this.posologyChange = ({ posologies }, instSource, instance, instanceIndex) => {
            instSource.posologies = posologies;
            if (instSource.isDispensaryPharmacy != undefined)
                instSource.isDispensaryPharmacy = false;
            this.calcConcordance(instanceIndex, instance);
        };
        /**
         * Set given [[IDrugInstanceSource]] dosage
         * @param {number} quantity
         * @param {number} type
         *  @param {IDropInfo} dropInfo
         * @param {IDrugInstanceSource} instSource
         * @param instance
         * @param instanceIndex
         */
        this.dosageChange = ({ quantity, type, dropInfo }, instSource, instance, instanceIndex) => {
            instSource.dosage = quantity;
            instSource.dosageType = type;
            if (instSource.isDispensaryPharmacy != undefined)
                instSource.isDispensaryPharmacy = false;
            if (instSource.dosageType == 14) {
                instSource.dosageDropInfo = dropInfo;
            }
            this.calcConcordance(instanceIndex, instance);
        };
        /**
         * Check if a given [[IDrugInstance]] is in conflict with [[IPatient]] allergies
         * @param {IDrugInstance} inst
         * @returns {boolean}
         */
        this.isAllergy = (inst) => {
            if (!inst.codeATC || !this.patient.allergies)
                return false;
            return !!this._.find(this.patient.allergies, (allergy) => {
                if (!allergy.codeATC)
                    return false;
                return inst.codeATC.substring(0, 4) === allergy.codeATC.substring(0, 4);
            });
        };
        /**
         * Get time from milliseconds to days hours minutes seconds
         * @param time
         * @returns {string}
         */
        this.getTimeDiff = (time) => {
            let x = time / 1000;
            const seconds = Math.floor(x % 60);
            x /= 60;
            const minutes = Math.floor(x % 60);
            x /= 60;
            const hours = Math.floor(x % 24);
            x /= 24;
            const days = Math.floor(x);
            return (days ? days + 'j ' : '') + (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm ' : '') + (seconds ? seconds + 's' : '');
        };
        /**
         * Save the [[IDrugBalance]]
         */
        this.save = () => {
            var _a, _b, _c, _d;
            const defer = this.$q.defer();
            this._isSave = true;
            this._isChange = false;
            this._stopDrugBalanceWatch();
            for (let inst of this.drugBalance.instances) {
                for (const src of inst.sources) {
                    if (src.selected) {
                        inst.validSource = angular.copy(src);
                        break;
                    }
                    //this.calcConcordance(indexInstance, inst);
                }
            }
            if ((_b = (_a = this.drugBalance) === null || _a === void 0 ? void 0 : _a.unSelectInstances) === null || _b === void 0 ? void 0 : _b.length) {
                let i = (_d = (_c = this.drugBalance) === null || _c === void 0 ? void 0 : _c.unSelectInstances) === null || _d === void 0 ? void 0 : _d.length;
                while (i--) {
                    if (this.drugBalance.unSelectInstances[i].denomination == null ||
                        this.drugBalance.unSelectInstances[i].denomination == '') {
                        this.drugBalance.unSelectInstances.splice(i, 1);
                    }
                }
            }
            if (!this.drugBalance.time)
                this.drugBalance.time = 0;
            this.drugBalance.time += Math.abs((new Date()).getTime() - this._time.getTime());
            if (this.drugBalance.statusIndicator !== EStatusIndicator.Validate
                || (this.drugBalance.statusIndicator === EStatusIndicator.Validate && this.drugBalance.validator)) {
                this.drugBalance.update = {
                    date: new Date(),
                    user: { _id: Utils.getId(this.Access.user), fullName: this.Access.user.fullName }
                };
            }
            this.HospivilleService.saveDrugBalance(this.drugBalance, true)
                .then((savedDBS) => {
                if ((this.drugBalance.statusIndicator === EStatusIndicator.Validate && this.medReconciliation.statusIndicator === EStatusIndicator.Validate) &&
                    this.Access.isInterfaced() && this.medReconciliation.initiator)
                    this.HospivilleService.exportMedRecStatus(Utils.getId(this.medReconciliation), this.EStatusIndicator.Validate, 'update');
                this.dbsForm.$setPristine();
                this.drugBalance = savedDBS;
                this._dugBalanceCopy = angular.copy(this.drugBalance);
                this.initList();
                this._startDrugBalanceWatch();
                this._time = new Date();
                this.ToastService.show(EMpStatus.Ok);
                defer.resolve();
            })
                .catch(() => {
                this._isSave = false;
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Init list to feet list view
         */
        this.initList = () => {
            var _a;
            if ((_a = this.drugBalance) === null || _a === void 0 ? void 0 : _a.instances)
                for (let inst of this.drugBalance.instances) {
                    inst.listSrc = [];
                    for (let index = 0; index < inst.sources.length; ++index) {
                        let same = false;
                        if ((!inst.sources[index].dosage && inst.sources[index].dosage != 0 && !inst.sources[index].dosageDropInfo)
                            || (inst.sources[index].dosageType == null || inst.sources[index].dosageType == undefined) || !inst.sources[index].posologies)
                            continue;
                        for (let i = 0; i < index; ++i) {
                            same = this.DrugInstanceService.compareDosages(inst.sources[i], inst.sources[index])
                                && this.DrugInstanceService.comparePosologiesArray(inst.sources[i].posologies, inst.sources[index].posologies);
                            if (same) {
                                break;
                            }
                        }
                        if (!same) {
                            let tmp = { instSrc: angular.copy(inst.sources[index]), sources: [] };
                            for (let source of inst.sources) {
                                let same = this.DrugInstanceService.compareDosages(source, inst.sources[index])
                                    && this.DrugInstanceService.comparePosologiesArray(source.posologies, inst.sources[index].posologies);
                                if (same) {
                                    if (source.selected) {
                                        tmp.instSrc.selected = true;
                                    }
                                    tmp.sources.push(source.source._id);
                                }
                            }
                            inst.listSrc.push(tmp);
                        }
                    }
                }
        };
        /**
         * Values change on list view
         * @param {IDrugInstance} inst
         * @param indexInstance
         */
        this.valueChange = (inst, indexInstance) => {
            for (let fromSource of inst.sources) {
                let sameSrc;
                for (let fromList of inst.listSrc) {
                    sameSrc = this._.find(fromList.sources, (src) => {
                        return src === fromSource.source._id;
                    });
                    if (sameSrc) {
                        fromSource.dosage = fromList.instSrc.dosage;
                        fromSource.dosageDropInfo = fromList.instSrc.dosageDropInfo;
                        fromSource.dosageType = fromList.instSrc.dosageType;
                        fromSource.posologies = fromList.instSrc.posologies;
                        break;
                    }
                }
                if (!sameSrc) {
                    fromSource.dosage = null;
                    fromSource.dosageDropInfo = null;
                    fromSource.dosageType = null;
                    fromSource.posologies = null;
                }
            }
            this.calcConcordance(indexInstance, inst);
        };
        /**
         * Selected source changed on list view
         * @param {IDrugInstance} inst
         * @param instList
         * @param {number} index
         * @param indexInstance
         */
        this.sourceChange = (inst, instList, index, indexInstance) => {
            if (((instList[index].instSrc.dosage || instList[index].instSrc.dosageDropInfo) && instList[index].instSrc.dosageType
                && instList[index].instSrc.posologies) && !instList[index].sources.length) {
                instList.splice(index, 1);
            }
            if (instList[index]) {
                for (let src of instList[index].sources) {
                    for (let i = 0; i < instList.length; ++i) {
                        if (i !== index && this._.find(instList[i].sources, (source) => source === src)) {
                            instList[i].sources = this._.without(instList[i].sources, src);
                            if (!instList[i].sources.length) {
                                instList.splice(i, 1);
                            }
                        }
                    }
                }
            }
            this.valueChange(inst, indexInstance);
        };
        /**
         * Add source to list
         * by catching the index of the drugbalance instance we get the datastucture of the drug
         * @param listSrc
         * @param index
         */
        this.addSrc = (listSrc, index) => {
            const elem = angular.copy(this.drugBalance.instances[index].sources[0]);
            elem.posologies = [];
            elem.dosage = null;
            if (elem.dosageDropInfo) {
                elem.dosageDropInfo.left = 0;
                elem.dosageDropInfo.right = 0;
            }
            listSrc.push({
                instSrc: {
                    posologies: elem.posologies,
                    dosage: elem.dosage,
                    dosageType: elem.dosageType,
                    dosageDropInfo: elem.dosageDropInfo
                }, sources: elem.source
            });
        };
        /**
         * Change source selection on list view
         * @param listSrc
         * @param src
         */
        this.selectSourceListChange = (listSrc, src) => {
            for (let elem of listSrc) {
                if (!Utils.compareIds(elem.instSrc.source, src.source))
                    elem.instSrc.selected = false;
            }
            src.selected = !src.selected;
        };
        /**
         * Duplicate from an existing [[IDrugBalance]]
         */
        this.duplicateDBS = () => {
            this.ModalService.show({
                component: 'hv-modal-duplicate-drug-balance',
                bindings: {
                    currentDbsId: this.drugBalance._id
                },
                escapeToClose: true
            }).then((dbsToDuplicate) => {
                this.drugBalance.instances = angular.copy(dbsToDuplicate.instances);
                this.drugBalance.sources = angular.copy(dbsToDuplicate.sources);
                for (let key in this.drugBalance.instances) {
                    if (this.drugBalance.instances.hasOwnProperty(key)) {
                        const inst = this.drugBalance.instances[key];
                        delete inst._id;
                        this.calcConcordance(+key, inst);
                    }
                }
                this.save();
                this.ToastService.show(EMpStatus.Ok);
            });
        };
        this.changeStatusIndicator = (status) => {
            this._stopDrugBalanceWatch();
            this.drugBalance.statusIndicator = status;
            this._startDrugBalanceWatch();
        };
        /**
         * Init drug balance sheet
         * @private
         */
        this._initDrugBalance = () => {
            this.ready = false;
            this._isSave = true;
            this._isChange = false;
            if (angular.isFunction(this._stopDrugBalanceWatch))
                this._stopDrugBalanceWatch();
            this.drugBalance = null;
            if (this.HospivilleService.hasPatient) {
                const promises = [];
                promises.push(this.HospivilleService.getPatient()
                    .then((res) => this.patient = res)
                    .catch((err) => this.ToastService.show(err)));
                promises.push(this.HospivilleService.getDBSForDBSView()
                    .then((drugBalanceSheet) => {
                    var _a;
                    this.drugBalance = drugBalanceSheet;
                    this.initList();
                    this._startDrugBalanceWatch();
                    if ((_a = this.drugBalance) === null || _a === void 0 ? void 0 : _a.instances) {
                        for (const indexInstance in this.drugBalance.instances) {
                            if (this.drugBalance.instances.hasOwnProperty(indexInstance))
                                this.calcConcordance(+indexInstance, this.drugBalance.instances[indexInstance]);
                        }
                    }
                })
                    .catch((err) => this.ToastService.show(err)));
                this.$q.all(promises).then(() => {
                    this.ready = true;
                })
                    .catch((err) => console.error(err));
            }
        };
        /**
         * To start the watcher on drug balance
         * @private
         */
        this._startDrugBalanceWatch = () => {
            this._stopDrugBalanceWatch = this.$scope.$watch('vm.drugBalance', (newValue, oldValue) => {
                if (newValue !== oldValue)
                    this._resetStatus();
            }, true);
        };
        /**
         * TO reset the drug balance status
         * @private
         */
        this._resetStatus = () => {
            if (this.dbsForm.$pristine)
                return;
            if (this.drugBalance.statusIndicator == EStatusIndicator.Validate) {
                this.drugBalance.statusIndicator = EStatusIndicator.Ongoing;
                this.drugBalance.validator = null;
            }
        };
        this.diService = this.DrugInstanceService;
        this.currentView = 'table';
        this._time = new Date();
        this.$scope.$on('refreshPatientFile', () => {
            this._initDrugBalance();
        });
        this.isAuthorised = this.Access.authorize(EHospivillePermission.DBS_Validate);
        this.migrationState = this.Access.isHVMigrationState();
        if (!this.migrationState) {
            this.$scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
                if (!this.dbsForm || (this.dbsForm && this.dbsForm.$pristine)) {
                    this._isSave = true;
                }
                if (!this._isSave || this._isChange) {
                    this._isSave = true;
                    this._isChange = false;
                    event.preventDefault();
                    this.ModalService.show({
                        component: 'mp-modal-confirm',
                        bindings: {
                            data: {
                                title: 'Sauvegarder',
                                text: 'Voulez-vous enregister les modifications apportées ? '
                            }
                        },
                        escapeToClose: false,
                        clickOutsideToClose: false
                    }).then(() => {
                        this.save().then(() => {
                            this.$state.go(toState, toParams);
                        }).catch(() => {
                            this.ToastService.show(EMpStatus.RejectByServer);
                        });
                    }).catch(() => {
                        this.$state.go(toState, toParams);
                    });
                }
            });
        }
        this.generatedConcordance = [];
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => this.constTypes = ans);
        this.HospivilleService.getMedRec(true)
            .then((medRec) => {
            this.medReconciliation = medRec;
        }).catch((err) => this.ToastService.show(err));
        // Get staff from the current hospital
        this.MemberService.getByHosp(this.Access.hospital._id)
            .then((members) => this.members = members);
        this._initDrugBalance();
        this.getActivityForCurrentMedRec();
    }
    hasSeveralMedRecs() {
        var _a, _b, _c, _d;
        return ((_b = (_a = this.patient) === null || _a === void 0 ? void 0 : _a.backupMedRecs) === null || _b === void 0 ? void 0 : _b.length) > 0
            || ((_d = (_c = this.patient) === null || _c === void 0 ? void 0 : _c.medicationReconciliations) === null || _d === void 0 ? void 0 : _d.length) > 1;
    }
    canAccessPreviousMedRec() {
        return this.Auth.authorize(EHospivillePermission.PreviousMedRec_Read);
    }
    /**
     * Get activity for the patient's current MedRec and activate circular loader (isLoading)
     */
    getActivityForCurrentMedRec() {
        this.isLoading = true;
        this.ActivitiesService.findOpenByMedRecId(this.Access.patient.medicationReconciliation._id)
            .then((res) => {
            this.activities = res.data;
            this.isLoading = false;
        })
            .catch((err) => {
            this.isLoading = false;
            this.ToastService.show(err);
        });
    }
    getSourceActivity(sourceFiness) {
        var _a;
        return (_a = this.activities) === null || _a === void 0 ? void 0 : _a.find((act) => act.dest.finessCode === sourceFiness);
    }
    /**
     * Check if activity is open
     */
    hasActivityOpen(sourceFiness) {
        if (!this.getSourceActivity(sourceFiness)) {
            return false;
        }
        return this.getSourceActivity(sourceFiness).status !== EActivityStatus.Ended
            && this.getSourceActivity(sourceFiness).status !== EActivityStatus.Canceled
            && this.getSourceActivity(sourceFiness).status !== EActivityStatus.MedRec;
    }
    getActivityStatusTranslation(status) {
        const translation = this.constTypes.activityStatus.find((actStat) => actStat.value === status);
        return this.$translate.instant(translation === null || translation === void 0 ? void 0 : translation.translate);
    }
    goToActivityDetails(sourceFiness) {
        if (!this.getSourceActivity(sourceFiness)) {
            return;
        }
        this.$state.go('hospivilleV2.activities.source.details', { id: this.getSourceActivity(sourceFiness)._id });
    }
    /**
     * Check if selected patient hs agreed to share information with other establishments
     */
    get hasAgreed() {
        var _a, _b;
        return ((_b = (_a = this.patient) === null || _a === void 0 ? void 0 : _a.consent) === null || _b === void 0 ? void 0 : _b.status) === EPatientConsent.OK;
    }
    /**
     * Open modal to compose a mail
     */
    composeMail(src) {
        return __awaiter(this, void 0, void 0, function* () {
            let mail = {};
            let res;
            let selectedPathway = 'classic';
            this.isLoading = true;
            try {
                mail.subject = this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.MAIL_SUBJECT');
                const canSend = yield this.ActivitiesService.canSendPrescriptionMail();
                if (!canSend) {
                    return this.$mdDialog.show(this.$mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.ACTIVITY_ALREADY_EXISTS.TITLE'))
                        .textContent(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.ACTIVITY_ALREADY_EXISTS.MESSAGE'))
                        .ok(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ACTIONS.OK')));
                }
                if (this.patient.pathways.includes(EPathways.Octave)) {
                    if (this.Auth.isBothOctaveAndPremium) {
                        selectedPathway = yield this.ModalService.show({
                            component: 'mp-modal-activity-pathway-picker',
                        });
                    }
                    if (this.Auth.isOnlyOctave) {
                        selectedPathway = EPathways.Octave;
                    }
                }
                if (selectedPathway === EPathways.Octave) {
                    mail.subject = this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.MAIL_SUBJECT_OCTAVE');
                }
                res = yield this.ModalService.show({
                    component: 'mp-modal-compose-mail',
                    bindings: {
                        pathway: selectedPathway,
                        selectedRecipient: src.establishment,
                        mail: mail,
                        hidden: { CC: true, BCC: true, attachments: true, secureSwitch: false },
                        disable: { subject: true, body: true },
                        secure: true,
                        bodyTemplate: 'INFOS_ASKING',
                        data: { patient: this.patient, medRec: this.HospivilleService.getMedRec() },
                    },
                    escapeToClose: true,
                });
            }
            catch (err) {
                this.isLoading = false;
                if (err !== EMpStatus.CloseByUser) {
                    this.ToastService.show(err);
                }
                return;
            }
            if (res.firstName) {
                yield this.MailsService.sendMssRequest(res);
                this.ToastService.show(EMpStatus.Ok);
                this.isLoading = false;
                return;
            }
            let request;
            switch (selectedPathway) {
                case EPathways.Octave:
                    request = this.MailsService.sendPrescriptionRequest(res.mail, res.isSecure, src.establishment, EActivityType.AskPrescriptionOctave);
                    break;
                default:
                    request = this.MailsService.sendPrescriptionRequest(res.mail, res.isSecure, src.establishment);
            }
            request.then(() => {
                this.ToastService.show(EMpStatus.Ok);
                this.getActivityForCurrentMedRec(); // Circular loader stops in this function
                this.isLoading = false;
            }).catch((err) => {
                this.isLoading = false;
                this.openAlertComposeMail(err);
            });
        });
    }
    /**
     * Open alert window if anything went wrong while sending mail (server error)
     * @param err
     */
    openAlertComposeMail(err) {
        const translations = this.ActivitiesService.setTextAlertComposeMail(err);
        this.$mdDialog.show(this.$mdDialog.alert()
            .clickOutsideToClose(true)
            .title(this.$translate.instant(translations.alertTitle))
            .textContent(this.$translate.instant(translations.alertMessage))
            .ok(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ACTIONS.OK')));
    }
    downloadPDF() {
        this.HospivilleService.saveDrugBalance(this.drugBalance, true)
            .then((savedDBS) => {
            this.dbsForm.$setPristine();
            this.drugBalance = savedDBS;
            this._dugBalanceCopy = angular.copy(this.drugBalance);
            this.DrugBalanceService.generatePdf(this.drugBalance._id, this.currentView, this.patient);
        })
            .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
    }
}
