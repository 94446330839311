export const locale = {
    lang: 'fr',
    data: {
        PURCHASE_GROUPS: {
            TITLE: "Groupements d'achat",
            SEARCH: "Rechercher un groupement d'achat",
            TABLE: {
                NAME: 'Nom',
                NBR_ACCOUNTS: 'Comptes',
                ROLES: 'Rôles'
            },
            DETAILS: {
                INFORMATIONS: {
                    TITLE: 'Informations',
                    NAME: "Nom du groupement d'achat"
                },
                PERMISSIONS: {
                    TITLE: 'Actions / Permissions',
                    ROLE_MAPUI: 'Rôle MaPUI',
                    ROLE_HOSPIVILLE: 'Rôle Hospiville',
                    ROLE_HOSPISTOCK: 'Rôle Hospistock',
                    ROLE_WORKSPACE: 'Rôle Workspace',
                    LOG_AS: {
                        LONG: `Se connecter dans le groupement d'achat`,
                        SHORT: `Se connecter`
                    },
                    DELETE: {
                        LONG: `Supprimer le groupement d'achat`,
                        SHORT: `Supprimer`
                    },
                    CONFIRM_LOGAS: "Voulez-vous vraiment vous logguer à ce groupement d'achat ?",
                    CONFIRM_DELETE: `Voulez-vous vraiment supprimer ce groupement d'achat ?`,
                    SNACK_DELETE: `Le groupement d'achat a été supprimé.`
                },
                MEMBERS: {
                    TITLE: 'Membres',
                    NO_MEMBERS: "Ce groupement d'achat n'a aucun membre.",
                    TABLE: {
                        NAME: 'Nom',
                        EMAIL: 'Mail',
                        LAST_CONNECT: 'Dernière connexion'
                    }
                }
            }
        }
    }
};
