export const locale = {
    lang: 'fr',
    data: {
        ARCHIVED_PATIENTS: {
            TITLE: 'Patients archivés',
            SEARCH: 'Rechercher un patient archivé',
            TABLE: {
                FULLNAME: 'Nom',
                EMPTY: 'Aucun patient archivé'
            }
        }
    }
};
