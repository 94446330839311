import { EMpStatus } from "@mapuilabs/mpl-interfaces";
/** @ngInject */
export class RegisterPharmacistController {
    constructor($stateParams, Auth, $state, ToastService) {
        this.$stateParams = $stateParams;
        this.Auth = Auth;
        this.$state = $state;
        this.ToastService = ToastService;
        this.register = () => {
            this.Auth.registerPharmacy(this.jwt, this.email).then((member) => {
                this.$state.go("mapui.pages_auth_login-v2", { email: member.email, valid: member.validMail });
            }).then((err) => {
                console.error(err);
            }).catch((err) => {
                if (err.data.match(/Email '(.*)' already assigned/)) {
                    this.ToastService.show(EMpStatus.EmailAlreadyInUSe);
                }
                else {
                    this.ToastService.show(EMpStatus.RejectByServer);
                }
            });
        };
        this.firstName = this.$stateParams.firstName;
        this.lastName = this.$stateParams.lastName;
        this.pharmacy = this.$stateParams.pharmacy;
        this.jwt = this.$stateParams.jwt;
    }
}
