var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@services/translation/translation.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AvailableRequestService } from '../available-request.service';
import { fuseAnimations } from '@fuse/animations';
import { locale as french } from '../../../../i18n/fr';
import { locale as english } from '../../../../i18n/en';
import { ModalInterestInformationComponent } from './modal-interest-information/modal-interest-information.component';
import { EAvailableStatus, EInterest } from '@mapuilabs/mpl-interfaces';
import { IdentityService } from '@services/identity/identity.service';
import { HospitalService } from '@services/hospital/hospital.service';
import { CDrugPreservation } from '@shared/constTypes/availables/drugPreservation.const';
import { Utils } from '@main/services/Utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
let AvailableDetailComponent = class AvailableDetailComponent {
    constructor(_availableService, _fuseTranslationLoaderService, _fuseSidebarService, _matDialog, _translationService, snackbarService, _identityService, hospitalService) {
        this._availableService = _availableService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseSidebarService = _fuseSidebarService;
        this._matDialog = _matDialog;
        this._translationService = _translationService;
        this.snackbarService = snackbarService;
        this._identityService = _identityService;
        this.hospitalService = hospitalService;
        this.interest = EInterest;
        this.cdrugPreservation = CDrugPreservation;
        this.unsubscribe = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        this.dynamicDataContainerConfig = {
            noData: {
                icon: 'shopping_cart',
                label: this._translationService.instant('AVAILABLE.DETAILS.NO_SELECTED_AVAILABLE')
            }
        };
    }
    ngOnInit() {
        this._availableService.onCurrentAvailableChangedFromList
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((currentAvailable) => {
            this.availableAndOffers = currentAvailable;
            if (currentAvailable === null || currentAvailable === void 0 ? void 0 : currentAvailable.offers) {
                this.offer = this.availableAndOffers.offers.find((offer) => Utils.compareIds(offer.hospital, this._identityService.hospital));
            }
            this.getHospitalDistance();
        });
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
    openDialogInterest() {
        const dialogRef = this._matDialog.open(ModalInterestInformationComponent, {
            width: '550px',
            data: this.offer
                ? this.offer
                : {
                    interest: EInterest.Interested,
                    member: this._identityService.user._id,
                    hospital: this._identityService.hospital._id,
                    available: this.availableAndOffers.available._id
                }
        });
        dialogRef.afterClosed().subscribe((result) => {
            let offer;
            if (result) {
                if (this.offer) {
                    offer = this.offer;
                    offer.interest = EInterest.Interested;
                    offer.comment = result.comment;
                    this._availableService.updateOffer(offer).subscribe((updatedOffer) => {
                        this.offer = updatedOffer;
                    }, (error) => console.error(error));
                }
                else {
                    result.interest = EInterest.Interested;
                    this.offer.interest = EInterest.Interested;
                    this._availableService.addOffer(result).subscribe((offer) => {
                        this.offer = result;
                        this.availableAndOffers.offers.push(offer);
                    });
                }
                this._availableService.onAvailableDetailChanged(this.availableAndOffers);
            }
        });
    }
    addOffer(interest) {
        let offer;
        if (!this.offer) {
            offer = {
                available: this.availableAndOffers.available._id.toString(),
                hospital: this._identityService.hospital._id,
                member: this._identityService.user._id,
                interest: interest
            };
            this._availableService.addOffer(offer).subscribe((newOffer) => {
                this.availableAndOffers.offers.push(newOffer);
                this.offer = newOffer;
            });
        }
        else {
            offer = this.offer;
            offer.interest = interest;
            this._availableService.updateOffer(offer).subscribe((updateOffer) => {
                this.offer = updateOffer;
                this.availableAndOffers.offers = this.availableAndOffers.offers.map((item) => {
                    if (Utils.compareIds(item, updateOffer)) {
                        return updateOffer;
                    }
                    return item;
                });
            });
        }
        this._availableService.onAvailableDetailChanged(this.availableAndOffers);
    }
    getHospitalDistance() {
        var _a, _b, _c;
        if ((_c = (_b = (_a = this.availableAndOffers) === null || _a === void 0 ? void 0 : _a.available) === null || _b === void 0 ? void 0 : _b.hospital) === null || _c === void 0 ? void 0 : _c._id) {
            this.hospitalService
                .getDistance(this.availableAndOffers.available.hospital._id)
                .subscribe((distance) => {
                this.distance = distance;
            });
        }
    }
    findPreservation(preservation) {
        for (const index in this.cdrugPreservation) {
            if (preservation == this.cdrugPreservation[index].value) {
                return this.cdrugPreservation[index].translate;
            }
        }
    }
    get isArchived() {
        return this.availableAndOffers.available.status == EAvailableStatus.Archived;
    }
    get available() {
        var _a;
        return (_a = this.availableAndOffers) === null || _a === void 0 ? void 0 : _a.available;
    }
    get product() {
        var _a;
        return (_a = this.available) === null || _a === void 0 ? void 0 : _a.drug;
    }
    get discount() {
        if (this.available.unitPrice == null || this.available.drug.manufacturerPrice == null) {
            return undefined;
        }
        if (isNaN(this.available.unitPrice) || isNaN(this.available.drug.manufacturerPrice)) {
            return undefined;
        }
        return Number(((1 - this.available.unitPrice / this.available.drug.manufacturerPrice) * 100).toFixed(2));
    }
    get totalPrice() {
        return Number((this.available.quantity * this.available.unitPrice).toFixed(2));
    }
    get hasReachedEndDate() {
        return new Date(this.available.endDate).getTime() - Date.now() <= 0;
    }
    getPreservationTranslation(value) {
        return this._translationService.getTranslation(this.cdrugPreservation, value);
    }
};
AvailableDetailComponent = __decorate([
    Component({
        selector: 'mpx-available-detail',
        template: require('./available-detail.component.html').default,
        styles: [require('./available-detail.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AvailableRequestService !== "undefined" && AvailableRequestService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object, typeof (_g = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _g : Object, typeof (_h = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _h : Object])
], AvailableDetailComponent);
export { AvailableDetailComponent };
