export class MpSearchEstablishmentTypeComponent {
    constructor() {
        this.controller = 'MpSearchEstablishmentTypeController';
        this.controllerAs = 'vm';
        this.template = require('./mp-search-establishment-type.html').default;
        this.bindings = {
            label: '<',
            type: '<',
            establishment: '<',
            form: '<',
            name: '<',
            onSelected: '&',
        };
    }
}
