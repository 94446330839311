/** @ngInject */
export class MpSearchTownController {
    /** @ngInject */
    constructor(GeoService) {
        this.GeoService = GeoService;
        /**
         * Search all [[ITown]] occurrence from the searchText
         * @returns {angular.IPromise<Array<ITown>>}
         */
        this.searchTown = () => {
            return this.GeoService.findTown(this.ctrl.searchText);
        };
        /**
         * An [[ITown]] is selected and returned
         */
        this.searchTownSelected = () => {
            if (this.ctrl.selected) {
                this.onSelected({
                    $event: { town: this.ctrl.selected },
                });
            }
        };
        this.searchTextChange = () => {
            if (this.onTextChange) {
                this.onTextChange({
                    $event: { text: this.ctrl.searchText },
                });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.town) {
            this.ctrl.searchText = (this.town) ? this.town.nom_de_la_commune : '';
        }
    }
}
