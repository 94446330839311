export const locale = {
    lang: 'fr',
    data: {
        LIST_EMAILS: {
            TITLE: `Mails sécurisés`,
            SEARCH_EMAIL: `Rechercher un mail`,
            TABLE: {
                HOSPITAL: `Hôpital`,
                EMAIL: `Adresse mail sécurisée`,
                MANAGER: `Responsable`
            }
        }
    }
};
