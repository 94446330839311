var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableRoutingModule } from './available-routing.module';
import { AvailableRequestModule } from './available-request/available-request.module';
import { AvailableHospitalModule } from './available-hospital/available-hospital.module';
import { AvailableSummaryModule } from './available-summary/available-summary.module';
let AvailableModule = class AvailableModule {
};
AvailableModule = __decorate([
    NgModule({
        declarations: [],
        imports: [CommonModule, AvailableRequestModule, AvailableHospitalModule, AvailableSummaryModule],
        exports: [AvailableRoutingModule]
    })
], AvailableModule);
export { AvailableModule };
