import * as angular from 'angular';
import { EMpStatus } from '@mapuilabs/mpl-interfaces';
/** @ngInject */
export class MpModalRoleController {
    /** @ngInject */
    constructor(ModalService, Api, ToastService) {
        this.ModalService = ModalService;
        this.Api = Api;
        this.ToastService = ToastService;
        /**
         * Close the modal without performing any action
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal and return the created group
         */
        this.ok = () => {
            this.role.project = this.project;
            this.ModalService.close({
                role: this.role,
            });
        };
    }
    $onInit() {
        this.Api.constType.get(res => {
            this.roleTypes = res.rolesTypes;
            this.roleSubscriptions = res.rolesSubscriptions;
        }, () => this.ToastService.show(EMpStatus.RejectByServer));
    }
    $onChanges(changes) {
        if (changes.role) {
            this.role = angular.copy(this.role);
        }
    }
}
