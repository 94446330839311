var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IdentityService } from '@app/services/identity/identity.service';
import { PermissionService } from '@app/services/permission/permission.service';
import { CrudReadonly } from '@app/shared/templates/crud/crud-readonly.class';
import { Utils } from '@main/services/Utils';
import { EHTTPStatus, HsPublicationContainer } from '@mapuilabs/mpl-interfaces';
import { UIRouter } from '@uirouter/angularjs';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
let HsImportSpreadsheetService = class HsImportSpreadsheetService extends CrudReadonly {
    constructor(_httpClient, _identityService, _route, _injector, _permissionService) {
        super(_injector, HsPublicationContainer, '', {
            create: null,
            read: null,
            update: null,
            delete: null
        });
        this._httpClient = _httpClient;
        this._identityService = _identityService;
        this._route = _route;
        this._injector = _injector;
        this._permissionService = _permissionService;
        // State
        this._currentState = this._route.globals.current;
        // Config
        this._serviceConfig = this._currentState.data.servicesConfig.publicationContainer;
        this._endPoint = this._serviceConfig.endPointBase + '/import-spreadsheet';
        this._permissions = this._serviceConfig.permissions;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Build methods
    // -----------------------------------------------------------------------------------------------------
    buildPublications(jsonSpreadsheet, importSpreadsheetConfig) {
        const publicationBuilder = this._serviceConfig.spreadsheetClassBuilder.publication;
        return this._spreadsheetImportContainerBuilder(publicationBuilder, jsonSpreadsheet, importSpreadsheetConfig);
    }
    buildAlternatives(jsonSpreadsheet, importSpreadsheetConfig) {
        const alternativeBuilder = this._serviceConfig.spreadsheetClassBuilder.alternative;
        return this._spreadsheetImportContainerBuilder(alternativeBuilder, jsonSpreadsheet, importSpreadsheetConfig);
    }
    _spreadsheetImportContainerBuilder(spreadsheetClassBuilder, jsonSpreadsheet, importSpreadsheetConfig) {
        return jsonSpreadsheet.map((obj) => {
            var _a;
            const keysToLowerCase = (_a = importSpreadsheetConfig.spreadsheetParserConfig.options) === null || _a === void 0 ? void 0 : _a.headerToLowerCase;
            const user = this._identityService.user;
            // Init data object with constructor and user info
            const data = new importSpreadsheetConfig.classConstructor({
                creator: Utils.getId(user === null || user === void 0 ? void 0 : user.hospital),
                creatorType: this._serviceConfig.viewType
            });
            // Add cell value to data using builder
            spreadsheetClassBuilder.forEach((item) => {
                // Set cellValue from parsed file or using default value
                const getFromValue = () => item.value;
                const getFromColumn = () => obj[keysToLowerCase ? item.column.toString().toLowerCase() : item.column];
                const cellValue = 'value' in item ? getFromValue() : getFromColumn();
                if (cellValue) {
                    // Apply optionnal transformation
                    const applyTransform = (value) => ('transform' in item ? item.transform(value) : value);
                    const transformedCellValue = applyTransform(cellValue);
                    // Add built property to object
                    const obj = _.set({}, item.path, transformedCellValue);
                    // Merge is used to merge arrays with multiple nested properties
                    // e.g { healthProducts : [{ ref: 'ref', name: 'name' }] }
                    _.merge(data, obj);
                }
            });
            // Validate built object
            const isValid = importSpreadsheetConfig.classValidator.isValid(data);
            return isValid ? { data, metadata: { row: obj.rowNum + 1 } } : null;
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Import methods
    // -----------------------------------------------------------------------------------------------------
    importPublicationsFromSpreadsheet(spreadsheetImportContainers) {
        const endpoint = `${this._endPoint}/publication`;
        return this._importFromSpreadsheet(endpoint, spreadsheetImportContainers);
    }
    importAlternativesFromSpreadsheet(spreadsheetImportContainers) {
        const endpoint = `${this._endPoint}/alternative`;
        return this._importFromSpreadsheet(endpoint, spreadsheetImportContainers);
    }
    _importFromSpreadsheet(endPoint, spreadsheetImportContainers) {
        // Guard: permissions
        if (!this._permissionService.authorize(this._permissions.create)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        // Guard: undefined
        if (!spreadsheetImportContainers) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        return this._httpClient.post(endPoint, spreadsheetImportContainers);
    }
};
HsImportSpreadsheetService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object, typeof (_c = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _c : Object, typeof (_d = typeof Injector !== "undefined" && Injector) === "function" ? _d : Object, typeof (_e = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _e : Object])
], HsImportSpreadsheetService);
export { HsImportSpreadsheetService };
