export const locale = {
    lang: 'fr',
    data: {
        RELEASE_NOTES: {
            TITLE: `Notes de version`,
            TABLE: {
                PROJECT: 'Projet',
                TITLE: 'Titre',
                ACTIONS: 'Actions',
                DUPLICATE: {
                    TITLE: 'Dupliquer',
                    NOTIF: 'Widget dupliqué'
                },
                EMPTY: 'Aucun widget'
            },
            DETAILS: {
                RESET: {
                    TITLE: `Ajout d'un élément en cours`,
                    MESSAGE: `Voulez-vous vraiment continuer ? L'ajout de l'élément de personnalisation en cours sera annulé.`
                },
                INVALID_FORM: 'Formulaire invalide',
                SETTINGS: {
                    LABEL: 'Paramètres',
                    TITLE: 'Titre du widget',
                    BACKGROUND_COLOR: 'Couleur de fond',
                    POSITIONS: {
                        ONE_COLUMN: `Affichage 1 colonne :`,
                        TWO_COLUMNS: `Affichage 2 colonnes :`,
                        THREE_COLUMNS: `Affichage 3 colonnes :`,
                        X: `Colonne`,
                        Y: `Ordre`,
                        DO_NOT_SHOW: `Ne pas afficher`
                    },
                    LINES: {
                        LABEL: `Lignes`,
                        ADD_BTN_TOOLTIP: `Ajouter une ligne`,
                        TABLE: {
                            LINE_LABEL: `Nom de la ligne`,
                            PROJECT: `Projet`,
                            PUBLICATION_DATE: `Date de la mise à jour`,
                            CONTENT: {
                                PLACEHOLDER: 'Tapez le contenu le ligne...'
                            },
                            TAGS: {
                                ADD_BTN: `Ajouter un tag`,
                                FORM_LABEL: `Intitulé`,
                                STATE_BTN: `Lien interne`,
                                VALIDATE_TOOLTIP: `Ajouter le tag`
                            },
                            KNOWLEDGE_BASE: `Article de la base de connaissance`
                        }
                    },
                    BUTTONS: {
                        LABEL: 'Boutons',
                        TITLE: 'Titre',
                        REDIRECT: {
                            TITLE: 'Redirection'
                        },
                        URL: 'URL',
                        COLOR: 'Couleur',
                        LAYOUT: 'Alignement'
                    }
                },
                PREVIEW: {
                    LABEL: 'Pré-visualisation'
                }
            },
            SNACKBAR: {
                WIDGET_SAVED: `Le widget a bien été sauvegardé.`,
                LINE_ADDED: `La ligne a bien été ajoutée.`,
                LINE_SAVED: `La ligne a bien été sauvegardée.`,
                LINE_REMOVED: `La ligne a bien été supprimée.`,
                TAG_ADDED: `Le tag a bien été ajouté.`,
                TAG_REMOVED: `Le tag a bien été retiré.`
            },
            TOOLTIPS: {
                ADDING_LINE: `Ajout d'une nouvelle ligne en cours`
            }
        }
    }
};
