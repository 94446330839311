import { SpreadsheetParserErrorsMessage } from '@services/spreadsheet-parser/spreadsheet-parser.types';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
export class SpreadsheetParserUtils {
    /**
     * Parse spreadsheet
     * @param file
     * @param format
     * @param options
     * @private
     */
    static parseSpreadsheet(file, format, options) {
        var _a, _b;
        // Process file to extract workbook
        const data = new Uint8Array(file);
        const workbook = XLSX.read(data, { type: 'array', cellDates: true });
        // Extract first sheet
        if (workbook.SheetNames.length === 0) {
            throw new Error(SpreadsheetParserErrorsMessage.NO_SHEET_FOUND);
        }
        // Extract first worksheet and update worksheet range to match the real range of the spreadsheet file
        const worksheet = this._updateWorkSheetRange(workbook.Sheets[workbook.SheetNames[0]]);
        // Get range of worksheet (rows and columns)
        const worksheetRange = XLSX.utils.decode_range(worksheet['!ref']);
        // Extract headers from worksheet & ensure values are string
        const headerRange = { s: { c: worksheetRange.s.c, r: 0 }, e: { c: worksheetRange.e.c, r: 0 } };
        const headerRangeValues = this._extractValueFromRange(worksheet, headerRange);
        const headers = _.chain(headerRangeValues)
            .first()
            .compact()
            .map((header) => header.toString())
            .value();
        // Handle case-sensitive option
        if (options === null || options === void 0 ? void 0 : options.headerToLowerCase) {
            // Convert all string data to lowercase
            headers.forEach((header, index) => (headers[index] = header.toLocaleLowerCase()));
            // Overwrite headers in worksheet
            XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });
        }
        // Correct invalid dates
        // Date are not parsed correctly by xlsx library on Google Chrome due to wrong implementation of getTimezoneOffset() for some dates (rounded).
        // This error resulting in an time offset when parsing dates in the xlsx library numdate function.
        // Sometimes this time offset cause the date to be one day before when the time offset is negative and the date time is initially 00:00.
        const isDate1904 = (_b = (_a = workbook.Workbook) === null || _a === void 0 ? void 0 : _a.WBProps) === null || _b === void 0 ? void 0 : _b.date1904;
        this._correctDateValuesFromRange(worksheet, worksheetRange, isDate1904);
        // Convert sheet to provided format
        switch (format) {
            case 'json':
                return {
                    headers,
                    spreadsheet: XLSX.utils
                        .sheet_to_json(worksheet, { raw: true })
                        // Rename __rowNum__ to rowNum to preserve property throw web worker's data copy by structured clone algorithm
                        .map((row) => (Object.assign(Object.assign({}, row), { rowNum: row.__rowNum__ }))),
                    format
                };
            case 'csv':
                return { headers, spreadsheet: XLSX.utils.sheet_to_csv(worksheet), format };
            case 'txt':
                return { headers, spreadsheet: XLSX.utils.sheet_to_txt(worksheet), format };
            case 'html':
                return { headers, spreadsheet: XLSX.utils.sheet_to_html(worksheet), format };
            case 'formulae':
                return { headers, spreadsheet: XLSX.utils.sheet_to_formulae(worksheet), format };
            default:
                throw new Error(SpreadsheetParserErrorsMessage.UNKNOWN_FORMAT);
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Parse methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Extract raw value from worksheet in a selected range
     * @param sheet XLSX worksheet
     * @param range XLSX range
     * @returns range values
     */
    static _extractValueFromRange(sheet, range) {
        var _a;
        const rangeValues = [];
        // Iterate over worksheet range
        for (let R = range.s.r; R <= range.e.r; ++R) {
            rangeValues.push([]);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                // Get cell_ref
                const cell_address = { c: C, r: R };
                const cell_ref = XLSX.utils.encode_cell(cell_address);
                // Extract raw value from cell
                rangeValues[R][C] = (_a = sheet[cell_ref]) === null || _a === void 0 ? void 0 : _a.v;
            }
        }
        return rangeValues;
    }
    static _correctDateValuesFromRange(sheet, range, isDate1904) {
        // Reverse process of xlsx library numdate function to get back the initial excel date code
        const datenum = (date) => {
            const basedate = new Date(1899, 11, 30, 0, 0, 0);
            const refdate = new Date();
            const dnthresh = basedate.getTime() + (refdate.getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
            const refoffset = refdate.getTimezoneOffset();
            const isOffsetDifferent = date.getTimezoneOffset() !== refoffset;
            const time = isOffsetDifferent
                ? date.getTime() - (date.getTimezoneOffset() - refoffset) * 60000
                : date.getTime();
            return (time - dnthresh) / 1000 / 60 / 60 / 24;
        };
        // Iterate over worksheet range
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                // Get cell_ref
                const cell_address = { c: C, r: R };
                const cell_ref = XLSX.utils.encode_cell(cell_address);
                const cell = sheet[cell_ref];
                if ((cell === null || cell === void 0 ? void 0 : cell.v) instanceof Date) {
                    // Reverse date to excel date code conversion
                    const excelCode = datenum(cell === null || cell === void 0 ? void 0 : cell.v);
                    // Convert excel code into date (the right way)
                    const { y, m, d, H, M, S } = XLSX.SSF.parse_date_code(excelCode, { date1904: isDate1904 });
                    const date = new Date(y, m - 1, d, H, M, S);
                    // Apply corrected date
                    cell.v = date;
                }
            }
        }
    }
    /**
     * Updates worksheet range to not count empty rows and avoid latency issues
     * From https://docs.sheetjs.com/docs/miscellany/errors/#worksheet-only-includes-one-row-of-data
     * @param ws
     * @private
     */
    static _updateWorkSheetRange(ws) {
        const range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
        Object.keys(ws)
            // Filter margin and ref attributes
            .filter((x) => {
            return x.charAt(0) !== '!';
        })
            .map(XLSX.utils.decode_cell)
            .forEach((x) => {
            range.s.c = Math.min(range.s.c, x.c);
            range.s.r = Math.min(range.s.r, x.r);
            range.e.c = Math.max(range.e.c, x.c);
            range.e.r = Math.max(range.e.r, x.r);
        });
        ws['!ref'] = XLSX.utils.encode_range(range);
        return ws;
    }
}
