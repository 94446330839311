var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LatLng, latLng } from 'leaflet';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
let GenericMapComponent = class GenericMapComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.updateGeo = new EventEmitter();
        this.options = {
            zoom: 4,
            doubleClickZoom: false,
            center: latLng(48.8534, 2.3488),
            layers: [L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {})]
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnChanges(changes) {
        if (this.marker) {
            if (!isNaN(this.geo.lat) && !isNaN(this.geo.lng)) {
                this.marker.setLatLng([this.geo.lat, this.geo.lng]);
            }
        }
        else if (this.map && !isNaN(this.geo.lat) && !isNaN(this.geo.lng)) {
            this.initMaker();
        }
    }
    onToggleDrag(input) {
        input.checked ? this.marker.dragging.enable() : this.marker.dragging.disable();
    }
    initMaker() {
        this.marker = L.marker([this.geo.lat, this.geo.lng], {
            draggable: false,
            icon: L.icon({
                iconSize: [40, 40],
                iconAnchor: [30, 40],
                iconUrl: 'assets/images/mapui/pins/marker_fuse_red.png',
                iconRetinaUrl: 'assets/images/mapui/pins/marker_fuse_red.png'
                // shadowUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png',
            })
        });
        this.marker.on('moveend', (event) => {
            if (event.target) {
                this.updateGeo.emit(event.target._latlng);
            }
        });
        this.marker.on('click', () => {
            this.centerMapToMarker();
        });
        this.marker.addTo(this.map);
        this.centerMapToMarker();
    }
    centerMapToMarker() {
        this.map.fitBounds(L.latLngBounds([this.marker.getLatLng()]));
        this.map.setZoom(15);
    }
    ngAfterViewInit() {
        this.map.invalidateSize();
    }
    onMapReady(map) {
        this.map = map;
        this.map.on('dblclick', (event) => {
            if (!this.marker) {
                this.updateGeo.emit(event.latlng);
            }
        });
        if (this.geo.lat && this.geo.lng) {
            this.initMaker();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof LatLng !== "undefined" && LatLng) === "function" ? _b : Object)
], GenericMapComponent.prototype, "geo", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], GenericMapComponent.prototype, "updateGeo", void 0);
GenericMapComponent = __decorate([
    Component({
        selector: 'mpx-generic-map',
        template: require('./generic-map.component.html').default,
        styles: [require('./generic-map.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], GenericMapComponent);
export { GenericMapComponent };
