import * as angular from 'angular';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
let _Access;
let _Auth;
let _VaultService;
let _ModalService;
let _HistoryService;
export class RunBlock {
    constructor($rootScope, $timeout, $state, $transitions, $anchorScroll, $location, $window, Auth, ModalService, Access, VaultService, HistoryService) {
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$transitions = $transitions;
        this.$anchorScroll = $anchorScroll;
        this.$location = $location;
        this.$window = $window;
        this.Auth = Auth;
        this.ModalService = ModalService;
        this.Access = Access;
        this.VaultService = VaultService;
        this.HistoryService = HistoryService;
        /**
         * Perform all checks and redirects
         * @param toState
         * @param fromState
         * @param event
         * @param $state
         * @param $rootScope
         * @return {boolean}
         * @private
         */
        this._performChecksNRedirects = (toState, fromState, event, $state, $rootScope, toParams) => {
            this._ignoreRoutes = [].concat(this._authRoutes, this._errorsRoutes, this._customExclude);
            for (const check of this._checks) {
                if (check.func(toState, fromState)) {
                    if (check.modal) {
                        // Display modal (access denied)
                        this.ModalService.show({
                            escapeToClose: false,
                            clickOutsideToClose: false,
                            component: check.modal
                        }).finally(() => {
                            if (check.route) {
                                // Redirect to route
                                const customHome = this.Access.getCustomHome();
                                if (!this.Auth.authorize(EMaPUIPermission.Home_Read) && !!customHome) {
                                    $state.go(customHome);
                                }
                                else {
                                    $state.go('mapui.home');
                                }
                            }
                        });
                    }
                    else if (check.route) {
                        // Redirect to route
                        event.preventDefault();
                        if (toParams.redirect) {
                            console.info('redirect to ', toParams.redirect);
                            $state.go('');
                        }
                        else {
                            console.info('Redirect to route', check.route);
                            $state.go(check.route);
                        }
                    }
                    else {
                        const customHome = this.Access.getCustomHome();
                        if (customHome) {
                            event.preventDefault();
                            console.info('Redirect to route', customHome);
                            $state.go(customHome);
                        }
                    }
                    return true;
                }
            }
            return true;
        };
        /**
         * Should user see error message for IE
         * @return {boolean}
         * @private
         */
        this._shouldSeeIEMessage = (toState) => {
            if (this._urlIn(this._errorsRoutes, toState.url)) {
                return false;
            }
            if (/MSIE 10/i.test(navigator.userAgent)) {
                // This is internet explorer 10
                return true;
            }
            return /MSIE 9/i.test(navigator.userAgent) || /rv:11\.0/i.test(navigator.userAgent);
        };
        /**
         * Should user see unsupported browser page
         * @param toState
         * @private
         */
        this._shouldSeeUnsupportedBrowser = (toState) => {
            if (this._urlIn(this._errorsRoutes, toState.url)) {
                return false;
            }
            return !this.Access.isShadowDOMAvailable();
        };
        /**
         * Should User be redirected to login page
         * @param toState
         * @return {boolean}
         * @private
         */
        this._shouldSeeLogin = (toState) => {
            return !this.Auth.user.email && !this._urlIn(this._ignoreRoutes, toState.url);
        };
        /**
         * Should User be redirected to home since he/she is already logged in
         * @param toState
         * @return {boolean}
         * @private
         */
        this._shouldNotSeeAuth = (toState) => {
            return (!!this.Auth.user.email &&
                this._urlIn(this._authRoutes, toState.url) &&
                !this._urlIn(this._errorsRoutes, toState.url) &&
                toState.url !== '/auth/forgot-password' &&
                !this.queryParams.redirect);
        };
        /**
         * Should User be redirected to first connection view
         * @param toState
         * @return {boolean}
         * @private
         */
        this._shouldSeeFirstConnect = (toState) => {
            this._ignoreRoutes.push('/first-connect');
            return (!this._urlIn(this._ignoreRoutes, toState.url) &&
                this.Access.isNotActivated() &&
                !this.Access.CGUisAccepted());
        };
        /**
         * Should User be redirected to reset password view
         * @param toState
         * @return {boolean}
         * @private
         */
        this._shouldSeeResetPassword = (toState) => {
            this._ignoreRoutes.push('/reset-password');
            return !this._urlIn(this._ignoreRoutes, toState.url) && this.Access.user.activation;
        };
        /**
         * Should User be redirected to cgu view
         * @param toState
         * @return {boolean}
         * @private
         */
        this._shouldSeeCGU = (toState) => {
            this._ignoreRoutes.push('/cgu');
            return !this._urlIn(this._ignoreRoutes, toState.url) && !this.Access.user.acceptedCGU;
        };
        /**
         * Should User be redirected fuck off
         * @param toState
         * @return {boolean}
         * @private
         */
        this._hasNotAccessTo = (toState) => {
            if (toState.data && toState.data.access) {
                if (Array.isArray(toState.data.access)) {
                    for (const access of toState.data.access) {
                        if (this.Auth.authorize(access)) {
                            return false;
                        }
                    }
                    return true;
                }
                else {
                    return !this.Auth.authorize(toState.data.access);
                }
            }
            return false;
        };
        /**
         * Doesn't user have access to home and do user have a custom home role
         * @param toState
         * @private
         */
        this._shouldSeeCustomHome = (toState) => {
            return (toState.data &&
                toState.data.access === EMaPUIPermission.Home_Read &&
                !this.Auth.authorize(toState.data.access) &&
                !!this.Access.getCustomHome());
        };
        /**
         * Is url in exclude list
         * @param exclude
         * @param {string} url
         * @return {number}
         * @private
         */
        this._urlIn = (exclude, url) => {
            //To remove params from the url
            url = url.split('?')[0];
            for (const e of exclude) {
                if (url.indexOf(e) === 0) {
                    return true;
                }
            }
            return false;
        };
        // Store state in the root scope for easy access
        $rootScope.state = $state;
        this._customExclude = ['/public-patient-survey'];
        this._errorsRoutes = [
            '/pages/errors/error-ie',
            '/common/errors/404',
            '/pages/errors/error-500',
            '/unsupported-browser'
        ];
        this._authRoutes = [
            '/auth/forgot-password',
            '/auth/login',
            '/auth/register',
            '/auth/register-validation',
            '/auth/register-pharmacist-validation',
            '/auth/unknown-user'
        ];
        this._checks = [
            { func: this._shouldSeeIEMessage, route: 'mapui.pages_errors_error-ie' },
            { func: this._shouldSeeUnsupportedBrowser, route: 'mapui.pages_incompatible_browser' },
            { func: this._shouldSeeLogin, route: 'mapui.pages_auth_login-v2' },
            { func: this._shouldNotSeeAuth, route: 'mapui.home' },
            { func: this._shouldSeeCustomHome, route: undefined },
            { func: this._shouldSeeFirstConnect, route: 'mapui.first-connection' },
            { func: this._shouldSeeResetPassword, route: 'mapui.reset-password' },
            { func: this._shouldSeeCGU, route: 'mapui.accept-cgu' },
            { func: this._hasNotAccessTo, route: 'mapui.home', modal: 'mp-modal-access' }
        ];
        //REDIRECT
        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
            // Activate loading indicator
            $rootScope.loadingProgress = true;
            this.queryParams = toParams;
            this._performChecksNRedirects(toState, fromState, event, $state, $rootScope, toParams);
            $rootScope.loadingProgress = false;
        });
        $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState) => {
            // De-activate loading indicator
            $timeout(function () {
                $rootScope.loadingProgress = false;
                if ($location.hash()) {
                    $anchorScroll();
                }
            });
            if ($state.current.data) {
                VaultService.init();
            }
            if (toState.data && toState.data.hasSensitiveData) {
                VaultService.showUnlockModalIfItIsLocked();
            }
            if (Utils.hasId(this.Auth.user)) {
                this.HistoryService.pushNavigation(toState, toParams);
            }
        });
        this.$transitions.onEnter({}, (transition) => {
            var _a;
            const transitionData = transition.to();
            const theme = (_a = transitionData === null || transitionData === void 0 ? void 0 : transitionData.data) === null || _a === void 0 ? void 0 : _a.theme;
            if (theme) {
                const cdkOverlay = angular.element('.cdk-overlay-container');
                cdkOverlay.removeClass(function (index, className) {
                    return (className.match(/\btheme-\S+/g) || []).join(' ');
                });
                cdkOverlay.addClass(theme);
            }
        });
    }
}
/** @ngInject */
export function runIndex($rootScope, $timeout, $state, $transitions, $anchorScroll, $location, $window, Auth, ModalService, Access, VaultService, HistoryService, $templateCache) {
    $templateCache.put('app/main/directives/mp-table/templates/mp-table-pagination.html', require('./main/directives/mp-table/templates/mp-table-pagination.html').default);
    return new RunBlock($rootScope, $timeout, $state, $transitions, $anchorScroll, $location, $window, Auth, ModalService, Access, VaultService, HistoryService);
}
