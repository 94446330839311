import { MpForgotPasswordComponent } from './mp-forgot-password.component';
import { MpForgotPasswordController } from './mp-forgot-password.controller';
import * as angular from 'angular';
angular
    .module('mapui.services.auth.forgot-password', [])
    .config(config)
    .component('mpForgotPassword', new MpForgotPasswordComponent())
    .controller('MpForgotPasswordController', MpForgotPasswordController);
/** @ngInject */
function config($stateProvider) {
    // State
    $stateProvider.state('mapui.pages_auth_forgot-password', {
        url: '/auth/forgot-password',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_auth_forgot-password': {
                template: require('./mp-forgot-password.html').default,
                controller: 'MpForgotPasswordController as vm',
            },
        },
        bodyClass: 'forgot-password',
    });
}
