var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EFileType } from '@mapuilabs/mpl-interfaces';
import { FileUploaderComponent } from '../../../shared/components/file-uploader/file-uploader.component';
let ExFileUploaderComponent = class ExFileUploaderComponent {
    constructor() {
        this.pageHeaderConfig = {
            title: 'File Upload Component',
            icon: 'extension'
        };
        this.type = EFileType.HospitalLogo;
        this.multiple = false;
        this.hideSendButton = false;
    }
    triggerUpload() {
        this._fileUploaderComponent.upload(false).subscribe((data) => { });
    }
};
__decorate([
    ViewChild(FileUploaderComponent, { static: false }),
    __metadata("design:type", typeof (_a = typeof FileUploaderComponent !== "undefined" && FileUploaderComponent) === "function" ? _a : Object)
], ExFileUploaderComponent.prototype, "_fileUploaderComponent", void 0);
ExFileUploaderComponent = __decorate([
    Component({
        selector: 'ex-file-uploader',
        template: require('./ex-file-uploader.component.html').default,
        styles: [require('./ex-file-uploader.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [])
], ExFileUploaderComponent);
export { ExFileUploaderComponent };
