export class MpSidenavDashboardFiltersController {
    /** @ngInject */
    constructor(Api, StatusService) {
        this.Api = Api;
        this.StatusService = StatusService;
    }
    $onInit() {
        this.Api.constType.get((res) => {
            this.loanTypes = res.loanTypes;
            this.loanStatusIndicators = res.loanStatusIndicators;
            for (let indicator of this.loanStatusIndicators) {
                indicator.html = this.StatusService.createHtmlIndicator(indicator.name);
            }
            this.establishmentsTypes = res.type_establishment;
        });
    }
}
