import { EGroup } from '@mapuilabs/mpl-interfaces';
export const CGroupStructure = {
    hospital: {
        value: EGroup.Hospital,
        translate: 'GROUPS.STRUCTURE.HOSPITAL'
    },
    member: {
        value: EGroup.Member,
        translate: 'GROUPS.STRUCTURE.MEMBER'
    }
};
