var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHospistockPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { hsLaboDrugStatisticConfig, hsLaboDrugStatisticServiceConfig } from '@app/hospistock/labo/drug/statistic/hs-labo-drug-statistic.config';
import { HsStatisticComponent } from '@app/hospistock/shared/components/statistic/hs-statistic.component';
import { HsPublicationSingleViewDetailComponent } from '@app/hospistock/shared/components/publication/single-view/single-view-detail/hs-publication-single-view-detail.component';
import { EHsToolbarViewModes } from '@app/hospistock/shared/types/hs-toolbar.types';
import { hsLaboDrugServiceConfig } from '@app/hospistock/labo/drug/hs-labo-drug.config';
import { hsLaboDrugPublicationConfig, hsLaboDrugPublicationServiceConfig } from '@app/hospistock/labo/drug/publication/hs-labo-drug-publication.config';
import { PermissionService } from '@services/permission/permission.service';
const states = [
    {
        name: 'mapui.hospistock.labo.drug.statistic',
        url: '/statistic/pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: HsStatisticComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-indigo',
            access: [EHospistockPermission.LaboDrugStatistic_SeeMenuItem],
            servicesConfig: hsLaboDrugStatisticServiceConfig
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsLaboDrugStatisticConfig
            }
        ]
    },
    {
        name: 'mapui.hospistock.labo.drug.statistic.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: HsPublicationSingleViewDetailComponent
            }
        },
        params: {
            id: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-indigo',
            access: EHospistockPermission.LaboDrugStatistic_SeeMenuItem,
            viewMode: EHsToolbarViewModes.SINGLE_DETAIL,
            servicesConfig: {
                healthProduct: hsLaboDrugServiceConfig,
                publicationContainer: hsLaboDrugPublicationServiceConfig
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService, PermissionService],
                resolveFn: hsLaboDrugPublicationConfig
            }
        ]
    }
];
let HsLaboDrugStatisticRoutingModule = class HsLaboDrugStatisticRoutingModule {
};
HsLaboDrugStatisticRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], HsLaboDrugStatisticRoutingModule);
export { HsLaboDrugStatisticRoutingModule };
