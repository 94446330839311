const Project = {
    MAPUI: 'mapui',
    HOSPIVILLE: 'hospiville',
    ADMIN_PANEL: 'admin-panel'
};
export const mapuiConstants = {
    MaPUI: {
        // Pages
        home: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.HOME.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' }
            }
        },
        // SETTINGS
        establishment: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.HOSPITAL_ACCOUNT.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' }
            }
        },
        establishmentTeam: {
            project: Project.MAPUI,
            translate: 'PERMISSIONS.ESTABLISHMENT_TEAM',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                edit: { translate: 'PERMISSIONS.EDIT' }
            }
        },
        groups: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.GROUPS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        // MAPUI
        preparations: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.PREPARATIONS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        prepInst: {
            project: Project.MAPUI,
            translate: 'PERMISSIONS.PREP_INST',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                createBorrowing: { translate: 'PERMISSIONS.CREATE_BORROWING' },
                createLoan: { translate: 'PERMISSIONS.CREATE_LOAN' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        steInst: {
            project: Project.MAPUI,
            translate: 'PERMISSIONS.STE_INST',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                createBorrowing: { translate: 'PERMISSIONS.CREATE_BORROWING' },
                createLoan: { translate: 'PERMISSIONS.CREATE_LOAN' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        stockout: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.STOCKOUT.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' },
                share: { translate: 'PERMISSIONS.SHARE' }
            }
        },
        stockoutLabo: {
            project: Project.MAPUI,
            translate: 'MAPUI.STOCKOUT_LABO.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        equivalences: {
            project: Project.MAPUI,
            translate: '"MAPUI.NAVIGATION.SPECIALIZATION.EQUIVALENCES.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        pharmaceuticInterventions: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.PHARMACEUTIC_INTERVENTIONS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        // EXCHANGES
        dashboard: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.DASHBOARD.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' }
            }
        },
        medInst: {
            project: Project.MAPUI,
            translate: 'PERMISSIONS.MED_INST',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                createBorrowing: { translate: 'PERMISSIONS.CREATE_BORROWING' },
                createLoan: { translate: 'PERMISSIONS.CREATE_LOAN' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        request: {
            project: Project.MAPUI,
            translate: 'PERMISSIONS.REQUESTS',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' },
                retake: { translate: 'PERMISSIONS.RETAKE' }
            }
        },
        map: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.MAP.TITLE"',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' }
            }
        },
        available: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.AVAILABLE.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' },
                retake: { translate: 'PERMISSIONS.RETAKE' }
            }
        },
        billing: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.BILLING.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' }
            }
        },
        statistics: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.STATISTICS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        archives: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.ARCHIVES.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' }
            }
        },
        // WORKSPACE
        files: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.FILES.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' },
                share: { translate: 'PERMISSIONS.SHARE' }
            }
        },
        news: {
            project: Project.MAPUI,
            translate: 'MAPUI.NAVIGATION.NEWS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' },
                share: { translate: 'PERMISSIONS.SHARE' }
            }
        }
    },
    HospiBille: {
        patients: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.PATIENTS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        patient: {
            project: Project.HOSPIVILLE,
            translate: 'PERMISSIONS.PATIENT',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        reconciliation: {
            project: Project.HOSPIVILLE,
            translate: 'PERMISSIONS.RECONCILIATION',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        patientFile: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.PATIENT_FILE.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        patientInterviewSurvey: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.PIS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        dbs: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.DRUG_BS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        medicationReconciliation: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.MED_RECONCILIATION.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                edit: { translate: 'PERMISSIONS.EDIT' }
            }
        },
        reconciliationSheet: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.RECONCILIATION_SHEET.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        prescription: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.PRESCRIPTION.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                edit: { translate: 'PERMISSIONS.EDIT' }
            }
        },
        patientFiles: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.PATIENT_FILES.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        extensivePatientInterview: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.EPI.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        treatmentAnalysis: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.TREATMENT_ANALYSIS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        carePlan: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.CARE_PLAN.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        activities: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.ACTIVITIES.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                edit: { translate: 'PERMISSIONS.EDIT' }
            }
        },
        mailbox: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.MAILBOX.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' }
            }
        },
        statistics: {
            project: Project.HOSPIVILLE,
            translate: 'HOSPIVILLE.NAVIGATION.STATISTICS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        }
    },
    AdminPanel: {
        adminPanel: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.PANEL.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        establishments: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.ADMIN.PANEL.HOSPITALS.LABEL',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        users: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.ADMIN.PANEL.USERS.LABEL',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        groups: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.ADMIN.PANEL.GROUPS.LABEL',
            permissions: {
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        statistics: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.STATS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        prospects: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.PROSPECT.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        secureEmails: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.EMAILS',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        addUser: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.INVITE_USER.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        ght: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.GHT.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        onlineUsers: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.ONLINE_USERS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        laboGroups: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.LABO_GROUP.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        cron: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.CRON.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                edit: { translate: 'PERMISSIONS.EDIT' }
            }
        },
        roles: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.ROLES',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        },
        logs: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.LOGS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' }
            }
        },
        apiAccounts: {
            project: Project.ADMIN_PANEL,
            translate: 'MAPUI.NAVIGATION.ADMIN.API_ACCOUNTS.TITLE',
            permissions: {
                seeMenuItem: { translate: 'PERMISSIONS.SEE_MENU_ITEM' },
                read: { translate: 'PERMISSIONS.READ' },
                create: { translate: 'PERMISSIONS.CREATE' },
                edit: { translate: 'PERMISSIONS.EDIT' },
                delete: { translate: 'PERMISSIONS.DELETE' }
            }
        }
    }
};
