var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as angular from 'angular';
import * as moment from 'moment';
import { EFileType, EMpStatus, EPathways, } from '@mapuilabs/mpl-interfaces';
export class MpModalComposeMailController {
    /** @ngInject */
    constructor(FileUploaderService, HealthProfessionalService, HospitalService, MemberService, ModalService, ToastService, $translate, Access, Api, MailsService) {
        this.FileUploaderService = FileUploaderService;
        this.HealthProfessionalService = HealthProfessionalService;
        this.HospitalService = HospitalService;
        this.MemberService = MemberService;
        this.ModalService = ModalService;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.Access = Access;
        this.Api = Api;
        this.MailsService = MailsService;
        this.EPathways = EPathways;
        this.hasLoadedMail = false;
        /**
         * Reload mail body template
         */
        this.reloadTemplate = () => {
            if (this.bodyTemplate === 'INFOS_ASKING') {
                let translatePath = 'MAPUI.SERVICES.MAILS.TEMPLATE.BODY.INFOS_ASKING';
                if (this.pathway === EPathways.Octave) {
                    translatePath += '.OCTAVE';
                }
                translatePath += this.secure ? this.data.patient.gender === 0 ? '.SECURE.MALE' : '.SECURE.FEMALE' : '.UNSECURE';
                this.mail.body = this.$translate.instant(translatePath, {
                    pharmacist: this.Access.user.fullName,
                    hospital: this.Access.hospital.name,
                    phone: this.Access.user.phone ? `\n${this.Access.user.phone}` : '',
                    service: this.data.medRec.hospitalService ? ' dans le service ' + this.data.medRec.hospitalService.name : '',
                    patientFullName: this.data.patient.fullName,
                    patientBirthDate: moment(this.data.patient.birthDate).format('DD/MM/YYYY'),
                    patientBirthPlace: this.data.patient.birthPlace,
                });
            }
            else if (this.bodyTemplate === 'SHARE_RECONCILIATION_SHEET') {
                let translatePath = 'MAPUI.SERVICES.MAILS.TEMPLATE.BODY.SHARE_RECONCILIATION_SHEET';
                translatePath += this.secure ? this.data.patient.gender === 0 ? '.SECURE.MALE' : '.SECURE.FEMALE' : '.UNSECURE';
                this.mail.subject = this.secure ?
                    `Fiche de conciliation${this.data.transit !== undefined ? ` ${this.data.transit}` : ''} de ${this.data.patient.gender === 0 ? 'M.' : 'Mme'} ${this.data.patient.fullName}`
                    : `Fiche de conciliation${this.data.transit !== undefined ? ` ${this.data.transit}` : ''}`;
                const transitName = {
                    0: ' d\'admission',
                    1: ' de sortie',
                    2: ' de transfert',
                };
                this.mail.body = this.$translate.instant(translatePath, {
                    pharmacist: this.Access.user.fullName,
                    hospital: this.Access.hospital.name,
                    service: this.data.medRec.hospitalService ? ' dans le service ' + this.data.medRec.hospitalService.name : '',
                    patientFullName: this.data.patient.fullName,
                    patientBirthDate: moment(this.data.patient.birthDate).format('DD/MM/YYYY'),
                    transit: this.data.medRec.transit !== undefined ? transitName[this.data.medRec.transit] : '',
                    medRecDate: moment(this.data.medRec.date).format('DD/MM/YYYY'),
                });
            }
            this.mail.to = undefined;
        };
        this.addressSelected = (ev) => {
            if (ev.mssLetterBox) {
                this.mail.to = ev.mssLetterBox.letterBoxAddress;
            }
            if (ev.selectedRecipient) {
                this.selectedRecipient = ev.selectedRecipient;
            }
        };
        /**
         * Close the modal without actions performing
         */
        this.closeDialog = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this.uploadFiles().then(() => {
                this._close({
                    mail: this.mail,
                    isSecure: this.secure,
                    dest: this.selectedRecipient && this.bodyTemplate == 'SHARE_RECONCILIATION_SHEET' ? this.selectedRecipient.finessCode : null
                });
            }).catch(console.error);
        };
        /**
         * Close the modal with actions performing
         * @param answer
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
        this.secure = true;
        this.tabIndex = 0;
        this.ctrl = {
            selected: null,
            searchText: '',
        };
        this.FileUploaderService.init(true, undefined, undefined, false);
        this.uploader = this.FileUploaderService.uploader;
        this.FileUploaderService.onAfterAddingAll = () => this.refreshQueue();
    }
    $onInit() {
        if (!this.mail) {
            this.mail = {};
        }
        if (this.mail && !this.mail.body) {
            this.reloadTemplate();
        }
        if (!this.mail.from) {
            if (this.secure) {
                this.hasLoadedMail = false;
                this.HospitalService.getSecureEmailAddress(this.Access.hospital._id)
                    .then((address) => {
                    this.hasLoadedMail = true;
                    if (!address) {
                        return this.ToastService.show(EMpStatus.NoResult);
                    }
                    this.mail.from = address.secureEmail;
                })
                    .catch((err) => {
                    this.hasLoadedMail = true;
                    console.error(err);
                    this.ToastService.show(EMpStatus.RejectByServer);
                });
            }
            else if (!this.secure) {
                this.mail.from = this.Access.user.email;
            }
        }
        this.getHospitalMember();
        this.askingDone = false;
        this.MailsService.getNotSecureEmailsByEstablishmentId(this.Access.hospital._id)
            .then((items) => {
            if (items) {
                this.autocompleteMailItems = items.data;
            }
        })
            .catch((err) => {
            console.error(err);
            this.ToastService.show(EMpStatus.RejectByServer);
        });
    }
    $onChanges(changes) {
        if (changes.mail) {
            this.mail = angular.copy(this.mail);
        }
    }
    changeTab(index) {
        this.tabIndex = index;
    }
    findReferencePharmacies() {
        this.recipientsList = [];
        this.data.patient.sources.forEach((src) => {
            var _a;
            if ((_a = src.references) === null || _a === void 0 ? void 0 : _a.find((ref) => ref._id === this.Access.patient._id)) {
                this.recipientsList.push(src.establishment);
            }
        });
    }
    // Unused
    addToActivity() {
        this._close({
            mail: this.mail,
            isSecure: this.secure,
            onlyActivity: true
        });
    }
    /**
     * Get uploader queue
     */
    get fileUploaderQueue() {
        return this.FileUploaderService.uploader.queue;
    }
    /**
     * Put IFileUploaderFiles in the ng-model for files chips list
     */
    refreshQueue() {
        var _a;
        this.uploaderQueue = (_a = this.fileUploaderQueue) === null || _a === void 0 ? void 0 : _a.map((fileItem) => fileItem.file);
    }
    /**
     * Remove a file from the chips list and from the file uploader queue
     * @param name
     */
    removeFile(name) {
        const toRemove = this.fileUploaderQueue.find((attachment) => attachment.file.name === name);
        this.FileUploaderService.uploader.removeFromQueue(toRemove);
        this.refreshQueue();
    }
    /**
     * Add files in the uploader queue to the db
     */
    uploadFiles() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.FileUploaderService.uploader.queue.length) {
                const uploadedFiles = yield this.FileUploaderService.upload(EFileType.EmailAttachment);
                const promises = [];
                if (!this.mail.attachments) {
                    this.mail.attachments = [];
                }
                for (let file of uploadedFiles) {
                    file.type = EFileType.EmailAttachment;
                    file.member = { _id: this.Access.user._id };
                    promises.push(this.Api.activities.source.files.add(file, (file) => {
                        this.mail.attachments.push(file);
                    }, (err) => {
                        throw new Error(err);
                    }));
                }
                yield Promise.all(promises);
            }
        });
    }
    getHospitalMember() {
        this.MemberService.getByHosp(this.Access.user.hospital._id).then((members) => {
            this.members = members;
        });
    }
    preFill(ev) {
        if (ev) {
            this.MemberService.get(ev.model, this.Access.isAdmin())
                .then((fullMember) => this.member = fullMember)
                .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
        }
    }
    sendMSSDemand() {
        if (this.member) {
            this.askingDone = true;
            this.MailsService.sendMssRequest(this.member)
                .then(() => this.ToastService.show(EMpStatus.Ok))
                .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
        }
    }
    searchNotMSSMail() {
        if (this.ctrl.searchText === '') {
            return this.autocompleteMailItems;
        }
        return this.autocompleteMailItems.filter((mail) => mail.includes(this.ctrl.searchText));
    }
    searchNotMssSelected() {
        if (this.ctrl.selected) {
            this.mail.to = this.ctrl.selected;
        }
    }
    ;
    searchTextChange() {
        this.mail.to = this.ctrl.searchText;
    }
}
