var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ArchivedDetailComponent } from './archives/archived-tab/archived-detail/archived-detail.component';
import { MemberDetailComponent } from './archives/archived-tab/archived-detail/member-detail/member-detail.component';
import { ArchivesComponent } from './archives/archives.component';
import { BillingDetailComponent } from './billing/billing-awaiting/billing-detail/billing-detail.component';
import { MemberBillingDetailComponent } from './billing/billing-awaiting/billing-detail/member-detail/member-detail.component';
import { BillingInvoiceComponent } from './billing/billing-invoice/billing-invoice.component';
import { BillingComponent } from './billing/billing.component';
import { ExchangeMapComponent } from './map/map.component';
const states = [
    {
        name: 'mapui.exchangeV2.request',
        url: '/request',
        abstract: true
    },
    {
        name: 'mapui.exchangeV2.map',
        url: '/map',
        abstract: false,
        views: {
            'content@mapui': {
                component: ExchangeMapComponent
            }
        },
        data: {
            theme: 'theme-blue'
        }
    },
    {
        name: 'mapui.exchangeV2.available',
        url: '/available',
        abstract: true
    },
    {
        name: 'mapui.exchangeV2.billing',
        url: '/billing?tab',
        abstract: false,
        views: {
            'content@mapui': {
                component: BillingComponent
            }
        },
        data: {
            theme: 'theme-blue'
        },
        params: {
            tab: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.exchangeV2.billing.detail',
        url: '/detail/:id',
        abstract: false,
        views: {
            'content@mapui': {
                component: BillingDetailComponent
            }
        },
        data: {
            theme: 'theme-blue'
        }
    },
    {
        name: 'mapui.exchangeV2.billing.member',
        url: '/detail/member',
        abstract: false,
        views: {
            'content@mapui': {
                component: MemberBillingDetailComponent
            }
        },
        data: {
            theme: 'theme-blue'
        }
    },
    {
        name: 'mapui.exchangeV2.billing.invoice',
        url: '/invoice/:id',
        abstract: false,
        views: {
            'content@mapui': {
                component: BillingInvoiceComponent
            }
        },
        data: {
            theme: 'theme-blue'
        }
    },
    {
        name: 'mapui.exchangeV2.archives',
        url: '/archives?tab',
        abstract: false,
        views: {
            'content@mapui': {
                component: ArchivesComponent
            }
        },
        data: {
            theme: 'theme-blue'
        },
        params: {
            tab: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.exchangeV2.archives.detail',
        url: '/detail/:id',
        abstract: false,
        views: {
            'content@mapui': {
                component: ArchivedDetailComponent
            }
        },
        data: {
            theme: 'theme-blue'
        }
    },
    {
        name: 'mapui.exchangeV2.archives.member',
        url: 'detail/member',
        abstract: false,
        views: {
            'content@mapui': {
                component: MemberDetailComponent
            }
        },
        data: {
            theme: 'theme-blue'
        }
    }
];
let ExchangeRoutingModule = class ExchangeRoutingModule {
};
ExchangeRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], ExchangeRoutingModule);
export { ExchangeRoutingModule };
