import * as angular from 'angular';
import { ModalService } from './modal.service';
import { MpModalKillController } from './mp-modal-kill/mp-modal-kill.controller';
import { MpModalKillComponent } from './mp-modal-kill/mp-modal-kill.component';
import { MpModalDownloadController } from './mp-modal-download/mp-modal-download.controller';
import { MpModalDownloadComponent } from './mp-modal-download/mp-modal-download.component';
import { MpModalConfirmController } from './mp-modal-confirm/mp-modal-confirm.controller';
import { MpModalConfirmComponent } from './mp-modal-confirm/mp-modal-confirm.component';
import { MpErrorMessageController } from './mp-error-message/mp-error-message.controller';
import { MpErrorMessageComponent } from './mp-error-message/mp-error-message.component';
angular
    .module('mapui.services.modal', [])
    .service('ModalService', ModalService)
    .controller('MpModalKillController', MpModalKillController)
    .component('mpModalKill', new MpModalKillComponent())
    .controller('MpModalDownloadController', MpModalDownloadController)
    .component('mpModalDownload', new MpModalDownloadComponent())
    .controller('MpModalConfirmController', MpModalConfirmController)
    .component('mpModalConfirm', new MpModalConfirmComponent())
    .controller('MpErrorMessageController', MpErrorMessageController)
    .component('mpErrorMessage', new MpErrorMessageComponent());
