import * as angular from 'angular';
/** @ngInject */
export class MpChipsMedicController {
    /** @ngInject */
    constructor(MedicService) {
        this.MedicService = MedicService;
        /**
         * Return an array containing all matches from value
         * @returns A promise containing an Array of string
         */
        this.searchDrugs = () => {
            return this.MedicService.findByName(this.ctrl.searchText);
        };
        /**
         * Return the proper object when the append is called.
         */
        this.transformChip = (chip) => {
            if (!chip.denomination && !this.disallowCustomName) {
                return { denomination: this.ctrl.searchText, UCD13: null, codeATC: null };
            }
            return { denomination: chip.denomination, UCD13: chip.UCD13, codeATC: chip.sp_catc_code_fk };
        };
        /**
         * Called onChanged when new drug is added
         */
        this.add = () => {
            if (this.onChanged) {
                this.onChanged({ $event: { drugs: this.drugs } });
            }
        };
        /**
         * Called onChanged when a drug is remove
         */
        this.remove = () => {
            if (this.onChanged) {
                this.onChanged({ $event: { drugs: this.drugs } });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
    $onInit() {
        if (!this.drugs) {
            this.drugs = [];
        }
    }
    $onChanges(changes) {
        if (changes.drugs) {
            this.drugs = angular.copy(this.drugs);
        }
    }
}
