export const locale = {
    lang: 'en',
    data: {
        LOAN_BORROW_WIDGET: {
            TITLE: `Loans / Borrows`,
            LOAN: 'Loan',
            BORROW: 'Borrow',
            VOUCHER: {
                LOAN: 'Loan voucher',
                BORROW: `Borrow voucher`,
                RETURN: 'Return voucher'
            },
            SEE: 'See',
            UNIT: 'unit',
            NO_LOAN: 'No loans / borrows.',
            SEE_MORE: 'See more loans/borrows',
            FORBIDDEN_MESSAGE: 'Access denied',
            CONTACT_US: 'Contact us'
        }
    }
};
