var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ReleaseNotesService } from '@services/homepage/widgets/release-notes/release-notes.service';
import { lastValueFrom } from 'rxjs';
import { ReleaseNoteDetailsComponent } from './release-note-details/release-note-details.component';
import { ReleaseNotesListComponent } from './release-notes-list/release-notes-list.component';
const states = [
    {
        name: 'mapui.adm.homepage.release-notes.list',
        url: '/list',
        views: {
            'content@mapui': {
                component: ReleaseNotesListComponent
            }
        },
        data: {
            access: EMaPUIPermission.WidgetHome_Read,
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.homepage.release-notes.details',
        url: '/details/:id',
        views: {
            'content@mapui': {
                component: ReleaseNoteDetailsComponent
            }
        },
        data: {
            access: EMaPUIPermission.WidgetHome_Read,
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: false
            }
        },
        resolve: [
            {
                token: 'fullWidget',
                deps: [Transition, ReleaseNotesService],
                resolveFn: (trans, releaseNotesService) => {
                    const id = trans.params().id;
                    if (id !== 'new') {
                        return lastValueFrom(releaseNotesService.getReleaseNoteWithLinesById(id, false));
                    }
                }
            }
        ]
    }
];
let ReleaseNotesRoutingModule = class ReleaseNotesRoutingModule {
};
ReleaseNotesRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], ReleaseNotesRoutingModule);
export { ReleaseNotesRoutingModule };
