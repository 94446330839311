import { EActivityType, EHospivillePermission, EMpStatus, EPathways } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export const CActivityPathways = {
    classic: 'MAPUI.SERVICES.MAILS.PATHWAYS.CLASSIC',
    [EPathways.Octave]: 'MAPUI.SERVICES.MAILS.PATHWAYS.OCTAVE'
};
export class MailsService {
    /** @ngInject */
    constructor($q, Access, $http, Api) {
        this.$q = $q;
        this.Access = Access;
        this.$http = $http;
        this.Api = Api;
        this.sendMail = (mail) => {
            const defer = this.$q.defer();
            this.Api.mails.mails.send({}, { mail: mail }, (ans) => {
                defer.resolve(ans);
            }, (err) => {
                defer.reject(err);
            });
            return defer.promise;
        };
        /**
         *
         * @param body Already get mail & isSecured
         * @param sources
         * @param option
         */
        this.sendReconciliationSheet = (body, sources, option) => {
            const defer = this.$q.defer();
            body.type = EActivityType.SendMedReconciliation;
            body.patient = Utils.getId(this.Access.patient);
            if (!this.Access.authorize(EHospivillePermission.Mailbox_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mails.mails.send({}, body, (ans) => defer.resolve(ans), err => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Send prescription request to the given pharmacy
         * @param mail
         * @param isSecure
         * @param pharmacy
         * @param activityType: By default EActivityType.AskPrescription
         */
        this.sendPrescriptionRequest = (mail, isSecure, pharmacy, activityType = EActivityType.AskPrescription) => {
            const defer = this.$q.defer();
            let body = {
                type: activityType,
                isSecure: isSecure,
                mail: mail,
                patient: Utils.getId(this.Access.patient),
                dest: pharmacy.finessCode,
                medicationReconciliation: Utils.getId(this.Access.patient.medicationReconciliation),
            };
            if (!this.Access.authorize(EHospivillePermission.Mailbox_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.mails.mails.send(body, (ans) => defer.resolve(ans), (err) => defer.reject(err));
            }
            return defer.promise;
        };
    }
    ;
    sendMssRequest(member) {
        const defer = this.$q.defer();
        if (!this.Access.authorize(EHospivillePermission.Mailbox_Create)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.Api.mails.mssRequest.send(member, (ans) => defer.resolve(ans), err => defer.reject(err));
        }
        return defer.promise;
    }
    getNotSecureEmailsByEstablishmentId(hospital) {
        const defer = this.$q.defer();
        if (!hospital) {
            defer.reject(EMpStatus.NoDataProvided);
        }
        else if (!this.Access.authorize(EHospivillePermission.Activities_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.$http.get('/api/hospiville/activities/not-secure-emails')
                .then((res) => {
                defer.resolve(res);
            })
                .catch((err) => defer.reject(err));
        }
        return defer.promise;
    }
}
