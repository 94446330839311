var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectPosologyComponent } from '../../../shared/components/select-posology/select-posology.component';
import { locale as french } from './../../../hospiville/i18n/fr';
import { locale as english } from './../../../hospiville/i18n/en';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
let ExSelectPosologyComponent = class ExSelectPosologyComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.formControl = new UntypedFormControl();
        this.pageHeaderConfig = {
            title: 'Select Posology Component',
            icon: 'extension '
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
    }
};
__decorate([
    ViewChild(SelectPosologyComponent, { static: true }),
    __metadata("design:type", typeof (_b = typeof SelectPosologyComponent !== "undefined" && SelectPosologyComponent) === "function" ? _b : Object)
], ExSelectPosologyComponent.prototype, "posologyComponent", void 0);
ExSelectPosologyComponent = __decorate([
    Component({
        selector: 'ex-select-posology',
        template: require('./ex-select-posology.component.html').default,
        styles: [require('./ex-select-posology.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], ExSelectPosologyComponent);
export { ExSelectPosologyComponent };
