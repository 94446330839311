export class DifferentTo {
    /** @ngInject */
    constructor() {
        this.link = (scope, elem, attr, ctrl) => {
            if (!ctrl)
                return;
            const ngModel = ctrl[0];
            ngModel.$validators.match = (value) => {
                return value != scope.$eval(attr.differentTo);
            };
            scope.$watch(() => {
                return scope.$eval(attr.differentTo);
            }, () => {
                ngModel.$validate();
            });
        };
        this.restrict = 'A';
        this.require = ['ngModel'];
    }
    static factory() {
        const directive = () => new DifferentTo();
        directive.$inject = [];
        return directive;
    }
}
