export const locale = {
    lang: 'fr',
    data: {
        patients_list_sidebar: {
            TITLE: 'Filtres',
            APPLY_BUTTON: 'Appliquer',
            FILTERS: {
                STATUS: {
                    TITLE: 'Statuts',
                    MEDREC: 'Conciliation',
                    DRUG_BALANCE: 'Bilan médicamenteux'
                },
                MEDREC: {
                    MAIN: 'Conciliations',
                    ONLY_ACTIVE: 'Seulement "En cours"',
                    PROCESS: 'Processus',
                    TRANSIT: 'Type'
                },
                MEDREC_DATE: {
                    TITLE: 'Date de conciliation',
                    START: 'Depuis',
                    END: "Jusqu'au"
                },
                HOSP_SERVICE: 'Service',
                PATHWAYS: {
                    MAIN: 'Parcours',
                    NO_PATHWAY: 'Hors parcours'
                }
            }
        }
    }
};
