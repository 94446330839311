export class MpModalMoveComponent {
    constructor() {
        this.controller = 'MpModalMoveController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-move.html').default;
        this.bindings = {
            files: '<',
            root: '<',
        };
    }
}
