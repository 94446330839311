import { EActivityOrigin } from '@mapuilabs/mpl-interfaces';
export const CActivityOrigin = {
    hospital: {
        value: EActivityOrigin.Hospital,
        translate: 'activities.ORIGIN.HOSPITAL'
    },
    pharmacy: {
        value: EActivityOrigin.Pharmacy,
        translate: 'activities.ORIGIN.PHARMACY'
    }
};
