var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from '@main/services/Utils';
import * as moment from 'moment';
import { EquivalenceVoucherService } from '@app/services/equivalences/equivalence-voucher.service';
let EquivalenceVoucherComponent = class EquivalenceVoucherComponent {
    constructor(_equivalenceVoucherService, _dialogRef, data) {
        this._equivalenceVoucherService = _equivalenceVoucherService;
        this._dialogRef = _dialogRef;
        this.data = data;
        /**
         * Save and close dialog
         */
        this.save = () => {
            if (!(this.voucher.service && this.voucher.patientName)) {
                return;
            }
            if (!Utils.hasId(this.voucher)) {
                this.voucher.dateDelivery = moment().format('l');
                this.voucher.equivalence = this.equivalence;
                this._equivalenceVoucherService.add(this.voucher).subscribe((voucher) => {
                    this._dialogRef.close(voucher);
                });
            }
            else {
                this._equivalenceVoucherService.save(this.voucher).subscribe((voucher) => {
                    this._dialogRef.close(voucher);
                });
            }
        };
    }
    get pdfLink() {
        return `/pdf-generation/voucher-equivalence.pdf?pdfmodel=voucher-equivalence&equivalence_id=${this.equivalence._id}&s=${this.voucher.service}&n=${this.voucher.patientName}&r=${this.voucher.reason}&p=${this.voucher.patientRoom}&date=${this.voucher.dateDelivery}`;
    }
    ngOnInit() {
        this.equivalence = this.data.equivalence;
        this.voucher = this.data.voucher || {
            dateDelivery: '',
            dosageMedic: '',
            patientName: '',
            patientRoom: '',
            service: '',
            hospital: null,
            reason: null,
            equivalence: null
        };
    }
};
EquivalenceVoucherComponent = __decorate([
    Component({
        selector: 'mpx-equivalence-voucher',
        template: require('./equivalence-voucher.component.html').default
    }),
    __param(2, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof EquivalenceVoucherService !== "undefined" && EquivalenceVoucherService) === "function" ? _a : Object, typeof (_b = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _b : Object, Object])
], EquivalenceVoucherComponent);
export { EquivalenceVoucherComponent };
