var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { AdminLaboratoryService } from '@services/admin/laboratory/admin-laboratory.service';
import { PermissionService } from '@services/permission/permission.service';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let AdminListLabosComponent = class AdminListLabosComponent extends ListPage {
    constructor(_adminLaboratoryService, _fuseTranslationLoaderService, _permissionService, _injector) {
        super(_injector, _adminLaboratoryService.getPage);
        this._adminLaboratoryService = _adminLaboratoryService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._permissionService = _permissionService;
        this._injector = _injector;
        this.displayedColumns = ['name', 'nbMembers', 'roles'];
        this.canAddLabo = this._permissionService.authorize(EAdminPanelPermission.Establishments_Create);
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translationService.instant('LABOS.TITLE'),
            searchBarPlaceholder: this._translationService.instant('LABOS.SEARCH'),
            svgIcon: 'preparation'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_e = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _e : Object)
], AdminListLabosComponent.prototype, "_paginator", void 0);
AdminListLabosComponent = __decorate([
    Component({
        selector: 'mpx-list-labos',
        template: require('./list-labos.component.html').default,
        styles: [require('./list-labos.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminLaboratoryService !== "undefined" && AdminLaboratoryService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _c : Object, typeof (_d = typeof Injector !== "undefined" && Injector) === "function" ? _d : Object])
], AdminListLabosComponent);
export { AdminListLabosComponent };
