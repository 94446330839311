var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Hospital, Preparation } from '@mapuilabs/mpl-interfaces';
import { PrepConditions, PrepSubCategory } from '@shared/constTypes/preparation/preparation.const';
import { IdentityService } from '@services/identity/identity.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
let EditPrepaModalComponent = class EditPrepaModalComponent {
    constructor(_fuseTranslationLoaderService, _dialogRef, _identityService, prepa) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._dialogRef = _dialogRef;
        this._identityService = _identityService;
        this.prepa = prepa;
        this.prepSubCategory = PrepSubCategory;
        this.prepConditions = PrepConditions;
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl((_a = this.prepa) === null || _a === void 0 ? void 0 : _a.name, Validators.required),
            dosage: new UntypedFormControl((_b = this.prepa) === null || _b === void 0 ? void 0 : _b.dosage, Validators.required),
            type: new UntypedFormControl((_c = this.prepa) === null || _c === void 0 ? void 0 : _c.type, Validators.required),
            galenicForm: new UntypedFormControl((_d = this.prepa) === null || _d === void 0 ? void 0 : _d.galenicForm, Validators.required),
            volume: new UntypedFormControl((_e = this.prepa) === null || _e === void 0 ? void 0 : _e.volume),
            conservationInst: new UntypedFormControl((_f = this.prepa) === null || _f === void 0 ? void 0 : _f.conservationInst.array, Validators.required),
            excipient: new UntypedFormControl((_g = this.prepa) === null || _g === void 0 ? void 0 : _g.excipient),
            prepAdministration: new UntypedFormControl((_h = this.prepa) === null || _h === void 0 ? void 0 : _h.prepAdministration, Validators.required),
            unitPrice: new UntypedFormControl((_j = this.prepa) === null || _j === void 0 ? void 0 : _j.unitPrice)
        });
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    closeModal() {
        if (this.form.invalid || this.form.pristine) {
            return;
        }
        const prepa = this.prepa ||
            new Preparation({
                name: this.form.controls.name.value,
                dosage: this.form.controls.dosage.value,
                type: this.form.controls.type.value,
                galenicForm: this.form.controls.galenicForm.value,
                volume: this.form.controls.volume.value,
                conservationInst: {
                    array: this.form.controls.conservationInst.value
                },
                excipient: this.form.controls.excipient.value,
                prepAdministration: this.form.controls.prepAdministration.value,
                unitPrice: this.form.controls.unitPrice.value,
                hospital: this._identityService.hospital
            });
        if (this.prepa) {
            prepa.name = this.form.controls.name.value;
            prepa.dosage = this.form.controls.dosage.value;
            prepa.type = this.form.controls.type.value;
            prepa.galenicForm = this.form.controls.galenicForm.value;
            prepa.volume = this.form.controls.volume.value;
            prepa.conservationInst.array = this.form.controls.conservationInst.value;
            prepa.excipient = this.form.controls.excipient.value;
            prepa.prepAdministration = this.form.controls.prepAdministration.value;
            prepa.unitPrice = Number(this.form.controls.unitPrice.value);
            prepa.hospital = new Hospital(this._identityService.hospital);
        }
        this._dialogRef.close(prepa);
    }
};
EditPrepaModalComponent = __decorate([
    Component({
        selector: 'mpx-edit-prepa-modal',
        template: require('./edit-prepa-modal.component.html').default,
        styles: [require('./edit-prepa-modal.component.scss')]
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _b : Object, typeof (_c = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _c : Object, typeof (_d = typeof Preparation !== "undefined" && Preparation) === "function" ? _d : Object])
], EditPrepaModalComponent);
export { EditPrepaModalComponent };
