export const locale = {
    lang: 'en',
    data: {
        HOSPIVILLE_TOOLBAR: {
            LABELS: {
                CONSENT: 'Consent:',
                MEDRECS: 'Reconciliations',
                CHANGE_PATIENT: 'Change patient',
                NEW_PATIENT: 'New patient',
                SELECTED_MEDREC: 'Selected reconciliation:',
                CHANGE_SELECTED_MEDREC: 'Change reconciliation'
            }
        }
    }
};
