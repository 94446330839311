var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import { ConfigurationComponent } from './configuration/configuration/configuration.component';
const states = [
    {
        name: 'hospivilleV2.activities',
        url: '/acts',
        abstract: true
    },
    {
        name: 'hospivilleV2.configuration',
        url: '/configuration-v2',
        views: {
            'content@hospivilleV2': {
                component: ConfigurationComponent
            }
        },
        data: {
            theme: 'theme-light-green',
            access: EHospivillePermission.Configuration_Read,
            hasSensitiveData: true
        }
    },
    {
        name: 'hospivilleV2.patients',
        url: '/patients',
        abstract: true
    }
];
let HospivilleRoutingModule = class HospivilleRoutingModule {
};
HospivilleRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], HospivilleRoutingModule);
export { HospivilleRoutingModule };
