export class MpLastFilesController {
    /** @ngInject */
    constructor(FilesService, Access, Api, $mdSidenav) {
        this.FilesService = FilesService;
        this.Access = Access;
        this.Api = Api;
        this.$mdSidenav = $mdSidenav;
        /**
         * Open the the given [[IFile]] details sidenav
         * @param elem
         */
        this.select = (elem) => {
            this.selected = elem;
            this.$mdSidenav('details-sidenav').open();
        };
        this.FilesService.getFilesByFilter('Date', 10, this.Access.user).then((ans) => {
            this.files = ans;
        });
        this.Api.filesType.get((ans) => {
            this.filesType = ans;
        });
    }
    ;
}
