var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityASchema, EActivityStatus, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { SelectPosologyComponent } from '@shared/components/select-posology/select-posology.component';
import { CActivityStatuses } from '@shared/constTypes/activity/activity-status.const';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { ActivitiesInstancesService } from '@services/hospiville/activities/activities-instances.service';
import { DrugInstanceService } from '@services/hospiville/drug-instance/drug-instance.service';
import { PosologyService } from '@services/hospiville/posology/posology.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { ActivityDestService } from '@services/hospiville/activities/activities-dest.service';
import { DialogService } from '@services/dialog/dialog.service';
let AddDrugBalanceTabComponent = class AddDrugBalanceTabComponent {
    constructor(_activitiesInstancesService, _activityService, _fuseTranslationLoaderService, _translationService, _posologyService, _snackBarService, _drugInstanceService, _dialogService) {
        this._activitiesInstancesService = _activitiesInstancesService;
        this._activityService = _activityService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._posologyService = _posologyService;
        this._snackBarService = _snackBarService;
        this._drugInstanceService = _drugInstanceService;
        this._dialogService = _dialogService;
        this.cActivityStatuses = CActivityStatuses;
        /**
         * Emits when the activity has been modified.
         */
        this.onActivityChanged = new EventEmitter();
        //Tells if the activity is OVER
        this.isFinished = false;
        /**
         * Change the activiy status to the given status
         * @param status
         */
        this.changeStatus = (status) => {
            this.activity.status = status;
            this._activityService.save(this.activity).subscribe((updatedActivity) => {
                if (updatedActivity) {
                    this.onActivityChanged.emit();
                    this._snackBarService.open(this._translationService.instant(`ACTIVITY_DETAILS.SNACKBAR.MODIFIED`));
                }
                else {
                    this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                }
            }, (error) => this._snackBarService.openError(error));
        };
        this.markAsFinished = () => {
            const dialogConfig = {
                title: this._translationService.instant('ADD_DRUG_BALANCE_TAB.FINISH_CONFIRM.TITLE'),
                message: this._translationService.instant('ADD_DRUG_BALANCE_TAB.FINISH_CONFIRM.MESSAGE'),
                maxWidth: '500px'
            };
            this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
                if (data) {
                    this.changeStatus(EActivityStatus.AddedToConciliation);
                }
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this.formGroup = new UntypedFormGroup({
            drugDosagePosology: new UntypedFormGroup({})
        });
    }
    ngOnChanges(changes) {
        if (changes.activity.currentValue) {
            this.isFinished =
                this.activity.status === CActivityStatuses.canceled.value ||
                    this.activity.status === CActivityStatuses.ended.value ||
                    this.activity.status === CActivityStatuses.medRec.value;
            this._activitiesInstancesService.getByActivity(this.activity).subscribe((res) => {
                this.simpleInstances = res;
            });
        }
    }
    ngOnDestroy() {
        this.onActivityChanged.unsubscribe();
    }
    get canManage() {
        return !this.isFinished && this.activity.status != this.cActivityStatuses.addedToConciliation.value;
    }
    addInstance(newInstance) {
        if (this._getInstanceDuplicate(newInstance) >= 0) {
            this._snackBarService.openCustomError(this._translationService.instant('ADD_DRUG_BALANCE_TAB.ALREADY_ADDED'), null, { duration: 3000 });
            return;
        }
        this.formGroup.controls.dosageType.reset(); // Needs to be reset first because it will trigger _updateDosageType
        this.formGroup.reset();
        this._activitiesInstancesService.add(this.activity, [newInstance]).subscribe((res) => {
            this.simpleInstances.push(...res);
            this._snackBarService.open(this._translationService.instant('ADD_DRUG_BALANCE_TAB.ADDED'));
        }, () => {
            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
        });
    }
    /**
     * Check if newInstance don't already exists in list
     * @private
     * @param instance
     */
    _getInstanceDuplicate(instance) {
        return this.simpleInstances.findIndex((inst) => {
            return (instance.UCD13 === inst.UCD13 &&
                instance.DCI === inst.DCI &&
                instance.denomination === inst.denomination);
        });
    }
    deleteInstance(instance) {
        this._activitiesInstancesService.delete(instance).subscribe(() => {
            this.simpleInstances.splice(this._getInstanceDuplicate(instance), 1);
            this._snackBarService.open(this._translationService.instant('ADD_DRUG_BALANCE_TAB.DELETED'));
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_j = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _j : Object)
], AddDrugBalanceTabComponent.prototype, "activity", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_k = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _k : Object)
], AddDrugBalanceTabComponent.prototype, "onActivityChanged", void 0);
__decorate([
    ViewChild(SelectPosologyComponent, { static: false }),
    __metadata("design:type", typeof (_l = typeof SelectPosologyComponent !== "undefined" && SelectPosologyComponent) === "function" ? _l : Object)
], AddDrugBalanceTabComponent.prototype, "_selectPosology", void 0);
AddDrugBalanceTabComponent = __decorate([
    Component({
        selector: 'add-drug-balance-tab',
        template: require('./add-drug-balance-tab.component.html').default,
        styles: [require('./add-drug-balance-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ActivitiesInstancesService !== "undefined" && ActivitiesInstancesService) === "function" ? _a : Object, typeof (_b = typeof ActivityDestService !== "undefined" && ActivityDestService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof PosologyService !== "undefined" && PosologyService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object, typeof (_g = typeof DrugInstanceService !== "undefined" && DrugInstanceService) === "function" ? _g : Object, typeof (_h = typeof DialogService !== "undefined" && DialogService) === "function" ? _h : Object])
], AddDrugBalanceTabComponent);
export { AddDrugBalanceTabComponent };
