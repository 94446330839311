var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e;
import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { Access } from '@main/services/auth/access.service';
import { MemberService } from '@app/services/member/member.service';
let EditEquivalenceComponent = class EditEquivalenceComponent {
    constructor(dialogRef, data, _fuseTranslationLoaderService, _access, _memberService, _fb) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._access = _access;
        this._memberService = _memberService;
        this._fb = _fb;
        this.selectedAutocomplete = (drug, obj, elem) => {
            obj[elem] = drug.denomination;
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        var _a, _b, _c, _d;
        this.editEquivalence = !!((_a = this.data) === null || _a === void 0 ? void 0 : _a.equivalence);
        this.equivalence = ((_b = this.data) === null || _b === void 0 ? void 0 : _b.equivalence) || {
            medicName: '',
            medicDci: '',
            medicPosology: '',
            substitutions: [
                {
                    name: '',
                    dci: ''
                },
                {
                    name: '',
                    dci: ''
                }
            ],
            type: null,
            delivery: '',
            reason: '',
            comment: '',
            hospital: null,
            vouchers: []
        };
        if (this.equivalence.substitutions.length === 1) {
            // Add a second substitution for the form to work less annoyingly
            // It is removed on submit if it hasn't changed
            this.equivalence.substitutions.push({ name: '', dci: '' });
        }
        this.form = this._fb.group({
            medicDci: this.equivalence.medicDci,
            equiDci1: this.equivalence.substitutions[0].dci,
            equiDci2: (_d = (_c = this.equivalence.substitutions) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.dci,
            posology: this.equivalence.medicPosology,
            delivery: this.equivalence.delivery,
            reason: this.equivalence.reason,
            comment: this.equivalence.comment,
            groups: this.equivalence.groups
        });
        this.groups = this._access.hospital.groups;
        if (this._access.user._id) {
            this._memberService.getById(this._access.user._id).subscribe((member) => {
                if (member.groups) {
                    this.groups = this.groups.concat(member.groups);
                }
            });
        }
    }
    submitForm() {
        var _a, _b, _c, _d;
        if (this.form.invalid) {
            return;
        }
        this.equivalence.medicDci = this.form.controls['medicDci'].value;
        this.equivalence.substitutions[0].dci = this.form.controls['equiDci1'].value;
        this.equivalence.substitutions[1].dci = this.form.controls['equiDci2'].value;
        this.equivalence.medicPosology = this.form.controls['posology'].value;
        this.equivalence.delivery = this.form.controls['delivery'].value;
        this.equivalence.reason = this.form.controls['reason'].value;
        this.equivalence.comment = this.form.controls['comment'].value;
        this.equivalence.groups = this.form.controls['groups'].value;
        if (this.equivalence.substitutions.length == 2 &&
            ((_b = (_a = this.equivalence.substitutions) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.name) === '' &&
            ((_d = (_c = this.equivalence.substitutions) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.dci) === '') {
            // Remove second substitution if it wasn't used
            this.equivalence.substitutions.pop();
        }
        this.dialogRef.close(this.equivalence);
    }
    onInputChanges(value, obj, elem) {
        obj[elem] = value;
    }
};
EditEquivalenceComponent = __decorate([
    Component({
        selector: 'mpx-edit-equivalence',
        template: require('./edit-equivalence.component.html').default
    }),
    __param(1, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _a : Object, Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof Access !== "undefined" && Access) === "function" ? _c : Object, typeof (_d = typeof MemberService !== "undefined" && MemberService) === "function" ? _d : Object, typeof (_e = typeof FormBuilder !== "undefined" && FormBuilder) === "function" ? _e : Object])
], EditEquivalenceComponent);
export { EditEquivalenceComponent };
