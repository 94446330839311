var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { lastValueFrom } from 'rxjs';
import { AdminHospitalService } from '../../services/admin/hospital/admin-hospital.service';
import { ActivitiesDetailByHospitalComponent } from './activities/activities-detail-by-hospital/activities-detail-by-hospital.component';
import { ListActivitiesComponent } from './activities/list-activities/list-activities.component';
import { HospitalDetailComponent } from './hospiville/stats-hospiville/hospital-detail/hospital-detail.component';
import { StatsHospivilleComponent } from './hospiville/stats-hospiville/stats-hospiville.component';
const states = [
    {
        name: 'mapui.adm.stats.hospiville',
        url: '/hospiville?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: StatsHospivilleComponent
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.Statistics_Read
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.stats.hospiville.detail',
        url: '/hospiville/detail/:id?period',
        views: {
            'content@mapui': {
                component: HospitalDetailComponent
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.Statistics_Read
        },
        params: {
            id: {
                dynamic: false
            },
            period: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'hospital',
                deps: [Transition, AdminHospitalService],
                resolveFn: (trans, adminHospitalService) => {
                    return lastValueFrom(adminHospitalService.getById(trans.params().id));
                }
            }
        ]
    },
    {
        name: 'mapui.adm.stats.activities',
        url: '/activities?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: ListActivitiesComponent
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.Statistics_Read
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.stats.activities.detail',
        url: '/activities/detail/:id?period',
        views: {
            'content@mapui': {
                component: ActivitiesDetailByHospitalComponent
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.Statistics_Read
        },
        params: {
            id: {
                dynamic: false
            },
            period: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'hospital',
                deps: [Transition, AdminHospitalService],
                resolveFn: (trans, adminHospitalService) => {
                    return lastValueFrom(adminHospitalService.getById(trans.params().id));
                }
            }
        ]
    }
];
let StatsRoutingModule = class StatsRoutingModule {
};
StatsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], StatsRoutingModule);
export { StatsRoutingModule };
