var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
let InformationTabComponent = class InformationTabComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    get theriaqueNames() {
        return this.form.get('theriaqueNames').value;
    }
    set theriaqueNames(value) {
        this.form.patchValue({ theriaqueNames: value });
    }
    get industrials() {
        return this.form.get('industrials').value;
    }
    set industrials(value) {
        this.form.patchValue({ industrials: value });
    }
    onLaboratoryExpSelected(laboratoryExp) {
        this.theriaqueNames = [
            ...this.theriaqueNames,
            { name: laboratoryExp.cdf_nom, code: laboratoryExp.cdf_code_pk }
        ];
        this.form.markAsDirty();
    }
    onLaboratoryExpDelete(index) {
        this.theriaqueNames.splice(index, 1);
        this.theriaqueNames = [...this.theriaqueNames];
        this.form.markAsDirty();
    }
    onIndustrialSelected(industrial) {
        this.industrials = [...this.industrials, { name: industrial.name }];
        this.form.markAsDirty();
    }
    onIndustrialDelete(index) {
        this.industrials.splice(index, 1);
        this.industrials = [...this.industrials];
        this.form.markAsDirty();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], InformationTabComponent.prototype, "form", void 0);
InformationTabComponent = __decorate([
    Component({
        selector: 'mpx-information-tab',
        template: require('./information-tab.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], InformationTabComponent);
export { InformationTabComponent };
