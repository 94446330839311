export class HvSelectPosologyComponent {
    constructor() {
        this.controller = 'HvSelectPosologyController';
        this.controllerAs = 'vm';
        this.template = require('./hv-select-posology.html').default;
        this.bindings = {
            posologies: '<',
            isRequired: '<',
            form: '<',
            name: '<',
            onChange: '&',
            id: '@',
        };
    }
}
