import { EstablishmentService } from './establishment.service';
import { MpSearchEstablishmentTypeController } from './mp-search-establishment-type/mp-search-establishment-type.controller';
import { MpSearchEstablishmentTypeComponent } from './mp-search-establishment-type/mp-search-establishment-type.component';
import { MpModalCreateOtherEstablishmentController } from './mp-modal-create-other-establishment/mp-modal-create-other-establishment.controller';
import { MpModalCreateOtherEstablishmentComponent } from './mp-modal-create-other-establishment/mp-modal-create-other-establishment.component';
import * as angular from 'angular';
angular.module('mapui.services.establishment', [])
    .service('EstablishmentService', EstablishmentService)
    .controller('MpSearchEstablishmentTypeController', MpSearchEstablishmentTypeController)
    .component('mpSearchEstablishmentType', new MpSearchEstablishmentTypeComponent())
    .controller('MpModalCreateOtherEstablishmentController', MpModalCreateOtherEstablishmentController)
    .component('mpModalCreateOtherEstablishment', new MpModalCreateOtherEstablishmentComponent());
