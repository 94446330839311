var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable, Injector } from '@angular/core';
import { TranslationService } from '../../translation/translation.service';
import { CMedicineDepartments } from '../../../shared/constTypes/medication-reconciliation/medicine-departments.const';
import { CrudTemplateService } from '../../../shared/templates/crud/crud.template';
import { EHospivillePermission, EHTTPStatus, MedicationReconciliation } from '@mapuilabs/mpl-interfaces';
import { forkJoin, throwError } from 'rxjs';
import { Utils } from '@main/services/Utils';
let MedicationReconciliationService = class MedicationReconciliationService extends CrudTemplateService {
    constructor(__injector, _translationService) {
        super(__injector, MedicationReconciliation, '/api/hospiville/patients/medication-reconciliation', {
            create: EHospivillePermission.MedicationReconciliation_Edit,
            read: EHospivillePermission.MedicationReconciliation_Read,
            update: EHospivillePermission.MedicationReconciliation_Edit,
            delete: EHospivillePermission.MedicationReconciliation_Edit
        });
        this.__injector = __injector;
        this._translationService = _translationService;
        /**
         * All the medicine department static definitions
         */
        this._medicineDepartments = CMedicineDepartments;
    }
    /**
     * Matches all medicine departments, e.g. : search 'P' result : 'Pédiatrie, Pneumologie, Psychatrie'
     * @param query representing the searched medicine department
     * @return array of all medicine departments matching the query
     */
    searchDepartment(query) {
        return this._medicineDepartments.filter((_medicineDepartment) => {
            const lowerName = this._translationService.instant(_medicineDepartment).toLowerCase();
            if (query) {
                return lowerName.indexOf(query.toLowerCase()) === 0;
            }
            return true;
        });
    }
    /**
     * Find one medRec for the given patient
     * @param patient
     * @param medRed
     */
    findOneByPatient(patient, medRed) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!patient || !medRed) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEnpoint = `/api/hospiville/patients/${patient}/medication-reconciliations/${medRed}`;
        return this._http.get(fullEnpoint);
    }
    /**
     * Get all medrecs for the given patient;
     * @param patient
     */
    findAllByPatient(patient) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!patient) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const observables = [];
        const fullEnpoint = `/api/hospiville/patients/${Utils.getId(patient)}/medication-reconciliations`;
        for (const mr of patient === null || patient === void 0 ? void 0 : patient.medicationReconciliations) {
            observables.push(this._http.get(`${fullEnpoint}/${Utils.getId(mr)}`));
        }
        return forkJoin(observables);
    }
};
MedicationReconciliationService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], MedicationReconciliationService);
export { MedicationReconciliationService };
