import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class ConfigurationService {
    /** @ngInject */
    constructor(Api, Access, $q) {
        this.Api = Api;
        this.Access = Access;
        this.$q = $q;
    }
    /**
     * Get all HospitalService from current hospital
     * @param hospital
     */
    getHospitalServices(hospital) {
        const defer = this.$q.defer();
        this.Api.configuration.all({}, (result) => {
            defer.resolve(result);
        }, () => defer.reject(EMpStatus.RejectByServer));
        return defer.promise;
    }
    /**
     * Add a hospital Service
     * @param service
     */
    addService(service) {
        let defer = this.$q.defer();
        this.Api.configuration.add(service, (result) => defer.resolve(result), () => defer.reject(EMpStatus.RejectByServer));
        return defer.promise;
    }
    /**
     * Update a Hospital Service
     * @param service
     */
    saveService(service) {
        let defer = this.$q.defer();
        this.Api.configuration.save(service, (item) => defer.resolve(item), () => defer.reject(EMpStatus.RejectByServer));
        return defer.promise;
    }
}
