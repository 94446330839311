import { EFileType, ELoanCat, ELoanState, EMpStatus, ETransportType, } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as angular from 'angular';
import { ModalLoanConditions } from './mp-modal-loan.conditions';
export class MpModalLoanCreatedController {
    /** @ngInject */
    constructor(Access, ModalService, MemberService, StatusService, MedicService, Api, FileUploaderService, $q, $translate, _) {
        this.Access = Access;
        this.ModalService = ModalService;
        this.MemberService = MemberService;
        this.StatusService = StatusService;
        this.MedicService = MedicService;
        this.Api = Api;
        this.FileUploaderService = FileUploaderService;
        this.$q = $q;
        this.$translate = $translate;
        this._ = _;
        /**
         *
         * @returns {boolean}
         */
        this.isValidationDisabled = () => {
            return false;
        };
        /**
         * Return if the [[ILoan]] needs acceptation step
         * @returns {boolean}
         */
        this.showValidationTab = () => {
            return this.StatusService.needAcceptation(this.loan);
        };
        /**
         * Return the string value of validation step
         * @returns {string}
         */
        this.validationStep = () => {
            return '2';
        };
        /**
         * Return if [[ILoan]] state is before confirmation step
         * @returns {boolean}
         */
        this.isConfirmationDisabled = () => {
            return this.state < ELoanState.Confirmation;
        };
        // region FORM DISPLAY
        /**
         * Show confirmation
         * @returns {boolean}
         */
        this.showConfirmationTab = () => {
            return true;
        };
        /**
         * Return the string value of confirmation step
         * @returns {string}
         */
        this.confirmationStep = () => {
            if (this.StatusService.needAcceptation(this.loan)) {
                return '3';
            }
            return '2';
        };
        /**
         * Return if [[ILoan]] state is before valid reception step
         * @returns {boolean}
         */
        this.isCommandConfirmationDisabled = () => {
            return this.state < ELoanState.ValidReception;
        };
        /**
         * Return if [[ILoan]] is preparation type
         * @returns {boolean}
         */
        this.showCommandConfirmationTab = () => {
            return this.StatusService.isPrep(this.loan);
        };
        /**
         * Return the string value of validate reception step
         * @returns {string}
         */
        this.commandConfirmationStep = () => {
            return '4';
        };
        /**
         * Return if [[ILoan]] state is before return step
         * @returns {boolean}
         */
        this.isReturnDisabled = () => {
            return this.state < ELoanState.Return;
        };
        /**
         * Return if [[ILoan]] needs to be return
         * @returns {boolean}
         */
        this.showReturnTab = () => {
            return (this.StatusService.isReturn(this.loan));
        };
        /**
         * Return string value step depending of [[ILoan]] type
         * @returns {string}
         */
        this.returnStep = () => {
            if (this.StatusService.needAcceptation(this.loan)) {
                if (this.StatusService.isPrep(this.loan)) {
                    return '5';
                }
                return '4';
            }
            return '3';
        };
        /**
         * Return if [[ILoan]] state is before return validation step
         * @returns {boolean}
         */
        this.isReturnValidDisabled = () => {
            return this.state < ELoanState.ReturnValidation;
        };
        /**
         * Same as returnStep function  TODO clean
         * @returns {string}
         */
        this.returnValidStep = () => {
            if (this.StatusService.needAcceptation(this.loan)) {
                return '4';
            }
            return '3';
        };
        /**
         * Function adding new [[ILoanLot]] to the [[ILoan]]
         */
        this.addPrepLot = () => {
            this.loan.lots.push({
                name: '',
                expiryDate: null,
                quantity: 0,
                limit: this.loan.quantity,
            });
        };
        /**
         * Remove lot at given index
         */
        this.deletePrepLot = (index) => {
            if (index > 0) {
                this.loan.lots.splice(index, 1);
                this.calcPrepQuantityRet();
            }
        };
        /**
         * Return quantity calculation
         */
        this.calcPrepQuantityRet = () => {
            let maxPrepQuantity = this.loan.quantity;
            let q_ret = 0;
            this.loan.quantityRet = 0;
            for (let i = 0; i < this.loan.lots.length; ++i) {
                q_ret += this.loan.lots[i].quantity;
            }
            this.loan.quantityRet = q_ret;
            maxPrepQuantity -= this.loan.quantityRet;
            for (let i = 0; i < this.loan.lots.length; ++i) {
                this.loan.lots[i].limit = this.loan.lots[i].quantity + maxPrepQuantity;
            }
        };
        /**
         * Reset the CIP13 and UCD13 code
         * Allow free text as entry
         * Called when the search text change
         */
        this.medicTextChanged = () => {
            this.loan.returnCIP13 = null;
            this.loan.returnUCD13 = null;
            this.loan.returnMedicName = this.autoCtrl.medic.search;
        };
        /**
         * Return an array containing all matches from value
         * @returns A promise containing an Array of string
         */
        this.searchMedics = () => {
            return this.MedicService.findByName(this.autoCtrl.medic.search);
        };
        // endregion
        // region PREPARATION
        /**
         * Set the selected medicine in the [[ILoan]]
         */
        this.searchMedicsSelected = () => {
            if (this.autoCtrl.medic.selected) {
                this.loan.returnCIP13 = this.autoCtrl.medic.selected.CIP13;
                this.loan.returnUCD13 = this.autoCtrl.medic.selected.UCD13;
                this.loan.returnMedicName = this.autoCtrl.medic.selected.denomination;
            }
            else {
                this.loan.returnCIP13 = null;
                this.loan.returnUCD13 = null;
            }
        };
        /**
         * Set the new [[IMember]] [[Id]] of the [[ILoan]] validator
         * @param model The new [[IMember]] [[Id]]
         */
        this.selectValidStaff = ({ model }) => {
            if (!model) {
                return;
            }
            this.loan.validPerson = { _id: model };
        };
        // endregion
        /**
         * Set the new [[IMember]] [[Id]] of the [[ILoan]] lending person
         * @param model The new [[IMember]] [[Id]]
         */
        this.selectLendingStaff = ({ model }) => {
            if (!model) {
                return;
            }
            this.loan.loanPerson = { _id: model };
        };
        /**
         * Set the new [[IMember]] [[Id]] of the [[ILoan]] borrowing person
         * @param model The new [[IMember]] [[Id]]
         */
        this.selectBorrowingStaff = ({ model }) => {
            if (!model) {
                return;
            }
            this.loan.borrowingPerson = { _id: model };
        };
        /**
         * Set the new [[IMember]] [[Id]] of the [[ILoan]] receiver person
         * @param model
         */
        this.selectReceiveStaff = ({ model }) => {
            if (!model) {
                return;
            }
            this.loan.steCheckPerson = { _id: model };
        };
        /**
         * Set the new [[IMember]] [[Id]] of the [[ILoan]] returning person
         * @param model The new [[IMember]] [[Id]]
         */
        this.selectReturnStaff = ({ model }) => {
            if (!model) {
                return;
            }
            this.loan.returnPerson = { _id: model };
        };
        /**
         * Submit the form
         * Perform some check before hands using the controller knowledge
         */
        this.submit = () => {
            // Save loan modifications (name, quantity, ...) without impacting status
            if (this._selectedTab === 'creation') {
                return this._close({ loan: this.loan, state: ELoanState.Edition });
            }
            let type;
            switch (this.state) {
                case ELoanState.Validation:
                    break;
                case ELoanState.Confirmation:
                    if (this.StatusService.isPrep(this.loan)) {
                        type = EFileType.AttachPrep;
                    }
                    break;
                case ELoanState.Return:
                    break;
            }
            this.FileUploaderService.upload(type)
                .then((items) => {
                const promises = [];
                for (let item of items) {
                    if (ELoanState.Validation === this.state) {
                        item.type = EFileType.AttachSteValid;
                    }
                    else if (ELoanState.Confirmation === this.state && this.StatusService.isPrep(this.loan)) {
                        item.type = EFileType.AttachPrep;
                    }
                    const defer = this.$q.defer();
                    promises.push(defer.promise);
                    this.Api.files.add(item, (file) => {
                        if (ELoanState.Validation === this.state) {
                            if (!this.loan.filesValidation) {
                                this.loan.filesValidation = [];
                            }
                            this.loan.filesValidation.push(file);
                        }
                        else if (ELoanState.Confirmation === this.state) {
                            if (!this.loan.filesConfirmation) {
                                this.loan.filesConfirmation = [];
                            }
                            this.loan.filesConfirmation.push(file);
                        }
                        else if (ELoanState.Return === this.state) {
                            if (!this.loan.filesReturn) {
                                this.loan.filesReturn = [];
                            }
                            this.loan.filesReturn.push(file);
                        }
                        defer.resolve();
                    });
                }
                this.$q.all(promises).then(() => {
                    this._close({ loan: this.loan, state: this.state });
                });
            })
                .catch(err => {
                if (err == EMpStatus.NoDataProvided) {
                    this._close({ loan: this.loan, state: this.state });
                }
            });
            for (let f of this._filesToDel) {
                this.Api.files.delete({ id: f._id });
            }
        };
        /**
         * Callback when an uploaded file is deleted by user
         * @param {IFile} f
         */
        this.fileDelete = (f) => {
            if (ELoanState.Validation === this.state) {
                Utils.removeObjectInstanceInArray(this.loan.filesValidation, f);
            }
            else if (ELoanState.Confirmation === this.state) {
                Utils.removeObjectInstanceInArray(this.loan.filesConfirmation, f);
            }
            else if (ELoanState.Return === this.state) {
                Utils.removeObjectInstanceInArray(this.loan.filesReturn, f);
            }
            this._filesToDel.push(f);
        };
        /**
         * Force validation step
         */
        this.forceValid = () => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.TITLE',
                        text: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.FORCE_VALID',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                this.loan.forcedValid = true;
                this.loan.updateItem = true;
                this.submit();
            });
        };
        /**
         * Force confirmation step
         */
        this.forceConfirm = () => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.TITLE',
                        text: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.FORCE_CONFIRM',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                this.loan.forcedConfirm = true;
                this.loan.updateItem = true;
                this.submit();
            });
        };
        /**
         * Force return step
         */
        this.forceReturn = () => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.TITLE',
                        text: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.FORCE_RETURN',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                this.loan.forcedReturn = true;
                this.loan.returnDate = new Date();
                this.loan.quantityRet = this.loan.quantity;
                this.loan.updateItem = true;
                this.submit();
            });
        };
        /**
         * On tab changed
         * @param {string} label
         */
        this.onTabChanged = (label) => {
            this._selectedTab = label;
        };
        /**
         * Select a drug with mp-search-drug component (from autocomplete)
         * @param drug
         */
        this.selectDrug = (drug) => {
            this.loan.medicName = drug.denomination;
            this.loan.UCD13 = drug.UCD13;
            this.loan.laboratory_exp = drug.laboratory_exp;
            this.loan.laboratory_tit = drug.laboratory_tit;
            this.loan.codeATC = drug.sp_catc_code_fk;
        };
        /**
         * Select a return drug with mp-search-drug component (from autocomplete)
         * @param drug
         */
        this.selectReturnDrug = (drug) => {
            this.loan.returnMedicName = drug.denomination;
            this.loan.returnUCD13 = drug.UCD13;
        };
        /**
         * Should hide submit button
         * @return {boolean}
         */
        this.shouldHideSubmitButton = () => {
            return this._selectedTab === 'creation' && !Utils.compareIds(this.Access.hospital, this.loan.lender);
        };
        /**
         *
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         * @private
         */
        this._close = (answer) => {
            this.FileUploaderService.uploader.clearQueue();
            this.ModalService.close(answer);
        };
        this.FileUploaderService.init(true);
        this.LoanState = ELoanState;
        this.conditions = new ModalLoanConditions(StatusService, Access);
        this._filesToDel = [];
        this.autoCtrl = {
            anc: {
                selected: null,
                search: '',
            },
            medic: {
                selected: null,
                search: null,
            },
        };
        this.prepTransport = [
            {
                label: this.$translate.instant('MAPUI.SERVICES.LOAN.MODAL.TABS.CONTENT.INPUTS.PREP_DELIVERY'),
                value: ETransportType.Delivery,
            },
            {
                label: this.$translate.instant('MAPUI.SERVICES.LOAN.MODAL.TABS.CONTENT.INPUTS.PREP_FETCH'),
                value: ETransportType.Fetch,
            },
        ];
    }
    $onInit() {
        this.uploader = this.FileUploaderService.uploader;
        this.Api.constType.get((res) => {
            this.constTypes = res;
        });
        this.MemberService.getByHosp(this.Access.hospital._id).then((staff) => {
            this.myStaff = staff;
            if (!this.StatusService.isMaD(this.loan) && !this.StatusService.isRequest(this.loan)) {
                if (this.StatusService.amIL(this.loan)) {
                    this.MemberService.getByHosp(this.loan.borrower._id).then((staff) => {
                        this.staff = this.myStaff.concat(staff);
                    });
                }
                else {
                    this.MemberService.getByHosp(this.loan.lender._id).then((staff) => {
                        this.staff = this.myStaff.concat(staff);
                    });
                }
            }
        });
        this.state = this.StatusService.calcStep(this.loan);
        if (ELoanCat.Prep == this.loan.loanCategoryCreation && ELoanState.Confirmation == this.state && !this.loan.lots) {
            this.loan.lots = [];
            this.addPrepLot();
        }
        if (this.loan.filesValidation) {
            this._.forEach(this.loan.filesValidation, (f, i) => {
                this.Api.files.get({ id: f._id || f }, (file) => {
                    this.loan.filesValidation[i] = file || null;
                });
            });
        }
        if (this.loan.filesConfirmation) {
            this._.forEach(this.loan.filesConfirmation, (f, i) => {
                this.Api.files.get({ id: f._id || f }, (file) => {
                    this.loan.filesConfirmation[i] = file || null;
                });
            });
        }
        if (this.loan.filesReturn) {
            this._.forEach(this.loan.filesReturn, (f, i) => {
                this.Api.files.get({ id: f._id || f }, (file) => {
                    this.loan.filesReturn[i] = file || null;
                });
            });
        }
        if (ELoanState.Return === this.state) {
            if (this.conditions.isMed(this.loan) || this.conditions.isDM(this.loan)) {
                this.loan.returnMedicName = this.loan.medicName;
                // this.loan.returnDate = new Date();
                this.loan.quantityRet = this.loan.quantity;
                this.loan.returnLotNumber = this.loan.lotNumber;
            }
        }
    }
    $onChanges(changes) {
        if (changes.loan) {
            this.loan = angular.copy(this.loan);
            this.autoCtrl.medic.search = this.loan.medicName;
        }
    }
}
