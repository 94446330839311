export class mpTab {
    /** @ngInject */
    constructor() {
        this.compile = (tElement) => {
            tElement.addClass('mp-tabs-tab');
            return function postLink(scope, iElement, iAttrs, ctrls) {
                const MpTabsCtrl = ctrls[0];
                // Is it an optional step
                // Register the step
                MpTabsCtrl.registerTab(iElement, scope);
                // Hide the step by default
                iElement.hide();
            };
        };
        this.restrict = 'E';
        this.require = ['^mpTabs'];
        this.priority = 1000;
        this.scope = {
            index: '=?',
            tabTitle: '=?',
        };
    }
    static factory() {
        const directive = () => new mpTab();
        directive.$inject = [];
        return directive;
    }
}
