export const locale = {
    lang: 'en',
    data: {
        ERROR_404: {
            TITLE: '404',
            CONTENT: 'Sorry but we could not find the page you are looking for',
            LINK: 'Go back to home'
        }
    }
};
