var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { FileSaverService } from 'ngx-filesaver';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
let HistoryService = class HistoryService {
    constructor(_httpClient, _fileSaverService) {
        this._httpClient = _httpClient;
        this._fileSaverService = _fileSaverService;
    }
    /**
     * Get all history items
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {boolean} includeAdmins
     * @param type
     * @param actions
     * @return {Q.Promise<Array<IHistory>>}
     */
    exportLogs() {
        return __awaiter(this, void 0, void 0, function* () {
            const spreadsheetEndPoint = '/xlsx-generation/stockout.xlsx?xlsxmodel=auth-logs';
            const start = new Date();
            const end = new Date();
            end.setDate(end.getDate() - 14);
            let params = new HttpParams();
            params = params.append('start', start.toISOString());
            params = params.append('end', end.toISOString());
            const headers = new HttpHeaders({
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const res = yield lastValueFrom(this._httpClient.get(spreadsheetEndPoint, {
                params: params,
                headers: headers,
                responseType: 'arraybuffer'
            }));
            const blob = new Blob([res], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            this._fileSaverService.save(blob, `login-stats.xls`);
        });
    }
};
HistoryService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object, typeof (_b = typeof FileSaverService !== "undefined" && FileSaverService) === "function" ? _b : Object])
], HistoryService);
export { HistoryService };
