var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { AdminHsNewsComponent } from './admin-hs-news.component';
const states = [
    {
        name: 'mapui.adm.hospistock.news',
        url: '/news',
        views: {
            'content@mapui': {
                component: AdminHsNewsComponent
            }
        },
        data: {
            theme: 'theme-purple',
            access: EAdminPanelPermission.HsStockoutConfig_Read
        }
    }
];
let AdminHsNewsRoutingModule = class AdminHsNewsRoutingModule {
};
AdminHsNewsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], AdminHsNewsRoutingModule);
export { AdminHsNewsRoutingModule };
