export class MpModalVoucherComponent {
    constructor() {
        this.controller = 'MpModalVoucherController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-voucher.html').default;
        this.bindings = {
            loan: '<',
            voucherType: '<',
        };
    }
}
