export class HvPatientPathwaysController {
    /** @ngInject */
    constructor(Api, $translate) {
        this.Api = Api;
        this.$translate = $translate;
        /**
         * A flag to show the button to add a pathway
         */
        this.showAddButton = false;
        this._showPathwaySelector = false;
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this._cPathways = ans.pathways;
            this._refreshUnusedPathways();
        });
    }
    _refreshUnusedPathways() {
        var _a, _b, _c;
        this.unusedPathways = Array.from(this._cPathways);
        if (((_b = (_a = this.patient) === null || _a === void 0 ? void 0 : _a.pathways) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            for (let p of (_c = this.patient) === null || _c === void 0 ? void 0 : _c.pathways) {
                let pIndex = this.unusedPathways.findIndex((e) => e.value === p);
                if (pIndex >= 0) {
                    this.unusedPathways.splice(pIndex, 1);
                }
            }
        }
    }
    get pathwaysLabels() {
        var _a, _b;
        if (this._cPathways) {
            let res = {};
            if (((_b = (_a = this.patient) === null || _a === void 0 ? void 0 : _a.pathways) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                for (let p of this.patient.pathways) {
                    let enumVal = this._cPathways.find((e) => e.value === p);
                    res[enumVal.value] = this.$translate.instant(enumVal.translate);
                }
            }
            return res;
        }
    }
    get showPathwaySelector() {
        return this._showPathwaySelector;
    }
    doShowPathwaySelector() {
        this._showPathwaySelector = true;
    }
    doNotShowPathwaySelector() {
        this._showPathwaySelector = false;
    }
    cancelAddPathway() {
        this.selectedPathway = null;
        this.doNotShowPathwaySelector();
    }
    get canAddPathway() {
        var _a;
        return ((_a = this.unusedPathways) === null || _a === void 0 ? void 0 : _a.length) > 0;
    }
    addPathway() {
        if (!this.patient.pathways) {
            this.patient.pathways = [];
        }
        this.patient.pathways.push(this.selectedPathway);
        this.onSavePathway({});
        this._refreshUnusedPathways();
        this.cancelAddPathway();
    }
    removePathway(pathway) {
        this.patient.pathways = this.patient.pathways.filter((p) => p !== pathway);
        this.onSavePathway({});
        this._refreshUnusedPathways();
        this.cancelAddPathway();
    }
}
