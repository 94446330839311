var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { ActivityASchema, EActivityStatus, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CGender } from '@shared/constTypes/member/member-gender.const';
import { CPatientConsentStatus } from '@shared/constTypes/consent/consent-status.const';
import { CMedRecTransit } from '@shared/constTypes/medication-reconciliation/medRec-transit.const';
import { CActivityStatuses } from '@shared/constTypes/activity/activity-status.const';
import { CActivityTypes } from '@shared/constTypes/activity/activity-type.const';
import { RolesService } from '@services/role/roles.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { ActivityDestService } from '@services/hospiville/activities/activities-dest.service';
import { ImportFilesModalComponent } from '../import-files-modal/import-files-modal.component';
import { take } from 'rxjs/operators';
let ActionsTabDestComponent = class ActionsTabDestComponent {
    constructor(_rolesService, _activityService, _matDialog, _snackBarService, _stateService, _translationService) {
        this._rolesService = _rolesService;
        this._activityService = _activityService;
        this._matDialog = _matDialog;
        this._snackBarService = _snackBarService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this.cGender = CGender;
        this.cPatientConsentStatus = CPatientConsentStatus;
        this.cMedRecTransit = CMedRecTransit;
        this.cActivityStatuses = CActivityStatuses;
        this.cActivityTypes = CActivityTypes;
        /**
         * Emits when the activity has been modified.
         */
        this.onActivityChanged = new EventEmitter();
        /**
         * Emits when the user wants to go to another tab by clicking on a button in the tab content.
         */
        this.goToTabIndex = new EventEmitter();
        /**
         * Emits when the tooltip on the save button of the parent component should be toggled.
         */
        this.showEndedActivityTooltip = new EventEmitter();
        this.isFinished = false;
        this.isWaiting = false;
        this.isCanceled = false;
        this.getTranslation = (from, value, interpolateParams) => {
            return this._translationService.getTranslation(from, value, interpolateParams);
        };
        /**
         * Opens a modal to import multiple files about the activity.
         */
        this.openImportFilesModal = () => {
            this._matDialog
                .open(ImportFilesModalComponent, { disableClose: false, maxWidth: '750px' })
                .afterClosed()
                .pipe(take(1))
                .subscribe((files) => {
                if (files) {
                    this._activityService
                        .addFiles(this.activity, files, this.isFinished ? EActivityStatus.EndedWithFiles : EActivityStatus.Files)
                        .pipe(take(1))
                        .subscribe((updatedActivity) => {
                        if (updatedActivity && updatedActivity._id === this.activity._id) {
                            this.onActivityChanged.emit(); // Refresh this.activity @Input()
                            this._snackBarService.open(this._translationService.instant('ACTIVITY_DETAILS.SNACKBAR.FILES_ADDED'));
                        }
                        else {
                            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
    }
    ngOnChanges(changes) {
        if (changes.activity) {
            this.isFinished =
                this.activity.status === this.cActivityStatuses.ended.value ||
                    this.activity.status === this.cActivityStatuses.medRec.value ||
                    this.activity.status === this.cActivityStatuses.endedWithFiles.value;
            this.isWaiting = this.activity.status === this.cActivityStatuses.waiting.value;
            this.isCanceled = this.activity.status === this.cActivityStatuses.canceled.value;
        }
    }
    toggleEndCancelTooltip() {
        if (this.isFinished) {
            switch (this.activity.status) {
                case EActivityStatus.MedRec:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.GOT_MEDREC');
                case EActivityStatus.Ended:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.ALREADY_ENDED');
                case EActivityStatus.Canceled:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.ALREADY_CANCELED');
            }
        }
        return '';
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _g : Object)
], ActionsTabDestComponent.prototype, "activity", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_h = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _h : Object)
], ActionsTabDestComponent.prototype, "onActivityChanged", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_j = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _j : Object)
], ActionsTabDestComponent.prototype, "goToTabIndex", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_k = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _k : Object)
], ActionsTabDestComponent.prototype, "showEndedActivityTooltip", void 0);
ActionsTabDestComponent = __decorate([
    Component({
        selector: 'hvx-actions-tab-dest',
        template: require('./actions-tab.component.html').default,
        encapsulation: ViewEncapsulation.None,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof RolesService !== "undefined" && RolesService) === "function" ? _a : Object, typeof (_b = typeof ActivityDestService !== "undefined" && ActivityDestService) === "function" ? _b : Object, typeof (_c = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object])
], ActionsTabDestComponent);
export { ActionsTabDestComponent };
