var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsFileService } from '@app/hospistock/shared/services/hs-file.service';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EFileType, EHsHealthProductTypes, EHsPublicationType, EHsUserTypes, HsPublicationDrugASchema, HsPublicationMedicalDeviceASchema } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { IdentityService } from '@services/identity/identity.service';
import { MedicService } from '@services/medic/medic.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { UploadService } from '@services/upload/upload.service';
import { CustomValidators } from '@shared/commons/validators';
import { FileDragNDropComponent, FileDragNDropReadType } from '@shared/components/file-drag-n-drop/file-drag-n-drop.component';
import { regExpFirstZerosAndSpecialCharacters } from '@shared/constTypes/reg-exp.const';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { lastValueFrom, map, of, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
let HsModalPublicationFormComponent = class HsModalPublicationFormComponent {
    constructor(_changeDetectorRef, _dialogService, _fuseTranslationLoaderService, _identityService, _medicService, _snackbarService, _fileService, _translationService, _uploadService, _fb, _dialogRef, data) {
        this._changeDetectorRef = _changeDetectorRef;
        this._dialogService = _dialogService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._identityService = _identityService;
        this._medicService = _medicService;
        this._snackbarService = _snackbarService;
        this._fileService = _fileService;
        this._translationService = _translationService;
        this._uploadService = _uploadService;
        this._fb = _fb;
        this._dialogRef = _dialogRef;
        this.data = data;
        this.fileDragNDropReadType = FileDragNDropReadType;
        this.eFileType = EFileType.PublicationAttachment;
        this.isSaving = false;
        this.ePublicationType = EHsPublicationType;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this.eHsUserTypes = EHsUserTypes;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        // Bind controller's services to injector's services
        this._healthProductService = this.data.injector.get(HsHealthProductService);
        this._publicationContainerService = this.data.injector.get(HsPublicationContainerService);
        // Init defaults
        this.healthProducts = [];
        // Init constants
        this.dynamicDataContainerConfig = {
            loader: {
                label: this._translationService.instant('PUBLICATION.MODAL.FORM.SAVE_IN_PROGRESS')
            }
        };
    }
    get types() {
        return this._publicationContainerService.types;
    }
    get viewType() {
        return this._publicationContainerService.viewType;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a, _b, _c, _d;
        // Init defaults
        this.mode = this.data.publication ? 'EDIT' : 'NEW';
        this.publication = this.data.publication || new this._publicationContainerService.publicationConstructor();
        // Generate form controls
        this.formType = this._fb.group({
            type: [this.publication.type, Validators.required],
            regulatoryQuota: [this.publication.regulatoryQuota === undefined ? null : this.publication.regulatoryQuota],
            remaindersConservation: this._fb.group({
                active: [
                    ((_a = this.publication.remaindersConservation) === null || _a === void 0 ? void 0 : _a.active) === undefined
                        ? null
                        : (_b = this.publication.remaindersConservation) === null || _b === void 0 ? void 0 : _b.active
                ],
                description: [((_c = this.publication.remaindersConservation) === null || _c === void 0 ? void 0 : _c.description) || null]
            }),
            transferLabo: [this.publication.transferLabo]
        });
        // Define broadcast FormGroup only for Labo View
        if (this._publicationContainerService.viewType === EHsUserTypes.LABO) {
            const isTargetGroupsEmpty = _.isEmpty(this.publication.targetGroups);
            this.formBroadcast = this._fb.group({
                targetGroups: [
                    this.publication.targetGroups || [],
                    isTargetGroupsEmpty ? CustomValidators.truthy : Validators.required
                ]
            });
            this.broadcastAllControl = new UntypedFormControl(isTargetGroupsEmpty);
            // Update targetGroups control validators depending on broadcastAllControl changes
            this.broadcastAllControl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((change) => {
                const targetGroupsControl = this.formBroadcast.get('targetGroups');
                if (change) {
                    targetGroupsControl.setValidators(CustomValidators.truthy);
                    targetGroupsControl.setValue([]);
                }
                else {
                    targetGroupsControl.setValidators(Validators.required);
                }
                // Update control
                targetGroupsControl.markAsDirty();
                targetGroupsControl.updateValueAndValidity();
            });
        }
        this.formInfo = this._fb.group({
            information: [this.publication.information],
            depositary: [this.publication.depositary],
            wholesaler: [this.publication.wholesaler]
        });
        this.formHealthProductRef = this._fb.group({
            healthProductRef: this._healthProductService.handleHealthProductRef((drugRef) => this._fb.group({
                name: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.name, Validators.required],
                ref: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.ref, [Validators.required, CustomValidators.ucd7or13]],
                ATC: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.ATC],
                laboratoryTit: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.laboratoryTit],
                operator: [drugRef === null || drugRef === void 0 ? void 0 : drugRef.operator],
                healthProducts: [(drugRef === null || drugRef === void 0 ? void 0 : drugRef.healthProducts) || [], Validators.required]
            }), (medicalDeviceRef) => this._fb.group({
                name: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.name, Validators.required],
                ref: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.ref],
                operator: [medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.operator, Validators.required],
                healthProducts: [(medicalDeviceRef === null || medicalDeviceRef === void 0 ? void 0 : medicalDeviceRef.healthProducts) || [], Validators.required]
            }), this.publication.healthProductRef),
            manual: [this.publication.manual]
        });
        this.formDates = this._fb.group({
            // If modifying a publication, set the start date validator to false if type is ShortExpiration or Quota.
            startDate: [
                this.publication.startDate,
                this._isStartDateOptional(this.publication.type) ? null : Validators.required
            ],
            endDate: [this.publication.endDate]
        });
        this.formFiles = this._fb.group({
            files: [this.publication.files || []]
        });
        // Subscribe to HospistockService Depositary
        this._publicationContainerService
            .getDepositary()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: (depositaries) => {
                this.depositaries = depositaries;
                if (this.mode === 'EDIT') {
                    if (this.publication.depositary) {
                        this.formInfo
                            .get('depositary')
                            .setValue(this.depositaries.find((item) => item._id === Utils.getId(this.publication.depositary)));
                    }
                }
            }
        });
        // Subscribe to HospistockService Wholesaler
        this._publicationContainerService
            .getWholesaler()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: (wholesalers) => {
                this.wholesalers = wholesalers;
                if (this.mode === 'EDIT') {
                    if (this.publication.wholesaler) {
                        this.formInfo
                            .get('wholesaler')
                            .setValue(this.wholesalers.find((item) => item._id === Utils.getId(this.publication.wholesaler)));
                    }
                }
            }
        });
        // Bind the search method for the autocomplete
        this.formHealthProductRef.get('healthProductRef.operator').valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(500), filter((value) => (value === null || value === void 0 ? void 0 : value.length) > 0), switchMap((value) => this._searchLaboExpInTheriaqueNames(value)));
        // Update startDate validators depending on type
        this.formType
            .get('type')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: (value) => {
                // Update startDate validator, so it is not required when type equals ShortExpiration or Quota
                if (this._isStartDateOptional(value)) {
                    this.formDates.get('startDate').clearValidators();
                }
                else {
                    this.formDates.get('startDate').setValidators(Validators.required);
                    this.formDates.markAsDirty();
                }
                this.formDates.get('startDate').updateValueAndValidity();
            }
        });
        // Update remaindersConservation description value when active value changes
        this.formType
            .get('remaindersConservation.active')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
            next: (value) => {
                if (!value) {
                    this.formType.get('remaindersConservation.description').setValue(null);
                }
            }
        });
        // Subscribe to health product changes to update existing health product flag
        this.publicationAlreadyExists$ = this.formHealthProductRef
            .get('healthProductRef.healthProducts')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll), switchMap(() => this._publicationAlreadyExists()));
        // Get labo's Hospistock config
        if (this._publicationContainerService.viewType === EHsUserTypes.LABO) {
            // Get theriaque names from labo for drug form component
            if (this.healthProductType === EHsHealthProductTypes.DRUG) {
                this.theriaqueNames = this._identityService.hospital.theriaqueNames;
                if (!this.theriaqueNames) {
                    throw new Error('Hospistock config error, no theriaqueNames provided for laboratory');
                }
            }
            // Get industrial from labo for medical-device form component
            if (this.healthProductType === EHsHealthProductTypes.MEDICAL_DEVICE) {
                this.industrials = this._identityService.hospital.industrials;
                if (((_d = this.industrials) === null || _d === void 0 ? void 0 : _d.length) === 0) {
                    throw new Error('Hospistock config error, no industrial provided for laboratory');
                }
                if (!this.formHealthProductRef.get('healthProductRef.operator').value) {
                    this.formHealthProductRef.patchValue({ healthProductRef: { operator: this.industrials[0].name } });
                }
            }
        }
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    optionSelected(selected) {
        this.formHealthProductRef.get('healthProductRef.operator').setValue(selected.name);
    }
    optionSelectedLabo(selected) {
        this.formType.get('transferLabo').setValue(selected);
    }
    /**
     * Check if all forms are valid and pristine
     */
    isInvalidOrPristine() {
        const forms = _.compact([
            this.formType,
            this.formBroadcast,
            this.formInfo,
            this.formHealthProductRef,
            this.formDates,
            this.formFiles
        ]);
        return forms.some((form) => form.invalid) || !forms.some((form) => !form.pristine);
    }
    /**
     * Submit form by gathering all form and user values and uploading related files
     */
    submitForm() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Prevent invalid form
            if (this.isInvalidOrPristine()) {
                return;
            }
            // Get form values
            const formValues = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.formInfo.value), (_a = this.formBroadcast) === null || _a === void 0 ? void 0 : _a.value), this.formType.value), this.formHealthProductRef.value), this.formDates.value), this.formFiles.value);
            try {
                // Upload files
                yield this._uploadFileSet();
                // Add files to publication
                if (this.fileContainerSet && this.fileContainerSet.size) {
                    this.fileContainerSet.forEach((file) => {
                        var _a;
                        if (file.error || !file.response || !file.response.body) {
                            console.error(`The file named '${(_a = file.file) === null || _a === void 0 ? void 0 : _a.name}' contains error or no response.`);
                            return; // Don't push the file
                        }
                        // Add uploaded file to form
                        this.formFiles.get('files').value.push(file.response.body);
                    });
                }
            }
            catch (err) {
                this._handleError(err);
                return;
            }
            // Get user values
            const userValues = {
                creator: Utils.getId(this.mode == 'NEW' ? this._identityService.user.hospital : this.publication.creator)
            };
            // Update publication
            Object.assign(this.publication, formValues, userValues);
            // Set isSaving status
            this.isSaving = true;
            const publication = this._healthProductService.handleHealthProductRef(
            // Handle HsDrugRef
            () => {
                // Convert to UCD13
                this.publication.healthProductRef.ref = Utils.ucd7ToUcd13(this.publication.healthProductRef.ref);
                return new HsPublicationDrugASchema(this.publication);
            }, 
            // Handle HsMedicalDeviceRef
            () => {
                // Clean medical devices ref
                this.publication.healthProductRef.healthProducts.forEach((healthProduct) => {
                    healthProduct.ref = healthProduct.ref.replace(regExpFirstZerosAndSpecialCharacters, '');
                });
                return new HsPublicationMedicalDeviceASchema(this.publication);
            });
            this._dialogRef.close(publication);
        });
    }
    /**
     * Handle onSelectionChanged file-uploader output
     */
    onFileSelectionChanged(fileContainerSet) {
        this.fileContainerSet = fileContainerSet;
        this.formFiles.markAsDirty();
    }
    clearStartDate() {
        this.formDates.get('startDate').reset(null);
        this.formDates.markAsDirty();
    }
    clearEndDate() {
        this.formDates.get('endDate').reset(null);
        this.formDates.markAsDirty();
    }
    /**
     * Download file using url parameter
     * @param fileObj
     */
    downloadFile(fileObj) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const buffer = yield lastValueFrom(this._uploadService.downloadFile(fileObj.url));
                const blob = new Blob([buffer]);
                const file = new File([blob], fileObj.name);
                saveAs(file); // Save in user's file system
            }
            catch (err) {
                this._snackbarService.openError(err);
                console.error(err);
            }
        });
    }
    /**
     * Delete file from publication
     * @param fileToDelete File to delete
     */
    deleteFile(fileToDelete) {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogConfig = {
                title: this._translationService.instant('PUBLICATION.MESSAGES.FILES.DELETE.TITLE'),
                message: this._translationService.instant('PUBLICATION.MESSAGES.FILES.DELETE.CONTENT'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            const actionConfirmed = yield lastValueFrom(this._dialogService.openConfirmDialog(dialogConfig));
            if (actionConfirmed) {
                try {
                    // Delete file from db & server
                    yield lastValueFrom(this._fileService.delete(fileToDelete));
                    // Delete file from local list
                    const files = this.formFiles.get('files').value;
                    const index = files.findIndex((file) => {
                        return file._id === fileToDelete._id;
                    });
                    if (index !== -1) {
                        files.splice(index, 1);
                        this.formFiles.markAsDirty();
                        // Update publication in db
                        Object.assign(this.publication, { files });
                    }
                    // Manually trigger rendering if view not destroyed.
                    // View can be destroyed if method called after a server request.
                    // In this case view can be destroyed if modal closed before delete response.
                    if (!this._changeDetectorRef['destroyed']) {
                        this._changeDetectorRef.detectChanges();
                    }
                }
                catch (err) {
                    this._snackbarService.openError(err);
                    console.error(err);
                    return;
                }
                this._snackbarService.open(this._translationService.instant('COMMONS.DELETED'));
            }
        });
    }
    scrollToSectionHook(event, stepper) {
        const stepId = stepper._getStepLabelId(event.selectedIndex);
        const stepElement = document.getElementById(stepId);
        if (stepElement) {
            stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
        }
    }
    _searchLaboExp(value) {
        return this._medicService.findLaboratoryExp(value).pipe(map((elem) => elem.map((tmp) => ({
            name: tmp.cdf_nom,
            code: tmp.cdf_code_pk
        }))));
    }
    _searchLaboExpInTheriaqueNames(value) {
        if (this.formHealthProductRef.get('manual').value) {
            if (this._publicationContainerService.viewType === EHsUserTypes.LABO) {
                return of(this._identityService.hospital.theriaqueNames.filter((elem) => elem.name.toUpperCase().includes(value.toUpperCase())));
            }
            else {
                return this._searchLaboExp(value);
            }
        }
    }
    _handleError(err) {
        console.error(err);
        this._snackbarService.open(this._translationService.instant('PUBLICATION.NOTIFS.ERROR'), undefined, {
            panelClass: 'warn'
        });
        this._dialogRef.close();
    }
    /**
     * Upload File Set and add Returned IFile(s) to publication
     */
    _uploadFileSet() {
        if (!this.fileContainerSet || !this.fileContainerSet.size) {
            return;
        }
        this._uploadService.uploadAndSave(this.fileContainerSet, this.eFileType);
        const uploadPromises = [];
        this.fileContainerSet.forEach((file) => {
            file.progress.subscribe({
                next: (value) => (file.response = value),
                error: (err) => (file.error = err),
                complete: () => (file.completed = true)
            });
            uploadPromises.push(lastValueFrom(file.progress));
        });
        return Promise.all(uploadPromises);
    }
    /**
     * If the entry enum value is ShortExpiration then start date is optional.
     * @param type : EHsPublicationType
     */
    _isStartDateOptional(type) {
        return type === EHsPublicationType.ShortExpiration;
    }
    _publicationAlreadyExists() {
        return __awaiter(this, void 0, void 0, function* () {
            const healthProductsRef = this.formHealthProductRef.get('healthProductRef.healthProducts').value;
            if (this.formHealthProductRef.get('manual').value || (healthProductsRef === null || healthProductsRef === void 0 ? void 0 : healthProductsRef.length) === 0) {
                return false;
            }
            return lastValueFrom(this._publicationContainerService.publicationExistsForUserFromHealthProductsRef(healthProductsRef.map(({ ref }) => ref), Utils.getId(this.publication)));
        });
    }
};
__decorate([
    ViewChild(FileDragNDropComponent, { static: false }),
    __metadata("design:type", typeof (_m = typeof FileDragNDropComponent !== "undefined" && FileDragNDropComponent) === "function" ? _m : Object)
], HsModalPublicationFormComponent.prototype, "fileDragNDrop", void 0);
HsModalPublicationFormComponent = __decorate([
    Component({
        selector: 'mpx-hs-modal-publication-form',
        template: require('./hs-modal-publication-form.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush,
        animations: fuseAnimations
    }),
    __param(11, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof DialogService !== "undefined" && DialogService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof MedicService !== "undefined" && MedicService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object, typeof (_g = typeof HsFileService !== "undefined" && HsFileService) === "function" ? _g : Object, typeof (_h = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _h : Object, typeof (_j = typeof UploadService !== "undefined" && UploadService) === "function" ? _j : Object, typeof (_k = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _k : Object, typeof (_l = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _l : Object, Object])
], HsModalPublicationFormComponent);
export { HsModalPublicationFormComponent };
