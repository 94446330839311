import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class ResetPasswordController {
    /** @ngInject */
    constructor(Auth, Access, Api, ToastService, $state) {
        this.Auth = Auth;
        this.Access = Access;
        this.Api = Api;
        this.ToastService = ToastService;
        this.$state = $state;
        /**
         * Submit new password and set a new password expireDate
         */
        this.submit = () => {
            this.Api.changePassword.save({
                _id: this.Auth.user._id,
                password: this.password.password,
                oldPwd: this.password.oldPwd,
            }, (member) => {
                this.Auth.user.activation = false;
                this.$state.go('mapui.home');
            }, () => {
                this.invalidPasswords.push(this.password.oldPwd);
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        this.password = { memberId: this.Auth.user._id, oldPwd: '', password: '' };
        this.invalidPasswords = [];
        this.askForCurrentPassword = !this.Access.user.activation;
    }
}
