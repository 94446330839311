var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import { ActivityDestService } from '@services/hospiville/activities/activities-dest.service';
import { ActivitySourceService } from '@services/hospiville/activities/activities-source.service';
import { ActivityDetailsDestComponent } from './activity-details-dest/activity-details-dest.component';
import { ActivityDetailsFileToDbsComponent } from './activity-details-file-to-dbs/activity-details-file-to-dbs.component';
import { ActivityDetailsSourceComponent } from './activity-details-source/activity-details-source.component';
import { ActivitiesSourceFilesService } from '@services/hospiville/activities/activities-source-files.service';
import { lastValueFrom } from 'rxjs';
const states = [
    {
        name: 'hospivilleV2.activities.source.details',
        url: '/details/:id?fileId',
        data: {
            theme: 'theme-light-green',
            access: EHospivillePermission.Activities_Read
        },
        views: {
            'content@hospivilleV2': {
                component: ActivityDetailsSourceComponent
            }
        },
        params: {
            id: { dynamic: true },
            fileId: { dynamic: true }
        },
        resolve: [
            {
                token: 'activity',
                deps: [Transition, ActivitySourceService],
                resolveFn: (trans, activityService) => {
                    return lastValueFrom(activityService.getById(trans.params().id));
                }
            }
        ]
    },
    {
        name: 'hospivilleV2.activities.source.details.file-to-dbs',
        url: '/file-to-dbs',
        data: {
            theme: 'theme-light-green',
            access: EHospivillePermission.Activities_Read
        },
        views: {
            'content@hospivilleV2': {
                component: ActivityDetailsFileToDbsComponent
            }
        },
        resolve: [
            {
                token: 'file',
                deps: [Transition, ActivitiesSourceFilesService],
                resolveFn: (trans, fileService) => {
                    return lastValueFrom(fileService.getById(trans.params().fileId.replace(/"/g, '')));
                }
            }
        ]
    },
    {
        name: 'hospivilleV2.activities.dest.details',
        url: '/details/:id',
        data: {
            theme: 'theme-light-green',
            access: EHospivillePermission.Activities_Read
        },
        views: {
            'content@hospivilleV2': {
                component: ActivityDetailsDestComponent
            }
        },
        params: {
            id: { dynamic: true },
            fileId: { dynamic: true }
        },
        resolve: [
            {
                token: 'activity',
                deps: [Transition, ActivityDestService],
                resolveFn: (trans, activityService) => {
                    return lastValueFrom(activityService.getById(trans.params().id));
                }
            }
        ]
    }
];
let ActivityDetailsRoutingModule = class ActivityDetailsRoutingModule {
};
ActivityDetailsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], ActivityDetailsRoutingModule);
export { ActivityDetailsRoutingModule };
