import * as angular from 'angular';
export class MpSidenavMemberController {
    /** @ngInject */
    constructor(Api) {
        this.Api = Api;
        this.Api.constType.get((ans) => {
            this.constTypes = ans;
        });
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.member) {
            this.member = angular.copy(this.member);
        }
    }
}
