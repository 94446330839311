import { EAnswer, } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvTreatmentsInterviewController {
    /** @ngInject */
    constructor(Api, _) {
        this.Api = Api;
        this._ = _;
        /**
         *
         * @type {EAnswer}
         */
        this.EAnswer = EAnswer;
        /**
         * Set given [[IDrugInstanceSource]] posology
         * @param posology
         * @param instSource
         */
        this.posologyChange = ({ posology }, instSource) => {
            instSource.posology = posology;
        };
        /**
         * Set given [[IDrugInstanceSource]] dosage
         * @param {number} quantity
         * @param {number} type
         * @param {number} dropInfo
         * @param {IDrugInstanceSource} instSource
         */
        this.dosageChange = ({ quantity, type, dropInfo }, instSource) => {
            if (!instSource)
                instSource = {};
            instSource.dosage = quantity;
            instSource.dosageType = type;
            if (type == 14) {
                instSource.dosageDropInfo = dropInfo;
            }
        };
        /**
         * Set a given [[IDrugInstance]] [[IMedUCD]]
         * @param drug
         * @param inst
         * @param index
         */
        this.selectDrugFor = (event, inst, index) => {
            inst.denomination = event.drug.denomination;
            inst.UCD13 = event.drug.UCD13;
            inst.codeATC = event.drug.codeATC;
            if (!inst.validSource)
                inst.validSource = {};
            if (this._.find(this.constTypes.dosages, { value: +event.pharmaForm }) && !inst.validSource.dosageType && event.pharmaForm) {
                inst.validSource.dosageType = Number(event.pharmaForm);
            }
            this.treatment.instances.splice(index, 1, angular.copy(inst));
        };
        /**
         * Add a new [[IDrugInstance]] to the current [[IDrugBalance]]
         */
        this.addDrug = () => {
            this.treatment.instances.push({});
        };
        /**
         * Delete a [[IDrugInstance]] from the [[IDrugBalance]]
         * @param inst
         * @param index
         */
        this.deleteInst = (inst, index) => {
            this.treatment.instances.splice(index, 1);
        };
        /**
         * Save [[IPatient]]
         */
        this.save = () => {
            if (this.onSave) {
                this.onSave({ treatment: this.treatment });
            }
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
    $onChanges(changes) {
        if (changes.treatment) {
            this.treatment = angular.copy(this.treatment);
        }
    }
}
