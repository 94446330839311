import { ELoanCat, EMpStatus, EVoucherEmergency, EVoucherVolume, EVoucherType, EVoucherWeight } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MpModalVoucherController {
    /** @ngInject */
    constructor(ModalService, DashboardService, Access, $translate, StatusService) {
        this.ModalService = ModalService;
        this.DashboardService = DashboardService;
        this.Access = Access;
        this.$translate = $translate;
        this.StatusService = StatusService;
        /**
         * Final [[ILoan]] list to add in the voucher
         */
        this.voucherList = [];
        this.pdfComment = "";
        this.eVoucherEmergency = EVoucherEmergency;
        this.eVoucherVolume = EVoucherVolume;
        this.eVoucherWeight = EVoucherWeight;
        this.transport = {
            emergency: undefined,
            service: undefined,
            nbrPackage: undefined,
            volume: undefined,
            weight: undefined
        };
        /**
         * Edit suggested loans list
         * @param item
         */
        this.toggleSuggestedLoans = (item) => {
            let idx = this.voucherList.indexOf(Utils.getId(item));
            if (idx > -1) {
                this.voucherList.splice(idx, 1);
            }
            else {
                this.voucherList.push(Utils.getId(item));
            }
        };
        /**
         * Check if the selected loan exist in final list
         * @param item
         */
        this.exists = (item) => {
            return this.voucherList.indexOf(Utils.getId(item)) > -1;
        };
        /**
         * Filter [[ILoan]]s regarding search input
         */
        this.filterLoans = () => {
            if (!this.loanSearch) {
                this.filteredLoans = this.suggestedLoans;
                return;
            }
            this.filteredLoans = this.suggestedLoans.filter((item) => {
                if (Utils.normalize(item.medicName).match(Utils.normalize(this.loanSearch)))
                    return true;
            });
        };
        /**
         * Generate the download link for voucher PDF
         * @returns {string}
         */
        this.generatePdfLink = () => {
            if (this.doesDisableSubmitButton)
                return;
            if (this.voucherType === EVoucherType.Loan)
                return `/pdf-generation/bon-de-pret.pdf?pdfmodel=voucher&loans=${this.voucherList}&type=loan&lang=${this._getLang()}&pdfComment=${encodeURI(this.pdfComment)}&transport=${encodeURI(JSON.stringify(this.transport))}`;
            else if (this.voucherType === EVoucherType.Borrow)
                return `/pdf-generation/bon-d-emprunt.pdf?pdfmodel=voucher&loans=${this.voucherList}&type=borrowing&lang=${this._getLang()}&pdfComment=${encodeURI(this.pdfComment)}&transport=${encodeURI(JSON.stringify(this.transport))}`;
            else if (this.voucherType === EVoucherType.Return)
                return `/pdf-generation/bon-de-retour.pdf?pdfmodel=voucher&loans=${this.voucherList}&type=return&lang=${this._getLang()}&pdfComment=${encodeURI(this.pdfComment)}&transport=${encodeURI(JSON.stringify(this.transport))}`;
        };
        /**
         * Get the current selected language
         * @returns {string} the current language
         */
        this._getLang = () => {
            return this.$translate.use();
        };
        /**
         * Filter a list of [[ILoan]] for loan voucher
         * @param {Array<ILoan>} loans
         * @returns {Array<ILoan>}
         * @private
         */
        this._isLent = (loans) => {
            if (!loans)
                return;
            for (let l of loans) {
                if (Utils.getId(l.lender) === this.Access.hospital._id &&
                    Utils.getId(l.borrower) === Utils.getId(this.loan.borrower))
                    this.suggestedLoans.push(l);
            }
            this.filteredLoans = this.suggestedLoans;
        };
        /**
         * Filter a list of [[ILoan]] for borrowings voucher
         * @param {Array<ILoan>} loans
         * @returns {Array<ILoan>}
         * @private
         */
        this._isBorrowed = (loans) => {
            if (!loans)
                return;
            for (let l of loans) {
                if (Utils.getId(l.lender) === Utils.getId(this.loan.lender) &&
                    Utils.getId(l.borrower) === this.Access.hospital._id)
                    this.suggestedLoans.push(l);
            }
            this.filteredLoans = this.suggestedLoans;
        };
        /**
         * Filter a list of [[ILoan]] for return voucher
         * @private
         */
        this._isReturned = () => {
            if (!this.suggestedLoans)
                return;
            for (let l of this.suggestedLoans) {
                if (this.StatusService.calcStep(l) !== 6 && this.StatusService.calcStep(l) !== 7)
                    this.suggestedLoans.splice(this.suggestedLoans.indexOf(l), 1);
            }
            this.filteredLoans = this.suggestedLoans;
        };
        /**
         * Close the modal with actions performing
         */
        this.submit = () => {
            this.ModalService.close({});
        };
        /**
         * Close the modal without actions performing
         */
        this.close = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        this.suggestedLoans = [];
    }
    $onInit() {
        this.voucherList.push(Utils.getId(this.loan));
        if (this.voucherType === EVoucherType.Loan) {
            if (this.loan.loanCategoryCreation === ELoanCat.LoanOrBorrow)
                this.DashboardService.getLoans().then((loans) => {
                    this._isLent(loans);
                });
            else if (this.loan.loanCategoryCreation === ELoanCat.Prep)
                this.DashboardService.getPreparations().then((loans) => {
                    this._isLent(loans);
                });
        }
        else if (this.voucherType === EVoucherType.Borrow) {
            if (this.loan.loanCategoryCreation === ELoanCat.LoanOrBorrow)
                this.DashboardService.getBorrowings().then((loans) => {
                    this._isBorrowed(loans);
                });
            else if (this.loan.loanCategoryCreation === ELoanCat.Prep)
                this.DashboardService.getPreparations().then((loans) => {
                    this._isBorrowed(loans);
                });
        }
        else if (this.voucherType === EVoucherType.Return) {
            this.DashboardService.getLoans().then((loans) => {
                this._isLent(loans);
                this._isReturned();
            });
            this.DashboardService.getBorrowings().then((loans) => {
                this._isBorrowed(loans);
                this._isReturned();
            });
        }
    }
    ;
    get doesDisableSubmitButton() {
        return this.voucherList.length === 0 || this.pdfComment === undefined || this.pdfComment.length > 500 || this.doesFormContainInvalidChar;
    }
    get doesFormContainInvalidChar() {
        return (this.pdfComment && this.pdfComment.includes('&')) || (this.transport.service && this.transport.service.includes('&'));
    }
}
