import { EGroup, EInvitationType, EMaPUIPermission, EMpStatus, EWorkspacePermission, } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class GroupService {
    /** @ngInject */
    constructor(Api, Access, ModalService, HospitalService, MemberService, $q, _) {
        this.Api = Api;
        this.Access = Access;
        this.ModalService = ModalService;
        this.HospitalService = HospitalService;
        this.MemberService = MemberService;
        this.$q = $q;
        this._ = _;
        /**
         * Matches all [[IGroup]]
         * @param query [[string]] representing the searched [[IGroup]]
         * @param es The [[IGroup]] where the [[IGroup]] are tested
         * @returns A promise containing the array of all the [[IGroup]] matching the query
         */
        this.searchGroups = (query, es) => {
            const defer = this.$q.defer();
            defer.resolve(es.filter(this._createFilter(query)));
            return defer.promise;
        };
        /**
         * Get a specific [[IGroup]]
         * @param id The [[Id]] of the group
         * @param opts
         * @returns A promise containing a [[IGroup]]
         */
        this.get = (id, opts = {}) => {
            const defer = this.$q.defer();
            const route = opts.admin ? this.Api.admin.group : this.Api.group.id;
            delete opts.admin;
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Read, EWorkspacePermission.Groups_Read])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                route.get(Object.assign({ id: id }, opts), (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Get all [[IGroup]]
         * @param opts
         * @returns A promise containing an array of [[IGroup]]
         */
        this.getAll = (opts = {}) => {
            const defer = this.$q.defer();
            const route = opts.admin ? this.Api.admin.group : this.Api.group.id;
            delete opts.admin;
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Read, EWorkspacePermission.Groups_Read])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                route.all(opts, (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Update an existing [[IGroup]] in dataBase using the [[Id]]
         * @param group The [[IGroup]] to update
         */
        this.save = (group) => {
            const defer = this.$q.defer();
            if (!group) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.group.id.save({ id: group._id }, group, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add a new [[IGroup]] or edit existing group in dataBase using the [[Id]]
         * @returns The [[IGroup]] newly created
         * @param event
         * @param group
         * @param addUserAsAdmin
         */
        this.createOrEdit = (event, group = null, addUserAsAdmin = false) => {
            const defer = this.$q.defer();
            if (!group && !this.Access.authorizeAny([EMaPUIPermission.Groups_Create, EWorkspacePermission.Groups_Create])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else if (group && !this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.ModalService.show({
                    component: 'mp-modal-group',
                    bindings: {
                        group: group,
                    },
                    ev: event,
                    escapeToClose: true,
                }).then(res => {
                    if (!group) {
                        if (addUserAsAdmin) {
                            res.administrators = res.administrators || [];
                            res.members = res.members || [];
                            res.administrators.push({ _id: this.Access.user._id, fullName: this.Access.user.fullName });
                            res.members.push(this.isH(res) ? {
                                _id: this.Access.hospital._id,
                                name: this.Access.hospital.name,
                            } : {
                                _id: this.Access.user._id,
                                fullName: this.Access.user.fullName,
                            });
                        }
                        this.Api.group.id.add(res, (ans) => {
                            if (addUserAsAdmin) {
                                if (this.isH(ans)) {
                                    this.Access.hospital.groups = this.Access.hospital.groups || [];
                                    this.Access.hospital.groups.push(ans);
                                    this.HospitalService.save(this.Access.hospital);
                                }
                                else {
                                    this.Access.user.groups = this.Access.user.groups || [];
                                    this.Access.user.groups.push(ans);
                                    this.MemberService.save(this.Access.user);
                                }
                            }
                            ans.administrators = res.administrators;
                            ans.members = res.members;
                            defer.resolve(ans);
                        }, () => defer.reject(EMpStatus.RejectByServer));
                    }
                    else {
                        this.Api.group.id.save(res, (ans) => {
                            ans.members = group.members;
                            ans.administrators = group.administrators;
                            defer.resolve(ans);
                        }, () => defer.reject(EMpStatus.RejectByServer));
                    }
                });
            }
            return defer.promise;
        };
        /**
         * Delete a group from the dataBase using the [[Id]]
         * @param group The [[IGroup]] to delete
         */
        this.delete = (group) => {
            const defer = this.$q.defer();
            if (!group) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if ((!this.isAdmin(group) || !this.Access.authorizeAny([EMaPUIPermission.Groups_Delete, EWorkspacePermission.Groups_Delete])) && !this.Access.isAdmin()) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.ModalService.show({
                    component: 'mp-modal-confirm',
                    bindings: {
                        data: {
                            title: 'MAPUI.SERVICES.GROUPS.CONFIRM.TITLE',
                            text: 'MAPUI.SERVICES.GROUPS.CONFIRM.DELETE_GROUP',
                        },
                    },
                    escapeToClose: false,
                }).then(() => {
                    this.Api.group.id.delete({ id: group._id }, group, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
                });
            }
            return defer.promise;
        };
        /**
         * Get all [[IGroup]] by type
         * @returns A promise containing an array of [[IGroup]]
         */
        this.getByType = (type) => {
            const defer = this.$q.defer();
            if (!type) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Read, EWorkspacePermission.Groups_Read])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.group.type.get({ type: type }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Invite user of hospital to join group (quit group or remove user)
         * @param {IGroup} group
         * @param event
         * @return {angular.IPromise<IGroup>}
         */
        this.inviteMember = (group, event) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit]) || !this.Access.isAdmin() && !this.isAdmin(group)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.ModalService.show({
                    component: this.isH(group) ? 'mp-modal-add-hospital' : 'mp-modal-invite-member',
                    bindings: {
                        excluded: [].concat(group.members, this._.map(group.invitations, (i) => {
                            return {
                                _id: i.id,
                            };
                        })),
                    },
                    ev: event,
                    escapeToClose: true,
                })
                    .then((res) => {
                    group.invitations = group.invitations || [];
                    group.invitations.push({
                        id: res._id,
                        name: res.name || res.fullName,
                        type: EInvitationType.Send,
                    });
                    this.save(group)
                        .then(() => defer.resolve())
                        .catch(err => defer.reject(err));
                });
            }
            return defer.promise;
        };
        /**
         * Current user ask to join group
         * @param {IGroup} group
         */
        this.join = (group) => {
            const defer = this.$q.defer();
            const invitation = {
                type: EInvitationType.Ask,
                id: this.isH(group) ? this.Access.hospital._id : this.Access.user._id,
                name: this.isH(group) ? this.Access.hospital.name : this.Access.user.fullName,
            };
            if (!this.Access.authorize(EMaPUIPermission.Groups_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.group.invitation.add({ gid: group._id }, invitation, () => {
                    group.invitations = group.invitations || [];
                    group.invitations.push(invitation);
                    defer.resolve();
                }, (err) => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Current user leave group
         * @param {IGroup} group
         * @return {angular.IPromise<void>}
         */
        this.leave = (group) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.ModalService.show({
                    component: 'mp-modal-confirm',
                    bindings: {
                        data: {
                            title: 'MAPUI.GROUPS.CONFIRM.TITLE',
                            text: 'MAPUI.GROUPS.CONFIRM.LEAVE_GROUP',
                        },
                    },
                    escapeToClose: false,
                }).then(() => {
                    this._doRemoveMember(group, this.isH(group) ? this.Access.hospital._id : this.Access.user._id)
                        .then(defer.resolve)
                        .catch(defer.reject);
                });
            }
            return defer.promise;
        };
        /**
         * Accept invitation
         * @param {IGroup} group
         * @param invitation
         */
        this.acceptInvitation = (group, invitation) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                if (!this.isAdmin(group) && !this.Access.isAdmin() &&
                    !Utils.compareIds(invitation.id, this.Access.hospital) && !Utils.compareIds(invitation.id, this.Access.user)) {
                    defer.reject(EMpStatus.NoAccess);
                }
                else {
                    this.Api.group.invitation.accept({ gid: group._id, iid: invitation.id }, {}, (item) => {
                        group.invitations = this._.without(group.invitations, this._.find(group.invitations, (i) => {
                            return i.id === invitation.id;
                        }));
                        group.members.push(item);
                        defer.resolve();
                    }, (err) => defer.reject(EMpStatus.RejectByServer));
                }
            }
            return defer.promise;
        };
        /**
         * Cancel or deny invitation
         * @param {IGroup} group
         * @param invitation
         */
        this.removeInvitation = (group, invitation) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                if (!this.isAdmin(group) && !this.Access.isAdmin() &&
                    !Utils.compareIds(invitation.id, this.Access.user) && !Utils.compareIds(invitation.id, this.Access.hospital)) {
                    defer.reject(EMpStatus.NoAccess);
                }
                else {
                    this.Api.group.invitation.delete({ gid: group._id, iid: invitation.id }, {}, () => {
                        group.invitations = this._.without(group.invitations, this._.find(group.invitations, (i) => {
                            return i.id === invitation.id;
                        }));
                        defer.resolve();
                    }, (err) => defer.reject(EMpStatus.RejectByServer));
                }
            }
            return defer.promise;
        };
        /**
         * Add user of hospital in group (force)
         * @param {IGroup} group
         * @param event
         * @return {angular.IPromise<IHospital | IMember>}
         */
        this.addMember = (group, event) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                if (!this.Access.isAdmin()) {
                    defer.reject(EMpStatus.NoAccess);
                }
                else {
                    this.ModalService.show({
                        component: this.isH(group) ? 'mp-modal-add-hospital' : 'mp-modal-invite-member',
                        bindings: {
                            excluded: [].concat(this.isH(group) ? group.membersH : group.members, this._.map(group.invitations, (i) => {
                                return {
                                    _id: i.id,
                                };
                            })),
                        },
                        ev: event,
                        escapeToClose: true,
                    })
                        .then((res) => {
                        const service = this.isH(group) ? this.HospitalService : this.MemberService;
                        service.get(res._id)
                            .then((item) => {
                            item.groups = item.groups || [];
                            item.groups.push({ _id: group._id });
                            service.save(item, true)
                                .then(() => {
                                group.members = group.members || [];
                                group.members.push(item);
                                defer.resolve(item);
                            })
                                .catch(defer.reject);
                        })
                            .catch(defer.reject);
                    });
                }
            }
            return defer.promise;
        };
        /**
         * Remove user of hospital from group (quit group or remove user)
         * @param {IGroup} group
         * @param {string} id
         * @return {angular.IPromise<void>}
         */
        this.removeMember = (group, id) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                if (!this.Access.isAdmin() && !this.isAdmin(group) && !Utils.compareIds(this.Access.user, id) && !Utils.compareIds(this.Access.hospital, id)) {
                    defer.reject(EMpStatus.NoAccess);
                }
                else {
                    this.ModalService.show({
                        component: 'mp-modal-confirm',
                        bindings: {
                            data: {
                                title: 'MAPUI.GROUPS.CONFIRM.TITLE',
                                text: 'MAPUI.SERVICES.GROUPS.CONFIRM.REMOVE_MEMBER',
                            },
                        },
                        escapeToClose: false,
                    }).then(() => {
                        this._doRemoveMember(group, id)
                            .then(defer.resolve)
                            .catch(defer.reject);
                    });
                }
            }
            return defer.promise;
        };
        /**
         * Add group administrator
         * @param {IGroup} group
         * @param {IMember} user
         */
        this.addAdministrator = (group, user) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                if (!this.isAdmin(group) && !this.Access.isAdmin()) {
                    defer.reject(EMpStatus.NoAccess);
                }
                else {
                    group.administrators = group.administrators || [];
                    group.administrators.push(user);
                    this.save(group)
                        .then(() => defer.resolve())
                        .catch(defer.reject);
                }
            }
            return defer.promise;
        };
        /**
         * Remove group administrator
         * @param {IGroup} group
         * @param {IMember} user
         */
        this.removeAdministrator = (group, user) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                if (!this.isAdmin(group) && !this.Access.isAdmin()) {
                    defer.reject(EMpStatus.NoAccess);
                }
                else {
                    Utils.removeObjectInstanceInArray(group.administrators, user);
                    this.save(group)
                        .then(() => defer.resolve())
                        .catch(defer.reject);
                }
            }
            return defer.promise;
        };
        /**
         * Get all user's groups
         * @param {IMember} user
         * @return {angular.IPromise<void>}
         */
        this.getAllUsersGroups = (user = this.Access.user) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Read, EWorkspacePermission.Groups_Read])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.group.byMember.get({ mId: Utils.getId(user) }, {}, (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
        };
        /**
         * Is a group of hospitals (by opposition of group of users)
         * @param {IGroup} group
         * @return {boolean}
         */
        this.isH = (group) => {
            return group && group.groupStructure === EGroup.Hospital;
        };
        /**
         * Is [[IMember]] [[IGroup]] administrator
         * @param {IGroup} group
         * @param {IMember} user
         * @return {boolean}
         */
        this.isAdmin = (group, user = this.Access.user) => {
            return !!this._.find(group.administrators, (a) => {
                return Utils.compareIds(a, user);
            });
        };
        /**
         * If the [[IGroup]] is editable
         * @param group
         * @returns {boolean}
         */
        this.isEditable = (group) => {
            if (group && EGroup.Member == group.groupStructure && group.type === null)
                return false;
            const selectType = this._.find(this._constGroupTypes, { value: group.type });
            return selectType ? selectType.editable : false;
        };
        /**
         * Remove group member
         * @param {IGroup} group
         * @param {string} id
         * @private
         */
        this._doRemoveMember = (group, id) => {
            const defer = this.$q.defer();
            if (!this.Access.authorizeAny([EMaPUIPermission.Groups_Edit, EWorkspacePermission.Groups_Edit])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.group.members.delete({ gid: group._id, id: id }, () => {
                    group.members = this._.without(group.members, this._.find(group.members, (m) => {
                        return Utils.compareIds(m, id);
                    }));
                    group.administrators = this._.without(group.administrators, this._.find(group.administrators, (m) => {
                        return Utils.compareIds(m, id);
                    }));
                    defer.resolve();
                }, (err) => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Filter that indicates if the [[IGroup]] name match the query
         * @param query
         * @returns {(group:Group)=>(boolean|boolean)}
         * @private
         */
        this._createFilter = (query) => {
            return function (group) {
                const lowerName = group.name.toLowerCase();
                if (query) {
                    return lowerName.indexOf(query.toLowerCase()) === 0;
                }
                return true;
            };
        };
        this.Api.constType.get((res) => {
            this._constGroupTypes = res.groupType;
        });
    }
}
