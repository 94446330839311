var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CEstablishmentType } from '@app/shared/constTypes/establishment-type/establishment-type.const';
let ExSearchEstablishmentFinessComponent = class ExSearchEstablishmentFinessComponent {
    constructor() {
        // form control used by the finess establishment selector
        this.establishmentForm = new UntypedFormControl();
        this.CEstablishmentType = CEstablishmentType;
        this.pageHeaderConfig = {
            title: 'Establishment finess search component',
            icon: 'extension '
        };
    }
    establishmentChange(data) {
        this.onChangeResult = data;
    }
};
ExSearchEstablishmentFinessComponent = __decorate([
    Component({
        selector: 'ex-search-establishment-finess',
        template: require('./ex-search-establishment-finess.component.html').default,
        styles: [require('./ex-search-establishment-finess.component.scss')]
    })
], ExSearchEstablishmentFinessComponent);
export { ExSearchEstablishmentFinessComponent };
