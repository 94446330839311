var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d;
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CrudReadonly } from '@app/shared/templates/crud/crud-readonly.class';
import { EHsUserTypes, HsAlternativeContainer } from '@mapuilabs/mpl-interfaces';
import { UIRouter } from '@uirouter/angularjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { HsDownloadService } from './hs-download.service';
let HsAlternativeContainerService = class HsAlternativeContainerService extends CrudReadonly {
    constructor(_injector, _downloadService, _httpClient, _route) {
        super(_injector, HsAlternativeContainer, '', {
            create: null,
            read: null,
            update: null,
            delete: null
        });
        this._injector = _injector;
        this._downloadService = _downloadService;
        this._httpClient = _httpClient;
        this._route = _route;
        // State
        this._currentState = this._route.globals.current;
        // Config
        this._serviceConfig = this._currentState.data.servicesConfig.alternativeContainer;
        // CrudReadonly config
        // CrudReadonly is only used to retrieve containers
        this._endPoint = this._serviceConfig.endPointBase;
        this._permissions = this._serviceConfig.permissions;
        // Init Subjects & BehaviorSubjects
        this.onReloadList = new Subject();
        this.alternativeContainers$$ = new BehaviorSubject([]);
    }
    /**
     * Download spreadsheet with all alternatives.
     */
    downloadAlternativesSpreadsheet(search, sort, filters) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._downloadService.downloadAlternativesSpreadsheet(search, sort, filters, EHsUserTypes.LABO);
        });
    }
    updateAlternativeContainers(alternativeContainers) {
        // Complete all BehaviorSujects
        this.alternativeContainers$$.value.forEach((publicationContainer) => publicationContainer.complete());
        // Update alternativeContainers with new BehaviorSubjects
        this.alternativeContainers$$.next(alternativeContainers.map((alternativeContainer) => new BehaviorSubject(alternativeContainer)));
    }
    getGroupsList() {
        return this._httpClient.get(`${this._endPoint}/groups`);
    }
};
HsAlternativeContainerService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _b : Object, typeof (_c = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _c : Object, typeof (_d = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _d : Object])
], HsAlternativeContainerService);
export { HsAlternativeContainerService };
