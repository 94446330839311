import { EHsPublicationStockIndicatorStatus } from '../../types/hs-publication-stock-indicator.types';
export const stockIndicatorThresholdsMap = new Map([
    [
        EHsPublicationStockIndicatorStatus.CONSUMPTION_ZERO,
        {
            label: 'PUBLICATION.THRESHOLDS.CONSUMPTION_ZERO.LABEL',
            color: '#4caf50',
            caption: 'PUBLICATION.THRESHOLDS.CONSUMPTION_ZERO.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.OPTIMAL,
        {
            value: Number.POSITIVE_INFINITY,
            label: 'PUBLICATION.THRESHOLDS.OPTIMAL.LABEL',
            color: '#4caf50',
            caption: 'PUBLICATION.THRESHOLDS.OPTIMAL.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.SUFFICIENT,
        {
            value: 14,
            label: 'PUBLICATION.THRESHOLDS.SUFFICIENT.LABEL',
            color: '#8bc34a',
            caption: 'PUBLICATION.THRESHOLDS.SUFFICIENT.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.LIMIT,
        {
            value: 7,
            label: 'PUBLICATION.THRESHOLDS.LIMIT.LABEL',
            color: '#ffeb3b',
            caption: 'PUBLICATION.THRESHOLDS.LIMIT.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.INSUFFICIENT,
        {
            value: 0,
            label: 'PUBLICATION.THRESHOLDS.INSUFFICIENT.LABEL',
            color: '#ff9800',
            caption: 'PUBLICATION.THRESHOLDS.INSUFFICIENT.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.CRITICAL,
        {
            value: -7,
            label: 'PUBLICATION.THRESHOLDS.CRITICAL.LABEL',
            color: '#f44336',
            caption: 'PUBLICATION.THRESHOLDS.CRITICAL.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.STOCK_ZERO,
        {
            label: 'PUBLICATION.THRESHOLDS.STOCK_ZERO.LABEL',
            color: '#f44336',
            caption: 'PUBLICATION.THRESHOLDS.STOCK_ZERO.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.CONSUMPTION_NULL,
        {
            label: 'PUBLICATION.THRESHOLDS.CONSUMPTION_NULL.LABEL',
            color: '#607d8b',
            caption: 'PUBLICATION.THRESHOLDS.CONSUMPTION_NULL.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.STOCK_NULL,
        {
            label: 'PUBLICATION.THRESHOLDS.STOCK_NULL.LABEL',
            color: '#607d8b',
            caption: 'PUBLICATION.THRESHOLDS.STOCK_NULL.CAPTION'
        }
    ],
    [
        EHsPublicationStockIndicatorStatus.NO_END_DATE,
        {
            label: 'PUBLICATION.THRESHOLDS.NO_END_DATE.LABEL',
            color: '#607d8b',
            caption: 'PUBLICATION.THRESHOLDS.NO_END_DATE.CAPTION'
        }
    ]
]);
