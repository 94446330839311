export const locale = {
    lang: 'en',
    data: {
        admHospitalDetail: {
            EDIT_TITLE: `Pharmacy - `,
            NEW_TITLE: `New pharmacy`,
            TABS: {
                INFORMATION: `Information`,
                OTHER: `Other`,
                PERMISSIONS: `Actions / Permissions`,
                MEMBERS: `Members`
            },
            INFORMATION: {
                NAME: `Pharmacy name`,
                ADDRESS: `Address`,
                NUMBER: `Number`,
                WAY: `Way`,
                ZIP: `Zip Code`,
                CITY: `City`,
                COUNTRY: `Country`,
                CONTACT: `Contact information`,
                PHONE_STD: `Standard phone`,
                PHONE_PHARMA: `Pharmacy phone`,
                FAX: `Fax`,
                WEBSITE: `Website`,
                CAPACITY: `Capacity`,
                BED: {
                    MCO: `MCO beds`,
                    SSR: `SSR beds`,
                    PSY: `PSY beds`,
                    EPHAD: `EPHAD beds`
                },
                LOCATION: `Location`,
                REGION: `Region`,
                LATITUDE: `Latitude`,
                LONGITUDE: `Longitude`,
                OTHER: `Other information`,
                FINESS: `FINESS`,
                FINESSJUR: `Juridical FINESS`,
                APPLY_FINESS: `Pre-fill`,
                LOGO: `Hospital logo`
            },
            ACTIONS: {
                ROLE_MAPUI: `MaPUI role`,
                ROLE_HOSPIVILLE: `Hospiville role`,
                ROLE_WORKSPACE: `Workspace role`,
                REGISTERED: `Registered`,
                ACTIVE: `Active`,
                MEMBER_SINCE: `Member since`,
                MEMBER_TO: `Member until`,
                TRIAL_END: `Trial end`,
                ADD_DAYS: `Add 30 days`,
                REMOVE_DAYS: `Remove 30 days`,
                MAX_DATA: `Available space (GB)`,
                LOG_AS: {
                    LONG: `Log in this pharmacy`,
                    SHORT: `Log in`
                },
                DELETE: {
                    LONG: `Delete the pharmacy`,
                    SHORT: `Delete`
                },
                CONFIRM_DELETE: `Do you really want to delete this pharmacy?`,
                SNACK_DELETE: `Pharmacy has been deleted.`,
                VALID: `Valid`,
                ERROR: `Error`
            },
            MEMBERS: {
                NAME: `Name`,
                EMAIL: `Email`,
                LAST_CONNECT: `Last connection`,
                ROLES: `Roles`,
                EMPTY: `This pharmacy has no members.`
            },
            EDIT_OK: `Pharmacy has been edited!`,
            CREATE_OK: `Pharmacy has been created!`
        }
    }
};
