import { EMpStatus, EPosologyType } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalSpecificPosologyController {
    /** @ngInject */
    constructor(ModalService, Api, DrugInstanceService, _) {
        this.ModalService = ModalService;
        this.Api = Api;
        this.DrugInstanceService = DrugInstanceService;
        this._ = _;
        this.panes = [];
        /**
         * Used to store any extra data for posologies
         */
        this.posologiesAnnex = [];
        /**
         * Remove an entry
         * @param index
         */
        this.removeEntry = (index) => {
            this.posologies.splice(index, 1);
            this.posologiesAnnex.splice(index, 1);
        };
        /**
         * Add a new entry
         */
        this.addEntry = () => {
            this.posologies.push({ repeat: {} });
            this.posologiesAnnex.push({});
        };
        /**
         * open the when modal
         */
        this.openWhenModal = (posology) => {
            this.ModalService.show({
                escapeToClose: false,
                clickOutsideToClose: true,
                component: 'hv-modal-when',
            }).then((ans) => {
                if (ans.date) {
                    if (!posology.repeat.time)
                        posology.repeat.time = [];
                    posology.repeat.time.push(ans.date);
                }
                else if (ans.moment) {
                    if (!posology.repeat.when)
                        posology.repeat.when = [];
                    posology.repeat.when.push(ans.moment);
                }
            });
        };
        this.formatTimeDisplay = (time) => {
            return `${time.hours}h${time.minutes < 10 ? `0${time.minutes}` : time.minutes}`;
        };
        /**
         * retuen the traduction for the giver dailyEvent
         * @param {number} moment
         * @returns {any}
         */
        this.getMomentTrad = (moment) => {
            for (let evt of this.dailyEvents) {
                if (evt.value == moment)
                    return evt.translate;
            }
        };
        /**
         * delete the element at the given idx from the list
         * @param list
         * @param idx
         */
        this.deleteElem = (list, idx) => {
            list.splice(idx, 1);
        };
        /**
         * clear the bounds duration fields when the radio btn is toggled
         */
        this.clearBoundsDuration = (posology) => {
            if (posology.repeat.bounds && posology.repeat.bounds.boundsDuration)
                posology.repeat.bounds.boundsDuration = null;
        };
        /**
         * Clear the bounds limits when the the radio btn is toggled
         * @param {ITiming} posology
         */
        this.clearBoundsLimits = (posology) => {
            if (posology.repeat.bounds && posology.repeat.bounds.boundsPeriod)
                posology.repeat.bounds.boundsPeriod = {};
        };
        this.clearFrequency = (posology) => {
            posology.repeat.frequency = null;
            posology.repeat.frequencyMax = null;
        };
        this.clearDuration = (posology) => {
            posology.repeat.duration = null;
            posology.repeat.durationUnit = null;
        };
        this.clearPeriod = (posology) => {
            posology.repeat.period = null;
            posology.repeat.periodUnit = null;
            posology.repeat.dayOfWeek = null;
        };
        this.clearTime = (posology) => {
            posology.repeat.when = null;
            posology.repeat.time = null;
        };
        this.clearBounds = (posology) => {
            this.clearBoundsDuration(posology);
            this.clearBoundsLimits(posology);
        };
        this.changeDaysOfWeek = (posology) => {
            if (posology.repeat && posology.repeat.dayOfWeek && !posology.repeat.dayOfWeek.length) {
                posology.repeat.dayOfWeek = null;
            }
        };
        /**
         * Called when the freeField checkbox is triggered
         * @param idx
         * @param freeField
         */
        this.toggleFreeField = (idx, freeField) => {
            if (freeField == true) {
                this.posologies[idx].repeat = {};
            }
            else {
                this.posologies[idx].sentence = null;
            }
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            for (let poso of this.posologies) {
                if (this.posologiesTmp && this.posologiesTmp.length)
                    for (let posotmp of this.posologiesTmp) {
                        if (!this._.isEqual(posotmp.repeat, poso.repeat) ||
                            !this._.isEqual(posotmp.sentence, poso.sentence) ||
                            !this._.isEqual(posotmp.ifNeeded, poso.ifNeeded)) {
                            poso.presetCode = EPosologyType.Other;
                        }
                    }
                else
                    poso.presetCode = EPosologyType.Other;
            }
            this._close(this.posologies);
        };
        this._loadConstTypes = (constTypes) => {
            this.daysOfWeek = constTypes.daysOfWeek;
            this.unitOfTimes = constTypes.unitsOfTime;
        };
        this._loadHvConstTypes = (hvConstTypes) => {
            this.dailyEvents = hvConstTypes.dailyEvents;
        };
        /**
         * Close the modal with actions performing
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
        this.diService = this.DrugInstanceService;
    }
    $onInit() {
        this.posologiesTmp = angular.copy(this.posologies);
        this.generateSentence = this.DrugInstanceService.generatePosologySentence;
        this.Api.hvConstType.get((ans) => {
            this._loadHvConstTypes(ans);
        });
        this.Api.constType.get((ans) => {
            this._loadConstTypes(ans);
        });
        if (!this.posologies) {
            this.posologies = [{}];
            this.posologies[0].repeat = {};
        }
    }
    $onChanges(changes) {
        if (changes.posologies) {
            this.posologies = angular.copy(this.posologies);
            this.panes = [];
            this.posologiesAnnex = [];
            if (this.posologies && this.posologies.length) {
                for (let poso of this.posologies) {
                    if (!poso.repeat) {
                        poso.repeat = {};
                    }
                    this.panes.push([]);
                    if (poso.sentence)
                        this.posologiesAnnex.push({ freeField: true });
                    else
                        this.posologiesAnnex.push({});
                }
            }
        }
    }
}
