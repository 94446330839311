var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EStatusIndicator, ETransit } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { EstablishmentPatientService } from '@services/establishment-patient/establishment-patient.service';
import { HospivilleSessionService } from '@services/hospiville/hospiville-session/hospiville-session.service';
import { IdentityService } from '@services/identity/identity.service';
import { CPatientConsentStatus } from '@shared/constTypes/consent/consent-status.const';
import { CMedRecProcess } from '@shared/constTypes/medication-reconciliation/medRec-process.const';
import { CMedRecStatus } from '@shared/constTypes/medication-reconciliation/medRec-status.const';
import { CPathways } from '@shared/constTypes/pathways/pathways.const';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { StateService } from '@uirouter/angular';
import * as moment from 'moment';
import { locale as hvEnglish } from '../../../../hospiville/i18n/en';
import { locale as hvFrench } from '../../../../hospiville/i18n/fr';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { Access } from '@main/services/auth/access.service';
let MainPatientsListComponent = class MainPatientsListComponent extends ListPage {
    constructor(_dialogService, _estabPatService, _fuseTranslationLoaderService, _hvSessionService, _identityService, _stateService, _ajsAccess, _injector) {
        super(_injector, _estabPatService.getPage);
        this._dialogService = _dialogService;
        this._estabPatService = _estabPatService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._hvSessionService = _hvSessionService;
        this._identityService = _identityService;
        this._stateService = _stateService;
        this._ajsAccess = _ajsAccess;
        this._injector = _injector;
        this.displayedColumns = ['gender', 'fullName', 'ipp', 'service', 'admission', 'exit'];
        this.cPatientConsentStatus = CPatientConsentStatus;
        this.cMedRecStatus = CMedRecStatus;
        this.cMedRecProcess = CMedRecProcess;
        this.cPathways = CPathways;
        this.mrTransit = ETransit;
        this.migrationState = false;
        /**
         * Check if the given source is patient reference
         * @param references
         * @param pid
         */
        this._isReference = (references, pid) => {
            if (!(references === null || references === void 0 ? void 0 : references.length)) {
                return false;
            }
            return !!references.find((ref) => Utils.compareIds(ref, pid));
        };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english, hvFrench, hvEnglish);
        // Header
        this.pageHeaderConfig = {
            title: this._translate('PATIENTS.TITLE'),
            searchBarPlaceholder: this._translate('PATIENTS.SEARCH'),
            icon: 'group'
        };
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    ngOnInit() {
        super.ngOnInit();
        this.migrationState = this._ajsAccess.isHVMigrationState();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    /**
     * Get the latest MR regarding on transit
     * @param medRecs
     * @param transit
     */
    _getLatestMr(medRecs, transit) {
        if (!medRecs || !medRecs.length || transit === null || transit === undefined) {
            return null;
        }
        // Desc sorting
        const sorted = medRecs.sort((mr1, mr2) => {
            return new Date(mr2.date).getTime() - new Date(mr1.date).getTime();
        });
        // Return first MR matching transit
        for (const mr of sorted) {
            if (mr.transit === transit) {
                return mr;
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    goToArchived() {
        this._stateService.go('hospivilleV2.patients.archived.list');
    }
    openUserGuideHospiville() {
        window.open('https://www.mapui.fr/guide_util_hospiville/').focus();
    }
    openUserGuideExchanges() {
        window.open('https://www.mapui.fr/guide_util_echanges_v_h_es/').focus();
    }
    /**
     * Get gender character from EGender value
     * @param gender
     */
    getGenderPatient(gender) {
        return gender === undefined ? '-' : gender === 0 ? '♂' : '♀';
    }
    /**
     * Get ipp of the given patient regarding current hospital
     * @param patient
     */
    getPatientIpp(patient) {
        if (!patient) {
            return '';
        }
        return patient.lppi ? patient.lppi[this._identityService.hospital.finessCode] : '';
    }
    /**
     * Extract [[IMasterPatient]] birth zipcode from birthPlace
     * @param patient
     */
    getPatientBirthZipCode(patient) {
        var _a, _b;
        if (!patient) {
            return '';
        }
        return (_b = (_a = patient.birthPlaceData) === null || _a === void 0 ? void 0 : _a.code_postal) === null || _b === void 0 ? void 0 : _b.substring(0, 2);
    }
    /**
     * Get arrival hospital service of active [[IMedicationReconciliation]]
     * @param patient
     * @returns {string}
     */
    getService(patient) {
        var _a, _b;
        if (!(patient === null || patient === void 0 ? void 0 : patient.medicationReconciliations)) {
            return '';
        }
        const activeConcil = patient.medicationReconciliations.find((medRec) => !!medRec.isActive);
        if (activeConcil) {
            return (_a = activeConcil.hospitalService) === null || _a === void 0 ? void 0 : _a.name;
        }
        const sorted = patient.medicationReconciliations.sort((mr1, mr2) => {
            return new Date(mr1.date).getTime() - new Date(mr2.date).getTime();
        });
        if (sorted && sorted[0]) {
            return ((_b = sorted[0].hospitalService) === null || _b === void 0 ? void 0 : _b.name) || '';
        }
    }
    /**
     * Return dot color regarding medRecs state
     * @param medRecs
     * @param transit
     */
    getReconciliationsStatus(medRecs, transit) {
        var _a, _b, _c;
        if (!medRecs || !medRecs.length) {
            return { color: '#cfd8db', date: null };
        }
        const latest = this._getLatestMr(medRecs, transit);
        if (!latest) {
            return { color: '#cfd8db', date: null };
        }
        if (!(latest === null || latest === void 0 ? void 0 : latest.dbs)) {
            return { color: '#cfd8db', date: (_a = new Date(latest.date)) !== null && _a !== void 0 ? _a : new Date(latest.creationDate) };
        }
        if (latest.statusIndicator === EStatusIndicator.Validate &&
            latest.dbs.statusIndicator === EStatusIndicator.Validate) {
            return { color: '#8cc34a', date: (_b = new Date(latest.date)) !== null && _b !== void 0 ? _b : new Date(latest.creationDate) };
        }
        else {
            return { color: '#ff9800', date: (_c = new Date(latest.date)) !== null && _c !== void 0 ? _c : new Date(latest.creationDate) };
        }
    }
    /**
     * Get constType object for patient consent with the given status
     * @param consent
     * @param key
     */
    getConsentConstValue(consent, key) {
        return Object.values(this.cPatientConsentStatus).find((consentObj) => {
            return consentObj.value === consent;
        })[key];
    }
    /**
     * Set given patient as expandedPatient
     * @param patient
     */
    showPatientDetails(patient) {
        if (patient.transfers.length === 0 || patient.transfers.filter((elem) => { var _a; return !!((_a = elem.hospital) === null || _a === void 0 ? void 0 : _a.name); }).length > 0) {
            this.expandedPatient = this.compareExpandedAndRowPatient(patient) ? null : patient;
        }
        else {
            this._estabPatService
                .getById(Utils.getId(patient))
                .subscribe((estabPatWithTransfers) => {
                this.expandedPatient = this.compareExpandedAndRowPatient(estabPatWithTransfers)
                    ? null
                    : estabPatWithTransfers;
                Utils.editObjectInstanceInArray(this.dataSource, estabPatWithTransfers);
            });
        }
    }
    /**
     * Get last transfer infos from estabPat
     * @param transfers
     */
    getLastTransferInfos(transfers) {
        const sorted = transfers.sort((trans1, trans2) => {
            return new Date(trans2.date).getTime() - new Date(trans1.date).getTime();
        });
        return { hospital: sorted[0].hospital.name, date: moment(sorted[0].date).format('L') };
    }
    get pathwaysList() {
        var _a;
        if (this.cPathways) {
            return (_a = this.expandedPatient) === null || _a === void 0 ? void 0 : _a.pathways.map((e) => {
                return this._translationService.instant(this.cPathways.find((p) => p.value === e).translate);
            }).join(', ');
        }
    }
    /**
     * Return last medRec epi, dbs & ctm infos regarding the given transit
     * @param medRecs
     * @param transit
     */
    getLastMedRecInfos(medRecs, transit) {
        var _a, _b, _c, _d;
        const latest = this._getLatestMr(medRecs, transit);
        const infos = {
            epi: '',
            process: '',
            dbs: {
                status: this._translationService.instant('PATIENTS.STATUS.NOT_REALIZED'),
                color: '#cfd8db'
            },
            ctm: {
                status: this._translationService.instant('PATIENTS.STATUS.NOT_REALIZED'),
                color: '#cfd8db'
            }
        };
        if (!(!latest || !medRecs || !medRecs.length)) {
            infos.epi = (_a = latest.epi) !== null && _a !== void 0 ? _a : '';
            infos.process = (_b = this._translationService.getTranslation(this.cMedRecProcess, latest === null || latest === void 0 ? void 0 : latest.process)) !== null && _b !== void 0 ? _b : '';
            if (((_c = latest === null || latest === void 0 ? void 0 : latest.dbs) === null || _c === void 0 ? void 0 : _c.statusIndicator) >= 0 && (latest === null || latest === void 0 ? void 0 : latest.statusIndicator) >= 0) {
                infos.dbs.status = this._translationService.getTranslation(this.cMedRecStatus, (_d = latest === null || latest === void 0 ? void 0 : latest.dbs) === null || _d === void 0 ? void 0 : _d.statusIndicator);
                infos.dbs.color = Object.values(this.cMedRecStatus).filter((statusObj) => {
                    return statusObj.value === latest.dbs.statusIndicator;
                })[0].color;
                infos.ctm.status = this._translationService.getTranslation(this.cMedRecStatus, latest === null || latest === void 0 ? void 0 : latest.statusIndicator);
                infos.ctm.color = Object.values(this.cMedRecStatus).filter((statusObj) => {
                    return statusObj.value === latest.statusIndicator;
                })[0].color;
            }
        }
        return infos;
    }
    /**
     * Select the given patient and redirect to patient file
     * @param patient
     */
    setActivePatient(patient) {
        const lastMr = this._estabPatService.getLastActiveMedRec(patient);
        this._hvSessionService.setActivePatient = patient;
        this._hvSessionService.setActiveMedRec = lastMr;
        this._stateService.go('hospiville.patient-file');
    }
    /**
     * Compare expandedPatientRow and given patientRow
     * @param patient
     */
    compareExpandedAndRowPatient(patient) {
        return Utils.compareIds(this.expandedPatient, patient);
    }
    /**
     * Open confirm modal and delete the given patient
     * @param patient
     */
    deletePatient(patient) {
        const dialogConfig = {
            title: this._translationService.instant('PATIENTS.MODAL.DELETE.TITLE'),
            message: this._translationService.instant('PATIENTS.MODAL.DELETE.MESSAGE')
        };
        this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
            if (data) {
                this._estabPatService.delete(patient).subscribe(() => {
                    this._hvSessionService.getActivePatient.subscribe((activePatient) => {
                        if (activePatient && Utils.compareIds(patient, activePatient)) {
                            this._hvSessionService.resetCookies();
                        }
                    });
                    this._snackbarService.open(this._translationService.instant('PATIENTS.TOASTS.DELETE_PATIENT.OK'));
                    this._reload.emit();
                }, () => {
                    this._snackbarService.open(this._translationService.instant('PATIENTS.TOASTS.KO'));
                });
            }
        });
    }
    /**
     * Get patient ref pharmacy(ies)
     * @param patient
     */
    getReferences(patient) {
        var _a, _b;
        const refs = [];
        for (const src of patient.sources) {
            if (this._isReference(src.references, patient._id)) {
                if (src === null || src === void 0 ? void 0 : src.name) {
                    refs.push(src === null || src === void 0 ? void 0 : src.name);
                }
                else if ((_a = src === null || src === void 0 ? void 0 : src.establishment) === null || _a === void 0 ? void 0 : _a.name) {
                    refs.push((_b = src === null || src === void 0 ? void 0 : src.establishment) === null || _b === void 0 ? void 0 : _b.name);
                }
            }
        }
        if (!refs.length) {
            return this._translationService.instant('PATIENTS.TABLE.TITLES.REFERENCE.NO_REF');
        }
        else {
            return refs.join(' ; ');
        }
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_j = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _j : Object)
], MainPatientsListComponent.prototype, "_paginator", void 0);
MainPatientsListComponent = __decorate([
    Component({
        selector: 'hvx-main-patients-list',
        template: require('./main-patients-list.component.html').default,
        styles: [require('./main-patients-list.component.scss'), require('../../../hospiville.scss')],
        animations: [
            fuseAnimations,
            trigger('detailPatient', [
                state('collapsed', style({ height: '0px', minHeight: '0' })),
                state('expanded', style({ height: '*' })),
                transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
            ])
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof EstablishmentPatientService !== "undefined" && EstablishmentPatientService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof HospivilleSessionService !== "undefined" && HospivilleSessionService) === "function" ? _d : Object, typeof (_e = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object, typeof (_g = typeof Access !== "undefined" && Access) === "function" ? _g : Object, typeof (_h = typeof Injector !== "undefined" && Injector) === "function" ? _h : Object])
], MainPatientsListComponent);
export { MainPatientsListComponent };
