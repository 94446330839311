export class MpSearchMedicalDeviceComponent {
    constructor() {
        this.controller = 'MpSearchMedicalDeviceController';
        this.controllerAs = 'vm';
        this.template = require('./mp-search-medical-device.html').default;
        this.bindings = {
            medicalDevice: '<',
            label: '<',
            form: '<',
            name: '@',
            required: '<',
            isDisabled: '<',
            onSelected: '&',
            onTextChange: '&',
        };
    }
}
