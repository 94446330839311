import * as angular from 'angular';
/** @ngInject */
export function runBlock(msUtils, fuseGenerator, fuseConfig) {
    /**
     * Generate extra classes based on registered themes so we
     * can use same colors with non-angular-material elements
     */
    fuseGenerator.generate();
    /**
     * Disable md-ink-ripple effects on mobile
     * if 'disableMdInkRippleOnMobile' config enabled
     */
    if (fuseConfig.getConfig('disableMdInkRippleOnMobile') && msUtils.isMobile()) {
        var bodyEl = angular.element('body');
        bodyEl.attr('md-no-ink', 'true');
    }
    /**
     * Put isMobile() to the html as a class
     */
    if (msUtils.isMobile()) {
        angular.element('html').addClass('is-mobile');
    }
    /**
     * Put browser information to the html as a class
     */
    var browserInfo = msUtils.detectBrowser();
    if (browserInfo) {
        var htmlClass = browserInfo.browser + ' ' + browserInfo.version + ' ' + browserInfo.os;
        angular.element('html').addClass(htmlClass);
    }
}
