import { MpUploadXlsxController } from './mp-upload-xlsx/mp-upload-xlsx.controller';
import { MpUploadXlsxComponent } from './mp-upload-xlsx/mp-upload-xlsx.component';
import { MpFileSelectDirective } from './core/directives/mp-file-select.directive';
import { MpFileDropDirective } from './core/directives/mp-file-drop.directive';
import { FileSelectorController } from './core/components/file-selector/file-selector.controller';
import { FileSelectorComponent } from './core/components/file-selector/file-selector.component';
import * as angular from 'angular';
angular.module('mapui.services.file', [])
    .controller('MpUploadXlsxController', MpUploadXlsxController)
    .component('mpUploadXlsx', new MpUploadXlsxComponent())
    .directive('mpFileSelect', MpFileSelectDirective.factory())
    .directive('mpFileDrop', MpFileDropDirective.factory())
    .controller('FileSelectorController', FileSelectorController)
    .component('fileSelector', new FileSelectorComponent());
