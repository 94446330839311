var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component } from '@angular/core';
import { EquivalencesService } from '@app/services/equivalences/equivalences.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { StateService } from '@uirouter/angular';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { DialogService } from '@app/services/dialog/dialog.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { EquivalenceVoucherService } from '@app/services/equivalences/equivalence-voucher.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { EquivalenceVoucherComponent } from '../equivalence-voucher/equivalence-voucher.component';
import { MatDialog } from '@angular/material/dialog';
let EquivalenceDetailComponent = class EquivalenceDetailComponent {
    constructor(_stateService, _equivalenceService, _equivalenceVoucherService, _fuseTranslationLoaderService, _translationService, _dialogService, _snackbarService, _matDialog) {
        this._stateService = _stateService;
        this._equivalenceService = _equivalenceService;
        this._equivalenceVoucherService = _equivalenceVoucherService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._snackbarService = _snackbarService;
        this._matDialog = _matDialog;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        const id = this._stateService.params['id'];
        this._equivalenceService.getById(id).subscribe((equivalence) => {
            this.equivalence = equivalence;
            this.pageHeaderConfig = {
                title: equivalence.medicName,
                icon: 'group'
            };
        });
        this._equivalenceService.getVouchers(id).subscribe((vouchers) => {
            this.vouchers = vouchers;
        });
    }
    /**
     * Open voucher modal to add new [[IEquivalenceVoucher]]
     * @param equivalence
     */
    openVoucherModal(voucher, index) {
        const dialogRef = this._matDialog.open(EquivalenceVoucherComponent, {
            width: '60%',
            data: {
                equivalence: this.equivalence,
                voucher: voucher
            }
        });
        dialogRef.afterClosed().subscribe({
            next: (voucher) => {
                if (voucher && typeof voucher !== 'string') {
                    this.vouchers.splice(index, 1, voucher);
                }
            },
            error: (err) => this._snackbarService.openError(err)
        });
    }
    deleteEquivalenceVoucher(voucher, index) {
        this._dialogService
            .openConfirmDialog({
            title: this._translate('COMMONS.DELETE') + ' ?',
            message: this._translate('EQUIVALENCES.DELETE_VOUCHER_MODAL.TEXT'),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        })
            .subscribe((result) => {
            if (result) {
                this._equivalenceVoucherService.delete(voucher).subscribe(() => {
                    this._snackbarService.open(this._translate('EQUIVALENCES.DELETE_MODAL.SUCCESS'));
                    this.vouchers.splice(index, 1);
                });
            }
        });
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
};
EquivalenceDetailComponent = __decorate([
    Component({
        selector: 'mpx-equivalence-detail',
        template: require('./equivalence-detail.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StateService !== "undefined" && StateService) === "function" ? _a : Object, typeof (_b = typeof EquivalencesService !== "undefined" && EquivalencesService) === "function" ? _b : Object, typeof (_c = typeof EquivalenceVoucherService !== "undefined" && EquivalenceVoucherService) === "function" ? _c : Object, typeof (_d = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof DialogService !== "undefined" && DialogService) === "function" ? _f : Object, typeof (_g = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _g : Object, typeof (_h = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _h : Object])
], EquivalenceDetailComponent);
export { EquivalenceDetailComponent };
