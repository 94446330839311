export class HvPatientTransferModalComponent {
    constructor() {
        this.controller = 'HvPatientTransferModalController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-transfer-modal.html').default;
        this.bindings = {
            hasOnGoingConcil: '<'
        };
    }
    ;
}
