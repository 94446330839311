export class MpSearchMedicComponent {
    constructor() {
        this.controller = 'MpSearchMedicController';
        this.controllerAs = 'vm';
        this.template = require('./mp-search-medic.html').default;
        this.bindings = {
            label: '<',
            form: '<',
            drug: '<',
            required: '<',
            isDisabled: '<',
            noCache: '<',
            disallowCustomName: '<',
            showDci: '<',
            name: '@',
            onSelected: '&',
            onTextChange: '&',
        };
    }
}
