var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { startWith } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { AdminARSService } from '@services/admin/ars/admin-ars.service';
import { UrlService } from '@services/url/url.service';
import { Hospital } from '@mapuilabs/mpl-interfaces';
let ARSMembersTableComponent = class ARSMembersTableComponent {
    constructor(_arsService, _urlService) {
        this._arsService = _arsService;
        this._urlService = _urlService;
        // Columns to display in the data table
        this.displayedColumns = ['fullName', 'email', 'twoFactorKey'];
        //The total length of the query (independent of the "item by page")
        this.resultLength = 0;
        // Flag telling the UI to wait for the results of the query and so to show the table loader
        this.isLoadingResults = true;
        /**
         * Sets paginator index to 0.
         * @private
         */
        this._resetPaginatorIndex = () => {
            this._paginator.pageIndex = 0;
        };
        /**
         * Updates pagination according to the MatPaginator argument.
         * @param paginator: MatPaginator object containing page index (starting at 0)
         * and size info.
         * @private
         */
        this._updatePageOptions = (paginator) => {
            this._pageOptions = {
                page: paginator.pageIndex + 1,
                limit: paginator.pageSize
            };
        };
        /**
         * Puts a sorting on the data table and reloads it (first page).
         * @param sort
         */
        this.sortChange = (sort) => {
            this.sortValue = sort;
            this._resetPaginatorIndex();
            this._updatePageOptions(this._paginator);
            this._reload.emit();
        };
        this._reload = new EventEmitter();
    }
    ngOnInit() {
        this._resetPaginatorIndex();
        this._paginator.pageSize = 5;
    }
    ngAfterViewInit() {
        this._paginator.page.subscribe((paginator) => {
            this._updatePageOptions(paginator);
            this._urlService.addParams({
                pagination: this._pageOptions
            });
            this._reload.emit();
        }, (err) => console.error(err));
        // startWith simulates a reload event at initialisation
        this._reload.pipe(startWith({})).subscribe(() => {
            this.isLoadingResults = true;
            this._arsService
                .getMembers(this.ars._id, this._pageOptions, this.sortValue)
                .subscribe((data) => {
                this.resultLength = data.total;
                this.dataSource = data.items;
                this.isLoadingResults = false;
            });
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Hospital !== "undefined" && Hospital) === "function" ? _c : Object)
], ARSMembersTableComponent.prototype, "ars", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_d = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _d : Object)
], ARSMembersTableComponent.prototype, "_paginator", void 0);
ARSMembersTableComponent = __decorate([
    Component({
        selector: 'mpx-ars-members-table',
        template: require('./members-table.component.html').default,
        styles: [require('./members-table.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminARSService !== "undefined" && AdminARSService) === "function" ? _a : Object, typeof (_b = typeof UrlService !== "undefined" && UrlService) === "function" ? _b : Object])
], ARSMembersTableComponent);
export { ARSMembersTableComponent };
