var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { GroupService } from '@app/services/group/group.service';
import { isEqual } from 'lodash';
import { take } from 'rxjs/operators';
let HsModalPublicationDetailBroadcastListComponent = class HsModalPublicationDetailBroadcastListComponent {
    constructor(_dialogRef, _groupService, _healthProductService, data) {
        this._dialogRef = _dialogRef;
        this._groupService = _groupService;
        this._healthProductService = _healthProductService;
        this.data = data;
        this.treeControl = new NestedTreeControl((node) => node.children);
        this.dataSource = new MatTreeNestedDataSource();
        this.showNotSelectedControl = new UntypedFormControl(false);
        // -----------------------------------------------------------------------------------------------------
        // @ View methods
        // -----------------------------------------------------------------------------------------------------
        this.hasChild = (_, node) => !!node.children && node.children.length > 0;
        this.config = data.config;
        this._targetGroups = data.targetGroups;
        this.dataSource.data = [];
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Retrieve Purchasing Group with Hospitals
        this._groupService
            .readGroupsWithHospitals(this._healthProductService.healthProductType)
            .pipe(take(1))
            .subscribe({
            next: (groups) => {
                this._handleIncorrectTargetGroups(groups);
                this.dataSource.data = this._buildTreeItemNodes(groups);
            },
            error: (err) => this._handleError(err)
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Log error and trigger snackbar with message
     * @param error Error to handle
     */
    _handleError(error) {
        this._dialogRef.close({ error });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Check if one of the targetGroups cannot be found in the purchasing groups retrieved from server.
     * TargetGroup may not be found if one of the targetGroups is no longer a purchasing group or if
     * a wrong group was added into the targetGroups attributes.
     * @param groups
     */
    _handleIncorrectTargetGroups(groups) {
        const groupsNotFound = this._targetGroups
            .map(({ group }) => group)
            .filter((group) => !groups.find(({ _id }) => _id === group));
        if (groupsNotFound.length > 0) {
            const message = 'Be carefull, the following targetGroups cannot be found among the purchasing groups:';
            console.error(message, groupsNotFound.toString());
        }
    }
    /**
     * Find targetGroup with id
     * @param id targetGroup's id to find
     * @returns targetGroup
     */
    _findTargetGroup(id) {
        return this._targetGroups.find((targetGroup) => targetGroup.group === id);
    }
    /**
     * Check if a group is selected in either treeListSelection or targetGroup depending on if treeListSelection is defined.
     * @param id Group id to check
     * @returns Is the group selected or not
     */
    _isGroupSelected(id) {
        return !!this._findTargetGroup(id);
    }
    /**
     * Check if an hospital is selected in targetGroups
     * @param groupId Group id containing the hospital to check
     * @param hospitalId Hospital id to check
     * @returns Is the hospital selected or not
     */
    _isHospitalSelected(groupId, hospitalId) {
        const targetGroup = this._findTargetGroup(groupId);
        return !!(targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.hospitals.includes(hospitalId)) || isEqual(targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.hospitals, []);
    }
    /**
     * Build TreeNestedItemNode array from GroupWithHospitals array
     * @param groups GroupWithHospital array
     * @returns TreeNestedItemNode array
     */
    _buildTreeItemNodes(groups) {
        return ((groups === null || groups === void 0 ? void 0 : groups.map((group) => ({
            label: group.name,
            selected: this._isGroupSelected(group._id),
            value: null,
            children: group.hospitals.map((hospital) => ({
                label: hospital.name,
                selected: this._isHospitalSelected(group._id, hospital._id),
                value: null
            }))
        }))) || []);
    }
    selectedChildrenCount(node) {
        return node.children ? node.children.filter(({ selected }) => selected).length : 0;
    }
};
HsModalPublicationDetailBroadcastListComponent = __decorate([
    Component({
        selector: 'mpx-hs-modal-publication-detail-broadcast-list',
        template: require('./hs-modal-publication-detail-broadcast-list.component.html').default,
        providers: [HsHealthProductService],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _a : Object, typeof (_b = typeof GroupService !== "undefined" && GroupService) === "function" ? _b : Object, typeof (_c = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _c : Object, Object])
], HsModalPublicationDetailBroadcastListComponent);
export { HsModalPublicationDetailBroadcastListComponent };
