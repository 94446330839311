export class MpModalImageController {
    /**
     * The parent DOM node
     */
    //private _parent;
    /** @ngInject */
    constructor($rootElement) {
        //this.showModal = false;
        //this._parent = angular.element($rootElement);
    }
}
