var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DialogService } from '@services/dialog/dialog.service';
import { TranslationService } from '@services/translation/translation.service';
import { DepositaryService } from '@services/admin/stockout/depositary.service';
let AdminHsConfigDepositariesComponent = class AdminHsConfigDepositariesComponent {
    constructor(_fuseTranslationLoaderService, _snackbarService, _dialogService, _translationService, _depositaryService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackbarService = _snackbarService;
        this._dialogService = _dialogService;
        this._translationService = _translationService;
        this._depositaryService = _depositaryService;
        this.currentEditionIndex = -1;
        this.currentEditionName = new UntypedFormControl('', Validators.required);
        this.newDepositaryName = new UntypedFormControl('', Validators.required);
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this._depositaryService.getAll().subscribe((depositaries) => (this.depositaries = depositaries), (error) => this._snackbarService.openError(error));
    }
    deleteDepositary(depositary) {
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('CONFIGURATION.DELETE_DEPOSITARY.TITLE'),
            message: this._translationService.instant('CONFIGURATION.DELETE_DEPOSITARY.CONTENT', {
                name: depositary.name
            }),
            confirmButton: this._translationService.instant('COMMONS.YES'),
            cancelButton: this._translationService.instant('COMMONS.NO')
        })
            .subscribe((data) => {
            if (data) {
                this._depositaryService.delete(depositary).subscribe((value) => {
                    if (value) {
                        this.depositaries = this.depositaries.filter((w) => w._id !== depositary._id);
                        this.newDepositaryName.reset();
                        this._snackbarService.open(this._translationService.instant('CONFIGURATION.DEPOSITARY_DELETED'));
                    }
                }, (error) => {
                    this._snackbarService.openError(error);
                });
            }
        });
    }
    startEditingDepositary(depositaryIndex) {
        this.currentEditionIndex = depositaryIndex;
        this.currentEditionName.setValue(this.depositaries[depositaryIndex].name);
        this.currentEditionName.markAsPristine();
    }
    cancelEditingService() {
        this.currentEditionIndex = -1;
        this.currentEditionName.markAsPristine();
    }
    saveDepositary(depositaryIndex, depositary) {
        this.depositaries[depositaryIndex].name = this.currentEditionName.value;
        this.currentEditionIndex = -1;
        this.currentEditionName.markAsPristine();
        this._depositaryService.save(depositary).subscribe((value) => {
            this.depositaries[depositaryIndex] = value;
            this.currentEditionIndex = -1;
            this.currentEditionName.markAsPristine();
        }, (error) => this._snackbarService.openError(error));
    }
    addDepositary() {
        this._depositaryService.add({ name: this.newDepositaryName.value }).subscribe((value) => {
            this.depositaries.push(value);
            this.newDepositaryName.reset();
        }, (error) => this._snackbarService.openError(error));
    }
};
AdminHsConfigDepositariesComponent = __decorate([
    Component({
        selector: 'mpx-admin-hs-config-depositaries',
        template: require('./admin-hs-config-depositaries.component.html').default,
        styles: [require('./admin-hs-config-depositaries.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof DepositaryService !== "undefined" && DepositaryService) === "function" ? _e : Object])
], AdminHsConfigDepositariesComponent);
export { AdminHsConfigDepositariesComponent };
