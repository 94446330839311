import { EMRProc } from '@mapuilabs/mpl-interfaces';
export const CMedRecProcess = {
    proactive: {
        value: EMRProc.Proactive,
        translate: 'MEDREC.PROCESS.PROACTIVE'
    },
    retroactive: {
        value: EMRProc.Retroactive,
        translate: 'MEDREC.PROCESS.RETROACTIVE'
    }
};
