var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { formatDate } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { StateService } from '@uirouter/angular';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { fuseAnimations } from '@fuse/animations';
import { Member } from '@mapuilabs/mpl-interfaces';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
import { DialogService } from '@services/dialog/dialog.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { getEstablishmentAdminState } from '@shared/constTypes/establishment-type/establishment-type.const';
import { take } from 'rxjs/operators';
let ActionsTabComponent = class ActionsTabComponent {
    constructor(_memberService, _snackBarService, _translationService, _dialogService, _stateService, locale) {
        this._memberService = _memberService;
        this._snackBarService = _snackBarService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._stateService = _stateService;
        this.locale = locale;
        /**
         * Emits when the user selected another hospital for the member.
         */
        this.onMemberChanged = new EventEmitter();
        /**
         * Form control used by the search establishment selector.
         */
        this.establishmentForm = new UntypedFormControl();
        this._setFormControls = (member) => {
            this.form.setControl('email', new UntypedFormControl(member.email, [Validators.required, Validators.email]));
        };
        this.updateMember = (member) => {
            return new Observable((observer) => {
                member.email = this.form.value.email;
                observer.next();
                observer.complete();
            });
        };
        /**
         * Closes the modal without actions performing.
         */
        this.cancelMenu = () => {
            this.menu.closeMenu();
            //this.establishmentForm.reset();
        };
        /**
         *  Closes the modal with actions performing.
         *  Calls the service to save the member's hospital value and sends the
         *  updated member to the parent component.
         */
        this.okMenu = () => {
            if (this.isEditing) {
                this._memberService.changeHospital(this.member, this.selectedEstablishment).subscribe((newMember) => {
                    this.onMemberChanged.emit(newMember);
                    this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.CHANGE_HOSPITAL_OK'));
                });
            }
            else {
                this.member.hospital = this.selectedEstablishment;
            }
            this.menu.closeMenu();
            this.establishmentForm.reset();
        };
        /**
         * Deletes the current hospital after user confirmation.
         */
        this.deleteMember = () => {
            const dialogConfig = {
                title: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.DELETE.TITLE'),
                message: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.DELETE.MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
                if (data) {
                    this._memberService.delete(this.member).subscribe(() => {
                        this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.DELETE_ACCOUNT_OK'));
                        this._stateService.go('mapui.adm.members.list');
                    });
                }
            });
        };
        /**
         * Switches between lock and unlock methods of the service, updates member
         * and shows a confirmation snackbar.
         * @private
         */
        this.toggleLock = () => {
            if (this.isFormPristine) {
                const action = this.member.lockedByAdmin
                    ? this._memberService.unlock(this.member)
                    : this._memberService.lock(this.member);
                action.subscribe((newMember) => {
                    if (newMember) {
                        this.onMemberChanged.emit(newMember);
                        this._snackBarService.open(this.member.lockedByAdmin
                            ? this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.ACTIVATE_OK')
                            : this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.DEACTIVATE_OK'));
                    }
                });
            }
        };
        /**
         * Set Member locked attribute to false
         * @private
         */
        this.unblockMember = () => {
            const dialogConfig = {
                title: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.UNBLOCK.TITLE'),
                message: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.UNBLOCK.MESSAGE')
            };
            this._dialogService.openConfirmDialog(dialogConfig).subscribe((isOk) => {
                if (isOk) {
                    this._memberService.unblockMember(this.member).subscribe((res) => {
                        if (res) {
                            this._memberService.getById(this.member._id).subscribe((newMember) => {
                                if (newMember) {
                                    this.onMemberChanged.emit(newMember);
                                    this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.UNBLOCK_OK'));
                                }
                            });
                        }
                    });
                }
            });
        };
        /**
         * Clears double authentication: twoFactorKey = null.
         */
        this.resetDoubleAuth = () => {
            const dialogConfig = {
                title: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.DISABLE_2FA.TITLE'),
                message: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.DISABLE_2FA.MESSAGE')
            };
            this._dialogService.openConfirmDialog(dialogConfig).subscribe((isOk) => {
                if (isOk) {
                    this._memberService.removeDoubleAuth(this.member).subscribe((newMember) => {
                        if (newMember) {
                            this.onMemberChanged.emit(newMember);
                            this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.REMOVE_DOUBLE_AUTH_OK'));
                        }
                    });
                }
            });
        };
    }
    ngOnInit() {
        var _a;
        if (this.isEditing == null) {
            this.isEditing = true;
        }
        if ((_a = this.member.hospital) === null || _a === void 0 ? void 0 : _a.establishmentType) {
            this.hospitalUrl = getEstablishmentAdminState(this.member.hospital.establishmentType);
        }
    }
    ngOnChanges(changes) {
        var _a;
        if (changes.member) {
            this._setFormControls(this.member);
            if ((_a = this.member.hospital) === null || _a === void 0 ? void 0 : _a.establishmentType) {
                this.hospitalUrl = getEstablishmentAdminState(this.member.hospital.establishmentType);
            }
        }
    }
    establishmentChange(establishment) {
        this.selectedEstablishment = establishment;
    }
    resurrectMember() {
        const dialogConfig = {
            title: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.RESURRECT.TITLE'),
            message: this._translationService.instant('admMemberDetails.MESSAGES.ALERT.RESURRECT.MESSAGE'),
            confirmButton: this._translationService.instant('COMMONS.OK')
        };
        this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
            if (data) {
                this.member.deleted = false;
                this._memberService.save(this.member).subscribe(() => {
                    this._snackBarService.open(this._translationService.instant('admMemberDetails.MESSAGES.SNACKBAR.RESURRECT_OK'));
                    this._stateService.go('mapui.adm.members.list');
                });
            }
        });
    }
    /**
     * Sends the welcome mail to the current member.
     */
    sendNewUserMail(mailType) {
        let dialogConfig;
        if (this.member.welcomeMailSent || this.member.inviteMailSent) {
            const selectedMail = this.member.inviteMailSent ? this.member.inviteMailSent : this.member.welcomeMailSent;
            dialogConfig = {
                title: this._translationService.instant('admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.ALREADY_SENT_TITLE'),
                message: this._translationService.instant(this.member.inviteMailSent
                    ? 'admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.SEND_INVITE_MAIL.ALREADY_SENT_MESSAGE'
                    : 'admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.SEND_WELCOME_MAIL.ALREADY_SENT_MESSAGE', {
                    date: formatDate(selectedMail.date, 'dd/MM/yyyy', this.locale),
                    member: selectedMail.member.fullName || `un administrateur`
                })
            };
        }
        else {
            dialogConfig = {
                title: this._translationService.instant(mailType === 'invite'
                    ? 'admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.SEND_INVITE_MAIL.TITLE'
                    : 'admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.SEND_WELCOME_MAIL.TITLE'),
                message: this._translationService.instant(mailType === 'invite'
                    ? 'admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.SEND_INVITE_MAIL.MESSAGE'
                    : 'admMemberDetails.ACTIONS.BUTTONS.EMAIL_CONFIRM_DIALOGS.SEND_WELCOME_MAIL.MESSAGE', {
                    member: this.member.fullName || `l'utilisateur`
                })
            };
        }
        this._dialogService
            .openConfirmDialog(dialogConfig)
            .pipe(take(1))
            .subscribe((ans) => {
            if (ans) {
                let req;
                mailType === 'invite'
                    ? (req = this._memberService.sendInviteMail(this.member))
                    : (req = this._memberService.sendWelcomeMail(this.member));
                req.pipe(take(1)).subscribe((ret) => {
                    this._snackBarService.open(this._translationService.instant('admMemberDetails.ACTIONS.BUTTONS.EMAIL_SENT'));
                    // Tells the parent component to reload the member
                    this.onMemberChanged.emit(ret);
                }, this._snackBarService.openError);
            }
        }, () => this._snackBarService.openDefaultError());
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _f : Object)
], ActionsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Member !== "undefined" && Member) === "function" ? _g : Object)
], ActionsTabComponent.prototype, "member", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ActionsTabComponent.prototype, "isFormPristine", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ActionsTabComponent.prototype, "isEditing", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_h = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _h : Object)
], ActionsTabComponent.prototype, "onMemberChanged", void 0);
__decorate([
    ViewChild('selectHospitalMenuTrigger', { static: false }),
    __metadata("design:type", typeof (_j = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _j : Object)
], ActionsTabComponent.prototype, "menu", void 0);
ActionsTabComponent = __decorate([
    Component({
        selector: 'mpx-actions-tab',
        template: require('./actions-tab.component.html').default,
        styles: [require('./actions-tab.component.scss')],
        animations: fuseAnimations
    }),
    __param(5, Inject(LOCALE_ID)),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, String])
], ActionsTabComponent);
export { ActionsTabComponent };
