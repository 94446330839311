export class MpFileUploaderComponent {
    constructor() {
        this.template = require('./mp-file-uploader.html').default;
        this.controller = 'MpFileUploaderController';
        this.controllerAs = 'vm';
        this.bindings = {
            fileUploaderService: '<',
            multiple: '<',
            onSuccess: '&',
            title: '@',
            files: '<',
            onFileDelete: '&',
            autoUpload: '<',
        };
    }
}
