export const locale = {
    lang: 'fr',
    data: {
        ADD_DRUG_BALANCE_TAB: {
            LIST: 'Liste de médicaments',
            ADD: 'Ajouter à la prescription',
            QUANTITY: 'Quantité',
            ADD_BTN: 'ajouter',
            TABLE: {
                NAME: 'Nom',
                DOSAGE: 'Dosage',
                POSOLOGY: 'Posologie'
            },
            ADDED: 'Ajouté à la prescription',
            DELETED: 'Element supprimé',
            FINISH: 'Terminer',
            ALREADY_ADDED: 'Médicament déjà présent dans le bilan',
            FINISH_CONFIRM: {
                TITLE: 'Valider la prescription',
                MESSAGE: "En validant, vous confirmez avoir complété entièrement la prescription. L'activité changera de statut et il ne sera plus possible de modifier la prescription. Voulez-vous continuer ?"
            }
        },
        INFO: {
            ADDED_HOSPITAL: "Ajouté par l'hôpital",
            MODIFIED_HOSPITAL: "Modifié par l'hôpital"
        }
    }
};
