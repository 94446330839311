var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ContentChild, ContentChildren, Directive, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { expansionAnimations } from '@app/shared/animations/animations.constant';
import * as _ from 'lodash';
// -----------------------------------------------------------------------------------------------------
// @ Directives
// -----------------------------------------------------------------------------------------------------
let GenericTableNoDataDirective = class GenericTableNoDataDirective {
};
GenericTableNoDataDirective = __decorate([
    Directive({
        selector: '[genericTableNoData]'
    })
], GenericTableNoDataDirective);
export { GenericTableNoDataDirective };
let GenericTableCustomTemplateDirective = class GenericTableCustomTemplateDirective {
    constructor(template) {
        this.template = template;
    }
};
__decorate([
    Input('genericTableCustomTemplate'),
    __metadata("design:type", String)
], GenericTableCustomTemplateDirective.prototype, "id", void 0);
GenericTableCustomTemplateDirective = __decorate([
    Directive({
        selector: '[genericTableCustomTemplate]'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TemplateRef !== "undefined" && TemplateRef) === "function" ? _a : Object])
], GenericTableCustomTemplateDirective);
export { GenericTableCustomTemplateDirective };
let GenericTableComponent = class GenericTableComponent {
    constructor() {
        this._sortAuto = false;
        this._innerScrollbar = false;
        this.rowClicked = new EventEmitter();
        this.sortChange = new EventEmitter();
        this.selectionChanged = new EventEmitter();
    }
    get sortAuto() {
        return this._sortAuto;
    }
    set sortAuto(val) {
        this._sortAuto = coerceBooleanProperty(val);
    }
    get innerScrollbar() {
        return this._innerScrollbar;
    }
    set innerScrollbar(val) {
        this._innerScrollbar = coerceBooleanProperty(val);
    }
    get displayedColumns() {
        var _a, _b, _c, _d;
        let result = [];
        if ((_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.checkboxColumn) === null || _b === void 0 ? void 0 : _b.active) {
            result.push('checkbox');
        }
        result = result.concat(this.columns.map(({ label }) => label));
        if ((_d = (_c = this.config) === null || _c === void 0 ? void 0 : _c.expandableRows) === null || _d === void 0 ? void 0 : _d.active) {
            result.push('expand');
        }
        return result;
    }
    ngOnInit() {
        var _a, _b;
        if ((_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.checkboxColumn) === null || _b === void 0 ? void 0 : _b.active) {
            this.selection = new SelectionModel(this.config.checkboxColumn.multiple, this.initialSelection || [], true);
            this.selection.changed.subscribe((changes) => {
                this.selectionChanged.emit(changes);
            });
        }
    }
    ngAfterViewInit() {
        if (this._sortAuto) {
            this.dataSource.sort = this.sortElement;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onRowClicked(row) {
        var _a, _b;
        if ((_a = this.config) === null || _a === void 0 ? void 0 : _a.clickableRows) {
            this.rowClicked.emit(row);
        }
        else if ((_b = this.config) === null || _b === void 0 ? void 0 : _b.expandableRows.active) {
            this.expandedElement = this.expandedElement === row ? null : row;
        }
    }
    onSortChange(sort) {
        this.sortChange.emit(sort);
    }
    readCellValue(object, column) {
        // Read obejct attribute
        let value = _.get(object, column.attribute);
        // Apply transform if needed
        if (column.transform) {
            value = column.transform(value);
        }
        return value;
    }
    getCustomTemplate(templateId) {
        var _a;
        return (_a = this.genericTableCustomTemplateDirectives.toArray().find(({ id }) => id === templateId)) === null || _a === void 0 ? void 0 : _a.template;
    }
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof MatTableDataSource !== "undefined" && MatTableDataSource) === "function" ? _b : Object)
], GenericTableComponent.prototype, "dataSource", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], GenericTableComponent.prototype, "columns", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GenericTableComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GenericTableComponent.prototype, "sort", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Array !== "undefined" && Array) === "function" ? _c : Object)
], GenericTableComponent.prototype, "initialSelection", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], GenericTableComponent.prototype, "sortAuto", null);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], GenericTableComponent.prototype, "innerScrollbar", null);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], GenericTableComponent.prototype, "rowClicked", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], GenericTableComponent.prototype, "sortChange", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], GenericTableComponent.prototype, "selectionChanged", void 0);
__decorate([
    ViewChild(MatSort),
    __metadata("design:type", typeof (_g = typeof MatSort !== "undefined" && MatSort) === "function" ? _g : Object)
], GenericTableComponent.prototype, "sortElement", void 0);
__decorate([
    ContentChild(GenericTableNoDataDirective),
    __metadata("design:type", GenericTableNoDataDirective)
], GenericTableComponent.prototype, "genericTableNoDataDirective", void 0);
__decorate([
    ContentChildren(GenericTableCustomTemplateDirective),
    __metadata("design:type", typeof (_h = typeof QueryList !== "undefined" && QueryList) === "function" ? _h : Object)
], GenericTableComponent.prototype, "genericTableCustomTemplateDirectives", void 0);
GenericTableComponent = __decorate([
    Component({
        selector: 'mpx-generic-table',
        template: require('./generic-table.component.html').default,
        animations: [expansionAnimations.bodyExpansion]
    }),
    __metadata("design:paramtypes", [])
], GenericTableComponent);
export { GenericTableComponent };
