var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { EHospivillePermission, EHTTPStatus, EstablishmentPatient } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
let EstablishmentPatientService = class EstablishmentPatientService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, EstablishmentPatient, '/api/hospiville/establishment-patient', {
            create: EHospivillePermission.Patient_Create,
            read: EHospivillePermission.Patient_Read,
            update: EHospivillePermission.Patient_Edit,
            delete: EHospivillePermission.Patient_Delete
        });
        this.__injector = __injector;
        this.getAllArchivedPatientsByEstablishmentPaginated = (pageOptions, search, sort, filters, otherParams) => {
            if (!this._permissionService.authorize(this._permissions.read)) {
                return throwError(() => EHTTPStatus.Unauthorized);
            }
            let params = new HttpParams();
            // Begin assigning parameters
            if (search) {
                params = params.append('search', search);
            }
            if (!pageOptions) {
                //if (pageOptions)
                pageOptions = { page: 1, limit: 10 };
            } //todo: Remove when admin v1 is not used anymore
            params = params.append('pageOptions', JSON.stringify(pageOptions));
            params = this.addOtherParams(params, otherParams);
            return this._http.get(this._endPoint + `/archived`, {
                params: params
            });
        };
    }
    getLastActiveMedRec(patient) {
        if (!(patient === null || patient === void 0 ? void 0 : patient.medicationReconciliations)) {
            return null;
        }
        const sorted = patient === null || patient === void 0 ? void 0 : patient.medicationReconciliations.sort((mr1, mr2) => {
            return new Date(mr2.date).getTime() - new Date(mr1.date).getTime();
        });
        for (const mr of sorted) {
            if (mr.isActive) {
                return mr;
            }
        }
    }
};
EstablishmentPatientService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], EstablishmentPatientService);
export { EstablishmentPatientService };
