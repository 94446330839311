export const locale = {
    lang: 'fr',
    data: {
        HOSPIVILLE_TOOLBAR: {
            LABELS: {
                CONSENT: 'Consentement :',
                MEDRECS: 'Conciliations',
                CHANGE_PATIENT: 'Changer de patient',
                NEW_PATIENT: 'Nouveau patient',
                SELECTED_MEDREC: 'Conciliation sélectionnée :',
                CHANGE_SELECTED_MEDREC: 'Changer de conciliation'
            }
        }
    }
};
