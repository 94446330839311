var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { Group, EAdminPanelPermission, EGroup } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '../../../shared/templates/crud/crud.template';
let AdminGroupsService = class AdminGroupsService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, Group, '/api/admin/groups', {
            create: EAdminPanelPermission.Establishments_Create,
            read: EAdminPanelPermission.Establishments_Read,
            update: EAdminPanelPermission.Establishments_Edit,
            delete: EAdminPanelPermission.Establishments_Delete
        });
    }
    /**
     * removeGroupMember
     * Remove member or hospital from group, as admin
     * @param group group target
     * @param member member to add
     */
    removeGroupMember(group, member) {
        const groupStructurePath = group.groupStructure === EGroup.Hospital ? 'hospitals' : 'members';
        const endpoint = `${this._endPoint}/${group._id}/${groupStructurePath}/${member._id}`;
        return this._http.delete(endpoint);
    }
    /**
     * addGroupMember
     * Add memberor hospital from group, as admin
     * @param group group target
     * @param member member to add
     */
    addGroupMember(group, member) {
        const groupStructurePath = group.groupStructure === EGroup.Hospital ? 'hospitals' : 'members';
        const endpoint = `${this._endPoint}/${groupStructurePath}/${group._id}`;
        return this._http.put(endpoint, { id: member._id });
    }
    /**
     * addGroupAdmin
     * Add administrator from group
     * @param group group target
     * @param member member to add
     */
    addGroupAdmin(group, member) {
        const endpoint = `${this._endPoint}/admins/${group._id}`;
        return this._http.put(endpoint, { id: member._id });
    }
    /**
     * removeGroupAdmin
     * Remove member or hospital from group
     * @param group group target
     * @param member member to add
     */
    removeGroupAdmin(group, member) {
        const endpoint = `${this._endPoint}/${group._id}/admins/${member._id}`;
        return this._http.delete(endpoint);
    }
};
AdminGroupsService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], AdminGroupsService);
export { AdminGroupsService };
