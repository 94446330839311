import * as angular from 'angular';
import { HospivilleService } from './hospiville.service';
import './activities/activities.module';
import './carePlan/carePlan.module';
import './configuration/configuration.module';
import './drugBalance/drugBalance.module';
import './drugInstance/drugInstance.module';
import './information/information.module';
import './medicationReconciliation/medicationReconciliation.module';
import './modal/modal.module';
import './mRResult/mRResult.module';
import './patient/patient.module';
import './source/source.module';
import './securedMessaging/secureMessaging.module';
angular
    .module('hospiville.services', [
    'hospiville.services.patient',
    'hospiville.services.source',
    'hospiville.services.drugBalance',
    'hospiville.services.medicationReconciliation',
    'hospiville.services.mRResult',
    'hospiville.services.drugInstance',
    'hospiville.services.information',
    'hospiville.services.activities',
    'hospiville.services.configuration',
    'hospiville.services.carePlan',
    'hospiville.services.modal',
    'hospiville.services.secureMessaging'
])
    .service('HospivilleService', HospivilleService);
