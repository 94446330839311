import { EMpStatus, EPosologyType, ESource, } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvPatientInterviewSurveyController {
    /** @ngInject */
    constructor(ToastService, HospivilleService, InformationService, SourceService, Access, Api, $state, $scope, _) {
        this.ToastService = ToastService;
        this.HospivilleService = HospivilleService;
        this.InformationService = InformationService;
        this.SourceService = SourceService;
        this.Access = Access;
        this.Api = Api;
        this.$state = $state;
        this.$scope = $scope;
        this._ = _;
        /**
         * @type {ESource}
         */
        this.ESource = ESource;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * Get the translation of a given posology
         * @param posology
         * @returns {string}
         */
        this.getPosology = (posology) => {
            if (!this.hvConstTypes || (!posology && posology !== 0))
                return;
            return this._.find(this.hvConstTypes.posology, { value: posology }).translate;
        };
        /**
         * Get the translation of a given dosage type
         * @param dosageType
         * @returns {string}
         */
        this.getDosageType = (dosageType) => {
            if (!this.hvConstTypes || (!dosageType && dosageType !== 0))
                return;
            return this._.find(this.hvConstTypes.dosages, { value: dosageType }).translate;
        };
        /**
         * Set [[IMedUCD]] to the current [[IDrugInstance]]
         * @param drug
         */
        this.selectDrug = (event) => {
            this.newDrugInst.denomination = event.drug.denomination;
            this.newDrugInst.UCD13 = event.drug.UCD13;
            this.newDrugInst.codeATC = event.drug.codeATC;
            this.newDrugInst.DCI = event.drug.DCI;
            if (this._.find(this.hvConstTypes.dosages, { value: +event.pharmaForm }) && event.pharmaForm) {
                if (!this.newDrugInst.sources[0])
                    this.newDrugInst.sources[0] = {};
                this.newDrugInst.sources[0].dosageType = +event.pharmaForm;
            }
        };
        /**
         * Set pharmacy selection
         * @param {ISource} source
         */
        this.pharmaChange = ({ source }) => {
            if (!this.patient.sources) {
                this.patient.sources = [];
            }
            else if (this.patient.sources.length && this.ESource.Pharmacy === this.patient.sources[this.patient.sources.length - 1].type) {
                this.patient.sources[this.patient.sources.length - 1] = source;
            }
            else {
                this.patient.sources.push(source);
            }
        };
        /**
         * Push current [[IDrugInstance]] to [[IDrugInstance]] table and init new current [[IDrugInstance]]
         */
        this.addDrug = () => {
            this.newDrugInst.sources[0].source = { _id: this.source._id };
            this.drugInstances.push(angular.copy(this.newDrugInst));
            this.newDrugInst = {
                sources: [{
                        posologies: null,
                        dosageDropInfo: null,
                    }],
            };
        };
        /**
         * Remove a [[IDrugInstance]]
         * @param index
         */
        this.deleteInst = (index) => {
            this.drugInstances.splice(index, 1);
        };
        /**
         * Set given [[IDrugInstanceSource]] posology
         * @param posology
         * @param instSource
         */
        this.posologyChange = ({ posologies }, instSource) => {
            instSource.posologies = posologies;
        };
        /**
         * Set given [[IDrugInstanceSource]] dosage
         * @param {number} quantity
         * @param {number} type
         * @param {IDropInfo} dropInfo
         * @param {IDrugInstanceSource} instSource
         */
        this.dosageChange = ({ quantity, type, dropInfo }, instSource) => {
            instSource.dosage = quantity;
            instSource.dosageType = type;
            if (type == 14) {
                instSource.dosageDropInfo = dropInfo;
            }
        };
        /**
         *
         * @param drugs
         * @param {string} drugFrom
         */
        this.otherDrugs = ({ drugs }, drugFrom) => {
            this._drugs[drugFrom] = drugs;
        };
        /**
         * Set patient drugs from allergies or self medication
         * @param drugs
         * @param drugFrom
         */
        this.selectDrugFrom = ({ drugs }, drugFrom) => {
            this.patient[drugFrom] = angular.copy(drugs);
        };
        /**
         * Save interview patient survey
         */
        this.saveInterview = () => {
            const existingSrc = this._.find(this._drugBalance.sources, (source) => {
                return source.source.type === this.source.type;
            });
            if (!existingSrc) {
                this.SourceService.add(this.source).then((src) => {
                    this.source = src;
                    this._drugBalance.sources.push({ date: new Date(), source: this.source });
                    this._save();
                });
            }
            else {
                this.source = existingSrc.source;
                this._save();
            }
        };
        /**
         * Set patient antibiotic drug
         * @param drug
         */
        this.selectAntibiotic = (event) => {
            if (!this.patient.antibiotic)
                this.patient.antibiotic = {};
            this.patient.antibiotic.drug = angular.copy(event.drug);
        };
        /**
         * Init Patient interview survey
         * @private
         */
        this._init = () => {
            this.drugInstances = [];
            this.Api.hvConstType.get((ans) => {
                this.hvConstTypes = ans;
            });
            this.member = this.Access.user;
            this.HospivilleService.getPatient()
                .then((patient) => {
                this.patient = patient;
                if (this.patient.lastHospitalizationComment || this.patient.lastHospitalizationDate) {
                    this.haveRecentHospitalization = true;
                }
            });
            this.HospivilleService.getDrugBalanceSheet()
                .then((DBS) => {
                this._drugBalance = DBS;
                this.newDrugInst = {
                    sources: [{
                            posologies: [],
                        }],
                };
                this.ready = true;
            }).catch(err => console.error(err));
        };
        /**
         * mark the value and the description of the Compliance Test
         */
        this.scoreDescription = () => {
            this.description.score = 0;
            for (let answer of this.patient.complianceTest) {
                if (answer == false)
                    this.description.score++;
            }
            switch (this.description.score) {
                case 4:
                    this.description.comment = 'HOSPIVILLE.PIS.COMPLIANCE.GOOD';
                    this.description.color = 'green-400-fg';
                    break;
                case 3:
                case 2:
                    this.description.comment = 'HOSPIVILLE.PIS.COMPLIANCE.WEAK';
                    this.description.color = 'orange-400-fg';
                    break;
                case 1:
                case 0:
                    this.description.comment = 'HOSPIVILLE.PIS.COMPLIANCE.BAD';
                    this.description.color = 'red-400-fg';
                    break;
                default:
                    this.description.comment = '';
                    break;
            }
        };
        /**
         * Save interview patient survey
         * @private
         */
        this._save = () => {
            let maxIndex = this.HospivilleService.getMaxIndex() + 1;
            //Creates drugInstances table from all drugs in the form
            for (let from in this._drugs) {
                if (this._drugs.hasOwnProperty(from)) {
                    for (let drug of this._drugs[from]) {
                        this.drugInstances.push(drug);
                    }
                }
            }
            //Loop on instances table created above
            for (const instance of this.drugInstances) {
                // if the instance is not found in the drugBalance instances
                if (!this._.find(this._drugBalance.instances, { denomination: instance.denomination })) {
                    //instance source is set
                    if (!instance.sources)
                        instance.sources = [{ source: { _id: null } }];
                    instance.sources[0].source._id = this.source._id;
                    //instance is given the maxIndex +1 index
                    instance.index = maxIndex++;
                }
            }
            let newInstances = angular.copy(this.drugInstances);
            //Loop on drugBalance instances
            for (let instance of this._drugBalance.instances) {
                let sameInst = this._.find(newInstances, { denomination: instance.denomination });
                //if the instance exist in the new instances
                if (sameInst) {
                    // new instance source is set
                    sameInst.sources[0].source._id = this.source._id;
                    let sourceid = this.source._id;
                    //check if this instance exist with the same source
                    let src = this._.find(instance.sources, (obj) => {
                        return (obj.source) ? obj.source._id === sourceid : obj.sources[0]._id === sourceid;
                    });
                    //if yes we replace the source in the drugBalance instance with the source of the new instance (???)
                    if (src) {
                        let index = this._.indexOf(instance.sources, src);
                        instance.sources[index] = sameInst.sources[0];
                    }
                    //else we add the source to the instance
                    else
                        instance.sources.push(sameInst.sources[0]);
                    //we remove the new instance from the array
                    newInstances = this._.without(newInstances, sameInst);
                }
            }
            //all remaining instance are pushed in drugBalance instances
            for (let newInstance of newInstances) {
                this._drugBalance.instances.push(newInstance);
            }
            //time spent on the page
            if (!this._drugBalance.time)
                this._drugBalance.time = 0;
            this._drugBalance.time += Math.abs((new Date()).getTime() - this._time.getTime());
            //save the drugBalance
            this.HospivilleService.saveDrugBalance(this._drugBalance)
                .then(() => {
                //save patient and change page
                this.HospivilleService.savePatient(this.patient).then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                    this.$state.go('hospiville.patient-file');
                });
            });
        };
        this.ready = false;
        this.source = {};
        this._drugs = {};
        this._time = new Date();
        this.description = {
            score: -1,
            comment: '',
            color: '',
        };
        this.$scope.$on('refreshPatientFile', () => {
            this._init();
        });
    }
    $onInit() {
        this._init();
        this.migrationState = this.Access.isHVMigrationState();
    }
    resetHospitalizationInformation() {
        if (this.haveRecentHospitalization == false) {
            this.patient.lastHospitalizationDate = null;
            this.patient.lastHospitalizationComment = null;
        }
    }
}
