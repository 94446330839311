var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Utils } from '@main/services/Utils';
import { CustomWidgetsService } from '@services/homepage/widgets/custom-widgets/custom-widgets.service';
import { ReleaseNotesService } from '@services/homepage/widgets/release-notes/release-notes.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ReleaseNoteWidgetComponent } from '@shared/components/release-note-widget/release-note-widget.component';
import { WidgetService } from '@shared/components/widgets/dashboard/widget.service';
import { Widget, WidgetInjection } from '@shared/components/widgets/dashboard/widgets';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
let DashboardComponent = class DashboardComponent {
    constructor(_widgetHomeService, widgetService, _snackbarService, _releaseNotesService, _injector) {
        this._widgetHomeService = _widgetHomeService;
        this.widgetService = widgetService;
        this._snackbarService = _snackbarService;
        this._releaseNotesService = _releaseNotesService;
        this._injector = _injector;
    }
    ngOnInit() {
        this.layout = this.widgetService.getLayout();
        this._widgetHomeService.getAll().subscribe((result) => {
            this.customWidgetList = result.filter((widget) => widget.active);
            this.customWidgetList = this.customWidgetList.sort((a, b) => a.priorityOrder - b.priorityOrder);
        }, (err) => this._snackbarService.openError(err));
        this._releaseNotesService
            .getAll()
            .pipe(take(1))
            .subscribe((releaseNotes) => {
            const observable = releaseNotes.map((releaseNote) => this._releaseNotesService.getReleaseNoteWithLinesById(Utils.getId(releaseNote)));
            const toAdd = [];
            forkJoin(observable)
                .pipe(take(1))
                .subscribe((releaseNotesAndLines) => {
                for (const releaseNoteAndLines of releaseNotesAndLines) {
                    toAdd.push(new Widget(ReleaseNoteWidgetComponent, releaseNoteAndLines.widget.positions, Injector.create({
                        providers: [
                            {
                                provide: WidgetInjection,
                                deps: [],
                                useValue: { releaseNotesAndLines: releaseNoteAndLines }
                            }
                        ],
                        parent: this._injector
                    })));
                }
                this.layout.addWidgets(toAdd);
                this.layout = Object.assign({}, this.layout); // Trigger input OnChanges
            });
        });
    }
};
DashboardComponent = __decorate([
    Component({
        selector: 'mpx-dashboard',
        template: require('./dashboard.component.html').default,
        styles: [require('./dashboard.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof CustomWidgetsService !== "undefined" && CustomWidgetsService) === "function" ? _a : Object, typeof (_b = typeof WidgetService !== "undefined" && WidgetService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof ReleaseNotesService !== "undefined" && ReleaseNotesService) === "function" ? _d : Object, typeof (_e = typeof Injector !== "undefined" && Injector) === "function" ? _e : Object])
], DashboardComponent);
export { DashboardComponent };
