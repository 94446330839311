import { EHospivillePermission, EMaPUIPermission, EMpStatus, } from '@mapuilabs/mpl-interfaces';
export class EstablishmentService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get a specific [[IEstablishment]]
         * @param id The [[IEstablishment]] [[Id]]
         * @returns A promise containing the [[IEstablishment]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.Establishment_Read) && !this.Access.authorize(EHospivillePermission.Establishment_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.establishment.byId.get({ id: id }, (res) => {
                    defer.resolve(res[0]);
                }, () => {
                    defer.reject(EMpStatus.RejectByServer);
                });
            }
            return defer.promise;
        };
        /**
         * Find [[IEstablishment]] by type [[EstablishmentType]]
         * @param name the given name to find
         * @param types
         * @returns {Promise<T>|IPromise<T>}
         */
        this.findByType = (name, types) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Establishment_Read) && !this.Access.authorize(EHospivillePermission.Establishment_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.establishment.byId.find({ name: name, types: types instanceof Array ? types.join(',') : [types] }, ans => defer.resolve(ans), () => defer.reject('Could not find hospitals'));
            }
            return defer.promise;
        };
        /**
         * Find all establishements with linked hospital by name and types
         * @param {string} name
         * @param {Array<EEstablishmentType> | EEstablishmentType} types
         * @return {angular.IPromise<Array<IEstablishment>>}
         */
        this.findWithHospitalByNameAndType = (name, types) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Establishment_Read) && !this.Access.authorize(EHospivillePermission.Establishment_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.establishment.withHospital.find({
                    name: name,
                    types: types instanceof Array ? types.join(',') : [types],
                }, ans => defer.resolve(ans), () => defer.reject('Could not find hospitals'));
            }
            return defer.promise;
        };
    }
}
