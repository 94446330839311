var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperIntl, MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { WidgetsModule } from '@app/shared/components/widgets/dashboard/widgets.module';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { CustomMatStepperIntl } from '@shared/components/CustomMatStepperIntl/CustomMatStepperIntl';
import { SharedModule } from '@shared/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HsDashboardComponent } from './components/dashboard/hs-dashboard.component';
import { HsDashboardWidgetContainerComponent } from './components/dashboard/widget/container/hs-dashboard-widget-container.component';
import { HsDashboardWidgetCountComponent } from './components/dashboard/widget/count/hs-dashboard-widget-count.component';
import { HsDashboardWidgetGroupListComponent } from './components/dashboard/widget/group-list/hs-dashboard-widget-group-list.component';
import { HsDashboardWidgetLaboListComponent } from './components/dashboard/widget/labo-list/hs-dashboard-widget-labo-list.component';
import { HsDashboardWidgetPublicationsSummaryComponent } from './components/dashboard/widget/labo-summary/hs-dashboard-widget-publications-summary.component';
import { HsDashboardWidgetNewsComponent } from './components/dashboard/widget/news/hs-dashboard-widget-news.component';
import { HsDrugFormComponent } from './components/form/drug/hs-form-drug.component';
import { HsMedicalDeviceSearchComponent } from './components/form/medical-device/hs-form-medical-device.component';
import { HsHealthProductDirectoryComponent } from './components/health-product-directory/hs-health-product-directory.component';
import { HsModalAlternativeFormComponent } from './components/modals/alternative/form/hs-modal-alternative-form.component';
import { HsModalBookletFormComponent } from './components/modals/booklet/form/hs-modal-booklet-form.component';
import { HsModalMarketFormComponent } from './components/modals/market/form/hs-modal-market-form.component';
import { HsModalPublicationDetailBroadcastListComponent } from './components/modals/publication/detail/broadcast-list/hs-modal-publication-detail-broadcast-list.component';
import { HsPublicationDetailStatsWidgetModalComponent } from './components/modals/publication/detail/stats-widget/hs-modal-publication-detail-stats-widget.component';
import { HsModalPublicationFormComponent } from './components/modals/publication/form/hs-modal-publication-form.component';
import { HsPublicationBroadcastTreeListComponent } from './components/publication/broadcast-list/broadcast-tree-list/hs-publication-broadcast-tree-list.component';
import { HsPublicationBroadcastListComponent } from './components/publication/broadcast-list/hs-publication-broadcast-list.component';
import { HsPublicationDetailActionTitleComponent } from './components/publication/detail/action-title/hs-publication-detail-action-title.component';
import { HsPublicationDetailAlternativeDetailComponent } from './components/publication/detail/alternatives/alternative/hs-publication-detail-alternative-detail.component';
import { HsPublicationDetailAlternativesComponent } from './components/publication/detail/alternatives/hs-publication-detail-alternatives.component';
import { HsPublicationDetailBroadcastListComponent } from './components/publication/detail/broadcast-list/hs-publication-detail-broadcast-list.component';
import { HsPublicationDetailCommentComponent } from './components/publication/detail/comments/comment/hs-publication-detail-comment.component';
import { HsPublicationDetailCommentsComponent } from './components/publication/detail/comments/hs-publication-detail-comments.component';
import { HsPublicationDetailFilesComponent } from './components/publication/detail/files/hs-publication-detail-files.component';
import { HsPublicationDetailComponent } from './components/publication/detail/hs-publication-detail.component';
import { HsPublicationDetailInformationComponent } from './components/publication/detail/information/hs-publication-detail-information.component';
import { HsPublicationDetailMarketComponent } from './components/publication/detail/market/hs-publication-detail-market.component';
import { HsPublicationDetailHealthProductsComponent } from './components/publication/detail/health-products/hs-publication-detail-health-products.component';
import { HsPublicationDetailStatsWidgetComponent } from './components/publication/detail/stats-widget/hs-publication-detail-stats-widget.component';
import { HsPublicationDetailSummaryComponent } from './components/publication/detail/summary/hs-publication-detail-summary.component';
import { HsPublicationDetailSupervisorComponent } from './components/publication/detail/supervisor/hs-publication-detail-supervisor.component';
import { HsPublicationDualViewComponent } from './components/publication/dual-view/hs-publication-dual-view.component';
import { HsPublicationListComponent } from './components/publication/list/hs-publication-list.component';
import { HsPublicationListItemComponent } from './components/publication/list/item/hs-publication-list-item.component';
import { HsPublicationListItemInfoComponent } from './components/publication/list/item/info/hs-publication-list-item-info.component';
import { HsPublicationOverviewComponent } from './components/publication/overview/hs-publication-overview.component';
import { HsPublicationSingleViewDetailComponent } from './components/publication/single-view/single-view-detail/hs-publication-single-view-detail.component';
import { HsPublicationSingleViewListComponent } from './components/publication/single-view/single-view-list/hs-publication-single-view-list.component';
import { HsPublicationStockIndicatorComponent } from './components/publication/stock-indicator/hs-publication-stock-indicator.component';
import { HsPublicationTagComponent } from './components/publication/tag/hs-publication-tag.component';
import { HsPublicationToolbarComponent } from './components/publication/toolbar/hs-publication-toolbar.component';
import { HsSidebarFiltersAdvancedHeaderComponent } from './components/sidebar/filters/advanced/header/hs-sidebar-filters-advanced-header.component';
import { HsSidebarFiltersAdvancedComponent } from './components/sidebar/filters/advanced/hs-sidebar-filters-advanced.component';
import { HsPublicationFiltersSidebarComponent } from './components/sidebar/filters/hs-sidebar-filters.component';
import { HsSidebarHistoryComponent } from './components/sidebar/history/hs-sidebar-history.component';
import { HsSidebarHistoryItemComponent } from './components/sidebar/history/item/hs-sidebar-history-item.component';
import { HsTransitionGroupComponent, HsTransitionGroupItemDirective } from './directives/hs-transition-group.directive';
import { HsAlternativesListComponent as HsAlternativesListComponent } from './components/alternatives-list/alternatives-list.component';
import { HsAlternativeToolbarComponent } from './components/alternatives-list/toolbar/hs-alternative-toolbar.component';
import { HsAlternativeFiltersSidebarComponent } from './components/alternatives-list/filters/hs-alternative-sidebar-filters.component';
import { HsStatisticComponent } from './components/statistic/hs-statistic.component';
import { HsModalPublicationStatisticProposedAlternativeComponent } from '@app/hospistock/shared/components/modals/publication/statistic/proposed-alternative/hs-modal-publication-statistic-proposed-alternative.component';
import { HsModalPublicationStatisticSelectedAlternativeComponent } from './components/modals/publication/statistic/selected-alternative/hs-modal-publication-statistic-selected-alternative.component';
import { HsHealthProductsRefPipe } from './pipes/healthProductsRef/health-products-ref.pipe';
let HsSharedModule = class HsSharedModule {
};
HsSharedModule = __decorate([
    NgModule({
        imports: [
            WidgetsModule,
            CommonModule,
            FuseSharedModule,
            FuseWidgetModule,
            InfiniteScrollModule,
            MomentModule,
            NgxChartsModule,
            QuillModule.forRoot(),
            NgScrollbarModule,
            SharedModule,
            // Material modules
            MatAutocompleteModule,
            MatBadgeModule,
            MatButtonModule,
            MatButtonToggleModule,
            MatCheckboxModule,
            MatChipsModule,
            MatDatepickerModule,
            MatDialogModule,
            MatDividerModule,
            MatExpansionModule,
            MatFormFieldModule,
            MatIconModule,
            MatInputModule,
            MatListModule,
            MatMenuModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRadioModule,
            MatRippleModule,
            MatSelectModule,
            MatSlideToggleModule,
            MatTableModule,
            MatTooltipModule,
            MatTreeModule,
            MatStepperModule
        ],
        declarations: [
            HsDashboardComponent,
            HsDashboardWidgetContainerComponent,
            HsDashboardWidgetCountComponent,
            HsDashboardWidgetGroupListComponent,
            HsDashboardWidgetLaboListComponent,
            HsDashboardWidgetPublicationsSummaryComponent,
            HsDashboardWidgetNewsComponent,
            HsDrugFormComponent,
            HsHealthProductDirectoryComponent,
            HsMedicalDeviceSearchComponent,
            HsModalAlternativeFormComponent,
            HsModalBookletFormComponent,
            HsModalMarketFormComponent,
            HsModalPublicationDetailBroadcastListComponent,
            HsModalPublicationFormComponent,
            HsModalPublicationStatisticProposedAlternativeComponent,
            HsModalPublicationStatisticSelectedAlternativeComponent,
            HsPublicationBroadcastListComponent,
            HsPublicationBroadcastTreeListComponent,
            HsPublicationDetailActionTitleComponent,
            HsPublicationDetailAlternativeDetailComponent,
            HsPublicationDetailAlternativesComponent,
            HsPublicationDetailBroadcastListComponent,
            HsPublicationDetailCommentComponent,
            HsPublicationDetailCommentsComponent,
            HsPublicationDetailComponent,
            HsPublicationDetailFilesComponent,
            HsPublicationDetailInformationComponent,
            HsPublicationDetailMarketComponent,
            HsPublicationDetailHealthProductsComponent,
            HsPublicationDetailStatsWidgetComponent,
            HsPublicationDetailStatsWidgetModalComponent,
            HsPublicationDetailSummaryComponent,
            HsPublicationDetailSupervisorComponent,
            HsPublicationDualViewComponent,
            HsPublicationFiltersSidebarComponent,
            HsPublicationListComponent,
            HsPublicationListItemComponent,
            HsPublicationListItemInfoComponent,
            HsPublicationOverviewComponent,
            HsPublicationSingleViewDetailComponent,
            HsPublicationSingleViewListComponent,
            HsPublicationStockIndicatorComponent,
            HsPublicationTagComponent,
            HsPublicationToolbarComponent,
            HsSidebarFiltersAdvancedComponent,
            HsSidebarFiltersAdvancedHeaderComponent,
            HsSidebarHistoryComponent,
            HsSidebarHistoryItemComponent,
            HsTransitionGroupComponent,
            HsTransitionGroupItemDirective,
            HsAlternativesListComponent,
            HsAlternativeToolbarComponent,
            HsAlternativeFiltersSidebarComponent,
            HsStatisticComponent,
            HsHealthProductsRefPipe
        ],
        exports: [
            HsDashboardWidgetContainerComponent,
            HsDashboardWidgetLaboListComponent,
            HsDashboardWidgetNewsComponent,
            HsMedicalDeviceSearchComponent,
            HsModalAlternativeFormComponent,
            HsModalPublicationDetailBroadcastListComponent,
            HsModalPublicationFormComponent,
            HsPublicationDetailAlternativesComponent,
            HsPublicationDetailBroadcastListComponent,
            HsPublicationDetailCommentsComponent,
            HsPublicationDetailComponent,
            HsPublicationDetailFilesComponent,
            HsPublicationDetailInformationComponent,
            HsPublicationDetailMarketComponent,
            HsPublicationDetailHealthProductsComponent,
            HsPublicationDetailStatsWidgetComponent,
            HsPublicationDetailStatsWidgetModalComponent,
            HsPublicationDetailSummaryComponent,
            HsPublicationDetailSupervisorComponent,
            HsPublicationDualViewComponent,
            HsPublicationFiltersSidebarComponent,
            HsPublicationListItemComponent,
            HsPublicationListItemInfoComponent,
            HsPublicationSingleViewListComponent,
            HsPublicationStockIndicatorComponent,
            HsPublicationTagComponent,
            HsPublicationToolbarComponent,
            HsSidebarFiltersAdvancedComponent,
            HsSidebarFiltersAdvancedHeaderComponent,
            HsSidebarHistoryComponent,
            HsTransitionGroupComponent,
            HsAlternativesListComponent,
            HsAlternativeToolbarComponent,
            HsAlternativeFiltersSidebarComponent
        ],
        providers: [
            {
                provide: STEPPER_GLOBAL_OPTIONS,
                useValue: { showError: true }
            },
            { provide: MatStepperIntl, useClass: CustomMatStepperIntl }
        ],
        entryComponents: [
            HsModalAlternativeFormComponent,
            HsDashboardWidgetPublicationsSummaryComponent,
            HsModalPublicationDetailBroadcastListComponent,
            HsPublicationDetailStatsWidgetModalComponent,
            HsModalPublicationFormComponent,
            HsDashboardWidgetContainerComponent,
            HsDashboardWidgetCountComponent,
            HsDashboardWidgetGroupListComponent,
            HsDashboardWidgetLaboListComponent,
            HsDashboardWidgetNewsComponent
        ]
    })
], HsSharedModule);
export { HsSharedModule };
