import { GeoService } from './geo.service';
import { MpSearchTownController } from './mp-search-town/mp-search-town.controller';
import { MpSearchTownComponent } from './mp-search-town/mp-search-town.component';
import { MpSearchCountryController } from './mp-search-country/mp-search-country.controller';
import { MpSearchCountryComponent } from './mp-search-country/mp-search-country.component';
import * as angular from 'angular';
angular
    .module('mapui.services.geo', [
    'mapui.services.auth',
])
    .service('GeoService', GeoService)
    .controller('MpSearchTownController', MpSearchTownController)
    .component('mpSearchTown', new MpSearchTownComponent())
    .controller('MpSearchCountryController', MpSearchCountryController)
    .component('mpSearchCountry', new MpSearchCountryComponent());
