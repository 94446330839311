var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { HsPublicationContainer } from '@mapuilabs/mpl-interfaces';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { lastValueFrom } from 'rxjs';
import { HsModalAlternativeFormComponent } from '../../../modals/alternative/form/hs-modal-alternative-form.component';
let HsPublicationDetailAlternativesComponent = class HsPublicationDetailAlternativesComponent {
    constructor(_changeDetectorRef, _fuseTranslationLoaderService, _injector, _matDialog, _snackbarService, _publicationContainerService, _translationService) {
        this._changeDetectorRef = _changeDetectorRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._injector = _injector;
        this._matDialog = _matDialog;
        this._snackbarService = _snackbarService;
        this._publicationContainerService = _publicationContainerService;
        this._translationService = _translationService;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init constants
        this.readonly = this._publicationContainerService.readonly;
    }
    // Accessors
    get metadata() {
        return this.publicationContainer.metadata;
    }
    get publication() {
        return this.publicationContainer.publication;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Retrieve alternatives
        this._retrieveAlternatives();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Remove alternative from alternatives.
     * @param id Alternative's id to remove;
     * @returns True if alternative is removed, false otherwise.
     */
    _removeAlternative(id) {
        const index = this.alternatives.findIndex((alternative) => Utils.compareIds(alternative, id));
        if (index !== -1) {
            this.alternatives.splice(index, 1);
            return true;
        }
        else {
            return false;
        }
    }
    /**
     * Sort alternatives list by moving selected alternative at the first index.
     */
    _sortAlternatives() {
        // Sort alternative by drug's name
        this.alternatives.sort((a, b) => a.healthProductRef.name.localeCompare(b.healthProductRef.name));
        // Set selectged alternative at first index
        if (this.selectedAlternative) {
            const isRemoved = this._removeAlternative(Utils.getId(this.selectedAlternative));
            if (isRemoved) {
                this.alternatives.unshift(this.selectedAlternative);
            }
        }
    }
    /**
     * Update selected alternative based on metadata;
     */
    _updateSelectedAlternative() {
        var _a, _b, _c, _d;
        const selectedAlternative = (_d = (_c = (_b = (_a = this.metadata) === null || _a === void 0 ? void 0 : _a.static) === null || _b === void 0 ? void 0 : _b.selectedAlternatives) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.alternative;
        this.selectedAlternative = this.alternatives.find((alternative) => Utils.compareIds(alternative, selectedAlternative));
    }
    /**
     * Update selected alternative, sort alternative list and trigger rendering
     */
    _updateAlternativeList() {
        // Update selected alternative
        this._updateSelectedAlternative();
        // Sort alternatives
        this._sortAlternatives();
        // Manually trigger rendering if view not destroyed.
        // View can be destroyed if method called after a server request.
        // In this case view can be destroyed if currentPublication changed before read alternatives response.
        if (!this._changeDetectorRef['destroyed']) {
            this._changeDetectorRef.detectChanges();
        }
    }
    /**
     * Retrieve alternatives from server and update list
     */
    _retrieveAlternatives() {
        return __awaiter(this, void 0, void 0, function* () {
            // Request alternatives based on publication Id
            this.alternatives = yield lastValueFrom(this._publicationContainerService.readAlternativesByPublication(Utils.getId(this.publication)));
            // Update alternative list
            this._updateAlternativeList();
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Alternatives CRUD operations
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create a new alternative by opening a form in dialog.
     * Update alternatives list if new alternative created.
     */
    addAlternative() {
        // Set dialog configuration
        const dialogRef = this._matDialog.open(HsModalAlternativeFormComponent, {
            data: {
                injector: this._injector,
                publication: this.publication
            },
            disableClose: true,
            minWidth: '60%',
            restoreFocus: false
        });
        // Open AlternativeFormModal
        try {
            dialogRef.afterClosed().subscribe({
                next: (alternative) => __awaiter(this, void 0, void 0, function* () {
                    if (alternative) {
                        // Add new alternative
                        const newAlternative = yield lastValueFrom(this._publicationContainerService.createAlternatives(Utils.getId(this.publication), alternative));
                        this.alternatives.push(newAlternative);
                        // Update alternative list
                        this._updateAlternativeList();
                        // Refresh metadata if first alternative added to trigger alternative tag
                        if (this.alternatives.length === 1) {
                            this._publicationContainerService.refreshDynamicMetadata(Utils.getId(this.publication));
                        }
                        // Notify user
                        this._snackbarService.open(this._translationService.instant('PUBLICATION.NOTIFS.ADD_ALTERNATIVE'));
                    }
                })
            });
        }
        catch (err) {
            this._snackbarService.openError(err);
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Alternative component event handler
    // -----------------------------------------------------------------------------------------------------
    onSelectionChanged() {
        // Update alternative list
        this._updateAlternativeList();
    }
    onDeleted(alternative) {
        // Reset selectedAlternative is deleted alternative was selected
        if (this.isSelected(alternative)) {
            Object.assign(this.metadata, { selectedAlternatives: [] });
        }
        // Remove alternative
        this._removeAlternative(Utils.getId(alternative));
        // Update alternative list
        this._updateAlternativeList();
        // Refresh metadata if last alternative removed to trigger alternative tag
        if (this.alternatives.length === 0) {
            this._publicationContainerService.refreshDynamicMetadata(Utils.getId(this.publication));
        }
    }
    isSelected(alternative) {
        return Utils.compareIds(this.selectedAlternative, alternative);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof HsPublicationContainer !== "undefined" && HsPublicationContainer) === "function" ? _h : Object)
], HsPublicationDetailAlternativesComponent.prototype, "publicationContainer", void 0);
HsPublicationDetailAlternativesComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-alternatives',
        template: require('./hs-publication-detail-alternatives.component.html').default,
        styles: [require('./hs-publication-detail-alternatives.component.scss')],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof Injector !== "undefined" && Injector) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object, typeof (_f = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _f : Object, typeof (_g = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _g : Object])
], HsPublicationDetailAlternativesComponent);
export { HsPublicationDetailAlternativesComponent };
