var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { HsModalPublicationDetailBroadcastListComponent } from '../../../modals/publication/detail/broadcast-list/hs-modal-publication-detail-broadcast-list.component';
let HsPublicationDetailBroadcastListComponent = class HsPublicationDetailBroadcastListComponent {
    constructor(_translateService, _dialog, _snackbarService) {
        this._translateService = _translateService;
        this._dialog = _dialog;
        this._snackbarService = _snackbarService;
        // Constants
        this.translationBase = 'PUBLICATION.DETAILS.BROADCAST_LIST';
    }
    // Accessors
    get isBroadcastedToAll() {
        return this.targetGroups.length === 0;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Log error and trigger snackbar with message
     * @param error Error to handle
     */
    _handleError(error) {
        console.error(error);
        this._snackbarService.openDefaultError();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open broadcast list in dedicated modal.
     */
    onShowList() {
        const config = {
            title: this._translateService.instant('PUBLICATION.TITLES.BROADCAST_LIST')
        };
        const dialogRef = this._dialog.open(HsModalPublicationDetailBroadcastListComponent, {
            data: { config, targetGroups: this.targetGroups },
            minWidth: '60%'
        });
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result === null || result === void 0 ? void 0 : result.error) {
                    this._handleError(result.error);
                }
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsPublicationDetailBroadcastListComponent.prototype, "targetGroups", void 0);
HsPublicationDetailBroadcastListComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-broadcast-list',
        template: `
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
            <mat-icon class="s-20 secondary-text">{{ isBroadcastedToAll ? 'domain' : 'domain_disabled' }}</mat-icon>

            <strong class="text-nowrap">
                {{ translationBase + '.STATUS' + (isBroadcastedToAll ? '.GLOBAL' : '.PARTIAL') | translate }}
                {{ 'COMMONS.PUNCTUATION.COLON' | translate }}
            </strong>

            <ng-container *ngIf="isBroadcastedToAll; else partial">
                <span>{{ translationBase + '.ALL' | translate }}</span>
            </ng-container>

            <ng-template #partial>
                <span>{{ translationBase + '.PARTIAL' | translate : { count: targetGroups.length } }}</span>

                <span class="cursor-pointer hint-text" (click)="onShowList()">
                    ({{ translationBase + '.SHOW_LIST' | translate }})
                </span>
            </ng-template>
        </div>
    `,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _a : Object, typeof (_b = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object])
], HsPublicationDetailBroadcastListComponent);
export { HsPublicationDetailBroadcastListComponent };
