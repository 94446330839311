export const locale = {
    lang: 'en',
    data: {
        RELEASE_NOTES_WIDGET: {
            FORBIDDEN_MESSAGE: 'Access denied',
            NO_LINES: `No news to display.`,
            CONTACT_US: 'Contact us',
            DETAILS: 'More info',
            MORE_LINES: `Load more`
        }
    }
};
