var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { LocationService } from '../../../../services/location/location.service';
import { find } from 'lodash';
import { Observable } from 'rxjs';
let OtherTabComponent = class OtherTabComponent {
    constructor(_locationService) {
        this._locationService = _locationService;
        _locationService.getAllRegions().subscribe((data) => {
            this.regions = data;
            if (this.hospital && this.form) {
                this._setFormControls(this.hospital);
            }
        });
    }
    ngOnChanges(changes) {
        if (changes.hospital) {
            this._setFormControls(this.hospital);
        }
    }
    onUpdateLatLng(geo) {
        this.form.controls.latitude.setValue(geo.lat);
        this.form.controls.longitude.setValue(geo.lng);
        this.form.markAsDirty();
    }
    _setFormControls(hospital) {
        this.form.setControl('phoneSTD', new UntypedFormControl(hospital.phoneSTD));
        this.form.setControl('phoneSCT', new UntypedFormControl(hospital.phoneSCT));
        this.form.setControl('emailPharmaDept', new UntypedFormControl(hospital.emailPharmaDept));
        this.form.setControl('website', new UntypedFormControl(hospital.website));
        this.form.setControl('fax', new UntypedFormControl(hospital.Fax));
        this.form.setControl('bedMCO', new UntypedFormControl(hospital.bedMCO));
        this.form.setControl('bedSSR', new UntypedFormControl(hospital.bedSSR));
        this.form.setControl('bedPSY', new UntypedFormControl(hospital.bedPSY));
        this.form.setControl('bedEPHAD', new UntypedFormControl(hospital.bedEPHAD));
        let foundRegion = null;
        if (hospital.region) {
            foundRegion = find(this.regions, (reg) => {
                var _a;
                return reg._id == ((_a = hospital.region) === null || _a === void 0 ? void 0 : _a._id);
            });
        }
        this.form.setControl('region', new UntypedFormControl(foundRegion));
        this.form.setControl('latitude', new UntypedFormControl(hospital.lat));
        this.form.setControl('longitude', new UntypedFormControl(hospital.lng));
    }
    updateHospital(hospital) {
        return new Observable((observer) => {
            hospital.phoneSTD = this.form.value.phoneSTD;
            hospital.phoneSCT = this.form.value.phoneSCT;
            hospital.emailPharmaDept = this.form.value.emailPharmaDept;
            hospital.website = this.form.value.website;
            hospital.Fax = this.form.value.fax;
            hospital.bedMCO = this.form.value.bedMCO;
            hospital.bedSSR = this.form.value.bedSSR;
            hospital.bedPSY = this.form.value.bedPSY;
            hospital.bedEPHAD = this.form.value.bedEPHAD;
            hospital.region = this.form.value.region;
            hospital.lat = this.form.value.latitude;
            hospital.lng = this.form.value.longitude;
            hospital.geo = [this.form.value.longitude, this.form.value.latitude];
            observer.next();
            observer.complete();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], OtherTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Hospital !== "undefined" && Hospital) === "function" ? _c : Object)
], OtherTabComponent.prototype, "hospital", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], OtherTabComponent.prototype, "active", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], OtherTabComponent.prototype, "animationDone", void 0);
OtherTabComponent = __decorate([
    Component({
        selector: 'mpx-other-tab',
        template: require('./other-tab.component.html').default,
        styles: [require('./other-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof LocationService !== "undefined" && LocationService) === "function" ? _a : Object])
], OtherTabComponent);
export { OtherTabComponent };
