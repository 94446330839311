import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class HvModalWhenController {
    /** @ngInject */
    constructor(ModalService, Api) {
        this.ModalService = ModalService;
        this.Api = Api;
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        this.ok = () => {
            if (this.type == true) {
                this.ModalService.close({
                    date: {
                        hours: this.date.getHours(),
                        minutes: this.date.getMinutes(),
                    },
                });
            }
            else if (this.type == false)
                this.ModalService.close({ moment: this.moment });
        };
        this.Api.hvConstType.get((ans) => {
            this.dailyEvents = ans.dailyEvents;
        });
    }
    $onInit() {
    }
    $onChanges(changes) {
    }
}
