export const locale = {
    lang: 'fr',
    data: {
        listExternalActors: {
            TITLE: 'Acteurs externes',
            SEARCH_ACTOR: 'Rechercher un acteur externe',
            TABLE: {
                NAME: 'Nom',
                TYPE: 'Type',
                CONFIGURATIONS: 'Configurations'
            },
            DELETED: "L'acteur a été supprimé !",
            CONFIRM_MESSAGE: 'Voulez-vous vraiment supprimer cet acteur ?'
        }
    }
};
