var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EDosageType } from '@mapuilabs/mpl-interfaces';
import { SelectPosologyComponent } from '@shared/components/select-posology/select-posology.component';
import { locale as fr } from './i18n/fr';
import { locale as en } from './i18n/en';
import { ActivitiesInstancesService } from '@services/hospiville/activities/activities-instances.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
let FormDrugDosagePosologyComponent = class FormDrugDosagePosologyComponent {
    constructor(_activitiesInstancesService, _fuseTranslationLoaderService, _snackBarService, translationService) {
        this._activitiesInstancesService = _activitiesInstancesService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackBarService = _snackBarService;
        this.translationService = translationService;
        /**
         * Enum containing all dosage types
         */
        this.eDosageType = EDosageType;
        this.onSendInstance = new EventEmitter();
        /**
         * Called when the dosageType changes. Updates the form controls for eye drop.
         */
        this._updateDosageType = (type) => {
            if (type == EDosageType.eye_drop) {
                this.drugDosagePosologyForm.removeControl('quantity');
                this.drugDosagePosologyForm.addControl('leftEye', new UntypedFormControl());
                this.drugDosagePosologyForm.addControl('rightEye', new UntypedFormControl());
            }
            else {
                this.drugDosagePosologyForm.addControl('quantity', new UntypedFormControl(null, [Validators.required, Validators.min(0)]));
                this.drugDosagePosologyForm.removeControl('leftEye');
                this.drugDosagePosologyForm.removeControl('rightEye');
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(fr, en);
    }
    ngOnInit() {
        this.drugDosagePosologyForm.addControl('medicName', new UntypedFormControl(null, [Validators.required]));
        this.drugDosagePosologyForm.addControl('quantity', new UntypedFormControl(null, [Validators.required, Validators.min(0)]));
        this.drugDosagePosologyForm.addControl('dosageType', new UntypedFormControl(null, [Validators.required]));
        this.drugDosagePosologyForm.addControl('posologyType', new UntypedFormControl(null, [Validators.required]));
        this.dosageTypeControl.valueChanges.subscribe(this._updateDosageType, console.error);
    }
    get medicNameControl() {
        var _a;
        return (_a = this.drugDosagePosologyForm) === null || _a === void 0 ? void 0 : _a.get('medicName');
    }
    get dosageTypeControl() {
        var _a;
        return (_a = this.drugDosagePosologyForm) === null || _a === void 0 ? void 0 : _a.get('dosageType');
    }
    get posologyTypeControl() {
        var _a;
        return (_a = this.drugDosagePosologyForm) === null || _a === void 0 ? void 0 : _a.get('posologyType');
    }
    get leftEyeControl() {
        var _a;
        return (_a = this.drugDosagePosologyForm) === null || _a === void 0 ? void 0 : _a.get('leftEye');
    }
    get rightEyeControl() {
        var _a;
        return (_a = this.drugDosagePosologyForm) === null || _a === void 0 ? void 0 : _a.get('rightEye');
    }
    onSearchMedicSelected(drug) {
        this.selectedMedic = drug;
    }
    sendInstance() {
        const newInstance = {
            codeATC: this.selectedMedic.sp_catc_code_fk,
            UCD13: this.selectedMedic.ucd13,
            denomination: this.selectedMedic.denomination,
            DCI: this.selectedMedic.sac_nom,
            info: {
                dosage: this.drugDosagePosologyForm.value.quantity,
                dosageType: this.drugDosagePosologyForm.value.dosageType,
                dosageDropInfo: this.drugDosagePosologyForm.value.dosageType == EDosageType.eye_drop
                    ? {
                        left: this.drugDosagePosologyForm.value.leftEye,
                        right: this.drugDosagePosologyForm.value.rightEye
                    }
                    : null,
                posologies: []
            }
        };
        newInstance.info.posologies = JSON.parse(JSON.stringify(this._selectPosology.posology));
        newInstance.info.posology = { type: this.drugDosagePosologyForm.value.posologyType, specific: null };
        this.onSendInstance.emit(newInstance);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _e : Object)
], FormDrugDosagePosologyComponent.prototype, "drugDosagePosologyForm", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], FormDrugDosagePosologyComponent.prototype, "onSendInstance", void 0);
__decorate([
    ViewChild(SelectPosologyComponent, { static: false }),
    __metadata("design:type", typeof (_g = typeof SelectPosologyComponent !== "undefined" && SelectPosologyComponent) === "function" ? _g : Object)
], FormDrugDosagePosologyComponent.prototype, "_selectPosology", void 0);
FormDrugDosagePosologyComponent = __decorate([
    Component({
        selector: 'form-drug-dosage-posology',
        template: require('./form-drug-dosage-posology.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ActivitiesInstancesService !== "undefined" && ActivitiesInstancesService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], FormDrugDosagePosologyComponent);
export { FormDrugDosagePosologyComponent };
