var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
let LoanMapService = class LoanMapService {
    constructor(_http) {
        this._http = _http;
        this._endPoint = '/api/loans/map';
    }
    getAll(filters = null) {
        //TODO permissions
        let params = new HttpParams();
        if (filters) {
            params = params.append('filters', JSON.stringify(filters));
        }
        return this._http.get(this._endPoint, { params: params });
    }
};
LoanMapService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object])
], LoanMapService);
export { LoanMapService };
