import 'c3';
import * as d3 from 'd3';
/** @ngInject */
export class MpHomeController {
    /** @ngInject */
    constructor(Auth, Api, HospitalService, $timeout, $rootScope, $state, Access) {
        this.Auth = Auth;
        this.Api = Api;
        this.HospitalService = HospitalService;
        this.$timeout = $timeout;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.Access = Access;
        /**
         * Fetch all the counts
         * @private
         */
        this._countAvailable = () => {
            this.HospitalService.countAvailable(this.Access.hospital._id)
                .then(res => this.count['myHosp'] = res);
            this.HospitalService.countAllAvailable()
                .then(res => this.count['all'] = res);
        };
        this.goToAvailableList = () => {
            this.$state.go('exchangeV2.available.available-hospital');
        };
        /**
         * Convert a timestamp to a readable date
         * @param timestamp The date timestamp
         * @return {string}
         */
        this.time_format = (timestamp) => {
            return d3.time.format('%Y-%m')(new Date(timestamp));
        };
        this.count = { 'myHosp': 0, 'all': 0 };
        this.busy = true;
        this.user = this.Access.user;
        this.nbLastWeek = 0;
        this.show = true;
    }
    $onInit() {
        this._countAvailable();
        this.$rootScope.$on('$translateChangeSuccess', () => {
            this.show = false;
            this.$timeout(0).then(() => this.show = true);
        });
        this.Api.lastSession.get({ id: this.Access.user._id }, (sessions) => {
            this.lastSession = new Date(sessions[sessions.length > 1 ? 1 : 0].date);
        });
    }
}
