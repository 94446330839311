export const locale = {
    lang: 'en',
    data: {
        listExternalActors: {
            TITLE: 'External actors',
            SEARCH_ACTOR: 'Search an external actor',
            TABLE: {
                NAME: 'Name',
                TYPE: 'Type',
                CONFIGURATIONS: 'Configurations'
            },
            DELETED: 'The external actor has been deleted!',
            CONFIRM_MESSAGE: 'Do you really want to delete this external actor?'
        }
    }
};
