import { HospitalService } from './hospital.service';
import { MpHospitalSearchController } from './mp-hospital-search/mp-hospital-search.controller';
import { MpHospitalSearchComponent } from './mp-hospital-search/mp-hospital-search.component';
import * as angular from 'angular';
angular
    .module('mapui.services.hospital', [
    'mapui.services.modal',
    'mapui.services.toast',
])
    .service('HospitalService', HospitalService)
    .controller('MpHospitalSearchController', MpHospitalSearchController)
    .component('mpHospitalSearch', new MpHospitalSearchComponent());
