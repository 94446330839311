import * as angular from 'angular';
import { VaultService } from './vault.service';
import { MpModalVaultWarningController } from './mp-modal-vault-warning/mp-modal-vault-warning.controller';
import { MpModalVaultWarningComponent } from './mp-modal-vault-warning/mp-modal-vault-warning.component';
import { MpModalVaultUnlockController } from './mp-modal-vault-unlock/mp-modal-vault-unlock.controller';
import { MpModalVaultUnlockComponent } from './mp-modal-vault-unlock/mp-modal-vault-unlock.component';
angular.module('mapui.services.vault', [])
    .service('VaultService', VaultService)
    .controller('MpModalVaultWarningController', MpModalVaultWarningController)
    .component('mpModalVaultWarning', new MpModalVaultWarningComponent())
    .controller('MpModalVaultUnlockController', MpModalVaultUnlockController)
    .component('mpModalVaultUnlock', new MpModalVaultUnlockComponent());
