var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
import { TranslationService } from '../translation/translation.service';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { CellValidators } from './spreadsheet-parser.const';
import { FileErrorSeverity, SpreadsheetParserError, SpreadsheetParserErrorContainer, SpreadsheetParserErrorsMessage } from './spreadsheet-parser.types';
import { SpreadsheetParserUtils } from '@services/spreadsheet-parser/spreadsheet-parser.utils';
let SpreadsheetParserService = class SpreadsheetParserService {
    constructor(_translationService, _fuseTranslationLoaderService) {
        this._translationService = _translationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._checkEmptyFile = (fileErrorContainer, headers, jsonSpreadSheet, config) => {
            // Check if the spreadsheet is empty
            if (jsonSpreadSheet.length === 0) {
                // Stack error and stop process by throwing new error
                const message = this._translate('ERRORS.MAJOR.EMPTY_FILE');
                const error = new SpreadsheetParserError(message);
                this._addError(fileErrorContainer, FileErrorSeverity.MAJOR, error, config);
            }
            return fileErrorContainer;
        };
        this._checkAllRequiredColumns = (fileErrorContainer, headers, jsonSpreadSheet, config) => {
            // Check if one of the required columns is not present in the spreadsheet
            config.columns.forEach((column) => {
                let isRequired;
                try {
                    isRequired = this._isColumnRequired(config.columns, column.label);
                }
                catch (err) {
                    // Log error
                    console.error(err);
                    // Stack error and stop process by throwing new error
                    const message = this._translate('ERRORS.MAJOR.INTERNAL_ERROR');
                    const error = new SpreadsheetParserError(message, column.label.toString());
                    this._addError(fileErrorContainer, FileErrorSeverity.MAJOR, error, config);
                }
                if (isRequired && !headers.includes(this._getColumnLabel(column.label, config))) {
                    // Stack error and stop process by throwing new error
                    const message = this._translate('ERRORS.MAJOR.MISSING_COLUMN');
                    const error = new SpreadsheetParserError(message, column.label.toString());
                    this._addError(fileErrorContainer, FileErrorSeverity.MAJOR, error, config);
                }
            });
            return fileErrorContainer;
        };
        this._checkCellContent = (fileErrorContainer, headers, jsonSpreadSheetProcessed, config) => {
            // Iterate on each row
            jsonSpreadSheetProcessed.forEach((row) => {
                // Iterate on each row's cell
                config.columns.forEach((column) => {
                    const label = this._getColumnLabel(column.label, config);
                    const cell = row[label];
                    const isEmpty = cell === undefined || cell === null;
                    const isRequired = this._isCellRequired(config.columns, column.label, row, config);
                    // Stack errors messages in fileErrorContainer
                    const stackErrors = (messages, severity) => {
                        // Remove falsy values then process messages
                        _.compact(Array.isArray(messages) ? messages : [messages]).forEach((message) => {
                            // Stack error and stop process by throwing new error
                            const error = new SpreadsheetParserError(message, label, row.rowNum + 1, cell === null || cell === void 0 ? void 0 : cell.toString());
                            this._addError(fileErrorContainer, severity, error, config);
                        });
                    };
                    // Processes (MINOR errors)
                    if (!isEmpty) {
                        const errorMessages = column.processes.map((process) => {
                            const processedCell = process.function(row[label]);
                            const isDifferent = processedCell !== row[label];
                            row[label] = processedCell;
                            const message = process.message;
                            const translateMessage = () => message && this._translate(message.key, message.params);
                            return isDifferent && translateMessage();
                        });
                        // Filter and stack errors
                        stackErrors(_.compact(errorMessages), FileErrorSeverity.MINOR);
                    }
                    // Validators (MODERATE errors)
                    if (!isEmpty || isRequired) {
                        const validatorErrorMessage = this._checkCellValidators(row, label, column.validators, config);
                        if (validatorErrorMessage) {
                            // Filter and stack errors
                            const errorMessage = Array.isArray(validatorErrorMessage)
                                ? validatorErrorMessage.map((item) => this._translate(item.key, item.params)).join(' ')
                                : this._translate(validatorErrorMessage.key, validatorErrorMessage.params);
                            stackErrors(errorMessage, FileErrorSeverity.MODERATE);
                        }
                    }
                });
            });
            return fileErrorContainer;
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    _getColumnLabel(column, config) {
        var _a;
        const label = column.toString();
        return ((_a = config.options) === null || _a === void 0 ? void 0 : _a.headerToLowerCase) ? label.toLocaleLowerCase() : label;
    }
    _addError(fileErrorContainer, severity, error, config) {
        // Stack error
        fileErrorContainer.addError(severity, error);
        // Handle abortOnMajorError option
        if (config.options.abortOnMajorError && severity === FileErrorSeverity.MAJOR) {
            throw new Error(SpreadsheetParserErrorsMessage.ABORTING_CHECK);
        }
    }
    /**
     * Parsing with Worker for new web navigators (Worker uses another browser thread to process the file)
     * @param file
     * @param format
     * @param options
     * @private
     */
    _parseSpreadsheetWorker(file, format, options) {
        return new Promise((resolve, reject) => {
            // Create an instance of the Web Worker by specifying the path to the file "spreadsheet-parser.worker.ts".
            this.worker = new Worker(new URL('./spreadsheet-parser.worker', import.meta.url));
            this.worker.onmessage = (event) => {
                this.worker.terminate();
                resolve(event.data);
            };
            this.worker.onerror = (error) => {
                this.worker.terminate();
                reject(error);
            };
            // Function to send a message to the Web Worker and trigger intensive processing
            this.worker.postMessage({ file, format, options });
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Check methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Bind TranslationService's instant method
     * @param value Text to translate
     * @param arg Translation arguments
     * @returns Translated text
     */
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    _validateCell(cellValue, validator) {
        return new UntypedFormControl(cellValue, validator.function).errors;
    }
    _isValidatorNameRequired(validator) {
        return validator.name === CellValidators.required().name;
    }
    _isConditionValid(condition, row, config) {
        const isConditionValid = (condition) => {
            const conditionColumnLabel = this._getColumnLabel(condition.column, config);
            // Validate trought FormControl as CellValidatorCondition are based on angular Validators
            return (this._isConditionValid(condition.validator.condition, row, config) &&
                !this._validateCell(row[conditionColumnLabel], condition.validator));
        };
        const isValidEmptyOrValid = (condition) => condition ? isConditionValid(condition) : true;
        return Array.isArray(condition)
            ? condition.every((item) => isValidEmptyOrValid(item))
            : isValidEmptyOrValid(condition);
    }
    /**
     * Check if a column is required. A column is considered as required if it has a validator "required" with no condition.
     * The required condition is recursively checked over the nested validators.
     * @param columns SpreadsheetParserConfig columns.
     * @param label Column label to check.
     * @returns Is the provided column required.
     */
    _isColumnRequired(columns, label) {
        const column = columns.find((column) => column.label === label);
        // Guard: column not found
        if (!column) {
            throw new Error('Cannot check required column. column not found');
        }
        const isValidatorRequiredWithoutCondition = (validator) => this._isValidatorNameRequired(validator) &&
            (Array.isArray(validator.condition) ? validator.condition.length === 0 : !validator.condition);
        return column.validators.some((validator) => isValidatorRequiredWithoutCondition(validator));
    }
    _isCellRequired(columns, label, row, config) {
        const sameConditionColumnGuard = (validator) => {
            var _a;
            return Array.isArray(validator.condition)
                ? validator.condition.some((condition) => condition.column === label)
                : ((_a = validator.condition) === null || _a === void 0 ? void 0 : _a.column) === label;
        };
        const isValidatorRequired = (validator) => {
            // Guard: same column than SpreadsheetParserConfigItem (inifite loop)
            if (sameConditionColumnGuard(validator)) {
                throw new Error("Cannot check required column. Validator's condition cannot have the same column name than the SpreadsheetParserConfigItem");
            }
            return this._isValidatorNameRequired(validator) && this._isConditionValid(validator.condition, row, config);
        };
        const column = columns.find((column) => column.label === label);
        // Guard: column not found
        if (!column) {
            throw new Error('Cannot check required column. column not found');
        }
        return column.validators.some((validator) => isValidatorRequired(validator));
    }
    _checkCellValidators(row, columnLabel, validators, config) {
        // Returns first error's message encountered
        for (const validator of validators) {
            // Condition valid or null
            if (!validator.condition || this._isConditionValid(validator.condition, row, config)) {
                // Validate trought FormControl as CellValidators are based on angular Validators
                const validationError = this._validateCell(row[columnLabel], validator);
                if (validationError) {
                    return validator.getMessage();
                }
            }
        }
    }
    _checkJsonSpreadsheet(headers, jsonSpreadSheet, config) {
        // Parse spreadsheet
        const fileErrorContainer = new SpreadsheetParserErrorContainer();
        // Check converted file
        const checkFunctions = [this._checkEmptyFile, this._checkAllRequiredColumns, this._checkCellContent];
        try {
            for (const checkFunction of checkFunctions) {
                checkFunction(fileErrorContainer, headers, jsonSpreadSheet, config);
            }
        }
        catch (err) {
            console.warn('Aborting spreadsheet import due to MAJOR error encountered.');
            // Propagate error if it is not an expected parsing error
            if (!fileErrorContainer.hasErrors(FileErrorSeverity.MAJOR)) {
                throw err;
            }
        }
        return fileErrorContainer;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Convert Loaded file as ArrayBuffer, check result using spreadsheetConfig
     * Return major & minor errors occured during processing.
     * @returns HsStockoutMatkertExtended object & Errors.
     */
    parseAsJson(fileContainer, config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // FileContainer type guard
            if (typeof fileContainer.data === 'string') {
                throw new Error(SpreadsheetParserErrorsMessage.WRONG_ARRAY_BUFFER);
            }
            if (fileContainer) {
                // Remove file extension in name
                const filename = fileContainer.file.name.replace(/\.[^.$]+$/, '');
                // Convert spreadsheet file using either Worker or Service depending on browser version
                const parseSpreadsheetResult = typeof Worker !== 'undefined'
                    ? // Web Workers are supported in this environment.
                        yield this._parseSpreadsheetWorker(fileContainer.data, 'json', config.options)
                    : // Web Workers are not supported in this environment (IE).
                        SpreadsheetParserUtils.parseSpreadsheet(fileContainer.data, 'json', config.options);
                const headers = parseSpreadsheetResult.headers;
                const jsonSpreadSheet = parseSpreadsheetResult.spreadsheet;
                // Check converted spreadsheet using config
                const errors = this._checkJsonSpreadsheet(headers, jsonSpreadSheet, config);
                // Handle abortOnMajorError option
                if (((_a = config.options) === null || _a === void 0 ? void 0 : _a.abortOnMajorError) && errors.hasErrors(FileErrorSeverity.MAJOR)) {
                    return { file: undefined, errors };
                }
                // Delete rows related to FileError of MODERATE severity
                const rowsToDelete = new Set(errors.moderate.errors.map(({ row }) => row));
                const newJsonSpreadSheet = jsonSpreadSheet.filter((row) => !rowsToDelete.has(row.rowNum + 1));
                return { file: { name: filename, content: newJsonSpreadSheet }, errors };
            }
        });
    }
};
SpreadsheetParserService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SpreadsheetParserService);
export { SpreadsheetParserService };
