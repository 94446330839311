export const locale = {
    lang: 'en',
    data: {
        listMembers: {
            TITLE: 'Users',
            SEARCH_MEMBER: 'Search a user',
            TABLE: {
                NAME: 'Name',
                EMAIL: 'Email',
                HOSPITAL: 'Hospital',
                ROLES: 'Roles',
                LAST_CONNECT: 'Last connection'
            },
            DELETED: 'The user has been deleted!',
            CONFIRM_MESSAGE: 'Do you really want to delete this user?'
        }
    }
};
