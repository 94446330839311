var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ExDatepickerLimitsComponent } from './dates/ex-datepicker-limits/ex-datepicker-limits.component';
import { ExDatesSelectorComponent } from './dates/ex-dates-selector/ex-dates-selector.component';
import { ExSinceDateMenuComponent } from './dates/ex-since-date-menu/ex-since-date-menu.component';
import { ExChipComponent } from './ex-chip/ex-chip.component';
import { ExDataUsageComponent } from './ex-data-usage/ex-data-usage.component';
import { ExFileDragNDropComponent } from './ex-file-drag-n-drop/ex-file-drag-n-drop.component';
import { ExFileUploaderComponent } from './ex-file-uploader/ex-file-uploader.component';
import { ExFileViewerComponent } from './ex-file-viewer/ex-file-viewer.component';
import { ExGenericDialogComponent } from './ex-generic-dialog/ex-generic-dialog.component';
import { ExKeyDataComponent } from './ex-key-data/ex-key-data.component';
import { ExSearchDrugComponent } from './ex-search-drug/ex-search-drug.component';
import { ExSearchEstablishmentFinessComponent } from './ex-search-establishment-finess/ex-search-establishment-finess.component';
import { ExSelectDosageTypeComponent } from './ex-select-dosage-type/ex-select-dosage-type.component';
import { ExSelectPosologyComponent } from './ex-select-posology/ex-select-posology.component';
import { SandboxComponent } from './sandbox.component';
import { ExSearchHospitalComponent } from '@app/admin/sandbox/ex-search-hospital/ex-search-hospital.component';
import { ExSearchMedicalDeviceComponent } from '@app/admin/sandbox/ex-search-medical-device/ex-search-medical-device.component';
const states = [
    {
        name: 'mapui.adm.sandbox.search-medical-device',
        url: '/search-medical-device',
        views: {
            'content@mapui': {
                component: ExSearchMedicalDeviceComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.drug-search',
        url: '/search-drug',
        views: {
            'content@mapui': {
                component: ExSearchDrugComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.main',
        url: '/main',
        views: {
            'content@mapui': {
                component: SandboxComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.chip',
        url: '/chip',
        views: {
            'content@mapui': {
                component: ExChipComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.data-usage',
        url: '/data-usage',
        views: {
            'content@mapui': {
                component: ExDataUsageComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.key-data',
        url: '/key-data',
        views: {
            'content@mapui': {
                component: ExKeyDataComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.file-drag-n-drop',
        url: '/file-drag-n-drop',
        views: {
            'content@mapui': {
                component: ExFileDragNDropComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.file-uploader',
        url: '/file-uploader',
        views: {
            'content@mapui': {
                component: ExFileUploaderComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.search-hospital',
        url: '/search-hospital',
        views: {
            'content@mapui': {
                component: ExSearchHospitalComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.search-establishment-finess',
        url: '/search-establishment-finess',
        views: {
            'content@mapui': {
                component: ExSearchEstablishmentFinessComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.generic-dialog',
        url: '/generic-dialog',
        views: {
            'content@mapui': {
                component: ExGenericDialogComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.file-viewer',
        url: '/file-viewer',
        views: {
            'content@mapui': {
                component: ExFileViewerComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.select-dosage-type',
        url: '/select-dosage-type',
        views: {
            'content@mapui': {
                component: ExSelectDosageTypeComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.select-posology',
        url: '/select-posology',
        views: {
            'content@mapui': {
                component: ExSelectPosologyComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.datepicker-limits',
        url: '/datepicker-limits',
        views: {
            'content@mapui': {
                component: ExDatepickerLimitsComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.dates-selector',
        url: '/dates-selector',
        views: {
            'content@mapui': {
                component: ExDatesSelectorComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    },
    {
        name: 'mapui.adm.sandbox.since-date-menu',
        url: '/since-date-menu',
        views: {
            'content@mapui': {
                component: ExSinceDateMenuComponent
            }
        },
        data: {
            theme: 'theme-purple'
        }
    }
];
let SandboxRoutingModule = class SandboxRoutingModule {
};
SandboxRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], SandboxRoutingModule);
export { SandboxRoutingModule };
