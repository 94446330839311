var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AdminGroupsService } from '../../services/admin/group/admin-groups.service';
import { lastValueFrom } from 'rxjs';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { ListGroupsComponent } from './list-groups/list-groups.component';
const states = [
    {
        name: 'mapui.adm.groups.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: ListGroupsComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.groups.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: GroupDetailComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'group',
                deps: [Transition, AdminGroupsService],
                resolveFn: (trans, adminGroupsService) => {
                    if (trans.params().id != 'new') {
                        return lastValueFrom(adminGroupsService.getById(trans.params().id));
                    }
                }
            }
        ]
    }
];
let GroupsRoutingModule = class GroupsRoutingModule {
};
GroupsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states })],
        exports: [UIRouterUpgradeModule]
    })
], GroupsRoutingModule);
export { GroupsRoutingModule };
