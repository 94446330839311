var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { DialogService } from '@services/dialog/dialog.service';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
import { TranslationService } from '@services/translation/translation.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { RolesService } from '@services/role/roles.service';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
let ActionsTabComponent = class ActionsTabComponent {
    constructor(_dialogService, _admMemberService, _translationService, _snackBarService, _stateService, _rolesService, _admHospitalService) {
        this._dialogService = _dialogService;
        this._admMemberService = _admMemberService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._stateService = _stateService;
        this._rolesService = _rolesService;
        this._admHospitalService = _admHospitalService;
        this.pageType = 'new';
        this._rolesService.getAllHospital().subscribe((data) => {
            for (const project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'hospistock':
                        this.hospistockRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                }
            }
            this.setFormControls();
        });
    }
    ngOnInit() {
        this.setFormControls();
    }
    setFormControls() {
        var _a, _b, _c, _d;
        const laboMapuiRoles = [];
        const laboHospivilleRoles = [];
        const laboHospistockRoles = [];
        const laboWorkspaceRoles = [];
        if (this.laboratory) {
            for (const role of this.laboratory.roles) {
                if (((_a = this.mapuiRoles) === null || _a === void 0 ? void 0 : _a.length) && this.mapuiRoles.filter((elem) => elem._id === role._id).length) {
                    laboMapuiRoles.push(this.mapuiRoles.find((elem) => elem._id === role._id));
                }
                else if (((_b = this.hospivilleRoles) === null || _b === void 0 ? void 0 : _b.length) &&
                    this.hospivilleRoles.filter((elem) => elem._id === role._id).length) {
                    laboHospivilleRoles.push(this.hospivilleRoles.find((elem) => elem._id === role._id));
                }
                else if (((_c = this.hospistockRoles) === null || _c === void 0 ? void 0 : _c.length) &&
                    this.hospistockRoles.filter((elem) => elem._id === role._id).length) {
                    laboHospistockRoles.push(this.hospistockRoles.find((elem) => elem._id === role._id));
                }
                else if (((_d = this.workspaceRoles) === null || _d === void 0 ? void 0 : _d.length) &&
                    this.workspaceRoles.filter((elem) => elem._id === role._id).length) {
                    laboWorkspaceRoles.push(this.workspaceRoles.find((elem) => elem._id === role._id));
                }
            }
        }
        this.form.setControl('roleMapui', new UntypedFormControl(laboMapuiRoles));
        this.form.setControl('roleHospiville', new UntypedFormControl(laboHospivilleRoles));
        this.form.setControl('roleHospistock', new UntypedFormControl(laboHospistockRoles));
        this.form.setControl('roleWorkspace', new UntypedFormControl(laboWorkspaceRoles));
        this.form.setControl('memberSince', new UntypedFormControl(this.laboratory && this.laboratory.memberSince ? new Date(this.laboratory.memberSince) : null));
        this.form.setControl('memberTo', new UntypedFormControl(this.laboratory && this.laboratory.memberTo ? new Date(this.laboratory.memberTo) : null));
    }
    updateLaboratory(labo) {
        labo.roles = this.form.value.roleMapui.concat(this.form.value.roleHospiville, this.form.value.roleHospistock, this.form.value.roleWorkspace);
        labo.memberSince = this.form.value.memberSince;
        labo.memberTo = this.form.value.memberTo;
    }
    logInHospital() {
        this._dialogService
            .openConfirmDialog({
            message: this._translationService.instant('LABOS.DETAILS.PERMISSIONS.CONFIRM_LOGAS')
        })
            .subscribe((ans) => {
            if (ans) {
                this._admMemberService.changeCurrentMemberHospital(this.laboratory).subscribe((data) => {
                    if (data !== null) {
                        window.location.reload();
                    }
                    else {
                        this._snackBarService.open(this._translationService.instant('COMMONS.ERRORS.DEFAULT'));
                    }
                });
            }
        });
    }
    // Delete the current laboratory after user confirmation
    deleteHospital() {
        this._dialogService
            .openConfirmDialog({
            message: this._translationService.instant('LABOS.DETAILS.PERMISSIONS.CONFIRM_DELETE')
        })
            .subscribe((data) => {
            if (data) {
                this._admHospitalService.delete(this.laboratory).subscribe(() => {
                    this._snackBarService.open(this._translationService.instant('LABOS.DETAILS.PERMISSIONS.SNACK_DELETE'));
                    this._stateService.go('mapui.adm.labos.list');
                });
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof Hospital !== "undefined" && Hospital) === "function" ? _h : Object)
], ActionsTabComponent.prototype, "laboratory", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_j = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _j : Object)
], ActionsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ActionsTabComponent.prototype, "pageType", void 0);
ActionsTabComponent = __decorate([
    Component({
        selector: 'mpx-actions-tab',
        template: require('./actions-tab.component.html').default,
        styles: [require('./actions-tab.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof RolesService !== "undefined" && RolesService) === "function" ? _f : Object, typeof (_g = typeof AdminHospitalService !== "undefined" && AdminHospitalService) === "function" ? _g : Object])
], ActionsTabComponent);
export { ActionsTabComponent };
