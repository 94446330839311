export class IsAdministratorDirective {
    /** @ngInject */
    constructor(ngIfDirective, Access) {
        this.ngIfDirective = ngIfDirective;
        this.Access = Access;
        this.link = (...args) => {
            const scope = args[0];
            const element = args[1];
            const attributes = args[2];
            const initialNgIf = attributes.ngIf;
            let ifEvaluator;
            if (initialNgIf) {
                ifEvaluator = () => {
                    return scope.$eval(initialNgIf) && this.Access.isAdmin();
                };
            }
            else {
                ifEvaluator = () => {
                    return this.Access.isAdmin();
                };
            }
            attributes.ngIf = ifEvaluator;
            this._ngIf.link.apply(this._ngIf, args);
        };
        this._ngIf = this.ngIfDirective[0];
        this.transclude = this._ngIf.transclude;
        this.priority = this._ngIf.priority - 1;
        this.terminal = this._ngIf.terminal;
        this.restrict = this._ngIf.restrict;
    }
    static factory() {
        const directive = (ngIfDirective, Access) => new IsAdministratorDirective(ngIfDirective, Access);
        directive.$inject = ['ngIfDirective', 'Access'];
        return directive;
    }
}
