export const subscriptionsEmails = {
    newLoanBorrowing: {
        translate: "SETTINGS.MAILS.SUBSCRIPTION.LOAN_BORROWING"
    },
    availableSummary: {
        translate: "SETTINGS.MAILS.SUBSCRIPTION.AVAILABLE"
    },
    newPreparation: {
        translate: "SETTINGS.MAILS.SUBSCRIPTION.PREP"
    },
    newProductDemand: {
        translate: "SETTINGS.MAILS.SUBSCRIPTION.PD"
    },
    newsletter: {
        translate: "SETTINGS.MAILS.SUBSCRIPTION.NEWSLETTER"
    },
    stockoutSummary: {
        translate: "SETTINGS.MAILS.SUBSCRIPTION.STOCKOUT"
    }
};
