var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { IdentityService } from '@app/services/identity/identity.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EHsHealthProductTypes, EHsPublicationType, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import * as moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
let HsPublicationDetailComponent = class HsPublicationDetailComponent {
    constructor(_changeDetectorRef, _fuseTranslationLoaderService, _healthProductService, _identityService, _publicationContainerService) {
        this._changeDetectorRef = _changeDetectorRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._healthProductService = _healthProductService;
        this._identityService = _identityService;
        this._publicationContainerService = _publicationContainerService;
        // Dynamic data
        this.computedTags = [];
        // Enums
        this.ePublicationType = EHsPublicationType;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        this._resetWidgetsData();
        // Init constants
        this.readonly = this._publicationContainerService.readonly;
        this.pharmaciesReadConfig = {
            title: 'PUBLICATION.TITLES.LABO_WIDGET.PHARMACY_READ',
            actionLabel: 'Voir liste',
            columns: [
                {
                    label: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
                    attribute: 'hospitalName'
                },
                {
                    label: 'PUBLICATION.MODAL.LABO_STATS.READ',
                    attribute: 'readDate'
                }
            ]
        };
        this.pharmaciesSeenConfig = {
            title: 'PUBLICATION.TITLES.LABO_WIDGET.PHARMACY_SEEN',
            actionLabel: 'Voir liste',
            columns: [
                {
                    label: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
                    attribute: 'hospitalName'
                },
                {
                    label: 'PUBLICATION.MODAL.LABO_STATS.SEEN',
                    attribute: 'seenDate'
                }
            ]
        };
        this.pharmaciesInBookletConfig = {
            title: 'PUBLICATION.TITLES.LABO_WIDGET.PHARMACY_BOOKLET',
            actionLabel: 'Voir liste',
            columns: [
                {
                    label: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
                    attribute: 'hospitalName'
                }
            ]
        };
        this.pharmaciesSelectAlternativeConfig = {
            title: 'PUBLICATION.TITLES.LABO_WIDGET.PHARMACY_SELECT_ALTERNATIVE',
            actionLabel: 'Voir liste',
            columns: [
                {
                    label: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
                    attribute: 'hospitalName'
                },
                {
                    label: 'PUBLICATION.TITLES.ALTERNATIVE',
                    attribute: 'alternativeName'
                },
                {
                    label: 'Source',
                    attribute: 'alternativeCreatorName'
                },
                {
                    label: 'Date',
                    attribute: 'selectionDate'
                }
            ]
        };
    }
    // Accessors
    get currentPublicationContainer() {
        var _a;
        return (_a = this.currentPublicationContainer$) === null || _a === void 0 ? void 0 : _a.value;
    }
    get marketItems() {
        var _a;
        return (_a = this.currentPublicationContainer) === null || _a === void 0 ? void 0 : _a.marketItems;
    }
    get currentMetadata() {
        var _a;
        return (_a = this.currentPublicationContainer) === null || _a === void 0 ? void 0 : _a.metadata;
    }
    get currentPublication() {
        var _a;
        return (_a = this.currentPublicationContainer) === null || _a === void 0 ? void 0 : _a.publication;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this.currentPublicationContainer$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            // Reset widgets data
            this._resetWidgetsData();
            // Update local properties
            this._updateLocalPublicationProperties();
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
    }
    ngAfterViewInit() {
        this._publicationContainerService.scrollbarDetailPanel = this.scrollBarDetailPanel;
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _resetWidgetsData() {
        this.pharmaciesRead$ = new BehaviorSubject(null);
        this.pharmaciesSeen$ = new BehaviorSubject(null);
        this.pharmaciesInBooklet$ = new BehaviorSubject(null);
        this.pharmaciesSelectAlternative$ = new BehaviorSubject(null);
    }
    _updatePharmaciesRead(read) {
        this.pharmaciesRead$.next({
            value: read === null || read === void 0 ? void 0 : read.length,
            data: read.map((item) => ({
                hospitalName: item.hospital,
                readDate: moment(item.firstRead.date).format('ll')
            })),
            publication: this.currentPublication
        });
    }
    _updatePharmaciesSeen(seen) {
        this.pharmaciesSeen$.next({
            value: seen === null || seen === void 0 ? void 0 : seen.length,
            data: seen.map((item) => ({
                hospitalName: item.hospital,
                seenDate: moment(item.firstSeen.date).format('ll')
            })),
            publication: this.currentPublication
        });
    }
    _updatePharmaciesInBooklet(inBooklet) {
        this.pharmaciesInBooklet$.next({
            value: inBooklet === null || inBooklet === void 0 ? void 0 : inBooklet.length,
            data: inBooklet.map((item) => ({
                hospitalName: item.name
            })),
            publication: this.currentPublication
        });
    }
    _updatePharmaciesSelectAlternative(selectedAlternatives) {
        const pharmaciesWhoHaveSelectedAlternative = selectedAlternatives.filter((item) => {
            const isCreatedByGroup = item.alternative.creatorType === EHsUserTypes.GA;
            const creator = isCreatedByGroup ? item.alternative.creatorGroup : item.alternative.creator;
            const somethingToCompare = isCreatedByGroup
                ? this._publicationContainerService.getPurchaseGroupWhereUserIsAdministrator(this._identityService.user)
                : this._identityService.user.hospital;
            return Utils.compareIds(creator, somethingToCompare);
        });
        this.pharmaciesSelectAlternative$.next({
            value: pharmaciesWhoHaveSelectedAlternative.length,
            denominator: selectedAlternatives.length,
            data: selectedAlternatives.map((value) => ({
                hospitalName: value.hospital.name,
                alternativeName: value.alternative.healthProductRef.name,
                alternativeCreatorName: this._publicationContainerService.readAlternativeCreatorName(value.alternative),
                selectionDate: moment(value.date).format('ll')
            })),
            publication: this.currentPublication
        });
    }
    _updateLocalPublicationProperties() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            // Tags
            if (this.config.header.tags) {
                this.computedTags = this._publicationContainerService.computeTags(this.currentMetadata);
            }
            // Update isImportant
            this.isImportant = (_b = (_a = this.currentMetadata) === null || _a === void 0 ? void 0 : _a.static) === null || _b === void 0 ? void 0 : _b.important;
            // Widgets
            if (this.config.stats) {
                const { read, seen, inBooklet, selectedAlternatives } = yield this._publicationContainerService.getAllHospitalStatsByPublication(this.currentPublication);
                this._updatePharmaciesRead(read);
                this._updatePharmaciesSeen(seen);
                this._updatePharmaciesInBooklet(inBooklet);
                this._updatePharmaciesSelectAlternative(selectedAlternatives);
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSupervisorChanged(supervisor) {
        this._publicationContainerService.setMetadataSupervisor(Utils.getId(this.currentPublication), this.currentMetadata, supervisor);
    }
    onDownloadPublicationStatSpreadsheet() {
        this._publicationContainerService.downloadPublicationStatSpreadsheet(this.currentPublication);
    }
    getTypeLabel(value) {
        return this._publicationContainerService.getTypeLabel(value);
    }
    setImportant() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._publicationContainerService.setMetadataImportant(Utils.getId(this.currentPublication), this.currentMetadata);
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationDetailComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof BehaviorSubject !== "undefined" && BehaviorSubject) === "function" ? _f : Object)
], HsPublicationDetailComponent.prototype, "currentPublicationContainer$", void 0);
__decorate([
    ViewChild(NgScrollbar),
    __metadata("design:type", typeof (_g = typeof NgScrollbar !== "undefined" && NgScrollbar) === "function" ? _g : Object)
], HsPublicationDetailComponent.prototype, "scrollBarDetailPanel", void 0);
HsPublicationDetailComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail',
        template: require('./hs-publication-detail.component.html').default,
        styles: [require('./hs-publication-detail.component.scss')],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _e : Object])
], HsPublicationDetailComponent);
export { HsPublicationDetailComponent };
