import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvExtensivePatientInterviewComponent } from './hv-extensive-patient-interview.component';
import { HvExtensivePatientInterviewController } from './hv-extensive-patient-interview.controller';
import { HvInformationInterviewComponent } from './hv-information-interview/hv-information-interview.component';
import { HvInformationInterviewController } from './hv-information-interview/hv-information-interview.controller';
import { HvManagingDrugInterviewComponent } from './hv-managing-drug-interview/hv-managing-drug-interview.component';
import { HvManagingDrugInterviewController } from './hv-managing-drug-interview/hv-managing-drug-interview.controller';
import { HvPathologiesInterviewComponent } from './hv-pathologies-interview/hv-pathologies-interview.component';
import { HvPathologiesInterviewController } from './hv-pathologies-interview/hv-pathologies-interview.controller';
import { HvTreatmentsInterviewComponent } from './hv-treatments-interview/hv-treatments-interview.component';
import { HvTreatmentsInterviewController } from './hv-treatments-interview/hv-treatments-interview.controller';
import { HvUnderstandingInterviewComponent } from './hv-understanding-interview/hv-understanding-interview.component';
import { HvUnderstandingInterviewController } from './hv-understanding-interview/hv-understanding-interview.controller';
angular
    .module('hospiville.patient.extensive-patient-interview', [])
    .config(configExtensivePatientInterview)
    .component('hvExtensivePatientInterview', new HvExtensivePatientInterviewComponent())
    .controller('HvExtensivePatientInterviewController', HvExtensivePatientInterviewController)
    .component('hvInformationInterview', new HvInformationInterviewComponent())
    .controller('HvInformationInterviewController', HvInformationInterviewController)
    .component('hvManagingDrugInterview', new HvManagingDrugInterviewComponent())
    .controller('HvManagingDrugInterviewController', HvManagingDrugInterviewController)
    .component('hvPathologiesInterview', new HvPathologiesInterviewComponent())
    .controller('HvPathologiesInterviewController', HvPathologiesInterviewController)
    .component('hvTreatmentsInterview', new HvTreatmentsInterviewComponent())
    .controller('HvTreatmentsInterviewController', HvTreatmentsInterviewController)
    .component('hvUnderstandingInterview', new HvUnderstandingInterviewComponent())
    .controller('HvUnderstandingInterviewController', HvUnderstandingInterviewController);
/** @ngInject */
function configExtensivePatientInterview($stateProvider, msApiProvider) {
    $stateProvider.state('hospiville.extensive-patient-interview', {
        url: '/entretien-recueil-infomration',
        data: {
            access: EHospivillePermission.ExtensivePatientInterview_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-extensive-patient-interview></hv-extensive-patient-interview>',
            },
        },
        bodyClass: 'hospiville extensive-patient-interview',
        resolve: {
            Data: function (msApi) {
                return msApi.resolve('treatment.data@get');
            },
        },
    });
    msApiProvider.register('treatment.data', ['app/main/hospiville/hv-patient/tmp-data/treatment.json']);
}
