import { EMpStatus, EPosologyType, ETransit, } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
import { saveAs } from "file-saver";
import * as moment from "moment";
export class HvCarePlanController {
    /** @ngInject */
    constructor(Api, Access, _, HospivilleService, InformationService, MemberService, CarePlanService, ToastService, DrugInstanceService, $window, $scope) {
        this.Api = Api;
        this.Access = Access;
        this._ = _;
        this.HospivilleService = HospivilleService;
        this.InformationService = InformationService;
        this.MemberService = MemberService;
        this.CarePlanService = CarePlanService;
        this.ToastService = ToastService;
        this.DrugInstanceService = DrugInstanceService;
        this.$window = $window;
        this.$scope = $scope;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[from], { value: value }).translate;
        };
        /**
         * Get the translation of a given value
         * @param value
         * @returns {string}
         */
        this.getShortDosage = (value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes.dosages, { value: value }).translateShort;
        };
        /**
         * On generation of the pdf register save carePlan
         */
        this.saveAndDownload = () => {
            if (this.migrationState) {
                return;
            }
            this.CarePlanService.saveCarePlane(this.carePlan).then((item) => {
                this.ToastService.show(EMpStatus.Ok);
                this.CarePlanService.downloadCarePlanPDF(item, this.patient).then((ans) => {
                    const blob = new Blob([(ans.data)], { type: 'application/pdf' });
                    saveAs(blob, `plan_de_prise_${this.patient.fullName} ${moment().format('L')}.pdf`);
                }).catch((err) => {
                    console.error(err);
                    this.ToastService.show(EMpStatus.RejectByServer);
                });
            }).catch((err) => {
                console.error(err);
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        this.diService = this.DrugInstanceService;
        this.establishment = this.Access.hospital;
        this.carePlan = {
            instances: [],
        };
        this.selectedMember = {};
        this.openPrescriptionInfo = true;
        this.indextable = -1;
        this.$scope.$on('refreshPatientFile', () => {
            this._init();
        });
    }
    /**
     * init carePlan
     */
    $onInit() {
        this._init();
        this.migrationState = this.Access.isHVMigrationState();
    }
    _init() {
        this.Api.constType.get((constTypes) => {
            this.constTypes = constTypes;
            this.Api.hvConstType.get((hvConstType) => {
                this.constTypes = this._.extend(this.constTypes, hvConstType);
            });
        });
        this.ready = false;
        if (this.Access.patient) {
            this.HospivilleService.getPatient()
                .then((patient) => {
                this.patient = patient;
                // const medrec = this._.find(this.patient.medicationReconciliations, (elem) => {
                //     return elem.active == true;
                // });
                this.HospivilleService.getMedRec().then(item => {
                    this.medicationReconciliation = item;
                    if (this.medicationReconciliation.prescription
                        && (this.medicationReconciliation.prescription.transit == ETransit.Exit
                            || this.medicationReconciliation.prescription.transit == ETransit.Transfer)) {
                        this.CarePlanService.getCarePlan(this.medicationReconciliation._id)
                            .then((item) => {
                            if (Utils.getId(item)) {
                                this.carePlan = item;
                                this.updateCarePlan();
                                if (this.carePlan.healthProfessional) {
                                    const i = this._.indexOf(this._.map(this.staff, (member) => {
                                        return member.fullName;
                                    }), this.carePlan.healthProfessional.name);
                                    if (i != -1) {
                                        this.selectedMember = this.staff[i];
                                        this.selectedMember.type = this.carePlan.healthProfessional.job;
                                    }
                                }
                            }
                            else if (this.initCarePlan()) {
                                this.CarePlanService.addCarePlane(this.carePlan).then((carePlan) => {
                                    this.carePlan._id = carePlan._id;
                                });
                            }
                        }).catch((err) => {
                            console.error(err);
                        });
                        this.ready = true;
                    }
                });
            });
        }
        this.MemberService.getByHosp(this.establishment._id)
            .then((members) => {
            this.staff = members;
        });
    }
    /**
     * init CarePlane
     */
    initCarePlan() {
        let newdrug = false;
        if (!this.carePlan.medicationReconciliation) {
            this.carePlan.medicationReconciliation = this.medicationReconciliation;
            this.carePlan.prescription = this.medicationReconciliation.prescription;
            if (!this.carePlan.date)
                this.carePlan.date = new Date();
        }
        for (let inst of this.medicationReconciliation.prescription.instances) {
            let index = this._.indexOf(this._.map(this.carePlan.instances, (e) => {
                return e.drugInstance._id;
            }), inst._id);
            if (index == -1) {
                let carePlanInstance = {
                    drugInstance: null,
                    periods: { 1: { 1: 0 }, 2: { 1: 0 }, 3: { 1: 0 }, 4: { 1: 0 } },
                    other: null,
                    comment: null,
                    explanation: null,
                };
                carePlanInstance.drugInstance = inst;
                carePlanInstance.periods = this.setPeriods(inst, carePlanInstance);
                newdrug = true;
                this.carePlan.instances.push(carePlanInstance);
            }
        }
        return newdrug;
    }
    /**
     * setPeriods of careplanInstance
     * @param instance
     * @param carePlanInstance
     */
    setPeriods(instance, carePlanInstance = null) {
        let periods;
        periods = { 1: { 1: 0 }, 2: { 1: 0 }, 3: { 1: 0 }, 4: { 1: 0 } };
        if (instance.validSource && instance.validSource.dosageType == 14) {
            periods = { 1: { 1: 0, 2: 0 }, 2: { 1: 0, 2: 0 }, 3: { 1: 0, 2: 0 }, 4: { 1: 0, 2: 0 } };
        }
        carePlanInstance.other = null;
        if (instance.validSource) {
            if (instance.validSource.posologies[0].presetCode == EPosologyType.Morning) {
                periods[1] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (instance.validSource.posologies[0].presetCode == EPosologyType.Noon) {
                periods[2] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (instance.validSource.posologies[0].presetCode == EPosologyType.Evening) {
                periods[3] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (instance.validSource.posologies[0].presetCode == EPosologyType.M_N_E) {
                periods[1] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
                periods[2] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
                periods[3] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (instance.validSource.posologies[0].presetCode == EPosologyType.M_E) {
                periods[1] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
                periods[3] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (instance.validSource.posologies[0].presetCode == EPosologyType.BEDTIME) {
                periods[4] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (instance.validSource.posologies[0].presetCode == EPosologyType.M_N_E_B) {
                periods[1] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
                periods[2] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
                periods[3] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
                periods[4] = (instance.validSource && instance.validSource.dosageType != 14) ? { 1: instance.validSource.dosage } :
                    { 1: instance.validSource.dosageDropInfo.left, 2: instance.validSource.dosageDropInfo.right };
            }
            else if (carePlanInstance) {
                carePlanInstance.other = this.DrugInstanceService.generateDosageSentence(instance.validSource) + this.DrugInstanceService.generatePosologySentence(instance.validSource.posologies[0]);
                carePlanInstance.periods = angular.copy(periods);
            }
        }
        return periods;
    }
    /**
     * set DrugInstance of the prescription
     * @param drugInstance
     * @param index
     */
    setDrugInstanceViewer(drugInstance, index) {
        this.hoverIndex = -1;
        if (this.indextable == index) {
            this.drugInstanceViewer = null;
            this.indextable = -1;
            this.openPrescriptionInfo = false;
            return;
        }
        this.openPrescriptionInfo = true;
        this.drugInstanceViewer = drugInstance;
        if (this.drugInstanceViewer.validSource) {
            let i = this._.indexOf(this._.map(this.constTypes.dosages, (e) => {
                return e.value;
            }), this.drugInstanceViewer.validSource.dosageType);
            this.dosageViewer = this.constTypes.dosages[i].translate;
            this.posologiViewer = this.DrugInstanceService.generatePosologySentence(this.drugInstanceViewer.validSource.posologies[0]);
        }
        this.indextable = index;
    }
    /**
     * save Careplan
     */
    save(printToast = true) {
        this.CarePlanService.saveCarePlane(this.carePlan).then(item => {
            if (printToast)
                this.ToastService.show(EMpStatus.Ok);
        }).catch((err) => {
            this.ToastService.show(EMpStatus.RejectByServer);
            console.error(err);
        });
    }
    /**
     * update CarePlan when the prescription is modified
     */
    updateCarePlan() {
        let tmpCarePlan = angular.copy(this.carePlan);
        tmpCarePlan.instances = [];
        for (let inst of this.medicationReconciliation.prescription.instances) {
            let index = this._.indexOf(this._.map(this.carePlan.instances, (e) => {
                return e.drugInstance._id;
            }), inst._id);
            if (index == -1) {
                let carePlanInstance = {
                    drugInstance: null,
                    periods: { 1: { 1: 0 }, 2: { 1: 0 }, 3: { 1: 0 }, 4: { 1: 0 } },
                    other: null,
                    comment: null,
                    explanation: null,
                };
                carePlanInstance.drugInstance = inst;
                carePlanInstance.periods = this.setPeriods(inst, carePlanInstance);
                tmpCarePlan.instances.push(carePlanInstance);
            }
            else {
                this.carePlan.instances[index].periods = this.setPeriods(inst, this.carePlan.instances[index]);
                tmpCarePlan.instances.push(this.carePlan.instances[index]);
            }
        }
        this.carePlan = tmpCarePlan;
        this.save(false);
    }
    /**
     * Selection of HealProfessional
     */
    selectedItemChange() {
        this.carePlan.healthProfessional = {
            job: this.selectedMember.type,
            name: this.selectedMember.fullName,
        };
    }
    /**
     * @param drugInstance
     * @param index
     */
    mouseOver(drugInstance, index) {
        if ((this.openPrescriptionInfo == true && this.indextable == -1) || (this.hoverIndex != index && this.indextable == -1)) {
            this.drugInstanceViewer = drugInstance;
            if (this.drugInstanceViewer.validSource) {
                let i = this._.indexOf(this._.map(this.constTypes.dosages, (e) => {
                    return e.value;
                }), this.drugInstanceViewer.validSource.dosageType);
                this.dosageViewer = this.constTypes.dosages[i].translate;
                this.posologiViewer = this.DrugInstanceService.generatePosologySentence(this.drugInstanceViewer.validSource.posologies[0]);
            }
            this.hoverIndex = index;
        }
    }
    /**
     * when blured
     */
    mouseLeave() {
        this.hoverIndex = -1;
    }
}
