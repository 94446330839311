var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { EFilterType, Hospital } from '@mapuilabs/mpl-interfaces';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslationService } from '../../../../../services/translation/translation.service';
import { locale as french } from '../../i18n/fr';
import { locale as english } from '../../i18n/en';
import { UrlService } from '../../../../../services/url/url.service';
import { StatsMedicationReconciliationService } from '../../../../../services/stats/medication-reconciliation.service';
let HospitalDetailComponent = class HospitalDetailComponent {
    constructor(_urlService, _statsMedicationReconciliationService, _fuseTranslationLoaderService, _translationService) {
        var _a, _b;
        this._urlService = _urlService;
        this._statsMedicationReconciliationService = _statsMedicationReconciliationService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this.updateCreationGraph = new EventEmitter();
        this.updateTimeGraph = new EventEmitter();
        this.timeAverage = 0;
        this.dateLimits = {
            start: (_a = this._urlService.getParam('period')) === null || _a === void 0 ? void 0 : _a.start,
            end: (_b = this._urlService.getParam('period')) === null || _b === void 0 ? void 0 : _b.end
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Toolbar
        this.sinceDateConfig = {
            button: {
                icon: { name: 'date_range', classes: ['secondary-text'] },
                label: this._translationService.instant('COMMONS.WORDS.PERIOD'),
                classes: ['cap']
            }
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Header
        this.pageHeaderConfig = {
            title: this.hospital.name,
            icon: 'local_hospital'
        };
    }
    ngAfterViewInit() {
        this.updateCreationGraph.emit();
        this.updateTimeGraph.emit();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getMedRecCreationGraphData(step, params) {
        return new Promise((resolve, reject) => {
            this._statsMedicationReconciliationService
                .getCreationGraphData(step, params.period, this.hospital._id)
                .subscribe((graphData) => {
                resolve([
                    {
                        name: this.hospital.name,
                        series: graphData
                    }
                ]);
            }, reject);
        });
    }
    onSinceChange($event) {
        this.dateLimits = $event;
    }
    setGraphsAverage(graphs) {
        let sum = null;
        let nbrValues = 0;
        for (const iGraph in graphs) {
            if (!graphs[0][iGraph] || !graphs[1][iGraph]) {
                continue;
            }
            const valueSum = graphs[0][iGraph].value + graphs[1][iGraph].value;
            if (valueSum > 0) {
                nbrValues++;
                if (sum === null) {
                    sum = valueSum;
                }
                else {
                    sum += valueSum;
                }
            }
        }
        this.timeAverage = sum === null ? 0 : Math.round((sum / nbrValues) * 100) / 100;
    }
    getMedRecTimeGraphData(step, params) {
        return new Promise((resolve, reject) => {
            this._statsMedicationReconciliationService
                .getTimeGraphData(step, params.period, this.hospital._id)
                .subscribe((graphData) => {
                resolve([
                    {
                        name: this._translationService.instant('STATS_HOSPIVILLE.GRAPH_TIME.CURVES.MED_REC'),
                        series: graphData[0]
                    },
                    {
                        name: this._translationService.instant('STATS_HOSPIVILLE.GRAPH_TIME.CURVES.DRUG_BAL'),
                        series: graphData[1]
                    }
                ]);
                this.setGraphsAverage(graphData);
            }, reject);
        });
    }
    getURLPeriodFilter() {
        return {
            filters: JSON.stringify([
                {
                    type: EFilterType.RANGE,
                    attribute: 'period',
                    start: this.dateLimits.start,
                    end: this.dateLimits.end
                }
            ])
        };
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Hospital !== "undefined" && Hospital) === "function" ? _e : Object)
], HospitalDetailComponent.prototype, "hospital", void 0);
HospitalDetailComponent = __decorate([
    Component({
        selector: 'mpx-hospital-detail',
        template: require('./hospital-detail.component.html').default,
        styles: [require('./hospital-detail.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UrlService !== "undefined" && UrlService) === "function" ? _a : Object, typeof (_b = typeof StatsMedicationReconciliationService !== "undefined" && StatsMedicationReconciliationService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], HospitalDetailComponent);
export { HospitalDetailComponent };
