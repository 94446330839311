var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { Component, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import { StateService } from '@uirouter/angular';
import { delay } from 'rxjs/operators';
import { ToolbarService } from '../../../layout/hospiville/toolbar/toolbar.service';
import { Auth as AjsAuth } from '../../../main/services/auth/auth.service';
import { navigation } from '../../../navigation';
import { IdentityService } from '../../services/identity/identity.service';
import { MemberService } from '../../services/member/member.service';
import { NavBarService } from '../../services/nav-bar-subject/nav-bar-subject.service';
import { PermissionService } from '../../services/permission/permission.service';
let NavbarComponent = class NavbarComponent {
    constructor(_fuseSidebarService, _fuseNavigationService, _memberService, _permissionService, _injector, _toolbarService, _identityService, _navBarService, _ajsAuth, _state) {
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseNavigationService = _fuseNavigationService;
        this._memberService = _memberService;
        this._permissionService = _permissionService;
        this._injector = _injector;
        this._toolbarService = _toolbarService;
        this._identityService = _identityService;
        this._navBarService = _navBarService;
        this._ajsAuth = _ajsAuth;
        this._state = _state;
        this._navigation = navigation;
        this.currentPlatform = 'MAPUI';
    }
    set directive(theDirective) {
        if (!theDirective) {
            return;
        }
        this._fusePerfectScrollbar = theDirective;
        // Update the scrollbar on collapsable item toggle
        this._fuseNavigationService.onItemCollapseToggled
            .pipe(delay(500))
            .subscribe(() => this._fusePerfectScrollbar.update());
    }
    ngOnInit() {
        if (!this._fuseNavigationService.getNavigation('navbar')) {
            this._navigation.forEach((nav) => {
                this._handleNavbarItem(nav);
            });
            this._navigation
                .find((nav) => nav.id === 'hospiville')
                .children.find((childNav) => childNav.id === 'hospiville.activities-v2-dest').hidden =
                !this._ajsAuth.authorize(EHospivillePermission.Activities_SeeMenuItem) || !this._ajsAuth.isPharmacy();
            this._navigation
                .find((nav) => nav.id === 'hospiville')
                .children.find((childNav) => childNav.id === 'hospiville.activities-v2-source').hidden =
                !this._ajsAuth.authorize(EHospivillePermission.Activities_SeeMenuItem) || !this._ajsAuth.isHospital();
            this._fuseNavigationService.register('navbar', this._navigation);
            this._fuseNavigationService.setCurrentNavigation('navbar');
            this._toolbarService.registerCallbacktoggleNavbar(() => {
                this._fuseSidebarService.getSidebar('navbar').toggleOpen();
            });
        }
        // folded -> not open
        this.folded = !this._identityService.user.config.sidenavOpened;
        if (this.folded) {
            document.getElementsByTagName('body')[0].classList.add('navbar-folded');
        }
        else {
            document.getElementsByTagName('body')[0].classList.remove('navbar-folded');
        }
        if (this._state.current.name.startsWith('hospiville')) {
            this.currentPlatform = 'HOSPIVILLE';
        }
        else {
            this.currentPlatform = 'MAPUI';
        }
        setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('.nav-link.active', -120);
        }, 50);
    }
    ngAfterViewInit() {
        this._fuseSidebarService.getSidebar('navbar').openedChanged.subscribe((isOpen) => {
            if (isOpen === false) {
                document.getElementsByTagName('body')[0].classList.remove('navbar-folded');
            }
        });
    }
    _handleNavbarItem(navigation) {
        var _a;
        // Go through navigation recurcively
        if ((_a = navigation.children) === null || _a === void 0 ? void 0 : _a.length) {
            navigation.children.forEach((nav) => this._handleNavbarItem(nav));
        }
        this._hideNavbarItemByPermission(navigation);
        this._handleNavbarItemFunction(navigation);
    }
    _hideNavbarItemByPermission(navigation) {
        if (Array.isArray(navigation.permissions)) {
            navigation.hidden = this._permissionService.authorizeAny(navigation.permissions) === false;
        }
        else if (typeof navigation.permissions === 'string') {
            navigation.hidden = this._permissionService.authorize(navigation.permissions) === false;
        }
    }
    _handleNavbarItemFunction(navigation) {
        if (navigation.displayFn) {
            navigation.hidden = !navigation.displayFn(this._injector, navigation);
        }
    }
    toggleFolded() {
        this.folded = !this.folded;
    }
    foldedChanged(folded) {
        if (folded) {
            document.getElementsByTagName('body')[0].classList.add('navbar-folded');
        }
        else {
            document.getElementsByTagName('body')[0].classList.remove('navbar-folded');
        }
        this._navBarService.navBarUnfolded.next(!folded);
        this._memberService.toggleFoldedNavBar(!folded);
    }
};
__decorate([
    ViewChild(FusePerfectScrollbarDirective, { static: true }),
    __metadata("design:type", typeof (_l = typeof FusePerfectScrollbarDirective !== "undefined" && FusePerfectScrollbarDirective) === "function" ? _l : Object),
    __metadata("design:paramtypes", [typeof (_m = typeof FusePerfectScrollbarDirective !== "undefined" && FusePerfectScrollbarDirective) === "function" ? _m : Object])
], NavbarComponent.prototype, "directive", null);
NavbarComponent = __decorate([
    Component({
        selector: 'mpx-navbar',
        template: require('./navbar.component.html').default,
        styles: [require('../../app.scss'), require('./navbar.component.scss')],
        encapsulation: ViewEncapsulation.None
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _a : Object, typeof (_b = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _b : Object, typeof (_c = typeof MemberService !== "undefined" && MemberService) === "function" ? _c : Object, typeof (_d = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _d : Object, typeof (_e = typeof Injector !== "undefined" && Injector) === "function" ? _e : Object, typeof (_f = typeof ToolbarService !== "undefined" && ToolbarService) === "function" ? _f : Object, typeof (_g = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _g : Object, typeof (_h = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _h : Object, typeof (_j = typeof AjsAuth !== "undefined" && AjsAuth) === "function" ? _j : Object, typeof (_k = typeof StateService !== "undefined" && StateService) === "function" ? _k : Object])
], NavbarComponent);
export { NavbarComponent };
