import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class FileUploaderService {
    /** @ngInject */
    constructor(FileUploader, $cookies, Access, Api, Auth, $q) {
        this.FileUploader = FileUploader;
        this.$cookies = $cookies;
        this.Access = Access;
        this.Api = Api;
        this.Auth = Auth;
        this.$q = $q;
        /**
         * Must be called before using FileUploadService;
         * @private
         */
        this.init = (autoClearQueue = true, allowedExtensions = undefined, sizeLimit = 50 * 1024 * 1024, checkWorkspaceSize = true) => {
            this.allowedExtensions = allowedExtensions;
            this.sizeLimit = sizeLimit;
            this.autoClearQueue = autoClearQueue;
            this._initializeCallbacks();
            this.uploader = new this.FileUploader();
            this.uploader.clearQueue();
            this.uploader.filters = [];
            this.uploader.onAfterAddingFile = (item) => {
                if (item.file) {
                    if (checkWorkspaceSize) {
                        this.Api.workspace.size.get({ hid: this.Auth.user.hospital._id }, (res) => {
                            let remaining = this.Access.hospital.maxDataUsage - res.size;
                            if (item.file.size > remaining) {
                                this.onItemNoSpaceAvailable(item);
                                item.remove();
                            }
                            if (item.file.size > this.sizeLimit) {
                                this.onItemFileSizeTooLarge(item);
                                item.remove();
                            }
                            else if (this.allowedExtensions && !~this.allowedExtensions.indexOf(this._extractExtension(item.file.name))) {
                                this.onItemFileBadExtension(item);
                                item.remove();
                            }
                            else {
                                this.onAfterAddingFile(item);
                            }
                        });
                    }
                    else {
                        this.onAfterAddingFile(item);
                    }
                }
            };
            this.uploader.onBeforeUploadItem = (item) => {
                this.onBeforeUploadItem(item, this.uploader.queue.indexOf(item));
            };
            this.uploader.onAfterAddingAll = () => {
                this.onAfterAddingAll();
            };
        };
        /**
         * Getter for allowed file extension
         * @returns {Array<String>}
         */
        this.getAllowedExtension = () => {
            return this.allowedExtensions;
        };
        /**
         * Upload all files in the uploader queue to the server
         * @param type The [[UploadFileType]] of the files
         * @returns A promise containing an array of [[LoanFile]]
         */
        this.upload = (type) => {
            const defer = this.$q.defer();
            const data = {
                files: [],
            };
            if (this.uploader.queue.length) {
                this._setOptions(defer, data);
                for (let i = 0; i < this.uploader.queue.length; ++i) {
                    const item = this.uploader.queue[i];
                    if (!item.isUploaded && !item.isUploading && !item.isCancel && !item.isError) {
                        item.onSuccess = (response) => {
                            if (!item.isCancel && !item.isError) {
                                const file = {};
                                file.name = item.file.name;
                                file.extension = response.extension;
                                file.hasThumbnail = response.hasThumbnail;
                                file.storageName = response.storageName;
                                file.size = item.file.size;
                                file.index = i;
                                data.files.push(file);
                                this.onItemUploadSuccess(item, file);
                            }
                            else {
                                this.onItemUploadError(item);
                            }
                        };
                        item.onError = () => {
                            this.onItemUploadError(item);
                        };
                        item.url = `/files/up/${type.toString()}/${encodeURI(item.file.name)}`;
                        item.upload();
                    }
                }
            }
            else {
                defer.reject(EMpStatus.NoDataProvided);
            }
            return defer.promise;
        };
        /**
         * Get queu length
         * @return {number}
         */
        this.length = () => {
            return this.uploader.queue.length;
        };
        /**
         * (re)initialize callbacks
         * @private
         */
        this._initializeCallbacks = () => {
            this.onAfterAddingFile = (item) => {
            };
            this.onAfterAddingAll = () => {
            };
            this.onBeforeUploadItem = (item, index) => {
            };
            this.onItemUploadSuccess = (item, f) => {
            };
            this.onItemUploadError = (item) => {
            };
            this.onItemFileSizeTooLarge = (item) => {
            };
            this.onItemNoSpaceAvailable = (item) => {
            };
            this.onItemFileBadExtension = (item) => {
            };
        };
        /**
         * Set the uploader options
         * @param defer The promise to resolve when all files are upload
         * @param data The data to pass to the resolved promise
         * @private
         */
        this._setOptions = (defer, data) => {
            this.uploader.onBeforeUploadItem = (item) => {
                item.headers = { 'X-XSRF-TOKEN': this.$cookies.get('XSRF-TOKEN') };
                this.onBeforeUploadItem(item, this.uploader.queue.indexOf(item));
            };
            this.uploader.onCompleteAll = () => {
                let allSucceed = true;
                for (const item of this.uploader.queue) {
                    if (item.isCancel || item.isError) {
                        allSucceed = false;
                        break;
                    }
                }
                if (allSucceed) {
                    defer.resolve(data.files);
                }
                if (this.autoClearQueue) {
                    this.uploader.clearQueue();
                }
            };
            this.uploader.onErrorItem = (fileItem) => {
                this.onItemUploadError(fileItem);
                defer.reject(EMpStatus.RejectByServer);
            };
            this.uploader.onCancelItem = (fileItem) => {
                defer.reject(EMpStatus.RejectByUser);
            };
        };
        /**
         * Get the file extension
         * @param filename
         * @returns {string}
         * @private
         */
        this._extractExtension = (filename) => {
            const lastDot = filename.lastIndexOf('.');
            if (lastDot > 0 && lastDot !== -1) {
                return filename.substr(lastDot + 1);
            }
            return null;
        };
        this.sizeLimit = 50 * 1024 * 1024 * 1024; //50Mo
        this.autoClearQueue = true;
    }
}
