var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input } from '@angular/core';
import { WidgetInjection } from '../widgets/dashboard/widgets';
import { fuseAnimations } from '@fuse/animations';
import { EProject, IReleaseNoteAndLines } from '@mapuilabs/mpl-interfaces';
import { StateService } from '@uirouter/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '@shared/components/release-note-widget/i18n/en';
import { locale as french } from '@shared/components/release-note-widget/i18n/fr';
import { ReleaseNotesService } from '@services/homepage/widgets/release-notes/release-notes.service';
import { take } from 'rxjs/operators';
import { SnackbarService } from '@services/snackbar/snackbar.service';
let ReleaseNoteWidgetComponent = class ReleaseNoteWidgetComponent {
    constructor(_widgetInjection, _stateService, _releaseNotesService, _snackbarService, _fuseTranslationLoaderService) {
        this._widgetInjection = _widgetInjection;
        this._stateService = _stateService;
        this._releaseNotesService = _releaseNotesService;
        this._snackbarService = _snackbarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.limit = 5;
        this.isLoading = false;
        this.contactLink = 'https://forms.mapui.fr/mapuilabs/form/Contact/formperma/NVfl4ZmolinDz_kudPArCztd3gBRdhsPnFYDO1XNFBk';
        this.eProject = EProject;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
    }
    ngOnInit() {
        if (!this.releaseNoteAndLines) {
            this.releaseNoteAndLines = this._widgetInjection.releaseNotesAndLines;
        }
    }
    /**
     * Shortcuts to get widget and lines.
     */
    get widget() {
        return this.releaseNoteAndLines.widget;
    }
    get lines() {
        return this.releaseNoteAndLines.lines.items;
    }
    get hasNextPage() {
        return this.releaseNoteAndLines.lines.hasNextPage;
    }
    get nextPage() {
        return this.releaseNoteAndLines.lines.nextPage;
    }
    loadMoreLines() {
        this.isLoading = true;
        this._releaseNotesService
            .getNextReleaseNoteLines(this.widget._id, {
            page: this.nextPage,
            limit: this.limit
        })
            .pipe(take(1))
            .subscribe((res) => {
            console.log(res);
            this.releaseNoteAndLines.lines.nextPage = res.nextPage;
            this.releaseNoteAndLines.lines.hasNextPage = res.hasNextPage;
            this.lines.push(...res.items);
            this.isLoading = false;
        }, (err) => {
            console.error(err);
            this.isLoading = false;
            this._snackbarService.openError(err);
        });
    }
    /**
     * Go to app page or external link in new tab.
     * @param button
     */
    navigateToLink(button) {
        console.log(button.state);
        if (button.state !== 'otherUrl') {
            console.log(button.state);
            this._stateService.go(button.state);
        }
        else if (button.url) {
            this.redirectTo(button.url);
        }
    }
    /**
     * Open link in new tab.
     * @param url
     */
    redirectTo(url) {
        window.open(url, '_blank');
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof IReleaseNoteAndLines !== "undefined" && IReleaseNoteAndLines) === "function" ? _f : Object)
], ReleaseNoteWidgetComponent.prototype, "releaseNoteAndLines", void 0);
ReleaseNoteWidgetComponent = __decorate([
    Component({
        selector: 'mpx-release-note-widget',
        template: require('./release-note-widget.component.html').default,
        styles: [require('./release-note-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof WidgetInjection !== "undefined" && WidgetInjection) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof ReleaseNotesService !== "undefined" && ReleaseNotesService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _e : Object])
], ReleaseNoteWidgetComponent);
export { ReleaseNoteWidgetComponent };
