var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Group } from '@mapuilabs/mpl-interfaces';
import { CGroupType } from '@shared/constTypes/group/group-type.const';
import { CGroupStructure } from '@shared/constTypes/group/group-structure.const';
let InformationTabComponent = class InformationTabComponent {
    constructor() {
        this.groupTypes = CGroupType;
        this.groupStructures = CGroupStructure;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], InformationTabComponent.prototype, "isUserAdmin", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof FormGroup !== "undefined" && FormGroup) === "function" ? _a : Object)
], InformationTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof Group !== "undefined" && Group) === "function" ? _b : Object)
], InformationTabComponent.prototype, "group", void 0);
InformationTabComponent = __decorate([
    Component({
        selector: 'mpx-information-tab',
        template: require('./information-tab.component.html').default,
        animations: fuseAnimations
    })
], InformationTabComponent);
export { InformationTabComponent };
