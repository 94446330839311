var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EEstablishmentType, Hospital } from '@mapuilabs/mpl-interfaces';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
import { HospitalService } from '@services/hospital/hospital.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { UrlService } from '@services/url/url.service';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { ActionsTabComponent } from './actions-tab/actions-tab.component';
import { HospistockTabComponent } from './hospistock-tab/hospistock-tab.component';
import { InformationTabComponent } from './information-tab/information-tab.component';
let AdminDetailLabosComponent = class AdminDetailLabosComponent {
    constructor(_adminHospitalService, _fuseTranslationLoaderService, _hospitalService, _snackBarService, _translationService, _urlService) {
        this._adminHospitalService = _adminHospitalService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._hospitalService = _hospitalService;
        this._snackBarService = _snackBarService;
        this._translationService = _translationService;
        this._urlService = _urlService;
        this.pageType = 'new';
        this.isMembersLoading = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        var _a, _b, _c;
        this.detailForm = new UntypedFormGroup({
            infoForm: new UntypedFormGroup({
                name: new FormControl((_a = this.laboratory) === null || _a === void 0 ? void 0 : _a.name, Validators.required),
                industrials: new FormControl(((_b = this.laboratory) === null || _b === void 0 ? void 0 : _b.industrials) || []),
                theriaqueNames: new FormControl(((_c = this.laboratory) === null || _c === void 0 ? void 0 : _c.theriaqueNames) || [])
            }),
            actionsForm: new UntypedFormGroup({}),
            hospistockForm: new UntypedFormGroup({})
        });
        if (this.laboratory) {
            this.pageType = 'edit';
        }
        else {
            this.laboratory = new Hospital({
                establishmentType: EEstablishmentType.Laboratory,
                trialEnd: new Date()
            });
        }
        const title = this.pageType === 'new'
            ? `${this._translationService.instant('LABOS.TITLE')} - ${this._translationService.instant('COMMONS.NEW')}`
            : `${this._translationService.instant('LABOS.TITLE')} - ${this.laboratory.name}`;
        this.pageHeaderConfig = {
            title: title,
            svgIcon: 'preparation'
        };
    }
    ngAfterViewInit() {
        this.tabGroup.selectedIndex = this._urlService.getParam('tab') || 0;
    }
    _updateLaboratory() {
        var _a, _b;
        this.actionsTabComponent.updateLaboratory(this.laboratory);
        this.laboratory.region = undefined;
        this.laboratory.industrials = (_a = this.detailForm.value.infoForm) === null || _a === void 0 ? void 0 : _a.industrials;
        this.laboratory.theriaqueNames = (_b = this.detailForm.value.infoForm) === null || _b === void 0 ? void 0 : _b.theriaqueNames;
        this.laboratory.name = this.detailForm.value.infoForm.name;
        this.hospistockTabComponent.updateLaboratoryHospistock(this.laboratory);
    }
    onAnimationDone() {
        this.animationDone = true;
    }
    onTabChange(event) {
        this._urlService.addParams({ tab: event.index }, true);
        this.animationDone = false;
    }
    isMemberTabActive() {
        var _a;
        if (this.pageType === 'edit' &&
            this.laboratory &&
            this.membersTab.isActive &&
            (this.laboratory.members === undefined || ((_a = this.laboratory.members) === null || _a === void 0 ? void 0 : _a.length) <= 0) &&
            !this.isMembersLoading) {
            this.isMembersLoading = true;
            this._hospitalService.getMembers(this.laboratory._id).subscribe((members) => {
                this.laboratory.members = members;
                if (!members || members.length <= 0) {
                    this.laboratory.members = null;
                }
                this.isMembersLoading = false;
            });
        }
    }
    saveLabo() {
        this._updateLaboratory();
        this._adminHospitalService.save(this.laboratory).subscribe((newLabo) => {
            this._snackBarService.open(this._translationService.instant('COMMONS.SAVED'));
            this.laboratory = newLabo;
            this.detailForm.markAsPristine();
        });
    }
    addLabo() {
        this._updateLaboratory();
        this._hospitalService.add(this.laboratory).subscribe((newLabo) => {
            this._snackBarService.open(this._translationService.instant('COMMONS.CREATED'));
            this.laboratory = newLabo;
            this.pageType = 'edit';
            this.detailForm.markAsPristine();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Hospital !== "undefined" && Hospital) === "function" ? _g : Object)
], AdminDetailLabosComponent.prototype, "laboratory", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], AdminDetailLabosComponent.prototype, "laboratoryGroups", void 0);
__decorate([
    ViewChild('tabGroup', { static: false }),
    __metadata("design:type", typeof (_h = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _h : Object)
], AdminDetailLabosComponent.prototype, "tabGroup", void 0);
__decorate([
    ViewChild(InformationTabComponent, { static: false }),
    __metadata("design:type", typeof (_j = typeof InformationTabComponent !== "undefined" && InformationTabComponent) === "function" ? _j : Object)
], AdminDetailLabosComponent.prototype, "informationTabComponent", void 0);
__decorate([
    ViewChild(ActionsTabComponent, { static: false }),
    __metadata("design:type", typeof (_k = typeof ActionsTabComponent !== "undefined" && ActionsTabComponent) === "function" ? _k : Object)
], AdminDetailLabosComponent.prototype, "actionsTabComponent", void 0);
__decorate([
    ViewChild(HospistockTabComponent, { static: false }),
    __metadata("design:type", typeof (_l = typeof HospistockTabComponent !== "undefined" && HospistockTabComponent) === "function" ? _l : Object)
], AdminDetailLabosComponent.prototype, "hospistockTabComponent", void 0);
__decorate([
    ViewChild('membersTab', { static: false }),
    __metadata("design:type", typeof (_m = typeof MatTab !== "undefined" && MatTab) === "function" ? _m : Object)
], AdminDetailLabosComponent.prototype, "membersTab", void 0);
AdminDetailLabosComponent = __decorate([
    Component({
        selector: 'mpx-detail-labos',
        template: require('./detail-labos.component.html').default,
        styles: [require('./detail-labos.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminHospitalService !== "undefined" && AdminHospitalService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof UrlService !== "undefined" && UrlService) === "function" ? _f : Object])
], AdminDetailLabosComponent);
export { AdminDetailLabosComponent };
