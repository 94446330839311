import * as angular from 'angular';
export class FileSelectorController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        /**
         *
         * @param {number} idx
         */
        this.removeFileAt = (idx) => {
            if (this.files[idx]) {
                this.files.splice(idx, 1);
                angular.element('input[type=\'file\']').val(null);
            }
        };
        this.confirmSelection = () => {
            if (this.onChange) {
                if (this.autoConfirm && this.files[0]) {
                    this.ModalService.show({
                        component: 'mp-modal-confirm',
                        bindings: {
                            data: {
                                title: 'MAPUI.SERVICES.INDICATORS.LABELS.IMPORT.CONFIRM_FILE.TITLE',
                                text: 'MAPUI.SERVICES.INDICATORS.LABELS.IMPORT.CONFIRM_FILE.TEXT',
                                values: this.files[0] ? { file: this.files[0].blob.name } : '',
                            },
                        },
                        escapeToClose: false,
                    }).then(() => {
                        this.onChange({ files: this.files });
                    });
                }
                else
                    this.onChange({ files: this.files });
            }
        };
        this.files = [];
    }
}
