import * as angular from 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-cookies';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-material';
import 'angular-translate';
import { msUtils } from './services/ms-utils.service';
import { fuseThemingService } from './theming/fuse-theming.service';
import { fuseThemes } from './theming/fuse-themes.constant';
import { fusePalettes } from './theming/fuse-palettes.constant';
import { fuseGeneratorService } from './theming/fuse-generator.service';
import { msThemeOptions, MsThemeOptionsController } from './theme-options/theme-options.directive';
import { fuseConfigProvider } from './config/fuse-config.provider';
import { MsFormWizardController, msFormWizardDirective, msFormWizardFormDirective } from './directives/ms-form-wizard/ms-form-wizard.directive';
import { msResponsiveTableDirective } from './directives/ms-responsive-table/ms-responsive-table.directive';
import { msScrollConfigProvider, msScrollDirective } from './directives/ms-scroll/ms-scroll.directive';
import { msSidenavHelperDirective } from './directives/ms-sidenav-helper/ms-sidenav-helper.directive';
import { msHorizontalStepperDirective, msHorizontalStepperStepDirective, MsStepperController, msVerticalStepperDirective, msVerticalStepperStepDirective } from './directives/ms-stepper/ms-stepper.directive';
import { msWidgetBackDirective, MsWidgetController, msWidgetDirective, msWidgetFrontDirective } from './directives/ms-widget/ms-widget.directive';
import { config as coreConfig } from './core.config';
import { runBlock } from './core.run';
import { config } from './theming/fuse-theming.config';
import { msApiProvider } from './services/ms-api.provider';
import '@uirouter/angularjs/release/stateEvents.js';
angular
    .module('app.core', [
    'ui.router',
    'ui.router.state.events',
    'ui.router.upgrade',
    'ngAnimate',
    'ngAria',
    'ngCookies',
    'ngMessages',
    'ngResource',
    'ngSanitize',
    'ngMaterial',
    'pascalprecht.translate'
])
    .config(config)
    .config(coreConfig)
    .run(runBlock)
    .constant('fuseThemes', fuseThemes)
    .constant('fusePalettes', fusePalettes)
    .factory('msUtils', msUtils)
    .factory('fuseGenerator', fuseGeneratorService)
    .service('fuseTheming', fuseThemingService)
    .controller('MsThemeOptionsController', MsThemeOptionsController)
    .directive('msThemeOptions', msThemeOptions)
    .provider('fuseConfig', fuseConfigProvider)
    .controller('MsFormWizardController', MsFormWizardController)
    .directive('msFormWizard', msFormWizardDirective)
    .directive('msFormWizardForm', msFormWizardFormDirective)
    .directive('msResponsiveTable', msResponsiveTableDirective)
    .provider('msScrollConfig', msScrollConfigProvider)
    .directive('msScroll', msScrollDirective)
    .directive('msSidenavHelper', msSidenavHelperDirective)
    .controller('MsStepperController', MsStepperController)
    .directive('msHorizontalStepper', msHorizontalStepperDirective)
    .directive('msHorizontalStepperStep', msHorizontalStepperStepDirective)
    .directive('msVerticalStepper', msVerticalStepperDirective)
    .directive('msVerticalStepperStep', msVerticalStepperStepDirective)
    .controller('MsWidgetController', MsWidgetController)
    .directive('msWidget', msWidgetDirective)
    .directive('msWidgetFront', msWidgetFrontDirective)
    .directive('msWidgetBack', msWidgetBackDirective)
    .provider('msApi', msApiProvider);
