import { EFilterType, EInterest } from '@mapuilabs/mpl-interfaces';
export const CAvailableRequestFilter = [
    {
        title: 'EMPTY',
        icons: ['clear'],
        value: {
            filters: [],
            replaceHistory: false
        }
    },
    {
        title: 'INTERESTED',
        icons: ['thumb_up_alt'],
        value: {
            filters: [
                {
                    attribute: 'interest',
                    type: EFilterType.VALUE,
                    value: EInterest.Interested
                }
            ],
            replaceHistory: true
        }
    },
    {
        title: 'NOT_INTERESTED',
        icons: ['thumb_down_alt'],
        value: {
            filters: [
                {
                    type: EFilterType.VALUE,
                    attribute: 'interest',
                    value: EInterest.NotInterested
                }
            ],
            replaceHistory: true
        }
    },
    {
        title: 'VALIDATED',
        icons: ['check_circle_outline'],
        value: {
            filters: [
                {
                    type: EFilterType.VALUE,
                    attribute: 'validated',
                    value: true
                }
            ],
            replaceHistory: true
        }
    },
    {
        title: 'WITHOUT_INTERESTED',
        icons: ['thumbs_up_down'],
        value: {
            filters: [
                {
                    type: EFilterType.VALUE,
                    attribute: 'withoutInterest',
                    value: true
                }
            ],
            replaceHistory: true
        }
    },
    {
        title: 'INTERESTED_AND_WITHOUT_INTERESTED',
        icons: ['thumb_up_alt', 'thumbs_up_down'],
        value: {
            filters: [
                {
                    type: EFilterType.VALUE,
                    attribute: 'interest',
                    value: EInterest.Interested
                },
                {
                    type: EFilterType.VALUE,
                    attribute: 'withoutInterest',
                    value: true
                }
            ],
            replaceHistory: true
        }
    }
];
