var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Hospital } from '@mapuilabs/mpl-interfaces';
let InformationTabComponent = class InformationTabComponent {
    ngOnInit() {
        this.setFormControls();
    }
    setFormControls() {
        var _a;
        this.form.setControl('name', new UntypedFormControl((_a = this.purchaseGroup) === null || _a === void 0 ? void 0 : _a.name, Validators.required));
    }
    updatePurchaseGroup(purchaseGroup) {
        purchaseGroup.name = this.form.controls.name.value;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof Hospital !== "undefined" && Hospital) === "function" ? _a : Object)
], InformationTabComponent.prototype, "purchaseGroup", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], InformationTabComponent.prototype, "form", void 0);
InformationTabComponent = __decorate([
    Component({
        selector: 'mpx-information-tab',
        template: require('./information-tab.component.html').default,
        styles: [require('./information-tab.component.scss')]
    })
], InformationTabComponent);
export { InformationTabComponent };
