import { EHospivillePermission, EMpStatus, EStatusIndicator, } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MedicationReconciliationService {
    /** @ngInject */
    constructor(Api, $q, $translate, Access) {
        this.Api = Api;
        this.$q = $q;
        this.$translate = $translate;
        this.Access = Access;
        /**
         * Get a specific [[IMedicationReconciliation]]
         * @param id The [[IMedicationReconciliation]] [[Id]]
         * @return A promise containing the [[IMedicationReconciliation]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.medicationReconciliations.id.get({
                    pid: this.Access.patient._id,
                    id: id,
                }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get all the [[IMedicationReconciliation]] for an [[IHospitalization]] given
         * @return A promise containing an array of [[IMedicationReconciliation]]
         */
        this.getAll = (dbsId) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.medicationReconciliations.id.all({ dbsId: dbsId }, {
                    pid: this.Access.patient._id,
                }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get all the [[IMedicationReconciliation]] for an [[IHospitalization]] given
         * @return A promise containing an array of [[IMedicationReconciliation]]
         */
        this.getAllPast = () => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.PreviousMedRec_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.medicationReconciliations.past.all({}, {
                    pid: this.Access.patient._id,
                }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an [[IMedicationReconciliation]]
         * @param medicationReconciliation The [[IMedicationReconciliation]] to update
         */
        this.save = (medicationReconciliation) => {
            const defer = this.$q.defer();
            if (!medicationReconciliation || !Utils.hasId(this.Access.patient)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.medicationReconciliations.id.save({
                    pid: this.Access.patient._id,
                }, medicationReconciliation, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get and set a medRec to inactive
         * @param medRec
         */
        this.end = (medRec) => {
            const defer = this.$q.defer();
            this.get(Utils.getId(medRec)).then((ans) => {
                ans.isActive = false;
                this.save(ans).then(defer.resolve).catch(defer.reject);
            }).catch(defer.reject);
            return defer.promise;
        };
        /**
         * Add an [[IMedicationReconciliation]] to the dataBase
         * @param medicationReconciliation The [[IMedicationReconciliation]] to create
         */
        this.add = (medicationReconciliation) => {
            const defer = this.$q.defer();
            if (!medicationReconciliation || !Utils.hasId(this.Access.patient)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                medicationReconciliation.statusIndicator = EStatusIndicator.Ongoing;
                this.Api.medicationReconciliations.id.add({
                    pid: this.Access.patient._id,
                }, medicationReconciliation, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Mark as deleted an [[IMedicationReconciliation]]
         * @param medicationReconciliation The [[IMedicationReconciliation]] to delete
         */
        this.delete = (medicationReconciliation) => {
            const defer = this.$q.defer();
            if (!medicationReconciliation || !Utils.hasId(this.Access.patient)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.medicationReconciliations.id.delete({
                    pid: this.Access.patient._id,
                }, medicationReconciliation, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Matches all medicine departments
         * @param query [[string]] representing the searched medicine department
         * @returns A promise containing the array of all medicine departments matching the query
         */
        this.searchDepartment = (query) => {
            const defer = this.$q.defer();
            defer.resolve(this._medicineDepartment.filter(this._createFilter(query)));
            return defer.promise;
        };
        /**
         * Search HospReason in Theriaque DB by label
         * @param query
         */
        this.searchHospReasonByName = (query) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit))
                defer.reject(EMpStatus.NoAccess);
            else {
                this.Api.hospReason.find({ label: query }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Search HospReason in Theriaque DB by code
         * @param code
         */
        this.searchHospReasonByCode = (code) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.hospReason.find({ code: code }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Filter that indicates if the medicine department name match the query
         * @param query
         * @private
         */
        this._createFilter = (query) => {
            return (medicineDepartment) => {
                const lowerName = this.$translate.instant(medicineDepartment.translate).toLowerCase();
                if (query) {
                    return lowerName.indexOf(query.toLowerCase()) === 0;
                }
                return true;
            };
        };
        this._medicationReconciliation = {};
        this.Api.hvConstType.get((ans) => {
            this._medicineDepartment = ans.medicineDepartment;
        });
    }
    /**
     * Use [[IMedicationReconciliation]] results to get an array of [[ISimpleInstance]] with dosage and posology.
     * @param id
     */
    convertToSimpleInstances(id) {
        const defer = this.$q.defer();
        if (!Utils.hasId(this.Access.patient)) {
            defer.reject(EMpStatus.NoDataProvided);
        }
        else if (!this.Access.authorize(EHospivillePermission.MedicationReconciliation_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.Api.medicationReconciliations.simpleInstances.get({
                pid: this.Access.patient._id,
                id: id,
            }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
        }
        return defer.promise;
    }
}
