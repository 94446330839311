export class Api {
    /** @ngInject */
    constructor($resource, 
    // private $socketResource: any,
    StaticResource) {
        this.$resource = $resource;
        this.StaticResource = StaticResource;
        this._generateStaticResourceForUrl = (url) => {
            return {
                get: (success = null, error = null) => {
                    return this.StaticResource.get(url)
                        .then((res) => (success ? success(res) : null))
                        .catch((err) => (error ? error(err) : null));
                }
            };
        };
        this.permissions = this.$resource('/api/permissions', {}, {
            all: { method: 'GET', isArray: true }
        });
        this.userJwt = this.$resource('/api/user/jwt', {}, {
            get: { method: 'GET' }
        });
        this.carePlan = this.$resource('/api/hospiville/care-plan/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            add: { method: 'POST' },
            save: { method: 'PUT' }
        });
        this.configuration = this.$resource('/api/hospiville/configuration/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            all: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' }
        });
        this.mails = {
            mails: this.$resource('/mails', {}, {
                send: { method: 'POST' }
            }),
            mssRequest: this.$resource('/mailMssRequest', {}, {
                send: { method: 'POST' }
            })
        };
        // region MaPUI
        this.contact = this.$resource('/contact-us', {}, {
            send: { method: 'POST' }
        });
        this.loans = {
            byId: this.$resource('/api/loans/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            byHospital: {
                loans: this.$resource('/api/hospitals/:hid/loans/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true },
                    add: { method: 'POST' },
                    save: { method: 'PUT' },
                    delete: { method: 'DELETE' }
                }),
                billings: this.$resource('/api/hospitals/:hid/billings/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true }
                }),
                billingsAwaiting: this.$resource('/api/hospitals/:hid/billings-awaiting/:id', {
                    id: '@_id',
                    hid: '@hid'
                }, {
                    get: { method: 'GET', isArray: true }
                }),
                settlements: this.$resource('/api/hospitals/:hid/settlements/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true }
                }),
                borrowings: this.$resource('/api/hospitals/:hid/borrowings/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true },
                    add: { method: 'POST' },
                    save: { method: 'PUT' },
                    delete: { method: 'DELETE' }
                }),
                archives: this.$resource('/api/hospitals/:hid/archives/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true }
                }),
                available: this.$resource('/api/hospitals/:hid/available/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true }
                }),
                requests: this.$resource('/api/hospitals/:hid/requests/:id', { id: '@_id', hid: '@hid' }, {
                    get: { method: 'GET', isArray: true }
                }),
                lastLoans: this.$resource('/api/hospitals/:hid/last-loans', { hid: '@hid' }, {
                    get: { method: 'GET', isArray: true }
                })
            },
            rollback: this.$resource('/api/hospitals/:hid/loans/:id/rollbacks', { id: '@_id', hid: '@hid' }, {
                add: { method: 'POST' },
                save: { method: 'PUT' }
            }),
            available: {
                byId: this.$resource('/api/loans/available', { nb: '@_nb' }, {
                    all: { method: 'GET', isArray: true }
                }),
                byGroup: this.$resource('/api/groups/:gid/available', { gid: '@gid' }, {
                    all: { method: 'GET', isArray: true }
                })
            },
            requests: this.$resource('/api/loans/requests', {}, {
                get: { method: 'GET', isArray: true }
            }),
            count: {
                loans: this.$resource('/api/loans/count/:hid', { hid: '@hid' }, {
                    get: { method: 'GET' }
                }),
                available: this.$resource('/api/loans/available/count/:hid', { pid: '@hid' }, {
                    get: { method: 'GET' }
                })
            }
        };
        this.member = {
            byId: this.$resource('/api/members/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            byHospital: this.$resource('/api/hospitals/:hid/members', { hid: '@hid' }, {
                all: { method: 'GET', isArray: true }
            }),
            byGroup: this.$resource('/api/groups/:gid/members', { gid: '@_gid' }, {
                all: { method: 'GET', isArray: true }
            }),
            list: this.$resource('/api/members/list', {}, {
                get: { method: 'GET', isArray: true }
            }),
            screenSize: this.$resource('/api/members/:id/screen-size', { id: '@_id' }, {
                send: { method: 'POST' }
            }),
            roles: this.$resource('/api/members/:id/roles', { id: '@_id' }, {
                all: { method: 'GET', isArray: true }
            })
        };
        this.notification = {
            default: this.$resource('/api/members/:mId/notifications/:id', { mId: '@mId', id: '@id' }, {
                all: { method: 'GET', isArray: true },
                markAsRead: { method: 'PUT' },
                markAllAsRead: { method: 'POST' },
                markAllAsSeen: { method: 'POST', params: { type: 'seen' } }
            }),
            count: this.$resource('/api/members/:mId/notifications/count', { mId: '@mId' }, {
                unread: { method: 'GET' }
            })
        };
        this.changePassword = this.$resource('/change-password/:id', { id: '@_id' }, {
            save: { method: 'PUT' }
        });
        this.unlockAccount = this.$resource('/unlock-user/:id', { id: '@_id' }, {
            save: { method: 'PUT' }
        });
        this.session = this.$resource('/api/session', {}, {
            all: { method: 'GET', isArray: true }
        });
        this.lastSession = this.$resource('/api/members/:id/sessions', { id: '@_id' }, {
            get: { method: 'GET', isArray: true }
        });
        this.regions = this.$resource('/api/external/regions/', {}, {
            get: { method: 'GET', isArray: true }
        });
        this.towns = this.$resource('api/external/town', {}, {
            find: { method: 'GET', isArray: true }
        });
        this.countries = this.$resource('api/external/country', {}, {
            find: { method: 'GET', isArray: true }
        });
        this.hospital = {
            find: {
                byName: this.$resource('/api/hospitals', { name: '@name', type: '@type' }, {
                    get: { method: 'GET', isArray: true }
                }),
                withSecureEmails: this.$resource('/api/hospitals/with-secure-emails/:id', { id: '@_id' }, {
                    get: { method: 'GET', isArray: true },
                    save: { method: 'PUT' }
                }),
                withApiAccounts: this.$resource('/api/hospitals/with-api-accounts/:id', { id: '@_id' }, {
                    get: { method: 'GET', isArray: true },
                    save: { method: 'PUT' }
                }),
                projectSubscription: this.$resource('/api/hospitals/project-subscription', { project: '@project', subscription: '@subscription', type: '@type' }, {
                    get: { method: 'GET', isArray: true }
                })
            },
            byId: this.$resource('/api/hospitals/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            light: this.$resource('/api/hospitalsLight/:id', { id: '@_id' }, {
                get: { method: 'GET', isArray: true }
            }),
            count: {
                available: this.$resource('/api/loans/available/count/:hid', { pid: '@hid' }, {
                    get: { method: 'GET' }
                }),
                patients: this.$resource('/api/hospiville/patients/count/:hid', { hid: '@hid' }, {
                    get: { method: 'GET' }
                }),
                stockouts: this.$resource('/api/stockouts/count/:hid', { hid: '@hid' }, {
                    get: { method: 'GET' }
                })
            },
            roles: this.$resource('/api/hospitals/roles', {}, {
                all: { method: 'GET', isArray: true }
            }),
            secureEmailAddress: this.$resource('/api/hospitals/secure-email-address/:hid', { hid: '@hid' }, {
                get: { method: 'GET' }
            })
        };
        this.voucher = this.$resource('/api/vouchers/:id', { id: '@_id' }, {
            all: { method: 'GET', isArray: true },
            get: { method: 'GET' },
            save: { method: 'PUT' },
            add: { method: 'POST' },
            delete: { method: 'DELETE' }
        });
        this.medic = {
            getLab: this.$resource('/api/external/laboratory', { code: '@code' }, {
                get: { method: 'GET' }
            }),
            byName: this.$resource('/api/external/drug/', { name: '@name' }, {
                get: { method: 'GET', isArray: true }
            })
        };
        this.medicalDevice = {
            byName: this.$resource('/api/external/cladimed/', { name: '@name' }, {
                get: { method: 'GET', isArray: true }
            })
        };
        this.preparation = {
            direct: this.$resource('/api/preparations/:id', { id: '@_id' }, {
                get: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            byHospital: this.$resource('/api/hospitals/:hid/preparations/:id', { hid: '@hid', id: '@_id' }, {
                get: { method: 'GET', isArray: true }
            })
        };
        this.dialogTeam = this.$resource('/api/dialogsTeam/:id', { id: '@_id' }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.dialog = this.$resource('/api/dialogs/:id', { id: '@_id' }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.files = this.$resource('/api/files/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            add: { method: 'POST' },
            delete: { method: 'DELETE' }
        });
        this.workspace = {
            files: this.$resource('/api/workspace/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            root: this.$resource('/api/workspace/root-files', {}, {
                get: { method: 'GET', isArray: true }
            }),
            size: this.$resource('/api/hospitals/:hid/total-files-size', { hid: '@hid' }, {
                get: { method: 'GET' }
            }),
            breadcrumbs: this.$resource('/api/workspace/:id/breadcrumbs', { id: '@_id' }, {
                get: { method: 'GET', isArray: true }
            }),
            rights: this.$resource('/api/workspace/directory-rights/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                save: { method: 'PUT' }
            })
        };
        this.labels = {
            preparations: this.$resource('/api/labels/preparations/:title', { title: '@_title' }, {
                all: { method: 'GET', isArray: true },
                get: { method: 'GET' },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            })
        };
        this.group = {
            type: this.$resource('/api/groupByType/:type', { type: '@type' }, {
                get: { method: 'GET', isArray: true }
            }),
            id: this.$resource('/api/groups/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            invitation: this.$resource('/api/groups/:gid/invitations/:iid', { gid: '@_gid', iid: '@_iid' }, {
                add: { method: 'POST' },
                accept: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            members: this.$resource('/api/groups/:gid/members/:id', { gid: '@_gid', id: '@id' }, {
                delete: { method: 'DELETE' }
            }),
            byMember: this.$resource('/api/members/:mId/groups', { mId: '@mId' }, {
                get: { method: 'GET', isArray: true }
            })
        };
        this.booklet = {
            id: this.$resource('/api/booklets/:id', { id: '@_id' }, {
                get: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            shared: this.$resource('/api/booklets/shared/:id', { id: '@_id' }, {
                get: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            })
        };
        this.invoice = this.$resource('/api/invoice/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            add: { method: 'POST' }
        });
        this.twoFa = this.$resource('/twoFa', {}, {
            get: { method: 'GET' },
            post: { method: 'POST' },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.prospects = this.$resource('/api/prospects/:id', { id: '@_id' }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.equivalences = this.$resource('/api/equivalences/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            all: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.equivalencesVoucher = this.$resource('/api/equivalences/vouchers/:id', { id: '@_id' }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.establishment = {
            byId: this.$resource('/api/external/establishment/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                find: { method: 'GET', isArray: true }
            }),
            withHospital: this.$resource('/api/external/establishment/with-hospital', {}, {
                find: { method: 'GET', isArray: true }
            })
        };
        this.healthProfessional = this.$resource('/api/external/health-professional/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            findMail: { method: 'GET' },
            find: { method: 'GET', isArray: true }
        });
        this.patients = this.$resource('/api/hospiville/patients/:id', { id: '@_id' }, {
            get: { method: 'GET' },
            all: { method: 'GET', isArray: true },
            find: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.transferPatient = this.$resource('/api/hospiville/patients/:pid/transfer', { pid: '@pid', id: '@_id' }, {
            post: { method: 'POST' }
        });
        this.patientLppi = this.$resource('/api/hospiville/patients/by-establishment-lppi', {}, {
            get: { method: 'GET' }
        });
        this.patientShare = this.$resource('/api/hospiville/patients/share', {}, {
            post: { method: 'POST' }
        });
        this.masterPatients = this.$resource('/api/hospiville/patients/master-patient', {}, {
            add: { method: 'POST' }
        });
        this.patientInfo = this.$resource('/api/hospiville/patient-infos/:id', { id: '@_id' }, {
            get: { method: 'GET' }
        });
        this.hfiles = this.$resource('/api/hospiville/patients/:pid/files/:id', { pid: '@pid', id: '@_id' }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.sources = this.$resource('/api/hospiville/sources/:id', { id: '@_id', type: '@type' }, {
            get: { method: 'GET' },
            getStatic: { method: 'GET' },
            all: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.drugBalances = this.$resource('/api/hospiville/patients/:pid/medication-reconciliations/:mid/drug-balances/:id', {
            pid: '@pid',
            mid: '@mid',
            id: '@_id'
        }, {
            get: { method: 'GET' },
            all: { method: 'GET', isArray: true },
            getByMid: { method: 'GET' },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.medicationReconciliations = {
            id: this.$resource('/api/hospiville/patients/:pid/medication-reconciliations/:id', {
                pid: '@pid',
                id: '@_id'
            }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                add: { method: 'POST' },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            past: this.$resource('/api/hospiville/patients/:pid/medication-reconciliations/past', {
                pid: '@pid'
            }, {
                all: { method: 'GET', isArray: true }
            }),
            simpleInstances: this.$resource('/api/hospiville/patients/:pid/medication-reconciliations/simple-instance/:id', {
                pid: '@pid',
                id: '@_id'
            }, {
                get: { method: 'GET', isArray: true }
            })
        };
        this.mrResults = this.$resource('/api/hospiville/patients/:pid/medication-reconciliations/:mid/mr-results/:id', {
            pid: '@pid',
            mid: '@mid',
            id: '@_id'
        }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.drugInstances = this.$resource('/api/hospiville/patients/:pid/medication-reconciliations/:mid/drug-instances/:id', {
            pid: '@pid',
            mid: '@mid',
            id: '@_id'
        }, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.statHospiville = {
            stats: {
                medRecs: this.$resource('/api/hospiville/stats/medication-reconciliations/', {}, {
                    get: { method: 'GET', isArray: true }
                })
            }
        };
        this.vault = this.$resource('/vault', {}, {
            check: { method: 'GET' },
            lock: { method: 'PUT', url: '/vault/lock' },
            unlock: { method: 'PUT', url: '/vault/unlock' }
        });
        this.history = this.$resource('/api/history/:id', {}, {
            get: { method: 'GET', isArray: true },
            add: { method: 'POST' }
        });
        this.admin = {
            hospital: {
                byId: this.$resource('/api/admin/hospitals/:id', { id: '@_id' }, {
                    get: { method: 'GET' },
                    all: { method: 'GET', isArray: true },
                    save: { method: 'PUT' },
                    delete: { method: 'DELETE' }
                }),
                byGroup: this.$resource('/api/admin/groups/:gid/hospitals/', { gid: '@_gid' }, {
                    all: { method: 'GET', isArray: true }
                })
            },
            member: {
                byId: this.$resource('/api/admin/members/:id', { id: '@_id' }, {
                    get: { method: 'GET' },
                    all: { method: 'GET', isArray: true },
                    save: { method: 'PUT' },
                    delete: { method: 'DELETE' }
                }),
                byHospital: this.$resource('/api/admin/hospitals/:hid/members/', { hid: '@_hid' }, {
                    all: { method: 'GET', isArray: true }
                }),
                changeHospital: this.$resource('/api/admin/members/:mid/change-hospital/:hid', {
                    mid: '@mid',
                    hid: '@hid'
                }, {
                    save: { method: 'PUT' }
                })
            },
            group: this.$resource('/api/admin/groups/:id', { id: '@_id' }, {
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true }
            }),
            groupLabo: this.$resource('api/admin/groups-labo/:id', { id: '@_id' }, {
                add: { method: 'POST' },
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            }),
            roles: this.$resource('api/admin/roles/:id', { id: '@_id', project: '@project' }, {
                add: { method: 'POST' },
                get: { method: 'GET' },
                all: { method: 'GET', isArray: true },
                save: { method: 'PUT' },
                delete: { method: 'DELETE' }
            })
        };
        this.externalActors = this.$resource('/api/external-actors/:id', { id: '@_id' }, {
            all: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' },
            get: { method: 'GET' }
        });
        this.externalConfigurations = this.$resource('/api/external-configurations/:id', { id: '@_id' }, {
            all: { method: 'GET', isArray: true },
            add: { method: 'POST' },
            get: { method: 'GET' },
            save: { method: 'PUT' },
            delete: { method: 'DELETE' }
        });
        this.groupsLabo = this.$resource('/api/groups-labo/:id', { id: '@_id' }, {
            add: { method: 'POST' },
            get: { method: 'GET' },
            all: { method: 'GET', isArray: true },
            delete: { method: 'DELETE' },
            save: { method: 'PUT' }
        });
        this.authorSpeciality = this.$resource('api/author-speciality/:id', { id: '@_id' }, {
            all: { method: 'GET', isArray: true }
        });
        this.hospReason = this.$resource('api/external/hosp-reason', {}, {
            find: { method: 'GET', isArray: true }
        });
        this.mssLetterBoxes = this.$resource('api/external/mss-letter-boxes', {}, {
            get: { method: 'GET', isArray: true }
        });
        this.activities = {
            source: {
                files: this.$resource('api/hospiville/activities/source/files', {}, { add: { method: 'POST' } })
            }
        };
        this.constType = this._generateStaticResourceForUrl('/app/data/ConstTypes.json');
        this.hvConstType = this._generateStaticResourceForUrl('/app/data/hospiville/ConstTypes.json');
        this.filesType = this._generateStaticResourceForUrl('/app/data/filestypes.json');
        this.filesTypeLabel = this._generateStaticResourceForUrl('/app/data/filestypeslabels.json');
        this.mapuiPermissions = this._generateStaticResourceForUrl('/app/data/permissions-mapui.json');
        this.hospivillePermissions = this._generateStaticResourceForUrl('/app/data/permissions-hospiville.json');
        this.workspacePermissions = this._generateStaticResourceForUrl('/app/data/permissions-workspace.json');
        this.adminPanelPermissions = this._generateStaticResourceForUrl('/app/data/permissions-admin-panel.json');
    }
}
