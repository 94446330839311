import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
export class MpModalCreateOtherEstablishmentController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        /**
         * Submit form values
         */
        this.submit = () => {
            this.ModalService.close(this.establishment);
        };
        /**
         * Cancel adding establishment
         */
        this.cancel = () => {
            this.ModalService.cancel(null);
        };
        this.establishment = {
            name: this.name,
            establishmentType: EEstablishmentType.Other,
            addrCountry: 'France',
        };
    }
}
