export const locale = {
    lang: 'fr',
    data: {
        LOAN_BORROW_WIDGET: {
            TITLE: `Prêts / Emprunts`,
            LOAN: 'Prêt',
            BORROW: 'Emprunt',
            VOUCHER: {
                LOAN: 'Bon de prêt',
                BORROW: `Bon d'emprunt`,
                RETURN: 'Bon de retour'
            },
            SEE: 'Voir',
            UNIT: 'unité',
            NO_LOAN: 'Pas de prêts / emprunts.',
            SEE_MORE: 'Voir plus de prêts/emprunts',
            FORBIDDEN_MESSAGE: "Vous n'avez pas accès à cette fonctionnalité.",
            CONTACT_US: 'Contactez-nous'
        }
    }
};
