var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { Location } from '@angular/common';
import { Directive, EventEmitter, Injector, ChangeDetectorRef } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { EFilterType } from '@mapuilabs/mpl-interfaces';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { UrlService } from '@services/url/url.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { startWith } from 'rxjs/operators';
let ListPage = class ListPage {
    constructor(_injector, _getPage) {
        this._injector = _injector;
        this._getPage = _getPage;
        //The total length of the query (independent of the "item by page")
        this.resultLength = 0;
        // Flag telling the UI to wait for the results of the query and so to show the table loader
        this.isLoadingResults = true;
        // Msg and icon to show when dataSource is empty
        // Can be re-defined in Component extending ListPage
        this.emptyTableMsg = 'COMMONS.EMPTY_TABLE';
        this.emptyTableIcon = 'package-variant';
        this.noData = false;
        // Inject services
        this._fuseSidebarService = _injector.get(FuseSidebarService);
        this._translationService = _injector.get(TranslationService);
        this._urlService = _injector.get(UrlService);
        this._location = _injector.get(Location);
        this._snackbarService = _injector.get(SnackbarService);
        this._changeDetectorRef = _injector.get(ChangeDetectorRef);
        this.dataSource = new BehaviorSubject([]);
        this._reload = new EventEmitter();
        // Detect navigation back and forth (buttons of the web browser)
        this._locationSub = this._location.subscribe((event) => {
            if (event.type === 'popstate') {
                this._updateTableFilters();
                this._reload.emit();
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get countFiltersApplied() {
        var _a;
        let count = 0;
        if ((_a = this.filtersValues) === null || _a === void 0 ? void 0 : _a.length) {
            this.filtersValues.forEach((filter) => {
                switch (filter.type) {
                    case EFilterType.VALUE:
                        count++;
                        break;
                    case EFilterType.VALUES:
                        count += filter.values.length;
                        break;
                    default:
                        console.error(`Filter type not supported. Please add EFilterType.${EFilterType[filter.type]} in this switch-case.`);
                }
            });
        }
        return count;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._resetPaginatorIndex();
        this._updateTableFilters();
    }
    ngAfterViewInit() {
        this._paginator.page.subscribe((paginator) => {
            this._updatePageOptions(paginator);
            this._urlService.addParams({
                pagination: this._pageOptions
            });
            this._reload.emit();
        }, (err) => console.error(err));
        // Rxjs startWith operator initiate subscription with empty object to simulates a reload event on initialisation
        this._reload.pipe(startWith({})).subscribe(() => __awaiter(this, void 0, void 0, function* () {
            this.isLoadingResults = true;
            try {
                const data = yield lastValueFrom(this._getPage(this._pageOptions, this.searchValue, this.sortValue, this.filtersValues, this.params));
                if (data) {
                    this.resultLength = data.total;
                    this.noData = this.resultLength < 1;
                    this.dataSource.next(data.items);
                }
                else {
                    console.error(data);
                    this._snackbarService.openCustomError(this._translationService.instant('COMMONS.ERRORS.DEFAULT'));
                    this.noData = true;
                }
                this.isLoadingResults = false;
                this._changeDetectorRef.detectChanges();
            }
            catch (error) {
                console.error(error);
                this._snackbarService.openError(error);
                this.isLoadingResults = false;
                this._changeDetectorRef.detectChanges();
                this.noData = true;
            }
        }));
    }
    ngOnDestroy() {
        this._locationSub.unsubscribe();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Sets paginator index to 0.
     * @private
     */
    _resetPaginatorIndex() {
        if (!this._paginator) {
            console.warn("_paginator == null, can't be reset.\n\t- Add in parent :\n\t\t@ViewChild(MatPaginator, { static: true })\n\t\tprotected _paginator: MatPaginator;\n\t- Try to call it after OnInit");
            return;
        }
        this._paginator.pageIndex = 0;
    }
    /**
     * Updates pagination according to the MatPaginator argument.
     * @param paginator: MatPaginator object containing page index (starting at 0)
     * and size info.
     * @private
     */
    _updatePageOptions(paginator) {
        if (!paginator) {
            console.warn("paginator == null, can't be update.\n\t- Add in parent :\n\t\t@ViewChild(MatPaginator, { static: true })\n\t\tprotected _paginator: MatPaginator;\n\t- Try to call it after OnInit");
            return;
        }
        this._pageOptions = {
            page: paginator.pageIndex + 1,
            limit: paginator.pageSize
        };
    }
    /**
     * Updates paginator according to the IPaginationOptions argument.
     * @param paginationOptions: IPaginationOptions object containing page index
     * (starting at 1) and size info.
     * @private
     */
    _updatePaginator(paginationOptions) {
        if (paginationOptions.page) {
            this._paginator.pageIndex = paginationOptions.page - 1;
        }
        if (paginationOptions.limit) {
            this._paginator.pageSize = paginationOptions.limit;
        }
    }
    /**
     * Puts the string the user is searching in the URL and reloads the table at page 1
     * to show the result of the query.
     */
    search() {
        this._resetPaginatorIndex();
        this._updatePageOptions(this._paginator);
        this._urlService.addParams({
            search: this.searchValue,
            pagination: this._pageOptions
        });
        this._reload.emit();
    }
    /**
     * Puts a sorting on the data table and reloads it (first page).
     * @param sort
     */
    sortChange(sort) {
        this.sortValue = sort;
        this._resetPaginatorIndex();
        this._updatePageOptions(this._paginator);
        this._urlService.addParams({
            sorting: this.sortValue
        });
        this._reload.emit();
    }
    /**
     * Gets all the filters in the URL and updates the corresponding variables.
     * @private
     */
    _updateTableFilters() {
        const urlPageOptions = this._urlService.getParam('pagination');
        if (urlPageOptions) {
            this._updatePaginator(urlPageOptions);
            this._updatePageOptions(this._paginator);
        }
        this.searchValue = this._urlService.getParam('search');
        this.sortValue = this._urlService.getParam('sorting');
        this.filtersValues = this._urlService.getParam('filters');
    }
    /**
     * When filters are applied in the sidebar, goes back to the first page of
     * the data table, updates all variables and URL. Then reloads the data table
     * to show the result of the query.
     * @param filtersApplied
     * @param sidebarName: Name of the sidebar in which filters were applied.
     */
    onFiltersApplied(filtersApplied, sidebarName) {
        if (sidebarName) {
            this.closeFiltersSidebar(sidebarName);
        }
        this._resetPaginatorIndex();
        this._updatePageOptions(this._paginator);
        this.filtersValues = filtersApplied.filters;
        if (filtersApplied.replaceHistory) {
            this._urlService.addParams({
                filters: this.filtersValues,
                pagination: this._pageOptions
            }, filtersApplied.replaceHistory);
        }
        // Not needed because replaceHistory of addParams() is false by default
        else {
            this._urlService.addParams({
                filters: this.filtersValues,
                pagination: this._pageOptions
            });
        }
        this._reload.emit();
    }
    /**
     * Finds the sidebar matching the given name and opens it if it's close.
     * @param sidebarName: String equals to the fuse-sidebar name attribute.
     */
    openFiltersSidebar(sidebarName) {
        this._fuseSidebarService.getSidebar(sidebarName).open();
    }
    /**
     * Finds the sidebar matching the given name and closes it if it's open.
     * @param sidebarName: String equals to the fuse-sidebar name attribute.
     */
    closeFiltersSidebar(sidebarName) {
        this._fuseSidebarService.getSidebar(sidebarName).close();
    }
};
ListPage = __decorate([
    Directive(),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, Function])
], ListPage);
export { ListPage };
