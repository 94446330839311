var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable, Injector } from '@angular/core';
import { EMaPUIPermission, Member } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '../../shared/templates/crud/crud.template';
import { IdentityService } from '../identity/identity.service';
let MemberService = class MemberService extends CrudTemplateService {
    constructor(__injector, _identityService) {
        super(__injector, Member, '/api/members', {
            create: EMaPUIPermission.User_Create,
            read: true,
            update: true,
            delete: EMaPUIPermission.User_Delete
        });
        this.__injector = __injector;
        this._identityService = _identityService;
    }
    isTwoFAuthUsed() {
        return this._http.post(`/twoFa`, { action: 'isTwofaUsed' });
    }
    generateTwoFAuthKey() {
        return this._http.post(`/twoFa`, { action: 'generateKey' });
    }
    checkTempTwoFAuthToken(token) {
        return this._http.post(`/twoFa`, { action: 'checkTempKey', token: token }, { responseType: 'text' });
    }
    removeTwoFAuth() {
        return this._http.post(`/twoFa`, { action: 'disable' }, { responseType: 'text' });
    }
    getCurrentHospitalMembers() {
        const endpoint = `${this._endPoint}/mine`;
        return this._http.get(endpoint);
    }
    toggleFoldedNavBar(isOpened) {
        this._identityService.user.config.sidenavOpened = isOpened;
        this._identityService.saveUser();
    }
};
MemberService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object])
], MemberService);
export { MemberService };
