var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Injectable } from '@angular/core';
import * as angular from 'angular';
let ThemeService = class ThemeService {
    updateOverlayTheme(theme) {
        if (theme) {
            const cdkOverlay = angular.element('.cdk-overlay-container');
            cdkOverlay.removeClass(function (index, className) {
                return (className.match(/\btheme-\S+/g) || []).join(' ');
            });
            cdkOverlay.addClass(theme);
        }
    }
};
ThemeService = __decorate([
    Injectable({
        providedIn: 'root'
    })
], ThemeService);
export { ThemeService };
