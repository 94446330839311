import { EHospivillePermission, EMaPUIPermission, ERoleSubscription, EWorkspacePermission } from "@mapuilabs/mpl-interfaces";
import { Utils } from "../Utils";
export class Access {
    constructor(Auth, $rootScope, $state, _) {
        this.Auth = Auth;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this._ = _;
        this.isHospital = this.Auth.isHospital;
        this.isPharmacy = this.Auth.isPharmacy;
        this.isCompany = this.Auth.isCompany;
        this.isLaboratory = this.Auth.isLaboratory;
        this.refreshCredentials = () => {
            if (this.Auth.user && this.Auth.user.hospital) {
                this.hospital = this.Auth.user.hospital;
            }
            else {
                this.hospital = null;
            }
            this.user = this.Auth.user;
            this._patient = this.Auth.patient;
        };
        /**
         * Return whether the user can create a loan instance
         * @returns {boolean}
         */
        this.canCreateInst = () => {
            return (this.Auth.authorize(EMaPUIPermission.MedInst_CreateLoan) ||
                this.Auth.authorize(EMaPUIPermission.MedInst_CreateBorrowing));
        };
        this.canCreateLoanMedInst = () => {
            return this.Auth.authorize(EMaPUIPermission.MedInst_CreateLoan);
        };
        this.canCreateBorrowingMedInst = () => {
            return this.Auth.authorize(EMaPUIPermission.MedInst_CreateBorrowing);
        };
        this.canCreateLoanPrepInst = () => {
            return this.Auth.authorize(EMaPUIPermission.PrepInst_CreateLoan);
        };
        this.canCreateBorrowingPrepInst = () => {
            return this.Auth.authorize(EMaPUIPermission.PrepInst_CreateBorrowing);
        };
        /**
         * Return whether the user can see a loan instance
         * @returns {boolean}
         */
        this.canSeeInst = () => {
            return this.Auth.authorize(EMaPUIPermission.MedInst_Read);
        };
        /**
         * Return whether the user can create available
         * @returns {boolean}
         */
        this.canDoMAD = () => {
            return this.Auth.authorize(EMaPUIPermission.Available_Create);
        };
        /**
         * Return whether the user can see available
         * @returns {boolean}
         */
        this.canSeeMAD = () => {
            return this.Auth.authorize(EMaPUIPermission.Available_Read);
        };
        /**
         * Return whether the user can book available
         * @returns {boolean}
         */
        this.canBookMAD = () => {
            return this.Auth.authorize(EMaPUIPermission.Available_Retake);
        };
        /**
         * Return whether the user can create preparations
         * @returns {boolean}
         */
        this.canDoPrep = () => {
            return (this.Auth.authorize(EMaPUIPermission.PrepInst_CreateLoan) ||
                this.Auth.authorize(EMaPUIPermission.PrepInst_CreateBorrowing));
        };
        /**
         * Return whether the user can see preparations loans
         * @returns {boolean}
         */
        this.canSeePrep = () => {
            return this.Auth.authorize(EMaPUIPermission.PrepInst_Read);
        };
        /**
         * Return whether the user can answer requests
         * @returns {boolean}
         */
        this.canAnswerRequest = () => {
            return this.Auth.authorize(EMaPUIPermission.Request_Retake);
        };
        /**
         * Return whether the user can create requests
         * @returns {boolean}
         */
        this.canDoRequest = () => {
            return this.Auth.authorize(EMaPUIPermission.Request_Create);
        };
        /**
         * Return whether the user can see requests
         * @returns {boolean}
         */
        this.canSeeRequest = () => {
            return this.Auth.authorize(EMaPUIPermission.Request_Read);
        };
        /**
         * Return whether the user can Access preparations module
         * @returns {boolean}
         */
        this.canAccessPrep = () => {
            return this.Auth.authorize(EMaPUIPermission.Preparations_Read);
        };
        /**
         * Return whether the user can access the workspace
         * @returns {boolean}
         */
        this.canAccessWorkSpace = () => {
            return this.Auth.authorize(EWorkspacePermission.Files_Read);
        };
        /**
         * Return whether the user is an admin
         * @returns {boolean}
         */
        this.isAdmin = (user = this.user) => {
            return this.isLogged() && !!user.isAdmin;
        };
        /**
         * Return whether the user belong to a hospital
         * @returns {boolean}
         */
        this.isLogged = () => {
            return Utils.hasId(this.user) && !!this.user.hospital;
        };
        /**
         * Return whether the user'establishment is free
         * @returns {boolean}
         */
        this.isFree = () => {
            return (this.isLogged() && !!this._.find(this.user.hospital.roles, (v) => v.subscription === ERoleSubscription.Free));
        };
        /**
         * Return whether the user's establishment is in trial mode
         */
        this.isTrial = () => {
            return (this.isLogged() &&
                !!this._.find(this.user.hospital.roles, (v) => v.subscription === ERoleSubscription.Trial));
        };
        /**
         * Return if the user's hospital is the same
         * @returns {boolean}
         */
        this.isMyHospital = (hospital) => {
            return Utils.compareIds(this.hospital, hospital);
        };
        /**
         * Return if the account is activated
         * @returns {boolean}
         */
        this.isNotActivated = () => {
            return this.isLogged() && !!this.user.activation;
        };
        /**
         * Return if user has access to interface
         * @returns {boolean}
         */
        this.isInterfaced = () => {
            var _a, _b;
            const hActor = [];
            const uActor = [];
            if (!((_a = this.hospital) === null || _a === void 0 ? void 0 : _a.externalConfigurations) || !((_b = this.user) === null || _b === void 0 ? void 0 : _b.externalIds))
                return false;
            for (let config of this.hospital.externalConfigurations)
                hActor.push(Utils.getId(config.actor));
            for (let exterIds of this.user.externalIds)
                uActor.push(Utils.getId(exterIds.actor));
            return !!this._.intersection(hActor, uActor);
        };
        this.isShadowDOMAvailable = () => {
            return !!document.head.attachShadow;
        };
        this.isHVMigrationState = () => {
            return this.Auth.authorize(EHospivillePermission.migrationPerm_Read);
        };
        /**
         * Return if the user has accepted the CGU
         * @returns {boolean}
         */
        this.CGUisAccepted = () => {
            return this.isLogged() && (!!this.user.acceptedCGU || this.isAdmin());
        };
        this.logout = this.Auth.logout;
        this.authorize = this.Auth.authorize;
        this.authorizeAny = this.Auth.authorizeAny;
        /**
         * Change selected patient
         * @param {Id} _id
         * @param {Id} medicationReconciliation
         */
        this.changePatient = (_id, medicationReconciliation) => {
            this.Auth.changePatient(_id, medicationReconciliation);
            this._patient = this.Auth.patient;
        };
        /**
         * Get custom home from establishment roles if exists
         */
        this.getCustomHome = () => {
            if (!this.hospital || !this.hospital.roles) {
                return null;
            }
            // concat hospital AND user roles to get home even from user roles
            const roles = this.user.roles.concat(this.hospital.roles);
            const states = this.$state.get();
            for (const role of roles) {
                if (role.home) {
                    const state = states.find((state) => state.name === role.home);
                    if (Array.isArray(state.data.access) === false) {
                        state.data.access = [state.data.access];
                    }
                    if (this.Auth.authorizeAny(state.data.access)) {
                        return role.home;
                    }
                }
            }
            return null;
        };
        this.refreshCredentials();
        this.$rootScope.$on('refreshAccess', this.refreshCredentials);
    }
    get patient() {
        return this._patient;
    }
}
Access.$inject = ['Auth', '$rootScope', '$state', '_'];
