var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType, ITimingPeriod } from '@mapuilabs/mpl-interfaces';
import { StateService } from '@uirouter/angularjs';
import * as moment from 'moment';
import { StatsMedicationReconciliationService } from '../../../../../services/stats/medication-reconciliation.service';
import { ListPage } from '../../../../../shared/templates/listPage/listPage.class';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
let StatsHvHospitalTableComponent = class StatsHvHospitalTableComponent extends ListPage {
    constructor(_injector, _fuseTranslationLoaderService, _stateService, _statsMedicationReconciliationService) {
        super(_injector, _statsMedicationReconciliationService.getPage);
        this._injector = _injector;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._stateService = _stateService;
        this._statsMedicationReconciliationService = _statsMedicationReconciliationService;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Table
        this.genericTableConfig = { clickableRows: true };
        const formatDate = (date) => (date ? moment(date).format('ll') : null);
        this.columns = [
            {
                label: this._translate('STATS_HOSPIVILLE.HOSPITALS_TABLE.HOSPITAL'),
                attribute: 'hospital',
                sort: true
            },
            {
                label: this._translate('STATS_HOSPIVILLE.HOSPITALS_TABLE.QUANTITY'),
                attribute: 'quantity',
                sort: true
            },
            {
                label: this._translate('STATS_HOSPIVILLE.HOSPITALS_TABLE.LAST_ACTIVITY'),
                attribute: 'last',
                transform: formatDate,
                sort: true
            }
        ];
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if (changes === null || changes === void 0 ? void 0 : changes.dateLimits.currentValue) {
            this._setDatesLimits(this.dateLimits);
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    _setDatesLimits(dates) {
        const applyFilters = [
            {
                type: EFilterType.RANGE,
                attribute: 'period',
                start: dates.start,
                end: dates.end
            }
        ];
        this.onFiltersApplied({ filters: applyFilters, replaceHistory: false }, null);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    goToDetail(id) {
        this._stateService.go('mapui.adm.stats.hospiville.detail', this.getDetailParams(id));
    }
    getDetailParams(id) {
        if (!this.filtersValues) {
            return { id: id };
        }
        const period = this.filtersValues.find((filter) => filter.attribute === 'period');
        if (!period) {
            return { id: id };
        }
        return { id: id, period: JSON.stringify(period) };
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof ITimingPeriod !== "undefined" && ITimingPeriod) === "function" ? _e : Object)
], StatsHvHospitalTableComponent.prototype, "dateLimits", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_f = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _f : Object)
], StatsHvHospitalTableComponent.prototype, "_paginator", void 0);
StatsHvHospitalTableComponent = __decorate([
    Component({
        selector: 'mpx-stats-hv-hospital-table',
        template: require('./stats-hv-hospital-table.component.html').default,
        styles: [require('./stats-hv-hospital-table.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof StatsMedicationReconciliationService !== "undefined" && StatsMedicationReconciliationService) === "function" ? _d : Object])
], StatsHvHospitalTableComponent);
export { StatsHvHospitalTableComponent };
