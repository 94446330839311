var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityDestService } from '@services/hospiville/activities/activities-dest.service';
import { CActivityStatuses } from '@shared/constTypes/activity/activity-status.const';
import { CActivityTypes } from '@shared/constTypes/activity/activity-type.const';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
let ListActivitiesDestViewComponent = class ListActivitiesDestViewComponent extends ListPage {
    constructor(_activityService, _fuseTranslationLoaderService, _injector) {
        super(_injector, _activityService.getPage);
        this._activityService = _activityService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._injector = _injector;
        this.activityStatuses = CActivityStatuses;
        this.CActivityTypes = CActivityTypes;
        // Columns to display in the data table
        this.displayedColumns = ['longDate', 'type', 'establishment.name', 'patient.name', 'status'];
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Header
        this.pageHeaderConfig = {
            title: this._translate('activities.TITLE'),
            searchBarPlaceholder: this._translate('activities.SEARCH_ACTIVITY'),
            icon: 'sync_alt'
        };
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    getActivityConstTranslate(constObj, value) {
        return this._translationService.getTranslation(constObj, value);
    }
    openUserGuideExchanges() {
        window.open('https://www.mapui.fr/guide_util_echanges_v_h_officine/').focus();
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_d = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _d : Object)
], ListActivitiesDestViewComponent.prototype, "_paginator", void 0);
ListActivitiesDestViewComponent = __decorate([
    Component({
        selector: 'hvx-list-activities-dest',
        template: require('./list-activities-dest-view.component.html').default,
        styles: [require('../list-activities.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ActivityDestService !== "undefined" && ActivityDestService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof Injector !== "undefined" && Injector) === "function" ? _c : Object])
], ListActivitiesDestViewComponent);
export { ListActivitiesDestViewComponent };
