var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsDashboardService } from '@app/hospistock/shared/services/hs-dashboard.service';
import { HsDownloadService } from '@app/hospistock/shared/services/hs-download.service';
import { ModalGenericTableComponent } from '@app/shared/components/modals/modal-generic-table/modal-generic-table.component';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
let HsDashboardWidgetGroupListComponent = class HsDashboardWidgetGroupListComponent {
    constructor(_fuseTranslationLoaderService, _dashboardService, _downloadService, _translate, dialog) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._dashboardService = _dashboardService;
        this._downloadService = _downloadService;
        this._translate = _translate;
        this.dialog = dialog;
        this.columns = [
            { label: this._translate.instant('COMMONS.WORDS.NAME'), attribute: 'name', classes: [] },
            { label: ' ', template: 'icon', classes: ['w-40', 'secondary-text'] },
            {
                label: this._translate.instant('DASHBOARD.WIDGET.GROUP_LIST.TABLE.CREATION_DATE'),
                attribute: 'creationDate',
                classes: ['w-120']
            },
            { label: this._translate.instant('COMMONS.WORDS.PHARMACY'), attribute: 'hospitalsTotal', classes: ['w-100'] }
        ];
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init
        this.noData = false;
        this.isLoading = false;
        // Widget config
        this.widgetContainerConfig = {
            title: this._translate.instant('DASHBOARD.WIDGET.GROUP_LIST.TITLE'),
            action: {
                icon: 'cloud_download'
            }
        };
        // Modal config
        this._modalGenericTableConfig = {
            title: this._translate.instant('DASHBOARD.WIDGET.GROUP_LIST.MODAL.TITLE'),
            columns: [{ label: this._translate.instant('COMMONS.WORDS.PHARMACY'), attribute: 'name' }]
        };
        // Simple table list config
        this.genericTableConfig = {
            clickableRows: true
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._getGroupList();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _getGroupList() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            try {
                this.groups = (yield lastValueFrom(this._dashboardService.getDashboardGroupList())).map((group) => Object.assign(group, { creationDate: moment(group.creationDate).format('MMM YYYY') }));
                const hospitalsTotal = [
                    ...new Set(this.groups.map((group) => group.hospitals.map((hospital) => hospital._id)).flat())
                ].length;
                this.groups.push({
                    _id: null,
                    hospitalsTotal,
                    name: 'Total PUI',
                    creationDate: null,
                    hospitals: []
                });
            }
            catch (err) {
                // Handle forbidden access
                if ((err === null || err === void 0 ? void 0 : err.status) === 403) {
                    this.forbiddenAccess = true;
                }
                // Assign empty array to display widget
                this.groups = [];
                this.noData = true;
                console.error(err);
            }
            this.isLoading = false;
        });
    }
    _openModal(row) {
        this.dialog.open(ModalGenericTableComponent, {
            data: { config: this._modalGenericTableConfig, dataSource: row.hospitals },
            minWidth: '60%'
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getSpreadsheetAndDownload() {
        this._downloadService.downloadGroupListAsSpreadsheet(this._dashboardService.viewType);
    }
    onRowClicked(row) {
        if (row._id) {
            this._openModal(row);
        }
    }
};
HsDashboardWidgetGroupListComponent = __decorate([
    Component({
        selector: 'mpx-hs-dashboard-widget-group-list',
        template: require('./hs-dashboard-widget-group-list.component.html').default,
        animations: fuseAnimations,
        providers: [HsDownloadService]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof HsDashboardService !== "undefined" && HsDashboardService) === "function" ? _b : Object, typeof (_c = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _c : Object, typeof (_d = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _d : Object, typeof (_e = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _e : Object])
], HsDashboardWidgetGroupListComponent);
export { HsDashboardWidgetGroupListComponent };
