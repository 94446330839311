import { RegisterPharmacistController } from './register-pharmacist.controller';
import * as angular from 'angular';
angular
    .module('mapui.services.auth.register-pharmacist', [])
    .config(config)
    .controller('RegisterPharmacistController', RegisterPharmacistController);
/** @ngInject */
function config($stateProvider) {
    $stateProvider.state('mapui.pages_register_pharmacist', {
        url: '/auth/register-pharmacist?firstName&lastName&pharmacy&jwt',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_register_pharmacist': {
                template: require('./register-pharmacist.html').default,
                controller: 'RegisterPharmacistController as vm',
            },
        },
        bodyClass: 'register-pharmacist',
    });
}
