export const locale = {
    lang: `fr`,
    data: {
        AVAILABLE: {
            TITLE: `Les offres disponibles`,
            TITLE_OWN: `Mes propositions`,
            SEARCH: `Rechercher une mise à disposition`,
            TOOLTIP_NEW: `Nouvelle mise à dispo.`,
            OPEN_FILTER: `Afficher les filtres`,
            EXPORT_EXCEL: `Exporter au format excel`,
            LIST: {
                NO_AVAILABLE: `Aucune mise à disposition`,
                BACK_TO_LIST: `Revenir à la liste`,
                ITEM: {
                    TITLES: {
                        QUANTITY: `Quantité : `,
                        ESTABLISHMENT: `Établissement : `,
                        EXPIRY_DATE: `Date de péremption : `,
                        END_DATE: {
                            LABEL: `Expire le : `,
                            TOOLTIP: `Annonce arrivée à expiration`
                        },
                        CREATION_DATE: `Publiée le : `,
                        PRICE: `Prix unitaire HT : `
                    },
                    TAGS: {
                        VALIDATED: `Reprise`
                    }
                }
            },
            INFO: {
                DRUG: 'Médicament',
                TITLE: 'Coordonnées',
                HOSPITAL: 'Hôpital',
                ADDRESS: 'Adresse',
                PHONE: 'Téléphone',
                PHARMACIST: 'Pharmacien(ne)',
                MAIL: 'Email',
                HOLDER: 'Laboratoire titulaire',
                CREATOR: "Laboratoire d'exploitation",
                ATC: 'ATC',
                DRUG_EXPIRATION_DATE: 'Date de péremption',
                QUANTITY: 'Quantité',
                UCD: 'UCD',
                DISTANCE: 'Distance',
                DCI: 'DCI',
                PRESERVATION: 'Conservation',
                TOTAL: 'Prix total HT (€)'
            },
            SORT: {
                TITLE: `Trier`,
                MENU: {
                    CREATION_DATE: 'Date de publication',
                    END_DATE: 'Date de fin',
                    EXPIRATION_DATE: 'Date de péremption',
                    QUANTITY: 'Quantité',
                    DISTANCE: 'Distance'
                }
            },
            TOOLTIPS: {
                OPEN_FILTER: 'Filtres',
                OPEN_SORT: 'Tri',
                REFRESH: 'Rafraîchir'
            },
            PRESET_FILTERS: {
                TITLE: `Filtres`,
                MENU: {
                    ADVANCED_FILTERS: 'Filtres avancés',
                    EMPTY: 'Aucun filtre',
                    INTERESTED: 'Intéressé',
                    NOT_INTERESTED: 'Pas intéressé',
                    VALIDATED: 'Reprise',
                    WITHOUT_INTERESTED: 'Pas évalué',
                    INTERESTED_AND_WITHOUT_INTERESTED: 'Intéressé et pas évalué'
                }
            },
            EDIT: {
                TITLE: {
                    NEW: `Nouvelle mise à disposition`,
                    EDIT: `Modification de mon offre`
                },
                MANDATORY_HINT: `Les champs marqués d'un astérisque (*) sont obligatoires.`,
                TOTAL_PRICE_ERROR: `Le prix total de votre offre doit être d'au minimum {{price}}€.`,
                NARCOTIC_ERROR: `La publication de médicaments stupéfiants n'est pas autorisée sur la plateforme.`,
                INPUTS: {
                    PRODUCT: `Produit`,
                    LABO: `Laboratoire exploitant`,
                    QUANTITY: `Quantité`,
                    PRICE: {
                        SHORT: `Prix de vente`,
                        LONG: `Prix de vente unit. HT (€)`
                    },
                    MANUFACTURER_PRICE: {
                        SHORT: `Prix d'achat`,
                        LONG: `Prix d'achat unit. HT (€)`
                    },
                    DISCOUNT: `Remise (%)`,
                    BATCH_NB: `Numéro de lot`,
                    PRESERVATION: `Conditions de conservation`,
                    EXPIRY_DATE: `Date de péremption du produit`,
                    DESCRIPTION: {
                        SHORT: `Commentaires`,
                        LONG: `Si nécessaire, apportez quelques précisions (état du conditionnement, numéro de téléphone...).`
                    },
                    MEMBER: {
                        LABEL: `Responsable de l'annonce`,
                        UNSELECTED_MEMBER: `Vous devez sélectionner un pharmacien dans la liste déroulante.`
                    },
                    END_DATE: `Date d'expiration de l'annonce`
                },
                AVAILABLE_ADDED: `La mise à disposition a été créée.`,
                AVAILABLE_UPDATED: `La mise à disposition a été modifiée.`,
                AVAILABLE_VALIDATED: `La mise à disposition a été validée.`
            },
            DETAILS: {
                NO_SELECTED_AVAILABLE: `Sélectionnez une offre pour afficher le détail.`,
                END_DATE_TOOLTIP: `Date d'expiration de l'annonce atteinte`,
                VALIDATED: `Reprise`,
                POST_INFO: {
                    TITLE: `Détails de l'annonce`,
                    QUANTITY: `Quantité`,
                    PRICE: `Prix unitaire HT (€)`,
                    TOTAL_PRICE: `Prix total HT (€)`,
                    EXPIRY_DATE: 'Date de péremption',
                    BATCH_NB: `Numéro de lot`,
                    REFEREE: `Responsable de l'annonce`,
                    CREATION_DATE: `Date de publication de l'annonce`,
                    END_DATE: `Date d'expiration de l'annonce`
                },
                DRUG_INFO: {
                    TITLE: `Informations sur le produit`,
                    ATC: 'ATC',
                    UCD: 'UCD',
                    HOLDER: 'Laboratoire titulaire',
                    CREATOR: "Laboratoire d'exploitation",
                    MANUFACTURER_PRICE: `Prix d'achat unit. HT (€)`,
                    PRESERVATION: 'Conservation'
                },
                OFFERS: {
                    TITLE: `Établissements intéressés`,
                    NONE: `Aucun établissement n'est intéressé pour le moment.`,
                    NO_COMMENT: `L'établissement n'a pas ajouté de commentaire à sa demande.`,
                    ACCEPT: `Valider la reprise`,
                    PREV: `Précédent`,
                    NEXT: `Suivant`,
                    DISTANCE: ` ({{distance}}km)`,
                    VALIDATED_TOOLTIP: `A repris la mise à dispo.`
                },
                VALIDATE_OFFER: {
                    TITLE: `Reprise de la mise à disposition`,
                    HINT: ``,
                    HOSPITAL_INFO: {
                        TITLE: `Coordonnées du repreneur`,
                        NAME: `Établissement`,
                        PHONE: `Téléphone de la pharmacie`,
                        EMAIL: `Email de la pharmacie`,
                        MEMBER: `Auteur de la demande`,
                        MEMBER_PHONE: `Téléphone`,
                        MEMBER_EMAIL: `Email`
                    },
                    COMMENT: `Commentaire de l'établissement`,
                    RECAP: {
                        TITLE: `Récapitulatif de la mise à disposition`,
                        PRODUCT: `Produit`,
                        QUANTITY: `Quantité`,
                        PRICE: `Prix unitaire HT (€)`,
                        EXPIRY_DATE: 'Date de péremption'
                    }
                },
                DELETE: {
                    TITLE: `Supprimer la mise à dispo.`,
                    MESSAGE: `Êtes-vous sûr(e) de vouloir supprimer la mise à disposition ?`,
                    DELETED: `La mise à dispo. a bien été supprimée.`
                },
                ARCHIVE: {
                    TITLE: `Archiver la mise à dispo.`,
                    MESSAGE: `Êtes-vous sûr(e) de vouloir archiver la mise à disposition ? Vous pourrez la retrouver en activant le filtre "Archivées".`
                }
            },
            SIDEBAR: {
                TITLE: `Filtres`,
                APPLY_BUTTON: `Appliquer`,
                RESET: `Réinitialiser`,
                TYPES_ERROR: `Sélectionnez au moins un type.`,
                GROUP: `Groupe`,
                IN_MY_REGION: `Dans ma région`,
                IN: `En`,
                TYPES: `Types`,
                FAVORITE: 'Favoris',
                LOCALIZATION: 'Localisation',
                INTEREST: 'Intéressé',
                NOT_INTEREST: 'Pas intéressé',
                NOT_RATED: `Pas évalué`,
                TOOK: 'Reprise',
                DISTRICT: 'Région',
                DISTANCE: 'Distance (km)',
                STATUS: {
                    TITLE: `État des annonces`,
                    OPEN: `Ouvertes`,
                    CLOSED: `Reprises`,
                    ARCHIVED: `Archivées`
                },
                END_DATE: {
                    TITLE: `Expiration des annonces`,
                    SOON_EXPIRED: `Bientôt expirées`,
                    EXPIRED: `Expirées uniquement`
                }
            }
        }
    }
};
