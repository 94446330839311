export class MpTimeController {
    /**
     * Use for min-date in mp-modal-event datepicker
     * @type {Date}
     */
    /** @ngInject */
    constructor($translate) {
        this.$translate = $translate;
        /**
         * Get the current Language
         * @returns {string}
         */
        this.getCurrentLang = () => {
            if (this.$translate.use() === 'fr') {
                return 'fr';
            }
            return 'en-gb';
        };
    }
    $onInit() {
        this.model = (this.model) ? new Date(this.model) : null;
    }
}
