var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LocationService } from '../../../services/location/location.service';
let RegionSelectComponent = class RegionSelectComponent {
    constructor(_locationService) {
        this._locationService = _locationService;
        _locationService.getAllRegions().subscribe((data) => {
            this.regions = data;
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], RegionSelectComponent.prototype, "parentForm", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], RegionSelectComponent.prototype, "formName", void 0);
RegionSelectComponent = __decorate([
    Component({
        selector: 'region-select',
        template: require('./region-select.component.html').default,
        styles: [require('./region-select.component.scss')],
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof LocationService !== "undefined" && LocationService) === "function" ? _a : Object])
], RegionSelectComponent);
export { RegionSelectComponent };
