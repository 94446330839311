var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { ListRolesComponent } from './list-roles/list-roles.component';
import { RolesRoutingModule } from './roles-routing.module';
import { RoleDetailComponent } from './list-roles/role-detail/role-detail.component';
import { EditRoleComponent } from './list-roles/edit-role/edit-role.component';
import { SharedModule } from '../../shared/shared.module';
let RolesModule = class RolesModule {
};
RolesModule = __decorate([
    NgModule({
        declarations: [ListRolesComponent, RoleDetailComponent, EditRoleComponent],
        imports: [
            CommonModule,
            UIRouterModule,
            BrowserAnimationsModule,
            FuseDirectivesModule,
            FuseSharedModule,
            FuseSidebarModule,
            SharedModule,
            MatIconModule,
            MatTableModule,
            MatTooltipModule,
            MatSortModule,
            MatButtonModule,
            MatCheckboxModule,
            MatDialogModule,
            MatFormFieldModule,
            MatSelectModule,
            MatInputModule,
            MatMenuModule,
            MatProgressSpinnerModule
        ],
        exports: [RolesRoutingModule]
    })
], RolesModule);
export { RolesModule };
