export class HvSearchDrugComponent {
    constructor() {
        this.controller = 'HvSearchDrugController';
        this.controllerAs = 'vm';
        this.template = require('./hv-search-drug.html').default;
        this.bindings = {
            label: '<',
            form: '<',
            drug: '<',
            name: '@',
            onSelected: '&',
            onTextChange: '&',
        };
    }
}
