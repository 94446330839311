export const locale = {
    lang: 'fr',
    data: {
        STATS_HOSPIVILLE: {
            HEADER: {
                TITLE: 'Statistiques Hospiville - Conciliations',
                START_DATE: 'Date de début',
                END_DATE: 'Date de fin'
            },
            HOSPITALS_TABLE: {
                HOSPITAL: 'Hôpital',
                QUANTITY: 'Quantité',
                LAST_MED_REC: 'Dernière'
            },
            WIDGET_TOTAL_MEDREC: {
                TITLE: 'Total de conciliations'
            },
            WIDGET_PERIOD_MEDREC: {
                TITLE: 'Conciliations sur la période'
            },
            GRAPH_CREATION: {
                TITLE: 'Évolution des créations de conciliations',
                CURVES: {
                    TOTAL: 'Total'
                },
                Y_AXIS: 'Nombre de conciliations'
            },
            GRAPH_TIME: {
                TITLE: 'Durée moyenne des conciliations (en moyenne : {{ average }} minutes)',
                CURVES: {
                    MED_REC: 'Conciliations',
                    DRUG_BAL: 'Bilans médicamenteux'
                },
                Y_AXIS: 'Durée des conciliations (en minutes)'
            }
        }
    }
};
