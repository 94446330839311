var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component } from '@angular/core';
import { LoanService } from '@app/services/loan/loan.service';
import { StatusService } from '@app/services/status/status.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EVoucherType } from '@mapuilabs/mpl-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angularjs';
import { MatDialog } from '@angular/material/dialog';
import { MemberService } from '@app/services/member/member.service';
import { Utils } from '@main/services/Utils';
import { locale as english } from '../../../archives/i18n/en';
import { locale as french } from '../../../archives/i18n/fr';
import { GenerateBillingComponent } from './billing-generate/billing-generate.component';
let BillingDetailComponent = class BillingDetailComponent {
    constructor(sts, _loanService, _stateService, _translateService, _memberService, _matDialog, _fuseTranslationLoaderService) {
        this.sts = sts;
        this._loanService = _loanService;
        this._stateService = _stateService;
        this._translateService = _translateService;
        this._memberService = _memberService;
        this._matDialog = _matDialog;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        const id = this._stateService.params['id'];
        this._loanService.getById(id).subscribe((loan) => {
            this.loan = loan;
            if (this.sts.amIL(this.loan) && this.loan.borrower) {
                this.othHospital = this.loan.borrower.name;
            }
            else if (this.loan.lender) {
                this.othHospital = this.loan.lender.name;
            }
            this._getLoanName();
            this._getBorrowingName();
            this._getValidName();
            this.pageHeaderConfig = {
                title: loan.medicName,
                icon: 'group'
            };
        });
    }
    hasFullName() {
        return !!this.loan.updateUID.fullName;
    }
    /**
     * Open voucher modal to create new
     * @param type
     */
    openVoucherModal(type) {
        let voucherType;
        if (type == 'loan') {
            voucherType = EVoucherType.Loan;
        }
        else if (type === 'borrow') {
            voucherType = EVoucherType.Borrow;
        }
        this._matDialog.open(GenerateBillingComponent, {
            width: '60%',
            data: {
                loan: this.loan,
                voucherType
            }
        });
    }
    showMember(member) {
        this._loanService.archiveMember = member;
        this._stateService.go('mapui.exchangeV2.billing.member');
    }
    _getLoanName() {
        if (Utils.hasId(this.loan.loanPerson)) {
            this._memberService.getById(Utils.getId(this.loan.loanPerson)).subscribe((member) => {
                this.loan.loanPerson = member;
            });
        }
    }
    _getBorrowingName() {
        if (Utils.hasId(this.loan.borrowingPerson)) {
            this._memberService.getById(Utils.getId(this.loan.borrowingPerson)).subscribe((member) => {
                this.loan.borrowingPerson = member;
            });
        }
    }
    _getValidName() {
        if (Utils.hasId(this.loan.validPerson)) {
            this._memberService.getById(Utils.getId(this.loan.validPerson)).subscribe((member) => {
                this.loan.validPerson = member;
            });
        }
    }
    /**
     * Get the current selected language
     * @returns {string} the current language
     */
    _getLang() {
        return this._translateService.getDefaultLang();
    }
};
BillingDetailComponent = __decorate([
    Component({
        selector: 'mpx-billing-detail',
        template: require('./billing-detail.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StatusService !== "undefined" && StatusService) === "function" ? _a : Object, typeof (_b = typeof LoanService !== "undefined" && LoanService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _d : Object, typeof (_e = typeof MemberService !== "undefined" && MemberService) === "function" ? _e : Object, typeof (_f = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _f : Object, typeof (_g = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _g : Object])
], BillingDetailComponent);
export { BillingDetailComponent };
