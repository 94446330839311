import * as angular from 'angular';
import { RoleService } from './role.service';
import { MpModalRoleController } from './mp-modal-role/mp-modal-role.controller';
import { MpModalRoleComponent } from './mp-modal-role/mp-modal-role.component';
angular
    .module('mapui.services.role', [
    'mapui.services.modal',
    'mapui.services.toast',
])
    .service('RoleService', RoleService)
    .controller('MpModalRoleController', MpModalRoleController)
    .component('mpModalRole', new MpModalRoleComponent());
