var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHospistockPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { HsStatisticComponent } from '@app/hospistock/shared/components/statistic/hs-statistic.component';
import { hsLaboMedicalDeviceStatisticConfig, hsLaboMedicalDeviceStatisticServiceConfig } from '@app/hospistock/labo/medical-device/statistic/hs-labo-medical-device-statistic.config';
import { HsPublicationSingleViewDetailComponent } from '@app/hospistock/shared/components/publication/single-view/single-view-detail/hs-publication-single-view-detail.component';
import { EHsToolbarViewModes } from '@app/hospistock/shared/types/hs-toolbar.types';
import { hsLaboMedicalDeviceServiceConfig } from '../hs-labo-medical-device.config';
import { hsLaboMedicalDevicePublicationConfig, hsLaboMedicalDevicePublicationServiceConfig } from '../publication/hs-labo-medical-device-publication.config';
import { PermissionService } from '@app/services/permission/permission.service';
const states = [
    {
        name: 'mapui.hospistock.labo.medical-device.statistic',
        url: '/statistic/pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: HsStatisticComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-indigo',
            access: [EHospistockPermission.LaboMedicalDeviceStatistic_SeeMenuItem],
            servicesConfig: hsLaboMedicalDeviceStatisticServiceConfig
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsLaboMedicalDeviceStatisticConfig
            }
        ]
    },
    {
        name: 'mapui.hospistock.labo.medical-device.statistic.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: HsPublicationSingleViewDetailComponent
            }
        },
        params: {
            id: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-indigo',
            access: EHospistockPermission.LaboMedicalDeviceStatistic_SeeMenuItem,
            viewMode: EHsToolbarViewModes.SINGLE_DETAIL,
            servicesConfig: {
                healthProduct: hsLaboMedicalDeviceServiceConfig,
                publicationContainer: hsLaboMedicalDevicePublicationServiceConfig
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService, PermissionService],
                resolveFn: hsLaboMedicalDevicePublicationConfig
            }
        ]
    }
];
let HsLaboMedicalDeviceStatisticRoutingModule = class HsLaboMedicalDeviceStatisticRoutingModule {
};
HsLaboMedicalDeviceStatisticRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], HsLaboMedicalDeviceStatisticRoutingModule);
export { HsLaboMedicalDeviceStatisticRoutingModule };
