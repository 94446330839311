import * as angular from 'angular';
import { MpFirstConnectionController } from './mp-first-connection.controller';
import { MpFirstConnectionComponent } from './mp-first-connection.component';
angular
    .module('mapui.first-connection', [
    'mapui.services.auth',
    'mapui.services.staff',
    'mapui.services.member',
    'mapui.services.hospital',
])
    .config(configFirstConnection)
    .controller('MpFirstConnectionController', MpFirstConnectionController)
    .component('mpFirstConnection', new MpFirstConnectionComponent())
    .run(runFirstConnection);
/** @ngInject */
function configFirstConnection($stateProvider) {
    $stateProvider.state('mapui.first-connection', {
        url: '/first-connect',
        data: {},
        views: {
            'main@': {
                template: require('../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.first-connection': {
                template: '<mp-first-connection></mp-first-connection>',
            },
        },
        resolve: {},
        bodyClass: 'first-connect',
    });
}
function runFirstConnection($templateCache) {
    $templateCache.put('app/main/general/mp-first-connection/steps/cgu.html', require('./steps/cgu.html').default);
    $templateCache.put('app/main/general/mp-first-connection/steps/user-information.html', require('./steps/user-information.html').default);
    $templateCache.put('app/main/general/mp-first-connection/steps/pwd.html', require('./steps/pwd.html').default);
    $templateCache.put('app/main/general/mp-loading/mp-loading.html', require('../mp-loading/mp-loading.html').default);
}
