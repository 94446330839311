var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { HospitalInformationComponent } from './hospital-information/hospital-information.component';
import { HospitalTeamComponent } from './hospital-team/hospital-team.component';
const states = [
    {
        name: 'mapui.setting.hospital-account.team',
        url: '/team',
        data: {
            access: EMaPUIPermission.Establishment_SeeMenuItem,
            theme: 'theme-blue'
        },
        views: {
            'content@mapui': {
                component: HospitalTeamComponent
            }
        }
    },
    {
        name: 'mapui.setting.hospital-account.information',
        url: '/information',
        data: {
            access: EMaPUIPermission.EstablishmentTeam_Read,
            theme: 'theme-blue'
        },
        views: {
            'content@mapui': {
                component: HospitalInformationComponent
            }
        }
    }
];
let HospitalAccountRoutingModule = class HospitalAccountRoutingModule {
};
HospitalAccountRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], HospitalAccountRoutingModule);
export { HospitalAccountRoutingModule };
