export const CLoanStatusIndicators = [
    {
        name: `ok`,
        translate: `SERVICES.STATUS.OK`,
        cssClass: `md-green-500-bg`
    },
    {
        name: `waitingAvailable`,
        translate: `SERVICES.STATUS.WAITING.AVAILABLE`,
        cssClass: `md-indigo-500-bg`
    },
    {
        name: `toAccept`,
        translate: `SERVICES.STATUS.TO.ACCEPT`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingAccept`,
        translate: `SERVICES.STATUS.WAITING.ACCEPT`,
        cssClass: `md-orange-500-bg`
    },
    {
        name: `toConfirm`,
        translate: `SERVICES.STATUS.TO.CONFIRM`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingConfirm`,
        translate: `SERVICES.STATUS.WAITING.CONFIRM`,
        cssClass: `md-yellow-600-bg`
    },
    {
        name: `waitingPublished`,
        translate: `SERVICES.STATUS.WAITING.PUBLISHED`,
        cssClass: `md-yellow-600-bg`
    },
    {
        name: `toLiberate`,
        translate: `SERVICES.STATUS.TO.LIBERATE`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingLiberate`,
        translate: `SERVICES.STATUS.WAITING.LIBERATE`,
        cssClass: `md-yellow-600-bg`
    },
    {
        name: `toReceive`,
        translate: `SERVICES.STATUS.TO.RECEIVE`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingReceive`,
        translate: `SERVICES.STATUS.WAITING.RECEIVE`,
        cssClass: `md-teal-500-bg`
    },
    {
        name: `toReturn`,
        translate: `SERVICES.STATUS.TO.RETURN`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingReturn`,
        translate: `SERVICES.STATUS.WAITING.RETURN`,
        cssClass: `md-cyan-500-bg`
    },
    {
        name: `toValidReturn`,
        translate: `SERVICES.STATUS.TO.VALID_RETURN`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingValidReturn`,
        translate: `SERVICES.STATUS.WAITING.VALID_RETURN`,
        cssClass: `md-cyan-700-bg`
    },
    {
        name: `prepManufacturing`,
        translate: `SERVICES.STATUS.WAITING.MANUFACTURING`,
        cssClass: `md-yellow-600-bg`
    },
    {
        name: `prepQuarantineSterility`,
        translate: `SERVICES.STATUS.WAITING.QUARANTINE`,
        cssClass: `md-yellow-600-bg`
    },
    {
        name: `toBill`,
        translate: `SERVICES.STATUS.TO.BILL`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingBill`,
        translate: `SERVICES.STATUS.WAITING.BILL`,
        cssClass: `md-purple-500-bg`
    },
    {
        name: `toSettle`,
        translate: `SERVICES.STATUS.TO.SETTLE`,
        cssClass: `md-red-500-bg`
    },
    {
        name: `waitingSettle`,
        translate: `SERVICES.STATUS.WAITING.SETTLE`,
        cssClass: `md-purple-700-bg`
    }
];
