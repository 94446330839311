var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { Auth as AjsAuth } from '../../../main/services/auth/auth.service';
let PermissionService = class PermissionService {
    constructor(_ajsAuth) {
        this._ajsAuth = _ajsAuth;
        /**
         * Tells if the current user has access to one of the given permissions
         * @param permissions
         */
        this.authorizeAny = (permissions) => {
            return this._ajsAuth.authorizeAny(permissions);
        };
        /**
         * Tells if the hospital provided has access of the given permissions
         * @param hospital
         * @param project
         * @param permissionsArray
         */
        this.hospitalHasPermissions = (hospital, permissionsArray) => {
            const roles = hospital.roles;
            const permissions = permissionsArray.map((permission) => permission.split('|')[1]);
            const rolesHospital = roles.flatMap((role) => {
                return role.permissions;
            });
            return permissions.every((permission) => rolesHospital.includes(permission));
        };
    }
    /**
     * Tells if the current user has access to the given permission
     * @param permission
     */
    authorize(permission) {
        if (!(permission instanceof Array)) {
            return this._ajsAuth.authorize(permission);
        }
        else {
            return this.authorizeAny(permission);
        }
    }
};
PermissionService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AjsAuth !== "undefined" && AjsAuth) === "function" ? _a : Object])
], PermissionService);
export { PermissionService };
