export class HvModalSelectDropComponent {
    constructor() {
        this.controller = 'HvModalSelectDropController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-select-drop.html').default;
        this.bindings = {
            dropInfo: '<',
        };
    }
    ;
}
