var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component } from '@angular/core';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { FileDragNDropReadType } from '@shared/components/file-drag-n-drop/file-drag-n-drop.component';
let ExFileDragNDropComponent = class ExFileDragNDropComponent {
    constructor(_snackbarService) {
        this._snackbarService = _snackbarService;
        this.pageHeaderConfig = {
            title: 'File Drag N Drop Component',
            icon: 'extension'
        };
        this.titles = {
            toolbar: 'Composant File Drag N Drop utilisation',
            subtitles: {
                documentation: 'Documentation',
                examples: 'Examples'
            }
        };
        this.multipleFilesAllowed = true;
        this.acceptedExtensions = '.pdf, .ods, .csv';
        this.readType = FileDragNDropReadType.ArrayBuffer;
        this.fileDragNDropReadType = FileDragNDropReadType;
        this.documentation = {
            inputs: {
                title: 'Inputs',
                items: [
                    {
                        name: 'multiple: boolean',
                        description: 'Allow one or multiple file import.'
                    },
                    {
                        name: 'accept: string',
                        description: 'Specify accepted file extensions. All extensions accepted by default.'
                    },
                    {
                        name: 'readType: FileDragNDropReadType',
                        description: 'Specify the format of the resulting file reading process.'
                    }
                ]
            },
            outputs: {
                title: 'Outputs',
                items: [
                    {
                        name: 'onLoadedFilesChanged: { file: File; content?: ArrayBuffer | string; progress?: number; }',
                        description: 'Emit event when the files list has changed.'
                    }
                ]
            }
        };
    }
    /**
     * Handler for dragNDropComponent onLoadedFilesChanged event output
     * @param files Files after change
     */
    updateLoadedFiles(files) {
        if (files.size > 0) {
            this._snackbarService.open('Fichiers lus affichés dans la console.');
        }
    }
};
ExFileDragNDropComponent = __decorate([
    Component({
        selector: 'mpx-ex-file-drag-n-drop',
        template: require('./ex-file-drag-n-drop.component.html').default,
        styles: [require('./ex-file-drag-n-drop.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _a : Object])
], ExFileDragNDropComponent);
export { ExFileDragNDropComponent };
