export class FileSelectorComponent {
    constructor() {
        this.template = require('./file-selector.html').default;
        this.controller = 'FileSelectorController';
        this.controllerAs = 'vm';
        this.bindings = {
            multiple: '<',
            autoConfirm: '<',
            onChange: '&',
        };
    }
}
