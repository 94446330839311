export const locale = {
    lang: 'fr',
    data: {
        CONFIGURATION: {
            TITLE: 'Configuration Hospistock',
            WHOLESALER_TITLE: 'Grossistes - Répartiteurs',
            DEPOSITARY_TITLE: 'Dépositaires',
            NEW_WHOLESALER_LABEL: 'Ajouter un grossiste - répartiteur',
            NEW_DEPOSITARY_LABEL: 'Ajouter un dépositaire',
            WHOLESALER_EDIT_WARNING: 'La modification impactera le grossiste - répartiteur sélectionné sur les ruptures existantes.',
            DEPOSITARY_EDIT_WARNING: 'La modification impactera le dépositaire sélectionné sur les ruptures existantes.',
            WHOLESALER_DELETED: 'Grossiste - répartiteur supprimé',
            DEPOSITARY_DELETED: 'Dépositaire supprimé',
            TABS: {
                WHOLESALERS: 'Grossistes - Répartiteurs',
                DEPOSITARIES: 'Dépositaires'
            },
            DELETE_WHOLESALER: {
                TITLE: "Confirmer la suppression d'un grossiste - répartiteur",
                CONTENT: `Voulez-vous vraiment supprimer le grossiste - répartiteur {{name}} ?`
            },
            DELETE_DEPOSITARY: {
                TITLE: "Confirmer la suppression d'un dépositaire",
                CONTENT: `Voulez-vous vraiment supprimer le dépositaire {{name}} ?`
            }
        }
    }
};
