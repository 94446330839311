export class HvSelectDosageComponent {
    constructor() {
        this.controller = 'HvSelectDosageController';
        this.controllerAs = 'vm';
        this.template = require('./hv-select-dosage.html').default;
        this.bindings = {
            quantity: '<',
            quantityName: '<',
            quantityRequired: '<',
            type: '<',
            typeName: '<',
            typeRequired: '<',
            form: '<',
            dropInfo: '<',
            onChange: '&',
        };
    }
}
