var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { take } from 'rxjs/operators';
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ActivityASchema, EHTTPStatus, EMpStatus, IFile } from '@mapuilabs/mpl-interfaces';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { SelectPosologyComponent } from '@shared/components/select-posology/select-posology.component';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { DialogService } from '@services/dialog/dialog.service';
import { ActivitiesInstancesService } from '@services/hospiville/activities/activities-instances.service';
let ActivityDetailsFileToDbsComponent = class ActivityDetailsFileToDbsComponent {
    constructor(_stateService, _fuseTranslationLoaderService, _translationService, _dialogService, _activitiesInstancesService, _snackBarService) {
        this._stateService = _stateService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._activitiesInstancesService = _activitiesInstancesService;
        this._snackBarService = _snackBarService;
        /**
         * List of instance provided by the server from the activity ID
         */
        this.instances = [];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('FILE_TO_DBS.TITLE')
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a;
        this.form = new UntypedFormGroup({
            drugDosagePosology: new UntypedFormGroup({})
        });
        this._activitiesInstancesService
            .getByActivity(this.activity, (_a = this.activity.dest) === null || _a === void 0 ? void 0 : _a.finessCode)
            .subscribe((res) => (this.instances = res));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    /**
     * Check if newInstance don't already exists in list
     * @private
     * @param instance
     */
    _getInstanceDuplicate(instance) {
        return this.instances.findIndex((inst) => {
            return (instance.UCD13 === inst.UCD13 &&
                instance.DCI === inst.DCI &&
                instance.denomination === inst.denomination);
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    get drugDosagePosologyForm() {
        var _a;
        return (_a = this.form) === null || _a === void 0 ? void 0 : _a.get('drugDosagePosology');
    }
    get dosageType() {
        return this.drugDosagePosologyForm.get('dosageType');
    }
    addInstance(newInstance) {
        if (this._getInstanceDuplicate(newInstance) >= 0) {
            this._snackBarService.openCustomError(this._translationService.instant('FILE_TO_DBS.ALREADY_ADDED'), null, {
                duration: 3000
            });
            return;
        }
        this.dosageType.reset(); // Needs to be reset first because it will trigger _updateDosageType
        this.form.reset();
        this.instances.push(newInstance);
        this.addToDbs(newInstance);
    }
    deleteInstance(instance) {
        this.instances.splice(this._getInstanceDuplicate(instance), 1);
        this.deleteToDbs(instance);
    }
    addToDbs(instance) {
        var _a;
        this._activitiesInstancesService
            .add(this.activity, [instance], (_a = this.activity.dest) === null || _a === void 0 ? void 0 : _a.finessCode)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return this._snackBarService.openErrorMpStatus(EMpStatus.NoResult);
            }
            this._snackBarService.open(this._translationService.instant('FILE_TO_DBS.ADDED'));
        }, (err) => {
            console.error(err);
            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
        });
    }
    deleteToDbs(instance) {
        var _a;
        this._activitiesInstancesService
            .delete(instance, (_a = this.activity.dest) === null || _a === void 0 ? void 0 : _a.finessCode)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return this._snackBarService.openErrorMpStatus(EMpStatus.NoResult);
            }
        }, (err) => {
            console.error(err);
            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
        });
    }
    goBackToActivity() {
        var _a;
        this._stateService
            .go('hospivilleV2.activities.source.details', {
            id: this.activity._id,
            fileId: (_a = this.file) === null || _a === void 0 ? void 0 : _a._id
        })
            .catch(console.error);
    }
    goToDbs() {
        this._stateService.go('hospiville.bmo').catch(console.error);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _g : Object)
], ActivityDetailsFileToDbsComponent.prototype, "activity", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof IFile !== "undefined" && IFile) === "function" ? _h : Object)
], ActivityDetailsFileToDbsComponent.prototype, "file", void 0);
__decorate([
    ViewChild(SelectPosologyComponent, { static: false }),
    __metadata("design:type", typeof (_j = typeof SelectPosologyComponent !== "undefined" && SelectPosologyComponent) === "function" ? _j : Object)
], ActivityDetailsFileToDbsComponent.prototype, "_selectPosology", void 0);
ActivityDetailsFileToDbsComponent = __decorate([
    Component({
        selector: 'hvx-activity-details-file-to-dbs',
        template: require('./activity-details-file-to-dbs.component.html').default,
        styles: [require('./activity-details-file-to-dbs.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StateService !== "undefined" && StateService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof ActivitiesInstancesService !== "undefined" && ActivitiesInstancesService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object])
], ActivityDetailsFileToDbsComponent);
export { ActivityDetailsFileToDbsComponent };
