var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslationService } from '../../services/translation/translation.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { MemberService } from '../../services/member/member.service';
import { IdentityService } from '../../services/identity/identity.service';
import { SnackbarService } from '../../services/snackbar/snackbar.service';
import { InformationTabComponent } from './information-tab/information-tab.component';
import { SettingsTabComponent } from './settings-tab/settings-tab.component';
import { MailsTabComponent } from './mails-tab/mails-tab.component';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
let UserAccountComponent = class UserAccountComponent {
    constructor(_identityService, _memberService, _fuseTranslationLoaderService, _translationService, _snackBarService) {
        this._identityService = _identityService;
        this._memberService = _memberService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: [
                this._translate('UserAccount.INFORMATION.FAMILYNAME'),
                this._translate('UserAccount.INFORMATION.FIRSTNAME'),
                '(email)'
            ].join(' '),
            icon: 'person_outline'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._memberService.getById(this._identityService.user._id).subscribe((user) => {
            this.createDetailFormGroup();
            this.user = user;
            // Update page header title
            this.pageHeaderConfig = Object.assign(this.pageHeaderConfig, {
                title: [user.firstName, user.familyName, '(' + user.email + ')'].join(' ')
            });
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // Create an empty FormGroup. The form controls will be added by the child components
    createDetailFormGroup() {
        this.detailForm = new UntypedFormGroup({
            infoForm: new UntypedFormGroup({}),
            settingsForm: new UntypedFormGroup({}),
            mailsForm: new UntypedFormGroup({}),
            notifsForm: new UntypedFormGroup({})
        });
    }
    updateMembers() {
        this.user = this.informationTabComponent.updateMember(this.user);
        this.user = this.settingsTabComponent.updateMember(this.user);
        this.user = this.mailsTabComponent.updateMember(this.user);
        this.user = this.notificationsTabComponent.updateMember(this.user);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    saveUser() {
        this.updateMembers();
        this._memberService.save(this.user).subscribe((newMember) => {
            this._translationService.setLanguage(newMember.config.language).subscribe(() => {
                this._snackBarService.open(this._translationService.instant('UserAccount.SAVE.SUCCESSFULLY'));
                this.detailForm.markAsPristine();
            }, (err) => {
                this._snackBarService.openError(err);
            });
        }, (err) => {
            if (err.status) {
                this._snackBarService.openError(err);
            }
        });
    }
};
__decorate([
    ViewChild(InformationTabComponent, { static: false }),
    __metadata("design:type", typeof (_f = typeof InformationTabComponent !== "undefined" && InformationTabComponent) === "function" ? _f : Object)
], UserAccountComponent.prototype, "informationTabComponent", void 0);
__decorate([
    ViewChild(SettingsTabComponent, { static: false }),
    __metadata("design:type", typeof (_g = typeof SettingsTabComponent !== "undefined" && SettingsTabComponent) === "function" ? _g : Object)
], UserAccountComponent.prototype, "settingsTabComponent", void 0);
__decorate([
    ViewChild(MailsTabComponent, { static: false }),
    __metadata("design:type", typeof (_h = typeof MailsTabComponent !== "undefined" && MailsTabComponent) === "function" ? _h : Object)
], UserAccountComponent.prototype, "mailsTabComponent", void 0);
__decorate([
    ViewChild(NotificationsTabComponent, { static: false }),
    __metadata("design:type", typeof (_j = typeof NotificationsTabComponent !== "undefined" && NotificationsTabComponent) === "function" ? _j : Object)
], UserAccountComponent.prototype, "notificationsTabComponent", void 0);
UserAccountComponent = __decorate([
    Component({
        selector: 'mpx-user-account',
        template: require('./user-account.component.html').default,
        styles: [require('./user-account.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object, typeof (_b = typeof MemberService !== "undefined" && MemberService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], UserAccountComponent);
export { UserAccountComponent };
