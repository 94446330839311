export const locale = {
    lang: 'en',
    data: {
        AVAILABLE: {
            CANT_CREATE: {
                TITLE: 'Your quota has been exceeded',
                TEXT: `You have reached the maximum number of free available offers. Contact the MaPUILabs team at contact@mapui.fr for more information.`
            }
        }
    }
};
