var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { ITimingPeriod } from '@mapuilabs/mpl-interfaces';
import { EChartDataType } from '../../../../../typings/chart-data-type.enum';
import { StatsMedicationReconciliationService } from '../../../../../services/stats/medication-reconciliation.service';
let MedRecWidgetComponent = class MedRecWidgetComponent {
    constructor(_statsMedicationReconciliationService) {
        this._statsMedicationReconciliationService = _statsMedicationReconciliationService;
        this.medRecWidget = {
            isLoading: true,
            type: EChartDataType.Number,
            title: 'STATS_HOSPIVILLE.WIDGET_TOTAL_MEDREC.TITLE',
            value: null,
            fontClass: 'font-size-56'
        };
        this.isReady = false;
    }
    ngOnInit() {
        if (this.dateLimits) {
            this.medRecWidget.title = 'STATS_HOSPIVILLE.WIDGET_PERIOD_MEDREC.TITLE';
        }
        if (this.colorClass) {
            this.medRecWidget.fontClass = this.medRecWidget.fontClass.concat(' ', this.colorClass);
        }
        this.isReady = true;
        this.updateData();
    }
    ngOnChanges(changes) {
        var _a;
        if ((_a = changes === null || changes === void 0 ? void 0 : changes.dateLimits) === null || _a === void 0 ? void 0 : _a.currentValue) {
            this.updateData();
        }
    }
    updateData() {
        if (this.isReady) {
            this._statsMedicationReconciliationService
                .getNumberOfConcialiations(this.hospital, this.dateLimits)
                .subscribe((numberOfMedRec) => {
                this.medRecWidget.value = numberOfMedRec;
                this.medRecWidget.isLoading = false;
            });
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], MedRecWidgetComponent.prototype, "hospital", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof ITimingPeriod !== "undefined" && ITimingPeriod) === "function" ? _b : Object)
], MedRecWidgetComponent.prototype, "dateLimits", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MedRecWidgetComponent.prototype, "colorClass", void 0);
MedRecWidgetComponent = __decorate([
    Component({
        selector: 'mpx-med-rec-widget',
        template: require('./med-rec-widget.component.html').default,
        styles: [require('./med-rec-widget.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StatsMedicationReconciliationService !== "undefined" && StatsMedicationReconciliationService) === "function" ? _a : Object])
], MedRecWidgetComponent);
export { MedRecWidgetComponent };
