var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
let FuseNavHorizontalCollapsableComponent = class FuseNavHorizontalCollapsableComponent {
    constructor(_fuseConfigService) {
        this._fuseConfigService = _fuseConfigService;
        this.isOpen = false;
        this.classes = 'nav-collapsable nav-item';
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Subscribe to config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this.fuseConfig = config;
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open
     */
    open() {
        this.isOpen = true;
    }
    /**
     * Close
     */
    close() {
        this.isOpen = false;
    }
};
__decorate([
    HostBinding('class'),
    __metadata("design:type", Object)
], FuseNavHorizontalCollapsableComponent.prototype, "classes", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FuseNavHorizontalCollapsableComponent.prototype, "item", void 0);
__decorate([
    HostListener('mouseenter'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FuseNavHorizontalCollapsableComponent.prototype, "open", null);
__decorate([
    HostListener('mouseleave'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FuseNavHorizontalCollapsableComponent.prototype, "close", null);
FuseNavHorizontalCollapsableComponent = __decorate([
    Component({
        selector: 'fuse-nav-horizontal-collapsable',
        template: require('./collapsable.component.html').default,
        styles: [require('./collapsable.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseConfigService !== "undefined" && FuseConfigService) === "function" ? _a : Object])
], FuseNavHorizontalCollapsableComponent);
export { FuseNavHorizontalCollapsableComponent };
