var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
let MpMatPaginatorIntl = class MpMatPaginatorIntl extends MatPaginatorIntl {
    constructor(translate) {
        super();
        this.translate = translate;
        this.getRangeLabel = (page, pageSize, length) => {
            if (length === 0 || pageSize === 0) {
                return `0 / ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} / ${length}`;
        };
        this.translate.onDefaultLangChange.subscribe((e) => {
            this.getAndInitTranslations();
        });
        this.getAndInitTranslations();
    }
    getAndInitTranslations() {
        this.translate
            .get(['PAGINATOR.ITEMS_PER_PAGE', 'PAGINATOR.NEXT_PAGE', 'PAGINATOR.PREVIOUS_PAGE', 'PAGINATOR.OF_LABEL'])
            .subscribe((translation) => {
            this.itemsPerPageLabel = translation['PAGINATOR.ITEMS_PER_PAGE'];
            this.nextPageLabel = translation['PAGINATOR.NEXT_PAGE'];
            this.previousPageLabel = translation['PAGINATOR.PREVIOUS_PAGE'];
            this.changes.next();
        });
    }
};
MpMatPaginatorIntl = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _a : Object])
], MpMatPaginatorIntl);
export { MpMatPaginatorIntl };
