import { EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { MpDashboardController } from './mp-dashboard.controller';
import { MpDashboardComponent } from './mp-dashboard.component';
import { MpSidenavDashboardFiltersController } from './mp-sidenav-dashboard-filters/mp-sidenav-dashboard-filters.controller';
import { MpSidenavDashboardFiltersComponent } from './mp-sidenav-dashboard-filters/mp-sidenav-dashboard-filters.component';
angular
    .module('mapui.dashboard', [
    'mapui.services.auth',
    'mapui.services.loan',
    'mapui.services.preparation',
    'mapui.services.status',
    'mapui.services.modal',
    'mapui.services.hospital',
    'mapui.services.toast',
    'ngResource',
])
    .config(configDashboard)
    .controller('MpDashboardController', MpDashboardController)
    .component('mpDashboard', new MpDashboardComponent())
    .controller('MpSidenavDashboardFiltersController', MpSidenavDashboardFiltersController)
    .component('mpSidenavDashboardFilters', new MpSidenavDashboardFiltersComponent())
    .run(runDashboard);
/** @ngInject */
function configDashboard($stateProvider) {
    $stateProvider.state('mapui.dashboard', {
        url: '/dashboard?tab',
        data: {
            access: EMaPUIPermission.Dashboard_Read,
        },
        views: {
            'contentangularjs@mapui': {
                template: '<mp-dashboard></mp-dashboard>',
            },
        },
        params: {
            tab: {
                dynamic: true
            }
        },
        resolve: {},
        bodyClass: 'dashboard',
    });
}
function runDashboard($templateCache) {
    $templateCache.put('app/main/exchanges/mp-dashboard/mp-dashboard-full.html', require('./mp-dashboard-full.html').default);
    $templateCache.put('app/main/exchanges/mp-dashboard/mp-dashboard-lite.html', require('./mp-dashboard-lite.html').default);
}
