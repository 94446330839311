var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { IFile } from '@mapuilabs/mpl-interfaces';
import { UploadService } from '@services/upload/upload.service';
import { FileService } from '@services/file/file.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
let FileViewerComponent = class FileViewerComponent {
    constructor(_fuseTranslationLoaderService, _domSanitizer, _uploadService, fileService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._domSanitizer = _domSanitizer;
        this._uploadService = _uploadService;
        this.fileService = fileService;
        this._snackBarService = _snackBarService;
        /**
         * View url that can be trusted.
         */
        this.safeFileUrl = '';
        this.isFilePreviewHidden = false;
        /**
         * Tells if the component can show the file regarding its format.
         */
        this.mustBeDownloaded = () => {
            return this.file && !this.isPdf() && !this.isImg();
        };
        /**
         * Call the FileService methods.
         */
        this.isPdf = () => {
            return this.fileService.isPdf(this.file);
        };
        this.isImg = () => {
            return this.fileService.isImg(this.file);
        };
        /**
         * Download the file that can't be showed.
         */
        this.download = () => {
            if (this.file.url && this.file.name) {
                saveAs(this.file.url, this.file.name);
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnChanges(changes) {
        // Sanitizes the viewUrl when the file changes
        if (changes.file.currentValue) {
            this.isFilePreviewHidden = false;
            this.safeFileUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(this.file.viewUrl);
        }
    }
    /**
     * Hide img if loading failed
     */
    hideFilePreview() {
        this.isFilePreviewHidden = true;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof IFile !== "undefined" && IFile) === "function" ? _f : Object)
], FileViewerComponent.prototype, "file", void 0);
FileViewerComponent = __decorate([
    Component({
        selector: 'file-viewer',
        template: require('./file-viewer.component.html').default,
        styles: [require('./file-viewer.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof DomSanitizer !== "undefined" && DomSanitizer) === "function" ? _b : Object, typeof (_c = typeof UploadService !== "undefined" && UploadService) === "function" ? _c : Object, typeof (_d = typeof FileService !== "undefined" && FileService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], FileViewerComponent);
export { FileViewerComponent };
