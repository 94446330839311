export class MpModalRequestComponent {
    constructor() {
        this.controller = 'MpModalRequestController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-request.html').default;
        this.bindings = {
            request: '<',
        };
    }
}
