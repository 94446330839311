export class RoleService {
    /** @ngInject */
    constructor(Api, $q) {
        this.Api = Api;
        this.$q = $q;
        this.getAllForEstablishments = () => {
            const defer = this.$q.defer();
            this.Api.hospital.roles.all(null, (res) => defer.resolve(res), (err) => defer.reject(err));
            return defer.promise;
        };
    }
}
