var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UIRouter } from '@uirouter/angularjs';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
let HsDashboardService = class HsDashboardService {
    constructor(_fuseTranslationLoaderService, _http, _route) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._http = _http;
        this._route = _route;
        // Config
        const currentState = this._route.globals.current;
        this._serviceConfig = currentState.data.servicesConfig.dashboard;
        this._endPoint = this._serviceConfig.endPoint;
        this.viewType = this._serviceConfig.viewType;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    getDashboardLaboList() {
        const endpoint = `${this._endPoint}/labo-list`;
        return this._http.get(endpoint);
    }
    getDashboardEstablishmentPublicationsSummary() {
        const endpoint = `${this._endPoint}/summary`;
        return this._http.get(endpoint);
    }
    getDashboardGroupList() {
        const endpoint = `${this._endPoint}/group-list?productType=${this._serviceConfig.productType}`;
        return this._http.get(endpoint);
    }
};
HsDashboardService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _b : Object, typeof (_c = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _c : Object])
], HsDashboardService);
export { HsDashboardService };
