import * as angular from 'angular';
export class HvSpecificPosologyController {
    /** @ngInject */
    constructor(Api, DrugInstanceService, _) {
        this.Api = Api;
        this.DrugInstanceService = DrugInstanceService;
        this._ = _;
        /**
         * return the generated sentence for the given posology
         * @param {ITiming} posology
         */
        this.getPosologySentence = (posology) => {
            return this.DrugInstanceService.generatePosologySentence(posology);
        };
        /**
         * Get the translation of a given value
         * @param type
         * @param value
         * @returns {string}
         */
        this.getTranslation = (type, value) => {
            if (!this._constTypes || !type || (!value && value !== 0))
                return;
            return this._.find(this._constTypes[type], { value: value }).translate;
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this._constTypes = ans;
        });
    }
    $onChanges(changes) {
        if (changes.posologies) {
            this.posologies = angular.copy(this.posologies);
        }
        if (changes.noStyle)
            this.noStyle = angular.copy(this.noStyle);
    }
}
