export const locale = {
    lang: 'en',
    data: {
        LIST_EMAILS: {
            TITLE: `Secure emails`,
            SEARCH_EMAIL: `Search an email`,
            TABLE: {
                HOSPITAL: `Hospital`,
                EMAIL: `Secure email address`,
                MANAGER: `Manager`
            }
        }
    }
};
