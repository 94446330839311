export const locale = {
    lang: 'en',
    data: {
        EYE_DROP_SELECTION: {
            TITLE: 'Drops',
            LEFT: 'Left eye',
            RIGHT: 'Right eye',
            OK: 'OK'
        }
    }
};
