export const locale = {
    lang: 'en',
    data: {
        STATS_HOSPIVILLE: {
            HEADER: {
                TITLE: 'Hospiville statistics - Reconciliations',
                START_DATE: 'Start date',
                END_DATE: 'End date'
            },
            HOSPITALS_TABLE: {
                HOSPITAL: 'Hospital',
                QUANTITY: 'Quantity',
                LAST_MED_REC: 'Last'
            },
            WIDGET_TOTAL_MEDREC: {
                TITLE: 'Total of reconciliations'
            },
            WIDGET_PERIOD_MEDREC: {
                TITLE: 'Reconciliations on the period'
            },
            GRAPH_CREATION: {
                TITLE: 'Evolution of reconciliation creation',
                CURVES: {
                    TOTAL: 'Total'
                },
                Y_AXIS: 'Number of reconciliation creation'
            },
            GRAPH_TIME: {
                TITLE: 'Average duration of reconciliations (average : {{ average }} minutes)',
                CURVES: {
                    MED_REC: 'Reconciliations',
                    DRUG_BAL: 'Drug balance'
                },
                Y_AXIS: 'Duration of reconciliations (in minutes)'
            }
        }
    }
};
