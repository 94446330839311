export const locale = {
    lang: 'en',
    data: {
        PATIENT: {
            CONSENT: {
                TITLE: `Consent:`,
                NOT_REALISED: `Not realized`,
                OK: `OK`,
                KO: `Refused`,
                WAITING: `Waiting`,
                UNREALISABLE: `Unrealisable`
            },
            PATHWAYS: {
                OCTAVE: 'OCTAVE'
            }
        },
        DOSAGE: {
            PILL: {
                NAME: 'pill',
                SHORT: 'pill'
            },
            EFFERVESCENT_TABLET: {
                NAME: 'effervescent tablet',
                SHORT: 'effervescent tablet'
            },
            ORODISPERSIBLE_TABLET: {
                NAME: 'orodispersible tablet',
                SHORT: 'orodispersible tablet'
            },
            CAPSULE: {
                NAME: 'capsule',
                SHORT: 'capsule'
            },
            SUPPOSITORY: {
                NAME: 'suppository',
                SHORT: 'suppository'
            },
            OVULE: {
                NAME: 'ovule',
                SHORT: 'ovule'
            },
            APPLICATION: {
                NAME: 'application',
                SHORT: 'application'
            },
            PATCH: {
                NAME: 'patch',
                SHORT: 'patch'
            },
            EYE_DROP: {
                NAME: 'eye drop',
                SHORT: 'eye drop'
            },
            DROP: {
                NAME: 'drop',
                SHORT: 'drop'
            },
            SYRINGE: {
                NAME: 'syringe',
                SHORT: 'syringe'
            },
            MILLILITER: {
                NAME: 'milliliter',
                SHORT: 'ml'
            },
            MILLIGRAM: {
                NAME: 'milligram',
                SHORT: 'mg'
            },
            MICROGRAM: {
                NAME: 'microgram',
                SHORT: 'µg'
            },
            GRAM: {
                NAME: 'gram',
                SHORT: 'g'
            },
            LITER: {
                NAME: 'liter',
                SHORT: 'l'
            },
            AEROSOL: {
                NAME: 'aerosol',
                SHORT: 'aerosol'
            },
            INHALATION: {
                NAME: 'inhalation',
                SHORT: 'inhalation'
            },
            PUFF: {
                NAME: 'puff',
                SHORT: 'puff'
            },
            UNIT: {
                NAME: 'unit',
                SHORT: 'unit'
            },
            BAG: {
                NAME: 'bag',
                SHORT: 'bag'
            },
            UNIDOSE: {
                NAME: 'unidose',
                SHORT: 'unidose'
            },
            TABLESPOON: {
                NAME: 'tablespoon',
                SHORT: 'tbsp'
            },
            TEASPOON: {
                NAME: 'teaspoon',
                SHORT: 'tsp'
            },
            GRANULE: {
                NAME: 'granule',
                SHORT: 'granule'
            },
            TUBE: {
                NAME: 'tube',
                SHORT: 'tube'
            },
            WASH: {
                NAME: 'wash',
                SHORT: 'wash'
            },
            BULB: {
                NAME: 'bulb',
                SHORT: 'bulb'
            },
            PELLET: {
                NAME: 'pellet',
                SHORT: 'pellet'
            },
            MEASURE_SPOON: {
                NAME: 'measure spoon',
                SHORT: 'measure spoon'
            },
            INTERNATIONAL_UNIT: {
                NAME: 'international unit',
                SHORT: 'IU'
            },
            POUCH: {
                NAME: 'pouch',
                SHORT: 'pouch'
            },
            INJECTION: {
                NAME: 'injection',
                SHORT: 'injection'
            },
            BOTTLE: {
                NAME: 'bottle',
                SHORT: 'bottle'
            },
            POT: {
                NAME: 'pot',
                SHORT: 'pot'
            },
            DOSE: {
                NAME: 'dose',
                SHORT: 'dose'
            }
        },
        POSOLOGY_TYPE: {
            MORNING: 'Morning',
            NOON: 'Noon',
            EVENING: 'Evening',
            BEDTIME: 'Bedtime',
            M_N_E: 'Morning - Noon - Evening',
            M_N_E_B: 'Morning - Noon - Evening - Bedtime',
            EVERY_4H: 'every 4h',
            EVERY_6H: 'every 6h',
            EVERY_8H: 'every 8h',
            M_E: 'Morning - Evening',
            M_N: 'Morning - Noon',
            N_E: 'Noon - Evening',
            DAY: 'Day',
            SNACK: 'Snack',
            OTHER: 'Other'
        },
        MEDREC: {
            STATUS: {
                ON_GOING: 'On going',
                TO_VALIDATE: 'To validate',
                VALIDATED: 'Validated'
            },
            TRANSIT: {
                ENTRANCE: 'Entrance reconciliation',
                TRANSFER: 'Transfer reconciliation',
                EXIT: 'Exit reconciliation'
            },
            PROCESS: {
                PROACTIVE: 'Proactive',
                RETROACTIVE: 'Retroactive'
            }
        }
    }
};
