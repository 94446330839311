var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { ActivityASchema, EActivityStatus, EActivityType, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CGender } from '@shared/constTypes/member/member-gender.const';
import { CPatientConsentStatus } from '@shared/constTypes/consent/consent-status.const';
import { CMedRecTransit } from '@shared/constTypes/medication-reconciliation/medRec-transit.const';
import { CActivityStatuses } from '@shared/constTypes/activity/activity-status.const';
import { CActivityTypes } from '@shared/constTypes/activity/activity-type.const';
import { RolesService } from '@services/role/roles.service';
import { DialogService } from '@services/dialog/dialog.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ActivitySourceService } from '@services/hospiville/activities/activities-source.service';
import { TranslationService } from '@services/translation/translation.service';
import { ImportFilesModalComponent } from '../../activity-details-dest/import-files-modal/import-files-modal.component';
let ActionsTabSourceComponent = class ActionsTabSourceComponent {
    constructor(_rolesService, _activityService, _dialogService, _snackBarService, _stateService, _translationService, _matDialog) {
        this._rolesService = _rolesService;
        this._activityService = _activityService;
        this._dialogService = _dialogService;
        this._snackBarService = _snackBarService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this._matDialog = _matDialog;
        this.cGender = CGender;
        this.cPatientConsentStatus = CPatientConsentStatus;
        this.cMedRecTransit = CMedRecTransit;
        this.cActivityStatuses = CActivityStatuses;
        this.cActivityTypes = CActivityTypes;
        /**
         * Emits when the activity has been modified.
         */
        this.onActivityChanged = new EventEmitter();
        /**
         * Emits when the tooltip on the save button of the parent component should be toggled.
         */
        this.showEndActivityTooltip = new EventEmitter();
        this.showCancelActivityTooltip = new EventEmitter();
        this.isFinished = false;
        this.isCanceled = false;
        /**
         * Changes the current [[ActivityASchema]] status
         * @param {EActivityStatus} status: New activity's status
         * @param snackBarConfirmMessage: Message to show when done
         */
        this.changeStatus = (status, snackBarConfirmMessage) => {
            this.activity.status = status;
            this._activityService.save(this.activity).subscribe((newActivity) => {
                if (newActivity) {
                    this._activityService.getById(this.activity._id).subscribe((newActivity) => {
                        if (newActivity) {
                            this.onActivityChanged.emit(newActivity);
                            this._snackBarService.open(this._translationService.instant(snackBarConfirmMessage || `ACTIVITY_DETAILS.SNACKBAR.MODIFIED`));
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
        this.getTranslation = (from, value, interpolateParams) => {
            return this._translationService.getTranslation(from, value, interpolateParams);
        };
        /**
         * Ends the current [[ActivityASchema]]
         */
        this.endActivity = () => {
            const dialogConfig = {
                title: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.CONFIRM_DIALOG_TITLE`),
                message: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.CONFIRM_DIALOG_MESSAGE`),
                confirmButton: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.CONFIRM_DIALOG_OK_BUTTON`)
            };
            this._dialogService
                .openConfirmDialog(dialogConfig)
                .pipe(take(1))
                .subscribe((hasConfirmed) => {
                if (hasConfirmed) {
                    this.changeStatus(this.cActivityStatuses.ended.value, 'ACTIVITY_DETAILS.SNACKBAR.ENDED');
                }
            }, this._snackBarService.openError);
        };
        /**
         * Cancels the current [[ActivityASchema]]
         */
        this.cancelActivity = () => {
            const dialogConfig = {
                title: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.CONFIRM_DIALOG_TITLE`),
                message: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.CONFIRM_DIALOG_MESSAGE`),
                cancelButton: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.CONFIRM_DIALOG_CANCEL_BUTTON`),
                confirmButton: this._translationService.instant(`ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.CONFIRM_DIALOG_OK_BUTTON`)
            };
            this._dialogService.openConfirmDialog(dialogConfig).subscribe((hasConfirmed) => {
                if (hasConfirmed) {
                    this.changeStatus(EActivityStatus.Canceled, `ACTIVITY_DETAILS.SNACKBAR.CANCELED`);
                }
            }, this._snackBarService.openError);
        };
        this.openImportFilesModal = () => {
            this._matDialog
                .open(ImportFilesModalComponent, { disableClose: false, maxWidth: '750px' })
                .afterClosed()
                .pipe(take(1))
                .subscribe((files) => {
                if (files) {
                    this._activityService
                        .addFiles(this.activity, files, this.isFinished ? EActivityStatus.EndedWithFiles : EActivityStatus.Files)
                        .pipe(take(1))
                        .subscribe((updatedActivity) => {
                        if (updatedActivity && updatedActivity._id === this.activity._id) {
                            this.onActivityChanged.emit(); // Refresh this.activity @Input()
                            this._snackBarService.open(this._translationService.instant('ACTIVITY_DETAILS.SNACKBAR.FILES_ADDED'));
                        }
                        else {
                            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
    }
    ngOnChanges(changes) {
        if (changes.activity) {
            this.isFinished =
                this.activity.status === EActivityStatus.EndedWithFiles ||
                    this.activity.status === EActivityStatus.Ended;
            this.isCanceled = this.activity.status === EActivityStatus.Canceled;
        }
    }
    get isSendMedRecActivity() {
        return this.activity.type === EActivityType.SendMedReconciliation;
    }
    toggleEndCancelTooltip() {
        if (this.isFinished) {
            switch (this.activity.status) {
                case EActivityStatus.Ended:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.ALREADY_ENDED');
                case EActivityStatus.Canceled:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.ALREADY_CANCELED');
            }
        }
        return '';
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _h : Object)
], ActionsTabSourceComponent.prototype, "activity", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ActionsTabSourceComponent.prototype, "migrationState", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_j = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _j : Object)
], ActionsTabSourceComponent.prototype, "onActivityChanged", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_k = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _k : Object)
], ActionsTabSourceComponent.prototype, "showEndActivityTooltip", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_l = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _l : Object)
], ActionsTabSourceComponent.prototype, "showCancelActivityTooltip", void 0);
ActionsTabSourceComponent = __decorate([
    Component({
        selector: 'hvx-actions-tab-source',
        template: require('./actions-tab.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof RolesService !== "undefined" && RolesService) === "function" ? _a : Object, typeof (_b = typeof ActivitySourceService !== "undefined" && ActivitySourceService) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof StateService !== "undefined" && StateService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object, typeof (_g = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _g : Object])
], ActionsTabSourceComponent);
export { ActionsTabSourceComponent };
