var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import * as MomentService from 'moment';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { AdminMemberService } from '../../../services/admin/member/admin-member.service';
import { AdminHistoryService } from '../../../services/admin/member/history/history.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { take } from 'rxjs/operators';
let DetailOnlineUsersComponent = class DetailOnlineUsersComponent {
    constructor(_fuseTranslationLoaderService, _stateService, _translationService, _adminMemberService, _historyService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._stateService = _stateService;
        this._translationService = _translationService;
        this._adminMemberService = _adminMemberService;
        this._historyService = _historyService;
        this.moment = MomentService;
        this.displayedColumns = ['duration', 'from', 'to'];
        this.isLoadingResults = true;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: '',
            icon: 'timelapse'
        };
    }
    ngOnInit() {
        // Get user
        this._adminMemberService
            .getById(this._stateService.params.id)
            .pipe(take(1))
            .subscribe((user) => {
            this.user = user;
            const title = user.fullName + ' (' + user.email + ')';
            this.pageHeaderConfig = Object.assign({}, this.pageHeaderConfig, { title });
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    goToAdminUserPage() {
        var _a;
        this._stateService.go('mapui.adm.members.details', { id: (_a = this.user) === null || _a === void 0 ? void 0 : _a._id });
    }
    onWeekChange(event) {
        this.isLoadingResults = true;
        this._historyService
            .getSessionsByDayByMemberIdAndByWeek(this._stateService.params.id, event)
            .pipe(take(1))
            .subscribe((sessions) => {
            this.daySessions = sessions.reverse();
            this.daySessions.forEach((day) => day.sessions.reverse());
            this.isLoadingResults = false;
        });
    }
    getDaySessionDate(sessions) {
        return this.moment(sessions[0].from).format('dddd DDD MMMM YYYY');
    }
    getDayTotalDuration(sessions) {
        let totalMS = 0;
        sessions.forEach((session) => (totalMS += session.duration));
        return this.moment.duration(totalMS).humanize();
    }
    getMomentDuration(time) {
        return this.moment.duration(time).humanize();
    }
};
DetailOnlineUsersComponent = __decorate([
    Component({
        selector: 'mpx-detail-online-users',
        template: require('./detail-online-users.component.html').default,
        styles: [require('./detail-online-users.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _d : Object, typeof (_e = typeof AdminHistoryService !== "undefined" && AdminHistoryService) === "function" ? _e : Object])
], DetailOnlineUsersComponent);
export { DetailOnlineUsersComponent };
