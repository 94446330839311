export const locale = {
    lang: 'en',
    data: {
        STOCKOUT_WIDGET: {
            TITLE: `Hospistock`,
            SEE_MORE: 'See more stockouts',
            NO_STOCKOUT: 'No recent stockouts.',
            FORBIDDEN_MESSAGE: 'Access denied',
            CONTACT_US: 'Contact us'
        }
    }
};
