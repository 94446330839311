var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable, Injector } from '@angular/core';
import { ActivityASchema, EActivityOrigin, EActivityStatus, EHospivillePermission, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '../../../shared/templates/crud/crud.template';
import { Access as AjsAccess } from '../../../../main/services/auth/access.service';
import { throwError } from 'rxjs';
import { Utils } from '@main/services/Utils';
let ActivitySourceService = class ActivitySourceService extends CrudTemplateService {
    constructor(__injector, _ajsAccess) {
        super(__injector, ActivityASchema, '/api/hospiville/activities/source', {
            create: EHospivillePermission.Activities_Create,
            read: EHospivillePermission.Activities_Read,
            update: EHospivillePermission.Activities_Edit
        });
        this.__injector = __injector;
        this._ajsAccess = _ajsAccess;
        this.addFiles = (activity, files, newStatus = EActivityStatus.Files) => {
            if (!activity || !activity.files || !files) {
                return throwError(() => EHTTPStatus.BadRequest);
            }
            files.forEach((file) => {
                var _a;
                if (file.error || !file.response || !file.response.body) {
                    console.error(`The file named '${(_a = file.file) === null || _a === void 0 ? void 0 : _a.name}' contains error or no response.`);
                    return; // Don't push the file
                }
                activity.files.push({
                    file: file.response.body,
                    from: EActivityOrigin.Hospital,
                    isHidden: false
                });
            });
            activity.status = newStatus;
            return this._http.put(`/api/hospiville/activities/source/${Utils.getId(activity)}?notif-pharma=true`, activity);
        };
    }
    updatePatient(idPatient, idMedRec) {
        this._ajsAccess.changePatient(idPatient, idMedRec);
    }
    deleteFile(fid) {
        if (!fid) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `/api/hospiville/activities/source/files/${fid}`;
        return this._http.delete(fullEndPoint);
    }
    /**
     * Tells the MSSante services that the sender's email address has been 'seen'
     */
    seenMssanteAddress() {
        const hospId = this._ajsAccess.user.hospital._id;
        const endpoint = `/api/hospiville/activities/source/seen-mail/${hospId}`;
        this._http.get(endpoint).subscribe(() => { }, console.error);
    }
    /**
     * Duplicate File and add it to patient files
     * @param patId
     * @param file
     */
    addDocToPatientFile(patId, file) {
        if (!patId || !file) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const endPoint = `/api/hospiville/activities/source/copy-doc/${Utils.getId(file)}`;
        return this._http.put(endPoint, { patient: patId });
    }
};
ActivitySourceService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof AjsAccess !== "undefined" && AjsAccess) === "function" ? _b : Object])
], ActivitySourceService);
export { ActivitySourceService };
