var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
let BytesPipe = class BytesPipe {
    constructor(_translateService) {
        this._translateService = _translateService;
        this._units = [
            { translate: 'UNITS.BYTES.B', power: 0, name: 'B' },
            { translate: 'UNITS.BYTES.KB', power: 1, name: 'kB' },
            { translate: 'UNITS.BYTES.MB', power: 2, name: 'MB' },
            { translate: 'UNITS.BYTES.GB', power: 3, name: 'GB' },
            { translate: 'UNITS.BYTES.TB', power: 4, name: 'TB' },
        ];
    }
    transform(value = 0, defaultUnit = null) {
        let unit = null;
        if (defaultUnit)
            unit = this._units.find(elem => elem.name == defaultUnit);
        if (!unit && value > 0)
            unit = this._units[Math.floor(Math.log(value) / Math.log(1024))];
        if (!unit && value === 0)
            unit = this._units[0];
        if (!unit)
            return '-';
        return `${(value / Math.pow(1024, unit.power)).toFixed(1)} ${this._translateService.instant(unit.translate)}`;
    }
};
BytesPipe = __decorate([
    Pipe({ name: 'mpxBytes' }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _a : Object])
], BytesPipe);
export { BytesPipe };
