export const locale = {
    lang: 'fr',
    data: {
        DRUG_DOS_POSO: {
            DRUG: `Médicament`,
            QUANTITY: `Quantité`,
            ADD: 'Ajouter à la prescription'
        }
    }
};
