export class HvSearchMssLetterBoxComponent {
    constructor() {
        this.controller = 'HvSearchMssLetterBoxController';
        this.controllerAs = 'vm';
        this.template = require('./hv-search-mss-letter-box.html').default;
        this.bindings = {
            chooseEstablishment: '<',
            letterBox: '<',
            establishments: '<',
            onSelected: '&',
        };
    }
}
