import * as angular from 'angular';
/** @ngInject */
export class MpSearchMedicalDeviceController {
    /** @ngInject */
    constructor(MedicalDeviceService) {
        this.MedicalDeviceService = MedicalDeviceService;
        this.searchMedicalDevice = () => {
            // if (this.ctrl.searchText.length >= 3)
            return this.MedicalDeviceService.findByName(this.ctrl.searchText);
        };
        /**
         * Set the selected medical Device
         */
        this.searchMedicalDeviceSelected = () => {
            if (this.ctrl.selected) {
                this.onSelected({ medicalDevice: this.ctrl.selected });
            }
        };
        /**
         * On text change call callback
         */
        this.searchTextChange = () => {
            if (this.onTextChange) {
                this.onTextChange({ text: this.ctrl.searchText });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
        this.name = this.name || 'search-medical-device-autocomplete';
    }
    ;
    $onInit() {
        if (this.medicalDevice) {
            this.ctrl.searchText = this.medicalDevice;
        }
    }
    $onChanges(changes) {
        if (changes.medicalDevice) {
            this.ctrl.searchText = angular.copy(this.medicalDevice);
        }
    }
}
