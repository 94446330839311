export const locale = {
    lang: 'fr',
    data: {
        DETAIL: {
            TITLE: `ARS`,
            INFORMATION: {
                TITLE: `Informations`,
                NAME: `Nom`,
                FRENCH_DEPARTMENTS: `Départements`,
                NB_ESTABLISHMENT: `Nombre d'établissements`,
                ROLES: {
                    MAPUI: `Rôle MaPUI`,
                    HOSPIVILLE: `Rôle Hospiville`,
                    WORKSPACE: `Rôle Workspace`
                }
            },
            TABLE: {
                TITLE: `Membres de `,
                MEMBER_NAME: `Nom du membre`,
                MEMBER_EMAIL: `Email de contact`,
                MEMBER_2FA: `2FA`
            },
            SNACKBAR: {
                OK: `L'ARS a bien été modifiée.`
            }
        }
    }
};
