export class MpLoanCardsComponent {
    constructor() {
        this.controller = 'MpLoanCardsController';
        this.controllerAs = 'vm';
        this.template = require('./mp-loan-cards.html').default;
        this.bindings = {
            loans: '<',
            type: '@',
            update: '&',
            openDetails: '&',
            deleteLoan: '&',
            editLoanReturn: '&',
            hideHospital: '<',
            statusFilter: '<',
            typeFilter: '<',
            establishmentFilter: '<',
        };
    }
}
