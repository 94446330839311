import { FilesService } from './mp-files.service';
import * as angular from 'angular';
import { EWorkspacePermission } from '@mapuilabs/mpl-interfaces';
import { MpFilesController } from './mp-files.controller';
import { MpFilesComponent } from './mp-files.component';
import 'angular-file-upload';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists';
import 'ng-contextmenu';
import '@iamadamjowett/angular-click-outside';
import 'jszip';
import { MpUploadsController } from './mp-uploads/mp-uploads.controller';
import { MpUploadsComponent } from './mp-uploads/mp-uploads.component';
import { MpModalPdfViewerController } from './mp-modal-pdf-viewer/mp-modal-pdf-viewer.controller';
import { MpModalPdfViewer } from './mp-modal-pdf-viewer/mp-modal-pdf-viewer.component';
import { MpModalMoveController } from './mp-modal-move/mp-modal-move.controller';
import { MpModalMoveComponent } from './mp-modal-move/mp-modal-move.component';
import { MpDirectoryHierarchyController } from './mp-modal-move/mp-directory-hierarchy/mp-directory-hierarchy.controller';
import { MpDirectoryHierarchyComponent } from './mp-modal-move/mp-directory-hierarchy/mp-directory-hierarchy.component';
import { MpLastFilesController } from './mp-last-files/mp-last-files.controller';
import { MpLastFilesComponent } from './mp-last-files/mp-last-files.component';
import { MpFileDetailsSidenavController } from './mp-file-details-sidenav/mp-file-details-sidenav.controller';
import { MpFileDetailsSidenavComponent } from './mp-file-details-sidenav/mp-file-details-sidenav.component';
import { MpDirectoryRightsController } from './mp-directory-rights/mp-directory-rights.controller';
import { MpDirectoryRightsComponent } from './mp-directory-rights/mp-directory-rights.component';
angular
    .module('mapui.files', [
    'mapui.services.auth',
    'mapui.services.modal',
    'angularFileUpload',
    'dndLists',
    'ngContextMenu',
    'angular-click-outside',
    'mapui.services.jszip',
    'ng',
])
    .config(configFiles)
    .service('FilesService', FilesService)
    .controller('MpFilesController', MpFilesController)
    .component('mpFiles', new MpFilesComponent())
    .controller('MpUploadsController', MpUploadsController)
    .component('mpUploads', new MpUploadsComponent())
    .controller('MpModalPdfViewerController', MpModalPdfViewerController)
    .component('mpModalPdfViewer', new MpModalPdfViewer())
    .controller('MpModalMoveController', MpModalMoveController)
    .component('mpModalMove', new MpModalMoveComponent())
    .controller('MpDirectoryHierarchyController', MpDirectoryHierarchyController)
    .component('mpDirectoryHierarchy', new MpDirectoryHierarchyComponent())
    .controller('MpLastFilesController', MpLastFilesController)
    .component('mpLastFiles', new MpLastFilesComponent())
    .controller('MpFileDetailsSidenavController', MpFileDetailsSidenavController)
    .component('mpFileDetailsSidenav', new MpFileDetailsSidenavComponent())
    .controller('MpDirectoryRightsController', MpDirectoryRightsController)
    .component('mpDirectoryRights', new MpDirectoryRightsComponent())
    .run(runFiles);
function configFiles($stateProvider) {
    // State
    $stateProvider.state('mapui.files', {
        url: '/files',
        data: {
            access: EWorkspacePermission.Files_Read,
        },
        views: {
            'contentangularjs@mapui': {
                template: '<mp-files></mp-files>',
            },
        },
        resolve: {},
        bodyClass: 'files',
    }).state('mapui.files.directory', {
        url: '/directory?:id',
        data: {
            access: EWorkspacePermission.Files_Read,
        },
        views: {
            'contentangularjs@mapui': {
                template: '<mp-files></mp-files>',
            },
        },
        resolve: {},
        bodyClass: 'files',
        reloadOnSearch: false,
    }).state('mapui.files.rights', {
        url: '/:id/rights',
        data: {
            access: EWorkspacePermission.FilesRights_Read,
        },
        views: {
            'contentangularjs@mapui': {
                template: '<mp-directory-rights></mp-directory-rights>',
            },
        },
        resolve: {},
        bodyClass: 'files',
    });
}
function runFiles($templateCache) {
    $templateCache.put('app/main/general/mp-loading/mp-loading.html', require('../../general/mp-loading/mp-loading.html').default);
    $templateCache.put('app/main/workspace/mp-files/sidenavs/main/main-sidenav.html', require('./sidenavs/main/main-sidenav.html').default);
    $templateCache.put('app/main/workspace/mp-files/views/grid/grid-view.html', require('./views/grid/grid-view.html').default);
    $templateCache.put('app/main/workspace/mp-files/views/list/list-view.html', require('./views/list/list-view.html').default);
}
