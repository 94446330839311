var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as angular from 'angular';
import { EActivityType, EEstablishmentType, EMpStatus, EPathways, EPatientConsent, ESource, } from '@mapuilabs/mpl-interfaces';
export class HvPatientSourceController {
    /** @ngInject */
    constructor(Api, _, $translate, $mdDialog, Access, Auth, MailsService, ActivitiesService, SourceService, ToastService, HospivilleService, ModalService) {
        this.Api = Api;
        this._ = _;
        this.$translate = $translate;
        this.$mdDialog = $mdDialog;
        this.Access = Access;
        this.Auth = Auth;
        this.MailsService = MailsService;
        this.ActivitiesService = ActivitiesService;
        this.SourceService = SourceService;
        this.ToastService = ToastService;
        this.HospivilleService = HospivilleService;
        this.ModalService = ModalService;
        /**
         * @type {ESource}
         */
        this.ESource = ESource;
        /**
         * @type {EPatientConsent}
         */
        this.EPatientConsent = EPatientConsent;
        /**
         * Flag for mail icon loader
         */
        this.hasCheckedActivity = true;
        /**
         * When source change
         */
        this.change = () => {
            if (this.onChange) {
                this.onChange({
                    $event: {
                        source: this.source,
                    },
                });
            }
        };
        /**
         * Get the [[EEstablishmentType]]
         * @returns {EEstablishmentType}
         */
        this.getEstablishmentType = () => {
            if (ESource.Pharmacy == this.type) {
                return EEstablishmentType.Pharmacy;
            }
            if (ESource.NursingHome == this.type) {
                return EEstablishmentType.EHPAD;
            }
        };
        /**
         * Get the translation value of source name by type
         * @returns {string}
         */
        this.getSourceName = () => {
            if (!this._sourceType)
                return;
            return this._.find(this._sourceType, { value: this.type }).translate;
        };
        /**
         * Set establishment [[ISource]] reference
         * @param establishment
         */
        this.selectEstablishment = ({ establishment }) => {
            this.source.establishment = establishment;
            this.change();
        };
        /**
         * Set health professional [[ISource]] reference
         * @param doctor
         */
        this.selectHealthPro = ({ doctor }) => {
            this.source.healthProfessional = doctor;
            this.change();
        };
        /**
         * Delete a [[ISource]]
         */
        this.delete = () => {
            if (this.onDelete) {
                this.onDelete({
                    $event: {
                        source: this.source,
                    },
                });
            }
        };
        /**
         * Add [[IPatient]] [[Id]] reference to the current [[ISource]]
         */
        this.addPatient = () => {
            if (!this.source.references) {
                this.source.references = [];
            }
            this.source.references.push({ _id: this.Access.patient._id });
            this.isReference = true;
            this.HospivilleService.savePatient(this.patient).then((savedPatient) => {
                this.patient = savedPatient;
                this.change();
            }).catch(() => {
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        /**
         * Remove [[IPatient]] [[Id]] reference to the current [[ISource]]
         */
        this.removePatient = () => {
            this._.each(this.source.references, (patient, index) => {
                if (patient._id == this.Access.patient._id) {
                    this.source.references.splice(index, 1);
                }
            });
            this.isReference = false;
            this.HospivilleService.savePatient(this.patient).then((savedPatient) => {
                this.patient = savedPatient;
                this.change();
            }).catch(() => {
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        /**
         * Check if the current [[IPatient]] is a reference to the current [[ISource]]
         * @returns {boolean}
         * @private
         */
        this._isReference = () => {
            return this.SourceService.isReference(this.source.references);
        };
        this.selectDoctor = () => {
            this.ModalService.show({
                component: 'hv-modal-search-health-pro',
                bindings: {},
                escapeToClose: true,
            }).then((res) => {
                this.onChange({
                    $event: { doctor: res, change: true },
                });
            });
        };
        this.source = {};
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this._sourceType = ans.sources;
        });
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(changes) {
        if (changes.sources) {
            this.sources = angular.copy(this.sources);
            if (this.sources) {
                if (this.sources.length === 1 && ESource.Pharmacy === this.source.type) {
                    this.source = angular.copy(this.sources[0]);
                }
                else {
                    this.source.type = this.type;
                    for (const source of this.sources) {
                        if (source.type == this.type) {
                            this.source = source;
                        }
                    }
                }
                this.isReference = this._isReference();
            }
        }
        if (changes.patient) {
            this.patient = angular.copy(this.patient);
        }
    }
    /**
     * Open modal to compose a mail
     */
    composeMail() {
        return __awaiter(this, void 0, void 0, function* () {
            let mail = {};
            let toSend;
            let selectedPathway = 'classic';
            this.hasCheckedActivity = false;
            try {
                mail.subject = this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.MAIL_SUBJECT');
                const canSend = yield this.ActivitiesService.canSendPrescriptionMail();
                this.hasCheckedActivity = true;
                if (!canSend) {
                    return this.$mdDialog.show(this.$mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.ACTIVITY_ALREADY_EXISTS.TITLE'))
                        .textContent(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.ACTIVITY_ALREADY_EXISTS.MESSAGE'))
                        .ok(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ACTIONS.OK')));
                }
                if (this.patient.pathways.includes(EPathways.Octave)) {
                    if (this.Auth.isBothOctaveAndPremium) {
                        selectedPathway = yield this.ModalService.show({
                            component: 'mp-modal-activity-pathway-picker',
                        });
                    }
                    if (this.Auth.isOnlyOctave) {
                        selectedPathway = EPathways.Octave;
                    }
                }
                if (selectedPathway === EPathways.Octave) {
                    mail.subject = this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.MAIL_SUBJECT_OCTAVE');
                }
                toSend = yield this.ModalService.show({
                    component: 'mp-modal-compose-mail',
                    bindings: {
                        pathway: selectedPathway,
                        selectedRecipient: this.source.establishment,
                        mail: mail,
                        hidden: { CC: true, BCC: true, attachments: true, secureSwitch: false },
                        disable: { subject: true, body: true },
                        secure: true,
                        bodyTemplate: 'INFOS_ASKING',
                        data: { patient: this.patient, medRec: this.HospivilleService.getMedRec() },
                    },
                    escapeToClose: true,
                });
            }
            catch (err) {
                this.hasCheckedActivity = true;
                if (err !== EMpStatus.CloseByUser) {
                    this.ToastService.show(err);
                }
                return;
            }
            let request;
            switch (selectedPathway) {
                case EPathways.Octave:
                    request = this.MailsService.sendPrescriptionRequest(toSend.mail, toSend.isSecure, this.source.establishment, EActivityType.AskPrescriptionOctave);
                    break;
                default:
                    request = this.MailsService.sendPrescriptionRequest(toSend.mail, toSend.isSecure, this.source.establishment);
            }
            request.then(() => this.ToastService.show(EMpStatus.Ok)).catch((err) => this.openAlertComposeMail(err));
        });
    }
    /**
     * Open alert window if anything went wrong while sending mail (server error)
     * @param err
     */
    openAlertComposeMail(err) {
        const translations = this.ActivitiesService.setTextAlertComposeMail(err);
        this.$mdDialog.show(this.$mdDialog.alert()
            .clickOutsideToClose(true)
            .title(this.$translate.instant(translations.alertTitle))
            .textContent(this.$translate.instant(translations.alertMessage))
            .ok(this.$translate.instant('HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ACTIONS.OK')));
    }
}
