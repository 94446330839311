import { EAdminPanelPermission, EEstablishmentType, EHospivillePermission, EMaPUIPermission, EMpStatus, ERoleSubscription } from "@mapuilabs/mpl-interfaces";
import { RoleUtils } from "../role/RoleUtils";
export class HospitalService {
    /** @ngInject */
    constructor(Api, Access, RoleService, $q, _) {
        this.Api = Api;
        this.Access = Access;
        this.RoleService = RoleService;
        this.$q = $q;
        this._ = _;
        /**
         * Get a specific [[IHospital]]
         * @param hid The [[Id]] of the hospital
         * @param admin
         * @returns A promise containing a [[IHospital]]
         */
        this.get = (hid, admin = false) => {
            const defer = this.$q.defer();
            const route = admin ? this.Api.admin.hospital.byId : this.Api.hospital.byId;
            if ((admin && !this.Access.authorize(EAdminPanelPermission.Establishments_Read)) ||
                (!admin && (!this.Access.authorize(EMaPUIPermission.Establishment_Read) && !this.Access.authorize(EHospivillePermission.Establishment_Read)))) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                route.get({ id: hid }, (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
            // return this[admin ? 'adminCachedData' : 'cachedData'].getById(hid);
        };
        /**
         * Get all [[IHospital]]
         * @returns A promise containing an array of [[IHospital]]
         */
        this.getAll = (admin = false) => {
            const defer = this.$q.defer();
            const route = admin ? this.Api.admin.hospital.byId : this.Api.hospital.byId;
            if ((admin && !this.Access.authorize(EAdminPanelPermission.Establishments_Read)) || (!admin && !this.Access.authorize(EMaPUIPermission.Establishment_Read))) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                route.all({}, (res) => defer.resolve(res), (err) => defer.reject(err));
            }
            return defer.promise;
            // return this[admin ? 'adminCachedData' : 'cachedData'].getAll();
        };
        /**
         * Add a new [[IHospital]] in dataBase using the [[Id]]
         * @param hospital The [[IHospital]] to create
         * @returns The [[IHospital]] newly created
         */
        this.add = (hospital) => {
            const defer = this.$q.defer();
            if (!hospital) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorizeAny([EMaPUIPermission.Establishment_Create, EAdminPanelPermission.Establishments_Create])) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.hospital.byId.add(hospital, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an existing [[IHospital]] in dataBase using the [[Id]]
         * @param hospital The [[IHospital]] to update
         * @param isAdmin
         * @returns {Promise<IHospital>}
         */
        this.save = (hospital, isAdmin = false) => {
            const defer = this.$q.defer();
            if (!hospital) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EMaPUIPermission.Establishment_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                const route = isAdmin ? this.Api.admin.hospital : this.Api.hospital;
                route.byId.save({ hid: hospital._id }, hospital, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Return the number of [[IHospital]]'s available
         * @param hid The [[Id]] of the [[IHospital]]
         * @returns A promise containing the number of [[IHospital]]'s available
         */
        this.countAvailable = (hid) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Available_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.hospital.count.available.get({ hid: hid }, (ans) => defer.resolve(ans.count), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Return the number of [[IHospital]]'s available
         * @returns A promise containing the number of [[IHospital]]'s available
         */
        this.countAllAvailable = () => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Available_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.hospital.count.available.get({}, (ans) => defer.resolve(ans.count), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         *
         * @param name
         * @param type
         * @return {IPromise<T>}
         */
        this.findByName = (name, type = EEstablishmentType.Hospital) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EMaPUIPermission.Establishment_Read) && !this.Access.authorize(EHospivillePermission.Establishment_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.hospital.find.byName.get({ name: name, type: type }, (ans) => defer.resolve(ans), () => defer.reject('Could not find hospitals'));
            }
            return defer.promise;
        };
        /**
         * Create hospital from establishment
         * @param {IEstablishment} e
         * @return {angular.IPromise<IHospital>}
         */
        this.createFromEstablishment = (e) => {
            const defer = this.$q.defer();
            const h = this.fillHospitalWithEstablishment({}, e);
            const roleUtils = new RoleUtils(this._);
            // Set trial by default as role
            this.RoleService.getAllForEstablishments()
                .then(roles => {
                roleUtils.setProjectsRoles(roles);
                roleUtils.setDefaultSelected(ERoleSubscription.Trial);
                h.roles = roleUtils.getDefinitiveRoles();
                this.add(h)
                    .then(defer.resolve)
                    .catch(defer.reject);
            }).catch(defer.reject);
            return defer.promise;
        };
        /**
         * Fill [[IHospital]] object with [[IEstablishment]] object values
         * @param {IHospital} h
         * @param {IEstablishment} e
         * @return {IHospital}
         */
        this.fillHospitalWithEstablishment = (h, e) => {
            h.name = e.name;
            h.addrNumber = e.addrNumber;
            h.addrStreet = e.addrStreet;
            h.addrCity = e.addrCity;
            h.addrZIP = e.addrZIP;
            h.addrCountry = e.addrCountry;
            h.region = e.region;
            h.phoneSTD = e.phone;
            h.Fax = e.fax;
            h.finessCode = e.finessCode;
            h.finessCodeJur = e.finessCodeJur;
            h.lat = e.geo.x;
            h.lng = e.geo.y;
            h.establishmentType = e.type;
            switch (e.category) {
                case 101:
                    h.hospitalType = 1;
                    break;
                case 106:
                    h.hospitalType = 4;
                    break;
                case 355:
                    h.hospitalType = 2;
                    break;
                case 292:
                    h.hospitalType = 3;
                    break;
            }
            h.active = false;
            return h;
        };
    }
    /**
     * Find [[SecureEmailAddress]] from current hospital ID.
     * @param hid
     */
    getSecureEmailAddress(hid) {
        const defer = this.$q.defer();
        if (!this.Access.authorize(EHospivillePermission.Mailbox_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.Api.hospital.secureEmailAddress.get({ hid: hid }, (ans) => defer.resolve(ans), (err) => defer.reject(err));
        }
        return defer.promise;
    }
    /**
     * Find all hospitals with the given subscription and project
     * @param project
     * @param subscription
     * @param type
     */
    findByProjectSubscription(project, subscription, type = EEstablishmentType.Hospital) {
        const defer = this.$q.defer();
        if (!this.Access.authorize(EMaPUIPermission.Establishment_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.Api.hospital.find.projectSubscription.get({ project: project, subscription: subscription, type: type }, (ans) => {
                defer.resolve(ans);
            }, () => {
                defer.reject('Could not find hospitals');
            });
        }
        return defer.promise;
    }
}
