import * as angular from 'angular';
import { MedicService } from './medic.service';
import { MpSearchMedicController } from './mp-search-medic/mp-search-medic.controller';
import { MpSearchMedicComponent } from './mp-search-medic/mp-search-medic.component';
import { MpChipsMedicController } from './mp-chips-medic/mp-chips-medic.controller';
import { MpChipsMedicComponent } from './mp-chips-medic/mp-chips-medic.component';
angular
    .module('mapui.services.medic', [])
    .service('MedicService', MedicService)
    .controller('MpSearchMedicController', MpSearchMedicController)
    .component('mpSearchMedic', new MpSearchMedicComponent())
    .controller('MpChipsMedicController', MpChipsMedicController)
    .component('mpChipsMedic', new MpChipsMedicComponent());
