var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, ContentChild, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { NavBarService } from '@app/services/nav-bar-subject/nav-bar-subject.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { ThemeService } from '@services/theme/theme.service';
import { UIRouter } from '@uirouter/angularjs';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { PageHeaderComponent } from '../page-header/page-header.component';
export var PageLayouts;
(function (PageLayouts) {
    PageLayouts["Simple"] = "simple";
    PageLayouts["Carded"] = "carded";
    PageLayouts["CardedNarrow"] = "carded-narrow";
    PageLayouts["CardedSideBar"] = "carded-sidebar";
})(PageLayouts || (PageLayouts = {}));
let PageLayoutComponent = class PageLayoutComponent {
    constructor(_fuseConfigService, _route, _themeService, _fuseSidebarService, _navBarService, _mediaObserver) {
        this._fuseConfigService = _fuseConfigService;
        this._route = _route;
        this._themeService = _themeService;
        this._fuseSidebarService = _fuseSidebarService;
        this._navBarService = _navBarService;
        this._mediaObserver = _mediaObserver;
        this.pageLayouts = PageLayouts;
        // Subjects
        this._unsubscribeAll = new Subject();
        this.isCompactViewMode = new BehaviorSubject(false);
        // Get theme from router
        this._theme = this._route.globals.current.data.theme;
        if (!this._theme) {
            throw new Error("The theme data's attribute must be provided on router state.");
        }
    }
    get theme() {
        return this._fuseConfig.scheme === 'dark' ? `${this._theme}-dark` : this._theme;
    }
    get cardedSidebar() {
        return this._fuseSidebarService.getSidebar('carded-sidebar');
    }
    get displayCompactViewFullTopBg() {
        var _a, _b;
        return ((_a = this._pageHeaderComponent) === null || _a === void 0 ? void 0 : _a.hasPageHeaderButtons) || ((_b = this._pageHeaderComponent) === null || _b === void 0 ? void 0 : _b.searchBar);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Subscribe to the config changes
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
            this._fuseConfig = config;
            this._themeService.updateOverlayTheme(this.theme);
        });
        // Update isCompactView on responsives & navBarUnfolded changes
        merge(this._navBarService.navBarUnfolded.pipe(tap((value) => (this._isNavBarUnfolded = value))), this._mediaObserver.asObservable())
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._updateIsCompactView());
        // Handle sidebar closing event when living compact view mode
        if (this.layout === 'carded-sidebar') {
            this.isCompactViewMode.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
                var _a;
                if (!value && ((_a = this.cardedSidebar) === null || _a === void 0 ? void 0 : _a.opened)) {
                    this.cardedSidebar.close();
                }
            });
        }
    }
    ngAfterContentInit() {
        if (this.layout === PageLayouts.CardedSideBar) {
            // Bind PageHeaderComponent onSidebarMenuButtonClick event to cardedSidebar
            this._pageHeaderComponent.onSidebarMenuButtonClick
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => this.cardedSidebar.toggleOpen());
        }
    }
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _updateIsCompactView() {
        this.isCompactViewMode.next(this._mediaObserver.isActive('lt-md') || (this._mediaObserver.isActive('md') && this._isNavBarUnfolded));
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], PageLayoutComponent.prototype, "layout", void 0);
__decorate([
    ContentChild(PageHeaderComponent),
    __metadata("design:type", typeof (_g = typeof PageHeaderComponent !== "undefined" && PageHeaderComponent) === "function" ? _g : Object)
], PageLayoutComponent.prototype, "_pageHeaderComponent", void 0);
PageLayoutComponent = __decorate([
    Component({
        selector: 'page-layout',
        template: require('./page-layout.component.html').default,
        styles: [require('./page-layout.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseConfigService !== "undefined" && FuseConfigService) === "function" ? _a : Object, typeof (_b = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _b : Object, typeof (_c = typeof ThemeService !== "undefined" && ThemeService) === "function" ? _c : Object, typeof (_d = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _d : Object, typeof (_e = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _e : Object, typeof (_f = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _f : Object])
], PageLayoutComponent);
export { PageLayoutComponent };
