var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSidebarModule } from '@fuse/components';
import { SharedModule } from '@shared/shared.module';
import { MapExchangeModule } from '@shared/map/exchange/map-exchange.module';
import { ExchangeMapComponent } from './map.component';
import { MapDetailsSidebarComponent } from './details-sidebar/map-details-sidebar.component';
let ExchangeMapModule = class ExchangeMapModule {
};
ExchangeMapModule = __decorate([
    NgModule({
        declarations: [ExchangeMapComponent, MapDetailsSidebarComponent],
        imports: [
            CommonModule,
            BrowserAnimationsModule,
            UIRouterModule,
            SharedModule,
            FuseSharedModule,
            FuseSidebarModule,
            FuseDirectivesModule,
            LeafletModule,
            LeafletMarkerClusterModule,
            MapExchangeModule,
            MatDividerModule,
            MatExpansionModule,
            MatIconModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatButtonModule,
            MatTooltipModule
        ]
    })
], ExchangeMapModule);
export { ExchangeMapModule };
