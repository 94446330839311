var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { HospivilleSessionService } from '@services/hospiville/hospiville-session/hospiville-session.service';
import { MedicationReconciliationService } from '@services/hospiville/medication-reconciliation/medication-reconciliation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { locale as hvFrench } from '../i18n/fr';
import { locale as hvEnglish } from '../i18n/en';
import { CPatientConsentStatus } from '@shared/constTypes/consent/consent-status.const';
import { TranslationService } from '@services/translation/translation.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CMedRecTransit } from '@shared/constTypes/medication-reconciliation/medRec-transit.const';
import { StateService } from '@uirouter/angular';
import { Access } from '@main/services/auth/access.service';
import { Utils } from '@main/services/Utils';
import { EstablishmentPatientService } from '@services/establishment-patient/establishment-patient.service';
let HospivilleToolbarComponent = class HospivilleToolbarComponent {
    constructor(_hvSessionService, _medRecService, _fuseTranslationLoaderService, _establishmentPatientService, _translationService, _stateService, _ajsAccess) {
        this._hvSessionService = _hvSessionService;
        this._medRecService = _medRecService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._establishmentPatientService = _establishmentPatientService;
        this._translationService = _translationService;
        this._stateService = _stateService;
        this._ajsAccess = _ajsAccess;
        this.cPatientConsentStatus = CPatientConsentStatus;
        this.cMedRecTransit = CMedRecTransit;
        this.toolbarCollapsed = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english, hvFrench, hvEnglish);
    }
    ngOnInit() {
        this._hvSessionService.getActivePatient.subscribe((patient) => {
            if (Utils.hasOnlyId(patient)) {
                this._establishmentPatientService.getById(Utils.getId(patient)).subscribe((fullPat) => {
                    this.selectedPatient = fullPat;
                    if (fullPat === null || fullPat === void 0 ? void 0 : fullPat.medicationReconciliations) {
                        this._getAllMedRec();
                    }
                }, (err) => {
                    console.error(err);
                });
            }
            else {
                this.selectedPatient = patient;
            }
        }, (err) => {
            console.error(err);
        });
        this._hvSessionService.getActiveMedRec.subscribe((medRec) => {
            this.selectedMedRec = medRec;
        }, (err) => {
            console.error(err);
        });
        this.migrationState = this._ajsAccess.isHVMigrationState();
    }
    /**
     * Get gender character from EGender value
     * @param gender
     */
    getGenderPatient(gender) {
        return gender === undefined ? '-' : gender === 0 ? '♂' : '♀';
    }
    /**
     * Extract [[IMasterPatient]] birth zipcode from birthPlace
     * @param patient
     */
    getPatientBirthZipCode(patient) {
        var _a;
        return (_a = patient === null || patient === void 0 ? void 0 : patient.birthPlaceData) === null || _a === void 0 ? void 0 : _a.code_postal;
    }
    /**
     * Get constType object for patient consent with the given status
     * @param consent
     * @param key
     */
    getConsentConstValue(consent, key) {
        return Object.values(this.cPatientConsentStatus).find((consentObj) => {
            return consentObj.value === consent;
        })[key];
    }
    /**
     * Reset selected patient medRec
     */
    resetSelectedMedRec() {
        this._hvSessionService.setActiveMedRec = null;
    }
    /**
     * Return color regarding medRec active status
     * @param medRec
     */
    isConciliationActive(medRec) {
        if (!medRec) {
            return '#F44335';
        }
        if (medRec.isActive) {
            return '#6EAADB';
        }
        return '#F44335';
    }
    /**
     * Update active MedRec in hvSessionService
     * @param ev
     */
    updateSelectedMedRec(ev) {
        this._hvSessionService.setActiveMedRec = ev.value;
    }
    /**
     * Return formated sentence for patient medRecs select option
     * @param transit
     */
    getMedRecTransitSentence(transit) {
        if (transit === null || transit === undefined) {
            return this._translationService.instant('HOSPIVILLE_TOOLBAR.LABELS.MEDRECS').slice(0, -1);
        }
        return this._translationService.getTranslation(this.cMedRecTransit, transit);
    }
    /**
     * Reset selected patient and medRec and redirect to patients list
     */
    changePatient() {
        this._hvSessionService.resetCookies();
        if (this._stateService.$current.name !== 'hospivilleV2.patients.list') {
            this._stateService.go('hospivilleV2.patients.list');
        }
    }
    /**
     * Open creation patient modal
     */
    createPatient() {
        // this._hvSessionService.resetCookies();
        if (this.migrationState) {
            return;
        }
        /** Remove when v1 toolbar outdated and use line above instead **/
        this._ajsAccess.changePatient(null, null);
        /** **/
        this._stateService.go('hospiville.patient-file', { newPatient: true }, { reload: true });
    }
    /**
     * Get all medrecs of selectedPatient
     */
    _getAllMedRec() {
        if (!this.selectedPatient) {
            return [];
        }
        this._medRecService.findAllByPatient(this.selectedPatient).subscribe((medRecs) => {
            this.selectedPatientMedRecs = medRecs;
        }, (err) => {
            console.error(err);
        });
    }
};
HospivilleToolbarComponent = __decorate([
    Component({
        selector: 'hvx-toolbar',
        template: require('./toolbar.component.html').default,
        styles: [require('./toolbar.component.scss')],
        animations: [
            fuseAnimations,
            trigger('expandToolbar', [
                state('collapsed', style({ height: '50px' })),
                state('expanded', style({ height: '130px' })),
                transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
            ])
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HospivilleSessionService !== "undefined" && HospivilleSessionService) === "function" ? _a : Object, typeof (_b = typeof MedicationReconciliationService !== "undefined" && MedicationReconciliationService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof EstablishmentPatientService !== "undefined" && EstablishmentPatientService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object, typeof (_g = typeof Access !== "undefined" && Access) === "function" ? _g : Object])
], HospivilleToolbarComponent);
export { HospivilleToolbarComponent };
