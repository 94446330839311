var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Injector } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { LoanService } from '@app/services/loan/loan.service';
import { SnackbarService } from '@app/services/snackbar/snackbar.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { loansBillConsts } from '@app/shared/constTypes/loans/loans-const';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ELoanState } from '@mapuilabs/mpl-interfaces';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let BillingSelectSettleComponent = class BillingSelectSettleComponent {
    constructor(_injector, _fuseTranslationLoaderService, _translationService, _loanService, _snackbarService, _fb) {
        this._injector = _injector;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._loanService = _loanService;
        this._snackbarService = _snackbarService;
        this._fb = _fb;
        this.isLoadingResults = true;
        /**
         * The list of [[ILoan]] to bill
         *
         */
        this.billings = [];
        /**
         * The list of [[ILoan]] to bill
         */
        this.billingsGroup = [];
        this.displayedColumns = ['select', 'info'];
        this.stepper1Validity = new FormControl('');
        this.constTypes = loansBillConsts;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        const initialSelection = [];
        const allowMultiSelect = true;
        this.selection = new SelectionModel(allowMultiSelect, initialSelection);
        this.finalSelection = [];
        this.billings = [];
        this.genericTableConfig = {
            clickableRows: true,
            checkboxColumn: {
                active: true,
                multiple: true
            }
        };
        this.columns = [
            {
                label: 'info',
                attribute: 'info'
            }
        ];
    }
    ngOnInit() {
        this._getSettlements();
        this.form = this._fb.group({
            formArray: this._fb.array([])
        });
        if (this.finalSelection.length > 0) {
            this.stepper1Validity.reset();
        }
        else {
            this.stepper1Validity.setErrors({ required: true });
        }
    }
    isDisabled(billing) {
        if (this.finalSelection.length > 0) {
            if (this.finalSelection[0].lender._id !== billing[0].lender._id) {
                return true;
            }
        }
        return false;
    }
    submitForm() {
        const date = new Date();
        this.finalSelection.forEach((selection) => (selection.billingDateBorrower = date));
        this.update(this.finalSelection);
    }
    /**
     * Update all the [[ILoan]] of the [[IInvoice]]
     * @param loans The array of [[ILoan]] to update
     */
    update(loans) {
        const requests = [];
        for (const loan of loans) {
            loan.state = ELoanState.Settle;
            requests.push(this._loanService.save(loan));
        }
        forkJoin(requests).subscribe({
            next: () => {
                this.finalSelection = [];
                this.form.reset();
                this.billings = [];
                this.billingsGroup = [];
                this.stepper1Validity.setErrors({ required: true });
                this._getSettlements();
                this._snackbarService.open(this._translationService.instant('BILLING.SUCCESS.SUCCESS_SETTLEMENT'));
            },
            error: (error) => {
                this._snackbarService.openError(error);
            }
        });
    }
    /**
     * Format all the unitPrice of the [[ILoan]]
     * @param loans The list of [[ILoan]] to format
     * @return {Array<ILoan>}
     * @private
     */
    _formatLoans(loans) {
        for (const l of loans) {
            l.unitPrice = Number(l.unitPrice);
        }
        return loans;
    }
    _getSettlements() {
        this._loanService.getSettlementsFrom().subscribe({
            next: (loans) => {
                if (loans) {
                    loans = this._formatLoans(loans);
                    const group = _.map(_.groupBy(loans, (loan) => {
                        if (!loan.lender) {
                            return;
                        }
                        else {
                            return loan.lender.name;
                        }
                    }), (v) => {
                        return v;
                    });
                    this.billings.push(...loans);
                    this.billingsGroup.push(...group);
                }
                this.isLoadingResults = false;
            },
            error: (error) => {
                this.isLoadingResults = false;
                console.error(error);
            }
        });
    }
    /**
     * Select or deselect the given [[ILoan]]
     * @param loan The [[ILoan]] to select or deselect
     * @param event The DOM event
     */
    toggleSelectLoan(loan) {
        var _a;
        this.selection.toggle(loan);
        const formArray = this.form.get('formArray');
        if (~this.finalSelection.indexOf(loan)) {
            formArray.removeAt(this.finalSelection.indexOf(loan));
            this.finalSelection.splice(this.finalSelection.indexOf(loan), 1);
        }
        else {
            this.finalSelection.push(loan);
            const values = formArray === null || formArray === void 0 ? void 0 : formArray.value.map((value) => {
                return value._id;
            });
            for (const selection of this.finalSelection) {
                if (!(values.length && values.includes(selection._id))) {
                    formArray.push(this._fb.group({
                        _id: selection._id,
                        unitPrice: {
                            value: !Number.isNaN(selection === null || selection === void 0 ? void 0 : selection.unitPrice.valueOf())
                                ? selection === null || selection === void 0 ? void 0 : selection.unitPrice.valueOf()
                                : undefined,
                            disabled: true
                        },
                        tva: { value: selection.tva, disabled: true },
                        charge: { value: selection.charge, disabled: true },
                        chargePercent: { value: selection.chargePercent, disabled: true },
                        comment: {
                            value: ((_a = selection.invoice) === null || _a === void 0 ? void 0 : _a.comment) ? selection.invoice.comment : '',
                            disabled: true
                        }
                    }));
                }
            }
            this.form.registerControl('formArray', formArray);
        }
        if (this.finalSelection.length > 0) {
            this.stepper1Validity.reset();
        }
        else {
            this.stepper1Validity.setErrors({ required: true });
        }
    }
};
BillingSelectSettleComponent = __decorate([
    Component({
        selector: 'mpx-billing-select-settle',
        template: require('./billing-select-settle.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof LoanService !== "undefined" && LoanService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object, typeof (_f = typeof FormBuilder !== "undefined" && FormBuilder) === "function" ? _f : Object])
], BillingSelectSettleComponent);
export { BillingSelectSettleComponent };
