var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { BehaviorSubject } from 'rxjs';
import { findIndex } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
let MailsService = class MailsService {
    constructor(_http) {
        this._http = _http;
        this.onMailsChanged = new BehaviorSubject([]);
        this.onSelectedMailChanged = new BehaviorSubject(null);
    }
    updateMails(mails) {
        this.mails = mails;
        this.updateSelectedMail(null);
        this.onMailsChanged.next(this.mails);
    }
    updateSelectedMail(mail) {
        if (!mail) {
            this.selectedMail = null;
        }
        else if (this.mails.length) {
            const idx = findIndex(this.mails, (m) => {
                return m._id == mail._id;
            });
            if (idx != -1) {
                this.selectedMail = this.mails[idx];
            }
        }
        this.onSelectedMailChanged.next(this.selectedMail);
    }
};
MailsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object])
], MailsService);
export { MailsService };
