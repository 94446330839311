var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { NotificationService } from '@services/notification/notification.service';
import { FuseConfigService } from '@fuse/services/config.service';
let NotificationPanelComponent = class NotificationPanelComponent {
    constructor(_fuseTranslationLoaderService, _notificationService, _fuseConfigService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._notificationService = _notificationService;
        this._fuseConfigService = _fuseConfigService;
        this.notifications = [];
        this.allLoaded = false;
        // Theme
        this._theme = 'theme-blue';
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    get theme() {
        return this.fuseConfig.scheme === 'dark' ? `${this._theme}-dark` : this._theme;
    }
    ngOnInit() {
        this._notificationService.getCount().then(() => {
            this.notifications = this._notificationService.notifications;
        });
        // Subscribe to the config changes
        this._fuseConfigService.config.subscribe((config) => {
            this.fuseConfig = config;
        });
        this._notificationService.upgradedService.opened.subscribe(() => this.onOpened());
        this.listenNotifications();
    }
    onOpened() {
        this.loadMore();
        this._notificationService.getCount();
        this._notificationService.markAllAs('seen');
    }
    markAllAsRead() {
        this._notificationService.markAllAs('read');
    }
    loadMore() {
        if (!this.allLoaded) {
            this._notificationService.loadMore().then(() => {
                this.notifications = this._notificationService.notifications;
                this.allLoaded = this._notificationService.allLoaded;
            });
        }
    }
    onClickNotification(notification) {
        this._notificationService.open(notification);
    }
    listenNotifications() {
        this._notificationService.listenNotifications().subscribe(() => {
            this.notifications = this._notificationService.notifications;
        });
    }
};
NotificationPanelComponent = __decorate([
    Component({
        selector: 'mpx-notification-panel',
        template: require('./notification-panel.component.html').default,
        styles: [require('./notification-panel.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof NotificationService !== "undefined" && NotificationService) === "function" ? _b : Object, typeof (_c = typeof FuseConfigService !== "undefined" && FuseConfigService) === "function" ? _c : Object])
], NotificationPanelComponent);
export { NotificationPanelComponent };
