var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StateService } from '@uirouter/angular';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { ActivityASchema, EActivityStatus, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CActivityOrigin } from '@shared/constTypes/activity/activity-origin.const';
import { Utils } from '@main/services/Utils';
import { DialogService } from '@services/dialog/dialog.service';
import { ActivitySourceService } from '@services/hospiville/activities/activities-source.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { UrlService } from '@services/url/url.service';
import { ImportFilesModalComponent } from '@app/hospiville/activities/activity-details/activity-details-dest/import-files-modal/import-files-modal.component';
let FilesTabSourceComponent = class FilesTabSourceComponent {
    constructor(_activityService, _stateService, _fuseTranslationLoaderService, _translationService, _snackBarService, _urlService, _matDialog, _dialogService) {
        this._activityService = _activityService;
        this._stateService = _stateService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._urlService = _urlService;
        this._matDialog = _matDialog;
        this._dialogService = _dialogService;
        this.cActivityOrigin = CActivityOrigin;
        /**
         * Emits when the activity has been modified.
         */
        this.onActivityChanged = new EventEmitter();
        /**
         * Emits when the tooltip on the save button of the parent component should be toggled.
         */
        this.showCanceledActivityTooltip = new EventEmitter();
        this.isFinished = false;
        this.isCanceled = false;
        this.selectFile = (file) => {
            this.selectedFile = file;
            this._urlService.addParams({ fileId: file.file._id });
        };
        this.toggleHideFile = () => {
            const fileIndex = this.activity.files.findIndex((file) => file.file._id === this.selectedFile.file._id);
            this.activity.files[fileIndex].isHidden = !this.selectedFile.isHidden;
            this._activityService
                .save(this.activity)
                .pipe(take(1))
                .subscribe((updatedActivity) => {
                if (updatedActivity) {
                    this.onActivityChanged.emit();
                    if (this.selectedFile.isHidden) {
                        this._snackBarService.open(this._translationService.instant('SNACKBAR.HIDE_FILE'));
                    }
                    this.selectedFile = null;
                }
            }, this._snackBarService.openError);
        };
        this.addToDbs = () => {
            this._activityService.updatePatient(Utils.getId(this.activity.patient), Utils.getId(this.activity.medicationReconciliation));
            this._stateService.go('hospivilleV2.activities.source.details.file-to-dbs');
        };
        this.addToPatientFiles = () => {
            if (!this.selectedFile) {
                return;
            }
            else {
                this.addingToPatientFile = true;
                this._activityService
                    .addDocToPatientFile(Utils.getId(this.activity.patient), this.selectedFile.file)
                    .subscribe((res) => {
                    if (res === EHTTPStatus.NotFound) {
                        this._snackBarService.open(this._translationService.instant('ALREADY_ADDED'), '', {
                            duration: 2500
                        });
                        this.addingToPatientFile = false;
                        return;
                    }
                    this._snackBarService.open(this._translationService.instant('MOVE_TO_PATIENT_FILES'), '', {
                        duration: 2500
                    });
                    this.addingToPatientFile = false;
                    return;
                }, this._snackBarService.openError);
            }
        };
        /**
         * Opens a modal to import multiple files about the activity (same as on Information/Actions tab).
         */
        this.openImportFilesModal = () => {
            this._matDialog
                .open(ImportFilesModalComponent, { disableClose: false, maxWidth: '750px' })
                .afterClosed()
                .pipe(take(1))
                .subscribe((files) => {
                if (files) {
                    this._activityService
                        .addFiles(this.activity, files, this.isFinished ? EActivityStatus.EndedWithFiles : EActivityStatus.Files)
                        .pipe(take(1))
                        .subscribe((updatedActivity) => {
                        if (updatedActivity && updatedActivity._id === this.activity._id) {
                            this.onActivityChanged.emit(); // Refresh this.activity @Input()
                            this._snackBarService.open(this._translationService.instant('ACTIVITY_DETAILS.SNACKBAR.FILES_ADDED'));
                        }
                        else {
                            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
        /**
         * Delete this file from File collection and in the Activity object on db
         */
        this.delete = () => {
            const dialogConfig = {
                title: this._translationService.instant('CONFIRM_DIALOG.DELETE_TITLE'),
                message: this._translationService.instant('CONFIRM_DIALOG.DELETE_MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            this._dialogService
                .openConfirmDialog(dialogConfig)
                .pipe(take(1))
                .subscribe((data) => {
                if (data) {
                    this._activityService
                        .deleteFile(this.selectedFile.file._id)
                        .pipe(take(1))
                        .subscribe((updatedActivity) => {
                        if (updatedActivity) {
                            this.selectedFile = null;
                            this.onActivityChanged.emit(); // Trigger refresh this.activity @Input()
                            if (updatedActivity.files.length === 0) {
                                return this._snackBarService.open(this._translationService.instant('SNACKBAR.NO_MORE_FILES'));
                            }
                            this._snackBarService.open(this._translationService.instant(`SNACKBAR.DELETED`));
                        }
                        else {
                            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.addingToPatientFile = false;
    }
    ngOnInit() {
        this._showFileFromUrl();
    }
    ngOnChanges(changes) {
        this.isFinished =
            this.activity.status === EActivityStatus.Canceled ||
                this.activity.status === EActivityStatus.Ended ||
                this.activity.status === EActivityStatus.MedRec;
        this.isCanceled = this.activity.status === EActivityStatus.Canceled;
        if (changes.currentTabIndex) {
            this._showFileFromUrl();
            if (changes.currentTabIndex.previousValue === 2) {
                this._stateService.go(
                // Remove URL parameters if user leaves Files tab
                'hospivilleV2.activities.source.details', { id: this.activity._id }, { inherit: false });
            }
        }
    }
    _showFileFromUrl() {
        this.fileId = this._urlService.getParam('fileId');
        if (this.fileId) {
            this.selectedFile = this.activity.files.find((activityFile) => activityFile.file._id === this.fileId);
        }
    }
    toggleEndCancelTooltip() {
        if (this.isFinished) {
            switch (this.activity.status) {
                case EActivityStatus.MedRec:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.GOT_MEDREC');
                case EActivityStatus.Ended:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.ALREADY_ENDED');
                case EActivityStatus.Canceled:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.ALREADY_CANCELED');
            }
        }
        return '';
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], FilesTabSourceComponent.prototype, "currentTabIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_j = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _j : Object)
], FilesTabSourceComponent.prototype, "activity", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FilesTabSourceComponent.prototype, "migrationState", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_k = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _k : Object)
], FilesTabSourceComponent.prototype, "onActivityChanged", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_l = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _l : Object)
], FilesTabSourceComponent.prototype, "showCanceledActivityTooltip", void 0);
FilesTabSourceComponent = __decorate([
    Component({
        selector: 'hvx-files-tab-source',
        template: require('./files-tab.component.html').default,
        styles: [require('./files-tab.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ActivitySourceService !== "undefined" && ActivitySourceService) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object, typeof (_f = typeof UrlService !== "undefined" && UrlService) === "function" ? _f : Object, typeof (_g = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _g : Object, typeof (_h = typeof DialogService !== "undefined" && DialogService) === "function" ? _h : Object])
], FilesTabSourceComponent);
export { FilesTabSourceComponent };
