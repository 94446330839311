export const locale = {
    lang: 'fr',
    data: {
        sidebar: {
            TITLE: 'Filters',
            filters: {
                ROLES: 'Rôles',
                ACCOUNT: 'Compte',
                account: {
                    LOCKED: 'Verrouillés',
                    BLOCKED: 'Bloqués',
                    NO_ESTABLISHMENT: 'Sans établissement',
                    DELETED: 'Comptes supprimés'
                }
            }
        }
    }
};
