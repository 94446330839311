var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DOCUMENT_CLICK } from '@core/tokens/events/document-click.token';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EHsUserTypes, HsPublicationCommentASchema } from '@mapuilabs/mpl-interfaces';
import { IdentityService } from '@services/identity/identity.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
let HsPublicationDetailCommentComponent = class HsPublicationDetailCommentComponent {
    constructor(_documentClick$, _changeDetectorRef, _fuseTranslationLoaderService, _identityService, _snackbarService) {
        this._documentClick$ = _documentClick$;
        this._changeDetectorRef = _changeDetectorRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._identityService = _identityService;
        this._snackbarService = _snackbarService;
        this.editComment = new EventEmitter();
        this.deleteComment = new EventEmitter();
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        // Init flags
        this.editMode = false;
        this.isCreator = false;
        this.isEdited = false;
    }
    setFormControls() {
        this.form = new UntypedFormGroup({});
        if (this.isGAViewType) {
            this.form.setControl('shareComment', new UntypedFormControl(this.comment.isShared, Validators.required));
            this.form.setControl('comment', new UntypedFormControl('', Validators.required));
            this.form.setValue({
                shareComment: this.comment.isShared,
                comment: this.comment.text
            });
        }
        else {
            this.form.setControl('comment', new UntypedFormControl('', Validators.required));
            this.form.setValue({ comment: this.comment.text });
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Update flags
        this.isCreator = Utils.compareIds(this._identityService.user, this.comment.author);
        this.author = this.comment.author.fullName;
        this.isEdited = this.comment.creationDate !== this.comment.updateDate;
        if (this.comment.authorType === EHsUserTypes.PUI) {
            this.establishment = this.comment.author.hospital.name;
        }
        else if (this.comment.authorType === EHsUserTypes.GA) {
            this.establishment = this.comment.authorGroup.name;
            this.commentIsShared = this.isGAViewType && this.comment.isShared;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open edit form by setting editMode to true to trigger #commentFormTemplate.
     * Method must wait next document click event before showing edit form.
     * Reason: Click event from document is fired with delay and trigger clickOutsideDirective's clickOutside event causing the form to be closed.
     * Recommendation : Do not use preventDefault instead otherwise form will not be closed when click event is fired on other edit menu button
     */
    openEditMode() {
        // Wait next click event
        this._documentClick$.pipe(take(1)).subscribe(() => {
            // Open edit mode
            this.editMode = true;
            // Update formControl value
            this.setFormControls();
            // Manually update
            this._changeDetectorRef.detectChanges();
        });
    }
    /**
     * Close edit form by setting editMode to false to trigger #commentFormTemplate.
     */
    closeEditMode() {
        // Close edit mode
        this.editMode = false;
        // Reset formControl
        this.form.reset();
    }
    /**
     * Submit comment and reset formControl.
     */
    submitComment() {
        // Close edit mode
        this.editMode = false;
        // Fire onEditComment to update commment
        let comment = cloneDeep(this.comment);
        comment = Object.assign(comment, { text: this.form.value.comment, isShared: this.form.value.shareComment });
        this.editComment.emit(comment);
        // Reset formControl
        this.form.reset();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof HsPublicationCommentASchema !== "undefined" && HsPublicationCommentASchema) === "function" ? _f : Object)
], HsPublicationDetailCommentComponent.prototype, "comment", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], HsPublicationDetailCommentComponent.prototype, "isGAViewType", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], HsPublicationDetailCommentComponent.prototype, "editComment", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_h = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _h : Object)
], HsPublicationDetailCommentComponent.prototype, "deleteComment", void 0);
HsPublicationDetailCommentComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-comment',
        template: require('./hs-publication-detail-comment.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(DOCUMENT_CLICK)),
    __metadata("design:paramtypes", [typeof (_a = typeof Observable !== "undefined" && Observable) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], HsPublicationDetailCommentComponent);
export { HsPublicationDetailCommentComponent };
