var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './../../../hospiville/i18n/fr';
import { locale as english } from './../../../hospiville/i18n/en';
let ExSelectDosageTypeComponent = class ExSelectDosageTypeComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.pageHeaderConfig = {
            title: 'Select Dosage Type Component',
            icon: 'extension '
        };
        this.formControl = new UntypedFormControl();
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() { }
};
ExSelectDosageTypeComponent = __decorate([
    Component({
        selector: 'ex-select-dosage-type',
        template: require('./ex-select-dosage-type.component.html').default,
        styles: [require('./ex-select-dosage-type.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], ExSelectDosageTypeComponent);
export { ExSelectDosageTypeComponent };
