export class MpModalComposeMailComponent {
    constructor() {
        this.controller = 'MpModalComposeMailController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-compose-mail.html').default;
        this.bindings = {
            recipientsList: '<',
            selectedRecipient: '<',
            destActivity: '<',
            mail: '<',
            hidden: '<',
            disable: '<',
            secure: '<',
            bodyTemplate: '<',
            pathway: '<',
            data: '<'
        };
    }
}
