var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component } from '@angular/core';
import { StatusService } from '@app/services/status/status.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from '../../../i18n/en';
import { locale as french } from '../../../i18n/fr';
import { LoanService } from '@app/services/loan/loan.service';
import { pharmacistRsp } from '@shared/constTypes/pharmacists/pharmacist-rsp.const';
let MemberBillingDetailComponent = class MemberBillingDetailComponent {
    constructor(sts, _fuseTranslationLoaderService, _loanService) {
        this.sts = sts;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._loanService = _loanService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.constTypes = pharmacistRsp;
    }
    ngOnInit() {
        this.member = this._loanService.archiveMember;
        this._memberActivities();
        this.pageHeaderConfig = {
            title: this.member.fullName
        };
    }
    _memberActivities() {
        this.memberActivites = [];
        for (const key in this.constTypes) {
            if (this.member[key]) {
                this.memberActivites.push(this.constTypes[key].translate);
            }
        }
    }
};
MemberBillingDetailComponent = __decorate([
    Component({
        selector: 'mpx-billing-detail',
        template: require('./member-detail.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StatusService !== "undefined" && StatusService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof LoanService !== "undefined" && LoanService) === "function" ? _c : Object])
], MemberBillingDetailComponent);
export { MemberBillingDetailComponent };
