var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { RolesService } from '@app/services/role/roles.service';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { Transition } from '@uirouter/angularjs';
import { ListRolesComponent } from './list-roles/list-roles.component';
import { RoleDetailComponent } from './list-roles/role-detail/role-detail.component';
import { lastValueFrom } from 'rxjs';
const states = [
    {
        name: 'mapui.adm.roles.project',
        url: '/list?:project',
        views: {
            'content@mapui': {
                component: ListRolesComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            project: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.roles.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: RoleDetailComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'role',
                deps: [Transition, RolesService],
                resolveFn: (trans, rolesService) => lastValueFrom(rolesService.getById(trans.params().id))
            }
        ]
    }
];
let RolesRoutingModule = class RolesRoutingModule {
};
RolesRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states })],
        exports: [UIRouterUpgradeModule]
    })
], RolesRoutingModule);
export { RolesRoutingModule };
