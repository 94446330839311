export const locale = {
    lang: 'en',
    data: {
        admHospitalDetail: {
            EDIT_TITLE: `Hospital - `,
            NEW_TITLE: `New hospital`,
            TABS: {
                INFORMATION: `Information`,
                OTHER: `Other`,
                PERMISSIONS: `Actions / Permissions`,
                MEMBERS: `Members`
            },
            INFORMATION: {
                NAME: `Hospital name`,
                ADDRESS: `Address`,
                NUMBER: `Number`,
                WAY: `Way`,
                ZIP: `Zip Code`,
                CITY: `City`,
                COUNTRY: `Country`,
                CONTACT: `Contact information`,
                PHONE_STD: `Standard phone`,
                PHONE_PHARMA: `Pharmacy phone`,
                FAX: `Fax`,
                WEBSITE: `Website`,
                CAPACITY: `Capacity`,
                BED: {
                    MCO: `MCO beds`,
                    SSR: `SSR beds`,
                    PSY: `PSY beds`,
                    EPHAD: `EPHAD beds`
                },
                LOCATION: `Location`,
                REGION: `Region`,
                LATITUDE: `Latitude`,
                LONGITUDE: `Longitude`,
                OTHER: `Other information`,
                FINESS: `FINESS`,
                FINESSJUR: `Juridical FINESS`,
                PURCHASE_GROUP: 'Purchasing groups',
                NON_SUBSCRIBED_GROUP: 'Non subscribed groups',
                PURCHASE_GRP_NAME: 'Group name',
                APPLY_FINESS: `Pre-fill`,
                LOGO: `Hospital Logo`
            },
            ACTIONS: {
                ROLE_MAPUI: `MaPUI role`,
                ROLE_HOSPIVILLE: `Hospiville role`,
                ROLE_HOSPISTOCK: `Hospistock role`,
                ROLE_WORKSPACE: `Workspace role`,
                REGISTERED: `Registered`,
                ACTIVE: `Active`,
                HOSPIVILLE_MEMBER_SINCE: `Hospiville member since`,
                HOSPIVILLE_MEMBER_TO: `Hospiville member until`,
                MEMBER_SINCE: `MaPUI member since`,
                MEMBER_TO: `MaPUI member until`,
                TRIAL_END: `Trial end`,
                ADD_DAYS: `Add 30 days`,
                REMOVE_DAYS: `Remove 30 days`,
                MAX_DATA: `Available space (GB)`,
                SECURE_MAIL: {
                    INPUT: `Secure email address`,
                    BUTTON: `Modify email address`
                },
                LOG_AS: {
                    LONG: `Log in this hospital`,
                    SHORT: `Log in`,
                    CONFIRM_DIALOG: {
                        TITLE: 'Log as...',
                        MESSAGE: `Do you want to log in {{establishment}} ?`
                    }
                },
                DELETE: {
                    LONG: `Delete the hospital`,
                    SHORT: `Delete`,
                    CONFIRM_DIALOG: {
                        TITLE: `Delete this hospital`,
                        MESSAGE: `Do you really want to delete hospital {{establishment}} ?`
                    }
                },
                SNACK_DELETE: `Hospital has been deleted.`,
                VALID: `Valid`,
                ERROR: `Error`
            },
            MEMBERS: {
                NAME: `Name`,
                EMAIL: `Email`,
                LAST_CONNECT: `Last connection`,
                ROLES: `Roles`,
                EMPTY: `This hospital has no members.`
            },
            EDIT_OK: `Hospital has been edited!`,
            CREATE_OK: `Hospital has been created!`
        }
    }
};
