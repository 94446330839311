export class HvModalConsentComponent {
    constructor() {
        this.controller = 'HvModalConsentController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-consent.html').default;
        this.bindings = {
            consent: '<',
        };
    }
}
