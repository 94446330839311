var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from '../../../../services/dialog/dialog.service';
import { TranslationService } from '../../../../services/translation/translation.service';
import { SnackbarService } from '../../../..//services/snackbar/snackbar.service';
import { AdminGroupsService } from '../../../../services/admin/group/admin-groups.service';
import { Utils } from '@main/services/Utils';
import { Group } from '@mapuilabs/mpl-interfaces';
let MembersTabComponent = class MembersTabComponent {
    constructor(_dialogService, _translationService, _snackBarService, _adminGroupService) {
        this._dialogService = _dialogService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._adminGroupService = _adminGroupService;
        /**
         * Form control used by the search member selector.
         */
        this.memberForm = new UntypedFormControl();
    }
    ngOnChanges(changes) {
        if (changes.group) {
            this.dataSource = new MatTableDataSource(this.group.members);
        }
    }
    removeMember(member) {
        const dialogConfig = {
            title: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_MEMBER.TITLE'),
            message: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_MEMBER.MESSAGE'),
            confirmButton: this._translationService.instant('COMMONS.DELETE')
        };
        this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
            if (!data) {
                return;
            }
            this._adminGroupService.removeGroupMember(this.group, member).subscribe(() => {
                Utils.removeElemFromArray(this.group.members, (value) => value._id === member._id, 1);
                this.dataSource = new MatTableDataSource(this.group.members); // refresh UI
                this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.DELETE_USER_OK'));
            }, () => {
                this._snackBarService.openDefaultError();
            });
        });
    }
    addMember() {
        this._adminGroupService.addGroupMember(this.group, this.selectedMember).subscribe(() => {
            if (!this.group.members || !this.group.members.length) {
                this.group.members = [];
            }
            this.group.members.push(this.selectedMember);
            this.closeMenu(); // close menu + reset search form
            this.dataSource = new MatTableDataSource(this.group.members); // refresh UI
            this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.ADD_USER_OK'));
        }, () => {
            this._snackBarService.openDefaultError();
        });
    }
    closeMenu() {
        this.menu.closeMenu();
        this.selectedMember = null;
        this.memberForm.reset();
    }
    onSelectNewMember(futureMember) {
        if (futureMember) {
            this.selectedMember = futureMember;
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Group !== "undefined" && Group) === "function" ? _e : Object)
], MembersTabComponent.prototype, "group", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MembersTabComponent.prototype, "animationDone", void 0);
__decorate([
    ViewChild('selectMemberMenuTrigger', { static: false }),
    __metadata("design:type", typeof (_f = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _f : Object)
], MembersTabComponent.prototype, "menu", void 0);
MembersTabComponent = __decorate([
    Component({
        selector: 'mpx-members-tab',
        template: require('./members-tab.component.html').default,
        styles: [require('./members-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof AdminGroupsService !== "undefined" && AdminGroupsService) === "function" ? _d : Object])
], MembersTabComponent);
export { MembersTabComponent };
