export const locale = {
    lang: 'fr',
    data: {
        LIST: {
            TITLE: `ARS`,
            //SEARCH:,
            TABLE: {
                NAME: `Nom`,
                ACCOUNTS: `Comptes`,
                FRENCH_DEPARTMENTS: `Départements`
            }
        }
    }
};
