export class MpTableProgressDirective {
    /** @ngInject */
    constructor() {
        this.compile = (tElement) => {
            /**
             * Post Link
             */
            return (scope, iElement, iAttrs, ctrls) => {
                const mpTableCtrl = ctrls[0];
                scope.vm.columns = mpTableCtrl.columns;
                scope.vm.loading = mpTableCtrl.loading;
            };
        };
        this.restrict = 'A';
        this.require = ['^mpTable'];
        this.controller = 'MpTableProgressController';
        this.controllerAs = 'vm';
        this.template = require('./templates/mp-table-progress.html').default;
        this.scope = {};
    }
}
MpTableProgressDirective.factory = () => {
    const directive = () => new MpTableProgressDirective();
    directive.$inject = [];
    return directive;
};
