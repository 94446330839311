var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@uirouter/angular';
import { MatTabGroup } from '@angular/material/tabs';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MailsService } from '../../../mails.service';
import { FileService } from '../../../../../../services/file/file.service';
import { TranslationService } from '../../../../../../services/translation/translation.service';
import { UrlService } from '../../../../../../services/url/url.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { CMailPriority } from '../../../../../../shared/constTypes/mail/mail-priority.const';
let ActivityMailsDetailsComponent = class ActivityMailsDetailsComponent {
    constructor(_mailService, _fuseTranslationLoaderService, _fileService, _domSanitizer, _translationService, _stateService, _urlService) {
        this._mailService = _mailService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fileService = _fileService;
        this._domSanitizer = _domSanitizer;
        this._translationService = _translationService;
        this._stateService = _stateService;
        this._urlService = _urlService;
        this.cMailPriority = CMailPriority;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this._mailService.onSelectedMailChanged.subscribe((mail) => {
            this.mail = mail;
            this.content = null;
            if (mail) {
                this._fileService.readStoredFileContent(mail.html).subscribe((data) => {
                    this.content = this._domSanitizer.bypassSecurityTrustHtml(data);
                });
            }
        });
    }
    getIcon(file) {
        return this._fileService.getIcon(file);
    }
    getPriorityTooltip() {
        var _a;
        return this._translationService.getTranslation(this.cMailPriority, (_a = this.mail) === null || _a === void 0 ? void 0 : _a.priority);
    }
    seeFile(fileId) {
        this._urlService.addParams({ fileId: fileId });
        this.tabGroup.selectedIndex = 2; // Go to Files tab
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof MatTabGroup !== "undefined" && MatTabGroup) === "function" ? _h : Object)
], ActivityMailsDetailsComponent.prototype, "tabGroup", void 0);
ActivityMailsDetailsComponent = __decorate([
    Component({
        selector: 'activity-mails-details',
        template: require('./activity-mails-details.component.html').default,
        styles: [require('./activity-mails-details.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MailsService !== "undefined" && MailsService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof FileService !== "undefined" && FileService) === "function" ? _c : Object, typeof (_d = typeof DomSanitizer !== "undefined" && DomSanitizer) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object, typeof (_g = typeof UrlService !== "undefined" && UrlService) === "function" ? _g : Object])
], ActivityMailsDetailsComponent);
export { ActivityMailsDetailsComponent };
