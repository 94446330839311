export const locale = {
    lang: 'fr',
    data: {
        TITLE: `Importer des fichiers`,
        SUBTITLE: `Fichiers à ajouter`,
        TIP: `Nous vous conseillons d'importer des fichiers au format PDF afin de faciliter l'ajout des prescriptions du patient au bilan médicamenteux.`,
        BUTTONS: {
            ADD_FILES: `Ajouter des fichiers`,
            SEND_FILES: `Envoyer les fichiers`
        }
    }
};
