var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { Member } from '@mapuilabs/mpl-interfaces';
import { LocationService } from '../../../../services/location/location.service';
import { CGender } from '../../../../shared/constTypes/member/member-gender.const';
import { TranslationService } from '../../../../services/translation/translation.service';
let IdentityTabComponent = class IdentityTabComponent {
    constructor(_locationService, _translationService) {
        this._locationService = _locationService;
        this._translationService = _translationService;
        this.cGender = CGender;
        this._setFormControls = (member) => {
            //this.form.setControl('email', new FormControl(member.email, [Validators.required, Validators.email]));
            this.form.setControl('userName', new UntypedFormControl(member.userName));
            this.form.setControl('firstName', new UntypedFormControl(member.firstName));
            this.form.setControl('familyName', new UntypedFormControl(member.familyName));
            this.form.setControl('gender', new UntypedFormControl(member.gender));
            this.form.setControl('rpps', new UntypedFormControl(member.RPPS));
            this.form.setControl('phone', new UntypedFormControl(member.phone));
        };
        if (this.member && this.form) {
            this._setFormControls(this.member);
        }
    }
    ngOnInit() { }
    ngOnChanges(changes) {
        if (changes.member) {
            this._setFormControls(this.member);
        }
    }
    updateMember(member) {
        return new Observable((observer) => {
            //member.email = this.form.value.email;
            member.userName = this.form.value.userName;
            member.firstName = this.form.value.firstName;
            member.familyName = this.form.value.familyName;
            member.gender = this.form.value.gender;
            member.phone = this.form.value.phone;
            member.RPPS = this.form.value.rpps;
            observer.next();
            observer.complete();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _c : Object)
], IdentityTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof Member !== "undefined" && Member) === "function" ? _d : Object)
], IdentityTabComponent.prototype, "member", void 0);
IdentityTabComponent = __decorate([
    Component({
        selector: 'mpx-identity-tab',
        template: require('./identity-tab.component.html').default,
        styles: [require('./identity-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof LocationService !== "undefined" && LocationService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], IdentityTabComponent);
export { IdentityTabComponent };
