var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHTTPStatus, SecureEmailAddressASchema } from '@mapuilabs/mpl-interfaces';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { SecureEmailAddressService } from '../../../services/admin/secure-email-address/secure-email-address.service';
import { AdminMemberService } from '../../../services/admin/member/admin-member.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { DialogService } from '../../../services/dialog/dialog.service';
let SecureEmailAddressDetailsComponent = class SecureEmailAddressDetailsComponent {
    constructor(_stateService, _snackBarService, _secureEmailAddressService, _dialogService, _fuseTranslationLoaderService, _memberService, _translationService) {
        this._stateService = _stateService;
        this._snackBarService = _snackBarService;
        this._secureEmailAddressService = _secureEmailAddressService;
        this._dialogService = _dialogService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._memberService = _memberService;
        this._translationService = _translationService;
        this.domain = '@hospiville.mssante.fr';
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('DETAILS_EMAIL.NEW_TITLE'),
            svgIcon: 'email-lock'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        if (this._stateService.params.id === 'new') {
            this.pageType = 'new';
            this.secureEmailAddress = new SecureEmailAddressASchema({});
        }
        else {
            this.pageType = 'edit';
        }
        this._createForm(this.secureEmailAddress);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    _getMembers(hospital) {
        this._memberService.getMembersByHospital(hospital._id.toString()).subscribe((members) => {
            this.hospitalMembers = members;
        }, (error) => this._snackBarService.openErrorFromStatus(error));
    }
    _resetManagerInputs() {
        this.detailForm.controls.managerFirstName.reset();
        this.detailForm.controls.managerLastName.reset();
        this.detailForm.controls.managerEmail.reset();
        this.detailForm.controls.managerPhone.reset();
    }
    _updateSecureEmailAddress() {
        this.secureEmailAddress.hospital = this.detailForm.value.hospital;
        this.secureEmailAddress.secureEmail = this.detailForm.value.secureEmail + this.domain;
        this.secureEmailAddress.managerFirstName = this.detailForm.value.managerFirstName;
        this.secureEmailAddress.managerLastName = this.detailForm.value.managerLastName;
        this.secureEmailAddress.managerEmail = this.detailForm.value.managerEmail;
        this.secureEmailAddress.managerPhone = this.detailForm.value.managerPhone;
    }
    _createForm(secureEmailAddress) {
        this.detailForm = new UntypedFormGroup({
            hospital: new UntypedFormControl(secureEmailAddress.hospital, [Validators.required]),
            secureEmail: new UntypedFormControl(this.emailLocalePart, [Validators.required]),
            managerFirstName: new UntypedFormControl(secureEmailAddress.managerFirstName, [Validators.required]),
            managerLastName: new UntypedFormControl(secureEmailAddress.managerLastName, [Validators.required]),
            managerEmail: new UntypedFormControl(secureEmailAddress.managerEmail, [Validators.required]),
            managerPhone: new UntypedFormControl(secureEmailAddress.managerPhone)
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    get emailLocalePart() {
        var _a, _b;
        const domainIndex = (_a = this.secureEmailAddress.secureEmail) === null || _a === void 0 ? void 0 : _a.indexOf(this.domain);
        return (_b = this.secureEmailAddress.secureEmail) === null || _b === void 0 ? void 0 : _b.substring(domainIndex, 0);
    }
    fillManagerInputs(event) {
        var _a;
        if ((event === null || event === void 0 ? void 0 : event.isUserInput) && ((_a = this.hospitalMembers) === null || _a === void 0 ? void 0 : _a.length)) {
            const chosenMember = this.hospitalMembers.find((member) => member._id === event.source.value);
            this.detailForm.controls.managerFirstName.setValue(chosenMember === null || chosenMember === void 0 ? void 0 : chosenMember.firstName);
            this.detailForm.controls.managerLastName.setValue(chosenMember === null || chosenMember === void 0 ? void 0 : chosenMember.familyName);
            this.detailForm.controls.managerEmail.setValue(chosenMember === null || chosenMember === void 0 ? void 0 : chosenMember.email);
            this.detailForm.controls.managerPhone.setValue(chosenMember === null || chosenMember === void 0 ? void 0 : chosenMember.phone);
        }
    }
    onOptionSelected(hospital) {
        var _a;
        if (!hospital) {
            return;
        }
        this._getMembers(hospital);
        this._resetManagerInputs();
        // Pre-fill secure mail input
        this.detailForm.controls.secureEmail.setValue((_a = hospital.normalizedName) === null || _a === void 0 ? void 0 : _a.toString());
    }
    addSecureEmailAddress() {
        this._updateSecureEmailAddress();
        this._secureEmailAddressService.add(this.secureEmailAddress).subscribe((newSecureEmail) => {
            if (!(newSecureEmail === null || newSecureEmail === void 0 ? void 0 : newSecureEmail._id)) {
                return this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._snackBarService.open(this._translate('DETAILS_EMAIL.ACTIONS.SNACKBAR.ADD'));
            this.pageType = 'edit';
            this.detailForm.markAsPristine();
            this._stateService.go('mapui.adm.secure-emails.detail', { id: newSecureEmail._id });
        }, (error) => {
            console.error(error.error);
            switch (error.status) {
                case EHTTPStatus.InternalServerError:
                    this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                    break;
                case EHTTPStatus.BadRequest:
                    this._snackBarService.openCustomError(this._translate('DETAILS_EMAIL.FORM.ERRORS.MAIL_ALREADY_CREATED'));
                    break;
                default:
                    console.error('Error status not treated.', error);
                    this._snackBarService.openDefaultError();
            }
        });
    }
    saveEmailAddress() {
        this._updateSecureEmailAddress();
        this._secureEmailAddressService.save(this.secureEmailAddress).subscribe((newSecureEmail) => {
            if (!(newSecureEmail === null || newSecureEmail === void 0 ? void 0 : newSecureEmail._id)) {
                return this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._snackBarService.open(this._translate('DETAILS_EMAIL.ACTIONS.SNACKBAR.UPDATE'));
            this._secureEmailAddressService.getById(newSecureEmail._id).subscribe((newestSecureEmail) => {
                if (!newestSecureEmail) {
                    return this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                }
                this.secureEmailAddress = newestSecureEmail;
                this.detailForm.markAsPristine();
            }, (error) => this._snackBarService.openErrorFromStatus(error === null || error === void 0 ? void 0 : error.status));
        }, (error) => {
            console.error(error.error);
            this._snackBarService.openErrorFromStatus(error === null || error === void 0 ? void 0 : error.status);
        });
    }
    deleteSecureEmailAddress() {
        const dialogConfig = {
            title: this._translate('DETAILS_EMAIL.ACTIONS.DIALOG.TITLE'),
            message: this._translate('DETAILS_EMAIL.ACTIONS.DIALOG.MESSAGE'),
            confirmButton: this._translate('DETAILS_EMAIL.ACTIONS.DIALOG.CONFIRM')
        };
        this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
            if (data) {
                this._secureEmailAddressService.delete(this.secureEmailAddress).subscribe((res) => {
                    if (res === 'OK') {
                        this._snackBarService.open(this._translate('DETAILS_EMAIL.ACTIONS.SNACKBAR.DELETE'));
                        this._stateService.go('mapui.adm.secure-emails.list');
                    }
                    else {
                        this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                    }
                }, (error) => {
                    console.error(error.error);
                    this._snackBarService.openErrorFromStatus(error === null || error === void 0 ? void 0 : error.status);
                });
            }
        }, (error) => this._snackBarService.openErrorFromStatus(error === null || error === void 0 ? void 0 : error.status));
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof SecureEmailAddressASchema !== "undefined" && SecureEmailAddressASchema) === "function" ? _h : Object)
], SecureEmailAddressDetailsComponent.prototype, "secureEmailAddress", void 0);
SecureEmailAddressDetailsComponent = __decorate([
    Component({
        selector: 'details-email',
        template: require('./details-email.component.html').default,
        styles: [require('./details-email.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StateService !== "undefined" && StateService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object, typeof (_c = typeof SecureEmailAddressService !== "undefined" && SecureEmailAddressService) === "function" ? _c : Object, typeof (_d = typeof DialogService !== "undefined" && DialogService) === "function" ? _d : Object, typeof (_e = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _e : Object, typeof (_f = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _f : Object, typeof (_g = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _g : Object])
], SecureEmailAddressDetailsComponent);
export { SecureEmailAddressDetailsComponent };
