var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component } from '@angular/core';
import { LoanService } from '@app/services/loan/loan.service';
import { StatusService } from '@app/services/status/status.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ModalService } from '@main/services/modal/modal.service';
import { EVoucherType } from '@mapuilabs/mpl-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { locale as french } from '../../i18n/fr';
import { locale as english } from '../../i18n/en';
import { Utils } from '@main/services/Utils';
import { MemberService } from '@app/services/member/member.service';
let ArchivedDetailComponent = class ArchivedDetailComponent {
    constructor(sts, _loanService, _stateService, _translateService, _modalService, _memberService, _fuseTranslationLoaderService) {
        this.sts = sts;
        this._loanService = _loanService;
        this._stateService = _stateService;
        this._translateService = _translateService;
        this._modalService = _modalService;
        this._memberService = _memberService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._getLoanName = () => {
            if (Utils.hasId(this.loan.loanPerson)) {
                this._memberService.getById(Utils.getId(this.loan.loanPerson)).subscribe((member) => {
                    this.loan.loanPerson = member;
                });
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        const id = this._stateService.params['id'];
        this._loanService.getLoan(id).subscribe((loan) => {
            this.loan = loan;
            if (this.sts.amIL(this.loan) && this.loan.borrower) {
                this.othHospital = this.loan.borrower.name;
            }
            else if (this.loan.lender) {
                this.othHospital = this.loan.lender.name;
            }
            this._getLoanName();
            this.pageHeaderConfig = {
                title: loan.medicName,
                icon: 'group'
            };
        });
    }
    hasFullName() {
        return !!this.loan.updateUID.fullName;
    }
    getLang() {
        return this._translateService.currentLang;
    }
    /**
     * Generate voucher for the given [[ILoan]], suggest loans for multiline voucher
     * @param {ILoan} loan
     * @param type
     */
    generateVoucher(type) {
        let voucher;
        if (type == 'loan') {
            voucher = EVoucherType.Loan;
        }
        else if (type === 'borrow') {
            voucher = EVoucherType.Borrow;
        }
        else if (type === 'return') {
            voucher = EVoucherType.Return;
        }
        this._modalService.show({
            component: 'mp-modal-voucher',
            bindings: {
                loan: this.loan,
                voucherType: voucher
            },
            escapeToClose: false
        });
    }
    showMember(member) {
        this._loanService.archiveMember = member;
        this._stateService.go('mapui.exchangeV2.archives.member');
    }
};
ArchivedDetailComponent = __decorate([
    Component({
        selector: 'mpx-archived-detail',
        template: require('./archived-detail.component.html').default,
        styles: [require('./archived-detail.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StatusService !== "undefined" && StatusService) === "function" ? _a : Object, typeof (_b = typeof LoanService !== "undefined" && LoanService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _d : Object, typeof (_e = typeof ModalService !== "undefined" && ModalService) === "function" ? _e : Object, typeof (_f = typeof MemberService !== "undefined" && MemberService) === "function" ? _f : Object, typeof (_g = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _g : Object])
], ArchivedDetailComponent);
export { ArchivedDetailComponent };
