import { EDrugBalanceType, EHospivillePermission, EMpStatus, EStatusIndicator } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
export class DrugBalanceService {
    /** @ngInject */
    constructor(Api, $q, Access, $http) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        this.$http = $http;
        /**
         * Get a specific [[IDrugBalance]]
         * @param id The [[IDrugBalance]] [[Id]]
         * @return A promise containing the [[IDrugBalance]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation) || !id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Read) && !this.Access.authorize(EHospivillePermission.Prescription_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugBalances.get({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                    id: id,
                }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get a specific [[IDrugBalance]]
         * @return A promise containing the [[IDrugBalance]]
         */
        this.getAll = () => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Read) && !this.Access.authorize(EHospivillePermission.Prescription_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugBalances.all({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get specific [[IDrugBalance]] linked to the given [[IMedicationReconciliation]] [[Id]]
         * @param {EDrugBalanceType} type
         * @param {Id} mid
         * @returns {angular.IPromise<IDrugBalance>}
         */
        this.getByMid = (type, mid) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || (!Utils.hasId(this.Access.patient.medicationReconciliation) && !mid)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Read) && !this.Access.authorize(EHospivillePermission.Prescription_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                const givenMid = mid ? mid : this.Access.patient.medicationReconciliation._id;
                this.Api.drugBalances.getByMid({
                    pid: this.Access.patient._id,
                    mid: givenMid,
                    type: type,
                }, (res) => {
                    if (Utils.hasId(res)) {
                        defer.resolve(res);
                    }
                    else {
                        this.add({
                            type: EDrugBalanceType.DrugBalance,
                            date: new Date(),
                            medicationReconciliation: { _id: mid },
                            statusIndicator: EStatusIndicator.Ongoing,
                        }).then((newDbs) => defer.resolve(newDbs));
                    }
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an [[IDrugBalance]]
         * @param drugBalance The [[IDrugBalance]] to update
         */
        this.save = (drugBalance) => {
            const defer = this.$q.defer();
            if (!drugBalance || !Utils.hasId(this.Access.patient)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Edit) && !this.Access.authorize(EHospivillePermission.Prescription_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugBalances.save({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, drugBalance, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add an [[IDrugBalance]] to the dataBase
         * @param drugBalance The [[IDrugBalance]] to create
         */
        this.add = (drugBalance) => {
            const defer = this.$q.defer();
            if (!drugBalance || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Edit) && !this.Access.authorize(EHospivillePermission.Prescription_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugBalances.add({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                }, drugBalance, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Mark as deleted an [[IDrugBalance]]
         * @param drugBalance The [[IDrugBalance]] to delete
         */
        this.delete = (drugBalance) => {
            const defer = this.$q.defer();
            if (!drugBalance || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Edit) && !this.Access.authorize(EHospivillePermission.Prescription_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugBalances.delete({
                    pid: this.Access.patient._id,
                    hid: this.Access.patient.medicationReconciliation._id,
                }, drugBalance, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
    generatePdf(drugBalanceId, layout, patient) {
        const req = {
            method: 'GET',
            url: `/pdf-generation/test.pdf?pdfmodel=drug-balance&drugBalanceId=${drugBalanceId}&layout=${layout}&patId=${Utils.getId(patient)}`,
            headers: { 'Content-Type': 'application/pdf' },
            responseType: 'arraybuffer'
        };
        this.$http(req).then((data) => {
            const blob = new Blob([(data.data)], { type: 'application/pdf' });
            saveAs(blob, `BMO_${patient.fullName} ${moment().format('L')}.pdf`);
        }).catch((err) => {
            console.error(err);
        });
    }
}
