import * as angular from 'angular';
/** @ngInject */
export class MpMergeController {
    /** @ngInject */
    constructor(_) {
        this._ = _;
        /**
         * Will return the value for the required line and source
         * @param line
         * @param source
         */
        this.getValue = (line, source) => {
            if (angular.isFunction(line.display)) {
                return line.display(source);
            }
            if (line.attribute) {
                return source[line.attribute];
            }
        };
        /**
         * Will compare the two given values
         * @param line
         * @param source1
         * @param source2
         */
        this.compareValue = (line, source1, source2) => {
            if (angular.isFunction(line.compare))
                return line.compare(source1, source2);
            if (angular.isFunction(line.get))
                return line.get(source1) == line.get(source2);
            if (line.attribute)
                return source1[line.attribute] == source2[line.attribute];
        };
        /**
         * compare multiple sources
         * @param line
         * @param sources
         */
        this.compareAllValues = (line, sources) => {
            if (sources && sources.length) {
                let idx = 0;
                for (let source of sources) {
                    if (idx > 0) {
                        if (!this.compareValue(line, sources[0], source))
                            return false;
                    }
                    idx++;
                }
                return true;
            }
            return false;
        };
        /**
         * Set the dest according to the source
         * @param line
         * @param dest
         * @param source
         */
        this.setValue = (line, dest, source) => {
            if (angular.isFunction(line.set))
                line.set(dest, source);
            else if (line.attribute)
                dest[line.attribute] = source[line.attribute];
            line.isSet = true;
            this._triggerChange();
        };
        /**
         * Return true if the source is selected at the given line
         * @param line
         * @param dest
         * @param source
         */
        this.isSelected = (line, dest, source) => {
            if (!line.isSet)
                return false;
            return this.compareValue(line, dest, source);
        };
        this._initSet = () => {
            for (let line of this.config.lines) {
                line.isSet = false;
                if (this.compareAllValues(line, this.sources)) {
                    line.alreadyEqual = true;
                    line.isSet = true;
                    this.setValue(line, this.dest, this.sources[0]);
                }
            }
        };
        /**
         * Trigger the parent onChange
         * @constructor
         */
        this._triggerChange = () => {
            let isComplete = true;
            if (this._.find(this.config.lines, (line) => {
                return line.isSet == false;
            }))
                isComplete = false;
            this.onChange({
                $event: {
                    dest: this.dest,
                    complete: isComplete,
                },
            });
        };
        this.dest = {};
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.config) {
            this.config = angular.copy(this.config);
            if (this.sources && this.config)
                this._initSet();
        }
        if (changes.sources) {
            this.sources = angular.copy(this.sources);
            if (this.sources && this.config)
                this._initSet();
        }
        if (changes.dest)
            this.dest = {};
    }
}
