var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '@shared/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { FuseSharedModule } from '@fuse/shared.module';
import { HomeRoutingModule } from './home.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomWidgetsComponent } from './custom-widgets/custom-widgets.component';
import { WidgetsModule } from '@shared/components/widgets/dashboard/widgets.module';
let HomeModule = class HomeModule {
};
HomeModule = __decorate([
    NgModule({
        declarations: [DashboardComponent, CustomWidgetsComponent],
        imports: [CommonModule, FuseSharedModule, FlexLayoutModule, SharedModule, WidgetsModule, MatDividerModule],
        exports: [HomeRoutingModule]
    })
], HomeModule);
export { HomeModule };
