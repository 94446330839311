import * as angular from 'angular';
export class HvInformationController {
    /** @ngInject */
    constructor() {
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.message) {
            this.message = angular.copy(this.message);
        }
        if (changes.messageTranslate) {
            this.messageTranslate = angular.copy(this.messageTranslate);
        }
    }
}
