export class HvSelectDrugBalanceSourceComponent {
    constructor() {
        this.controller = 'HvSelectDrugBalanceSourceController';
        this.controllerAs = 'vm';
        this.template = require('./hv-select-drug-balance-source.html').default;
        this.bindings = {
            source: '<',
            patientSources: '<',
            dbsSources: '<',
            form: '@',
            label: '@',
            onChange: '&',
        };
    }
}
