import { HvInformationComponent } from './hv-information/hv-information.component';
import * as angular from 'angular';
import { HvInformationController } from './hv-information/hv-information.controller';
import { HvModalInformationComponent } from './hv-modal-information/hv-modal-information.component';
import { HvModalInformationController } from './hv-modal-information/hv-modal-information.controller';
import { InformationService } from './information.service';
angular.module('hospiville.services.information', [])
    .component('hvInformation', new HvInformationComponent())
    .controller('HvInformationController', HvInformationController)
    .component('hvModalInformation', new HvModalInformationComponent())
    .controller('HvModalInformationController', HvModalInformationController)
    .service('InformationService', InformationService);
