import * as angular from 'angular';
import { MemberService } from './member.service';
import { MpSidenavMemberController } from './mp-sidenav-member/mp-sidenav-member.controller';
import { MpSidenavMemberComponent } from './mp-sidenav-member/mp-sidenav-member.component';
angular
    .module('mapui.services.member', [
    'mapui.services.auth',
    'mapui.services.modal',
    'mapui.services.staff',
])
    .service('MemberService', MemberService)
    .controller('MpSidenavMemberController', MpSidenavMemberController)
    .component('mpSidenavMember', new MpSidenavMemberComponent());
