export class MpVirtualPageSelectDirective {
    /** @ngInject */
    constructor() {
        this.controller = 'MpVirtualPageSelectController';
        this.controllerAs = 'vm';
        this.require = ['^mpTablePagination'];
    }
    static factory() {
        const directive = () => new MpVirtualPageSelectDirective();
        directive.$inject = [];
        return directive;
    }
}
