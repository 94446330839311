var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AdminARSService } from '../../../services/admin/ars/admin-ars.service';
import { ListPage } from '../../../shared/templates/listPage/listPage.class';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let ListARSComponent = class ListARSComponent extends ListPage {
    constructor(_arsService, _fuseTranslationLoaderService, _injector) {
        super(_injector, _arsService.getPage);
        this._arsService = _arsService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._injector = _injector;
        // Columns to display in the data table
        this.displayedColumns = ['name', 'nbMembers', 'frenchDepartments'];
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translationService.instant('LIST.TITLE'),
            icon: 'pin_drop'
        };
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_d = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _d : Object)
], ListARSComponent.prototype, "_paginator", void 0);
ListARSComponent = __decorate([
    Component({
        selector: 'mpx-list-ars',
        template: require('./list-ars.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminARSService !== "undefined" && AdminARSService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof Injector !== "undefined" && Injector) === "function" ? _c : Object])
], ListARSComponent);
export { ListARSComponent };
