var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { EAdminPanelPermission, EHospistockPermission, EHospivillePermission, EMaPUIPermission, EWorkspacePermission, EProjects, Role, RoleProject } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { map, shareReplay } from 'rxjs/operators';
const projectPermissionsMap = new Map([
    [EProjects.ADMIN_PANEL, EAdminPanelPermission],
    [EProjects.HOSPISTOCK, EHospistockPermission],
    [EProjects.HOSPIVILLE, EHospivillePermission],
    [EProjects.MAPUI, EMaPUIPermission],
    [EProjects.WORKSPACE, EWorkspacePermission]
]);
let RolesService = class RolesService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, Role, '/api/admin/roles', {
            create: EAdminPanelPermission.Roles_Create,
            read: EAdminPanelPermission.Roles_Read,
            update: EAdminPanelPermission.Roles_Edit,
            delete: EAdminPanelPermission.Roles_Delete
        });
        this.__injector = __injector;
        this._endpoints = {
            hospitals: '/api/hospitals/roles',
            members: '/api/members/roles'
        };
        /**
         * Get all roles for a member.
         * @param id
         */
        this.getAllMember = (id = null) => {
            // Construct endpoint
            const endpointTree = this._endpoints.members.split('/');
            endpointTree.splice(3, 0, id); // Insert member id in endpoint tree: /api/members/:id/roles
            const fullEndpoint = endpointTree.join('/');
            return this._getRoles(fullEndpoint);
        };
    }
    //https://blog.thoughtram.io/angular/2018/03/05/advanced-caching-with-rxjs.html
    get defaultMember() {
        if (!this.defaultMemberRole) {
            //shareReplay allows the http request to be made only once.
            this.defaultMemberRole = this.getAllMember().pipe(shareReplay(1));
        }
        return this.defaultMemberRole;
    }
    /**
     * Get all roles for the current hospital
     */
    getAllHospital() {
        return this._getRoles(this._endpoints.hospitals);
    }
    /**
     * Get all roles according to the given endpoint.
     * @param endpoint
     * @private
     */
    _getRoles(endpoint) {
        return this._http.get(endpoint).pipe(map((data) => {
            const roles = [];
            for (const elem of data) {
                roles.push(new RoleProject(elem));
            }
            return roles;
        }));
    }
    getAllByProject(project) {
        return this._http.get('api/admin/roles/', { params: { project: project } });
    }
    getPermissionFromProject(project) {
        return projectPermissionsMap.get(project);
    }
};
RolesService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], RolesService);
export { RolesService };
