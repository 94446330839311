var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HsMarketItemASchema } from '@mapuilabs/mpl-interfaces';
let HsPublicationDetailMarketComponent = class HsPublicationDetailMarketComponent {
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._updateLocalPublicationProperties();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _updateLocalPublicationProperties() {
        const market = this.marketItem.market;
        if (typeof market === 'string') {
            console.error('Cannot display detail market properly, market is not populated');
            return;
        }
        const group = market.group;
        if (typeof group === 'string') {
            console.error('Cannot display detail market properly, group is not populated');
            return;
        }
        this.groupName = group.name;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof HsMarketItemASchema !== "undefined" && HsMarketItemASchema) === "function" ? _a : Object)
], HsPublicationDetailMarketComponent.prototype, "marketItem", void 0);
HsPublicationDetailMarketComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-market',
        template: `
        <mpx-small-list *ngIf="marketItem">
            <mpx-small-list-item *ngIf="groupName">
                <mpx-small-list-col bold> Groupement d'achat </mpx-small-list-col>

                <mpx-small-list-col>{{ groupName }}</mpx-small-list-col>
            </mpx-small-list-item>

            <mpx-small-list-item *ngIf="marketItem.marketSubject">
                <mpx-small-list-col bold>{{ 'Objet du marché' | translate }}</mpx-small-list-col>

                <mpx-small-list-col>{{ marketItem.marketSubject }}</mpx-small-list-col>
            </mpx-small-list-item>

            <mpx-small-list-item *ngIf="marketItem.campaign">
                <mpx-small-list-col bold>{{ 'Campagne' | translate }}</mpx-small-list-col>

                <mpx-small-list-col>{{ marketItem.campaign }}</mpx-small-list-col>
            </mpx-small-list-item>

            <mpx-small-list-item *ngIf="marketItem.marketNum">
                <mpx-small-list-col bold>{{ 'N° de marché' | translate }}</mpx-small-list-col>

                <mpx-small-list-col>{{ marketItem.marketNum }}</mpx-small-list-col>
            </mpx-small-list-item>

            <mpx-small-list-item *ngIf="marketItem.lot">
                <mpx-small-list-col bold>{{ 'N° de lot' | translate }}</mpx-small-list-col>

                <mpx-small-list-col>{{ marketItem.lot }}</mpx-small-list-col>
            </mpx-small-list-item>

            <mpx-small-list-item *ngIf="marketItem.abbreviation">
                <mpx-small-list-col bold>{{ 'Abréviation' | translate }}</mpx-small-list-col>

                <mpx-small-list-col>{{ marketItem.abbreviation }}</mpx-small-list-col>
            </mpx-small-list-item>
        </mpx-small-list>
    `,
        changeDetection: ChangeDetectionStrategy.OnPush
    })
], HsPublicationDetailMarketComponent);
export { HsPublicationDetailMarketComponent };
