var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Input } from '@angular/core';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { fuseAnimations } from '@fuse/animations';
import { Utils } from '@main/services/Utils';
import { MatTableDataSource } from '@angular/material/table';
import { AdminLaboGroupService } from '@services/admin/labo-group/admin-labo-group.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
let GroupsTabComponent = class GroupsTabComponent {
    constructor(_adminLaboGroupService, _translationService, _snackBarService) {
        this._adminLaboGroupService = _adminLaboGroupService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this.isLoading = false;
        this.dataSource = new MatTableDataSource();
        this.displayedColumns = ['name', 'nbHospitals', 'action'];
    }
    ngOnInit() {
        this.updateDatasource();
    }
    updateDatasource() {
        this.dataSource.data = this.laboratoryGroups;
    }
    removeGroup(group) {
        this._adminLaboGroupService.delete(group).subscribe(() => {
            this._snackBarService.open(this._translationService.instant('LABOS.DETAILS.GROUPS.DETAIL.DELETE_OK'));
            const index = this.laboratoryGroups.indexOf(group);
            if (index >= 0) {
                this.laboratoryGroups.splice(index, 1);
                this.updateDatasource();
            }
        }, (err) => this._snackBarService.openError(err));
    }
    // Sort
    sortCompare(a, b, direct) {
        if (!a) {
            return direct ? 1 : -1;
        }
        if (!b) {
            return direct ? -1 : 1;
        }
        if (a === b) {
            return 0;
        }
        if (a < b) {
            return direct ? -1 : 1;
        }
        return direct ? 1 : -1;
    }
    sortData(sort) {
        if (!this.laboratoryGroups) {
            return;
        }
        const data = this.laboratoryGroups.slice();
        if (!sort || !sort.active || sort.direction === '') {
            this.laboratoryGroups = data.sort((a, b) => {
                return this.sortCompare(Utils.normalize(a.name), Utils.normalize(b.name), true);
            });
            this.updateDatasource();
            return;
        }
        this.laboratoryGroups = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'name':
                    return this.sortCompare(Utils.normalize(a.name), Utils.normalize(b.name), isAsc);
                case 'nbHospitals':
                    return this.sortCompare(a.hospitals.length, b.hospitals.length, isAsc);
                default:
                    return 0;
            }
        });
        this.updateDatasource();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof Array !== "undefined" && Array) === "function" ? _d : Object)
], GroupsTabComponent.prototype, "laboratoryGroups", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GroupsTabComponent.prototype, "isLoading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], GroupsTabComponent.prototype, "animationDone", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Hospital !== "undefined" && Hospital) === "function" ? _e : Object)
], GroupsTabComponent.prototype, "laboratory", void 0);
GroupsTabComponent = __decorate([
    Component({
        selector: 'mpx-groups-tab',
        template: require('./groups-tab.component.html').default,
        styles: [require('./groups-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminLaboGroupService !== "undefined" && AdminLaboGroupService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object])
], GroupsTabComponent);
export { GroupsTabComponent };
