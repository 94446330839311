export class HvModalWaitingDownloadComponent {
    constructor() {
        this.controller = 'HvModalWaitingDownloadController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-waiting-download.html').default;
        this.bindings = {
            downloading: '<'
        };
    }
}
