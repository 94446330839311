var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { Component, Injector, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DialogService } from '@app/services/dialog/dialog.service';
import { LoanService } from '@app/services/loan/loan.service';
import { StatusService } from '@app/services/status/status.service';
import { ListPage } from '@app/shared/templates/listPage/listPage.class';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { Access } from '@main/services/auth/access.service';
import { ExchangeStatusesService } from '@services/exchange/statuses/exchange-statuses.service';
import { CLoanStatusIndicators } from '@shared/constTypes/loans/loan-status.const';
import { StateService } from '@uirouter/angularjs';
import { locale as english_status } from '../../request/i18n/en';
import { locale as french_status } from '../../request/i18n/fr';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let BillingAwaitingComponent = class BillingAwaitingComponent extends ListPage {
    constructor(_injector, sts, _access, _loanService, _fuseTranslationLoaderService, _exchangeStatusService, _stateService, _dialogService) {
        super(_injector, _loanService.getBillingWaitingFrom);
        this._injector = _injector;
        this.sts = sts;
        this._access = _access;
        this._loanService = _loanService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._exchangeStatusService = _exchangeStatusService;
        this._stateService = _stateService;
        this._dialogService = _dialogService;
        this.cLoanStatusIndicators = CLoanStatusIndicators;
        this._fuseTranslationLoaderService.loadTranslations(french, english, french_status, english_status);
        this.genericTableConfig = { clickableRows: true };
        this.columns = [
            {
                label: this._translate('BILLING.AWAITING.HEADERS.TYPE'),
                template: 'typeLogo',
                sort: true,
                attribute: 'loanSubCategoryCreation'
            },
            {
                label: this._translate('BILLING.AWAITING.HEADERS.MODIFIED'),
                attribute: 'updateDate',
                sort: true,
                template: 'updateDate'
            },
            {
                label: this._translate('BILLING.AWAITING.HEADERS.NAME'),
                attribute: 'medicName',
                sort: true
            },
            { label: this._translate('BILLING.AWAITING.HEADERS.HOSPITAL'), template: 'hospital' },
            { label: this._translate('BILLING.AWAITING.HEADERS.STATUS'), template: 'status' },
            { label: this._translate('BILLING.AWAITING.HEADERS.QUANTITY'), attribute: 'quantity', sort: true },
            {
                label: this._translate('BILLING.AWAITING.HEADERS.PRICE'),
                template: 'price',
                attribute: 'unitPrice',
                sort: true
            },
            { label: this._translate('BILLING.AWAITING.HEADERS.ACTION'), template: 'actions' }
        ];
    }
    getStatusCSSClass(loan) {
        const statusName = this._exchangeStatusService.getStatusName(loan);
        return this.cLoanStatusIndicators.find((status) => status.name === statusName).cssClass;
    }
    /**
     * Find translation for the status of the given loan.
     * @param loan
     */
    getStatusTranslation(loan) {
        const statusName = this._exchangeStatusService.getStatusName(loan);
        return this.cLoanStatusIndicators.find((status) => status.name === statusName).translate;
    }
    isMyHospital(hospital) {
        return Utils.compareIds(this._access.hospital, hospital);
    }
    _translate(value) {
        return this._translationService.instant(value);
    }
    openDetail(billingAwaiting) {
        this._stateService.go('mapui.exchangeV2.billing.detail', { id: billingAwaiting._id });
    }
    /**
     * Force the settlement or billing of a given [[ILoan]]
     * @param event The DOM event
     * @param loan The [[ILoan]] to force
     */
    force(event, loan) {
        event.stopPropagation();
        this._dialogService
            .openConfirmDialog({
            title: this._translate('BILLING.AWAITING.CONFIRM.FORCE.TITLE'),
            message: this.isMyHospital(loan.borrower)
                ? this._translate('BILLING.AWAITING.CONFIRM.FORCE.BILL_MESSAGE')
                : this._translate('BILLING.AWAITING.CONFIRM.FORCE.SETTLE_MESSAGE'),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        })
            .subscribe((result) => {
            if (result) {
                if (this.isMyHospital(loan.borrower)) {
                    loan.billingDateLender = new Date();
                }
                else {
                    loan.billingDateBorrower = new Date();
                }
                this._loanService.save(loan).subscribe({
                    next: () => {
                        this.search();
                        this._snackbarService.open(this.isMyHospital(loan.borrower)
                            ? this._translate('BILLING.SUCCESS.SUCCESS_BILLING')
                            : this._translate('BILLING.SUCCESS.SUCCESS_SETTLEMENT'));
                    },
                    error: (err) => this._snackbarService.openError(err)
                });
            }
        });
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_j = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _j : Object)
], BillingAwaitingComponent.prototype, "_paginator", void 0);
BillingAwaitingComponent = __decorate([
    Component({
        selector: 'mpx-billing-awaiting',
        template: require('./billing-awaiting.component.html').default,
        styles: [require('../../../status-colors.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof StatusService !== "undefined" && StatusService) === "function" ? _b : Object, typeof (_c = typeof Access !== "undefined" && Access) === "function" ? _c : Object, typeof (_d = typeof LoanService !== "undefined" && LoanService) === "function" ? _d : Object, typeof (_e = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _e : Object, typeof (_f = typeof ExchangeStatusesService !== "undefined" && ExchangeStatusesService) === "function" ? _f : Object, typeof (_g = typeof StateService !== "undefined" && StateService) === "function" ? _g : Object, typeof (_h = typeof DialogService !== "undefined" && DialogService) === "function" ? _h : Object])
], BillingAwaitingComponent);
export { BillingAwaitingComponent };
