var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { CellProcesses, CellValidators } from '@app/services/spreadsheet-parser/spreadsheet-parser.const';
import { Utils } from '@main/services/Utils';
import { EHospistockPermission, EHsPharmacyDrugBookletImportResultMessages, EHsPharmacyDrugPublicationsImport, HsBookletItemDrugASchema } from '@mapuilabs/mpl-interfaces';
import { HsModalBookletFormComponent } from '@app/hospistock/shared/components/modals/booklet/form/hs-modal-booklet-form.component';
import { EHsHealthProductDirectoryTypes } from '@app/hospistock/shared/types/hs-health-product-directory.types';
// -----------------------------------------------------------------------------------------------------
// @ HealtProductReferences Global Config
// -----------------------------------------------------------------------------------------------------
const translationBase = 'PUBLICATION.BOOKLET';
// -----------------------------------------------------------------------------------------------------
// @ HealtProductReferences Service Config
// -----------------------------------------------------------------------------------------------------
const endPoint = 'api/hospistock/pharmacy/drug/booklet';
const permissions = {
    create: EHospistockPermission.PharmacyDrugBooklet_Create,
    read: EHospistockPermission.PharmacyDrugBooklet_Read,
    update: EHospistockPermission.PharmacyDrugBooklet_Edit,
    delete: EHospistockPermission.PharmacyDrugBooklet_Delete
};
const handleRefArray = (value) => {
    if (!value) {
        // optional array
        return value;
    }
    // Guard: must be an array
    if (!Array.isArray(value)) {
        throw new Error('Cannot handle ref, value must be an array. Value:' + value);
    }
    const buildRef = (value) => {
        if (typeof value !== 'string') {
            throw new Error('Cannot handle ref, value must be of type string. Value:' + value);
        }
        return { ref: value };
    };
    return value.map((item) => buildRef(item));
};
const spreadsheetClassBuilder = [
    {
        column: EHsPharmacyDrugPublicationsImport.UCD,
        path: ['healthProductRef', 'ref']
    },
    {
        column: EHsPharmacyDrugPublicationsImport.DRUG_NAME,
        path: ['healthProductRef', 'name']
    },
    {
        column: EHsPharmacyDrugPublicationsImport.CIP,
        path: ['healthProductRef', 'healthProducts'],
        transform: handleRefArray
    },
    {
        column: EHsPharmacyDrugPublicationsImport.CONSUMPTION,
        path: ['consumption']
    },
    {
        column: EHsPharmacyDrugPublicationsImport.STOCK,
        path: ['stock']
    }
];
export const hsPharmacyDrugBookletServiceConfig = {
    classConstructor: HsBookletItemDrugASchema,
    endPoint,
    permissions,
    readLatestImportDate: true,
    spreadsheetClassBuilder
};
// -----------------------------------------------------------------------------------------------------
// @ HealtProductDirectory Config
// -----------------------------------------------------------------------------------------------------
// Import Config
const spreadsheetParserConfig = {
    columns: [
        {
            label: EHsPharmacyDrugPublicationsImport.UCD,
            processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.UCD13()],
            validators: [CellValidators.required(), CellValidators.ucd()]
        },
        {
            label: EHsPharmacyDrugPublicationsImport.DRUG_NAME,
            processes: [CellProcesses.trim()],
            validators: []
        },
        {
            label: EHsPharmacyDrugPublicationsImport.CONSUMPTION,
            processes: [CellProcesses.clear(), CellProcesses.number()],
            validators: [CellValidators.number()]
        },
        {
            label: EHsPharmacyDrugPublicationsImport.STOCK,
            processes: [CellProcesses.clear(), CellProcesses.number()],
            validators: [CellValidators.number()]
        },
        {
            label: EHsPharmacyDrugPublicationsImport.CIP,
            processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.split(';'), CellProcesses.CIP13()],
            validators: [CellValidators.array(';', CellValidators.cip())]
        }
    ],
    options: {
        headerToLowerCase: true,
        abortOnMajorError: true
    }
};
const spreadsheetImportResultMessagesMap = new Map(Utils.enumToKeys(EHsPharmacyDrugBookletImportResultMessages).map((key) => [
    key,
    translationBase + '.IMPORT.REPORT.MESSAGES.' + key
]));
const importConfig = {
    spreadsheetImportResultMessagesMap,
    spreadsheetParserConfig
};
// Page Header Config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant(translationBase + '.TITLES.MAIN'),
    searchBarPlaceholder: translationService.instant(translationBase + '.SEARCH'),
    icon: 'import_contacts'
});
// Table Config
const tableConfig = (translationService) => {
    const concatRef = (healthProducts) => { var _a; return (_a = healthProducts === null || healthProducts === void 0 ? void 0 : healthProducts.map(({ ref }) => ref)) === null || _a === void 0 ? void 0 : _a.join(' ; '); };
    return {
        columns: [
            {
                label: translationService.instant(translationBase + '.TABLE.DRUG_NAME'),
                attribute: 'healthProductRef.name',
                sort: true,
                classes: ['min-w-192']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.UCD13'),
                attribute: 'healthProductRef.ref',
                sort: true,
                classes: ['min-w-80 max-w-15-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.CIP13'),
                attribute: 'healthProductRef.healthProducts',
                transform: concatRef,
                sort: true,
                classes: ['min-w-128 max-w-20-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.CONSUMPTION'),
                attribute: 'consumption',
                sort: true,
                classes: ['w-96']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.STOCK'),
                attribute: 'stock',
                sort: true,
                classes: ['w-96']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.ACTIONS'),
                template: 'actionsTemplate',
                classes: ['w-96'],
                sort: false
            }
        ]
    };
};
export const hsPharmacyDrugBookletConfig = (translationService, fuseTranslationLoaderService) => __awaiter(void 0, void 0, void 0, function* () {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        healthProductDirectoryType: EHsHealthProductDirectoryTypes.BOOKLET,
        import: importConfig,
        pageHeader: pageHeaderConfig(translationService),
        table: tableConfig(translationService),
        translationBase,
        modal: HsModalBookletFormComponent
    };
});
