/** @ngInject */
export class RegisterPharmacistValidationController {
    constructor($stateParams, $state) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.goToHome = () => {
            this.$state.go('mapui.home');
        };
        this.goToLogin = () => {
            this.$state.go('mapui.pages_auth_login-v2');
        };
        this.goToRegister = () => {
            this.$state.go('mapui.pages_auth_register-v2');
        };
        this.email = this.$stateParams.email;
        this.error = this.$stateParams.error;
    }
}
