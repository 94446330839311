//TODO: Add translation and update SnackbarService
export const CMpStatus = [
    {
        name: 'Ok',
        value: 1,
        translate: '',
    },
    {
        name: 'CloseByUser',
        value: 2,
        translate: '',
    },
    {
        name: 'RejectByServer',
        value: 3,
        translate: '',
    },
    {
        name: 'NoDataProvided',
        value: 4,
        translate: '',
    },
    {
        name: 'NoResult',
        value: 5,
        translate: '',
    },
    {
        name: 'NoAccess',
        value: 6,
        translate: '',
    },
    {
        name: 'LoginFail',
        value: 7,
        translate: '',
    },
    {
        name: 'LoginCaptchaFail',
        value: 8,
        translate: '',
    },
    {
        name: 'TwoFaFail',
        value: 9,
        translate: '',
    },
    {
        name: 'LoginEmailNotVerified',
        value: 10,
        translate: '',
    },
    {
        name: 'RejectByUser',
        value: 11,
        translate: '',
    },
    {
        name: 'EmailAlreadyInUse',
        value: 12,
        translate: '',
    },
    {
        name: 'PwdTooShort',
        value: 13,
        translate: '',
    },
    {
        name: 'UserAlreadyInUse',
        value: 14,
        translate: '',
    },
    {
        name: 'CGUNotAccepted',
        value: 15,
        translate: '',
    },
    {
        name: 'NotAvailableAnymore',
        value: 16,
        translate: '',
    },
    {
        name: 'CPSCardUndetected',
        value: 17,
        translate: '',
    },
    {
        name: 'NoValidation',
        value: 18,
        translate: '',
    },
];
