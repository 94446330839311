import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvMedicationReconciliationComponent } from './hv-medication-reconciliation.component';
import { HvMedicationReconciliationController } from './hv-medication-reconciliation.controller';
import { HvModalDrugComponent } from './hv-modal-drug/hv-modal-drug.component';
import { HvModalDrugController } from './hv-modal-drug/hv-modal-drug.controller';
import { HvModalConciliationComponent } from './hv-modal-conciliation/hv-modal-conciliation.component';
import { HvModalConciliationController } from './hv-modal-conciliation/hv-modal-conciliation.controller';
angular
    .module('hospiville.patient.medication-reconciliation', [
    'mapui.services.modal',
])
    .config(configMedicationReconciliation)
    .component('hvMedicationReconciliation', new HvMedicationReconciliationComponent())
    .controller('HvMedicationReconciliationController', HvMedicationReconciliationController)
    .component('hvModalDrug', new HvModalDrugComponent())
    .controller('HvModalDrugController', HvModalDrugController)
    .component('hvModalConciliation', new HvModalConciliationComponent())
    .controller('HvModalConciliationController', HvModalConciliationController);
/** @ngInject */
function configMedicationReconciliation($stateProvider) {
    $stateProvider.state('hospiville.ctm', {
        url: '/ctm',
        data: {
            access: EHospivillePermission.MedicationReconciliation_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-medication-reconciliation></hv-medication-reconciliation>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville medication-reconciliation',
    });
}
