import { ELoanState, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as angular from 'angular';
import { ModalLoanConditions } from '../mp-modal-loan/mp-modal-loan.conditions';
/** @ngInject */
export class MpLoanCardsController {
    /** @ngInject */
    constructor(StatusService, Access, LoanService, ToastService, ModalService, Api, $q) {
        this.StatusService = StatusService;
        this.Access = Access;
        this.LoanService = LoanService;
        this.ToastService = ToastService;
        this.ModalService = ModalService;
        this.Api = Api;
        this.$q = $q;
        /**
         * All [[ELoanState]]s available
         */
        this.state = ELoanState;
        this.sortByType = (loan) => {
            if (this.sts.isPrep(loan))
                return 'PREP';
            if (this.sts.isDM(loan))
                return 'DM';
            if (this.sts.isMed(loan))
                return 'MED';
            return 'OTHER';
        };
        /**
         * Check if all [[ILoan]]s are loaded
         * @returns {boolean}
         */
        this.loading = () => {
            return !this.loans;
        };
        /**
         * Check if the given status should be displayed depending on the set filters
         * @param {string} statusName
         * @returns {boolean}
         */
        this.isStatusInFilter = (statusName) => {
            if (!this.statusFilter || !this.statusFilter.length)
                return true;
            return this.statusFilter.indexOf(statusName) >= 0;
        };
        /**
         * Edit the status of the given [[ILoan]](Preparation)
         * and save it in dashboard datable
         * @param {ILoan} loan
         */
        this.editPrepLabels = (loan, idx) => {
            this.ModalService.show({
                component: 'mp-modal-prep-status',
                bindings: {
                    loan: loan,
                },
                escapeToClose: false,
            }).then((res) => {
                this.LoanService.save(res)
                    .then((l) => {
                    Utils.editObjectInstanceInArray(this.loans, l);
                    this.ToastService.show(EMpStatus.Ok);
                })
                    .catch(err => this.ToastService.show(err));
            })
                .catch(err => this.ToastService.show(err));
        };
        /**
         * Output function for the `mp-loan-rollback` component
         * @param loan the changed [[ILoan]]
         * @param rollback the [[ILoan]] before changed
         */
        this.rollback = (loan, rollback) => {
            if (loan && rollback) {
                this.$q.all([
                    this.LoanService.rollback(rollback),
                    this.LoanService.save(loan),
                ])
                    .then(() => this.ToastService.show(EMpStatus.Ok))
                    .catch(err => this.ToastService.show(err));
            }
        };
        /**
         * filter the loans in the 'filteredLoans' object, based on the status filters
         * @private
         */
        this._filterLoans = () => {
            this.filteredLoans = [];
            if (!this.loans || !this.loans.length)
                return;
            for (let loan of this.loans) {
                let add = true;
                if (!this.isStatusInFilter(loan.status)) {
                    add = false;
                }
                if (this.typeFilter && loan.loanSubCategoryCreation !== this.typeFilter) {
                    add = false;
                }
                if (this.establishmentFilter && this.establishmentFilter.length && loan.borrower && loan.lender) {
                    const hospital = !Utils.compareIds(loan.borrower, this.Access.hospital) ? loan.borrower : loan.lender;
                    if (hospital && !~this.establishmentFilter.indexOf(hospital.establishmentType)) {
                        add = false;
                    }
                }
                if (add) {
                    this.filteredLoans.push(loan);
                }
            }
        };
        this.conditions = new ModalLoanConditions(StatusService, Access);
        this.sts = this.StatusService;
    }
    $onInit() {
        this.Api.constType.get((res) => {
            this.loanTypes = {};
            this.loanStatusIndicators = {};
            this.establishmentsTypes = {};
            for (const indicator of res.loanStatusIndicators) {
                this.loanStatusIndicators[indicator.name] = this.StatusService.createHtmlIndicator(indicator.name);
            }
            for (const type of res.loanTypes) {
                this.loanTypes[type.value] = type;
            }
            for (const type of res.type_establishment) {
                this.establishmentsTypes[type.value] = type.translate;
            }
        });
    }
    $onChanges(changes) {
        var _a, _b;
        if (changes.statusFilter || changes.typeFilter || changes.establishmentFilter) {
            this.statusFilter = angular.copy(this.statusFilter);
            this.typeFilter = angular.copy(this.typeFilter);
            this.establishmentFilter = angular.copy(this.establishmentFilter);
            this._filterLoans();
        }
        if (changes.loans) {
            this.loans = angular.copy(this.loans);
            if (this.loans) {
                for (let loan of this.loans) {
                    this.StatusService.getStatusIndicator(loan);
                    loan.totalPrice = loan.unitPrice * loan.quantity;
                    loan.hospitalName = '';
                    if (this.type === 'loan' && loan.borrower) {
                        loan.hospitalName = loan.borrower.name;
                    }
                    else if (this.type === 'borrowing' && loan.lender) {
                        loan.hospitalName = loan.lender.name;
                    }
                    else if (this.type === 'archive') {
                        if (this.StatusService.amIL(loan)) {
                            if (loan.borrower) {
                                loan.hospitalName = loan.borrower.name;
                            }
                        }
                        if (this.StatusService.amIB(loan)) {
                            if (loan.lender) {
                                loan.hospitalName = loan.lender.name;
                            }
                        }
                    }
                    else if (this.type === 'preparations') {
                        loan.hospitalName = loan.lender._id === this.Access.hospital._id ? (_a = loan === null || loan === void 0 ? void 0 : loan.borrower) === null || _a === void 0 ? void 0 : _a.name : (_b = loan === null || loan === void 0 ? void 0 : loan.lender) === null || _b === void 0 ? void 0 : _b.name;
                    }
                }
                this._filterLoans();
            }
        }
    }
}
