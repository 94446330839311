import { LocalFile } from '../../LocalFile';
/**
 * Allow the binding between the file drop and an ng-model
 * The model returned is a [[LocalFile]]
 * @private
 */
export class MpFileDropDirective {
    /** @ngInject */
    constructor() {
        this.link = (scope, element /*angular.IAugmentedJQuery*/, attrs, ctrl) => {
            element.on('drop', onDrop);
            element.on('dragOver', onDragOver);
            element.on('dragLeave', onDragLeave);
            /**
             * Retrieve the files from the drop event
             * @param event
             * @return {DataTransfer}
             * @private
             */
            function _getTransfert(event) {
                return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
            }
            /**
             * Stop the event propagation and prevent the default action.
             * @param event
             * @private
             */
            function _preventAndStop(event) {
                event.preventDefault();
                event.stopPropagation();
            }
            /**
             * Callback for the drop event
             * the `ng-model` MUST be an array of [[LocalFile]]
             * Create a new [[LocalFile]] and add it to the `ng-model`
             */
            function onDrop(event) {
                const transfert = _getTransfert(event);
                let files;
                let localFiles;
                if (!transfert) {
                    return;
                }
                files = transfert.files;
                localFiles = ctrl.$modelValue.length ? [...ctrl.$modelValue] : [];
                for (let f of files) {
                    localFiles.push(new LocalFile({
                        element: element,
                        blob: f,
                        scope: scope,
                    }));
                }
                _preventAndStop(event);
                ctrl.$setViewValue(localFiles);
            }
            function onDragOver(event) {
                _preventAndStop(event);
            }
            function onDragLeave(event) {
                _preventAndStop(event);
            }
            scope.$on('destroy', function () {
                element.off('drop', onDrop);
                element.off('dragOver', onDragOver);
                element.off('dragLeave', onDragLeave);
            });
        };
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    static factory() {
        const directive = () => new MpFileDropDirective();
        directive.$inject = [];
        return directive;
    }
}
