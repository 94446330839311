import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MpModalDownloadController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this.ModalService.close(EMpStatus.Ok);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
    }
    ;
}
