import * as angular from 'angular';
export class MpMainController {
    /** @ngInject */
    constructor($scope, $rootScope, $state, $transitions, Access) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$transitions = $transitions;
        this.Access = Access;
        this.hospivilleClass = '';
        this.mapuiClass = '';
        this.displayMigrationInfo = false;
        this.updateClass();
        $transitions.onSuccess({}, () => {
            this.updateClass();
        });
        // Data
        //////////
        // Remove the splash screen
        $scope.$on('$viewContentAnimationEnded', function (event) {
            // REMOVED AT MIGRATION
            // if (event.targetScope.$id === $scope.$id) {
            $rootScope.$broadcast('msSplashScreen::remove');
            angular.element(document.querySelector('#preloader')).remove();
            // }
        });
        this.displayMigrationInfo =
            this.Access.isHVMigrationState() && this.$state.current.name.startsWith('hospiville');
    }
    //this is used to aply 100% height only on currently used ui-view
    updateClass() {
        const state = this.$state.current.name;
        if (state.startsWith('hospiville')) {
            this.mapuiClass = '';
            this.hospivilleClass = 'mp-height-100-hv';
        }
        else {
            this.mapuiClass = 'mp-height-100';
            this.hospivilleClass = '';
        }
    }
}
