var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Injectable } from '@angular/core';
import { AvailableWidgetComponent } from '@shared/components/widgets/home/available-widget/available-widget.component';
import { EMemberStatus } from '@mapuilabs/mpl-interfaces';
import { RequestWidgetComponent } from '@shared/components/widgets/home/request-widget/request-widget.component';
import { LoanOrBorrowWidgetComponent } from '@shared/components/widgets/home/loan-or-borrow-widget/loan-or-borrow-widget.component';
import { TwitterWidgetComponent } from '@shared/components/widgets/home/twitter-widget/twitter-widget.component';
import { HsStockoutWidgetComponent } from '@shared/components/widgets/home/stockout-widget/stockout-widget.component';
let WidgetInjection = class WidgetInjection {
};
WidgetInjection = __decorate([
    Injectable()
], WidgetInjection);
export { WidgetInjection };
export class Widget {
    constructor(component, weightByColumn, injector = null) {
        this.component = component;
        this.weightByColumn = weightByColumn;
        this.injector = injector;
    }
}
export class Layout {
    constructor(widgets, memberStatus) {
        this.widgets = widgets;
        this.memberStatus = memberStatus;
    }
    addWidgets(widgets) {
        this.widgets.push(...widgets);
    }
}
export class DefaultLayout extends Layout {
    constructor() {
        super([
            new Widget(HsStockoutWidgetComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
            new Widget(TwitterWidgetComponent, [{ 0: 99 }, { 0: 3 }, { 0: 2 }]),
            new Widget(AvailableWidgetComponent, [{ 0: 2 }, { 1: 1 }, { 1: 1 }]),
            new Widget(RequestWidgetComponent, [{ 0: 4 }, { 1: 2 }, { 1: 2 }]),
            new Widget(LoanOrBorrowWidgetComponent, [{ 0: 3 }, { 0: 2 }, { 2: 1 }])
        ], null);
    }
}
export class PharmacistLayout extends Layout {
    constructor() {
        super([
            new Widget(HsStockoutWidgetComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
            new Widget(TwitterWidgetComponent, [{ 0: 99 }, { 0: 3 }, { 0: 2 }]),
            new Widget(AvailableWidgetComponent, [{ 0: 2 }, { 1: 1 }, { 1: 1 }]),
            new Widget(RequestWidgetComponent, [{ 0: 4 }, { 1: 2 }, { 1: 2 }]),
            new Widget(LoanOrBorrowWidgetComponent, [{ 0: 3 }, { 0: 2 }, { 2: 1 }])
        ], EMemberStatus.Pharmacist);
    }
}
export class PreparatorLayout extends Layout {
    constructor() {
        super([
            new Widget(HsStockoutWidgetComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
            new Widget(TwitterWidgetComponent, [{ 0: 99 }, { 0: 3 }, { 0: 2 }]),
            new Widget(AvailableWidgetComponent, [{ 0: 2 }, { 1: 1 }, { 1: 1 }]),
            new Widget(RequestWidgetComponent, [{ 0: 4 }, { 1: 2 }, { 1: 2 }]),
            new Widget(LoanOrBorrowWidgetComponent, [{ 0: 3 }, { 0: 2 }, { 2: 1 }])
        ], EMemberStatus.Preparator);
    }
}
export class PharmacyStudentLayout extends Layout {
    constructor() {
        super([
            new Widget(HsStockoutWidgetComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
            new Widget(TwitterWidgetComponent, [{ 0: 99 }, { 0: 3 }, { 0: 2 }]),
            new Widget(AvailableWidgetComponent, [{ 0: 2 }, { 1: 1 }, { 1: 1 }]),
            new Widget(RequestWidgetComponent, [{ 0: 4 }, { 1: 2 }, { 1: 2 }]),
            new Widget(LoanOrBorrowWidgetComponent, [{ 0: 3 }, { 0: 2 }, { 2: 1 }])
        ], EMemberStatus.PharmacyStudent);
    }
}
export class DoctorsLayout extends Layout {
    constructor() {
        super([
            new Widget(HsStockoutWidgetComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
            new Widget(TwitterWidgetComponent, [{ 0: 99 }, { 0: 3 }, { 0: 2 }]),
            new Widget(AvailableWidgetComponent, [{ 0: 2 }, { 1: 1 }, { 1: 1 }]),
            new Widget(RequestWidgetComponent, [{ 0: 4 }, { 1: 2 }, { 1: 2 }]),
            new Widget(LoanOrBorrowWidgetComponent, [{ 0: 3 }, { 0: 2 }, { 2: 1 }])
        ], EMemberStatus.Doctor);
    }
}
