var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BehaviorSubject } from 'rxjs';
let HsPublicationListComponent = class HsPublicationListComponent {
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationListComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof BehaviorSubject !== "undefined" && BehaviorSubject) === "function" ? _a : Object)
], HsPublicationListComponent.prototype, "publicationContainers$$", void 0);
HsPublicationListComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-list',
        template: require('./hs-publication-list.component.html').default,
        animations: fuseAnimations,
        changeDetection: ChangeDetectionStrategy.OnPush
    })
], HsPublicationListComponent);
export { HsPublicationListComponent };
