export class HvModalConciliationComponent {
    constructor() {
        this.controller = 'HvModalConciliationController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-conciliation.html').default;
        this.bindings = {
            conciliation: '<',
        };
    }
}
