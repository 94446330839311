export const locale = {
    lang: 'fr',
    data: {
        AVAILABLE: {
            CANT_CREATE: {
                TITLE: 'Votre quota est dépassé',
                TEXT: `Vous avez atteint le nombre maximum de mises à disposition en version gratuite. Contactez les équipes de MaPUILabs via l'adresse contact@mapui.fr pour plus d'informations.`
            }
        }
    }
};
