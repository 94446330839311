export class MpLabelComponent {
    constructor() {
        this.controller = 'MpLabelController';
        this.controllerAs = 'vm';
        this.template = require('./mp-label.html').default;
        this.bindings = {
            model: '<',
            onSelected: '&',
            onRemove: '&',
            labelType: '<',
            placeholder: '<',
        };
    }
}
