export const locale = {
    lang: 'en',
    data: {
        DETAILS_EMAIL: {
            NEW_TITLE: `New email address`,
            FORM: {
                TITLE_EMAIL_ADDRESS: `Email address`,
                TITLE_MANAGER: `Email address manager`,
                HOSPITAL: `Hospital`,
                SECURE_MAIL: `Email address`,
                MEMBER: {
                    LABEL: `Member`,
                    PLACEHOLDER: `Select a member of the hospital as the manager`
                },
                MANAGER_FIRST_NAME: `First name`,
                MANAGER_LAST_NAME: `Last name`,
                MANAGER_MAIL: `Email`,
                MANAGER_PHONE: `Phone`,
                BUTTONS: {
                    DELETE: `Delete`
                },
                ERRORS: {
                    MAIL_ALREADY_CREATED: `A secure email address already exists for this establishment.`
                }
            },
            ACTIONS: {
                DIALOG: {
                    TITLE: `Delete email address`,
                    MESSAGE: `Do you really want yo delete this secure email address?`,
                    CONFIRM: `Delete`
                },
                SNACKBAR: {
                    ADD: `The email address has successfully been added.`,
                    UPDATE: `The email address has successfully been modified.`,
                    DELETE: `The email address has successfully been deleted.`
                }
            }
        }
    }
};
