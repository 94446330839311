export class HvDropDisplayComponent {
    constructor() {
        this.controller = 'HvDropDisplayController';
        this.controllerAs = 'vm';
        this.template = require('./hv-drop-display.html').default;
        this.bindings = {
            dropInfo: '<',
            sentence: '<',
        };
    }
    ;
}
