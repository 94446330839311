export class HsPublicationStatisticContainer {
    constructor(publicationStatisticContainer) {
        this.creationDate = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.creationDate;
        this.updateDate = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.updateDate;
        this.creator = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.creator;
        this.creatorType = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.creatorType;
        this.startDate = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.startDate;
        this.endDate = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.endDate;
        this.type = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.type;
        this.healthProductRef = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.healthProduct;
        this.averageDuration = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.averageDuration;
        this.metadata = publicationStatisticContainer === null || publicationStatisticContainer === void 0 ? void 0 : publicationStatisticContainer.metadata;
    }
}
