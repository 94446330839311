import { EMailPriority } from '@mapuilabs/mpl-interfaces';
export const CMailPriority = {
    high: {
        value: EMailPriority.High,
        stringValue: 'high',
        translate: 'COMMONS.MAIL_PRIORITY.HIGH',
    },
    normal: {
        value: EMailPriority.Normal,
        stringValue: 'normal',
        translate: 'COMMONS.MAIL_PRIORITY.NORMAL',
    },
    low: {
        value: EMailPriority.Low,
        stringValue: 'low',
        translate: 'COMMONS.MAIL_PRIORITY.LOW',
    },
};
