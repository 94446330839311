export class RegisterValidationController {
    /** @ngInject */
    constructor($rootScope, $location, $state) {
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$state = $state;
        this.goToHome = () => {
            this.$state.go('mapui.home');
        };
        this.goToLogin = () => {
            this.$state.go('mapui.pages_auth_login-v2');
        };
    }
}
