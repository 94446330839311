var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { HsPublicationASchema } from '@mapuilabs/mpl-interfaces';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { stockIndicatorThresholdsMap } from '../../../constTypes/publication/hs-publication-stock-indicator.const';
import { EHsPublicationStockIndicatorStatus } from '../../../types/hs-publication-stock-indicator.types';
let HsPublicationStockIndicatorComponent = class HsPublicationStockIndicatorComponent {
    constructor(_changeDetectorRef, _publicationContainerService) {
        this._changeDetectorRef = _changeDetectorRef;
        this._publicationContainerService = _publicationContainerService;
        this.eStockIndicatorStatus = EHsPublicationStockIndicatorStatus;
        this.remainingStockDays = 0;
        this.coveredDays = 0;
        // Init Subjects
        this._unsubscribeAll = new Subject();
    }
    get thresholds() {
        return Array.from(stockIndicatorThresholdsMap.values());
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._computeStockIndicator();
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Compute stock value if drug is present in booklet
     */
    _computeStockIndicator() {
        // Handle publication terminated
        if (this._publicationContainerService.isPublicationTerminated(this.publication)) {
            throw new Error('Stock indicator with status TERMINATED should not be displayed');
        }
        // Handle future publication
        if (this._publicationContainerService.isFuturePublication(this.publication)) {
            throw new Error('Stock indicator for future publication should not be displayed');
        }
        // Handle missing booklet stock information into all bookletItems
        if (this.bookletItems.some((bookletItem) => bookletItem.stock === null)) {
            this.status = EHsPublicationStockIndicatorStatus.STOCK_NULL;
            this.threshold = stockIndicatorThresholdsMap.get(this.status);
            this._changeDetectorRef.detectChanges();
            return;
        }
        // Handle booklet items total stock information equal to zero
        if (this.bookletItems.some((bookletItem) => bookletItem.stock === 0)) {
            this.status = EHsPublicationStockIndicatorStatus.STOCK_ZERO;
            this.threshold = stockIndicatorThresholdsMap.get(this.status);
            this._changeDetectorRef.detectChanges();
            return;
        }
        // Handle missing booklet consumption information into all bookletItems
        if (this.bookletItems.some((bookletItem) => bookletItem.consumption === null)) {
            this.status = EHsPublicationStockIndicatorStatus.CONSUMPTION_NULL;
            this.threshold = stockIndicatorThresholdsMap.get(this.status);
            this._changeDetectorRef.detectChanges();
            return;
        }
        // Handle booklet consumption information equal to zero
        if (this.bookletItems.some((bookletItem) => bookletItem.consumption === 0)) {
            this.status = EHsPublicationStockIndicatorStatus.CONSUMPTION_ZERO;
            this.threshold = stockIndicatorThresholdsMap.get(this.status);
            this._changeDetectorRef.detectChanges();
            return;
        }
        // Convert dates to moment date objects starting on the start of the day
        const startDate = moment(this.publication.startDate).startOf('day');
        const endDate = moment(this.publication.endDate).startOf('day');
        let importToStartPeriodDaysTotal = 0;
        let stockCapacityDaysTotal = 0;
        for (const bookletItem of this.bookletItems) {
            if ((bookletItem === null || bookletItem === void 0 ? void 0 : bookletItem.stock) &&
                (bookletItem === null || bookletItem === void 0 ? void 0 : bookletItem.stock) !== 0 &&
                (bookletItem === null || bookletItem === void 0 ? void 0 : bookletItem.consumption) &&
                (bookletItem === null || bookletItem === void 0 ? void 0 : bookletItem.consumption) !== 0) {
                // Convert dates to moment date objects starting on the start of the day
                const importDate = moment(bookletItem.updateDate).startOf('day');
                // Calculate period from latest import to publication start date & stock capacity
                const importToStartPeriodDays = startDate.diff(importDate, 'days');
                const stockCapacityDays = Math.floor(bookletItem.stock / bookletItem.consumption);
                stockCapacityDaysTotal = stockCapacityDaysTotal + stockCapacityDays;
                importToStartPeriodDaysTotal = importToStartPeriodDaysTotal + importToStartPeriodDays;
                // Calculate publication period and end of the stock capacity
                const publicationPeriodDays = endDate.diff(startDate, 'days');
                const stockCapacityEndDate = moment(importDate).add(stockCapacityDays, 'days');
                // Calculate publication indicator values
                this.remainingStockDays = this.remainingStockDays + stockCapacityEndDate.diff(endDate, 'days');
                this.coveredDays =
                    this.coveredDays + Math.min(publicationPeriodDays, stockCapacityDays - importToStartPeriodDays);
            }
        }
        // Handle no publication end date
        if (!this.publication.endDate) {
            this.status = EHsPublicationStockIndicatorStatus.NO_END_DATE;
            this.threshold = stockIndicatorThresholdsMap.get(this.status);
            this.coveredDays = stockCapacityDaysTotal - importToStartPeriodDaysTotal;
            this._changeDetectorRef.detectChanges();
            return;
        }
        const effectivesthresholds = this.thresholds
            .filter(({ value }) => value !== undefined)
            .sort((a, b) => a.value - b.value);
        this.threshold = effectivesthresholds.find((threshold) => this.remainingStockDays < threshold.value);
        this._changeDetectorRef.detectChanges();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof HsPublicationASchema !== "undefined" && HsPublicationASchema) === "function" ? _c : Object)
], HsPublicationStockIndicatorComponent.prototype, "publication", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsPublicationStockIndicatorComponent.prototype, "bookletItems", void 0);
HsPublicationStockIndicatorComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-stock-indicator',
        template: require('./hs-publication-stock-indicator.component.html').default,
        styles: [
            `
            .spot-indicator {
                height: 10px;
                width: 10px;
                border-radius: 50%;
            }
        `
        ],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _b : Object])
], HsPublicationStockIndicatorComponent);
export { HsPublicationStockIndicatorComponent };
