import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class LoginV2Controller {
    /** @ngInject */
    constructor(Auth, Access, ToastService, VaultService, $mdDialog, MemberService, $translate, $stateParams, $state, $window, $location) {
        this.Auth = Auth;
        this.Access = Access;
        this.ToastService = ToastService;
        this.VaultService = VaultService;
        this.$mdDialog = $mdDialog;
        this.MemberService = MemberService;
        this.$translate = $translate;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$window = $window;
        this.$location = $location;
        /**
         * Login acction
         */
        this.login = () => {
            let options = {
                username: this.username,
                password: this.password,
            };
            if (this.twoFaToken) {
                options.twoFactorToken = this.twoFaToken;
            }
            this.Auth.login(options)
                .then((res) => {
                if (res.token) {
                    this.needTwofaToken = true;
                    return;
                }
                this.Auth.updateUserAccesses()
                    .then(() => {
                    if (this.$stateParams.RPPS) {
                        res.user.RPPS = this.$stateParams.RPPS;
                        this.MemberService.save(res.user)
                            .then(() => {
                            this.$mdDialog.show(this.$mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(this.$translate.instant('CPS.MODALS.RPPS_LINKED_TITLE'))
                                .textContent(this.$translate.instant('CPS.MODALS.RPPS_LINKED_TEXT'))
                                .ok('Ok'))
                                .then(() => {
                                this._redirect();
                            });
                        })
                            .catch((err) => {
                            this.$mdDialog.show(this.$mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(this.$translate.instant('CPS.MODALS.RPPS_NOT_LINKED_TITLE'))
                                .textContent(this.$translate.instant('CPS.MODALS.RPPS_NOT_LINKED_TEXT'))
                                .ok('Ok'))
                                .then(() => {
                                this._redirect();
                            });
                        });
                    }
                    else {
                        this.Auth._changeUser(res);
                        this._redirect();
                    }
                })
                    .catch(() => this.ToastService.show(EMpStatus.LoginFail));
            })
                .catch((err) => {
                if (err.data == 'FailedToLogin') {
                    this.ToastService.show(EMpStatus.LoginFail);
                }
                else if (err.data == 'MailNotVerified') {
                    this.ToastService.show(EMpStatus.LoginEmailNotVerified);
                }
                else if (err.data == 'TooManyAttempts') {
                    this.isLocked = true;
                }
                if (!this.needTwofaToken)
                    this.password = '';
                else
                    this.twoFaToken = '';
                console.log('Failed', err.data);
                this.failedAttempts++;
            });
        };
        this._redirect = () => {
            this.VaultService.init();
            this.VaultService.setUnlocked();
            if (this.Access.isNotActivated() && !this.Access.CGUisAccepted()) {
                this.$state.go('mapui.first-connection');
            }
            else if (this.Access.user.activation) {
                this.$state.go('mapui.reset-password');
            }
            else if (this.$stateParams.redirect) {
                this.$location.path(this.$stateParams.redirect);
            }
            else {
                this.$state.go('mapui.home');
            }
        };
        this.redirectToECPS = () => {
            this.$window.location = '/ecps/login';
        };
        this.redirectToCreateECPS = () => {
            this.$window.location = '/ecps/create';
        };
        this.needTwofaToken = false;
        this.failedAttempts = 0;
        if (this.$stateParams.email && this.$stateParams.valid) {
            this.username = this.$stateParams.email;
            this.password = this.$stateParams.valid;
            this.login();
        }
        if (this.Auth.user.email && this.Auth.user.email != '' && this.$stateParams.redirect) {
            this._redirect();
        }
    }
}
