export class MpModalPrepLabelsComponent {
    constructor() {
        this.controller = 'MpModalPrepLabelsController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-prep-labels.html').default;
        this.bindings = {
            loan: '<',
        };
    }
}
