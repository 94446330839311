import * as angular from 'angular';
export class HvDosageController {
    /** @ngInject */
    constructor(Api, _) {
        this.Api = Api;
        this._ = _;
        /**
         * Get the translation of a given dosage type
         * @param dosageType
         * @returns {string}
         */
        this.getDosageType = (dosageType) => {
            if (!this._constTypes || (!dosageType && dosageType !== 0))
                return;
            return this._.find(this._constTypes.dosages, { value: dosageType }).translate;
        };
        this.Api.hvConstType.get((ans) => {
            this._constTypes = ans;
        });
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.source) {
            this.source = angular.copy(this.source);
        }
    }
}
