export class HvPatientSearchComponent {
    constructor() {
        this.controller = 'HvPatientSearchController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-search.html').default;
        this.bindings = {
            onSelected: '&',
        };
    }
}
