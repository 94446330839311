export const rolesTypes = {
    establishment: {
        value: 1,
        translate: 'ROLES.TYPES.ESTABLISHMENT'
    },
    user: {
        value: 2,
        translate: 'ROLES.TYPES.USER'
    }
};
