export class MpDirectoryHierarchyComponent {
    constructor() {
        this.controller = 'MpDirectoryHierarchyController';
        this.controllerAs = 'vm';
        this.template = require('./mp-directory-hierarchy.html').default;
        this.bindings = {
            directories: '<',
            excluded: '<',
            //http://stackoverflow.com/questions/20073305/how-to-pass-a-function-reference-in-a-recursive-directive-in-angular
            selectDirectory: '&',
            topSelectDir: '=', // DO NOT CHANGE: Trick to fix a bug with callback & recursion
        };
    }
}
