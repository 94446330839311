import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalDrugController {
    /** @ngInject */
    constructor(Api, ModalService, _) {
        this.Api = Api;
        this.ModalService = ModalService;
        this._ = _;
        /**
         * Set given [[IDrugInstanceSource]] posology
         * @param {IDrugInstance} drug
         * @param {IPosology} posology
         */
        this.posologyChange = (drug, { posologies }) => {
            if (!drug.validSource)
                drug.validSource = {};
            drug.validSource.posologies = posologies;
        };
        /**
         * Set given [[IDrugInstanceSource]] dosage
         * @param {number} quantity
         * @param {number} type
         * * @param {IDropInfo} dropInfo
         * @param {IDrugInstance} drug
         */
        this.dosageChange = ({ quantity, type, dropInfo }, drug) => {
            if (!drug.validSource)
                drug.validSource = {};
            drug.validSource.dosage = quantity;
            drug.validSource.dosageType = type;
            if (type == 14) {
                drug.validSource.dosageDropInfo = dropInfo;
            }
        };
        /**
         * Output function
         * Set the [[IDrugInstance]] [[IMedUCD]]
         * @param {IDrugInstance} instance
         * @param drug
         */
        this.selectDrug = (instance, event) => {
            instance.denomination = event.drug.denomination;
            instance.UCD13 = event.drug.UCD13;
            instance.codeATC = event.drug.codeATC;
            instance.DCI = event.drug.DCI;
            if (this._.find(this.dosageTypes, { value: +event.pharmaForm }) && event.pharmaForm) {
                if (!instance.validSource)
                    instance.validSource = {};
                instance.validSource.dosageType = +event.pharmaForm;
            }
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this._close(this.drugs);
        };
        /**
         * Close the modal with actions performing
         * @param answer
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
    }
    $onChanges(changes) {
        if (changes.drugs) {
            this.drugs = angular.copy(this.drugs);
        }
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.dosageTypes = ans.dosages;
        });
    }
}
