import { EGender } from '@mapuilabs/mpl-interfaces';
export const CGender = {
    male: {
        value: EGender.Male,
        translate: 'COMMONS.GENDERS.MALE',
    },
    female: {
        value: EGender.Female,
        translate: 'COMMONS.GENDERS.FEMALE',
    },
};
