var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
let HsPublicationDetailStatsWidgetModalComponent = class HsPublicationDetailStatsWidgetModalComponent {
    constructor(input, _fuseTranslationLoaderService, _translationService) {
        this.input = input;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        this.title = input.config.title;
        this.publication = input.data.publication;
        this.dataSource = new MatTableDataSource(input.data.data);
        // Table
        this.columns = input.config.columns.map((column) => ({
            label: this._translate(column.label),
            attribute: column.attribute
        }));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
};
__decorate([
    ViewChild(MatPaginator),
    __metadata("design:type", typeof (_c = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _c : Object)
], HsPublicationDetailStatsWidgetModalComponent.prototype, "paginator", void 0);
HsPublicationDetailStatsWidgetModalComponent = __decorate([
    Component({
        selector: 'mpx-hs-modal-publication-detail-stats-widget',
        template: require('./hs-modal-publication-detail-stats-widget.component.html').default,
        animations: fuseAnimations,
        providers: [HsPublicationContainerService, HsHealthProductService]
    }),
    __param(0, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [Object, typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], HsPublicationDetailStatsWidgetModalComponent);
export { HsPublicationDetailStatsWidgetModalComponent };
