export class HvNirValidationDirective {
    /** @ngInject */
    constructor() {
        this.link = (scope, elem, attr, ctrl) => {
            // if (!ctrl)
            //     return;
            const ngModel = ctrl[0];
            ngModel.$validators.nirLength = function (value) {
                if (value) {
                    value = value.split(' ').join('');
                    return value.length == 13 || value.length == 15;
                }
                return true;
            };
            ngModel.$validators.nirKey = function (value) {
                if (value) {
                    value = value.split(' ').join('');
                    if (value.length === 15) {
                        let corsedep = value.substr(5, 2);
                        if (corsedep[1] == 'A' || corsedep[1] == 'B') {
                            let number = parseInt(corsedep[0]) * 10 - (corsedep[1].charCodeAt(0) - 64);
                            value = value.replace(corsedep, number.toString());
                        }
                        const nir = 97 - (parseInt(value.substr(0, value.length - 2)) % 97);
                        return nir == parseInt(value.substr(value.length - 2, 2));
                    }
                }
                return true;
            };
        };
        this.require = ['ngModel'];
    }
    static factory() {
        const directive = () => new HvNirValidationDirective();
        directive.$inject = [];
        return directive;
    }
}
