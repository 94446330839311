var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EGroupType, Group } from '@mapuilabs/mpl-interfaces';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { TranslationService } from '@services/translation/translation.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { AdminGroupsService } from '@services/admin/group/admin-groups.service';
let GroupDetailComponent = class GroupDetailComponent {
    constructor(_fuseTranslationLoaderService, _stateService, _adminGroupService, _translationService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._stateService = _stateService;
        this._adminGroupService = _adminGroupService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    get isPurchaseGroup() {
        return this.group.type === EGroupType.Purchase;
    }
    ngOnInit() {
        var _a, _b;
        if (this._stateService.params.id === 'new') {
            this.pageType = 'new';
            this.group = new Group({});
        }
        else {
            this.pageType = 'edit';
        }
        this.detailForm = new FormGroup({
            name: new FormControl(this.group.name, Validators.required),
            type: new FormControl(this.group.type, Validators.required),
            groupStructure: new FormControl({ value: this.group.groupStructure, disabled: this.pageType === 'edit' }, Validators.required),
            hospistockConfig: new FormGroup({
                alternatives: new FormGroup({
                    moderator: new FormControl(((_b = (_a = this.group.hospistockConfig) === null || _a === void 0 ? void 0 : _a.alternatives) === null || _b === void 0 ? void 0 : _b.moderator) || false)
                })
            })
        });
        // Header
        const title = this.pageType === 'new'
            ? this._translationService.instant('GROUP_DETAIL.NEW_TITLE')
            : `${this._translationService.instant('GROUP_DETAIL.EDIT_TITLE')} ${this.group.name}`;
        this.pageHeaderConfig = {
            title: title,
            icon: 'group'
        };
    }
    onAnimationDone() {
        this.animationDone = true;
    }
    onTabChange() {
        this.animationDone = false;
    }
    updateGroup() {
        Object.assign(this.group, this.detailForm.value);
    }
    // Save an already existing group
    saveGroup() {
        this.updateGroup();
        this._adminGroupService.save(this.group).subscribe((newGroup) => {
            this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.EDIT_OK'));
            this.group = newGroup;
            this.detailForm.markAsPristine();
        });
    }
    // Add an new group to the database
    addGroup() {
        this.updateGroup();
        this._adminGroupService.add(this.group).subscribe((newGroup) => {
            this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.CREATE_OK'));
            this.group = newGroup;
            this.pageType = 'edit';
            this.detailForm.markAsPristine();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Group !== "undefined" && Group) === "function" ? _f : Object)
], GroupDetailComponent.prototype, "group", void 0);
GroupDetailComponent = __decorate([
    Component({
        selector: 'mpx-group-detail',
        template: require('./group-detail.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof AdminGroupsService !== "undefined" && AdminGroupsService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], GroupDetailComponent);
export { GroupDetailComponent };
