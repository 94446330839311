export class MpFileDetailsSidenavController {
    /** @ngInject */
    constructor(Api) {
        this.Api = Api;
        /**
         * Get the file type
         * @param f
         * @returns {string}
         */
        this.getFileType = (f) => {
            if (!this.filesTypeLabel || !f)
                return null;
            const type = f.isDirectory ? 'FILE_TYPE.DIRECTORY' : this.filesTypeLabel[f.extension];
            return type ? type : this.filesTypeLabel['default'];
        };
        /**
         * Return text file extension (used for file type .svg)
         * @param {IFile} f
         * @returns {string}
         */
        this.getFileExt = (f) => {
            if (!this.filesType) {
                return null;
            }
            if (f && f.extension && this.filesType[f.extension]) {
                return this.filesType[f.extension];
            }
            return this.filesType['default'];
        };
        this.Api.filesType.get((ans) => {
            this.filesType = ans;
        });
        this.Api.filesTypeLabel.get((ans) => {
            this.filesTypeLabel = ans;
        });
    }
}
