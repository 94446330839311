import * as angular from 'angular';
import { AcceptCGUController } from './accept-cgu.controller';
import { AcceptCGUComponent } from './accept-cgu.component';
angular.module('mapui.accept-cgu', [])
    .config(($stateProvider) => {
    $stateProvider.state('mapui.accept-cgu', {
        url: '/cgu',
        data: {},
        views: {
            'main@': {
                template: require('../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.accept-cgu': {
                template: '<accept-cgu></accept-cgu>',
            },
        },
        bodyClass: 'accept-cgu',
    });
})
    .controller('AcceptCGUController', AcceptCGUController)
    .component('acceptCgu', new AcceptCGUComponent())
    .run(runAcceptCGU);
function runAcceptCGU($templateCache) {
    $templateCache.put('app/main/general/mp-loading/mp-loading.html', require('../mp-loading/mp-loading.html').default);
}
