var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Member } from '@mapuilabs/mpl-interfaces';
import { CGender } from '../../../shared/constTypes/member/member-gender.const';
import { pharmacistsStatus } from '../../../shared/constTypes/pharmacists/pharmacists.const';
import { pharmacyStudentStatus } from '../../../shared/constTypes/pharmacists/pharmacy-students.const';
import { AuthorSpecialityService } from '../../../services/author-speciality/author-speciality.service';
let InformationTabComponent = class InformationTabComponent {
    constructor(_authorSpecialityService) {
        this._authorSpecialityService = _authorSpecialityService;
        this.cGender = CGender;
        this.pharmacistsPro = pharmacistsStatus;
        this.pharmacyStudents = pharmacyStudentStatus;
        this.myJobCode = undefined;
    }
    ngOnChanges(changes) {
        var _a;
        if (changes.user.currentValue) {
            this.setFormControls();
            this.setJobCode((_a = this.user.job) === null || _a === void 0 ? void 0 : _a._id);
        }
    }
    ngOnInit() {
        this._authorSpecialityService.getAll().subscribe((result) => {
            var _a;
            this.authorSpeciality = result;
            this.setJobCode((_a = this.user.job) === null || _a === void 0 ? void 0 : _a._id);
        });
    }
    setFormControls() {
        var _a;
        this.form.setControl('firstName', new UntypedFormControl(this.user.firstName));
        this.form.setControl('familyName', new UntypedFormControl(this.user.familyName));
        this.form.setControl('gender', new UntypedFormControl(this.user.gender));
        this.form.setControl('phone', new UntypedFormControl(this.user.phone));
        this.form.setControl('inpp', new UntypedFormControl(this.user.INPP));
        this.form.setControl('rpps', new UntypedFormControl(this.user.RPPS));
        this.form.setControl('job', new UntypedFormControl((_a = this.user.job) === null || _a === void 0 ? void 0 : _a._id));
        this.form.setControl('jobStatus', new UntypedFormControl(this.user.status));
    }
    updateMember(member) {
        member.firstName = this.form.value.firstName;
        member.familyName = this.form.value.familyName;
        member.gender = this.form.value.gender;
        member.phone = this.form.value.phone;
        member.INPP = this.form.value.inpp;
        member.RPPS = this.form.value.rpps;
        member.job = this.myJobCode;
        member.status = this.form.value.jobStatus;
        return member;
    }
    setJobCode(jobCode) {
        if (!this.authorSpeciality || !jobCode) {
            return;
        }
        for (const speciality of this.authorSpeciality) {
            if (speciality._id === jobCode) {
                this.myJobCode = speciality;
                break;
            }
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], InformationTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Member !== "undefined" && Member) === "function" ? _c : Object)
], InformationTabComponent.prototype, "user", void 0);
InformationTabComponent = __decorate([
    Component({
        selector: 'mpx-information-tab',
        template: require('./information-tab.component.html').default,
        styles: [require('./information-tab.component.scss')],
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AuthorSpecialityService !== "undefined" && AuthorSpecialityService) === "function" ? _a : Object])
], InformationTabComponent);
export { InformationTabComponent };
