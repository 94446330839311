var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
let ExSearchMedicalDeviceComponent = class ExSearchMedicalDeviceComponent {
    constructor(_fb) {
        this._fb = _fb;
        this.pageHeaderConfig = {
            title: 'Medical device Search Component',
            icon: 'extension '
        };
    }
    ngOnInit() {
        this.formHealthProductRef = this._fb.group({
            name: [null, Validators.required],
            ref: [null, [Validators.required]],
            operator: [null, Validators.required],
            healthProducts: [[] || null, Validators.required]
        });
        this.formManual = this._fb.control(false);
    }
};
ExSearchMedicalDeviceComponent = __decorate([
    Component({
        selector: 'ex-search-medical-device',
        template: require('./ex-search-medical-device.component.html').default,
        styles: [require('./ex-search-medical-device.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _a : Object])
], ExSearchMedicalDeviceComponent);
export { ExSearchMedicalDeviceComponent };
