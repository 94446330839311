import * as angular from 'angular';
import { MpTimeController } from './mp-time/mp-time.controller';
import { MpTimeComponent } from './mp-time/mp-time.component';
import { MpNothingToShowController } from './mp-nothing-to-show/mp-nothing-to-show.controller';
import { MpNothingToShowComponent } from './mp-nothing-to-show/mp-nothing-to-show.component';
import { MpDateController } from './mp-date/mp-date.controller';
import { MpDateComponent } from './mp-date/mp-date.component';
angular
    .module('mapui.common', []).controller('MpTimeController', MpTimeController)
    .component('mpTime', new MpTimeComponent())
    .controller('MpNothingToShowController', MpNothingToShowController)
    .component('mpNothingToShow', new MpNothingToShowComponent())
    .controller('MpDateController', MpDateController)
    .component('mpDate', new MpDateComponent());
