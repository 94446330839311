export const locale = {
    lang: 'en',
    data: {
        DRAG_N_DROP: {
            TITLES: {
                FILES_LIST: 'Files list to import'
            },
            DROPZONE: {
                INSTRUCTIONS: 'Drag and drop files here',
                OR: 'or',
                BROWSE_BTN: 'Browse'
            },
            ERRORS: {
                MULTIPLE_NOT_ALLOWED: "You can't load more than one file"
            }
        }
    }
};
