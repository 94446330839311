var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import * as _ from 'lodash';
import { EFilterType, EHsHealthProductTypes } from '@mapuilabs/mpl-interfaces';
import { locale as english } from '../../../../i18n/en';
import { locale as french } from '../../../../i18n/fr';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { Subject, lastValueFrom, merge } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsAlternativeContainerService } from '@app/hospistock/shared/services/hs-alternative-container.service';
let HsAlternativeFiltersSidebarComponent = class HsAlternativeFiltersSidebarComponent {
    constructor(_fuseSidebarService, _fuseTranslationLoaderService, _alternativeContainerService, _healthProductService, _fb) {
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._alternativeContainerService = _alternativeContainerService;
        this._healthProductService = _healthProductService;
        this._fb = _fb;
        this.types = CHsPublicationType;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this.groupsLoaded = false;
        this.groups = [];
        this.groupGroups = [];
        this._filterSearch = (opt, value) => {
            const filterValue = value.toLowerCase();
            return opt.filter((item) => item.label.toLowerCase().includes(filterValue));
        };
        this._filterSelected = (opt, selected) => {
            return opt.filter((item) => !selected.find((selectedItem) => _.isEqual(selectedItem, item)));
        };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._subscriptionNotifier = new Subject();
        // Init Events
        this.filtersApplied = new EventEmitter();
        this.filterCount = new EventEmitter();
    }
    get groupsFormArray() {
        return this.form.get('creatorGroup');
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if ('applyFilters' in changes) {
            if (this.form) {
                this._setFormControls();
            }
        }
    }
    ngOnInit() {
        // Init form
        this._initForm();
        // Save form value records
        this._formRecordDefault = this.form.value;
        this._formRecordLast = this.form.value;
        // Init changesCount based on formGroups
        this.changesCount = Object.keys(this._formRecordDefault).reduce((a, key) => Object.assign(a, { [key]: 0 }), {});
        // Update changesCount and emit filterCount
        this.form.valueChanges.pipe(takeUntil(this._subscriptionNotifier)).subscribe((changes) => {
            Object.keys(changes).forEach((formGroupName) => {
                this.changesCount[formGroupName] = this._getChangesCount(formGroupName);
            });
            this._updateFilterCount();
        });
        this._refreshListGroups();
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._subscriptionNotifier.next();
        // Unsubscribe subscriptionNotifier
        this._subscriptionNotifier.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    _refreshListGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            this.groups = yield lastValueFrom(this._alternativeContainerService.getGroupsList());
            this.groupGroups = this._groupByFirstLetter(this.groups.filter((group) => !!group).map((group) => ({ label: group.name, id: group._id })));
            this.groupsLoaded = true;
            this._setFormControls();
            // Update groupGroupOptions on autocomplete and groupsFormArray change
            this.groupGroupOptions = merge(this.groupAutocompleteFormControl.valueChanges.pipe(startWith(''), map((search) => {
                if (_.isString(search)) {
                    return this._filterGroup(this.groupGroups, this.groupsFormArray.value, search);
                }
            })), this.groupsFormArray.valueChanges.pipe(map((groups) => this._filterGroup(this.groupGroups, groups))));
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Autocompletes
    // -----------------------------------------------------------------------------------------------------
    _groupByFirstLetter(list) {
        const groups = [];
        list.forEach((item) => {
            var _a;
            const letter = (_a = item.label) === null || _a === void 0 ? void 0 : _a[0];
            let group = groups.find((group) => group.letter === letter);
            if (!group) {
                group = { letter, options: [] };
                groups.push(group);
            }
            group.options.push(item);
        });
        return groups;
    }
    _filterGroup(groups, groupss, search) {
        let _groups = groups;
        // Filter selected groups
        if (groupss && groupss.length > 0) {
            _groups = _groups
                .map((groupGroup) => ({
                letter: groupGroup.letter,
                options: this._filterSelected(groupGroup.options, groupss)
            }))
                .filter((group) => group.options.length > 0);
        }
        // Filter with search entry
        if (search) {
            _groups = _groups
                .map((group) => ({ letter: group.letter, options: this._filterSearch(group.options, search) }))
                .filter((group) => group.options.length > 0);
        }
        return _groups;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Event handlers
    // -----------------------------------------------------------------------------------------------------
    onOpenChanged(opened) {
        if (!opened) {
            this._resetFormToLast();
        }
        else {
            this._refreshListGroups();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Form
    // -----------------------------------------------------------------------------------------------------
    _initForm() {
        this.groupAutocompleteFormControl = this._fb.control('');
        this.form = this._fb.group({
            creationDate: this._fb.group({
                start: null,
                end: null
            }),
            selectionDate: this._fb.group({
                start: null,
                end: null
            }),
            creatorGroup: this._fb.array([])
        });
    }
    _getUrlFilter(filterAttribute, opt) {
        if (!this.applyFilters || this.applyFilters.length === 0) {
            return null;
        }
        const filter = this.applyFilters.find((filter) => filter.attribute === filterAttribute);
        if (filter) {
            switch (filter.type) {
                case EFilterType.VALUE:
                    return filter.value;
                case EFilterType.VALUES:
                    return opt ? filter.values.includes(opt) : filter.values;
                case EFilterType.RANGE:
                    return filter;
            }
        }
    }
    _setFormControls() {
        // Reset form
        this._resetFormToDefault();
        // ==================================================
        // Set creationDate URL fitler values
        const creationDateFormGroup = this.form.get('creationDate');
        // creationDate
        const urlFilterCreationDate = this._getUrlFilter('creationDate');
        if (urlFilterCreationDate === null || urlFilterCreationDate === void 0 ? void 0 : urlFilterCreationDate.start) {
            creationDateFormGroup.get('start').setValue(urlFilterCreationDate.start);
        }
        if (urlFilterCreationDate === null || urlFilterCreationDate === void 0 ? void 0 : urlFilterCreationDate.end) {
            creationDateFormGroup.get('end').setValue(urlFilterCreationDate.end);
        }
        // ==================================================
        // Set selection URL fitler values
        const selectionDateFormGroup = this.form.get('selectionDate');
        // selectionDate
        const urlFilterSelection = this._getUrlFilter('selectionDate');
        if (urlFilterSelection === null || urlFilterSelection === void 0 ? void 0 : urlFilterSelection.start) {
            selectionDateFormGroup.get('start').setValue(urlFilterSelection.start);
        }
        if (urlFilterSelection === null || urlFilterSelection === void 0 ? void 0 : urlFilterSelection.end) {
            selectionDateFormGroup.get('end').setValue(urlFilterSelection.end);
        }
        // ==================================================
        // Set Groups URL fitler values
        const urlFilterGroups = this._getUrlFilter('creatorGroup');
        if (this.groupsLoaded && urlFilterGroups) {
            this.groupsFormArray.clear();
            urlFilterGroups.forEach((urlGroupId) => {
                // url only contains group id, we need to complete this group id with group name to display selected group
                const fullGroup = this.groups.find((group) => group._id === urlGroupId);
                if (fullGroup) {
                    this.groupsFormArray.push(this._fb.control({ id: fullGroup._id, label: fullGroup.name }));
                }
            });
        }
        // Update last form record
        this._formRecordLast = this.form.value;
    }
    _getChangesCount(formGroupName) {
        const formGroupValues = this.form.value[formGroupName];
        const formGroupRecordDefaultValues = this._formRecordDefault[formGroupName];
        const res = _.reduce(formGroupValues, function (result, value, key) {
            return _.isEqual(value, formGroupRecordDefaultValues[key]) ? result : result.concat(key);
        }, []);
        return res.length;
    }
    _updateFilterCount() {
        // Calculate filters total
        const filterCount = Object.values(this.changesCount).reduce((accumulator, currentValue) => accumulator + currentValue);
        // Emit value in filterCount output
        this.filterCount.emit(filterCount);
    }
    _applyFilterDate(formControlName) {
        const datesFormGroup = this.form.get(formControlName);
        const periodFilter = {
            type: EFilterType.RANGE,
            attribute: formControlName
        };
        const startValue = datesFormGroup.get('start').value;
        if (startValue) {
            periodFilter.start = startValue;
        }
        const endValue = datesFormGroup.get('end').value;
        if (endValue) {
            periodFilter.end = endValue;
        }
        return periodFilter;
    }
    onOptionSelected(formArray, formControl, group) {
        formControl.reset();
        formArray.push(this._fb.control(group));
    }
    onRemoveSelected(formArray, index) {
        formArray.removeAt(index);
    }
    isFormDefaultValue() {
        return _.isEqual(this._formRecordDefault, this.form.value);
    }
    isFormLastValue() {
        return _.isEqual(this._formRecordLast, this.form.value);
    }
    /**
     * Sends the filters to the list view so that it can update.
     */
    onApplyFilters(replaceHistory = false) {
        var _a;
        const filters = [];
        // ==================================================
        // Apply creationDate filters
        const creationDateFormGroup = this.form.get('creationDate');
        // creationDate
        if (creationDateFormGroup.get('start').value || creationDateFormGroup.get('end').value) {
            filters.push(this._applyFilterDate('creationDate'));
        }
        // ==================================================
        // Apply selectionDate filters
        const selectionDateFormGroup = this.form.get('selectionDate');
        // selectionDate
        if (selectionDateFormGroup.get('start').value || selectionDateFormGroup.get('end').value) {
            filters.push(this._applyFilterDate('selectionDate'));
        }
        // ==================================================
        // Apply Groups filters
        if (((_a = this.groupsFormArray.value) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            filters.push({
                type: EFilterType.VALUES,
                attribute: 'creatorGroup',
                inArray: true,
                values: this.groupsFormArray.value.map((value) => value.id)
            });
        }
        // Apply filters by emitting filterApplied event
        this.filtersApplied.emit({ filters, replaceHistory });
        // Self close sidebar
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
    }
    _resetFormToDefault() {
        // Reset form
        this.form.reset(this._formRecordDefault);
        // Reset formArray manually because controls set after init
        this.groupsFormArray.clear();
    }
    _resetFormToLast() {
        // Reset form
        this.form.reset(this._formRecordLast);
        // Reset groups formArray manually because set after init
        this.groupsFormArray.clear();
        this._formRecordLast.creatorGroup.forEach((group) => {
            this.groupsFormArray.push(this._fb.control(group));
        });
    }
    resetFilters() {
        // Reset form
        this._resetFormToDefault();
        // Reload table
        this.filtersApplied.emit({
            filters: [],
            replaceHistory: true
        });
        // Self close sidebar
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsAlternativeFiltersSidebarComponent.prototype, "applyFilters", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], HsAlternativeFiltersSidebarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], HsAlternativeFiltersSidebarComponent.prototype, "filterCount", void 0);
HsAlternativeFiltersSidebarComponent = __decorate([
    Component({
        selector: 'mpx-hs-alternative-sidebar-filters',
        template: require('./hs-alternative-sidebar-filters.component.html').default,
        styles: [require('./hs-alternative-sidebar-filters.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HsAlternativeContainerService !== "undefined" && HsAlternativeContainerService) === "function" ? _c : Object, typeof (_d = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _d : Object, typeof (_e = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _e : Object])
], HsAlternativeFiltersSidebarComponent);
export { HsAlternativeFiltersSidebarComponent };
