import * as angular from 'angular';
export class mpVerticalStepperStepDirective {
    /** @ngInject */
    constructor() {
        this.compile = (tElement) => {
            tElement.addClass('mp-stepper-step');
            return function postLink(scope, iElement, iAttrs, ctrls) {
                const FormCtrl = ctrls[0], MsStepperCtrl = ctrls[1];
                //scope.isfinalStep = scope.finalStep != undefined || angular.isDefined(iAttrs.finalStep);
                if (scope.finalStep == undefined) {
                    scope.finalStep = angular.isDefined(iAttrs.finalStep);
                }
                scope.stepInfo = MsStepperCtrl.registerStep(iElement, scope, FormCtrl);
                scope.vm = MsStepperCtrl;
                iElement.find('.mp-stepper-step-content').hide();
            };
        };
        this.restrict = 'E';
        this.require = ['form', '^mpVerticalStepper'];
        this.priority = 1000;
        this.scope = {
            step: '=?',
            stepTitle: '=?',
            optionalStep: '=?',
            hideStep: '=?',
            finalStep: '=?',
        };
        this.transclude = true;
        this.template = require('./templates/step/vertical-step.html').default;
    }
    static factory() {
        const directive = () => new mpVerticalStepperStepDirective();
        directive.$inject = [];
        return directive;
    }
}
