import { RegisterV2Controller } from './register-v2.controller';
import * as angular from 'angular';
angular
    .module('mapui.services.auth.register-v2', [])
    .config(config)
    .controller('RegisterV2Controller', RegisterV2Controller);
/** @ngInject */
function config($stateProvider) {
    // State
    $stateProvider.state('mapui.pages_auth_register-v2', {
        url: '/auth/register',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_auth_register-v2': {
                template: require('./register-v2.html').default,
                controller: 'RegisterV2Controller as vm',
            },
        },
        bodyClass: 'register-v2',
    });
}
