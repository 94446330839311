import './core/theming/fuse-theming.service';
import 'angular';
import 'angular-material';
import * as moment from 'moment';
import { EPubSubTopic, ESocketTopic } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as Sentry from '@sentry/angular';
export class IndexController {
    /** @ngInject */
    constructor(VaultService, SocketService, $rootScope, fuseTheming, Auth, Access, $scope, $translate, $mdDateLocale, $locale, Idle, PubSubService, $state, $cookies, Api) {
        this.VaultService = VaultService;
        this.$cookies = $cookies;
        this.Api = Api;
        const vm = this;
        vm.login = false;
        vm.displayCookieBar = null;
        SocketService.connect();
        $scope.$on('$locationChangeStart', (next, current) => {
            if (!Access.isLogged()) {
                vm.login = false;
                if (vm.displayCookieBar === null) {
                    this.displayCookieBar = this.$cookies.getObject('displayCookieBar') !== false;
                    this.$cookies.putObject('displayCookieBar', false);
                }
                else {
                    this.displayCookieBar = false;
                }
            }
            else {
                vm.login = true;
            }
        });
        // Data
        vm.themes = fuseTheming.themes;
        //////////
        $scope.$on('IdleStart', function () {
            // the user appears to have gone idle
            // console.info('the user appears to have gone idle', Idle.getTimeout());
            if (!Access.isPharmacy()) {
                PubSubService.emit(EPubSubTopic.IDLE_START, Idle.getTimeout());
            }
        });
        $scope.$on('IdleWarn', function (e, countdown) {
            // follows after the IdleStart event, but includes a countdown until the user is considered timed out
            // the countdown arg is the number of seconds remaining until then.
            // you can change the title or display a warning dialog from here.
            // you can let them resume their session by calling Idle.watch()
            // console.info("Idle warning", countdown);
            if (!Access.isPharmacy()) {
                PubSubService.emit(EPubSubTopic.IDLE_WARN, countdown);
            }
        });
        $scope.$on('IdleTimeout', function () {
            // the user has timed out (meaning idleDuration + timeout has passed without any activity)
            // this is where you'd log them
            // console.info("Idle timeout");
            if (!Access.isPharmacy()) {
                PubSubService.emit(EPubSubTopic.IDLE_TIMEOUT);
            }
        });
        $scope.$on('IdleEnd', function () {
            // the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
            // console.info("Idle End (user is not AFK anymore)");
            PubSubService.emit(EPubSubTopic.IDLE_END);
        });
        PubSubService.on(EPubSubTopic.RESET_IDLE, () => {
            // console.info("Start Idle watch");
            Idle.watch();
        });
        PubSubService.on(EPubSubTopic.IDLE_STOP, () => {
            // console.info("Stop Idle watch");
            Idle.unwatch();
        });
        // Change date format according to selected language
        $rootScope.$on('$translateChangeSuccess', function () {
            moment.locale($translate.use());
            const localeDate = moment.localeData();
            $mdDateLocale.months = localeDate._months;
            $mdDateLocale.shortMonths = localeDate._monthsShort;
            $mdDateLocale.days = localeDate._weekdays;
            $mdDateLocale.shortDays = localeDate._weekdaysMin;
            $mdDateLocale.firstDayOfWeek = localeDate._week.dow;
            $mdDateLocale.msgCalendar = $translate.instant('CALENDAR.TITLE');
            $mdDateLocale.msgOpenCalendar = $translate.instant('CALENDAR.OPEN');
            // Uses moment.js to parse and format dates.
            $mdDateLocale.parseDate = function (dateString) {
                const m = moment(dateString, 'L', true);
                return m.isValid() ? m.toDate() : new Date(NaN);
            };
            $mdDateLocale.formatDate = function (date) {
                const m = moment(date);
                return m.isValid() ? m.format('L') : '';
            };
            $mdDateLocale.monthHeaderFormatter = function (date) {
                return $mdDateLocale.shortMonths[date.getMonth()] + ' ' + date.getFullYear();
            };
            // In addition to date display, date components also need localized messages
            // for aria-labels for screen-reader users.
            $mdDateLocale.weekNumberFormatter = function (weekNumber) {
                return $translate.instant('CALENDAR.WEEK_NBR', { nbr: weekNumber });
            };
        });
        PubSubService.on(EPubSubTopic.HIDE_COOKIE_BAR, () => {
            this.displayCookieBar = false;
        });
        SocketService.on(ESocketTopic.Permissions, (msg) => {
            Auth.setUserAccesses(msg);
        });
        SocketService.on(ESocketTopic.Roles, (msg) => {
            Access.user.roles = msg.roles;
            Access.hospital.roles = msg.hospital.roles;
        });
        if (Utils.hasId(Auth.user)) {
            this.Api.member.screenSize.send({ id: Utils.getId(Auth.user) }, {
                width: $(window).width(),
                height: $(window).height()
            });
        }
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: Utils.getId(Auth.user),
                username: Auth.user.fullName,
                email: Auth.user.email
            });
        });
    }
}
