var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, HostBinding, Input } from '@angular/core';
let ChipComponent = class ChipComponent {
    constructor() {
        // Set default size
        this.size = 'md';
    }
    ngOnInit() {
        this.smChipClass = this.size === 'sm';
        this.mdChipClass = this.size === 'md';
        this.lgChipClass = this.size === 'lg';
    }
};
__decorate([
    HostBinding('class.sm-chip'),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "smChipClass", void 0);
__decorate([
    HostBinding('class.md-chip'),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "mdChipClass", void 0);
__decorate([
    HostBinding('class.lg-chip'),
    __metadata("design:type", Boolean)
], ChipComponent.prototype, "lgChipClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ChipComponent.prototype, "size", void 0);
ChipComponent = __decorate([
    Component({
        selector: 'chip',
        template: ` <ng-content></ng-content> `,
        styles: [
            `
            :host {
                display: flex;
                align-items: center;
                height: 20px;
                padding: 0 8px;
                font-size: 11px;
                font-weight: 600;
                border-radius: 10px;
            }

            :host.sm-chip {
                height: 20px;
                padding: 0 8px;
                font-size: 11px;
                border-radius: 10px;
            }

            :host.md-chip {
                height: 24px;
                padding: 0 10px;
                font-size: 12px;
                border-radius: 12px;
            }

            :host.lg-chip {
                height: 28px;
                padding: 0 12px;
                font-size: 14px;
                border-radius: 14px;
            }
        `
        ]
    }),
    __metadata("design:paramtypes", [])
], ChipComponent);
export { ChipComponent };
