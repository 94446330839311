var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MedicService } from '@services/medic/medic.service';
import { BehaviorSubject, lastValueFrom, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
let HsDrugFormComponent = class HsDrugFormComponent {
    constructor(_fuseTranslationLoaderService, _medicService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._medicService = _medicService;
        this.searchFormControl = new UntypedFormControl();
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        this.isLoading = new BehaviorSubject(false);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // TODO: Implement mpx-search-theriaque-laboratory
        // Bind the search method for the autocomplete
        // On labo exp field, if changes are detected, if field is null trigger empty observable else trigger observable with labs names
        this.autocompleteLabExpList = this.formHealthProductRef.get('operator').valueChanges.pipe(debounceTime(500), takeUntil(this._unsubscribeAll), switchMap((searchValue) => typeof searchValue === 'string' && (searchValue === null || searchValue === void 0 ? void 0 : searchValue.length) > 0
            ? this._searchLaboExpInTheriaqueNames(searchValue)
            : of([])));
        if (this.formManual.value === true) {
            this.formHealthProductRef.get('healthProducts').disable();
        }
        // Handle form manual checkbox
        this.formManual.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next: (value) => {
                if (value) {
                    this.formHealthProductRef.get('healthProducts').disable();
                }
                else {
                    this.formHealthProductRef.get('healthProducts').enable();
                }
                this._updateHealthProducts([]);
            }
        });
        // Suscribe to healthProducts changes after init to mark form as dirty
        this._initHealthProducts().then(() => this.formHealthProductRef
            .get('healthProducts')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe({ next: () => this.formHealthProductRef.get('healthProducts').markAsDirty() }));
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    _initHealthProducts() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if ((_a = this.formHealthProductRef) === null || _a === void 0 ? void 0 : _a.get('ref').value) {
                yield this._searchDrugs();
            }
        });
    }
    _searchLaboExp(value) {
        return this._medicService
            .findLaboratoryExp(value)
            .pipe(map((elem) => elem.map(({ cdf_nom, cdf_code_pk }) => ({ name: cdf_nom, code: cdf_code_pk }))));
    }
    _searchLaboExpInTheriaqueNames(value) {
        if (this.formManual.value) {
            if (this.laboratoriesExp && Array.isArray(this.laboratoriesExp)) {
                return of(this.laboratoriesExp.filter((elem) => elem.name.toUpperCase().includes(value.toUpperCase())));
            }
            else {
                return this._searchLaboExp(value);
            }
        }
    }
    _searchDrugs() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Toggle flag isLoading
            this.isLoading.next(true);
            try {
                const healthProducts = yield lastValueFrom(
                // Read drug from UCD and extract presentations
                // Read presentations from UCD does not exists yet and Serge is gone...
                this._medicService
                    .findDrugByName((_a = this.formHealthProductRef) === null || _a === void 0 ? void 0 : _a.get('ref').value, { cleanDci: true, labos: true })
                    .pipe(filter((drugs) => drugs.length === 1), map((drugs) => drugs.pop()), map(({ cip13 }) => cip13)));
                this._updateHealthProducts(healthProducts);
            }
            catch (_b) {
                this._updateHealthProducts([]);
            }
            // Toggle flag isLoading
            this.isLoading.next(false);
        });
    }
    /**
     * Update local presentations with presentations get from Theriaque db
     * @param healthProducts Presentations get from Theriaque db
     */
    _updateHealthProducts(healthProducts) {
        this.theriaquePresentations = healthProducts.map((cip) => ({ name: cip.presentation, ref: cip.cip13 }));
        // Select unique presentation by default
        if (this.theriaquePresentations.length === 1) {
            const formPresentation = this.formHealthProductRef.get('healthProducts');
            formPresentation.setValue(this.theriaquePresentations);
            // Allow form to be submitted after modification in edit mode
            this.formHealthProductRef.markAsDirty();
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    isRequired(control) {
        return control.hasValidator(Validators.required);
    }
    /**
     * Reset drug form inputs
     */
    onResetDrugForm() {
        this.formHealthProductRef.reset({ healthProducts: [] });
        this._updateHealthProducts([]);
    }
    onOptionSelected(selected) {
        this.formHealthProductRef.get('operator').setValue(selected.name);
    }
    compareCIP13Objects(drug1, drug2) {
        var _a, _b;
        return ((_a = drug1 === null || drug1 === void 0 ? void 0 : drug1.ref) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = drug2 === null || drug2 === void 0 ? void 0 : drug2.ref) === null || _b === void 0 ? void 0 : _b.toString());
    }
    /**
     * Handle search-medic-clean-dci onSelected output
     * @param drug Drug object returned by Theriaque db
     */
    onMedicChange(drug) {
        return __awaiter(this, void 0, void 0, function* () {
            // Update drug values from result
            this.formHealthProductRef.setValue({
                ref: drug.ucd13,
                name: drug.denomination,
                ATC: drug.sp_catc_code_fk,
                laboratoryTit: drug.laboratory_tit,
                operator: drug.laboratory_exp,
                healthProducts: []
            });
            // Update presentation
            this._updateHealthProducts(drug.cip13);
            // Allow form to be submitted
            this.formHealthProductRef.markAsDirty();
            // Reset search input value
            this.searchFormControl.setValue('');
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof FormGroup !== "undefined" && FormGroup) === "function" ? _c : Object)
], HsDrugFormComponent.prototype, "formHealthProductRef", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _d : Object)
], HsDrugFormComponent.prototype, "formManual", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsDrugFormComponent.prototype, "laboratoriesExp", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Observable !== "undefined" && Observable) === "function" ? _e : Object)
], HsDrugFormComponent.prototype, "alreadyExists$", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsDrugFormComponent.prototype, "alreadyExistsLabel", void 0);
HsDrugFormComponent = __decorate([
    Component({
        selector: 'mpx-hs-form-drug',
        template: require('./hs-form-drug.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof MedicService !== "undefined" && MedicService) === "function" ? _b : Object])
], HsDrugFormComponent);
export { HsDrugFormComponent };
