export class MpLastFilesComponent {
    constructor() {
        this.controller = 'MpLastFilesController';
        this.controllerAs = 'vm';
        this.template = require('./mp-last-files.html').default;
        this.bindings = {
            options: '=',
        };
    }
}
