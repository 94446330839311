var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { UntypedFormControl } from '@angular/forms';
let ExSearchDrugComponent = class ExSearchDrugComponent {
    constructor() {
        this.medicForm = new UntypedFormControl();
        this.pageHeaderConfig = {
            title: 'Medic Search Component',
            icon: 'extension '
        };
        this.cleanDci = false;
    }
    medicChange(data) {
        this.onChangeResult = data;
    }
};
ExSearchDrugComponent = __decorate([
    Component({
        selector: 'ex-search-drug',
        template: require('./ex-search-drug.component.html').default,
        styles: [require('./ex-search-drug.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [])
], ExSearchDrugComponent);
export { ExSearchDrugComponent };
