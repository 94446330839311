import * as angular from 'angular';
export class PasswordValidatorDirective {
    /** @ngInject */
    constructor() {
        this.compile = (elem) => {
            const parent = elem.parent();
            parent.addClass('password-validator');
            parent.append(angular.element('<md-button class="md-icon-button password-validator-info">'
                + '   <md-icon md-font-icon="icon-question-mark-circle"></md-icon>'
                + '   <md-tooltip>'
                + '      <span translate="MAPUI.GENERAL.PASSWORD.CHANGE.VALIDATOR_INFO"></span>'
                + '   </md-tooltip>'
                + '</md-button>'));
            return {
                post: (scope, elem, attr, ctrl) => {
                    if (!ctrl)
                        return;
                    const ngModel = ctrl[0];
                    ngModel.$validators.passwordNeedUppercase = (value) => {
                        return !!value && !!value.match(this._hasOneOrMoreUppercasePattern);
                    };
                    ngModel.$validators.passwordNeedLowercase = (value) => {
                        return !!value && !!value.match(this._hasOneOrMoreLowercasePattern);
                    };
                    // ngModel.$validators.passwordNeedSpecial = (value) => {
                    //   return !!value && !!value.match(this._hasOneOrMoreSpecialCharPattern);
                    // };
                    ngModel.$validators.passwordNeedDigit = (value) => {
                        return !!value && !!value.match(this._hasOneOrMoreDigitPattern);
                    };
                    ngModel.$validators.passwordIsTooShort = (value) => {
                        return !!value && value.length >= this._minLength;
                    };
                },
            };
        };
        this.restrict = 'A';
        this.require = ['ngModel'];
        this._hasOneOrMoreUppercasePattern = /^(?=.*[A-Z]).+$/;
        this._hasOneOrMoreLowercasePattern = /^(?=.*[a-z]).+$/;
        // this._hasOneOrMoreSpecialCharPattern = /^(?=.*[!@#&;:"'`/§°_€%£=,<>áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ~¨} \+\|\?\{\[\]\$\^\*\.\-\(\)\\]).+$/;
        this._hasOneOrMoreDigitPattern = /^(?=.*[0-9]).+$/;
        this._minLength = 8;
    }
    static factory() {
        const directive = () => new PasswordValidatorDirective();
        directive.$inject = [];
        return directive;
    }
}
