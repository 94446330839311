import * as angular from 'angular';
export class MpModalImageDirective {
    /** @ngInject */
    constructor($rootElement) {
        this.$rootElement = $rootElement;
        this.compile = (tElement) => {
            return (scope, element, attrs) => {
                const root = angular.element(attrs.parent || '#loanModal');
                const modal = angular.element(element[0].querySelector('.mp-modal-image-modal'));
                modal.removeClass('ng-show');
                modal.addClass('ng-hide');
                modal.on('click', (event) => {
                    event.preventDefault();
                    element.prepend(modal);
                    modal.removeClass('ng-show');
                    modal.addClass('ng-hide');
                });
                element.on('click', () => {
                    root.prepend(modal);
                    modal.removeClass('ng-hide');
                    modal.addClass('ng-show');
                });
            };
        };
        this.template = require('./mp-modal-image.html').default;
        this.controller = 'MpModalImageController';
        this.controllerAs = 'vm';
        this.scope = {};
        this.bindToController = {
            src: '<',
            parent: '@',
        };
    }
    static factory() {
        const directive = ($rootElement) => new MpModalImageDirective($rootElement);
        directive.$inject = ['$rootElement'];
        return directive;
    }
}
