var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { CSidebarHistory } from '@app/hospistock/shared/constTypes/hs-sidebar-history.const';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { EHsPublicationHistoryType, EHsUserTypes, HsPublicationHistoryASchema } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import { IdentityService } from '@services/identity/identity.service';
let HsSidebarHistoryItemComponent = class HsSidebarHistoryItemComponent {
    constructor(_identityService, _publicationContainerService) {
        this._identityService = _identityService;
        this._publicationContainerService = _publicationContainerService;
        this.ePublicationHistoryType = EHsPublicationHistoryType;
        this.user = this._identityService.user;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        const historyAuthor = this.history.author;
        const historyGroupAuthor = this.history.authorGroup;
        const isSameHospital = Utils.compareIds(this.user.hospital, historyAuthor.hospital);
        if (this.history.authorType === EHsUserTypes.PUI || this.history.authorType === EHsUserTypes.LABO) {
            this.author = isSameHospital ? historyAuthor.fullName : historyAuthor.hospital.name;
        }
        else if (this.history.authorType === EHsUserTypes.GA) {
            this.author = isSameHospital ? historyAuthor.fullName : historyGroupAuthor.name;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getHistoryText(historyEnum) {
        var _a;
        return (_a = CSidebarHistory[historyEnum]) === null || _a === void 0 ? void 0 : _a.translate;
    }
    getRemainderConservation(remainderConservationValue) {
        return CSidebarHistory[Utils.getYesNoNotApplicableValue(remainderConservationValue)].translate;
    }
    getRegulatoryQuotaText(regulatoryQuotaValue) {
        return CSidebarHistory[Utils.getYesNoUnKnownValue(regulatoryQuotaValue)].translate;
    }
    getTypeLabel(value) {
        return this._publicationContainerService.getTypeLabel(value);
    }
    getUnitPackagingText(unitPackagingValue) {
        return CSidebarHistory[Utils.getYesNoUnKnownValue(unitPackagingValue)].translate;
    }
    getPresentations(presentations) {
        return presentations.map(({ ref }) => ref).join(', ');
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof HsPublicationHistoryASchema !== "undefined" && HsPublicationHistoryASchema) === "function" ? _c : Object)
], HsSidebarHistoryItemComponent.prototype, "history", void 0);
HsSidebarHistoryItemComponent = __decorate([
    Component({
        selector: 'mpx-hs-sidebar-history-item',
        template: require('./hs-sidebar-history-item.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object, typeof (_b = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _b : Object])
], HsSidebarHistoryItemComponent);
export { HsSidebarHistoryItemComponent };
