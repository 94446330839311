export class CarePlanService {
    /** @ngInject */
    constructor(Api, Access, $q, $http) {
        this.Api = Api;
        this.Access = Access;
        this.$q = $q;
        this.$http = $http;
    }
    /**
     * Get CarePlan by MedicationReconciliation id
     * @param Mid
     */
    getCarePlan(Mid) {
        const defer = this.$q.defer();
        this.Api.carePlan.get({ id: Mid }, (result) => {
            defer.resolve(result);
        }, (err) => {
            defer.reject(err);
        });
        return defer.promise;
    }
    addCarePlane(carePlane) {
        const defer = this.$q.defer();
        this.Api.carePlan.add(carePlane, (result) => {
            defer.resolve(result);
        }, (err) => defer.reject(err));
        return defer.promise;
    }
    saveCarePlane(carePlane) {
        const defer = this.$q.defer();
        this.Api.carePlan.save(carePlane, (result) => {
            defer.resolve(result);
        }, (err) => defer.reject(err));
        return defer.promise;
    }
    downloadCarePlanPDF(carePlan, patient) {
        const defer = this.$q.defer();
        this.$http({
            method: 'GET',
            url: `/pdf-generation/plan-de-prise_${patient.firstName}${patient.familyName}.pdf?pdfmodel=care-plan&cpid=${carePlan._id}&pid=${patient._id}`,
            headers: {
                'Content-Type': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })
            .then((ans) => {
            defer.resolve(ans);
        }).catch((ans) => {
            defer.reject(ans.statusText);
        });
        return defer.promise;
    }
}
