export const locale = {
    lang: 'en',
    data: {
        listHospitals: {
            TITLE: 'Hospitals',
            SEARCH_HOSPITAL: 'Search a hospital',
            TABLE: {
                NAME: 'Name',
                FINESS_CODES: 'Finess codes',
                GROUP: 'Group',
                ACCOUNTS: 'Accounts',
                ROLES: 'Roles'
            },
            DELETED: 'The hospital has been deleted!',
            CONFIRM_MESSAGE: 'Do you really want to delete this hospital?',
            IMPORT_BTN: `Importer`
        }
    }
};
