var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { ExternalConfigurationService as AjsExternalConfigurationService } from '../../../main/services/external-actors/external-configuration.service';
import { EHTTPStatus, ExternalConfiguration } from '@mapuilabs/mpl-interfaces';
import { from, throwError } from 'rxjs';
let ExternalConfigurationService = class ExternalConfigurationService {
    constructor(ajsExtConfService) {
        this.ajsExtConfService = ajsExtConfService;
    }
    resolve(params) {
        return new Promise((resolve, reject) => {
            if (params.id == 'new') {
                resolve(new ExternalConfiguration());
            }
            else {
                this.getById(params.id).then((ans) => {
                    resolve(new ExternalConfiguration(ans));
                }).catch(() => reject);
            }
        });
    }
    getById(id) {
        return this.ajsExtConfService.getById(id);
    }
    save(config) {
        let promise = new Promise((resolve, reject) => {
            this.ajsExtConfService.save(config).then((ans) => {
                resolve(new ExternalConfiguration(ans));
            }).catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    add(config) {
        let promise = new Promise((resolve, reject) => {
            this.ajsExtConfService.create(config).then((ans) => {
                resolve(ans);
            }).catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
    delete(config) {
        let promise = new Promise((resolve, reject) => {
            this.ajsExtConfService.delete(config).then((ans) => {
                resolve(ans);
            }).catch(() => {
                return throwError(() => EHTTPStatus.InternalServerError);
            });
        });
        return from(promise);
    }
};
ExternalConfigurationService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AjsExternalConfigurationService !== "undefined" && AjsExternalConfigurationService) === "function" ? _a : Object])
], ExternalConfigurationService);
export { ExternalConfigurationService };
