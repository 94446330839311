export const locale = {
    lang: 'en',
    data: {
        PATIENTS: {
            TITLE: 'Patients dashboard',
            OLD_LIST: `Old patients dashboard`,
            SEARCH: 'Search patient',
            STATUS: {
                NOT_REALIZED: 'not realized'
            },
            ARCHIVED: 'Archived patients',
            MODAL: {
                DELETE: {
                    TITLE: 'Delete patient',
                    MESSAGE: 'Do you really want to delete this patient?'
                }
            },
            TOOLTIPS: {
                FILTER_SIDEBAR: 'Display filters'
            },
            TOASTS: {
                KO: 'Error occured.',
                DELETE_PATIENT: {
                    OK: 'Patient deleted.'
                }
            },
            TABLE: {
                HEADERS: {
                    PATIENT: 'Patient',
                    PPI: 'PPI',
                    SERVICE: 'Service',
                    ADMISSION: 'Admission',
                    EXIT: 'Exit'
                },
                MENU: {
                    SEE_MORE: 'Details',
                    DELETE: 'Delete'
                },
                TITLES: {
                    PATIENT_INFOS: 'Patient informations',
                    ENTRANCE_MEDREC: 'Entrance medication reconciliation',
                    EXIT_MEDREC: 'Exit medication reconciliation',
                    REFERENCE: {
                        TITLE: `Reference pharmacy(ies)`,
                        NO_REF: `No reference pharmacy has been provided for this patient.`
                    }
                },
                LABELS: {
                    CONSENT: 'Consent:',
                    SOCIAL_NUMBER: 'Social number:',
                    TRANSFER: 'Transferred by:',
                    PATHWAYS: 'Pathways:',
                    EPI: 'EPI:',
                    PROCESS: 'Processus:',
                    DBS: 'DBS:',
                    CTM: 'Reconciliation sheet:',
                    PATIENT_FILE: 'Patient file'
                }
            }
        }
    }
};
