import { EFileType, EMpStatus } from '@mapuilabs/mpl-interfaces';
export class HvModalImportPrescriptionController {
    /** @ngInject */
    constructor(FileUploaderService, ModalService) {
        this.FileUploaderService = FileUploaderService;
        this.ModalService = ModalService;
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            if (this.FileUploaderService.uploader.queue.length) {
                this.FileUploaderService.upload(EFileType.Hospiville)
                    .then(files => {
                    this.ModalService.close(files);
                });
            }
            else {
                this.ModalService.close([]);
            }
        };
    }
    $onInit() {
        this.FileUploaderService.init(true);
        this.uploader = this.FileUploaderService.uploader;
    }
}
