var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
let TranslationService = class TranslationService {
    constructor(_translateService // ngx translate service
    ) {
        this._translateService = _translateService;
        /**
         * Gets the translation of a given value
         * @param from: Object containing the needed translation
         * @param value
         * @param interpolateParams
         * @returns {string}
         */
        this.getTranslation = (from, value, interpolateParams) => {
            var _a, _b;
            if (!from || value == null) {
                return '';
            }
            // isNaN(undefined) = true but isNaN(null) is false
            // !value = false if value = 0
            for (const key in from) {
                if (((_a = from[key]) === null || _a === void 0 ? void 0 : _a.value) === value || ((_b = from[key]) === null || _b === void 0 ? void 0 : _b.stringValue) === value) {
                    return this.instant(from[key].translate, interpolateParams);
                }
            }
            return '';
        };
    }
    /**
     * Calls the ngx TranslateService instant method. Only one service is
     * instantiated in components' controllers.
     * @param key
     * @param interpolateParams
     */
    instant(keys, interpolateParams) {
        if (Array.isArray(keys)) {
            return keys
                .map((key, index) => {
                const translation = String(this._translateService.instant(key, interpolateParams));
                return index === 0 ? translation : translation.toLocaleLowerCase();
            })
                .join(' ');
        }
        else {
            return this._translateService.instant(keys, interpolateParams);
        }
    }
    setLanguage(lang) {
        return this._translateService.use(lang);
    }
};
TranslationService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslateService // ngx translate service
         !== "undefined" && TranslateService // ngx translate service
        ) === "function" ? _a : Object])
], TranslationService);
export { TranslationService };
