import { EDrugBalanceType, EMpStatus, } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class HvModalDrugBalanceEntryController {
    /** @ngInject */
    constructor(ModalService, HospivilleService, DrugBalanceService, Api, Access, $rootScope, _) {
        this.ModalService = ModalService;
        this.HospivilleService = HospivilleService;
        this.DrugBalanceService = DrugBalanceService;
        this.Api = Api;
        this.Access = Access;
        this.$rootScope = $rootScope;
        this._ = _;
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[from], { value: value }).translate;
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = ({ dbs, instances }) => {
            this.dbs = dbs;
            this._close(this.dbs);
        };
        /**
         * Close the modal with actions performing
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        this.Access.changePatient(Utils.getId(this.activity.patient), Utils.getId(this.activity.medicationReconciliation));
        this.$rootScope.$emit('refreshPatient');
        this.HospivilleService.getPatient().then((patient) => {
            this.patient = patient;
            this.source = this._.find(this.patient.sources, (src) => {
                return src.establishment && src.establishment.finessCode === this.Access.hospital.finessCode;
            });
        });
        this.DrugBalanceService.getByMid(EDrugBalanceType.DrugBalance, this.activity.medicationReconciliation._id).then((dbs) => {
            this.dbs = dbs;
        });
    }
}
