import { ESortingDirection } from '@mapuilabs/mpl-interfaces';
export const CAvailableSortStates = {
    default: { direction: undefined, icon: 'unfold_more' },
    asc: { direction: ESortingDirection.asc, icon: 'keyboard_arrow_up' },
    desc: { direction: ESortingDirection.desc, icon: 'keyboard_arrow_down' }
};
export const CAvailableHospitalSort = [
    {
        title: 'CREATION_DATE',
        active: 'creationDate'
    },
    {
        title: 'END_DATE',
        active: 'endDate'
    },
    {
        title: 'EXPIRATION_DATE',
        active: 'expiryDate'
    }
];
export const CAvailableSort = [
    {
        title: 'CREATION_DATE',
        active: 'creationDate'
    },
    {
        title: 'EXPIRATION_DATE',
        active: 'expiryDate'
    },
    {
        title: 'QUANTITY',
        active: 'quantity'
    },
    {
        title: 'DISTANCE',
        active: 'hospital.calculatedDistance'
    }
];
