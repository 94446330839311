export const locale = {
    lang: 'fr',
    data: {
        AVAILABLE_WIDGET: {
            TITLE: `Dernières mises à disposition`,
            UNIT_TOTAL: ` unité | Total : `,
            NO_AVAILABLE: 'Pas de mises à disposition récentes.',
            EXPIRES: 'Expire le ',
            SEE_MORE: 'Voir plus de mises à disposition',
            FORBIDDEN_MESSAGE: "Vous n'avez pas accès à cette fonctionnalité.",
            CONTACT_US: 'Contactez-nous'
        }
    }
};
