var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule } from '@fuse/components';
import { ExchangeMapLegendComponent } from './map-legend/map-legend.component';
import { LoansFilterComponent } from './map-legend/filters/loans-filter/loans-filter.component';
import { ExchangeSearchBarComponent } from './map-legend/search-bar/search-bar.component';
import { ExchangeGroupsFilterComponent } from './map-legend/filters/groups-filter/groups-filter.component';
import { MapMadSearchComponent } from './map-legend/filters/mad-search/mad-search.component';
import { MatRippleModule } from '@angular/material/core';
let MapExchangeModule = class MapExchangeModule {
};
MapExchangeModule = __decorate([
    NgModule({
        declarations: [
            ExchangeMapLegendComponent,
            ExchangeSearchBarComponent,
            LoansFilterComponent,
            ExchangeGroupsFilterComponent,
            MapMadSearchComponent
        ],
        imports: [
            BrowserAnimationsModule,
            UIRouterModule,
            InfiniteScrollModule,
            FuseSharedModule,
            FuseSidebarModule,
            MatIconModule,
            MatButtonModule,
            MatSnackBarModule,
            MatChipsModule,
            MatListModule,
            MatCardModule,
            MatExpansionModule,
            MatTabsModule,
            MatCheckboxModule,
            MatSelectModule,
            MatRippleModule
        ],
        exports: [ExchangeMapLegendComponent],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
    })
], MapExchangeModule);
export { MapExchangeModule };
