var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { fuseAnimations } from '@fuse/animations';
import { Group } from '@mapuilabs/mpl-interfaces';
import { CGroupType } from '@shared/constTypes/group/group-type.const';
import { CGroupStructure } from '@shared/constTypes/group/group-structure.const';
import { DialogService } from '@services/dialog/dialog.service';
import { TranslationService } from '@services/translation/translation.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { AdminGroupsService } from '@services/admin/group/admin-groups.service';
let InformationTabComponent = class InformationTabComponent {
    constructor(_dialogService, _stateService, _adminGroupService, _translationService, _snackBarService) {
        this._dialogService = _dialogService;
        this._stateService = _stateService;
        this._adminGroupService = _adminGroupService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this.groupTypes = CGroupType;
        this.groupStructures = CGroupStructure;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    deleteGroup() {
        const dialogConfig = {
            title: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_GROUP.TITLE'),
            message: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_GROUP.MESSAGE'),
            confirmButton: this._translationService.instant('COMMONS.DELETE')
        };
        this._dialogService.openConfirmDialog(dialogConfig).subscribe((data) => {
            if (data) {
                this._adminGroupService.delete(this.group).subscribe(() => {
                    this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.DELETE_USER_OK'));
                    this._stateService.go('mapui.adm.groups.list');
                }, () => {
                    this._snackBarService.openDefaultError();
                });
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof FormGroup !== "undefined" && FormGroup) === "function" ? _f : Object)
], InformationTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Group !== "undefined" && Group) === "function" ? _g : Object)
], InformationTabComponent.prototype, "group", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], InformationTabComponent.prototype, "pageType", void 0);
InformationTabComponent = __decorate([
    Component({
        selector: 'mpx-information-tab',
        template: require('./information-tab.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof StateService !== "undefined" && StateService) === "function" ? _b : Object, typeof (_c = typeof AdminGroupsService !== "undefined" && AdminGroupsService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], InformationTabComponent);
export { InformationTabComponent };
