export const locale = {
    lang: 'fr',
    data: {
        RELEASE_NOTES_WIDGET: {
            FORBIDDEN_MESSAGE: `Vous n'avez pas accès à cette fonctionnalité.`,
            NO_LINES: `Aucune nouveauté à afficher.`,
            CONTACT_US: `Contactez-nous`,
            DETAILS: `Plus d'infos`,
            MORE_LINES: `Charger plus`
        }
    }
};
