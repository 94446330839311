var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Member } from '@mapuilabs/mpl-interfaces';
import { Observable } from 'rxjs';
import { RolesService } from '../../../../services/role/roles.service';
let RolesTabComponent = class RolesTabComponent {
    constructor(_rolesService) {
        this._rolesService = _rolesService;
        /**
         *
         * @param member
         * @private
         */
        this._setFormControls = (member) => {
            const mapuiRolesDefault = [];
            const hospivilleRolesDefault = [];
            const hospistockRolesDefault = [];
            const workspaceRolesDefault = [];
            const adminPanelRolesDefault = [];
            if (member) {
                if (member.roles) {
                    for (const role of member.roles) {
                        if (this.mapuiRoles &&
                            this.mapuiRoles.length &&
                            this.mapuiRoles.filter((elem) => elem._id == role._id).length) {
                            mapuiRolesDefault.push(this.mapuiRoles.find((elem) => elem._id == role._id));
                        }
                        else if (this.hospivilleRoles &&
                            this.hospivilleRoles.length &&
                            this.hospivilleRoles.filter((elem) => elem._id == role._id).length) {
                            hospivilleRolesDefault.push(this.hospivilleRoles.find((elem) => elem._id == role._id));
                        }
                        if (this.hospistockRoles &&
                            this.hospistockRoles.length &&
                            this.hospistockRoles.filter((elem) => elem._id == role._id).length) {
                            hospistockRolesDefault.push(this.hospistockRoles.find((elem) => elem._id == role._id));
                        }
                        if (this.workspaceRoles &&
                            this.workspaceRoles.length &&
                            this.workspaceRoles.filter((elem) => elem._id == role._id).length) {
                            workspaceRolesDefault.push(this.workspaceRoles.find((elem) => elem._id == role._id));
                        }
                        if (this.adminPanelRoles &&
                            this.adminPanelRoles.length &&
                            this.adminPanelRoles.filter((elem) => elem._id == role._id).length) {
                            adminPanelRolesDefault.push(this.adminPanelRoles.find((elem) => elem._id == role._id));
                        }
                    }
                }
            }
            this.form.setControl('roleMapui', new UntypedFormControl(mapuiRolesDefault));
            this.form.setControl('roleHospiville', new UntypedFormControl(hospivilleRolesDefault));
            this.form.setControl('roleHospistock', new UntypedFormControl(hospistockRolesDefault));
            this.form.setControl('roleWorkspace', new UntypedFormControl(workspaceRolesDefault));
            this.form.setControl('isAdmin', new UntypedFormControl(member ? member.isAdmin : null));
            this.form.setControl('roleAdminPanel', new UntypedFormControl(adminPanelRolesDefault));
        };
        // Used by the parent component to construct the updated user instance.
        this.updateMember = (member) => {
            return new Observable((observer) => {
                member.roles = this.form.value.roleMapui.concat(this.form.value.roleHospiville, this.form.value.roleHospistock, this.form.value.roleWorkspace, this.form.value.roleAdminPanel);
                member.isAdmin = this.form.value.isAdmin;
                observer.next();
                observer.complete();
            });
        };
    }
    ngOnChanges(changes) {
        if (changes.member) {
            this._setFormControls(this.member);
        }
    }
    ngOnInit() {
        this._rolesService.getAllMember(this.member._id).subscribe((data) => {
            for (const project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'hospistock':
                        this.hospistockRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                        break;
                    case 'adminPanel':
                        this.adminPanelRoles = project.roles;
                }
            }
            this._setFormControls(this.member);
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], RolesTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Member !== "undefined" && Member) === "function" ? _c : Object)
], RolesTabComponent.prototype, "member", void 0);
RolesTabComponent = __decorate([
    Component({
        selector: 'mpx-roles-tab',
        template: require('./roles-tab.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof RolesService !== "undefined" && RolesService) === "function" ? _a : Object])
], RolesTabComponent);
export { RolesTabComponent };
