var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NgModule } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { GroupService } from '@services/group/group.service';
import { EHospistockPermission, EMaPUIPermission } from '@mapuilabs/mpl-interfaces';
import { lastValueFrom } from 'rxjs';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { ListGroupsComponent } from './list-groups/list-groups.component';
const states = [
    {
        name: 'mapui.setting.groups.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: ListGroupsComponent
            }
        },
        data: {
            theme: 'theme-blue',
            access: [
                EMaPUIPermission.Groups_Read,
                EHospistockPermission.PurchaseGroupDrugPublication_Read,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Read
            ]
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.setting.groups.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: GroupDetailComponent
            }
        },
        data: {
            theme: 'theme-blue',
            access: [
                EMaPUIPermission.Groups_Read,
                EHospistockPermission.PurchaseGroupDrugPublication_Read,
                EHospistockPermission.PurchaseGroupMedicalDevicePublication_Read
            ]
        },
        params: {
            id: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'group',
                deps: [Transition, GroupService],
                resolveFn: (trans, groupService) => __awaiter(void 0, void 0, void 0, function* () {
                    const idParam = trans.params().id;
                    if (idParam && idParam != 'new') {
                        const group = yield lastValueFrom(groupService.getById(idParam));
                        return group;
                    }
                })
            }
        ]
    }
];
let GroupsRoutingModule = class GroupsRoutingModule {
};
GroupsRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], GroupsRoutingModule);
export { GroupsRoutingModule };
