import { ENotificationType } from '@mapuilabs/mpl-interfaces';
/** @ngInject */
export class MpLoanLiteController {
    /** @ngInject */
    constructor(LoanService, ModalService, NotificationService, DashboardService, $state, ToastService) {
        this.LoanService = LoanService;
        this.ModalService = ModalService;
        this.NotificationService = NotificationService;
        this.DashboardService = DashboardService;
        this.$state = $state;
        this.ToastService = ToastService;
        /**
         * On destroy remove listener
         */
        this.$onDestroy = () => {
            this.NotificationService.unregister(this._listener);
        };
        /**
         * Get the last 10 available.
         * @private
         */
        this._getAvailable = () => {
            this.available = [];
            this.LoanService.getLastAvailable(10).then((res) => {
                this.available = res;
            });
        };
        /**
         * Get the last 10 requests.
         * @private
         */
        this._getRequests = () => {
            this.requests = [];
            this.LoanService.getLastRequests(10).then((res) => {
                this.requests = res;
            });
        };
        /**
         * Function opens available modal and save the [[ILoan]] if changed.
         * @param event the [[Event]]
         * @param available the [[ILoan]] selected
         */
        this.openAvailable = (event, available) => {
            this.$state.go('mapui.exchangeV2.available.available.available', { id: available._id });
        };
        /**
         * Function open request modal and save the [[ILoan]] if changed.
         * @param event the [[Event]]
         * @param request the [[ILoan]] selected
         */
        this.openRequest = (event, request) => {
            this.DashboardService.openRequest(event, request)
                .then(() => {
                this._getRequests();
            })
                .catch((err) => this.ToastService.show(err));
        };
        this._listener = this.NotificationService.register([ENotificationType.Available, ENotificationType.Request], (notif) => {
            if (ENotificationType.Available === notif.type) {
                this._getAvailable();
            }
            else {
                this._getRequests();
            }
        });
    }
    $onInit() {
        this._getAvailable();
        this._getRequests();
    }
}
