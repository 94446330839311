export const loansBillConsts = {
    tva: [
        {
            name: '2.1',
            value: 2.1
        },
        {
            name: '5.5',
            value: 5.5
        },
        {
            name: '10',
            value: 10
        },
        {
            name: '20',
            value: 20
        }
    ],
    bill_type: [
        {
            name: '%',
            value: true
        },
        {
            name: '€',
            value: false
        }
    ]
};
