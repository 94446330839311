import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { EHospistockPermission, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import * as moment from 'moment';
// -----------------------------------------------------------------------------------------------------
// @ HsAlternative Service Config
// -----------------------------------------------------------------------------------------------------
const translationBase = 'PUBLICATION.LABO';
// Permission for create / update / delete set to empty array to force readonly to true
const permissions = {
    create: [],
    read: EHospistockPermission.LaboDrugPublication_Read,
    update: [],
    delete: []
};
export const hsLaboAlternativeServiceConfig = {
    endPointBase: 'api/hospistock/labo/drug/alternative',
    permissions
};
// -----------------------------------------------------------------------------------------------------
// @ HsAlternative Config
// -----------------------------------------------------------------------------------------------------
// Page Header config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant('PUBLICATION.TITLES.MAIN_LABO_ALTERNATIVES'),
    subtitle: translationService.instant('COMMONS.WORDS.DRUGS'),
    searchBarPlaceholder: translationService.instant('PUBLICATION.SEARCH'),
    svgIcon: 'cart-remove'
});
// Table Config
const tableColumns = (translationService) => {
    const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);
    const concatRef = (healthProducts) => { var _a; return (_a = healthProducts === null || healthProducts === void 0 ? void 0 : healthProducts.map(({ ref }) => ref)) === null || _a === void 0 ? void 0 : _a.join('; '); };
    const formatSource = (alternative) => {
        const getName = (creator) => typeof creator === 'string' ? creator : creator.name;
        return alternative.creatorType === EHsUserTypes.GA
            ? getName(alternative.creatorGroup)
            : getName(alternative.creator);
    };
    return [
        // publication
        {
            label: translationService.instant('COMMONS.WORDS.DCI'),
            attribute: 'alternative.publication.healthProductRef.dcis',
            sort: false,
            transform: (dcis) => dcis === null || dcis === void 0 ? void 0 : dcis.join('; '),
            tooltip: translationService.instant('PUBLICATION.TOOLTIPS.PRODUCT_OUT_OF_STOCK')
        },
        {
            label: translationService.instant('COMMONS.WORDS.VIRTUAL_DRUG'),
            template: 'virtual',
            sort: false
        },
        {
            label: translationService.instant('COMMONS.WORDS.TYPE'),
            template: 'type',
            classes: ['max-w-128']
        },
        {
            label: translationService.instant('COMMONS.WORDS.DATE_START'),
            attribute: 'alternative.publication.startDate',
            sort: true,
            transform: formatDate,
            classes: ['w-88']
        },
        {
            label: translationService.instant('COMMONS.WORDS.DATE_END'),
            attribute: 'alternative.publication.endDate',
            sort: true,
            transform: formatDate,
            classes: ['w-88']
        },
        // alternative
        {
            label: translationService.instant(translationBase + '.TABLE.ALTERNATIVE'),
            attribute: 'alternative.healthProductRef.name',
            sort: true,
            tooltip: translationService.instant('PUBLICATION.TOOLTIPS.YOUR_PRODUCT')
        },
        {
            label: translationService.instant(translationBase + '.TABLE.UCD13'),
            attribute: 'alternative.healthProductRef.ref',
            sort: true,
            classes: ['w-120']
        },
        {
            label: translationService.instant(translationBase + '.TABLE.CIP13'),
            attribute: 'alternative.healthProductRef.healthProducts',
            sort: false,
            transform: concatRef,
            classes: ['w-120']
        },
        {
            label: translationService.instant(translationBase + '.TABLE.SOURCE'),
            attribute: 'alternative',
            sort: false,
            transform: formatSource
        },
        {
            label: translationService.instant(translationBase + '.TABLE.CREATION_DATE'),
            attribute: 'alternative.creationDate',
            sort: true,
            transform: formatDate,
            classes: ['w-88']
        },
        {
            label: translationService.instant(translationBase + '.TABLE.PUI_ESTABLISHMENT'),
            attribute: 'metadata.hospital.name',
            sort: true
        },
        {
            label: translationService.instant(translationBase + '.TABLE.SELECTION_DATE'),
            attribute: 'metadata.selectedAlternatives[0].date',
            sortAttribute: 'metadata.selectedAlternatives.date',
            sort: true,
            transform: formatDate,
            classes: ['w-88']
        }
    ];
};
export const hsAlternativeConfig = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        pageHeader: pageHeaderConfig(translationService),
        translationBase,
        columns: tableColumns(translationService)
    };
};
