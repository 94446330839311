export const locale = {
    lang: 'en',
    data: {
        COMMONS: {
            ACCEPT: 'Accept',
            ADD: 'Add',
            APPLY: 'Apply',
            ARCHIVE: 'Archive',
            ARCHIVED: 'Archived',
            ASK_CONFIRM: 'Confirm?',
            CANCEL: 'Cancel',
            CONFIRM: 'Confirm',
            COPY: 'Copy',
            CLOSE: `Close`,
            CLOSED: 'Closed',
            CREATE: 'Create',
            CREATED: 'Created',
            DEFAULT: 'By default',
            DELETE: 'Delete',
            DELETED: 'Deleted',
            DETAIL: 'Detail',
            EDIT: 'Edit',
            EDITED: 'Edited',
            NEW: 'New',
            NO: 'No',
            NO_RESULT_FOUND: 'No result found',
            OK: 'Ok',
            OPTIONAL: 'Optional',
            OPEN: `Open`,
            OPEN_ADJ: 'Open',
            PREV: 'Prev',
            RESET: 'Reset',
            REFUSE: 'Refuse',
            SAVE: 'Save',
            SAVED: 'Saved',
            SELECT: 'Select',
            SEARCH: 'Search',
            UNKNOWN: 'Unknown',
            UNKNOWN_F: 'Unknown',
            UNSELECT: 'Unselect',
            VALIDATE: 'Validate',
            VALIDATED: 'Validated',
            YES: 'Yes',
            WORDS: {
                ADMIN: 'Admin',
                ATC: 'ATC',
                DATE_END: 'End date',
                DATE_START: 'Start date',
                DISPENSARY: 'Officine',
                DCI: 'DCI',
                DRUG: 'Drug',
                VIRTUAL_DRUG: 'Virtual drug',
                DRUGS: 'Drugs',
                EDIT_MENU: 'Edit menu',
                END: 'End',
                GROUP: 'Group',
                HOSPISTOCK: 'Hospistock',
                LABORATORY: 'Laboratory',
                MEDICAL_DEVICE: 'Medical device',
                MEDICAL_DEVICES: 'Medical devices',
                NAME: 'Name',
                NEW: 'New',
                NEXT: 'Next',
                NONE: 'No',
                OVER: 'Over',
                PATIENT: 'Patient',
                PERIOD: 'Period',
                PHARMACY: 'Pharmacy',
                PUBLICATION: 'Publication',
                PUBLICATIONS: 'Publications',
                REASON: 'Reason',
                REF: 'Ref',
                REFERENCE: 'Supplier reference',
                ROW: 'Row',
                SEARCH: 'Search',
                SELECTION: 'Selection',
                SOURCE: 'Source',
                START: 'Start',
                STATISTICS: 'Statistics',
                SUBSCRIBED: 'Subscribed',
                TERMINATED_F: 'Terminated',
                TERMINATED: 'Terminated',
                TEMPLATE: 'Template',
                TYPE: 'Type',
                UCD: 'UCD',
                UPDATED: 'Updated',
                CIP: 'CIP'
            },
            VERBS: {
                DOWNLOAD: 'Download',
                EXPORT: 'Export',
                IMPORT: 'Import',
                LOAD: 'Load',
                SEARCH: 'Search',
                SEE: 'See',
                UPDATE_PAST_F: 'Updated',
                UPDATE_PAST: 'Updated'
            },
            MESSAGES: {
                LOADING_DATA: 'Loading data',
                NO_DATA: 'No data',
                SEE_ALL: 'See all',
                SEE_LESS: 'See less',
                SEE_MORE: 'See more'
            },
            ERRORS: {
                DEFAULT: 'An error has occured.',
                BAD_REQUEST: 'Bad request.',
                FORBIDDEN: 'Forbidden request.',
                NOT_FOUND: 'Resoure not found',
                INTERNAL_SERVER_ERROR: 'Internal server error.',
                CONFLICT: 'Action canceled, data synchronisation...',
                NO_FILE_CONTENT: 'Could not load content.'
            },
            GENDERS: {
                FEMALE: 'Female',
                MALE: 'Male'
            },
            EMPTY_TABLE: `No result.`,
            MAIL_PRIORITY: {
                HIGH: 'High priority',
                NORMAL: 'Normal priority',
                LOW: 'Low priority'
            },
            PUNCTUATION: {
                COLON: ':',
                ELLIPSIS: '...',
                SEMICOLON: ';'
            }
        },
        PAGINATOR: {
            ITEMS_PER_PAGE: 'item per page',
            NEXT_PAGE: 'Next page',
            PREVIOUS_PAGE: 'Previous page',
            OF_LABEL: 'of'
        },
        VALIDATORS: {
            CLADIMED: 'Please select a cladimed or enter a cladimed code composed of 6 characters',
            DATE: 'This value must be a valid date',
            EMAIL: `Email is not in the right format.`,
            REQUIRED: `This field is required.`,
            NUMBER: 'This value must be numeric',
            NUMBER_OR_DECIMAL_NUMBER: 'This value must be a numeric or a decimal number',
            MANUAL_CLADIMED: 'Cladimed 6',
            UCD7_OR_13: 'A UCD must be composed of 7 or 13 digits'
        },
        UNITS: {
            BYTES: {
                B: 'bytes',
                KB: 'Kb',
                MB: 'Mb',
                GB: 'Gb',
                TB: 'Tb'
            }
        },
        TIME: {
            SECONDS: 'seconds',
            HOURS: 'hours',
            DAYS: 'days',
            DAY_ABBR: 'd',
            WEEKS: 'weeks',
            MONTHS: 'months',
            YEARS: 'years'
        },
        MAPUI: {
            GENERAL: {
                CONST_TYPE: {
                    ESTABLISHMENT: {
                        HOSPITAL: 'Hospital',
                        PHARMACY: 'Pharmacy',
                        EHPAD: 'Long-term care',
                        LABORATORY: 'Laboratory',
                        COMPANY: 'Company',
                        OTHER: 'Other',
                        ARS: 'ARS',
                        NATIONAL: 'National Entity',
                        PURCHASE_GROUP: 'Purchasing Group'
                    },
                    MEDICINE_DEPARTMENT: {
                        ADDICTOLOGY: 'Addictology',
                        ALGOLOGY: 'Algology',
                        ALCOOLOGY: 'Alcoology',
                        ALLERGOLOGY: 'Allergology',
                        PAC: 'Pathological anatomy and cytology',
                        ANDROLOGY: 'Andrology',
                        ANGIOLOGY: 'Angiology',
                        BIOCHEMISTRY: 'Biochemistry',
                        SURGERY: 'Surgery',
                        DIGESTIVE_SURG: 'Digestive surgery',
                        FACIAL_SURG: 'Maxilla-facial and plastic surgery',
                        PAEDIATRIC_SURG: 'Paediatric surgery',
                        ORTHO_SURG: 'Orthopaedic surgery and traumatology',
                        PLASTIC_SURG: 'Plastic surgery',
                        CARDIO_SURG: 'Thoracic and cardiovascular surgery',
                        UROLOGICAL_SURG: 'Urological surgery',
                        VASCULAR_SURG: 'Vascular surgery',
                        VISCERAL_SURG: 'Visceral surgery',
                        CARDIOLOGY: 'Cardiology',
                        CORONARY_ANGIOGRAPHY: 'Coronary angiography',
                        DERMATOLOGY: 'Dermatology',
                        ENDOCRINOLOGY: 'Endocrinology',
                        GYNECOLOGY: 'Gynecology',
                        GERIATRIC: 'Geriatric',
                        GERIATRIC_SHORT_STAY: 'Geriatric short stay',
                        GENETIC: 'Genetic',
                        HAEMATOLOGY: 'Haematology',
                        GASTROENTEROLOGY: 'Gastroenterology',
                        INFECTIOUS_DISEASE: 'Infectious disease',
                        INTERNAL_MEDICINE: 'Internal medicine',
                        NUCLEAR_MEDICINE: 'Nuclear medicine',
                        NEPHROLOGY: 'Nephrology',
                        NEO_NATOLOGY: 'Neo-natology',
                        NEUROSURGERY: 'Neurosurgery',
                        NEUROLOGY: 'Neurology',
                        NEUROPSYCHOLOGY: 'Neuropsychology',
                        PEDIATRIC_NEUROLOGY: 'Pediatric neurology',
                        PEDIATRICS: 'Pediatrics',
                        PNEUMOLOGY: 'Pneumology',
                        PSYCHIATRIC: 'Psychiatric',
                        OPHTHALMOLOGY: 'Ophthalmology',
                        ODONTOLOGY: 'Odontology',
                        ONCOLOGY: 'Oncology',
                        OTOLOGY: 'Otology',
                        ENT_STOMATOLOGY: 'ENT / Stomatology',
                        RADIOLOGY_IMAGERY: 'Radiology / Imagery',
                        RADIOTHERAPY: 'Radiotherapy',
                        INTENSIVE_CARE: 'Intensive care',
                        RHEUMATHOLOGY: 'Rheumathology',
                        PALLIATIVE_CARE: 'Palliative care',
                        STOMATOLOGY: 'Stomatology',
                        EMERGENCY: 'Emergency'
                    },
                    GROUP: {
                        TYPES: {
                            ALL: 'All',
                            REGION: 'Region',
                            GHT: 'GHT',
                            PURCHASE: 'Purchase',
                            ASSOCIATION: 'Association',
                            PRIVATE: 'Private group',
                            MUTUAL: 'Mutual group',
                            OTHER: 'Other',
                            PURCHASE_NON_SUBSCRIBED: 'Purchase - non subscribed'
                        }
                    }
                }
            },
            HOME_INFO_DATABASES: `The datasets of this module are made with the databases coming from ATC (property of WHO), Médicabase (property of Association Médicabase), BDPM (property of ANSM), UCD (property of CIP Club), Thériaque (property of CNHIM) and CIOdm (property of PHAST Services).`
        },
        STOCK_TYPE: {
            MEDIC: `Drug`,
            MEDICAL_DEVICE: `Medical device`
        },
        STOCK_RISK: {
            DRUGS_CAT: {
                SPECIFIC: 'Specific',
                CORTICOSTEROIDS: 'Corticosteroids',
                SEDATION: 'Sedation / Anaesthesia',
                SEDATION_STUP: 'Sedation / Anaesthesia (Narcotics)',
                LOCOREGIONAL_ANESTHESIA: 'Locoregional anesthesia',
                PAIN_RELIEVER: 'Antalgy',
                INFECTIOLOGY: 'Infectiology',
                CARDIOLOGY: 'Cardiology',
                MEDICAL_DEVICE: 'Medical device',
                DIALYSIS: 'Dialysis',
                INSULIN: 'Insulin',
                ANTITHROMBOTIC: 'Antithrombotic',
                IMMUNISUPPRESSANT: 'Immunisuppressant',
                VACCINES: 'Vaccines',
                OTHER: 'Others'
            },
            MDS_CAT: {
                CANULA: 'Cannula',
                CATHETER: 'Catheters',
                FILTER: 'Filters',
                DIALYSIS: 'Dialysis solutions and kits',
                HUMIDIFIER: 'Humidifiers',
                LARYNGOSCOPE: 'Laryngoscope Video Slide',
                MASK: 'Masks 02',
                INFUSION: 'Perfusion sets and accessories',
                SYRINGE: 'Syringes',
                PROBE: 'Probes',
                OTHER: 'Others'
            }
        },
        PHARMACIST: {
            STATUS: {
                HOD: 'Head of department',
                AH_ASSISTANT: 'Academic hospital assistant',
                SPECIALIST_ASSISTANT: 'Specialist assistant',
                INTERNAL: 'Internal',
                ASSISTANT_PHARMA: 'Assistant pharmacist',
                PHARMA_MANAGER: 'Pharmacist manager',
                ATTACHED_PRACTITIONER: 'Attached practitioner',
                AH_PRACTITIONER: 'Academic hospital practitioner',
                HOSPITAL_PRACTITIONER: 'Hospital practitioner',
                SURGEON: 'Surgeon'
            }
        },
        PHARMACY_STUDENT: {
            STATUS: {
                '5HU': '5HU student'
            }
        },
        CHANGE_PASSWORD: {
            TITLE: 'Change password',
            EXPIRED: {
                TITLE: 'Your password expired',
                SUBTITLE: 'Please change it'
            },
            TEMPORARY: {
                TITLE: 'The temporary password must be changed',
                SUBTITLE: 'Create your own password'
            },
            INPUTS: {
                CURRENT: 'Current password',
                NEW: 'New password',
                CONFIRM: 'Confirm new password'
            },
            ERRORS: {
                REQUIRED: 'This field is required',
                TOO_SHORT: 'Password must contains at least 8 characters',
                NO_UPPERCASE: 'Password must contains at least one uppercase character',
                NO_LOWERCASE: 'Password must contains at least one lowercase character',
                NO_DIGIT: 'Password must contains at least one digit',
                MISMATCH: 'Passwords mismatch',
                MATCH: 'The new password cannot be the current password',
                WRONG_PASSWORD: 'The password is incorrect'
            },
            VALIDATOR_INFO: 'The password must contains at least 8 characters including at least one uppercase, one lowercase, one digit and one special character',
            SAVE: 'Save password',
            SUCCESS_EDITED: 'Password successfully edited'
        },
        SETTINGS: {
            MAILS: {
                SUBSCRIPTION: {
                    LOAN_BORROWING: 'Loans and Borrowings',
                    AVAILABLE: 'Available',
                    PREP: 'Preparations',
                    PD: 'Product demands',
                    NEWSLETTER: 'Newsletters',
                    STOCKOUT: 'Stockouts',
                    REQUESTS: 'Drugs or medical devices requests'
                }
            },
            NOTIFICATIONS: {
                SUBSCRIPTION: {
                    LOAN: 'Loans',
                    BORROWING: 'Borrowings',
                    AVAILABLE: 'Available',
                    AVAILABLE_EXPIRE: 'Expiring available',
                    PREPARATION: 'Preparations',
                    REQUEST: 'Product demands',
                    REQUEST_EXPIRE: 'Expiring product demands',
                    NEWS: 'News',
                    HOSPITAL: "New hospital's and pharmacies",
                    FILE: 'Shared files',
                    GROUP: 'Groups and invitations',
                    USER: 'Users',
                    STOCKOUT_LABO: 'Update of laboratory stockout'
                }
            }
        },
        ROLES: {
            TYPES: {
                ESTABLISHMENT: 'Establishment',
                USER: 'User'
            },
            SUBSCRIPTIONS: {
                FREE: 'Free',
                TRIAL: 'Trial',
                SUBSCRIBED: 'Subscribed',
                NONE: 'None'
            },
            PERMISSIONS: {
                TITLE: 'Permissions',
                SEE_MENU_ITEM: 'See in menu',
                READ: 'See',
                CREATE: 'Create',
                EDIT: 'Edit',
                DELETE: 'Delete',
                SHARE: 'SHARE',
                CREATE_BORROWING: 'Loan create',
                CREATE_LOAN: 'Borrowing create',
                RETAKE: 'Retake',
                VALIDATE: 'Validate'
            }
        },
        EXCHANGES: {
            TYPES: {
                PREPARATION: 'Preparation',
                MEDIC: 'Pill',
                MEDICAL_DEVICE: 'Medical device'
            },
            AVAILABLE: {
                DRUG: {
                    PRESERVATION: {
                        FRESH_BETWEEN_8_DEG_AND_15_DEG: 'AU FRAIS (8 A 15 DEGRES)',
                        SAFE_FROM_HUMIDITY: "A L'ABRI DE L'HUMIDITE",
                        SAFE_FROM_LIGHT: "A L'ABRI DE LA LUMIERE",
                        INSIDE_FREEZER: 'AU CONGELATEUR',
                        INSIDE_FRIDGE: 'AU REFRIGERATEUR (2 A 8 DEGRES)',
                        ROOM_TEMPERATURE: 'A TEMPERATURE AMBIANTE',
                        SAFE_FROM_HEAT: "A L'ABRI DE LA CHALEUR",
                        NOT_FROZEN: 'NE PAS CONGELER',
                        NOT_STORED_IN_FRIDGE: 'NE PAS CONSERVER AU REFRIGERATEUR',
                        IN_ORIGIN_WRAP: "DANS L'EMBALLAGE D'ORIGINE",
                        SAFE_FROM_FROST: "A L'ABRI DU GEL (INFERIEUR A 0°C)",
                        CAREFULLY_CLOSED: 'SOIGNEUSEMENT FERME',
                        SAFE_FROM_FLAME: "A L'ABRI D'UNE FLAMME",
                        VERTICAL_POSITION: 'EN POSITION VERTICALE',
                        NOT_EXPOSED_TO_SUN_RAYS: 'NE PAS EXPOSER AUX RAYONS DU SOLEIL',
                        SAFE_FROM_X_RAYS: "A L'ABRI DES RAYONS X",
                        AT_37_DEG: 'A UNE TEMPERATURE DE 37 DEGRES',
                        NOT_DRILLED: 'NE PAS PERCER',
                        NOT_THROWN_TO_FIRE_EVEN_EMPTY: 'NE PAS JETER AU FEU MEME VIDE',
                        NOT_EXPOSED_TO_EXCESSIVE_HEAT: 'NE PAS EXPOSER A UNE CHALEUR EXCESSIVE',
                        SAFE_FROM_IONIZING_RAYS: "A L'ABRI DES RAYONS IONISANTS",
                        IN_CLIMATIC_AREA_1: 'EN ZONE CLIMATIQUE I',
                        SAFE_FROM_FLAMMABLE_MATERIAL: "A L'ABRI DES MATIERES COMBUSTIBLES",
                        IN_CLIMATIC_AREA_2: 'EN ZONE CLIMATIQUE II',
                        IN_CLIMATIC_AREA_3: 'EN ZONE CLIMATIQUE III',
                        IN_CLIMATIC_AREA_4: 'EN ZONE CLIMATIQUE IV',
                        NO_SPECIFIC_MEASURE: 'PAS DE PRECAUTION PARTICULIERE',
                        NOT_OVER_30_DEG: 'NE PAS DEPASSER 30 DEGRES',
                        NOT_OVER_25_DEG: 'NE PAS DEPASSER 25 DEGRES',
                        NOT_SHAKEN: 'NE PAS AGITER',
                        RESPECT_COLD_CHAIN: 'RESPECTER LA CHAINE DU FROID',
                        STRICTLY_HIGHER_15_DEG: 'A TEMPERATURE > A 15 DEGRES',
                        KEEP_AWAY_FROM_FROZEN_ITEM: 'PAS DE CONTACT AVEC OBJET CONGELE',
                        NOT_EXPOSED_HIGHER_50_DEG: 'NE PAS EXPOSER A UNE TEMP > 50 DEGRES',
                        NOT_PRESERVED_UNDER_MIN_10_DEG_CEL: 'NE PAS CONSERVER AU DESSOUS DE -10°C',
                        SAFE_FROM_BUMP_AND_DROP: "A L'ABRI DES CHOCS ET DES CHUTES",
                        STRICTLY_HIGHER_4_DEG: 'A TEMPERATURE > A 4 DEGRES',
                        NO_INFO: 'AUCUNE INFORMATION',
                        STRICTLY_HIGHER_5_DEG: 'A TEMPERATURE > A 5 DEGRES',
                        IN_ORIGINAL_PRIMARY_PACKAGING: "DANS CONDITIONNEMENT PRIMAIRE D'ORIGINE",
                        STORED_LOWER_OR_EQUAL_THAN_MIN_18_DEG: 'CONSERVER A UNE TEMPERATURE < OU = A -18 DEGRES',
                        NOT_OVER_MIN_20_DEG: 'NE PAS DEPASSER TEMP > - 20 DEGRES',
                        NOT_STORED_UNDER_2_DEG_CEL: 'NE PAS CONSERVER AU DESSOUS DE 2°C',
                        CHECK_LOOK_IF_EXPOSED_TO_HEAT: 'SI EXPOSITION CHALEUR : VERIFIER ASPECT',
                        NOT_STORED_UNDER_MIN_20_DEG_CEL: 'NE PAS CONSERVER AU DESSOUS DE -20°C',
                        NOT_STORED_UNDER_MIN_10_DEG_CEL: 'NE PAS CONSERVER AU DESSUS DE -10°C',
                        NOT_HIGHER_22_DEG: 'NE PAS DEPASSER 22 DEGRES',
                        NOT_STORED_UNDER_18_DEG_CEL: 'NE PAS CONSERVER AU DESSOUS DE 18°C',
                        NOT_HIGHER_35_DEG: 'NE PAS DEPASSER 35 DEGRES',
                        SAFE_FROM_COLD: "A L'ABRI DU FROID",
                        SAFE_FROM_DRYING_UP: "A L'ABRI DU DESSECHEMENT",
                        STRICTLY_HIGHER_10_DEG: 'A TEMPERATURE > A 10 DEGRES',
                        FLAT_POSITION: 'A PLAT',
                        NOT_HEATED: 'NE PAS CHAUFFER',
                        BETWEEN_2_AND_8_DEG_AFTER_OPEN_AND_BEFORE_USE: 'ENTRE 2 ET 8 DEGRES APRES OUVERTURE ET AVANT UTLISATION ',
                        STORED_UNDER_MIN_20_DEG_CEL: 'CONSERVER AU DESSOUS DE -20°C',
                        STRICTLY_HIGHER_8_DEG: 'A TEMPERATURE > A 8 DEGRES',
                        REGULAR_CHECK_OF_LOOK: 'VERIFIER PERIODIQUEMENT ASPECT',
                        IN_CRYO_CONTAINER_UNDER_MIN_120_DEG: 'EN CONTENEUR POUR CONSERVATION CRYOGENIQUE (- 120 DEGRES)',
                        STORED_IN_LIQUID_NITROGEN_VAP_STATE: "CONSERVER DANS LA PHASE VAPEUR DE L'AZOTE LIQUIDE (< - 150 DEGRES)",
                        NOT_STERILIZED: 'NE PAS STERILISER',
                        NO_RADIATION_EXPO: 'NE PAS EXPOSER AUX RAYONNEMENTS',
                        NOT_OVER_40_DEG: 'NE PAS DEPASSER 40 DEGRES',
                        NOT_STORED_UNDER_MIN_18_DEG_CEL: 'NE PAS CONSERVER AU DESSUS DE -18°C',
                        STRICTLY_TO_4_DEG: 'STRICTEMENT A 4 DEGRES'
                    }
                }
            }
        },
        PHARMACY: {
            PREPARATION: {
                CATEGORIES: {
                    HOSPITAL: 'Hospital',
                    MASTERFUL: 'Masterful'
                },
                CONDITIONS: {
                    AMBIENT_TEMP: 'Ambient temperature',
                    FROM2TO8: 'From +2 to +8°C',
                    NO_LIGHT: 'No light'
                }
            },
            PUBLICATION: {
                TYPES: {
                    STOCKOUT: 'Stockout',
                    STOCKOUT_SHORT: 'Stockout',
                    QUOTA: 'Quota',
                    QUOTA_SHORT: 'Quota',
                    PRESSURE: 'Pressure',
                    COM_ENDED: 'End of sale',
                    SHORT_EXPIRATION: 'Short expiration',
                    WHOLESALER_ONLY: 'Wholesaler only',
                    TRANSFER_LABO: 'Transfer'
                },
                TAGS: {
                    ARCHIVED: 'Archived',
                    PUI: 'Pharmacy',
                    GA: 'Purchasing group',
                    FINISHED: 'Finished',
                    BOOKLET: 'Booklet',
                    MARKET: 'Market',
                    ALTERNATIVE: 'Alternative',
                    PROCESSED: 'Processed',
                    SETTINGS: {
                        FILTER: 'Filter',
                        HIDE: 'Hide',
                        INCLUDE: 'Include'
                    }
                },
                HISTORY: {
                    ALTERNATIVE: {
                        CREATE: 'Addition of an alternative',
                        DELETE: 'Deletion of an alternative',
                        UPDATE: 'Modification of an alternative',
                        TITLE: 'Alternative',
                        // Alternative Drug
                        UPDATE_DRUG: 'Modification of an alternative drug',
                        // Alternative Medical device
                        UPDATE_MEDICAL_DEVICE: 'Modification of an alternative medical device',
                        // Alternatives information
                        CREATE_INFORMATION: 'Addition of alternative information',
                        DELETE_INFORMATION: 'Deletion of alternative information',
                        UPDATE_INFORMATION: 'Modification of alternative information',
                        // Alternatives start date
                        CREATE_START_DATE: 'Addition of alternative start date',
                        DELETE_START_DATE: 'Deletion of alternative start date',
                        UPDATE_START_DATE: 'Modification of alternative start date',
                        // Alternatives end date
                        CREATE_END_DATE: 'Addition of alternative replenishment date',
                        DELETE_END_DATE: 'Deletion of alternative replenishment date',
                        UPDATE_END_DATE: 'Modification of alternative replenishment date',
                        // Alternative Unit packaging
                        UPDATE_UNIT_PACKAGING: 'Modification of alternative unit packaging'
                    },
                    PUBLICATION: {
                        CREATE: 'Addition of publication',
                        DELETE: 'Deletion of publication',
                        UPDATE: 'Modification of publication',
                        // Depositary
                        CREATE_DEPOSITARY: 'Addition of a depositary',
                        DELETE_DEPOSITARY: 'Deletion of a depositary',
                        UPDATE_DEPOSITARY: 'Modification of a depositary',
                        // Drug
                        UPDATE_DRUG: 'Modification of a drug',
                        UPDATE_DRUG_PRESENTATION: 'Modification of presentations',
                        // Information
                        CREATE_INFORMATION: 'Addition of information',
                        DELETE_INFORMATION: 'Deletion of information',
                        UPDATE_INFORMATION: 'Modification of information',
                        // End date
                        CREATE_END_DATE: 'Addition of replenishment date',
                        DELETE_END_DATE: 'Deletion of replenishment date',
                        UPDATE_END_DATE: 'Modification of replenishment date',
                        // Medical device
                        UPDATE_MEDICAL_DEVICE: 'Modification of a medical device group name',
                        UPDATE_MEDICAL_DEVICE_PRESENTATION: 'Modification of reference',
                        // Regulatory quota
                        CREATE_REGULATORY_QUOTA: 'Addition of regulatory quota',
                        DELETE_REGULATORY_QUOTA: 'Deletion of regulatory quota',
                        UPDATE_REGULATORY_QUOTA: 'Modification of regulatory quota',
                        // Remainders conservation
                        CREATE_REMAINDERS_CONSERVATION: 'Addition of remainder conservation',
                        DELETE_REMAINDERS_CONSERVATION: 'Deletion of remainder conservation',
                        UPDATE_REMAINDERS_CONSERVATION: 'Modification of remainder conservation',
                        // Start date
                        CREATE_START_DATE: 'Addition of publication start date',
                        DELETE_START_DATE: 'Deletion of publication start date',
                        UPDATE_START_DATE: 'Modification of publication start date',
                        // Target groups
                        DELETE_TARGET_GROUPS: 'Deletion of target groups',
                        UPDATE_TARGET_GROUPS: 'Modification of target groups',
                        // Wholesaler
                        CREATE_WHOLESALER: 'Addition of a wholesaler - distributor',
                        DELETE_WHOLESALER: 'Deletion of a wholesaler - distributor',
                        UPDATE_WHOLESALER: 'Modification of a wholesaler - distributor'
                    },
                    // Comments
                    CREATE_COMMENT: 'Addition of a comment',
                    DELETE_COMMENT: 'Deletion of a comment',
                    UPDATE_COMMENT: 'Modification of a comment',
                    // Regulatory quota
                    REGULATORY_QUOTA: {
                        YES: 'Yes',
                        NO: 'No',
                        UNKNOWN: 'Unknown'
                    },
                    // Remainders conservation
                    REMAINDERS_CONSERVATION: {
                        YES: 'Yes',
                        NO: 'No'
                    },
                    // Tag
                    CREATE_TAG: "Ajout d'un tag",
                    DELETE_TAG: "Suppression d'un tag",
                    // Type
                    DELETE_PUBLICATION_TYPE: 'Deletion of publication type',
                    UPDATE_PUBLICATION_TYPE: 'Modification of publication type',
                    NEW_VALUE: 'New value',
                    NO_DETAILS: 'No details',
                    OLD_VALUE: 'Old value',
                    VALUE: 'Value',
                    // Unit packaging
                    UNIT_PACKAGING: {
                        YES: 'Yes',
                        NO: 'No',
                        UNKNOWN: 'Unknown'
                    }
                }
            }
        }
    }
};
