var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
let StatesMenuComponent = class StatesMenuComponent {
    constructor(_fuseTranslationLoaderService, _fuseNavigationService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseNavigationService = _fuseNavigationService;
        this.navItems = this._fuseNavigationService.getCurrentNavigation();
        this.onChange = new EventEmitter();
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Array !== "undefined" && Array) === "function" ? _c : Object)
], StatesMenuComponent.prototype, "navItems", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], StatesMenuComponent.prototype, "addOther", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], StatesMenuComponent.prototype, "onChange", void 0);
__decorate([
    ViewChild('childMenu'),
    __metadata("design:type", typeof (_d = typeof MatMenu !== "undefined" && MatMenu) === "function" ? _d : Object)
], StatesMenuComponent.prototype, "childMenu", void 0);
StatesMenuComponent = __decorate([
    Component({
        selector: 'mpx-states-menu',
        template: require('./states-menu.component.html').default,
        styles: [require('./states-menu.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _b : Object])
], StatesMenuComponent);
export { StatesMenuComponent };
