var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
import { GroupsRoutingModule } from './groups-routing.module';
import { ListGroupsComponent } from './list-groups/list-groups.component';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { InformationTabComponent } from './group-detail/information-tab/information-tab.component';
import { MembersTabComponent } from './group-detail/members-tab/members-tab.component';
import { ListAdminComponent } from './group-detail/information-tab/list-admin/list-admin.component';
import { GroupHospistockTabComponent } from './group-detail/hospistock-tab/group-hospistock-tab.component';
let GroupsModule = class GroupsModule {
};
GroupsModule = __decorate([
    NgModule({
        declarations: [
            ListGroupsComponent,
            GroupDetailComponent,
            InformationTabComponent,
            MembersTabComponent,
            GroupHospistockTabComponent,
            ListAdminComponent
        ],
        imports: [
            CommonModule,
            UIRouterModule,
            BrowserAnimationsModule,
            ClipboardModule,
            ReactiveFormsModule,
            FuseSharedModule,
            FuseDirectivesModule,
            FuseConfirmDialogModule,
            FuseSidebarModule,
            SharedModule,
            MatIconModule,
            MatButtonModule,
            MatTableModule,
            MatSortModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatTabsModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            MatSlideToggleModule,
            MatMenuModule,
            MatRadioModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [GroupsRoutingModule]
    })
], GroupsModule);
export { GroupsModule };
