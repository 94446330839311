export const locale = {
    lang: 'en',
    data: {
        LABOS: {
            TITLE: 'Laboratories',
            SEARCH: 'Search a laboratory',
            TABLE: {
                NAME: 'Name',
                NBR_ACCOUNTS: 'Accounts',
                ROLES: 'Roles'
            },
            DETAILS: {
                INFORMATIONS: {
                    TITLE: 'Informations',
                    NAME: {
                        TITLE: 'Laboratory name',
                        PLACEHOLDER: 'Please inter the laboratory name'
                    },
                    THERIAQUE_NAME: {
                        TITLE: 'Theriaque - Operating laboratories list',
                        PLACEHOLDER: 'Search for an operating laboratory'
                    },
                    EXHAUSMED_NAME: {
                        TITLE: 'Exhausmed - Industrials list',
                        PLACEHOLDER: 'Search an industrial'
                    },
                    CONFIGURATION: {
                        NEW_THERIAQUENAME_LABEL: 'Exploiting laboratory name in Theriaque',
                        THERIAQUENAME_EDIT_WARNING: 'The modifications will impact this operating laboratory name for Theriaque',
                        DELETE_THERIAQUENAME: {
                            TITLE: 'Confirm the deletion of this operating laboratory name for Theriaque',
                            CONTENT: ' Do you really want to delete this operating laboratory name for Theriaque : {{name}} ?'
                        }
                    }
                },
                PERMISSIONS: {
                    TITLE: 'Actions / Permissions',
                    ROLE_MAPUI: 'MaPUI role',
                    ROLE_HOSPIVILLE: 'Hospiville role',
                    ROLE_HOSPISTOCK: 'Hospistock role',
                    ROLE_WORKSPACE: 'Workspace role',
                    LOG_AS: {
                        LONG: `Log in this laboratory`,
                        SHORT: `Log in`
                    },
                    DELETE: {
                        LONG: `Delete the laboratory`,
                        SHORT: `Delete`
                    },
                    CONFIRM_LOGAS: 'Do you really want to log in this laboratory?',
                    CONFIRM_DELETE: `Do you really want to delete this laboratory?`,
                    SNACK_DELETE: `Laboratory has been deleted.`
                },
                MEMBERS: {
                    TITLE: 'Members',
                    NO_MEMBERS: 'No member in this laboratory.',
                    TABLE: {
                        NAME: 'Name',
                        EMAIL: 'Email',
                        LAST_CONNECT: 'Last connection',
                        ROLES: `Roles`
                    }
                },
                GROUPS: {
                    TITLE: 'Groups',
                    NO_GROUPS: 'No group in this laboratory.',
                    TABLE: {
                        NAME: 'Name',
                        NB_HOSPITALS: 'Number of hospitals',
                        ACTION: 'Action'
                    },
                    DETAIL: {
                        TITLE: 'Group',
                        INFORMATIONS: {
                            TITLE: 'Informations',
                            NAME: 'Group name'
                        },
                        HOSPITALS: {
                            TITLE: 'Hospitals',
                            NO_HOSPITALS: 'No hospital in this laboratory group.',
                            TABLE: {
                                NAME: 'Name',
                                ACTION: 'Action'
                            }
                        },
                        CREATE_OK: 'Group has been created!',
                        EDIT_OK: 'Group has been edited!',
                        DELETE_OK: 'Group has been deleted!'
                    }
                },
                HOSPISTOCK: {
                    TITLE: 'Hospistock',
                    FORM: {
                        IMPORT: {
                            TITLE: 'Import stockout by file',
                            IMPORT_MODE: {
                                LABEL: 'Exact replacement of online publications by the publications of the import file. The publications that are not in the file will be closed.'
                            }
                        },
                        NOTIFICATIONS: {
                            TITLE: 'Configuration of notifications of proposed alternative publications',
                            EMAIL_CONTACT: 'Contact email address',
                            EMAIL: 'Email',
                            CHECKBOX_EMAIL_CONTACT: 'Send emails to the contact email address configured above',
                            CHECKBOX_EMAIL_MEMBERS: 'Send emails to members who can access to the alternatives of publications',
                            TOGGLE_SEND_MAIL: 'Send an email only on the first alternative proposal'
                        }
                    }
                }
            }
        }
    }
};
