export class HvInformationComponent {
    constructor() {
        this.controller = 'HvInformationController';
        this.controllerAs = 'vm';
        this.template = require('./hv-information.html').default;
        this.bindings = {
            type: '<',
            message: '<',
            messageTranslate: '@',
        };
    }
}
