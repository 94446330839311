export const locale = {
    lang: 'en',
    data: {
        TOOLBAR: {
            TOGGLE_NAVIGATION: 'Toggle navigation',
            TOGGLE_NAVIGATION_FOLD: 'Toggle navigation fold',
            TOGGLE_NAVIGATION_MODE: 'Toggle navigation mode',
            SEARCH: 'Search',
            USER_SETTINGS: 'User settings',
            TOGGLE_QUICK_PANEL: 'Toggle quick panel',
            ENGLISH: 'English',
            SPANISH: 'Spanish',
            TURKISH: 'Turkish',
            FRENCH: 'French',
            PROFILE: {
                MY_PROFILE: 'My profile',
                LOGOUT: 'Logout'
            },
            NAV: {
                HOME: 'Home',
                DASHBOARD: 'Dashboard',
                MAP: 'Map'
            }
        },
        NOTIFICATIONS: {
            TITLE: 'Notifications',
            MARK_ALL_AS_READ: 'Mark all as read',
            SETTINGS: 'Settings',
            NO_NOTIF: 'No notification',
            ALL_LOADED: 'End of notifications',
            RELATIVE_TIME: '{{diff}} ago',
            LOAD_MORE: 'See more',
            LABEL: {
                LOAN: {
                    CREATE: '<strong>{{hospital}}</strong> lend you {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> take {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> confirmed the {{nbr}} <strong>{{drug}}</strong> loan',
                    RETURN: '<strong>{{hospital}}</strong> returned the {{nbr}} <strong>{{drug}}</strong> loan',
                    BILL: '<strong>{{hospital}}</strong> billed the {{nbr}} <strong>{{drug}}</strong> loan',
                    SETTLE: "<strong>{{hospital}}</strong> settled the {{nbr}} <strong>{{drug}}</strong> loan's bill"
                },
                BORROWING: {
                    CREATE: '<strong>{{hospital}}</strong> borrows you {{nbr}} <strong>{{drug}}</strong>',
                    ANSWER: '<strong>{{hospital}}</strong> lend you {{nbr}} <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> confirmed the {{nbr}} <strong>{{drug}}</strong> borrow',
                    RETURN: '<strong>{{hospital}}</strong> returned the {{nbr}} <strong>{{drug}}</strong> borrow',
                    BILL: '<strong>{{hospital}}</strong> billed the {{nbr}} <strong>{{drug}}</strong> loan',
                    SETTLE: "<strong>{{hospital}}</strong> settled the {{nbr}} <strong>{{drug}}</strong> borrow's bill"
                },
                AVAILABLE: {
                    CREATE: '<strong>{{hospital}}</strong> put {{nbr}} <strong>{{drug}}</strong> available',
                    REQUEST: '<strong>{{hospital}}</strong> wish to take {{nbr}} <strong>{{drug}}</strong>',
                    EXPIRE: 'The availability of {{nbr}} <strong>{{drug}}</strong> expired',
                    VALIDATE: '<strong>{{hospital}}</strong> validated your request of retake for {{nbr}} <strong>{{drug}}</strong>'
                },
                PREPARATION: {
                    CREATE: '<strong>{{hospital}}</strong> prepare for you {{nbr}} <strong>{{drug}}</strong>',
                    REQUEST: '<strong>{{hospital}}</strong> ask for the preparation of {{nbr}} <strong>{{drug}}</strong>',
                    VALIDATE: '<strong>{{hospital}}</strong> validated the preparation of <strong>{{drug}}</strong>',
                    CONFIRM: '<strong>{{hospital}}</strong> freed the preparation of <strong>{{drug}}</strong>',
                    'VALIDATE-RECEPTION': '<strong>{{hospital}}</strong> received the preparation of <strong>{{drug}}</strong>',
                    BILL: '<strong>{{hospital}}</strong> billed the preparation of {{nbr}} <strong>{{drug}}</strong>',
                    SETTLE: '<strong>{{hospital}}</strong> settled the preparation of {{nbr}} <strong>{{drug}}</strong> bill'
                },
                REQUEST: {
                    CREATE: '<strong>{{hospital}}</strong> needs {{nbr}} <strong>{{drug}}</strong>',
                    EXPIRE: 'Your request of {{nbr}} <strong>{{drug}}</strong> expired'
                },
                GROUP: {
                    REQUEST: '<strong>{{who}}</strong> wants to join your group <strong>{{group}}</strong>',
                    INVITE: '<strong>{{who}}</strong> invite you to join the group <strong>{{group}}</strong>',
                    CANCEL: '<strong>{{who}}</strong> cancelled the invitation to join the group <strong>{{group}}</strong>',
                    ACCEPT: '<strong>{{who}}</strong> accepted your invitation to join the group <strong>{{group}}</strong>',
                    DENY: '<strong>{{who}}</strong> refused your invitation to join the group <strong>{{group}}</strong>',
                    QUIT: '<strong>{{who}}</strong> left the group <strong>{{group}}</strong>',
                    EJECT: '<strong>{{who}}</strong> kicked you out of the group <strong>{{group}}</strong>',
                    GRANT: '<strong>{{who}}</strong> ranked you as group administrator <strong>{{group}}</strong>',
                    REVOKE: '<strong>{{who}}</strong> revoke your administrators privileges <strong>{{group}}</strong>'
                },
                HOSPITAL: {
                    'RANK-MEMBER': 'Your establishment is now a premium member'
                },
                USER: {
                    'RANK-HOSPIVILLE': 'Vous avez dorénavant accès à HospiVille !',
                    'RANK-ADMIN': 'Vous êtes dorénavant administrateur !'
                },
                'THERAPEUTIC-BOOKLET': {
                    SHARE: '<strong>{{who}}</strong> has started to share informations with <strong>{{group}}</strong> about <strong>{{booklet}}</strong>',
                    'REMOVE-SHARE': '<strong>{{who}}</strong> has stopped to share informations with <strong>{{group}}</strong> about <strong>{{booklet}}</strong>',
                    'UPDATE-SHARE': '<strong>{{who}}</strong> has updated shared informations with <strong>{{group}}</strong> about <strong>{{booklet}}</strong>'
                },
                SHAREDFILE: {
                    CREATE: 'The file <strong>{{filename}}</strong> has been shared with you in folder <strong>{{parent}}</strong>'
                },
                ADMIN: {
                    LOAN: {
                        CREATE: '<strong>{{hospital}}</strong> lend {{nbr}} <strong>{{drug}}</strong> to {{dest}}',
                        ANSWER: '<strong>{{hospital}}</strong> take {{nbr}} <strong>{{drug}}</strong> to {{dest}}',
                        CONFIRM: '<strong>{{hospital}}</strong> confirmed the {{nbr}} <strong>{{drug}}</strong> loan to {{dest}}',
                        RETURN: '<strong>{{hospital}}</strong> returned the {{nbr}} <strong>{{drug}}</strong> loan made by {{dest}}',
                        BILL: '<strong>{{hospital}}</strong> billed {{dest}} the {{nbr}} <strong>{{drug}}</strong> loan to {{dest}}',
                        SETTLE: "<strong>{{hospital}}</strong> settled the {{nbr}} <strong>{{drug}}</strong> loan's bill made by {{dest}}"
                    },
                    BORROWING: {
                        CREATE: '<strong>{{hospital}}</strong> borrows {{nbr}} <strong>{{drug}}</strong> to {{dest}}',
                        ANSWER: '<strong>{{hospital}}</strong> lend {{nbr}} <strong>{{drug}}</strong> to {{dest}}',
                        CONFIRM: '<strong>{{hospital}}</strong> confirmed the {{nbr}} <strong>{{drug}}</strong> borrow to {{dest}}',
                        RETURN: '<strong>{{hospital}}</strong> returned the {{nbr}} <strong>{{drug}}</strong> borrow to {{dest}}',
                        BILL: '<strong>{{hospital}}</strong> billed the {{nbr}} <strong>{{drug}}</strong> loan to {{dest}}',
                        SETTLE: "<strong>{{hospital}}</strong> settled the {{nbr}} <strong>{{drug}}</strong> borrow's bill made by {{dest}}"
                    },
                    AVAILABLE: {
                        REQUEST: '<strong>{{hospital}}</strong> wish to take {{nbr}} <strong>{{drug}}</strong> made by {{dest}}',
                        EXPIRE: 'The availability of {{nbr}} <strong>{{drug}}</strong> created by {{dest}} expired'
                    },
                    REQUEST: {
                        EXPIRE: 'The request of {{nbr}} <strong>{{drug}}</strong> created by {{dest}} expired'
                    },
                    PREPARATION: {
                        CREATE: '<strong>{{hospital}}</strong> prepare {{nbr}} <strong>{{drug}}</strong> for {{dest}}',
                        REQUEST: '<strong>{{hospital}}</strong> ask {{dest}} for the preparation of {{nbr}} <strong>{{drug}}</strong>',
                        VALIDATE: '<strong>{{hospital}}</strong> validated the preparation of <strong>{{drug}}</strong> for {{dest}}',
                        CONFIRM: '<strong>{{hospital}}</strong> freed the preparation of <strong>{{drug}}</strong> for {{dest}}',
                        'VALIDATE-RECEPTION': '<strong>{{hospital}}</strong> received the preparation of <strong>{{drug}}</strong> made by {{dest}}',
                        BILL: '<strong>{{hospital}}</strong> billed {{dest}} the preparation of {{nbr}} <strong>{{drug}}</strong>',
                        SETTLE: '<strong>{{hospital}}</strong> settled the preparation of {{nbr}} <strong>{{drug}}</strong> bill made by {{dest}}'
                    },
                    GROUP: {
                        REQUEST: '<strong>{{who}}</strong> wants to join the group <strong>{{group}}</strong>',
                        INVITE: '<strong>{{who}}</strong> invite {{dest}} to join the group <strong>{{group}}</strong>',
                        CANCEL: '<strong>{{who}}</strong> cancelled the invitation to join the group <strong>{{group}}</strong>',
                        ACCEPT: '<strong>{{who}}</strong> accepted your invitation to join the group <strong>{{group}}</strong>',
                        DENY: "<strong>{{who}}</strong> refused {{dest}}'s invitation to join the group <strong>{{group}}</strong>",
                        QUIT: '<strong>{{who}}</strong> left the group <strong>{{group}}</strong>',
                        EJECT: '<strong>{{who}}</strong> kicked {{dest}} out of the group <strong>{{group}}</strong>',
                        GRANT: '<strong>{{who}}</strong> ranked {{dest}} as group administrator <strong>{{group}}</strong>',
                        REVOKE: '<strong>{{who}}</strong> revoke {{dest}} administrators privileges <strong>{{group}}</strong>'
                    },
                    USER: {
                        CREATE: '<strong>{{email}}</strong> joined the plateform',
                        JOIN: '<strong>{{email}}</strong> joined the plateform on an invited account',
                        'CHANGE-HOSPITAL': '<strong>{{hospital}}</strong> was assigned to <strong>{{email}}</strong> by {{admin}}',
                        LOCKED: '<strong>{{email}}</strong> locked his/her account (too many attempts)',
                        'IP-LOCKED': 'The IP <strong>{{ip}}</strong> was temporary blocked (too many attempts)',
                        UNLOCKED: '<strong>{{email}}</strong> was unlocked by {{admin}}',
                        'ADD-RIGHTS': "The right(s) <strong>{{rights.join(', ')}}</strong> was/where assigned to <strong>{{email}}</strong> by {{admin}}",
                        'REMOVE-RIGHTS': "he right(s) <strong>{{rights.join(', ')}}</strong> was/where revoked to <strong>{{email}}</strong> by {{admin}}"
                    },
                    HOSPITAL: {
                        'ADD-RIGHTS': "The right(s) <strong>{{rights.join(', ')}}</strong> was/where assigned to <strong>{{hospital}}</strong> by {{admin}}",
                        'REMOVE-RIGHTS': "The right(s)<strong>{{rights.join(', ')}}</strong> was/where revoked to <strong>{{hospital}}</strong> by {{admin}}"
                    },
                    'THERAPEUTIC-BOOKLET': {
                        SHARE: '<strong>{{who}}</strong> has started to share informations with <strong>{{nbGroups}}</strong> about <strong>{{booklet}}</strong>',
                        'REMOVE-SHARE': '<strong>{{who}}</strong> has stopped to share informations about <strong>{{booklet}}</strong>',
                        'UPDATE-SHARE': '<strong>{{who}}</strong> has updated shared informations with <strong>{{nbGroups}}</strong> groups about <strong>{{booklet}}</strong>'
                    },
                    SHAREDFILE: {
                        CREATE: 'The file <strong>{{filename}}</strong> has been shared in folder <strong>{{parent}}</strong>'
                    }
                }
            }
        }
    }
};
