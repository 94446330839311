export const locale = {
    lang: `en`,
    data: {
        VOUCHER: {
            TITLE: 'Generate a voucher',
            NAME: 'Voucher creation for:',
            SUGGESTION: 'Suggestion of exchanges to add to this voucher:',
            SUGGESTION_EXCHANGES: 'Suggestion of exchanges',
            FORBIDDEN_CHARACTER: 'A character is forbidden in the form.',
            NO_LOAN: 'None loans found.',
            VALID: 'Valid',
            TRANSPORT: {
                TITLE: 'Transport voucher',
                EMERGENCY: {
                    TITLE: 'Emergency',
                    LESS_2HOURS: '< 2 hours',
                    LESS_6HOURS: '< 6 hours',
                    FROM_24HOURS_TO_48HOURS: '24 to 48h'
                },
                SERVICE: 'Service',
                NBR_PACKAGE: 'Package number',
                VOLUME: {
                    TITLE: 'Volume',
                    SMALL: 'small volume',
                    LARGE: 'large volume'
                },
                WEIGHT: {
                    TITLE: 'Weight',
                    LIGHT: 'low',
                    HEAVY: 'heavy'
                },
                COMMENT: 'Commentaire'
            }
        }
    }
};
