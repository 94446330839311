export const locale = {
    lang: 'en',
    data: {
        CONFIGURATION: {
            TITLE: 'Configuration',
            SERVICE_TITLE: 'My establishment services',
            NEW_SERVICE_LABEL: 'New service in your hospital',
            EDIT_WARNING: 'Modifying the service will affect the name of the service selected on previous reconciliations.',
            SERVICE_DELETED: 'Service deleted',
            TOOLTIP: {
                ADD: 'Add new service',
                EDIT: 'Edit',
                RESTORE: 'Restore service'
            }
        }
    }
};
