import { EGroupType } from '@mapuilabs/mpl-interfaces';
export const CGroupType = {
    region: {
        value: EGroupType.Region,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.REGION'
    },
    ght: {
        value: EGroupType.GHT,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.GHT'
    },
    purchase: {
        value: EGroupType.Purchase,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.PURCHASE'
    },
    purchase_non_subscribed: {
        value: EGroupType.Purchase_Non_Subscribed,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.PURCHASE_NON_SUBSCRIBED'
    },
    association: {
        value: EGroupType.Association,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.ASSOCIATION'
    },
    private: {
        value: EGroupType.Private,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.PRIVATE'
    },
    mutual: {
        value: EGroupType.Mutual,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.MUTUAL'
    },
    other: {
        value: EGroupType.Other,
        translate: 'MAPUI.GENERAL.CONST_TYPE.GROUP.TYPES.OTHER'
    }
};
