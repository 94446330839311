export class MpModalCreateOtherEstablishmentComponent {
    constructor() {
        this.controller = 'MpModalCreateOtherEstablishmentController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-create-other-establishment.html').default;
        this.bindings = {
            name: '<',
        };
    }
}
