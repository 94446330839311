import { EHsPublicationHistoryType, EHsRegulatoryQuotaType, EHsRemainderConservationType, EHsUnitPackagingType } from '@mapuilabs/mpl-interfaces';
export const CSidebarHistory = {
    // -----------------------------------------------------------------------------------------------------
    // @ Alternative
    // -----------------------------------------------------------------------------------------------------
    [EHsPublicationHistoryType.CREATE_ALTERNATIVE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.CREATE'
    },
    [EHsPublicationHistoryType.DELETE_ALTERNATIVE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.DELETE'
    },
    // Alternative Drug
    [EHsPublicationHistoryType.UPDATE_ALTERNATIVE_DRUG]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.UPDATE_DRUG'
    },
    // Alternative Medical device
    [EHsPublicationHistoryType.UPDATE_ALTERNATIVE_MEDICAL_DEVICE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.UPDATE_MEDICAL_DEVICE'
    },
    // Alternatives information
    [EHsPublicationHistoryType.DELETE_ALTERNATIVE_INFORMATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.DELETE_INFORMATION'
    },
    [EHsPublicationHistoryType.UPDATE_ALTERNATIVE_INFORMATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.UPDATE_INFORMATION'
    },
    // Alternatives start date
    [EHsPublicationHistoryType.DELETE_ALTERNATIVE_START_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.DELETE_START_DATE'
    },
    [EHsPublicationHistoryType.UPDATE_ALTERNATIVE_START_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.UPDATE_START_DATE'
    },
    // Alternatives end date
    [EHsPublicationHistoryType.DELETE_ALTERNATIVE_END_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.DELETE_END_DATE'
    },
    [EHsPublicationHistoryType.UPDATE_ALTERNATIVE_END_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.UPDATE_END_DATE'
    },
    // Alternatives unit packaging
    [EHsPublicationHistoryType.UPDATE_ALTERNATIVE_UNIT_PACKAGING]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.ALTERNATIVE.UPDATE_UNIT_PACKAGING'
    },
    // -----------------------------------------------------------------------------------------------------
    // @ Publication
    // -----------------------------------------------------------------------------------------------------
    // Publications
    [EHsPublicationHistoryType.CREATE_PUBLICATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.CREATE'
    },
    // Depositary
    [EHsPublicationHistoryType.DELETE_PUBLICATION_DEPOSITARY]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.DELETE_DEPOSITARY'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_DEPOSITARY]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_DEPOSITARY'
    },
    // Drug
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_DRUG]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_DRUG'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_DRUG_PRESENTATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_DRUG_PRESENTATION'
    },
    // Information
    [EHsPublicationHistoryType.DELETE_PUBLICATION_INFORMATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.DELETE_INFORMATION'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_INFORMATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_INFORMATION'
    },
    // End date
    [EHsPublicationHistoryType.DELETE_PUBLICATION_END_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.DELETE_END_DATE'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_END_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_END_DATE'
    },
    // Medical device
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_MEDICAL_DEVICE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_MEDICAL_DEVICE'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_MEDICAL_DEVICE_PRESENTATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_MEDICAL_DEVICE_PRESENTATION'
    },
    // Regulatory quota
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_REGULATORY_QUOTA]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_REGULATORY_QUOTA'
    },
    // Remainders conservation
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_REMAINDERS_CONSERVATION]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_REMAINDERS_CONSERVATION'
    },
    // Start date
    [EHsPublicationHistoryType.DELETE_PUBLICATION_START_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.DELETE_START_DATE'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_START_DATE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_START_DATE'
    },
    // Target groups
    [EHsPublicationHistoryType.DELETE_PUBLICATION_TARGET_GROUPS]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.DELETE_TARGET_GROUPS'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_TARGET_GROUPS]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_TARGET_GROUPS'
    },
    // Wholesaler
    [EHsPublicationHistoryType.DELETE_PUBLICATION_WHOLESALER]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.DELETE_WHOLESALER'
    },
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_WHOLESALER]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.PUBLICATION.UPDATE_WHOLESALER'
    },
    // -----------------------------------------------------------------------------------------------------
    // @ Metadata & others
    // -----------------------------------------------------------------------------------------------------
    // Comments
    [EHsPublicationHistoryType.CREATE_COMMENT]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.CREATE_COMMENT'
    },
    [EHsPublicationHistoryType.DELETE_COMMENT]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.DELETE_COMMENT'
    },
    [EHsPublicationHistoryType.UPDATE_COMMENT]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.UPDATE_COMMENT'
    },
    // Regulatory quota
    [EHsRegulatoryQuotaType.YES]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.REGULATORY_QUOTA.YES'
    },
    [EHsRegulatoryQuotaType.NO]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.REGULATORY_QUOTA.NO'
    },
    [EHsRegulatoryQuotaType.UNKNOWN]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.REGULATORY_QUOTA.UNKNOWN'
    },
    // Remainders conservation
    [EHsRemainderConservationType.YES]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.REMAINDERS_CONSERVATION.YES'
    },
    [EHsRemainderConservationType.NO]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.REMAINDERS_CONSERVATION.NO'
    },
    [EHsRemainderConservationType.NOT_APPLICABLE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.REMAINDERS_CONSERVATION.NOT_APPLICABLE'
    },
    // Tag
    [EHsPublicationHistoryType.DELETE_TAG]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.DELETE_TAG'
    },
    [EHsPublicationHistoryType.CREATE_TAG]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.CREATE_TAG'
    },
    // Type
    [EHsPublicationHistoryType.UPDATE_PUBLICATION_TYPE]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.UPDATE_PUBLICATION_TYPE'
    },
    // Unit packaging
    [EHsUnitPackagingType.YES]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.UNIT_PACKAGING.YES'
    },
    [EHsUnitPackagingType.NO]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.UNIT_PACKAGING.NO'
    },
    [EHsUnitPackagingType.UNKNOWN]: {
        translate: 'PHARMACY.PUBLICATION.HISTORY.UNIT_PACKAGING.UNKNOWN'
    }
};
