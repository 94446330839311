import { EHospistockPermission, EPubSubTopic } from '@mapuilabs/mpl-interfaces';
export class VaultService {
    /** @ngInject */
    constructor($q, ModalService, Api, Access, $state, PubSubService) {
        this.$q = $q;
        this.ModalService = ModalService;
        this.Api = Api;
        this.Access = Access;
        this.$state = $state;
        this.PubSubService = PubSubService;
        this.$onDestruct = () => {
            for (const topic of this._topics) {
                topic();
            }
        };
        /**
         * Initialise vault with no data and lock it if needed
         */
        this.init = () => {
            if (this.isInit) {
                return;
            }
            this.Api.vault.check({}, {}, (data) => {
                this.isInit = true;
                if (data.locked) {
                    this._isLocked = true;
                    this._showUnlockWithPasswordModal();
                    this.PubSubService.emit(EPubSubTopic.IDLE_STOP);
                    return this.PubSubService.emit(EPubSubTopic.VAULT_LOCKED);
                }
                this.setUnlocked();
            });
        };
        this.setUnlocked = () => {
            this._isLocked = false;
            this.PubSubService.emit(EPubSubTopic.VAULT_UNLOCKED);
            this.PubSubService.emit(EPubSubTopic.RESET_IDLE);
        };
        // /**
        //  * Remove data from vault and keep track of time
        //  */
        // public flush = () => {
        //   console.info('Vault flushed');
        //   this.clear();
        //   this._lastFlush = Date.now();
        //   this.PubSubService.emit(EPubSubTopic.IDLE_STOP);
        // };
        /**
         * Lock the vault
         * All data will be send to the server to be encrypted and stored
         * Local data are then removed from vault.
         * @return {angular.IPromise<any>}
         */
        this.lock = (showUnlockModal = false) => {
            const defer = this.$q.defer();
            if (this._isLocked) {
                defer.resolve();
                return defer.promise;
            }
            this._isLocked = true;
            this.PubSubService.emit(EPubSubTopic.VAULT_LOCKED);
            this.PubSubService.emit(EPubSubTopic.IDLE_STOP);
            /* this.Api.vault().send({data: angular.copy(this.data)});
             this.clear();*/
            if (showUnlockModal) {
                this._showUnlockWithPasswordModal();
            }
            this.Api.vault.lock({}, {}, () => defer.resolve(), () => defer.reject());
            return defer.promise;
        };
        /**
         * Unlock the vault and restore data
         * Remove the unlock vault with password view if needed
         * @param password User's password
         * @return {IPromise<any>}
         */
        this.unlock = (password) => {
            const defer = this.$q.defer();
            if (!this._isLocked) {
                defer.resolve();
                return defer.promise;
            }
            // Ask for password
            this.Api.vault.unlock({}, { password }, (res) => {
                if (res.same) {
                    /* this._fill(res);*/
                    this.setUnlocked();
                    this.ModalService.close(null);
                    this.$state.reload();
                    return defer.resolve();
                }
                return defer.reject();
            }, () => {
                if (!password) {
                    this._showUnlockWithPasswordModal();
                }
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Is vault locked
         * @return {boolean}
         */
        this.isLocked = () => {
            return this._isLocked;
        };
        this.showUnlockModalIfItIsLocked = () => {
            if (this._isLocked && this.isInit) {
                this._showUnlockWithPasswordModal();
            }
        };
        /**
         * Show modal to ask user for password to unlock Vault
         * @private
         */
        this._showUnlockWithPasswordModal = () => {
            var _a;
            if (((_a = this.$state.current) === null || _a === void 0 ? void 0 : _a.data.hasSensitiveData) ||
                this.Access.authorize(EHospistockPermission.VaultDisconnection_Read)) {
                this.ModalService.show({
                    component: 'mp-modal-vault-unlock',
                    bindings: {},
                    escapeToClose: false,
                    clickOutsideToClose: false,
                    multiple: false
                });
            }
        };
        /**
         * Show modal to warn user the Vault is soon to be locked
         * @private
         */
        this._showIdleWarningModal = (remainingTime) => {
            this.ModalService.close(null);
            this.ModalService.show({
                component: 'mp-modal-vault-warning',
                bindings: {
                    countdown: remainingTime
                },
                escapeToClose: false,
                multiple: false
            });
        };
        this._isLocked = true;
        this.isInit = false;
        this._topics = [];
        this._topics.push(this.PubSubService.on(EPubSubTopic.IDLE_TIMEOUT, () => {
            var _a;
            if (this._isLocked === false) {
                this.lock(((_a = this.$state.current) === null || _a === void 0 ? void 0 : _a.data.hasSensitiveData) ||
                    this.Access.authorize(EHospistockPermission.VaultDisconnection_Read));
            }
        }));
        this._topics.push(this.PubSubService.on(EPubSubTopic.IDLE_START, (val) => {
            var _a;
            if (this._isLocked === false) {
                // Do not impact vault on routes that does not contains sensitive data
                if (((_a = this.$state.current) === null || _a === void 0 ? void 0 : _a.data.hasSensitiveData) ||
                    this.Access.authorize(EHospistockPermission.VaultDisconnection_Read)) {
                    this._showIdleWarningModal(val);
                }
            }
        }));
        this._topics.push(this.PubSubService.on(EPubSubTopic.IDLE_END, () => {
            var _a;
            if (this._isLocked === false) {
                // Do not impact vault on routes that does not contains sensitive data
                if (((_a = this.$state.current) === null || _a === void 0 ? void 0 : _a.data.hasSensitiveData) ||
                    this.Access.authorize(EHospistockPermission.VaultDisconnection_Read)) {
                    ModalService.close(null);
                }
            }
        }));
    }
}
