import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HvPatientMedRecsController {
    /** @ngInject */
    constructor(_, $state, Api, Access, $scope, $translate, ModalService, HospivilleService, ToastService, MedicationReconciliationService, ConfigurationService) {
        this._ = _;
        this.$state = $state;
        this.Api = Api;
        this.Access = Access;
        this.$scope = $scope;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.HospivilleService = HospivilleService;
        this.ToastService = ToastService;
        this.MedicationReconciliationService = MedicationReconciliationService;
        this.ConfigurationService = ConfigurationService;
        this.addHospReason = (ev, medRec) => {
            medRec.hospReason = ev.reason;
        };
        /**
         * Save [[IMedicationReconciliation]]
         * @param medRec
         * @param index
         */
        this.saveMedRec = (medRec, index) => {
            this.MedicationReconciliationService.save(medRec)
                .then((saveMedRec) => {
                if (this.Access.isInterfaced() && saveMedRec.initiator)
                    this.HospivilleService.exportMedRecStatus(Utils.getId(saveMedRec), 2, 'update');
                for (let medicationReconciliation of this.patient.medicationReconciliations) {
                    if (medicationReconciliation._id === medRec._id) {
                        medicationReconciliation.active = saveMedRec.isActive;
                        medicationReconciliation.date = saveMedRec.date;
                        break;
                    }
                }
                this.HospivilleService.savePatient(this.patient);
                this.$scope.$emit('refreshPatient');
                this.ToastService.show(EMpStatus.Ok);
            })
                .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
        };
        /**
         * Add a new [[IMedicationReconciliation]]
         */
        this.addMedRec = () => {
            let medRec = {
                date: new Date,
                isActive: true,
                hospital: this.Access.hospital,
            };
            this.MedicationReconciliationService.add(medRec)
                .then((newMedRec) => {
                if (!this.medRecs) {
                    this.medRecs = [];
                }
                this.medRecs.push(newMedRec);
                if (!this.patient.medicationReconciliations) {
                    this.patient.medicationReconciliations = [];
                }
                this.patient.medicationReconciliations.push({
                    date: newMedRec.date,
                    active: newMedRec.isActive,
                    _id: newMedRec._id,
                });
                this.HospivilleService.savePatient(this.patient)
                    .then(() => {
                    this.Access.changePatient(this.patient._id, newMedRec._id);
                    this.$scope.$emit('refreshPatient');
                    this.ToastService.show(EMpStatus.Ok);
                });
            })
                .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
        };
        /**
         * Delete a given [[IMedicationReconciliation]]
         * @param medRec
         */
        this.deleteMedRec = (medRec) => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: this.$translate.instant('HOSPIVILLE.GENERAL.CONFIRM_MODAL.TITLE'),
                        text: this.$translate.instant('HOSPIVILLE.GENERAL.CONFIRM_MODAL.TEXT'),
                    },
                },
                escapeToClose: true,
            }).then(() => {
                this.MedicationReconciliationService.delete(medRec)
                    .then(() => {
                    this.medRecs = this._.without(this.medRecs, this._.find(this.medRecs, { _id: medRec._id }));
                    this.patient.medicationReconciliations = this._.without(this.patient.medicationReconciliations, this._.find(this.patient.medicationReconciliations, { _id: medRec._id }));
                    this.HospivilleService.savePatient(this.patient)
                        .then(() => {
                        this.$scope.$emit('refreshPatient');
                        this.ToastService.show(EMpStatus.Ok);
                    });
                })
                    .catch(err => this.ToastService.show(err));
            });
        };
        /**
         * Close given [[IMedicationReconciliation]]
         */
        this.endMedRec = (medicationReconciliation) => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'HOSPIVILLE.TOOLBAR.MODAL.CONFIRM.TITLE',
                        text: 'HOSPIVILLE.TOOLBAR.MODAL.CONFIRM.TEXT',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                if (this.patient.medicationReconciliations) {
                    for (let medRec of this.patient.medicationReconciliations) {
                        if (medRec._id === medicationReconciliation._id) {
                            medRec.active = false;
                            break;
                        }
                    }
                }
                medicationReconciliation.isActive = false;
                this.MedicationReconciliationService.save(medicationReconciliation)
                    .then((saveMedRec) => {
                    if (this.Access.isInterfaced() && saveMedRec.initiator)
                        this.HospivilleService.exportMedRecStatus(Utils.getId(saveMedRec), 3, 'update');
                    this.HospivilleService.savePatient(this.patient).then(() => {
                        this.$scope.$emit('refreshPatient');
                        this.ToastService.show(EMpStatus.Ok);
                    });
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            });
        };
        /**
         * Check if an active [[IMedicationReconciliation]] exists
         * @returns {boolean}
         */
        this.isActiveMedRec = () => {
            if (!this.medRecs)
                return false;
            return !!this._.find(this.medRecs, { isActive: true });
        };
        /**
         * Get the translation of a given value
         * @param type
         * @param value
         * @returns {string}
         */
        this.getTranslation = (type, value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[type], { value: value }).translate;
        };
        /**
         * Return all match department
         * @param value
         * @returns {angular.IPromise<Array<any>>}
         */
        this.searchDepartment = (value) => {
            return this.MedicationReconciliationService.searchDepartment(value);
        };
        /**
         * Get time from milliseconds to days hours minutes seconds
         * @param time
         * @returns {string}
         */
        this.getTimeDiff = (time) => {
            let x = time / 1000;
            const seconds = Math.floor(x % 60);
            x /= 60;
            const minutes = Math.floor(x % 60);
            x /= 60;
            const hours = Math.floor(x % 24);
            x /= 24;
            const days = Math.floor(x);
            return (days ? days + 'j ' : '') + (hours ? hours + 'h ' : '') + (minutes ? minutes + 'm ' : '') + (seconds ? seconds + 's' : '');
        };
        /**
         * navigate to the hospiville configuration Page
         */
        this.goToConfig = () => {
            this.$state.go('hospivilleV2.configuration');
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        this.ConfigurationService.getHospitalServices(this.Access.hospital).then((result) => {
            this.HospitalServices = result;
            this.HospitalServices.sort((a, b) => {
                if (a.hsDeleted)
                    return 1;
                else if (b.hsDeleted)
                    return -1;
                return 0;
            });
        });
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(changes) {
        if (changes.medRecs) {
            this.medRecs = angular.copy(this.medRecs);
        }
    }
}
