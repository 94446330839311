var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
let FuseShortcutsComponent = class FuseShortcutsComponent {
    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(_cookieService, _fuseMatchMediaService, _fuseNavigationService, _mediaObserver, _renderer) {
        this._cookieService = _cookieService;
        this._fuseMatchMediaService = _fuseMatchMediaService;
        this._fuseNavigationService = _fuseNavigationService;
        this._mediaObserver = _mediaObserver;
        this._renderer = _renderer;
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);
        if (this._cookieService.check('FUSE2.shortcuts')) {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else {
            // User's shortcut items
            this.shortcutItems = [
                {
                    title: 'Calendar',
                    type: 'item',
                    icon: 'today',
                    url: '/apps/calendar'
                },
                {
                    title: 'Mail',
                    type: 'item',
                    icon: 'email',
                    url: '/apps/mail'
                },
                {
                    title: 'Contacts',
                    type: 'item',
                    icon: 'account_box',
                    url: '/apps/contacts'
                },
                {
                    title: 'To-Do',
                    type: 'item',
                    icon: 'check_box',
                    url: '/apps/todo'
                }
            ];
        }
    }
    ngAfterViewInit() {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            if (this._mediaObserver.isActive('gt-sm')) {
                this.hideMobileShortcutsPanel();
            }
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Search
     *
     * @param event
     */
    search(event) {
        const value = event.target.value.toLowerCase();
        if (value === '') {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;
            return;
        }
        this.searching = true;
        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }
    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle) {
        event.stopPropagation();
        for (let i = 0; i < this.shortcutItems.length; i++) {
            if (this.shortcutItems[i].url === itemToToggle.url) {
                this.shortcutItems.splice(i, 1);
                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
                return;
            }
        }
        this.shortcutItems.push(itemToToggle);
        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }
    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem) {
        return this.shortcutItems.find((item) => {
            return item.url === navigationItem.url;
        });
    }
    /**
     * On menu open
     */
    onMenuOpen() {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }
    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel() {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel() {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FuseShortcutsComponent.prototype, "navigation", void 0);
__decorate([
    ViewChild('searchInput', { static: false }),
    __metadata("design:type", Object)
], FuseShortcutsComponent.prototype, "searchInputField", void 0);
__decorate([
    ViewChild('shortcuts', { static: false }),
    __metadata("design:type", typeof (_f = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _f : Object)
], FuseShortcutsComponent.prototype, "shortcutsEl", void 0);
FuseShortcutsComponent = __decorate([
    Component({
        selector: 'fuse-shortcuts',
        template: require('./shortcuts.component.html').default,
        styles: [require('./shortcuts.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof CookieService !== "undefined" && CookieService) === "function" ? _a : Object, typeof (_b = typeof FuseMatchMediaService !== "undefined" && FuseMatchMediaService) === "function" ? _b : Object, typeof (_c = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _c : Object, typeof (_d = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _d : Object, typeof (_e = typeof Renderer2 !== "undefined" && Renderer2) === "function" ? _e : Object])
], FuseShortcutsComponent);
export { FuseShortcutsComponent };
