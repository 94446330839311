import { EHospivillePermission, EHTTPStatus, EMpStatus, } from '@mapuilabs/mpl-interfaces';
export class ActivitiesService {
    /** @ngInject */
    constructor($q, $http, HospivilleService, Access) {
        this.$q = $q;
        this.$http = $http;
        this.HospivilleService = HospivilleService;
        this.Access = Access;
        /**
         * Check activity before opening modal to compose and send prescription demand.
         */
        this.canSendPrescriptionMail = () => {
            return new Promise((resolve, reject) => {
                // Check if an activity already exists for the current MedRec
                this.HospivilleService.getMedRec().then((mr) => {
                    if (!mr) {
                        return reject(EMpStatus.NoResult);
                    }
                    this.findOpenByMedRecId(mr._id).then((res) => {
                        var _a;
                        resolve(((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.length) <= 0);
                    }).catch((err) => {
                        console.error(err);
                        reject(EMpStatus.RejectByServer);
                    });
                }).catch((err) => {
                    console.error(err);
                    reject(EMpStatus.RejectByServer);
                });
            });
        };
    }
    /**
     * Find activity for the given MedRec
     * @param mrid
     */
    findLastByMedRecId(mrid) {
        const defer = this.$q.defer();
        if (!mrid) {
            defer.reject(EMpStatus.NoDataProvided);
        }
        else if (!this.Access.authorize(EHospivillePermission.Activities_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.$http.get(`/api/hospiville/patients/activities/by-med-rec/last/${mrid}`)
                .then((res) => defer.resolve(res))
                .catch((err) => defer.reject(err));
        }
        return defer.promise;
    }
    /**
     * Find open activities (ie not ended, not canceled and with no medrec) for the given MedRec
     * @param mrid
     */
    findOpenByMedRecId(mrid) {
        const defer = this.$q.defer();
        if (!mrid) {
            defer.reject(EMpStatus.NoDataProvided);
        }
        else if (!this.Access.authorize(EHospivillePermission.Activities_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else {
            this.$http.get(`/api/hospiville/patients/activities/by-med-rec/open/${mrid}`)
                .then((res) => defer.resolve(res))
                .catch((err) => defer.reject(err));
        }
        return defer.promise;
    }
    /**
     * Download an activity file and return the array buffer from the request to
     * recreate the file.
     * @param fileUrl
     */
    downloadFile(fileUrl) {
        const defer = this.$q.defer();
        if (!this.Access.authorize(EHospivillePermission.Activities_Read)) {
            defer.reject(EMpStatus.NoAccess);
        }
        else if (!fileUrl) {
            defer.reject(EMpStatus.NoDataProvided);
        }
        else {
            this.$http.get(fileUrl, { responseType: 'arraybuffer' })
                .then((buff) => defer.resolve(buff))
                .catch((err) => defer.reject(err));
        }
        return defer.promise;
    }
    ;
    setTextAlertComposeMail(err) {
        switch (err.status) {
            case EHTTPStatus.BadRequest:
                return {
                    alertTitle: 'HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.BAD_REQUEST.TITLE',
                    alertMessage: 'HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.BAD_REQUEST.MESSAGE',
                };
            case EHTTPStatus.InternalServerError:
                return {
                    alertTitle: 'HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.SERVER_ERROR.TITLE',
                    alertMessage: 'HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.SERVER_ERROR.MESSAGE',
                };
            default:
                return {
                    alertTitle: 'HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.DEFAULT_ERROR.TITLE',
                    alertMessage: 'HOSPIVILLE.PATIENT.MAILS.PRESCRIPTION_REQUEST.ERRORS.DEFAULT_ERROR.MESSAGE',
                };
        }
    }
    getActivityByPatient(idPatient) {
        const defer = this.$q.defer();
        this.$http.get(`/api/hospiville/activities/source/by-patient/${idPatient}`)
            .then((res) => defer.resolve(res))
            .catch((err) => defer.reject(err));
        return defer.promise;
    }
}
