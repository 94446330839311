export const locale = {
    lang: 'en',
    data: {
        CRON: {
            TITLE: 'CRON Manager',
            TABLE: {
                NAME: 'Name',
                STATUS: {
                    TITLE: 'Status',
                    RUNNING: 'Running',
                    ON: 'Scheduled',
                    OFF: 'Un-scheduled'
                },
                LAST_RUN: 'Last run',
                SCHEDULE: 'Schedule rules',
                ACTIONS: {
                    TITLE: 'Actions',
                    RUN: {
                        TITLE: 'Run task once',
                        MODAL: {
                            TITLE: 'Confirmation needed',
                            MESSAGE: 'Do you really want to run this task?',
                            NOTIF: 'Task started'
                        }
                    },
                    ON: {
                        TITLE: 'Schedule task',
                        MODAL: {
                            TITLE: 'Confirmation needed',
                            MESSAGE: 'Do you really want to schedule this task?',
                            NOTIF: 'Task scheduled'
                        }
                    },
                    OFF: {
                        TITLE: 'Unschedule task',
                        MODAL: {
                            TITLE: 'Confirmation needed',
                            MESSAGE: 'Do you really want to un-schedule this task?',
                            NOTIF: 'Task un-scheduled'
                        }
                    },
                    DELETE: {
                        TITLE: 'Delete task',
                        MODAL: {
                            TITLE: 'Confirmation needed',
                            MESSAGE: 'Do you really want to delete this task?',
                            NOTIF: 'Task deleted'
                        }
                    }
                }
            }
        }
    }
};
