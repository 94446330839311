var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EAdminPanelPermission, Hospital } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
const END_POINT = '/api/hospistock/laboratory';
let HsLaboratoryService = class HsLaboratoryService extends CrudTemplateService {
    constructor(__injector, _httpClient) {
        super(__injector, Hospital, END_POINT, {
            create: EAdminPanelPermission.Establishments_Create,
            read: [],
            update: EAdminPanelPermission.Establishments_Edit,
            delete: EAdminPanelPermission.Establishments_Delete
        });
        this.__injector = __injector;
        this._httpClient = _httpClient;
    }
    /**
     * The function only exists to know if a publication was created by Pfizer so we can display a dedicated label when a
     * publication is terminated...
     * (Do not hesitate to remove if the specific label is not used anymore)
     */
    getPfizerLaboratory() {
        return this._httpClient.get(`${END_POINT}/pfizer`);
    }
};
HsLaboratoryService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _b : Object])
], HsLaboratoryService);
export { HsLaboratoryService };
