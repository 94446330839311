var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType, EInterest, FrenchRegion } from '@mapuilabs/mpl-interfaces';
import { StateService } from '@uirouter/core';
import { locale as english } from '../../../i18n/en';
import { locale as french } from '../../../i18n/fr';
let FilterSidebarComponent = class FilterSidebarComponent {
    constructor(_fuseSidebarService, _fuseTranslationLoaderService, _stateService) {
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._stateService = _stateService;
        this.form = new UntypedFormGroup({});
        this.frenchRegion = new FrenchRegion();
        this._setDefaultFilters = () => {
            this.applyFilters = [
                {
                    type: EFilterType.VALUE,
                    attribute: 'interest',
                    value: EInterest.Interested
                },
                {
                    type: EFilterType.VALUE,
                    attribute: 'withoutInterest',
                    value: true
                },
                {
                    type: EFilterType.VALUE,
                    attribute: 'validated',
                    value: true
                }
            ];
            this.filtersApplied.emit({
                filters: this.applyFilters,
                replaceHistory: true
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.filtersApplied = new EventEmitter();
    }
    ngAfterViewInit() {
        this._setDefaultFilters();
    }
    ngOnChanges(changes) {
        if ('applyFilters' in changes) {
            this.applyFilters = this.applyFilters || [];
            this.setFormControls();
        }
    }
    onApplyFilters(replaceHistory = false) {
        const filters = [];
        if (this.form.controls['interest'].value == true) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'interest',
                value: EInterest.Interested
            });
        }
        if (this.form.controls['notInterest'].value == true) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'interest',
                value: EInterest.NotInterested
            });
        }
        if (this.form.controls['validated'].value == true) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'validated',
                value: this.form.controls['validated'].value
            });
        }
        if (this.form.controls['withoutInterest'].value == true) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'withoutInterest',
                value: this.form.controls['withoutInterest'].value
            });
        }
        if (this.form.controls['distance'].value) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'hospital.geo',
                value: this.form.controls['distance'].value
            });
        }
        if (this.form.controls['region'].value && this.form.controls['region'].value.length > 0) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: 'hospital.addrZIP',
                value: this.form.controls['region'].value
            });
        }
        this.filtersApplied.emit({
            filters,
            replaceHistory
        });
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
        this.form.markAsPristine();
    }
    resetFilters() {
        this.form.controls.interest.reset();
        this.form.controls.notInterest.reset();
        this.form.controls.validated.reset();
        this.form.controls.distance.reset();
        this.form.controls.region.reset();
        this.filtersApplied.emit({
            filters: [],
            replaceHistory: true
        });
        // Self close sidebar
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
    }
    setFormControls() {
        this.form.setControl('interest', new UntypedFormControl(this.getUrlFilter('interest', EInterest.Interested) == EInterest.Interested));
        this.form.setControl('notInterest', new UntypedFormControl(this.getUrlFilter('interest', EInterest.NotInterested) == EInterest.NotInterested));
        this.form.setControl('validated', new UntypedFormControl(this.getUrlFilter('validated')));
        this.form.setControl('withoutInterest', new UntypedFormControl(this.getUrlFilter('withoutInterest')));
        this.form.setControl('region', new UntypedFormControl(this.getUrlFilter('hospital.addrZIP')));
        this.form.setControl('distance', new UntypedFormControl(this.getUrlFilter('hospital.geo')));
    }
    getUrlFilter(filterAttribute, opt) {
        const filter = this.applyFilters.find((filter) => {
            if (filter.attribute === filterAttribute) {
                if (opt) {
                    return filter.value === opt;
                }
                return true;
            }
            return false;
        });
        if (filter) {
            if (filter.type === EFilterType.VALUE) {
                return filter.value;
            }
            else if (filter.type === EFilterType.VALUES) {
                return filter.values.includes(opt);
            }
            else if (filter.type === EFilterType.RANGE) {
                return filter;
            }
        }
        return null;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], FilterSidebarComponent.prototype, "applyFilters", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], FilterSidebarComponent.prototype, "filtersApplied", void 0);
FilterSidebarComponent = __decorate([
    Component({
        selector: 'mpx-filter-sidebar',
        template: require('./filter-sidebar.component.html').default,
        styles: [require('./filter-sidebar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object])
], FilterSidebarComponent);
export { FilterSidebarComponent };
