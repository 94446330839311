var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { UrlService } from '@services/url/url.service';
import { StatsActivitiesService } from '@services/stats/stats-activities.service';
import { TranslationService } from '@app/services/translation/translation.service';
let ListActivitiesComponent = class ListActivitiesComponent {
    constructor(_translateService, _statsActivitiesService, _urlService, _fuseTranslationLoaderService, _translationService) {
        var _a, _b;
        this._translateService = _translateService;
        this._statsActivitiesService = _statsActivitiesService;
        this._urlService = _urlService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this.dateLimits = {
            start: (_a = this.getFilter('period')) === null || _a === void 0 ? void 0 : _a.start,
            end: (_b = this.getFilter('period')) === null || _b === void 0 ? void 0 : _b.end
        };
        this.updateGraph = new EventEmitter();
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Header
        this.pageHeaderConfig = {
            title: this._translationService.instant('STATS_HOSPIVILLE.HEADER.TITLE'),
            icon: 'local_hospital'
        };
        // Toolbar
        this.sinceDateConfig = {
            button: {
                icon: { name: 'date_range', classes: ['secondary-text'] },
                label: this._translationService.instant('COMMONS.WORDS.PERIOD'),
                classes: ['cap']
            }
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngAfterViewInit() {
        this.updateGraph.emit();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    getFilter(attribute) {
        if (!this._urlService.getParam('filters')) {
            return null;
        }
        const filters = this._urlService.getParam('filters');
        return filters.find((filter) => filter.attribute === attribute);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSinceChange($event) {
        this.dateLimits = $event;
    }
    getActivitiesGraphData(step, params) {
        return new Promise((resolve, reject) => {
            this._statsActivitiesService.getCreationGraphData(step, params.period).subscribe((graphData) => {
                resolve([
                    {
                        name: this._translateService.instant('STATS_HOSPIVILLE.GRAPH_CREATION.CURVES.TOTAL'),
                        series: graphData
                    }
                ]);
            }, reject);
        });
    }
};
ListActivitiesComponent = __decorate([
    Component({
        selector: 'mpx-list-activities',
        template: require('./list-activities.component.html').default,
        styles: [require('./list-activities.component.scss')],
        animations: fuseAnimations,
        providers: [StatsActivitiesService]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _a : Object, typeof (_b = typeof StatsActivitiesService !== "undefined" && StatsActivitiesService) === "function" ? _b : Object, typeof (_c = typeof UrlService !== "undefined" && UrlService) === "function" ? _c : Object, typeof (_d = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object])
], ListActivitiesComponent);
export { ListActivitiesComponent };
