import * as angular from 'angular';
import { UnknownUserController } from './unknown-user.controller';
angular.module('mapui.services.auth.unknown-user', [])
    .config(config)
    .controller('UnknownUserController', UnknownUserController);
/** @ngInject */
function config($stateProvider) {
    $stateProvider.state('mapui.page_auth_unknown_user', {
        url: '/auth/unknown-user',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.page_auth_unknown_user': {
                template: require('./unknown-user.html').default,
                controller: 'UnknownUserController as vm',
            },
        },
        bodyClass: 'unknown-user',
    });
}
