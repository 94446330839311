export const locale = {
    lang: 'fr',
    data: {
        MAIL_DETAILS: {
            NOT_SELECTED: `Sélectionnez un message à lire`,
            FROM: `De :`,
            TO: `À :`,
            ATTACHMENTS: {
                TITLE: `Pièces jointes`,
                TOOLTIP: `Retrouvez toutes les pièces jointes dans l'onglet Fichiers.`
            }
        }
    }
};
