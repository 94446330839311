var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HospivilleToolbarComponent } from './toolbar.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
let HospivilleToolbarModule = class HospivilleToolbarModule {
};
HospivilleToolbarModule = __decorate([
    NgModule({
        declarations: [HospivilleToolbarComponent],
        imports: [
            CommonModule,
            FlexModule,
            MatIconModule,
            TranslateModule,
            MatFormFieldModule,
            MatAutocompleteModule,
            MatInputModule,
            MatSelectModule,
            MatButtonModule,
            FormsModule,
            MatTooltipModule
        ],
        exports: [HospivilleToolbarComponent]
    })
], HospivilleToolbarModule);
export { HospivilleToolbarModule };
