var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { EGroupType, ELoanMapCategory } from '@mapuilabs/mpl-interfaces';
import { HospitalService } from '@services/hospital/hospital.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
const config = {
    layers: {
        available: {
            icon: 'assets/images/mapui/pins/marker_fuse_yellow.png',
            category: ELoanMapCategory.Available
        },
        request: {
            icon: 'assets/images/mapui/pins/marker_fuse_red.png',
            category: ELoanMapCategory.Request
        },
        avAndRe: {
            icon: 'assets/images/mapui/pins/marker_fuse_yellow_red.png',
            category: ELoanMapCategory.AvailableAndRequest
        },
        others: {
            icon: 'assets/images/mapui/pins/marker_fuse_blue.png',
            category: ELoanMapCategory.None
        }
    }
};
let ExchangeMapService = class ExchangeMapService {
    constructor(_fuseSidebarService, _hospitalService, _snackbarService) {
        this._fuseSidebarService = _fuseSidebarService;
        this._hospitalService = _hospitalService;
        this._snackbarService = _snackbarService;
        this._availableLayerGroup = L.layerGroup();
        this._requestLayerGroup = L.layerGroup();
        this._avAndReLayerGroup = L.layerGroup();
        this._othersLayerGroup = L.layerGroup();
        this.resetFilters();
    }
    set filterManager(filterManager) {
        this._filterManager = filterManager;
        this._filterManager.filterUpdated.subscribe((filters) => {
            // if (filters.loanType) {
            //     this.filterCategories(filters.loanType.values);
            // }
        });
    }
    set markerClusterGroup(group) {
        this._markerClusterGroup = group;
    }
    get markerClusterGroup() {
        return this._markerClusterGroup;
    }
    get loanMapHospitals() {
        return this._loanMapHospitals;
    }
    set loanMapHospitals(loansMapHospitals) {
        this._loanMapHospitals = loansMapHospitals;
    }
    createMarkers() {
        this.flushGroups();
        for (const cat of this._loanMapHospitals) {
            let group = null;
            let conf = null;
            switch (cat.category) {
                case ELoanMapCategory.Available:
                    group = this._availableLayerGroup;
                    conf = config.layers.available;
                    break;
                case ELoanMapCategory.Request:
                    group = this._requestLayerGroup;
                    conf = config.layers.request;
                    break;
                case ELoanMapCategory.AvailableAndRequest:
                    group = this._avAndReLayerGroup;
                    conf = config.layers.avAndRe;
                    break;
                case ELoanMapCategory.None:
                    group = this._othersLayerGroup;
                    conf = config.layers.others;
                    break;
            }
            for (const item of cat.items) {
                if (!item.hospital.geo || !group) {
                    continue;
                }
                group.addLayer(this._createMarker(item.hospital, conf));
            }
        }
        this._addLayers();
    }
    _addLayers() {
        this._markerClusterGroup.clearLayers();
        for (const elem of this._categoriesToShow) {
            switch (elem) {
                case ELoanMapCategory.Available:
                    this._markerClusterGroup.addLayer(this._availableLayerGroup);
                    break;
                case ELoanMapCategory.Request:
                    this._markerClusterGroup.addLayer(this._requestLayerGroup);
                    break;
                case ELoanMapCategory.AvailableAndRequest:
                    this._markerClusterGroup.addLayer(this._avAndReLayerGroup);
                    break;
                default:
                    this._markerClusterGroup.addLayer(this._othersLayerGroup);
            }
        }
    }
    _createMarker(hospital, config) {
        if (hospital.geo) {
            const newMarker = L.marker([hospital.geo[1], hospital.geo[0]], {
                icon: L.icon({
                    iconSize: [40, 40],
                    iconAnchor: [30, 40],
                    iconUrl: config.icon,
                    iconRetinaUrl: config.icon
                    // shadowUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png',
                })
            });
            newMarker.on('click', (ev) => {
                this.sidebarHospital = hospital._id;
                this._openDetailsSidebar('sidebar-details');
            });
            return newMarker;
        }
    }
    _openDetailsSidebar(sidebarName) {
        this.sidebarOpened = true;
        this._fuseSidebarService.getSidebar(sidebarName).open();
    }
    openHospitalSidebar(hospId) {
        this.sidebarHospital = hospId;
        this._openDetailsSidebar('sidebar-details');
    }
    getHospitalDetails(hid) {
        return new Observable((observer) => {
            const fullHospital = {
                data: null,
                available: null,
                ght: null
            };
            this._hospitalService.getById(hid).subscribe((hospital) => {
                fullHospital.data = hospital;
                if (hospital.groups) {
                    for (const group of hospital.groups) {
                        if (group.type == EGroupType.GHT) {
                            fullHospital.ght = group;
                        }
                    }
                }
                forkJoin([
                    //this._hospitalService.getMembers(hospital._id),
                    this._hospitalService.getAvailable(hospital._id),
                    this._hospitalService.getRequestLoans(hospital._id)
                ]).subscribe((res) => {
                    //const validMembers = res[0].filter(member => member.acceptedCGU && member.firstName && member.familyName && member.email);
                    //this.sidebarHospital.data.pharmacists = validMembers.filter(member => member.type === EMemberStatus.Pharmacist);
                    //this.sidebarHospital.data.preparators = validMembers.filter(member => member.type === EMemberStatus.Preparator);
                    fullHospital.available = res[0];
                    fullHospital.data.requests = res[1];
                    observer.next(fullHospital);
                    observer.complete();
                }, (error) => observer.error(error));
            }, (error) => observer.error(error));
        });
    }
    flushGroups() {
        this._availableLayerGroup.clearLayers();
        this._requestLayerGroup.clearLayers();
        this._avAndReLayerGroup.clearLayers();
        this._othersLayerGroup.clearLayers();
        this._markerClusterGroup.clearLayers();
    }
    resetFilters() {
        this._search = null;
        this._categoriesToShow = [
            ELoanMapCategory.Available,
            ELoanMapCategory.Request,
            ELoanMapCategory.AvailableAndRequest,
            ELoanMapCategory.None
        ];
        this._groupToShow = null;
    }
    resetSidebar() {
        this.sidebarHospital = null;
        this.sidebarOpened = false;
    }
    filterGroup(group) {
        this._groupToShow = group;
        this.createMarkers();
    }
    updateSearch(search) {
        this._search = search;
        this.createMarkers();
    }
};
ExchangeMapService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _a : Object, typeof (_b = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object])
], ExchangeMapService);
export { ExchangeMapService };
