var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { ActivityASchema, EActivityStatus, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CActivityStatuses } from '@shared/constTypes/activity/activity-status.const';
import { CActivityOrigin } from '@shared/constTypes/activity/activity-origin.const';
import { ActivityDestService } from '@services/hospiville/activities/activities-dest.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { DialogService } from '@services/dialog/dialog.service';
import { ImportFilesModalComponent } from '../import-files-modal/import-files-modal.component';
let FilesTabDestComponent = class FilesTabDestComponent {
    constructor(_activityService, _fuseTranslationLoaderService, _translationService, _matDialog, _dialogService, _snackBarService) {
        this._activityService = _activityService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._matDialog = _matDialog;
        this._dialogService = _dialogService;
        this._snackBarService = _snackBarService;
        this.cActivityStatuses = CActivityStatuses;
        this.cActivityOrigin = CActivityOrigin;
        /**
         * Emits when the activity has been modified.
         */
        this.onActivityChanged = new EventEmitter();
        /**
         * Emits when the tooltip on the save button of the parent component should be toggled.
         */
        this.showCanceledActivityTooltip = new EventEmitter();
        this.isFinished = false;
        this.isWaiting = false;
        this.isCanceled = false;
        this.selectFile = (file) => {
            this.selectedFile = file;
            this.commentForm = new UntypedFormGroup({
                comment: new UntypedFormControl(this.selectedFile.comment)
            });
        };
        /**
         * Opens a modal to import multiple files about the activity (same as on Information/Actions tab).
         */
        this.openImportFilesModal = () => {
            this._matDialog
                .open(ImportFilesModalComponent, { disableClose: false, maxWidth: '750px' })
                .afterClosed()
                .pipe(take(1))
                .subscribe((files) => {
                if (files) {
                    this._activityService
                        .addFiles(this.activity, files, this.isFinished ? EActivityStatus.EndedWithFiles : EActivityStatus.Files)
                        .subscribe((updatedActivity) => {
                        if (updatedActivity && updatedActivity._id === this.activity._id) {
                            this.onActivityChanged.emit(); // Refresh this.activity @Input()
                            this._snackBarService.open(this._translationService.instant('ACTIVITY_DETAILS.SNACKBAR.FILES_ADDED'));
                        }
                        else {
                            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
        /**
         * Save comment from IActivityFile to IActivity
         */
        this.saveComment = () => {
            if (!this.selectedFile) {
                this.commentMenuTrigger.closeMenu();
                return;
            }
            this.selectedFile.comment = this.commentForm.value.comment;
            this._activityService
                .save(this.activity)
                .pipe(take(1))
                .subscribe(() => {
                this.onActivityChanged.emit();
                this.commentMenuTrigger.closeMenu();
                this._snackBarService.open(this._translationService.instant('SNACKBAR.COMMENT_ADDED'));
            }, this._snackBarService.openError);
        };
        /**
         * Delete this file from File collection and in the Activity object on db
         */
        this.delete = () => {
            const dialogConfig = {
                title: this._translationService.instant('CONFIRM_DIALOG.DELETE_TITLE'),
                message: this._translationService.instant('CONFIRM_DIALOG.DELETE_MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            this._dialogService
                .openConfirmDialog(dialogConfig)
                .pipe(take(1))
                .subscribe((data) => {
                if (data) {
                    this._activityService
                        .deleteFile(this.selectedFile.file._id)
                        .pipe(take(1))
                        .subscribe((updatedActivity) => {
                        if (updatedActivity) {
                            this.selectedFile = null;
                            this.onActivityChanged.emit(); // Trigger refresh this.activity @Input()
                            if (updatedActivity.files.length === 0) {
                                return this._snackBarService.open(this._translationService.instant('SNACKBAR.NO_MORE_FILES'));
                            }
                            this._snackBarService.open(this._translationService.instant(`SNACKBAR.DELETED`));
                        }
                        else {
                            this._snackBarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                        }
                    }, this._snackBarService.openError);
                }
            }, this._snackBarService.openError);
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnChanges() {
        this.isFinished =
            this.activity.status === this.cActivityStatuses.ended.value ||
                this.activity.status === this.cActivityStatuses.medRec.value ||
                this.activity.status === this.cActivityStatuses.endedWithFiles.value;
        this.isWaiting = this.activity.status === this.cActivityStatuses.waiting.value;
        this.isCanceled = this.activity.status === this.cActivityStatuses.canceled.value;
    }
    toggleEndCancelTooltip() {
        if (this.isFinished) {
            switch (this.activity.status) {
                case EActivityStatus.MedRec:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.GOT_MEDREC');
                case EActivityStatus.Ended:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.END_ACTIVITY.ALREADY_ENDED');
                case EActivityStatus.Canceled:
                    return this._translationService.instant('ACTIVITY_DETAILS.TABS.ACTIONS.BUTTONS.CANCEL_ACTIVITY.ALREADY_CANCELED');
            }
        }
        return '';
    }
};
__decorate([
    ViewChild(MatMenuTrigger),
    __metadata("design:type", typeof (_g = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _g : Object)
], FilesTabDestComponent.prototype, "commentMenuTrigger", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FilesTabDestComponent.prototype, "currentTabIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_h = typeof ActivityASchema !== "undefined" && ActivityASchema) === "function" ? _h : Object)
], FilesTabDestComponent.prototype, "activity", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_j = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _j : Object)
], FilesTabDestComponent.prototype, "onActivityChanged", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_k = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _k : Object)
], FilesTabDestComponent.prototype, "showCanceledActivityTooltip", void 0);
FilesTabDestComponent = __decorate([
    Component({
        selector: 'hvx-files-tab-dest',
        template: require('./files-tab.component.html').default,
        styles: [require('./files-tab.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ActivityDestService !== "undefined" && ActivityDestService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof DialogService !== "undefined" && DialogService) === "function" ? _e : Object, typeof (_f = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _f : Object])
], FilesTabDestComponent);
export { FilesTabDestComponent };
