export const locale = {
    lang: 'fr',
    data: {
        NAVIGATION: {
            EQUIVALENCES: {
                TITLE: 'Equivalences'
            }
        },
        EQUIVALENCES: {
            TITLE: 'Equivalences',
            SEARCH: 'Rechercher une equivalence',
            BUTTON_ADD: 'Ajouter une Equivalence',
            BUTTON_EXCEL: 'Importer',
            MEDIC: 'Médicament',
            MEDIC_DCI: 'DCI du médicament',
            MEDIC_POSOLOGY: 'Posologie du médicament',
            EQUIVALENCE: 'Equivalence',
            EQUIVALENCE_DCI: "DCI de l'équivalence",
            ACTION: 'Action',
            REASON: 'Raison',
            DELIVER_MEDIC: 'Delivré après avis médical',
            EDIT: 'Editer',
            ACTIONS: 'Actions',
            CREATE_VOUCHER: 'Générer un bon',
            SHARING: {
                SHARES: 'Partages',
                SHARE_WITH: 'Partager avec :'
            },
            ADD_MODAL: {
                NEW: 'Ajouter une équivalence',
                MEDIC_DESC: 'Informations sur le médicament :',
                MEDIC_NAME: 'Nom du médicament',
                MEDIC_DCI: 'Dénomination commune internationale (DCI)',
                MEDIC_POSOLOGY: 'Posologie du médicament',
                EQUI_DESC: "Informations sur l'équivalent :",
                EQUI_NAME: "Nom de l'équivalent",
                EQUI_DCI: 'Dénomination commune internationale (DCI)',
                EQUI_DESC2: 'Informations sur le second équivalent :',
                DELIVER: 'Delivré',
                DELIVER_MEDIC: 'Delivré après avis médical',
                DELIVERY_TYPE: 'Type de livraison',
                COMMENT: 'Posologies équivalentes',
                ACTION: 'Action',
                REASON: 'Raison',
                OK: 'Ajouter une équivalence',
                EDIT: "Modifier l'équivalence",
                SUCCESS: {
                    NEW: 'Équivalence crée',
                    EDIT: 'Équivalence modifiée'
                }
            },
            SHARED: 'Partagée',
            DELETE_MODAL: {
                TITLE: 'Supprimer cette équivalence',
                TEXT: 'Souhaitez-vous vraiment supprimer cette équivalence ?',
                SUCCESS: 'Équivalence supprimée'
            },
            DELETE_VOUCHER_MODAL: {
                TITLE: 'Supprimer ce bon',
                TEXT: 'Souhaitez-vous vraiment supprimer ce bon ?'
            },
            IMPORT_MODAL: {
                TITLE: 'Importer des equivalences',
                FIRST_ROW: 'Utiliser la première ligne comme noms de colonnes :',
                SELECT_SHEET: 'Feuille de calcul a utiliser',
                SELECT_COLUMN: 'Indiquer quelles colonnes doivent êtres utilisées',
                MEDIC_NAME: 'Nom du médicament',
                MEDIC_DCI: 'Dénomination commune internationale (DCI)',
                MEDIC_POSOLOGY: 'Posologie du médicament',
                EQUI_NAME: "Nom de l'équivalent",
                EQUI_DCI: 'Dénomination commune internationale (DCI)',
                DELIVERY_TYPE: 'Type de livraison',
                ACTION: 'Action',
                REASON: 'Raison',
                COMMENT: 'Posologies équivalentes',
                OK: 'Importer'
            },
            IMPORT: {
                REPORT: {
                    SUCCESS: {
                        STATUS: 'Équivalences créées',
                        INSTRUCTIONS: 'Les équivalences listées ci-dessous ont été correctement créées.'
                    },
                    ERROR: {
                        STATUS: 'Équivalences non importées',
                        INSTRUCTIONS: "Les équivalences listées ci-dessous ont rencontré une erreur et n'ont pas pu être importées."
                    }
                }
            },
            PDF_MODAL: {
                TITLE: 'Générer un bon pour cette équivalence',
                SERVICE: 'Service',
                PATIENT_NAME: 'Nom et prénom du patient',
                PATIENT_ROOM: 'Chambre du patient',
                DATE: 'Délivré le',
                REASON: 'Raison',
                REASONS: {
                    NO_BOOKLET: 'Hors livret (ne commandons pas)',
                    NOT_APPROVED: 'Non agréé au hopitaux (ne commandons pas)',
                    LABORATORY_SOLDOUT: 'Rupture Laboratoire',
                    WAITING_DELIVERY: 'En attente de livraison',
                    BY_PATIENT: 'Médicament apporté par le patient'
                },
                GENERATE: 'Créer et telecharger un bon',
                SAVE: 'Sauvegarder',
                DOWNLOAD: 'Telecharger le bon',
                DOSAGE_MEDIC: 'Posologie du médicament',
                DOSAGE_EQUIVALENCE: "Posologie de l'équivalence",
                DOSAGE_EQUIVALENCE2: 'Posologie de la seconde équivalence'
            },
            DETAILS: {
                TITLE: 'Equivalence',
                INFO: 'Informations',
                MEDIC_NAME: 'Nom du médicament',
                MEDIC_DCI: 'Dénomination commune internationale (DCI)',
                MEDIC_POSOLOGY: 'Posologie du médicament',
                EQUI_NAME: "Nom de l'équivalent",
                EQUI_DCI: 'Dénomination commune internationale (DCI)',
                COMMENT: 'Remarque'
            },
            SIDENAV: {
                INFO: 'Informations',
                MEDIC_NAME: 'Nom du médicament',
                MEDIC_DCI: 'DCI du médicament',
                MEDIC_POSOLOGY: 'Posologie du médicament',
                EQUIVALENCE_NAME: "Nom de l'équivalence",
                EQUIVALENCE_DCI: "DCI de l'équivalence",
                EQUIVALENCE_NAME2: 'Nom de la 2nd équivalence',
                EQUIVALENCE_DCI2: 'DCI de la 2nd équivalence',
                COMMENT: 'Posologies équivalentes',
                VOUCHER: 'Historique',
                ADD_VOUCHER: 'Générer un Bon',
                MODIFIED_BY: 'Dernière modif. par',
                MODIFIED_WHEN: 'Date de dernière modif.'
            }
        }
    }
};
