var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { EHsHealthProductTypes, EHsPublicationType, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
let HsPublicationDetailSummaryComponent = class HsPublicationDetailSummaryComponent {
    constructor(_changeDetectorRef, _translationService, _publicationContainerService, _healthProductService) {
        this._changeDetectorRef = _changeDetectorRef;
        this._translationService = _translationService;
        this._publicationContainerService = _publicationContainerService;
        this._healthProductService = _healthProductService;
        // Enum
        this.ePublicationType = EHsPublicationType;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        // Init Subjects
        this._unsubscribeAll = new Subject();
    }
    // Accessors
    get publicationContainer() {
        var _a;
        return (_a = this.publicationContainer$) === null || _a === void 0 ? void 0 : _a.value;
    }
    get publication() {
        return this.publicationContainer.publication;
    }
    get bookletItems() {
        return this.publicationContainer.bookletItems;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this.publicationContainer$.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            // Update local publication properties
            this._updateLocalPublicationProperties();
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        });
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    getRemaindersConservationText(value) {
        if (typeof value === 'boolean') {
            return this._translate(value === true ? 'COMMONS.YES' : 'COMMONS.NO');
        }
        else {
            return this._translate('PUBLICATION.MODAL.FORM.REMAINDERS_CONSERVATION.ACTIVE.NOT_PRACTICABLE');
        }
    }
    getRegulatoryQuotaText(value) {
        if (typeof value === 'boolean') {
            return this._translate(value === true ? 'COMMONS.YES' : 'COMMONS.NO');
        }
        else {
            return this._translate('COMMONS.UNKNOWN');
        }
    }
    getCustomStyle(value) {
        if (typeof value === 'boolean') {
            return value === true ? 'green-fg' : 'red-fg';
        }
    }
    _updateLocalPublicationProperties() {
        this.isTerminated = this._publicationContainerService.isPublicationTerminated(this.publication);
        this.isFuture = this._publicationContainerService.isFuturePublication(this.publication);
        this.isPfizerLaboExp = this._publicationContainerService.isPfizerLaboExp(this.publication);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Get the publication source depending of the publication creatorType
     * @param publication HsPublication to process
     * @returns HsPublication source
     */
    getPublicationSource(publication) {
        return publication.creatorType === EHsUserTypes.GA
            ? publication.creatorGroup.name
            : publication.creator.name;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof BehaviorSubject !== "undefined" && BehaviorSubject) === "function" ? _e : Object)
], HsPublicationDetailSummaryComponent.prototype, "publicationContainer$", void 0);
HsPublicationDetailSummaryComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-summary',
        template: require('./hs-publication-detail-summary.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _c : Object, typeof (_d = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _d : Object])
], HsPublicationDetailSummaryComponent);
export { HsPublicationDetailSummaryComponent };
