import * as angular from 'angular';
import { SocketService } from './socket.service';
import { SocketResourceProvider } from './socket-resource.service';
(function () {
    'use strict';
    angular
        .module('mapui.services.socket', [])
        .service('SocketService', SocketService)
        .provider('$socketResource', SocketResourceProvider);
})();
