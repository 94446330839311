var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { take, takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { locale as french } from '../../../../i18n/fr';
import { locale as english } from '../../../../i18n/en';
import { EAvailableStatus, EHTTPStatus, EInterest } from '@mapuilabs/mpl-interfaces';
import { CDrugPreservation } from '@shared/constTypes/availables/drugPreservation.const';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { DialogService } from '@services/dialog/dialog.service';
import { HospitalService } from '@services/hospital/hospital.service';
import { AvailableHospitalService } from '../available-hospital.service';
import { EditAvailableComponent } from '../available-hospital-list/edit-available/edit-available.component';
import { AcceptAvailableOfferComponent } from './accept-available-offer/accept-available-offer.component';
import { AvailableOfferHospitalService } from '../available-offer-hospital.service';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
let AvailableHospitalDetailComponent = class AvailableHospitalDetailComponent {
    constructor(_availableHospitalService, _fuseTranslationLoaderService, _availableOfferHospitalService, _hospitalService, _snackbarService, _translationService, _dialogService, _matDialog) {
        this._availableHospitalService = _availableHospitalService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._availableOfferHospitalService = _availableOfferHospitalService;
        this._hospitalService = _hospitalService;
        this._snackbarService = _snackbarService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._matDialog = _matDialog;
        this.cDrugPreservation = CDrugPreservation;
        this.unsubscribe = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        this.dynamicDataContainerConfig = {
            noData: {
                icon: 'shopping_cart',
                label: this._translationService.instant('AVAILABLE.DETAILS.NO_SELECTED_AVAILABLE')
            }
        };
    }
    ngOnInit() {
        this._availableHospitalService.onCurrentAvailableChanged
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((currentAv) => {
            this.offersDistances = [];
            if (currentAv === null || currentAv === void 0 ? void 0 : currentAv.offers) {
                this.getDistances(currentAv.offers);
            }
            this.setStep(null); // Reset stepper
            this.currentAvAndOffers = currentAv;
        });
    }
    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
    /**
     * Get only AvailableOffer as EInterest.Interested
     */
    get offers() {
        var _a;
        return (_a = this.currentAvAndOffers) === null || _a === void 0 ? void 0 : _a.offers.filter((offer) => offer.interest === EInterest.Interested);
    }
    get available() {
        var _a;
        return (_a = this.currentAvAndOffers) === null || _a === void 0 ? void 0 : _a.available;
    }
    get product() {
        var _a;
        return (_a = this.available) === null || _a === void 0 ? void 0 : _a.drug;
    }
    get isClosed() {
        return this.available.status === EAvailableStatus.Closed;
    }
    get isArchived() {
        return this.available.status === EAvailableStatus.Archived;
    }
    isValidated(offer) {
        return offer.validated;
    }
    getPreservationTranslation(value) {
        return this._translationService.getTranslation(this.cDrugPreservation, value);
    }
    getDistances(offers) {
        var _a;
        if (!offers) {
            return;
        }
        for (const offer of offers) {
            if (!((_a = offer === null || offer === void 0 ? void 0 : offer.hospital) === null || _a === void 0 ? void 0 : _a._id)) {
                return;
            }
            this._hospitalService
                .getDistance(offer.hospital._id)
                .pipe(take(1))
                .subscribe((distance) => {
                this.offersDistances[offer._id] = distance;
            }, console.error);
        }
    }
    get discount() {
        if (this.available.unitPrice == null || this.available.drug.manufacturerPrice == null) {
            return undefined;
        }
        if (isNaN(this.available.unitPrice) || isNaN(this.available.drug.manufacturerPrice)) {
            return undefined;
        }
        return Number(((1 - this.available.unitPrice / this.available.drug.manufacturerPrice) * 100).toFixed(2));
    }
    get hasReachedEndDate() {
        return new Date(this.available.endDate).getTime() - Date.now() <= 0;
    }
    get cannotEdit() {
        return this.hasReachedEndDate; //TODO: && freemium
    }
    get showDCIs() {
        var _a;
        return (_a = this.product.DCI) === null || _a === void 0 ? void 0 : _a.slice(0, 4).join(', ');
    }
    get totalPrice() {
        return Number((this.available.quantity * this.available.unitPrice).toFixed(2));
    }
    setStep(index) {
        this.step = index;
    }
    openOfferModal() {
        this._matDialog
            .open(EditAvailableComponent, {
            minHeight: '540px',
            maxHeight: '640px',
            minWidth: '640px',
            maxWidth: '940px',
            data: { available: this.available }
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return; // Canceled, do nothing
            }
            this._availableHospitalService
                .save(res)
                .pipe(take(1))
                .subscribe((available) => {
                this.refreshAvailableAfterUpdate(available);
            }, this._snackbarService.openErrorFromStatus);
        }, console.error);
    }
    openAcceptModal(offer) {
        this._matDialog
            .open(AcceptAvailableOfferComponent, {
            minHeight: '450px',
            maxHeight: '600px',
            width: '960px',
            data: { available: this.currentAvAndOffers.available, offers: [offer] }
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return; // Canceled, do nothing
            }
            this.currentAvAndOffers.available.status = EAvailableStatus.Closed;
            offer.validated = true;
            this._availableOfferHospitalService
                .save(offer)
                .pipe(take(1))
                .subscribe(() => {
                this._availableHospitalService
                    .save(this.available)
                    .pipe(take(1))
                    .subscribe((available) => {
                    this.refreshAvailableAfterUpdate(available);
                }, this._snackbarService.openErrorFromStatus);
            }, this._snackbarService.openErrorFromStatus);
        }, this._snackbarService.openErrorFromStatus);
    }
    deleteAvailable() {
        if (!this.currentAvAndOffers) {
            return;
        }
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('AVAILABLE.DETAILS.DELETE.TITLE'),
            message: this._translationService.instant('AVAILABLE.DETAILS.DELETE.MESSAGE')
        })
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return;
            }
            this._availableHospitalService
                .delete(this.available)
                .pipe(take(1))
                .subscribe((res) => {
                if (res === 'OK') {
                    return this._snackbarService.open(this._translationService.instant('AVAILABLE.DETAILS.DELETE.DELETED'));
                }
                this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }, this._snackbarService.openErrorFromStatus);
        }, this._snackbarService.openErrorFromStatus);
    }
    archiveAvailable() {
        if (!this.currentAvAndOffers) {
            return;
        }
        this._dialogService
            .openConfirmDialog({
            title: this._translationService.instant('AVAILABLE.DETAILS.ARCHIVE.TITLE'),
            message: this._translationService.instant('AVAILABLE.DETAILS.ARCHIVE.MESSAGE')
        })
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return;
            }
            this.available.status = EAvailableStatus.Archived;
            this._availableHospitalService
                .save(this.available)
                .pipe(take(1))
                .subscribe((available) => {
                this.refreshAvailableAfterUpdate(available);
            }, this._snackbarService.openErrorFromStatus);
        }, this._snackbarService.openErrorFromStatus);
    }
    refreshAvailableAfterUpdate(available) {
        if (!(available === null || available === void 0 ? void 0 : available._id)) {
            return this._snackbarService.openErrorFromStatus(EHTTPStatus.NotFound);
        }
        this._availableHospitalService
            .getById(available._id)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.NotFound);
            }
            this.currentAvAndOffers.available = res;
            this._availableHospitalService.setCurrentAvailable(this.currentAvAndOffers);
            this._snackbarService.open(this._translationService.instant('AVAILABLE.EDIT.AVAILABLE_VALIDATED'));
        }, this._snackbarService.openErrorFromStatus);
    }
};
AvailableHospitalDetailComponent = __decorate([
    Component({
        selector: 'mpx-available-hospital-detail',
        template: require('./available-hospital-detail.component.html').default,
        styles: [require('./available-hospital-detail.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AvailableHospitalService !== "undefined" && AvailableHospitalService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof AvailableOfferHospitalService !== "undefined" && AvailableOfferHospitalService) === "function" ? _c : Object, typeof (_d = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object, typeof (_f = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _f : Object, typeof (_g = typeof DialogService !== "undefined" && DialogService) === "function" ? _g : Object, typeof (_h = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _h : Object])
], AvailableHospitalDetailComponent);
export { AvailableHospitalDetailComponent };
