var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component } from '@angular/core';
import { IdentityService } from '../../../../services/identity/identity.service';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
const DATE_INPUT_FORMAT = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
let ExDatesSelectorComponent = class ExDatesSelectorComponent {
    constructor(identityService) {
        this.identityService = identityService;
        this.startDate = moment.now();
        this.displayUnitOfTime = 'isoWeeks';
        this.skipUnitOfTime = 'weeks';
        this.unitToSkip = 1;
        this.prefix = '';
        this.suffix = '';
        this.format = 'dddd D MMMM YYYY';
        this.lang = 'fr';
        this.pageHeaderConfig = {
            title: 'Dates selector Component',
            icon: 'extension '
        };
    }
};
ExDatesSelectorComponent = __decorate([
    Component({
        selector: 'mpx-ex-dates-selector',
        template: require('./ex-dates-selector.component.html').default,
        styles: [require('./ex-dates-selector.component.scss')],
        providers: [
            {
                provide: DateAdapter,
                useClass: MomentDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            },
            { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMAT }
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object])
], ExDatesSelectorComponent);
export { ExDatesSelectorComponent };
