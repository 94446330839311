var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HsAlternativeContainerService } from '@app/hospistock/shared/services/hs-alternative-container.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
let HsAlternativeToolbarComponent = class HsAlternativeToolbarComponent {
    constructor(_fuseSidebarService, _alternativeContainerService) {
        this._fuseSidebarService = _fuseSidebarService;
        this._alternativeContainerService = _alternativeContainerService;
        this.filtersApplied = new EventEmitter();
        // Init Subjects
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Debound to avoid to much request to the server
        this.refreshList = debounce(this.refreshList, 800, {
            leading: true,
            trailing: false
        });
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Download a spreadsheet matching the current sort & filters
     */
    downloadSpreadsheet() {
        return __awaiter(this, void 0, void 0, function* () {
            // Update pending flag
            this.isDownloadSpreadsheetPending = true;
            try {
                yield this._alternativeContainerService.downloadAlternativesSpreadsheet(this.searchValue, this.sortValue, this.filtersValues);
            }
            finally {
                // Update pending flag
                this.isDownloadSpreadsheetPending = false;
            }
        });
    }
    /**
     * Refresh the list by triggering the ListPage _reload method
     */
    refreshList() {
        this._alternativeContainerService.onReloadList.next();
    }
    /**
     * Finds the sidebar matching the given name and opens it if it's close.
     * @param sidebarName: String equals to the fuse-sidebar name attribute.
     */
    openFiltersSidebar(sidebarName) {
        this._fuseSidebarService.getSidebar(sidebarName).open();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], HsAlternativeToolbarComponent.prototype, "filterCount", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsAlternativeToolbarComponent.prototype, "filtersValues", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], HsAlternativeToolbarComponent.prototype, "isLoading", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], HsAlternativeToolbarComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsAlternativeToolbarComponent.prototype, "sortValue", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], HsAlternativeToolbarComponent.prototype, "filtersApplied", void 0);
HsAlternativeToolbarComponent = __decorate([
    Component({
        selector: 'mpx-hs-alternative-toolbar',
        template: require('./hs-alternative-toolbar.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _a : Object, typeof (_b = typeof HsAlternativeContainerService !== "undefined" && HsAlternativeContainerService) === "function" ? _b : Object])
], HsAlternativeToolbarComponent);
export { HsAlternativeToolbarComponent };
