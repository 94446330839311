var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { CFrenchDepartments } from '../../../shared/constTypes/global/french-departments.const';
import { AdminARSService } from '../../../services/admin/ars/admin-ars.service';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { RolesService } from '../../../services/role/roles.service';
import { TranslationService } from '@app/services/translation/translation.service';
let DetailARSComponent = class DetailARSComponent {
    constructor(_arsService, _fuseTranslationLoaderService, _snackbarService, _translationService, _rolesService) {
        this._arsService = _arsService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackbarService = _snackbarService;
        this._translationService = _translationService;
        this._rolesService = _rolesService;
        this.cFrenchDepartments = CFrenchDepartments;
        this._setFormControls = (ars) => {
            this.detailForm = new UntypedFormGroup({});
            this._setRolesFormControls(ars);
            this.detailForm.setControl('establishmentName', new UntypedFormControl(ars.name));
            this.detailForm.setControl('frenchDepartments', new UntypedFormControl(ars.frenchDepartments));
            this.detailForm.setControl('nbEstablishment', new UntypedFormControl(ars.nbEstablishment));
        };
        this._setRolesFormControls = (ars) => {
            const mapuiRolesDefault = [];
            const hospivilleRolesDefault = [];
            const workspaceRolesDefault = [];
            if (ars) {
                for (let role of ars.roles) {
                    if (this.mapuiRoles &&
                        this.mapuiRoles.length &&
                        this.mapuiRoles.filter((elem) => elem._id == role._id).length) {
                        mapuiRolesDefault.push(this.mapuiRoles.find((elem) => elem._id == role._id));
                    }
                    else if (this.hospivilleRoles &&
                        this.hospivilleRoles.length &&
                        this.hospivilleRoles.filter((elem) => elem._id == role._id).length) {
                        hospivilleRolesDefault.push(this.hospivilleRoles.find((elem) => elem._id == role._id));
                    }
                    if (this.workspaceRoles &&
                        this.workspaceRoles.length &&
                        this.workspaceRoles.filter((elem) => elem._id == role._id).length) {
                        workspaceRolesDefault.push(this.workspaceRoles.find((elem) => elem._id == role._id));
                    }
                }
            }
            this.detailForm.setControl('roleMapui', new UntypedFormControl(mapuiRolesDefault));
            this.detailForm.setControl('roleHospiville', new UntypedFormControl(hospivilleRolesDefault));
            this.detailForm.setControl('roleWorkspace', new UntypedFormControl(workspaceRolesDefault));
        };
        this.updateARS = () => {
            // PUT only info from inputs, or it breaks roles
            const newInfo = {
                _id: this.ars._id,
                name: this.detailForm.value.establishmentName,
                frenchDepartments: this.detailForm.value.frenchDepartments,
                nbEstablishment: this.detailForm.value.nbEstablishment,
                roles: this.detailForm.value.roleMapui.concat(this.detailForm.value.roleHospiville, this.detailForm.value.roleWorkspace)
            };
            this._arsService.save(newInfo).subscribe((newARS) => {
                if (newARS) {
                    this._snackbarService.open(this._translationService.instant('DETAIL.SNACKBAR.OK'));
                    this._arsService.getById(newARS._id).subscribe((updatedARS) => {
                        this.ars = updatedARS;
                        this.detailForm.markAsPristine();
                    });
                }
            }, (err) => this._snackbarService.openError(err));
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this._rolesService.getAllHospital().subscribe((data) => {
            for (let project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                }
            }
            this._setFormControls(this.ars);
        });
    }
    ngOnInit() {
        this.pageHeaderConfig = {
            title: `${this._translationService.instant('DETAIL.TITLE')} ${this.ars.name}`,
            icon: 'pin_drop'
        };
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Hospital !== "undefined" && Hospital) === "function" ? _f : Object)
], DetailARSComponent.prototype, "ars", void 0);
DetailARSComponent = __decorate([
    Component({
        selector: 'mpx-detail-ars',
        template: require('./detail-ars.component.html').default,
        styles: [],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminARSService !== "undefined" && AdminARSService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof RolesService !== "undefined" && RolesService) === "function" ? _e : Object])
], DetailARSComponent);
export { DetailARSComponent };
