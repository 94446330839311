import { EDiscrepancy, EMpStatus, EPosologyType, EStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalConciliationController {
    /** @ngInject */
    constructor(Api, _, ModalService) {
        this.Api = Api;
        this._ = _;
        this.ModalService = ModalService;
        /**
         * @type {EStatus}
         */
        this.EStatus = EStatus;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * @type {EDiscrepancy}
         */
        this.EDiscrepancy = EDiscrepancy;
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this.constTypes || (!value && value !== 0))
                return;
            return this._.find(this.constTypes[from], { value: value }).translate;
        };
        /**
         * Update hasDiscrepancy when conciliation status changes
         */
        this.calcDiscrepancy = () => {
            this.conciliation.hasDiscrepancy = !this.conciliation.status ? null : (this.conciliation.status !== EStatus.Maintain
                && this.conciliation.status !== EStatus.Equivalence);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this._close(this.conciliation);
        };
        /**
         * Close the modal with actions performing
         * @param answer
         * @private
         */
        this._close = (answer) => {
            if (answer.status === EStatus.Maintain || answer.status === EStatus.Equivalence) {
                answer.discrepancyType = null;
                answer.subDiscrepancyType = undefined;
                answer.hasDiscrepancy = undefined;
                answer.clinicalConsequence = undefined;
                answer.decision = undefined;
            }
            this.ModalService.close(answer);
        };
        this.editStatus = false;
    }
    $onChanges(changes) {
        if (changes.conciliation) {
            this.conciliation = angular.copy(this.conciliation);
        }
    }
    $onInit() {
        if (!this.conciliation.discrepancyCorrected)
            this.conciliation.discrepancyCorrected = false;
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
}
