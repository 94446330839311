import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalComplianceTestController {
    /** @ngInject */
    constructor(ModalService, Api) {
        this.ModalService = ModalService;
        this.Api = Api;
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this._close(this.test);
        };
        this.definitionPatient = () => {
            // console.log(this.constTypes);
        };
        /**
         * Close the modal with actions performing
         * @param answer
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
        /**
         * mark the value and the description of the Compliance Test
         */
        this.scoreDescription = () => {
            this.description.score = 0;
            for (let answer of this.test) {
                if (answer == false)
                    this.description.score++;
            }
            switch (this.description.score) {
                case 4:
                    this.description.comment = 'HOSPIVILLE.PIS.COMPLIANCE.GOOD';
                    this.description.color = 'green-400-fg';
                    break;
                case 3:
                case 2:
                    this.description.comment = 'HOSPIVILLE.PIS.COMPLIANCE.WEAK';
                    this.description.color = 'orange-400-fg';
                    break;
                case 1:
                case 0:
                    this.description.comment = 'HOSPIVILLE.PIS.COMPLIANCE.BAD';
                    this.description.color = 'red-400-fg';
                    break;
                default:
                    this.description.comment = '';
                    break;
            }
        };
        this.description = {
            score: -1,
            comment: '',
            color: '',
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        if (this.test.length > 0)
            this.scoreDescription();
        else
            this.test = [];
    }
    $onChanges(changes) {
        if (changes.test) {
            this.test = angular.copy(this.test);
        }
    }
}
