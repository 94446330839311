var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NgModule } from '@angular/core';
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { AdminHospitalService } from '@services/admin/hospital/admin-hospital.service';
import { AdminLaboGroupService } from '@services/admin/labo-group/admin-labo-group.service';
import { Transition } from '@uirouter/angular';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { lastValueFrom } from 'rxjs';
import { AdminDetailLabosComponent } from './detail-labos/detail-labos.component';
import { LaboGroupDetailComponent } from './labo-group-detail/labo-group-detail.component';
import { AdminListLabosComponent } from './list-labos/list-labos.component';
const states = [
    {
        name: 'mapui.adm.labos.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: AdminListLabosComponent
            }
        },
        data: {
            access: EAdminPanelPermission.AdminPanel_Read,
            theme: 'theme-purple'
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.labos.detail',
        url: '/detail/:id?tab',
        views: {
            'content@mapui': {
                component: AdminDetailLabosComponent
            }
        },
        data: {
            access: EAdminPanelPermission.AdminPanel_Read,
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: false
            },
            tab: {
                dynamic: true
            }
        },
        resolve: [
            {
                token: 'laboratory',
                deps: [Transition, AdminHospitalService],
                resolveFn: (trans, adminHospitalService) => __awaiter(void 0, void 0, void 0, function* () {
                    const idParam = trans.params().id;
                    if (idParam && idParam !== 'new') {
                        const laboratory = lastValueFrom(yield adminHospitalService.getById(idParam));
                        return laboratory;
                    }
                })
            },
            {
                token: 'laboratoryGroups',
                deps: [Transition, AdminLaboGroupService],
                resolveFn: (trans, adminLaboGroupService) => __awaiter(void 0, void 0, void 0, function* () {
                    const idParam = trans.params().id;
                    if (idParam && idParam !== 'new') {
                        const laboratoryGroups = lastValueFrom(yield adminLaboGroupService.getLaboGroupByLabo(idParam));
                        return laboratoryGroups;
                    }
                })
            }
        ]
    },
    {
        name: 'mapui.adm.labos.group-detail',
        url: '/group-detail/:id?laboratory',
        views: {
            'content@mapui': {
                component: LaboGroupDetailComponent
            }
        },
        data: {
            access: EAdminPanelPermission.AdminPanel_Read,
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: false
            }
        },
        resolve: [
            {
                token: 'laboratory',
                deps: [Transition, AdminHospitalService],
                resolveFn: (trans, adminHospitalService) => __awaiter(void 0, void 0, void 0, function* () {
                    const laboIdParam = trans.params().laboratory;
                    return lastValueFrom(yield adminHospitalService.getById(laboIdParam));
                })
            },
            {
                token: 'laboratoryGroup',
                deps: [Transition, AdminLaboGroupService],
                resolveFn: (trans, adminLaboGroupService) => __awaiter(void 0, void 0, void 0, function* () {
                    const idParam = trans.params().id;
                    if (idParam && idParam !== 'new') {
                        return yield lastValueFrom(adminLaboGroupService.getById(idParam));
                    }
                })
            }
        ]
    }
];
let AdminLabosRoutingModule = class AdminLabosRoutingModule {
};
AdminLabosRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], AdminLabosRoutingModule);
export { AdminLabosRoutingModule };
