var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
/**
 * List of classes to add to MpxButton instances based on host attributes to
 * style as different variants.
 */
var MPX_BUTTON_HOST_ATTRIBUTES;
(function (MPX_BUTTON_HOST_ATTRIBUTES) {
    MPX_BUTTON_HOST_ATTRIBUTES["BUTTON"] = "mpx-button";
    MPX_BUTTON_HOST_ATTRIBUTES["IMPORTANT_BUTTON"] = "mpx-important-button";
    MPX_BUTTON_HOST_ATTRIBUTES["MODERATE_BUTTON"] = "mpx-moderate-button";
    MPX_BUTTON_HOST_ATTRIBUTES["WARNING_BUTTON"] = "mpx-warning-button";
})(MPX_BUTTON_HOST_ATTRIBUTES || (MPX_BUTTON_HOST_ATTRIBUTES = {}));
const stylesMap = new Map([
    [MPX_BUTTON_HOST_ATTRIBUTES.BUTTON, ['mat-stroked-button', 'cap']],
    [MPX_BUTTON_HOST_ATTRIBUTES.MODERATE_BUTTON, ['mat-stroked-button', 'mat-primary', 'cap']],
    [MPX_BUTTON_HOST_ATTRIBUTES.WARNING_BUTTON, ['mat-stroked-button', 'mat-warn', 'cap']],
    [MPX_BUTTON_HOST_ATTRIBUTES.IMPORTANT_BUTTON, ['mat-raised-button', 'mat-primary', 'cap', 'mat-elevation-z0']]
]);
let MpxButtonComponent = class MpxButtonComponent extends MatButton {
    constructor(elementRef, _focusMonitor, _animationMode) {
        super(elementRef, _focusMonitor, _animationMode);
        this._animationMode = _animationMode;
        // For each of the variant selectors that is present in the button's host
        // attributes, add the correct corresponding classes.
        for (const attr of Object.values(MPX_BUTTON_HOST_ATTRIBUTES)) {
            if (this._hasHostAttributes(attr)) {
                const hostElement = this._getHostElement();
                const styleClasses = stylesMap.get(attr);
                styleClasses.forEach((styleClass) => hostElement.classList.add(styleClass));
            }
        }
    }
};
MpxButtonComponent = __decorate([
    Component({
        selector: `button[mpx-button], button[mpx-important-button], button[mpx-moderate-button], button[mpx-warning-button], a[mpx-button], a[mpx-important-button], a[mpx-moderate-button], a[mpx-warning-button]`,
        host: {
            // Note that we ignore the user-specified tabindex when it's disabled for
            // consistency with the `mat-button` applied on native buttons where even
            // though they have an index, they're not tabbable.
            '[attr.tabindex]': 'disabled ? -1 : tabIndex',
            '[attr.disabled]': 'disabled || null',
            '[attr.aria-disabled]': 'disabled.toString()',
            '[class._mat-animation-noopable]': '_animationMode === "NoopAnimations"',
            '[class.mat-button-disabled]': 'disabled',
            class: 'mat-focus-indicator'
        },
        inputs: ['disabled', 'disableRipple', 'color'],
        template: require('./button.component.html').default,
        encapsulation: ViewEncapsulation.None,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(2, Optional()),
    __param(2, Inject(ANIMATION_MODULE_TYPE)),
    __metadata("design:paramtypes", [typeof (_a = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _a : Object, typeof (_b = typeof FocusMonitor !== "undefined" && FocusMonitor) === "function" ? _b : Object, String])
], MpxButtonComponent);
export { MpxButtonComponent };
