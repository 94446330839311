var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslationService } from '@app/services/translation/translation.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { IRole, EPermissionRights, EProjects } from '@mapuilabs/mpl-interfaces';
import { RolesService } from '@services/role/roles.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import * as _ from 'lodash';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
const columnsBase = ['select', 'name', EPermissionRights.SEE_MENU_ITEM];
const columnsCrudRights = [
    EPermissionRights.READ,
    EPermissionRights.CREATE,
    EPermissionRights.EDIT,
    EPermissionRights.DELETE
];
const projectColumnsMap = new Map([
    [EProjects.ADMIN_PANEL, [...columnsBase, ...columnsCrudRights]],
    [EProjects.HOSPISTOCK, [...columnsBase, ...columnsCrudRights]],
    [EProjects.HOSPIVILLE, [...columnsBase, ...columnsCrudRights, EPermissionRights.VALIDATE]],
    [
        EProjects.MAPUI,
        [
            ...columnsBase,
            ...columnsCrudRights,
            EPermissionRights.CREATE_BORROWING,
            EPermissionRights.CREATE_LOAN,
            EPermissionRights.RETAKE
        ]
    ],
    [EProjects.WORKSPACE, [...columnsBase, ...columnsCrudRights, EPermissionRights.SHARE]]
]);
const permissionNameRegExp = new RegExp('[^|]+(?=-)');
const permissionTypeRegExp = new RegExp('[^-]*$');
let RoleDetailComponent = class RoleDetailComponent {
    constructor(_fuseTranslationLoaderService, _rolesService, _translationService, _snackBarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._rolesService = _rolesService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._translationBasePath = ['ROLES'];
        this.form = new UntypedFormGroup({});
        this.masterCheckboxStatus = 0;
        this.permissionsCheckboxStatus = {};
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init page header config
        this.pageHeaderConfig = { title: '' };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        const isProjects = Utils.constructEnumPredicate(EProjects);
        // Guard: check role's project
        if (!isProjects(this.role.project)) {
            const message = `Unknown project ${this.role.project}. The role's must be part of the following projects: `;
            throw new Error(message + Utils.enumToKeys(EProjects));
        }
        // Update translation path from role
        this._translationBasePath.push(Utils.toSnakeCase(this.role.project).toUpperCase());
        // Find and parse prokect's permissions enum
        this.permissions = this._parsePermissions(this.role.project);
        // Setup form
        this._setPermissionsFormControl();
        // Update master & permission checkboxes
        this._updateMasterAndPermissionsChecbox();
        // Update table and page header config from role
        this.tableColumns = projectColumnsMap.get(this.role.project);
        this.pageHeaderConfig = Object.assign({}, this.pageHeaderConfig, { title: this.role.name });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translateRoleName(name) {
        const nameSnakeCase = Utils.toSnakeCase(name).toUpperCase();
        return this._translationService.instant([...this._translationBasePath, nameSnakeCase].join('.'));
    }
    _parsePermissions(project) {
        const permissions = [];
        const projectPermissions = this._rolesService.getPermissionFromProject(project);
        _.chain(projectPermissions)
            .groupBy((value) => permissionNameRegExp.exec(value))
            .forEach((value, key) => permissions.push({
            name: key,
            rights: value.map((item) => (permissionTypeRegExp.exec(item) || []).pop()),
            translation: this._translateRoleName(key)
        }))
            .value();
        return permissions.sort((a, b) => a.translation.localeCompare(b.translation));
    }
    _setPermissionsFormControl() {
        Object.values(this.permissions).forEach((permission) => {
            permission.rights.forEach((type) => {
                const name = [permission.name, type].join('-');
                this.form.setControl(name, new UntypedFormControl(this.role.permissions.includes(name)));
            });
        });
    }
    _getCheckboxStatus(nbChecked, size) {
        if (nbChecked === size) {
            return 2;
        }
        else if (nbChecked > 0) {
            return 1;
        }
        return 0;
    }
    _getNumberRightsChecked(permission) {
        const checkedPermissionRights = permission.rights.filter((right) => this.form.get(permission.name + '-' + right).value);
        return checkedPermissionRights.length;
    }
    _updatePermissionCheckbox(permission) {
        const nbChecked = this._getNumberRightsChecked(permission);
        const size = permission.rights.length;
        const status = this._getCheckboxStatus(nbChecked, size);
        this.permissionsCheckboxStatus[permission.name] = status;
    }
    _updateMasterCheckbox() {
        const nbChecked = Object.values(this.permissionsCheckboxStatus).filter((status) => status === 2).length;
        const size = Object.keys(this.permissionsCheckboxStatus).length;
        const status = this._getCheckboxStatus(nbChecked, size);
        this.masterCheckboxStatus = status;
    }
    _updateMasterAndPermissionsChecbox() {
        this.permissions.forEach((permission) => {
            // Update permission checkbox
            this._updatePermissionCheckbox(permission);
        });
        // Update master checkbox
        this._updateMasterCheckbox();
    }
    _togglePermissionSelection(permission, value) {
        permission.rights.forEach((right) => {
            this.form.controls[permission.name + '-' + right].setValue(value);
        });
    }
    _toggleMasterSelection(value) {
        this.permissions.forEach((permission) => {
            // Toggle permission selection checkbox
            this._togglePermissionSelection(permission, value);
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onRightSelectionChanged(permission) {
        // Update master & permission checkboxes
        this._updatePermissionCheckbox(permission);
        this._updateMasterCheckbox();
    }
    onPermissionSelectionChanged(permission, value) {
        // Toggle permission selection checkbox
        this._togglePermissionSelection(permission, value);
        // Update master & permission checkboxes
        this._updatePermissionCheckbox(permission);
        this._updateMasterCheckbox();
        // Manually mark form as dirty
        this.form.markAsDirty();
    }
    onMasterSelectionChanged(value) {
        // Toggle master selection checkbox
        this._toggleMasterSelection(value);
        // Update master & permission checkboxes
        this._updateMasterAndPermissionsChecbox();
        // Manually mark form as dirty
        this.form.markAsDirty();
    }
    savePermissions() {
        // Get permissions from form
        const permissions = Object.entries(this.form.value)
            .filter(([key, value]) => value)
            .map(([key]) => key);
        // Update role's permissions
        Object.assign(this.role, { permissions });
        // Save
        this._rolesService.save(this.role).subscribe({
            next: () => this._snackBarService.open(this._translationService.instant('ROLES.SAVE_SUCCESS')),
            error: (err) => this._snackBarService.openError(err)
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof IRole !== "undefined" && IRole) === "function" ? _e : Object)
], RoleDetailComponent.prototype, "role", void 0);
RoleDetailComponent = __decorate([
    Component({
        selector: 'mpx-role-detail',
        template: require('./role-detail.component.html').default,
        styles: [require('./role-detail.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof RolesService !== "undefined" && RolesService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object])
], RoleDetailComponent);
export { RoleDetailComponent };
