export const locale = {
    lang: 'en',
    data: {
        sidebar: {
            TITLE: 'Filters',
            filters: {
                TYPES: 'Types',
                ROLES: 'Roles',
                ACTIVE: 'Active',
                REGISTER: 'Registered'
            }
        }
    }
};
