export class HvModalDrugBalanceAddPastMedRecComponent {
    constructor() {
        this.controller = 'HvModalDrugBalanceAddPastMedRecController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-drug-balance-add-past-medrec.html').default;
        this.bindings = {
            medReconciliation: '<',
        };
    }
}
