var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EVoucherType } from '@mapuilabs/mpl-interfaces';
import { locale as english } from '@shared/components/widgets/home/loan-or-borrow-widget/i18n/en';
import { locale as french } from '@shared/components/widgets/home/loan-or-borrow-widget/i18n/fr';
import { locale as serviceEnglish } from '../../../../../exchange/request/i18n/en';
import { locale as serviceFrench } from '../../../../../exchange/request/i18n/fr';
import { DashboardService } from '@main/services/dashboard/dashboard.service';
import { ModalService } from '@main/services/modal/modal.service';
import { Utils } from '@main/services/Utils';
import { IdentityService } from '@services/identity/identity.service';
import { LoanService } from '@services/loan/loan.service';
let LoanOrBorrowWidgetComponent = class LoanOrBorrowWidgetComponent {
    constructor(_fuseTranslationLoaderService, _translateService, _loanService, _identityService, _modalService, _dashboardService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._loanService = _loanService;
        this._identityService = _identityService;
        this._modalService = _modalService;
        this._dashboardService = _dashboardService;
        this._fuseTranslationLoaderService.loadTranslations(english, french, serviceEnglish, serviceFrench);
        this.translate = this._translateService.instant('LOAN_BORROW_WIDGET');
    }
    ngOnInit() {
        this.getLastLoans();
    }
    getLastLoans() {
        this._loanService.getLastLoan(Utils.getId(this._identityService.hospital), 10).subscribe((loan) => {
            this.loans = loan;
            for (const item of this.loans) {
                if (Utils.compareIds(this === null || this === void 0 ? void 0 : this._identityService.hospital, item.borrower)) {
                    item.type = this.translate.BORROW;
                }
                else if (Utils.compareIds(this === null || this === void 0 ? void 0 : this._identityService.hospital, item.lender)) {
                    item.type = this.translate.LOAN;
                }
                else {
                    item.type = '';
                }
            }
        }, (error) => {
            if (error.status == 403) {
                this.forbiddenAccess = true;
            }
        });
    }
    isLenderFromLoanOrBorrow(loan) {
        return this._loanService.isLenderFromLoanOrBorrow(loan);
    }
    isBorrowerFromLoanOrBorrow(loan) {
        return this._loanService.isBorrowerFromLoanOrBorrow(loan);
    }
    getStatusIndicator(loan, key) {
        return this._loanService.getStatusIndicator(loan)[key];
    }
    isPrep(loan) {
        return this._loanService.isPrep(loan);
    }
    isDM(loan) {
        return this._loanService.isDM(loan);
    }
    isMed(loan) {
        return this._loanService.isMed(loan);
    }
    generateVoucher(loan, type) {
        let voucher;
        switch (type) {
            case 'loan':
                voucher = EVoucherType.Loan;
                break;
            case 'borrow':
                voucher = EVoucherType.Borrow;
                break;
            case 'return':
                voucher = EVoucherType.Return;
                break;
            default:
                break;
        }
        this._modalService.show({
            component: 'mp-modal-voucher',
            bindings: {
                loan: loan,
                voucherType: voucher
            },
            escapeToClose: false
        });
    }
    openUpdateModal(event, loan) {
        this._dashboardService.openLoanUpdateModal(event, loan).then(() => {
            this.getLastLoans();
        });
    }
    openAddModal(event) {
        this._dashboardService.openLoanCreationModal(event).then(() => {
            this.getLastLoans();
        });
    }
};
LoanOrBorrowWidgetComponent = __decorate([
    Component({
        selector: 'mpx-loan-or-borrow-widget',
        template: require('./loan-or-borrow-widget.component.html').default,
        styles: [require('./loan-or-borrow-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _b : Object, typeof (_c = typeof LoanService !== "undefined" && LoanService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof ModalService !== "undefined" && ModalService) === "function" ? _e : Object, typeof (_f = typeof DashboardService !== "undefined" && DashboardService) === "function" ? _f : Object])
], LoanOrBorrowWidgetComponent);
export { LoanOrBorrowWidgetComponent };
