export const locale = {
    lang: 'en',
    data: {
        SOURCE_INST_LIST: {
            LIST: 'Drug list',
            FINISH_BTN: `Finish`,
            TABLE: {
                NAME: 'Name',
                DOSAGE: `Dosage`,
                POSOLOGY: `Posology`
            },
            FINISH_CONFIRM: {
                TITLE: `Validate prescription`,
                MESSAGE: 'By validating you will en the prescription edition process. The activity status will change and you will not be able to edit the prescription anymore. Continue ?'
            },
            DELETED: 'Element deleted',
            INFO: {
                ADDED_HOSPITAL: 'Added by hospital',
                MODIFIED_HOSPITAL: 'Modified by hospital'
            }
        }
    }
};
