import { EHsPublicationTag } from '@mapuilabs/mpl-interfaces';
export var EHsAdvancedFilterSettings;
(function (EHsAdvancedFilterSettings) {
    EHsAdvancedFilterSettings["FILTER"] = "FILTER";
    EHsAdvancedFilterSettings["HIDE"] = "HIDE";
})(EHsAdvancedFilterSettings || (EHsAdvancedFilterSettings = {}));
// -----------------------------------------------------------------------------------------------------
// @ Const Types
// -----------------------------------------------------------------------------------------------------
// Define hospistock tags
const hsTags = {
    [EHsPublicationTag.ALTERNATIVE]: {
        bulletStyles: 'brown-bg',
        rank: 5,
        translate: 'PHARMACY.PUBLICATION.TAGS.ALTERNATIVE'
    },
    [EHsPublicationTag.ARCHIVED]: {
        bulletStyles: 'purple-bg',
        rank: 1,
        translate: 'PHARMACY.PUBLICATION.TAGS.ARCHIVED'
    },
    [EHsPublicationTag.GA]: {
        bulletStyles: 'teal-bg',
        rank: 3,
        translate: 'PHARMACY.PUBLICATION.TAGS.MARKET'
    },
    [EHsPublicationTag.PROCESSED]: {
        bulletStyles: 'light-green-bg',
        rank: 2,
        translate: 'PHARMACY.PUBLICATION.TAGS.PROCESSED'
    },
    [EHsPublicationTag.PUI]: {
        bulletStyles: 'blue-bg',
        rank: 4,
        translate: 'PHARMACY.PUBLICATION.TAGS.BOOKLET'
    }
};
// Define hospistock tag filter settings
const hsAdvancedFilterSettings = {
    [EHsAdvancedFilterSettings.FILTER]: {
        value: true,
        translate: 'PHARMACY.PUBLICATION.TAGS.SETTINGS.FILTER'
    },
    [EHsAdvancedFilterSettings.HIDE]: {
        value: false,
        translate: 'PHARMACY.PUBLICATION.TAGS.SETTINGS.HIDE'
    }
};
// Common
export const CHsStaticTags = {
    [EHsPublicationTag.ARCHIVED]: hsTags[EHsPublicationTag.ARCHIVED]
};
export const CHsDynamicTags = {
    [EHsPublicationTag.ALTERNATIVE]: hsTags[EHsPublicationTag.ALTERNATIVE]
};
export const CHsAdvancedFilterSettings = {
    [EHsAdvancedFilterSettings.FILTER]: hsAdvancedFilterSettings[EHsAdvancedFilterSettings.FILTER],
    [EHsAdvancedFilterSettings.HIDE]: hsAdvancedFilterSettings[EHsAdvancedFilterSettings.HIDE]
};
// Pharmacy
export const CHsStaticTagsPharmacy = {
    [EHsPublicationTag.PUI]: hsTags[EHsPublicationTag.PUI],
    [EHsPublicationTag.PROCESSED]: hsTags[EHsPublicationTag.PROCESSED]
};
export const CHsDynamicTagsPharmacy = {
    [EHsPublicationTag.GA]: hsTags[EHsPublicationTag.GA]
};
// Group
export const CHsStaticTagsGroup = {
    [EHsPublicationTag.GA]: hsTags[EHsPublicationTag.GA],
    [EHsPublicationTag.PROCESSED]: hsTags[EHsPublicationTag.PROCESSED]
};
export const CHsDynamicTagsGroup = {};
// Labo
export const CHsStaticTagsLabo = {};
export const CHsDynamicTagsLabo = {};
// Admin
export const CHsStaticTagsAdmin = {};
export const CHsDynamicTagsAdmin = {};
// -----------------------------------------------------------------------------------------------------
// @ Fonctions
// -----------------------------------------------------------------------------------------------------
export function getTagFromEnum(tag) {
    return hsTags[tag];
}
export function getInfoFromTag(label) {
    const tagInfos = getTagFromEnum(label);
    return Object.assign({ label }, tagInfos);
}
