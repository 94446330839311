import { Utils } from '@main/services/Utils';
export var ESpreadsheetImportReportListTypes;
(function (ESpreadsheetImportReportListTypes) {
    ESpreadsheetImportReportListTypes["SUCCESS"] = "SUCCESS";
    ESpreadsheetImportReportListTypes["INFO"] = "INFO";
    ESpreadsheetImportReportListTypes["WARNING"] = "WARNING";
    ESpreadsheetImportReportListTypes["ERROR"] = "ERROR";
})(ESpreadsheetImportReportListTypes || (ESpreadsheetImportReportListTypes = {}));
export class SpreadsheetImportReportList {
    constructor(map, limit) {
        this._defaultLimit = 10;
        this._limitOffset = 10;
        this._defaultMap = new Map([
            [ESpreadsheetImportReportListTypes.SUCCESS, { config: null, items: [] }],
            [ESpreadsheetImportReportListTypes.INFO, { config: null, items: [] }],
            [ESpreadsheetImportReportListTypes.WARNING, { config: null, items: [] }],
            [ESpreadsheetImportReportListTypes.ERROR, { config: null, items: [] }]
        ]);
        // Init map
        this.map = this._setupMap(map, this._defaultMap);
        // Init limit
        this.limit = limit || this._defaultLimit;
        this._reportListTypeToLimit = new Map([
            [ESpreadsheetImportReportListTypes.SUCCESS.toString(), this._defaultLimit],
            [ESpreadsheetImportReportListTypes.INFO.toString(), this._defaultLimit],
            [ESpreadsheetImportReportListTypes.WARNING.toString(), this._defaultLimit],
            [ESpreadsheetImportReportListTypes.ERROR.toString(), this._defaultLimit]
        ]);
    }
    get hasSomethingToDisplay() {
        return [...this.map.values()].some((element) => { var _a; return !((_a = element.config) === null || _a === void 0 ? void 0 : _a.hide) && element.items.length > 0; });
    }
    // Guard: ensure item's type is in ESpreadsheetImportReportListTypes before using map
    _typeGuard(type) {
        if (!(type in ESpreadsheetImportReportListTypes)) {
            console.warn(`Cannot handle type ${type}, because it does not match a ESpreadsheetImportReportListTypes.`);
            return;
        }
    }
    _setupMap(map, defaultMap) {
        const resultMap = new Map();
        Utils.enumToValues(ESpreadsheetImportReportListTypes).forEach((type) => {
            this._typeGuard(type);
            const element = Object.assign({}, defaultMap.get(type), map.get(type));
            resultMap.set(type, element);
        });
        return resultMap;
    }
    increaseTypeList(type) {
        this._typeGuard(type);
        this._reportListTypeToLimit.set(type, this._reportListTypeToLimit.get(type) + this._limitOffset);
    }
    getTypeLimit(type) {
        this._typeGuard(type);
        return this._reportListTypeToLimit.get(type);
    }
}
