var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CrudTemplateService } from '@app/shared/templates/crud/crud.template';
import { EHTTPStatus, HsDrugRef, HsMedicalDeviceRef } from '@mapuilabs/mpl-interfaces';
import { UIRouter } from '@uirouter/angularjs';
import * as _ from 'lodash';
import { BehaviorSubject, take, tap, throwError } from 'rxjs';
const HEALTH_PRODUCT_INSTANCE_ERROR = new Error('Unknown HsHealthProduct instance');
let HsHealthProductDirectoryService = class HsHealthProductDirectoryService extends CrudTemplateService {
    constructor(_httpClient, _route, _injector) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        super(_injector, (_a = _route.globals.current.data.servicesConfig) === null || _a === void 0 ? void 0 : _a.healthProductDirectory.classConstructor, (_b = _route.globals.current.data.servicesConfig) === null || _b === void 0 ? void 0 : _b.healthProductDirectory.endPoint, (_c = _route.globals.current.data.servicesConfig) === null || _c === void 0 ? void 0 : _c.healthProductDirectory.permissions);
        this._httpClient = _httpClient;
        this._route = _route;
        this._injector = _injector;
        this.readonly = false;
        // Config
        const currentState = this._route.globals.current;
        this._serviceConfig = currentState.data.servicesConfig.healthProductDirectory;
        this._permissions = (_d = this._serviceConfig) === null || _d === void 0 ? void 0 : _d.permissions;
        // Handle readonly permission
        this.readonly = !this._permissionService.authorizeAny([
            (_e = this._permissions) === null || _e === void 0 ? void 0 : _e.create.toString(),
            (_f = this._permissions) === null || _f === void 0 ? void 0 : _f.update.toString(),
            (_g = this._permissions) === null || _g === void 0 ? void 0 : _g.delete.toString()
        ]);
        // Init Subjects & BehaviorSubjects
        this.latestHealthProductDirectory$ = new BehaviorSubject(null);
        // Read latest import date
        if ((_h = this._serviceConfig) === null || _h === void 0 ? void 0 : _h.readLatestImportDate) {
            this._updateLatestHealthProductDirectory();
        }
    }
    _updateLatestHealthProductDirectory() {
        this._readLatestHealthProductDirectory()
            .pipe(take(1))
            .subscribe({ next: (data) => this.latestHealthProductDirectory$.next(data) });
    }
    _readLatestHealthProductDirectory() {
        return this._httpClient.get(`${this._endPoint}/latest`);
    }
    handleByInstance(drugHandler, medicalDeviceHandler, healthProduct) {
        if (healthProduct instanceof HsDrugRef) {
            return drugHandler(healthProduct);
        }
        else if (healthProduct instanceof HsMedicalDeviceRef) {
            return medicalDeviceHandler(healthProduct);
        }
        else {
            throw HEALTH_PRODUCT_INSTANCE_ERROR;
        }
    }
    unhandledHsHealthProductType() {
        throw new Error('Unknown or undefined HsHealthProductType.');
    }
    buildHealthProductDirectory(jsonSpreadsheet, keysToLowerCase) {
        if (!this._serviceConfig.spreadsheetClassBuilder) {
            throw new Error('Cannot build health product directory, spreadsheetClassBuilder not provided.');
        }
        return jsonSpreadsheet.map((obj) => {
            const healthProductReferencesItem = new this._serviceConfig.classConstructor({});
            // Build object based on spreadsheetConfig keys
            const reducer = (accumulator, currentValue) => ({ [currentValue]: accumulator });
            this._serviceConfig.spreadsheetClassBuilder.forEach((item) => {
                const getFromValue = () => item.value;
                const getFromColumn = () => obj[keysToLowerCase ? item.column.toLowerCase() : item.column];
                const applyTransform = (value) => ('transform' in item ? item.transform(value) : value);
                const transformedValue = applyTransform('value' in item ? getFromValue() : getFromColumn());
                const initialValue = item.path.reduceRight(reducer, transformedValue);
                _.merge(healthProductReferencesItem, initialValue);
            });
            return { data: healthProductReferencesItem, metadata: { row: obj.rowNum + 1 } };
        });
    }
    importFromSpreadsheet(spreadsheetImportContainers) {
        const endpoint = `${this._endPoint}/import-spreadsheet`;
        // Guard: permissions
        if (!this._permissionService.authorize(this._permissions.create)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        // Guard: undefined
        if (!spreadsheetImportContainers) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        return this._httpClient
            .post(endpoint, spreadsheetImportContainers)
            .pipe(tap(() => this._updateLatestHealthProductDirectory()));
    }
    itemExistsForUserFromHealthProductsRef(healthProductRef, id) {
        let params = new HttpParams();
        params = params.set('healthProductRef', JSON.stringify(healthProductRef));
        if (id) {
            params = params.set('id', id);
        }
        return this._httpClient.get(`${this._endPoint}/item-exists`, { params });
    }
};
HsHealthProductDirectoryService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object, typeof (_b = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _b : Object, typeof (_c = typeof Injector !== "undefined" && Injector) === "function" ? _c : Object])
], HsHealthProductDirectoryService);
export { HsHealthProductDirectoryService };
