var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable, Injector } from '@angular/core';
import { EHTTPStatus, EMaPUIPermission, ReleaseNoteASchema } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
let ReleaseNotesService = class ReleaseNotesService extends CrudTemplateService {
    constructor(__injector) {
        super(__injector, ReleaseNoteASchema, '/api/admin/homepage/widgets/release-note', {
            create: EMaPUIPermission.WidgetHome_Create,
            read: EMaPUIPermission.WidgetHome_Read,
            update: EMaPUIPermission.WidgetHome_Edit,
            delete: EMaPUIPermission.WidgetHome_Delete
        });
        this.__injector = __injector;
    }
    getReleaseNoteWithLinesById(id, paginate = true) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const params = new HttpParams().set('paginate', paginate.toString());
        const fullEndPoint = `${this._endPoint}/${id}`;
        return this._http.get(fullEndPoint, { params: params });
    }
    postReleaseNoteWithLines(releaseNoteAndLines) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!releaseNoteAndLines) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        return this._http.post(this._endPoint, releaseNoteAndLines);
    }
    putReleaseNoteWithLines(releaseNoteAndLines) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!releaseNoteAndLines) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${releaseNoteAndLines.widget._id}`;
        return this._http.put(fullEndPoint, releaseNoteAndLines);
    }
    /**
     * Get the next ReleaseNoteLines.
     * @param wid: Id of the parent ReleaseNote.
     * @param pageOptions: Page and limit to get the next ReleaseNoteLines.
     */
    getNextReleaseNoteLines(wid, pageOptions) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!(pageOptions === null || pageOptions === void 0 ? void 0 : pageOptions.page) || !wid) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const params = new HttpParams().set('pageOptions', JSON.stringify(pageOptions));
        const fullEndPoint = `${this._endPoint}/${wid}/release-note-line`;
        return this._http.get(fullEndPoint, { params: params });
    }
    /**
     * Add one ReleaseNoteLine.
     * @param wid: Id of the parent ReleaseNote.
     * @param releaseNoteLine: ReleaseNoteLine to add.
     */
    postReleaseNoteLine(wid, releaseNoteLine) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!releaseNoteLine || !wid) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${wid}/release-note-line`;
        return this._http.post(fullEndPoint, releaseNoteLine);
    }
    /**
     * Update one ReleaseNoteLine.
     * @param wid: Id of the parent ReleaseNote.
     * @param releaseNoteLine: ReleaseNoteLine to update.
     */
    putReleaseNoteLine(wid, releaseNoteLine) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!(releaseNoteLine === null || releaseNoteLine === void 0 ? void 0 : releaseNoteLine._id) || !wid) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${wid}/release-note-line/${releaseNoteLine._id}`;
        return this._http.put(fullEndPoint, releaseNoteLine);
    }
    /**
     * Delete one ReleaseNoteLine.
     * @param wid: Id of the parent ReleaseNote.
     * @param id: Id of the ReleaseNoteLine to delete.
     */
    deleteReleaseNoteLine(wid, id) {
        if (!this._permissionService.authorize(this._permissions.read)) {
            return throwError(() => EHTTPStatus.Unauthorized);
        }
        if (!wid || !id) {
            return throwError(() => EHTTPStatus.BadRequest);
        }
        const fullEndPoint = `${this._endPoint}/${wid}/release-note-line/${id}`;
        return this._http.delete(fullEndPoint, { responseType: 'text' });
    }
};
ReleaseNotesService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object])
], ReleaseNotesService);
export { ReleaseNotesService };
