var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component } from '@angular/core';
import { MemberService } from '../../../../services/member/member.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
let TwoFaModalComponent = class TwoFaModalComponent {
    constructor(_memberService, _dialogRef) {
        this._memberService = _memberService;
        this._dialogRef = _dialogRef;
        this.isEnterValid = false;
        this.isTokenValid = true;
        this.token = new UntypedFormControl('', [Validators.required, this.isFormTokenValid()]);
        this.key = undefined;
        this._memberService.generateTwoFAuthKey().subscribe((res) => {
            this.key = res;
        });
    }
    isFormTokenValid() {
        return () => {
            if (!this.isTokenValid) {
                return { isTokenValid: {} };
            }
            return null;
        };
    }
    validToken() {
        this.isEnterValid = true;
        this._memberService.checkTempTwoFAuthToken(this.token.value).subscribe(() => {
            this._dialogRef.close();
        }, () => {
            this.isTokenValid = false;
            this.isEnterValid = false;
            this.token.setErrors({
                isTokenValid: true,
            });
        });
    }
};
TwoFaModalComponent = __decorate([
    Component({
        selector: 'mpx-two-fa-modal',
        template: require('./two-fa-modal.component.html').default,
        styles: [require('./two-fa-modal.component.scss')],
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MemberService !== "undefined" && MemberService) === "function" ? _a : Object, typeof (_b = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _b : Object])
], TwoFaModalComponent);
export { TwoFaModalComponent };
