var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { NgModule } from '@angular/core';
const states = [
    {
        name: 'mapui.adm.homepage.release-notes',
        url: '/release-notes',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.homepage.custom-widgets',
        url: '/custom-widgets',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    }
];
let HomepageRoutingModule = class HomepageRoutingModule {
};
HomepageRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], HomepageRoutingModule);
export { HomepageRoutingModule };
