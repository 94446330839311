var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ReleaseNoteDetailsComponent_1;
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHTTPStatus, EProject, IReleaseNoteAndLines, ReleaseNoteASchema } from '@mapuilabs/mpl-interfaces';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { Utils } from '@main/services/Utils';
import { DialogService } from '@services/dialog/dialog.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { ReleaseNotesService } from '@services/homepage/widgets/release-notes/release-notes.service';
import { WidgetInjection } from '../../../../shared/components/widgets/dashboard/widgets';
let ReleaseNoteDetailsComponent = ReleaseNoteDetailsComponent_1 = class ReleaseNoteDetailsComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _dialogService, _snackbarService, _releaseNotesService, fuseNavigationService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._snackbarService = _snackbarService;
        this._releaseNotesService = _releaseNotesService;
        this.fuseNavigationService = fuseNavigationService;
        this.quillModules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }],
                [{ indent: '-1' }, { indent: '+1' }],
                ['image']
            ]
        };
        this.EProjects = EProject;
        this._unsubscribe = new Subject();
        this.isEditPage = false;
        this.isUpdatingWidget = false;
        this.isAddingNewLine = false;
        this.linesList = [];
        this.dataSource = new MatTableDataSource();
        this.linesTableColumns = ['name', 'publicationDate', 'expandIcon'];
        this.isAddingTag = false;
        this.buttonsList = [];
        this.buttonsListColumns = ['title', 'state', 'color', 'actions'];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        var _a;
        this.isEditPage = !!this.fullWidget;
        if (!this.fullWidget) {
            this.fullWidget = {
                widget: new ReleaseNoteASchema(),
                lines: {
                    items: [],
                    hasNextPage: false,
                    nextPage: null
                }
            };
        }
        this.linesList = this.lines || [];
        this.buttonsList = ((_a = this.widget.buttons) === null || _a === void 0 ? void 0 : _a.list) || [];
        this.createWidgetFormGroup();
        this.createButtonFormGroup();
        this.pageHeaderConfig = {
            title: [
                this._translate('RELEASE_NOTES.TITLE'),
                this.isEditPage
                    ? this.widget.title.message || this._translate('COMMONS.EDIT')
                    : this._translate('COMMONS.NEW')
            ].join(' - '),
            icon: 'event_note'
        };
    }
    ngAfterViewInit() {
        this._refreshDataSource();
        this.dataSource.paginator = this.paginator;
    }
    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    /**
     * Shortcuts to get widget and lines.
     */
    get widget() {
        return this.fullWidget.widget;
    }
    get lines() {
        return this.fullWidget.lines.items;
    }
    /**
     * Create form from a list of controls.
     * @private
     */
    static _createFormGroup(form, controls) {
        var _a;
        if (!form) {
            return;
        }
        for (const control of controls) {
            if (!(control === null || control === void 0 ? void 0 : control.name)) {
                return;
            }
            form.setControl(control.name, new UntypedFormControl({ value: control.defaultValue, disabled: (_a = control.disabled) !== null && _a !== void 0 ? _a : false }, control.validators));
        }
    }
    /**
     * Create form for widget creation and its getters.
     * @private
     */
    createWidgetFormGroup() {
        var _a, _b, _c, _d, _e, _f, _g;
        this.formWidget = new UntypedFormGroup({});
        ReleaseNoteDetailsComponent_1._createFormGroup(this.formWidget, [
            {
                name: 'title',
                defaultValue: (_b = (_a = this.widget) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.message,
                validators: Validators.required
            },
            {
                name: 'titleColor',
                defaultValue: (_d = (_c = this.widget) === null || _c === void 0 ? void 0 : _c.title) === null || _d === void 0 ? void 0 : _d.color
            },
            {
                name: 'background',
                defaultValue: (_e = this.widget) === null || _e === void 0 ? void 0 : _e.background
            },
            {
                name: 'oneColumnXPosition',
                defaultValue: this.getXPositionForDisplay(1),
                disabled: true,
                validators: [Validators.min(0), Validators.max(1)]
            },
            {
                name: 'oneColumnYPosition',
                defaultValue: this.getYPositionForDisplay(1),
                validators: Validators.min(1)
            },
            {
                name: 'twoColumnsXPosition',
                defaultValue: this.getXPositionForDisplay(2),
                disabled: this.getXPositionForDisplay(2) === 0,
                validators: [Validators.min(0), Validators.max(2)]
            },
            {
                name: 'twoColumnsYPosition',
                defaultValue: this.getYPositionForDisplay(2),
                validators: Validators.min(1)
            },
            {
                name: 'threeColumnsXPosition',
                defaultValue: this.getXPositionForDisplay(3),
                disabled: this.getXPositionForDisplay(3) === 0,
                validators: [Validators.min(0), Validators.max(3)]
            },
            {
                name: 'threeColumnsYPosition',
                defaultValue: this.getYPositionForDisplay(3),
                validators: Validators.min(1)
            },
            {
                name: 'buttonsLayout',
                defaultValue: (_g = (_f = this.widget) === null || _f === void 0 ? void 0 : _f.buttons) === null || _g === void 0 ? void 0 : _g.layout
            }
        ]);
    }
    get titleControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('title');
    }
    get titleColorControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('titleColor');
    }
    get backgroundControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('background');
    }
    get oneColumnXPositionControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('oneColumnXPosition');
    }
    get oneColumnYPositionControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('oneColumnYPosition');
    }
    get twoColumnsXPositionControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('twoColumnsXPosition');
    }
    get twoColumnsYPositionControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('twoColumnsYPosition');
    }
    get threeColumnsXPositionControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('threeColumnsXPosition');
    }
    get threeColumnsYPositionControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('threeColumnsYPosition');
    }
    get btnsLayoutControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('buttonsLayout');
    }
    /**
     * Get the widget's column number for a given display.
     * @param columns
     */
    getXPositionForDisplay(columns) {
        var _a;
        return +Object.keys((_a = this.widget) === null || _a === void 0 ? void 0 : _a.positions[columns - 1])[0] + 1;
    }
    /**
     * Get the widget's order in column for a given display.
     * @param columns
     */
    getYPositionForDisplay(columns) {
        var _a;
        return +Object.values((_a = this.widget) === null || _a === void 0 ? void 0 : _a.positions[columns - 1])[0] + 1;
    }
    /**
     * Use slide toggles to tell if widget should appear on home page.
     * @param event
     * @param columnNb
     */
    toggleDoNotShow(event, columnNb) {
        switch (columnNb) {
            case 1:
                if (event.checked) {
                    this.oneColumnXPositionControl.setValue(0);
                    this.oneColumnYPositionControl.disable();
                }
                else {
                    this.oneColumnXPositionControl.setValue(1);
                    this.oneColumnYPositionControl.enable();
                }
                this.formWidget.markAsDirty();
                break;
            case 2:
                if (event.checked) {
                    this.twoColumnsXPositionControl.setValue(0);
                    this.twoColumnsXPositionControl.disable();
                    this.twoColumnsYPositionControl.disable();
                }
                else {
                    this.twoColumnsXPositionControl.setValue(this.getXPositionForDisplay(2) || 1);
                    this.twoColumnsXPositionControl.enable();
                    this.twoColumnsYPositionControl.enable();
                }
                this.formWidget.markAsDirty();
                break;
            case 3:
                if (event.checked) {
                    this.threeColumnsXPositionControl.setValue(0);
                    this.threeColumnsXPositionControl.disable();
                    this.threeColumnsYPositionControl.disable();
                }
                else {
                    this.threeColumnsXPositionControl.setValue(this.getXPositionForDisplay(3) || 1);
                    this.threeColumnsXPositionControl.enable();
                    this.threeColumnsYPositionControl.enable();
                }
                this.formWidget.markAsDirty();
                break;
            default:
                console.log('Unknown column number.');
                break;
        }
    }
    /**
     * Create form for line creation and its getters.
     * @private
     */
    createLineFormGroup(line) {
        var _a;
        this.formLine = new UntypedFormGroup({});
        // Update preview when there are changes on a line
        this.formLine.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
            if (line) {
                this.updateLineListFromForm(line);
            }
        });
        ReleaseNoteDetailsComponent_1._createFormGroup(this.formLine, [
            {
                name: 'name',
                defaultValue: line === null || line === void 0 ? void 0 : line.name,
                validators: Validators.required
            },
            {
                name: 'project',
                defaultValue: line === null || line === void 0 ? void 0 : line.project,
                validators: Validators.required
            },
            {
                name: 'publicationDate',
                defaultValue: line === null || line === void 0 ? void 0 : line.publicationDate,
                validators: Validators.required
            },
            {
                name: 'content',
                defaultValue: (_a = line === null || line === void 0 ? void 0 : line.content) === null || _a === void 0 ? void 0 : _a.message
            },
            {
                name: 'knowledgeBaseArticle',
                defaultValue: line === null || line === void 0 ? void 0 : line.knowledgeBaseArticle
            }
        ]);
    }
    get lineNameControl() {
        return this.formLine.get('name');
    }
    get lineProjectControl() {
        return this.formLine.get('project');
    }
    get linePubDateControl() {
        return this.formLine.get('publicationDate');
    }
    get lineContentControl() {
        return this.formLine.get('content');
    }
    get lineKBArticleControl() {
        return this.formLine.get('knowledgeBaseArticle');
    }
    isNew(line) {
        return line._id === 'new';
    }
    /**
     * Create form for tag creation and its getters.
     * @private
     */
    createTagFormGroup() {
        this.formTag = new UntypedFormGroup({});
        ReleaseNoteDetailsComponent_1._createFormGroup(this.formTag, [
            {
                name: 'tagName',
                validators: Validators.required
            },
            {
                name: 'tagLink',
                defaultValue: null,
                validators: Validators.required
            }
        ]);
    }
    get tagNameControl() {
        var _a;
        return (_a = this.formTag) === null || _a === void 0 ? void 0 : _a.get('tagName');
    }
    get tagLinkControl() {
        var _a;
        return (_a = this.formTag) === null || _a === void 0 ? void 0 : _a.get('tagLink');
    }
    /**
     * Create form for button creation and its getters.
     * @private
     */
    createButtonFormGroup() {
        this.formButtons = new UntypedFormGroup({});
        ReleaseNoteDetailsComponent_1._createFormGroup(this.formButtons, [
            {
                name: 'title',
                defaultValue: '',
                validators: Validators.required
            },
            {
                name: 'titleColor',
                defaultValue: ''
            },
            {
                name: 'state',
                validators: this.doesGetState(true)
            },
            {
                name: 'url',
                defaultValue: '',
                validators: this.doesGetState(false)
            },
            {
                name: 'color'
            }
        ]);
    }
    get btnTitleControl() {
        var _a;
        return (_a = this.formButtons) === null || _a === void 0 ? void 0 : _a.get('title');
    }
    get btnTitleColorControl() {
        var _a;
        return (_a = this.formButtons) === null || _a === void 0 ? void 0 : _a.get('titleColor');
    }
    get btnStateControl() {
        var _a;
        return (_a = this.formButtons) === null || _a === void 0 ? void 0 : _a.get('state');
    }
    get btnColorControl() {
        var _a;
        return (_a = this.formButtons) === null || _a === void 0 ? void 0 : _a.get('color');
    }
    get btnUrlControl() {
        var _a;
        return (_a = this.formButtons) === null || _a === void 0 ? void 0 : _a.get('url');
    }
    /**
     * Validator function to check if a state has been selected for the new tag.
     * @param isState
     * @private
     */
    doesGetState(isState) {
        return () => {
            var _a, _b;
            const stateValue = (_a = this.btnStateControl) === null || _a === void 0 ? void 0 : _a.value;
            if (isState) {
                return stateValue ? null : { doesGetState: true };
            }
            return stateValue === 'otherUrl' && !((_b = this.btnUrlControl) === null || _b === void 0 ? void 0 : _b.value) ? { doesGetState: true } : null;
        };
    }
    /**
     * Set given line as the expanded line in table.
     * @param line
     */
    showLineDetails(line) {
        if (this.compareExpandedAndRowLine(line)) {
            this.expandedLine = null;
            if (!this.formLine.pristine && !this.isNew(line)) {
                this.saveLine(line);
            }
        }
        else {
            if (this.canSaveFormLine) {
                this.saveLine(this.expandedLine);
            }
            this.expandedLine = line;
        }
        this.createLineFormGroup(this.expandedLine);
        this.createTagFormGroup();
        Utils.editObjectInstanceInArray(this.linesList, line);
    }
    /**
     * Check if form can be saved.
     */
    get canSaveFormLine() {
        if (!this.formLine) {
            return false;
        }
        return !this.formLine.invalid && this.formLine.dirty;
    }
    /**
     * Compare the expanded line row and a given line row.
     * @param line
     */
    compareExpandedAndRowLine(line) {
        var _a;
        return ((_a = this.expandedLine) === null || _a === void 0 ? void 0 : _a._id) === (line === null || line === void 0 ? void 0 : line._id);
    }
    /**
     * Show or not show the tag form.
     */
    toggleShowTagForm() {
        this.isAddingTag = !this.isAddingTag;
    }
    /**
     * Add a tag in the given line.
     * @param line
     */
    addTag(line) {
        var _a, _b;
        if (!line) {
            return;
        }
        if (!line.tags) {
            line.tags = [];
        }
        line.tags.push({
            title: {
                message: (_a = this.tagNameControl) === null || _a === void 0 ? void 0 : _a.value,
                color: '#000000'
            },
            state: (_b = this.tagLinkControl) === null || _b === void 0 ? void 0 : _b.value,
            color: '#dcdcdc'
        });
        Utils.editObjectInstanceInArray(this.linesList, line);
        if (!this.isNew(line)) {
            this._dbUpdateLine(line, 'RELEASE_NOTES.SNACKBAR.TAG_ADDED');
        }
        this.toggleShowTagForm();
        this.formTag.reset();
    }
    /**
     * Remove a tag from a given line.
     * @param line
     * @param tag
     */
    removeTag(line, tag) {
        const currLine = this._findOneLineById(line._id);
        if (!(currLine === null || currLine === void 0 ? void 0 : currLine.tags)) {
            return;
        }
        currLine.tags = currLine.tags.reduce((acc, curr) => {
            var _a, _b;
            if (((_a = curr.title) === null || _a === void 0 ? void 0 : _a.message) !== ((_b = tag.title) === null || _b === void 0 ? void 0 : _b.message) && curr.state !== tag.state) {
                acc.push(curr);
            }
            return acc;
        }, []);
        if (!this.isNew(line)) {
            this._dbUpdateLine(line, 'RELEASE_NOTES.SNACKBAR.TAG_REMOVED');
        }
    }
    /**
     * Add new line in widget instance
     */
    addRow() {
        var _a, _b;
        this.isAddingNewLine = true;
        const line = {
            _id: 'new',
            name: 'Nouvelle ligne',
            project: null,
            publicationDate: null,
            content: {
                message: null,
                color: '#000000'
            },
            parent: (_b = (_a = this.fullWidget) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b._id
        };
        if (!this.linesList) {
            this.linesList = [];
        }
        this.linesList.unshift(line);
        this.updateLinesList();
        this.showLineDetails(line);
    }
    /**
     * Refresh MatTableDataSource.data to trigger UI table refresh
     * @private
     */
    _refreshDataSource() {
        this.dataSource.data = this.linesList;
    }
    /**
     * Update page buttons list form an empty array.
     * @private
     */
    updateLineListFromForm(line) {
        var _a, _b, _c, _d, _e;
        const currLine = this._findOneLineById(line._id) || {};
        currLine.name = (_a = this.lineNameControl) === null || _a === void 0 ? void 0 : _a.value;
        currLine.project = (_b = this.lineProjectControl) === null || _b === void 0 ? void 0 : _b.value;
        currLine.publicationDate = (_c = this.linePubDateControl) === null || _c === void 0 ? void 0 : _c.value;
        if (!currLine.content) {
            currLine.content = { color: '#000000' };
        }
        currLine.content.message = (_d = this.lineContentControl) === null || _d === void 0 ? void 0 : _d.value;
        currLine.knowledgeBaseArticle = (_e = this.lineKBArticleControl) === null || _e === void 0 ? void 0 : _e.value;
    }
    /**
     * Update page buttons list form an empty array.
     * @private
     */
    updateLinesList() {
        this.linesList = Object.assign([], this.linesList);
        this._refreshDataSource();
    }
    /**
     * Sort lines list from the latest to the oldest publication date.
     */
    sortLines() {
        this.linesList.sort((a, b) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime());
        this.updateLinesList();
    }
    /**
     * Add or update line in database.
     * @param line
     */
    saveLine(line) {
        if (!this.isEditPage) {
            line._id = (this.linesList.length - 1).toString();
            this.isAddingNewLine = false;
            this.formLine.markAsPristine();
            this.expandedLine = null;
            return;
        }
        if (this.isNew(line)) {
            return this._dbAddLine(line, 'RELEASE_NOTES.SNACKBAR.LINE_ADDED');
        }
        this._dbUpdateLine(line, 'RELEASE_NOTES.SNACKBAR.LINE_SAVED');
    }
    /**
     * Delete line from database.
     * @param line
     */
    deleteLine(line) {
        if (!this.isEditPage) {
            this.cancelLine();
            return;
        }
        this._dbDeleteLine(line, 'RELEASE_NOTES.SNACKBAR.LINE_REMOVED');
    }
    /**
     * Cancel line addition.
     */
    cancelLine() {
        var _a;
        this.linesList = (_a = this.linesList) === null || _a === void 0 ? void 0 : _a.reduce((acc, curr) => {
            if (!this.isNew(curr)) {
                acc.push(curr);
            }
            return acc;
        }, []);
        this._refreshDataSource();
        this.formLine.reset();
        this.formTag.reset();
        this.isAddingNewLine = false;
    }
    /**
     * Update page buttons list form an empty array.
     * @private
     */
    updateButtonsList() {
        this.buttonsList = Object.assign([], this.buttonsList);
    }
    /**
     * Add a button in the widget footer.
     */
    addButton() {
        var _a, _b, _c, _d, _e;
        if (this.formButtons.invalid || !this.formButtons.dirty) {
            return;
        }
        this.buttonsList.push({
            title: {
                message: (_a = this.btnTitleControl) === null || _a === void 0 ? void 0 : _a.value,
                color: (_b = this.btnTitleColorControl) === null || _b === void 0 ? void 0 : _b.value
            },
            state: (_c = this.btnStateControl) === null || _c === void 0 ? void 0 : _c.value,
            url: (_d = this.btnUrlControl) === null || _d === void 0 ? void 0 : _d.value,
            color: (_e = this.btnColorControl) === null || _e === void 0 ? void 0 : _e.value
        });
        this.updateButtonsList();
        this.formWidget.markAsDirty();
        this.formButtons.reset();
    }
    /**
     * Find given button in the page buttons list and change its color.
     * @param button
     * @param color
     */
    editButtonColor(button, color) {
        const foundButton = this.buttonsList.find((btn) => btn.title === button.title &&
            btn.state === button.state &&
            btn.url === button.url &&
            btn.color === button.color);
        if (!foundButton) {
            return;
        }
        foundButton.color = color;
        this.formWidget.markAsDirty();
    }
    /**
     * Remove a button from the widget footer.
     * @param button
     */
    deleteButton(button) {
        Utils.removeElemFromArray(this.buttonsList, (btn) => btn.title === button.title &&
            btn.state === button.state &&
            btn.url === button.url &&
            btn.color === button.color);
        this.updateButtonsList();
        this.formWidget.markAsDirty();
    }
    /**
     * Check forms, tell user there is an unfinished element and save widget.
     */
    saveWidget() {
        var _a, _b;
        if (this.formWidget.invalid || this.formWidget.pristine) {
            return this._snackbarService.openCustomError(this._translationService.instant('RELEASE_NOTES.DETAILS.INVALID_FORM'));
        }
        if (((_a = this.formLine) === null || _a === void 0 ? void 0 : _a.pristine) === false || !((_b = this.formButtons) === null || _b === void 0 ? void 0 : _b.pristine)) {
            this._dialogService
                .openConfirmDialog({
                title: this._translationService.instant('RELEASE_NOTES.DETAILS.RESET.TITLE'),
                message: this._translationService.instant('RELEASE_NOTES.DETAILS.RESET.MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.YES'),
                cancelButton: this._translationService.instant('COMMONS.NO')
            })
                .pipe(takeUntil(this._unsubscribe))
                .subscribe((doesCancel) => {
                if (doesCancel) {
                    this.formButtons.reset();
                    this.formLine.reset();
                    this._saveWidget();
                }
            }, console.error);
        }
        else {
            this._saveWidget();
        }
    }
    /**
     * Use form controls inputs to create a widget instance.
     * @param oldFullWidget
     */
    createWidgetAndLinesFromForm(oldFullWidget) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!((_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.controls)) {
            return null;
        }
        const newWidget = {
            widget: {
                title: {
                    message: (_b = this.titleControl) === null || _b === void 0 ? void 0 : _b.value,
                    color: (_c = this.titleColorControl) === null || _c === void 0 ? void 0 : _c.value
                },
                background: (_d = this.backgroundControl) === null || _d === void 0 ? void 0 : _d.value,
                buttons: {
                    layout: (_e = this.btnsLayoutControl) === null || _e === void 0 ? void 0 : _e.value,
                    list: this.buttonsList
                },
                positions: [
                    { [((_f = this.oneColumnXPositionControl) === null || _f === void 0 ? void 0 : _f.value) - 1]: ((_g = this.oneColumnYPositionControl) === null || _g === void 0 ? void 0 : _g.value) - 1 },
                    { [((_h = this.twoColumnsXPositionControl) === null || _h === void 0 ? void 0 : _h.value) - 1]: ((_j = this.twoColumnsYPositionControl) === null || _j === void 0 ? void 0 : _j.value) - 1 },
                    { [((_k = this.threeColumnsXPositionControl) === null || _k === void 0 ? void 0 : _k.value) - 1]: ((_l = this.threeColumnsYPositionControl) === null || _l === void 0 ? void 0 : _l.value) - 1 }
                ]
            },
            lines: {
                items: this.linesList,
                hasNextPage: false,
                nextPage: null
            }
        };
        if (oldFullWidget === null || oldFullWidget === void 0 ? void 0 : oldFullWidget.widget) {
            newWidget.widget._id = oldFullWidget.widget._id;
            newWidget.widget.creationUID = oldFullWidget.widget.creationUID;
            newWidget.widget.creationDate = oldFullWidget.widget.creationDate;
        }
        return newWidget;
    }
    /**
     * Match a given line ID with a line in the widget.
     * @param id
     * @private
     */
    _findOneLineById(id) {
        var _a;
        return (_a = this.linesList) === null || _a === void 0 ? void 0 : _a.find((l) => l._id === id);
    }
    /**
     * Toggle update or add widget in database.
     * @private
     */
    _saveWidget() {
        if (this.isEditPage) {
            return this._dbUpdateWidget();
        }
        this._dbAddWidget();
    }
    /**
     * Add widget with its lines in database, update it after save or show error message if it fails.
     * @private
     */
    _dbAddWidget() {
        const toAdd = this.createWidgetAndLinesFromForm();
        toAdd.lines.items = this.linesList.filter((l) => l._id !== 'new');
        this.isUpdatingWidget = true;
        this._releaseNotesService.postReleaseNoteWithLines(toAdd).subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this.fullWidget = res;
            this._dbRefreshWidget(() => {
                this.isEditPage = true;
                this.isUpdatingWidget = false;
                this.expandedLine = null;
                this.createWidgetFormGroup();
            });
        }, (err) => this._snackbarService.openError(err));
    }
    /**
     * Save widget with its lines in database, update it after save or show error message if it fails.
     * @param message
     * @private
     */
    _dbUpdateWidget(message = 'RELEASE_NOTES.SNACKBAR.WIDGET_SAVED') {
        const toAdd = this.createWidgetAndLinesFromForm(this.fullWidget);
        toAdd.lines.items = this.linesList.filter((l) => l._id !== 'new');
        this.isUpdatingWidget = true;
        this._releaseNotesService
            .putReleaseNoteWithLines(toAdd)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._dbRefreshWidget(() => {
                this.isUpdatingWidget = false;
                this.formWidget.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Add one widget line in database, update lines list after save or show error message if it fails.
     * @param line
     * @param message
     * @private
     */
    _dbAddLine(line, message = 'RELEASE_NOTES.SNACKBAR.LINE_SAVED') {
        delete line._id;
        this.isUpdatingWidget = true;
        this._releaseNotesService
            .postReleaseNoteLine(this.widget._id, line)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._dbRefreshWidget(() => {
                this.isAddingNewLine = false;
                this.isUpdatingWidget = false;
                this.formLine.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Update one widget line in database, update lines list after save or show error message if it fails.
     * @param line
     * @param message
     * @private
     */
    _dbUpdateLine(line, message = 'RELEASE_NOTES.SNACKBAR.WIDGET_SAVED') {
        this.isUpdatingWidget = true;
        this._releaseNotesService
            .putReleaseNoteLine(this.widget._id, this._findOneLineById(line._id))
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._dbRefreshWidget(() => {
                this.isUpdatingWidget = false;
                this.formLine.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Add one widget line in database, update lines list after save or show error message if it fails.
     * @param line
     * @param message
     * @private
     */
    _dbDeleteLine(line, message = 'RELEASE_NOTES.SNACKBAR.LINE_SAVED') {
        this.isUpdatingWidget = true;
        this._releaseNotesService
            .deleteReleaseNoteLine(this.widget._id, line._id)
            .pipe(take(1))
            .subscribe((res) => {
            if (res !== 'OK') {
                this.isUpdatingWidget = false;
                console.error(res);
                return this._snackbarService.openDefaultError();
            }
            this._dbRefreshWidget(() => {
                this.isUpdatingWidget = false;
                this.formLine.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Get full widget from database.
     * @param callback
     * @private
     */
    _dbRefreshWidget(callback) {
        this._releaseNotesService
            .getReleaseNoteWithLinesById(this.fullWidget.widget._id, false)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this.fullWidget = res;
            this.linesList = res.lines.items;
            this.updateLinesList();
            this.buttonsList = res.widget.buttons.list;
            this.updateButtonsList();
            callback();
        }, this._snackbarService.openErrorFromStatus);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof IReleaseNoteAndLines !== "undefined" && IReleaseNoteAndLines) === "function" ? _g : Object)
], ReleaseNoteDetailsComponent.prototype, "fullWidget", void 0);
__decorate([
    ViewChild(MatPaginator),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], ReleaseNoteDetailsComponent.prototype, "paginator", void 0);
ReleaseNoteDetailsComponent = ReleaseNoteDetailsComponent_1 = __decorate([
    Component({
        selector: 'mpx-release-note-details',
        template: require('./release-note-details.component.html').default,
        styles: [require('./release-note-details.component.scss')],
        providers: [WidgetInjection],
        animations: [
            fuseAnimations,
            trigger('lineDetails', [
                state('collapsed', style({ height: '0px', minHeight: '0' })),
                state('expanded', style({ height: '*' })),
                transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
            ])
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof ReleaseNotesService !== "undefined" && ReleaseNotesService) === "function" ? _e : Object, typeof (_f = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _f : Object])
], ReleaseNoteDetailsComponent);
export { ReleaseNoteDetailsComponent };
