var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { LocationService } from '../../../../services/location/location.service';
import { find } from 'lodash';
import { Observable } from 'rxjs';
let OtherTabComponent = class OtherTabComponent {
    constructor(_locationService) {
        this._locationService = _locationService;
        _locationService.getAllRegions().subscribe((data) => {
            this.regions = data;
            if (this.pharmacy && this.form)
                this._setFormControls(this.pharmacy);
        });
    }
    ngOnChanges(changes) {
        if (changes.pharmacy) {
            this._setFormControls(this.pharmacy);
        }
    }
    _setFormControls(pharmacy) {
        this.form.setControl('phoneSTD', new UntypedFormControl(pharmacy.phoneSTD));
        this.form.setControl('phoneSCT', new UntypedFormControl(pharmacy.phoneSCT));
        this.form.setControl('website', new UntypedFormControl(pharmacy.website));
        this.form.setControl('fax', new UntypedFormControl(pharmacy.Fax));
        this.form.setControl('bedMCO', new UntypedFormControl(pharmacy.bedMCO));
        this.form.setControl('bedSSR', new UntypedFormControl(pharmacy.bedSSR));
        this.form.setControl('bedPSY', new UntypedFormControl(pharmacy.bedPSY));
        this.form.setControl('bedEPHAD', new UntypedFormControl(pharmacy.bedEPHAD));
        let foundRegion = null;
        if (pharmacy.region) {
            foundRegion = find(this.regions, (reg) => {
                return reg._id == pharmacy.region._id;
            });
        }
        this.form.setControl('region', new UntypedFormControl(foundRegion));
        this.form.setControl('latitude', new UntypedFormControl(pharmacy.lat));
        this.form.setControl('longitude', new UntypedFormControl(pharmacy.lng));
    }
    updateHospital(pharmacy) {
        return new Observable((observer) => {
            pharmacy.phoneSTD = this.form.value.phoneSTD;
            pharmacy.phoneSCT = this.form.value.phoneSCT;
            pharmacy.website = this.form.value.website;
            pharmacy.Fax = this.form.value.fax;
            pharmacy.bedMCO = this.form.value.bedMCO;
            pharmacy.bedSSR = this.form.value.bedSSR;
            pharmacy.bedPSY = this.form.value.bedPSY;
            pharmacy.bedEPHAD = this.form.value.bedEPHAD;
            pharmacy.region = this.form.value.region;
            pharmacy.lat = this.form.value.latitude;
            pharmacy.lng = this.form.value.longitude;
            pharmacy.geo = [this.form.value.longitude, this.form.value.latitude];
            observer.next();
            observer.complete();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _b : Object)
], OtherTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Hospital !== "undefined" && Hospital) === "function" ? _c : Object)
], OtherTabComponent.prototype, "pharmacy", void 0);
OtherTabComponent = __decorate([
    Component({
        selector: 'mpx-other-tab',
        template: require('./other-tab.component.html').default,
        styles: [require('./other-tab.component.scss')],
        animations: fuseAnimations,
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof LocationService !== "undefined" && LocationService) === "function" ? _a : Object])
], OtherTabComponent);
export { OtherTabComponent };
