import { ENotificationType } from '@mapuilabs/mpl-interfaces';
export const ICONS_CONFIG = {
    [ENotificationType.Available]: 'shopping_cart',
    [ENotificationType.Loan]: 'swap_horiz',
    [ENotificationType.Borrowing]: 'swap_horiz',
    [ENotificationType.Preparation]: 'custom-prep-nav',
    [ENotificationType.Request]: 'search',
    [ENotificationType.Group]: 'groups',
    [ENotificationType.User]: 'account_circle',
    [ENotificationType.Hospital]: 'local_hospital',
    [ENotificationType.SharedFile]: 'drafts'
};
export const BADGES_CONFIG = [
    {
        id: 'admin.panel',
        notificationTypes: [ENotificationType.User],
        color: '#F44336'
    },
    {
        id: 'exchanges.dashboard',
        notificationTypes: [ENotificationType.Loan, ENotificationType.Preparation, ENotificationType.Borrowing],
        color: '#F44336'
    },
    {
        id: 'exchanges.map',
        notificationTypes: [ENotificationType.Available, ENotificationType.Request],
        color: '#9C27B0'
    },
    {
        id: 'settings.groups',
        notificationTypes: [ENotificationType.Group],
        color: '#009688'
    }
];
