var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalGenericTableComponent } from '@app/shared/components/modals/modal-generic-table/modal-generic-table.component';
import { fuseAnimations } from '@fuse/animations';
import { TooltipService } from '@swimlane/ngx-charts';
import { curveMonotoneX } from 'd3-shape';
import * as _ from 'lodash';
import * as moment from 'moment';
let HsDashboardWidgetCountComponent = class HsDashboardWidgetCountComponent {
    constructor(_dialog, chartToolTipService, viewContainerRef) {
        this._dialog = _dialog;
        this.chartToolTipService = chartToolTipService;
        this.viewContainerRef = viewContainerRef;
        this._nbMonth = 12;
        this.graphConfig = {
            autoScale: false,
            curve: curveMonotoneX,
            scheme: { domain: ['#2196f3'] },
            schemeType: 'ordinal',
            showGridLines: false,
            xAxis: true,
            xAxisTickFormatting: (val) => moment(val).format('MMM'),
            yAxis: true,
            yAxisTickFormatting: Math.round
        };
        this.chartToolTipService.injectionService.setRootViewContainer(this.viewContainerRef);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if ('listItems' in changes) {
            this._buildGraphSeries(this.listItems);
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _buildGraphSeries(items) {
        const dataFormat = 'YYYY-MM';
        // Empty array for each month
        const itemsPerMonthDefault = _(_.range(1, this._nbMonth + 1))
            .map((item) => [
            moment()
                .subtract(this._nbMonth - item, 'months')
                .format(dataFormat),
            []
        ])
            .fromPairs()
            .value();
        // Group items per month
        this._itemsPerMonth = _(items)
            .groupBy((item) => moment(item.creationDate).format(dataFormat))
            .defaults(itemsPerMonthDefault)
            .value();
        // Format data for graph
        const series = _(this._itemsPerMonth)
            .toPairs()
            .map(([date, items]) => ({ name: date, value: items.length }))
            .sortBy((item) => item.name)
            .value();
        // Get number of new items this month
        this.nbNewItemsThisMonth = _.last(series).value;
        // Accumulate values & format date
        let accumulator = 0;
        series.forEach((item) => {
            accumulator += item.value;
            item.value = Math.round(accumulator);
            item.name = new Date(item.name);
        });
        // Set graphData
        this.graphData = [{ name: this.config.items.label, series: _.takeRight(series, this._nbMonth) }];
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    getTooltipItems(graphDataItem) {
        return this._itemsPerMonth[moment(graphDataItem.name).format('YYYY-MM')];
    }
    onSeeListClicked() {
        this._dialog.open(ModalGenericTableComponent, {
            data: { config: this.config.modal, dataSource: this.listItems },
            minWidth: '60%'
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsDashboardWidgetCountComponent.prototype, "listItems", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsDashboardWidgetCountComponent.prototype, "config", void 0);
HsDashboardWidgetCountComponent = __decorate([
    Component({
        selector: 'mpx-hs-dashboard-widget-count',
        template: require('./hs-dashboard-widget-count.component.html').default,
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _a : Object, typeof (_b = typeof TooltipService !== "undefined" && TooltipService) === "function" ? _b : Object, typeof (_c = typeof ViewContainerRef !== "undefined" && ViewContainerRef) === "function" ? _c : Object])
], HsDashboardWidgetCountComponent);
export { HsDashboardWidgetCountComponent };
