var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, HostBinding, Input } from '@angular/core';
let FuseNavHorizontalItemComponent = class FuseNavHorizontalItemComponent {
    /**
     * Constructor
     */
    constructor() {
        this.classes = 'nav-item';
    }
};
__decorate([
    HostBinding('class'),
    __metadata("design:type", Object)
], FuseNavHorizontalItemComponent.prototype, "classes", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FuseNavHorizontalItemComponent.prototype, "item", void 0);
FuseNavHorizontalItemComponent = __decorate([
    Component({
        selector: 'fuse-nav-horizontal-item',
        template: require('./item.component.html').default,
        styles: [require('./item.component.scss')]
    }),
    __metadata("design:paramtypes", [])
], FuseNavHorizontalItemComponent);
export { FuseNavHorizontalItemComponent };
