var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import PerfectScrollbar from 'perfect-scrollbar';
import * as _ from 'lodash';
import { FusePerfectScrollbarGeometry, FusePerfectScrollbarPosition } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.interfaces';
import { FuseConfigService } from '@fuse/services/config.service';
let FusePerfectScrollbarDirective = class FusePerfectScrollbarDirective {
    /**
     * Constructor
     *
     * @param {ElementRef} elementRef
     * @param {FuseConfigService} _fuseConfigService
     * @param {Platform} _platform
     * @param {Router} _router
     */
    constructor(elementRef, _fuseConfigService, _platform) {
        this.elementRef = elementRef;
        this._fuseConfigService = _fuseConfigService;
        this._platform = _platform;
        // Set the defaults
        this.isInitialized = false;
        this.isMobile = false;
        // Set the private defaults
        this._animation = null;
        this._enabled = false;
        this._debouncedUpdate = _.debounce(this.update, 150);
        this._options = {
            updateOnRouteChange: false
        };
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Perfect Scrollbar options
     *
     * @param value
     */
    set fusePerfectScrollbarOptions(value) {
        // Merge the options
        this._options = _.merge({}, this._options, value);
        // Destroy and re-init the PerfectScrollbar to update its options
        setTimeout(() => {
            this._destroy();
        });
        setTimeout(() => {
            this._init();
        });
    }
    get fusePerfectScrollbarOptions() {
        // Return the options
        return this._options;
    }
    /**
     * Is enabled
     *
     * @param {boolean | ""} value
     */
    set enabled(value) {
        // If nothing is provided with the directive (empty string),
        // we will take that as a true
        if (value === '') {
            value = true;
        }
        // Return, if both values are the same
        if (this.enabled === value) {
            return;
        }
        // Store the value
        this._enabled = value;
        // If enabled...
        if (this.enabled) {
            // Init the directive
            this._init();
        }
        else {
            // Otherwise destroy it
            this._destroy();
        }
    }
    get enabled() {
        // Return the enabled status
        return this._enabled;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Subscribe to window resize event
        fromEvent(window, 'resize')
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(150))
            .subscribe(() => {
            // Update the PerfectScrollbar
            this.update();
        });
    }
    /**
     * After view init
     */
    ngAfterViewInit() {
        // Check if scrollbars enabled or not from the main config
        this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
            this.enabled = settings.customScrollbars;
        });
        this.enabled = true;
        // Commented for MAPUI. This part is using Angular Router while
        // our application use ui-router
        // Scroll to the top on every route change
        // if ( this.fusePerfectScrollbarOptions.updateOnRouteChange )
        // {
        //     this._router.events
        //         .pipe(
        //             takeUntil(this._unsubscribeAll),
        //             filter(event => event instanceof NavigationEnd)
        //         )
        //         .subscribe(() => {
        //             setTimeout(() => {
        //                 this.scrollToTop();
        //                 this.update();
        //             }, 0);
        //         });
        // }
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        this._destroy();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        // Return, if already initialized
        if (this.isInitialized) {
            return;
        }
        // Check if is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
        }
        // Return if it's mobile
        if (this.isMobile) {
            // Return...
            return;
        }
        // Set as initialized
        this.isInitialized = true;
        // Initialize the perfect-scrollbar
        this.ps = new PerfectScrollbar(this.elementRef.nativeElement, Object.assign({}, this.fusePerfectScrollbarOptions));
        // Unbind 'keydown' events of PerfectScrollbar since it causes an extremely
        // high CPU usage on Angular Material inputs.
        // Loop through all the event elements of this PerfectScrollbar instance
        this.ps.event.eventElements.forEach((eventElement) => {
            // If we hit to the element with a 'keydown' event...
            if (typeof eventElement.handlers['keydown'] !== 'undefined') {
                // Unbind it
                eventElement.element.removeEventListener('keydown', eventElement.handlers['keydown'][0]);
            }
        });
    }
    /**
     * Destroy
     *
     * @private
     */
    _destroy() {
        if (!this.isInitialized || !this.ps) {
            return;
        }
        // Destroy the perfect-scrollbar
        this.ps.destroy();
        // Clean up
        this.ps = null;
        this.isInitialized = false;
    }
    /**
     * Update scrollbars on window resize
     *
     * @private
     */
    _updateOnResize() {
        this._debouncedUpdate();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Document click
     *
     * @param {Event} event
     */
    documentClick(event) {
        if (!this.isInitialized || !this.ps) {
            return;
        }
        // Update the scrollbar on document click..
        // This isn't the most elegant solution but there is no other way
        // of knowing when the contents of the scrollable container changes.
        // Therefore, we update scrollbars on every document click.
        this.ps.update();
    }
    /**
     * Update the scrollbar
     */
    update() {
        if (!this.isInitialized) {
            return;
        }
        // Update the perfect-scrollbar
        this.ps.update();
    }
    /**
     * Destroy the scrollbar
     */
    destroy() {
        this.ngOnDestroy();
    }
    /**
     * Returns the geometry of the scrollable element
     *
     * @param prefix
     */
    geometry(prefix = 'scroll') {
        return new FusePerfectScrollbarGeometry(this.elementRef.nativeElement[prefix + 'Left'], this.elementRef.nativeElement[prefix + 'Top'], this.elementRef.nativeElement[prefix + 'Width'], this.elementRef.nativeElement[prefix + 'Height']);
    }
    /**
     * Returns the position of the scrollable element
     *
     * @param absolute
     */
    position(absolute = false) {
        if (!absolute && this.ps) {
            return new FusePerfectScrollbarPosition(this.ps.reach.x || 0, this.ps.reach.y || 0);
        }
        else {
            return new FusePerfectScrollbarPosition(this.elementRef.nativeElement.scrollLeft, this.elementRef.nativeElement.scrollTop);
        }
    }
    /**
     * Scroll to
     *
     * @param x
     * @param y
     * @param speed
     */
    scrollTo(x, y, speed) {
        if (y == null && speed == null) {
            this.animateScrolling('scrollTop', x, speed);
        }
        else {
            if (x != null) {
                this.animateScrolling('scrollLeft', x, speed);
            }
            if (y != null) {
                this.animateScrolling('scrollTop', y, speed);
            }
        }
    }
    /**
     * Scroll to X
     *
     * @param {number} x
     * @param {number} speed
     */
    scrollToX(x, speed) {
        this.animateScrolling('scrollLeft', x, speed);
    }
    /**
     * Scroll to Y
     *
     * @param {number} y
     * @param {number} speed
     */
    scrollToY(y, speed) {
        this.animateScrolling('scrollTop', y, speed);
    }
    /**
     * Scroll to top
     *
     * @param {number} offset
     * @param {number} speed
     */
    scrollToTop(offset, speed) {
        this.animateScrolling('scrollTop', offset || 0, speed);
    }
    /**
     * Scroll to left
     *
     * @param {number} offset
     * @param {number} speed
     */
    scrollToLeft(offset, speed) {
        this.animateScrolling('scrollLeft', offset || 0, speed);
    }
    /**
     * Scroll to right
     *
     * @param {number} offset
     * @param {number} speed
     */
    scrollToRight(offset, speed) {
        const left = this.elementRef.nativeElement.scrollWidth - this.elementRef.nativeElement.clientWidth;
        this.animateScrolling('scrollLeft', left - (offset || 0), speed);
    }
    /**
     * Scroll to bottom
     *
     * @param {number} offset
     * @param {number} speed
     */
    scrollToBottom(offset, speed) {
        const top = this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.clientHeight;
        this.animateScrolling('scrollTop', top - (offset || 0), speed);
    }
    /**
     * Scroll to element
     *
     * @param qs
     * @param offset
     * @param speed
     */
    scrollToElement(qs, offset, speed) {
        const element = this.elementRef.nativeElement.querySelector(qs);
        if (!element) {
            return;
        }
        const elementPos = element.getBoundingClientRect();
        const scrollerPos = this.elementRef.nativeElement.getBoundingClientRect();
        if (this.elementRef.nativeElement.classList.contains('ps--active-x')) {
            const currentPos = this.elementRef.nativeElement['scrollLeft'];
            const position = elementPos.left - scrollerPos.left + currentPos;
            this.animateScrolling('scrollLeft', position + (offset || 0), speed);
        }
        if (this.elementRef.nativeElement.classList.contains('ps--active-y')) {
            const currentPos = this.elementRef.nativeElement['scrollTop'];
            const position = elementPos.top - scrollerPos.top + currentPos;
            this.animateScrolling('scrollTop', position + (offset || 0), speed);
        }
    }
    /**
     * Animate scrolling
     *
     * @param target
     * @param value
     * @param speed
     */
    animateScrolling(target, value, speed) {
        if (this._animation) {
            window.cancelAnimationFrame(this._animation);
            this._animation = null;
        }
        if (!speed || typeof window === 'undefined') {
            this.elementRef.nativeElement[target] = value;
        }
        else if (value !== this.elementRef.nativeElement[target]) {
            let newValue = 0;
            let scrollCount = 0;
            let oldTimestamp = performance.now();
            let oldValue = this.elementRef.nativeElement[target];
            const cosParameter = (oldValue - value) / 2;
            const step = (newTimestamp) => {
                scrollCount += Math.PI / (speed / (newTimestamp - oldTimestamp));
                newValue = Math.round(value + cosParameter + cosParameter * Math.cos(scrollCount));
                // Only continue animation if scroll position has not changed
                if (this.elementRef.nativeElement[target] === oldValue) {
                    if (scrollCount >= Math.PI) {
                        this.animateScrolling(target, value, 0);
                    }
                    else {
                        this.elementRef.nativeElement[target] = newValue;
                        // On a zoomed out page the resulting offset may differ
                        oldValue = this.elementRef.nativeElement[target];
                        oldTimestamp = newTimestamp;
                        this._animation = window.requestAnimationFrame(step);
                    }
                }
            };
            window.requestAnimationFrame(step);
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], FusePerfectScrollbarDirective.prototype, "fusePerfectScrollbarOptions", null);
__decorate([
    Input('fusePerfectScrollbar'),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], FusePerfectScrollbarDirective.prototype, "enabled", null);
__decorate([
    HostListener('window:resize'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], FusePerfectScrollbarDirective.prototype, "_updateOnResize", null);
__decorate([
    HostListener('document:click', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Event !== "undefined" && Event) === "function" ? _d : Object]),
    __metadata("design:returntype", void 0)
], FusePerfectScrollbarDirective.prototype, "documentClick", null);
FusePerfectScrollbarDirective = __decorate([
    Directive({
        selector: '[fusePerfectScrollbar]'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _a : Object, typeof (_b = typeof FuseConfigService !== "undefined" && FuseConfigService) === "function" ? _b : Object, typeof (_c = typeof Platform !== "undefined" && Platform) === "function" ? _c : Object])
], FusePerfectScrollbarDirective);
export { FusePerfectScrollbarDirective };
