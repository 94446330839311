export const locale = {
    lang: 'fr',
    data: {
        HOSPITAL_INFORMATION: {
            TITLE: 'Mon hôpital - Information',
            CONTENT: {
                ADDRESS: {
                    TITLE: 'Adresse',
                    INFO: {
                        STREET_NBR: 'Numéro',
                        STREET: 'Voie',
                        ZIP_CODE: 'Code postal',
                        CITY: 'Ville',
                        COUNTRY: 'Pays'
                    }
                },
                PHONE: {
                    TITLE: 'Téléphone / Fax / Email',
                    INFO: {
                        SWITCHBOARD: 'Standard téléphonique',
                        SECRETARY: 'Secrétariat pharmacie téléphone',
                        FAX: 'Fax',
                        PHARMA_DEPT_EMAIL: 'Email de la pharmacie'
                    }
                },
                WEB: {
                    TITLE: 'Site web',
                    INFO: {
                        ADDRESS: 'URL'
                    }
                },
                CAPACITY: {
                    TITLE: 'Capacité de lits',
                    INFO: {
                        MCO: 'MCO',
                        EPHAD: 'EPHAD',
                        SSR: 'SSR',
                        PSY: 'PSY'
                    }
                }
            },
            SAVE: {
                SUCCESS: 'Hôpital sauvegardé',
                FAILURE: "Échec de la sauvegarde de l'hôpital"
            }
        }
    }
};
