var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable, Injector } from '@angular/core';
import { from } from 'rxjs';
import { EMaPUIPermission, Hospital } from '@mapuilabs/mpl-interfaces';
import { HospitalService as AjsHospitalService } from '../../../main/services/hospital/hospital.service';
import { CrudTemplateService } from '../../shared/templates/crud/crud.template';
import { HttpParams } from '@angular/common/http';
let HospitalService = class HospitalService extends CrudTemplateService {
    constructor(__injector, _ajsHospitalService) {
        super(__injector, Hospital, '/api/hospitals', {
            // Read is allowed for all logged in user
            read: null,
            create: EMaPUIPermission.Establishment_Create,
            update: EMaPUIPermission.Establishment_Edit
        });
        this.__injector = __injector;
        this._ajsHospitalService = _ajsHospitalService;
    }
    /**
     * Get all members of a given hospital
     * @param hid
     */
    getMembers(hid) {
        const endpoint = `${this._endPoint}/${hid}/members`;
        return this._http.get(endpoint);
    }
    /**
     * Return an IHospital with only the groups attribute (populated)
     */
    getGroups() {
        const endpoint = `${this._endPoint}/groups`;
        return this._http.get(endpoint);
    }
    /**
     * Get different categories of loans by hospital id
     * @param hid
     */
    getAvailable(hid) {
        const endpoint = `${this._endPoint}/${hid}/available`;
        return this._http.get(endpoint);
    }
    getRequestLoans(hid) {
        const endpoint = `${this._endPoint}/${hid}/loans/request`;
        return this._http.get(endpoint);
    }
    //TODO: Do not use Ajs service
    findByName(name, type) {
        const promise = new Promise((resolve, reject) => {
            this._ajsHospitalService
                .findByName(name, type)
                .then((hosps) => {
                const newHosps = [];
                if (hosps && hosps.length) {
                    for (const h of hosps) {
                        newHosps.push(new Hospital(h));
                    }
                }
                resolve(newHosps);
            })
                .catch(reject);
        });
        return from(promise);
    }
    //TODO: Do not use Ajs service
    getById(id) {
        const promise = new Promise((resolve, reject) => {
            this._ajsHospitalService
                .get(id)
                .then((hosp) => {
                resolve(new Hospital(hosp));
            })
                .catch(reject);
        });
        return from(promise);
    }
    getDistance(hospitalId) {
        return this._http.get(`${this._endPoint}/distance/${hospitalId}`);
    }
    /**
     * getMembersByHospitalId
     * Get all Members related to the group by group's Members and/or group's Hospitals
     * @param hospitalId group id target
     */
    getMembersByHospitalId(hospitalId, otherParams) {
        const endpoint = `${this._endPoint}/${hospitalId}/members/`;
        let params = new HttpParams();
        params = this.addOtherParams(params, otherParams);
        return this._http.get(endpoint, { params: params });
    }
    /**
     * searchMembersByGroupId
     * Search into all Members related to the group by group's Members and/or group's Hospitals
     * @param hospitalId group id target
     * @param search search string
     */
    searchMembersByHospitalId(hospitalId, search) {
        const params = [
            {
                param: 'pageOptions',
                value: JSON.stringify({ limit: 10, page: 1 })
            },
            {
                param: 'search',
                value: search
            }
        ];
        return this.getMembersByHospitalId(hospitalId, params);
    }
};
HospitalService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof AjsHospitalService !== "undefined" && AjsHospitalService) === "function" ? _b : Object])
], HospitalService);
export { HospitalService };
