var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a;
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
// Create the injection token for the custom settings
export const FUSE_CONFIG = new InjectionToken('fuseCustomConfig');
let FuseConfigService = class FuseConfigService {
    /**
     * Constructor
     *
     * @param {Platform} _platform
     * @param {Router} _router
     * @param _config
     * @ngInject
     */
    constructor(_platform, 
    // private _router: Router,
    _config) {
        this._platform = _platform;
        this._config = _config;
        // Set the default config from the user provided config (from forRoot)
        this._defaultConfig = _config;
        // Initialize the service
        this._init();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    /**
     * Set and get the config
     */
    set config(value) {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();
        // Merge the new config
        config = _.merge({}, config, value);
        // Notify the observers
        this._configSubject.next(config);
    }
    get config() {
        return this._configSubject.asObservable();
    }
    /**
     * Get default config
     *
     * @returns {any}
     */
    get defaultConfig() {
        return this._defaultConfig;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Initialize
     *
     * @private
     */
    _init() {
        /**
         * Disable custom scrollbars if browser is mobile
         */
        if (this._platform.ANDROID || this._platform.IOS) {
            this._defaultConfig.customScrollbars = false;
        }
        // Set the config from the default config
        this._configSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));
        // Commented by MaPUI. This code use the angular Router while
        // our application uses ui-router
        // Reload the default layout config on every RoutesRecognized event
        // if the current layout config is different from the default one
        // this._router.events
        //     .pipe(filter(event => event instanceof ResolveEnd))
        //     .subscribe(() => {
        //         if ( !_.isEqual(this._configSubject.getValue().layout, this._defaultConfig.layout) )
        //         {
        //             // Clone the current config
        //             const config = _.cloneDeep(this._configSubject.getValue());
        //
        //             // Reset the layout from the default config
        //             config.layout = _.cloneDeep(this._defaultConfig.layout);
        //
        //             // Set the config
        //             this._configSubject.next(config);
        //         }
        //     });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Set config
     *
     * @param value
     * @param {{emitEvent: boolean}} opts
     */
    setConfig(value, opts = { emitEvent: true }) {
        // Get the value from the behavior subject
        let config = this._configSubject.getValue();
        // Merge the new config
        config = _.merge({}, config, value);
        // If emitEvent option is true...
        if (opts.emitEvent === true) {
            // Notify the observers
            this._configSubject.next(config);
        }
    }
    /**
     * Get config
     *
     * @returns {Observable<any>}
     */
    getConfig() {
        return this._configSubject.asObservable();
    }
    /**
     * Reset to the default config
     */
    resetToDefaults() {
        // Set the config from the default config
        this._configSubject.next(_.cloneDeep(this._defaultConfig));
    }
};
FuseConfigService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __param(1, Inject(FUSE_CONFIG)),
    __metadata("design:paramtypes", [typeof (_a = typeof Platform !== "undefined" && Platform) === "function" ? _a : Object, Object])
], FuseConfigService);
export { FuseConfigService };
