var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, Input } from '@angular/core';
let SmallListButtonComponent = class SmallListButtonComponent {
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SmallListButtonComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SmallListButtonComponent.prototype, "tooltip", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SmallListButtonComponent.prototype, "svgIcon", void 0);
SmallListButtonComponent = __decorate([
    Component({
        selector: 'mpx-small-list-button',
        template: `
        <button mat-icon-button class="md-icon-button centered-content">
            <mat-icon class="secondary-text" [matTooltip]="tooltip" [svgIcon]="svgIcon">{{ icon }}</mat-icon>
        </button>
    `
    })
], SmallListButtonComponent);
export { SmallListButtonComponent };
