var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
let KeyDataWidgetComponent = class KeyDataWidgetComponent {
    constructor(_datePipe, _fuseTranslationLoaderService) {
        this._datePipe = _datePipe;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.data = null; // Change 07/10: Typescript warning for @Input()'s type as interface
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], KeyDataWidgetComponent.prototype, "data", void 0);
KeyDataWidgetComponent = __decorate([
    Component({
        selector: 'widget-key-data',
        template: require('./key-data.component.html').default,
        styles: [require('./key-data.component.scss')],
        animations: fuseAnimations,
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DatePipe !== "undefined" && DatePipe) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], KeyDataWidgetComponent);
export { KeyDataWidgetComponent };
