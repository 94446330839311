var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { InformationTabComponent } from './information-tab/information-tab.component';
import { SettingsTabComponent } from './settings-tab/settings-tab.component';
import { MailsTabComponent } from './mails-tab/mails-tab.component';
import { NotificationsTabComponent } from './notifications-tab/notifications-tab.component';
import { UserAccountComponent } from './user-account.component';
import { TwoFaModalComponent } from './settings-tab/two-fa-modal/two-fa-modal.component';
import { SharedModule } from '@app/shared/shared.module';
let UserAccountModule = class UserAccountModule {
};
UserAccountModule = __decorate([
    NgModule({
        declarations: [
            UserAccountComponent,
            InformationTabComponent,
            SettingsTabComponent,
            MailsTabComponent,
            NotificationsTabComponent,
            TwoFaModalComponent
        ],
        imports: [
            CommonModule,
            QRCodeModule,
            UIRouterUpgradeModule,
            FuseSharedModule,
            FuseDirectivesModule,
            SharedModule,
            MatIconModule,
            MatTabsModule,
            MatButtonModule,
            ReactiveFormsModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            MatSlideToggleModule,
            MatCheckboxModule,
            MatDialogModule,
            MatProgressSpinnerModule,
            MatDividerModule
        ],
        exports: [UserAccountComponent]
    })
], UserAccountModule);
export { UserAccountModule };
