var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, Input } from '@angular/core';
import { ITimingPeriod } from '@mapuilabs/mpl-interfaces';
import { EChartDataType } from '@typings/chart-data-type.enum';
import { StatsActivitiesService } from '@services/stats/stats-activities.service';
let ActivitiesWidgetComponent = class ActivitiesWidgetComponent {
    constructor(_statsActivitiesService) {
        this._statsActivitiesService = _statsActivitiesService;
        this.activitiesWidget = {
            isLoading: true,
            type: EChartDataType.Number,
            title: 'STATS_HOSPIVILLE.WIDGET_TOTAL_ACTIVITIES.TITLE',
            value: null,
            fontClass: 'font-size-56'
        };
        this.isReady = false;
    }
    ngOnInit() {
        if (this.dateLimits) {
            this.activitiesWidget.title = 'STATS_HOSPIVILLE.WIDGET_PERIOD_ACTIVITIES.TITLE';
        }
        if (this.colorClass) {
            this.activitiesWidget.fontClass = this.activitiesWidget.fontClass.concat(' ', this.colorClass);
        }
        this.isReady = true;
        this.updateData();
    }
    ngOnChanges(changes) {
        var _a;
        if ((_a = changes === null || changes === void 0 ? void 0 : changes.dateLimits) === null || _a === void 0 ? void 0 : _a.currentValue) {
            this.updateData();
        }
    }
    updateData() {
        if (this.isReady) {
            this._statsActivitiesService
                .getNumberOfActivities(this.hospital, this.dateLimits)
                .subscribe((activitiesNb) => {
                this.activitiesWidget.value = activitiesNb;
                this.activitiesWidget.isLoading = false;
            });
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ActivitiesWidgetComponent.prototype, "hospital", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof ITimingPeriod !== "undefined" && ITimingPeriod) === "function" ? _b : Object)
], ActivitiesWidgetComponent.prototype, "dateLimits", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ActivitiesWidgetComponent.prototype, "colorClass", void 0);
ActivitiesWidgetComponent = __decorate([
    Component({
        selector: 'mpx-activities-widget',
        template: require('./activities-widget.component.html').default,
        styles: [require('./activities-widget.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StatsActivitiesService !== "undefined" && StatsActivitiesService) === "function" ? _a : Object])
], ActivitiesWidgetComponent);
export { ActivitiesWidgetComponent };
