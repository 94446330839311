import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class ToastService {
    /** @ngInject */
    constructor($mdToast) {
        this.$mdToast = $mdToast;
        /**
         *
         * @param txt
         * @private
         */
        this._ok = (txt) => {
            function ToastController() {
                this.error = txt;
            }
            ;
            this.$mdToast.show({
                controller: ToastController,
                controllerAs: 'vm',
                bindToController: true,
                template: require('./templates/toast-ok.html').default,
                hideDelay: 2500,
                position: 'top right',
                // locals: { error: txt },
            });
        };
        /**
         *
         * @param txt
         * @private
         */
        this._error = (txt) => {
            function ToastController() {
                this.error = txt;
            }
            ;
            this.$mdToast.show({
                controller: ToastController,
                controllerAs: 'vm',
                bindToController: true,
                template: require('./templates/toast-error.html').default,
                hideDelay: 7000,
                position: 'top right',
                // locals: { error: txt },
            });
        };
        /**
         *
         * @param status
         */
        this.show = (status) => {
            switch (status) {
                case EMpStatus.Ok:
                    this._ok('TOAST.OK');
                    break;
                case EMpStatus.RejectByServer:
                    this._error('TOAST.SERVER');
                    break;
                case EMpStatus.NoValidation:
                    this._error('TOAST.NO_VALIDATION');
                    break;
                case EMpStatus.NoDataProvided:
                    this._error('TOAST.DATA');
                    break;
                case EMpStatus.NoAccess:
                    this._error('TOAST.ACCESS');
                    break;
                case EMpStatus.LoginFail:
                    this._error('TOAST.LOGIN.LOGIN_FAIL');
                    break;
                case EMpStatus.LoginCaptchaFail:
                    this._error('TOAST.LOGIN.CAPTCHA_FAIL');
                    break;
                case EMpStatus.TwoFaFail:
                    this._error('TOAST.LOGIN.TWOFA_FAIL');
                    break;
                case EMpStatus.LoginEmailNotVerified:
                    this._error('TOAST.LOGIN.EMAIL_NOT_VERIFIED');
                    break;
                case EMpStatus.NoResult:
                    this._error('TOAST.NO_RESULT');
                    break;
                case EMpStatus.EmailAlreadyInUSe:
                    this._error('TOAST.EMAIL_IN_USE');
                    break;
                case EMpStatus.PwdTooShort:
                    this._error('TOAST.PWD_SHORT');
                    break;
                case EMpStatus.UserAlreadyInUSe:
                    this._error('TOAST.USER_IN_USE');
                    break;
                case EMpStatus.CGUNotAccepted:
                    this._error('TOAST.CGU_NOT_ACCEPTED');
                    break;
                case EMpStatus.CloseByUser:
                    this._error('TOAST.NO_RESULT');
                    break;
                case EMpStatus.NotAvailableAnymore:
                    this._error('TOAST.NOT_AVAILABLE_ANYMORE');
                    break;
                case EMpStatus.CPSCardUndetected:
                    this._error('TOAST.CPS_CARD_UNDETECTED');
                    break;
            }
        };
    }
}
