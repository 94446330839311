var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UserAccountComponent } from './user-account/user-account.component';
const states = [
    {
        name: 'mapui.setting.user-account-v2',
        url: '/user-account',
        data: {
            theme: 'theme-blue'
        },
        views: {
            'content@mapui': {
                component: UserAccountComponent
            }
        }
    },
    {
        name: 'mapui.setting.groups',
        url: '/groups',
        abstract: true
    },
    {
        name: 'mapui.setting.hospital-account',
        url: '/hospital-account',
        abstract: true
    }
];
let SettingRoutingModule = class SettingRoutingModule {
};
SettingRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], SettingRoutingModule);
export { SettingRoutingModule };
