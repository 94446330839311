var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { TranslationService } from '@app/services/translation/translation.service';
let SandboxComponent = class SandboxComponent {
    constructor(_fuseTranslationLoaderService, _translationService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this.components = [
            {
                title: 'Search medical device',
                selector: 'search-medical-device',
                description: 'Autocomplete pour la recherche de dispositif médical',
                uisref: 'mapui.adm.sandbox.search-medical-device'
            },
            {
                title: 'Search drug',
                selector: 'search-drug',
                description: 'Autocomplete pour la recherche de medicament',
                uisref: 'mapui.adm.sandbox.drug-search'
            },
            {
                title: 'Data Usage',
                selector: 'data-usage',
                description: "Progress bar indiquant l'espace utilisé",
                uisref: 'mapui.adm.sandbox.data-usage'
            },
            {
                title: 'Chip',
                selector: 'chip',
                description: 'Simple chip with configurable size',
                uisref: 'mapui.adm.sandbox.chip'
            },
            {
                title: 'Key Data',
                selector: 'key-data',
                description: 'Widget sous forme de petite carte contenant une donnée importante (date, nombre ou texte).',
                uisref: 'mapui.adm.sandbox.key-data'
            },
            {
                title: 'File Drag N Drop',
                selector: 'file-drag-n-drop',
                description: "Composant permettant d'importer un ou plusieurs fichiers par inpu et drag-n-drop.",
                uisref: 'mapui.adm.sandbox.file-drag-n-drop'
            },
            {
                title: 'File Uploader',
                selector: 'file-uploader',
                description: "Composant permettant l'envoi de fichiers vers le serveur",
                uisref: 'mapui.adm.sandbox.file-uploader'
            },
            {
                title: 'File viewer',
                selector: 'file-viewer',
                description: `Afficher un document dans un élément adapté à son format (pdf, jpg, png) ou proposer de le télécharger (dans le cas d'un format inconnu)`,
                uisref: 'mapui.adm.sandbox.file-viewer'
            },
            {
                title: 'Search Hospital',
                selector: 'mpx-search-hospital',
                description: "Composant permettant la recherche d'hopitaux",
                uisref: 'mapui.adm.sandbox.search-hospital'
            },
            {
                title: 'Establishment finess search',
                selector: 'search-establishment-finess',
                description: "Composant permettant la recherche d'étblissement dans la base finess. Il est composé d'un autocomplete",
                uisref: 'mapui.adm.sandbox.search-establishment-finess'
            },
            {
                title: 'Generic Dialog',
                selector: 'generic-dialog',
                description: "Dialog générique permettant l'affichage d'une boîte de dialogue simple et configurable",
                uisref: 'mapui.adm.sandbox.generic-dialog'
            },
            {
                title: 'Select Dosage Type',
                selector: 'select-dosage-type',
                description: "Select pour le dosage d'un medicament",
                uisref: 'mapui.adm.sandbox.select-dosage-type'
            },
            {
                title: 'Select Posology',
                selector: 'select-posology',
                description: 'Selection des posologies simple avec intergration de la modal de posologies complexe',
                uisref: 'mapui.adm.sandbox.select-posology'
            },
            {
                title: 'Datepicker limits',
                selector: 'mpx-datepicker-limits',
                description: 'Selection de bornes avec 2 datepickers',
                uisref: 'mapui.adm.sandbox.datepicker-limits'
            },
            {
                title: 'Dates selector',
                selector: 'mpx-dates-selector',
                description: 'Selection de plages de dates',
                uisref: 'mapui.adm.sandbox.dates-selector'
            },
            {
                title: 'Since date menu',
                selector: 'mpx-since-date-menu',
                description: "Menu pour selectionner rapidement une date depuis aujourd'hui",
                uisref: 'mapui.adm.sandbox.since-date-menu'
            }
        ];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translationService.instant('sandbox.TITLE'),
            icon: 'extension'
        };
    }
};
SandboxComponent = __decorate([
    Component({
        selector: 'mpx-sandbox',
        template: require('./sandbox.component.html').default,
        styles: [require('./sandbox.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], SandboxComponent);
export { SandboxComponent };
