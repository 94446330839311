var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f;
import { Component, ChangeDetectorRef, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
export var FileDragNDropStatus;
(function (FileDragNDropStatus) {
    FileDragNDropStatus[FileDragNDropStatus["EMPTY"] = 0] = "EMPTY";
    FileDragNDropStatus[FileDragNDropStatus["LOADING"] = 1] = "LOADING";
    FileDragNDropStatus[FileDragNDropStatus["DONE"] = 2] = "DONE";
})(FileDragNDropStatus || (FileDragNDropStatus = {}));
export var FileDragNDropReadType;
(function (FileDragNDropReadType) {
    FileDragNDropReadType[FileDragNDropReadType["File"] = 0] = "File";
    FileDragNDropReadType[FileDragNDropReadType["ArrayBuffer"] = 1] = "ArrayBuffer";
    FileDragNDropReadType[FileDragNDropReadType["BinaryString"] = 2] = "BinaryString";
    FileDragNDropReadType[FileDragNDropReadType["DataURL"] = 3] = "DataURL";
    FileDragNDropReadType[FileDragNDropReadType["Text"] = 4] = "Text";
})(FileDragNDropReadType || (FileDragNDropReadType = {}));
/**
 * Component import and read files using browse button or dra and drop zone:
 *
 * - @Input() multiple: Allow one or multiple file import.
 * - @Input() accept: Specify accepted file extensions. All extensions accepted by default.
 * - @Input() readType: Specify the format of the resulting file reading process.
 * - @Output() onLoadedFilesChanged: Emit event when the files list has changed.
 */
let FileDragNDropComponent = class FileDragNDropComponent {
    constructor(_fuseTranslationLoaderService, _translateService, _snackbarService, _changeDetectorRef) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._snackbarService = _snackbarService;
        this._changeDetectorRef = _changeDetectorRef;
        this.multiple = false;
        this.accept = '';
        this.readType = FileDragNDropReadType.ArrayBuffer;
        this.status = FileDragNDropStatus.EMPTY;
        this.fileContainerSet = new Set();
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.loadedFilesChanged = new EventEmitter();
    }
    get isFileContainerSetEmpty() {
        return this.fileContainerSet.size === 0;
    }
    /**
     * Clear files list if inputs changed
     * @param changes
     */
    ngOnChanges(changes) {
        for (const propName in changes) {
            switch (propName) {
                case 'multiple':
                case 'accept':
                case 'readType': {
                    this.clearFileList();
                }
            }
        }
    }
    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    processFiles(fileList) {
        return __awaiter(this, void 0, void 0, function* () {
            this.status = FileDragNDropStatus.LOADING;
            // Check if multiples files are provided in no multiple mode
            if (!this.multiple && fileList.length > 1) {
                const errMessage = this._translateService.instant('DRAG_N_DROP.ERRORS.MULTIPLE_NOT_ALLOWED');
                this._snackbarService.openCustomError(errMessage);
                console.error('Multiple files in input but mupltiple file option is not enabled in component.');
                return;
            }
            // New files to be read and added to this.files
            const fileContainerSet = new Set();
            for (const key in fileList) {
                if (!isNaN(parseInt(key))) {
                    fileContainerSet.add({ file: fileList[key] });
                }
            }
            // Read files if ReadType id different than 'File'
            if (this.readType !== FileDragNDropReadType.File) {
                yield this.readFiles(fileContainerSet);
            }
            // Add files to list
            if (this.multiple) {
                for (const file of fileContainerSet) {
                    this.fileContainerSet.add(file);
                }
            }
            else {
                this.fileContainerSet = fileContainerSet;
            }
            this.status = FileDragNDropStatus.DONE;
            this.loadedFilesChanged.emit(this.fileContainerSet);
            this._changeDetectorRef.detectChanges();
        });
    }
    /**
     * Read multiples files using the readFile methode
     * @param fileContainerSet Files to be read
     */
    readFiles(fileContainerSet) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield Promise.all(Array.from(fileContainerSet).map((file) => __awaiter(this, void 0, void 0, function* () {
                return yield this.readFile(file);
            })));
        });
    }
    /**
     * Read files depending on the readType and add it to the loadedFiles list.
     * Provide progress attribute to the file during processing.
     * @param fileContainer File to be read
     */
    readFile(fileContainer) {
        return new Promise((resolve, reject) => {
            // Init progress
            fileContainer.progress = of(0);
            // Define reader events handlers
            function progressHandler(evt) {
                if (evt.lengthComputable) {
                    const percentLoaded = Math.round((evt.loaded / evt.total) * 100);
                    if (percentLoaded < 100) {
                        fileContainer.progress = of(percentLoaded);
                    }
                }
            }
            function errorHandler(evt) {
                switch (evt.target.error.code) {
                    case evt.target.error.NOT_FOUND_ERR:
                        reject(new Error('File Not Found!'));
                        break;
                    default:
                        reject(new Error('An error occurred reading this file.'));
                }
            }
            function loadHandler(evt) {
                fileContainer.progress = of(100);
                fileContainer.completed = true;
                fileContainer.data = evt.target.result;
                resolve();
            }
            // Attach handlers to reader events
            const reader = new FileReader();
            reader.onprogress = progressHandler;
            reader.onerror = errorHandler;
            reader.onload = loadHandler;
            // Start reading depending on reading type
            switch (this.readType) {
                case FileDragNDropReadType.ArrayBuffer:
                    reader.readAsArrayBuffer(fileContainer.file);
                    break;
                case FileDragNDropReadType.BinaryString:
                    reader.readAsBinaryString(fileContainer.file);
                    break;
                case FileDragNDropReadType.DataURL:
                    reader.readAsDataURL(fileContainer.file);
                    break;
                case FileDragNDropReadType.Text:
                    reader.readAsText(fileContainer.file);
                    break;
                default:
                    break;
            }
        });
    }
    /**
     * Delete file from files list
     * @param index (File index)
     */
    deleteFile(fileContainer) {
        if (fileContainer.progress && !fileContainer.completed) {
            throw new Error('Cannot remove file, loading in progress...');
        }
        this.fileContainerSet.delete(fileContainer);
        this.loadedFilesChanged.emit(this.fileContainerSet);
    }
    /**
     * Clear both files and loaded lists
     */
    clearFileList() {
        this.fileContainerSet.clear();
    }
    /**
     * Trigger fileInput click
     */
    onBrowseClick() {
        this.fileInput.nativeElement.click();
    }
    /**
     * Handle file(s) drop event
     */
    onFileDropped(fileList) {
        this.processFiles(fileList);
    }
    /**
     * Handle file from browsing event
     */
    onInputChanged(files) {
        this.processFiles(files);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FileDragNDropComponent.prototype, "multiple", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], FileDragNDropComponent.prototype, "accept", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FileDragNDropComponent.prototype, "readType", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], FileDragNDropComponent.prototype, "loadedFilesChanged", void 0);
__decorate([
    ViewChild('fileInput', { static: false }),
    __metadata("design:type", typeof (_f = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _f : Object)
], FileDragNDropComponent.prototype, "fileInput", void 0);
FileDragNDropComponent = __decorate([
    Component({
        selector: 'file-drag-n-drop',
        template: require('./file-drag-n-drop.component.html').default,
        styles: [require('./file-drag-n-drop.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _d : Object])
], FileDragNDropComponent);
export { FileDragNDropComponent };
