var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MedicService } from '../../../services/medic/medic.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
let SearchDciComponent = class SearchDciComponent {
    constructor(_medicService) {
        this._medicService = _medicService;
        this.onSelected = new EventEmitter();
        this.onInputChanged = new EventEmitter();
        this._search = (value) => {
            if (value != '') {
                return new Observable((observer) => {
                    this._medicService.findDciByName(value).subscribe((data) => {
                        observer.next(data);
                    });
                });
            }
            else {
                return of(null);
            }
        };
    }
    ngOnInit() {
        this.dcis = this.parentFormControl.valueChanges.pipe(startWith(''), debounceTime(500), switchMap(this._search));
    }
    inputChanged() {
        this.onInputChanged.emit({ dci: this.parentFormControl.value });
    }
    optionSelected(selected) {
        this.onSelected.emit(selected);
    }
    displayFn(dci) {
        return dci && dci.name ? dci.name : undefined;
    }
    //Called whenever the input change to for the panel to open
    //Original problem is that the panel wont open if the component
    //is placed in a mat-tab
    openPanel() {
        this.autoTrigger.openPanel();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_b = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _b : Object)
], SearchDciComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchDciComponent.prototype, "placeholder", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], SearchDciComponent.prototype, "onSelected", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchDciComponent.prototype, "onInputChanged", void 0);
__decorate([
    ViewChild('autoTrigger', { static: true }),
    __metadata("design:type", typeof (_e = typeof MatAutocompleteTrigger !== "undefined" && MatAutocompleteTrigger) === "function" ? _e : Object)
], SearchDciComponent.prototype, "autoTrigger", void 0);
SearchDciComponent = __decorate([
    Component({
        selector: 'search-dci',
        template: require('./search-dci.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MedicService !== "undefined" && MedicService) === "function" ? _a : Object])
], SearchDciComponent);
export { SearchDciComponent };
