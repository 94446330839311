import * as angular from 'angular';
import { MailsService } from './mails.service';
import { MpModalComposeMailController } from './mp-modal-compose-mail/mp-modal-compose-mail.controller';
import { MpModalComposeMailComponent } from './mp-modal-compose-mail/mp-modal-compose-mail.component';
import { HvMssMailValidatorDirective } from '../../directives/hv-mss-mail-validator/hv-mss-mail-validator';
import { MpModalActivityPathwayPickerComponent } from '@main/services/mails/mp-modal-activity-pathway-picker/mp-modal-activity-pathway-picker.component';
import { MpModalActivityPathwayPickerController } from '@main/services/mails/mp-modal-activity-pathway-picker/mp-modal-activity-pathway-picker.controller';
angular
    .module('mapui.services.mails', [])
    .run(runModalMail)
    .service('MailsService', MailsService)
    .controller('MpModalComposeMailController', MpModalComposeMailController)
    .component('mpModalComposeMail', new MpModalComposeMailComponent())
    .controller('MpModalActivityPathwayPickerController', MpModalActivityPathwayPickerController)
    .component('mpModalActivityPathwayPicker', new MpModalActivityPathwayPickerComponent())
    .directive('hvMailDomainValidator', HvMssMailValidatorDirective.factory());
function runModalMail($templateCache) {
    $templateCache.put('app/main/services/mails/mp-modal-compose-mail/views/secure-information-asking.html', require('./mp-modal-compose-mail/views/secure-information-asking.html').default);
    $templateCache.put('app/main/services/mails/mp-modal-compose-mail/views/unsecure-information-asking.html', require('./mp-modal-compose-mail/views/unsecure-information-asking.html').default);
    $templateCache.put('app/main/services/mails/mp-modal-compose-mail/views/octave/secure-information-asking.html', require('./mp-modal-compose-mail/views/octave/secure-information-asking.html').default);
    $templateCache.put('app/main/services/mails/mp-modal-compose-mail/views/octave/unsecure-information-asking.html', require('./mp-modal-compose-mail/views/octave/unsecure-information-asking.html').default);
    $templateCache.put('app/main/services/mails/mp-modal-compose-mail/views/share-reconciliation-sheet.html', require('./mp-modal-compose-mail/views/share-reconciliation-sheet.html').default);
    $templateCache.put('app/main/services/mails/mp-modal-compose-mail/views/unsecure-share-reconciliation-sheet.html', require('./mp-modal-compose-mail/views/unsecure-share-reconciliation-sheet.html').default);
}
