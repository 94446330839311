var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import * as moment from 'moment';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType, EPathways } from '@mapuilabs/mpl-interfaces';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { CMedRecProcess } from '@shared/constTypes/medication-reconciliation/medRec-process.const';
import { CMedRecStatus } from '@shared/constTypes/medication-reconciliation/medRec-status.const';
import { CMedRecTransit } from '@shared/constTypes/medication-reconciliation/medRec-transit.const';
import { CPathways } from '@shared/constTypes/pathways/pathways.const';
import { IdentityService } from '@services/identity/identity.service';
import { UrlService } from '@services/url/url.service';
let MainPatientsListSidebarComponent = class MainPatientsListSidebarComponent {
    constructor(_fuseTranslationLoaderService, _fuseSidebarService, _urlService, _identityService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseSidebarService = _fuseSidebarService;
        this._urlService = _urlService;
        this._identityService = _identityService;
        this.cMedRecStatus = CMedRecStatus;
        this.cMedRecProcess = CMedRecProcess;
        this.cMedRecTransit = CMedRecTransit;
        this.cPathways = CPathways;
        this.medRecLimitDateForm = new UntypedFormGroup({
            start: new UntypedFormControl(null),
            end: new UntypedFormControl(null)
        });
        /**
         * Set default filters when user reaches /admin/member/list.
         * @private
         */
        this._setDefaultFilters = () => {
            this.applyFilters = [
                {
                    attribute: 'medRec.isActive',
                    type: EFilterType.VALUE,
                    value: true
                }
            ];
            if (this.canFilterOCTAVEPatient) {
                this.applyFilters.push({
                    attribute: 'pathways',
                    type: EFilterType.VALUES,
                    inArray: true,
                    values: [EPathways.Octave, 'no-pathway']
                });
            }
            this.onApplyFilters(true);
        };
        /**
         * Sends the filters to the list view so that it can update.
         */
        this.onApplyFilters = (replaceHistory = false) => {
            this.filtersApplied.emit({
                filters: this.applyFilters,
                replaceHistory: replaceHistory
            });
        };
        /**
         * Returns if the checkbox should be checked.
         * @param checkboxName: String to find in the filters array (this.applyFilters).
         * @param value: Value to look for in the values array of a IFilterValues.
         */
        this.isChecked = (checkboxName, value) => {
            if (this.applyFilters) {
                const index = this.applyFilters.findIndex((filter) => filter.attribute === checkboxName);
                if (index > -1) {
                    switch (this.applyFilters[index].type) {
                        case EFilterType.VALUE:
                            return true;
                        case EFilterType.VALUES:
                        case EFilterType.TYPE:
                            if (value !== undefined) {
                                // value can be null
                                return this.applyFilters[index].values.includes(value);
                            }
                    }
                }
            }
            return false;
        };
        /**
         * Removes a IFilter matching a given name from an array. Does nothing if the
         * filter could not be found.
         * @param filterName: String matching with a key in the source array.
         */
        this._removeFilter = (filterName) => {
            const index = this.applyFilters.findIndex((filter) => filter.attribute === filterName);
            if (index > -1) {
                this.applyFilters.splice(index, 1);
            }
        };
        /**
         * Saves or removes a IFilterType from the filters array, which attribute is
         * given in MatCheckbox (aka MatCheckboxChange.source) name.
         * @param checkboxChange: Event on the checkboxes used for type filters.
         */
        this.onFilterTypeChecked = (checkboxChange) => {
            const checkboxName = checkboxChange.source.name.toString();
            const checkboxValue = checkboxChange.source.value;
            const isChecked = checkboxChange.checked;
            if (!this.applyFilters) {
                this.applyFilters = [];
            }
            const filterIndex = this.applyFilters.findIndex((filter) => filter.attribute === checkboxName);
            if (isChecked) {
                if (filterIndex > -1) {
                    // Filter already exists: adds the value to the IFilterType.values array
                    this.applyFilters[filterIndex].values.push(checkboxValue);
                }
                else {
                    // Create a IFilterType to add in the filter array
                    const applyFilter = {
                        type: EFilterType.TYPE,
                        attribute: checkboxName,
                        values: [checkboxValue]
                    };
                    this.applyFilters.push(applyFilter);
                }
            }
            else {
                if (filterIndex > -1) {
                    // Double checking filter existence
                    const filterValues = this.applyFilters[filterIndex].values;
                    if (filterValues.length > 1) {
                        // Remove value from the array
                        filterValues.splice(filterValues.findIndex((value) => value === checkboxValue), 1);
                    }
                    else {
                        // If only one value, remove the whole filter from applyFilters array
                        this.applyFilters.splice(filterIndex, 1);
                    }
                }
                else {
                    throw Error(`Filter ${checkboxName} missing. Could not remove it from the list of filter to apply.`);
                }
            }
        };
        this.onFilterValueSlided = (slideToggleChange) => {
            const toggleName = slideToggleChange.source.name.toString();
            const isSlided = slideToggleChange.checked;
            if (!this.applyFilters) {
                this.applyFilters = [];
            }
            const filterIndex = this.applyFilters.findIndex((filter) => filter.attribute === toggleName);
            if (filterIndex > -1) {
                if (isSlided) {
                    this.applyFilters[filterIndex].value = isSlided;
                }
                else if (!isSlided) {
                    this.applyFilters.splice(filterIndex, 1);
                }
            }
            else {
                const applyFilter = {
                    type: EFilterType.VALUE,
                    attribute: toggleName,
                    value: isSlided
                };
                this.applyFilters.push(applyFilter);
            }
        };
        /**
         * Saves or removes a IFilterValues from the filters array, which attribute is
         * given in MatCheckbox (aka MatCheckboxChange.source) name.
         * @param checkboxChange: Event on the checkboxes used for unique value filters.
         */
        this.onFilterValuesChecked = (checkboxChange) => {
            const checkboxName = checkboxChange.source.name.toString();
            const checkboxValue = checkboxChange.source.value;
            const isChecked = checkboxChange.checked;
            if (!this.applyFilters) {
                this.applyFilters = [];
            }
            const filterIndex = this.applyFilters.findIndex((filter) => filter.attribute === checkboxName);
            if (isChecked) {
                if (filterIndex > -1) {
                    // Filter already exists: adds the value to the IFilterValues.values array
                    this.applyFilters[filterIndex].values.push(checkboxValue);
                }
                else {
                    // Create a IFilterValues to add in the filter array
                    this.applyFilters.push({
                        type: EFilterType.VALUES,
                        attribute: checkboxName,
                        inArray: true,
                        values: [checkboxValue]
                    });
                }
            }
            else {
                if (filterIndex > -1) {
                    // Double checking filter existence
                    const filterValues = this.applyFilters[filterIndex].values;
                    if (filterValues.length > 1) {
                        // Remove value from the array
                        filterValues.splice(filterValues.findIndex((value) => value === checkboxValue), 1);
                    }
                    else {
                        // If only one value, remove the whole filter from applyFilters array
                        this.applyFilters.splice(filterIndex, 1);
                    }
                }
                else {
                    throw Error(`Filter ${checkboxName} missing. Could not remove it from the list of filter to apply.`);
                }
            }
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.filtersApplied = new EventEmitter();
    }
    ngAfterViewInit() {
        this._setDefaultFilters();
    }
    get canFilterOCTAVEPatient() {
        return this._identityService.isBothOctaveAndPremium;
    }
    /**
     * Update applyFilters when date inputs change
     */
    onDateFilterChange() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!this.applyFilters) {
            this.applyFilters = [];
        }
        const filterIdx = this.applyFilters.findIndex((filter) => filter.attribute === 'medRec.date' && filter.type === EFilterType.RANGE);
        if (filterIdx > -1) {
            (this.applyFilters[filterIdx].start = ((_a = this.medRecLimitDateForm.controls['start']) === null || _a === void 0 ? void 0 : _a.value)
                ? moment((_b = this.medRecLimitDateForm.controls['start']) === null || _b === void 0 ? void 0 : _b.value).toDate()
                : null),
                (this.applyFilters[filterIdx].end = ((_c = this.medRecLimitDateForm.controls['end']) === null || _c === void 0 ? void 0 : _c.value)
                    ? moment((_d = this.medRecLimitDateForm.controls['end']) === null || _d === void 0 ? void 0 : _d.value).toDate()
                    : null);
        }
        else {
            const applyFilter = {
                type: EFilterType.RANGE,
                attribute: 'medRec.date',
                start: ((_e = this.medRecLimitDateForm.controls['start']) === null || _e === void 0 ? void 0 : _e.value)
                    ? moment((_f = this.medRecLimitDateForm.controls['start']) === null || _f === void 0 ? void 0 : _f.value).toDate()
                    : null,
                end: ((_g = this.medRecLimitDateForm.controls['end']) === null || _g === void 0 ? void 0 : _g.value)
                    ? moment((_h = this.medRecLimitDateForm.controls['end']) === null || _h === void 0 ? void 0 : _h.value).toDate()
                    : null
            };
            this.applyFilters.push(applyFilter);
        }
    }
    /**
     * Initialize medRecLimitDateForm and check
     * @private
     */
    _setDateLimitFormControls() {
        const filterIdx = this.applyFilters.findIndex((filter) => filter.attribute === 'medRec.date' && filter.type === EFilterType.RANGE);
        if (filterIdx > -1) {
            this.medRecLimitDateForm['start'].setValue(this.applyFilters[filterIdx].start);
            this.medRecLimitDateForm['end'].setValue(this.applyFilters[filterIdx].end);
        }
    }
};
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], MainPatientsListSidebarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Array !== "undefined" && Array) === "function" ? _f : Object)
], MainPatientsListSidebarComponent.prototype, "applyFilters", void 0);
MainPatientsListSidebarComponent = __decorate([
    Component({
        selector: 'hvx-main-patients-list-sidebar',
        template: require('./main-patients-list-sidebar.component.html').default,
        styles: [require('./main-patients-list-sidebar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _b : Object, typeof (_c = typeof UrlService !== "undefined" && UrlService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object])
], MainPatientsListSidebarComponent);
export { MainPatientsListSidebarComponent };
