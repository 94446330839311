export class MpKeyEvents {
    /** @ngInject */
    constructor($document) {
        this.$document = $document;
        this.link = (scope, elem, attrs) => {
            let onKeydownCallback = scope.$eval(attrs.onKeydown);
            let onKeyUpCallback = scope.$eval(attrs.onKeyup);
            document.body.className += ' unselectable';
            if (onKeydownCallback) {
                this.$document.on('keydown', function (e) {
                    onKeydownCallback(e.which);
                });
            }
            if (onKeyUpCallback) {
                this.$document.on('keyup', function (e) {
                    onKeyUpCallback(e.which);
                });
            }
            scope.$on('$destroy', () => {
                this.$document.off('keydown');
                this.$document.off('keyup');
                document.body.classList.remove('unselectable');
            });
        };
        this.restrict = 'E';
    }
    static factory() {
        const directive = ($document) => new MpKeyEvents($document);
        directive.$inject = ['$document'];
        return directive;
    }
}
