export const locale = {
    lang: `en`,
    data: {
        ARCHIVES: {
            TITLE: 'Archives',
            SEARCH: 'Search',
            TO_ARCHIVE: 'To archive',
            LOAN: 'Loan',
            BORROWING: 'Borrowing',
            ARCHIVES: 'Archives',
            ARCHIVE: 'Archive',
            ARCHIVE_MODAL: {
                CONFIRM: {
                    TITLE: 'Confirmation',
                    ARCHIVE_ITEM: 'Archive selected loan.',
                    ARCHIVE_ITEMS: 'Archive selected loans.'
                }
            },
            DELETE_MODAL: {
                DELETE: 'Delete this exchange ?',
                REMOVE_AVAILABLE: 'Remove this available product ?',
                SUCCESS: 'Exchange deleted'
            },
            HEADERS: {
                TYPE: 'Type',
                SELECTION: 'Selection',
                MODIFIED: 'Date',
                NAME: 'Name',
                HOSPITAL: 'Hospital',
                STATUS: 'Status',
                QUANTITY: 'Quantity',
                PRICE: 'Price',
                ACTIONS: 'Actions',
                LABS: 'Labs (own | exp)',
                EXPIRY_DATE: 'Expiry date',
                GALENIC: 'Galenic formulation',
                TRANSPORT_TYPE: 'Transport mode'
            }
        },
        INFO_COVID: 'This is a collaborative tool between health facilities. It is not related to the COVID-19 module and RHAs.',
        SERVICES: {
            LOAN: {
                SUMMARY: {
                    HEADERS: {
                        GENERAL: 'General information',
                        COMMENTS: 'Comments',
                        STAFF: 'Staff',
                        VOUCHERS: 'Vouchers and invoice'
                    },
                    CONTENT: {
                        TYPES: {
                            LABEL: 'Type',
                            LOAN: 'Loan',
                            BORROW: 'Borrow',
                            REQUEST: 'Request',
                            AVAILABLE: 'Available'
                        },
                        LABORATORY: {
                            LABEL: 'Laboratory'
                        },
                        STAFF: {
                            LENDING: 'Lending person',
                            BORROWING: 'Borrowing person',
                            VALID: 'Validator'
                        },
                        CATEGORIES: {
                            LABEL: 'Category',
                            DRUG: 'Drug',
                            MD: 'Medical device',
                            PREP: 'Preparation'
                        },
                        COMMENTS: {
                            LENDER: 'Lender comment',
                            BORROWER: 'Borrower comment',
                            REQUEST: 'Request comment',
                            BILL: 'Bill comment',
                            RETURN: 'Return comment',
                            VALID: 'Validation comment',
                            RECEIPT: 'Receipt comment',
                            NO_COMMENTS: 'No comments'
                        },
                        UPDATE: {
                            TITLE: 'Last update',
                            LABEL: '{{date}} at {{time}} by {{who}}'
                        },
                        DATES: {
                            CREATION: 'Created {{date}} at {{time}}',
                            EXPIRY: 'Expiry date'
                        },
                        RETURN_TYPE: {
                            SIMPLE: 'Returned {{date}} at {{time}}',
                            BILLED: 'Billed {{date}} at {{time}}'
                        },
                        QUANTITY: 'Quantity',
                        LOT_NUMBER: 'Lot number',
                        OPERATING_ROOM: 'Operating room'
                    },
                    ACTIONS: {
                        DOWNLOAD: {
                            VOUCHER: {
                                LOAN: 'Loan voucher',
                                BORROWING: 'Borrowing voucher',
                                RETURN: 'Return voucher'
                            },
                            INVOICE: 'Invoice'
                        }
                    }
                }
            },
            MEMBER: {
                EMAIL: 'Email',
                PHONE: 'Phone',
                ACTIVITIES: 'Activities'
            }
        }
    }
};
