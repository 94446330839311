import { EMpStatus } from '@mapuilabs/mpl-interfaces';
/** @ngInject */
export class MpForgotPasswordController {
    /** @ngInject */
    constructor(Auth, ToastService) {
        this.Auth = Auth;
        this.ToastService = ToastService;
        this.changePassword = () => {
            this.Auth.forgotPassword({ email: this.email }, () => {
                this.success = true;
            }, (err) => {
                if (err.data == 'User not validated') {
                    this.errType = 1;
                    this.ToastService.show(EMpStatus.NoValidation);
                }
                else {
                    this.errType = 0;
                    this.ToastService.show(EMpStatus.RejectByServer);
                }
                this.excludedEmails.push(this.email);
            });
        };
        this.success = false;
        this.excludedEmails = [];
        this.errType = 0;
    }
    ;
}
