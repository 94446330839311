export const locale = {
    lang: 'fr',
    data: {
        STATS_HOSPIVILLE: {
            HEADER: {
                TITLE: 'Statistiques Hospiville - Activités',
                START_DATE: 'Date de début',
                END_DATE: 'Date de fin'
            },
            HOSPITALS_TABLE: {
                SEARCH: 'Rechercher',
                HOSPITAL: 'Hôpital',
                QUANTITY: 'Quantité',
                LAST_ACTIVITY: 'Dernière'
            },
            WIDGET_TOTAL_ACTIVITIES: {
                TITLE: "Total d'activités"
            },
            WIDGET_PERIOD_ACTIVITIES: {
                TITLE: 'Activités sur la période'
            },
            GRAPH_CREATION: {
                TITLE: "Évolution des créations d'activités",
                CURVES: {
                    TOTAL: 'Total'
                },
                Y_AXIS: 'Nombre d`activités'
            },
            GRAPH_TIME: {
                TITLE: 'Durée moyenne des activités (en moyenne : {{ average }} minutes)',
                CURVES: {
                    ACTIVITIES: 'Activités',
                    DRUG_BAL: 'Bilans médicamenteux'
                },
                Y_AXIS: 'Durée des activités (en minutes)'
            }
        }
    }
};
