var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestModule } from './request/request.module';
import { ExchangeRoutingModule } from './exchange.routing';
import { ExchangeMapModule } from './map/map.module';
import { AvailableModule } from './available/available.module';
import { ArchivesModule } from './archives/archives.module';
import { BillingModule } from './billing/billing.module';
let ExchangeModule = class ExchangeModule {
};
ExchangeModule = __decorate([
    NgModule({
        imports: [CommonModule, RequestModule, ExchangeMapModule, AvailableModule, ArchivesModule, BillingModule],
        exports: [ExchangeRoutingModule]
    })
], ExchangeModule);
export { ExchangeModule };
