import { UnlockVaultController } from './unlock-vault.controller';
import * as angular from 'angular';
const config = ($stateProvider) => {
    $stateProvider.state('mapui.pages_auth_unlock-vault', {
        url: '/auth/unlock-vault?:redirectURL',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_auth_unlock-vault': {
                controller: 'UnlockVaultController as vm',
            },
        },
    });
};
angular
    .module('mapui.services.auth.unlock-vault', [])
    .config(config)
    .controller('UnlockVaultController', UnlockVaultController);
