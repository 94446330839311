var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b;
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { TranslationService } from '@services/translation/translation.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHsHealthProductTypes, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
import * as moment from 'moment';
let HsModalPublicationStatisticSelectedAlternativeComponent = class HsModalPublicationStatisticSelectedAlternativeComponent {
    constructor(input, _fuseTranslationLoaderService, _translationService) {
        this.input = input;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
        this.translationBase = `PUBLICATION.LABO.${this.input.healthProductType}.STATISTIC.SELECTED_ALTERNATIVE_TABLE`;
        this.title = `${this.translationBase}.TITLE`;
        // Table
        this.dataSource = new MatTableDataSource(input.selectedAlternatives);
        const concatRef = (healthProducts) => { var _a; return (_a = healthProducts === null || healthProducts === void 0 ? void 0 : healthProducts.map(({ ref }) => ref)) === null || _a === void 0 ? void 0 : _a.join(' ; '); };
        const formatDate = (date) => (date ? moment(date).format('ll') : null);
        this.columns = [
            {
                label: this._translate(`${this.translationBase}.TABLE.HEALTH_PRODUCT_REF_NAME`),
                attribute: 'alternative.healthProductRef.name'
            },
            this.input.healthProductType === EHsHealthProductTypes.DRUG
                ? {
                    label: this._translate(`${this.translationBase}.TABLE.HEALTH_PRODUCT_REF_REF`),
                    attribute: 'alternative.healthProductRef.ref'
                }
                : null,
            {
                label: this._translate(`${this.translationBase}.TABLE.HEALTH_PRODUCT_REF_HEALTH_PRODUCTS_REF`),
                transform: concatRef,
                attribute: 'alternative.healthProductRef.healthProducts'
            },
            {
                label: this._translate(`${this.translationBase}.TABLE.OPERATOR`),
                attribute: 'alternative.operator'
            },
            {
                label: this._translate(`${this.translationBase}.TABLE.SOURCE`),
                template: 'creatorTemplate'
            },
            {
                label: this._translate(`${this.translationBase}.TABLE.SELECTED_DATE`),
                transform: formatDate,
                attribute: 'date'
            },
            {
                label: this._translate(`${this.translationBase}.TABLE.PHARMACY_SELECTED_ALTERNATIVE`),
                attribute: 'hospital.name'
            }
        ];
        this.columns = this.columns.filter((column) => column !== null);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    formatSource(alternative) {
        return alternative.creatorType === EHsUserTypes.GA
            ? alternative.creatorGroup.name
            : alternative.creator.name;
    }
};
HsModalPublicationStatisticSelectedAlternativeComponent = __decorate([
    Component({
        selector: 'mpx-hs-modal-publication-statistic-selected-alternative',
        template: require('./hs-modal-publication-statistic-selected-alternative.component.html').default,
        animations: fuseAnimations,
        providers: [HsHealthProductService]
    }),
    __param(0, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [Object, typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], HsModalPublicationStatisticSelectedAlternativeComponent);
export { HsModalPublicationStatisticSelectedAlternativeComponent };
