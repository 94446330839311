var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdminPurchaseGroupsRoutingModule } from './purchase-groups-routing.module';
import { AdminListPurchaseGroupsComponent } from './list-purchase-groups/list-purchase-groups.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminDetailPurchaseGroupsComponent } from './detail-purchase-groups/detail-purchase-groups.component';
import { SharedModule } from '@shared/shared.module';
import { InformationTabComponent } from './detail-purchase-groups/information-tab/information-tab.component';
import { MembersTabComponent } from './detail-purchase-groups/members-tab/members-tab.component';
import { ActionsTabComponent } from './detail-purchase-groups/actions-tab/actions-tab.component';
import { FuseConfirmDialogModule, FuseSidebarModule } from '@fuse/components';
let AdminPurchaseGroupsModule = class AdminPurchaseGroupsModule {
};
AdminPurchaseGroupsModule = __decorate([
    NgModule({
        declarations: [
            AdminListPurchaseGroupsComponent,
            AdminDetailPurchaseGroupsComponent,
            InformationTabComponent,
            MembersTabComponent,
            ActionsTabComponent
        ],
        imports: [
            CommonModule,
            UIRouterModule,
            BrowserAnimationsModule,
            AdminPurchaseGroupsRoutingModule,
            FuseSharedModule,
            FuseConfirmDialogModule,
            FuseSidebarModule,
            SharedModule,
            MatTableModule,
            MatButtonModule,
            MatSortModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatPaginatorModule,
            MatIconModule,
            MatTabsModule,
            MatCheckboxModule,
            MatInputModule,
            MatMenuModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [AdminPurchaseGroupsRoutingModule],
        bootstrap: [AdminListPurchaseGroupsComponent, AdminDetailPurchaseGroupsComponent]
    })
], AdminPurchaseGroupsModule);
export { AdminPurchaseGroupsModule };
