import { CActivityPathways } from '@main/services/mails/mails.service';
import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MpModalActivityPathwayPickerController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        this.CActivityPathways = CActivityPathways;
        /**
         * Close the modal with actions performing
         * @param answer
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
        /**
         * Close the modal without actions performing
         */
        this.closeDialog = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
    }
    /**
     * Close the modal with actions performing
     */
    choose(selectedPathway) {
        this._close(selectedPathway);
    }
    ;
}
