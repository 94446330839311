var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { ExchangeRequestService } from '@services/exchange/request.service';
import { ExchangeStatusesService } from '@services/exchange/statuses/exchange-statuses.service';
import { CLoanStatusIndicators } from '@shared/constTypes/loans/loan-status.const';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import * as moment from 'moment';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let RequestListComponent = class RequestListComponent extends ListPage {
    constructor(_dashboardService, _exchangeRequestService, _exchangeStatusService, _fuseTranslationLoaderService, _injector) {
        super(_injector, _exchangeRequestService.getPage);
        this._dashboardService = _dashboardService;
        this._exchangeRequestService = _exchangeRequestService;
        this._exchangeStatusService = _exchangeStatusService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._injector = _injector;
        this.cLoanStatusIndicators = CLoanStatusIndicators;
        this.tooltipForIcon = {
            preparation: 'REQUESTS_LIST.TOOLTIPS.PREPARATION',
            pill: 'REQUESTS_LIST.TOOLTIPS.PILL',
            'medical-device': 'REQUESTS_LIST.TOOLTIPS.MEDICAL_DEVICE'
        };
        // Emits when the tooltip on the save button of the parent component should be toggled;
        this.showIconTooltip = new EventEmitter();
        // -----------------------------------------------------------------------------------------------------
        // @ View methods
        // -----------------------------------------------------------------------------------------------------
        /**
         * Find css class for the status of the given loan.
         * @param loan
         */
        this.getStatusCSSClass = (loan) => {
            const statusName = this._exchangeStatusService.getStatusName(loan);
            return this.cLoanStatusIndicators.find((status) => status.name === statusName).cssClass;
        };
        /**
         * Find translation for the status of the given loan.
         * @param loan
         */
        this.getStatusTranslation = (loan) => {
            const statusName = this._exchangeStatusService.getStatusName(loan);
            return this.cLoanStatusIndicators.find((status) => status.name === statusName).translate;
        };
        this.getIcon = (loan) => {
            return this._exchangeStatusService.getIcon(loan);
        };
        this.openRequestLoanUpdateModal = (event, loan) => {
            this._dashboardService.openRequestLoanUpdateModal(event, loan).subscribe((res) => {
                if (typeof res === 'number') {
                    if (res !== 1) {
                        this._snackbarService.openErrorMpStatus(res);
                    }
                }
                else {
                    this._snackbarService.open(this._translationService.instant('REQUESTS_LIST.SNACKBAR.EDIT_OK'));
                }
                this._reload.emit();
            });
        };
        this.getIconTooltip = (loan) => {
            return this._translationService.instant(this.tooltipForIcon[this.getIcon(loan)]);
        };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('REQUESTS_LIST.TITLE'),
            icon: 'announcement'
        };
        const formatDate = (date) => (date ? moment(date).format('ll') : null);
        this.columns = [
            {
                label: this._translate('REQUESTS_LIST.TABLE.TYPE'),
                template: 'typeTemplate',
                classes: ['max-w-72']
            },
            {
                label: this._translate('REQUESTS_LIST.TABLE.CREATION_DATE'),
                attribute: 'creationDate',
                transform: formatDate,
                classes: ['max-w-140'],
                sort: true
            },
            {
                label: this._translate('REQUESTS_LIST.TABLE.MEDIC_NAME'),
                attribute: 'medicName',
                sort: true
            },
            {
                label: this._translate('REQUESTS_LIST.TABLE.HOSPITAL_NAME'),
                attribute: 'borrower.finessCode',
                sort: true
            },
            {
                label: this._translate('REQUESTS_LIST.TABLE.QUANTITY'),
                attribute: 'quantity',
                sort: true
            },
            {
                label: this._translate('REQUESTS_LIST.TABLE.STATUS'),
                template: 'statusTemplate'
            }
        ];
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_f = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _f : Object)
], RequestListComponent.prototype, "_paginator", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], RequestListComponent.prototype, "showIconTooltip", void 0);
RequestListComponent = __decorate([
    Component({
        selector: 'request-list',
        template: require('./request-list.component.html').default,
        styles: [require('../../../status-colors.scss'), require('./request-list.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DashboardService !== "undefined" && DashboardService) === "function" ? _a : Object, typeof (_b = typeof ExchangeRequestService !== "undefined" && ExchangeRequestService) === "function" ? _b : Object, typeof (_c = typeof ExchangeStatusesService !== "undefined" && ExchangeStatusesService) === "function" ? _c : Object, typeof (_d = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _d : Object, typeof (_e = typeof Injector !== "undefined" && Injector) === "function" ? _e : Object])
], RequestListComponent);
export { RequestListComponent };
