/** @ngInject */
export class MpSearchEstablishmentTypeController {
    /** @ngInject */
    constructor(EstablishmentService) {
        this.EstablishmentService = EstablishmentService;
        /**
         * Search all [[IEstablishment]] occurrence from the searchText
         * @returns {angular.IPromise<Array<IEstablishment>>}
         */
        this.searchEstablishment = () => {
            return this.EstablishmentService.findByType(this.ctrl.searchText, this.type);
        };
        /**
         * An [[IEstablishment]] is selected and returned
         */
        this.searchEstablishmentSelected = () => {
            if (this.ctrl.selected) {
                this.onSelected({
                    $event: { establishment: this.ctrl.selected },
                });
            }
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
    }
    $onChanges(changes) {
        if (changes.establishment) {
            this.ctrl.searchText = (this.establishment) ? this.establishment.name : '';
        }
    }
}
