export const locale = {
    lang: 'fr',
    data: {
        GROUPS: {
            TITLE: 'Groupes',
            SEARCH: 'Rechercher un groupe',
            CREATE_NEW: 'Créer un nouveau groupe',
            TYPE: 'Types de groupe',
            STRUCTURE: {
                HOSPITAL: 'Hôpitaux',
                MEMBER: 'Personnes'
            },
            TABLE: {
                NAME: 'Nom',
                TYPE: 'Type',
                ACTION: 'Action',
                GROUP_STRUCTURE: 'Type de membres'
            }
        },
        GROUP_DETAIL: {
            EDIT_TITLE: `Groupe - `,
            NEW_TITLE: `Nouveau groupe`,
            TABS: {
                INFORMATION: `Informations`,
                MEMBERS: `Membres`
            },
            EDIT_OK: `Le groupe a été modifié !`,
            CREATE_OK: `Le groupe a été créé !`,
            INFORMATION: {
                TITLES: {
                    NAME: 'Nom',
                    TYPE: 'Type',
                    GROUP_STRUCTURE: 'Type de membres',
                    LIST_ADMIN: 'Liste des administrateurs'
                },
                ADMINS: {
                    TABLE: {
                        NAME: 'Nom',
                        HOSPITAL: 'Hôpital',
                        EMAIL: 'Email',
                        ACTION: 'Action'
                    }
                }
            },
            MEMBERS: {
                EMPTY: `Ce groupe n'a aucun membre.`
            },
            MESSAGES: {
                SNACKBAR: {
                    DELETE_USER_OK: `Ce membre a été retiré !`,
                    DELETE_ADMIN_OK: `Cet administrateur a été retiré !`,
                    ADD_USER_OK: `Ce membre a été ajouté !`,
                    DELETE_GROUP_OK: `Ce membre a été supprimé !`,
                    ADD_ADMIN_OK: `Cet administrateur a été ajouté !`
                },
                DELETE_MEMBER: {
                    TITLE: `Confirmer la suppression`,
                    MESSAGE: `Voulez-vous vraiment retirer ce membre du groupe ?`
                },
                DELETE_GROUP: {
                    TITLE: `Confirmer la suppression`,
                    MESSAGE: `Voulez-vous vraiment supprimer ce groupe ?`,
                    BUTTON: {
                        LONG: 'Supprimer le groupe',
                        SHORT: 'Supprimer'
                    }
                }
            },
            HOSPISTOCK: {
                ALTERNATIVES: {
                    TITLE: 'Alternatives',
                    MODERATOR: 'Modération des alternatives des laboratoires'
                }
            }
        }
    }
};
