var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { AdminMemberService } from '@services/admin/member/admin-member.service';
let SearchMemberComponent = class SearchMemberComponent {
    constructor(_adminMemberService, _fuseTranslationLoaderService) {
        this._adminMemberService = _adminMemberService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        /**
         * Emits when an establishment is selected.
         */
        this.onSelected = new EventEmitter();
        this.members = null;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this.members = this.parentFormControl.valueChanges.pipe(startWith(''), debounceTime(500), switchMap((value) => {
            return this.search(value);
        }));
    }
    search(value) {
        // console.log(value);
        if (value && value !== '') {
            return new Observable((observer) => {
                this._adminMemberService.getMembersByNameOrEmail(value).subscribe((data) => {
                    const filteredData = data.items.filter((elem) => {
                        return (!this.exclude ||
                            !this.exclude.find((ex) => ex._id.toString() == elem._id.toString()));
                    });
                    observer.next(filteredData);
                });
            });
        }
        else {
            return of(null);
        }
    }
    inputChanged() {
        this.onSelected.emit(null);
    }
    optionSelected(selected) {
        this.onSelected.emit(selected);
    }
    displayFn(member) {
        if (member) {
            return member.fullName || member.email;
        }
    }
    reset() {
        this.parentFormControl.reset();
        this.onSelected.emit(null);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _c : Object)
], SearchMemberComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], SearchMemberComponent.prototype, "exclude", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchMemberComponent.prototype, "onSelected", void 0);
SearchMemberComponent = __decorate([
    Component({
        selector: 'mpx-search-member',
        template: require('./search-member.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchMemberComponent);
export { SearchMemberComponent };
