import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class MedicService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get laboratory by code
         * @param {string} code
         * @return {angular.IPromise<any>}
         */
        this.getLab = (code) => {
            const defer = this.$q.defer();
            this.Api.medic.getLab.get({ code: code }, ans => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            return defer.promise;
        };
        /**
         * Return an array containing all matches from value
         * @param value A string with 3 at least characters
         * @returns A promise containing an Array of string
         */
        this.findByName = (value) => {
            const defer = this.$q.defer();
            this.Api.medic.byName.get({ name: encodeURI(value) }, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            return defer.promise;
        };
    }
}
