import { EMpStatus, EPosologyType, EStatusIndicator, } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class HvDrugBalanceSheetSynthesisController {
    /** @ngInject */
    constructor(Api, _, ToastService, Access, DrugInstanceService, HospivilleService, $translate, ModalService, $mdBottomSheet, drugBalance) {
        this.Api = Api;
        this._ = _;
        this.ToastService = ToastService;
        this.Access = Access;
        this.DrugInstanceService = DrugInstanceService;
        this.HospivilleService = HospivilleService;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.$mdBottomSheet = $mdBottomSheet;
        this.drugBalance = drugBalance;
        /**
         * All posology types
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * Get [[IDrugBalanceSource]] name translation
         * @param srcId
         * @returns {string}
         */
        this.getSourceNameById = (srcId) => {
            if (srcId === 'concordance') {
                return 'HOSPIVILLE.DRUG_BS.SYNTHESIS.CONCORDANCE';
            }
            let source = this._.find(this.drugBalance.sources, function (s) {
                return Utils.compareIds(s.source, srcId);
            });
            if (!this._constTypes || !source || Utils.isNaN(Number(source.source.type)))
                return;
            return this._.find(this._constTypes.sources, { value: source.source.type }).translate;
        };
        /**
         * Get the translation of a given posology
         * @param posology
         * @returns {string}
         */
        this.getPosology = (posology) => {
            if (!this._constTypes || (!posology && posology !== 0))
                return;
            return this._.find(this._constTypes.posology, { value: posology }).translate;
        };
        /**
         * get the sentence for a specific posology
         * @param {ITiming} posology
         */
        this.getPosologySentence = (posology) => {
            return this.DrugInstanceService.generatePosologySentence(posology);
        };
        /**
         * Get the translation of a given dosage type
         * @param dosageType
         * @returns {string}
         */
        this.getDosageType = (dosageType) => {
            if (!this._constTypes || (!dosageType && dosageType !== 0))
                return;
            return this._.find(this._constTypes.dosages, { value: dosageType }).translate;
        };
        /**
         * Export drug balance synthesis
         */
        this.exportDBSSynthesis = () => {
            var _a, _b, _c;
            if (this.drugBalance.medicationReconciliation.transit == null) {
                this.ModalService.show({
                    component: 'hv-modal-select-reconciliation-type',
                    bindings: {
                        medRec: this.drugBalance.medicationReconciliation,
                    },
                    escapeToClose: false,
                }).then((updatedMedRec) => {
                    this.HospivilleService.saveMedRec(updatedMedRec, false, true).then((medRec) => {
                        var _a, _b, _c;
                        this.drugBalance.medicationReconciliation = medRec;
                        let query = `dbId=${Utils.getId(this.drugBalance)}&pname=${(_a = this.patient) === null || _a === void 0 ? void 0 : _a.firstName}-${(_b = this.patient) === null || _b === void 0 ? void 0 : _b.familyName}&pId=${Utils.getId(this.patient)}&pdate=${(_c = this.patient) === null || _c === void 0 ? void 0 : _c.birthDate}&uname=${this.patient.usualName}
                    &id=${Utils.getId(this.drugBalance.medicationReconciliation)}`;
                        this.HospivilleService.exportSynthesisSheet(query, 'dbs').then(() => {
                            this.ToastService.show(EMpStatus.Ok);
                        });
                    }).catch((err) => {
                        console.error(err);
                    });
                });
            }
            else {
                let query = `dbId=${Utils.getId(this.drugBalance)}&pname=${(_a = this.patient) === null || _a === void 0 ? void 0 : _a.firstName}-${(_b = this.patient) === null || _b === void 0 ? void 0 : _b.familyName}&pId=${Utils.getId(this.patient)}&pdate=${(_c = this.patient) === null || _c === void 0 ? void 0 : _c.birthDate}&uname=${this.patient.usualName}
        &id=${Utils.getId(this.drugBalance.medicationReconciliation)}`;
                this.HospivilleService.exportSynthesisSheet(query, 'dbs').then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                });
            }
        };
        /**
         * Check if interface allows reconciliation sheet export
         */
        this.canExport = () => {
            return this.Access.isInterfaced();
        };
        /**
         * Check medRec initiator
         */
        this.checkInitiator = () => {
            if (this.drugBalance)
                return !!this.drugBalance.medicationReconciliation.initiator;
        };
        /**
         * Save [[IDrugBalance]]
         */
        this.save = () => {
            this.$mdBottomSheet.hide(this.drugBalance);
        };
        /**
         * Get the translation of a given value
         * @param from
         * @param value
         * @returns {string}
         */
        this.getTranslation = (from, value) => {
            if (!this._constTypes || (!value && value !== 0))
                return;
            return this._.find(this._constTypes[from], { value: value }).translate;
        };
        /**
         * Get the current selected language
         * @returns {string} the current language
         */
        this._getLang = () => {
            return this.$translate.use();
        };
        this.diService = this.DrugInstanceService;
        this.HospivilleService.getPatient().then((patient) => this.patient = patient);
        this.Api.hvConstType.get((ans) => {
            this._constTypes = ans;
        });
        // this.HospivilleService.getDrugBalanceSheet()
        //     .then((dbs: IDrugBalance) => this.drugBalance = angular.copy(dbs))
        //     .catch(err => console.error(err));
    }
    $onInit() {
        this.migrationState = this.Access.isHVMigrationState();
    }
    isDrugBalanceValidate() {
        if (!this.drugBalance) {
            return false;
        }
        return this.drugBalance.statusIndicator === EStatusIndicator.Validate;
    }
}
