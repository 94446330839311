var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component, Injector, ViewChild } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { StateService, Transition } from '@uirouter/angular';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { NavBarService } from '@app/services/nav-bar-subject/nav-bar-subject.service';
import { EAvailableStatus, EHTTPStatus } from '@mapuilabs/mpl-interfaces';
import { CAvailableHospitalSort, CAvailableSortStates } from '@shared/constTypes/availables/available-sort.const';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { Subject } from 'rxjs';
import { locale as globalEnglish } from '../../../../i18n/en';
import { locale as globalFrench } from '../../../../i18n/fr';
import { locale as english } from '../../i18n/en';
import { locale as french } from '../../i18n/fr';
import { AvailableHospitalService } from '../available-hospital.service';
import { CantCreateModalComponent } from './cant-create-modal/cant-create-modal.component';
import { EditAvailableComponent } from './edit-available/edit-available.component';
let AvailableHospitalListComponent = class AvailableHospitalListComponent extends ListPage {
    constructor(_availableHospitalService, _injector, _fuseTranslationLoaderService, _matDialog, _navBarService, _stateService, _transition) {
        super(_injector, _availableHospitalService.getPageAvailableOffer);
        this._availableHospitalService = _availableHospitalService;
        this._injector = _injector;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._matDialog = _matDialog;
        this._navBarService = _navBarService;
        this._stateService = _stateService;
        this._transition = _transition;
        this.sorts = CAvailableHospitalSort;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english, globalFrench, globalEnglish);
        // Init Subjects
        this._subscriptionNotifier = new Subject();
        this.dataSource.subscribe((data) => {
            if (data && data.length > 0) {
                this._availableHospitalService.setCurrentAvailable(data.find((avAndOffers) => avAndOffers.available._id == this._transition.params().id));
            }
        }, console.error);
        this.pageHeaderConfig = {
            title: this._translationService.instant('AVAILABLE.TITLE_OWN'),
            searchBarPlaceholder: this._translationService.instant('AVAILABLE.SEARCH'),
            icon: 'shopping_cart'
        };
    }
    // Table
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    get availableAndOffers() {
        return this.dataSource.value;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._navBarService.navBarUnfolded
            .pipe(takeUntil(this._subscriptionNotifier))
            .subscribe((value) => (this.navBarUnfolded = value));
        this._availableHospitalService.onCurrentAvailableChanged.subscribe((currentAv) => (this.currentAvAndOffers = currentAv));
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this._availableHospitalService.setCurrentAvailable(null);
        this.dataSource.unsubscribe();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _isClosed(av) {
        return av.status === EAvailableStatus.Closed;
    }
    _isArchived(av) {
        return av.status === EAvailableStatus.Archived;
    }
    _isCurrentAvailable(av) {
        var _a, _b;
        return av._id == ((_b = (_a = this.currentAvAndOffers) === null || _a === void 0 ? void 0 : _a.available) === null || _b === void 0 ? void 0 : _b._id);
    }
    _openCantCreateModal() {
        this._matDialog.open(CantCreateModalComponent, {
            maxWidth: '800px'
        });
    }
    _openNewOfferModal() {
        this._matDialog
            .open(EditAvailableComponent, {
            minHeight: '540px',
            maxHeight: '640px',
            minWidth: '640px',
            maxWidth: '940px'
        })
            .afterClosed()
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                return;
            }
            this._availableHospitalService
                .add(res)
                .pipe(take(1))
                .subscribe((newAv) => {
                if (!newAv) {
                    return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
                }
                this._reload.emit();
                this._snackbarService.open(this._translationService.instant('AVAILABLE.EDIT.AVAILABLE_ADDED'));
            }, this._snackbarService.openErrorFromStatus);
        }, console.error);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    resetCurrentAvailable() {
        this._availableHospitalService.setCurrentAvailable(null);
    }
    createNewAvailable() {
        this._availableHospitalService
            .getCreationPermission()
            .pipe(take(1))
            .subscribe((permission) => {
            if (permission.canCreate) {
                this._openNewOfferModal();
            }
            else {
                this._openCantCreateModal();
            }
        });
    }
    readAvailable(avAndOffers) {
        this._stateService.go('mapui.exchangeV2.available.available-hospital.available', {
            id: avAndOffers.available._id
        });
        this._availableHospitalService.setCurrentAvailable(avAndOffers);
    }
    getStyle(av) {
        const styles = [];
        const isCurrentAvailable = this._isCurrentAvailable(av.available);
        if (isCurrentAvailable) {
            styles.push(...['primary-border-left bl-4 bl-solid']);
        }
        const isArchived = this._isArchived(av.available);
        if (isArchived) {
            styles.push('background-bg');
        }
        const isClosed = this._isClosed(av.available);
        if (isClosed) {
            styles.push('green-50-bg');
        }
        return styles;
    }
    /**
     * Toggle the sidebar
     * @param name
     */
    toggleSidebar(name) {
        this._fuseSidebarService.getSidebar(name).open();
    }
    /**
     * Deselect current stockout
     */
    deselectCurrentAvailable() {
        this._stateService.go('mapui.exchangeV2.available.available-hospital.available', {
            id: '0'
        });
        this._availableHospitalService.setCurrentAvailable(null);
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    getSortState(sort) {
        if (!this.sortValue || this.sortValue.active !== sort.active) {
            return CAvailableSortStates.default;
        }
        else {
            return CAvailableSortStates[this.sortValue.direction];
        }
    }
    selectSort(sort) {
        const nextState = {
            default: { active: sort.active, direction: CAvailableSortStates.asc.direction },
            asc: { active: sort.active, direction: CAvailableSortStates.desc.direction },
            desc: undefined
        };
        if (this.sortValue && (sort === null || sort === void 0 ? void 0 : sort.active) === this.sortValue.active) {
            this.sortChange(nextState[this.sortValue.direction]);
        }
        else {
            this.sortChange(nextState.default);
        }
    }
    isSortActive(sort) {
        var _a;
        return sort.active === ((_a = this.sortValue) === null || _a === void 0 ? void 0 : _a.active);
    }
};
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], AvailableHospitalListComponent.prototype, "_paginator", void 0);
AvailableHospitalListComponent = __decorate([
    Component({
        selector: 'mpx-available-hospital-list',
        template: require('./available-hospital-list.component.html').default,
        styles: [require('./available-hospital-list.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AvailableHospitalService !== "undefined" && AvailableHospitalService) === "function" ? _a : Object, typeof (_b = typeof Injector !== "undefined" && Injector) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof NavBarService !== "undefined" && NavBarService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object, typeof (_g = typeof Transition !== "undefined" && Transition) === "function" ? _g : Object])
], AvailableHospitalListComponent);
export { AvailableHospitalListComponent };
