export const locale = {
    lang: 'en',
    data: {
        PURCHASE_GROUPS: {
            TITLE: 'Purchasing groups',
            SEARCH: 'Search a purchasing group',
            TABLE: {
                NAME: 'Name',
                NBR_ACCOUNTS: 'Accounts',
                ROLES: 'Roles'
            },
            DETAILS: {
                INFORMATIONS: {
                    TITLE: 'Informations',
                    NAME: 'Purchasing group name'
                },
                PERMISSIONS: {
                    TITLE: 'Actions / Permissions',
                    ROLE_MAPUI: 'MaPUI role',
                    ROLE_HOSPIVILLE: 'Hospiville role',
                    ROLE_HOSPISTOCK: 'Hospistock role',
                    ROLE_WORKSPACE: 'Workspace role',
                    LOG_AS: {
                        LONG: `Log in this purchasing group`,
                        SHORT: `Log in`
                    },
                    DELETE: {
                        LONG: `Delete the purchasing group`,
                        SHORT: `Delete`
                    },
                    CONFIRM_LOGAS: 'Do you really want to log in this purchasing group?',
                    CONFIRM_DELETE: `Do you really want to delete this purchasing group?`,
                    SNACK_DELETE: `Purchasing group has been deleted.`
                },
                MEMBERS: {
                    TITLE: 'Members',
                    NO_MEMBERS: 'No member in this purchasing group.',
                    TABLE: {
                        NAME: 'Name',
                        EMAIL: 'Email',
                        LAST_CONNECT: 'Last connection'
                    }
                }
            }
        }
    }
};
