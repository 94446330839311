export const locale = {
    lang: 'en',
    data: {
        ACTIVITY_DETAILS: {
            TABS: {
                ACTIONS: {
                    TITLE: `Information and actions`,
                    STATUS: `Activity status:`,
                    INFORMATION: {
                        TITLE: `Information`,
                        PATIENT: `Patient`,
                        CONSENT: `Consent`,
                        SOURCE: `Expeditor`,
                        DEST: `Receiver`,
                        MED_REC_TRANSIT: `Reconciliation type`,
                        NB_DOCS: `Number of documents associated to this activity`
                    },
                    BUTTONS: {
                        END_ACTIVITY: {
                            BUTTON_LABEL: `Close activity`,
                            CONFIRM_DIALOG_TITLE: `Close request`,
                            CONFIRM_DIALOG_MESSAGE: `Do you really want to close this activity?`,
                            CONFIRM_DIALOG_OK_BUTTON: `End this activity`,
                            ALREADY_ENDED: `Activity has already been closed.`
                        },
                        CANCEL_ACTIVITY: {
                            BUTTON_LABEL: `Cancel activity`,
                            CONFIRM_DIALOG_TITLE: `Cancel request`,
                            CONFIRM_DIALOG_MESSAGE: `Do you really want to cancel this activity?`,
                            CONFIRM_DIALOG_CANCEL_BUTTON: `Back`,
                            CONFIRM_DIALOG_OK_BUTTON: `Cancel this activity`,
                            ALREADY_CANCELED: `Activity has already been canceled.`
                        },
                        IMPORT_FILES: `Import files`,
                        ADD_TO_MEDREC: `Add to medication reconciliation`,
                        GOT_MEDREC: `A medication reconciliation sheet has already been sent.`
                    }
                },
                MAILS: `Mails`,
                FILES: `Files`,
                ADD_TO_DRUG_BALANCE: `Add to drug balance`
            },
            SNACKBAR: {
                MODIFIED: `The activity has been successfully modified.`,
                ENDED: `The activity has been successfully ended.`,
                CANCELED: `The activity has been successfully canceled.`,
                FILES_ADDED: `The files have been successfully added to the activity.`
            }
        }
    }
};
