var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { HospitalAccountRoutingModule } from './hospital-account-routing.module';
import { HospitalTeamComponent } from './hospital-team/hospital-team.component';
import { HospitalInformationComponent } from './hospital-information/hospital-information.component';
import { PermissionInputComponent } from './hospital-information/permission-input/permission-input.component';
let HospitalAccountModule = class HospitalAccountModule {
};
HospitalAccountModule = __decorate([
    NgModule({
        declarations: [HospitalTeamComponent, HospitalInformationComponent, PermissionInputComponent],
        imports: [
            CommonModule,
            HospitalAccountRoutingModule,
            BrowserAnimationsModule,
            FuseSharedModule,
            FuseDirectivesModule,
            FuseSidebarModule,
            SharedModule,
            MatTableModule,
            MatSelectModule,
            MatButtonModule,
            MatPaginatorModule,
            MatIconModule,
            MatSortModule,
            MatTooltipModule,
            MatDividerModule,
            MatProgressSpinnerModule,
            MatInputModule,
            MatRippleModule
        ],
        exports: [HospitalAccountRoutingModule]
    })
], HospitalAccountModule);
export { HospitalAccountModule };
