var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IExhausmedIndustrial } from '@mapuilabs/mpl-interfaces';
import { IndustrialService } from '@services/industrial/industrial.service';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { map } from 'rxjs';
let SearchIndustrialComponent = class SearchIndustrialComponent {
    constructor(_industrialService, _fuseTranslationLoaderService) {
        this._industrialService = _industrialService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.exclude = [];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init EventEmitters
        this.inputReset = new EventEmitter();
        this.inputChanged = new EventEmitter();
        this.optionSelected = new EventEmitter();
        // Search industrial config
        this.searchAutocompleteConfig = {
            displayFn: (industrial) => (industrial ? industrial === null || industrial === void 0 ? void 0 : industrial.name : null),
            // typeof searchValue === 'string' ? this._industrialService.findByName(searchValue) : null,
            autocompleteSearchFn: (searchValue) => typeof searchValue === 'string'
                ? this._industrialService
                    .findByName(searchValue)
                    .pipe(map((industrials) => industrials.filter(({ name }) => { var _a; return !((_a = this.exclude) === null || _a === void 0 ? void 0 : _a.some((exclude) => exclude.name === name)); })))
                : null,
            minimumLength: 3
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    onOptionSelected(selectedIndustrial) {
        this.optionSelected.emit(selectedIndustrial);
    }
    onInputChanged(industrialInput) {
        this.inputChanged.emit(industrialInput);
    }
    onInputReset() {
        this.inputReset.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchIndustrialComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchIndustrialComponent.prototype, "required", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof IExhausmedIndustrial !== "undefined" && IExhausmedIndustrial) === "function" ? _c : Object)
], SearchIndustrialComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], SearchIndustrialComponent.prototype, "exclude", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchIndustrialComponent.prototype, "inputChanged", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], SearchIndustrialComponent.prototype, "inputReset", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], SearchIndustrialComponent.prototype, "optionSelected", void 0);
SearchIndustrialComponent = __decorate([
    Component({
        selector: 'mpx-search-industrial',
        template: require('./search-industrial.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IndustrialService !== "undefined" && IndustrialService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchIndustrialComponent);
export { SearchIndustrialComponent };
