var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CPosologyType } from '../../constTypes/drugs/posologyType.const';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { EPosologyType } from '@mapuilabs/mpl-interfaces';
import { PosologyService } from '@services/hospiville/posology/posology.service';
let SelectPosologyComponent = class SelectPosologyComponent {
    constructor(_fuseTranslationLoaderService, _posologyService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._posologyService = _posologyService;
        this.onSelected = new EventEmitter();
        this.cPosologyType = CPosologyType;
        this.ePosologyType = EPosologyType;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    set posology(data) {
        this._posology = data;
        this.onSelected.emit(this._posology);
    }
    get posology() {
        return this._posology;
    }
    inputChanged() {
        if (this.parentFormControl.value != EPosologyType.Other) {
            this.posology = [this._posologyService.getPresetPosology(this.parentFormControl.value)];
        }
        else {
            this.posology = null;
            this.openPosologyModal();
        }
    }
    openPosologyModal() {
        this._posologyService.openSpecificPosologyModal(this.posology).subscribe((value) => {
            this.parentFormControl.setValue(EPosologyType.Other);
            this.posology = value;
        });
    }
    getPosologySentence() {
        if (this.posology && this.posology.length) {
            return this._posologyService.generateArrayPosology(this.posology);
        }
        return null;
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _c : Object)
], SelectPosologyComponent.prototype, "parentFormControl", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SelectPosologyComponent.prototype, "onSelected", void 0);
SelectPosologyComponent = __decorate([
    Component({
        selector: 'select-posology',
        template: require('./select-posology.component.html').default,
        styles: [require('./select-posology.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof PosologyService !== "undefined" && PosologyService) === "function" ? _b : Object])
], SelectPosologyComponent);
export { SelectPosologyComponent };
