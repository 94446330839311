import { EAnswer } from '@mapuilabs/mpl-interfaces';
export class HvManagingDrugInterviewController {
    /** @ngInject */
    constructor(Api, _) {
        this.Api = Api;
        this._ = _;
        /**
         *
         * @type {EAnswer}
         */
        this.EAnswer = EAnswer;
        /**
         * Save [[IPatient]]
         */
        this.save = () => {
            if (this.onSave) {
                this.onSave({ $event: { treatment: this.treatment } });
            }
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
}
