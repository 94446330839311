export const locale = {
    lang: 'fr',
    data: {
        admMemberDetails: {
            EDIT_TITLE: 'Utilisateur - ',
            NEW_TITLE: 'Nouvel utilisateur',
            TABS: {
                ACTIONS: 'Informations / Actions',
                IDENTITY: 'Identité',
                ROLES: 'Rôles'
            },
            ACTIONS: {
                INFORMATION: {
                    TITLE: 'Informations',
                    HOSPITAL: 'Hôpital',
                    NO_HOSPITAL: "Pas d'établissement",
                    CREATION_DATE: 'Date de création',
                    CGU: 'CGU',
                    LAST_CONNECTION: 'Dernière connexion',
                    BROWSER_OS: 'Navigateur / OS',
                    LOCKED: 'Verrouillé',
                    BLOCKED: 'Bloqué',
                    VALID_MAIL: 'Mot de passe temporaire',
                    DELETED: 'Compte supprimé'
                },
                BUTTONS: {
                    SELECT_HOSPITAL: 'Sélectionner un hôpital',
                    CHANGE_HOSPITAL: "Changer d'hôpital",
                    SAVE_TOOLTIP: "Veuillez sauvegarder l'utilisateur avant d'effectuer cette action",
                    ACTIVATE: 'Activer le compte',
                    DEACTIVATE: 'Désactiver le compte',
                    UNBLOCK: 'Débloquer le compte',
                    WELCOME_MAIL: 'Envoyer email de bienvenue',
                    INVITE_MAIL: "Envoyer email d'invitation",
                    REMOVE_DOUBLE_AUTH: 'Supprimer la double authentification',
                    DELETE_ACCOUNT: 'Supprimer le compte',
                    RESURRECT_ACCOUNT: 'Ressusciter le compte',
                    EMAIL_CONFIRM_DIALOGS: {
                        SEND_WELCOME_MAIL: {
                            TITLE: 'Email de bienvenue',
                            MESSAGE: 'Voulez-vous envoyer un email de bienvenue à {{member}} ?',
                            ALREADY_SENT_MESSAGE: 'Un email de bienvenue à déjà été envoyé le {{date}} par {{member}}. Voulez vous continuer ?'
                        },
                        SEND_INVITE_MAIL: {
                            TITLE: `Email d'invitation`,
                            MESSAGE: `Voulez-vous envoyer un email d'invitation à {{member}} ?`,
                            ALREADY_SENT_MESSAGE: "Un email d'invitation à déjà été envoyé le {{date}} par {{member}}. Voulez vous continuer ?"
                        },
                        ALREADY_SENT_TITLE: 'Email précédemment envoyé'
                    },
                    EMAIL_SENT: "L'email a été envoyé"
                }
            },
            IDENTITY: {
                EMAIL: 'Email',
                USERNAME: 'Login',
                FIRST_NAME: 'Prénom',
                FAMILY_NAME: 'Nom',
                GENDER: 'Genre',
                PHONE: 'Téléphone',
                RPPS: 'Numéro RPPS'
            },
            ROLES: {
                ROLE_MAPUI: 'Rôle MaPUI',
                ROLE_HOSPIVILLE: 'Rôle Hospiville',
                ROLE_HOSPISTOCK: 'Rôle Hospistock',
                ROLE_WORKSPACE: 'Rôle Workspace',
                IS_ADMIN: 'Administrateur',
                ROLE_ADMIN_PANEL: 'Rôle Administrateur'
            },
            MESSAGES: {
                SNACKBAR: {
                    EDIT_OK: "L'utilisateur a été modifié !",
                    CREATE_OK: "L'utilisateur a été créé !",
                    CHANGE_HOSPITAL_OK: "L'hôpital du membre a été changé !",
                    ACTIVATE_OK: 'Le compte a été activé !',
                    DEACTIVATE_OK: 'Le compte a été désactivé !',
                    UNBLOCK_OK: 'Le compte a été débloqué !',
                    REMOVE_DOUBLE_AUTH_OK: 'La double authentification a été supprimée !',
                    DELETE_ACCOUNT_OK: "L'utilisateur a été supprimé !",
                    RESURRECT_OK: "L'utilisateur a été ressuscité !"
                },
                ALERT: {
                    UNBLOCK: {
                        TITLE: 'Débloquer le compte',
                        MESSAGE: 'Voulez-vous vraiment débloquer le compte ?'
                    },
                    DISABLE_2FA: {
                        TITLE: 'Supprimer la double authentification',
                        MESSAGE: 'Voulez-vous vraiment effacer la double authentification pour cet utilisateur ?'
                    },
                    DELETE: {
                        TITLE: 'Confirmer la suppression',
                        MESSAGE: "Voulez-vous vraiment supprimer l'utilisateur ?"
                    },
                    RESURRECT: {
                        TITLE: 'Confirmer la résurrection',
                        MESSAGE: "Voulez-vous vraiment ressusciter l'utilisateur ?"
                    }
                }
            }
        }
    }
};
