import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HvModalDuplicateDrugBalanceController {
    /** @ngInject */
    constructor(DrugBalanceService, ModalService, Api, _) {
        this.DrugBalanceService = DrugBalanceService;
        this.ModalService = ModalService;
        this.Api = Api;
        this._ = _;
        /**
         * Get [[IDrugBalanceSource]] name translation
         * @param srcId
         * @returns {string}
         */
        this.getSourceNameById = (srcId) => {
            if (srcId === 'concordance') {
                return 'HOSPIVILLE.DRUG_BS.SYNTHESIS.CONCORDANCE';
            }
            let source = this._.find(this.selectedDBS.sources, function (s) {
                return Utils.compareIds(s.source, srcId);
            });
            if (!this._constTypes || !source || Utils.isNaN(Number(source.source.type)))
                return;
            return this._.find(this._constTypes.sources, { value: source.source.type }).translate;
        };
        /**
         * Get the translation of a given posology
         * @param posology
         * @returns {string}
         */
        this.getPosology = (posology) => {
            if (!this._constTypes || (!posology && posology !== 0))
                return;
            return this._.find(this._constTypes.posology, { value: posology }).translate;
        };
        /**
         * Get the translation of a given dosage type
         * @param dosageType
         * @returns {string}
         */
        this.getDosageType = (dosageType) => {
            if (!this._constTypes || (!dosageType && dosageType !== 0))
                return;
            return this._.find(this._constTypes.dosages, { value: dosageType }).translate;
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this._close(this.selectedDBS);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
    }
    $onInit() {
        this.DrugBalanceService.getAll().then((drugBalances) => {
            this.drugBalanceSheets = drugBalances;
        });
        this.Api.hvConstType.get((ans) => {
            this._constTypes = ans;
        });
    }
    $onChanges(changes) {
        if (changes.currentDbsId) {
            this.currentDbsId = angular.copy(this.currentDbsId);
        }
    }
}
