var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFileType, IFile } from '@mapuilabs/mpl-interfaces';
import { ActivitySourceService } from '@services/hospiville/activities/activities-source.service';
import { FileService } from '@services/file/file.service';
import { CActivityOrigin } from '@shared/constTypes/activity/activity-origin.const';
import { TranslationService } from '@services/translation/translation.service';
let FilesListComponent = class FilesListComponent {
    constructor(_activityService, _fileService, _fuseTranslationLoaderService, _translationService) {
        this._activityService = _activityService;
        this._fileService = _fileService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this.eFileType = EFileType;
        this.cActivityOrigin = CActivityOrigin;
        /**
         * Emits when a file has been selected.
         */
        this.onFileSelected = new EventEmitter();
        /**
         * Emits when the tooltip on the save button of the parent component should be toggled.
         */
        this.showTooltip = new EventEmitter();
        /**
         * Sorted files regarding their isHidden attribute.
         */
        this.visibleFiles = [];
        this.hiddenFiles = [];
        this.getIcon = (file) => {
            return this._fileService.getIcon(file);
        };
        /**
         * Returns a class name to highlight a selected file.
         * @param file
         */
        this.isSelectedFile = (file) => {
            var _a;
            return ((_a = this.selectedFile) === null || _a === void 0 ? void 0 : _a._id) === file._id ? 'selected-file' : '';
        };
        /**
         * Get translation fro constTypes
         * @param from
         * @param value
         * @param interpolateParams
         */
        this.getTranslation = (from, value, interpolateParams) => {
            return this._translationService.getTranslation(from, value, interpolateParams);
        };
        /**
         * Outputs the clicked file as the selected file.
         * @param file
         */
        this.selectFile = (file) => {
            this.selectedFile = file.file;
            this.onFileSelected.emit(file);
        };
    }
    ngOnChanges(changes) {
        var _a;
        // When a panel is opened and the user changes tab, the panel content
        // keeps its 'opened' state (apparently) but does not visually close.
        // Coming back to this tab shows a closed panel but the user has to
        // click twice on the header to open it. = Overlay problem?
        if (changes.currentTabIndex) {
            if (changes.currentTabIndex.currentValue === this.filesTabIndex) {
                this.visibleFilesTab.open();
            }
            else {
                this.visibleFilesTab.close();
                this.hiddenFilesTab.close();
            }
        }
        if (changes.files) {
            this.visibleFiles = [];
            this.hiddenFiles = [];
            if ((_a = this.files) === null || _a === void 0 ? void 0 : _a.length) {
                this.files.forEach((file) => {
                    if (file.isHidden) {
                        this.hiddenFiles.push(file);
                    }
                    else {
                        this.visibleFiles.push(file);
                    }
                });
            }
            // "Sort" by newest file
            this.hiddenFiles.reverse();
            this.visibleFiles.reverse();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Array !== "undefined" && Array) === "function" ? _e : Object)
], FilesListComponent.prototype, "files", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof IFile !== "undefined" && IFile) === "function" ? _f : Object)
], FilesListComponent.prototype, "selectedFile", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FilesListComponent.prototype, "currentTabIndex", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], FilesListComponent.prototype, "filesTabIndex", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], FilesListComponent.prototype, "onFileSelected", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_h = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _h : Object)
], FilesListComponent.prototype, "showTooltip", void 0);
__decorate([
    ViewChild('visibleFilesTab', { static: true }),
    __metadata("design:type", typeof (_j = typeof MatExpansionPanel !== "undefined" && MatExpansionPanel) === "function" ? _j : Object)
], FilesListComponent.prototype, "visibleFilesTab", void 0);
__decorate([
    ViewChild('hiddenFilesTab', { static: true }),
    __metadata("design:type", typeof (_k = typeof MatExpansionPanel !== "undefined" && MatExpansionPanel) === "function" ? _k : Object)
], FilesListComponent.prototype, "hiddenFilesTab", void 0);
FilesListComponent = __decorate([
    Component({
        selector: 'hvx-files-list',
        template: require('./files-list.component.html').default,
        styles: [require('./files-list.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ActivitySourceService !== "undefined" && ActivitySourceService) === "function" ? _a : Object, typeof (_b = typeof FileService !== "undefined" && FileService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object])
], FilesListComponent);
export { FilesListComponent };
