export const locale = {
    lang: 'fr',
    data: {
        PANEL_TITLES: {
            VISIBLE_FILES: `Fichiers`,
            HIDDEN_FILES: `Fichiers masqués`
        },
        TOOLTIPS: {
            MAIL: `Pièce jointe de mail`,
            IMPORT: `Fichier importé`,
            COMMENT: 'Commentaire associé au document'
        },
        PLACEHOLDERS: {
            COMMENT: `Commentaire`
        },
        BUTTONS: {
            IMPORT: `Importer un fichier`,
            DELETE: `Supprimer le fichier`,
            ADD_COMMENT: `Ajouter un commentaire`,
            EDIT_COMMENT: 'Modifier le commentaire',
            VALID: `Valider`
        },
        SNACKBAR: {
            DELETED: `Le fichier a bien été supprimé.`,
            NO_MORE_FILES: `Tous les fichiers ont été supprimés, l'activité est repassée 'En attente'.`,
            COMMENT_ADDED: `Commentaire ajouté au fichier.`
        },
        CONFIRM_DIALOG: {
            DELETE_TITLE: `Confirmer la suppression du fichier sélectionné`,
            DELETE_MESSAGE: `Êtes-vous sûr de vouloir supprimer ce fichier ?`
        }
    }
};
