var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { find } from 'lodash';
import * as mime from 'mime';
import { TranslationService } from '../translation/translation.service';
let FileService = class FileService {
    constructor(_http, _translationService) {
        this._http = _http;
        this._translationService = _translationService;
        this._defaultIcon = 'file-file';
        this._mapMimeIcon = [
            { mimetype: 'video/x-msvideo', icon: 'file-avi' },
            { mimetype: 'text/css', icon: 'file-css' },
            { mimetype: 'application/msword', icon: 'file-doc' },
            { mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon: 'file-doc' },
            { mimetype: 'application/javascript', icon: 'file-javascript' },
            { mimetype: 'image/jpeg', icon: 'file-jpg' },
            { mimetype: 'application/pdf', icon: 'file-pdf' },
            { mimetype: 'image/png', icon: 'file-png' },
            { mimetype: 'application/vnd.ms-powerpoint', icon: 'file-ppt' },
            { mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', icon: 'file-ppt' },
            { mimetype: 'application/rtf', icon: 'file-rtf' },
            { mimetype: 'image/svg+xml', icon: 'file-svg' },
            { mimetype: 'text/plain', icon: 'file-txt' },
            { mimetype: 'application/vnd.ms-excel', icon: 'file-xls' },
            { mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: 'file-xls' },
            { mimetype: 'text/html', icon: 'file-html' }
        ];
        /**
         * Finds an icon according with the given mimetype.
         * @param mimetype
         */
        this.getIconFromMime = (mimetype) => {
            if (!mimetype) {
                return this._defaultIcon;
            }
            const found = find(this._mapMimeIcon, (elem) => elem.mimetype == mimetype);
            if (found) {
                return found.icon;
            }
            return this._defaultIcon;
        };
        /**
         * Gets an icon according with the given file.
         * @param file
         */
        this.getIcon = (file) => {
            if (!file || !file.extension) {
                return this._defaultIcon;
            }
            return this.getIconFromMime(mime.getType(file.extension));
        };
        /**
         * Attests file pdf extension
         * @param file
         */
        this.isPdf = (file) => {
            return !!file && file.extension === 'pdf';
        };
        /**
         * Tells if the file extension is one of the possible image extensions
         * @param file
         */
        this.isImg = (file) => {
            return !!file && (file.extension === 'jpg' || file.extension === 'jpeg' || file.extension === 'png');
        };
        /**
         * Get the content of a file
         * @param file
         */
        this.readStoredFileContent = (file) => {
            if (!(file === null || file === void 0 ? void 0 : file.viewUrl)) {
                return of(this._translationService.instant('COMMONS.ERRORS.NO_FILE_CONTENT'));
            }
            return this._http.get(file.viewUrl, { responseType: 'text' });
        };
    }
};
FileService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object])
], FileService);
export { FileService };
