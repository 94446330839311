var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { HospitalService } from '@services/hospital/hospital.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { LoanService } from '@services/loan/loan.service';
import { TranslationService } from '@services/translation/translation.service';
import { ExchangeMapService } from '../map-exchange.service';
import { StateService } from '@uirouter/core';
import { Utils } from '@main/services/Utils';
let MapDetailsSidebarComponent = class MapDetailsSidebarComponent {
    constructor(_hospitalService, _fuseTranslationLoaderService, _fuseSidebarService, _snackbarService, _translationService, dashboardService, _loanService, _exchangeMapService, _stateService) {
        this._hospitalService = _hospitalService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseSidebarService = _fuseSidebarService;
        this._snackbarService = _snackbarService;
        this._translationService = _translationService;
        this.dashboardService = dashboardService;
        this._loanService = _loanService;
        this._exchangeMapService = _exchangeMapService;
        this._stateService = _stateService;
        this.isLoading = false;
        this.openLoanUpdateModal = (event, loan) => {
            this.dashboardService.openLoanUpdateModal(event, loan).subscribe(() => {
                this._fuseSidebarService.getSidebar('sidebar-details').close();
                this._snackbarService.open(this._translationService.instant('MAP_DETAIL_SIDEBAR.SNACKBAR'));
                this._resetHospital();
            }, (error) => this._snackbarService.openError(error));
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnChanges(changes) {
        var _a;
        if ((_a = changes.hid) === null || _a === void 0 ? void 0 : _a.currentValue) {
            this.isLoading = true;
            this._exchangeMapService.getHospitalDetails(changes.hid.currentValue).subscribe((fullHospital) => {
                if (fullHospital) {
                    this._resetHospital();
                    this.fullHospital = fullHospital;
                }
                else {
                    console.error(`Result of query is falsy: ${fullHospital}`);
                    this._snackbarService.openDefaultError();
                    this._resetHospital();
                }
                this.isLoading = false;
            }, (error) => {
                this.isLoading = false;
                this._snackbarService.openError(error);
            });
        }
    }
    get hospitalData() {
        var _a;
        return (_a = this.fullHospital) === null || _a === void 0 ? void 0 : _a.data;
    }
    get requests() {
        var _a;
        return (_a = this.hospitalData) === null || _a === void 0 ? void 0 : _a.requests;
    }
    get availables() {
        return this.fullHospital.available;
    }
    get addrFirstLine() {
        return `${this.hospitalData.addrNumber} ${this.hospitalData.addrStreet}`;
    }
    get addrSecondLine() {
        return `${this.hospitalData.addrZIP} ${this.hospitalData.addrCity} (${this.hospitalData.addrCountry})`;
    }
    _resetHospital() {
        this.fullHospital = {
            data: null,
            available: null,
            ght: null
        };
    }
    redirectToDetail(available) {
        this._stateService.go('mapui.exchangeV2.available.available.available', {
            id: Utils.getId(available)
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], MapDetailsSidebarComponent.prototype, "hid", void 0);
MapDetailsSidebarComponent = __decorate([
    Component({
        selector: 'map-details-sidebar',
        template: require('./map-details-sidebar.component.html').default,
        styles: [require('./map-details-sidebar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof DashboardService !== "undefined" && DashboardService) === "function" ? _f : Object, typeof (_g = typeof LoanService !== "undefined" && LoanService) === "function" ? _g : Object, typeof (_h = typeof ExchangeMapService !== "undefined" && ExchangeMapService) === "function" ? _h : Object, typeof (_j = typeof StateService !== "undefined" && StateService) === "function" ? _j : Object])
], MapDetailsSidebarComponent);
export { MapDetailsSidebarComponent };
