export class MpSidenavStaffTypeComponent {
    constructor() {
        this.controller = 'MpSidenavStaffTypeController';
        this.controllerAs = 'vm';
        this.template = require('./mp-sidenav-staff-type.html').default;
        this.bindings = {
            onEdit: '&',
            onChange: '&',
        };
    }
}
