import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class HvPatientFileController {
    /** @ngInject */
    constructor($q, $scope, $rootScope, $state, $stateParams, Access, ModalService, HospivilleService, PatientService, ToastService, VaultService, $location, InformationService) {
        this.$q = $q;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.Access = Access;
        this.ModalService = ModalService;
        this.HospivilleService = HospivilleService;
        this.PatientService = PatientService;
        this.ToastService = ToastService;
        this.VaultService = VaultService;
        this.$location = $location;
        this.InformationService = InformationService;
        /**
         * Save [[IPatient]]
         */
        this.savePatient = (patient = null) => {
            const defer = this.$q.defer();
            this.HospivilleService.savePatient(patient || this.patient)
                .then((patient) => {
                this._newPatientNotSaved = false;
                this._isSave = true;
                this.isChange = false;
                this.patient = patient;
                this.$rootScope.$emit('refreshPatient');
                this.ToastService.show(EMpStatus.Ok);
                defer.resolve();
            })
                .catch(() => {
                this.ToastService.show(EMpStatus.RejectByServer);
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Init [[IPatient]] data
         * @private
         */
        this._initPatient = (newPatient = false) => {
            this._isSave = false;
            this.isChange = false;
            if (this.HospivilleService.hasPatient) {
                this.HospivilleService.getPatient().then((patient) => {
                    this.patient = patient;
                    if (this.patient && this.patient.medicationReconciliations) {
                        this.HospivilleService.getAllMedRec(this.patient.medicationReconciliations)
                            .then((medRecs) => {
                            this.medicationReconciliations = medRecs;
                            this._sendMedRecCreationStatus(this.Access.patient.medicationReconciliation);
                        });
                    }
                    else {
                        this.medicationReconciliations = [];
                    }
                });
            }
            else if (newPatient) {
                this.ModalService.show({
                    component: 'hv-modal-master-patient',
                    bindings: {},
                    escapeToClose: false,
                }).then((ans) => {
                    this.PatientService.setMasterPatient(ans).then((pat) => {
                        if (pat._id) {
                            this.Access.changePatient(pat._id, null);
                        }
                        else {
                            pat.sources = [];
                        }
                        this.savePatient(pat)
                            .then(() => {
                            this.ToastService.show(EMpStatus.Ok);
                        })
                            .catch(() => {
                            this.ToastService.show(EMpStatus.RejectByServer);
                        });
                    });
                });
            }
        };
        this._sendMedRecCreationStatus = (medRec) => {
            // console.log("CHECK SEND STATUS MED REC", medRec);
            const options = this.$location.search().options;
            this.$location.search('options', null);
            if (options === undefined) {
                return;
            }
            const parsed = JSON.parse(options);
            // console.log("OPTIONS", options);
            if (parsed.updateStatus === undefined || parsed.updateStatus === false) {
                return;
            }
            if (!this.Access.isInterfaced()) {
                return;
            }
            this.HospivilleService.exportMedRecStatus(Utils.getId(medRec), 0, "create");
        };
        this.$scope.$on('refreshPatientFile', () => {
            this._initPatient();
        });
        // this.$scope.$on('generateNewPatientFile', () => {
        //    this._pToSave = angular.copy(this.patient);
        //    this._initPatient();
        // });
        if (!this.Access.isHVMigrationState()) {
            this.$scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
                if (!this.patient || !this.patientForm || (this.patientForm && this.patientForm.$pristine)) {
                    this._isSave = true;
                }
                if (!this._isSave || this.isChange || this._newPatientNotSaved) {
                    this._isSave = true;
                    this.isChange = false;
                    event.preventDefault();
                    if (!this.VaultService.isLocked()) {
                        this.ModalService.show({
                            component: 'mp-modal-confirm',
                            bindings: {
                                data: {
                                    title: 'Sauvegarder',
                                    text: 'Voulez-vous enregister les modifications apportées ? ',
                                },
                            },
                            escapeToClose: false,
                            clickOutsideToClose: false
                        }).then(() => {
                            this.savePatient(this._pToSave).then(() => {
                                this._pToSave = null;
                                if (!toState.data || !toState.data.hasSensitiveData) {
                                    this.HospivilleService.lockVault();
                                }
                                this.$state.go(toState, toParams);
                            })
                                .catch(() => {
                                this._pToSave = null;
                                if (!toState.data || !toState.data.hasSensitiveData) {
                                    this.HospivilleService.lockVault();
                                }
                                this.$state.go(toState, toParams);
                            });
                        }).catch(() => {
                            this._pToSave = null;
                            if (!toState.data || !toState.data.hasSensitiveData) {
                                this.HospivilleService.lockVault();
                            }
                            this.$state.go(toState, toParams);
                        });
                    }
                    else {
                        this._pToSave = null;
                        this.$state.go(toState, toParams);
                    }
                }
            });
        }
    }
    $onInit() {
        if (this.$stateParams.selectedTab) {
            this.selected = this.$stateParams.selectedTab;
            this._initPatient(this.$stateParams.newPatient);
        }
        else {
            this._initPatient(this.$stateParams.newPatient);
        }
    }
}
