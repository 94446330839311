export const locale = {
    lang: 'en',
    data: {
        PANEL_TITLES: {
            VISIBLE_FILES: `Received files`,
            HIDDEN_FILES: `Masked files`
        },
        TOOLTIPS: {
            MAIL: 'Mail attachment',
            IMPORT: 'Imported files',
            COMMENT: 'Comment associated to file'
        },
        BUTTONS: {
            IMPORT: `Import a file`,
            HIDE: `Hide this file`,
            SHOW: `Show this file`,
            ADD_TO_DRUG_BALANCE: `Add to drug balance`,
            ADD_TO_PATIENT_FILES: 'Add to patient files',
            VIEW_COMMENT: `View comment`,
            DELETE: `Delete file`
        },
        SNACKBAR: {
            HIDE_FILE: `The file has been moved to the "Hidden files" section.`,
            NO_MORE_FILES: `The files have been deleted, the activity is back to the 'Waiting' status.`
        },
        MOVE_TO_PATIENT_FILES: `The file has been added to patient files.`,
        ALREADY_ADDED: "File already added to patient's files",
        CONFIRM_DIALOG: {
            DELETE_TITLE: `Confirm the deletion of the selected file`,
            DELETE_MESSAGE: `Are you sure you want to delete this file?`
        }
    }
};
