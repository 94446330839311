/** @ngInject */
export function config($ariaProvider, $logProvider, msScrollConfigProvider, fuseConfigProvider) {
    // Enable debug logging
    $logProvider.debugEnabled(true);
    /*eslint-disable */
    // ng-aria configuration
    $ariaProvider.config({
        tabindex: false,
    });
    // Fuse theme configurations
    fuseConfigProvider.config({
        'disableCustomScrollbars': false,
        'disableCustomScrollbarsOnMobile': true,
        'disableMdInkRippleOnMobile': true,
    });
    // msScroll configuration
    msScrollConfigProvider.config({
        wheelPropagation: true,
    });
    /*eslint-enable */
}
