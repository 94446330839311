export const locale = {
    lang: 'fr',
    data: {
        externalConfigDetail: {
            TITLE_NEW: 'Nouvelle configuration',
            TITLE_EDIT: 'Configuration - {{id}}',
            INFORMATION_TAB: {
                TITLE: 'Informations',
                URL: 'URL',
                USERNAME: "Nom d'utilisateur",
                PASSWORD: 'Mot de passe'
            },
            KEYS_TAB: {
                TITLE: 'Clés',
                PUBLIC: 'Clé publique',
                PRIVATE: 'Clé privée'
            },
            HOSPITALS_TAB: {
                TITLE: 'Hôpitaux',
                ESTABLISHMENT: 'Établissement'
            }
        }
    }
};
