var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { EAdminPanelPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { AdminHsRoutingModule } from './hospistock/admin-hs-routing.module';
const states = [
    {
        name: 'mapui.adm.ars',
        url: '/ars',
        abstract: true
    },
    {
        name: 'mapui.adm.hospitals',
        url: '/hospitals',
        data: {
            access: EAdminPanelPermission.Establishments_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.pharmacies',
        url: '/pharmacy',
        data: {
            access: EAdminPanelPermission.Establishments_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.members',
        url: '/members',
        data: {
            access: EAdminPanelPermission.Users_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.groups',
        url: '/groups',
        abstract: true
    },
    {
        name: 'mapui.adm.labos',
        url: '/labos',
        abstract: true
    },
    {
        name: 'mapui.adm.external-actors-v2',
        url: '/externalactor',
        abstract: true
    },
    {
        name: 'mapui.adm.stats',
        url: '/stats',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.homepage',
        url: '/homepage',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.sandbox',
        url: '/sandbox',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.online_users',
        url: '/online-users',
        data: {
            access: EAdminPanelPermission.OnlineUsers_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.cron',
        url: '/cron-v2',
        data: {
            access: EAdminPanelPermission.Cron_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.roles',
        url: '/roles-v2',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.secure-emails',
        url: '/secure-emails',
        data: {
            access: EAdminPanelPermission.AdminPanel_Read
        },
        abstract: true
    },
    {
        name: 'mapui.adm.purchase-groups',
        url: '/purchase-groups',
        abstract: true
    }
];
let AdminRoutingModule = class AdminRoutingModule {
};
AdminRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states }), AdminHsRoutingModule]
    })
], AdminRoutingModule);
export { AdminRoutingModule };
