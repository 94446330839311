import { EAdminPanelPermission, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class ExternalActorService {
    /** @ngInject */
    constructor(Api, $q, Access) {
        this.Api = Api;
        this.$q = $q;
        this.Access = Access;
        /**
         * Get all [[IExternalActor]]
         * @returns A promise containing an array of [[IExternalActor]]
         */
        this.getAll = () => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalActors_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalActors.all((ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get one [[IExternalActor]] by his id
         * @param actor
         */
        this.getById = (actor) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalActors_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalActors.get({ id: Utils.getId(actor) }, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Create a new [[IExternalActor]]
         * @param actor
         */
        this.create = (actor) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalActors_Create)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalActors.add(actor, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an existing [[IExternalActor]] in dataBase using the [[Id]]
         * @param actor The [[IExternalActor]] to update
         * @returns {Promise<IExternalActor>}
         */
        this.save = (actor) => {
            const defer = this.$q.defer();
            if (!actor) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EAdminPanelPermission.ExternalActors_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                // console.log('actor to save : ', actor);
                this.Api.externalActors.save({ id: actor._id }, actor, (ans) => defer.resolve(ans), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Delete the given [[IExternalActor]]
         * @param actor
         */
        this.delete = (actor) => {
            const defer = this.$q.defer();
            if (!this.Access.authorize(EAdminPanelPermission.ExternalActors_Delete)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.externalActors.delete({ id: Utils.getId(actor) }, (ans) => {
                    defer.resolve(ans);
                }, () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
    }
}
