export const locale = {
    lang: 'fr',
    data: {
        UserAccount: {
            TABS: {
                INFORMATION: `Informations générales`,
                PARAMS: `Paramètres`,
                MAILS: `Liste de diffusion`,
                NOTIFICATIONS: `Notifications`
            },
            INFORMATION: {
                FIRSTNAME: `Prénom`,
                FAMILYNAME: `Nom`,
                GENDER: `Genre`,
                PHONE: `Téléphone`,
                INPP: `Identification nationale PP`,
                RPPS: `Numéro RPPS`,
                JOB: {
                    TITLE: `Métier`,
                    STATUS: `Statut`,
                    ACTIVITIES: `Activités`
                }
            },
            SETTINGS: {
                LANGUAGE: 'Langue',
                NOTIF_SOUND: 'Son des notifications',
                FORGOT_PASSWORD: 'Mot de passe oublié ?',
                DOUBLE_AUTH: {
                    TITLE: 'Double authentification',
                    ADD: 'Ajouter',
                    DELETE: 'Désactiver la double authentification',
                    KEY: 'Clé',
                    TOKEN: 'Code',
                    INVALID_TOKEN: 'Veuillez entrer le code de validation',
                    CANCEL: 'Annuler',
                    VALID: 'Valider',
                    ERROR_MSG: 'Code invalide'
                },
                OTHER: 'Autre'
            },
            EMAILS: {
                PREFIX: 'Recevoir les mails concernant les '
            },
            NOTIFICATIONS: {
                PREFIX: 'Recevoir les notifications concernant les '
            },
            SAVE: {
                SUCCESSFULLY: 'Profil sauvegardé'
            }
        }
    }
};
