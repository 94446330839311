var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Member } from '@mapuilabs/mpl-interfaces';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';
import { TranslationService } from '../../../services/translation/translation.service';
import { languagesList } from '../../../shared/constTypes/global/languages.const';
import { MemberService } from '../../../services/member/member.service';
import { TwoFaModalComponent } from './two-fa-modal/two-fa-modal.component';
import { ChangePasswordService } from '../../../services/change-password/change-password.service';
let SettingsTabComponent = class SettingsTabComponent {
    constructor(_memberService, _changePasswordService, _matDialog, _translationService, _snackBarService) {
        this._memberService = _memberService;
        this._changePasswordService = _changePasswordService;
        this._matDialog = _matDialog;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        //Password
        this.pwdGroup = new UntypedFormGroup({});
        this.isOldPwdValid = true;
        //Language
        this.languages = languagesList;
        //Two factors auth
        this.isFAUsed = false;
        this.setIsTwoFAuthUsed();
    }
    ngOnChanges(changes) {
        if (changes.user.currentValue) {
            this.setFormControls();
        }
        this.setPwdFormControls();
    }
    setPwdFormControls() {
        this.pwdGroup.setControl('oldPwd', new UntypedFormControl('', [Validators.required, this.isOldPwdFormValid()]));
        this.pwdGroup.setControl('newPwd', new UntypedFormControl('', [
            Validators.required,
            this.checkFormEqualTo('oldPwd', 'match', 'CHANGE_PASSWORD.ERRORS.MATCH', false),
            this.checkRegExp(/[A-Z]/, 'noUppercase', 'CHANGE_PASSWORD.ERRORS.NO_UPPERCASE'),
            this.checkRegExp(/[a-z]/, 'noLowercase', 'CHANGE_PASSWORD.ERRORS.NO_LOWERCASE'),
            this.checkRegExp(/[0-9]/, 'noDigit', 'CHANGE_PASSWORD.ERRORS.NO_DIGIT'),
            Validators.minLength(8),
        ]));
        this.pwdGroup.setControl('confirmPwd', new UntypedFormControl('', [
            Validators.required,
            this.checkFormEqualTo('newPwd', 'mismatch', 'CHANGE_PASSWORD.ERRORS.MISMATCH', true),
        ]));
    }
    setFormControls() {
        //Language
        this.form.setControl('language', new UntypedFormControl(this.user.config.language));
        //Notification sound
        this.form.setControl('notifSound', new UntypedFormControl(this.user.config.notificationSound));
    }
    updateMember(member) {
        member.config.language = this.form.value.language;
        member.config.notificationSound = this.form.value.notifSound;
        return member;
    }
    //Password
    updatePwdForm() {
        for (const control in this.pwdGroup.controls) {
            this.pwdGroup.controls[control].updateValueAndValidity();
        }
    }
    isOldPwdFormValid() {
        return () => {
            if (!this.isOldPwdValid) {
                return { isOldPwdValid: {} };
            }
            return null;
        };
    }
    checkFormEqualTo(controlName, name, errorMsg, isEqual) {
        return (control) => {
            const controlValue = control.value;
            if (!controlValue || !this.pwdGroup.controls[controlName].value) {
                return null;
            }
            if (controlValue.localeCompare(this.pwdGroup.controls[controlName].value)) {
                return isEqual ? { [name]: { value: errorMsg } } : null;
            }
            return isEqual ? null : { [name]: { value: errorMsg } };
        };
    }
    checkRegExp(nameRe, name, errorMsg) {
        return (control) => {
            return nameRe.test(control.value) ? null : { [name]: { value: errorMsg } };
        };
    }
    savePassword(pwdFormDirective) {
        this.updatePwdForm();
        if (!this.pwdGroup.invalid) {
            this._changePasswordService
                .changePassword({
                _id: this.user._id,
                oldPwd: this.pwdGroup.controls.oldPwd.value,
                password: this.pwdGroup.controls.newPwd.value,
            })
                .then(() => {
                this._snackBarService.open(this._translationService.instant('CHANGE_PASSWORD.SUCCESS_EDITED'));
                this.pwdGroup.reset();
                pwdFormDirective.resetForm();
                this.pwdGroup.markAsPristine();
            })
                .catch((err) => {
                this._snackBarService.openError(err);
                this.isOldPwdValid = false;
                this.updatePwdForm();
            });
        }
    }
    //Two factors auth
    setIsTwoFAuthUsed() {
        this._memberService.isTwoFAuthUsed().subscribe((res) => {
            this.isFAUsed = res.used;
        });
    }
    open2FAModal() {
        const dialogRef = this._matDialog.open(TwoFaModalComponent);
        dialogRef.afterClosed().subscribe(() => {
            this.setIsTwoFAuthUsed();
        });
    }
    remove2FA() {
        this._memberService.removeTwoFAuth().subscribe((res) => {
            this.setIsTwoFAuthUsed();
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof UntypedFormGroup !== "undefined" && UntypedFormGroup) === "function" ? _f : Object)
], SettingsTabComponent.prototype, "form", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof Member !== "undefined" && Member) === "function" ? _g : Object)
], SettingsTabComponent.prototype, "user", void 0);
SettingsTabComponent = __decorate([
    Component({
        selector: 'mpx-settings-tab',
        template: require('./settings-tab.component.html').default,
        styles: [require('./settings-tab.component.scss')],
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MemberService !== "undefined" && MemberService) === "function" ? _a : Object, typeof (_b = typeof ChangePasswordService !== "undefined" && ChangePasswordService) === "function" ? _b : Object, typeof (_c = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _c : Object, typeof (_d = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _d : Object, typeof (_e = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _e : Object])
], SettingsTabComponent);
export { SettingsTabComponent };
