import * as angular from 'angular';
import { HvDosageComponent } from './hv-dosage/hv-dosage.component';
import { HvDosageController } from './hv-dosage/hv-dosage.controller';
import { HvDropDisplayComponent } from './hv-drop-display/hv-drop-display.component';
import { HvDropDisplayController } from './hv-drop-display/hv-drop-display.controller';
import { HvListInstancesComponent } from './hv-list-instances/hv-list-instances.component';
import { HvListInstancesController } from './hv-list-instances/hv-list-instances.controller';
import { HvModalSelectDropComponent } from './hv-modal-select-drop/hv-modal-select-drop.component';
import { HvModalSelectDropController } from './hv-modal-select-drop/hv-modal-select-drop.controller';
import { HvModalWhenComponent } from './hv-modal-specific-posology/hv-modal-when/hv-modal-when.component';
import { HvModalWhenController } from './hv-modal-specific-posology/hv-modal-when/hv-modal-when.controller';
import { HvModalSpecificPosologyComponent } from './hv-modal-specific-posology/hv-modal-specific-posology.component';
import { HvModalSpecificPosologyController } from './hv-modal-specific-posology/hv-modal-specific-posology.controller';
import { HvSelectDosageComponent } from './hv-select-dosage/hv-select-dosage.component';
import { HvSelectDosageController } from './hv-select-dosage/hv-select-dosage.controller';
import { HvSelectPosologyComponent } from './hv-select-posology/hv-select-posology.component';
import { HvSelectPosologyController } from './hv-select-posology/hv-select-posology.controller';
import { HvSpecificPosologyComponent } from './hv-specific-posology/hv-specific-posology.component';
import { HvSpecificPosologyController } from './hv-specific-posology/hv-specific-posology.controller';
import { DrugInstanceService } from './drugInstance.service';
import { HvInstanceComponent } from './hv-instance/hv-instance.component';
import { HvInstanceController } from './hv-instance/hv-instance.controller';
import { Access } from "@main/services/auth/access.service";
angular.module('hospiville.services.drugInstance', [])
    .component('hvDosage', new HvDosageComponent())
    .controller('HvDosageController', HvDosageController)
    .component('hvDropDisplay', new HvDropDisplayComponent())
    .controller('HvDropDisplayController', HvDropDisplayController)
    .component('hvInstance', new HvInstanceComponent())
    .controller('HvInstanceController', HvInstanceController)
    .component('hvListInstances', new HvListInstancesComponent())
    .controller('HvListInstancesController', HvListInstancesController)
    .component('hvModalSelectDrop', new HvModalSelectDropComponent())
    .controller('HvModalSelectDropController', HvModalSelectDropController)
    .component('hvModalWhen', new HvModalWhenComponent())
    .controller('HvModalWhenController', HvModalWhenController)
    .component('hvModalSpecificPosology', new HvModalSpecificPosologyComponent())
    .controller('HvModalSpecificPosologyController', HvModalSpecificPosologyController)
    .component('hvSelectDosage', new HvSelectDosageComponent())
    .controller('HvSelectDosageController', HvSelectDosageController)
    .component('hvSelectPosology', new HvSelectPosologyComponent())
    .controller('HvSelectPosologyController', HvSelectPosologyController)
    .component('hvSpecificPosology', new HvSpecificPosologyComponent())
    .controller('HvSpecificPosologyController', HvSpecificPosologyController)
    .service('DrugInstanceService', DrugInstanceService)
    .service('Access', Access);
