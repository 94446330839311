var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageHeaderService } from '../../page-header.service';
let PageHeaderSearchBarButtonComponent = class PageHeaderSearchBarButtonComponent {
    constructor(_pageHeaderService) {
        this._pageHeaderService = _pageHeaderService;
        this.onClick = new EventEmitter();
    }
    get isCompactViewMode() {
        return this._pageHeaderService.isCompactViewMode;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], PageHeaderSearchBarButtonComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PageHeaderSearchBarButtonComponent.prototype, "svgIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], PageHeaderSearchBarButtonComponent.prototype, "disabled", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_b = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _b : Object)
], PageHeaderSearchBarButtonComponent.prototype, "onClick", void 0);
PageHeaderSearchBarButtonComponent = __decorate([
    Component({
        selector: 'page-header-search-bar-button',
        template: `
        <button
            *ngIf="(isCompactViewMode | async) === false; else pageHeaderButton"
            mat-icon-button
            [disabled]="disabled"
            (click)="onClick.emit()"
        >
            <mat-icon [ngClass]="{ 'icon-fg': !disabled }" [svgIcon]="svgIcon"> {{ icon }} </mat-icon>
        </button>

        <ng-template #pageHeaderButton>
            <page-header-button
                [text]="icon || svgIcon"
                [svgIcon]="svgIcon"
                [disabled]="disabled"
                [icon]="icon"
                (onClick)="onClick.emit()"
            >
            </page-header-button>
        </ng-template>
    `,
        styles: [
            `
            :host {
                display: flex;
                align-items: center;
            }
        `
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof PageHeaderService !== "undefined" && PageHeaderService) === "function" ? _a : Object])
], PageHeaderSearchBarButtonComponent);
export { PageHeaderSearchBarButtonComponent };
