var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { ELoanCat, EMpStatus } from '@mapuilabs/mpl-interfaces';
import { DashboardService as AjsDashboardService } from '../../../main/services/dashboard/dashboard.service';
import { IdentityService } from '../identity/identity.service';
import { LoanService } from '../loan/loan.service';
import { SnackbarService } from '../snackbar/snackbar.service';
let DashboardService = class DashboardService {
    constructor(_ajsDashboardService, _identityService, _loanService, _snackbarService) {
        this._ajsDashboardService = _ajsDashboardService;
        this._identityService = _identityService;
        this._loanService = _loanService;
        this._snackbarService = _snackbarService;
        this.openLoanUpdateModal = (event, loan) => {
            return new Observable((observer) => {
                this._loanService.getById(loan._id).subscribe((fullLoan) => {
                    let modal;
                    switch (loan.loanCategoryCreation) {
                        case ELoanCat.Available:
                            modal = this.openAvailableLoanUpdateModal(event, fullLoan);
                            break;
                        case ELoanCat.Request:
                            modal = this.openRequestLoanUpdateModal(event, fullLoan);
                            break;
                        default:
                            console.error(`Loan category unknown.`);
                            return observer.error(throwError(EMpStatus.RejectByServer));
                    }
                    modal.subscribe((res) => {
                        if (typeof res === 'number') {
                            if (res !== 1) {
                                this._snackbarService.openErrorMpStatus(res);
                                observer.error(throwError(res));
                            }
                        }
                        else {
                            observer.next(res);
                            observer.complete();
                        }
                    }, (error) => this._snackbarService.openError(error));
                }, (error) => this._snackbarService.openError(error));
            });
        };
        this.openAvailableLoanUpdateModal = (event, loan) => {
            // If the request has been created from user's hospital, can modify available
            if (this._identityService.hospital._id === loan.lender._id) {
                return from(this._ajsDashboardService.openLoanUpdateModal(event, loan));
            }
            // If not from user's hospital, can answer
            return from(this._ajsDashboardService.openAvailable(event, loan));
        };
        this.openRequestLoanUpdateModal = (event, loan) => {
            // If the request has been created from user's hospital, can modify request
            if (this._identityService.hospital._id === loan.borrower._id) {
                return from(this._ajsDashboardService.openLoanUpdateModal(event, loan));
            }
            // If not from user's hospital, can answer
            return from(this._ajsDashboardService.openRequest(event, loan));
        };
    }
    openLoanCreationModal(event, loan) {
        return from(this._ajsDashboardService.openLoanCreationModal(event, loan));
    }
};
DashboardService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AjsDashboardService !== "undefined" && AjsDashboardService) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object, typeof (_c = typeof LoanService !== "undefined" && LoanService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object])
], DashboardService);
export { DashboardService };
