import { EEVentTiming, EHospivillePermission, EMpStatus, EPosologyType, ETimingAbbreviation, EUnitsOfTime } from "@mapuilabs/mpl-interfaces";
import { Utils } from "../../../services/Utils";
import * as angular from "angular";
import * as moment from "moment";
export class DrugInstanceService {
    /** @ngInject */
    constructor(Api, $q, $translate, _, Access, ModalService) {
        this.Api = Api;
        this.$q = $q;
        this.$translate = $translate;
        this._ = _;
        this.Access = Access;
        this.ModalService = ModalService;
        /**
         * Path for general translation
         * @type {string}
         * @private
         */
        this._translatePath = "HOSPIVILLE.SERVICES.DRUG_INSTANCE.POSOLOGY.SENTENCE.";
        /**
         * Get a specific [[IDrugInstance]]
         * @param id The [[IDrugInstance]] [[Id]]
         * @return A promise containing the [[IDrugInstance]]
         */
        this.get = (id) => {
            const defer = this.$q.defer();
            if (!Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation) || !id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Read)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugInstances.get({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id,
                    id: id
                }, (res) => defer.resolve(res[0]), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Update an [[IDrugInstance]]
         * @param drugInstance The [[IDrugInstance]] to update
         */
        this.save = (drugInstance) => {
            const defer = this.$q.defer();
            if (!drugInstance || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugInstances.save({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id
                }, drugInstance, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Add an [[IDrugInstance]] to the dataBase
         * @param drugInstance The [[IDrugInstance]] to create
         */
        this.add = (drugInstance) => {
            const defer = this.$q.defer();
            if (!drugInstance || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugInstances.add({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id
                }, drugInstance, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Mark as deleted an [[IDrugInstance]]
         * @param drugInstance The [[IDrugInstance]] to delete
         */
        this.delete = (drugInstance) => {
            const defer = this.$q.defer();
            if (!drugInstance || !Utils.hasId(this.Access.patient) || !Utils.hasId(this.Access.patient.medicationReconciliation)) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            else if (!this.Access.authorize(EHospivillePermission.DBS_Edit)) {
                defer.reject(EMpStatus.NoAccess);
            }
            else {
                this.Api.drugInstances.delete({
                    pid: this.Access.patient._id,
                    mid: this.Access.patient.medicationReconciliation._id
                }, drugInstance, (res) => defer.resolve(res), () => defer.reject(EMpStatus.RejectByServer));
            }
            return defer.promise;
        };
        /**
         * Get the translation of a given dosage type
         * @param dosageType
         * @returns {string}
         */
        this.getDosageType = (dosageType) => {
            if (!this._hvConstType || (!dosageType && dosageType !== 0))
                return;
            return this._.find(this._hvConstType.dosages, { value: dosageType }).translate;
        };
        /**
         * Create [[IDrugInstance]] from [[ISimpleInstance]]
         * @param simple
         * @param source
         * @param idx
         */
        this.createDrugInstFromSimpleInst = (simple, source, idx) => {
            let drugInstance = {};
            const keys = Object.keys(simple);
            for (let k of keys) {
                if (k === "info") {
                    simple[k].source = { _id: Utils.getId(source) };
                    drugInstance.sources = [simple[k]];
                    delete simple[k];
                }
                drugInstance[k] = simple[k];
            }
            drugInstance.index = idx;
            return drugInstance;
        };
        /**
         *
         * @param simples
         * @param drugs
         * @param newSrc
         */
        this.mergeDIArrAndSIArr = (simples, drugs, newSrc) => {
            if (!simples.length)
                return drugs;
            let newInsts = [];
            if (!drugs.length) {
                // return simples.map((inst) => {this.createDrugInstFromSimpleInst(inst, newSrc)});
                let maxIdx = -1;
                for (let inst of simples)
                    newInsts.push(this.createDrugInstFromSimpleInst(inst, newSrc, ++maxIdx));
                return newInsts;
            }
            let maxIdx = drugs.length;
            for (let s of simples) {
                let isInArray = [];
                isInArray = drugs.map((d, index) => d.UCD13 === s.UCD13 ? index : undefined).filter(x => x >= 0);
                if (isInArray.length) {
                    s.info.source = { _id: Utils.getId(newSrc) };
                    for (let idx of isInArray) {
                        drugs[idx].sources ? drugs[idx].sources.push(s.info) : drugs[idx].sources = [s.info];
                    }
                }
                else {
                    newInsts.push(this.createDrugInstFromSimpleInst(s, newSrc, maxIdx));
                    maxIdx++;
                }
            }
            drugs.push(...newInsts);
            return drugs;
        };
        this.generateDosageSentence = (source) => {
            let sentence = "";
            if (source && source.dosageType) {
                if (source.dosageType == 14 && source.dosageDropInfo) { // drop
                    if (source.dosageDropInfo.left == source.dosageDropInfo.right && source.dosageDropInfo.left != 0) {
                        sentence += source.dosageDropInfo.left + " goutte";
                        if (source.dosageDropInfo.left > 1)
                            sentence += "s";
                        sentence += " dans chaque oeil";
                        return sentence;
                    }
                    if (source.dosageDropInfo.left) {
                        sentence += source.dosageDropInfo.left + " goutte";
                        if (source.dosageDropInfo.left > 1)
                            sentence += "s";
                        sentence += " oeil gauche";
                    }
                    if (source.dosageDropInfo.right) {
                        if (source.dosageDropInfo.left)
                            sentence += " ";
                        sentence += source.dosageDropInfo.right + " goutte";
                        if (source.dosageDropInfo.right > 1)
                            sentence += "s";
                        sentence += " oeil droit";
                    }
                }
                else if (source.dosageType != 14) {
                    sentence += (source.dosage || "") + " " + this.$translate.instant(this.getDosageType(source.dosageType));
                }
            }
            return sentence;
        };
        this.generatePosologySentence = (posology) => {
            if (!posology) {
                return null;
            }
            let sentence = "";
            if (posology.code) {
                for (let code of posology.code) {
                    switch (code) {
                        case ETimingAbbreviation.BID:
                            sentence += " 2 fois pas jour";
                            break;
                        case ETimingAbbreviation.TID:
                            sentence += " 3 fois par jour";
                            break;
                        case ETimingAbbreviation.QID:
                            sentence += " 4 fois par jour";
                            break;
                        case ETimingAbbreviation.AM:
                            sentence += " tous les matins";
                            break;
                        case ETimingAbbreviation.PM:
                            sentence += " tous les après midi";
                            break;
                        case ETimingAbbreviation.QD:
                            sentence += " tous les jours";
                            break;
                        case ETimingAbbreviation.QOD:
                            sentence += " QOD";
                            break;
                        case ETimingAbbreviation.Q4H:
                            sentence += " toutes les 4 heures";
                    }
                }
            }
            sentence += " " + this.generateFrequencySentence(posology) +
                " " + this.generateDurationSentence(posology) +
                " " + this.generatePeriodSentence(posology) +
                " " + this.generateMomentSentence(posology) +
                " " + this.generateBoundsSentence(posology) +
                " " + this.generateOtherSentence(posology);
            if (posology.sentence) {
                return sentence += " " + posology.sentence;
            }
            return sentence;
        };
        this.generateFrequencySentence = (posology) => {
            let sentence = "";
            if (!posology.repeat)
                return sentence;
            if (posology.repeat.frequency) {
                sentence += posology.repeat.frequency.toString() + " ";
                if (posology.repeat.frequencyMax) {
                    sentence += this.$translate.instant(this._translatePath + "TO") + " " +
                        posology.repeat.frequencyMax.toString() + " ";
                }
                sentence += this.$translate.instant(this._translatePath + "TIMES");
            }
            else if (posology.repeat.frequencyMax) {
                sentence += posology.repeat.frequencyMax.toString() + " " +
                    this.$translate.instant(this._translatePath + "TIMES") + " " +
                    this.$translate.instant(this._translatePath + "MAX");
            }
            return sentence;
        };
        this.generateDurationSentence = (posology) => {
            let sentence = "";
            if (!posology.repeat) {
                return sentence;
            }
            if (posology.repeat.duration && posology.repeat.durationUnit)
                sentence += this.$translate.instant(this._translatePath + "FOR") + " " +
                    posology.repeat.duration.toString() + " " +
                    this.$translate.instant(this._getUnitOfTime(posology.repeat.durationUnit).translate, { plural: posology.repeat.duration > 1 ? "s" : "" });
            return sentence;
        };
        this.generatePeriodSentence = (posology) => {
            let sentence = "";
            if (!posology.repeat)
                return sentence;
            if (posology.repeat.period && posology.repeat.periodUnit) {
                sentence += this.$translate.instant(this._translatePath + "EVERY");
                if (this._getUnitOfTime(posology.repeat.periodUnit).gender == 1) {
                    sentence = sentence.substring(0, sentence.length - 1);
                    sentence += "tes";
                }
                sentence += " " + this.$translate.instant(this._translatePath + "LES") + " ";
                if (!posology.repeat.periodMax && posology.repeat.period > 1)
                    sentence += posology.repeat.period.toString() + " ";
                if (posology.repeat.periodMax) {
                    sentence += posology.repeat.period.toString() + " ";
                    sentence += this.$translate.instant(this._translatePath + "TO") + " " + posology.repeat.periodMax.toString() + " ";
                }
                if (posology.repeat.periodUnit) {
                    sentence += this.$translate.instant(this._getUnitOfTime(posology.repeat.periodUnit).translate, { plural: "s" });
                }
            }
            if (posology.repeat.dayOfWeek) {
                sentence += " " + this.$translate.instant(this._translatePath + "THE") + " ";
                let found = 0;
                for (let elem of posology.repeat.dayOfWeek) {
                    for (let day of this._daysOfWeek) {
                        if (elem == day.value) {
                            sentence += this.$translate.instant(day.translate);
                            found += 1;
                            if (found < posology.repeat.dayOfWeek.length)
                                sentence += ", ";
                            else
                                sentence += " ";
                        }
                    }
                }
            }
            return sentence;
        };
        this.generateMomentSentence = (posology) => {
            let sentence = "";
            if (!posology.repeat)
                return sentence;
            if (posology.repeat.time) {
                let found = 0;
                for (let time of posology.repeat.time) {
                    sentence += this.$translate.instant(this._translatePath + "AT") + " "
                        + time.hours + "h";
                    if (time.minutes < 10)
                        sentence += "0";
                    sentence += time.minutes;
                    found += 1;
                    if (found < posology.repeat.time.length)
                        sentence += ", ";
                }
            }
            if (posology.repeat.when && this._hvConstType) {
                let found = 0;
                if (posology.repeat.time) {
                    sentence += ", ";
                }
                for (let when of posology.repeat.when) {
                    for (let event of this._hvConstType.dailyEvents) {
                        if (when == event.value) {
                            sentence += " " + this.$translate.instant(event.translate);
                        }
                    }
                    found += 1;
                    if (found < posology.repeat.when.length)
                        sentence += ", ";
                }
            }
            return sentence;
        };
        this.generateBoundsSentence = (posology) => {
            let sentence = "";
            if (!posology.repeat)
                return sentence;
            if (posology.repeat.bounds) {
                if (posology.repeat.bounds.boundsPeriod && posology.repeat.bounds.boundsPeriod.start) {
                    sentence += this.$translate.instant(this._translatePath + "START") + " "
                        + moment(posology.repeat.bounds.boundsPeriod.start).format("DD/MM/YYYY");
                    if (posology.repeat.bounds.boundsPeriod.end)
                        sentence += " ";
                }
                if (posology.repeat.bounds.boundsPeriod && posology.repeat.bounds.boundsPeriod.end) {
                    sentence += this.$translate.instant(this._translatePath + "END") + " "
                        + moment(posology.repeat.bounds.boundsPeriod.end).format("DD/MM/YYYY");
                }
                if (posology.repeat.bounds.boundsDuration && posology.repeat.bounds.boundsDuration.unit && posology.repeat.bounds.boundsDuration.value) {
                    sentence += this.$translate.instant(this._translatePath + "FOR") + " "
                        + posology.repeat.bounds.boundsDuration.value + " "
                        + this.$translate.instant(this._getUnitOfTime(posology.repeat.bounds.boundsDuration.unit).translate);
                    if (posology.repeat.bounds.boundsDuration.value > 1 && posology.repeat.bounds.boundsDuration.unit != EUnitsOfTime.Month)
                        sentence += "s";
                }
            }
            return sentence;
        };
        this.generateOtherSentence = (posology) => {
            let sentence = "";
            if (!posology.ifNeeded)
                return sentence;
            else {
                sentence += "si besoin";
            }
            return sentence;
        };
        /**
         * Return a presetted ITiming based on the given type
         * @param {EPosologyType} type
         * @returns {any}
         */
        this.getPresetPosology = (type) => {
            let preset = {
                // Morning
                11: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.MORN],
                        offset: null
                    },
                    code: null
                },
                // Noon
                1: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.NOON],
                        offset: null
                    },
                    code: null
                },
                // Evening
                2: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [3],
                        offset: null
                    },
                    code: null
                },
                // M_N_E
                3: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.MORN, EEVentTiming.NOON, EEVentTiming.EVE],
                        offset: null
                    },
                    code: null
                },
                // M_E
                4: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [1, 3],
                        offset: null
                    },
                    code: null
                },
                // DAY
                5: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: 1,
                        periodMax: null,
                        periodUnit: EUnitsOfTime.Day,
                        dayOfWeek: null,
                        time: null,
                        when: null,
                        offset: null
                    },
                    code: null
                },
                // BEDTIME
                6: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [6],
                        offset: null
                    },
                    code: null
                },
                //Matin - midi - soir - coucher
                7: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.MORN, EEVentTiming.NOON, EEVentTiming.EVE, EEVentTiming.HS],
                        offset: null
                    },
                    code: null
                },
                //Toute les 4h
                8: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: 4,
                        periodMax: null,
                        periodUnit: EUnitsOfTime.Hour,
                        dayOfWeek: null,
                        time: null,
                        when: null,
                        offset: null
                    },
                    code: null
                },
                //Toute les 6h
                9: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: 6,
                        periodMax: null,
                        periodUnit: EUnitsOfTime.Hour,
                        dayOfWeek: null,
                        time: null,
                        when: null,
                        offset: null
                    },
                    code: null
                },
                //Matin Midi
                12: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.MORN, EEVentTiming.NOON],
                        offset: null
                    },
                    code: null
                },
                //Gouter
                13: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: null,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.SNACK],
                        offset: null
                    },
                    code: null
                },
                //Toute les 8h
                14: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: 8,
                        periodMax: null,
                        periodUnit: EUnitsOfTime.Hour,
                        dayOfWeek: null,
                        time: null,
                        when: null,
                        offset: null
                    },
                    code: null
                },
                //Midi-Soir
                15: {
                    event: null,
                    repeat: {
                        bounds: null,
                        count: null,
                        countMax: null,
                        duration: null,
                        durationMax: null,
                        durationUnit: null,
                        frequency: null,
                        frequencyMax: null,
                        period: 8,
                        periodMax: null,
                        periodUnit: null,
                        dayOfWeek: null,
                        time: null,
                        when: [EEVentTiming.NOON, EEVentTiming.EVE],
                        offset: null
                    },
                    code: null
                }
            };
            return preset[type];
        };
        this.compareDosages = (src1, src2) => {
            if (src1.dosageType != src2.dosageType) {
                // console.log("dosage diff : type");
                return false;
            }
            if (src1.dosageType != 14 && src1.dosage != src2.dosage) {
                // console.log("dosage diff : dosage value");
                return false;
            }
            if (src1.dosageType == 14 && ((!src1.dosageDropInfo || !src2.dosageDropInfo) || (src1.dosageDropInfo.left != src2.dosageDropInfo.left
                || src1.dosageDropInfo.right != src2.dosageDropInfo.right))) {
                // console.log("dosafe diff : dropInfo");
                return false;
            }
            return true;
        };
        this.comparePosologiesArray = (arr1, arr2) => {
            if (!arr1 && !arr2)
                return true;
            if (!arr1 || !arr2)
                return false;
            if (arr1.length != arr2.length)
                return false;
            arr1 = angular.copy(arr1);
            arr2 = angular.copy(arr2);
            for (let i in arr1) {
                let found = false;
                for (let y in arr2) {
                    if (this.comparePosologies(arr1[i], arr2[y])) {
                        found = true;
                        arr2.splice(parseInt(y), 1);
                        break;
                    }
                }
                if (!found)
                    return false;
            }
            return true;
        };
        this.comparePosologies = (poso1, poso2) => {
            if (!poso1 && !poso2)
                return true;
            if ((poso1.sentence && !poso2.sentence) || (!poso1.sentence && poso2.sentence))
                return false;
            if (poso1.sentence && poso2.sentence)
                return poso1.sentence == poso2.sentence;
            //if posos have same preset Code and not specific
            if (poso1.presetCode != EPosologyType.Other && poso1.presetCode === poso2.presetCode)
                return true;
            // if (poso1.presetCode != poso2.presetCode) {
            //    console.log("diff : presetCode");
            //    return false;
            // }
            if (poso1.ifNeeded !== poso2.ifNeeded) {
                return false;
            }
            if (!poso1.repeat && !poso2.repeat) {
                return true;
            }
            if (!poso1.repeat || !poso2.repeat)
                return false;
            if (poso1.repeat.bounds && poso2.repeat.bounds) {
                if (!angular.equals(poso1.repeat.bounds, poso2.repeat.bounds)) {
                    // console.log("diff: bounds not equals");
                    return false;
                }
            }
            if (poso1.repeat.count != poso2.repeat.count) {
                // console.log("diff:  count");
                return false;
            }
            if (poso1.repeat.countMax != poso2.repeat.countMax) {
                // console.log("dff: countMax");
                return false;
            }
            if (poso1.repeat.duration != poso2.repeat.duration) {
                // console.log("diff: duration");
                return false;
            }
            if (poso1.repeat.durationUnit != poso2.repeat.durationUnit) {
                // console.log("diff: durationUnit");
                return false;
            }
            if (poso1.repeat.frequency != poso2.repeat.frequency) {
                // console.log("diff: frequency");
                return false;
            }
            if (poso1.repeat.frequencyMax != poso2.repeat.frequencyMax) {
                // console.log("diff: frequencyMax");
                return false;
            }
            if (poso1.repeat.period != poso2.repeat.period) {
                // console.log("diff: period");
                return false;
            }
            if (poso1.repeat.periodMax != poso2.repeat.periodMax) {
                // console.log("diff: periodMax");
                return false;
            }
            if (poso1.repeat.periodUnit != poso2.repeat.periodUnit) {
                // console.log("diff: periodUnit");
                return false;
            }
            if (!this._compareArray(poso1.repeat.dayOfWeek, poso2.repeat.dayOfWeek)) {
                // console.log("dff : compare dayOfWeek");
                return false;
            }
            if (!this._deepCompareArray(poso1.repeat.time, poso2.repeat.time)) {
                // console.log("diff: deep compare time");
                return false;
            }
            if (!this._compareArray(poso1.repeat.when, poso2.repeat.when)) {
                // console.log("diff: compare when");
                return false;
            }
            if (poso1.repeat.offset != poso2.repeat.offset) {
                // console.log("diff: offset");
                return false;
            }
            return true;
        };
        this.openSpecificPosologyModal = (timings) => {
            const defer = this.$q.defer();
            this.ModalService.show({
                component: "hv-modal-specific-posology",
                bindings: {
                    posologies: timings
                },
                escapeToClose: false
            }).then((res) => {
                defer.resolve((JSON.parse(angular.toJson(res))));
            });
            return defer.promise;
        };
        this._deepCompareArray = (arr1, arr2) => {
            arr1 = angular.copy(arr1);
            arr2 = angular.copy(arr2);
            if (!arr1 && !arr2)
                return true;
            if (!arr1 || !arr2)
                return false;
            if (arr1.length != arr2.length)
                return false;
            if (!arr1.length)
                return true;
            for (let idx1 in arr1) {
                let found = false;
                for (let idx2 in arr2) {
                    if (arr1[idx1] == arr2[idx2]) {
                        found = true;
                        arr2.splice(parseInt(idx2), 1);
                        break;
                    }
                }
                if (!found)
                    return false;
            }
            return true;
        };
        this._compareArray = (arr1, arr2) => {
            if (!arr1 && !arr2)
                return true;
            if (!arr1 || !arr2)
                return false;
            arr1.sort();
            arr2.sort();
            return angular.equals(arr1, arr2);
        };
        this._getUnitOfTime = (value) => {
            for (let unit of this._unitOfTimes) {
                if (unit.value == value) {
                    return unit;
                }
            }
            return null;
        };
        this._getDaysOfWeek = (value) => {
            for (let day of this._daysOfWeek) {
                if (day.value == value) {
                    return day.translate;
                }
            }
            return "";
        };
        this.Api.constType.get((ans) => {
            this._daysOfWeek = ans.daysOfWeek;
            this._unitOfTimes = ans.unitsOfTime;
        });
        this.Api.hvConstType.get((ans) => {
            this._hvConstType = ans;
        });
        this._emptyInstance = {
            validSource: {},
            drug: null,
            denomination: null,
            codeATC: null,
            UCD13: null,
            CIP13: null,
            DCI: null,
            index: null
        };
    }
    get emptyInstance() {
        return angular.copy(this._emptyInstance);
    }
}
