import { Injector } from '@angular/core';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsDashboardWidgetGroupListComponent } from '@app/hospistock/shared/components/dashboard/widget/group-list/hs-dashboard-widget-group-list.component';
import { HS_DASHBOARD_WIDGET_LABO_LIST_CONFIG, HsDashboardWidgetLaboListComponent } from '@app/hospistock/shared/components/dashboard/widget/labo-list/hs-dashboard-widget-labo-list.component';
import { HsDashboardWidgetPublicationsSummaryComponent } from '@app/hospistock/shared/components/dashboard/widget/labo-summary/hs-dashboard-widget-publications-summary.component';
import { HsDashboardWidgetNewsComponent } from '@app/hospistock/shared/components/dashboard/widget/news/hs-dashboard-widget-news.component';
import { Layout, Widget } from '@app/shared/components/widgets/dashboard/widgets';
import { EHsHealthProductTypes, EHsUserTypes } from '@mapuilabs/mpl-interfaces';
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Dashboard Service Config
// -----------------------------------------------------------------------------------------------------
const endPoint = 'api/hospistock/labo/medical-device/dashboard';
const viewType = EHsUserTypes.LABO;
const productType = EHsHealthProductTypes.MEDICAL_DEVICE;
export const hsLaboMedicalDeviceDashboardServiceConfig = {
    endPoint,
    viewType,
    productType
};
export const hsLaboMedicalDeviceDashboardLayout = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    const widgetLaboListConfig = {
        columns: [
            {
                label: translationService.instant('DASHBOARD.WIDGET.LABO_LIST.TABLE.NAME'),
                attribute: 'name'
            },
            {
                label: translationService.instant('DASHBOARD.WIDGET.LABO_LIST.TABLE.CREATION_DATE'),
                attribute: 'creationDate'
            }
        ]
    };
    return new Layout([
        new Widget(HsDashboardWidgetPublicationsSummaryComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }]),
        new Widget(HsDashboardWidgetNewsComponent, [{ 0: 1 }, { 1: 1 }, { 1: 1 }]),
        new Widget(HsDashboardWidgetLaboListComponent, [{ 0: 1 }, { 0: 1 }, { 0: 1 }], Injector.create({
            providers: [{ provide: HS_DASHBOARD_WIDGET_LABO_LIST_CONFIG, useValue: widgetLaboListConfig }]
        })),
        new Widget(HsDashboardWidgetGroupListComponent, [{ 0: 1 }, { 1: 1 }, { 2: 0 }])
    ], null);
};
