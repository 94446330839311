var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { EFileType } from '@mapuilabs/mpl-interfaces';
import { UploadService } from '@services/upload/upload.service';
import { FileService } from '@services/file/file.service';
let FileUploaderComponent = class FileUploaderComponent {
    constructor(_fuseTranslationLoaderService, _uploadService, _fileService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._uploadService = _uploadService;
        this._fileService = _fileService;
        /**
         * Used by the parent to be notified when the file list change
         */
        this.onSelectionChange = new EventEmitter();
        this.fileContainers = new Set();
        /**
         * Start the uploading process
         * @param saveFilesInDb
         */
        this.upload = (saveFilesInDb) => {
            return new Observable((observer) => {
                if (!this.fileContainers.size) {
                    observer.next();
                    observer.complete();
                    return;
                }
                // Start the upload and save the progress map
                saveFilesInDb
                    ? this._uploadService.uploadAndSave(this.fileContainers, this.type)
                    : this._uploadService.upload(this.fileContainers, this.type);
                // Convert the progress map into an array
                const allProgressObservables = [];
                this.fileContainers.forEach((ctnr) => {
                    allProgressObservables.push(ctnr.progress.pipe());
                    ctnr.progress.subscribe((val) => {
                        ctnr.response = val;
                    }, (err) => {
                        ctnr.error = err;
                    }, () => {
                        ctnr.completed = true;
                    });
                });
                // When all progress-observables are completed...
                forkJoin(allProgressObservables).subscribe((end) => {
                    observer.next(this.fileContainers);
                    observer.complete();
                }, (err) => {
                    observer.error(err);
                    console.log(err);
                });
            });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    /**
     * Triggered when a file is added in the hidden input.
     * @param data
     */
    onFilesAdded(data) {
        const files = this.file.nativeElement.files;
        if (!this.multiple && files.length) {
            this.fileContainers.clear();
        }
        for (const key in files) {
            if (!isNaN(parseInt(key))) {
                this.fileContainers.add({ file: files[key] });
            }
        }
        this.onSelectionChange.emit(this.fileContainers);
    }
    /**
     * Simulate a click on the hidden file input
     */
    addFiles() {
        this.file.nativeElement.click();
    }
    /**
     * Remove an item from the files Set
     * @param item
     */
    removeItem(item) {
        this.fileContainers.delete(item);
        this.onSelectionChange.emit(this.fileContainers);
    }
    /**
     * Tells if the send button should be disabled
     */
    disableSendButton() {
        let ret = true;
        this.fileContainers.forEach((fileCtnr) => {
            if (!fileCtnr.progress) {
                ret = false;
            }
        });
        return ret;
    }
    getIcon(file) {
        return this._fileService.getIconFromMime(file.type);
    }
    /**
     * Used to clear the files from an external component
     */
    clear() {
        this.fileContainers.clear();
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof EFileType !== "undefined" && EFileType) === "function" ? _d : Object)
], FileUploaderComponent.prototype, "type", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FileUploaderComponent.prototype, "multiple", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], FileUploaderComponent.prototype, "hideSendButton", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], FileUploaderComponent.prototype, "onSelectionChange", void 0);
__decorate([
    ViewChild('file', { static: false }),
    __metadata("design:type", Object)
], FileUploaderComponent.prototype, "file", void 0);
FileUploaderComponent = __decorate([
    Component({
        selector: 'file-uploader',
        template: require('./file-uploader.component.html').default,
        styles: [require('./file-uploader.component.scss')]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof UploadService !== "undefined" && UploadService) === "function" ? _b : Object, typeof (_c = typeof FileService !== "undefined" && FileService) === "function" ? _c : Object])
], FileUploaderComponent);
export { FileUploaderComponent };
