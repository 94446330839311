export const locale = {
    lang: 'fr',
    data: {
        FILE_TO_DBS: {
            TITLE: `Ajout de l'ordonnance officine au bilan médicamenteux`,
            FILE_VIEW: {
                FROM: `Document envoyé par :`,
                PATIENT: `Patient :`
            },
            PRESCRIPTION_VIEW: {
                TITLE: `Prescription`
            },
            ADDED: 'Ajouté à la prescription',
            ALREADY_ADDED: 'Médicament déjà présent dans le bilan',
            BUTTONS: {
                GO_TO_DBS: `Aller vers le bilan médicamenteux`
            },
            ALERT_SAVE: {
                TITLE: `Changements non sauvegardés`,
                MESSAGE: `Vous avez fait des changements sur la prescription qui n'ont pas été sauvegardés. Êtes-vous sûr(e) de vouloir quitter la page ?`
            }
        }
    }
};
