var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var AdminHsNewsComponent_1;
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CHsDashboardIconsCategory } from '@app/hospistock/shared/constTypes/hs-dashboard.const';
import { EHsNewsCategory, EHTTPStatus, IVirtualScrollPagination } from '@mapuilabs/mpl-interfaces';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { Utils } from '@main/services/Utils';
import { DialogService } from '@services/dialog/dialog.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { AdminHsNewsService } from '@services/admin/stockout/news.service';
let AdminHsNewsComponent = AdminHsNewsComponent_1 = class AdminHsNewsComponent {
    constructor(_fuseTranslationLoaderService, _translationService, _dialogService, _snackbarService, _adminHsNewsService, fuseNavigationService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this._dialogService = _dialogService;
        this._snackbarService = _snackbarService;
        this._adminHsNewsService = _adminHsNewsService;
        this.fuseNavigationService = fuseNavigationService;
        this.quillModules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }],
                [{ indent: '-1' }, { indent: '+1' }],
                ['image']
            ]
        };
        this.ecategorys = EHsNewsCategory;
        this.iconsCategory = CHsDashboardIconsCategory;
        this._unsubscribe = new Subject();
        this.isUpdatingWidget = false;
        this.isAddingNewLine = false;
        this.linesList = [];
        this.dataSource = new MatTableDataSource();
        this.linesTableColumns = ['title', 'publicationDate', 'expandIcon'];
        this.isAddingTag = false;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('NEWS_HOSPISTOCK.TITLE'),
            icon: 'event_note'
        };
    }
    // Accessors
    get lines() {
        return this.linesList;
    }
    get titleControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('title');
    }
    get titleColorControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('titleColor');
    }
    get backgroundControl() {
        var _a;
        return (_a = this.formWidget) === null || _a === void 0 ? void 0 : _a.get('background');
    }
    get lineNameControl() {
        return this.formLine.get('title');
    }
    get lineCategoryControl() {
        return this.formLine.get('category');
    }
    get linePubDateControl() {
        return this.formLine.get('publicationDate');
    }
    get lineContentControl() {
        return this.formLine.get('content');
    }
    get lineKBArticleControl() {
        return this.formLine.get('knowledgeBaseArticle');
    }
    /**
     * Check if form can be saved.
     */
    get canSaveFormLine() {
        if (!this.formLine) {
            return false;
        }
        return !this.formLine.invalid && this.formLine.dirty;
    }
    get tagNameControl() {
        var _a;
        return (_a = this.formTag) === null || _a === void 0 ? void 0 : _a.get('tagName');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    //
    ngOnInit() {
        this._dbRefreshWidget();
        this.linesList = this.lines || [];
    }
    ngAfterViewInit() {
        this._refreshDataSource();
        this.dataSource.paginator = this.paginator;
    }
    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create form from a list of controls.
     * @private
     */
    static _createFormGroup(form, controls) {
        var _a;
        if (!form) {
            return;
        }
        for (const control of controls) {
            if (!(control === null || control === void 0 ? void 0 : control.name)) {
                return;
            }
            form.setControl(control.name, new UntypedFormControl({ value: control.defaultValue, disabled: (_a = control.disabled) !== null && _a !== void 0 ? _a : false }, control.validators));
        }
    }
    /**
     * Create form for line creation and its getters.
     * @private
     */
    createLineFormGroup(line) {
        var _a;
        this.formLine = new UntypedFormGroup({});
        // Update preview when there are changes on a line
        this.formLine.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
            if (line) {
                this.updateLineListFromForm(line);
            }
        });
        AdminHsNewsComponent_1._createFormGroup(this.formLine, [
            {
                name: 'title',
                defaultValue: line === null || line === void 0 ? void 0 : line.title,
                validators: Validators.required
            },
            {
                name: 'publicationDate',
                defaultValue: line === null || line === void 0 ? void 0 : line.publicationDate,
                validators: Validators.required
            },
            {
                name: 'content',
                defaultValue: (_a = line === null || line === void 0 ? void 0 : line.content) === null || _a === void 0 ? void 0 : _a.message
            },
            {
                name: 'category',
                defaultValue: line === null || line === void 0 ? void 0 : line.category,
                validators: Validators.required
            }
        ]);
    }
    isNew(line) {
        return line._id === 'new';
    }
    /**
     * Create form for tag creation and its getters.
     * @private
     */
    createTagFormGroup() {
        this.formTag = new UntypedFormGroup({});
        AdminHsNewsComponent_1._createFormGroup(this.formTag, [
            {
                name: 'tagName',
                validators: Validators.required
            }
        ]);
    }
    /**
     * Set given line as the expanded line in table.
     * @param line
     */
    showLineDetails(line) {
        if (this.compareExpandedAndRowLine(line)) {
            this.expandedLine = null;
            if (!this.formLine.pristine && !this.isNew(line)) {
                this.saveLine(line);
            }
        }
        else {
            if (this.canSaveFormLine) {
                this.saveLine(this.expandedLine);
            }
            this.expandedLine = line;
        }
        this.createLineFormGroup(this.expandedLine);
        this.createTagFormGroup();
        Utils.editObjectInstanceInArray(this.linesList, line);
    }
    /**
     * Compare the expanded line row and a given line row.
     * @param line
     */
    compareExpandedAndRowLine(line) {
        var _a;
        return ((_a = this.expandedLine) === null || _a === void 0 ? void 0 : _a._id) === (line === null || line === void 0 ? void 0 : line._id);
    }
    compareDate(newsDate) {
        return new Date() < new Date(newsDate);
    }
    /**
     * Show or not show the tag form.
     */
    toggleShowTagForm() {
        this.isAddingTag = !this.isAddingTag;
    }
    /**
     * Add a tag in the given line.
     * @param line
     */
    addTag(line) {
        var _a;
        if (!line) {
            return;
        }
        if (!line.tags) {
            line.tags = [];
        }
        line.tags.push({
            title: {
                message: (_a = this.tagNameControl) === null || _a === void 0 ? void 0 : _a.value,
                color: '#000000'
            },
            state: null,
            color: '#dcdcdc'
        });
        Utils.editObjectInstanceInArray(this.linesList, line);
        if (!this.isNew(line)) {
            this._dbUpdateLine(line, 'NEWS_HOSPISTOCK.SNACKBAR.TAG_ADDED');
        }
        this.toggleShowTagForm();
        this.formTag.reset();
    }
    /**
     * Remove a tag from a given line.
     * @param line
     * @param tag
     */
    removeTag(line, tag) {
        const currLine = this._findOneLineById(line._id);
        if (!(currLine === null || currLine === void 0 ? void 0 : currLine.tags)) {
            return;
        }
        currLine.tags = currLine.tags.reduce((acc, curr) => {
            var _a, _b;
            if (((_a = curr.title) === null || _a === void 0 ? void 0 : _a.message) !== ((_b = tag.title) === null || _b === void 0 ? void 0 : _b.message) && curr.state !== tag.state) {
                acc.push(curr);
            }
            return acc;
        }, []);
        if (!this.isNew(line)) {
            this._dbUpdateLine(line, 'NEWS_HOSPISTOCK.SNACKBAR.TAG_REMOVED');
        }
    }
    /**
     * Add new line in widget instance
     */
    addRow() {
        this.isAddingNewLine = true;
        const line = {
            _id: 'new',
            title: 'Nouvelle ligne',
            category: EHsNewsCategory.News,
            publicationDate: null,
            content: {
                message: null,
                color: '#000000'
            }
        };
        if (!this.linesList) {
            this.linesList = [];
        }
        this.linesList.unshift(line);
        this.updateLinesList();
        this.showLineDetails(line);
    }
    /**
     * Refresh MatTableDataSource.data to trigger UI table refresh
     * @private
     */
    _refreshDataSource() {
        this.dataSource.data = this.linesList;
    }
    /**
     * Update page buttons list form an empty array.
     * @private
     */
    updateLineListFromForm(line) {
        var _a, _b, _c, _d;
        const currLine = this._findOneLineById(line._id) || {};
        currLine.title = (_a = this.lineNameControl) === null || _a === void 0 ? void 0 : _a.value;
        currLine.publicationDate = (_b = this.linePubDateControl) === null || _b === void 0 ? void 0 : _b.value;
        if (!currLine.content) {
            currLine.content = { color: '#000000' };
        }
        currLine.content.message = (_c = this.lineContentControl) === null || _c === void 0 ? void 0 : _c.value;
        currLine.category = (_d = this.lineCategoryControl) === null || _d === void 0 ? void 0 : _d.value;
    }
    /**
     * Update page buttons list form an empty array.
     * @private
     */
    updateLinesList() {
        this.linesList = Object.assign([], this.linesList);
        this._refreshDataSource();
    }
    /**
     * Sort lines list from the latest to the oldest publication date.
     */
    sortLines() {
        this.linesList.sort((a, b) => new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime());
        this.updateLinesList();
    }
    /**
     * Add or update line in database.
     * @param line
     */
    saveLine(line) {
        if (this.isNew(line)) {
            return this._dbAddLine(line, 'NEWS_HOSPISTOCK.SNACKBAR.LINE_ADDED');
        }
        this._dbUpdateLine(line, 'NEWS_HOSPISTOCK.SNACKBAR.LINE_SAVED');
    }
    /**
     * Delete line from database.
     * @param line
     */
    deleteLine(line) {
        this._dbDeleteLine(line, 'NEWS_HOSPISTOCK.SNACKBAR.LINE_REMOVED');
    }
    /**
     * Cancel line addition.
     */
    cancelLine() {
        var _a;
        this.linesList = (_a = this.linesList) === null || _a === void 0 ? void 0 : _a.reduce((acc, curr) => {
            if (!this.isNew(curr)) {
                acc.push(curr);
            }
            return acc;
        }, []);
        this._refreshDataSource();
        this.formLine.reset();
        this.formTag.reset();
        this.isAddingNewLine = false;
    }
    /**
     * Check forms, tell user there is an unfinished element and save widget.
     */
    saveWidget() {
        var _a;
        if (this.formWidget.invalid || this.formWidget.pristine) {
            return this._snackbarService.openCustomError(this._translationService.instant('NEWS_HOSPISTOCK.DETAILS.INVALID_FORM'));
        }
        if (((_a = this.formLine) === null || _a === void 0 ? void 0 : _a.pristine) === false) {
            this._dialogService
                .openConfirmDialog({
                title: this._translationService.instant('NEWS_HOSPISTOCK.DETAILS.RESET.TITLE'),
                message: this._translationService.instant('NEWS_HOSPISTOCK.DETAILS.RESET.MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.YES'),
                cancelButton: this._translationService.instant('COMMONS.NO')
            })
                .pipe(takeUntil(this._unsubscribe))
                .subscribe((doesCancel) => {
                if (doesCancel) {
                    this._saveWidget();
                }
            }, console.error);
        }
        else {
            this._saveWidget();
        }
    }
    /**
     * Match a given line I with a line in the widget.
     * @param id
     * @private
     */
    _findOneLineById(id) {
        var _a;
        return (_a = this.linesList) === null || _a === void 0 ? void 0 : _a.find((l) => l._id === id);
    }
    /**
     * Toggle update or add widget in database.
     * @private
     */
    _saveWidget() {
        return this._dbRefreshWidget();
    }
    /**
     * Add one widget line in database, update lines list after save or show error message if it fails.
     * @param line
     * @param message
     * @private
     */
    _dbAddLine(line, message = 'NEWS_HOSPISTOCK.SNACKBAR.LINE_SAVED') {
        delete line._id;
        this.isUpdatingWidget = true;
        this._adminHsNewsService
            .add(line)
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._dbRefreshWidget(() => {
                this.isAddingNewLine = false;
                this.isUpdatingWidget = false;
                this.formLine.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Update one widget line in database, update lines list after save or show error message if it fails.
     * @param line
     * @param message
     * @private
     */
    _dbUpdateLine(line, message = 'NEWS_HOSPISTOCK.SNACKBAR.WIDGET_SAVED') {
        this.isUpdatingWidget = true;
        this._adminHsNewsService
            .save(this._findOneLineById(line._id))
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this._dbRefreshWidget(() => {
                this.isUpdatingWidget = false;
                this.formLine.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Add one widget line in database, update lines list after save or show error message if it fails.
     * @param line
     * @param message
     * @private
     */
    _dbDeleteLine(line, message = 'NEWS_HOSPISTOCK.SNACKBAR.LINE_SAVED') {
        this._adminHsNewsService.delete(line).subscribe((res) => {
            if (res !== 'OK') {
                this.isUpdatingWidget = false;
                console.error(res);
                return this._snackbarService.openDefaultError();
            }
            this._dbRefreshWidget(() => {
                this.isUpdatingWidget = false;
                this.formLine.markAsPristine();
                this._snackbarService.open(this._translationService.instant(message));
            });
        }, this._snackbarService.openError);
    }
    /**
     * Get full widget from database.
     * @param callback
     * @private
     */
    _dbRefreshWidget(callback) {
        this._adminHsNewsService
            .getAll()
            .pipe(take(1))
            .subscribe((res) => {
            if (!res) {
                this.isUpdatingWidget = false;
                return this._snackbarService.openErrorFromStatus(EHTTPStatus.InternalServerError);
            }
            this.linesList = res;
            this.updateLinesList();
            if (callback) {
                callback();
            }
        }, this._snackbarService.openErrorFromStatus);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_g = typeof IVirtualScrollPagination !== "undefined" && IVirtualScrollPagination) === "function" ? _g : Object)
], AdminHsNewsComponent.prototype, "news", void 0);
__decorate([
    ViewChild(MatPaginator),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], AdminHsNewsComponent.prototype, "paginator", void 0);
AdminHsNewsComponent = AdminHsNewsComponent_1 = __decorate([
    Component({
        selector: 'mpx-admin-hs-news.component',
        template: require('./admin-hs-news.component.html').default,
        styles: [require('./admin-hs-news.component.scss')],
        animations: [
            fuseAnimations,
            trigger('lineDetails', [
                state('collapsed', style({ height: '0px', minHeight: '0' })),
                state('expanded', style({ height: '*' })),
                transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
            ])
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof DialogService !== "undefined" && DialogService) === "function" ? _c : Object, typeof (_d = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _d : Object, typeof (_e = typeof AdminHsNewsService !== "undefined" && AdminHsNewsService) === "function" ? _e : Object, typeof (_f = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _f : Object])
], AdminHsNewsComponent);
export { AdminHsNewsComponent };
