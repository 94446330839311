import { EVoucherType } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class MpSidenavSummaryController {
    /** @ngInject */
    constructor($translate, StatusService, MemberService, ModalService) {
        this.$translate = $translate;
        this.StatusService = StatusService;
        this.MemberService = MemberService;
        this.ModalService = ModalService;
        /**
         * Get the name of the [[IMember]] who made the validation step
         * @private
         */
        this._getValidName = () => {
            if (Utils.hasId(this.loan.validPerson)) {
                this.MemberService.get(this.loan.validPerson._id)
                    .then((member) => {
                    this.loan.validPerson = member;
                });
            }
        };
        /**
         * Get the name of the lender [[IMember]]
         * @private
         */
        this._getLoanName = () => {
            if (Utils.hasId(this.loan.loanPerson)) {
                this.MemberService.get(this.loan.loanPerson._id)
                    .then((member) => {
                    this.loan.loanPerson = member;
                });
            }
        };
        /**
         * Get the name of the borrower [[IMember]]
         * @private
         */
        this._getBorrowingName = () => {
            if (Utils.hasId(this.loan.borrowingPerson)) {
                this.MemberService.get(this.loan.borrowingPerson._id)
                    .then((member) => {
                    this.loan.borrowingPerson = member;
                });
            }
        };
        /**
         * Get the current selected language
         * @returns {string} the current language
         */
        this.getLang = () => {
            return this.$translate.use();
        };
        /**
         * Generate voucher for the given [[ILoan]], suggest loans for multiline voucher
         * @param {ILoan} loan
         * @param type
         */
        this.generateVoucher = (type) => {
            if (type == 'loan')
                this.voucher = EVoucherType.Loan;
            else if (type === 'borrow')
                this.voucher = EVoucherType.Borrow;
            else if (type === 'return')
                this.voucher = EVoucherType.Return;
            this.ModalService.show({
                component: 'mp-modal-voucher',
                bindings: {
                    loan: this.loan,
                    voucherType: this.voucher,
                },
                escapeToClose: false,
            });
        };
        /**
         * Show a selected [[IMember]] details
         * @param member
         */
        this.openSidenavMember = (member) => {
            if (this.onMemberSelection) {
                this.onMemberSelection({
                    $event: {
                        member: member,
                    },
                });
            }
        };
    }
    $onInit() {
        this.sts = this.StatusService;
    }
    $onChanges(changes) {
        if (changes.loan) {
            this.loan = angular.copy(this.loan);
            if (this.loan) {
                if (this.StatusService.amIL(this.loan) && this.loan.borrower) {
                    this.othHospital = this.loan.borrower.name;
                }
                else if (this.loan.lender) {
                    this.othHospital = this.loan.lender.name;
                }
                this._getBorrowingName();
                this._getLoanName();
                this._getValidName();
            }
        }
    }
}
