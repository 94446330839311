var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Injectable, Injector } from '@angular/core';
import { Access } from '@main/services/auth/access.service';
import { EMaPUIPermission, Invoice } from '@mapuilabs/mpl-interfaces';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { StatusService } from '../status/status.service';
let InvoiceService = class InvoiceService extends CrudTemplateService {
    constructor(_injector, _access, sts) {
        super(_injector, Invoice, '/api/invoice', {
            create: EMaPUIPermission.Request_Create,
            read: EMaPUIPermission.Request_Read,
            update: EMaPUIPermission.Request_Edit,
            delete: EMaPUIPermission.Request_Delete
        });
        this._injector = _injector;
        this._access = _access;
        this.sts = sts;
    }
};
InvoiceService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof Access !== "undefined" && Access) === "function" ? _b : Object, typeof (_c = typeof StatusService !== "undefined" && StatusService) === "function" ? _c : Object])
], InvoiceService);
export { InvoiceService };
