var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Component, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { Hospital } from '@mapuilabs/mpl-interfaces';
import { AdminMemberService } from '../../../../services/admin/member/admin-member.service';
let MembersTabComponent = class MembersTabComponent {
    constructor(_admMembersService, _fuseTranslationLoaderService) {
        this._admMembersService = _admMembersService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.displayedColumns = ['fullName', 'email', 'lastConnect', 'roles'];
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this._admMembersService.getMembersByHospital(this.hospital._id).subscribe((members) => {
            this.members = members;
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Hospital !== "undefined" && Hospital) === "function" ? _c : Object)
], MembersTabComponent.prototype, "hospital", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], MembersTabComponent.prototype, "animationDone", void 0);
MembersTabComponent = __decorate([
    Component({
        selector: 'mpx-members-tab',
        template: require('./members-tab.component.html').default,
        styles: [require('./members-tab.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof AdminMemberService !== "undefined" && AdminMemberService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], MembersTabComponent);
export { MembersTabComponent };
