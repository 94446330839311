var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchivesComponent } from './archives.component';
import { SharedModule } from '@app/shared/shared.module';
import { ToArchiveTabComponent } from './to-archive-tab/to-archive-tab.component';
import { ArchivedTabComponent } from './archived-tab/archived-tab.component';
import { ArchivedDetailComponent } from './archived-tab/archived-detail/archived-detail.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MemberDetailComponent } from './archived-tab/archived-detail/member-detail/member-detail.component';
let ArchivesModule = class ArchivesModule {
};
ArchivesModule = __decorate([
    NgModule({
        declarations: [
            ArchivesComponent,
            ArchivedTabComponent,
            ToArchiveTabComponent,
            ArchivedDetailComponent,
            MemberDetailComponent
        ],
        imports: [
            CommonModule,
            MatPaginatorModule,
            MatTabsModule,
            MatCheckboxModule,
            MatIconModule,
            MatTooltipModule,
            MatButtonModule,
            TranslateModule,
            FusePipesModule,
            FlexLayoutModule,
            SharedModule
        ]
    })
], ArchivesModule);
export { ArchivesModule };
