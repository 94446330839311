/**
 * Created by Eygle on 15/02/2017.
 */
/** @ngInject */
export class MpUploadsController {
    /** @ngInject */
    constructor($scope) {
        this.$scope = $scope;
        this.$onDestroy = () => {
            if (this.watcher) {
                this.watcher();
            }
        };
        /**
         * Cancel Upload file
         * @param item
         */
        this.cancel = (item) => {
            this.uploader.cancelItem(item);
        };
        /**
         * Set show to false
         */
        this.close = () => {
            this.show = false;
        };
        /**
         * Set collapse to true
         */
        this.collapse = () => {
            this.collapsed = true;
        };
        /**
         * Set collapse to false
         */
        this.expand = () => {
            this.collapsed = false;
        };
        /**
         * Format file path
         * @param str
         * @returns {string}
         */
        this.formatPath = (str) => {
            if (!str)
                return '';
            let path = str.split('/');
            let limit = 20;
            let res = '';
            for (let i = path.length - 1; i >= 0; i--) {
                if (!res.length) {
                    res = path[i];
                }
                else if (res.length + path[i].length + 1 <= limit) {
                    res = path[i] + '/' + res;
                }
                else {
                    return '.../' + res;
                }
            }
            if (res.length > limit) {
                res = '...' + res.substr(res.length - limit);
            }
            return res;
        };
        /**
         * Format file name
         * @param name
         * @returns {string}
         */
        this.formatName = (name) => {
            let limit = 25;
            if (name.length > limit) {
                name = name.substr(0, limit - 7) + '...' + name.substr(name.length - 6);
            }
            return name;
        };
        // Force expand when opening
        this.watcher = this.$scope.$watch('vm.show', val => {
            if (val) {
                this.expand();
            }
        });
    }
}
