export class MpSidenavDashboardFiltersComponent {
    constructor() {
        this.controller = 'MpSidenavDashboardFiltersController';
        this.controllerAs = 'vm';
        this.template = require('./mp-sidenav-dashboard-filters.html').default;
        this.bindings = {
            filters: '<',
        };
    }
}
