var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { TranslationService } from '@app/services/translation/translation.service';
import * as _ from 'lodash';
import { DynamicDataContainerContentDirective } from './dynamic-data-container-content.directive';
let DynamicDataContainerComponent = class DynamicDataContainerComponent {
    constructor(_translationService) {
        this._translationService = _translationService;
        this._defaultConfig = {
            loader: {
                label: this._translationService.instant('COMMONS.MESSAGES.LOADING_DATA') +
                    this._translationService.instant('COMMONS.PUNCTUATION.ELLIPSIS')
            },
            noData: {
                svgIcon: 'package-variant',
                label: this._translationService.instant('COMMONS.MESSAGES.NO_DATA') +
                    this._translationService.instant('COMMONS.PUNCTUATION.ELLIPSIS')
            }
        };
    }
    set config(val) {
        this._config = _.merge({}, this._defaultConfig, val);
    }
    get config() {
        return this._config || this._defaultConfig;
    }
};
__decorate([
    ContentChild(DynamicDataContainerContentDirective, { read: TemplateRef }),
    __metadata("design:type", typeof (_b = typeof TemplateRef !== "undefined" && TemplateRef) === "function" ? _b : Object)
], DynamicDataContainerComponent.prototype, "dynamicDataContainerContentTemplateRef", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DynamicDataContainerComponent.prototype, "isLoading", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], DynamicDataContainerComponent.prototype, "noData", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [Object])
], DynamicDataContainerComponent.prototype, "config", null);
DynamicDataContainerComponent = __decorate([
    Component({
        selector: 'mpx-dynamic-data-container',
        template: require('./dynamic-data-container.component.html').default,
        styles: ['']
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _a : Object])
], DynamicDataContainerComponent);
export { DynamicDataContainerComponent };
