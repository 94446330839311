export class AdminService {
    /** @ngInject */
    constructor(ModalService, Api, $q) {
        this.ModalService = ModalService;
        this.Api = Api;
        this.$q = $q;
        this.openModalImportHospitalsManagement = () => {
            const defer = this.$q.defer();
            this.ModalService.show({
                component: 'mp-modal-upload-hospitals-file',
                bindings: {},
                escapeToClose: false,
            }).then((res) => {
                defer.resolve(res);
            }).catch((err) => {
                defer.reject(err);
            });
            return defer.promise;
        };
    }
}
