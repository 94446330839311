export const locale = {
    lang: 'fr',
    data: {
        sidebar: {
            TITLE: 'Filtres',
            filters: {
                TYPES: 'Types',
                ROLES: 'Rôles',
                ACTIVE: 'Actif',
                REGISTER: 'Enregistré'
            }
        }
    }
};
