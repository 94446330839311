export class MpModalLoanCreationComponent {
    constructor() {
        this.controller = 'MpModalLoanCreationController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-loan.html').default;
        this.bindings = {
            loan: '<',
        };
    }
}
