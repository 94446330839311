export class HvModalSpecificPosologyComponent {
    constructor() {
        this.controller = 'HvModalSpecificPosologyController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-specific-posology.html').default;
        this.bindings = {
            posologies: '<',
        };
    }
}
