export const locale = {
    lang: 'fr',
    data: {
        CREATE_HOSPITAL_MEMBER: {
            TITLE: "Création d'un nouveau membre",
            INPUTS: {
                FIRSTNAME: `Prénom`,
                FAMILYNAME: `Nom`,
                GENDER: `Genre`,
                PHONE: `Téléphone`,
                EMAIL: 'Mail',
                INPP: `Identification nationale PP`,
                RPPS: `Numéro RPPS`,
                JOB: {
                    TITLE: `Métier`,
                    STATUS: `Status`,
                    ACTIVITIES: `Activités`
                },
                ROLES: {
                    MAPUI: 'Roles MaPUI',
                    HOSPIVILLE: 'Roles Hospiville',
                    WORKSPACE: 'Roles Workspace'
                }
            },
            ERRORS: {
                EMAIL: {
                    REQUIRED: 'Ce champ est requis',
                    EMAIL: 'Entrez un mail valide',
                    DOMAIN: "Nom de domaine invalide, contactez l'équipe support pour ajouter le membre",
                    EXIST: 'Un membre existe déjà avec ce mail'
                }
            },
            SUCCESS: {
                CREATE: 'Membre ajouté',
                SAVE: 'Membre sauvegardé'
            }
        }
    }
};
