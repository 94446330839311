var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { Component, ElementRef, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { ExternalActorsService } from '@services/external-actor/external-actors.service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { FuseUtils } from '@fuse/utils';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { PermissionService } from '@services/permission/permission.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { StateService } from '@uirouter/angularjs';
let ListExternalActorsComponent = class ListExternalActorsComponent {
    constructor(externalActorsService, _snackBar, _stateService, _matDialog, _translationService, _translateService, _fuseTranslationLoaderService, _snackBarService, _permissionService) {
        this.externalActorsService = externalActorsService;
        this._snackBar = _snackBar;
        this._stateService = _stateService;
        this._matDialog = _matDialog;
        this._translationService = _translationService;
        this._translateService = _translateService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._snackBarService = _snackBarService;
        this._permissionService = _permissionService;
        this.displayedColumns = ['name', 'type', 'configurations', 'buttons'];
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // Add languages
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Header
        this.pageHeaderConfig = {
            title: this._translate('listExternalActors.TITLE'),
            searchBarPlaceholder: this._translate('listExternalActors.SEARCH_ACTOR'),
            icon: 'account_balance'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this.dataSource = new ExternalActorsDataSource(this.externalActorsService, this.paginator, this.sort);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        if (!this.dataSource) {
            return;
        }
        this.dataSource.filter = searchValue;
    }
    addExternalActor() {
        this._stateService.go('mapui.adm.external-actors-v2.detail', { id: 'new' });
    }
    // Get the i18n translation for the actor type
    getTypeTranslation(actor) {
        if (actor.type == 1) {
            return 'Editeur';
        }
        return '';
    }
    // Delete the given actor
    deleteActor(actor) {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = this._translateService.instant('listExternalActors.CONFIRM_MESSAGE');
        this.confirmDialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.externalActorsService.delete(actor).subscribe(() => {
                    this._snackBarService.open(this._translateService.instant('listExternalActors.DELETED'));
                }, (err) => {
                    this._snackBarService.openError(err);
                });
            }
            this.confirmDialogRef = null;
        });
    }
};
__decorate([
    ViewChild('filter', { static: true }),
    __metadata("design:type", typeof (_k = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _k : Object)
], ListExternalActorsComponent.prototype, "filter", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_l = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _l : Object)
], ListExternalActorsComponent.prototype, "paginator", void 0);
__decorate([
    ViewChild(MatSort, { static: true }),
    __metadata("design:type", typeof (_m = typeof MatSort !== "undefined" && MatSort) === "function" ? _m : Object)
], ListExternalActorsComponent.prototype, "sort", void 0);
ListExternalActorsComponent = __decorate([
    Component({
        selector: 'mpx-list-external-actors',
        template: require('./list-external-actors.component.html').default,
        styles: [require('./list-external-actors.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ExternalActorsService !== "undefined" && ExternalActorsService) === "function" ? _a : Object, typeof (_b = typeof MatSnackBar !== "undefined" && MatSnackBar) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _d : Object, typeof (_e = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _e : Object, typeof (_f = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _f : Object, typeof (_g = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _g : Object, typeof (_h = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _h : Object, typeof (_j = typeof PermissionService !== "undefined" && PermissionService) === "function" ? _j : Object])
], ListExternalActorsComponent);
export { ListExternalActorsComponent };
export class ExternalActorsDataSource extends DataSource {
    constructor(_externalActorsService, _matPaginator, _matSort) {
        super();
        this._externalActorsService = _externalActorsService;
        this._matPaginator = _matPaginator;
        this._matSort = _matSort;
        this._filterChange = new BehaviorSubject('');
        this._filteredDataChange = new BehaviorSubject('');
    }
    connect(collectionViewer) {
        const displayDataChanges = [
            this._externalActorsService.onExternalActorsChanged,
            this._filterChange,
            this._matPaginator.page,
            this._matSort.sortChange
        ];
        return merge(...displayDataChanges).pipe(map(() => {
            let data = this._externalActorsService.externalActors.slice();
            data = this.filterData(data);
            this.filteredData = [...data];
            data = this.sortData(data);
            const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
            return data.splice(startIndex, this._matPaginator.pageSize);
        }));
    }
    // Filtered data
    get filteredData() {
        return this._filteredDataChange.value;
    }
    set filteredData(value) {
        this._filteredDataChange.next(value);
    }
    // Filter
    get filter() {
        return this._filterChange.value;
    }
    set filter(filter) {
        this._filterChange.next(filter);
    }
    /**
     * Filter data
     *
     * @param data
     * @returns {any}
     */
    filterData(data) {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }
    /**
     * Sort data
     *
     * @param data
     * @returns {any[]}
     */
    sortData(data) {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }
        return data.sort((a, b) => {
            let propertyA = '';
            let propertyB = '';
            switch (this._matSort.active) {
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;
                case 'type':
                    [propertyA, propertyB] = [a.type, b.type];
                    break;
                case 'configurations':
                    [propertyA, propertyB] = [a.configurations.lengh, b.configurations.length];
                    break;
            }
            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }
    disconnect(collectionViewer) { }
}
