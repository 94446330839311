var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { ArchivedRoutingModule } from './archived-routing.module';
import { ArchivedPatientsListComponent } from './archived-patients-list/archived-patients-list.component';
import { UIRouterModule } from '@uirouter/angular';
import { SharedModule } from '@shared/shared.module';
let ArchivedModule = class ArchivedModule {
};
ArchivedModule = __decorate([
    NgModule({
        declarations: [ArchivedPatientsListComponent],
        imports: [
            CommonModule,
            FuseSharedModule,
            UIRouterModule,
            SharedModule,
            MatIconModule,
            MatSelectModule,
            MatButtonModule,
            MatPaginatorModule,
            MatTableModule,
            MatSortModule,
            MatProgressSpinnerModule,
            MatInputModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
        exports: [ArchivedRoutingModule]
    })
], ArchivedModule);
export { ArchivedModule };
