var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
let MedicService = class MedicService {
    constructor(_http) {
        this._http = _http;
        this._endPoint = 'api/external';
    }
    /**
     * Find an array of drugs from Theriaque matching given name
     * @param name
     * @param options
     */
    findDrugByName(name, options) {
        var _a, _b;
        // Set default options if not provided
        options = Object.assign({ cleanDci: false, labos: false }, options);
        if (!name) {
            return of(null);
        }
        const params = new HttpParams()
            .set('name', name)
            .set('cleanDci', (_a = options.cleanDci) === null || _a === void 0 ? void 0 : _a.toString())
            .set('labos', (_b = options.labos) === null || _b === void 0 ? void 0 : _b.toString());
        return this._http.get(`${this._endPoint}/drug`, { params: params });
    }
    /**
     * Find an array of drugs from Theriaque matching given name
     * @param name
     * @param laboratoriesExp
     * @param options
     */
    findDrugByNameWithLabExp(name, laboratoriesExp, options) {
        var _a;
        // Set default options if not provided
        options = Object.assign({ cleanDci: false, labos: false }, options);
        if (!name) {
            return null;
        }
        const params = new HttpParams()
            .set('name', name)
            .set('laboratoriesExp', JSON.stringify(laboratoriesExp))
            .set('labos', (_a = options.labos) === null || _a === void 0 ? void 0 : _a.toString());
        return this._http.get(`${this._endPoint}/drug-by-labos-exp`, { params: params });
    }
    /**
     * Find an array of operating laboratories from Theriaque matching given name
     * @param name
     */
    findLaboratoryExp(name) {
        const params = new HttpParams().append('name', name);
        return this._http.get(`${this._endPoint}/laboratory-exp`, { params: params });
    }
    /**
     * Find an array of DCI from Theriaque matching given name
     * @param dci
     */
    findDciByName(dci) {
        if (!dci) {
            return null;
        }
        const params = new HttpParams().set('name', dci);
        return this._http.get(`${this._endPoint}/dci`, { params: params });
    }
    /**
     * Find an array of drugs from Theriaque matching given DCI
     * @param dciCode
     */
    findDrugsByDci(dciCode) {
        if (!dciCode) {
            return null;
        }
        const params = new HttpParams().set('dciCode', dciCode.toString());
        return this._http.get(`${this._endPoint}/dci`, { params: params });
    }
    cip7ToCip13(cip7) {
        if (!cip7 || cip7.toString().length != 7) {
            throw new Error('invalid cip7');
        }
        const precip = '34009'.concat(cip7.toString());
        const array = precip.split('');
        let total = 0;
        array.forEach((item, iterator) => {
            if (iterator % 2 == 0) {
                total += parseInt(item);
            }
            else {
                total += parseInt(item) * 3;
            }
        });
        const final = precip.concat((Math.ceil(total / 10) * 10 - total).toString());
        return parseInt(final);
    }
    ucd7ToUcd13(ucd7) {
        if (!ucd7 || ucd7.toString().length != 7) {
            throw new Error('invalid UCD7');
        }
        const preucd = '34008'.concat(ucd7.toString());
        const array = preucd.split('');
        let total = 0;
        array.forEach((item, iterator) => {
            if (iterator % 2 == 0) {
                total += parseInt(item);
            }
            else {
                total += parseInt(item) * 3;
            }
        });
        const final = preucd.concat((Math.ceil(total / 10) * 10 - total).toString());
        return parseInt(final);
    }
};
MedicService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object])
], MedicService);
export { MedicService };
