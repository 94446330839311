var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e;
import { Injectable, Injector } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ENotificationAction, ENotificationType, ESocketTopic, Notification } from '@mapuilabs/mpl-interfaces';
import { IdentityService } from '@services/identity/identity.service';
import { CrudTemplateService } from '@shared/templates/crud/crud.template';
import { StateService } from '@uirouter/angular';
import * as moment from 'moment';
import { Subject, lastValueFrom } from 'rxjs';
import { NotificationService as UpgradedService } from '../../../main/services/notification/notification.service';
import { BADGES_CONFIG, ICONS_CONFIG } from '../../shared/constTypes/notification/notification.config';
let NotificationService = class NotificationService extends CrudTemplateService {
    constructor(__injector, fuseNavigationService, _stateService, identityService, upgradedService) {
        super(__injector, Notification, '/api/members/', null);
        this.__injector = __injector;
        this.fuseNavigationService = fuseNavigationService;
        this._stateService = _stateService;
        this.identityService = identityService;
        this.upgradedService = upgradedService;
        this.count = { unread: {}, unseen: 0 };
        this._index = 0;
        this.isReady = false;
        this.allLoaded = false;
        this._addNotifications = (notifications, atFirst = false) => {
            const formattedNotifications = notifications.map((notification) => {
                notification.label =
                    'NOTIFICATIONS.LABEL.' +
                        (notification.admin ? 'ADMIN.' : '') +
                        notification.type.toUpperCase() +
                        '.' +
                        notification.action.toUpperCase();
                notification.date = new Date(notification.date);
                notification.relativeTime = moment.duration(moment().diff(notification.date)).humanize();
                notification.icon = ICONS_CONFIG[notification.type];
                return notification;
            });
            this.notifications = atFirst
                ? [...formattedNotifications, ...this.notifications]
                : [...this.notifications, ...formattedNotifications];
        };
        this.notifications = [];
        this._memberId = identityService.user._id;
    }
    listenNotifications() {
        const obs = new Subject();
        this.upgradedService.listenNotification().subscribe((value) => {
            this._socketCallback(value.topic, value.notification);
            obs.next();
        });
        this.upgradedService.listenNotificationUpdate().subscribe((value) => {
            this._socketCallback(value.topic, value.notification);
            obs.next();
        });
        return obs;
    }
    getCount() {
        return __awaiter(this, void 0, void 0, function* () {
            const count = yield lastValueFrom(this._http.get(`${this._endPoint}${this._memberId}/notifications/count`));
            this.count = count;
            this.formattedCount = this.getFormattedCount(this.count);
            this._updateNavigationBadges();
        });
    }
    /**
     * Format counter of notifications by displaying 99+ if the value is >= 100
     * @param {string} category
     * @return {string}
     */
    getFormattedCount(count) {
        if (count) {
            if (!count.unseen) {
                return '';
            }
            else {
                return count.unseen < 100 ? count.unseen.toString() : '99+';
            }
        }
        else {
            return '';
        }
    }
    /**
     * Mark all notifications as read or seen
     */
    markAllAs(param) {
        const body = {};
        const url = `${this._endPoint}${this._memberId}/notifications`;
        this.notifications.forEach((notification) => {
            notification[param] = true;
        });
        if (param === 'read') {
            this.count.unread = Object.entries(this.count.unread).reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [key]: value })), {});
        }
        else {
            body['type'] = 'seen';
        }
        this.count.unseen = 0;
        this._http.put(url, body, { responseType: 'text' }).subscribe(() => {
            if (param === 'read') {
                this._updateNavigationBadges();
            }
        }, (err) => console.error(err));
    }
    _countPerCategories(args) {
        for (const key of args) {
            if (this.count.unread[key] > 0) {
                return this.count.unread[key];
            }
        }
        return 0;
    }
    _updateNavigationBadges() {
        BADGES_CONFIG.map((conf) => (Object.assign(Object.assign({}, conf), { nbr: this._countPerCategories(conf.notificationTypes) }))).forEach((badge) => this._updateBadge(badge));
    }
    _updateBadge(badge) {
        if (badge.nbr > 0) {
            this.fuseNavigationService.updateNavigationItem(badge.id, {
                badge: {
                    title: badge.nbr,
                    bg: badge.color || '#F44336'
                }
            });
        }
        else {
            this.fuseNavigationService.updateNavigationItem(badge.id, {
                badge: null
            });
        }
    }
    open(notif) {
        if (!notif.read) {
            this.count.unread[notif.type]--;
            notif.read = true;
            this.markOneAsRead(notif._id);
            this._updateNavigationBadges();
        }
        switch (notif.type) {
            case ENotificationType.Available:
                this._stateService.go('mapui.exchangeV2.available.available.available', { id: notif.data.available });
                break;
            case ENotificationType.Loan:
            case ENotificationType.Borrowing:
                this._stateService.go('mapui.dashboard');
                break;
            case ENotificationType.Request:
                this._stateService.go('mapui.exchangeV2.request.list');
                break;
            case ENotificationType.Preparation:
                this._stateService.go('mapui.pharmacyV2.preparations.list');
                break;
            case ENotificationType.Group:
                this._stateService.go('mapui.groups.details', { id: notif.target });
                break;
            case ENotificationType.User:
                if (notif.admin) {
                    //This now just opens the new member details page. change-hospital-modal is not used anymore.
                    this._stateService.go('mapui.adm.members.details', { id: notif.target });
                }
                else {
                    this._stateService.go(notif.action === ENotificationAction.RankHospiville
                        ? 'hospivilleV2.patients.list'
                        : 'mapui.adm.hospitals.list');
                }
                break;
            case ENotificationType.Hospital:
                this._stateService.go('mapui.setting.hospital-account.information');
                break;
            case ENotificationType.SharedFile:
                this._stateService.go('mapui.files.directory', { id: notif.target });
                break;
        }
    }
    markOneAsRead(id) {
        this._http.put(`${this._endPoint}${this._memberId}/notifications/${id}`, null).subscribe();
    }
    loadMore() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield lastValueFrom(this._http.get(`${this._endPoint}${this._memberId}/notifications`, {
                params: { start: JSON.stringify(this._index || 0) }
            }));
            this._addNotifications(res);
            if (this.notifications.length === res.length) {
                this.allLoaded = true;
            }
            this._index += res.length;
        });
    }
    _socketCallback(topic, notif) {
        const intercepted = this._callListeners(topic, notif);
        if (ESocketTopic.Notification === topic) {
            this._addNotifications([notif], true);
        }
        else {
            if (notif.data.isSystem) {
                return;
            }
        }
        if (!intercepted) {
            if (ESocketTopic.Notification === topic) {
                this._index = this.notifications.length;
                this.count.unseen++;
                if (!this.count.unread[notif.type]) {
                    this.count.unread[notif.type] = 0;
                }
                this.count.unread[notif.type]++;
            }
            this._updateNavigationBadges();
        }
    }
    _callListeners(topic, notif) {
        this.upgradedService.listeners.forEach((listener) => {
            if ((!listener.types || !!~listener.types.indexOf(notif.type)) &&
                (!listener.target || listener.target === notif.target)) {
                listener.callback(notif);
                if (listener.stopPropagation) {
                    if (ESocketTopic.Notification === topic) {
                        notif.read = true;
                    }
                    return true;
                }
            }
        });
        return false;
    }
};
NotificationService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Injector !== "undefined" && Injector) === "function" ? _a : Object, typeof (_b = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _d : Object, typeof (_e = typeof UpgradedService !== "undefined" && UpgradedService) === "function" ? _e : Object])
], NotificationService);
export { NotificationService };
