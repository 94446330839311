import * as angular from 'angular';
import { ESharingCategory, ESharingType } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MpShareController {
    /** @ngInject */
    constructor(Api, Access, MemberService, GroupService, _) {
        this.Api = Api;
        this.Access = Access;
        this.MemberService = MemberService;
        this.GroupService = GroupService;
        this._ = _;
        /**
         * Called when a md-select list value is changed by the user
         */
        this.onSelectChange = () => {
            switch (this.shareWithType) {
                case ESharingType.Group:
                    if (this.shareWithLocalValues) {
                        this.onSelect({
                            shareWithType: this.shareWithType,
                            shareWithValues: this._formatReturnValues(this.shareWithLocalValues),
                        });
                    }
                    break;
                case ESharingType.Hospital:
                case ESharingType.Everybody:
                case ESharingType.Region:
                case ESharingType.Public:
                case ESharingType.Self:
                default:
                    this.onSelect({ shareWithType: this.shareWithType });
                    break;
            }
        };
        /**
         * Add a sharing type to the md-select list
         * @param type
         * @private
         */
        this._addSharingTypeToList = (type) => {
            if (type === ESharingType.Public && !this.Access.isAdmin()) {
                return;
            }
            if (type === ESharingType.Group && (!this.Access.hospital.groups || this.Access.hospital.groups.length === 0)) {
                return;
            }
            for (let t in this._sharingTypes) {
                if (this._sharingTypes.hasOwnProperty(t) && this._sharingTypes[t].value === type) {
                    this.types[t] = this._sharingTypes[t];
                    return;
                }
            }
        };
        /**
         * Takes an array of string (_id) and return an array of {_id: string}
         * @return {Array}
         * @private
         */
        this._formatReturnValues = (arr) => {
            if (!arr)
                return null;
            const values = [];
            for (let v of arr) {
                const res = { _id: v };
                if (this.includeNames) {
                    const group = this._.find(this.groups, (g) => {
                        return Utils.compareIds(g, v);
                    });
                    res.name = group ? group.name : undefined;
                }
                values.push(res);
            }
            return values;
        };
        /**
         * Takes an array of {_id: string} and return an array of string (_id) used for md-select
         * @return {any}
         * @private
         */
        this._formatIncomingValues = (arr) => {
            if (!arr)
                return null;
            const values = [];
            console.log('Sharing groups', arr);
            for (let v of arr) {
                values.push(v._id);
            }
            return values;
        };
        this.types = {};
        this.shareOnlyWithGroups = false;
    }
    $onInit() {
        this.Api.constType.get(ans => {
            this._sharingTypes = ans.sharingTypes;
            switch (this.category) {
                case ESharingCategory.News:
                    this._addSharingTypeToList(ESharingType.Hospital);
                    this._addSharingTypeToList(ESharingType.Group);
                    this._addSharingTypeToList(ESharingType.Region);
                    this._addSharingTypeToList(ESharingType.Everybody);
                    if (this.Access.isAdmin())
                        this._addSharingTypeToList(ESharingType.Public);
                    break;
                case ESharingCategory.Files:
                    this._addSharingTypeToList(ESharingType.Hospital);
                    this._addSharingTypeToList(ESharingType.Group);
                    this._addSharingTypeToList(ESharingType.Region);
                    this._addSharingTypeToList(ESharingType.Everybody);
                    break;
                case ESharingCategory.StockoutComment:
                case ESharingCategory.Chat:
                case ESharingCategory.Stockout:
                case ESharingCategory.Booklet:
                case ESharingCategory.Equivalence:
                    this._addSharingTypeToList(ESharingType.Group);
                    this.shareOnlyWithGroups = true;
                    this.shareWithType = ESharingType.Group;
                    break;
            }
            this.shareWithLocalValues = this._formatIncomingValues(this.shareWithValues);
        });
        if (this.groups && this.groups.length) {
            let copyGroup = angular.copy(this.groups);
            this.groups = [];
            for (let group of copyGroup) {
                this.GroupService.get(group._id).then((fullGroup) => {
                    this.groups.push(fullGroup);
                });
            }
        }
        else {
            this.groups = this.Access.hospital.groups;
            if (this.Access.user._id) {
                this.MemberService.get(this.Access.user._id)
                    .then((member) => {
                    if (member.groups) {
                        this.groups = this.groups.concat(member.groups);
                    }
                });
            }
        }
    }
}
