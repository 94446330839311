export class HvModalComplianceTestComponent {
    constructor() {
        this.controller = 'HvModalComplianceTestController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-compliance-test.html').default;
        this.bindings = {
            test: '<',
        };
    }
}
