var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AvailableSummaryComponent } from './available-summary.component';
const states = [
    {
        name: 'mapui.exchangeV2.available.available.available',
        url: '/:id',
        views: {
            'content@mapui': {
                component: AvailableSummaryComponent
            }
        },
        params: {
            id: {
                dynamic: true,
                reload: false,
                value: '0'
            }
        },
        data: {
            theme: 'theme-blue'
        }
    }
];
let AvailableSummaryRoutingModule = class AvailableSummaryRoutingModule {
};
AvailableSummaryRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], AvailableSummaryRoutingModule);
export { AvailableSummaryRoutingModule };
