var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component, ContentChildren, Directive, ElementRef, Input, QueryList } from '@angular/core';
let HsTransitionGroupItemDirective = class HsTransitionGroupItemDirective {
    constructor(elRef) {
        this.el = elRef.nativeElement;
    }
};
HsTransitionGroupItemDirective = __decorate([
    Directive({
        selector: '[transition-group-item]'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _a : Object])
], HsTransitionGroupItemDirective);
export { HsTransitionGroupItemDirective };
let HsTransitionGroupComponent = class HsTransitionGroupComponent {
    ngAfterViewInit() {
        setTimeout(() => this.refreshPosition('prevPos'), 0); // save init positions on next 'tick'
        this.items.changes.subscribe((items) => {
            items.forEach((item) => (item.prevPos = item.newPos || item.prevPos));
            items.forEach(this.runCallback);
            this.refreshPosition('newPos');
            items.forEach((item) => (item.prevPos = item.prevPos || item.newPos)); // for new items
            const animate = () => {
                items.forEach(this.applyTranslation);
                this['_forceReflow'] = document.body.offsetHeight; // force reflow to put everything in position
                this.items.forEach(this.runTransition.bind(this));
            };
            const willMoveSome = items.some((item) => {
                const dx = item.prevPos.left - item.newPos.left;
                const dy = item.prevPos.top - item.newPos.top;
                return dx || dy;
            });
            if (willMoveSome) {
                animate();
            }
            else {
                setTimeout(() => {
                    // for removed items
                    this.refreshPosition('newPos');
                    animate();
                }, 0);
            }
        });
    }
    runCallback(item) {
        if (item.moveCallback) {
            item.moveCallback();
        }
    }
    runTransition(item) {
        if (!item.moved) {
            return;
        }
        const cssClass = this.class + '-move';
        const el = item.el;
        const style = el.style;
        el.classList.add(cssClass);
        style.transform = style.webkitTransform = style.transitionDuration = '';
        el.addEventListener('transitionend', (item.moveCallback = (e) => {
            if (!e || /transform$/.test(e.propertyName)) {
                el.removeEventListener('transitionend', item.moveCallback);
                item.moveCallback = null;
                el.classList.remove(cssClass);
            }
        }));
    }
    refreshPosition(prop) {
        this.items.forEach((item) => {
            item[prop] = item.el.getBoundingClientRect();
        });
    }
    applyTranslation(item) {
        item.moved = false;
        const dx = item.prevPos.left - item.newPos.left;
        const dy = item.prevPos.top - item.newPos.top;
        if (dx || dy) {
            item.moved = true;
            const style = item.el.style;
            style.transform = style.webkitTransform = 'translate(' + dx + 'px,' + dy + 'px)';
            style.transitionDuration = '0s';
        }
    }
};
__decorate([
    Input('transition-group'),
    __metadata("design:type", Object)
], HsTransitionGroupComponent.prototype, "class", void 0);
__decorate([
    ContentChildren(HsTransitionGroupItemDirective),
    __metadata("design:type", typeof (_b = typeof QueryList !== "undefined" && QueryList) === "function" ? _b : Object)
], HsTransitionGroupComponent.prototype, "items", void 0);
HsTransitionGroupComponent = __decorate([
    Component({
        selector: '[transition-group]',
        template: '<ng-content></ng-content>'
    })
], HsTransitionGroupComponent);
export { HsTransitionGroupComponent };
