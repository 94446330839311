var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableDetailComponent } from './available-detail/available-detail.component';
import { AvailableListComponent } from './available-list/available-list.component';
import { FlexModule } from '@angular/flex-layout';
import { AvailableRequestRoutingModule } from './available-request-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterSidebarComponent } from './available-list/filter-sidebar/filter-sidebar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { FuseSidebarModule } from '@fuse/components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AvailableListItemComponent } from './available-list/available-list-item/available-list-item.component';
import { MatRippleModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalInterestInformationComponent } from './available-detail/modal-interest-information/modal-interest-information.component';
import { MatBadgeModule } from '@angular/material/badge';
import { SharedModule } from '@app/shared/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
let AvailableRequestModule = class AvailableRequestModule {
};
AvailableRequestModule = __decorate([
    NgModule({
        declarations: [
            AvailableDetailComponent,
            AvailableListComponent,
            FilterSidebarComponent,
            AvailableListItemComponent,
            ModalInterestInformationComponent
        ],
        imports: [
            CommonModule,
            BrowserAnimationsModule,
            FlexModule,
            FuseSharedModule,
            FuseSidebarModule,
            SharedModule,
            MatBadgeModule,
            MatButtonModule,
            MatCheckboxModule,
            MatDatepickerModule,
            MatDialogModule,
            MatDividerModule,
            MatIconModule,
            MatInputModule,
            MatListModule,
            MatMenuModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatTooltipModule,
            AvailableRequestRoutingModule,
            NgScrollbarModule
        ],
        exports: [AvailableDetailComponent]
    })
], AvailableRequestModule);
export { AvailableRequestModule };
