import { EMpStatus } from '@mapuilabs/mpl-interfaces';
export class HvModalSelectReconciliationTypeController {
    /** @ngInject */
    constructor(ModalService, Api) {
        this.ModalService = ModalService;
        this.Api = Api;
        /**
         * Close the modal with actions performing
         */
        this.save = () => {
            this.ModalService.close(this.medRec);
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
}
