var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b;
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { UploadService } from '@services/upload/upload.service';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';
let HsPublicationDetailFilesComponent = class HsPublicationDetailFilesComponent {
    constructor(_uploadService, _snackbarService) {
        this._uploadService = _uploadService;
        this._snackbarService = _snackbarService;
    }
    /**
     * Download file using url parameter
     * @param url File to download link
     */
    downloadFile(fileObj) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const buffer = yield lastValueFrom(this._uploadService.downloadFile(fileObj.url));
                const blob = new Blob([buffer]);
                const file = new File([blob], fileObj.name);
                saveAs(file); // Save in user's file system
            }
            catch (err) {
                this._snackbarService.openError(err);
            }
        });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsPublicationDetailFilesComponent.prototype, "files", void 0);
HsPublicationDetailFilesComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-files',
        template: `
        <mpx-small-list>
            <mpx-small-list-item *ngFor="let file of files">
                <mpx-small-list-col>{{ file['name'] }}</mpx-small-list-col>

                <mpx-small-list-button
                    icon="cloud_download"
                    [tooltip]="'COMMONS.VERBS.DOWNLOAD' | translate"
                    (click)="downloadFile(file)"
                ></mpx-small-list-button>
            </mpx-small-list-item>
        </mpx-small-list>
    `,
        styles: [
            `
            :host {
                display: block;
            }
        `
        ],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof UploadService !== "undefined" && UploadService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object])
], HsPublicationDetailFilesComponent);
export { HsPublicationDetailFilesComponent };
