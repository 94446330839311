export class HvPatientMedRecsComponent {
    constructor() {
        this.controller = 'HvPatientMedRecsController';
        this.controllerAs = 'vm';
        this.template = require('./hv-patient-med-recs.html').default;
        this.bindings = {
            patient: '<',
            medRecs: '<'
        };
    }
}
