var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { UIRouterModule } from '@uirouter/angular';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSidebarModule } from '@fuse/components';
import { ActivitiesRoutingModule } from './activities-routing.module';
import { ListActivitiesRoutingModule } from './list-activities/list-activities-routing.module';
import { ActivityDetailsRoutingModule } from './activity-details/activity-details-routing.module';
import { SharedModule } from '@shared/shared.module';
import { MailsService } from './activity-details/mails.service';
import { ListActivitiesSidebarComponent } from './list-activities/filters-sidebar/list-activities-sidebar.component';
import { ListActivitiesSourceViewComponent } from './list-activities/list-activities-source-view/list-activities-source-view.component';
import { ListActivitiesDestViewComponent } from './list-activities/list-activities-dest-view/list-activities-dest-view.component';
import { ActivityDetailsSourceComponent } from './activity-details/activity-details-source/activity-details-source.component';
import { ActivityDetailsDestComponent } from './activity-details/activity-details-dest/activity-details-dest.component';
import { ActivityMailsTabComponent } from './activity-details/activity-details-source/activity-mails-tab/activity-mails-tab.component';
import { ActivityMailsListComponent } from './activity-details/activity-details-source/activity-mails-tab/activity-mails-list/activity-mails-list.component';
import { ActivityMailsListItemComponent } from './activity-details/activity-details-source/activity-mails-tab/activity-mails-list/activity-mails-list-item/activity-mails-list-item.component';
import { ActivityMailsDetailsComponent } from './activity-details/activity-details-source/activity-mails-tab/activity-mails-details/activity-mails-details.component';
import { ActionsTabSourceComponent } from './activity-details/activity-details-source/actions-tab/actions-tab.component';
import { ActionsTabDestComponent } from './activity-details/activity-details-dest/actions-tab/actions-tab.component';
import { ImportFilesModalComponent } from './activity-details/activity-details-dest/import-files-modal/import-files-modal.component';
import { FilesTabDestComponent } from './activity-details/activity-details-dest/files-tab/files-tab.component';
import { FilesTabSourceComponent } from './activity-details/activity-details-source/files-tab/files-tab.component';
import { FilesListComponent } from './activity-details/files-list/files-list.component';
import { AddDrugBalanceTabComponent } from './activity-details/activity-details-dest/add-drug-balance-tab/add-drug-balance-tab.component';
import { MailContentCompoment } from './activity-details/activity-details-source/activity-mails-tab/activity-mails-details/mail-content/mail-content.compoment';
import { ActivityDetailsFileToDbsComponent } from './activity-details/activity-details-file-to-dbs/activity-details-file-to-dbs.component';
let ActivitiesModule = class ActivitiesModule {
};
ActivitiesModule = __decorate([
    NgModule({
        declarations: [
            ListActivitiesSidebarComponent,
            ListActivitiesSourceViewComponent,
            ListActivitiesDestViewComponent,
            ActivityDetailsSourceComponent,
            ActivityDetailsDestComponent,
            ActivityDetailsFileToDbsComponent,
            ActionsTabSourceComponent,
            ActionsTabDestComponent,
            ImportFilesModalComponent,
            ActivityMailsTabComponent,
            ActivityMailsListComponent,
            ActivityMailsListItemComponent,
            ActivityMailsDetailsComponent,
            FilesTabDestComponent,
            FilesTabSourceComponent,
            FilesListComponent,
            AddDrugBalanceTabComponent,
            MailContentCompoment
        ],
        imports: [
            CommonModule,
            UIRouterModule,
            FuseDirectivesModule,
            FuseSharedModule,
            FuseSidebarModule,
            SharedModule,
            MatButtonModule,
            MatIconModule,
            MatTableModule,
            MatPaginatorModule,
            MatSortModule,
            MatRippleModule,
            MatProgressSpinnerModule,
            MatCheckboxModule,
            MatInputModule,
            MatListModule,
            MatTabsModule,
            MatTooltipModule,
            MatDialogModule,
            MatExpansionModule,
            MatMenuModule,
            MatBadgeModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }, MailsService],
        exports: [ActivitiesRoutingModule, ListActivitiesRoutingModule, ActivityDetailsRoutingModule]
    })
], ActivitiesModule);
export { ActivitiesModule };
