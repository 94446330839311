var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g;
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { HsPublicationContainerService } from '@app/hospistock/shared/services/hs-publication-container.service';
import { DOCUMENT_CLICK } from '@core/tokens/events/document-click.token';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Member } from '@mapuilabs/mpl-interfaces';
import { IdentityService } from '@services/identity/identity.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let HsPublicationDetailSupervisorComponent = class HsPublicationDetailSupervisorComponent {
    constructor(_documentClick$, _changeDetectorRef, _fuseTranslationLoaderService, _publicationContainerService, _identityService) {
        this._documentClick$ = _documentClick$;
        this._changeDetectorRef = _changeDetectorRef;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._publicationContainerService = _publicationContainerService;
        this._identityService = _identityService;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init event emitter
        this.supervisorChanged = new EventEmitter();
        // Init constants
        this.hospital = this._identityService.hospital;
        this.readonly = this._publicationContainerService.readonly;
        this._preventNextClickToCloseMenu = false;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Add selected member and close search menu
     */
    addSupervisor() {
        return __awaiter(this, void 0, void 0, function* () {
            this.supervisorChanged.emit(this.selectedMember);
            this.closeEditMode();
        });
    }
    /**
     * Open edit form by setting editMode to true to trigger form.
     * Method must wait next document click event before showing edit form.
     * Reason: Click event from document is fired with delay and trigger clickOutsideDirective's clickOutside event causing the form to be closed.
     * Recommendation : Do not use preventDefault instead otherwise form will not be closed when click event is fired on other edit menu button
     */
    openEditMode() {
        // Wait next click event
        this._documentClick$.pipe(take(1)).subscribe(() => {
            // Open edit mode
            this.editMode = true;
            // Manually update
            this._changeDetectorRef.detectChanges();
        });
    }
    /**
     * Close edit form by setting editMode to false to hide form.
     */
    closeEditMode() {
        // Prevent click to close menu if needed
        if (!this._preventNextClickToCloseMenu) {
            // Close edit mode
            this.editMode = false;
            // Reset selectedMember
            this.selectedMember = undefined;
        }
        else {
            // Reset _preventNextClickToCloseMenu
            this._preventNextClickToCloseMenu = false;
        }
    }
    /**
     * Store selected member in local variable
     * @param member memebr to store
     */
    onMemberSelected(member) {
        // Prevent click in autocomplete's panel to close edit menu.
        // Autocomplete's options are contained in overlay and are considered ouside of clickOutsideDirective's reference.
        this._preventNextClickToCloseMenu = true;
        // Update selected member
        this.selectedMember = member;
    }
    /**
     * Delete supervisor
     */
    deleteSupervisor() {
        this.supervisorChanged.emit(null);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Member !== "undefined" && Member) === "function" ? _f : Object)
], HsPublicationDetailSupervisorComponent.prototype, "supervisor", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], HsPublicationDetailSupervisorComponent.prototype, "supervisorChanged", void 0);
HsPublicationDetailSupervisorComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-detail-supervisor',
        template: require('./hs-publication-detail-supervisor.component.html').default,
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(DOCUMENT_CLICK)),
    __metadata("design:paramtypes", [typeof (_a = typeof Observable !== "undefined" && Observable) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object, typeof (_d = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _d : Object, typeof (_e = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _e : Object])
], HsPublicationDetailSupervisorComponent);
export { HsPublicationDetailSupervisorComponent };
