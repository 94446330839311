import * as angular from 'angular';
import { RegisterPharmacistValidationController } from './register-pharmacist-validation.controller';
angular.module('mapui.services.auth.register-pharmacist-validation', [])
    .config(config)
    .controller('RegisterPharmacistValidationController', RegisterPharmacistValidationController);
function config($stateProvider) {
    $stateProvider.state('mapui.pages_auth_register_pharmacist_validation', {
        url: '/auth/register-pharmacist-validation?:email&error',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_auth_register_pharmacist_validation': {
                template: require('./register-pharmacist-validation.html').default,
                controller: 'RegisterPharmacistValidationController as vm',
            },
        },
        bodyClass: 'register-pharmacist-validation',
    });
}
