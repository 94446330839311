export const locale = {
    lang: 'en',
    data: {
        CONFIGURATION: {
            TITLE: 'Hospistock configuration',
            WHOLESALER_TITLE: 'Wholesalers',
            DEPOSITARY_TITLE: 'Depositaries',
            NEW_WHOLESALER_LABEL: 'Add a new wholesaler',
            NEW_DEPOSITARY_LABEL: 'Add a new depositary',
            WHOLESALER_EDIT_WARNING: 'Editing will affect the wholesaler selected on existing stockouts.',
            DEPOSITARY_EDIT_WARNING: 'Editing will affect the depositary selected on existing stockouts.',
            WHOLESALER_DELETED: 'Wholesaler deleted',
            DEPOSITARY_DELETED: 'Depositary deleted',
            TABS: {
                WHOLESALERS: 'Wholesalers',
                DEPOSITARIES: 'Depositaries'
            },
            DELETE_WHOLESALER: {
                TITLE: 'Confirm wholesaler deletion',
                CONTENT: `Do you really want to delete the wholesaler {{name}}?`
            },
            DELETE_DEPOSITARY: {
                TITLE: 'Confirm depositary deletion',
                CONTENT: `Do you really want to delete the depositary {{name}}?`
            }
        }
    }
};
