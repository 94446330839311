import { EPosologyType } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvSelectPosologyController {
    /** @ngInject */
    constructor(ModalService, DrugInstanceService, Api, Access) {
        this.ModalService = ModalService;
        this.DrugInstanceService = DrugInstanceService;
        this.Api = Api;
        this.Access = Access;
        /**
         * @type {EPosologyType}
         */
        this.EPosologyType = EPosologyType;
        /**
         * Open hv-modal-specific-posology
         */
        this.editSpecificPosology = () => {
            this.ModalService.show({
                component: 'hv-modal-specific-posology',
                bindings: {
                    posologies: this.posologies,
                },
                escapeToClose: true,
            })
                .then((posologies) => {
                this.posologies = posologies;
                this._change();
            });
        };
        /**
         * When posology change
         */
        this.posologyChange = () => {
            if (!this.presetPosology)
                return;
            if (this.presetPosology === EPosologyType.Other) {
                this.posologies = [{ presetCode: this.presetPosology }];
                this.editSpecificPosology();
            }
            else {
                this.posologies = [];
                let newPoso = this.DrugInstanceService.getPresetPosology(this.presetPosology);
                newPoso.presetCode = this.presetPosology;
                this.posologies.push(newPoso);
                this._change();
            }
        };
        /**
         * When posology changeX
         * @private
         */
        this._change = () => {
            if (this.onChange) {
                this.onChange({
                    $event: { posologies: this.posologies },
                });
            }
        };
        this.diService = this.DrugInstanceService;
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
        this.migrationState = this.Access.isHVMigrationState();
    }
    $onChanges(changes) {
        if (changes.posologies) {
            this.posologies = angular.copy(this.posologies);
            if (this.posologies && this.posologies.length >= 1) {
                this.presetPosology = this.posologies[0].presetCode;
            }
            else {
                this.presetPosology = null;
            }
        }
    }
}
