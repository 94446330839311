var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
let UploadService = class UploadService {
    constructor(_http) {
        this._http = _http;
        /** Service inspired from https://malcoded.com/posts/angular-file-upload-component-with-express/
         * https://github.com/LukasMarx/angular-file-upload
         **/
        this._endPoint = 'files/up';
        /**
         * Get a file thanks to its given url.
         * @param url
         */
        this.downloadFile = (url) => {
            if (!url) {
                return null;
            }
            return this._http.get(url, { responseType: 'arraybuffer' });
        };
        this._upload = (fileCtnrs, type, endPoint) => {
            if (!fileCtnrs || type == null || !endPoint) {
                return null;
            }
            // This will be the our resulting map
            const status = {};
            fileCtnrs.forEach((ctnr) => {
                const filename = encodeURIComponent(ctnr.file.name);
                const formData = new FormData();
                formData.append('file', ctnr.file);
                // Create a http-post request and pass the form
                // Tell it to report the upload progress
                const req = new HttpRequest('POST', `${endPoint}/${type}/${filename}`, formData, {
                    reportProgress: true
                });
                // Create a new progress-subject for every file
                const progress = new Subject();
                // Send the http-request and subscribe for progress-updates
                this._http.request(req).subscribe((event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        // Calculate the progress percentage
                        const percentDone = Math.round((100 * event.loaded) / event.total);
                        // Pass the percentage into the progress-stream
                        progress.next(percentDone);
                    }
                    else if (event instanceof HttpResponse) {
                        // Close the progress-stream if we get an answer form the API
                        // The upload is complete
                        progress.next(event);
                        progress.complete();
                    }
                }, (err) => {
                    // When the http upload fails
                    progress.error(err);
                });
                // Save every progress-observable in a map of all observables
                status[filename] = {
                    progress: progress.asObservable()
                };
                ctnr.progress = progress.asObservable();
            });
            return status;
        };
        this.upload = (fileCtnrs, type) => {
            if (!fileCtnrs || type == null) {
                return null;
            }
            return this._upload(fileCtnrs, type, this._endPoint);
        };
        this.uploadAndSave = (fileCtnrs, type) => {
            if (!fileCtnrs || type == null) {
                return null;
            }
            const endPoint = 'files/up-save';
            return this._upload(fileCtnrs, type, endPoint);
        };
    }
};
UploadService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _a : Object])
], UploadService);
export { UploadService };
