export class MpModalAvailableComponent {
    constructor() {
        this.controller = 'MpModalAvailableController';
        this.controllerAs = 'vm';
        this.template = require('./mp-modal-available.html').default;
        this.bindings = {
            available: '<',
        };
    }
}
