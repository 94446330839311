export class MpDragOverDirective {
    /** @ngInject */
    constructor(ngIfDirective) {
        this.ngIfDirective = ngIfDirective;
        this.link = (...args) => {
            const element = args[1];
            const overClass = args[2].mpDragOver || 'drag-over';
            element.on('dragover', function () {
                if (!element[0].classList.contains(overClass)) {
                    element[0].classList.add(overClass);
                }
            });
            element.on('dragleave', function () {
                if (element[0].classList.contains(overClass)) {
                    element[0].classList.remove(overClass);
                }
            });
            element.on('drop', function () {
                if (element[0].classList.contains(overClass)) {
                    element[0].classList.remove(overClass);
                }
            });
            element.on('dragexit', function () {
                if (element[0].classList.contains(overClass)) {
                    element[0].classList.remove(overClass);
                }
            });
            element.on('dragend', function () {
                if (element[0].classList.contains(overClass)) {
                    element[0].classList.remove(overClass);
                }
            });
        };
        this._ngIf = this.ngIfDirective[0];
        this.priority = this._ngIf.priority - 1;
    }
    static factory() {
        const directive = (ngIfDirective) => new MpDragOverDirective(ngIfDirective);
        directive.$inject = ['ngIfDirective'];
        return directive;
    }
}
