var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
import { fuseAnimations } from '@fuse/animations';
let TwitterWidgetComponent = class TwitterWidgetComponent {
    constructor(_fuseTranslationLoaderService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
    }
    ngOnInit() { }
    ngAfterViewInit() {
        window.twttr.widgets.createTimeline({
            sourceType: 'profile',
            screenName: '_mapui'
        }, document.getElementById('twitter'), {
            height: 400,
            chrome: 'noheader, nofooter',
            dnt: true
        });
    }
};
TwitterWidgetComponent = __decorate([
    Component({
        selector: 'mpx-twitter-widget',
        template: require('./twitter-widget.component.html').default,
        styles: [require('./twitter-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object])
], TwitterWidgetComponent);
export { TwitterWidgetComponent };
