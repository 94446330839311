export var EHsPublicationStockIndicatorStatus;
(function (EHsPublicationStockIndicatorStatus) {
    EHsPublicationStockIndicatorStatus["CONSUMPTION_ZERO"] = "CONSUMPTION_ZERO";
    EHsPublicationStockIndicatorStatus["OPTIMAL"] = "OPTIMAL";
    EHsPublicationStockIndicatorStatus["SUFFICIENT"] = "SUFFICIENT";
    EHsPublicationStockIndicatorStatus["LIMIT"] = "LIMIT";
    EHsPublicationStockIndicatorStatus["INSUFFICIENT"] = "INSUFFICIENT";
    EHsPublicationStockIndicatorStatus["CRITICAL"] = "CRITICAL";
    EHsPublicationStockIndicatorStatus["STOCK_ZERO"] = "STOCK_ZERO";
    EHsPublicationStockIndicatorStatus["CONSUMPTION_NULL"] = "CONSUMPTION_NULL";
    EHsPublicationStockIndicatorStatus["STOCK_NULL"] = "STOCK_NULL";
    EHsPublicationStockIndicatorStatus["NO_END_DATE"] = "NO_END_DATE";
})(EHsPublicationStockIndicatorStatus || (EHsPublicationStockIndicatorStatus = {}));
