import { EFileType, EMpStatus, EPatientConsent } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class HvPatientFilesController {
    /** @ngInject */
    constructor(FileUploaderService, HFilesService, HospivilleService, ToastService, PatientService, Api, Access, $scope, $timeout, InformationService) {
        this.FileUploaderService = FileUploaderService;
        this.HFilesService = HFilesService;
        this.HospivilleService = HospivilleService;
        this.ToastService = ToastService;
        this.PatientService = PatientService;
        this.Api = Api;
        this.Access = Access;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.InformationService = InformationService;
        /**
         * Allowed to see files depends on the patient consent
         * @param file
         */
        this.isConsentAndAccess = (file) => {
            return !(this.patient && this.patient.consent && this.patient.consent.status !== EPatientConsent.OK
                && file && !Utils.compareIds(file.hospital, this.Access.hospital._id));
        };
        /**
         * Delete a single file or all files in the selection
         * @param file
         * @param index
         */
        this.deleteFile = (file = null, index) => {
            this.HFilesService.confirmDeleteFileModal([file]).then(() => {
                this.HFilesService.deleteFile(file).then(() => {
                    this.patient.files.splice(index, 1);
                    this.HospivilleService.savePatient(this.patient);
                    this.ToastService.show(EMpStatus.Ok);
                })
                    .catch(() => {
                    this.ToastService.show(EMpStatus.RejectByServer);
                });
            });
        };
        this.mpUploadIsOpen = false;
        this.FileUploaderService.init();
        this.uploader = this.FileUploaderService.uploader;
        this.patient = null;
        this.isPharmacy = this.Access.isPharmacy();
        this.FileUploaderService.onAfterAddingAll = () => {
            this.FileUploaderService.upload(EFileType.Hospiville);
        };
        this.FileUploaderService.onItemUploadSuccess = (item, f) => {
            f.type = EFileType.Hospiville;
            this.HFilesService.saveUploadedFile(item, f).then((file) => {
                item.isComplete = true;
                if (!this.patient.files) {
                    this.patient.files = [];
                }
                this.patient.files.push(file);
                this.HospivilleService.savePatient(this.patient);
                this.ToastService.show(EMpStatus.Ok);
            }).catch(err => {
                item.isError = true;
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        this.FileUploaderService.onBeforeUploadItem = (item, index) => {
            this.$timeout(() => {
                this.$scope.$broadcast('scroll-to', { 'selector': '.item-' + index });
            }, 200);
        };
    }
    $onInit() {
        this.Api.filesType.get((ans) => {
            this.filesType = ans;
        });
        if (this.HospivilleService.hasPatient) {
            this.HospivilleService.getPatient().then((patient) => {
                this.patient = patient;
            });
        }
        this.migrationState = this.Access.isHVMigrationState();
    }
}
