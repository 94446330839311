import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvDrugBSComponent } from './hv-drug-balance-sheet.component';
import { HvDrugBSController } from './hv-drug-balance-sheet.controller';
import { HvModalDrugBalanceAddSourceComponent } from './hv-modal-drug-balance-add-source/hv-modal-drug-balance-add-source.component';
import { HvDrugBalanceSheetSynthesisController } from './hv-drug-balance-sheet-synthesis/hv-drug-balance-sheet-synthesis.controller';
import { HvModalDrugBalanceAddSourceController } from './hv-modal-drug-balance-add-source/hv-modal-drug-balance-add-source.controller';
import { HvModalDuplicateDrugBalanceComponent } from './hv-modal-duplicate-drug-balance/hv-modal-duplicate-drug-balance.component';
import { HvModalDuplicateDrugBalanceController } from './hv-modal-duplicate-drug-balance/hv-modal-duplicate-drug-balance.controller';
import { HvSelectDrugBalanceSourceComponent } from './hv-select-drug-balance-source/hv-select-drug-balance-source.component';
import { HvSelectDrugBalanceSourceController } from './hv-select-drug-balance-source/hv-select-drug-balance-source.controller';
import { HvModalDrugBalanceAddPastMedRecComponent } from './hv-modal-drug-balance-add-past-medrec/hv-modal-drug-balance-add-past-medrec.component';
import { HvModalDrugBalanceAddPastMedRecController } from './hv-modal-drug-balance-add-past-medrec/hv-modal-drug-balance-add-past-medrec.controller';
angular
    .module('hospiville.patient.dbs', [])
    .config(configDrugBS)
    .run(runDrugBs)
    .component('hvDrugBs', new HvDrugBSComponent())
    .controller('HvDrugBSController', HvDrugBSController)
    .controller('HvDrugBalanceSheetSynthesisController', HvDrugBalanceSheetSynthesisController)
    .component('hvModalDrugBalanceAddSource', new HvModalDrugBalanceAddSourceComponent())
    .controller('HvModalDrugBalanceAddSourceController', HvModalDrugBalanceAddSourceController)
    .component('hvModalDrugBalanceAddPastMedRec', new HvModalDrugBalanceAddPastMedRecComponent())
    .controller('HvModalDrugBalanceAddPastMedRecController', HvModalDrugBalanceAddPastMedRecController)
    .component('hvModalDuplicateDrugBalance', new HvModalDuplicateDrugBalanceComponent())
    .controller('HvModalDuplicateDrugBalanceController', HvModalDuplicateDrugBalanceController)
    .component('hvSelectDrugBalanceSource', new HvSelectDrugBalanceSourceComponent())
    .controller('HvSelectDrugBalanceSourceController', HvSelectDrugBalanceSourceController);
/** @ngInject */
function configDrugBS($stateProvider) {
    $stateProvider.state('hospiville.bmo', {
        url: '/bmo',
        data: {
            access: EHospivillePermission.DBS_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-drug-bs></hv-drug-bs>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville drug-bs',
    });
}
function runDrugBs($templateCache) {
    $templateCache.put('app/main/hospiville/hv-patient/hv-drug-balance-sheet/views/table/table-view.html', require('./views/table/table-view.html').default);
    $templateCache.put('app/main/hospiville/hv-patient/hv-drug-balance-sheet/views/list/list-view.html', require('./views/list/list-view.html').default);
}
