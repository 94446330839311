import { HvCarePlanComponent } from './hv-care-plan.component';
import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { HvCarePlanController } from './hv-care-plan.controller';
angular
    .module('hospiville.patient.care-plan', [])
    .config(configCarePlan)
    .component('hvCarePlan', new HvCarePlanComponent())
    .controller('HvCarePlanController', HvCarePlanController);
/** @ngInject */
function configCarePlan($stateProvider, msApiProvider) {
    $stateProvider.state('hospiville.care-plan', {
        url: '/plan-prise',
        data: {
            access: EHospivillePermission.CarePlan_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-care-plan></hv-care-plan>',
            },
        },
        resolve: {
            Data: function (msApi) {
                return msApi.resolve('treatment.data@get');
            },
        },
        bodyClass: 'hospiville care-plan',
    });
    msApiProvider.register('treatment.data', ['app/main/hospiville/hv-patient/tmp-data/treatment.json']);
}
