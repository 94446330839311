import { EMpStatus, ESource, ETransit, } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HvPrescriptionController {
    /** @ngInject */
    constructor(HospivilleService, InformationService, ModalService, ToastService, SourceService, MedicationReconciliationService, Api, Access, _, $scope, $state, $q) {
        this.HospivilleService = HospivilleService;
        this.InformationService = InformationService;
        this.ModalService = ModalService;
        this.ToastService = ToastService;
        this.SourceService = SourceService;
        this.MedicationReconciliationService = MedicationReconciliationService;
        this.Api = Api;
        this.Access = Access;
        this._ = _;
        this.$scope = $scope;
        this.$state = $state;
        this.$q = $q;
        /**
         * Set a given [[IDrugInstance]] [[IMedUCD]]
         * @param drug
         * @param inst
         * @param index
         */
        this.selectDrugFor = (event, inst, index) => {
            inst.denomination = event.drug.denomination;
            inst.UCD13 = event.drug.UCD13;
            inst.codeATC = event.drug.codeATC;
            inst.DCI = event.drug.DCI;
            if (!inst.validSource) {
                inst.validSource = {};
            }
            if (this._.find(this.constTypes.dosages, { value: +event.pharmaForm }) && !inst.validSource.dosageType && event.pharmaForm) {
                inst.validSource.dosageType = Number(event.pharmaForm);
            }
            this.prescription.instances.splice(index, 1, angular.copy(inst));
        };
        /**
         * Add a new [[IDrugInstance]] to the current [[IDrugBalance]]
         */
        this.addLine = () => {
            let newDI = {
                index: this.HospivilleService.getMaxIndex() + 1,
                drugBalance: { _id: this.prescription._id },
            };
            this.prescription.instances.push(newDI);
        };
        /**
         * Set doctor to null
         * @param {string} search
         */
        this.searchDoctorChanged = ({ search }) => {
            this.doctor = null;
        };
        /**
         * Set the [[IHealthProfessional]] (doctor)  source to the [[IDrugBalance]]
         * @param doctor
         */
        this.selectHealthPro = ({ doctor }) => {
            if (!doctor) {
                return;
            }
            if (!this.prescription.sources[0]) {
                this.prescription.sources.push({ date: new Date, source: { type: ESource.Doctor } });
            }
            if (!this.prescription.sources[0].source) {
                this.prescription.sources[0].source = { type: ESource.Doctor };
            }
            this.prescription.sources[0].source.healthProfessional = doctor;
            this.doctor = this.prescription.sources[0].source;
            if (Utils.hasId(this.prescription.sources[0].source)) {
                this.SourceService.save(this.prescription.sources[0].source);
            }
            else {
                this.SourceService.add(this.prescription.sources[0].source).then((src) => {
                    this.prescription.sources[0].source = src;
                });
            }
        };
        /**
         * Set given [[IDrugInstanceSource]] posology
         * @param posology
         * @param instSource
         */
        this.posologyChange = ({ posologies }, instSource) => {
            if (!instSource)
                instSource = {};
            instSource.posologies = posologies;
        };
        /**
         * Set given [[IDrugInstanceSource]] dosage
         * @param {number} quantity
         * @param {number} type
         * @param {IDropInfo} dropInfo
         * @param {IDrugInstanceSource} instSource
         */
        this.dosageChange = ({ quantity, type, dropInfo }, instSource) => {
            if (!instSource)
                instSource = {};
            instSource.dosage = quantity;
            instSource.dosageType = type;
            if (quantity != 14)
                instSource.dosageDropInfo = dropInfo;
        };
        /**
         * Save the current [[IDrugBalance]]
         */
        this.save = () => {
            const defer = this.$q.defer();
            this.HospivilleService.saveDrugBalance(this.prescription)
                .then((prescription) => {
                this._isSave = true;
                this.prescription = prescription;
                this.ToastService.show(EMpStatus.Ok);
                defer.resolve();
            })
                .catch(() => {
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Delete a [[IDrugInstance]] from the [[IDrugBalance]]
         * @param inst
         * @param index
         */
        this.deleteInst = (inst, index) => {
            if (!Utils.hasId(inst)) {
                this.prescription.instances.splice(index, 1);
            }
            else {
                this.HospivilleService.deleteDrugInst(inst)
                    .then(() => {
                    this.prescription.instances.splice(index, 1);
                    this.ToastService.show(EMpStatus.Ok);
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            }
        };
        /**
         * Init prescription data
         * @private
         */
        this._initPrescription = () => {
            this.Api.hvConstType.get((constTypes) => {
                this.constTypes = constTypes;
                if (!this.HospivilleService.hasPatient) {
                    return;
                }
                this.HospivilleService.getMedRec()
                    .then((medRec) => {
                    this.medRec = medRec;
                    if (medRec && medRec._id) {
                        this.medRec = medRec;
                        this.prescription = medRec.prescription;
                        if (this.prescription && this.prescription._id) {
                            this.setDoctorRequiring();
                            this.doctor = this.prescription.sources[0] ? this.prescription.sources[0].source : null;
                            this.transitType = (!this.prescription.transit && this.prescription.transit !== 0) ? null : this._.find(constTypes.transit, { value: this.prescription.transit }).translate;
                        }
                        else {
                            this.prescription = {
                                sources: [],
                                instances: [],
                                transit: medRec.transit,
                            };
                            this.HospivilleService.addPrescription(this.prescription)
                                .then((newPrescription) => {
                                medRec.prescription = newPrescription;
                                this.HospivilleService.saveMedRec(medRec);
                                this.prescription._id = newPrescription._id;
                                this.setDoctorRequiring();
                            });
                        }
                        this.ready = !!(medRec.dbs);
                    }
                });
            });
        };
        /**
         *  set the  prescribing doctor field required by the prescription type
         */
        this.setDoctorRequiring = () => {
            this.required = this.prescription.transit !== ETransit.Entrance;
        };
        this.populateValidSource = (inst) => {
            if (!inst.validSource) {
                inst.validSource = {};
            }
        };
        /**
         * Tells of the save button should be disabled.
         */
        this.disableSave = () => {
            if (!this.formPrescription) {
                return false;
            }
            return this.formPrescription.$invalid || (!this.doctor && this.required);
        };
        this._isSave = false;
        this.required = true;
        this.ready = false;
        this._confirmLeave = false;
        this.$scope.$on('refreshPatientFile', () => {
            this._initPrescription();
        });
        this.migrationState = this.Access.isHVMigrationState();
        if (!this.migrationState) {
            this.$scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
                if (this.disableSave() && !this._confirmLeave) {
                    event.preventDefault();
                    this.ModalService.show({
                        component: 'mp-modal-confirm',
                        bindings: {
                            data: {
                                title: 'HOSPIVILLE.PRESCRIPTION.MISSING_INFO.TITLE',
                                text: 'HOSPIVILLE.PRESCRIPTION.MISSING_INFO.TEXT',
                            },
                        },
                        escapeToClose: false,
                        clickOutsideToClose: false
                    }).then(() => {
                        this._confirmLeave = true;
                        this.$state.go(toState);
                    });
                }
                else if (!this.disableSave()) {
                    if (!this.formPrescription || (this.formPrescription && this.formPrescription.$pristine)) {
                        this._isSave = true;
                    }
                    if (!this._isSave) {
                        this._isSave = true;
                        event.preventDefault();
                        this.ModalService.show({
                            component: 'mp-modal-confirm',
                            bindings: {
                                data: {
                                    title: 'HOSPIVILLE.PRESCRIPTION.CONFIRM_LEAVE.TITLE',
                                    text: 'HOSPIVILLE.PRESCRIPTION.CONFIRM_LEAVE.TEXT',
                                },
                            },
                            escapeToClose: false,
                        }).then(() => {
                            this.save().then(() => {
                                this.$state.go(toState);
                            }).catch(() => {
                                this.ToastService.show(EMpStatus.RejectByServer);
                            });
                        }).catch(() => {
                            this.$state.go(toState);
                        });
                    }
                }
            });
        }
    }
    $onInit() {
        this._initPrescription();
    }
}
