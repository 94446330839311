var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EstablishmentPatientService } from '../../establishment-patient/establishment-patient.service';
import { MedicationReconciliationService } from '../medication-reconciliation/medication-reconciliation.service';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from '@main/services/Utils';
import { Access } from '@main/services/auth/access.service';
let HospivilleSessionService = class HospivilleSessionService {
    constructor(_cookieService, _establishmentPatientService, _medRecService, _ajsAccess) {
        this._cookieService = _cookieService;
        this._establishmentPatientService = _establishmentPatientService;
        this._medRecService = _medRecService;
        this._ajsAccess = _ajsAccess;
        this.selectedPatient = new BehaviorSubject(null);
        this.selectedMedRec = new BehaviorSubject(null);
        const cookiesInfos = this._getCookieInfos();
        this._establishmentPatientService.getById(cookiesInfos._id).subscribe((patient) => {
            this.selectedPatient.next(patient);
        }, (err) => {
            return err;
        });
        this._medRecService.findOneByPatient(cookiesInfos._id, cookiesInfos.medicationReconciliation._id).subscribe((medRec) => {
            this.selectedMedRec.next(medRec);
        }, (err) => {
            return err;
        });
    }
    set setActivePatient(patient) {
        // this._setCookieInfos(Utils.getId(patient), Utils.getId(this.selectedMedRec.getValue()));
        /** To remove when HV toolbar will gonna be updated and use line above instead **/
        this._ajsAccess.changePatient(Utils.getId(patient), Utils.getId(this.selectedMedRec.value));
        /** **/
        this.selectedPatient.next(patient);
    }
    get getActivePatient() {
        return this.selectedPatient.asObservable();
    }
    set setActiveMedRec(medRec) {
        // this._setCookieInfos(Utils.getId(this.selectedPatient.getValue()), Utils.getId(medRec));
        /** To remove when HV toolbar will gonna be updated and use line above instead **/
        this._ajsAccess.changePatient(Utils.getId(this.selectedPatient.value), Utils.getId(medRec));
        /** **/
        this.selectedMedRec.next(medRec);
    }
    get getActiveMedRec() {
        return this.selectedMedRec.asObservable();
    }
    resetCookies() {
        this._setCookieInfos(null, null);
        this.selectedPatient.next(null);
        this.selectedMedRec.next(null);
    }
    _getCookieInfos() {
        if (this._cookieService.get('patient')) {
            return JSON.parse(this._cookieService.get('patient'));
        }
        else {
            return { _id: null, medicationReconciliation: { _id: null } };
        }
    }
    _setCookieInfos(patient, medRec) {
        this._cookieService.set('patient', JSON.stringify({ _id: patient, medicationReconciliation: { _id: medRec } }));
    }
};
HospivilleSessionService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof CookieService !== "undefined" && CookieService) === "function" ? _a : Object, typeof (_b = typeof EstablishmentPatientService !== "undefined" && EstablishmentPatientService) === "function" ? _b : Object, typeof (_c = typeof MedicationReconciliationService !== "undefined" && MedicationReconciliationService) === "function" ? _c : Object, typeof (_d = typeof Access !== "undefined" && Access) === "function" ? _d : Object])
], HospivilleSessionService);
export { HospivilleSessionService };
