export const locale = {
    lang: `fr`,
    data: {
        MAP_DETAIL_SIDEBAR: {
            DETAILS: {
                TITLE: `Détails`,
                ADDRESS: `Adresse`,
                PHONE: `Téléphones et fax`,
                PHONE_SCT: `Tél. Secrétariat Pharmacie :`,
                PHONE_STD: `Tél. Standard Hôpital :`,
                FAX: `Fax :`,
                SEE_AVAILABLE: 'Voir le détail',
                SEE_MORE: 'Voir plus'
            },
            PHARMACIST: `Pharmaciens`,
            PREPARATOR: `Préparateurs`,
            LOANS: {
                TITLE_AVAILABLE: `Mises à disposition`,
                TITLE_REQUEST: `Demandes`,
                EXPIRY_DATE: `Date de péremption :`,
                QUANTITY: `Quantité :`
            },
            SNACKBAR: `L'élément a été mis à jour.`
        }
    }
};
