export class HvListInstancesComponent {
    constructor() {
        this.controller = 'HvListInstancesController';
        this.controllerAs = 'vm';
        this.template = require('./hv-list-instances.html').default;
        this.bindings = {
            instances: '<',
            drugBalance: '<',
            source: '<',
            addToDbs: '<',
            onSave: '&',
        };
    }
}
