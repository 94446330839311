export const locale = {
    lang: 'en',
    data: {
        NAVIGATION: {
            EQUIVALENCES: {
                TITLE: 'Equivalences'
            }
        },
        EQUIVALENCES: {
            TITLE: 'Equivalences',
            SEARCH: 'Serch an equivalence',
            BUTTON_ADD: 'Add an Equivalence',
            BUTTON_EXCEL: 'Import',
            MEDIC: 'Medic Name',
            MEDIC_DCI: 'Medic INN',
            MEDIC_POSOLOGY: 'Medic Posology',
            EQUIVALENCE: 'Equivalence',
            EQUIVALENCE_DCI: 'Equivalence INN',
            REASON: 'Reason',
            ACTION: 'Action',
            DELIVER_MEDIC: 'Delivered after medical advice',
            EDIT: 'Edit',
            ACTIONS: 'Actions',
            SHARING: {
                SHARES: 'Shares',
                SHARE_WITH: 'Share with :'
            },
            ADD_MODAL: {
                NEW: 'Add an equivalence',
                MEDIC_DESC: 'Medicine information :',
                MEDIC_NAME: 'Medicine Name',
                MEDIC_DCI: 'International non-proprietary name (INN)',
                MEDIC_POSOLOGY: 'Drug posology',
                EQUI_DESC: 'Equivalent Information :',
                EQUI_NAME: 'Equivalent Name',
                EQUI_DCI: 'International non-proprietary name (INN)',
                EQUI_DESC2: 'Second equivalent Information :',
                DELIVER: 'Delivered',
                DELIVER_MEDIC: 'Delivered after medical advice',
                DELIVERY_TYPE: 'Delivery type',
                COMMENT: 'Comment',
                ACTION: 'Action',
                REASON: 'Reason',
                OK: 'Add an equivalence',
                EDIT: 'Edit this equivalence',
                SUCCESS: {
                    NEW: 'Equivalence created',
                    EDIT: 'Equivalence updated'
                }
            },
            SHARED: 'Shared',
            DELETE_MODAL: {
                TITLE: 'Delete this equivalence',
                TEXT: 'Do you really want to remove this equivalence?',
                SUCCESS: 'Equivalence deleted'
            },
            DELETE_VOUCHER_MODAL: {
                TITLE: 'Delete this voucher',
                TEXT: 'Do you really want to remove this voucher?'
            },
            IMPORT_MODAL: {
                TITLE: 'Import some equivalences',
                FIRST_ROW: 'Use the first row as column titles',
                SELECT_SHEET: 'Select the sheet that will be used :',
                SELECT_COLUMN: 'Indicate which column should be used for each input',
                MEDIC_NAME: 'Drug Name',
                MEDIC_DCI: 'International non-proprietary name (INN)',
                MEDIC_POSOLOGY: 'Drug posology',
                EQUI_NAME: 'Equivalent Name',
                EQUI_DCI: 'International non-proprietary name (INN)',
                DELIVERY_TYPE: 'Delivery type',
                ACTION: 'Action',
                REASON: 'Reason',
                COMMENT: 'Equivalent Dosage',
                OK: 'Import'
            },
            IMPORT: {
                SUCCESS: {
                    STATUS: 'Created equivalences',
                    INSTRUCTIONS: 'The equivalences listed below have been created successfully.'
                },
                ERROR: {
                    STATUS: 'Not imported equivalences',
                    INSTRUCTIONS: 'The equivalences listed below have not been imported.'
                }
            },
            PDF_MODAL: {
                TITLE: 'Generate an PDF record for the Equivalence',
                SERVICE: 'Service',
                PATIENT_NAME: 'Patient names',
                PATIENT_ROOM: 'Patient room',
                DATE: 'Deliver the',
                REASON: 'Reason',
                REASONS: {
                    NO_BOOKLET: 'Not in booklet',
                    NOT_APPROVED: 'Not approved for hospitals',
                    LABORATORY_SOLDOUT: 'Laboratory Sold Out',
                    WAITING_DELIVERY: 'Waiting for Delivery',
                    BY_PATIENT: 'Medicine brought by patient'
                },
                GENERATE: 'Generate a voucher',
                SAVE: 'Save',
                DOWNLOAD: 'Download the voucher',
                DOSAGE_MEDIC: 'Medicine Dosage',
                DOSAGE_EQUIVALENCE: 'Equivalence Dosage',
                DOSAGE_EQUIVALENCE2: 'Second quivalence Dosage'
            },
            DETAILS: {
                TITLE: 'Equivalence',
                INFO: 'Information',
                MEDIC_NAME: 'Medicine name',
                MEDIC_DCI: 'International non-proprietary name (INN)',
                MEDIC_POSOLOGY: 'Drug posology',
                EQUI_NAME: 'Equivalent Name',
                EQUI_DCI: 'International non-proprietary name (INN)',
                COMMENT: 'Equivalent Dosage'
            },
            SIDENAV: {
                INFO: 'Information',
                MEDIC_NAME: 'Medic Name',
                MEDIC_DCI: 'Medic INN',
                MEDIC_POSOLOGY: 'Drug posology',
                EQUIVALENCE_NAME: 'Equivalence Name',
                EQUIVALENCE_DCI: 'Equivalence INN',
                EQUIVALENCE_NAME2: '2nd Equivalence Name',
                EQUIVALENCE_DCI2: '2nd Equivalence INN',
                COMMENT: 'Equivalent Dosages',
                VOUCHER: 'History',
                ADD_VOUCHER: 'Add a voucher',
                MODIFIED_BY: 'Edited by',
                MODIFIED_WHEN: 'Date of edition'
            }
        }
    }
};
