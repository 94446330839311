export class HvSpecificPosologyComponent {
    constructor() {
        this.controller = 'HvSpecificPosologyController';
        this.controllerAs = 'vm';
        this.template = require('./hv-specific-posology.html').default;
        this.bindings = {
            posologies: '<',
            noStyle: '<',
        };
    }
}
