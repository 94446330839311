import { EFilterType, EHsPublicationFilter, EHsPublicationTag } from '@mapuilabs/mpl-interfaces';
// -----------------------------------------------------------------------------------------------------
// @ Shared
// -----------------------------------------------------------------------------------------------------
const filters = {
    empty: {
        title: 'EMPTY',
        value: {
            filters: [],
            replaceHistory: false
        }
    },
    unread: {
        title: 'UNREAD',
        value: {
            filters: [
                {
                    attribute: EHsPublicationFilter.UNREAD,
                    type: EFilterType.VALUE,
                    value: true
                }
            ],
            replaceHistory: false
        }
    },
    important: {
        title: 'IMPORTANT',
        value: {
            filters: [
                {
                    attribute: EHsPublicationFilter.IMPORTANT,
                    type: EFilterType.VALUE,
                    value: true
                }
            ],
            replaceHistory: false
        }
    }
};
export const CHsPresetFilters = Object.values(filters);
// -----------------------------------------------------------------------------------------------------
// @ Pharmacy
// -----------------------------------------------------------------------------------------------------
const filtersPharmacy = {
    puiCurrent: {
        title: 'PUI_CURRENT',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.PUI,
                    type: EFilterType.VALUE,
                    value: true
                },
                {
                    attribute: EHsPublicationFilter.TERMINATED,
                    type: EFilterType.VALUE,
                    value: false
                }
            ],
            replaceHistory: false
        }
    },
    puiTerminated: {
        title: 'PUI_TERMINATED',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.PUI,
                    type: EFilterType.VALUE,
                    value: true
                },
                {
                    attribute: EHsPublicationFilter.TERMINATED,
                    type: EFilterType.VALUE,
                    value: true
                }
            ],
            replaceHistory: false
        }
    },
    puiNoArchived: {
        title: 'PUI_NO_ARCHIVED',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.PUI,
                    type: EFilterType.VALUE,
                    value: true
                },
                {
                    attribute: EHsPublicationTag.ARCHIVED,
                    type: EFilterType.VALUE,
                    value: false
                }
            ],
            replaceHistory: false
        }
    }
};
export const CHsDefaultFiltersPharmacy = filtersPharmacy.puiNoArchived;
export const CHsPresetFiltersPharmacy = Object.values(filtersPharmacy);
// -----------------------------------------------------------------------------------------------------
// @ Group
// -----------------------------------------------------------------------------------------------------
const filtersGroup = {
    gaCurrent: {
        title: 'GA_CURRENT',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.GA,
                    type: EFilterType.VALUE,
                    value: true
                },
                {
                    attribute: EHsPublicationFilter.TERMINATED,
                    type: EFilterType.VALUE,
                    value: false
                }
            ],
            replaceHistory: false
        }
    },
    gaTerminated: {
        title: 'GA_TERMINATED',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.GA,
                    type: EFilterType.VALUE,
                    value: true
                },
                {
                    attribute: EHsPublicationFilter.TERMINATED,
                    type: EFilterType.VALUE,
                    value: true
                }
            ],
            replaceHistory: false
        }
    },
    gaNoArchived: {
        title: 'GA_NO_ARCHIVED',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.GA,
                    type: EFilterType.VALUE,
                    value: true
                },
                {
                    attribute: EHsPublicationTag.ARCHIVED,
                    type: EFilterType.VALUE,
                    value: false
                }
            ],
            replaceHistory: false
        }
    }
};
export const CHsDefaultFiltersGroup = filtersGroup.gaNoArchived;
export const CHsPresetFiltersGroup = Object.values(filtersGroup);
// -----------------------------------------------------------------------------------------------------
// @ Labo
// -----------------------------------------------------------------------------------------------------
const filtersLabo = {
    current: {
        title: 'CURRENT',
        value: {
            filters: [
                {
                    attribute: EHsPublicationFilter.TERMINATED,
                    type: EFilterType.VALUE,
                    value: false
                }
            ],
            replaceHistory: false
        }
    },
    terminated: {
        title: 'TERMINATED',
        value: {
            filters: [
                {
                    attribute: EHsPublicationFilter.TERMINATED,
                    type: EFilterType.VALUE,
                    value: true
                }
            ],
            replaceHistory: false
        }
    },
    noArchived: {
        title: 'NO_ARCHIVED',
        value: {
            filters: [
                {
                    attribute: EHsPublicationTag.ARCHIVED,
                    type: EFilterType.VALUE,
                    value: false
                }
            ],
            replaceHistory: false
        }
    }
};
export const CHsDefaultFiltersLabo = filtersLabo.noArchived;
export const CHsPresetFiltersLabo = Object.values(filtersLabo);
// -----------------------------------------------------------------------------------------------------
// @ Admin
// -----------------------------------------------------------------------------------------------------
const filtersAdmin = {
    empty: {
        title: 'EMPTY',
        value: {
            filters: [],
            replaceHistory: false
        }
    }
};
export const CHsDefaultFiltersAdmin = filtersAdmin.empty;
export const CHsPresetFiltersAdmin = Object.values(filtersAdmin);
