var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ListOnlineUsersComponent } from './list-online-users/list-online-users.component';
import { DetailOnlineUsersComponent } from './detail-online-users/detail-online-users.component';
const states = [
    {
        name: 'mapui.adm.online_users.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: ListOnlineUsersComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        }
    },
    {
        name: 'mapui.adm.online_users.details',
        url: '/details/:id',
        views: {
            'content@mapui': {
                component: DetailOnlineUsersComponent
            }
        },
        data: {
            theme: 'theme-purple'
        },
        params: {
            id: {
                dynamic: false
            }
        }
    }
];
let OnlineUsersRoutingModule = class OnlineUsersRoutingModule {
};
OnlineUsersRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], OnlineUsersRoutingModule);
export { OnlineUsersRoutingModule };
