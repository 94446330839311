export class HvSearchHospReasonComponent {
    constructor() {
        this.controller = 'HvSearchHospReasonController';
        this.controllerAs = 'vm';
        this.template = require('./hv-search-hosp-reason.html').default;
        this.bindings = {
            reason: '<',
            onSelected: '&'
        };
    }
}
