var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Input } from '@angular/core';
import { MailASchema } from '@mapuilabs/mpl-interfaces';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MailsService } from '../../../../mails.service';
import { TranslationService } from '@services/translation/translation.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { CMailPriority } from '@shared/constTypes/mail/mail-priority.const';
let ActivityMailsListItemComponent = class ActivityMailsListItemComponent {
    constructor(_mailService, _fuseTranslationLoaderService, _translationService) {
        this._mailService = _mailService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translationService = _translationService;
        this.cMailPriority = CMailPriority;
        this.EActivityMailStatus = EActivityMailStatus;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    getPriorityTooltip() {
        var _a;
        return this._translationService.getTranslation(this.cMailPriority, (_a = this.mail) === null || _a === void 0 ? void 0 : _a.priority);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof MailASchema !== "undefined" && MailASchema) === "function" ? _d : Object)
], ActivityMailsListItemComponent.prototype, "mail", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ActivityMailsListItemComponent.prototype, "status", void 0);
ActivityMailsListItemComponent = __decorate([
    Component({
        selector: 'activity-mails-list-item',
        template: require('./activity-mails-list-item.component.html').default,
        styles: [require('./activity-mails-list-item.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MailsService !== "undefined" && MailsService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object])
], ActivityMailsListItemComponent);
export { ActivityMailsListItemComponent };
export var EActivityMailStatus;
(function (EActivityMailStatus) {
    EActivityMailStatus[EActivityMailStatus["sent"] = 1] = "sent";
    EActivityMailStatus[EActivityMailStatus["received"] = 2] = "received";
})(EActivityMailStatus || (EActivityMailStatus = {}));
