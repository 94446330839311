export class HvModalPharmaInterventionComponent {
    constructor() {
        this.controller = 'HvModalPharmaInterventionController';
        this.controllerAs = 'vm';
        this.template = require('./hv-modal-pharma-intervention.html').default;
        this.bindings = {
            intervention: '<',
            drugs: '<',
        };
    }
}
