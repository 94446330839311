var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { MapService } from '../../shared/map/map.service';
import { FilterManager } from '../../shared/map/filter-manager.class';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { ExchangeMapService } from './map-exchange.service';
import { LoanMapService } from '../../services/loan/map.service';
let ExchangeMapComponent = class ExchangeMapComponent {
    constructor(_fuseTranslationLoaderService, _mapService, _loanMapService, _exchangeMapService, _fuseSidebarService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._mapService = _mapService;
        this._loanMapService = _loanMapService;
        this._exchangeMapService = _exchangeMapService;
        this._fuseSidebarService = _fuseSidebarService;
        this.options = this._mapService.defaultOptions;
        this.markerClusterOptions = {
            showCoverageOnHover: false,
            maxClusterRadius: 15,
            iconCreateFunction: this._mapService.uniqueClusterIcon
        };
        this.markerClusterData = [];
        this.filterManager = new FilterManager();
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngAfterViewInit() {
        this._fuseSidebarService.getSidebar('sidebar-details').openedChanged.subscribe((isOpen) => {
            if (!isOpen) {
                this._exchangeMapService.sidebarHospital = null;
            }
        });
    }
    ngOnDestroy() {
        this._exchangeMapService.flushGroups();
        this._exchangeMapService.resetFilters();
        this._exchangeMapService.resetSidebar();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    markerClusterReady(group) {
        this._exchangeMapService.markerClusterGroup = group;
        this.markerClusterGroup = this._exchangeMapService.markerClusterGroup;
        // this._updateHospitalsList();
        this.filterManager.filterUpdated.subscribe((filters) => {
            this._updateHospitalsList(filters);
        });
    }
    onMapReady(map) {
        map.addControl(L.control.zoom({ position: 'topright' }));
        this._map = map;
    }
    _updateHospitalsList(filters = null) {
        this._exchangeMapService.flushGroups();
        this._loanMapService.getAll(filters).subscribe((res) => {
            this._exchangeMapService.loanMapHospitals = res; // tmp
            this._exchangeMapService.createMarkers();
            this._exchangeMapService.filterManager = this.filterManager;
        });
    }
};
ExchangeMapComponent = __decorate([
    Component({
        selector: 'exchange-map',
        template: require('./map.component.html').default,
        styles: [require('../../shared/map/map.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof MapService !== "undefined" && MapService) === "function" ? _b : Object, typeof (_c = typeof LoanMapService !== "undefined" && LoanMapService) === "function" ? _c : Object, typeof (_d = typeof ExchangeMapService !== "undefined" && ExchangeMapService) === "function" ? _d : Object, typeof (_e = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _e : Object])
], ExchangeMapComponent);
export { ExchangeMapComponent };
