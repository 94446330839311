var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Injectable } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ELoanCat, ELoanSubCat } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import { IdentityService } from '../../identity/identity.service';
let ExchangeStatusesService = class ExchangeStatusesService {
    constructor(_identityService, _fuseTranslationLoaderService) {
        this._identityService = _identityService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._refreshHospital = () => {
            if (this._identityService.hospital) {
                this._hid = this._identityService.hospital._id;
            }
            else {
                this._hid = null;
            }
        };
        /**
         * Find the dedicated asset according to the category of the given loan.
         * @param loan [[ILoan]]
         */
        this.getIcon = (loan) => {
            if (this._isPrep(loan)) {
                return 'preparation';
            }
            if (this._isMed(loan)) {
                return 'pill';
            }
            if (this._isDM(loan)) {
                return 'medical-device';
            }
        };
        /**
         * Return whether the loan is a preparation loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isPrep = (loan) => {
            if (!loan) {
                return false;
            }
            return ELoanCat.Prep == loan.loanCategoryCreation;
        };
        /**
         * Return whether the loan is an available
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isMaD = (loan) => {
            if (!loan) {
                return false;
            }
            return ELoanCat.Available == loan.loanCategoryCreation;
        };
        /**
         * Return whether the loan is a medical device loan.
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isDM = (loan) => {
            if (!loan) {
                return false;
            }
            return ELoanSubCat.DM == loan.loanSubCategoryCreation;
        };
        /**
         * Return whether the loan is a medication loan.
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isMed = (loan) => {
            if (!loan) {
                return false;
            }
            return ELoanSubCat.Medic == loan.loanSubCategoryCreation;
        };
        /**
         * Return whether the loan need an acceptation step
         * Used only for and preparations
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._needAcceptation = (loan) => {
            if (!loan) {
                return false;
            }
            return this._isPrep(loan);
        };
        /**
         * Return whether the loan is accepted
         * Used only for preparations
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isAcceptedPrep = (loan) => {
            if (!loan) {
                return false;
            }
            return loan.inPrep != null;
        };
        /**
         * Return whether the current user is the lender of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._amIL = (loan) => {
            if (!loan) {
                return false;
            }
            return Utils.compareIds(this._hid, loan.lender);
        };
        /**
         * Return whether the current user is the borrower of the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._amIB = (loan) => {
            if (!loan) {
                return false;
            }
            return Utils.compareIds(this._hid, loan.borrower);
        };
        /**
         * Return whether the loan is a loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isLoan = (loan) => {
            if (!loan) {
                return false;
            }
            return Utils.compareIds(loan.creaMember, loan.loanPerson);
        };
        /**
         * Return whether the preparation is received
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isReceived = (loan) => {
            return this._isPrep(loan) && Utils.hasId(loan.steCheckPerson);
        };
        /**
         * Return whether the loan is a borrow
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isBorrow = (loan) => {
            if (!loan) {
                return false;
            }
            // If the loan is already created then we cas check the borrowingPerson._id
            const createdLoanIsBorrow = Utils.compareIds(loan.creaMember, loan.borrowingPerson);
            // If the loan is in creation the previous condition is false and we check if the user selected the borrowing of a drug
            return !createdLoanIsBorrow &&
                loan.loanCategoryCreation === 1 &&
                loan.loanSubCategoryCreation === 1 &&
                !loan.loanTypeCreation
                ? true
                : createdLoanIsBorrow;
        };
        /**
         * Return whether the return is valid
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isReturnVerified = (loan) => {
            return Utils.hasId(loan.steCheckPerson);
        };
        /**
         * Return whether the loan is returned
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isReturned = (loan) => {
            /* if (!loan) return false;
             if (loan.oldReturnDate != undefined && loan.oldReturnDate != null)
             return !!loan.oldReturnDate;
             else*/
            return !!loan.returnDate;
        };
        /**
         * Return whether the loan need a return
         * @param loan the [[Loan]] to test
         * @returns {boolean}
         */
        this._isReturn = (loan) => {
            if (!loan) {
                return false;
            }
            return loan.returnExpected;
        };
        /**
         * Return whether the loan is a bill
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isBill = (loan) => {
            if (!loan) {
                return false;
            }
            return !this._isReturn(loan);
        };
        /**
         * Return whether the lender has billed the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._hasLBilled = (loan) => {
            if (!loan) {
                return false;
            }
            return !!loan.billingDateLender;
        };
        /**
         * Return whether the borrower has billed the loan
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._hasBBilled = (loan) => {
            if (!loan) {
                return false;
            }
            return !!loan.billingDateBorrower;
        };
        /**
         * Return whether the loan is confirmed
         * @param loan the [[ILoan]] to test
         * @returns {boolean}
         */
        this._isConfirmed = (loan) => {
            if (!loan) {
                return false;
            }
            return !!loan.confirmationDate;
        };
        /**
         *
         * @param loan
         */
        this.getStatusName = (loan) => {
            let res = 'ok';
            if (this._isMaD(loan)) {
                res = 'waitingAvailable';
            }
            else if (this._needAcceptation(loan) && !this._isAcceptedPrep(loan)) {
                if (this._amIL(loan)) {
                    res = 'toAccept';
                }
                else {
                    res = 'waitingAccept';
                }
            }
            else if (!this._isConfirmed(loan)) {
                if (this._isPrep(loan)) {
                    if (this._amIL(loan)) {
                        res = 'toLiberate';
                    }
                    else {
                        res = 'waitingLiberate';
                    }
                }
                else if ((this._isLoan(loan) && this._amIB(loan)) || (this._isBorrow(loan) && this._amIL(loan))) {
                    res = 'toConfirm';
                }
                else {
                    if (loan.loanCategoryCreation === ELoanCat.Request) {
                        res = 'waitingPublished';
                    }
                    else {
                        res = 'waitingConfirm';
                    }
                }
            }
            else if (this._isPrep(loan) && !this._isReceived(loan)) {
                if (this._amIB(loan)) {
                    res = 'toReceive';
                }
                else {
                    res = 'waitingReceive';
                }
            }
            else if (this._isReturn(loan)) {
                if (!this._isReturned(loan)) {
                    if (this._amIB(loan)) {
                        res = 'toReturn';
                    }
                    else {
                        res = 'waitingReturn';
                    }
                }
                else if (!this._isReturnVerified(loan)) {
                    if (this._amIL(loan)) {
                        res = 'toValidReturn';
                    }
                    else {
                        res = 'waitingValidReturn';
                    }
                }
            }
            else if (this._isBill(loan)) {
                if (!this._hasLBilled(loan)) {
                    if (this._amIL(loan)) {
                        res = 'toBill';
                    }
                    else {
                        res = 'waitingBill';
                    }
                }
                else if (!this._hasBBilled(loan)) {
                    if (this._amIB(loan)) {
                        res = 'toSettle';
                    }
                    else {
                        res = 'waitingSettle';
                    }
                }
            }
            return res;
        };
        this._refreshHospital();
    }
};
ExchangeStatusesService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], ExchangeStatusesService);
export { ExchangeStatusesService };
