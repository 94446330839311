var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListExternalActorsComponent } from './list-external-actors/list-external-actors.component';
import { ExternalActorsRoutingModule } from './external-actors-routing.module';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseConfirmDialogModule } from '@fuse/components';
import { ExternalActorDetailComponent } from './external-actor-detail/external-actor-detail.component';
import { UIRouterModule } from '@uirouter/angular';
import { ExternalConfigDetailComponent } from './external-config-detail/external-config-detail.component';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from '../../shared/shared.module';
let ExternalActorsModule = class ExternalActorsModule {
    constructor() { }
};
ExternalActorsModule = __decorate([
    NgModule({
        declarations: [ListExternalActorsComponent, ExternalActorDetailComponent, ExternalConfigDetailComponent],
        imports: [
            BrowserAnimationsModule,
            FuseSharedModule,
            MatIconModule,
            CommonModule,
            FuseDirectivesModule,
            MatTableModule,
            MatSortModule,
            MatRippleModule,
            MatPaginatorModule,
            MatMenuModule,
            MatButtonModule,
            MatSnackBarModule,
            MatDialogModule,
            FuseConfirmDialogModule,
            MatTabsModule,
            MatFormFieldModule,
            MatInputModule,
            MatSelectModule,
            UIRouterModule,
            ClipboardModule,
            MatProgressSpinnerModule,
            MatAutocompleteModule,
            SharedModule,
        ],
        exports: [ExternalActorsRoutingModule]
    }),
    __metadata("design:paramtypes", [])
], ExternalActorsModule);
export { ExternalActorsModule };
