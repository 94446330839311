var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, catchError, merge, retry, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
let SearchBarComponent = class SearchBarComponent {
    constructor(_changeDetectorRef) {
        this._changeDetectorRef = _changeDetectorRef;
        // Init EventEmitters
        this.inputChanges = new EventEmitter();
        this.search = new EventEmitter();
        this.focusIn = new EventEmitter();
        this.focusOut = new EventEmitter();
        this.optionSelected = new EventEmitter();
        this.inputReset = new EventEmitter();
        // Init subjects
        this.isLoading$ = new BehaviorSubject(false);
        // Init form controls
        this.searchFormControl = new UntypedFormControl();
        this._unsubscribeAll = new Subject();
    }
    get autocomplete() {
        return this._autocomplete;
    }
    set autocomplete(value) {
        this._autocomplete = coerceBooleanProperty(value);
    }
    get displayFn() {
        return (value) => { var _a; return typeof value === 'string' ? value : (_a = this.searchAutocompleteConfig) === null || _a === void 0 ? void 0 : _a.displayFn(value); };
    }
    get autocompleteSearchFn() {
        var _a;
        return (_a = this.searchAutocompleteConfig) === null || _a === void 0 ? void 0 : _a.autocompleteSearchFn;
    }
    get minimumLength() {
        var _a;
        return (_a = this.searchAutocompleteConfig) === null || _a === void 0 ? void 0 : _a.minimumLength;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if ('searchValue' in changes) {
            // Update form value
            this.searchFormControl.setValue(this.displayFn(this.searchValue), {
                emitEvent: changes.searchValue.isFirstChange()
            });
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        }
        if ('required' in changes) {
            // Handle required validator
            if (this.required) {
                this.searchFormControl.setValidators(Validators.required);
            }
            else {
                this.searchFormControl.clearValidators();
            }
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        }
        if ('disabled' in changes) {
            // Handle required validator
            if (this.disabled) {
                this.searchFormControl.disable();
            }
            else {
                this.searchFormControl.enable();
            }
            // Manually trigger rendering
            this._changeDetectorRef.detectChanges();
        }
    }
    ngOnInit() {
        // Handle autocomplete config
        if (this.autocomplete) {
            // Guard: Ensure config is provided
            if (!this.searchAutocompleteConfig) {
                throw new Error('Cannot initiate search autocomplete component, please provide config');
            }
            // Is the search value compliant with the provided miminum length
            const hasMinimumLength = (searchValue) => (searchValue === null || searchValue === void 0 ? void 0 : searchValue.length) >= this.minimumLength;
            // Retrieve options by calling the autocompleteSearchFn & handle the isLoading flag & debounce
            const searchOptions$ = this.searchFormControl.valueChanges.pipe(startWith(''), debounceTime(500), filter((searchValue) => !this.minimumLength || hasMinimumLength(searchValue)), tap(() => this.isLoading$.next(true)), switchMap((value) => this.autocompleteSearchFn(value)), tap(() => this.isLoading$.next(false)), catchError(() => {
                this.isLoading$.next(false);
                throw new Error('No result found');
            }), retry());
            this.searchFormControl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((value) => {
                this.inputChanges.emit(value);
            });
            // Reset options when search value does not have the required minimum length
            const resetOptions$ = this.searchFormControl.valueChanges.pipe(filter((searchValue) => !hasMinimumLength(searchValue)), map(() => []));
            // Retrieve options by suscribing to autocompleteSearchFn handler
            this.options$ = this.minimumLength ? merge(searchOptions$, resetOptions$) : searchOptions$;
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Fire onSearch event
     */
    onSearch() {
        this.search.emit(this.searchFormControl.value);
    }
    /**
     * Fire onFocusIn event
     */
    onFocusIn() {
        this.focusIn.emit();
    }
    /**
     * Fire onFocusOut event
     */
    onFocusOut() {
        this.focusOut.emit();
    }
    /**
     * Fire optionSelected event
     */
    onOptionSelected(option) {
        this.optionSelected.emit(option);
    }
    /**
     * Reset searchValue and search
     */
    onInputReset() {
        this.searchFormControl.reset();
        this.onSearch();
        this.inputReset.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchBarComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], SearchBarComponent.prototype, "placeholder", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchBarComponent.prototype, "required", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SearchBarComponent.prototype, "searchAutocompleteConfig", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SearchBarComponent.prototype, "searchValue", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_b = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _b : Object)
], SearchBarComponent.prototype, "inputChanges", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], SearchBarComponent.prototype, "search", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchBarComponent.prototype, "focusIn", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], SearchBarComponent.prototype, "focusOut", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], SearchBarComponent.prototype, "optionSelected", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], SearchBarComponent.prototype, "inputReset", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean),
    __metadata("design:paramtypes", [Boolean])
], SearchBarComponent.prototype, "autocomplete", null);
SearchBarComponent = __decorate([
    Component({
        selector: 'mpx-search-bar',
        template: require('./search-bar.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object])
], SearchBarComponent);
export { SearchBarComponent };
