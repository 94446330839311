var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Directive, HostBinding, Input } from '@angular/core';
let MpxButtonsContainerDirective = class MpxButtonsContainerDirective {
    constructor() {
        this.displayFlexClass = true;
        this.itemsCenterClass = true;
        this.marginBetweenX8Class = true;
        this.justifyEndClass = true;
        this.justify = 'end';
    }
    ngOnChanges(changes) {
        if ('justify' in changes) {
            switch (this.justify) {
                case 'start':
                    this.justifyStartClass = true;
                    this.justifyCenterClass = false;
                    this.justifyEndClass = false;
                    break;
                case 'center':
                    this.justifyStartClass = false;
                    this.justifyCenterClass = true;
                    this.justifyEndClass = false;
                    break;
                case 'end':
                    this.justifyStartClass = false;
                    this.justifyCenterClass = false;
                    this.justifyEndClass = true;
                    break;
                default:
                    break;
            }
        }
    }
};
__decorate([
    HostBinding('class.display-flex'),
    __metadata("design:type", Object)
], MpxButtonsContainerDirective.prototype, "displayFlexClass", void 0);
__decorate([
    HostBinding('class.items-center'),
    __metadata("design:type", Object)
], MpxButtonsContainerDirective.prototype, "itemsCenterClass", void 0);
__decorate([
    HostBinding('class.margin-between-x-8'),
    __metadata("design:type", Object)
], MpxButtonsContainerDirective.prototype, "marginBetweenX8Class", void 0);
__decorate([
    HostBinding('class.justify-start'),
    __metadata("design:type", Boolean)
], MpxButtonsContainerDirective.prototype, "justifyStartClass", void 0);
__decorate([
    HostBinding('class.justify-center'),
    __metadata("design:type", Boolean)
], MpxButtonsContainerDirective.prototype, "justifyCenterClass", void 0);
__decorate([
    HostBinding('class.justify-end'),
    __metadata("design:type", Object)
], MpxButtonsContainerDirective.prototype, "justifyEndClass", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], MpxButtonsContainerDirective.prototype, "justify", void 0);
MpxButtonsContainerDirective = __decorate([
    Directive({
        selector: '[mpx-buttons-container]'
    })
], MpxButtonsContainerDirective);
export { MpxButtonsContainerDirective };
