var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AvailableHospitalListComponent } from './available-hospital-list/available-hospital-list.component';
const states = [
    {
        name: 'mapui.exchangeV2.available.available-hospital.available',
        url: '/:id?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: AvailableHospitalListComponent
            }
        },
        params: {
            id: {
                dynamic: true,
                reload: false,
                value: '0'
            },
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-blue'
        }
    }
];
let AvailableHospitalRoutingModule = class AvailableHospitalRoutingModule {
};
AvailableHospitalRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterUpgradeModule.forChild({ states: states })],
        exports: [UIRouterUpgradeModule]
    })
], AvailableHospitalRoutingModule);
export { AvailableHospitalRoutingModule };
