import * as angular from 'angular';
import { EHospivillePermission } from '@mapuilabs/mpl-interfaces';
import { HvStatisticService } from './hv-statisitcs.service';
import { HvStatisticsComponent } from './hv-statistics.component';
import { HvStatisticsController } from './hv-statistics.controller';
import { HvModalWaitingDownloadComponent } from "./hv-modal-waiting-download/hv-modal-waiting-download.component";
import { HvModalWaitingDownloadController } from "./hv-modal-waiting-download/hv-modal-waiting-download.controller";
import 'angular-nvd3';
import 'c3';
import 'c3-angular';
angular
    .module('hospiville.statistics', ['nvd3', 'gridshore.c3js.chart'])
    .config(configStatisticsHV)
    .service('HvStatisticService', HvStatisticService)
    .component('hvStatistics', new HvStatisticsComponent())
    .controller('HvStatisticsController', HvStatisticsController)
    .component('hvModalWaitingDownload', new HvModalWaitingDownloadComponent())
    .controller('HvModalWaitingDownloadController', HvModalWaitingDownloadController);
/** @ngInject */
function configStatisticsHV($stateProvider) {
    $stateProvider.state('hospiville.statistics', {
        url: '/hospiville/statistics',
        data: {
            access: EHospivillePermission.Statistics_Read,
            hasSensitiveData: true,
        },
        views: {
            'contenthvangularjs@hospiville': {
                template: '<hv-statistics></hv-statistics>',
            },
        },
        resolve: {},
        bodyClass: 'hospiville statistics',
    });
}
