export class MpFormatSizeFilter {
    static filter() {
        return (bytes) => {
            const precision = 1;
            const units = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po'];
            const number = Math.floor(Math.log(bytes) / Math.log(1024));
            let result = (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);
            if (result.indexOf('.0') === result.length - 2) {
                result = result.substr(0, result.length - 2);
            }
            return result + ' ' + units[number];
        };
    }
}
