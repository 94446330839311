import { EConsentSource, EMpStatus, EPatientConsent } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { Utils } from '@main/services/Utils';
export class HvModalConsentController {
    /** @ngInject */
    constructor(ModalService, Access, MemberService, HospitalService) {
        this.ModalService = ModalService;
        this.Access = Access;
        this.MemberService = MemberService;
        this.HospitalService = HospitalService;
        /**
         * Reference to EPatientConsent
         */
        this.EPatientConsent = EPatientConsent;
        /**
         * Reference to EConsentSource
         */
        this.EConsentSource = EConsentSource;
        /**
         * Used for unchecked radio-buttons
         */
        this.resetTmpStatus = () => {
            this.tmpConsent.from = undefined;
            this.tmpStatus = undefined;
        };
        /**
         * Close modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close modal with actions performing
         */
        this.ok = () => {
            if (this.tmpStatus != undefined)
                this.tmpConsent.status = this.tmpStatus;
            this.consent = this.tmpConsent;
            this.consent.date = new Date();
            this.consent.hospital = this.Access.hospital;
            this.consent.user = this.Access.user;
            this.ModalService.close(this.consent);
        };
    }
    $onInit() {
        if (this.consent) {
            this.tmpConsent = angular.copy(this.consent);
            if (this.EPatientConsent.OK === this.tmpConsent.status ||
                this.EPatientConsent.KO === this.tmpConsent.status) {
                this.tmpStatus = this.tmpConsent.status;
                this.tmpConsent.status = this.EPatientConsent.NotRealized;
            }
        }
    }
    $onChanges(changes) {
        if (changes.consent) {
            this.consent = angular.copy(this.consent);
            if (this.consent && this.consent.user) {
                this.MemberService.get(Utils.getId(this.consent.user)).then((member) => {
                    this.user = member;
                });
            }
            if (this.consent && this.consent.hospital) {
                this.HospitalService.get(Utils.getId(this.consent.hospital)).then((hospital) => {
                    this.hospital = hospital;
                });
            }
        }
    }
}
