var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, Input } from '@angular/core';
import { HsDashboardService } from '@app/hospistock/shared/services/hs-dashboard.service';
import { Layout } from '@shared/components/widgets/dashboard/widgets';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
let HsDashboardComponent = class HsDashboardComponent {
    constructor() {
        this.pageHeaderConfig = {
            title: 'Dashboard',
            icon: 'dashboard'
        };
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_a = typeof Layout !== "undefined" && Layout) === "function" ? _a : Object)
], HsDashboardComponent.prototype, "layout", void 0);
HsDashboardComponent = __decorate([
    Component({
        selector: 'mpx-hs-dashboard',
        template: require('./hs-dashboard.component.html').default,
        providers: [HsDashboardService, HsHealthProductService]
    })
], HsDashboardComponent);
export { HsDashboardComponent };
