export const pharmacistsStatus = {
    headOfService: {
        name: "Head of department",
        value: 6,
        translate: "PHARMACIST.STATUS.HOD"
    },
    univHospitaltAssistant: {
        name: "Academic hospital assistant",
        value: 7,
        translate: "PHARMACIST.STATUS.AH_ASSISTANT"
    },
    specialistAssistant: {
        name: "Specialist assistant",
        value: 4,
        translate: "PHARMACIST.STATUS.SPECIALIST_ASSISTANT"
    },
    intern: {
        name: "Internal",
        value: 5,
        translate: "PHARMACIST.STATUS.INTERNAL"
    },
    assistantPharmacist: {
        name: "Assistant pharmacist",
        value: 9,
        translate: "PHARMACIST.STATUS.ASSISTANT_PHARMA"
    },
    managerPharmacist: {
        name: "Pharmacist manager",
        value: 1,
        translate: "PHARMACIST.STATUS.PHARMA_MANAGER"
    },
    attachePractitioner: {
        name: "Attached practitioner",
        value: 3,
        translate: "PHARMACIST.STATUS.ATTACHED_PRACTITIONER"
    },
    univHospitalPractitioner: {
        name: "Academic hospital practitioner",
        value: 8,
        translate: "PHARMACIST.STATUS.AH_PRACTITIONER"
    },
    hospitalPractitioner: {
        name: "Hospital practitioner",
        value: 2,
        translate: "PHARMACIST.STATUS.HOSPITAL_PRACTITIONER"
    },
    surgeon: {
        name: "Surgeon",
        value: 11,
        translate: "PHARMACIST.STATUS.SURGEON"
    }
};
