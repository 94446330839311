import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvPatientShareModalController {
    /** @ngInject */
    constructor(ModalService, PatientService, ToastService) {
        this.ModalService = ModalService;
        this.PatientService = PatientService;
        this.ToastService = ToastService;
        /**
         * method triggered when a hospital is selected
         * @param hospital
         */
        this.selectHospital = (hospital) => {
            this.selectedHospital = hospital;
        };
        /**
         * Add the selected hospital in the hospital list to ba shared to
         */
        this.addSelectedHospitalToList = () => {
            this.hospitalsList.push(this.selectedHospital);
            this.selectedHospital = null;
            this.triggerClean = true;
        };
        this.resetClean = () => {
            // console.log('reset !');
            this.triggerClean = false;
        };
        this.removeHospital = (index) => {
            this.hospitalsList.splice(index, 1);
        };
        /**
         * Validate the sharing
         */
        this.ok = () => {
            let request = {
                patient: this.patient,
                hospitals: this.hospitalsList,
                master: this.masterPatient,
            };
            this.PatientService.postPatientShareRequest(request).then((ans) => {
                this.ToastService.show(EMpStatus.Ok);
                this.ModalService.close({ masterPatient: ans });
            }).catch((err) => {
                this.ToastService.show(EMpStatus.RejectByServer);
                console.log(err);
                this.ModalService.close(null);
            });
        };
        /**
         * Close the modal without doing anything
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
    }
    $onInit() {
        this.triggerClean = false;
    }
    $onChanges(changes) {
        if (changes.patient) {
            this.patient = angular.copy(this.patient);
            this.hospitalsList = [];
            // console.log(this.patient);
        }
    }
}
