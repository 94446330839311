export const locale = {
    lang: 'en',
    data: {
        HOSPITAL_INFORMATION: {
            TITLE: 'My hospital - Information',
            CONTENT: {
                ADDRESS: {
                    TITLE: 'Address',
                    INFO: {
                        STREET_NBR: 'Street number',
                        STREET: 'Street',
                        ZIP_CODE: 'Postal code',
                        CITY: 'City',
                        COUNTRY: 'Country'
                    }
                },
                PHONE: {
                    TITLE: 'Phone',
                    INFO: {
                        SWITCHBOARD: 'Switchboard',
                        SECRETARY: 'Pharmacy phone secretary',
                        FAX: 'Fax',
                        PHARMA_DEPT_EMAIL: 'Pharmacy department email'
                    }
                },
                WEB: {
                    TITLE: 'Website',
                    INFO: {
                        ADDRESS: 'Link'
                    }
                },
                CAPACITY: {
                    TITLE: 'Capacity (beds)',
                    INFO: {
                        MCO: 'MCO',
                        EPHAD: 'EPHAD',
                        SSR: 'SSR',
                        PSY: 'PSY'
                    }
                }
            },
            SAVE: {
                SUCCESS: 'Hospital saved',
                FAILURE: 'Hospital save failure'
            }
        }
    }
};
