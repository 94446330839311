var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { HsPublicationDualViewComponent } from '@app/hospistock/shared/components/publication/dual-view/hs-publication-dual-view.component';
import { HsPublicationSingleViewDetailComponent } from '@app/hospistock/shared/components/publication/single-view/single-view-detail/hs-publication-single-view-detail.component';
import { HsPublicationSingleViewListComponent } from '@app/hospistock/shared/components/publication/single-view/single-view-list/hs-publication-single-view-list.component';
import { EHsToolbarViewModes } from '@app/hospistock/shared/types/hs-toolbar.types';
import { PermissionService } from '@app/services/permission/permission.service';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHospistockPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { hsPharmacyMedicalDeviceServiceConfig } from '../hs-pharmacy-medical-device.config';
import { hsPharmacyMedicalDevicePublicationConfig, hsPharmacyMedicalDevicePublicationServiceConfig, hsPharmacyMedicalDevicePublicationSingleListConfig } from './hs-pharmacy-medical-device-publication.config';
const states = [
    {
        name: 'mapui.hospistock.pharmacy.medical-device.publication',
        url: '',
        redirectTo: 'mapui.hospistock.pharmacy.medical-device.publication.dual'
    },
    {
        name: 'mapui.hospistock.pharmacy.medical-device.publication.list',
        url: '/list?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: HsPublicationSingleViewListComponent
            }
        },
        params: {
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-blue',
            access: EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem,
            viewMode: EHsToolbarViewModes.SINGLE_LIST,
            servicesConfig: {
                healthProduct: hsPharmacyMedicalDeviceServiceConfig,
                publicationContainer: hsPharmacyMedicalDevicePublicationServiceConfig
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsPharmacyMedicalDevicePublicationSingleListConfig
            }
        ]
    },
    {
        name: 'mapui.hospistock.pharmacy.medical-device.publication.detail',
        url: '/detail/:id',
        views: {
            'content@mapui': {
                component: HsPublicationSingleViewDetailComponent
            }
        },
        params: {
            id: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-blue',
            access: EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem,
            viewMode: EHsToolbarViewModes.SINGLE_DETAIL,
            servicesConfig: {
                healthProduct: hsPharmacyMedicalDeviceServiceConfig,
                publicationContainer: hsPharmacyMedicalDevicePublicationServiceConfig
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService],
                resolveFn: hsPharmacyMedicalDevicePublicationSingleListConfig
            }
        ]
    },
    {
        name: 'mapui.hospistock.pharmacy.medical-device.publication.dual',
        url: '/dual/:id?pagination?sorting?search?filters',
        views: {
            'content@mapui': {
                component: HsPublicationDualViewComponent
            }
        },
        // Documentation below
        params: {
            id: {
                dynamic: true,
                squash: true,
                value: null
            },
            pagination: {
                dynamic: true
            },
            sorting: {
                dynamic: true
            },
            search: {
                dynamic: true
            },
            filters: {
                dynamic: true
            }
        },
        data: {
            theme: 'theme-blue',
            access: [EHospistockPermission.PharmacyMedicalDevicePublication_SeeMenuItem],
            viewMode: EHsToolbarViewModes.DUAL,
            servicesConfig: {
                healthProduct: hsPharmacyMedicalDeviceServiceConfig,
                publicationContainer: hsPharmacyMedicalDevicePublicationServiceConfig
            }
        },
        resolve: [
            {
                token: 'config',
                deps: [TranslationService, FuseTranslationLoaderService, PermissionService],
                resolveFn: hsPharmacyMedicalDevicePublicationConfig
            }
        ]
    }
];
let HsPharmacyMedicalDevicePublicationRoutingModule = class HsPharmacyMedicalDevicePublicationRoutingModule {
};
HsPharmacyMedicalDevicePublicationRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], HsPharmacyMedicalDevicePublicationRoutingModule);
export { HsPharmacyMedicalDevicePublicationRoutingModule };
// -----------------------------------------------------------------------------------------------------
// @ Ng2StateDeclaration Doc
// -----------------------------------------------------------------------------------------------------
// Ng2StateDeclaration
// https://ui-router.github.io/ng2/docs/2.0.0/interfaces/state.ng2statedeclaration.html
// ParamDeclaration
// https://ui-router.github.io/ng2/docs/2.0.0/interfaces/params.paramdeclaration.html
// # squash: boolean
// Configures how a default parameter value is represented in the URL when the current parameter value is the same as the default value.
// There are three squash settings:
//     false: The parameter's default value is not squashed. It is encoded and included in the URL
//     true: The parameter's default value is omitted from the URL. If the parameter is preceeded and followed by slashes in the state's url declaration, then one of those slashes are omitted. This can allow for cleaner looking URLs.
//     "&lt;arbitrary string&gt;": The parameter's default value is replaced with an arbitrary placeholder of your choice.
// # dynamic: boolean
// When dynamic is true, changes to the parameter value will not cause the state to be entered/exited. The resolves will not be re-fetched, nor will views be reloaded.
// Normally, if a parameter value changes, the state which declared that the parameter will be reloaded (entered/exited). When a parameter is dynamic, a transition still occurs, but it does not cause the state to exit/enter.
// This can be useful to build UI where the component updates itself when the param values change. A common scenario where this is useful is searching/paging/sorting.
// # value: any
// Specifies the default value for this parameter. This implicitly sets this parameter as optional.
// When UI-Router routes to a state and no value is specified for this parameter in the URL or transition, the default value will be used instead. If value is a function, it will be injected and invoked, and the return value used.
