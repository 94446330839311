var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsModalMarketFormComponent } from '@app/hospistock/shared/components/modals/market/form/hs-modal-market-form.component';
import { EHsHealthProductDirectoryTypes } from '@app/hospistock/shared/types/hs-health-product-directory.types';
import { CellProcesses, CellValidators } from '@app/services/spreadsheet-parser/spreadsheet-parser.const';
import { Utils } from '@main/services/Utils';
import { EHospistockPermission, EHsGroupDrugMarketImportResultMessages, EHsGroupDrugPublicationsImport, HsMarketItemDrugASchema } from '@mapuilabs/mpl-interfaces';
// -----------------------------------------------------------------------------------------------------
// @ HealtProductReferences Global Config
// -----------------------------------------------------------------------------------------------------
const translationBase = 'PUBLICATION.MARKET';
// -----------------------------------------------------------------------------------------------------
// @ HealtProductReferences Service Config
// -----------------------------------------------------------------------------------------------------
const endPoint = 'api/hospistock/purchase-group/drug/market';
const permissions = {
    create: EHospistockPermission.PurchaseGroupDrugMarket_Create,
    read: EHospistockPermission.PurchaseGroupDrugMarket_Read,
    update: EHospistockPermission.PurchaseGroupDrugMarket_Edit,
    delete: EHospistockPermission.PurchaseGroupDrugMarket_Delete
};
const handleRefArray = (value) => {
    if (!value) {
        // optional array
        return value;
    }
    // Guard: must be an array
    if (!Array.isArray(value)) {
        throw new Error('Cannot handle ref, value must be an array. Value:' + value);
    }
    const buildRef = (value) => {
        if (typeof value !== 'string') {
            throw new Error('Cannot handle ref, value must be of type string. Value:' + value);
        }
        return { ref: value };
    };
    return value.map((item) => buildRef(item));
};
const spreadsheetClassBuilder = [
    {
        column: EHsGroupDrugPublicationsImport.UCD,
        path: ['healthProductRef', 'ref']
    },
    {
        column: EHsGroupDrugPublicationsImport.CIP,
        path: ['healthProductRef', 'healthProducts'],
        transform: handleRefArray
    },
    {
        column: EHsGroupDrugPublicationsImport.DRUG_NAME,
        path: ['healthProductRef', 'name']
    },
    {
        column: EHsGroupDrugPublicationsImport.BATCH_NUMBER,
        path: ['lot']
    },
    {
        column: EHsGroupDrugPublicationsImport.MARKET_NUMBER,
        path: ['marketNum']
    },
    {
        column: EHsGroupDrugPublicationsImport.MARKET_SUBJECT,
        path: ['marketSubject']
    },
    {
        column: EHsGroupDrugPublicationsImport.CAMPAIGN,
        path: ['campaign']
    },
    {
        column: EHsGroupDrugPublicationsImport.ABBREVIATION,
        path: ['abbreviation']
    }
];
export const hsPurchaseGroupDrugMarketServiceConfig = {
    classConstructor: HsMarketItemDrugASchema,
    endPoint,
    permissions,
    readLatestImportDate: true,
    spreadsheetClassBuilder
};
// -----------------------------------------------------------------------------------------------------
// @ HealtProductDirectory Config
// -----------------------------------------------------------------------------------------------------
// Import Config
const spreadsheetParserConfig = {
    columns: [
        {
            label: EHsGroupDrugPublicationsImport.UCD,
            processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.UCD13()],
            validators: [CellValidators.required(), CellValidators.ucd()]
        },
        {
            label: EHsGroupDrugPublicationsImport.CIP,
            processes: [CellProcesses.string(), CellProcesses.clear(), CellProcesses.split(';'), CellProcesses.CIP13()],
            validators: [CellValidators.array(';', CellValidators.cip())]
        },
        {
            label: EHsGroupDrugPublicationsImport.DRUG_NAME,
            processes: [CellProcesses.trim()],
            validators: []
        },
        {
            label: EHsGroupDrugPublicationsImport.BATCH_NUMBER,
            processes: [CellProcesses.clear()],
            validators: []
        },
        {
            label: EHsGroupDrugPublicationsImport.MARKET_NUMBER,
            processes: [CellProcesses.clear()],
            validators: []
        },
        {
            label: EHsGroupDrugPublicationsImport.MARKET_SUBJECT,
            processes: [CellProcesses.trim()],
            validators: []
        },
        {
            label: EHsGroupDrugPublicationsImport.CAMPAIGN,
            processes: [CellProcesses.clear()],
            validators: []
        },
        {
            label: EHsGroupDrugPublicationsImport.ABBREVIATION,
            processes: [CellProcesses.trim()],
            validators: []
        }
    ],
    options: {
        headerToLowerCase: true,
        abortOnMajorError: true
    }
};
const spreadsheetImportResultMessagesMap = new Map(Utils.enumToKeys(EHsGroupDrugMarketImportResultMessages).map((key) => [
    key,
    translationBase + '.IMPORT.REPORT.MESSAGES.' + key
]));
const importConfig = {
    spreadsheetImportResultMessagesMap,
    spreadsheetParserConfig
};
// Page Header Config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant(translationBase + '.TITLES.MAIN'),
    searchBarPlaceholder: translationService.instant(translationBase + '.SEARCH'),
    icon: 'import_contacts'
});
// Table Config
const tableConfig = (translationService) => {
    const concatRef = (healthProducts) => { var _a; return (_a = healthProducts === null || healthProducts === void 0 ? void 0 : healthProducts.map(({ ref }) => ref)) === null || _a === void 0 ? void 0 : _a.join(' ; '); };
    return {
        columns: [
            {
                label: translationService.instant(translationBase + '.TABLE.DRUG_NAME'),
                attribute: 'healthProductRef.name',
                sort: true,
                classes: ['min-w-192']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.UCD13'),
                attribute: 'healthProductRef.ref',
                sort: true,
                classes: ['min-w-80 max-w-10-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.CIP13'),
                attribute: 'healthProductRef.healthProducts',
                transform: concatRef,
                sort: true,
                classes: ['min-w-128 max-w-10-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.LOT'),
                attribute: 'lot',
                sort: true,
                classes: ['min-w-80 max-w-5-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.MARKETNUM'),
                attribute: 'marketNum',
                sort: true,
                classes: ['min-w-96 max-w-5-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.MARKETSUBJECT'),
                attribute: 'marketSubject',
                sort: true,
                classes: ['min-w-148 max-w-10-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.CAMPAIGN'),
                attribute: 'campaign',
                sort: true,
                classes: ['min-w-96 max-w-5-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.ABBREVIATION'),
                attribute: 'abbreviation',
                sort: true,
                classes: ['min-w-96 max-w-5-p']
            },
            {
                label: translationService.instant(translationBase + '.TABLE.ACTIONS'),
                template: 'actionsTemplate',
                classes: ['w-96'],
                sort: false
            }
        ]
    };
};
export const hsPurchaseGroupDrugMarketConfig = (translationService, fuseTranslationLoaderService) => __awaiter(void 0, void 0, void 0, function* () {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        healthProductDirectoryType: EHsHealthProductDirectoryTypes.MARKET,
        import: importConfig,
        pageHeader: pageHeaderConfig(translationService),
        table: tableConfig(translationService),
        translationBase,
        modal: HsModalMarketFormComponent
    };
});
