import { ValidationController } from './validation.controller';
import * as angular from 'angular';
angular
    .module('mapui.services.auth.validation', [])
    .config(config)
    .controller('ValidationController', ValidationController);
/** @ngInject */
function config($stateProvider) {
    // State
    $stateProvider.state('mapui.pages_auth_validation', {
        url: '/auth/forgot-password',
        views: {
            'main@': {
                template: require('../../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.pages_auth_validation-password': {
                template: require('./validation.html').default,
                controller: 'ForgotPasswordController as vm',
            },
        },
        bodyClass: 'forgot-password',
    });
}
