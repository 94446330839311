export const locale = {
    lang: 'en',
    data: {
        externalConfigDetail: {
            TITLE_NEW: 'New configuration',
            TITLE_EDIT: 'Configuration - {{id}}',
            INFORMATION_TAB: {
                TITLE: 'Information',
                URL: 'URL',
                USERNAME: 'Username',
                PASSWORD: 'Password'
            },
            KEYS_TAB: {
                TITLE: 'Keys',
                PUBLIC: 'Public key',
                PRIVATE: 'Private key'
            },
            HOSPITALS_TAB: {
                TITLE: 'Hospitals',
                ESTABLISHMENT: 'Establishment'
            }
        }
    }
};
