import { EMpStatus, EPathways } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
/** @ngInject */
export class HvModalMasterPatientController {
    /** @ngInject */
    constructor(ModalService, HospivilleService, Auth, Api) {
        this.ModalService = ModalService;
        this.HospivilleService = HospivilleService;
        this.Auth = Auth;
        this.Api = Api;
        /**
         * Used my the mp-search-town
         * @param town
         */
        this.selectTown = ({ town }) => {
            this.patient.birthPlaceData = town;
            this.patient.birthPlace = town ? town.nom_de_la_commune : null;
        };
        this.townTextChange = ({ text }) => {
            if (!text) {
                this.patient.birthPlaceData = null;
                this.patient.birthDate = null;
            }
        };
        this.selectCountry = ({ country }) => {
            this.patient.birthCountryData = country;
        };
        /**
         * Used to clear data when switching birth method
         */
        this.switchBirthMethod = () => {
            if (this.bornAbroad) {
                this.patient.birthPlaceData = null;
                this.patient.birthPlace = null;
            }
            else {
                this.patient.birthCountryData = null;
            }
        };
        /**
         * Close modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close modal with actions performing
         */
        this.ok = () => {
            // this.PatientService.setMasterPatient(this.patient)
            if (this.unknownBirthPlace) {
                this.patient.birthPlace = null;
                this.patient.birthPlaceData = null;
                this.patient.birthCountryData = null;
            }
            if (this.Auth.isOnlyOctave) {
                this.patient.pathways = [EPathways.Octave];
            }
            this.ModalService.close(this.patient);
        };
        this.unknownBirthPlace = false;
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.genderType = ans.gender;
        });
    }
    $onChanges(changes) {
        if (changes.patient) {
            this.patient = angular.copy(this.patient);
            if (this.patient && this.patient.birthCountryData)
                this.bornAbroad = true;
        }
    }
    get canAddOctavePathway() {
        return this.Auth.isBothOctaveAndPremium;
    }
    get emptyPatient() {
        return !this.HospivilleService.hasPatient;
    }
}
