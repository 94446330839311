export class HvMssMailValidatorDirective {
    /** @ngInject */
    constructor() {
        this.link = (scope, elem, attr, ctrl) => {
            const ngModel = ctrl[0];
            ngModel.$validators.mailDomain = function (value) {
                if (value) {
                    value = value.split('@');
                    if (value[1]) {
                        return !value[1].includes('mssante.fr');
                    }
                }
                return true;
            };
        };
        this.require = ['ngModel'];
    }
    static factory() {
        const directive = () => new HvMssMailValidatorDirective();
        directive.$inject = [];
        return directive;
    }
}
