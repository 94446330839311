var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { StateService } from '@uirouter/angular';
import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
let UrlService = class UrlService {
    constructor(_stateService, _uIRouter) {
        this._stateService = _stateService;
        this._uIRouter = _uIRouter;
        /**
         * Add given params to the current state and redirect to it
         * @param params
         * @param replaceHistory
         */
        this.addParams = (params, replaceHistory = false) => {
            const toAdd = {};
            for (const param in params) {
                if (params.hasOwnProperty(param)) {
                    toAdd[param] = JSON.stringify(params[param]);
                }
            }
            this._stateService.go(this._uIRouter.globals.current, toAdd, { location: replaceHistory ? 'replace' : true });
        };
        /**
         * Get the param associate to the given name in the current state
         * @param name
         */
        this.getParam = (name) => {
            if (this._stateService.params[name]) {
                try {
                    return JSON.parse(this._stateService.params[name]);
                }
                catch (e) {
                    return this._stateService.params[name];
                }
            }
            return null;
        };
    }
};
UrlService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StateService !== "undefined" && StateService) === "function" ? _a : Object, typeof (_b = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _b : Object])
], UrlService);
export { UrlService };
