var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EFilterType } from '@mapuilabs/mpl-interfaces';
import { RolesService } from '@services/role/roles.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let ListHospitalsSidebarComponent = class ListHospitalsSidebarComponent {
    constructor(_rolesService, _snackBarService, _fuseTranslationLoaderService) {
        this._rolesService = _rolesService;
        this._snackBarService = _snackBarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.form = new FormGroup({
            roleConfig: new FormControl('$all'),
            roleMapui: new FormControl([]),
            roleHospiville: new FormControl([]),
            roleHospistock: new FormControl([]),
            roleWorkspace: new FormControl([]),
            active: new FormControl(),
            register: new FormControl()
        });
        // Save form value records
        this._formRecordDefault = this.form.value;
        this.hospistockRoles = [];
        /**
         * Sends the filters to the list view so that it can update.
         */
        this.onApplyFilters = (replaceHistory = false) => {
            const filters = [];
            const filterRoleValues = [
                ...this.form.controls['roleMapui'].value,
                ...this.form.controls['roleHospiville'].value,
                ...this.form.controls['roleHospistock'].value,
                ...this.form.controls['roleWorkspace'].value
            ];
            if (filterRoleValues.length > 0) {
                filters.push({
                    type: EFilterType.VALUES,
                    attribute: 'roles',
                    inArray: true,
                    values: filterRoleValues
                });
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'roleConfig',
                    value: this.form.controls['roleConfig'].value
                });
            }
            if (this.form.controls['active'].value === true) {
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'active',
                    value: true
                });
            }
            if (this.form.controls['register'].value === true) {
                filters.push({
                    type: EFilterType.VALUE,
                    attribute: 'register',
                    value: true
                });
            }
            this.filtersApplied.emit({ filters, replaceHistory });
        };
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.filtersApplied = new EventEmitter();
        this._rolesService.getAllHospital().subscribe((data) => {
            for (const project of data) {
                switch (project.name) {
                    case 'mapui':
                        this.mapuiRoles = project.roles;
                        break;
                    case 'hospiville':
                        this.hospivilleRoles = project.roles;
                        break;
                    case 'hospistock':
                        this.hospistockRoles = project.roles;
                        break;
                    case 'workspace':
                        this.workspaceRoles = project.roles;
                }
            }
            this._setFormControls();
        }, (error) => this._snackBarService.openError(error));
    }
    ngOnChanges(changes) {
        if ('applyFilters' in changes) {
            this.applyFilters = this.applyFilters || [];
            this._setFormControls();
        }
    }
    resetFilters() {
        this.form.reset(this._formRecordDefault);
        this.filtersApplied.emit({
            filters: [],
            replaceHistory: true
        });
    }
    _setFormControls() {
        if (this.mapuiRoles && this.hospivilleRoles && this.hospistockRoles && this.workspaceRoles) {
            const filterRole = this.applyFilters.find((filter) => filter.attribute === 'roles');
            const filterRoleConfig = this.applyFilters.find((filter) => filter.attribute === 'roleConfig');
            if (filterRole) {
                const mapuiFormControl = this.mapuiRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const hospivilleFormControl = this.hospivilleRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const hospistockFormControl = this.hospistockRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                const workspaceFormControl = this.workspaceRoles
                    .filter((role) => filterRole.values.includes(role._id))
                    .map((tmp) => tmp._id);
                this.form.setControl('roleConfig', new FormControl(filterRoleConfig.value));
                this.form.setControl('roleMapui', new FormControl(mapuiFormControl));
                this.form.setControl('roleHospiville', new FormControl(hospivilleFormControl));
                this.form.setControl('roleHospistock', new FormControl(hospistockFormControl));
                this.form.setControl('roleWorkspace', new FormControl(workspaceFormControl));
            }
            const active = this.applyFilters.find((filter) => filter.attribute === 'active');
            const register = this.applyFilters.find((filter) => filter.attribute === 'register');
            this.form.setControl('active', new FormControl((active === null || active === void 0 ? void 0 : active.value) === true ? true : false));
            this.form.setControl('register', new FormControl((register === null || register === void 0 ? void 0 : register.value) === true ? true : false));
        }
    }
};
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], ListHospitalsSidebarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_e = typeof Array !== "undefined" && Array) === "function" ? _e : Object)
], ListHospitalsSidebarComponent.prototype, "applyFilters", void 0);
ListHospitalsSidebarComponent = __decorate([
    Component({
        selector: 'mpx-hospitals-sidebar',
        template: require('./list-hospitals-sidebar.component.html').default,
        styles: [require('./list-hospitals-sidebar.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof RolesService !== "undefined" && RolesService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object, typeof (_c = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _c : Object])
], ListHospitalsSidebarComponent);
export { ListHospitalsSidebarComponent };
