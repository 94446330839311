import * as XLSX from 'xlsx';
import * as _ from "underscore";
export class SUtils {
}
/**
 * Convert an ArrayBuffer to a binary string
 * @param buffer
 * @return {string}
 * @constructor
 */
SUtils.ArrayBufferToBinaryString = (buffer) => {
    const bytes = new Uint8Array(buffer);
    const length = bytes.byteLength;
    let binary = '';
    for (let i = 0; i < length; ++i) {
        binary += String.fromCharCode(bytes[i]);
    }
    return binary;
};
export class SpreadSheetCreator {
    /** @ngInject */
    constructor(data) {
        /* generate a worksheet */
        this._ws = XLSX.utils.json_to_sheet(data);
        /* add to workbook */
        this._wb = XLSX.utils.book_new();
    }
    /**
     * Create and download the spreadshit file
     * @param fileName
     * @param sheetName
     */
    create(fileName, sheetName) {
        XLSX.utils.book_append_sheet(this._wb, this._ws, sheetName);
        /* write workbook and force a download */
        XLSX.writeFile(this._wb, fileName);
    }
}
export class SpreadSheet {
    /**
     * Load the given file as a workbook
     * @param {IWorkBook} workbook The file to parse
     * @param {number} shift If non-null the headers index in the file
     */
    /** @ngInject */
    constructor(file, shift = null, rawData = false) {
        /**
         * Calculate the max numbers of rows and columns
         * @private
         */
        this._calcLimits = () => {
            this._nbRows = this._activeWorksheet.length;
            this._activeWorksheet.forEach((column) => {
                if (column.length > this._nbColumns) {
                    this._nbColumns = column.length;
                }
            });
        };
        /**
         * Add a function to each row to retrieve the data at the designated data name
         * function signature :
         *  ```
         *    getValueFor(name: string): string
         *  ```
         * @private
         */
        this._extendRows = () => {
            for (let r = 0; r < this._activeWorksheet.length; ++r) {
                this._activeWorksheet[r]['getValueFor'] = (name) => {
                    return this._activeWorksheet[r][this.matches[name]];
                };
            }
        };
        /**
         * Enable the use of headers
         * @param {number} shift The headers index in the file
         */
        this.enableHeaders = (shift = 1) => {
            this._rowShift = shift;
        };
        /**
         * Disable the use of headers
         */
        this.disabledHeaders = () => {
            this._rowShift = 0;
        };
        /**
         * Iterate on each row and call the given callback
         * @param {(row: Array<string>) => void} fn The callback to call
         */
        this.forEachRow = (fn) => {
            for (let idx = 0 + this._rowShift; idx < this._activeWorksheet.length; ++idx) {
                fn(this._activeWorksheet[idx]);
            }
        };
        /**
         * Group the rows by the key passed as argument
         * @param {string} key
         * @return {_.Dictionary<Array<ISpreadSheetRow>>}
         */
        this.rowsGroupedBy = (key) => {
            return _.groupBy(this._activeWorksheet.slice(this._rowShift), (row) => {
                return row.getValueFor(key);
            });
        };
        const reader = new FileReader();
        reader.onload = (event) => {
            // If file it too big the event is not digest => Ensure that the change appears in a digest loop
            file.scope.$evalAsync(() => {
                this._workbook = XLSX.read(SUtils.ArrayBufferToBinaryString(event.target.result), { type: 'binary', raw: rawData });
            });
        };
        reader.readAsArrayBuffer(file.blob);
        this._rowShift = shift;
        this._nbColumns = 0;
        this._nbRows = 0;
        this.matches = {};
    }
    /**
     * Return the max number of rows
     * @return {number}
     */
    get rows() {
        return this._nbRows;
    }
    /**
     * Return the max number of columns
     * @return {number}
     */
    get columns() {
        return this._nbColumns;
    }
    /**
     * Change the active worksheet
     * @param {string} ws The worksheet name
     */
    set worksheet(ws) {
        if (this._workbook && this._workbook.Sheets[ws]) {
            this._worksheetName = ws;
            this._activeWorksheet = XLSX.utils.sheet_to_json(this._workbook.Sheets[ws], { header: 1, raw: true });
            this._calcLimits();
            this._extendRows();
        }
    }
    /**
     * the current worksheet name
     * @return {string}
     */
    get worksheet() {
        return this._worksheetName;
    }
    /**
     * Return the headers names
     * @return {Array<string>}
     */
    get headerNames() {
        return this._rowShift === null && this._rowShift > 0 ? [] : this._activeWorksheet[this._rowShift - 1];
    }
    /**
     * Return the list of sheetnames
     * @return {Array<string>}
     */
    get sheetNames() {
        return this._workbook.SheetNames;
    }
    /**
     * Toogle the use of the first row as headers
     * @param {boolean} value
     */
    set firstRowAsHeader(value) {
        value ? this.enableHeaders() : this.disabledHeaders();
    }
    /**
     * Return whether the headers are used
     * @return {boolean}
     */
    get firstRowAsHeader() {
        return !!this._rowShift;
    }
    /**
     * Indicated whether an active worksheet has been set and thus the Spreadsheet can be properly used
     * @return {boolean}
     */
    get isReady() {
        return !!this._workbook && !!this._activeWorksheet;
    }
    /**
     * Indicated whether the file has been processed and a proper workbook has been created
     * @return {boolean}
     */
    get initialized() {
        return !!this._workbook;
    }
}
