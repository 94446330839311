var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '../i18n/fr';
import { locale as english } from '../i18n/en';
import { locale as globalFrench } from '../../../i18n/fr';
import { locale as globalEnglish } from '../../../i18n/en';
import { IdentityService } from '@services/identity/identity.service';
import { AvailableRequestService } from '../available-request/available-request.service';
import { StateService, Transition } from '@uirouter/angular';
import { AvailableSummaryService } from './available-summary.service';
import { Utils } from '@main/services/Utils';
import { AvailableHospitalService } from '../available-hospital/available-hospital.service';
import { TranslationService } from '@app/services/translation/translation.service';
let AvailableSummaryComponent = class AvailableSummaryComponent {
    constructor(_fuseTranslationLoaderService, _identityService, _availableRequestService, _availableHospitalService, _availableSummaryService, _transition, _translationService, _stateService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._identityService = _identityService;
        this._availableRequestService = _availableRequestService;
        this._availableHospitalService = _availableHospitalService;
        this._availableSummaryService = _availableSummaryService;
        this._transition = _transition;
        this._translationService = _translationService;
        this._stateService = _stateService;
        this.availableAndOffers = null;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        this.pageHeaderConfig = {
            title: this._translate('AVAILABLE.TITLE'),
            icon: 'shopping_cart'
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        this._fuseTranslationLoaderService.loadTranslations(french, english, globalFrench, globalEnglish);
    }
    ngAfterViewInit() {
        this._availableSummaryService.getUniqueAvailableOffer(this._transition.params().id).subscribe((isComplete) => {
            if (isComplete) {
                this.availableAndOffers = this._availableSummaryService.currentAvailable;
                if (!this.availableFromHospital()) {
                    this._availableRequestService.setCurrentAvailable(this.availableAndOffers);
                }
                else {
                    this._availableHospitalService.setCurrentAvailable(this.availableAndOffers);
                }
            }
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    availableFromHospital() {
        var _a;
        return Utils.compareIds((_a = this.availableAndOffers) === null || _a === void 0 ? void 0 : _a.available.hospital, this._identityService.hospital);
    }
    goToAvailable() {
        if (this.availableFromHospital()) {
            this._stateService.go('mapui.exchangeV2.available.available-hospital.available', {
                id: this.availableAndOffers.available._id
            });
        }
        else {
            this._stateService.go('mapui.exchangeV2.available.available-request.available', {
                id: this.availableAndOffers.available._id
            });
        }
    }
};
AvailableSummaryComponent = __decorate([
    Component({
        selector: 'mpx-available',
        template: require('./available-summary.component.html').default,
        styles: [require('./available-summary.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _b : Object, typeof (_c = typeof AvailableRequestService !== "undefined" && AvailableRequestService) === "function" ? _c : Object, typeof (_d = typeof AvailableHospitalService !== "undefined" && AvailableHospitalService) === "function" ? _d : Object, typeof (_e = typeof AvailableSummaryService !== "undefined" && AvailableSummaryService) === "function" ? _e : Object, typeof (_f = typeof Transition !== "undefined" && Transition) === "function" ? _f : Object, typeof (_g = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _g : Object, typeof (_h = typeof StateService !== "undefined" && StateService) === "function" ? _h : Object])
], AvailableSummaryComponent);
export { AvailableSummaryComponent };
