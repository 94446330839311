export const locale = {
    lang: 'fr',
    data: {
        REQUESTS_LIST: {
            TITLE: `Demandes de produits`,
            INFO_COVID: `Ceci est un outil collaboratif entre établissements de santé.`,
            TABLE: {
                EMPTY: `Pas de demande pour le moment.`,
                MEDIC_NAME: `Nom du médicament`,
                HOSPITAL_NAME: `Hôpital`,
                CREATION_DATE: `Date`,
                TYPE: `Type`,
                STATUS: `Statut`,
                QUANTITY: `Quantité`
            },
            TOOLTIPS: {
                PREPARATION: 'Préparation',
                PILL: 'Médicament',
                MEDICAL_DEVICE: 'Dispositif médical'
            },
            SNACKBAR: {
                EDIT_OK: `La demande a été mise à jour.`
            }
        },
        SERVICES: {
            STATUS: {
                TYPES: {
                    LOAN: `Prêt`,
                    AVAILABLE: `Mise à disposition`,
                    BORROW: `Emprunt`
                },
                VOUCHERS: {
                    RETURN: `Bon de retour`,
                    LOAN: `Bon de prêt`,
                    BORROW: `Bon d'emprunt`
                },
                FORCED: {
                    FORCED: `le {{date}} par {{name}}`,
                    CONFIRM: `Confirmation forcée `,
                    RETURN: `Retour forcé le {{date}} par {{name}}`,
                    RELEASE: `Libération forcée `
                },
                WAITING: {
                    AVAILABLE: `En attente de reprise`,
                    ACCEPT: `En attente d'acceptation`,
                    CONFIRM: `En attente de confirmation`,
                    PUBLISHED: `Demande publiée`,
                    LIBERATE: `En attente de libération`,
                    BILL: `En attente d'avis de paiement`,
                    RETURN: `En attente de retour`,
                    RECEIVE: `En attente de réception`,
                    SETTLE: `En attente de liquidation`,
                    VALID_RETURN: `En attente de vérification`,
                    QUARANTINE: `En quarantaine de stérilité`,
                    MANUFACTURING: `En fabrication`
                },
                OK: `Ok`,
                TO: {
                    ACCEPT: `À accepter`,
                    RELEASE: `À libérer`,
                    CONFIRM: `À confirmer`,
                    LIBERATE: `À libérer`,
                    RETURN: `À retourner`,
                    BILL: `À facturer`,
                    SETTLE: `À liquider`,
                    RECEIVE: `À réceptionner`,
                    VALID_RETURN: `À vérifier`
                },
                DID: {
                    ACCEPTED: `Accepté le {{date}}`,
                    RELEASED: `Libéré le {{date}} par {{name}}`,
                    CONFIRMED: `Confirmé le {{date}} par {{name}}`,
                    YOU_RELEASED: `Vous avez libéré le {{date}}`,
                    YOU_CONFIRMED: `Vous avez confirmé le {{date}}`,
                    RETURNED: `Retourné le {{date}}`,
                    PAYED_OFF: `Facture liquidée le {{date}}`,
                    BILLED: `Facturé le {{date}}`
                },
                AUTO: `automatiquement`
            }
        }
    }
};
