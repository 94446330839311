export const CMedicineDepartments = [
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ADDICTOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ALGOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ALCOOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ALLERGOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PAC',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ANDROLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ANGIOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.BIOCHEMISTRY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.SURGERY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.DIGESTIVE_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.FACIAL_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PAEDIATRIC_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ORTHO_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PLASTIC_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.CARDIO_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.UROLOGICAL_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.VASCULAR_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.VISCERAL_SURG',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.CARDIOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.CORONARY_ANGIOGRAPHY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.DERMATOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ENDOCRINOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.GYNECOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.GERIATRIC',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.GERIATRIC_SHORT_STAY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.GENETIC',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.HAEMATOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.GASTROENTEROLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.INFECTIOUS_DISEASE',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.INTERNAL_MEDICINE',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.NUCLEAR_MEDICINE',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.NEPHROLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.NEO_NATOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.NEUROSURGERY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.NEUROLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.NEUROPSYCHOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PEDIATRIC_NEUROLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PEDIATRICS',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PNEUMOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PSYCHIATRIC',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.OPHTHALMOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ODONTOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ONCOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.OTOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.ENT_STOMATOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.RADIOLOGY_IMAGERY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.RADIOTHERAPY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.INTENSIVE_CARE',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.RHEUMATHOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.PALLIATIVE_CARE',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.STOMATOLOGY',
    'MAPUI.GENERAL.CONST_TYPE.MEDICINE_DEPARTMENT.EMERGENCY'
];
