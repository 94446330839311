export class mpVerticalStepperDirective {
    /** @ngInject */
    constructor($timeout) {
        this.$timeout = $timeout;
        this.compile = (tElement) => {
            tElement.addClass('mp-stepper');
            return function postLink(scope, iElement, iAttrs, ctrls) {
                const FormCtrl = ctrls[0], MsStepperCtrl = ctrls[1];
                this.$timeout(function () {
                    MsStepperCtrl.setOrientation('vertical');
                    MsStepperCtrl.registerMainForm(FormCtrl);
                    MsStepperCtrl.setupSteps();
                });
            };
        };
        this.restrict = 'A';
        this.scope = {};
        this.require = ['form', 'mpVerticalStepper'];
        this.priority = 1001;
        this.controller = 'MpStepperController as vm';
        this.bindToController = {
            model: '=ngModel',
            show: '<',
            submit: '&',
        };
        this.transclude = true;
        this.template = require('./templates/vertical.html').default;
    }
    static factory() {
        const directive = ($timeout) => new mpVerticalStepperDirective($timeout);
        directive.$inject = ['$timeout'];
        return directive;
    }
}
