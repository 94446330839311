var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule } from '@angular/core';
import { HsDashboardComponent } from '@app/hospistock/shared/components/dashboard/hs-dashboard.component';
import { TranslationService } from '@app/services/translation/translation.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHospistockPermission } from '@mapuilabs/mpl-interfaces';
import { UIRouterModule } from '@uirouter/angular';
import { hsPurchaseGroupDrugServiceConfig } from '../hs-purchase-group-drug.config';
import { hsPurchaseGroupDrugDashboardLayout, hsPurchaseGroupDrugDashboardServiceConfig } from './hs-purchase-group-drug-dashboard.config';
const states = [
    {
        name: 'mapui.hospistock.purchase-group.drug.dashboard',
        url: '/dashboard',
        views: {
            'content@mapui': {
                component: HsDashboardComponent
            }
        },
        data: {
            theme: 'theme-green',
            access: [EHospistockPermission.PurchaseGroupDrugDashboard_SeeMenuItem],
            servicesConfig: {
                healthProduct: hsPurchaseGroupDrugServiceConfig,
                dashboard: hsPurchaseGroupDrugDashboardServiceConfig
            }
        },
        resolve: [
            {
                token: 'layout',
                resolveFn: hsPurchaseGroupDrugDashboardLayout,
                deps: [TranslationService, FuseTranslationLoaderService]
            }
        ]
    }
];
let HsPurchaseGroupDrugDashboardRoutingModule = class HsPurchaseGroupDrugDashboardRoutingModule {
};
HsPurchaseGroupDrugDashboardRoutingModule = __decorate([
    NgModule({
        imports: [UIRouterModule.forChild({ states: states })]
    })
], HsPurchaseGroupDrugDashboardRoutingModule);
export { HsPurchaseGroupDrugDashboardRoutingModule };
