import { EPosologyType } from '@mapuilabs/mpl-interfaces';
export const CPosologyType = [
    {
        value: EPosologyType.Morning,
        translate: 'POSOLOGY_TYPE.MORNING',
    },
    {
        value: EPosologyType.Noon,
        translate: 'POSOLOGY_TYPE.NOON',
    },
    {
        value: EPosologyType.SNACK,
        translate: 'POSOLOGY_TYPE.SNACK',
    },
    {
        value: EPosologyType.Evening,
        translate: 'POSOLOGY_TYPE.EVENING',
    },
    {
        value: EPosologyType.BEDTIME,
        translate: 'POSOLOGY_TYPE.BEDTIME',
    },
    {
        value: EPosologyType.M_N_E,
        translate: 'POSOLOGY_TYPE.M_N_E',
    },
    {
        value: EPosologyType.M_N,
        translate: 'POSOLOGY_TYPE.M_N',
    },
    {
        value: EPosologyType.M_E,
        translate: 'POSOLOGY_TYPE.M_E',
    },
    {
        value: EPosologyType.N_E,
        translate: 'POSOLOGY_TYPE.N_E',
    },
    {
        value: EPosologyType.DAY,
        translate: 'POSOLOGY_TYPE.DAY',
    },
    {
        value: EPosologyType.M_N_E_B,
        translate: 'POSOLOGY_TYPE.M_N_E_B',
    },
    {
        value: EPosologyType.EVERY_4_H,
        translate: 'POSOLOGY_TYPE.EVERY_4H',
    },
    {
        value: EPosologyType.EVERY_6_H,
        translate: 'POSOLOGY_TYPE.EVERY_6H',
    },
    {
        value: EPosologyType.EVERY_8_H,
        translate: 'POSOLOGY_TYPE.EVERY_8H',
    },
    {
        value: EPosologyType.Other,
        translate: 'POSOLOGY_TYPE.OTHER',
    },
];
