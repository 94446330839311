var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Component } from '@angular/core';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { WidgetPublicationService } from '@services/homepage/widgets/stockout/widget-stockout.service';
import { locale as english } from './i18n/en';
import { locale as french } from './i18n/fr';
let HsStockoutWidgetComponent = class HsStockoutWidgetComponent {
    constructor(_fuseTranslationLoaderService, _stockoutWidgetService) {
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._stockoutWidgetService = _stockoutWidgetService;
        this._fuseTranslationLoaderService.loadTranslations(english, french);
    }
    ngOnInit() {
        this.getStockout();
    }
    getStockout() {
        this._stockoutWidgetService.getPage({ limit: 10, page: 1 }).subscribe((res) => {
            this.publications = res.items.map((item) => item.stockout);
        }, (error) => {
            if (error.status == 403) {
                this.forbiddenAccess = true;
            }
        });
    }
    getType(value) {
        return Object.values(CHsPublicationType).find((type) => type.value === value);
    }
};
HsStockoutWidgetComponent = __decorate([
    Component({
        selector: 'mpx-hs-stockout-widget',
        template: require('./stockout-widget.component.html').default,
        styles: [require('./stockout-widget.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof WidgetPublicationService !== "undefined" && WidgetPublicationService) === "function" ? _b : Object])
], HsStockoutWidgetComponent);
export { HsStockoutWidgetComponent };
