var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CladimedService } from '@services/cladimed/cladimed.service';
import { locale as english } from '@shared/components/search-medic-clean-dci/i18n/en';
import { locale as french } from '@shared/components/search-medic-clean-dci/i18n/fr';
let SearchCladimedComponent = class SearchCladimedComponent {
    constructor(_cladimedService, _fuseTranslationLoaderService) {
        this._cladimedService = _cladimedService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init EventEmitters
        this.inputChanged = new EventEmitter();
        this.inputReset = new EventEmitter();
        this.optionSelected = new EventEmitter();
        // Search Cladimed config
        this.searchAutocompleteConfig = {
            displayFn: (cladimed) => {
                if (!(cladimed === null || cladimed === void 0 ? void 0 : cladimed.name) || !(cladimed === null || cladimed === void 0 ? void 0 : cladimed.ref)) {
                    return null;
                }
                const option = cladimed.name + ' - ' + cladimed.ref;
                return cladimed.ref.length === 7 ? '•  ' + option : option;
            },
            autocompleteSearchFn: (searchValue) => this._cladimedService.findByName(searchValue),
            minimumLength: 3
        };
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    onOptionSelected(selectedCladimed) {
        this.optionSelected.emit(selectedCladimed);
    }
    onInputChanged(cladimedInput) {
        this.inputChanged.emit(cladimedInput);
    }
    onInputReset() {
        this.inputReset.emit();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchCladimedComponent.prototype, "disabled", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof Pick !== "undefined" && Pick) === "function" ? _c : Object)
], SearchCladimedComponent.prototype, "searchValue", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], SearchCladimedComponent.prototype, "required", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchCladimedComponent.prototype, "inputChanged", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_e = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _e : Object)
], SearchCladimedComponent.prototype, "inputReset", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], SearchCladimedComponent.prototype, "optionSelected", void 0);
SearchCladimedComponent = __decorate([
    Component({
        selector: 'search-cladimed',
        template: require('./search-cladimed.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof CladimedService !== "undefined" && CladimedService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchCladimedComponent);
export { SearchCladimedComponent };
