import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
export class MpDirectoryRightsController {
    /** @ngInject */
    constructor($stateParams, DirectoryRightsService, FilesService, Api, ToastService, $q, $state, $translate, Access) {
        this.$stateParams = $stateParams;
        this.DirectoryRightsService = DirectoryRightsService;
        this.FilesService = FilesService;
        this.Api = Api;
        this.ToastService = ToastService;
        this.$q = $q;
        this.$state = $state;
        this.$translate = $translate;
        this.Access = Access;
        this.$onInit = () => {
            const promises = [];
            promises.push(this.FilesService.getRootDirectories());
            const deferBC = this.$q.defer();
            this.Api.workspace.breadcrumbs.get({ id: this.$stateParams.id }, (res) => {
                this.breadCrumbs = res;
                this._root = this.breadCrumbs.length ? this.breadCrumbs[0] : null;
                deferBC.resolve();
            }, () => {
                deferBC.reject();
            });
            promises.push(deferBC.promise);
            const deferDir = this.$q.defer();
            this.Api.workspace.rights.get({ id: this.$stateParams.id }, (res) => {
                this.directory = res;
                deferDir.resolve();
            }, () => {
                deferBC.reject();
            });
            promises.push(deferDir.promise);
            this.$q.all(promises).then(() => {
                this._checkAccess();
                this._initRights();
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        /**
         * Save user's rights if different from parent folder rights (used by default)
         * @param rights
         * @param key
         */
        this.saveRights = (rights, key) => {
            // Check all or check all in hospital
            if (rights.isDefault) { // (un)check all
                for (let r of this.directoryMembersRights) {
                    r[key] = rights[key];
                }
            }
            const dirDefaultRights = this._getDirectoryDefaultRights();
            this.directory.defaultRights = this.DirectoryRightsService.directoryRightsToBitmask(dirDefaultRights);
            this.directory.membersRights = [];
            for (let m of this.directoryMembersRights) {
                if (!m.isMember)
                    continue;
                let bitmask = this.DirectoryRightsService.directoryRightsToBitmask(m);
                if (bitmask !== this.directory.defaultRights) {
                    this.directory.membersRights.push({ member: { _id: m._id }, rights: bitmask });
                }
            }
            this.Api.workspace.rights.save({
                _id: this.directory._id,
                defaultRights: this.directory.defaultRights,
                membersRights: this.directory.membersRights,
            }, () => {
                this.ToastService.show(EMpStatus.Ok);
            }, (err) => {
                console.error(err);
                this.ToastService.show(EMpStatus.RejectByServer);
            });
        };
        /**
         * return icon for f as root directory
         * @param f
         * @returns {string}
         */
        this.getRootIcon = (f) => {
            if (f.member) {
                return 'icon-account';
            }
            else if (f.hospital) {
                return 'icon-hospital';
            }
            else if (f.region) {
                return 'icon-map-marker';
            }
            else if (f.group) {
                return 'icon-account-multiple';
            }
            return null;
        };
        /**
         * Change directory (click on breadcrumb item)
         * @param dir
         */
        this.changeDir = (dir) => {
            this.$state.go('mapui.files.rights', { id: dir._id });
        };
        /**
         * Is current directory the root directory
         * @return {boolean}
         */
        this.isRoot = () => {
            return Utils.compareIds(this.directory, this._root);
        };
        /**
         * Define if user has access to current directory rights management
         * @private
         */
        this._checkAccess = () => {
            if (!this._root)
                return;
            this.hasAccess = true;
        };
        /**
         * Load or create rights for current directory
         * @private
         */
        this._initRights = () => {
            let defaultRights = this.DirectoryRightsService.getDirectoryRightsBitmask(this.breadCrumbs);
            this._originalDirectoryRights = defaultRights;
            this.Api.member.byHospital.all({ hid: this.Access.user.hospital._id }, members => {
                const refDirectory = this.DirectoryRightsService.getReferenceDirectory(this.breadCrumbs);
                //const hospitals = this._groupMembersPerHospital(members);
                let r = this.DirectoryRightsService.bitmaskToDirectoryRights(defaultRights);
                r.isDefault = true;
                r.name = this.$translate.instant('FILES.RIGHTS.LABELS.DEFAULTS');
                this.directoryMembersRights.push(r);
                for (let m of members) {
                    let r = this.DirectoryRightsService.bitmaskToDirectoryRights(defaultRights);
                    if (refDirectory.membersRights) {
                        const rights = this._getMemberSpecificRights(m, refDirectory);
                        if (rights) {
                            r = rights;
                        }
                    }
                    r.isMember = true;
                    r._id = m._id;
                    r.name = m.fullName;
                    this.directoryMembersRights.push(r);
                }
            });
        };
        /**
         * Try to find member specific rights inside {directory} membersRights
         * @param member
         * @param directory
         * @return {any}
         * @private
         */
        this._getMemberSpecificRights = (member, directory) => {
            for (let t of directory.membersRights) {
                if (Utils.compareIds(t.member, member)) {
                    return this.DirectoryRightsService.bitmaskToDirectoryRights(t.rights);
                }
            }
            return null;
        };
        /**
         * Extract default directory rights entry from list of rights
         * @return {any}
         * @private
         */
        this._getDirectoryDefaultRights = () => {
            for (let d of this.directoryMembersRights) {
                if (d.isDefault) {
                    return d;
                }
            }
            return null;
        };
        this.breadCrumbs = [];
        this.isLoading = true;
        this.directoryMembersRights = [];
    }
}
