import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalDrugBalanceAddSourceController {
    /** @ngInject */
    constructor(ModalService, SourceService, _) {
        this.ModalService = ModalService;
        this.SourceService = SourceService;
        this._ = _;
        /**
         *
         * @param {ISource} source
         */
        this.sourceChange = ({ source }) => {
            this.source.source = source;
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            this._close(this.source);
        };
        /**
         * Close the modal with actions performing
         * @private
         */
        this._close = (answer) => {
            if (!answer.source._id) {
                this.SourceService.add(answer.source).then((source) => {
                    answer.source = source;
                    this.ModalService.close(answer);
                });
            }
            else
                this.ModalService.close(answer);
        };
        this.source = {};
    }
    $onInit() { }
    $onChanges(changes) {
        if (changes.patientSources) {
            this.patientSources = angular.copy(this.patientSources);
        }
        if (changes.dbsSources) {
            this.dbsSources = angular.copy(this.dbsSources);
        }
    }
}
