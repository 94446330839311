var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { HsAlternativeContainerService } from '@app/hospistock/shared/services/hs-alternative-container.service';
import { HsDownloadService } from '@app/hospistock/shared/services/hs-download.service';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { map, skip, Subject, takeUntil } from 'rxjs';
import { CHsPublicationType } from '../../constTypes/publication/hs-publication-type.const';
let HsAlternativesListComponent = class HsAlternativesListComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _alternativeContainerService, _injector) {
        // ListPage
        super(_injector, _alternativeContainerService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._alternativeContainerService = _alternativeContainerService;
        this._injector = _injector;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init subjects
        this._unsubscribeAll = new Subject();
        // Init constants
        this.genericTableConfig = { clickableRows: false };
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // HsAlternative service values
    get alternativeContainers$$() {
        return this._alternativeContainerService.alternativeContainers$$;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // ListPage ngOnInit
        super.ngOnInit();
        // Update config
        this.pageHeaderConfig = this.config.pageHeader;
        this.columns = this.config.columns;
        // Update Service alternatives on ListPage dataSource change event
        // Skip first BehaviorSubject initial value
        this.dataSource.pipe(skip(1), takeUntil(this._unsubscribeAll)).subscribe({
            next: (alternativeContainers) => this._alternativeContainerService.updateAlternativeContainers(alternativeContainers),
            error: (err) => console.error(err)
        });
        // Update alternatives$ on alternativeContainers$$ changes
        // TODO: handle update on changes of one of the items of alternativeContainers$$ independently
        this.alternatives$ = this.alternativeContainers$$.pipe(takeUntil(this._unsubscribeAll), map((alternativeContainers$) => alternativeContainers$.map((alternativeContainer$) => alternativeContainer$.value)));
        // Subscribe to reload event
        this._alternativeContainerService.onReloadList
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._reload.emit());
    }
    ngOnDestroy() {
        // ListPage ngOnDestroy
        super.ngOnDestroy();
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    getTypeLabel(value) {
        return Object.values(CHsPublicationType).find((type) => type.value === value).translate;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsAlternativesListComponent.prototype, "config", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_d = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _d : Object)
], HsAlternativesListComponent.prototype, "_paginator", void 0);
HsAlternativesListComponent = __decorate([
    Component({
        selector: 'mpx-alternatives-list',
        template: require('./alternatives-list.component.html').default,
        animations: fuseAnimations,
        providers: [HsAlternativeContainerService, HsHealthProductService, HsDownloadService]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof HsAlternativeContainerService !== "undefined" && HsAlternativeContainerService) === "function" ? _b : Object, typeof (_c = typeof Injector !== "undefined" && Injector) === "function" ? _c : Object])
], HsAlternativesListComponent);
export { HsAlternativesListComponent };
