var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e;
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '@main/services/Utils';
import { Access } from '@main/services/auth/access.service';
import { Auth } from '@main/services/auth/auth.service';
import { MemberService as AjsMemberService } from '../../../main/services/member/member.service';
import { HospitalService } from '@services/hospital/hospital.service';
let IdentityService = class IdentityService {
    constructor(_ajsAuth, _ajsAccess, _ajsMemberService, _hospitalService, _http) {
        this._ajsAuth = _ajsAuth;
        this._ajsAccess = _ajsAccess;
        this._ajsMemberService = _ajsMemberService;
        this._hospitalService = _hospitalService;
        this._http = _http;
        this.isPharmacy = this._ajsAuth.isPharmacy;
        this.hasPremiumReadPermission = this._ajsAuth.hasPremiumReadPermission;
        this.hasOctaveReadPermission = this._ajsAuth.hasOctaveReadPermission;
        this.isOnlyPremium = this._ajsAuth.isOnlyPremium;
        this.isOnlyOctave = this._ajsAuth.isOnlyOctave;
        this.isBothOctaveAndPremium = this._ajsAuth.isBothOctaveAndPremium;
        this.reloadHospital();
    }
    get user() {
        return this._ajsAuth.user;
    }
    get hospital() {
        return this._ajsAuth.user ? this._ajsAuth.user.hospital : null;
    }
    saveUser() {
        this._ajsMemberService.save(this.user).then((res) => {
            //GOING TO UPDATE COOKIE
            return this._ajsAuth.updateUserCookie(this.user);
        });
    }
    reloadHospital() {
        this._hospitalService.getById(Utils.getId(this.user.hospital)).subscribe((hospital) => {
            this._ajsAuth.user.hospital = hospital;
            this._ajsAccess.hospital = hospital;
            this._ajsAccess.user.hospital = hospital;
        });
    }
    /**
     * getMembersByNameOrEmail
     * @param {string} search name or email
     * @return {Observable<IPaginationResult<Member>>} array of found members
     */
    getMembersByName(search) {
        const fullEndpoint = `/api/hospitals/${this.hospital._id}/members`;
        let params = new HttpParams();
        // Begin assigning parameters
        if (search) {
            params = params.append('name', search);
        }
        return this._http.get(fullEndpoint, { params: params });
    }
};
IdentityService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Auth !== "undefined" && Auth) === "function" ? _a : Object, typeof (_b = typeof Access !== "undefined" && Access) === "function" ? _b : Object, typeof (_c = typeof AjsMemberService !== "undefined" && AjsMemberService) === "function" ? _c : Object, typeof (_d = typeof HospitalService !== "undefined" && HospitalService) === "function" ? _d : Object, typeof (_e = typeof HttpClient !== "undefined" && HttpClient) === "function" ? _e : Object])
], IdentityService);
export { IdentityService };
