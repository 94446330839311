var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from './i18n/fr';
import { locale as english } from './i18n/en';
import { IdentityService } from '@services/identity/identity.service';
let SearchEstablishmentMemberComponent = class SearchEstablishmentMemberComponent {
    constructor(_identityService, _fuseTranslationLoaderService) {
        this._identityService = _identityService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        /**
         * Path to translation
         */
        this.labelPath = 'SEARCH_ESTA_MEMBER.SELECT';
        /**
         * Emits when an establishment is selected.
         */
        this.onSelected = new EventEmitter();
        this.members = null;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        this.members = this.parentFormControl.valueChanges.pipe(startWith(''), debounceTime(500), switchMap((value) => {
            if (typeof value === 'string') {
                return this.search(value);
            }
            // Return empty array when hospital has just been selected
            return of([]);
        }));
    }
    /**
     * Check if form control is set as required
     */
    get isRequired() {
        var _a;
        if ((_a = this.parentFormControl) === null || _a === void 0 ? void 0 : _a.validator) {
            const validator = this.parentFormControl.validator({});
            return validator && validator.required;
        }
        return false;
    }
    search(value) {
        if (value && value != '') {
            return new Observable((observer) => {
                this._identityService.getMembersByName(value).subscribe((data) => {
                    observer.next(data);
                });
            });
        }
        else {
            return of(null);
        }
    }
    inputChanged() {
        this.onSelected.emit(null);
    }
    optionSelected(member) {
        this.onSelected.emit(member);
    }
    displayFn(member) {
        var _a;
        return (_a = member === null || member === void 0 ? void 0 : member.fullName) !== null && _a !== void 0 ? _a : undefined;
    }
    reset() {
        this.parentFormControl.reset();
        this.onSelected.emit(null);
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_c = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _c : Object)
], SearchEstablishmentMemberComponent.prototype, "parentFormControl", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], SearchEstablishmentMemberComponent.prototype, "labelPath", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], SearchEstablishmentMemberComponent.prototype, "onSelected", void 0);
SearchEstablishmentMemberComponent = __decorate([
    Component({
        selector: 'search-establishment-member',
        template: require('./search-establishment-member.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof IdentityService !== "undefined" && IdentityService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object])
], SearchEstablishmentMemberComponent);
export { SearchEstablishmentMemberComponent };
