var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { ChangeDetectionStrategy, Component, Injector, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as french } from '@app/hospistock/i18n/fr';
import { locale as english } from '@app/hospistock/i18n/en';
import { EHsHealthProductTypes } from '@mapuilabs/mpl-interfaces';
import { HsDownloadService } from '@app/hospistock/shared/services/hs-download.service';
import { HsStatisticService } from '@app/hospistock/shared/services/hs-statistic.service';
import { ListPage } from '@shared/templates/listPage/listPage.class';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { MatDialog } from '@angular/material/dialog';
import { HsModalPublicationStatisticProposedAlternativeComponent } from '../modals/publication/statistic/proposed-alternative/hs-modal-publication-statistic-proposed-alternative.component';
import { HsModalPublicationStatisticSelectedAlternativeComponent } from '../modals/publication/statistic/selected-alternative/hs-modal-publication-statistic-selected-alternative.component';
import { HsPublicationDetailStatsWidgetModalComponent } from '../modals/publication/detail/stats-widget/hs-modal-publication-detail-stats-widget.component';
import { StateService } from '@uirouter/angular';
import { UIRouterGlobals } from '@uirouter/angularjs';
import * as moment from 'moment';
let HsStatisticComponent = class HsStatisticComponent extends ListPage {
    constructor(_downloadService, _fuseTranslationLoaderService, _matDialog, _router, _statisticService, _stateService, _injector) {
        super(_injector, _statisticService.getStatisticPage);
        this._downloadService = _downloadService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._matDialog = _matDialog;
        this._router = _router;
        this._statisticService = _statisticService;
        this._stateService = _stateService;
        this._injector = _injector;
        this.downloadDisabled = false;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._unsubscribeAll = new Subject();
        this.genericTableConfig = { clickableRows: true };
    }
    // Table
    get columns() {
        return this.config.table.columns;
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    ngOnInit() {
        // ListPage ngOnInit
        super.ngOnInit();
        // Update config
        this.pageHeaderConfig = this.config.pageHeader;
        this.translationBase = this.config.translationBase;
        this.healthProductType = this.config.healthProductType;
    }
    ngOnDestroy() {
        // ListPage ngOnDestroy
        super.ngOnDestroy();
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    openProposedAlternativesModal(proposedAlternatives) {
        this._matDialog.open(HsModalPublicationStatisticProposedAlternativeComponent, {
            data: { proposedAlternatives, healthProductType: this.healthProductType },
            minWidth: '60%',
            restoreFocus: false
        });
    }
    openSelectedAlternativesModal(selectedAlternatives) {
        this._matDialog.open(HsModalPublicationStatisticSelectedAlternativeComponent, {
            data: { selectedAlternatives, healthProductType: this.healthProductType },
            minWidth: '60%',
            restoreFocus: false
        });
    }
    openCountSeenModal(count) {
        const data = {
            value: count === null || count === void 0 ? void 0 : count.length,
            data: count.map((item) => ({
                hospitalName: item.hospital.name,
                seenDate: moment(item.members
                    .filter((member) => { var _a; return !!((_a = member.seen) === null || _a === void 0 ? void 0 : _a[0]); })
                    .reduce((metadata1, metadata2) => { var _a, _b; return ((_a = metadata1.seen) === null || _a === void 0 ? void 0 : _a[0].date) < ((_b = metadata2.seen) === null || _b === void 0 ? void 0 : _b[0].date) ? metadata1 : metadata2; }).seen[0].date).format('ll')
            })),
            publication: null
        };
        const config = {
            title: 'PUBLICATION.TITLES.LABO_WIDGET.PHARMACY_SEEN',
            actionLabel: 'Voir liste',
            columns: [
                {
                    label: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
                    attribute: 'hospitalName'
                },
                {
                    label: 'PUBLICATION.MODAL.LABO_STATS.SEEN',
                    attribute: 'seenDate'
                }
            ]
        };
        this._matDialog.open(HsPublicationDetailStatsWidgetModalComponent, {
            data: { data, config },
            minWidth: '60%',
            restoreFocus: false
        });
    }
    openCountReadModal(count) {
        const data = {
            value: count === null || count === void 0 ? void 0 : count.length,
            data: count.map((item) => ({
                hospitalName: item.hospital.name,
                readDate: moment(item.members
                    .filter((member) => { var _a; return !!((_a = member.read) === null || _a === void 0 ? void 0 : _a[0]); })
                    .reduce((metadata1, metadata2) => { var _a, _b; return ((_a = metadata1.read) === null || _a === void 0 ? void 0 : _a[0].date) < ((_b = metadata2.read) === null || _b === void 0 ? void 0 : _b[0].date) ? metadata1 : metadata2; }).read[0].date).format('ll')
            })),
            publication: null
        };
        const config = {
            title: 'PUBLICATION.TITLES.LABO_WIDGET.PHARMACY_READ',
            actionLabel: 'Voir liste',
            columns: [
                {
                    label: 'MAPUI.GENERAL.CONST_TYPE.ESTABLISHMENT.HOSPITAL',
                    attribute: 'hospitalName'
                },
                {
                    label: 'PUBLICATION.MODAL.LABO_STATS.READ',
                    attribute: 'readDate'
                }
            ]
        };
        this._matDialog.open(HsPublicationDetailStatsWidgetModalComponent, {
            data: { data, config },
            minWidth: '60%',
            restoreFocus: false
        });
    }
    downloadStatistics() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.downloadDisabled) {
                return; // Prevent download if the button is disabled
            }
            this.downloadDisabled = true;
            try {
                yield this._downloadService.downloadLaboPublicationsStatsSpreadsheet(this.searchValue, this.sortValue);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                this.downloadDisabled = false;
            }
        });
    }
    /**
     * Handles the click event when a row is clicked in the publication list.
     * Navigates to the detail view of the selected publication.
     * @param publication - The selected publication to view in detail.
     */
    onRowClicked(publication) {
        // Navigates to the detail view with the publication’s ID
        this._stateService.go(this._router.current.name + '.detail', { id: publication._id });
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsStatisticComponent.prototype, "config", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], HsStatisticComponent.prototype, "_paginator", void 0);
HsStatisticComponent = __decorate([
    Component({
        selector: 'mpx-hs-statistic',
        template: require('./hs-statistic.component.html').default,
        providers: [HsHealthProductService, HsDownloadService, HsStatisticService],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _c : Object, typeof (_d = typeof UIRouterGlobals !== "undefined" && UIRouterGlobals) === "function" ? _d : Object, typeof (_e = typeof HsStatisticService !== "undefined" && HsStatisticService) === "function" ? _e : Object, typeof (_f = typeof StateService !== "undefined" && StateService) === "function" ? _f : Object, typeof (_g = typeof Injector !== "undefined" && Injector) === "function" ? _g : Object])
], HsStatisticComponent);
export { HsStatisticComponent };
