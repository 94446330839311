import { EMpStatus, ENotificationType, EVoucherType } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
import { ModalLoanConditions } from '../../services/loan/mp-modal-loan/mp-modal-loan.conditions';
/** @ngInject */
export class MpDashboardController {
    /** @ngInject */
    constructor(DashboardService, LoanService, ModalService, $mdSidenav, $state, $translate, Api, Access, StatusService, NotificationService, ToastService, $stateParams) {
        this.DashboardService = DashboardService;
        this.LoanService = LoanService;
        this.ModalService = ModalService;
        this.$mdSidenav = $mdSidenav;
        this.$state = $state;
        this.$translate = $translate;
        this.Api = Api;
        this.Access = Access;
        this.StatusService = StatusService;
        this.NotificationService = NotificationService;
        this.ToastService = ToastService;
        this.$stateParams = $stateParams;
        /**
         * On destroy remove listener
         */
        this.$onDestroy = () => {
            this.NotificationService.unregister(this._listener);
        };
        /**
         * Get the loans when the tab is selected
         */
        this.getLoans = () => {
            if (!this.loans || this._needRefresh) {
                this.DashboardService.getLoans()
                    .then((loans) => {
                    this.loans = loans;
                    for (const loan of this.loans) {
                        loan.state = this.StatusService.calcStep(loan);
                    }
                })
                    .catch(() => this.loans = []);
            }
        };
        /**
         * Get the preparations when the tab is selected
         */
        this.getPreparations = () => {
            if (!this.preparations || this._needRefresh) {
                this.DashboardService.getPreparations()
                    .then((loans) => {
                    this.preparations = loans;
                    this.preparations.forEach((loan) => {
                        loan.state = this.StatusService.calcStep(loan);
                    });
                })
                    .catch(() => this.preparations = []);
            }
        };
        /**
         * Get the borrowings when the tab is selected
         */
        this.getBorrowings = () => {
            if (!this.borrowings || this._needRefresh) {
                this.DashboardService.getBorrowings()
                    .then((loans) => {
                    this.borrowings = loans;
                    for (const loan of this.borrowings) {
                        loan.state = this.StatusService.calcStep(loan);
                    }
                })
                    .catch(() => this.borrowings = []);
            }
        };
        /**
         * Get the requests when the tab is selected
         */
        this.getRequests = () => {
            if (!this.requests || this._needRefresh) {
                this.DashboardService.getRequests()
                    .then((loans) => this.requests = loans)
                    .catch(() => this.requests = []);
            }
        };
        /**
         * Open the details sidenav
         * @param loan The loan to display in the details sidenav
         */
        this.toggleSidenav = (loan) => {
            this.selected = angular.copy(loan);
            this.$mdSidenav('mp-sidenav-summary')
                .toggle();
        };
        /**
         * Open the filters sidenav
         */
        this.toggleFiltersSidenav = () => {
            this.$mdSidenav('mp-sidenav-dashboard-filters')
                .toggle();
        };
        /**
         * Open a modal to create a new [[ILoan]]
         * @param event The DOM event
         */
        this.openCreationModal = (event) => {
            event.stopPropagation();
            this.DashboardService.openLoanCreationModal(event)
                .then(() => {
                this._refresh();
            });
        };
        /**
         * Open a modal to update a [[ILoan]]
         * @param event The DOM event
         * @param loan The [[ILoan]] to update
         */
        this.openUpdateModal = (event, loan) => {
            event.stopPropagation();
            this.DashboardService.openLoanUpdateModal(event, loan)
                .then(() => {
                this._refresh();
            });
        };
        /**
         * Go to the full version of the dashboard from the lite
         */
        this.goToFull = () => {
            this.$state.go('mapui.dashboard');
        };
        /**
         * Get the current selected language
         * @returns {string} the current language
         */
        this.getLang = () => {
            return this.$translate.use();
        };
        /**
         * Open the [[IMember]] sidenav
         * @param member
         */
        this.openMemberSideNav = ({ member }) => {
            if (!member) {
                return;
            }
            this.selectedMember = member;
            this.$mdSidenav('mp-sidenav-member')
                .open();
        };
        /**
         * Delete the given [[ILoan]]
         * @param loan The [[ILoan]] to delete
         */
        this.deleteLoan = (loan) => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.SERVICES.LOAN.MODAL.CONFIRM.TITLE',
                        text: this.sts.isMaD(loan) ? 'MAPUI.DASHBOARD.MODAL.CONFIRM.REMOVE_AVAILABLE' : 'MAPUI.DASHBOARD.MODAL.CONFIRM.DELETE',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                this.LoanService.delete(loan).then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                    this._refresh();
                });
            });
        };
        /**
         * Edit [[ILoan]] return type (bill or return)
         * @param loan
         */
        this.editLoanReturn = (loan) => {
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: {
                        title: 'MAPUI.SERVICES.LOAN.MODAL.EDIT_RETURN.TITLE',
                        text: !loan.returnExpected ? 'MAPUI.SERVICES.LOAN.MODAL.EDIT_RETURN.RETURN' : 'MAPUI.SERVICES.LOAN.MODAL.EDIT_RETURN.BILL',
                    },
                },
                escapeToClose: false,
            }).then(() => {
                loan.returnExpected = !loan.returnExpected;
                loan.returnComment = null;
                loan.billNumber = null;
                loan.commentBill = null;
                loan.charge = null;
                loan.tva = null;
                loan.unitPrice = null;
                this.LoanService.save(loan).then(() => {
                    this.ToastService.show(EMpStatus.Ok);
                    this._refresh();
                });
            });
        };
        /**
         * Generate voucher for the given [[ILoan]], suggest loans for multiline voucher
         * @param {ILoan} loan
         * @param type
         */
        this.generateVoucher = (loan, type) => {
            if (type == 'loan')
                this.voucher = EVoucherType.Loan;
            else if (type === 'borrow')
                this.voucher = EVoucherType.Borrow;
            else if (type === 'return')
                this.voucher = EVoucherType.Return;
            this.ModalService.show({
                component: 'mp-modal-voucher',
                bindings: {
                    loan: loan,
                    voucherType: this.voucher,
                },
                escapeToClose: false,
            });
        };
        /**
         * Init the list of the last action performed on the [[ILoan]]
         * @private
         */
        this._initLastLoans = () => {
            this.Api.constType.get((res) => {
                this.type = res.loanType;
                this.LoanService.getLastLoans(this.Access.hospital._id, 3)
                    .then((loans) => {
                    this.lastLoans = loans;
                    this.lastLoans.forEach((value) => {
                        if (this.sts.isMaD(value)) {
                            value.type = this.type.available.translate;
                        }
                        else if (this.sts.isRequest(value)) {
                            value.type = this.type['product-demand'].translate;
                        }
                        else if (this.sts.amIL(value)) {
                            value.type = this.type.loan.translate;
                        }
                        else {
                            value.type = this.type.borrowing.translate;
                        }
                    });
                });
            });
        };
        /**
         * Refresh all the dashboard data
         * @private
         */
        this._refresh = () => {
            this._needRefresh = true;
            if (this.lite) {
                this._initLastLoans();
            }
            else {
                this.getLoans();
                this.getBorrowings();
                this.getRequests();
                this.getPreparations();
            }
            this._needRefresh = false;
        };
        this._needRefresh = false;
        this.conditions = new ModalLoanConditions(this.StatusService, this.Access);
        this.sts = this.StatusService;
        this.filters = { loanStatus: [], establishmentType: [] };
        this._listener = this.NotificationService.register([
            ENotificationType.Loan,
            ENotificationType.Borrowing,
            ENotificationType.Preparation,
        ], (n) => {
            this._needRefresh = true;
            switch (n.type) {
                case ENotificationType.Loan:
                case ENotificationType.Borrowing:
                    if (this.loans) {
                        this.getLoans();
                    }
                    if (this.borrowings) {
                        this.getBorrowings();
                    }
                    break;
                case ENotificationType.Preparation:
                    if (this.preparations) {
                        this.getPreparations();
                    }
                    break;
            }
            this._needRefresh = false;
        });
    }
    $onInit() {
        this.getLoans();
        if (this.lite) {
            this._initLastLoans();
        }
    }
}
