var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { ChangeDetectionStrategy, Component, Injector, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DialogService } from '@app/services/dialog/dialog.service';
import { SpreadsheetImportModalComponent } from '@app/shared/components/spreadsheet-import-modal/spreadsheet-import-modal.component';
import { ESpreadsheetImportReportListTypes, SpreadsheetImportReportList } from '@app/shared/components/spreadsheet-import-modal/spreadsheet-import-report-list/spreadsheet-import-report-list.types';
import { ListPage } from '@app/shared/templates/listPage/listPage.class';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { EHsHealthProductTypes, SpreadsheetImportResultStatus } from '@mapuilabs/mpl-interfaces';
import * as _ from 'lodash';
import * as moment from 'moment';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { locale as english } from '../../../i18n/en';
import { locale as french } from '../../../i18n/fr';
import { HsDownloadService } from '../../services/hs-download.service';
import { HsHealthProductDirectoryService } from '../../services/hs-health-product-directory.service';
import { HsHealthProductService } from '../../services/hs-health-product.service';
let HsHealthProductDirectoryComponent = class HsHealthProductDirectoryComponent extends ListPage {
    constructor(_fuseTranslationLoaderService, _healthProductDirectoryService, _downloadService, _healthProductService, _matDialog, _dialogService, _injector) {
        super(_injector, _healthProductDirectoryService.getPage);
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._healthProductDirectoryService = _healthProductDirectoryService;
        this._downloadService = _downloadService;
        this._healthProductService = _healthProductService;
        this._matDialog = _matDialog;
        this._dialogService = _dialogService;
        this._injector = _injector;
        this.downloadDisabled = false;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this._templateDownloadCallback = () => {
            this.downloadDisabled = false;
            this._changeDetectorRef.detectChanges();
        };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._unsubscribeAll = new Subject();
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // Table
    get columns() {
        return this.config.table.columns;
    }
    get pageSize() {
        return this._paginator.pageSize;
    }
    get pageIndex() {
        return this._paginator.pageIndex;
    }
    // HsPublication service values
    get readonly() {
        return this._healthProductDirectoryService.readonly;
    }
    // Health Product service values
    get latestHealthProductDirectory$() {
        return this._healthProductDirectoryService.latestHealthProductDirectory$;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // ListPage ngOnInit
        super.ngOnInit();
        // Update config
        this.importConfig = this.config.import;
        this.pageHeaderConfig = this.config.pageHeader;
        this.translationBase = this.config.translationBase;
        // Update subtitle on latest import date changes
        this.latestHealthProductDirectory$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((latestHealthProductDirectory) => {
            const subtitle = this._generateLatestImportSubtitle(latestHealthProductDirectory);
            Object.assign(this.pageHeaderConfig, { subtitle });
        });
    }
    ngOnDestroy() {
        // ListPage ngOnDestroy
        super.ngOnDestroy();
        // Unsubscribe all subscriptions
        this._unsubscribeAll.next();
        // Unsubscribe subscriptionNotifier
        this._unsubscribeAll.complete();
        // Remove event listeners
        removeEventListener('beforeunload', this._templateDownloadCallback);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Component methods
    // -----------------------------------------------------------------------------------------------------
    _generateLatestImportSubtitle(healthProductDirectory) {
        const generateImportedMessage = () => this._translate(this.translationBase + '.TITLES.LATEST_DIRECTORY_IMPORT', {
            value: moment(healthProductDirectory.creationDate).format('ll')
        });
        const generateNeverImportedMessage = () => this._translate(this.translationBase + '.TITLES.DIRECTORY_NEVER_IMPORTED');
        return healthProductDirectory ? generateImportedMessage() : generateNeverImportedMessage();
    }
    /**
     * Acces the value of a health product item using an array of keys
     * @param item health product item
     * @param keys Array of keys used to access the value
     * @returns Value
     */
    getHealthProductDirectoryValueFromKeys(item, keys) {
        const reducer = (value, key) => (Array.isArray(value) && (value === null || value === void 0 ? void 0 : value[0]) ? value[0][key] : value[key]);
        return keys.reduce(reducer, item);
    }
    /**
     * Map and translate import result's messages returned by HospistockService's importFromSpreadsheet method.
     * @param message Message returned by HospistockService's importFromSpreadsheet method as HsHealthProductSpreadsheetImportResultMessages enum.
     * @returns Mapped & Translated message to display in the import report list.
     */
    translateReportListMessage(message) {
        return this._translate(this.importConfig.spreadsheetImportResultMessagesMap.get(message));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open publication form modal to create/update publication
     */
    importHealthProductDirectory() {
        // Guard: no import config
        if (!this.importConfig) {
            throw new Error('Import config not provided, cannot import health products directory.');
        }
        // Define uploead function to use in import modal
        const uploadFn = (file, importReportConfig) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            // Build health product directory
            const spreadsheetImportContainers = this._healthProductDirectoryService.buildHealthProductDirectory(file.content, (_a = this.importConfig.spreadsheetParserConfig.options) === null || _a === void 0 ? void 0 : _a.headerToLowerCase);
            // Upload health product directory
            const response = yield lastValueFrom(this._healthProductDirectoryService.importFromSpreadsheet(spreadsheetImportContainers));
            // Build reportList from import result
            const importResultStatusToReportListTypesMap = new Map([
                [SpreadsheetImportResultStatus.CREATED.toString(), ESpreadsheetImportReportListTypes.SUCCESS],
                [SpreadsheetImportResultStatus.UPDATED.toString(), ESpreadsheetImportReportListTypes.INFO],
                [SpreadsheetImportResultStatus.IGNORED.toString(), ESpreadsheetImportReportListTypes.WARNING],
                [SpreadsheetImportResultStatus.REJECTED.toString(), ESpreadsheetImportReportListTypes.ERROR]
            ]);
            const map = new Map();
            _(response)
                // => { CREATED: SpreadsheetImportResultContainer[], UPDATED: SpreadsheetImportResultContainer[], ...}
                .groupBy('metadata.status')
                // => { [CREATED, SpreadsheetImportResultContainer[]], [UPDATED: SpreadsheetImportResultContainer[]], ...}
                .toPairs()
                // => [ { type: SUCCESS, elements: { metadata: { message: string }, data: HsHealthProductItem }[] }, { type: INFO, elements: { metadata: { message: string }, data: HsHealthProductItem }[] }, ...]
                .forEach(([status, results]) => {
                const type = importResultStatusToReportListTypesMap.get(status);
                const config = importReportConfig.get(type);
                const items = results;
                map.set(type, { config, items });
            });
            return new SpreadsheetImportReportList(map);
        });
        const data = {
            title: this._translate(this.translationBase + '.TITLES.ADD'),
            parsing: {
                acceptedExtensions: '.ods, .xls, .xlsx, .csv',
                config: this.importConfig.spreadsheetParserConfig,
                report: {
                    config: new Map([
                        // Corrected errors
                        [ESpreadsheetImportReportListTypes.INFO, { hide: true }]
                    ])
                }
            },
            import: {
                report: {
                    config: new Map([
                        [
                            ESpreadsheetImportReportListTypes.SUCCESS,
                            {
                                status: this._translate(this.translationBase + '.IMPORT.REPORT.SUCCESS.STATUS'),
                                instructions: this._translate(this.translationBase + '.IMPORT.REPORT.SUCCESS.INSTRUCTIONS')
                            }
                        ],
                        [
                            ESpreadsheetImportReportListTypes.WARNING,
                            {
                                status: this._translate(this.translationBase + '.IMPORT.REPORT.WARNING.STATUS'),
                                instructions: this._translate(this.translationBase + '.IMPORT.REPORT.WARNING.INSTRUCTIONS')
                            }
                        ]
                    ]),
                    template: this._importReportListCustomTemplate
                },
                uploadFn
            }
        };
        const dialogRef = this._matDialog.open(SpreadsheetImportModalComponent, {
            data,
            disableClose: true,
            minWidth: '60%',
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe((isFileUploaded) => {
            if (isFileUploaded) {
                this._reload.emit();
            }
        }, (err) => this._snackbarService.openError(err));
    }
    onSearch(searchValue) {
        this.searchValue = searchValue;
        this.search();
    }
    /**
     * Download import template for either Booklet or Market.
     */
    downloadImportTemplate() {
        var _a;
        if (!((_a = this.config) === null || _a === void 0 ? void 0 : _a.translationBase)) {
            throw new Error('No valid translation found');
        }
        const fileTemplateUrl = this._translate(this.config.translationBase + '.IMPORT.TEMPLATE.' + this.healthProductType);
        if (this.downloadDisabled) {
            return;
        }
        this.downloadDisabled = true;
        this._downloadService
            .downloadImportTemplate(fileTemplateUrl)
            .addEventListener('beforeunload', this._templateDownloadCallback);
    }
    /**
     * Open either HsBookletModalComponent or HsMarketModalComponent form modal to create HsHealthProductItem
     */
    addHealthProduct() {
        const dialogRef = this._matDialog.open(this.config.modal, {
            data: { injector: this._injector },
            disableClose: true,
            minWidth: '60%',
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe({
            next: (hsHealthProductItem) => {
                if (hsHealthProductItem) {
                    this._healthProductDirectoryService.add(hsHealthProductItem).subscribe({
                        next: () => {
                            this._reload.emit();
                            this._snackbarService.open(this._translate(this.translationBase + '.NOTIFS.ADD_TO'));
                        },
                        error: (err) => this._snackbarService.openError(err)
                    });
                }
            },
            error: (err) => this._snackbarService.openError(err)
        });
    }
    /**
     * Delete either HsBookletItem or HsMarketItem
     */
    deleteHealthProduct(item) {
        const name = item.healthProductRef.name;
        this._dialogService
            .openConfirmDialog({
            title: this._translate(this.translationBase + '.TITLES.DELETE_ITEM'),
            message: this._translate(this.translationBase + '.CONTENT.DELETE_ITEM', { name }),
            confirmButton: this._translate('COMMONS.YES'),
            cancelButton: this._translate('COMMONS.NO')
        })
            .subscribe((data) => {
            if (data) {
                this._healthProductDirectoryService.delete(item).subscribe({
                    next: () => {
                        this._reload.emit();
                        this._snackbarService.open(this._translate(this.translationBase + '.CONTENT.DELETED_ITEM', { name }));
                    },
                    error: (error) => this._snackbarService.openError(error)
                });
            }
        });
    }
    /**
     * Open either HsBookletModalComponent or HsMarketModalComponent form modal to update HsHealthProductItem
     */
    editHealthProduct(item) {
        const dialogRef = this._matDialog.open(this.config.modal, {
            data: { injector: this._injector, item },
            disableClose: true,
            minWidth: '60%',
            restoreFocus: false
        });
        dialogRef.afterClosed().subscribe({
            next: (hsHealthProductItem) => {
                if (hsHealthProductItem) {
                    this._healthProductDirectoryService.save(hsHealthProductItem).subscribe({
                        next: () => {
                            this._reload.emit();
                            this._snackbarService.open(this._translate(this.translationBase + '.NOTIFS.MODIFIED'));
                        },
                        error: (err) => {
                            console.error(err);
                            this._snackbarService.openError(err);
                        }
                    });
                }
            },
            error: (err) => {
                console.error(err);
                this._snackbarService.openError(err);
            }
        });
    }
    downloadSpreadsheet() {
        return __awaiter(this, void 0, void 0, function* () {
            // Update pending flag
            this.isDownloadSpreadsheetPending = true;
            yield this._downloadService.downloadProductDirectorySpreadsheet(this.searchValue, this.sortValue, this.config.healthProductDirectoryType);
            // Update pending flag
            this.isDownloadSpreadsheetPending = false;
        });
    }
    _translate(value, arg) {
        return this._translationService.instant(value, arg);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsHealthProductDirectoryComponent.prototype, "config", void 0);
__decorate([
    ViewChild(MatPaginator, { static: true }),
    __metadata("design:type", typeof (_h = typeof MatPaginator !== "undefined" && MatPaginator) === "function" ? _h : Object)
], HsHealthProductDirectoryComponent.prototype, "_paginator", void 0);
__decorate([
    ViewChild('importReportListCustomTemplate', { static: true }),
    __metadata("design:type", typeof (_j = typeof TemplateRef !== "undefined" && TemplateRef) === "function" ? _j : Object)
], HsHealthProductDirectoryComponent.prototype, "_importReportListCustomTemplate", void 0);
HsHealthProductDirectoryComponent = __decorate([
    Component({
        selector: 'mpx-hs-health-product-directory',
        template: require('./hs-health-product-directory.component.html').default,
        providers: [HsHealthProductDirectoryService, HsHealthProductService, HsDownloadService],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _a : Object, typeof (_b = typeof HsHealthProductDirectoryService !== "undefined" && HsHealthProductDirectoryService) === "function" ? _b : Object, typeof (_c = typeof HsDownloadService !== "undefined" && HsDownloadService) === "function" ? _c : Object, typeof (_d = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _d : Object, typeof (_e = typeof MatDialog !== "undefined" && MatDialog) === "function" ? _e : Object, typeof (_f = typeof DialogService !== "undefined" && DialogService) === "function" ? _f : Object, typeof (_g = typeof Injector !== "undefined" && Injector) === "function" ? _g : Object])
], HsHealthProductDirectoryComponent);
export { HsHealthProductDirectoryComponent };
