export const locale = {
    lang: 'en',
    data: {
        sidebar: {
            TITLE: 'Filters',
            filters: {
                ROLES: 'Roles',
                ACCOUNT: 'Account',
                account: {
                    LOCKED: 'Locked',
                    BLOCKED: 'Blocked',
                    NO_ESTABLISHMENT: 'No establishment',
                    DELETED: 'Deleted accounts'
                }
            }
        }
    }
};
