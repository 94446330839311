export const locale = {
    lang: 'en',
    data: {
        ADD_DRUG_BALANCE_TAB: {
            LIST: 'Drug list',
            ADD: 'Add to prescription',
            QUANTITY: 'Quantity',
            ADD_BTN: 'add',
            TABLE: {
                NAME: 'Name',
                DOSAGE: 'Dosage',
                POSOLOGY: 'Posology'
            },
            ADDED: 'Added to prescription',
            DELETED: 'Element deleted',
            FINISH: 'finish',
            ALREADY_ADDED: 'Drug already existing in drug balance',
            FINISH_CONFIRM: {
                TITLE: 'Validate prescription',
                MESSAGE: 'By validating, you confirm having entirely completed the prescription. The activity status will change and you will not be able to edit the prescription anymore. Continue ?'
            }
        },
        INFO: {
            ADDED_HOSPITAL: 'Added by hospital',
            MODIFIED_HOSPITAL: 'Modified by hospital'
        }
    }
};
