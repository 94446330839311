var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HsPublicationBroadcastTreeItemFlatNode, HsPublicationBroadcastTreeNestedItemNode } from './hs-publication-broadcast-tree-list.types';
let HsPublicationBroadcastTreeListComponent = class HsPublicationBroadcastTreeListComponent {
    constructor() {
        /**
         * Emits when the user selected another hospital for the member.
         */
        this.selectionChanged = new EventEmitter();
        /** Map from flat node to nested node. This helps us finding the nested node to be modified */
        this._flatNodeMap = new Map();
        /** Map from nested node to flattened node. This helps us to keep the same object for selection */
        this._nestedNodeMap = new Map();
        // -----------------------------------------------------------------------------------------------------
        // @ Tree methods
        // -----------------------------------------------------------------------------------------------------
        this._getLevel = (node) => node.level;
        this._isExpandable = (node) => node.expandable;
        this._getChildren = (node) => node.children;
        /**
         * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
         */
        this._transformer = (node, level) => {
            var _a;
            const existingNode = this._nestedNodeMap.get(node);
            const flatNode = existingNode && existingNode.label === node.label
                ? existingNode
                : new HsPublicationBroadcastTreeItemFlatNode();
            flatNode.expandable = !!((_a = node.children) === null || _a === void 0 ? void 0 : _a.length);
            flatNode.label = node.label;
            flatNode.level = level;
            flatNode.selected = node.selected;
            flatNode.value = node.value;
            this._flatNodeMap.set(flatNode, node);
            this._nestedNodeMap.set(node, flatNode);
            return flatNode;
        };
        // -----------------------------------------------------------------------------------------------------
        // @ View methods
        // -----------------------------------------------------------------------------------------------------
        this.hasChild = (_, _nodeData) => _nodeData.expandable;
        this._unsubscribeAll = new Subject();
        this._treeFlattener = new MatTreeFlattener(this._transformer, this._getLevel, this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this._treeFlattener, []);
        this.checklistSelection = new SelectionModel(true /* multiple */);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if ('data' in changes) {
            if (this.data) {
                this.dataSource.data = this.data;
                this._updateSelectionModel();
            }
        }
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    _handleSelectionChanges() {
        // Use treeControl instead of checklistSelection to be able to use _nestedNodes method when parent are not selected
        const nodes = this.treeControl.dataNodes.map((node) => Object.assign(node, { selected: this.checklistSelection.isSelected(node) }));
        // Emit result
        this.selectionChanged.emit(this._nestedNodes(nodes));
    }
    /**
     * Update SelectionModel node references to match new ones when updating dataSource
     * @param data New dataSource value
     */
    _updateSelectionModel() {
        // Map actual selected nodes references with new nodes references
        const selectedNodes = this.treeControl.dataNodes.filter(({ selected }) => selected);
        // Update SelectionModel nodes reference
        this.checklistSelection = new SelectionModel(true);
        // Emit selected items value on selection change
        this.checklistSelection.changed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => this._handleSelectionChanges());
        // Update selection
        _.isEmpty(selectedNodes)
            ? // Trigger manually onSelectionChanged
                this._handleSelectionChanges()
            : // Update selection and trigger onSelection changed with previous subscription
                this.checklistSelection.select(...selectedNodes);
    }
    /**
     * Transform flat nodes array into a nested nodes array based on order and level
     * @param nodes Flat nodes to transform
     * @returns Nested nodes
     */
    _nestedNodes(nodes) {
        let currentLevel;
        const reducer = (accumulator, flatNode) => {
            const level = this._getLevel(flatNode);
            const node = new HsPublicationBroadcastTreeNestedItemNode(flatNode);
            let result;
            // Reach sibling's descendant
            if (currentLevel < level) {
                accumulator[level] = [node];
                result = accumulator;
            }
            // Reach sibling or parent
            else {
                // Handle children if parent reached
                if (currentLevel > level) {
                    const children = accumulator.pop() || [];
                    children.reverse();
                    Object.assign(node, { children });
                }
                const siblings = accumulator.pop() || [];
                siblings.push(node);
                result = [...accumulator, siblings];
            }
            // Update currentLevel
            currentLevel = level;
            return result;
        };
        const nestFlatNodes = () => {
            const nestedNodes = nodes.reduceRight(reducer, []);
            return _.isEmpty(nestedNodes) ? [] : nestedNodes.pop();
        };
        return _.isEmpty(nodes) ? [] : nestFlatNodes();
    }
    /* Get the parent node of a node */
    _getParentNode(node) {
        const currentLevel = this._getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (this._getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }
    /** Check root node checked state and change it accordingly */
    _checkRootNodeSelection(node) {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        }
        else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }
    /* Checks all the parents when a leaf node is selected/unselected */
    _checkAllParentsSelection(node) {
        let parent = this._getParentNode(node);
        while (parent) {
            this._checkRootNodeSelection(parent);
            parent = this._getParentNode(parent);
        }
    }
    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node) {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 &&
            descendants.every((child) => {
                return this.checklistSelection.isSelected(child);
            });
        return descAllSelected;
    }
    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node) {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }
    /** Toggle the item selection. Select/deselect all the descendants node */
    itemSelectionToggle(node) {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);
        // Force update for the parent
        descendants.forEach((child) => this.checklistSelection.isSelected(child));
        this._checkAllParentsSelection(node);
    }
    /** Toggle a leaf item selection. Check all the parents to see if they changed */
    leafItemSelectionToggle(node) {
        this.checklistSelection.toggle(node);
        this._checkAllParentsSelection(node);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsPublicationBroadcastTreeListComponent.prototype, "data", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_a = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _a : Object)
], HsPublicationBroadcastTreeListComponent.prototype, "selectionChanged", void 0);
HsPublicationBroadcastTreeListComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-broadcast-tree-list',
        template: require('./hs-publication-broadcast-tree-list.component.html').default,
        styles: ['']
    }),
    __metadata("design:paramtypes", [])
], HsPublicationBroadcastTreeListComponent);
export { HsPublicationBroadcastTreeListComponent };
