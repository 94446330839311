import { EAnswer, EAssistance, ELifePlace, ELifeStyle, ESource } from '@mapuilabs/mpl-interfaces';
export class HvInformationInterviewController {
    /** @ngInject */
    constructor(Api) {
        this.Api = Api;
        /**
         *
         * @type {ESource}
         */
        this.ESource = ESource;
        /**
         *
         * @type {EAssistance}
         */
        this.EAssistance = EAssistance;
        /**
         *
         * @type {EAnswer}
         */
        this.EAnswer = EAnswer;
        /**
         *
         * @type {ELifeStyle}
         */
        this.ELifeStyle = ELifeStyle;
        /**
         *
         * @type {ELifePlace}
         */
        this.ELifePlace = ELifePlace;
        /**
         * Save [[IPatient]]
         */
        this.save = () => {
            if (this.onSave) {
                this.onSave({});
            }
        };
    }
    $onInit() {
        this.Api.hvConstType.get((ans) => {
            this.constTypes = ans;
        });
    }
}
