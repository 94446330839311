import { locale as english } from '@app/hospistock/i18n/en';
import { locale as french } from '@app/hospistock/i18n/fr';
import { CHsDefaultFiltersAdmin, CHsPresetFiltersAdmin } from '@app/hospistock/shared/constTypes/hs-preset-filters.const';
import { CHsMedicalDeviceSort } from '@app/hospistock/shared/constTypes/hs-sort.const';
import { CHsDynamicTags, CHsDynamicTagsAdmin, CHsStaticTags, CHsStaticTagsAdmin } from '@app/hospistock/shared/constTypes/hs-tag.const';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { EAdminPanelPermission, EHsPublicationFilter, EHsUserTypes, HsPublicationAlternativeMedicalDeviceASchema, HsPublicationMedicalDeviceASchema, HsPublicationMetadataMedicalDeviceASchema } from '@mapuilabs/mpl-interfaces';
import * as _ from 'lodash';
import * as moment from 'moment';
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Service Config
// -----------------------------------------------------------------------------------------------------
const viewType = EHsUserTypes.ADMIN;
const translationBase = 'PUBLICATION.ADMIN';
// Permission for create / update / delete set to empty array to force readonly to true
const permissions = {
    create: [],
    read: EAdminPanelPermission.HsStockoutConfig_Read,
    update: [],
    delete: []
};
const types = [];
export const hsServiceConfigAdmin = {
    constructors: {
        publication: HsPublicationMedicalDeviceASchema,
        publicationAlternative: HsPublicationAlternativeMedicalDeviceASchema,
        publicationMetadata: HsPublicationMetadataMedicalDeviceASchema
    },
    endPointBase: 'api/admin/hospistock/medical-device',
    permissions,
    spreadsheetClassBuilder: null,
    viewType,
    types
};
// -----------------------------------------------------------------------------------------------------
// @ HsPublication Config
// -----------------------------------------------------------------------------------------------------
// Detail config
const detailConfig = {
    header: {
        important: false,
        seen: false,
        tags: false
    },
    healthProducts: true,
    alternatives: true,
    summary: true,
    information: true,
    files: true,
    market: true,
    broadcastList: true,
    stats: true,
    supervisor: false,
    comments: true
};
// Sorts config
const sorts = CHsMedicalDeviceSort;
// Tags config
const tags = {
    static: Object.assign(Object.assign({}, CHsStaticTags), CHsStaticTagsAdmin),
    dynamic: Object.assign(Object.assign({}, CHsDynamicTags), CHsDynamicTagsAdmin)
};
// Filters config
const filtersConfig = {
    presetFilters: [
        // ...CStockoutPresetFilters,
        ...CHsPresetFiltersAdmin
    ],
    defaultFilters: CHsDefaultFiltersAdmin,
    filtersSidebarConfig: {
        filters: [
            EHsPublicationFilter.DATE,
            EHsPublicationFilter.OPERATORS,
            EHsPublicationFilter.OTHER,
            EHsPublicationFilter.SUPERVISORS,
            EHsPublicationFilter.TAGS,
            EHsPublicationFilter.TYPES
        ],
        tags: Object.assign(Object.assign({}, tags.static), tags.dynamic),
        others: { important: true, unread: true }
    }
};
// Page Header config
const pageHeaderConfig = (translationService) => ({
    title: translationService.instant('COMMONS.WORDS.HOSPISTOCK') +
        ' ' +
        translationService.instant('COMMONS.WORDS.ADMIN') +
        ' - ' +
        translationService.instant('COMMONS.WORDS.PUBLICATIONS'),
    subtitle: translationService.instant('COMMONS.WORDS.MEDICAL_DEVICES'),
    searchBarPlaceholder: translationService.instant('PUBLICATION.SEARCH'),
    svgIcon: 'cart-remove'
});
// Toolbar config
const toolbarConfig = {
    selection: false,
    sorts,
    tags,
    switchView: false
};
// Table Config
const tableColumns = (translationService) => {
    const publicationTypeTranslations = _(CHsPublicationType)
        .mapKeys((value) => value.value)
        .mapValues((value) => translationService.instant(value.translate))
        .value();
    const formatDate = (date) => (date ? moment(date).format('ll') : null);
    const formatPublicationType = (type) => publicationTypeTranslations[type];
    return [
        {
            label: translationService.instant('COMMONS.WORDS.SOURCE'),
            attribute: 'publication.creatorType',
            sort: true
        },
        {
            label: translationService.instant('COMMONS.WORDS.TYPE'),
            attribute: 'publication.type',
            sort: true,
            transform: formatPublicationType
        },
        {
            label: translationService.instant('COMMONS.WORDS.NAME'),
            attribute: 'publication.healthProductRef.name',
            sort: true
        },
        {
            label: translationService.instant('PUBLICATION.WORDS.LABO_OPERATOR'),
            attribute: 'publication.healthProductRef.operator',
            sort: true
        },
        {
            label: translationService.instant('COMMONS.WORDS.DATE_START'),
            attribute: 'publication.startDate',
            sort: true,
            transform: formatDate
        },
        {
            label: translationService.instant('COMMONS.WORDS.DATE_END'),
            attribute: 'publication.endDate',
            sort: true,
            transform: formatDate
        }
    ];
};
export const hsPublicationConfigAdmin = (translationService, fuseTranslationLoaderService) => {
    // Load translations
    fuseTranslationLoaderService.loadTranslations(french, english);
    return {
        columns: tableColumns(translationService),
        detail: detailConfig,
        filters: filtersConfig,
        headerStats: true,
        import: null,
        list: null,
        pageHeader: pageHeaderConfig(translationService),
        toolbar: toolbarConfig,
        translationBase
    };
};
