var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
let CustomWidgetsComponent = class CustomWidgetsComponent {
    constructor(_mediaObserver) {
        this._mediaObserver = _mediaObserver;
    }
    ngOnInit() {
        this.watcher = this._mediaObserver.asObservable().subscribe(() => {
            if (this._mediaObserver.isActive('gt-md')) {
                this.columnNumber = 3;
            }
            else if (this._mediaObserver.isActive('md')) {
                this.columnNumber = 2;
            }
            else if (this._mediaObserver.isActive('lt-md')) {
                this.columnNumber = 1;
            }
            this.widths = [];
            this.flex = [];
            this.widgetList.forEach((widget, i) => {
                this.widths.push(this.computeWidgetWidth(widget));
                this.flex.push(this.computeWidgetFlex(widget, i));
            });
        });
    }
    ngOnDestroy() {
        this.watcher.unsubscribe();
    }
    computeWidgetWidth(widget) {
        switch (widget.width) {
            case 1:
                return this.columnNumber === 1 ? 100 : this.columnNumber === 2 ? 50 : 33;
            case 2:
                return this.columnNumber === 3 ? 66 : 100;
            case 3:
                return 100;
        }
    }
    computeWidgetFlex(widget, index) {
        switch (widget.width) {
            case 2:
                return this.columnNumber === 3
                    ? index % 2 === 0 && index < this.widgetList.length - 1
                        ? '2 0 auto'
                        : '0 0 auto'
                    : '0 0 auto';
            default:
                return '0 0 auto';
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", Array)
], CustomWidgetsComponent.prototype, "widgetList", void 0);
CustomWidgetsComponent = __decorate([
    Component({
        selector: 'mpx-custom-widgets',
        template: require('./custom-widgets.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof MediaObserver !== "undefined" && MediaObserver) === "function" ? _a : Object])
], CustomWidgetsComponent);
export { CustomWidgetsComponent };
