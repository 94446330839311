import { HospivilleService } from '../main/hospiville/services/hospiville.service';
import { Auth } from '../main/services/auth/auth.service';
import { ExternalActorService } from '../main/services/external-actors/external-actor.service';
import { ExternalConfigurationService } from '../main/services/external-actors/external-configuration.service';
import { HospitalService } from '../main/services/hospital/hospital.service';
import { Api } from '../index.api.ajs';
import { DrugInstanceService } from '../main/hospiville/services/drugInstance/drugInstance.service';
import { MemberService } from '../main/services/member/member.service';
import { PubSubService } from '../main/services/pub-sub/pub-sub.service';
import { AdminService } from '../main/services/admin/admin.service';
import { Access } from '../main/services/auth/access.service';
import { DashboardService } from '../main/services/dashboard/dashboard.service';
import { QuickPanelService } from '../layout/vertical-navigation/quick-panel/quick-panel.service';
import { NotificationService } from '../main/services/notification/notification.service';
import { ToolbarService } from '../layout/hospiville/toolbar/toolbar.service';
import { ModalService } from '@main/services/modal/modal.service';
export const hospivilleServiceProvider = {
    provide: HospivilleService,
    useFactory: (i) => i.get('HospivilleService'),
    deps: ['$injector']
};
export const AuthUpgrade = {
    provide: Auth,
    useFactory: (i) => i.get('Auth'),
    deps: ['$injector']
};
export const AccessUpgrade = {
    provide: Access,
    useFactory: (i) => i.get('Access'),
    deps: ['$injector']
};
export const ApiUpgrade = {
    provide: Api,
    useFactory: (i) => i.get('Api'),
    deps: ['$injector']
};
export const ExternalActorServiceUpgrade = {
    provide: ExternalActorService,
    useFactory: (i) => i.get('ExternalActorService'),
    deps: ['$injector']
};
export const ExternalConfigurationServiceUpgrade = {
    provide: ExternalConfigurationService,
    useFactory: (i) => i.get('ExternalConfigurationService'),
    deps: ['$injector']
};
export const HospitalServiceUpgrade = {
    provide: HospitalService,
    useFactory: (i) => i.get('HospitalService'),
    deps: ['$injector']
};
export const DrugInstanceServiceUpgrade = {
    provide: DrugInstanceService,
    useFactory: (i) => i.get('DrugInstanceService'),
    deps: ['$injector']
};
export const MemberServiceUpgrade = {
    provide: MemberService,
    useFactory: (i) => i.get('MemberService'),
    deps: ['$injector']
};
export const PubSubServiceUpgrade = {
    provide: PubSubService,
    useFactory: (i) => i.get('PubSubService'),
    deps: ['$injector']
};
export const AdminServiceUpgrade = {
    provide: AdminService,
    useFactory: (i) => i.get('AdminService'),
    deps: ['$injector']
};
export const DashboardServiceUpgrade = {
    provide: DashboardService,
    useFactory: (i) => i.get('DashboardService'),
    deps: ['$injector']
};
export const QuickPanelServiceUpgrade = {
    provide: QuickPanelService,
    useFactory: (i) => i.get('QuickPanelService'),
    deps: ['$injector']
};
export const NotificationServiceUpgrade = {
    provide: NotificationService,
    useFactory: (i) => i.get('NotificationService'),
    deps: ['$injector']
};
export const ToolbarServiceUpgrade = {
    provide: ToolbarService,
    useFactory: (i) => i.get('ToolbarService'),
    deps: ['$injector']
};
export const ModalServiceUpgrade = {
    provide: ModalService,
    useFactory: (i) => i.get('ModalService'),
    deps: ['$injector']
};
