import { EMpStatus } from '@mapuilabs/mpl-interfaces';
import * as angular from 'angular';
export class HvModalSelectDropController {
    /** @ngInject */
    constructor(ModalService) {
        this.ModalService = ModalService;
        /**
         * Close modal without acions performing
         */
        this.cancel = () => {
            if (!this.dropInfo)
                this.dropInfo = {};
            if (!this.dropInfo.left)
                this.dropInfo.left = 0;
            if (!this.dropInfo.right)
                this.dropInfo.right = 0;
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        /**
         * Close the modal with actions performing
         */
        this.ok = () => {
            if (!this.dropInfo.left)
                this.dropInfo.left = 0;
            if (!this.dropInfo.right)
                this.dropInfo.right = 0;
            this.ModalService.close(this.dropInfo);
        };
    }
    $onInit() {
    }
    $onChanges(changes) {
        if (changes.dropInfo) {
            this.dropInfo = angular.copy(this.dropInfo);
        }
    }
}
