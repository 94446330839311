export class mpTabs {
    /** @ngInject */
    constructor() {
        this.compile = (tElement) => {
            tElement.addClass('mp-tabs');
            return function postLink(scope, iElement, iAttrs, ctrls) {
                const mpTabsCtrl = ctrls[0];
                mpTabsCtrl.setupTabs();
            };
        };
        this.restrict = 'AE';
        this.scope = {};
        this.priority = 1001;
        this.transclude = true;
        this.template = require('./templates/tabs.html').default;
        this.require = ['mpTabs'];
        this.controller = 'MpTabsController as vm';
    }
    static factory() {
        const directive = () => new mpTabs();
        directive.$inject = [];
        return directive;
    }
}
