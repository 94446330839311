export const pharmacistRsp = {
    RSP_DSP: {
        name: '',
        value: 'RSP_DSP',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.DSP'
    },
    RSP_STR: {
        value: 'RSP_STR',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.STR'
    },
    RSP_PRI: {
        name: 'Computed prescription',
        value: 'RSP_PRI',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.PRI'
    },
    RSP_PHC: {
        name: 'Clinical pharmacy',
        value: 'RSP_PHC',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.PHC'
    },
    RSP_LOG: {
        name: 'Logistic',
        value: 'RSP_LOG',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.LOG'
    },
    RSP_VGL: {
        name: 'Vigilance',
        value: 'RSP_VGL',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.VGL'
    },
    RSP_CMD: {
        name: '',
        value: 'RSP_CMD',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.CMD'
    },
    RSP_ACM: {
        name: 'Drugs purchasing',
        value: 'RSP_ACM',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.ACM'
    },
    RSP_ACD: {
        name: 'Medical devices purchasing',
        value: 'RSP_ACD',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.ACD'
    },
    RSP_ESC: {
        name: 'Clinical trials',
        value: 'RSP_ESC',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.ESC'
    },
    RSP_CHI: {
        name: 'Chemotherapy',
        value: 'RSP_CHI',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.CHI'
    },
    RSP_NUP: {
        name: 'Parenteral nutrition',
        value: 'RSP_NUP',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.NUP'
    },
    RSP_PRH: {
        name: 'Hospital preparations',
        value: 'RSP_PRH',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.PRH'
    },
    RSP_PRM: {
        name: 'Masterful preparations',
        value: 'RSP_PRM',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.PRM'
    },
    RSP_HYG: {
        name: 'Hygiene',
        value: 'RSP_HYG',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.HYG'
    },
    RSP_GAZ: {
        name: 'Medical gases',
        value: 'RSP_GAZ',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.GAZ'
    },
    RSP_COM: {
        name: 'COMEDIMS',
        value: 'RSP_COM',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.COM'
    },
    RSP_CLU: {
        name: 'CLUD',
        value: 'RSP_CLU',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.CLU'
    },
    RSP_CLA: {
        name: 'CLAN',
        value: 'RSP_CLA',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.CLA'
    },
    RSP_CLI: {
        name: 'CLIN',
        value: 'RSP_CLI',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.CLI'
    },
    RSP_RET: {
        name: 'Retrocessions',
        value: 'RSP_RET',
        translate: 'MAPUI.GENERAL.CONST_TYPE.PHARMACIST.RSP.RET'
    }
};
