export const locale = {
    lang: `en`,
    data: {
        MAP_DETAIL_SIDEBAR: {
            DETAILS: {
                TITLE: `Details`,
                ADDRESS: `Address`,
                PHONE: `Phones and fax`,
                PHONE_SCT: `Phone Pharmacy Secretariat:`,
                PHONE_STD: `Phone Standard Hospital:`,
                FAX: `Fax:`,
                SEE_AVAILABLE: 'See detail',
                SEE_MORE: 'Voir plus'
            },
            PHARMACIST: `Pharmacists`,
            PREPARATOR: `Preparators`,
            LOANS: {
                TITLE_AVAILABLE: `Available`,
                TITLE_REQUEST: `Request`,
                EXPIRY_DATE: `Expiry date:`,
                QUANTITY: `Quantity:`
            },
            SNACKBAR: `The element has been updated.`
        }
    }
};
