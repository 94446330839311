var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UIRouterModule } from '@uirouter/angular';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from '../../../shared/shared.module';
import { HospitalDetailComponent } from './stats-hospiville/hospital-detail/hospital-detail.component';
import { MedRecWidgetComponent } from './stats-hospiville/med-rec-widget/med-rec-widget.component';
import { StatsHospivilleComponent } from './stats-hospiville/stats-hospiville.component';
import { StatsHvHospitalTableComponent } from './stats-hospiville/stats-hv-hospital-table/stats-hv-hospital-table.component';
let StatsHospivilleModule = class StatsHospivilleModule {
};
StatsHospivilleModule = __decorate([
    NgModule({
        declarations: [
            StatsHospivilleComponent,
            StatsHvHospitalTableComponent,
            MedRecWidgetComponent,
            HospitalDetailComponent
        ],
        imports: [
            BrowserAnimationsModule,
            CommonModule,
            FuseSharedModule,
            FuseWidgetModule,
            MatButtonModule,
            MatCardModule,
            MatIconModule,
            MatInputModule,
            MatMenuModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatRippleModule,
            MatSelectModule,
            MatSortModule,
            MatTableModule,
            NgScrollbarModule,
            NgxChartsModule,
            SharedModule,
            UIRouterModule
        ],
        providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
    })
], StatsHospivilleModule);
export { StatsHospivilleModule };
