var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from '@angular/core';
import { EHsHealthProductTypes } from '@mapuilabs/mpl-interfaces';
import { UIRouter } from '@uirouter/angularjs';
const HEALTH_PRODUCT_REF_TYPE_ERROR = new Error('Unknown EHsHealthProductType value');
let HsHealthProductService = class HsHealthProductService {
    constructor(_route) {
        this._route = _route;
        // State
        const currentState = this._route.globals.current;
        // Config
        const serviceConfig = currentState.data.servicesConfig;
        this.healthProductType = serviceConfig.healthProduct.healthProductType;
    }
    isDrugRef(healthProductRef) {
        return this.healthProductType === EHsHealthProductTypes.DRUG;
    }
    isDrug(healthProduct) {
        return this.healthProductType === EHsHealthProductTypes.DRUG;
    }
    isMedicalDeviceRef(healthProductRef) {
        return this.healthProductType === EHsHealthProductTypes.MEDICAL_DEVICE;
    }
    isMedicalDevice(healthProduct) {
        return this.healthProductType === EHsHealthProductTypes.MEDICAL_DEVICE;
    }
    handleHealthProductRef(drugHandler, medicalDeviceHandler, healthProductRef) {
        if (this.isDrugRef(healthProductRef)) {
            return drugHandler(healthProductRef);
        }
        else if (this.isMedicalDeviceRef(healthProductRef)) {
            return medicalDeviceHandler(healthProductRef);
        }
        else {
            throw HEALTH_PRODUCT_REF_TYPE_ERROR;
        }
    }
    handleHealthProduct(drugHandler, medicalDeviceHandler, healthProduct) {
        if (this.isDrug(healthProduct)) {
            return drugHandler(healthProduct);
        }
        else if (this.isMedicalDeviceRef(healthProduct)) {
            return medicalDeviceHandler(healthProduct);
        }
        else {
            throw HEALTH_PRODUCT_REF_TYPE_ERROR;
        }
    }
};
HsHealthProductService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [typeof (_a = typeof UIRouter !== "undefined" && UIRouter) === "function" ? _a : Object])
], HsHealthProductService);
export { HsHealthProductService };
