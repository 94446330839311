var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Component, EventEmitter, Input, Output } from '@angular/core';
let ResponsiveBtnComponent = class ResponsiveBtnComponent {
    constructor() {
        this.onClick = new EventEmitter();
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ResponsiveBtnComponent.prototype, "text", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ResponsiveBtnComponent.prototype, "icon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ResponsiveBtnComponent.prototype, "svgIcon", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ResponsiveBtnComponent.prototype, "color", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_a = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _a : Object)
], ResponsiveBtnComponent.prototype, "onClick", void 0);
ResponsiveBtnComponent = __decorate([
    Component({
        selector: 'responsive-btn',
        template: `
        <button fxShow fxHide.lt-md mat-raised-button [color]="color" (click)="onClick.emit()" class="cap">
            <span>{{ text }}</span>
        </button>

        <button mat-mini-fab fxHide fxShow.lt-md [color]="color" (click)="onClick.emit()" class="mat-elevation-z2">
            <mat-icon [svgIcon]="svgIcon">{{ icon }}</mat-icon>
        </button>
    `,
        styles: ['']
    }),
    __metadata("design:paramtypes", [])
], ResponsiveBtnComponent);
export { ResponsiveBtnComponent };
