var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var UpperCaseInputDirective_1;
var _a, _b, _c;
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
let UpperCaseInputDirective = UpperCaseInputDirective_1 = class UpperCaseInputDirective extends DefaultValueAccessor {
    input($event) {
        const target = $event.target;
        const start = target.selectionStart;
        target.value = target.value.toUpperCase();
        target.setSelectionRange(start, start);
        this.onChange(target.value);
    }
    constructor(renderer, elementRef) {
        super(renderer, elementRef, false);
    }
};
__decorate([
    HostListener('input', ['$event']),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_c = typeof InputEvent !== "undefined" && InputEvent) === "function" ? _c : Object]),
    __metadata("design:returntype", void 0)
], UpperCaseInputDirective.prototype, "input", null);
UpperCaseInputDirective = UpperCaseInputDirective_1 = __decorate([
    Directive({
        selector: 'input[toUpperCase]',
        providers: [
            {
                provide: NG_VALUE_ACCESSOR,
                multi: true,
                useExisting: forwardRef(() => UpperCaseInputDirective_1)
            }
        ]
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof Renderer2 !== "undefined" && Renderer2) === "function" ? _a : Object, typeof (_b = typeof ElementRef !== "undefined" && ElementRef) === "function" ? _b : Object])
], UpperCaseInputDirective);
export { UpperCaseInputDirective };
