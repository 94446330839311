var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, EventEmitter, Input, Output } from '@angular/core';
let PermissionInputComponent = class PermissionInputComponent {
    constructor() {
        this.isEditing = false;
        this.inputType = 'text';
        this.dataChange = new EventEmitter();
    }
    onKeyUp() {
        this.dataChange.emit(this.data);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], PermissionInputComponent.prototype, "isEditing", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PermissionInputComponent.prototype, "label", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], PermissionInputComponent.prototype, "data", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], PermissionInputComponent.prototype, "inputType", void 0);
__decorate([
    Output(),
    __metadata("design:type", Object)
], PermissionInputComponent.prototype, "dataChange", void 0);
PermissionInputComponent = __decorate([
    Component({
        selector: 'mpx-permission-input',
        template: require('./permission-input.component.html').default,
        styles: [require('./permission-input.component.scss')]
    })
], PermissionInputComponent);
export { PermissionInputComponent };
