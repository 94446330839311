import { regExpCladimed, regExpDecimalNumber, regExpNumber } from '@shared/constTypes/reg-exp.const';
export class CustomValidators {
    static ucd7or13(control) {
        // Guard value is null
        if (!control.value) {
            return null;
        }
        const UCD_7_REGEXP = /^[0-9]{7}$/i;
        const UCD_13_REGEXP = /^[0-9]{13}$/i;
        const ucd7 = UCD_7_REGEXP.test(control.value);
        const ucd13 = UCD_13_REGEXP.test(control.value);
        return ucd7 || ucd13 ? null : { ucd7or13: { ucd7, ucd13 } };
    }
    static ucd7(control) {
        // Guard value is null
        if (!control.value) {
            return null;
        }
        const UCD_7_REGEXP = /^[0-9]{7}$/i;
        const ucd7 = UCD_7_REGEXP.test(control.value);
        return ucd7 ? null : { ucd7 };
    }
    static ucd13(control) {
        // Guard value is null
        if (!control.value) {
            return null;
        }
        const UCD_13_REGEXP = /^[0-9]{13}$/i;
        const ucd13 = UCD_13_REGEXP.test(control.value);
        return ucd13 ? null : { ucd13 };
    }
    static number(control) {
        // Guard value is null
        if (!control.value) {
            return null;
        }
        return !regExpNumber.test(control.value) ? { number: true } : null;
    }
    /**
     *
     * Check if the control is a decimal number
     * @param control
     */
    static decimalNumber(control) {
        // Guard value is null
        if (!control.value) {
            return null;
        }
        return !regExpDecimalNumber.test(control.value) ? { decimalNumber: true } : null;
    }
    static cladimed(control) {
        // Guard value is null
        if (!control.value) {
            return null;
        }
        const isCladimed = !regExpCladimed.test(control.value);
        return isCladimed ? { cladimed: true } : null;
    }
    /**
     * @description
     * Validator that requires the control have a truthy value.
     *
     * @usageNotes
     *
     * ### Validate that the field is truthy
     *
     * ```typescript
     * const control = new FormControl('', Validators.truthy);
     *
     * console.log(control.errors); // {truthy: true}
     * ```
     *
     * @returns An error map with the `truthy` property
     * if the validation check fails, otherwise `null`.
     *
     * @see `updateValueAndValidity()`
     *
     */
    static truthy(control) {
        return !control.value ? { truthy: true } : null;
    }
}
