var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c, _d;
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Output } from '@angular/core';
import { DOCUMENT_CLICK } from '@core/tokens/events/document-click.token';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
let CompactEditMenuComponent = class CompactEditMenuComponent {
    constructor(documentClick$, _changeDetectorRef) {
        this.documentClick$ = documentClick$;
        this._changeDetectorRef = _changeDetectorRef;
        this.edit = new EventEmitter();
        this.delete = new EventEmitter();
        // Init flags
        this.isAnimationActive = false;
        this.showEditMenu = false;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Open edit menu by setting showEditMenu to true to trigger #editMenuTemplate.
     * Method must wait next document click event before showing edit menu.
     * Reason: Click event from document is fired with delay and trigger clickOutsideDirective's clickOutside event causing the menu to be closed.
     * Recommendation : Do not use preventDefault instead otherwise edit menu will not be closed when click event is fired on other comment's menu button
     */
    openEditMenu() {
        // Wait next click event
        this.documentClick$.pipe(take(1)).subscribe(() => {
            // Open edit menu
            this.showEditMenu = true;
            // Manually update
            this._changeDetectorRef.detectChanges();
        });
    }
    /**
     * Close edit menu by setting showEditMenu to false to trigger #editMenuTemplate.
     */
    closeEditMenu() {
        // Close edit menu
        this.showEditMenu = false;
    }
};
__decorate([
    Output(),
    __metadata("design:type", typeof (_c = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _c : Object)
], CompactEditMenuComponent.prototype, "edit", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_d = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _d : Object)
], CompactEditMenuComponent.prototype, "delete", void 0);
CompactEditMenuComponent = __decorate([
    Component({
        selector: 'mpx-compact-edit-menu',
        template: require('./compact-edit-menu.component.html').default,
        animations: [
            // Custom animation to use 200ms with In & Out transitions
            trigger('slideInOutRight', [
                state('void', style({ transform: 'translateX(100%)' })),
                state('*', style({ transform: 'translateX(0)' })),
                transition('void => *', animate('200ms')),
                transition('* => void', animate('200ms'))
            ])
        ],
        changeDetection: ChangeDetectionStrategy.OnPush
    }),
    __param(0, Inject(DOCUMENT_CLICK)),
    __metadata("design:paramtypes", [typeof (_a = typeof Observable !== "undefined" && Observable) === "function" ? _a : Object, typeof (_b = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _b : Object])
], CompactEditMenuComponent);
export { CompactEditMenuComponent };
