var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CHsAdvancedFilterSettings } from '@app/hospistock/shared/constTypes/hs-tag.const';
import { CHsPublicationType } from '@app/hospistock/shared/constTypes/publication/hs-publication-type.const';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { Utils } from '@main/services/Utils';
import { EFilterType, EHsHealthProductTypes, EHsPublicationFilter } from '@mapuilabs/mpl-interfaces';
import * as _ from 'lodash';
import { lastValueFrom, merge, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { locale as english } from '../../../../i18n/en';
import { locale as french } from '../../../../i18n/fr';
import { HsPublicationContainerService } from '../../../services/hs-publication-container.service';
let HsPublicationFiltersSidebarComponent = class HsPublicationFiltersSidebarComponent {
    constructor(_fuseSidebarService, _fuseTranslationLoaderService, _publicationContainerService, _healthProductService, _fb) {
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._publicationContainerService = _publicationContainerService;
        this._healthProductService = _healthProductService;
        this._fb = _fb;
        this.types = CHsPublicationType;
        this.advancedFilterSettings = CHsAdvancedFilterSettings;
        this.eHsHealthProductTypes = EHsHealthProductTypes;
        this.eHsPublicationFilter = EHsPublicationFilter;
        // Pipe keyvalue : comparer to order by ascending property value
        this.orderByRank = (a, b) => {
            return a.value.rank - b.value.rank;
        };
        this.operatorsLoaded = false;
        this.operatorGroups = [];
        this.supervisorsLoaded = false;
        this.supervisorGroups = [];
        this._filterSearch = (opt, value) => {
            const filterValue = value.toLowerCase();
            return opt.filter((item) => item.label.toLowerCase().includes(filterValue));
        };
        this._filterSelected = (opt, selected) => {
            return opt.filter((item) => !selected.find((selectedItem) => _.isEqual(selectedItem, item)));
        };
        // Load translations
        this._fuseTranslationLoaderService.loadTranslations(french, english);
        // Init Subjects
        this._subscriptionNotifier = new Subject();
        // Init Events
        this.filtersApplied = new EventEmitter();
        this.filterCount = new EventEmitter();
    }
    get operatorsFormArray() {
        return this.form.get(EHsPublicationFilter.OPERATORS);
    }
    get supervisorsFormArray() {
        return this.form.get(EHsPublicationFilter.SUPERVISORS);
    }
    get isPfizerOperator() {
        return this._publicationContainerService.isPfizerOperator;
    }
    get healthProductType() {
        return this._healthProductService.healthProductType;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnChanges(changes) {
        if ('applyFilters' in changes) {
            if (this.form) {
                this._setFormControls();
            }
        }
    }
    ngOnInit() {
        // Default config
        if (!this.config.filters) {
            this.config.filters = Object.values(EHsPublicationFilter);
        }
        // Generate publicationFilters
        this.publicationFilters = Object.assign({}, ...this.config.filters.map((key) => ({ [key]: true })));
        // Init form
        this._initForm();
        // Save form value records
        this._formRecordDefault = this.form.value;
        this._formRecordLast = this.form.value;
        // Init changesCount based on formGroups
        this.changesCount = Object.keys(this._formRecordDefault).reduce((a, key) => Object.assign(a, { [key]: 0 }), {});
        // Update changesCount and emit filterCount
        this.form.valueChanges.pipe(takeUntil(this._subscriptionNotifier)).subscribe((changes) => {
            Object.keys(changes).forEach((formGroupName) => {
                this.changesCount[formGroupName] = this._getChangesCount(formGroupName);
            });
            this._updateFilterCount();
        });
        if (this.publicationFilters[EHsPublicationFilter.OPERATORS]) {
            this._refreshListOperators();
        }
        if (this.publicationFilters[EHsPublicationFilter.SUPERVISORS]) {
            this._refreshListSupervisors();
        }
    }
    ngOnDestroy() {
        // Unsubscribe all subscriptions
        this._subscriptionNotifier.next();
        // Unsubscribe subscriptionNotifier
        this._subscriptionNotifier.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    _refreshListOperators() {
        return __awaiter(this, void 0, void 0, function* () {
            const listOperators = yield lastValueFrom(this._publicationContainerService.getOperatorsList());
            this.operatorGroups = this._groupByFirstLetter(listOperators.filter((operator) => !!operator).map((operator) => ({ label: operator })));
            this.operatorsLoaded = true;
            this._setFormControls();
            // Update operatorGroupOptions on autocomplete and operatorsFormArray change
            this.operatorGroupOptions = merge(this.operatorAutocompleteFormControl.valueChanges.pipe(startWith(''), map((search) => {
                if (_.isString(search)) {
                    return this._filterGroup(this.operatorGroups, this.operatorsFormArray.value, search);
                }
            })), this.operatorsFormArray.valueChanges.pipe(map((operators) => this._filterGroup(this.operatorGroups, operators))));
        });
    }
    _refreshListSupervisors() {
        return __awaiter(this, void 0, void 0, function* () {
            const listSupervisors = yield lastValueFrom(this._publicationContainerService.getSupervisorsList());
            this.supervisorGroups = this._groupByFirstLetter(listSupervisors
                .filter((supervisor) => !!supervisor)
                .map((supervisor) => ({ label: supervisor.fullName, id: Utils.getId(supervisor) }))
                .filter((supervisor) => !!supervisor.label));
            this.supervisorsLoaded = true;
            this._setFormControls();
            // Update supervisorsGroupOptions on autocomplete and supervisorsFormArray change
            this.supervisorGroupOptions = merge(this.supervisorAutocompleteFormControl.valueChanges.pipe(startWith(''), map((search) => {
                if (_.isString(search)) {
                    return this._filterGroup(this.supervisorGroups, this.supervisorsFormArray.value, search);
                }
            })), this.supervisorsFormArray.valueChanges.pipe(map((supervisors) => this._filterGroup(this.supervisorGroups, supervisors))));
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Autocompletes
    // -----------------------------------------------------------------------------------------------------
    _groupByFirstLetter(list) {
        const groups = [];
        list.forEach((item) => {
            var _a;
            const letter = (_a = item.label) === null || _a === void 0 ? void 0 : _a[0];
            let group = groups.find((group) => group.letter === letter);
            if (!group) {
                group = { letter, options: [] };
                groups.push(group);
            }
            group.options.push(item);
        });
        return groups;
    }
    _filterGroup(groups, operators, search) {
        let _groups = groups;
        // Filter selected operators
        if (operators && operators.length > 0) {
            _groups = _groups
                .map((operatorGroup) => ({
                letter: operatorGroup.letter,
                options: this._filterSelected(operatorGroup.options, operators)
            }))
                .filter((group) => group.options.length > 0);
        }
        // Filter with search entry
        if (search) {
            _groups = _groups
                .map((group) => ({ letter: group.letter, options: this._filterSearch(group.options, search) }))
                .filter((group) => group.options.length > 0);
        }
        return _groups;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Event handlers
    // -----------------------------------------------------------------------------------------------------
    onOpenChanged(opened) {
        if (!opened) {
            this._resetFormToLast();
        }
        else {
            if (this.publicationFilters[EHsPublicationFilter.OPERATORS]) {
                this._refreshListOperators();
            }
            if (this.publicationFilters[EHsPublicationFilter.SUPERVISORS]) {
                this._refreshListSupervisors();
            }
        }
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Form
    // -----------------------------------------------------------------------------------------------------
    _initForm() {
        this.operatorAutocompleteFormControl = this._fb.control('');
        this.supervisorAutocompleteFormControl = this._fb.control('');
        this.form = this._fb.group({
            [EHsPublicationFilter.TAGS]: this._fb.group(Object.assign({}, ...Object.keys(this.config.tags).map((key) => ({
                [key]: false
            })))),
            [EHsPublicationFilter.DATE]: this._fb.group({
                [EHsPublicationFilter.END_DATE]: null,
                [EHsPublicationFilter.START_DATE]: null,
                [EHsPublicationFilter.TERMINATED]: false
            }),
            [EHsPublicationFilter.TYPES]: this._fb.group(Object.assign({}, ...Object.keys(this.types).map((key) => ({ [key]: false })))),
            [EHsPublicationFilter.OPERATORS]: this._fb.array([]),
            [EHsPublicationFilter.OTHER]: this._fb.group({
                [EHsPublicationFilter.IMPORTANT]: false,
                [EHsPublicationFilter.UNREAD]: false
            }),
            [EHsPublicationFilter.SUPERVISORS]: this._fb.array([])
        });
    }
    _getUrlFilter(filterAttribute, opt) {
        if (!this.applyFilters || this.applyFilters.length === 0) {
            return null;
        }
        const filter = this.applyFilters.find((filter) => filter.attribute === filterAttribute);
        if (filter) {
            switch (filter.type) {
                case EFilterType.VALUE:
                    return filter.value;
                case EFilterType.VALUES:
                    return opt ? filter.values.includes(opt) : filter.values;
                case EFilterType.RANGE:
                    return filter;
            }
        }
    }
    _getAdvancedFilterValueFromUrl(attribute) {
        const urlFilterValue = this._getUrlFilter(attribute);
        const buildFilterValue = () => {
            // Find corresponding advancedFilterSetting
            const advancedFilterSetting = Object.values(this.advancedFilterSettings).find((filterSetting) => filterSetting.value === urlFilterValue);
            if (!advancedFilterSetting) {
                throw new Error('Cannot get filter from url, value is invalid');
            }
            // Apply value to form
            return advancedFilterSetting;
        };
        return _.isBoolean(urlFilterValue) ? buildFilterValue() : null;
    }
    _applyAdvancedFilterValueToForm(control, attribute) {
        const tagAdvancedFilterValue = this._getAdvancedFilterValueFromUrl(attribute);
        if (tagAdvancedFilterValue) {
            control.get(attribute).setValue(tagAdvancedFilterValue);
        }
    }
    _setFormControls() {
        // Reset form
        this._resetFormToDefault();
        // ==================================================
        // Set Tags URL fitler values
        const tagsFormGroup = this.form.get(EHsPublicationFilter.TAGS);
        Object.keys(this.config.tags).forEach((tag) => this._applyAdvancedFilterValueToForm(tagsFormGroup, tag));
        // ==================================================
        // Set Dates URL fitler values
        const datesFormGroup = this.form.get(EHsPublicationFilter.DATE);
        // Terminated
        this._applyAdvancedFilterValueToForm(datesFormGroup, EHsPublicationFilter.TERMINATED);
        // Period
        const urlFilterPeriod = this._getUrlFilter(EHsPublicationFilter.PERIOD);
        if (urlFilterPeriod === null || urlFilterPeriod === void 0 ? void 0 : urlFilterPeriod.start) {
            datesFormGroup.get(EHsPublicationFilter.START_DATE).setValue(urlFilterPeriod.start);
        }
        if (urlFilterPeriod === null || urlFilterPeriod === void 0 ? void 0 : urlFilterPeriod.end) {
            datesFormGroup.get(EHsPublicationFilter.END_DATE).setValue(urlFilterPeriod.end);
        }
        // ==================================================
        // Set Types URL fitler values
        const typesFormGroup = this.form.get(EHsPublicationFilter.TYPES);
        Object.values(this.types).forEach((type) => {
            const urlFilterType = this._getUrlFilter(EHsPublicationFilter.TYPES, type.value);
            if (urlFilterType) {
                typesFormGroup.get(type.name).setValue(urlFilterType);
            }
        });
        // ==================================================
        // Set operators URL fitler values
        const urlFilterOperators = this._getUrlFilter(EHsPublicationFilter.OPERATORS);
        if (this.operatorsLoaded && urlFilterOperators) {
            this.operatorsFormArray.clear();
            urlFilterOperators.forEach((operator) => {
                this.operatorsFormArray.push(this._fb.control({ label: operator }));
            });
        }
        // ==================================================
        // Set Others URL fitler values
        const othersFormGroup = this.form.get(EHsPublicationFilter.OTHER);
        // Important
        const urlFilterImportant = this._getUrlFilter(EHsPublicationFilter.IMPORTANT);
        if (urlFilterImportant) {
            othersFormGroup.get(EHsPublicationFilter.IMPORTANT).setValue(urlFilterImportant);
        }
        // Unread
        const urlFilterSeen = this._getUrlFilter(EHsPublicationFilter.UNREAD);
        if (urlFilterSeen) {
            othersFormGroup.get(EHsPublicationFilter.UNREAD).setValue(urlFilterSeen);
        }
        // ==================================================
        // Set Supervisors URL fitler values
        const urlFilterSupervisors = this._getUrlFilter(EHsPublicationFilter.SUPERVISORS);
        if (this.supervisorsLoaded && urlFilterSupervisors) {
            this.supervisorsFormArray.clear();
            urlFilterSupervisors.forEach((supervisorId) => {
                const supervisor = this.supervisorGroups
                    .flatMap((supervisorGroup) => supervisorGroup.options)
                    .find((supervisor) => supervisor.id === supervisorId);
                if (supervisor) {
                    this.supervisorsFormArray.push(this._fb.control(supervisor));
                }
                else {
                    throw new Error("Cannot find supervisor with id '" + supervisorId + "' in supervisors retrieved from server");
                }
            });
        }
        // Update last form record
        this._formRecordLast = this.form.value;
    }
    _getChangesCount(formGroupName) {
        const formGroupValues = this.form.value[formGroupName];
        const formGroupRecordDefaultValues = this._formRecordDefault[formGroupName];
        const res = _.reduce(formGroupValues, function (result, value, key) {
            return _.isEqual(value, formGroupRecordDefaultValues[key]) ? result : result.concat(key);
        }, []);
        return res.length;
    }
    _updateFilterCount() {
        // Calculate filters total
        const filterCount = Object.values(this.changesCount).reduce((accumulator, currentValue) => accumulator + currentValue);
        // Emit value in filterCount output
        this.filterCount.emit(filterCount);
    }
    _applyFilterDate() {
        const datesFormGroup = this.form.get(EHsPublicationFilter.DATE);
        const periodFilter = {
            type: EFilterType.RANGE,
            attribute: EHsPublicationFilter.PERIOD
        };
        const startDateValue = datesFormGroup.get(EHsPublicationFilter.START_DATE).value;
        if (startDateValue) {
            periodFilter.start = startDateValue;
        }
        const endDateValue = datesFormGroup.get(EHsPublicationFilter.END_DATE).value;
        if (endDateValue) {
            periodFilter.end = endDateValue;
        }
        return periodFilter;
    }
    _applyFilterGroup(filters, value, name) {
        const index = filters.findIndex((filter) => filter.attribute === name);
        if (index === -1) {
            filters.push({
                type: EFilterType.VALUES,
                attribute: name,
                inArray: true,
                values: [value]
            });
        }
        else {
            filters[index].values.push(value);
        }
    }
    onOptionSelected(formArray, formControl, operator) {
        formControl.reset();
        formArray.push(this._fb.control(operator));
    }
    onRemoveSelected(formArray, index) {
        formArray.removeAt(index);
    }
    isFormDefaultValue() {
        return _.isEqual(this._formRecordDefault, this.form.value);
    }
    isFormLastValue() {
        return _.isEqual(this._formRecordLast, this.form.value);
    }
    _getAdvancedFilterValueFromForm(control, attribute) {
        const formValue = control.get(attribute).value;
        const buildFilterValue = () => ({
            type: EFilterType.VALUE,
            attribute,
            value: formValue.value
        });
        return formValue ? buildFilterValue() : null;
    }
    _applyAdvancedFilterValue(filters, control, attribute) {
        const filterValue = this._getAdvancedFilterValueFromForm(control, attribute);
        if (filterValue) {
            filters.push(filterValue);
        }
    }
    /**
     * Sends the filters to the list view so that it can update.
     */
    onApplyFilters(replaceHistory = false) {
        var _a, _b;
        const filters = [];
        // ==================================================
        // Apply Tags filters
        const tagsFormGroup = this.form.get(EHsPublicationFilter.TAGS);
        Object.keys(this.config.tags).forEach((tag) => this._applyAdvancedFilterValue(filters, tagsFormGroup, tag));
        // ==================================================
        // Apply Dates filters
        const datesFormGroup = this.form.get(EHsPublicationFilter.DATE);
        // Terminated
        this._applyAdvancedFilterValue(filters, datesFormGroup, EHsPublicationFilter.TERMINATED);
        // Period
        if (datesFormGroup.get(EHsPublicationFilter.START_DATE).value ||
            datesFormGroup.get(EHsPublicationFilter.END_DATE).value) {
            filters.push(this._applyFilterDate());
        }
        // ==================================================
        // Apply Types filters
        const typesFormGroup = this.form.get(EHsPublicationFilter.TYPES);
        Object.values(this.types).forEach((type) => {
            if (typesFormGroup.get(type.name).value === true) {
                this._applyFilterGroup(filters, type.value, EHsPublicationFilter.TYPES);
            }
        });
        // ==================================================
        // Apply Operators filters
        if (((_a = this.operatorsFormArray.value) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            filters.push({
                type: EFilterType.VALUES,
                attribute: EHsPublicationFilter.OPERATORS,
                inArray: true,
                values: this.operatorsFormArray.value.map((value) => value.label)
            });
        }
        // ==================================================
        // Apply Others filters
        const othersFormGroup = this.form.get(EHsPublicationFilter.OTHER);
        // Important
        if (othersFormGroup.get(EHsPublicationFilter.IMPORTANT).value === true) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: EHsPublicationFilter.IMPORTANT,
                value: true
            });
        }
        // Not Seen
        if (othersFormGroup.get(EHsPublicationFilter.UNREAD).value === true) {
            filters.push({
                type: EFilterType.VALUE,
                attribute: EHsPublicationFilter.UNREAD,
                value: true
            });
        }
        // ==================================================
        // Apply Supervisors filters
        if (((_b = this.supervisorsFormArray.value) === null || _b === void 0 ? void 0 : _b.length) > 0) {
            filters.push({
                type: EFilterType.VALUES,
                attribute: EHsPublicationFilter.SUPERVISORS,
                inArray: true,
                values: this.supervisorsFormArray.value.map((value) => value.id)
            });
        }
        // Apply filters by emitting filterApplied event
        this.filtersApplied.emit({ filters, replaceHistory });
        // Self close sidebar
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
    }
    _resetFormToDefault() {
        // Reset form
        this.form.reset(this._formRecordDefault);
        // Reset formArray manually because controls set after init
        if (this.publicationFilters && this.publicationFilters[EHsPublicationFilter.OPERATORS]) {
            this.operatorsFormArray.clear();
        }
        if (this.publicationFilters && this.publicationFilters[EHsPublicationFilter.SUPERVISORS]) {
            this.supervisorsFormArray.clear();
        }
    }
    _resetFormToLast() {
        // Reset form
        this.form.reset(this._formRecordLast);
        // Reset operators formArray manually because set after init
        if (this.publicationFilters[EHsPublicationFilter.OPERATORS]) {
            this.operatorsFormArray.clear();
            this._formRecordLast[EHsPublicationFilter.OPERATORS].forEach((operator) => {
                this.operatorsFormArray.push(this._fb.control(operator));
            });
        }
        // Reset supervisors formArray manually because set after init
        if (this.publicationFilters[EHsPublicationFilter.SUPERVISORS]) {
            this.supervisorsFormArray.clear();
            this._formRecordLast[EHsPublicationFilter.SUPERVISORS].forEach((supervisor) => {
                this.supervisorsFormArray.push(this._fb.control(supervisor));
            });
        }
    }
    resetFilters() {
        // Reset form
        this._resetFormToDefault();
        // Reload table
        this.filtersApplied.emit({
            filters: [],
            replaceHistory: true
        });
        // Self close sidebar
        this._fuseSidebarService.getSidebar('sidebar-filter').close();
    }
    clearEndDate() {
        this.form.get(`${EHsPublicationFilter.DATE}.${EHsPublicationFilter.END_DATE}`).reset();
        this.form.markAsDirty();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], HsPublicationFiltersSidebarComponent.prototype, "config", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], HsPublicationFiltersSidebarComponent.prototype, "applyFilters", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_f = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _f : Object)
], HsPublicationFiltersSidebarComponent.prototype, "filtersApplied", void 0);
__decorate([
    Output(),
    __metadata("design:type", typeof (_g = typeof EventEmitter !== "undefined" && EventEmitter) === "function" ? _g : Object)
], HsPublicationFiltersSidebarComponent.prototype, "filterCount", void 0);
HsPublicationFiltersSidebarComponent = __decorate([
    Component({
        selector: 'mpx-hs-sidebar-filters',
        template: require('./hs-sidebar-filters.component.html').default,
        styles: [require('./hs-sidebar-filters.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof FuseSidebarService !== "undefined" && FuseSidebarService) === "function" ? _a : Object, typeof (_b = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _b : Object, typeof (_c = typeof HsPublicationContainerService !== "undefined" && HsPublicationContainerService) === "function" ? _c : Object, typeof (_d = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _d : Object, typeof (_e = typeof UntypedFormBuilder !== "undefined" && UntypedFormBuilder) === "function" ? _e : Object])
], HsPublicationFiltersSidebarComponent);
export { HsPublicationFiltersSidebarComponent };
