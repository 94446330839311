var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
let FuseNavVerticalCollapsableComponent = class FuseNavVerticalCollapsableComponent {
    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {Router} _router
     */
    constructor(_changeDetectorRef, _fuseNavigationService, _router, _state) {
        this._changeDetectorRef = _changeDetectorRef;
        this._fuseNavigationService = _fuseNavigationService;
        this._router = _router;
        this._state = _state;
        this.item = null; // Change 07/10: Typescript warning for @Input()'s type as interface
        this.classes = 'nav-collapsable nav-item';
        this.isOpen = false;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit() {
        // Listen for router events
        this._router.onSuccess({}, () => {
            // Check if the url can be found in
            // one of the children of this item
            if (this.isUrlInChildren(this.item, this._state)) {
                this.expand();
            }
            else {
                this.collapse();
            }
        });
        // Listen for collapsing of any navigation item
        this._fuseNavigationService.onItemCollapsed.pipe(takeUntil(this._unsubscribeAll)).subscribe((clickedItem) => {
            if (clickedItem && clickedItem.children) {
                // Check if the clicked item is one
                // of the children of this item
                if (this.isChildrenOf(this.item, clickedItem)) {
                    return;
                }
                // Check if the url can be found in
                // one of the children of this item
                if (this.isUrlInChildren(this.item, this._state)) {
                    return;
                }
                // If the clicked item is not this item, collapse...
                if (this.item !== clickedItem) {
                    this.collapse();
                }
            }
        });
        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, this._state)) {
            this.expand();
        }
        else {
            this.collapse();
        }
        // Subscribe to navigation item
        merge(this._fuseNavigationService.onNavigationItemAdded, this._fuseNavigationService.onNavigationItemUpdated, this._fuseNavigationService.onNavigationItemRemoved)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle collapse
     *
     * @param ev
     */
    toggleOpen(ev) {
        ev.preventDefault();
        this.isOpen = !this.isOpen;
        // Navigation collapse toggled...
        this._fuseNavigationService.onItemCollapsed.next(this.item);
        this._fuseNavigationService.onItemCollapseToggled.next();
    }
    /**
     * Expand the collapsable navigation
     */
    expand() {
        if (this.isOpen) {
            return;
        }
        this.isOpen = true;
        // Mark for check
        this._changeDetectorRef.markForCheck();
        this._fuseNavigationService.onItemCollapseToggled.next();
    }
    /**
     * Collapse the collapsable navigation
     */
    collapse() {
        if (!this.isOpen) {
            return;
        }
        this.isOpen = false;
        // Mark for check
        this._changeDetectorRef.markForCheck();
        this._fuseNavigationService.onItemCollapseToggled.next();
    }
    /**
     * Check if the given parent has the
     * given item in one of its children
     *
     * @param parent
     * @param item
     * @returns {boolean}
     */
    isChildrenOf(parent, item) {
        const children = parent.children;
        if (!children) {
            return false;
        }
        if (children.indexOf(item) > -1) {
            return true;
        }
        for (const child of children) {
            if (child.children) {
                if (this.isChildrenOf(child, item)) {
                    return true;
                }
            }
        }
        return false;
    }
    /**
     * Check if the given url can be found
     * in one of the given parent's children
     *
     * @param parent
     * @param url
     * @returns {boolean}
     */
    isUrlInChildren(parent, url) {
        const children = parent.children;
        if (!children) {
            return false;
        }
        for (const child of children) {
            if (child.children) {
                if (this.isUrlInChildren(child, url)) {
                    return true;
                }
            }
            if (child.url === url || url.includes(child.url)) {
                return true;
            }
        }
        return false;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], FuseNavVerticalCollapsableComponent.prototype, "item", void 0);
__decorate([
    HostBinding('class'),
    __metadata("design:type", Object)
], FuseNavVerticalCollapsableComponent.prototype, "classes", void 0);
__decorate([
    HostBinding('class.open'),
    __metadata("design:type", Object)
], FuseNavVerticalCollapsableComponent.prototype, "isOpen", void 0);
FuseNavVerticalCollapsableComponent = __decorate([
    Component({
        selector: 'fuse-nav-vertical-collapsable',
        template: require('./collapsable.component.html').default,
        styles: [require('./collapsable.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof ChangeDetectorRef !== "undefined" && ChangeDetectorRef) === "function" ? _a : Object, typeof (_b = typeof FuseNavigationService !== "undefined" && FuseNavigationService) === "function" ? _b : Object, typeof (_c = typeof TransitionService !== "undefined" && TransitionService) === "function" ? _c : Object, typeof (_d = typeof StateService !== "undefined" && StateService) === "function" ? _d : Object])
], FuseNavVerticalCollapsableComponent);
export { FuseNavVerticalCollapsableComponent };
