export const locale = {
    lang: 'fr',
    data: {
        REQUEST_WIDGET: {
            TITLE: `Dernières demandes`,
            UNIT: ` unité(s)`,
            DEADLINE: 'Date limite : le ',
            NO_REQUEST: 'Pas de demandes récentes.',
            SEE_MORE: 'Voir plus de demandes',
            FORBIDDEN_MESSAGE: "Vous n'avez pas accès à cette fonctionnalité.",
            CONTACT_US: 'Contactez-nous'
        }
    }
};
