import * as angular from 'angular';
import './reset-password/reset-password.module';
import './mp-home/mp-home.module';
import './mp-first-connection/mp-first-connection.module';
import './accept-cgu/accept-cgu.module';
import { MpModalContactController } from './mp-modal-contact/mp-modal-contact.controller';
import { MpModalContactComponent } from './mp-modal-contact/mp-modal-contact.component';
import { MpModalAccessController } from './mp-modal-access/mp-modal-access.controller';
import { MpModalAccessComponent } from './mp-modal-access/mp-modal-access.component';
angular
    .module('mapui.general', [
    'mapui.home',
    'mapui.first-connection',
    'mapui.reset-password',
    'mapui.accept-cgu'
])
    .controller('MpModalContactController', MpModalContactController)
    .component('mpModalContact', new MpModalContactComponent())
    .controller('MpModalAccessController', MpModalAccessController)
    .component('mpModalAccess', new MpModalAccessComponent());
