import * as angular from 'angular';
import { MpSidenavStaffTypeController } from './mp-sidenav-staff-type/mp-sidenav-staff-type.controller';
import { MpSidenavStaffTypeComponent } from './mp-sidenav-staff-type/mp-sidenav-staff-type.component';
import { MpSelectStaffMemberController } from './mp-select-staff-member/mp-select-staff-member.controller';
import { MpSelectStaffMemberComponent } from './mp-select-staff-member/mp-select-staff-member.component';
angular
    .module('mapui.services.staff', [
    'mapui.services.auth',
    'mapui.services.modal',
])
    .controller('MpSidenavStaffTypeController', MpSidenavStaffTypeController)
    .component('mpSidenavStaffType', new MpSidenavStaffTypeComponent())
    .controller('MpSelectStaffController', MpSelectStaffMemberController)
    .component('mpSelectStaffMember', new MpSelectStaffMemberComponent());
