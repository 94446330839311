import * as angular from 'angular';
import { ELabelType } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
/** @ngInject */
export class MpLabelController {
    /** @ngInject */
    constructor(LoanService) {
        this.LoanService = LoanService;
        /**
         * Define if the chip is already exist
         * @param chip
         * @returns {any}
         */
        this.transformChip = (chip) => {
            if (angular.isObject(chip)) {
                this.model.push(chip);
                this.onSelected({ labelList: this.model });
                return chip;
            }
            else {
                let nwChip = {};
                nwChip.title = chip;
                nwChip.type = this.labelType;
                this.model.push(nwChip);
                this.onSelected({ labelList: this.model });
                return chip;
            }
        };
        this.removeChip = (idx) => {
            this.onRemove({ idx: idx });
        };
        /**
         * Research function for [[ILabel]]
         * @returns {angular.IPromise<Array<ILabel>>}
         */
        this.searchLabels = (query) => {
            let results = query ? this.labels.filter((item) => {
                if (item.normalizedTitle.match(Utils.normalize(query)))
                    return item.title;
            }) : '';
            return results;
        };
        this.ctrl = {
            selected: null,
            searchText: '',
        };
        this.labels = [];
        if (this.labelType === ELabelType.Preparations)
            this.LoanService.getPreparationsLabels().then((res) => {
                for (let l of res) {
                    if (!l.restricted)
                        this.labels.push(l);
                }
            });
    }
    $onInit() {
        if (!this.model) {
            this.model = [];
        }
    }
    $onChanges(changes) {
        if (changes.model) {
            this.model = angular.copy(this.model);
        }
    }
}
