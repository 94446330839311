var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SmallListButtonComponent } from './mpx-small-list-button.component';
import { SmallListColComponent } from './mpx-small-list-col.component';
import { SmallListItemComponent } from './mpx-small-list-item.component';
import { SmallListTitleComponent } from './mpx-small-list-title.component';
import { SmallListComponent } from './mpx-small-list.component';
let SmallListModule = class SmallListModule {
};
SmallListModule = __decorate([
    NgModule({
        imports: [CommonModule, FuseSharedModule, MatButtonModule, MatTooltipModule, MatDividerModule, MatIconModule],
        declarations: [
            SmallListComponent,
            SmallListTitleComponent,
            SmallListItemComponent,
            SmallListButtonComponent,
            SmallListColComponent
        ],
        exports: [
            SmallListComponent,
            SmallListTitleComponent,
            SmallListItemComponent,
            SmallListButtonComponent,
            SmallListColComponent
        ]
    })
], SmallListModule);
export { SmallListModule };
