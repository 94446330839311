import { EEstablishmentType, ELabelType, ELoanCat, ELoanState, EMpStatus, ETransportType } from '@mapuilabs/mpl-interfaces';
import { Utils } from '@main/services/Utils';
import * as angular from 'angular';
import { ModalLoanConditions } from './mp-modal-loan.conditions';
export class MpModalLoanCreationController {
    /** @ngInject */
    constructor(ModalService, MemberService, StatusService, ToastService, Access, Api, _, $timeout, $translate, HospitalService, EstablishmentService, PreparationService, LoanService, DashboardService) {
        this.ModalService = ModalService;
        this.MemberService = MemberService;
        this.StatusService = StatusService;
        this.ToastService = ToastService;
        this.Access = Access;
        this.Api = Api;
        this._ = _;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.HospitalService = HospitalService;
        this.EstablishmentService = EstablishmentService;
        this.PreparationService = PreparationService;
        this.LoanService = LoanService;
        this.DashboardService = DashboardService;
        /**
         * Search hospital
         * @return {angular.IPromise<Array<IEstablishment>>}
         */
        this.searchHospitals = () => {
            if (EEstablishmentType.Other != this.establishment.type) {
                return this.EstablishmentService.findWithHospitalByNameAndType(this.autoCtrl.hospitals.search, this.establishment.type);
            }
            return this.HospitalService.findByName(this.autoCtrl.hospitals.search, EEstablishmentType.Other);
        };
        /**
         * Callback when hospital search selected an hospital
         */
        this.searchHospitalsSelected = () => {
            if (this.autoCtrl.hospitals.selected) {
                this.establishment.selected = this.autoCtrl.hospitals.selected;
            }
        };
        /**
         * Open modal to create non existing establishment
         */
        this.createEstablishment = () => {
            this.ModalService.show({
                component: 'mp-modal-create-other-establishment',
                bindings: {
                    name: this.autoCtrl.hospitals.search
                },
                ev: event,
                escapeToClose: true,
                clickOutsideToClose: false
            })
                .then((ans) => {
                this.isCreatingEstablishment = true;
                this.HospitalService.add(ans)
                    .then((res) => {
                    this.ToastService.show(EMpStatus.Ok);
                    this.isCreatingEstablishment = false;
                    this.establishment.selected = res;
                })
                    .catch(() => this.ToastService.show(EMpStatus.RejectByServer));
            });
        };
        // REGION FORM DISPLAY
        /**
         * Return if disabled validation step
         * @returns {boolean}
         */
        this.isValidationDisabled = () => {
            return false;
        };
        /**
         * Return if show validation step
         * @returns {boolean}
         */
        this.showValidationTab = () => {
            return false;
        };
        /**
         * Return validation string value
         * @returns {string}
         */
        this.validationStep = () => {
            return '2';
        };
        /**
         * Return if disabled confirmation step
         * @returns {boolean}
         */
        this.isConfirmationDisabled = () => {
            return false;
        };
        /**
         * Return if show confirmation step
         * @returns {boolean}
         */
        this.showConfirmationTab = () => {
            return false;
        };
        /**
         * Return confirmation string value
         * @returns {string}
         */
        this.confirmationStep = () => {
            return '3';
        };
        /**
         * Return if disabled return step
         * @returns {boolean}
         */
        this.isReturnDisabled = () => {
            return false;
        };
        /**
         * Return id show return step
         * @returns {boolean}
         */
        this.showReturnTab = () => {
            return false;
        };
        /**
         * Return return string value
         * @returns {string}
         */
        this.returnStep = () => {
            return '4';
        };
        // END REGION
        /**
         * Change the selected [[IHospital]]
         */
        this.changeSelectedHospital = () => {
            if (!this.establishment.selected)
                return;
            const id = this.loan.loanTypeCreation ? Utils.getId(this.Access.hospital) : Utils.getId(this.establishment.selected.hospital) || Utils.getId(this.establishment.selected); // selected can be a IHospital or a IEstablishment with a hospital reference
            if (id && ELoanCat.Prep === this.loan.loanCategoryCreation) {
                this.PreparationService.getAllFrom(id).then((preparations) => {
                    this.establishment.selected.preparations = preparations;
                }).catch(() => {
                    this.establishment.selected.preparations = [];
                });
            }
        };
        /**
         * Function which calculate the minimum date for loan urgency or set to today date.
         * @returns {boolean}
         */
        this.calcMinDate = () => {
            if (!this.loan.urgence) {
                this.minDate.setDate(this.today.getDate() + 2);
            }
            else {
                this.minDate.setDate(this.today.getDate());
            }
            this.loan.deliveryDate = null;
            return true;
        };
        /**
         * Set the type in case of preparation
         */
        this.setTypeCreation = () => {
            this.loan.loanSubCategoryCreation = null;
            this.loan.loanTypeCreation = null;
        };
        /**
         * Callback when the user selected or added labels
         * @param {Array<ILabel>} list
         */
        this.addLabel = (list) => {
            this.loan.prepLabels = this._.uniq(list, 'title');
        };
        /**
         * Callback when the user remove a label
         * @param {number} idx
         */
        this.removeLabel = (idx) => {
            if (this.loan.prepLabels)
                this.loan.prepLabels.splice(idx, 1);
        };
        /**
         * Submit the form
         * Perform some check before hands using the controller knowledge
         */
        this.submit = () => {
            return this._close({
                loan: this.loan,
                establishment: this.establishment.selected,
                creaMember: { _id: this.loan.creaMember._id }
            });
        };
        /**
         * Set the new [[IMember]] [[Id]] of the [[ILoan]] creator
         * @param model The new [[IMember]] [[Id]]
         */
        this.selectCreaMember = ({ model }) => {
            if (!model) {
                return;
            }
            this.loan.creaMember = { _id: model };
        };
        /**
         * Close the modal without actions performing
         */
        this.cancel = () => {
            this.ModalService.cancel(EMpStatus.CloseByUser);
        };
        // region AutoComplete
        /**
         * Select a drug with mp-search-drug component (from autocomplete)
         * @param drug
         */
        this.selectDrug = (drug) => {
            this.matchingAvailable = [];
            this.available.forEach((available) => {
                if (available.medicName == drug.denomination) {
                    this.matchingAvailable.push(available);
                }
            });
            this.loan.medicName = drug.denomination;
            this.loan.UCD13 = drug.UCD13;
            //this.loan.CIP13 = drug.CIP13;
            this.loan.laboratory_exp = drug.laboratory_exp;
            this.loan.laboratory_tit = drug.laboratory_tit;
            this.loan.codeATC = drug.sp_catc_code_fk;
        };
        /**
         * Select a drug with mp-search-medical-device component (from autocomplete)
         * @param medicalDevice
         */
        this.selectMedicalDevice = (medicalDevice) => {
            this.loan.medicName = medicalDevice.name;
        };
        this.medicalDeviceTextChange = (text) => {
            this.loan.medicName = text;
        };
        /**
         * Return an array containing all matches from value
         * @returns A promise containing an Array of string
         */
        this.searchPreps = () => {
            return this.PreparationService.searchPreps(this.autoCtrl.prep.search, this.establishment.selected);
        };
        /**
         * Set the selected preparation in the [[ILoan]]
         */
        this.searchPrepsSelected = () => {
            if (this.autoCtrl.prep.selected) {
                this.loan.preparation = { _id: this.autoCtrl.prep.selected._id };
                this.loan.medicName = this.autoCtrl.prep.selected.name + ' - ' + this.autoCtrl.prep.selected.dosage;
            }
            else {
                this.loan.preparation = null;
            }
        };
        /**
         * Allow free text as entry
         * Called when the search text change
         */
        this.prepTextChanged = () => {
            this.loan.medicName = this.autoCtrl.prep.search;
        };
        /**
         * Get the current selected language
         * @returns {string} the current language
         */
        this.getLang = () => {
            return this.$translate.use();
        };
        /**
         * Check if [[IHospital]] is a favorite
         * @param item
         * @returns {boolean}
         */
        this.isFavorite = (item) => {
            if (this.activeHospital.favorites) {
                if (this._.find(this.activeHospital.favorites, (favorite) => {
                    return Utils.compareIds(favorite, Utils.getId(item)) || Utils.compareIds(favorite, Utils.getId(item.hospital));
                }) !== undefined) {
                    return true;
                }
            }
            return false;
        };
        /**
         * Edit favorite list from the active [[IHospital]]
         * @param id the [[IHospital]]'s id to add or remove from favorites
         */
        this.editFavorite = (id) => {
            if (!this.activeHospital.favorites) {
                this.activeHospital.favorites = [];
            }
            console.log(JSON.parse(JSON.stringify(id)));
            id = Utils.getId(id);
            console.log('new ID, ', id);
            const favorite = this._.find(this.activeHospital.favorites, (favorite) => {
                return Utils.compareIds(favorite, id);
            });
            if (!favorite) {
                this.activeHospital.favorites.push({ _id: id });
            }
            else {
                this.activeHospital.favorites = this._.without(this.activeHospital.favorites, favorite);
            }
            this.HospitalService.save(this.activeHospital)
                .then(() => this.ToastService.show(EMpStatus.Ok))
                .catch(err => this.ToastService.show(err));
        };
        /**
         * Open a given [[ILoan]]
         * @param event
         * @param loan
         */
        this.openAvailableModal = (event, loan) => {
            event.stopPropagation();
            this.DashboardService.openAvailable(event, loan)
                .then(() => {
                this.cancel();
            });
        };
        // endregion
        /**
         * Close the modal with actions performing
         * @private
         */
        this._close = (answer) => {
            this.ModalService.close(answer);
        };
        this.isCreatingEstablishment = false;
        this.LoanState = ELoanState;
        this.today = new Date();
        this.minDate = new Date();
        this.minDate.setDate(this.today.getDate() + 2);
        this.state = ELoanState.Creation;
        this.matchingAvailable = [];
        this.conditions = new ModalLoanConditions(StatusService, Access);
        this.labelType = ELabelType.Preparations;
        this.establishment = {
            type: 1,
            selected: undefined
        };
        this.autoCtrl = {
            hospitals: {
                selected: null,
                search: null
            },
            prep: {
                selected: null,
                search: null
            }
        };
        this.prepTransport = [
            {
                label: this.$translate.instant('MAPUI.SERVICES.LOAN.MODAL.TABS.CONTENT.INPUTS.PREP_DELIVERY'),
                value: ETransportType.Delivery
            },
            {
                label: this.$translate.instant('MAPUI.SERVICES.LOAN.MODAL.TABS.CONTENT.INPUTS.PREP_FETCH'),
                value: ETransportType.Fetch
            }
        ];
    }
    $onInit() {
        this.Api.constType.get((res) => {
            this.constTypes = res;
        });
        this.MemberService.getByHosp(this.Access.hospital._id).then((staff) => {
            this.myStaff = staff;
        });
        if (Utils.hasId(this.Access.user)) {
            this.loan.creaMember = { _id: this.Access.user._id };
        }
        this.HospitalService.get(this.Access.hospital._id)
            .then((hosp) => {
            this.activeHospital = hosp;
        });
        this.LoanService.getAvailable().then((available) => {
            this.available = available;
        });
    }
    $onChanges(changes) {
        if (changes.loan) {
            this.loan = angular.copy(this.loan);
            if (!this.loan) {
                this.loan = {};
            }
            if (this.loan.loanCategoryCreation && !this.conditions.canDoAction(this.loan.loanCategoryCreation)) {
                this.loan.loanCategoryCreation = null;
            }
        }
    }
}
