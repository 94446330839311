import { EMpStatus } from '@mapuilabs/mpl-interfaces';
/** @ngInject */
export class HFilesService {
    /** @ngInject */
    constructor(Api, Access, $q, ModalService) {
        this.Api = Api;
        this.Access = Access;
        this.$q = $q;
        this.ModalService = ModalService;
        /**
         *
         * @param {IFileUploaderItem} item
         * @param {IFile} file
         * @returns {angular.IPromise<IFile>}
         */
        this.saveUploadedFile = (item, file) => {
            const defer = this.$q.defer();
            if (!item || !file || !this.Access.patient._id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            this.Api.hfiles.add({ pid: this.Access.patient._id }, file, (res) => {
                defer.resolve(res);
            }, (err) => {
                defer.reject(err);
            });
            return defer.promise;
        };
        /**
         * Open the confirmation Modal before deleting a file.
         * Update the displayed files on success
         * @param files
         * @returns {IPromise<any>|Promise<any>}
         */
        this.confirmDeleteFileModal = (files) => {
            const defer = this.$q.defer();
            let title, text;
            if (files.length == 1) {
                title = 'FILES.CONFIRM.DELETE_' + (files[0].isDirectory ? 'DIRECTORY' : 'FILE') + '.TITLE';
                text = 'FILES.CONFIRM.DELETE_' + (files[0].isDirectory ? 'DIRECTORY' : 'FILE') + '.MESSAGE';
            }
            else {
                title = 'FILES.CONFIRM.DELETE_MULTIPLE.TITLE';
                text = 'FILES.CONFIRM.DELETE_MULTIPLE.MESSAGE';
            }
            const data = {
                title: title,
                text: text,
                values: { nbr: files.length },
            };
            this.ModalService.show({
                component: 'mp-modal-confirm',
                bindings: {
                    data: data,
                },
                escapeToClose: false,
            }).then(() => {
                defer.resolve();
            }).catch(() => {
                defer.reject();
            });
            return defer.promise;
        };
        /**
         * Delete the given [IFile]
         * @param file
         * @returns {IPromise<any>|Promise<any>}
         */
        this.deleteFile = (file) => {
            const defer = this.$q.defer();
            if (!file || !this.Access.patient._id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            this.Api.hfiles.delete({ pid: this.Access.patient._id }, file, (res) => {
                defer.resolve(res);
            }, (res) => {
                defer.reject(res);
            });
            return defer.promise;
        };
        /**
         *
         * @param {IFile} file
         * @returns {angular.IPromise<void>}
         */
        this.saveFile = (file) => {
            const defer = this.$q.defer();
            if (!file || !this.Access.patient._id) {
                defer.reject(EMpStatus.NoDataProvided);
            }
            this.Api.hfiles.save({ pid: this.Access.patient._id }, file, () => {
                defer.resolve();
            }, () => {
                defer.reject();
            });
            return defer.promise;
        };
    }
    ;
}
