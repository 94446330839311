export class QuickPanelService {
    /** @ngInject */
    constructor(NotificationService, $mdSidenav) {
        this.NotificationService = NotificationService;
        this.$mdSidenav = $mdSidenav;
        this.open = (view) => {
            this.selectedView = view;
            this.$mdSidenav('quick-panel').open();
            this.NotificationService.emitOpened();
        };
    }
}
