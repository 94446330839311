var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component } from '@angular/core';
import { LoanService } from '@app/services/loan/loan.service';
import { StatusService } from '@app/services/status/status.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angularjs';
import * as _ from 'lodash';
import { InvoiceService } from '../../../services/invoice/invoice.service';
import { MemberService } from '@app/services/member/member.service';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
let BillingInvoiceComponent = class BillingInvoiceComponent {
    constructor(sts, _loanService, _stateService, _translateService, _memberService, _invoiceService, _fuseTranslationLoaderService) {
        this.sts = sts;
        this._loanService = _loanService;
        this._stateService = _stateService;
        this._translateService = _translateService;
        this._memberService = _memberService;
        this._invoiceService = _invoiceService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._fuseTranslationLoaderService.loadTranslations(french, english);
    }
    ngOnInit() {
        const id = this._stateService.params['id'];
        this._invoiceService.getById(id).subscribe((invoice) => {
            this.invoice = invoice;
            this.taxes = {};
            this.charge = 0;
            this.totals = { HT: 0, TTC: 0 };
            this.invoice = invoice;
            for (const elem of this.invoice.loans) {
                if (elem.tva) {
                    if (!this.taxes[elem.tva]) {
                        this.taxes[elem.tva] = 0;
                    }
                    this.taxes[elem.tva] += elem.quantity * elem.unitPrice * (elem.tva / 100);
                }
                if (elem.charge) {
                    this.charge += elem.chargePercent
                        ? elem.quantity * elem.unitPrice * (elem.charge / 100)
                        : elem.charge;
                }
                this.totals.HT += elem.quantity * elem.unitPrice;
            }
            this.totals.TTC =
                this.totals.HT + _.reduce(this.taxes, (memo, num) => memo + num, 0) + this.charge;
            this.pageHeaderConfig = {
                title: this._translateService.instant('BILLING.BILLINGS'),
                icon: 'receipt_long'
            };
        });
    }
    hasFullName() {
        return !!this.loan.updateUID.fullName;
    }
    showMember(member) {
        this._loanService.archiveMember = member;
        this._stateService.go('mapui.exchangeV2.billing.member');
    }
    /**
     * Get the current selected language
     * @returns {string} the current language
     */
    _getLang() {
        return this._translateService.getDefaultLang();
    }
    _generatePdfLink() {
        return `/pdf-generation/avis-de-paiement.pdf?pdfmodel=invoice&id=${this.invoice._id}&type=invoice&lang=${this._getLang()}`;
    }
};
BillingInvoiceComponent = __decorate([
    Component({
        selector: 'mpx-billing-invoice',
        template: require('./billing-invoice.component.html').default
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof StatusService !== "undefined" && StatusService) === "function" ? _a : Object, typeof (_b = typeof LoanService !== "undefined" && LoanService) === "function" ? _b : Object, typeof (_c = typeof StateService !== "undefined" && StateService) === "function" ? _c : Object, typeof (_d = typeof TranslateService !== "undefined" && TranslateService) === "function" ? _d : Object, typeof (_e = typeof MemberService !== "undefined" && MemberService) === "function" ? _e : Object, typeof (_f = typeof InvoiceService !== "undefined" && InvoiceService) === "function" ? _f : Object, typeof (_g = typeof FuseTranslationLoaderService !== "undefined" && FuseTranslationLoaderService) === "function" ? _g : Object])
], BillingInvoiceComponent);
export { BillingInvoiceComponent };
