var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
let SmallListComponent = class SmallListComponent {
    constructor() {
        this.size = 'sm';
    }
    ngOnInit() {
        this.smSizeClass = this.size === 'sm';
        this.xsSizeClass = this.size === 'xs';
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], SmallListComponent.prototype, "size", void 0);
__decorate([
    HostBinding('class.sm-size'),
    __metadata("design:type", Boolean)
], SmallListComponent.prototype, "smSizeClass", void 0);
__decorate([
    HostBinding('class.xs-size'),
    __metadata("design:type", Boolean)
], SmallListComponent.prototype, "xsSizeClass", void 0);
SmallListComponent = __decorate([
    Component({
        selector: 'mpx-small-list',
        template: `
        <ng-content select="mpx-small-list-title"></ng-content>

        <ng-content select="mpx-small-list-item"></ng-content>
    `,
        encapsulation: ViewEncapsulation.None,
        styles: [require('./mpx-small-list.component.scss')]
    })
], SmallListComponent);
export { SmallListComponent };
