var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { BackButtonDirective } from '../back-button/back-button.directive';
import { PageHeaderButtonLabelComponent } from './page-header-button/page-header-button-label/page-header-button-label.component';
import { PageHeaderButtonComponent } from './page-header-button/page-header-button.component';
import { PageHeaderSearchBarButtonComponent } from './page-header-search-bar/page-header-search-bar-button/page-header-search-bar-button.component';
import { PageHeaderSearchBarComponent } from './page-header-search-bar/page-header-search-bar.component';
import { PageHeaderComponent } from './page-header.component';
import { PageHeaderService } from './page-header.service';
let PageHeaderModule = class PageHeaderModule {
};
PageHeaderModule = __decorate([
    NgModule({
        imports: [CommonModule, FuseSharedModule, MatIconModule, MatButtonModule, MatInputModule],
        declarations: [
            PageHeaderButtonComponent,
            PageHeaderButtonLabelComponent,
            PageHeaderComponent,
            PageHeaderSearchBarButtonComponent,
            PageHeaderSearchBarComponent,
            BackButtonDirective
        ],
        exports: [
            PageHeaderButtonComponent,
            PageHeaderButtonLabelComponent,
            PageHeaderComponent,
            PageHeaderSearchBarButtonComponent,
            PageHeaderSearchBarComponent
        ],
        providers: [PageHeaderService]
    })
], PageHeaderModule);
export { PageHeaderModule };
