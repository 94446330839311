export const locale = {
    lang: 'fr',
    data: {
        LABOS: {
            TITLE: 'Laboratoires',
            SEARCH: 'Rechercher un laboratoire',
            TABLE: {
                NAME: 'Nom',
                NBR_ACCOUNTS: 'Comptes',
                ROLES: 'Rôles'
            },
            DETAILS: {
                INFORMATIONS: {
                    TITLE: 'Informations',
                    NAME: {
                        TITLE: 'Nom du laboratoire',
                        PLACEHOLDER: 'Renseigner le nom du laboratoire'
                    },
                    THERIAQUE_NAME: {
                        TITLE: 'Theriaque - Liste des laboratoires exploitants',
                        PLACEHOLDER: 'Rechercher un laboratoire exploitant'
                    },
                    EXHAUSMED_NAME: {
                        TITLE: 'Exhausmed - Liste des industriels',
                        PLACEHOLDER: 'Rechercher un industriel'
                    },
                    CONFIGURATION: {
                        THERIAQUENAME_EDIT_WARNING: 'Les modifications impacteront le nom de labo exploitant pour Thériaque',
                        DELETE_THERIAQUENAME: {
                            TITLE: 'Confirmer la suppression du nom de labo exploitant pour Thériaque',
                            CONTENT: ' Voulez-vous vraiment supprimer le nom de labo exploitant pour Thériaque: {{name}} ?'
                        }
                    }
                },
                PERMISSIONS: {
                    TITLE: 'Actions / Permissions',
                    ROLE_MAPUI: 'Rôle MaPUI',
                    ROLE_HOSPIVILLE: 'Rôle Hospiville',
                    ROLE_HOSPISTOCK: 'Rôle Hospistock',
                    ROLE_WORKSPACE: 'Rôle Workspace',
                    LOG_AS: {
                        LONG: `Se connecter dans le laboratoire`,
                        SHORT: `Se connecter`
                    },
                    DELETE: {
                        LONG: `Supprimer le laboratoire`,
                        SHORT: `Supprimer`
                    },
                    CONFIRM_LOGAS: 'Voulez-vous vraiment vous connecter à ce laboratoire ?',
                    CONFIRM_DELETE: `Voulez-vous vraiment supprimer ce laboratoire ?`,
                    SNACK_DELETE: `Le laboratoire a été supprimé.`,
                    MEMBER_SINCE: `Membre MaPUI depuis`,
                    MEMBER_TO: `Member Mapui jusqu'a`
                },
                MEMBERS: {
                    TITLE: 'Membres',
                    NO_MEMBERS: "Ce laboratoire n'a aucun membre.",
                    TABLE: {
                        NAME: 'Nom',
                        EMAIL: 'Mail',
                        LAST_CONNECT: 'Dernière connexion',
                        ROLES: `Rôles`
                    }
                },
                GROUPS: {
                    TITLE: 'Groupes',
                    NO_GROUPS: "Ce laboratoire n'a aucun groupe.",
                    TABLE: {
                        NAME: 'Nom',
                        NB_HOSPITALS: "Nombre d'hôpitaux",
                        ACTION: 'Action'
                    },
                    DETAIL: {
                        TITLE: 'Groupe',
                        INFORMATIONS: {
                            TITLE: 'Informations',
                            NAME: 'Nom du groupe'
                        },
                        HOSPITALS: {
                            TITLE: 'Hôpitaux',
                            NO_HOSPITALS: "Ce groupe n'a aucun hôpital.",
                            TABLE: {
                                NAME: 'Nom',
                                ACTION: 'Action'
                            }
                        },
                        CREATE_OK: 'Le groupe a été créé !',
                        EDIT_OK: 'Le groupe a été modifié !',
                        DELETE_OK: 'Le groupe a été supprimé !'
                    }
                },
                HOSPISTOCK: {
                    TITLE: 'Hospistock',
                    FORM: {
                        IMPORT: {
                            TITLE: 'Importation de publications par fichier',
                            IMPORT_MODE: {
                                LABEL: "Remplacement exact des publications en ligne par les publications du fichier d'import. Les publications non présentes seront fermées."
                            }
                        },
                        NOTIFICATIONS: {
                            TITLE: "Configuration de notifications des propositions d'alternatives aux publications",
                            EMAIL_CONTACT: 'Adresse mail de contact',
                            EMAIL: 'Email',
                            CHECKBOX_EMAIL_CONTACT: "Envoyer les emails à l'adresse mail de contact configurée ci-dessus",
                            CHECKBOX_EMAIL_MEMBERS: 'Envoyer les emails aux membres pouvant accéder aux alternatives des publications',
                            TOGGLE_SEND_MAIL: "Envoyer un email uniquement lors de la première proposition d'alternative"
                        }
                    }
                }
            }
        }
    }
};
