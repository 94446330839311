export class MpFilesDisplayController {
    /** @ngInject */
    constructor(Api) {
        this.Api = Api;
        /**
         * Return text file extension (used for file type .svg)
         * @param {IFile} f
         * @returns {string}
         */
        this.getFileExt = (f) => {
            if (!this.filesType)
                return 'default';
            if (f && f.extension && this.filesType[f.extension]) {
                return this.filesType[f.extension];
            }
            return this.filesType['default'];
        };
    }
    $onInit() {
        this.Api.filesType.get((ans) => {
            this.filesType = ans;
        });
    }
}
