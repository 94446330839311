var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d;
import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { HsHealthProductService } from '@app/hospistock/shared/services/hs-health-product.service';
import { fuseAnimations } from '@fuse/animations';
import { EEstablishmentType } from '@mapuilabs/mpl-interfaces';
import { GroupService } from '@services/group/group.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
let HsPublicationBroadcastListComponent = class HsPublicationBroadcastListComponent {
    constructor(_groupService, _snackbarService, _healthProductService) {
        this._groupService = _groupService;
        this._snackbarService = _snackbarService;
        this._healthProductService = _healthProductService;
        // Autocomplete
        this._letterGroups = [];
        this._hasGroupSelectedValidator = () => {
            return !this.hasGroupSelected ? { groupSelected: true } : null;
        };
        /**
         * Filter letterGroupOptions based on autocomplete search value
         * @param letterGroupOptions letterGroupOptions to filter
         * @param search Autocomplete search value
         * @returns Filtered letterGroupOptions
         */
        this._filterLetterGroupOptionsBySearch = (letterGroupOptions, search) => {
            const filterValue = search.toLowerCase();
            return letterGroupOptions.filter((letterGroupOption) => letterGroupOption.label.toLowerCase().includes(filterValue));
        };
        this._unsubscribeAll = new Subject();
        // Use of custom Validator with arrow function to be able to use local variables externals from FormControl
        this.autocompleteFormControl = new UntypedFormControl('', this._hasGroupSelectedValidator);
        this.groupsFormArray = new UntypedFormArray([], Validators.required);
        // Init to empty array for autocomplete options init
        this.letterGroups$ = new BehaviorSubject([]);
        // Init to null so TreeList data will not be initialized before selectedGroupsAsTreeNodes$ first change
        this.selectedGroupsAsTreeNodes$ = new BehaviorSubject(null);
        // Init to null to allow _buildTreeItemNodes to check selected items in targetGroups
        this._treeListSelection$ = new BehaviorSubject(null);
    }
    // Accessors
    get letterGroupOptions() {
        return this._letterGroups.flatMap((letterGroup) => letterGroup.options);
    }
    get targetGroupsControl() {
        return this.control.get('targetGroups');
    }
    get hasGroupSelected() {
        return this.letterGroupOptions.some(({ control }) => control.value);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    ngOnInit() {
        // Retrieve Purchase Group with Hospitals
        this._groupService
            .readGroupsWithHospitals(this._healthProductService.healthProductType)
            .pipe(take(1))
            .subscribe({
            next: (groups) => {
                // Build letterGroups
                this._letterGroups = this._groupByFirstLetter(groups.map((group) => ({
                    label: group.name,
                    value: group,
                    control: new UntypedFormControl(!!this._getTargetGroup(group._id) && group)
                })));
                // Update letterGroups$
                this.letterGroups$.next(this._letterGroups);
                // Store controls in FormArray to easily catch changes event on any of the letterGroupOptions control
                this.letterGroupOptions.forEach((letterGroupOption) => this.groupsFormArray.push(letterGroupOption.control));
                // Update selectedGroupsAsTreeNodes on FormArray changes
                this.groupsFormArray.valueChanges
                    .pipe(takeUntil(this._unsubscribeAll), 
                // Subscribe to groupsFormArray with startWith to skip changes caused by adding all letterGroupOptions
                startWith(this.groupsFormArray.value), 
                // Ensure deep copy of groups elements because map operator only create new reference for an array but not for the inner elements
                map((groups) => _.cloneDeep(groups)), 
                // Filter letterGroupOptions control not selected
                map((groups) => _.compact(groups)), 
                // Convert to TreeItemNodes
                map((groups) => this._buildTreeItemNodes(groups)))
                    .subscribe((items) => this.selectedGroupsAsTreeNodes$.next(items));
                // Update autocompleteFormControl to refresh _hasGroupSelectedValidator error
                this.autocompleteFormControl.updateValueAndValidity();
            },
            error: (err) => this._handleError(err)
        });
        // Update letterGroups on autocomplete changes
        this.autocompleteFormControl.valueChanges
            .pipe(takeUntil(this._unsubscribeAll), startWith(''), map((search) => this._filterLetterGroup(this._letterGroups, search)))
            .subscribe((items) => this.letterGroups$.next(items));
        // Update targetGroups on treeListSelection changes
        this._treeListSelection$
            .pipe(takeUntil(this._unsubscribeAll), 
        // Filter _treeListSelection$ initialisation with null
        filter((nodes) => !!nodes), 
        // Ensure deep copy of TreeNestedItemNode elements because map operator only create new reference for an array but not for the inner elements
        map((nodes) => _.cloneDeep(nodes)), 
        // Keep only selected items and replace selected group's children by empty array
        map((nodes) => this._filterTreeListSelection(nodes)), 
        // Convert to targetGroups
        map((nodes) => this._buildTargetGroupsFromTreeListSelection(nodes)))
            .subscribe({
            next: (targetGroups) => this.targetGroupsControl.setValue(targetGroups)
        });
    }
    /**
     * On destroy
     */
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Controller methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Log error and trigger snackbar with message
     * @param err Error to handle
     */
    _handleError(err) {
        console.error(err);
        this._snackbarService.openError(err);
    }
    /**
     * Return targetGroup from control using group
     * @param id The group id
     * @returns targetGroup extract from control
     */
    _getTargetGroup(id) {
        return this.targetGroupsControl.value.find(({ group }) => group === id);
    }
    /**
     * Check if a group is selected in either treeListSelection or targetGroup depending on if treeListSelection is defined.
     * Behavior subject _treeListSelection is not define before first selectionChange from TreeListComponent is emitted.
     * @param id Group id to check
     * @returns Is the group selected or not
     */
    _isGroupSelected(id) {
        const treeListSelection = this._treeListSelection$.value;
        // Check if group is selected from TreeListSelection
        //  => when treeListSelection value defined
        const isSelectedFromTreeListSelection = () => {
            const goupNode = treeListSelection.find(({ value }) => value._id === id);
            return goupNode ? goupNode.selected : true;
        };
        // Check if group is selected from targetGroups
        //  => at initialization when treeListSelection value is null
        const isSelectedFromTargetGroups = () => {
            const targetGroup = this._getTargetGroup(id);
            return targetGroup ? _.isEmpty(targetGroup.hospitals) : true;
        };
        return treeListSelection ? isSelectedFromTreeListSelection() : isSelectedFromTargetGroups();
    }
    /**
     * Check if an hospital is selected in either treeListSelection or targetGroup depending on if treeListSelection is defined.
     * Behavior subject _treeListSelection is not define before first selectionChange from TreeListComponent is emitted.
     * @param id Group id containing the hospital to check
     * @param id Hospital id to check
     * @returns Is the hospital selected or not
     */
    _isHospitalSelected(groupId, hospitalId) {
        const treeListSelection = this._treeListSelection$.value;
        // Check if group is selected from TreeListSelection
        //  => when treeListSelection value defined
        const isSelectedFromTreeListSelection = () => {
            const goupNode = treeListSelection.find(({ value }) => value._id === groupId);
            const isHospitalSelected = () => {
                const hospitals = goupNode.children;
                return _.isEmpty(hospitals) || hospitals.find(({ value }) => value._id === hospitalId).selected;
            };
            return goupNode ? isHospitalSelected() : true;
        };
        // Check if group is selected from targetGroups
        //  => at initialization when treeListSelection value is null
        const isSelectedFromTargetGroups = () => {
            const targetGroup = this._getTargetGroup(groupId);
            const isHospitalSelected = () => {
                const hospitals = targetGroup.hospitals;
                return _.isEmpty(hospitals) || !!hospitals.find((id) => id === hospitalId);
            };
            return targetGroup ? isHospitalSelected() : true;
        };
        return treeListSelection ? isSelectedFromTreeListSelection() : isSelectedFromTargetGroups();
    }
    /**
     * Build TreeNestedItemNode array from GroupWithHospitals array
     * @param groups GroupWithHospital array
     * @returns TreeNestedItemNode array
     */
    _buildTreeItemNodes(groups) {
        return ((groups === null || groups === void 0 ? void 0 : groups.map((group) => ({
            label: group.name,
            selected: this._isGroupSelected(group._id),
            value: { _id: group._id, establishmentType: EEstablishmentType.PurchaseGroup },
            children: group.hospitals.map((hospital) => ({
                label: hospital.name,
                selected: this._isHospitalSelected(group._id, hospital._id),
                value: { _id: hospital._id, establishmentType: hospital.establishmentType }
            }))
        }))) || []);
    }
    /**
     * Group letterGroupOptions by first letter to be displayed in autocomplete selection
     * @param letterGroupOptions letterGroupOptions array to group
     * @returns Grouped letterGroupOptions
     */
    _groupByFirstLetter(letterGroupOptions) {
        const letterGroups = [];
        letterGroupOptions.forEach((letterGroupOption) => {
            var _a, _b;
            const letter = (_b = (_a = letterGroupOption.label) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.toUpperCase();
            let letterGroup = letterGroups.find((letterGroup) => letterGroup.letter === letter);
            if (!letterGroup) {
                letterGroup = { letter, options: [] };
                letterGroups.push(letterGroup);
            }
            letterGroup.options.push(letterGroupOption);
        });
        letterGroups.sort((a, b) => {
            return a.letter.localeCompare(b.letter);
        });
        return letterGroups;
    }
    /**
     * Filter letterGroupOptions based on autocomplete search value and empty letterGroups
     * @param letterGroups letterGroups to filter
     * @param search Autocomplete search value
     * @returns Filtered letterGroups
     */
    _filterLetterGroup(letterGroups, search) {
        return letterGroups
            .map(({ letter, options }) => ({
            letter,
            options: search ? this._filterLetterGroupOptionsBySearch(options, search) : options
        }))
            .filter(({ options }) => options.length > 0);
    }
    /**
     * Filter TreeNestedItemNodes to keep only selected items and replace selected group's children by empty array
     * @param nodes TreeNestedItemNodes to filter
     * @returns Filtered TreeNestedItemNodes
     */
    _filterTreeListSelection(nodes) {
        return (nodes
            // Filter children not selected or replace children by empty array if all selected
            .map((node) => Object.assign(node, {
            children: node.selected || _.isEmpty(node.children)
                ? []
                : node.children.filter(({ selected }) => selected)
        }))
            // Filter groups which is not selected & does not have any children selected
            .filter((node) => node.selected || (!node.selected && !_.isEmpty(node.children))));
    }
    /**
     * Build IHsPublicationTargetGroup array from TreeNestedItemNode
     * @param nodes TreeNestedItemNode to convert
     * @returns IHsPublicationTargetGroup
     */
    _buildTargetGroupsFromTreeListSelection(nodes) {
        return nodes.map(({ value, children }) => ({
            group: value._id,
            hospitals: children.map(({ value }) => value._id)
        }));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ View methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Handle autocomplete options click event by updating letterGroupOptin control value with the GroupWithHospitals is selected and false if not selected.
     * @param event Click event
     * @param groupOption Group option to handle
     */
    onOptionClicked(event, groupOption) {
        // Prevent autocomplete to assign groupOption to input value & close selection
        event.stopPropagation();
        // Assign value to groupOption control
        groupOption.control.setValue(!groupOption.control.value ? groupOption.value : false);
        // Update autocompleteFormControl to refresh _hasGroupSelectedValidator error
        this.autocompleteFormControl.updateValueAndValidity();
        // Update required validator error
        this.targetGroupsControl.updateValueAndValidity();
    }
    /**
     * Catch autocomplete options mouseDown event by to prevent fromControl to updateValueAndValidity before we do it manually in onOptionClicked.
     * This allow to avoid displaying errors before the groupOption control value is assigned.
     * @param event
     */
    onOptionMouseDown(event) {
        event.preventDefault();
    }
    /**
     * Handle TreeListComponent onSelectionChanged event by updating the _treeListSelection §BehaviorSubject
     * @param nodes TreeListComponent node selection
     */
    onTreeSelectionChanged(nodes) {
        this._treeListSelection$.next(nodes);
        // Mark form as dirty if selection changed after initialization
        // Does not work with lodash deepCopy...
        const deepCopy = (el) => JSON.parse(JSON.stringify(el));
        if (!_.isEqual(deepCopy(nodes), deepCopy(this.selectedGroupsAsTreeNodes$.value))) {
            this.targetGroupsControl.markAsDirty();
        }
    }
};
__decorate([
    Input(),
    __metadata("design:type", typeof (_d = typeof UntypedFormControl !== "undefined" && UntypedFormControl) === "function" ? _d : Object)
], HsPublicationBroadcastListComponent.prototype, "control", void 0);
HsPublicationBroadcastListComponent = __decorate([
    Component({
        selector: 'mpx-hs-publication-broadcast-list',
        template: require('./hs-publication-broadcast-list.component.html').default,
        providers: [HsHealthProductService],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof GroupService !== "undefined" && GroupService) === "function" ? _a : Object, typeof (_b = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _b : Object, typeof (_c = typeof HsHealthProductService !== "undefined" && HsHealthProductService) === "function" ? _c : Object])
], HsPublicationBroadcastListComponent);
export { HsPublicationBroadcastListComponent };
