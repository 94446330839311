import * as angular from 'angular';
/**
 * Encapsulate a selected file for future use
 */
export class LocalFile {
    /**
     *
     * @param options
     */
    /** @ngInject */
    constructor(options) {
        let { extend } = angular;
        extend(this, options);
    }
}
