import * as angular from 'angular';
import { ResetPasswordController } from './reset-password.controller';
import { ResetPasswordComponent } from './reset-password.component';
angular.module('mapui.reset-password', [
    'mapui.services.auth',
]).controller('ResetPasswordController', ResetPasswordController)
    .component('resetPassword', new ResetPasswordComponent())
    .config(config);
/** @ngInject */
function config($stateProvider) {
    $stateProvider.state('mapui.reset-password', {
        url: '/reset-password',
        data: {},
        views: {
            'main@': {
                template: require('../../../layout/content-only/main/main.html').default,
                controller: 'MainController as vm',
            },
            'content@mapui.reset-password': {
                template: '<reset-password></reset-password>',
            },
        },
        bodyClass: 'reset-password',
    });
}
