var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d, _e, _f, _g;
import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { Group } from '@mapuilabs/mpl-interfaces';
import { Access } from '@main/services/auth/access.service';
import { DialogService } from '@services/dialog/dialog.service';
import { GroupService } from '@services/group/group.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { TranslationService } from '@services/translation/translation.service';
import { lastValueFrom } from 'rxjs';
let ListAdminComponent = class ListAdminComponent {
    constructor(_dialogService, _translationService, _snackBarService, _groupService, _access) {
        this._dialogService = _dialogService;
        this._translationService = _translationService;
        this._snackBarService = _snackBarService;
        this._groupService = _groupService;
        this._access = _access;
        this.adminForm = new UntypedFormControl();
    }
    ngOnChanges(changes) {
        if (changes.group) {
            this.updateDatasource();
            this.displayedColumns = this.isUserAdmin
                ? ['name', 'hospital', 'email', 'action']
                : ['name', 'hospital', 'email'];
        }
    }
    updateDatasource() {
        this.dataSource = new MatTableDataSource(this.group.administrators);
    }
    removeAdmin(adminMember) {
        return __awaiter(this, void 0, void 0, function* () {
            const dialogConfig = {
                title: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_MEMBER.TITLE'),
                message: this._translationService.instant('GROUP_DETAIL.MESSAGES.DELETE_MEMBER.MESSAGE'),
                confirmButton: this._translationService.instant('COMMONS.DELETE')
            };
            const data = yield lastValueFrom(this._dialogService.openConfirmDialog(dialogConfig));
            if (!data) {
                return;
            }
            try {
                yield lastValueFrom(this._groupService.removeGroupAdmin(this.group, adminMember));
                const index = this.group.administrators.indexOf(adminMember);
                if (index !== -1) {
                    this.group.administrators.splice(index, 1);
                }
                this.updateDatasource();
                this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.DELETE_ADMIN_OK'));
            }
            catch (_a) {
                this._snackBarService.openDefaultError();
            }
        });
    }
    addAdmin() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (!this.group.administrators || !this.group.administrators.length) {
                    this.group.administrators = [];
                }
                const admin = yield lastValueFrom(this._groupService.addGroupAdmin(this.group, this.selectedAdmin));
                this.group.administrators.push(admin);
                this.closeMenu();
                this.updateDatasource();
                this._snackBarService.open(this._translationService.instant('GROUP_DETAIL.MESSAGES.SNACKBAR.ADD_ADMIN_OK'));
            }
            catch (_a) {
                this._snackBarService.openDefaultError();
            }
        });
    }
    closeMenu() {
        this.menu.closeMenu();
        this.selectedAdmin = null;
    }
    onSelectNewAdmin(futureAdmin) {
        if (futureAdmin) {
            this.selectedAdmin = futureAdmin;
        }
    }
    isAdminCurrentUser(admin) {
        return admin._id === this._access.user._id;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ListAdminComponent.prototype, "isUserAdmin", void 0);
__decorate([
    Input(),
    __metadata("design:type", typeof (_f = typeof Group !== "undefined" && Group) === "function" ? _f : Object)
], ListAdminComponent.prototype, "group", void 0);
__decorate([
    ViewChild('selectMemberMenuTrigger', { static: false }),
    __metadata("design:type", typeof (_g = typeof MatMenuTrigger !== "undefined" && MatMenuTrigger) === "function" ? _g : Object)
], ListAdminComponent.prototype, "menu", void 0);
ListAdminComponent = __decorate([
    Component({
        selector: 'mpx-list-admin',
        template: require('./list-admin.component.html').default,
        styles: [require('./list-admin.component.scss')],
        animations: fuseAnimations
    }),
    __metadata("design:paramtypes", [typeof (_a = typeof DialogService !== "undefined" && DialogService) === "function" ? _a : Object, typeof (_b = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _b : Object, typeof (_c = typeof SnackbarService !== "undefined" && SnackbarService) === "function" ? _c : Object, typeof (_d = typeof GroupService !== "undefined" && GroupService) === "function" ? _d : Object, typeof (_e = typeof Access !== "undefined" && Access) === "function" ? _e : Object])
], ListAdminComponent);
export { ListAdminComponent };
