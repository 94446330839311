var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var _a, _b, _c;
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Drug, EDrugCategoryList } from '@mapuilabs/mpl-interfaces';
import { TranslationService } from '@services/translation/translation.service';
import { CDrugPreservation } from '@shared/constTypes/availables/drugPreservation.const';
import { Subject } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { AvailableHospitalService } from '../../available-hospital.service';
let EditAvailableComponent = class EditAvailableComponent {
    constructor(_dialogRef, _availableHospitalService, _translationService, data) {
        var _a;
        this._dialogRef = _dialogRef;
        this._availableHospitalService = _availableHospitalService;
        this._translationService = _translationService;
        this.data = data;
        this._freemiumMaxDelay = 60 * 24 * 60 * 60 * 1000; // 2 months, 60 days
        this.minTotalPrice = 0;
        this.cDrugPreservation = CDrugPreservation;
        this.hasSelectedMember = false;
        this.narcoticError = false;
        this.componentDestroyed$ = new Subject();
        this.form = new UntypedFormGroup({});
        this._setFormControls();
        this._setOfferPriceEvents();
        this.startDate = this.available ? new Date((_a = this.available) === null || _a === void 0 ? void 0 : _a.creationDate) : new Date();
        if (this._availableHospitalService.isFreemium()) {
            this.maxEndDate = new Date(this.startDate.getTime() + this._freemiumMaxDelay);
        }
    }
    ngOnDestroy() {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }
    get available() {
        var _a;
        return (_a = this.data) === null || _a === void 0 ? void 0 : _a.available;
    }
    get narcoticErrorTooltip() {
        if (this.narcoticError) {
            return 'AVAILABLE.EDIT.NARCOTIC_ERROR';
        }
        return '';
    }
    _setFormControls() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        this.form.setControl('product', new UntypedFormControl(Drug.toITheriaqueDrug((_a = this.available) === null || _a === void 0 ? void 0 : _a.drug), Validators.required));
        this.form.setControl('labo', new UntypedFormControl((_c = (_b = this.available) === null || _b === void 0 ? void 0 : _b.drug) === null || _c === void 0 ? void 0 : _c.laboratoryExp, Validators.required));
        this.form.setControl('quantity', new UntypedFormControl((_d = this.available) === null || _d === void 0 ? void 0 : _d.quantity, [Validators.min(1), Validators.required]));
        this.form.setControl('unitPrice', new UntypedFormControl((_e = this.available) === null || _e === void 0 ? void 0 : _e.unitPrice, [Validators.min(0), Validators.required]));
        this.form.setControl('manufacturerPrice', new UntypedFormControl((_g = (_f = this.available) === null || _f === void 0 ? void 0 : _f.drug) === null || _g === void 0 ? void 0 : _g.manufacturerPrice, Validators.min(0)));
        this.form.setControl('batchNumber', new UntypedFormControl((_h = this.available) === null || _h === void 0 ? void 0 : _h.batchNumber, Validators.required));
        this.form.setControl('preservation', new UntypedFormControl((_k = (_j = this.available) === null || _j === void 0 ? void 0 : _j.drug) === null || _k === void 0 ? void 0 : _k.preservation));
        this.form.setControl('expiryDate', new UntypedFormControl((_l = this.available) === null || _l === void 0 ? void 0 : _l.expiryDate, Validators.required));
        this.form.setControl('description', new UntypedFormControl((_m = this.available) === null || _m === void 0 ? void 0 : _m.description));
        this.form.setControl('member', new UntypedFormControl(this.memberWithHospital, Validators.required));
        this.form.setControl('endDate', new UntypedFormControl((_o = this.available) === null || _o === void 0 ? void 0 : _o.endDate, Validators.required));
        this.form.setControl('discount', new UntypedFormControl(this.discount, [Validators.min(0), Validators.max(100)]));
    }
    get memberWithHospital() {
        var _a, _b, _c, _d, _e;
        return {
            _id: (_b = (_a = this.available) === null || _a === void 0 ? void 0 : _a.member) === null || _b === void 0 ? void 0 : _b._id,
            fullName: (_d = (_c = this.available) === null || _c === void 0 ? void 0 : _c.member) === null || _d === void 0 ? void 0 : _d.fullName,
            hospital: (_e = this.available) === null || _e === void 0 ? void 0 : _e.hospital
        };
    }
    get productControl() {
        return this.form.get('product');
    }
    get laboControl() {
        return this.form.get('labo');
    }
    get quantityControl() {
        return this.form.get('quantity');
    }
    get unitPriceControl() {
        return this.form.get('unitPrice');
    }
    get discountControl() {
        return this.form.get('discount');
    }
    get manufacturerPriceControl() {
        return this.form.get('manufacturerPrice');
    }
    get batchNumberControl() {
        return this.form.get('batchNumber');
    }
    get preservationControl() {
        return this.form.get('preservation');
    }
    get expiryDateControl() {
        return this.form.get('expiryDate');
    }
    get descriptionControl() {
        return this.form.get('description');
    }
    get memberControl() {
        return this.form.get('member');
    }
    get endDateControl() {
        return this.form.get('endDate');
    }
    _setOfferPriceEvents() {
        this.quantityControl.valueChanges
            .pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
            .subscribe((val) => {
            if (!val || val < 0) {
                return;
            }
            this._checkTotalPrice();
        });
        this.unitPriceControl.valueChanges
            .pipe(debounceTime(250), takeUntil(this.componentDestroyed$))
            .subscribe((val) => {
            if (val == null || val < 0) {
                return;
            }
            this._checkTotalPrice();
            this._updateDiscount();
        });
        this.discountControl.valueChanges
            .pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
            .subscribe((val) => {
            if (val == null || val < 0) {
                return;
            }
            this._updatePrice();
            this._checkTotalPrice();
        });
        this.manufacturerPriceControl.valueChanges
            .pipe(debounceTime(500), takeUntil(this.componentDestroyed$))
            .subscribe((val) => {
            if (val == null) {
                return this.discountControl.setValue(null, { emitEvent: false });
            }
            if (val < 0) {
                return;
            }
            this._updatePrice();
            this._updateDiscount();
        });
    }
    _checkTotalPrice(min = this.minTotalPrice) {
        if (this.total == null) {
            return;
        }
        if (this.total < min) {
            this.unitPriceControl.setErrors({ totalTooLow: true });
            this.quantityControl.setErrors({ totalTooLow: true });
        }
        else {
            this.unitPriceControl.setErrors(null);
            this.quantityControl.setErrors(null);
        }
    }
    get showTotalTooLowError() {
        if (!this.quantityControl || !this.unitPriceControl) {
            return false;
        }
        return !!this.quantityControl.getError('totalTooLow') || !!this.unitPriceControl.getError('totalTooLow');
    }
    _updateDiscount() {
        if (this.discount == null) {
            return;
        }
        if (isNaN(this.discount)) {
            return;
        }
        this.discountControl.setValue(this.discount, { emitEvent: false });
    }
    _updatePrice() {
        if (this.unitPrice) {
            this.unitPriceControl.setValue(this.unitPrice, { emitEvent: false });
        }
    }
    get unitPrice() {
        if (this.discountControl.value == null || this.manufacturerPriceControl.value == null) {
            return undefined;
        }
        if (isNaN(this.discountControl.value) || isNaN(this.manufacturerPriceControl.value)) {
            return undefined;
        }
        return Number(((1 - this.discountControl.value / 100) * this.manufacturerPriceControl.value).toFixed(2));
    }
    get discount() {
        if (this.unitPriceControl.value == null || this.manufacturerPriceControl.value == null) {
            return undefined;
        }
        if (isNaN(this.unitPriceControl.value) || isNaN(this.manufacturerPriceControl.value)) {
            return undefined;
        }
        return Number(((1 - this.unitPriceControl.value / this.manufacturerPriceControl.value) * 100).toFixed(2));
    }
    get total() {
        if (this.unitPriceControl.value == null || this.quantityControl.value == null) {
            return undefined;
        }
        if (isNaN(this.unitPriceControl.value) || isNaN(this.quantityControl.value)) {
            return undefined;
        }
        return Number((this.unitPriceControl.value * this.quantityControl.value).toFixed(2));
    }
    onSelectProduct(event) {
        this.productControl.setValue(event);
        if (!event.ucd13) {
            return;
        }
        this.narcoticError = event.sp_cdf_li_code_fk == EDrugCategoryList.Narcotic;
        this._availableHospitalService
            .getDrugCommercialInfo(event.ucd13)
            .pipe(take(1))
            .subscribe((res) => {
            var _a, _b;
            if ((res === null || res === void 0 ? void 0 : res.length) > 0) {
                this.laboControl.setValue((_a = res[0]) === null || _a === void 0 ? void 0 : _a.laboratory_exp);
                this.manufacturerPriceControl.setValue((_b = res[0]) === null || _b === void 0 ? void 0 : _b.unit_price);
            }
        });
    }
    get hasSelectedProduct() {
        var _a;
        return !!((_a = this.productControl.value) === null || _a === void 0 ? void 0 : _a.ucd13);
    }
    onMemberSelected(member) {
        if (!member) {
            this.hasSelectedMember = false;
            return;
        }
        this.form.controls.member.setValue(member);
        this.hasSelectedMember = true;
    }
    _checkForm() {
        if (this.memberControl.touched && !this.hasSelectedMember) {
            this.memberControl.setErrors({
                unselected: this._translationService.instant('AVAILABLE.EDIT.INPUTS.MEMBER.UNSELECTED_MEMBER')
            });
            return false;
        }
        return true;
    }
    submitForm() {
        var _a, _b, _c;
        if (this.form.invalid || this.form.pristine || !this.form.dirty) {
            return;
        }
        if (this._checkForm()) {
            const available = {
                _id: (_c = (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.available) === null || _b === void 0 ? void 0 : _b._id) !== null && _c !== void 0 ? _c : undefined,
                drug: {
                    name: this.productControl.value.denomination,
                    DCI: this.productControl.value.sac_nom,
                    UCD13: this.productControl.value.ucd13,
                    laboratoryExp: this.laboControl.value,
                    preservation: this.preservationControl.value,
                    manufacturerPrice: this.manufacturerPriceControl.value
                        ? Number(this.manufacturerPriceControl.value)
                        : null
                },
                quantity: this.quantityControl.value,
                unitPrice: this.unitPriceControl.value,
                batchNumber: this.batchNumberControl.value,
                expiryDate: this.expiryDateControl.value,
                endDate: new Date(new Date(this.endDateControl.value).setHours(23, 59, 59)),
                description: this.descriptionControl.value,
                member: this.memberControl.value,
                hospital: this.memberControl.value.hospital
            };
            this._dialogRef.close(available);
        }
    }
};
EditAvailableComponent = __decorate([
    Component({
        selector: 'mpx-edit-available',
        template: require('./edit-available.component.html').default,
        styles: [require('./edit-available.component.scss')]
    }),
    __param(3, Inject(MAT_DIALOG_DATA)),
    __metadata("design:paramtypes", [typeof (_a = typeof MatDialogRef !== "undefined" && MatDialogRef) === "function" ? _a : Object, typeof (_b = typeof AvailableHospitalService !== "undefined" && AvailableHospitalService) === "function" ? _b : Object, typeof (_c = typeof TranslationService !== "undefined" && TranslationService) === "function" ? _c : Object, Object])
], EditAvailableComponent);
export { EditAvailableComponent };
